import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Theme } from "../../appConfig";
import { Color, FontWeight, Media } from "../../theme";
import { hexToRgba } from "../../helpers/colors";
import { styleWhenTrue } from "../../helpers/rendering";

import { ReactComponent as ExternalSvg } from "../../images/externalIcon.svg";
import { ReactComponent as InternalSvg } from "../../images/internalIcon.svg";
import { ReactComponent as UserAddSvg } from "../../images/userAdd.svg";
import { ReactComponent as UserCheckSvg } from "../../images/userCheck.svg";

export const Content = styled.div`
  max-width: 800px;
  padding: 80px 30px;
  margin: 0 auto;

  ${Media.tablet`
    padding: 120px 30px;
  `}
`;

export const Title = styled.h1`
  font-weight: ${FontWeight.SemiBold};
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 25px;
  color: ${Color.deepGray};
`;

export const Asterix = styled.span`
  color: ${Theme.RegistrationColorTheme};
`;

export const OptionsRow = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-of-type) {
    margin-bottom: 60px;
  }

  ${Media.tablet`
    flex-direction: row;
  `}
`;

const IconStyles = css`
  margin-right: 35px;
  flex-shrink: 0;
`;

export const ExternalIcon = styled(ExternalSvg)`
  ${IconStyles}
`;

export const InternalIcon = styled(InternalSvg)`
  ${IconStyles}
`;

export const UserAddIcon = styled(UserAddSvg)`
  ${IconStyles}
`;

export const UserCheckIcon = styled(UserCheckSvg)`
  ${IconStyles}
`;

export const OptionText = styled.div`
  text-align: left;
`;

export const OptionTitle = styled.h1`
  font-size: 14px;
  color: ${Color.almostBlack};
`;

export const OptionSubtitle = styled.p`
  font-weight: ${FontWeight.Light};
  font-size: 10px;
  line-height: 14px;
  margin-top: 3px;
`;

export const Option = styled.button`
  display: flex;
  align-items: center;
  height: 100px;
  width: 100%;
  background-color: ${Color.transparent};
  border: 1px solid ${hexToRgba(Color.gray4, 0.3)};
  padding: 0 35px;
  border-radius: 2px;

  & + & {
    margin-top: 8px;
  }

  ${styleWhenTrue(prop("selected"), css`
    border-color: ${Theme.RegistrationColorTheme};

    ${ExternalIcon}, ${InternalIcon} {
      path {
        stroke: ${Theme.RegistrationColorTheme};
      }
    }

    ${UserAddIcon}, ${UserCheckIcon} {
      path {
        fill: ${Theme.RegistrationColorTheme};
      }
    }

    ${OptionTitle} {
      font-weight: ${FontWeight.Bold};
    }
  `)}

  ${styleWhenTrue(prop("$unselected"), css`
    ${ExternalIcon}, ${InternalIcon} {
      path {
        stroke: ${Color.gray7};
      }
    }

    ${UserAddIcon}, ${UserCheckIcon} {
      path {
        fill: ${Color.gray7}
      }
    }
  `)}

  ${Media.tablet`
    max-width: 392px;

    & + & {
      margin-top: 0;
      margin-left: 16px;
    }
  `}
`;
