import styled from "styled-components";

import { FontWeight, Color, Media } from "../../../../../../../theme";
import { ReactComponent as ArrowBaseIcon } from "../../../../../../../images/arrow.svg";

export const Container = styled.div`
  display: none;
  position: absolute;
  top: 130px;
  font-size: 16px;
  width: 33px;
  height: 123px;
  font-weight: ${FontWeight.SemiBold};

  ${Media.tablet`
    display: flex;
    flex-direction: column;
    align-items: center;
    right: 60px;
  `}

  ${Media.desktop`
    right: 90px;
  `}
`;

export const OptionsSelectedCounter = styled.span`
  color: ${Color.blue};
  margin-bottom: 5px;
`;

export const OptionsCounter = styled.span`
  color: ${Color.gray3};
`;

export const ArrowIcon = styled(ArrowBaseIcon)`
  fill: ${Color.blue};
`;

export const ArrowUpIcon = styled(ArrowIcon)`
  transform: rotate(90deg);
  margin-bottom: 30px;
  cursor: pointer;
`;

export const ArrowDownIcon = styled(ArrowIcon)`
  transform: rotate(-90deg);
  margin-top: 30px;
  cursor: pointer;
`;
