import React, { useMemo } from "react";
import { times, identity } from "ramda";

import { Separator, Part } from "./radialSeparator.styled";

export const RadialSeparator = ({ strokeWidth }) => {
  const indexCounter = times(identity, 13);
  const turns = useMemo(() => 1 / indexCounter.length, [indexCounter]);

  const renderSeparator = (turn) => (
    <Separator key={turn} turn={turn}>
      <Part strokeWidth={strokeWidth} />
    </Separator>
  );

  return indexCounter.map((index) => renderSeparator(index * turns));
};
