import { ReactComponent as ModuleIcon } from "../../images/forms.svg";

export const Config = {
  moduleName: "surveys",
  dependencies: [],
  label: "app.mainNavigation.formsAndTests",
  Icon: ModuleIcon,
  urls: ["/formularze"],
  routeName: "api_survey_list",
  routes: {
    entry: "/:id"
  },
  options: {},
  theme: {}
};
