import { createSelector } from "reselect";
import { prop } from "ramda";

export const selectCompetenceDomain = prop("competence");

export const selectIsPending = createSelector(
  selectCompetenceDomain, prop("isPending"),
);

export const selectUserCompetenceList = createSelector(
  selectCompetenceDomain, prop("userCompetenceList"),
);

export const selectCompetenceOverview = createSelector(
  selectCompetenceDomain, prop("competenceOverview"),
);

export const selectCompetenceSkills = createSelector(
  selectCompetenceDomain, prop("competenceSkills"),
);

export const selectCompetenceHistory = createSelector(
  selectCompetenceDomain, prop("competenceHistory"),
);

export const selectCompetenceStatistics = createSelector(
  selectCompetenceDomain, prop("competenceStatistics"),
);

export const selectCompetenceScales = createSelector(
  selectCompetenceDomain, prop("competenceScales"),
);

export const selectResources = createSelector(
  selectCompetenceDomain, prop("resources"),
);

export const selectResourceStatistic = createSelector(
  selectResources, prop("statistic"),
);

export const selectResourceItems = createSelector(
  selectResources, prop("items"),
);

export const selectSelfMarkSurvey = createSelector(
  selectCompetenceDomain, prop("selfMarkSurvey"),
);

export const selectSelfMarkSurveyPages = createSelector(
  selectSelfMarkSurvey, prop("pages"),
);

export const selectConfirmationCategories = createSelector(
  selectCompetenceDomain, prop("confirmationCategories"),
);

export const selectConfirmationItems = createSelector(
  selectCompetenceDomain, prop("confirmations"),
);
