import styled from "styled-components";

import { CommentList as CommentListBase } from "../../components/commentList/commentList.component";
import { hexToRgba } from "../../helpers/colors";
import { Color, FontWeight, Media } from "../../theme";

export const Container = styled.div``;

export const CommentsWrapper = styled.div`
  max-width: 980px;
  margin: 0 auto;
  padding: 0 0 70px;

  ${Media.tablet`
    padding: 0 20px 70px;
  `}
`;

export const RelatedVideosWrapper = styled.div`
  padding-top: 70px;
`;

export const CommentCounter = styled.p`
  font-size: 17px;
  line-height: 20px;
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.black};
  margin: 70px 20px 32px;

  ${Media.tablet`
    margin: 70px 0 32px;
  `}
`;

export const CommentList = styled(CommentListBase)`
  ${Media.tablet`
    border: 1px solid ${Color.gray8};
    box-shadow: 0 5px 10px 0 ${hexToRgba(Color.deepGray, 0.01)};
  `}
`;
