export const IDDFilterTheme = {
  Visible: "visible",
  Hidden: "hidden",
};

export const CircleColorType = {
  Gold: "gold",
  Silver: "silver",
  Bronze: "bronze",
  Empty: "empty",
};

export const FilterValue = {
  Video: "video",
  Contents: "contents",
  Minutes: "minutes",
};

export const TagType = {
  Competence: "competence",
  Default: "default",
};

export const FilterType = {
  All: {
    name: "learning.filters.filters.all",
    type: "type",
    value: "all",
  },
  Lesson: {
    name: "learning.filters.filters.elearning",
    type: "type",
    value: "lesson",
  },
  Contents: {
    name: "learning.filters.filters.video",
    type: "type",
    value: "contents",
  },
  Files: {
    name: "global.filesToDownload",
    type: "type",
    value: "files",
  },
  IDD: {
    name: "global.idd",
    type: "type",
    value: "minutes",
  },
  Cyclical: {
    name: "learning.filters.filters.cyclic",
    type: "cyclical",
    value: true,
  },
  Required: {
    name: "learning.filters.filters.required",
    type: "required",
    value: true,
  },
  StatusNotStarted: {
    name: "learning.filters.filters.notStarted",
    type: "statusKey",
    value: "not_started",
  },
  StatusComplete: {
    name: "learning.filters.filters.complete",
    type: "statusKey",
    value: "finished",
  },
  StatusInProgress: {
    name: "learning.filters.filters.during",
    type: "statusKey",
    value: "in_progress",
  },
  StatusFailed: {
    name: "learning.filters.filters.failed",
    type: "statusKey",
    value: "failed",
  },
};
