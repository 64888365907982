import { ReactComponent as ModuleIcon } from "./images/moduleIcon.svg";

export const Config = {
  moduleName: "videoPlayer",
  dependencies: [],
  label: "Video player",
  Icon: ModuleIcon,
  urls: ["/wideo/:id"],
  routeName: "",
  routes: {},
  options: {},
  theme: {},
};
