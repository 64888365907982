import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { Loader } from "../../components/loader/loader.component";
import { renderWhenTrue, renderWhenTrueOtherwise } from "../../helpers/rendering";
import { useModuleConfig, ModuleType } from "../";

import { SurveyItem } from "./components/surveyItem/surveyItem.component";
import {
  selectIsPending,
  selectSurveys,
  selectTests,
  selectCompetenceMeasures,
} from "./redux/surveys.selectors";
import { SurveysActions } from "./redux/surveys.reducer";
import {
  Container,
  Wrapper,
  PageHeader,
  SectionHeader,
} from "./surveys.styled";
import { TestItem } from "./components/testItem/testItem.component";

const Surveys = () => {
  const { t } = useTranslation();
  const config = useModuleConfig(ModuleType.Surveys);
  const dispatch = useDispatch();
  const isPending = useSelector(selectIsPending);
  const tests = useSelector(selectTests);
  const surveys = useSelector(selectSurveys);
  const competenceMeasures = useSelector(selectCompetenceMeasures);

  useEffect(() => {
    dispatch(SurveysActions.fetchLists());

    return () => {
      dispatch(SurveysActions.clearLists());
    };
  }, [dispatch]);

  const renderTestItem = (item) => (
    <TestItem
      key={item.id}
      title={item.name}
      dateTimestamp={item.createdAt}
      attempts={item.attempts}
      result={item.result}
      award={item.award}
      minutes={item.minutes}
    />
  );

  const renderSurveyItem = (item) => (
    <SurveyItem
      key={item.id}
      title={item.name}
      id={item.id}
    />
  );

  const renderMeasureItem = (item) => (
    <TestItem
      key={item.id}
      title={item.name}
      dateTimestamp={item.createdAt}
      attempts={item.attempts}
      competenceResult={item.competenceResult || {}}
    />
  );

  const renderTestList = renderWhenTrue(() => (
    <>
      <SectionHeader>{t("forms.formAndTest.tests")}</SectionHeader>
      {tests.map(renderTestItem)}
    </>
  ));

  const renderSurveyList = renderWhenTrue(() => (
    <>
      <SectionHeader>{t("forms.formAndTest.surveys")}</SectionHeader>
      {surveys.map(renderSurveyItem)}
    </>
  ));

  const renderCompetenceMeasureList = renderWhenTrue(() => (
    <>
      <SectionHeader>{t("surveys.competenceMeasures")}</SectionHeader>
      {competenceMeasures.map(renderMeasureItem)}
    </>
  ));

  const renderSurveys = () => (
    <Wrapper>
      <PageHeader>{t(config.label)}</PageHeader>
      {renderTestList(!!tests.length)}
      {renderCompetenceMeasureList(!!competenceMeasures.length)}
      {renderSurveyList(!!surveys.length)}
    </Wrapper>
  );

  const renderContent = renderWhenTrueOtherwise(
    renderSurveys,
    () => <Loader />,
  );

  return (
    <ThemeProvider theme={config.theme}>
      <Container>
        {renderContent(!isPending)}
      </Container>
    </ThemeProvider>
  );
};

export default Surveys;
