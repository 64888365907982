import React, { useCallback, useMemo, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { cond, equals, T, always } from "ramda";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";

import { VideoModal } from "../../modules/dashboard/components/videoModal/videoModal.component";
import { selectFavouriteAddRouteAvailable } from "../../userContext/redux/userContext.selectors";
import { CommonActions } from "../../redux/common/common.reducer";
import { ReactComponent as DownloadIcon } from "../../images/download.svg";
import { ReactComponent as CheckImg } from "../../images/check.svg";
import { ReactComponent as GoldMedal } from "../../images/goldMedal.svg";
import { ReactComponent as SilverMedal } from "../../images/silverMedal.svg";
import { ReactComponent as BronzeMedal } from "../../images/bronzeMedal.svg";
import {
  renderWhenTrue,
  renderWhenTrueOtherwise,
} from "../../helpers/rendering";
import { AppConfig } from "../../appConfig";
import { Tooltip } from "../tooltip/tooltip.component";

import { MultiFilesDrawer } from "./components/multiFilesDrawer/multiFilesDrawer.component";
import {
  Container,
  Image,
  ImageWrapper,
  Title,
  RequiredIcons,
  WarningIcon,
  ClockIcon,
  MarksWrapper,
  MultiFilesOverlay,
  MultiFilesCounter,
  MultiFilesIcon,
  ProgressBarBackground,
  ProgressBar,
  Category,
  DownloadIconWrapper,
  FavouriteIcon,
  FavouriteListButton,
  TextsWrapper,
  Description,
  TitleAndCategory,
} from "./thumbnail.styled";
import {
  AwardType,
  FavouriteItemType,
  ItemType,
} from "./thumbnail.constants";

export const Thumbnail = (props) => {
  const {
    className,
    id,
    type,
    title,
    image,
    required,
    minutes,
    completed,
    award,
    items,
    progress,
    category,
    validityTimestamp,
    urlMiniature,
    url,
    videoTitle,
    favorite = false,
    description,
    listView,
    setThumbnailHeight,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  const favouriteAddRouteAvailable = useSelector(selectFavouriteAddRouteAvailable);
  const [drawerVisible, setDrawerVisible] = useState(hash === `#${id}` && type === ItemType.Contents);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFavouriteIconActive, setIsFavouriteIconActive] = useState(favorite);
  const imageSrc = useMemo(() => {
    if (type === ItemType.Files) {
      return "/assets/img/files.jpg";
    }
    return image || (items && items[0].image);
  }, [image, items, type]);

  useEffect(() => {
    setIsFavouriteIconActive(favorite);
  }, [favorite]);

  const observer = useRef(new ResizeObserver((entries) => {
    const { height } = entries[0].contentRect;
    if (height) {
      setThumbnailHeight(height);
    }
  }));

  useEffect(() => {
    if (imageRef?.current && setThumbnailHeight) {
      observer.current.observe(imageRef.current);
    }
  }, [imageRef, observer]);

  useEffect(() => {
    setDrawerVisible(hash === `#${id}` && type === ItemType.Contents);
  }, [hash, type, id]);

  const handleOnClick = useCallback(() => {
    if (items) {
      setDrawerVisible(true);
      if (type === ItemType.Contents) {
        navigate(`#${id}`);
      }
    } else if (type === ItemType.Lesson || type === ItemType.ScrollLesson) {
      navigate(`/lekcje/${id}`);
    } else if (type === ItemType.Video) {
      navigate(`/wideo/${id}`);
    } else if (url) {
      setIsModalOpen(true);
    }
  }, [id, type, navigate, items, url]);

  const handleCloseDrawer = () => {
    setDrawerVisible(false);

    if (type === ItemType.Contents) {
      navigate("");
    }
  };

  const getCompletedMark = cond([
    [equals(AwardType.Gold), always(<GoldMedal />)],
    [equals(AwardType.Silver), always(<SilverMedal />)],
    [equals(AwardType.Bronze), always(<BronzeMedal />)],
    [T, always(<CheckImg />)],
  ]);

  const toggleFavouriteItem = (event) => {
    event.stopPropagation();
    let materialType;
    if (type === ItemType.Contents) materialType = FavouriteItemType.ContentGroup;
    if (type === ItemType.Files) materialType = FavouriteItemType.FileGroup;
    if (type === ItemType.Items) materialType = FavouriteItemType.Competence;
    if (type === ItemType.Lesson || type === ItemType.Video || type === ItemType.ScrollLesson) {
      materialType = FavouriteItemType.Content;
    }

    if (isFavouriteIconActive) {
      dispatch(CommonActions.removeFavouriteItem(materialType, id));
    } else {
      dispatch(CommonActions.addFavouriteItem(materialType, id));
    }
    setIsFavouriteIconActive(!isFavouriteIconActive);
  };

  const renderMinutesIcon = renderWhenTrue(() => <ClockIcon />);

  const renderWarningIcon = renderWhenTrue(() => (
    <Tooltip title={t("learning.filters.filters.required")}>
      <WarningIcon />
    </Tooltip>
  ));

  const renderCompletedMark = renderWhenTrue(() => getCompletedMark(award));

  const renderDownloadIcon = renderWhenTrue(() => (
    <DownloadIconWrapper>
      <DownloadIcon />
    </DownloadIconWrapper>
  ));

  const renderProgress = renderWhenTrue(() => (
    <ProgressBarBackground>
      <ProgressBar value={progress * 100} />
    </ProgressBarBackground>
  ));

  const renderMultiFilesOverlay = renderWhenTrue(() => (
    <MultiFilesOverlay>
      <MultiFilesCounter>{items.length}</MultiFilesCounter>
      <MultiFilesIcon />
    </MultiFilesOverlay>
  ));

  const renderValidity = renderWhenTrue(() => (
    <>| {dayjs.unix(validityTimestamp).format("YYYY-MM-DD")}</>
  ));

  const renderImage = renderWhenTrueOtherwise(
    () => <Image src={`${AppConfig.content}${imageSrc}`} />,
    () => <Image src={`${urlMiniature}`} />
  );

  const handleCloseModal = () => setIsModalOpen(false);

  const renderVideoModal = renderWhenTrue(() => (
    <VideoModal
      open={isModalOpen}
      handleCloseModal={handleCloseModal}
      videoSrc={url}
      videoTitle={videoTitle}
    />
  ));

  const renderCategory = renderWhenTrue(() => (
    <Category $listView={listView}>
      {category} {renderValidity(!!validityTimestamp)}
    </Category>
  ));

  return (
    <>
      <Container
        className={className}
        onClick={handleOnClick}
        $listView={listView}
      >
        <ImageWrapper ref={imageRef} $listView={listView}>
          {renderImage(!urlMiniature)}
          {renderMultiFilesOverlay(!!items)}
          {renderProgress(!!progress && progress !== 1)}
          <MarksWrapper>
            {favouriteAddRouteAvailable && (
              <Tooltip
                title={
                  isFavouriteIconActive
                    ? t("favouriteList.removeFromFavourites")
                    : t("favouriteList.addToFavourites")
                }
              >
                <FavouriteListButton onClick={toggleFavouriteItem} $isFavourite={isFavouriteIconActive}>
                  <FavouriteIcon />
                </FavouriteListButton>
              </Tooltip>
            )}
            {renderCompletedMark(!!completed)}
            {renderDownloadIcon(type === ItemType.Files)}
          </MarksWrapper>
          {!!listView && (
            <RequiredIcons>
              {renderMinutesIcon(!!minutes)}
              {renderWarningIcon(!!required)}
            </RequiredIcons>
          )}
        </ImageWrapper>
        <TextsWrapper>
          <TitleAndCategory $listView={listView}>
            {renderCategory(!!category)}
            <Title $listView={listView}>{title}</Title>
          </TitleAndCategory>
          {!!description && listView && (
            <Description listView={listView}>
              {description}
            </Description>
          )}
        </TextsWrapper>
        {!listView && (
          <RequiredIcons>
            {renderMinutesIcon(!!minutes)}
            {renderWarningIcon(!!required)}
          </RequiredIcons>
        )}
      </Container>
      <MultiFilesDrawer
        open={drawerVisible && !!items}
        items={items}
        title={title}
        onClose={handleCloseDrawer}
        type={type}
      />
      {renderVideoModal(!!url)}
    </>
  );
};
