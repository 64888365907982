import styled from "styled-components";

import { Theme } from "../../appConfig";
import { Color, FontWeight, Media } from "../../theme";
import { Button as ButtonBase } from "../../components/button/button.component";

import { ReactComponent as InfoSvg } from "../../images/infoIcon.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 115px;
  padding: 30px;
  border-top: 1px solid ${Color.gray5};

  ${Media.tablet`
    flex-direction: row;
    padding: 0 30px;
  `}
`;

export const ButtonsRow = styled.div`
  margin-top: 25px;
  width: 100%;
  margin-left: auto;

  ${Media.tablet`
    margin-top: 0;
    width: auto;
    display: flex;
  `}
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoIcon = styled(InfoSvg)`
  margin-right: 15px;
  path {
    fill: ${Theme.RegistrationColorTheme};
  }
`;

export const InfoText = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: ${Color.gray4};
`;

export const InfoLink = styled.a`
  text-decoration: none;
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.deepGray};
`;

export const Button = styled(ButtonBase)`
  width: 100%;

  & + & {
    margin-top: 10px;

    ${Media.tablet`
      margin-left: 20px;
      margin-top: 0;
    `}
  }
`;
