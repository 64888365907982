import React from "react";
import PropTypes from "prop-types";

import { Container } from "./icon.styled";
import { IconVariant } from "./icon.constants";

export const Icon = (props) => {
  const {
    className,
    onClick = Function.prototype,
    active,
    special,
    children,
    size,
    variant = IconVariant.Default
  } = props;
  return (
    <Container
      onClick={onClick}
      className={className}
      size={size}
      $variant={variant}
      $active={active}
      special={special}
    >
      {children}
    </Container>
  );
};

Icon.propTypes = {
  children: PropTypes.any,
  variant: PropTypes.oneOf(Object.values(IconVariant)),
  size: PropTypes.number,
  className: PropTypes.string,
  active: PropTypes.bool,
  special: PropTypes.bool,
  onClick: PropTypes.func,
};
