/* eslint-disable react/no-string-refs */
import React from "react";
export default class Player extends React.Component {
  componentWillUnmount() {
    const { video } = this.refs;
    video.pause();
    video.src = "";
  }

  render() {
    const { url, finished } = this.props;
    return (
      <div>
        <video ref="video" preload="auto" autoPlay={ true } onEnded={ finished }>
          <source src={ url } type="video/mp4" />
        </video>
      </div>
    );
  }
}
