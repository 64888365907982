import React, { useState } from "react";
import { google, ics } from "calendar-link";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { useTranslation } from "react-i18next";

import { ButtonVariant } from "../../../../components/button/button.constants";
import GoogleIcon from "../../images/google.png";
import OutlookIcon from "../../images/outlook.png";
import AppleIcon from "../../images/apple.png";

import {
  Container,
  StyledButton,
  Options,
  Option,
  CalendarLink,
  CalendarIcon,
  OptionIcon,
} from "./addToCalendar.styled";
import { getCalendarEvent } from "./addToCalendar.utils";

export const AddToCalendar = ({ trainingData }) => {
  const { t } = useTranslation();
  const [optionsOpen, setOptionsOpen] = useState(false);
  const calendarEventIcs = getCalendarEvent(trainingData, true);
  const calendarEventOnline = getCalendarEvent(trainingData, false);

  const toggleCalendarOptions = () => {
    setOptionsOpen(!optionsOpen);
  };

  return (
    <ClickAwayListener onClickAway={() => setOptionsOpen(false)}>
      <Container>
        <StyledButton
          $large
          $isOpen={optionsOpen}
          variant={ButtonVariant.Outlined}
          onClick={toggleCalendarOptions}
        >
          <CalendarIcon />
          {t("training.addToCalendar.addToCalendar")}
        </StyledButton>
        <Options $visible={optionsOpen}>
          <Option>
            <CalendarLink
              href={google(calendarEventOnline)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <OptionIcon src={GoogleIcon} />
              {t("training.addToCalendar.google")}
            </CalendarLink>
          </Option>
          <Option>
            <CalendarLink
              href={ics(calendarEventIcs)}
              target="_blank"
              rel="noopener noreferrer"
              download={`${calendarEventIcs.title}.ics`}
            >
              <OptionIcon src={OutlookIcon} />
              {t("training.addToCalendar.outlook")}
            </CalendarLink>
          </Option>
          <Option>
            <CalendarLink
              href={ics(calendarEventIcs)}
              target="_blank"
              rel="noopener noreferrer"
              download={`${calendarEventIcs.title}.ics`}
            >
              <OptionIcon src={AppleIcon} />
              {t("training.addToCalendar.apple")}
            </CalendarLink>
          </Option>
        </Options>
      </Container>
    </ClickAwayListener>
  );
};
