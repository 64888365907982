import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color } from "../../../../theme/colors";
import { Media } from "../../../../theme/media";

export const Container = styled.div`
  padding: 30px 80px 30px 30px;
  background-color: ${Color.white};
  position: relative;

  ${Media.desktop`
    height: 385px;
  `}
`;

export const Avatar = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
`;

export const Name = styled.p`
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  color: ${Color.deepGray};
  margin-bottom: 15px;
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${Color.gray6};
  margin-bottom: 49px;
`;

export const DataWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 13px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Data = styled.span`
  color: ${Color.deepGray};
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  margin-left: 11px;

  ${styleWhenTrue(prop("lowercase"), css`
    text-transform: lowercase;
  `)}
`;

export const ContactInfoWrapper = styled.div`
  margin-top: 50px;

  ${Media.desktop`
    position: absolute;
    bottom: 30px;
    left: 30px;
  `}
`;
