import React from "react";
import PropTypes from "prop-types";

import {
  ProgressBorder,
  MarkCircle,
  StarIcon,
  Mark,
  MaxMark,
  MarkIcon,
} from "./progressMark.styled";

export const ProgressMark = ({
  className,
  rate,
  maxRate,
  large,
  variant,
}) => {
  const strokeWidth = large ? 4 : 6;

  if (!rate) {
    return (
      <MarkCircle className={className} noRate large={large}>
        <StarIcon />
      </MarkCircle>
    );
  }

  return (
    <ProgressBorder
      className={className}
      value={rate}
      maxValue={maxRate}
      strokeWidth={strokeWidth}
      large={large}
      variant={variant}
    >
      <MarkCircle large={(!!large).toString()} variant={variant}>
        <Mark large={large}>{rate}</Mark>
        <MaxMark large={large}>/{maxRate}</MaxMark>
        <MarkIcon large={(!!large).toString()} />
      </MarkCircle>
    </ProgressBorder>
  );
};

ProgressMark.propTypes = {
  className: PropTypes.string,
  rate: PropTypes.number,
  maxRate: PropTypes.number,
  large: PropTypes.bool,
  variant: PropTypes.string,
};
