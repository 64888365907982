import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { cond, equals, T, always } from "ramda";

import { ButtonColor } from "../../../../components/button/button.constants";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { ConfirmationForm } from "../confirmationForm/confirmationForm.component";
import { ConfirmationList } from "../confirmationList/confirmationList.component";
import { SuggestionForm } from "../suggestionForm/suggestionForm.component";

import { BoxType, ConfirmationData, SuggestionData } from "./suggestionBox.constants";
import {
  Container,
  SectionHeader,
  Info,
  InfoText,
  AddButtonWrapper,
  PlusIcon,
  AddButtonText,
  AddButton,
} from "./suggestionBox.styled";

export const SuggestionBox = ({ className, competenceId, type }) => {
  const { t } = useTranslation();

  const getTheme = cond([
    [equals(BoxType.Confirmation), always(ConfirmationData)],
    [T, always(SuggestionData)],
  ]);

  const getForm = cond([
    [equals(BoxType.Confirmation), always(ConfirmationForm)],
    [T, always(SuggestionForm)],
  ]);

  const theme = getTheme(type);
  const Form = getForm(type);

  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => setModalOpen(false);

  const handleOpenModal = () => setModalOpen(true);

  const renderConfirmationList = renderWhenTrue(() => (
    <ConfirmationList competenceId={competenceId} />
  ));

  return (
    <Container className={className}>
      <SectionHeader>
        {t(theme.headerId)}
      </SectionHeader>
      <Info>
        <InfoText>
          {t(theme.infoId)}
        </InfoText>
      </Info>
      <AddButtonWrapper>
        <PlusIcon />
        <AddButtonText>{t(theme.buttonInfoId)}</AddButtonText>
        <AddButton
          color={ButtonColor.Primary}
          onClick={handleOpenModal}
        >
          {t(theme.buttonTextId)}
        </AddButton>
      </AddButtonWrapper>
      <Form
        open={modalOpen}
        onClose={handleCloseModal}
        competenceId={competenceId}
      />
      {renderConfirmationList(type === BoxType.Confirmation)}
    </Container>
  );
};

SuggestionBox.propTypes = {
  className: PropTypes.string,
  competenceId: PropTypes.number,
  type: PropTypes.string.isRequired,
};
