import styled, { css } from "styled-components";
import { propEq } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color, FontWeight, Media } from "../../../../theme";
import { AnswerStatus } from "../../../interactions.constants";

export const Container = styled.div`
  border: 1px solid ${Color.dirtyWhite};
  padding: 25px 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${Media.tablet`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  `}
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 10px;
`;

export const Button = styled.button`
  width: 80px;
  height: 40px;
  background-color: ${Color.white};
  border-radius: 3px;
  border: 1px solid ${Color.blue5};
  color: ${Color.deepGray};
  font-size: 10px;
  line-height: 2;
  font-weight: ${FontWeight.Bold};
  text-transform: uppercase;

  ${styleWhenTrue(propEq(AnswerStatus.Checked, "status"), css`
    background-color: ${Color.deepGray};
    color: ${Color.white};
    border: 1px solid ${Color.deepGray};
  `)}

  ${styleWhenTrue(propEq(AnswerStatus.CorrectChecked, "status"), css`
    background-color: ${Color.green};
    color: ${Color.white};
    border: 1px solid ${Color.green};
  `)}

  ${styleWhenTrue(propEq(AnswerStatus.WrongChecked, "status"), css`
    background-color: ${Color.red};
    color: ${Color.white};
    border: 1px solid ${Color.red};
  `)}
`;
