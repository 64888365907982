import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";

import { ConfirmationModal } from "../../../../components/confirmationModal/confirmationModal.component";

import { Browser, ErrorIcon } from "./browserModal.styled";

export const BrowserModal = ({ className, open }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClose = () => navigate("/");

  return (
    <ConfirmationModal
      open={open}
      confirmText={t("webinars.browserModal.back")}
      onClose={onClose}
      className={className}
      withoutDecline
    >
      <ErrorIcon />
      {t("webinars.browserModal.browser")}
      {" "}
      <Browser>{t("webinars.browserModal.ie")}</Browser>
      {" "}
      {t("webinars.browserModal.browserSupport")}
    </ConfirmationModal>
  );
};

BrowserModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
};
