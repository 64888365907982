import styled, { css } from "styled-components";
import { prop } from "ramda";

import { hexToRgba } from "../../../../../helpers/colors";
import { styleWhenTrue } from "../../../../../helpers/rendering";
import { Color, FontWeight } from "../../../../../theme";

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 1;
`;

export const Stage = styled.button`
  width: 156px;
  height: 44px;
  padding: 0 24px;
  margin: 0;
  box-shadow: 0 6px 10px ${hexToRgba(Color.violet, 0.1)};
  border: none;
  background-color: ${Color.white};
  text-transform: uppercase;
  font-size: 9px;
  line-height: 12px;
  font-weight: ${FontWeight.ExtraBold};
  color: ${Color.green};
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin-left: 2px;
  }

  ${styleWhenTrue(prop("active"), css`
    background-color: ${Color.violet};
    color: ${Color.white};
    cursor: default;
    pointer-events: none;

    ${styleWhenTrue(prop("isLastStage"), css`
      background-color: ${Color.black};
    `)}
  `)}

  ${styleWhenTrue(prop("disabled"), css`
    background-color: ${Color.lightViolet2};
    color: ${Color.violet};
    cursor: default;
    pointer-events: none;
  `)}
`;
