import React from "react";
import styled from "styled-components";

import { Icon, ProgressHalf, GrayCircle, IconWrap } from "../UIElements";
import { colors, breakpoints } from "../UIGlobals";

const ProgressBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: ${props => (props.width ? props.width : "auto")};
  height: ${props => (props.height ? props.height + "px" : "auto")};
  background: ${props => (props.background ? colors()[props.background] : colors().white)};
  box-shadow: 0 5px 10px 0 rgba(38, 39, 42, 0.01);
  margin-right: ${props => (props.marginRight ? props.marginRight + "px" : "0")};
`;

const ProgressWrap = styled.div`
  position: relative;
  width: 156px;
  height: 156px;
  display: flex;
`;

const ProgressText = styled.span`
  font-size: 1.3rem;
  color: ${colors().gray1};
  font-weight: 600;
  text-align: center;
  display: ${props => props.display ? props.display : "block"};
  ${props => props.alignItems && "align-items: " + props.alignItems};
  ${props => props.justifyContent && "justify-content: " + props.justifyContent};
  ${props => props.bottom && "bottom: " + props.bottom};
  ${props => props.position && "position: " + props.position};
  width: 100%;
  @media (${breakpoints().maxL}) {
    margin-top: -10px;
  }
`;

export const AwardsProgressBox = ({ medalType, award, label, width, marginRight }) => {
  let iconColor, halfColor;

  switch (medalType) {
  case "gold":
    halfColor = "gold";
    iconColor = colors().yellow;
    break;
  case "silver":
    halfColor = "gray2";
    iconColor = colors().gray2;
    break;
  case "bronze":
    halfColor = "brown";
    iconColor = colors().brown;
    break;
  default:
    break;
  }

  const percentage = Math.floor(award.percent * 100);

  return (
    <ProgressBox marginRight={marginRight || 1} width={width || "100%"} height={240}>
      <ProgressWrap>
        <IconWrap>
          <Icon icon="status_medal" size={24} color={iconColor} />
        </IconWrap>
        <GrayCircle>{percentage}%</GrayCircle>
        <ProgressHalf color={halfColor} percent={percentage} />
        <ProgressText bottom="-3px" position="absolute">
          {label ? label : award.label}
        </ProgressText>
      </ProgressWrap>
    </ProgressBox>
  );
};

export { ProgressBox, ProgressText, ProgressWrap };
