import React, { useEffect, useState, useMemo, useRef } from "react";
import { equals, always, T, cond } from "ramda";
import PropTypes from "prop-types";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { OnboardingMode } from "../../../../app/app.constants";
import { UserRoleName } from "../../../../userContext/userContext.constants";
import TransitionAnim from "../../animations/transition.json";
import { selectUserStages, selectUserContentPassed, selectOnboardingMode } from "../../redux/onboarding.selectors";
import { HeroName } from "../../onboarding.constants";
import { ContentType } from "../defaultStages/stage/stage.constants";

import { Statistics } from "./statistics/statistics.component";
import { Stages } from "./stages/stages.component";
import { Task } from "./task/task.component";
import {
  Container,
  AnimationWrapper,
  GameContainer,
  BackgroundWrapper,
  LeftButton,
  RightButton,
  ArrowImg,
  AnimTextWrapper,
  SmokWrapper,
  TransitionContainer,
  CloseButton,
  CloseIcon,
  TaskMessage,
} from "./game.styled";
import {
  BgAnimPromise,
  LeasinGoBgAnimPromise,
  TextAnimPromise,
  LeasinGoTextAnimPromise,
  HeroAnimPromise,
  OrdinaryMaxStageView,
  LeasinGoMaxStageView,
  TaskPointers,
  SmokAnimPromise,
} from "./game.constants";

export const Game = ({
  hero,
  goBack,
  stages,
  initialStage,
  initialView,
  onTutorialClick,
}) => {
  const navigate = useNavigate();
  const userStages = useSelector(selectUserStages)?.stages;
  const contentPassed = useSelector(selectUserContentPassed);
  const onboardingMode = useSelector(selectOnboardingMode);
  const [onRightSide, setOnRightSide] = useState(false);
  const [faceToRight, setFaceToRight] = useState(true);
  const [staticAnim, setStaticAnim] = useState(true);
  const [resetAnim, setResetAnim] = useState(false);
  const [bgAnim, setBgAnim] = useState();
  const [textAnim, setTextAnim] = useState();
  const [animStand, setAnimStand] = useState();
  const [animWalk, setAnimWalk] = useState();
  const [smokAnim, setSmokAnim] = useState();
  const [leftButtonDisabled, setLeftButtonDisabled] = useState(false);
  const [rightButtonDisabled, setRightButtonDisabled] = useState(false);
  const [currentStage, setCurrentStage] = useState(initialStage);
  const [currentView, setCurrentView] = useState(initialView);
  const [transitionHidden, setTransitionHidden] = useState(true);
  const [taskMessageVisible, setTaskMessageVisible] = useState(false);
  const stageResult = Math.round(userStages[currentStage - 1].count / userStages[currentStage - 1].total * 100);
  const gameResult = Math.floor(contentPassed.count / contentPassed.total * 100);
  const isLeasinGoMode = onboardingMode === OnboardingMode.LeasinGo;
  const isLastStage = currentStage === (isLeasinGoMode ? 3 : 5);
  const maxStageView = isLeasinGoMode ? LeasinGoMaxStageView : OrdinaryMaxStageView;
  const timeoutRef = useRef(null);

  const areAllTasksDoneInThisView = useMemo(() => {
    const tasksDoneInThisView = stages[currentStage - 1].itemGroup["1"].items.filter((item, index) => {
      const shouldTaskBeVisibleOnCurrentView = (TaskPointers[currentStage - 1][index]?.view === currentView && item.name !== "TEST")
      || (item.name === "TEST" && currentView === maxStageView[`stage${currentStage}`]);
      return shouldTaskBeVisibleOnCurrentView && item.isEnabled && item.passed;
    }).length;
    const allTasksInThisView = stages[currentStage - 1].itemGroup["1"].items.filter((item, index) => {
      const shouldTaskBeVisibleOnCurrentView = (TaskPointers[currentStage - 1][index]?.view === currentView && item.name !== "TEST")
      || (item.name === "TEST" && currentView === maxStageView[`stage${currentStage}`]);
      return shouldTaskBeVisibleOnCurrentView;
    }).length;
    return tasksDoneInThisView === allTasksInThisView;
  }, [currentStage, currentView, stages]);

  useEffect(() => {
    const standAnimPromise = cond([
      [equals(HeroName.Doris), always(HeroAnimPromise.DorisStatic)],
      [equals(HeroName.Kate), always(HeroAnimPromise.KateStatic)],
      [equals(HeroName.Steven), always(HeroAnimPromise.StevenStatic)],
      [equals(HeroName.George), always(HeroAnimPromise.GeorgeStatic)],
      [T, always(null)],
    ]);
    const walkAnimPromise = cond([
      [equals(HeroName.Doris), always(HeroAnimPromise.DorisWalk)],
      [equals(HeroName.Kate), always(HeroAnimPromise.KateWalk)],
      [equals(HeroName.Steven), always(HeroAnimPromise.StevenWalk)],
      [equals(HeroName.George), always(HeroAnimPromise.GeorgeWalk)],
      [T, always(null)],
    ]);

    standAnimPromise(hero.name).then(setAnimStand);
    walkAnimPromise(hero.name).then(setAnimWalk);
  }, [hero.name]);

  useEffect(() => {
    const currentBgAnimPromise = isLeasinGoMode ? LeasinGoBgAnimPromise : BgAnimPromise;
    const currentTextAnimPromise = isLeasinGoMode ? LeasinGoTextAnimPromise : TextAnimPromise;

    currentBgAnimPromise[`Bg${currentStage}_${currentView}`].then(setBgAnim);
    currentTextAnimPromise[`stage${currentStage}`].then(setTextAnim);
  }, [currentStage, currentView, isLeasinGoMode]);

  useEffect(() => {
    SmokAnimPromise.then(setSmokAnim);
  }, []);

  useEffect(() => {
    if (!stages[currentStage - 1].itemGroup["1"].items.filter((item, index) => {
      const shouldTaskBeVisibleOnCurrentView = (TaskPointers[currentStage - 1][index]?.view === currentView && item.name !== "TEST")
      || (item.name === "TEST" && currentView === maxStageView[`stage${currentStage}`]);
      return shouldTaskBeVisibleOnCurrentView;
    }).length) {
      if (faceToRight) {
        if (maxStageView[`stage${currentStage}`] > currentView) {
          setCurrentView(currentView + 1);
        } else {
          setCurrentView(1);
          setCurrentStage(currentStage + 1);
        }
      } else {
        if (currentView > 1) {
          setCurrentView(currentView - 1);
        } else {
          setCurrentView(maxStageView[`stage${currentStage - 1}`]);
          setCurrentStage(currentStage - 1);
        }
      }
    }
  }, [currentStage, currentView, stages, faceToRight]);

  const handleSetOnRightSide = () => {
    setFaceToRight(true);

    if (onRightSide) {
      if (maxStageView[`stage${currentStage}`] > currentView) {
        setCurrentView(currentView + 1);
      } else {
        setCurrentView(1);
        setCurrentStage(currentStage + 1);
        setTransitionHidden(false);
        setTimeout(() => setTransitionHidden(true), 1500);
      }

      setResetAnim(true);
      setOnRightSide(false);
      setTimeout(() => setResetAnim(false), 1);
      return;
    }

    setOnRightSide(true);
    setStaticAnim(false);
    setLeftButtonDisabled(true);
    setRightButtonDisabled(true);

    timeoutRef.current = setTimeout(() => {
      if (maxStageView[`stage${currentStage}`] > currentView) {
        setCurrentView(currentView + 1);
      } else {
        setCurrentView(1);
        setCurrentStage(currentStage + 1);
        setTransitionHidden(false);
        setTimeout(() => setTransitionHidden(true), 1500);
      }

      setStaticAnim(true);
      setResetAnim(true);
      setOnRightSide(false);
      setLeftButtonDisabled(false);
      setRightButtonDisabled(false);
      setTimeout(() => setResetAnim(false), 1);
    }, 3000);
  };

  const handleSetOnLeftSide = () => {
    setFaceToRight(false);

    if (!onRightSide) {
      if (currentView > 1) {
        setCurrentView(currentView - 1);
      } else {
        setCurrentStage(currentStage - 1);
        setCurrentView(maxStageView[`stage${currentStage - 1}`]);
        setTransitionHidden(false);
        setTimeout(() => setTransitionHidden(true), 1500);
      }

      setResetAnim(true);
      setOnRightSide(true);
      setTimeout(() => setResetAnim(false), 1);
      return;
    }

    setOnRightSide(false);
    setStaticAnim(false);
    setLeftButtonDisabled(true);
    setRightButtonDisabled(true);

    timeoutRef.current = setTimeout(() => {
      if (currentView > 1) {
        setCurrentView(currentView - 1);
      } else {
        setCurrentStage(currentStage - 1);
        setCurrentView(maxStageView[`stage${currentStage - 1}`]);
        setTransitionHidden(false);
        setTimeout(() => setTransitionHidden(true), 1500);
      }

      setStaticAnim(true);
      setResetAnim(true);
      setOnRightSide(true);
      setLeftButtonDisabled(false);
      setRightButtonDisabled(false);
      setTimeout(() => setResetAnim(false), 1);
    }, 3000);
  };

  const chooseStage = (stage) => {
    clearTimeout(timeoutRef.current);
    setStaticAnim(true);
    setResetAnim(true);
    setCurrentStage(stage);
    setCurrentView(1);
    setOnRightSide(false);
    setFaceToRight(true);
    setLeftButtonDisabled(false);
    setRightButtonDisabled(false);
    setTimeout(() => setResetAnim(false), 1);
  };

  const handleLessonRedirect = (lesson) => {
    const isLastOnboardingTest = isLastStage && currentView === 3;

    if (lesson.contentType === ContentType.Lesson || lesson.contentType === ContentType.ScrollLesson) {
      navigate(`/lekcje/${lesson.id}`, {
        state: {
          from: UserRoleName.Onboarding,
          isLastOnboardingTest,
          currentView,
          currentStage,
        },
      });
    } else if (lesson.contentType === ContentType.Video) {
      navigate(`/wideo/${lesson.id}`, {
        state: {
          from: UserRoleName.Onboarding,
          isLastOnboardingTest,
          currentView,
          currentStage,
        },
      });
    }
  }

  return (
    <Container
      semiDarkBg={currentStage === 2 || currentStage === 4}
      darkBg={isLastStage}
    >
      <TransitionContainer isHidden={transitionHidden}>
        <Lottie
          animationData={transitionHidden ? undefined : TransitionAnim}
          loop={false}
          rendererSettings={{
            preserveAspectRatio: "xMidYMid slice",
          }}
        />
      </TransitionContainer>
      <LeftButton
        onClick={handleSetOnLeftSide}
        disabled={leftButtonDisabled}
        isHidden={currentView === 1 && currentStage === 1}
      >
        <ArrowImg />
      </LeftButton>
      <GameContainer>
        <Statistics
          onGoBack={goBack}
          gameResult={gameResult}
          stageResult={stageResult}
          isLastStage={isLastStage}
        />
        <BackgroundWrapper>
          <Lottie
            animationData={bgAnim}
            loop
            rendererSettings={{
              preserveAspectRatio: "xMidYMid slice",
            }}
          />
        </BackgroundWrapper>
        <AnimationWrapper
          $onRightSide={onRightSide}
          mirror={(faceToRight && hero.name !== HeroName.George) || (hero.name === HeroName.George && !faceToRight)}
          resetAnim={resetAnim}
        >
          <Lottie
            animationData={staticAnim ? animStand : animWalk}
            loop
            rendererSettings={{
              preserveAspectRatio: "xMidYMid slice",
            }}
          />
        </AnimationWrapper>
        {currentView === maxStageView[`stage${currentStage}`] && (
          <AnimTextWrapper>
            <Lottie
              animationData={textAnim}
              loop={false}
              rendererSettings={{
                preserveAspectRatio: "xMidYMid slice",
              }}
            />
          </AnimTextWrapper>
        )}
        {currentStage === 4 && currentView === maxStageView.stage4 && (
          <SmokWrapper>
            <Lottie
              animationData={smokAnim}
              loop
              rendererSettings={{
                preserveAspectRatio: "xMidYMid slice",
              }}
            />
          </SmokWrapper>
        )}
        {stages[currentStage - 1].itemGroup["1"].items.map((item, index) => {
          const top = item.name === "TEST"
            ? TaskPointers[currentStage - 1][TaskPointers[currentStage - 1].length - 1].top
            : TaskPointers[currentStage - 1][index]?.top || 0;
          const left = item.name === "TEST"
            ? TaskPointers[currentStage - 1][TaskPointers[currentStage - 1].length - 1].left
            : TaskPointers[currentStage - 1][index]?.left || 0;
          const shouldTaskBeVisibleOnCurrentView = (TaskPointers[currentStage - 1][index]?.view === currentView && item.name !== "TEST")
          || (item.name === "TEST" && currentView === maxStageView[`stage${currentStage}`]);
          if (shouldTaskBeVisibleOnCurrentView) {
            return (
              <Task
                key={item.id}
                title={item.name}
                top={top}
                left={left}
                onTaskClick={() => item.isEnabled ? handleLessonRedirect(item) : undefined}
                isDisabled={!item.isEnabled}
                passed={item.isEnabled && item.passed}
              />
            );
          }

          return null;
        })}
        <Stages
          stages={stages}
          activeStage={currentStage}
          setActiveStage={chooseStage}
          onTutorialClick={onTutorialClick}
        />
        <TaskMessage hidden={!taskMessageVisible} />
      </GameContainer>
      <RightButton
        onClick={handleSetOnRightSide}
        disabled={
          rightButtonDisabled
          || (currentView === maxStageView[`stage${currentStage}`] && !stages[currentStage]?.isEnabled)
        }
        isHidden={currentStage === (isLeasinGoMode ? 3 : 5) && currentView === 3}
        isBlocked={!areAllTasksDoneInThisView}
        onMouseEnter={() => setTaskMessageVisible(!areAllTasksDoneInThisView)}
        onMouseOut={() => setTaskMessageVisible(false)}
      >
        <ArrowImg />
      </RightButton>
      <CloseButton onClick={goBack}>
        <CloseIcon />
      </CloseButton>
    </Container>
  );
};

Game.propTypes = {
  hero: PropTypes.string,
  goBack: PropTypes.func,
  stages: PropTypes.arrayOf(PropTypes.object),
  initialStage: PropTypes.number,
  onTutorialClick: PropTypes.func,
};
