import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { ReactComponent as KnowledgeBaseIcon } from "../../modules/knowledgeBase/images/moduleIcon.svg";
import { ReactComponent as ArrowSvg } from "../../images/arrow.svg";
import { ReactComponent as SearchSmallSvg } from "../../images/searchSmall.svg";
import { ReactComponent as TrainingsBaseIcon } from "../../images/trainings.svg";
import { ReactComponent as ProfileMenuIcon } from "../../images/profile.svg";
import { styleWhenTrue } from "../../helpers/rendering";
import { hexToRgba } from "../../helpers/colors";
import { Color, FontWeight, Media, Separator as SeparatorBase } from "../../theme";
import { SmallAvatar } from "../../UIElements";

import { ReactComponent as ProfileSvg } from "./img/user.svg";
import { ReactComponent as SearchSvg } from "./img/search.svg";
import { ReactComponent as BaseSvg } from "./img/base.svg";
import { ReactComponent as TrainingsSvg } from "./img/trainings.svg";
import { TargetPosition } from "./tutorialStepPopup.constants";

export const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: ${hexToRgba(Color.black, 0.5)};
  z-index: 999;
`;

export const Container = styled.div`
  position: absolute;
  background-color: ${Color.white};
  width: 340px;
  top: ${({ top }) => top}px;
  ${({ left }) => left ? `left: ${left}px;` : ""}
  ${({ right }) => right ? `right: ${right}px;` : ""}
  z-index: 1000;

  &::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    ${({ arrowRight }) => arrowRight ? `right: ${arrowRight}px;` : ""}
    ${({ arrowLeft }) => arrowLeft ? `left: ${arrowLeft}px;` : ""}
    ${({ arrowTop }) => arrowTop ? `top: ${arrowTop}px;` : ""}
    ${({ arrowBottom }) => arrowBottom ? `bottom: ${arrowBottom}px;` : ""}
  }

  ${styleWhenTrue(propEq("targetPosition", TargetPosition.Top), css`
    &::before {
      border-bottom: 22px solid ${Color.white};
      border-right: 17px solid transparent;
      border-left: 17px solid transparent;
      top: -22px;
    }
  `)}

  ${styleWhenTrue(propEq("targetPosition", TargetPosition.Left), css`
    &::before {
      border-bottom: 17px solid transparent;
      border-right: 22px solid ${Color.white};
      border-top: 17px solid transparent;
      left: -22px;
    }
  `)}

  ${styleWhenTrue(propEq("targetPosition", TargetPosition.Bottom), css`
    &::before {
      border-left: 17px solid transparent;
      border-right: 17px solid transparent;
      border-top: 22px solid ${Color.white};
      bottom: -22px;
    }
  `)}

  ${Media.tablet`
    width: 470px;
  `}
`;

export const StepContent = styled.div`
  && {
    padding: 35px;
  }
`;

export const StepHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const StepTitle = styled.p`
  font-weight: 700;

  && {
    font-size: 18px;
    line-height: 24px;
    color: ${Color.black};
  }
`;

export const StepText = styled.p`
  margin-top: 25px;

  && {
    color: ${Color.gray9};
    font-size: 14px;
    line-height: 22px;
  }
`;

export const StepSkipButton = styled.button`
  background-color: ${Color.transparent};
  margin: 0;
  border: none;
  text-transform: uppercase;
  font-weight: 700;

  && {
    color: ${Color.gray9};
    font-size: 12px;
    line-height: 20px;
    padding: 0;
  }
`;

export const Separator = styled(SeparatorBase)`
  margin: 0;
`;

export const StepButtons = styled.div`
  display: flex;
  justify-content: space-between;

  && {
    padding: 30px 35px;
  }
`;

export const NavButtons = styled.div`
  display: flex;
`;

const NavButtonStyles = css`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(48, 54, 60, 0.2), 0 0 20px rgba(31, 27, 22, 0.03);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PrevButton = styled.button`
  ${NavButtonStyles}
  margin-right: 10px;
  background-color: ${Color.white};
`;

export const PrevArrow = styled(ArrowSvg)`
  fill: ${Color.gray9};
  height: 16px;
  width: 9px;
  position: relative;
  left: -2px;
`;

export const NextButton = styled.button`
  ${NavButtonStyles}
  background-color: ${() => Color.brandBasic};
`;

export const NextArrow = styled(ArrowSvg)`
  fill: ${Color.white};
  height: 16px;
  width: 9px;
  transform: rotate(180deg);
  position: relative;
  right: -2px;
`;

export const Avatar = styled(SmallAvatar)`
  margin-right: 0;
`;

export const StepTarget = styled.div`
  border-radius: 50%;
  background-color: ${Color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  ${({ top }) => top ? `top: ${top}px;` : ""}
  ${({ left }) => left ? `left: ${left}px;` : ""}
  ${({ right }) => right ? `right: ${right}px;` : ""}
  ${({ bottom }) => bottom ? `bottom: ${bottom}px;` : ""}
  height: 64px;
  width: 64px;

  ${styleWhenTrue(prop("small"), css`
    width: 48px;
    height: 48px;
  `)}
`;

const stepImgStyles = css`
  margin-right: 15px;
`;

export const ProfileImg = styled(ProfileSvg)`
  ${stepImgStyles}

  path {
    stroke: ${() => Color.brandBasic};
  }
`;

export const SearchImg = styled(SearchSvg)`
  ${stepImgStyles}

  path {
    fill: ${() => Color.brandBasic};
  }
`;

export const BaseImg = styled(BaseSvg)`
  ${stepImgStyles}

  path {
    fill: ${() => Color.brandBasic};
  }
`;

export const TrainingsImg = styled(TrainingsSvg)`
  ${stepImgStyles}

  path {
    fill: ${() => Color.brandBasic};
  }
`;

export const SearchIcon = styled(SearchSmallSvg)`
  width: 18px;
  height: 18px;
  stroke: ${Color.black};
`;

export const BaseIcon = styled(KnowledgeBaseIcon)``;

export const TrainingsIcon = styled(TrainingsBaseIcon)``;

export const ProfileIcon = styled(ProfileMenuIcon)``;

export const MobileMenuTarget = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const MobileMenuTitle = styled.p`
  color: ${Color.gray9};
  font-weight: ${FontWeight.SemiBold};
  font-size: 12px;
  margin-top: 6px;
  white-space: nowrap;
`;
