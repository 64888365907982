import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import shuffle from "lodash/shuffle";

import InteractionQuestionHeader from "../../../../components/interactionQuestionHeader/interactionQuestionHeader.component";
import QuestionTypeError from "../questionTypeError/questionTypeError.component";
import { QuestionType } from "../quizQuestions.constants";

import {
  Container,
  Answers,
  ButtonWrapper,
  Button,
  ErrorMessage,
  InfoMessage,
} from "./questionItem.styled";

const QuestionItem = ({
  data,
  isLastQuestion,
  loadedFromApi,
  isResultsView,
  setCompleted,
}) => {
  const {
    type,
    title,
    question,
    answers,
    shuffleAnswers,
  } = data;
  const { t } = useTranslation();
  const displayedAnswers = useMemo(
    () => shuffleAnswers ? shuffle(answers) : answers,
    [answers, shuffleAnswers],
  );
  const [checkedAnswerIDs, setCheckedAnswerIDs] = useState([]);
  const [answersConfirmed, setAnswersConfirmed] = useState(isResultsView);
  const [answersDisabled, setAnswersDisabled] = useState(isResultsView);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [checkedTrueFalseStatementIDs, setCheckedTrueFalseStatementsIDs] = useState(new Set([]));
  const allTrueFalseStatementsChecked = checkedTrueFalseStatementIDs.size === answers.length;
  const buttonDisabled = !checkedAnswerIDs.length
    || answersConfirmed
    || (type === QuestionType.TrueFalse && !allTrueFalseStatementsChecked);

  const handleAnswerSelect = (id, value) => {
    setAnswersConfirmed(false);
    setShowErrorMessage(false);

    switch (type) {
    case QuestionType.SingleChoice:
      setCheckedAnswerIDs([id]);
      break;
    case QuestionType.MultipleChoice:
      checkedAnswerIDs.includes(id)
        ? setCheckedAnswerIDs(checkedAnswerIDs.filter((answerId) => answerId !== id))
        : setCheckedAnswerIDs([...checkedAnswerIDs, id]);
      break;
    case QuestionType.TrueFalse:
      if (value === true && !checkedAnswerIDs.includes(id)) {
        setCheckedAnswerIDs([...checkedAnswerIDs, id]);
      }

      if (value === false && checkedAnswerIDs.includes(id)) {
        setCheckedAnswerIDs(checkedAnswerIDs.filter((answerId) => answerId !== id))
      }

      setCheckedTrueFalseStatementsIDs(new Set([...checkedTrueFalseStatementIDs, id]));
      break;
    }
  };

  const checkValuesCorrect = () => {
    return answers.every(({ id, value }) => (
      (value && checkedAnswerIDs.includes(id)) || (!value && !checkedAnswerIDs.includes(id))
    ));
  };

  const confirmAnswers = () => {
    setAnswersConfirmed(true);

    if (loadedFromApi || checkValuesCorrect()) {
      setCompleted(checkedAnswerIDs);
      setAnswersDisabled(true);
    } else {
      setShowErrorMessage(true);
    }
  };

  return (
    <Container>
      <InteractionQuestionHeader
        type={type}
        title={title}
        question={question}
      />
      {Object.values(QuestionType).includes(type) ? (
        <>
          <Answers
            type={type}
            answers={displayedAnswers}
            checkedAnswerIDs={checkedAnswerIDs}
            checkedTrueFalseStatementIDs={checkedTrueFalseStatementIDs}
            areAnswersConfirmed={answersConfirmed || isResultsView}
            disabled={answersDisabled}
            onAnswerSelect={handleAnswerSelect}
            isResultsView={isResultsView}
          />
          <ButtonWrapper>
            <Button
              disabled={buttonDisabled}
              onClick={confirmAnswers}
            >
              {t("interactions.quizQuestions.confirm")}
            </Button>
          </ButtonWrapper>
          {showErrorMessage && (
            <ErrorMessage>{t("interactions.quizQuestions.answersNotCorrect")}</ErrorMessage>
          )}
          {answersDisabled && !isLastQuestion && (
            <InfoMessage>{t("interactions.quizQuestions.answersCorrect")}</InfoMessage>
          )}
        </>
      ) : (
        <QuestionTypeError />
      )}
    </Container>
  );
};

export default QuestionItem;
