export const OnboardingMode = {
  Default: "onboarding",
  LeasinGo: "onboarding-leasingo",
  OnboardingAll: "onboarding-all",
  HR: "onboarding-hr",
  OnboardingVWFS: "onboarding-vwfs",
};

export const StorageName = {
  OnboardingBoardView: "onboarding-board-view",
};
