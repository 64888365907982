import styled, { css } from "styled-components";
import { propEq } from "ramda";

import { ReactComponent as OptionsSvg } from "../../images/options.svg";
import { styleWhenTrue } from "../../helpers/rendering";
import { Color } from "../../theme/colors";

export const Container = styled.div`
  margin-left: auto;
  position: relative;
`;

export const OptionsButton = styled.button`
  display: flex;
  background-color: ${Color.transparent};
  border: 0;
  cursor: pointer;
  color: ${Color.gray4};
  margin-left: 18px;
`;

export const Dropdown = styled.div`
  width: 160px;
  background-color: ${Color.deepGray};
  padding: 10px 0;
  position: absolute;
  top: 0;
  left: -160px;
`;

export const DropdownOption = styled.button`
  display: flex;
  padding: 10px 0;
  align-items: center;
  cursor: pointer;
  width: 100%;
  color: ${Color.gray4};
  border: 0;
  background-color: ${Color.transparent};

  &:hover {
    color: ${Color.white};
  }
`;

export const OptionIconWrapper = styled.div`
  width: 48px;
  display: flex;
  justify-content: center;
`;

export const OptionsIcon = styled(OptionsSvg)`
  ${styleWhenTrue(propEq("true", "active"), css`
    && {
      fill: ${() => Color.brandBasic};
    }
  `)}
`;
