import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { selectLessonInstanceId } from "../../../lesson/redux/lesson.selectors";
import { Loader } from "../../../components/loader/loader.component";
import { getData } from "../../helpers/api";

import { getTaskData, saveAnswers } from "./services/api";
import QuestionItem from "./questionItem/questionItem.component";
import { Container } from "./quizQuestions.styled";
import { parseQuestions } from "./quizQuestions.utils";
import { TaskStatus } from "./quizQuestions.constants";
import { FinishInfo } from "./finishInfo/finishInfo.component";

const QuizQuestions = ({ baseUrl, config, id, callComplete }) => {
  const instanceId = useSelector(selectLessonInstanceId);
  const [taskAttemptId, setTaskAttemptId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [nextQuestionIndex, setNextQuestionIndex] = useState(0);
  const [loadedFromApi, setLoadedFromApi] = useState(false);
  const [confirmedAnswers, setConfirmedAnswers] = useState([]);
  const [isResultsView, setIsResultsView] = useState(false);

  const loadQuestions = async (data) => {
    if (data.loadFromApi) {
      setLoadedFromApi(true);
      const { data } = await getTaskData(instanceId, id);
      setTaskAttemptId(data?.attemptInfo?.attemptId);
      setIsResultsView(data?.attemptInfo.status === TaskStatus.Done);
      const parsedQuestions = parseQuestions(data?.structure?.children);
      setQuestions(parsedQuestions);
    } else {
      setQuestions(data.questions);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (baseUrl && config) {
      getData(`${baseUrl}${config}`, loadQuestions);
    }
  }, [baseUrl, config]);

  useEffect(() => {
    if (questions?.length) {
      setLoading(false);
    }
  }, [questions]);

  const handleCallComplete = () => {
    callComplete();
    window.scrollTo(0, 0);
  };

  const markQuestionCompleted = async (questionIndex, answerIDs) => {
    const newIndex = questionIndex + 1;
    let newConfirmedAnswers;

    if (confirmedAnswers.length <= questionIndex) {
      newConfirmedAnswers = [...confirmedAnswers, [...answerIDs]];
    } else {
      newConfirmedAnswers = confirmedAnswers.map((answersArray, index) => (
        index === questionIndex ? [...answerIDs] : [...answersArray]
      ));
    }

    setConfirmedAnswers(newConfirmedAnswers);

    if (newIndex > nextQuestionIndex) {
      setNextQuestionIndex(newIndex);
    }

    if (newIndex === questions.length && !loadedFromApi) {
      handleCallComplete();
    } else if (newIndex === questions.length && loadedFromApi) {
      const mergedAnswers = newConfirmedAnswers.reduce((answersArray, item) => {
        const newArray = [...answersArray, ...item];
        return newArray;
      }, []);

      const { data } = await saveAnswers(instanceId, taskAttemptId, id, mergedAnswers);
      const parsedQuestions = parseQuestions(data?.children);
      setQuestions(parsedQuestions);
      setIsResultsView(true);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      {questions.map((question, index) => (
        nextQuestionIndex >= index ? (
          <QuestionItem
            key={question.title}
            data={question}
            isLastQuestion={index + 1 === questions.length}
            loadedFromApi={loadedFromApi}
            isResultsView={isResultsView}
            setCompleted={(answerIDs) => markQuestionCompleted(index, answerIDs)}
          />
        ) : null
      ))}
      {isResultsView && (
        <FinishInfo callComplete={handleCallComplete} />
      )}
    </Container>
  );
};

export default QuizQuestions;
