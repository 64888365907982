export const IndicatorsTabURI = {
  Strategic: "strategic",
  Common: "common",
  Statistics: "statistics",
};

export const IndicatorsTabName = {
  [IndicatorsTabURI.Strategic]: "strategic",
  [IndicatorsTabURI.Common]: "common",
  [IndicatorsTabURI.Statistics]: "statistics",
};

export const CompetenceType = {
  Strategic: "strategic",
  Common: "common",
};
