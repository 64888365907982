import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";
import { useLocation } from "react-router-dom";

import { LessonActions } from "../../lesson/redux/lesson.reducer";
import { RightDrawer } from "../../components/rightDrawer/rightDrawer.component";
import { Float } from "../../components/float/float.component";
import { useModuleConfig, ModuleType } from "../";

import { ContentBlocked } from "./components/contentBlocked/contentBlocked.component";
import { FinishBox } from "./components/finishBox/finishBox.component";
import { Page } from "./components/page/page.component";
import { SlideList } from "./components/slideList/slideList.component";
import FinalQuiz from "./components/finalQuiz/finalQuiz.component";
import {
  Container,
  Wrapper,
  Separator,
} from "./scrollLesson.styled";

const ScrollLesson = ({ data, baseUrl }) => {
  const { search } = useLocation();
  const config = useModuleConfig(ModuleType.ScrollLesson);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const listOptions = data.pages.map((page, id) => ({
    id,
    title: page.children[0].params.title,
    disabled: id !== 0 && !data.pages[id - 1]?.completed,
  }));
  const [currentPageId, setCurrentPageId] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrollButtonClicked, setScrollButtonClicked] = useState(false);
  const [scrollPageId, setScrollPageId] = useState(null);
  const [withGradientBgStyle, setWithGradientBgStyle] = useState(false);
  const floatTitle = currentPageId && data.pages.length
    ? data.pages[currentPageId].children[0].params.title
    : t("lesson.scrollContent.introduction");
  const lessonCompleted = data.pages.every((page) => page.completed);
  const lessonWithTest = data.quizes?.length;

  useEffect(() => {
    const slideCounter = new URLSearchParams(search).get("slide");
    if (slideCounter) {
      setScrollPageId(Number(slideCounter));
      setTimeout(() => setScrollPageId(null), 1);
    }
  }, [search]);

  const openList = () => setDrawerOpen(true);

  const closeList = () => setDrawerOpen(false);

  const handleScrollToSlide = (pageId) => {
    closeList();
    setScrollPageId(pageId);
    setTimeout(() => setScrollPageId(null), 1);
  };

  const handleScrollButtonClick = () => {
    setScrollButtonClicked(true);
    setTimeout(() => setScrollButtonClicked(false), 1);
  };

  const handleVisibleChange = (pageId) => {
    setCurrentPageId(pageId);
  };

  const handleSlideComplete = (interactionId, pageId, isLastSlide) => {
    dispatch(LessonActions.setInteractionCompleted(interactionId, pageId, !isLastSlide));
  };

  const handleFinishClick = () => {
    const lastSlideData = data.pages[data.pages.length - 1].children[0];
    dispatch(LessonActions.setInteractionCompleted(lastSlideData.children[0].id, lastSlideData.parentId));
    window.scrollTo(0, 0);
  };

  return (
    <ThemeProvider theme={config.theme}>
      <Container $gradientBgStyle={withGradientBgStyle}>
        <Wrapper>
          {data.pages.map((page, index) => (
            (index === 0 || data.pages[index - 1]?.completed) && (
              <React.Fragment key={page.id}>
                <ScrollIntoViewIfNeeded
                  active={(index === 1 && scrollButtonClicked) || scrollPageId === index}
                >
                  <Page
                    data={page}
                    baseUrl={baseUrl}
                    callComplete={handleSlideComplete}
                    currentSlideCounter={index + 1}
                    slidesOverallCounter={data.pages.length}
                    onFloatClick={openList}
                    onVisibleChange={() => handleVisibleChange(index)}
                    onScrollButtonClick={handleScrollButtonClick}
                    withGradientBgStyle={withGradientBgStyle}
                    setGradientBg={setWithGradientBgStyle}
                  />
                </ScrollIntoViewIfNeeded>
                <Separator />
              </React.Fragment>
            )
          ))}
          {lessonCompleted && lessonWithTest && (
            <FinalQuiz data={data.quizes[0]} />
          )}
          {lessonCompleted && !lessonWithTest && (
            <>
              <FinishBox handleClick={handleFinishClick} />
              <Separator />
            </>
          )}
          {!lessonCompleted && (
            <>
              <ContentBlocked />
              <Separator />
            </>
          )}
        </Wrapper>
        <Float
          currentSection={currentPageId}
          sectionsOverall={data.pages.length}
          title={floatTitle}
          onClick={openList}
          fixed
          hidden={!currentPageId}
        />
        <RightDrawer open={drawerOpen} onClose={closeList}>
          <SlideList
            onClose={closeList}
            options={listOptions}
            onSelectOption={handleScrollToSlide}
          />
        </RightDrawer>
      </Container>
    </ThemeProvider>
  );
};

ScrollLesson.propTypes = {
  data: PropTypes.object,
  baseUrl: PropTypes.string,
};

export default ScrollLesson;
