import React from "react"
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { ButtonColor } from "../../../../components/button/button.constants";
import { StarsRate } from "../../../../components/starsRate/starsRate.component";
import { renderWhenTrueOtherwise } from "../../../../helpers/rendering";
import { simpleDateFromTimestamp } from "../../../../helpers/date";
import { useMediaListener } from "../../../../hooks/useMediaListener";
import { BulletList } from "../bulletList/bulletList.component";

import { RateType } from "./competenceMeasureDropdown.constants";
import {
  Container,
  Table,
  TableHead,
  TableBody,
  Title,
  ScoreOverviewItem,
  ScoreOverview,
  ScoreOverviewText,
  ScoreOverviewHeader,
  ButtonCell,
  TestLink,
} from "./competenceMeasureDropdown.styled";

export const CompetenceMeasureDropdown = ({ skill, rate, competenceId }) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaListener();
  const { description } = skill;
  const date = rate && rate.date ? simpleDateFromTimestamp(rate.date) : "-";
  const rateType = skill.rateType || { name: "", label: "-" };
  const starsRate = rate && rate.rate;

  const renderTestButton = () => {
    const label = rate !== null
      ? t("competence.competenceMeasureDropdown.repeatTest")
      : t("competence.competenceMeasureDropdown.doRate");

    return (
      <TestLink
        color={ButtonColor.Primary}
        to={`/competence/${competenceId}/self-mark/${skill.id}`}
        hidden={rateType.name !== RateType.Card}
      >
        {label}
      </TestLink>
    );
  };

  const renderScoreTable = () => {
    return (
      <Table>
        <TableHead>
          <tr>
            <th>{t("competence.competenceMeasureDropdown.rate")}</th>
            <th>{t("competence.competenceMeasureDropdown.date")}</th>
            <th>{t("competence.competenceMeasureDropdown.type")}</th>
            <th></th>
          </tr>
        </TableHead>
        <TableBody>
          <tr>
            <td>
              <StarsRate rate={starsRate} />
            </td>
            <td>{date}</td>
            <td>{rateType.label}</td>
            <ButtonCell>
              {renderTestButton(rateType.name === RateType.Card)}
            </ButtonCell>
          </tr>
        </TableBody>
      </Table>
    );
  };

  const renderScoreList = () => {
    return (
      <ScoreOverview>
        <ScoreOverviewItem>
          <ScoreOverviewHeader>{t("competence.competenceMeasureDropdown.rate")}</ScoreOverviewHeader>
          <StarsRate rate={starsRate} />
        </ScoreOverviewItem>
        <ScoreOverviewItem>
          <ScoreOverviewHeader>{t("competence.competenceMeasureDropdown.date")}</ScoreOverviewHeader>
          <ScoreOverviewText>{date}</ScoreOverviewText>
        </ScoreOverviewItem>
        <ScoreOverviewItem>
          <ScoreOverviewHeader>{t("competence.competenceMeasureDropdown.type")}</ScoreOverviewHeader>
          <ScoreOverviewText>{rateType.label}</ScoreOverviewText>
        </ScoreOverviewItem>
        {renderTestButton(rateType.name === RateType.Card)}
      </ScoreOverview>
    );
  };

  const renderTable = renderWhenTrueOtherwise(
    renderScoreList,
    renderScoreTable,
  );

  return (
    <Container>
      {renderTable(isMobile)}
      <Title>{t("competence.competenceMeasureDropdown.skills")}</Title>
      <BulletList text={description} />
    </Container>
  );
};

CompetenceMeasureDropdown.propTypes = {
  skill: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    rateType: PropTypes.object,
    weight: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
  }),
  rate: PropTypes.shape({
    rate: PropTypes.number.isRequired,
    maxRate: PropTypes.number.isRequired,
    date: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    author: PropTypes.string.isRequired,
  }),
  competenceId: PropTypes.number.isRequired,
};
