import React from "react";

import { Container, Text, RadioButton } from "./answer.styled";

const Answer = ({ data, isSelected, setSelected }) => {
  return (
    <Container $selected={isSelected} onClick={() => setSelected(data.id)}>
      <RadioButton $selected={isSelected} />
      <Text $selected={isSelected}>{data.value}</Text>
    </Container>
  );
};

export default Answer;
