import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { renderWhenTrue } from "../../../../helpers/rendering";
import { convertListToBoolMap } from "../../../../helpers/transform";
import { Checkbox } from "../../../checkbox/checkbox.component";

import { Container, Title } from "./tagDropdown.styled";

export const TagDropdown = ({
  competence,
  checkboxStates,
  setCheckboxStates,
  dropdownRef,
  closeDropdown,
  expandedButtonRef,
  clearFiltersTagRef,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)
      && clearFiltersTagRef.current && !clearFiltersTagRef.current.contains(event.target)
      && expandedButtonRef.current && !expandedButtonRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [clearFiltersTagRef, closeDropdown, dropdownRef, expandedButtonRef]);

  useEffect(() => {
    const everyCompetenceMeasureChecked = competence.skills.every(
      (skill) => !!checkboxStates[skill.id]
    );
    if (everyCompetenceMeasureChecked && !checkboxStates[competence.id]) {
      setCheckboxStates({ ...checkboxStates, [competence.id]: true });
    }
  }, [checkboxStates, competence.id, competence.skills, setCheckboxStates]);

  const handleCheckboxChange = (event) => {
    const { target } = event;
    const otherCompetencesIds = Object.keys(checkboxStates).filter(
      (key) => !competence.skills.find((skill) => skill.id === Number(key))
    );
    const wholeCompetenceChecked = target.value === competence.id;
    if (wholeCompetenceChecked) {
      const newCheckboxStates = {
        ...convertListToBoolMap(Object.keys(checkboxStates), target.checked),
        ...convertListToBoolMap(otherCompetencesIds, false),
      };
      setCheckboxStates(newCheckboxStates);
    }
    else {
      const newCheckboxStates = {
        ...checkboxStates,
        ...convertListToBoolMap(otherCompetencesIds, false),
        [target.value]: target.checked,
      };
      setCheckboxStates(newCheckboxStates);
    }
  };

  const preventClosingDropdown = (event) => {
    event.stopPropagation();
  };

  const renderCompetenceCheckbox = () => (
    <Checkbox
      key={competence.id}
      label={competence.name}
      value={competence.id}
      onChange={handleCheckboxChange}
      checked={checkboxStates[competence.id]}
    />
  );

  const renderSkillsCheckboxes = () => (
    competence.skills.map((skill) => (
      <Checkbox
        key={skill.id}
        label={skill.name}
        value={skill.id}
        onChange={handleCheckboxChange}
        checked={checkboxStates[skill.id]}
      />
    ))
  );

  const renderSkillsTitle = renderWhenTrue(() => (
    <Title>{t("components.tagDropdown.skills")}</Title>
  ));

  return (
    <Container
      ref={dropdownRef}
      onClick={preventClosingDropdown}
    >
      <Title>{t("components.tagDropdown.competence")}</Title>
      {renderCompetenceCheckbox()}
      {renderSkillsTitle(!!competence.skills.length)}
      {renderSkillsCheckboxes()}
    </Container>
  )
};

TagDropdown.propTypes = {
  competence: PropTypes.object,
  checkboxStates: PropTypes.object,
  setCheckboxStates: PropTypes.func,
  dropdownRef: PropTypes.any,
  closeDropdown: PropTypes.func,
  expandedButtonRef: PropTypes.any,
  clearFiltersTagRef: PropTypes.any,
};
