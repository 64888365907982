import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";

import { selectUserId } from "../../../../userContext/redux/userContext.selectors";
import { ButtonColor } from "../../../../components/button/button.constants";
import { Switch } from "../../../../components/switch/switch.component";
import { CookieType, CookieName } from "../../../../app/cookiePopup/cookiePopup.constants";
import { isCookieEnabled } from "../../../../helpers/isCookieEnabled";

import {
  Container,
  FieldsContainer,
  FieldsWrapper,
  SectionTitle,
  Separator,
  SwitchRow,
  SwitchLabel,
  Button,
  Message,
} from "./cookiesTab.styled";
import { AnalyticalCookies, FunctionalCookies } from "./cookiesTab.constants";

export const CookiesTab = ({ className }) => {
  const { t } = useTranslation();
  const userId = useSelector(selectUserId);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [cookiesEnabled, setCookiesEnabled] = useState({
    [CookieType.Necessary]: true,
    [CookieType.Functional]: isCookieEnabled(CookieName.Functional),
    [CookieType.Analytical]: isCookieEnabled(CookieName.Analytical),
  });

  const handleSettingsChange = (cookieType, enabled) => {
    const newSettings = {
      ...cookiesEnabled,
      [cookieType]: enabled,
    };
    setCookiesEnabled(newSettings);
    setButtonDisabled(false);
    setShowConfirmationMessage(false);
  };

  const handleRemoveCookie = (cookieName) => {
    const cookies = new Cookies();
    cookies.remove(cookieName, { path: "/", domain: document.domain });
    localStorage.removeItem(cookieName);
  };

  const handleSave = () => {
    for (const [key, value] of Object.entries(cookiesEnabled)) {
      if (value === true) {
        localStorage.setItem(`${key}CookiesEnabled`, true);
      }

      if (value === false) {
        localStorage.removeItem(`${key}CookiesEnabled`);

        if (key === CookieType.Analytical) {
          AnalyticalCookies.forEach(handleRemoveCookie);
        }

        if (key === CookieType.Functional) {
          FunctionalCookies(userId).forEach(handleRemoveCookie);
        }
      }
    }

    setButtonDisabled(true);
    setShowConfirmationMessage(true);
  };

  return (
    <Container className={className}>
      <FieldsContainer>
        <FieldsWrapper>
          <SectionTitle>
            {t("profile.cookiesTab.manage")}
          </SectionTitle>
          <Separator />
          {Object.values(CookieType).map((cookieType) => (
            <React.Fragment key={cookieType}>
              <SwitchRow>
                <SwitchLabel>{t(`profile.cookiesTab.${cookieType}`)}</SwitchLabel>
                <Switch
                  checked={cookiesEnabled[cookieType]}
                  disabled={cookieType === CookieType.Necessary}
                  onChange={({ target }) => handleSettingsChange(cookieType, target.checked)}
                />
              </SwitchRow>
              <Separator />
            </React.Fragment>
          ))}
          <Button
            $large
            color={ButtonColor.Primary}
            onClick={handleSave}
            disabled={buttonDisabled}
          >
            {t("profile.cookiesTab.save")}
          </Button>
          {showConfirmationMessage && <Message>{t("profile.cookiesTab.changesSaved")}</Message>}
        </FieldsWrapper>
      </FieldsContainer>
    </Container>
  );
};
