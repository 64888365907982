import styled from "styled-components";

import { hexToRgba } from "../../helpers/colors";
import { Color, Media } from "../../theme";

export const Container = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${Color.white};
  border: 0;
  display: none;
  box-shadow: 0 2px 4px ${hexToRgba(Color.gray6, 0.2)}, 0 0 20px ${hexToRgba(Color.deepGray, 0.03)};

  svg {
    fill: ${Color.gray9};
    height: 14px;
  }

  &:hover {
    svg {
      fill: ${Color.gray4};
    }
  }

  ${Media.desktop`
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;
