import styled from "styled-components";

import { Color, Media } from "../../../../theme";

export const Container = styled.div`
  width: 100%;
  padding-bottom: 50px;
  background-color: ${Color.almostWhite};

  ${Media.tablet`
    padding-bottom: 90px;
  `}
`;

export const Wrapper = styled.div`
  max-width: 940px;
  margin: 0 auto;

  ${Media.tablet`
    padding: 0 20px;
  `}
`;

export const BackButtonContainer = styled.div`
  padding: 33px 20px;

  ${Media.tablet`
    padding: 33px 0;
  `}
`;
