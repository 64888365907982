import styled, { css } from "styled-components";

import { ContentMaxWidth, Media, FontWeight } from "../../../../theme";
import { ReactComponent as MonstersSvg } from "../../images/monsters.svg";
import { ReactComponent as MonstersGraphSvg } from "../../images/monstersGraph.svg";
import { ReactComponent as MonsterOvalsSvg } from "../../images/monsterOvals.svg";
import { ReactComponent as MonstersBellSvg } from "../../images/monstersBell.svg";
import { ReactComponent as ClockSvg } from "../../images/clock.svg";
import { ReactComponent as WirusSvg } from "../../images/wirus.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
`;

export const Header = styled.h3`
  font-size: 34px;
  line-height: 46px;
  font-weight: ${FontWeight.ExtraBold};
  max-width: 550px;
  text-align: center;
  margin: 80px 25px 30px;

  ${Media.tablet`
    margin: 180px 0 30px;
  `}
`;

export const MonstersImg = styled(MonstersSvg)`
  margin-top: 44px;
  width: 90%;
  display: none;

  ${Media.tablet`
    display: block;
  `}
`;

export const BackgroundImageStyle = css`
  z-index: -1;
  position: absolute;
  display: none;

  ${Media.tablet`
    display: block;
  `}
`;

export const MonstersGraph = styled(MonstersGraphSvg)`
  ${BackgroundImageStyle}
  top: 0;
  left: 25px;
`;

export const MonsterOvals = styled(MonsterOvalsSvg)`
  ${BackgroundImageStyle}
  top: 270px;
  left: 125px;
`;

export const MonstersBell = styled(MonstersBellSvg)`
  ${BackgroundImageStyle}
  top: 168px;
  right: 90px;
`;

export const Clock = styled(ClockSvg)`
  ${BackgroundImageStyle}
  top: 310px;
  right: 25px;
`;

export const WirusImg = styled(WirusSvg)`
  margin-top: 32px;

  ${Media.tablet`
    display: none;
  `}
`;
