import React, { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Formik, Field } from "formik";
import { useSelector } from "react-redux";

import { ContentEditor } from "../../../contentEditor/contentEditor.component";
import { ButtonVariant } from "../../../button/button.constants";
import { selectUserData } from "../../../../userContext/redux/userContext.selectors";
import { AvatarType } from "../../../avatar/avatar.constants";
import { Avatar } from "../../../avatar/avatar.component";

import { FormType } from "./commentForm.constants";
import { CommentSchema } from "./commentForm.schema";
import {
  Container,
  AvatarWrapper,
  Form,
  FieldWrapper,
  Button,
  MobileButton,
  Actions,
  KeyEventWrapper,
} from "./commentForm.styled";
import { renderWhenTrueOtherwise } from "../../../../helpers/rendering";

export const CommentForm = (props) => {
  const {
    className,
    onSubmit,
    onCancel,
    values = {},
    type = FormType.Create,
  } = props;
  const { t } = useTranslation();
  const { user } = useSelector(selectUserData);
  const initialValues = useMemo(() => ({
    id: values.id || null,
    content: values.content || "",
  }), [values.id, values.content]);

  const handleCancel = useCallback((event) => {
    event.persist();
    onCancel(event);
  }, [onCancel]);

  const handleEnterPress = (key, form) => {
    if (key === "Enter" && !form.isSubmitting) {
      form.setFieldValue("content", form.values.content.replace(/\n/g, ""));
      form.handleSubmit();
    }
  };

  const renderEditButtons = () => (
    <Actions>
      <Button
        onClick={handleCancel}
        variant={ButtonVariant.Outlined}
      >
        {t("news.comments.cancel")}
      </Button>
      <Field>
        {({ form }) => (
          <Button
            onClick={form.handleSubmit}
            disabled={!form.isValid || form.isSubmitting}
          >
            {t("news.comments.send")}
          </Button>
        )}
      </Field>
    </Actions>
  );

  const renderCreateButton = () => (
    <Field>
      {({ form }) => (
        <MobileButton
          onClick={form.handleSubmit}
          disabled={!form.isValid || form.isSubmitting}
          variant={ButtonVariant.Outlined}
          $large
        >
          {t("news.comments.send")}
        </MobileButton>
      )}
    </Field>
  );

  const renderButtons = renderWhenTrueOtherwise(
    renderEditButtons,
    renderCreateButton,
  );

  return (
    <Container className={className}>
      <AvatarWrapper>
        <Avatar
          src={user.avatar}
          name={`${user.name} ${user.surname}`}
          type={AvatarType.Small}
          dark
        />
      </AvatarWrapper>
      <Formik
        validationSchema={CommentSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        <Form>
          <Field name="content">
            {({ field, meta, form }) => (
              <FieldWrapper>
                <KeyEventWrapper onKeyDown={({ key }) => handleEnterPress(key, form)}>
                  <ContentEditor
                    {...field}
                    placeholder={t("news.comments.addComment")}
                    error={meta.touched ? meta.error : ""}
                    oneLine
                  />
                </KeyEventWrapper>
              </FieldWrapper>
            )}
          </Field>
          {renderButtons(type === FormType.Edit)}
        </Form>
      </Formik>
    </Container>
  );
};


CommentForm.propTypes = {
  className: PropTypes.string,
};
