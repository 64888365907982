import React from "react";
const ResultScreen = ({ message, action, onAction }) => (
  <div className="drag-and-drop--result-screen">
    <h3 className="drag-and-drop--header hidden">{
      Array.isArray(message)
        ? message.map((line, i) => [line, <br key={ `br-${i}` } />])
        : message }
    </h3>
    <button className="drag-and-drop--button hidden" onClick={ onAction }>{ action }</button>
  </div>
);
export default ResultScreen;