import React, { useState } from "react";
import { useSelector } from "react-redux";

import { selectCurrentRoom } from "../../redux/webinars.selectors";
import { RoomsModalFirstView } from "../roomsModalFirstView/roomsModalFirstView.component";
import { AssignUsersView } from "../assignUsersView/assignUsersView.component";
import { RoomsList } from "../roomsList/roomsList.component";
import { RoomsFinalEditView } from "../roomsFinalEditView/roomsFinalEditView.component";

import { RoomsModalView } from "./roomsModal.constants";
import {
  Container,
  CloseIcon,
  CloseButton,
} from "./roomsModal.styled";

export const RoomsModal = ({
  handleClose,
}) => {
  const room = useSelector(selectCurrentRoom);
  const rooms = room.parent?.groups || room.groups;
  const [currentView, setCurrentView] = useState(RoomsModalView.Initial);
  const [participantsAssignment, setParticipantsAssignment] = useState();
  const [newRooms, setNewRooms] = useState([]);

  const handleBackFromFinalView = () => {
    setCurrentView(RoomsModalView.Initial);
  };

  if (rooms?.length) {
    return (
      <>
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <RoomsList rooms={rooms} />
      </>
    );
  }

  return (
    <Container>
      <CloseButton onClick={handleClose}>
        <CloseIcon />
      </CloseButton>
      <RoomsModalFirstView
        handleNextButton={() => setCurrentView(RoomsModalView.AssignUsersToRooms)}
        rooms={newRooms}
        updateRooms={setNewRooms}
        open={currentView === RoomsModalView.Initial}
        participantsAssignment={participantsAssignment}
        setParticipantsAssignment={setParticipantsAssignment}
      />
      {currentView === RoomsModalView.AssignUsersToRooms && (
        <AssignUsersView
          goBack={() => setCurrentView(RoomsModalView.Initial)}
          goToNextPage={() => setCurrentView(RoomsModalView.RoomsFinalEdit)}
          rooms={newRooms}
          updateRooms={setNewRooms}
          participantsAssignment={participantsAssignment}
          open={currentView === RoomsModalView.AssignUsersToRooms}
        />
      )}
      {currentView === RoomsModalView.RoomsFinalEdit && (
        <RoomsFinalEditView
          rooms={newRooms}
          updateRooms={setNewRooms}
          goBack={handleBackFromFinalView}
        />
      )}
    </Container>
  );
};
