import styled, { css } from "styled-components";
import { prop } from "ramda";

import { ReactComponent as AddIconBase } from "../../../../images/addInCircle.svg";
import { ReactComponent as UploadIconBase } from "../../../../images/upload.svg";
import { ReactComponent as MovieIconBase } from "../../../../images/movie.svg";
import { Input as InputBase } from "../../../../components/input/input.component";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color, FontWeight, Media } from "../../../../theme";

export const Header = styled.h3`
  font-size: 18px;
  line-height: 20px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.deepGray};
  margin-bottom: 30px;
  margin-left: 0;
`;

export const ModalContentStep1 = styled.div`
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 10px;
  position: relative;

  ${Media.tablet`
    padding: 0;
  `}

  ${styleWhenTrue(prop("isDragActive"), css`
    opacity: 0.5;
  `)}
`;

export const ModalContentStep2 = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${Media.tablet`
    height: 550px;
  `}
`;

export const AddIcon = styled(AddIconBase)`
  g {
    stroke: ${() => Color.brandBasic};
  }
`;

export const CircleIconWrapper = styled.div`
  height: 140px;
  width: 140px;
  border-radius: 100%;
  background-color: ${Color.almostWhite};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlusIcon = styled(UploadIconBase)`
  width: 140px;
  fill: ${() => Color.brandBasic};
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;

  ${Media.tablet`
    width: 375px;
  `}
`;

export const Text = styled.span`
  margin-top: 20px;
  font-size: 14px;
  line-height: 22px;
  color: ${Color.deepGray};
  text-align: center;

  ${Media.tablet`
    font-size: 16px;
  `}
`;

export const Subtext = styled.span`
  margin: 5px 0 25px;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.17px;
  color: ${Color.gray4};
  text-align: center;

  ${Media.tablet`
    font-size: 14px;
  `}
`;

export const FileInput = styled.input`
  visibility: hidden;
`;

export const FileInputLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 145px;
  height: 43px;
  border-radius: 3px;
  background-color: ${() => Color.brandBasic};
  color: ${Color.white};
  cursor: pointer;
  transition: background-color 150ms ease-in-out;
  font-size: 10px;
  font-weight: ${FontWeight.Bold};
  text-transform: uppercase;

  &:hover {
    opacity: 0.6;
  }
`;

export const Box2 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  ${Media.tablet`
    padding: 60px 250px 0 30px;
  `}
`;

export const ThumbnailHeader = styled.h4`
  font-size: 12px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.deepGray};
  margin-top: 35px;
`;

export const ImageThumbnailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;

  ${Media.tablet`
    align-items: center;
    flex-direction: row;
  `}
`;

export const ImageThumbnail = styled.img`
  border-radius: 3px;
  opacity: 0.8;
  cursor: pointer;
  width: 140px;
  height: 88px;

  & + & {
    margin-top: 5px;
  }

  ${Media.tablet`
    width: 100px;
    height: 63px;
    
    & + & {
      margin-top: 0;
      margin-left: 5px;
    }  
  `}

  ${styleWhenTrue(prop("selected"),css`
    border: 2px solid ${() => Color.brandBasic};
    opacity: 1;
  `)}
`;

export const InfoText = styled.span`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: ${Color.gray4};
  text-align: center;
`;

export const AdminVerificationText = styled.span`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: ${Color.gray4};
`;

export const BoldText = styled.span`
  font-weight: ${FontWeight.Bold};
  color: ${Color.deepGray};
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid ${Color.gray8};
  height: 100px;
  padding: 0 30px;

  ${Media.tablet`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const MovieIcon = styled(MovieIconBase)`
  height: 21px;
  width: 19px;
  margin-right: 15px;
`;

export const TransferInfoWrapper = styled.div`
  display: none;
  align-items: center;
  ${Media.tablet`
    display: flex;
  `}
`;

export const HiddenCanvas = styled.canvas`
  display: none;
`;

export const HiddenVideo = styled.video`
  display: none;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const RequiredField = styled.span`
  display: none;
  font-size: 10px;
  color: ${Color.gray4};
  position: absolute;
  right: 20px;
  top: 14px;

  ${Media.tablet`
    display: inline;
  `}
`;

export const Input = styled(InputBase)`
  ${Media.tablet`
    padding-right: 90px;
  `}
`;
