import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../helpers/rendering";
import { ThumbnailList as ThumbnailListBase } from "../thumbnailList/thumbnailList.component";

export const Container = styled.div``;

export const ThumbnailList = styled(ThumbnailListBase)`
  margin-top: 60px;
  position: relative;

  ${styleWhenTrue(prop("noMargin"), css`
    .thumbnailList {
      margin: 0;
    }
  `)}
`;
