import styled from "styled-components";

import { ReactComponent as HourglassSvg } from "../../../../../images/hourglass.svg";
import { FontWeight, Color } from "../../../../../theme";

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const ProgressLabel = styled.p`
  font-size: 12px;
  line-height: 22px;
  margin-left: 10px;
`;

export const VerificationLabel = styled.p`
  font-size: 10px;
  line-height: 13px;
  font-weight: ${FontWeight.ExtraBold};
  color: ${Color.gray3};
  text-transform: uppercase;
  margin-right: 10px;
`;

export const HourglassIcon = styled(HourglassSvg)`
  width: 33px;
  height: 33px;
  flex-shrink: 0;
`;

export const NewLabel = styled.p`
  font-size: 12px;
  line-height: 16px;
  padding: 8px 7px;
  color: ${Color.orange};
  border: 1px solid ${Color.orange};
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: ${FontWeight.Bold};
`;
