import { createSelector } from "reselect";
import { prop, path } from "ramda";

export const selectOnboardingDomain = prop("onboarding");

export const selectIsInitialDataPending = createSelector(
  selectOnboardingDomain, prop("isPending"),
);

export const selectOnboardingData = createSelector(
  selectOnboardingDomain, path(["data"]),
);

export const selectOnboardingCertificatePath = createSelector(
  selectOnboardingData, path(["certificatePath"]),
);

export const selectCertificatePathStages = createSelector(
  selectOnboardingCertificatePath, path(["stages"]),
);

export const selectOnboardingPathPassed = createSelector(
  selectOnboardingCertificatePath, path(["passed"]),
);

export const selectOnboardingContactInfo = createSelector(
  selectOnboardingData, path(["contactInfo"]),
);

export const selectOnboardingStatistic = createSelector(
  selectOnboardingData, path(["statistic"]),
);

export const selectUserStages = createSelector(
  selectOnboardingStatistic, path(["stages"]),
);

export const selectFinishedUserStages = createSelector(
  selectUserStages, path(["count"]),
);

export const selectTotalUserStages = createSelector(
  selectUserStages, path(["total"]),
);

export const selectUserContentPassed = createSelector(
  selectOnboardingStatistic, path(["items"]),
);

export const selectOnboardingUser = createSelector(
  selectOnboardingData, path(["user"]),
);

export const selectOnboardingHero = createSelector(
  selectOnboardingUser, path(["onboardingHero"]),
);

export const selectOnboardingMode = createSelector(
  selectOnboardingUser, path(["onboardingMode"]),
);

export const selectHeroesList = createSelector(
  selectOnboardingDomain, path(["heroesList"]),
);