import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { prop } from "ramda";

import { breakpoints } from "../../UIGlobals";
import { styleWhenTrue } from "../../helpers/rendering";
import { Color, Media, Transitions } from "../../theme";

export const Sidebar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  padding: 13px 0 13px 18px;
  border-right: 1px solid ${Color.dirtyWhite};
  overflow: hidden;
  height: 100vh;
  width: 80px;
  background: ${Color.white};
  transition: ${Transitions.custom};
  max-height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;

  ${styleWhenTrue(prop("disabled"), css`
    overflow: visible;
  `)}

  @media (${breakpoints().minL}) {
    &:hover {
      width: 300px;
    }

    ${styleWhenTrue(prop("disabled"), css`
        width: 300px;
    `)}
  }

  @media (${breakpoints().maxL}) {
    width: 300px;
    left: -300px;

    ${styleWhenTrue(prop("$mobileVisible"), css`
      left: 0;
    `)}

    ${styleWhenTrue(prop("disabled"), css`
      left: 0;
    `)}
  }

  @media (${breakpoints().maxM}) {
    ${styleWhenTrue(prop("disabled"), css`
      display: none;
    `)}
  }
`;

export const Background = styled.div`
  @media (${breakpoints().maxL}) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    background-color: ${Color.semiDarkGray};
    z-index: 99;
    transition: ${Transitions.custom};

    ${styleWhenTrue(prop("$mobileVisible"), css`
      opacity: 0.3;
      pointer-events: auto;
    `)}
  }
`;

export const LogoLink = styled(NavLink)`
  cursor: pointer;
  position: absolute;
`;

export const Nav = styled.nav`
  display: table-cell;
  left: 0;
  height: calc(100vh - 26px);
  padding-top: 70px;
  vertical-align: middle;

  a, div {
    color: ${Color.gray4};
    font-size: 13px;
    line-height: 42px;
    padding: 0;
  }

  ${Media.tabletWide`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  `}
`;

export const RightNavSelect = styled.div`
  font-size: 11px;
  font-weight: 600;
  padding: 5px;
  line-height: 1.91;
  text-align: left;
  color: ${Color.black};
  cursor: pointer;
  transition: ${Transitions.custom};

  &:hover {
    opacity: 0.7;
  }
`;

export const ArrowDown = styled.div`
  position: absolute;
  left: 235px;
  top: 82vh;
  box-shadow: 1px 1.732px 0 0 rgba(31, 27, 22, 0.08), 0 2px 10px 0 rgba(31, 27, 22, 0.02), 0 0 20px 0 rgba(31, 27, 22, 0.03);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;

  i {
    pointer-events: none;
  }
`;

export const TutorialWrapper = styled.div`
  position: relative;
`;
