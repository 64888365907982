import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue, styleIfElse } from "../../../../helpers/rendering";
import { Color, Media } from "../../../../theme";
import { ReactComponent as CameraOffSVG } from "../../images/cameraOff.svg";
import { PlayInfo as PlayInfoBase } from "../playInfo/playInfo.component";

export const Container = styled.div`
  ${styleWhenTrue(prop("withPlayInfo"), css`
    position: relative;
  `)}
  
  ${styleWhenTrue(prop("mobileHidden"), css`
    display: none;
  `)}

  ${Media.tablet`
    display: block;
    grid-area: 1 / 2 / 1 / 2;
    padding-bottom: 20px;

    ${styleWhenTrue(prop("fullScreen"), css`
      padding: 20px 16px;
      display: flex;
      align-items: center;

      ${styleWhenTrue(prop("isSecondVideo"), css`
        padding: 0 16px;
      `)}
    `)}

    ${styleWhenTrue(prop("isSecondVideo"), css`
      grid-area: 2 / 2 / 2 / 2;
    `)}

    ${styleIfElse(prop("large"), css`
      display: flex;
      align-items: center;
      grid-area: 1 / 1 / ${styleIfElse(prop("fullScreen"), 5, 4)} / 2;
      padding: 0;
      width: 100%;
      background-color: ${Color.black};
      overflow: hidden;

      video {
        max-height: unset;
      }
    `, css`
      video {
        height: 100%;
      }
    `)}
  `}
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.black};
  padding: 100px 0;

  ${Media.tablet`
    min-height: 504px;
  `}
`;

export const VideoBox = styled.video`
  max-height: 250px;

  ${Media.mobile`
    max-height: 350px;
  `}

  ${Media.tablet`
    object-fit: cover;
  `}

  ${styleWhenTrue(prop("hidden"), css`
    display: none;
  `)}

  ${styleWhenTrue(prop("tallerThanContainer"), css`
      height: 100%;
      width: auto;
      margin: 0 auto;
  `)}
`;

export const CameraOffIcon = styled(CameraOffSVG)`
  width: 110px;
  height: 91px;

  g {
    fill: ${Color.error};
  }
`;

export const PlayInfo = styled(PlayInfoBase)`
  position: absolute;
  left: 30px;
  bottom: 20px;

  ${styleWhenTrue(prop("fullScreen"), css`
    bottom: 80px;
  `)}
`;
