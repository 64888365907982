import styled from "styled-components";

import { ListHeader as ListHeaderBase } from "../../../../../UIElements";
import { breakpoints } from "../../../../../UIGlobals";
import { Color } from "../../../../../theme";

export const TeamActivityHeader = styled(ListHeaderBase)`
  line-height: 26px;
  margin-bottom: 25px;

  @media (${breakpoints().maxM}) {
    padding: 0 20px;
  }
`;

export const ExpandButtonText = styled.div`
  font-size: 10px;
  color: ${Color.black};
  font-weight: bold;
  line-height: 20px;
  padding: 0 10px;
  cursor: pointer;
`;
