import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { convertListToBoolMap } from "../../../../helpers/transform";

import { FilterButton } from "./tagFilter.styled";

export const TagFilter = ({
  tag,
  dropdownShowStates,
  setDropdownShowStates,
  expandedButtonRef,
  selectedFilters,
  isClearTag,
  clearFilters,
  clearFiltersTagRef,
  closeDropdown = Function.prototype,
  isCompetenceTag,
  setChosen = Function.prototype,
}) => {
  const { t } = useTranslation();

  const activeFilter = isClearTag
    ? !selectedFilters?.length
    : selectedFilters?.length &&
    (selectedFilters.includes(isCompetenceTag ? tag.id : tag) ||
      !!tag.skills?.find((skill) => selectedFilters.includes(skill.id)));

  const toggleDropdown = () => {
    const hiddenDropdowns = convertListToBoolMap(Object.keys(dropdownShowStates), false);
    setDropdownShowStates({ ...hiddenDropdowns, [tag.id]: !dropdownShowStates[tag.id] });
  };

  const handleButtonClick = () => {
    if (isClearTag) {
      clearFilters();
      closeDropdown();
    } else if (isCompetenceTag) {
      toggleDropdown();
    } else {
      setChosen(tag);
    }
  };

  if (expandedButtonRef) {
    return (
      <FilterButton ref={expandedButtonRef} active={activeFilter} onClick={handleButtonClick}>
        {tag.name}
      </FilterButton>
    );
  }

  if (isClearTag) {
    return (
      <FilterButton ref={clearFiltersTagRef} active={activeFilter} onClick={handleButtonClick}>
        {t("components.tagFilter.allMaterials")}
      </FilterButton>
    );
  }

  return (
    <FilterButton active={activeFilter} onClick={handleButtonClick}>
      {tag.name}
    </FilterButton>
  );
};

TagFilter.propTypes = {
  tag: PropTypes.object,
  dropdownShowStates: PropTypes.object,
  setDropdownShowStates: PropTypes.func,
  expandedButtonRef: PropTypes.any,
  selectedFilters: PropTypes.array,
  isClearTag: PropTypes.bool,
  clearFilters: PropTypes.func,
  clearFiltersTagRef: PropTypes.any,
  closeDropdown: PropTypes.func,
  isCompetenceTag: PropTypes.bool,
  setChosen: PropTypes.func,
};
