import styled from "styled-components";
import { Link as LinkBase } from "react-router-dom";

import { Button } from "../../../../components/button/button.component";
import { ReactComponent as TrashSvg } from "../../../../images/trash.svg";
import { hexToRgba } from "../../../../helpers/colors";
import { Color, ContentMaxWidth, Media } from "../../../../theme";

export const Container = styled.div`
  background-color: ${Color.almostWhite};
  padding: 40px 0 80px;

  ${Media.tablet`
    padding: 40px 80px 80px;
  `}

  ${Media.desktop`
    padding: 40px 160px 80px;
  `}
`;

export const FormWrapper = styled.div`
  margin: 0 auto;
  padding: 60px 0 80px;
  max-width: ${ContentMaxWidth};
  background-color: ${Color.white};
  box-shadow: 0 5px 10px ${hexToRgba(Color.deepGray, 0.01)};
`;

export const FormBox = styled.div`
  margin: 0 auto;
  max-width: 640px;
  padding: 0 20px;
`;

export const Form = styled.div`
  margin-top: 40px;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AddButton = styled(Button)`
  margin-left: 20px;
  margin-right: 10px;

  ${Media.mobile`
    margin-left: 29px;
  `}
`;

export const TrashIcon = styled(TrashSvg)`
  width: 14px;
  height: 18px;
  fill: ${() => Color.brandBasic};
`;

export const RemoveButton = styled(Button)`
  background-color: ${Color.almostWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 43px;
  padding: 0;

  &:hover {
    ${TrashIcon} {
      fill: ${Color.white};
    }
  }
`;

export const AvatarAlert = styled.p`
  background-color: ${Color.lightYellow};
  color: ${Color.deepGray};
  font-size: 12px;
  line-height: 16px;
  padding: 12px 15px;
  display: inline-flex;
  margin-top: 5px;
  border-radius: 2px;
`;

export const Link = styled(LinkBase)`
  color: ${() => Color.brandBasic};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
