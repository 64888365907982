import { phpApi, nodeApi, handleApiError } from "../../../api";

export const getInitialData = () => {
  return phpApi.get("/api/dashboard").catch(handleApiError);
};

export const getHostSex = () => {
  return nodeApi.get("/dashboard/host").catch(handleApiError);
};

export const switchHostSex = (sex) => {
  return nodeApi.post("/dashboard/host", {
    gender: sex,
  }).catch(handleApiError);
};

export const createUserVideo = (formData) => {
  return nodeApi.post("/dashboard/users/videos", formData).catch(handleApiError);
};

export const getUserVideo = () => {
  return nodeApi.get("/dashboard/users/videos/my").catch(handleApiError);
};

export const deleteUserVideo = () => {
  return nodeApi.delete("/dashboard/users/videos/my").catch(handleApiError);
};

export const getAllUserVideos = () => {
  return nodeApi.get("/dashboard/users/videos").catch(handleApiError);
};

export const getCompetenceItems = () => {
  return phpApi.get("/api/competence/items").catch(handleApiError);
};

export const fetchBanners = () => {
  return nodeApi.get("/banners").catch(handleApiError);
};
