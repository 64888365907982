import React from "react";
import styled from "styled-components";

import { Icon, Tiles, IconText, TilesText } from ".";
import { colors, breakpoints } from "../UIGlobals";

export const Col = styled.div`
  width: ${props => (props.width ? props.width : "100%")};
  display: flex;
  flex-direction: column;
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom + "px" : "0")};
  margin-right: ${props => (props.marginRight ? props.marginRight + "px" : "0")};
  ${props => props.marginBottom && "margin-bottom: " + props.marginBottom + "px"};
  &:last-of-type {
    padding-bottom: 0;
  }
  ${props => props.mobileMarginBottom &&
    `@media (${breakpoints().maxM}) {
      margin-bottom: ${props.mobileMarginBottom}px;
    }`
  }
`;

export const StatisticTile = ({
  bottomSeparator,
  leftSeparator,
  isBlack,
  icon,
  statisticNumber,
  statisticText
}) => (
  <Tiles
    height={138}
    {...(bottomSeparator && { marginBottom: 2 })}
    {...(leftSeparator && { marginLeft: 2 })}
  >
    <IconText>
      <Icon icon={icon} size={36} color={colors().gray7} />
      <Col>
        <TilesText
          paddingLeft={30}
          landscapeLeftPadding={20}
          fontWeight={600}
          fontSize={3}
          color={isBlack ? colors().deepGray : "brandBasic"}
          paddingBottom={5}
          statisticNumber
        >
          {statisticNumber}
        </TilesText>
        <TilesText
          lineHeight={22}
          paddingLeft={30}
          landscapeLeftPadding={20}
          fontWeight={isBlack ? 200 : 600}
          fontSize={1.5}
        >
          {statisticText}
        </TilesText>
      </Col>
    </IconText>
  </Tiles>
);
