import styled from "styled-components";

import { ReactComponent as ErrorSvg } from "../../../../images/appError.svg";
import { FontWeight } from "../../../../theme";

export const Browser = styled.span`
  font-weight: ${FontWeight.SemiBold};
`;

export const ErrorIcon = styled(ErrorSvg)`
   display: block;
   height: 90px;
   width: 90px;
   margin: 0 auto 30px;
`;
