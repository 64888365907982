import React from "react";
import { useTranslation } from "react-i18next";

import { breakpointsArray } from "../../../../../UIGlobals";
import { Icon } from "../../../../../UIElements";

import {
  SmallBox,
  BlackCircle,
  LightGrayText,
  Box,
  RankingTextWrapper,
  RankingIconWrapper,
} from "./userRankingBox.styled";

export const UserRankingBox = ({ ranking }) => {
  const { t } = useTranslation();

  if (window.innerWidth <= breakpointsArray.l && window.innerWidth > breakpointsArray.s) {
    return (
      <SmallBox>
        <BlackCircle>{ranking.position}</BlackCircle>
        <div>
          {t("statistics.userRankingBox.rankingPlace")}
          <LightGrayText>
            {ranking.points} {t("statistics.userRankingBox.points")}
          </LightGrayText>
        </div>
      </SmallBox>
    );
  }

  return (
    <Box>
      <RankingTextWrapper>
        <BlackCircle>{ranking.position}</BlackCircle>
        <div>
          {t("statistics.userRankingBox.rankingPlace")}
          <LightGrayText>
            {ranking.points} {t("statistics.userRankingBox.points")}
          </LightGrayText>
        </div>
      </RankingTextWrapper>
      <RankingIconWrapper>
        <Icon icon="woman_with_flag" size={240} />
      </RankingIconWrapper>
    </Box>
  );
};
