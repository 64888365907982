import styled from "styled-components";

import { Color, FontWeight } from "../../../../theme";
import { hexToRgba } from "../../../../helpers/colors";

export const Container = styled.div`
  position: absolute;
  display: none;
  top: 70px;
  left: 0;
  width: 280px;
  box-shadow: 0 2px 4px ${hexToRgba(Color.gray6, 0.2)}, 0 0 20px ${hexToRgba(Color.gray6, 0.03)};
  background-color: ${Color.white};
  border-radius: 4px;
  padding: 20px;
  z-index: 1;

  label {
    color: ${Color.black};
    height: 21px;
    padding-left: 30px;

    div {
      width: 21px;
      height: 21px;

      &::after {
        left: 6px;
        width: 5px;
        top: 2px;
        height: 10px;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 14px;
    }
  }

  p {
    font-size: 12px;
    color: ${Color.gray2};
  }
`;

export const Title = styled.div`
  color: ${() => Color.brandBasic};
  font-weight: ${FontWeight.bold};
  padding-bottom: 7px;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  font-size: 10px;
  border-bottom: 1px solid ${Color.gray5};
`;

