import styled, { css } from "styled-components";
import { prop } from "ramda";

import { hexToRgba } from "../../../../../helpers/colors";
import { styleWhenTrue } from "../../../../../helpers/rendering";
import { Color, FontWeight } from "../../../../../theme";
import { ReactComponent as CitySvg } from "../../../images/city.svg";

export const Container = styled.div`
  text-transform: uppercase;
  color: ${Color.violet};
  font-size: 14px;
  line-height: 19px;
  font-weight: ${FontWeight.ExtraBold};
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
`;

export const BackButton = styled.button`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 128px;
  height: 128px;
  border: 4px solid ${Color.white};
  border-radius: 50%;
  box-shadow: 0 6px 10px ${hexToRgba(Color.violet, 0.1)};
  background-color: ${Color.lightYellow2};
  font-size: 14px;
  line-height: 19px;
  font-weight: ${FontWeight.ExtraBold};
  color: ${Color.violet};
`;

export const CityImg = styled(CitySvg)`
  width: 92px;
  height: 60px;
  margin-bottom: 10px;
`;

export const Stats = styled.div`
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const Result = styled.p`
  width: 46px;
  height: 46px;
  background-color: ${Color.lightViolet};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ResultLabel = styled.p`
  position: absolute;
  bottom: -20px;

  ${styleWhenTrue(prop("isLastStage"), css`
    color: ${Color.lightViolet};
  `)}
`;
