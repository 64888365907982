import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { remove } from "ramda";
import { useFormik } from "formik";
import fileDialog from "file-dialog";

import { Chip } from "../../../../components/chip/chip.component";
import { ButtonVariant } from "../../../../components/button/button.constants";
import { ConfirmationModal } from "../../../../components/confirmationModal/confirmationModal.component";
import { ContentEditor } from "../../../../components/contentEditor/contentEditor.component";
import { Loader } from "../../../../components/loader/loader.component";
import { ReactComponent as AttachmentIcon } from "../../../../images/attachment.svg";
import { renderWhenTrue, renderWhenTrueOtherwise } from "../../../../helpers/rendering";
import { simpleDateFromTimestamp } from "../../../../helpers/date";
import { GamificationActions } from "../../redux/gamification.reducer";
import { selectOpenQuestionPending, selectOpenQuestion } from "../../redux/gamification.selectors";

import { FileMaxSize, FileMaxSizeInMB, FileTypeAccepted } from "./openQuestion.constants";
import { QuestionSchema } from "./openQuestion.schema";
import {
  Container,
  Header,
  HeaderText,
  VerticalSeparator,
  QuestionType,
  Activity,
  HorizontalSeparator,
  ButtonSeparator,
  Title,
  ActionButtons,
  Button,
  BackLink,
  Warning,
  ModalText,
  AddButton,
  Icon,
  Files,
  FilesLabel,
  FilesList,
  FilesEmpty,
  FileErrorText,
  ErrorFileName,
  WarningIcon,
} from "./openQuestion.styled";

export const OpenQuestion = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const isPending = useSelector(selectOpenQuestionPending);
  const question = useSelector(selectOpenQuestion);
  const questionFormatted = question
    ? question.question.split("\n").map((text, index) => (
      <React.Fragment key={index}>
        {text}
        <br />
      </React.Fragment>
    ))
    : "";
  const [popupOpen, setPopupOpen] = useState(false);
  const [modalSendButtonText, setModalSendButtonText] = useState(t("gamification.openQuestion.send"));
  const [modalSendButtonDisabled, setModalSendButtonDisabled] = useState(false);
  const [filesOverSize, setFilesOverSize] = useState([]);
  const initialValues = {
    answer: "",
    files: [],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmitFinish = () => {
    setPopupOpen(false);
    formik.setSubmitting(false);
    formik.resetForm();
    navigate("/gamification");
  };

  const handleSubmit = () => {
    dispatch(GamificationActions.sendOpenQuestion(formik.values, onSubmitFinish, params.id));
    setModalSendButtonText(t("gamification.openQuestion.sending"));
    setModalSendButtonDisabled(true);
  };

  const formik = useFormik({
    validationSchema: QuestionSchema,
    initialValues,
    onSubmit: () => handleSubmit(),
  });

  useEffect(() => {
    dispatch(GamificationActions.fetchOpenQuestion(params.id));

    return () => {
      dispatch(GamificationActions.clearQuestion());
    }
  }, [dispatch, params.id]);

  const togglePopup = () => {
    setPopupOpen(!popupOpen);
  };

  const handleAddFiles = useCallback((files, filesOverSize) => {
    formik.setFieldValue("files", formik.values.files.concat(files));
    setFilesOverSize(filesOverSize);
  }, [formik]);

  const handleNewFileClick = () => {
    fileDialog({ multiple: true, accept: FileTypeAccepted }).then((files) => {
      const preparedFiles = [...files]
        .filter((file) => {
          const fileName = file.name.split(".");
          return file.size <= FileMaxSize && FileTypeAccepted.includes(fileName[fileName.length - 1]);
        });
      const invalidFiles = [...files]
        .filter((file) => {
          const fileName = file.name.split(".");
          return file.size > FileMaxSize || !FileTypeAccepted.includes(fileName[fileName.length - 1]);
        })
        .map((file) => file.name);
      handleAddFiles(preparedFiles, invalidFiles);
    });
  };

  const getRemoveFileHandler = useCallback((index) => () => {
    const updatedFiles = remove(index, 1, formik.values.files);

    formik.setFieldValue("files", updatedFiles);
    setFilesOverSize([]);
  }, [formik]);

  const renderFileList = () => (
    <FilesList>
      {formik.values.files.map(({ name }, index) => (
        <Chip
          key={name}
          maxLength={30}
          onRemove={getRemoveFileHandler(index)}
        >
          {name}
        </Chip>
      ))}
    </FilesList>
  );

  const renderFileError = renderWhenTrue(() => (
    <>
      <FileErrorText>
        {t("news.addNews.article.attachmentsNotAdded")}
        {filesOverSize.map((filename, index) => {
          const isLast = index === filesOverSize.length - 1;
          return (
            <ErrorFileName key={filename}> {filename}{!isLast && ","}</ErrorFileName>
          );
        })}
      </FileErrorText>
      <FileErrorText>
        {t("gamification.openQuestion.fileInvalid", { format: FileTypeAccepted, maxSize: FileMaxSizeInMB })}
      </FileErrorText>
    </>
  ));

  const renderFiles = () => (
    <>
      <AddButton variant={ButtonVariant.Outlined} onClick={handleNewFileClick}>
        <Icon size={15}>
          <AttachmentIcon />
        </Icon>
        {t("components.contentEditor.toolbar.attachFiles")}
      </AddButton>
      <Files withError={!!filesOverSize.length}>
        <FilesLabel>{t("news.addNews.article.attachmentsLabel")}</FilesLabel>
        {renderWhenTrueOtherwise(
          renderFileList,
          () => <FilesEmpty>{t("news.addNews.article.attachmentsEmptyLabel")}</FilesEmpty>,
        )(!!formik.values.files.length)}
      </Files>
      {renderFileError(!!filesOverSize.length)}
    </>
  );

  if (isPending || !question) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  return (
    <Container className={className}>
      <Header>
        <HeaderText>{t("gamification.openQuestion.openQuestion")}</HeaderText>
        <VerticalSeparator />
        <QuestionType>{question.label}</QuestionType>
        <Activity>
          {t("gamification.openQuestion.activeTo")}
          {" "}
          {simpleDateFromTimestamp(question.dateTo)}
        </Activity>
      </Header>
      <HorizontalSeparator />
      <Title>{questionFormatted}</Title>
      <form onSubmit={formik.handleSubmit}>
        <ContentEditor
          name="answer"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={formik.handleFocus}
          value={formik.values.answer}
          error={formik.errors.answer}
          placeholder={t("gamification.openQuestion.writeAnswer")}
          withToolbar
        />
        {renderFiles()}
        <ButtonSeparator />
        <ActionButtons>
          <BackLink to="/gamification">
            {t("gamification.openQuestion.back")}
          </BackLink>
          <Button
            onClick={togglePopup}
            disabled={!formik.isValid || formik.isSubmitting || !formik.values.answer.length}
          >
            {t("gamification.openQuestion.send")}
          </Button>
        </ActionButtons>
        <ConfirmationModal
          open={popupOpen}
          confirmText={modalSendButtonText}
          declineText={t("gamification.openQuestion.cancel")}
          onDecline={togglePopup}
          onConfirm={formik.handleSubmit}
          HeaderIcon={WarningIcon}
          isConfirmButtonDisabled={modalSendButtonDisabled}
          title={t("gamification.openQuestion.sendAnswer")}
        >
          <Warning>{t("gamification.openQuestion.warning")}</Warning>
          <ModalText>{t("gamification.openQuestion.warningText")}</ModalText>
          <ModalText>{t("gamification.openQuestion.youSure")}</ModalText>
        </ConfirmationModal>
      </form>
    </Container>
  );
};

OpenQuestion.propTypes = {
  className: PropTypes.string,
};
