import styled from "styled-components";

import { Button as ButtonBase } from "../../../../components/button/button.component";
import { ReactComponent as CheckSvg } from "../../../../images/check.svg";
import { hexToRgba } from "../../../../helpers/colors";
import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.div`
  background-color: ${Color.white};
  box-shadow: 0 5px 10px ${hexToRgba(Color.deepGray, 0.01)};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 20px;

  ${Media.tablet`
    flex-direction: row;
    padding: 45px 35px;
  `}
`;

export const CheckIcon = styled(CheckSvg)`
  display: none;
  width: 50px;
  height: 50px;
  margin-right: 30px;
  flex-shrink: 0;

  ${Media.tablet`
    display: block;
  `}
`;

export const TextWrapper = styled.div``;

export const BoldText = styled.p`
  color: ${Color.deepGray};
  font-weight: ${FontWeight.SemiBold};
  font-size: 18px;
  line-height: 20px;
`;

export const Text = styled.p`
  font-size: 15px;
  line-height: 17px;
  color: ${Color.gray9};
  margin-top: 7px;
`;

export const Button = styled(ButtonBase)`
  width: 100%;
  margin-top: 20px;

  ${Media.tablet`
    width: auto;
    margin-left: auto;
    margin-top: 0;
  `}
`;
