import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { ProgressMark } from "../../../../components/progressMark/progressMark.component";
import { ChartColorType } from "../../../../components/simpleProgressChart/simpleProgressChart.constants";
import { Loader } from "../../../../components/loader/loader.component";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { CompetenceActions } from "../../redux/competence.reducer";
import {
  selectUserCompetenceList,
  selectIsPending,
} from "../../redux/competence.selectors";
import { SuggestionBox } from "../suggestionBox/suggestionBox.component";
import { BoxType } from "../suggestionBox/suggestionBox.constants";

import { CompetenceType } from "./competenceList.constants";
import {
  Container,
  Wrapper,
  PageHeader,
  SectionTitle,
  Section,
  CompetenceItem,
  TextWrapper,
  Title,
  Level,
  ArrowIcon,
} from "./competenceList.styled";

export const CompetenceList = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isPending = useSelector(selectIsPending);
  const competences = useSelector(selectUserCompetenceList);

  useEffect(() => {
    dispatch(CompetenceActions.getUserCompetenceList());
  }, [dispatch]);

  const onCompetenceClick = (id) => {
    navigate(`/competence/${id}/details`);
  };

  const renderMarkCircle = (rate) => {
    if (!rate) return <ProgressMark />;

    return (
      <ProgressMark
        rate={rate.rate}
        maxRate={rate.maxRate}
        variant={ChartColorType.Gold}
      />
    );
  };

  const renderCompetenceItem = ({ competence, rate }) => {
    const levelText = rate
      ? `${t("competence.level")} ${t(rate.name)}`
      : t("competence.ratingInProgress");

    return (
      <CompetenceItem
        key={competence.id}
        onClick={() => onCompetenceClick(competence.id)}
      >
        {renderMarkCircle(rate)}
        <TextWrapper>
          <Title>{competence.name}</Title>
          <Level>{levelText}</Level>
        </TextWrapper>
        <ArrowIcon />
      </CompetenceItem>
    );
  };

  const renderCompetences = (type) => {
    const filteredCompetences = competences.filter((item) => (
      item.competence.type.enum === type
    ));

    if (!filteredCompetences.length) return null;

    return (
      <Section>
        <SectionTitle>{filteredCompetences[0].competence.type.name}</SectionTitle>
        {filteredCompetences.map(renderCompetenceItem)}
      </Section>
    );
  };

  const renderLoader = renderWhenTrue(() => <Loader />);

  const renderContent = renderWhenTrue(() => (
    <Wrapper>
      <PageHeader>{t("competence.pageTitle")}</PageHeader>
      {renderCompetences(CompetenceType.Strategic)}
      {renderCompetences(CompetenceType.Common)}
      <SuggestionBox type={BoxType.Suggestion} />
    </Wrapper>
  ));

  return (
    <Container className={className}>
      {renderLoader(isPending)}
      {renderContent(!isPending)}
    </Container>
  );
};

CompetenceList.propTypes = {
  className: PropTypes.string,
};
