import React from "react";
import PropTypes from "prop-types";

import {
  Container,
  List,
  Label,
  Item,
  Button,
  CheckIcon,
} from "./deviceList.styled";

export const DeviceList = ({
  className,
  onChooseDevice,
  devices,
  label,
  currentDeviceId,
}) => {
  const renderDevices = devices.map((device, index) => {
    const activated = currentDeviceId ? currentDeviceId === device.deviceId : index === 0;
    return (
      <Item key={device.deviceId}>
        <Button
          onClick={() => onChooseDevice(device.deviceId)}
          activated={activated}
        >
          <CheckIcon />
          {device.label}
        </Button>
      </Item>
    );
  });

  return (
    <Container className={className}>
      <Label>{label}</Label>
      <List>
        {renderDevices}
      </List>
    </Container>
  );
};

DeviceList.propTypes = {
  className: PropTypes.string,
  devices: PropTypes.arrayOf(PropTypes.object),
  onChooseDevice: PropTypes.func,
};
