import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Loader } from "../../../../components/loader/loader.component";
import { CommonActions } from "../../../../redux/common/common.reducer";
import { ConfirmationModal } from "../../../../components/confirmationModal/confirmationModal.component";
import { Switch } from "../../../../components/switch/switch.component";
import { ButtonColor, ButtonVariant } from "../../../../components/button/button.constants";
import { selectUserData } from "../../../../userContext/redux/userContext.selectors";
import { TrainingsActions } from "../../redux/trainings.reducer";
import { FormInput } from "../formInput/formInput.component";

import {
  Container,
  Title,
  Section,
  SectionHeader,
  Separator,
  InputRow,
  SwitchWrapper,
  SwitchLabel,
  Buttons,
  CancelButton,
  EnrollButton,
} from "./enrollForm.styled";
import { FormSchema } from "./enrollForm.schema";

export const EnrollForm = ({
  onClose,
  withAccommodation,
  withParking,
  withVege,
  eventId,
  inDetailsView,
  onEnrollClick,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userData = useSelector(selectUserData);
  const [enrollButtonDisabled, setEnrollButtonDisabled] = useState(false);
  const [phonePopupVisible, setPhonePopupVisible] = useState(false);
  const initialValues = useMemo(() => ({
    supervisorName: "",
    supervisorSurname: "",
    supervisorEmail: "",
    email: userData.user.email,
    phone: userData.user.phone || "",
    parking: false,
    accommodation: false,
    vegeFood: false,
  }), [userData]);

  const formik = useFormik({
    validationSchema: FormSchema,
    initialValues,
    onSubmit: () => handleSubmit(),
  });

  const finishSubmitting = (withPhoneUpdate) => {
    if (withPhoneUpdate) {
      dispatch(CommonActions.changeUserPhoneNumber(formik.values.phone));
    }

    setPhonePopupVisible(false);

    const formData = {
      supervisor: {
        name: formik.values.supervisorName,
        surname: formik.values.supervisorSurname,
        email: formik.values.supervisorEmail,
      },
      user: {
        email: formik.values.email,
        phone: formik.values.phone,
        accommodation: formik.values.accommodation,
        parking: formik.values.parking,
        vege: formik.values.vegeFood,
      }
    };

    dispatch(TrainingsActions.enrollEventWithForm(eventId, formData, () => {
      onClose();
      setEnrollButtonDisabled(false);

      if (inDetailsView) {
        dispatch(TrainingsActions.getEventDetails(eventId));
      }

      if (onEnrollClick) {
        onEnrollClick();
      }
    }));
  };

  const handleSubmit = () => {
    setEnrollButtonDisabled(true);

    if (formik.values.phone !== userData.user.phone) {
      setPhonePopupVisible(true);
    } else {
      finishSubmitting();
    }
  };

  const handleSwitchPositionChange = (name, value) => {
    formik.setFieldValue(name, value);
  };

  return (
    <Container>
      <Title>{t("trainings.enrollForm.enrollForm")}</Title>
      <form onSubmit={formik.submit}>
        <Section>
          <SectionHeader>{t("trainings.enrollForm.supervisorContact")}</SectionHeader>
          <Separator />
          <InputRow>
            <FormInput
              name="supervisorName"
              title={t("trainings.enrollForm.name")}
              placeholder={t("trainings.enrollForm.typeName")}
              value={formik.values.supervisorName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.supervisorName && formik.errors.supervisorName}
            />
            <FormInput
              name="supervisorSurname"
              title={t("trainings.enrollForm.surname")}
              placeholder={t("trainings.enrollForm.typeSurname")}
              value={formik.values.supervisorSurname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.supervisorSurname && formik.errors.supervisorSurname}
            />
          </InputRow>
          <InputRow>
            <FormInput
              name="supervisorEmail"
              title={t("trainings.enrollForm.supervisorEmail")}
              placeholder={t("trainings.enrollForm.typeSupervisorEmail")}
              value={formik.values.supervisorEmail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.supervisorEmail && formik.errors.supervisorEmail}
            />
          </InputRow>
        </Section>
        <Section>
          <SectionHeader>{t("trainings.enrollForm.participantContact")}</SectionHeader>
          <Separator />
          <InputRow>
            <FormInput
              name="email"
              title={t("trainings.enrollForm.participantEmail")}
              placeholder={t("trainings.enrollForm.typeParticipantEmail")}
              value={formik.values.email}
              disabled
            />
            <FormInput
              name="phone"
              title={t("trainings.enrollForm.phone")}
              type="tel"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && formik.errors.phone}
            />
          </InputRow>
        </Section>
        <Section>
          <SectionHeader>{t("trainings.enrollForm.trainingDetails")}</SectionHeader>
          <Separator />
          {withAccommodation && (
            <SwitchWrapper>
              <SwitchLabel>{t("trainings.enrollForm.accommodation")}</SwitchLabel>
              <Switch
                checked={formik.values.accommodation}
                onChange={({ target }) => handleSwitchPositionChange("accommodation", target.checked)}
              />
            </SwitchWrapper>
          )}
          {withParking && (
            <SwitchWrapper>
              <SwitchLabel>{t("trainings.enrollForm.parking")}</SwitchLabel>
              <Switch
                checked={formik.values.parking}
                onChange={({ target }) => handleSwitchPositionChange("parking", target.checked)}
              />
            </SwitchWrapper>
          )}
          {withVege && (
            <SwitchWrapper>
              <SwitchLabel>{t("trainings.enrollForm.vegeMeal")}</SwitchLabel>
              <Switch
                checked={formik.values.vegeFood}
                onChange={({ target }) => handleSwitchPositionChange("vegeFood", target.checked)}
              />
            </SwitchWrapper>
          )}
        </Section>
        <Buttons>
          <CancelButton
            onClick={onClose}
            $large
            variant={ButtonVariant.Outlined}
          >
            {t("trainings.enrollForm.cancel")}
          </CancelButton>
          <EnrollButton
            color={ButtonColor.Primary}
            $large
            onClick={formik.handleSubmit}
            disabled={enrollButtonDisabled}
          >
            {enrollButtonDisabled ? <Loader size={33} /> : t("trainings.enrollForm.enroll")}
          </EnrollButton>
        </Buttons>
      </form>
      <ConfirmationModal
        open={phonePopupVisible}
        onDecline={() => finishSubmitting(false)}
        onConfirm={() => finishSubmitting(true)}
      >
        {t("trainings.enrollForm.phoneChanged")}
      </ConfirmationModal>
    </Container>
  );
};
