import styled from "styled-components";

import { Color } from "../../../../theme";
import { ReactComponent as SquaresSvg } from "../../images/squares.svg";

export const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: ${Color.deepGray};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const SquaresIcon = styled(SquaresSvg)`
  margin-right: 20px;

  path {
    fill: ${() => Color.brandBasic};
  }
`;

export const Text = styled.p`
  font-size: 20px;
  color: ${Color.white};
`;

