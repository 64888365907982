import { prop } from "ramda";
import styled, { css } from "styled-components";

import { hexToRgba } from "../../helpers/colors";
import { styleWhenTrue } from "../../helpers/rendering";

import {
  Color,
  ContentMaxWidth,
  FontWeight,
  Media,
  PageHeader as PageHeaderBase,
} from "../../theme";

import { ReactComponent as SquaresSvg } from "./images/squares.svg";
import { ReactComponent as ListSvg } from "./images/list.svg";
import { ReactComponent as CalendarSvg } from "./images/calendar.svg";

export const Container = styled.div`
  padding: 54px 0;
  background-color: ${Color.almostWhite};
  display: flex;
  justify-content: center;

  ${Media.tablet`
    padding: 54px 80px;
  `}

  ${Media.desktop`
    padding: 54px 160px;
  `}
`;

export const Wrapper = styled.div`
  max-width: ${ContentMaxWidth};
  width: 100%;
`;

export const Header = styled.div`
  padding: 56px 0 0;
  margin: 0 20px 20px;
  
  ${Media.tablet`
    display: flex;
    align-items: center;
    margin: 0 0 40px;
  `}
`;

export const PageHeader = styled(PageHeaderBase)`
  margin: 0 0 40px;

  ${Media.tablet`
    margin: 0 auto 0 0;
  `}
`;

export const HeaderRightSide = styled.div`
  display: flex;
  align-items: center;

  ${Media.tablet`
    padding: 0;
    border: none;
    justify-content: flex-end;
  `}
`;

export const ToggleViewButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const ViewText = styled.p`
  font-weight: ${FontWeight.Bold};
  font-size: 11px;
  text-transform: uppercase;
  margin-right: 15px;
`;

export const SquaresIcon = styled(SquaresSvg)`
  path {
    stroke: ${() => Color.brandBasic};
  }
`;

export const ListIcon = styled(ListSvg)`
  path {
    fill: ${() => Color.brandBasic};
  }
`;

export const CalendarIcon = styled(CalendarSvg)`
  path {
    fill: ${() => Color.brandBasic};
  }
`;

export const ToggleViewButton = styled.button`
  outline: none;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${Color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px ${hexToRgba(Color.darkGray, 0.2)}, 0 0 20px ${hexToRgba(Color.almostBlack, 0.03)};

  & + & {
    margin-left: 10px;
  }

  ${styleWhenTrue(prop("active"), css`
    background-color: ${() => Color.brandBasic};

    ${SquaresIcon} {
      path {
        stroke: ${Color.white};
      }
    }
    ${ListIcon} {
      path {
        fill: ${Color.white};
      }
    }
    ${CalendarIcon} {
      path {
        fill: ${Color.white};
      }
    }
  `)}
`;

export const SearchWrapper = styled.div`
  margin-bottom: 60px;
`;
