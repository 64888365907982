import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

import { containerStyle, theme } from "./locationMap.constants";
import { Placeholder } from "./locationMap.styled";

export const LocationMap = ({ lat, lng, isMarkerShown }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDQUGV1G7FxaRp_-HeuTP22IGjaIr3D2k4"
  });

  if (!isLoaded) {
    return (
      <Placeholder />
    );
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={8}
      center={{ lat: lat || 51.919438, lng: lng || 19.145136 }}
      options={{ styles: theme }}
    >
      {isMarkerShown && (
        <Marker position={{ lat: lat || 51.919438, lng: lng || 19.145136 }} />
      )}
    </GoogleMap>
  );
};
