import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { hexToRgba } from "../../../../helpers/colors";
import { ReactComponent as CalendarSvg } from "../../../../images/calendar.svg";
import { ReactComponent as PinSvg } from "../../../../images/pin.svg";
import { Color, FontWeight, Media } from "../../../../theme";
import { Button as ButtonBase } from "../../../button/button.component";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${Media.tablet`
    flex-direction: row;
  `}
`;

export const Date = styled.p`
  background-color: ${() => hexToRgba(Color.brandBasic, 0.1)};
  color: ${() => Color.brandBasic};
  width: 100px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 17px;
  font-weight: ${FontWeight.Bold};
  font-size: 10px;
`;

export const AvailableEnrolments = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  ${Media.mobile`
    margin-top: 0;
    margin-left: 35px;
  `}

  ${Media.tabletWide`
    margin-left: 50px;
  `}
`;

export const CalendarIcon = styled(CalendarSvg)`
  width: 15px;
  height: 15px;
  fill: ${() => Color.brandBasic};
`;

export const EnrolmentsText = styled.p`
  color: ${Color.deepGray};
  font-size: 12px;
  line-height: 17px;
  margin-left: 11px;
  min-width: 100px;
`;

export const PinIcon = styled(PinSvg)`
  width: 16px;
  height: 24px;
  fill: ${() => Color.brandBasic};
`;

export const Location = styled.div`
  display: flex;
  align-items: center;

  ${Media.mobile`
    margin-left: 30px;
  `}

  ${Media.tablet`
    margin-right: 20px;
  `}

  ${Media.tabletWide`
    margin-left: 45px;
  `}
`;

export const City = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${Color.deepGray};
  margin-left: 8px;
`;

export const Button = styled(ButtonBase)`
  margin: 5px 0;

  ${Media.mobile`
    margin: 0 10px;
  `}

  ${Media.tablet`
    margin: 5px 0 0;
    width: auto;
    
    :first-of-type {
      margin-top: 0;
    }
  `}

  ${styleWhenTrue(prop("disabled"), css`
    pointer-events: none;
  `)}
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;

  ${Media.mobile`
    flex-direction: row;
  `}

  ${Media.tablet`
    margin-top: 0;
    margin-left: auto;
    flex-direction: column;
  `}
`;

export const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Media.mobile`
    flex-direction: row;
  `}
`;
