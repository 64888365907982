import styled from "styled-components";
import { Color, Media } from "../../../../theme";

export const Container = styled.div``;

export const Wrapper = styled.div`
  & + & {
    margin-top: 20px;
  }

  ${Media.tablet`
    margin-top: 40px;
  `}
`;

export const TextDiv = styled.div`
  margin-top: 10px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.17px;
  display: flex;
  align-items: center;
`;

export const Dot = styled.span`
  width: 4px;
  height: 4px;
  background-color: ${() => Color.brandBasic};
  border-radius: 50%;
  margin-right: 19px;
  display: inline-block;
  flex-shrink: 0;
`;
