/* eslint-disable @typescript-eslint/no-explicit-any */
import { prop } from 'ramda';
import { createSelector, Selector } from 'reselect';

// TODO: remove this ANY
export const selectRegistrationDomain: any = prop('registration');

export const selectSections: Selector = createSelector(
  selectRegistrationDomain, prop('sections'),
);

export const selectCompanies: Selector = createSelector(
  selectRegistrationDomain, prop('companies'),
);

export const selectDuties: Selector = createSelector(
  selectRegistrationDomain, prop('duties'),
);

export const selectAccountType: Selector = createSelector(
  selectRegistrationDomain, prop('accountType'),
);
