import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { selectUserId } from "../../../../../userContext/redux/userContext.selectors";
import { CookieName } from "../../../../../app/cookiePopup/cookiePopup.constants";
import { useLocalStorage } from "../../../../../hooks/useLocalStorage";
import { isCookieEnabled } from "../../../../../helpers/isCookieEnabled";
import { Theme } from "../../../../../appConfig";
import { CompetenceActions } from "../../../../competence/redux/competence.reducer";
import {
  selectUserCompetenceList,
  selectCompetenceSkills,
} from "../../../../competence/redux/competence.selectors";
import { CompetenceType } from "../../../teamIndicators.constants";
import { TeamIndicatorsActions } from "../../../redux/teamIndicators.reducer";
import { StrategicPopulationsItemKey, CommonPopulationsItemKey } from "../indicatorsTab.constants";

import {
  ModalContent,
  ButtonRow,
  Title,
  Button,
  PlusIcon,
  Modal,
  DropdownInput,
} from "./newChartPopup.styled";

export const NewChartPopup = ({ handleClose, competenceType, isOpen, onAddNew }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);
  const storageItemName = competenceType === CompetenceType.Strategic
    ? `${StrategicPopulationsItemKey}${userId}`
    : `${CommonPopulationsItemKey}${userId}`;
  const [storageCharts, setStorageCharts] = useLocalStorage(storageItemName);
  const [selectedCompetence, setSelectedCompetence] = useState();
  const [selectedSkill, setSelectedSkill] = useState();
  const competences = useSelector(selectUserCompetenceList);
  const filteredCompetences = competences.filter((item) => (
    item.competence.type.enum === competenceType
  )).map(({competence}) => ({
    name: competence.name,
    label: competence.name,
    id: competence.id,
  }));
  const skills = useSelector(selectCompetenceSkills);
  const formattedSkills = [
    {
      name: t("teamIndicators.newChartPopup.allMeasures"),
      label: t("teamIndicators.newChartPopup.allMeasures"),
      id: selectedCompetence ? selectedCompetence.id : null,
    },
    ...skills.map(({ skill }) => ({
      name: skill.name,
      label: skill.name,
      id: skill.id,
    }))
  ];

  const themeCompetenceType = competenceType === CompetenceType.Strategic
    ? Theme.CompetenceType : competenceType;

  const createNewChart = () => {
    dispatch(TeamIndicatorsActions.createNewChart(selectedCompetence.id, selectedSkill.id));
    const newCharts = [
      ...storageCharts,
      { competenceId: selectedCompetence.id, selectedSkill: selectedSkill.id },
    ];
    if (isCookieEnabled(CookieName.Functional)) setStorageCharts(newCharts);
    onAddNew(newCharts);
    closePopup();
  };

  const closePopup = () => {
    setSelectedCompetence();
    setSelectedSkill();
    handleClose();
  };

  const handleCompetenceChange = ({ target }) => {
    const competence = filteredCompetences.find((competence) => competence.id === target.value);
    setSelectedCompetence(competence);
    dispatch(CompetenceActions.getCompetenceSkills(competence.id));
  };

  const handleSkillChange = ({ target }) => {
    const skill = formattedSkills.find((skill) => skill.id === target.value);
    setSelectedSkill(skill);
  };

  return (
    <Modal
      title={t("teamIndicators.newChartPopup.addNewChart")}
      onClose={closePopup}
      HeaderIcon={PlusIcon}
      open={isOpen}
    >
      <ModalContent>
        <Title>{t(`teamIndicators.newChartPopup.${themeCompetenceType}`)}</Title>
        <DropdownInput
          onChange={handleCompetenceChange}
          value={selectedCompetence && selectedCompetence.name}
          valueId={selectedCompetence && selectedCompetence.id}
          placeholder={t("teamIndicators.newChartPopup.chooseFromList")}
          options={filteredCompetences}
          disabled={!filteredCompetences.length}
        />
        <Title>{t("teamIndicators.newChartPopup.competenceSkill")}</Title>
        <DropdownInput
          onChange={handleSkillChange}
          value={selectedSkill && selectedSkill.name}
          valueId={selectedSkill && selectedSkill.id}
          placeholder={t("teamIndicators.newChartPopup.chooseFromList")}
          options={formattedSkills}
          disabled={!selectedCompetence}
        />
      </ModalContent>
      <ButtonRow>
        <Button
          disabled={!selectedSkill}
          onClick={createNewChart}
        >
          {t("teamIndicators.newChartPopup.confirm")}
        </Button>
      </ButtonRow>
    </Modal>
  );
};
