import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { SectionHeader } from "../../../../theme/typography";
import { selectUpcomingEvents } from "../../redux/dashboard.selectors";
import { UpcomingEvent } from "../upcomingEvent/upcomingEvent.component";

import { Container, Events } from "./upcomingEvents.styled";

export const UpcomingEvents = ({ className }) => {
  const { t } = useTranslation();
  const upcomingEvents = useSelector(selectUpcomingEvents);

  const renderEvents = useCallback(() => (
    upcomingEvents.map((event) => (
      <UpcomingEvent
        key={event.id}
        id={event.id}
        timestamp={event.timestampStart}
        place={event.city}
        title={event.course.name}
        seatsAvailable={event.availableEnrolments}
      />
    ))
  ), [upcomingEvents]);

  if (!upcomingEvents.length) {
    return null;
  }

  return (
    <Container className={className}>
      <SectionHeader>
        {t("dashboard.dashboard.incomingCourse")}
      </SectionHeader>
      <Events>
        {renderEvents()}
      </Events>
    </Container>
  );
};


UpcomingEvents.propTypes = {
  className: PropTypes.string,
};
