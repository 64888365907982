import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

import { Modal } from "../../../../components/modal/modal.component";
import { ConfirmationModal } from "../../../../components/confirmationModal/confirmationModal.component";
import { Button } from "../../../../components/button/button.component";
import { ButtonColor, ButtonVariant } from "../../../../components/button/button.constants";
import { Icon } from "../../../../components/icon/icon.component";
import { Tooltip } from "../../../../components/tooltip/tooltip.component";
import { Loader } from "../../../../components/loader/loader.component";
import { renderWhenTrueOtherwise, renderWhenTrue } from "../../../../helpers/rendering";
import { ReactComponent as CameraIcon } from "../../images/camera.svg";
import { ReactComponent as RecordIcon } from "../../images/record.svg";
import { ReactComponent as LeaveIcon } from "../../images/leaveIcon.svg";
import { ReactComponent as FlipchartModalIcon } from "../../images/flipchart.svg";
import { WebinarsActions } from "../../redux/webinars.reducer";
import {
  selectCurrentRoom,
  selectParticipants,
  selectRoomAccess,
  selectVideoDevices,
  selectAudioDevices,
  selectCurrentVideoDevice,
  selectCurrentAudioDevice,
  selectRecording,
  selectRecordingPaused,
  selectStreamsMuted,
  selectIsCameraLoading,
  selectIsAudioLoading,
  selectDevicePermissionError,
} from "../../redux/webinars.selectors";
import { RoomStatus, RoomType } from "../../webinars.constants";
import { StreamType, StreamDirection } from "../../services/streams.constants";
import { BroadcastType } from "../video/video.constants";
import { DeviceList } from "../deviceList/deviceList.component";

import {
  Container,
  StopIcon,
  PlayIcon,
  OvalButtonWrapper,
  Circle,
  StreamButtonLabel,
  Presenter,
  DarkText,
  Buttons,
  RightSideTools,
  MediaButtonWrapper,
  PanelButton,
  ListButton,
  MicrophoneIcon,
  WideScreenButton,
  InfoPanel,
  Title,
  LiveInfo,
  ArrowIcon,
  CameraOffIcon,
  ShareIcon,
  FlipchartIcon,
  MicrophoneOffIcon,
  Separator,
  RoomsIcon,
  RoomsButton,
  ModalText,
  CloseGroupButton,
  Bold,
  SquaresIcon,
  ModalContent,
  ButtonWrapper,
  ExitIcon,
  FullScreenIcon,
  DotsIcon,
  MoreDropdown,
  DropdownOption,
  ShrinkScreenIcon,
  DropdownText,
  MoreButtonWrapper,
} from "./buttonPanel.styled";

export const ButtonPanel = ({
  className,
  fullScreen,
  onOpenStreamModal,
  onFullScreen,
  onRoomsButtonClick,
  onFlipchartToggle,
  classesMode,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const room = useSelector(selectCurrentRoom);
  const participants = useSelector(selectParticipants);
  const roomAccess = useSelector(selectRoomAccess);
  const videoDevices = useSelector(selectVideoDevices);
  const audioDevices = useSelector(selectAudioDevices);
  const currentVideoDevice = useSelector(selectCurrentVideoDevice);
  const currentAudioDevice = useSelector(selectCurrentAudioDevice);
  const recording = useSelector(selectRecording);
  const recordingPaused = useSelector(selectRecordingPaused);
  const streamsMuted = useSelector(selectStreamsMuted);
  const isCameraLoading = useSelector(selectIsCameraLoading);
  const isAudioLoading = useSelector(selectIsAudioLoading);
  const devicePermissionError = useSelector(selectDevicePermissionError);
  const inPrivateGroup = room.type === RoomType.MeetingGroup;
  const presenter = participants.find((participant) => participant.role === BroadcastType.Presenter);
  const isCurrentUserPresenter = presenter && presenter.user.isCurrentUser;
  const canToggleMedia = roomAccess.shareCamera || roomAccess.shareCameraWithPermission;
  const streamScreenButtonVisible = roomAccess.shareScreen || roomAccess.shareScreenWithPermission;
  const flipchartButtonVisible = classesMode && (roomAccess.createFlipchart || roomAccess.closeFlipchart);
  const remoteStreamsTypes = room.streams
    ? room.streams.filter((stream) => stream.direction === StreamDirection.In).map((stream) => stream.type)
    : [];
  const localStreamsTypes = room.streams
    ? room.streams.filter((stream) => stream.direction === StreamDirection.Out && !stream.muted).map((stream) => stream.type)
    : [];
  const [showCameraList, setShowCameraList] = useState(false);
  const [showAudioList, setShowAudioList] = useState(false);
  const [leaveModalVisible, setLeaveModalVisible] = useState(false);
  const [closeGroupModalVisible, setCloseGroupModalVisible] = useState(false);
  const [screenStreamModalOpen, setScreenStreamModalOpen] = useState(false);
  const [flipchartDenyModalOpen, setFlipchartDenyModalOpen] = useState(false);
  const [openingScreenStreamModalOpen, setOpeningScreenStreamModalOpen] = useState(false);
  const [closingFlipchartModalOpen, setClosingFlipchartModalOpen] = useState(false);
  const [moreDropdownOpen, setMoreDropdownOpen] = useState(false);
  const [flipchartTimeoutId, setFlipchartTimeoutId] = useState(0);
  const isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;

  const openLeaveModal = () => setLeaveModalVisible(true);
  const closeLeaveModal = () => setLeaveModalVisible(false);
  const openScreenStreamModal = () => setScreenStreamModalOpen(true);
  const closeScreenStreamModal = () => setScreenStreamModalOpen(false);
  const closeFlipchartDenyModal = () => setFlipchartDenyModalOpen(false);
  const openClosingFlipchartModal = () => setClosingFlipchartModalOpen(true);
  const closeClosingFlipchartModal = () => setClosingFlipchartModalOpen(false);
  const hideCloseGroupModal = () => setCloseGroupModalVisible(false);
  const openCloseGroupModal = () => setCloseGroupModalVisible(true);

  const closeGroup = () => {
    dispatch(WebinarsActions.closeRoom(room.id));
    hideCloseGroupModal();
  };

  useEffect(() => {
    if (flipchartTimeoutId !== 0) {
      setTimeout(() => {
        if (!localStreamsTypes.includes(StreamType.Screen)) {
          onFlipchartToggle();
          setFlipchartTimeoutId(0);
        } else {
          setFlipchartTimeoutId(Date.now());
        }
      }, 200);
    }
  }, [flipchartTimeoutId]);

  const hideMicrophoneMutedModal = () => dispatch(WebinarsActions.hideStreamMutedModal(StreamType.Audio));

  const hideCameraMutedModal = () => dispatch(WebinarsActions.hideStreamMutedModal(StreamType.Camera));

  const closeDevicePermissionErrorModal = () => dispatch(WebinarsActions.setDevicePermissionError(null));

  const goToTrainingPage = () => {
    navigate(`/szkolenia/${room.eventId}`);
  };

  const toggleCameraList = () => {
    setShowAudioList(false);
    setShowCameraList(!showCameraList);
  };

  const toggleAudioList = () => {
    setShowCameraList(false);
    setShowAudioList(!showAudioList);
  };

  const handleToggleMedia = (streamType, id) => {
    const stream = room.streams.find(
      (stream) => stream.type === streamType && stream.direction === StreamDirection.Out
    );

    if (stream) {
      const deviceId = currentVideoDevice !== id && currentAudioDevice !== id ? id : null;
      dispatch(WebinarsActions.handleToggleMedia(room, streamType, deviceId));
    } else if (roomAccess.shareCamera) {
      dispatch(WebinarsActions.createStream(streamType, id));
    } else {
      dispatch(WebinarsActions.handleSendPermissionRequest(StreamType.Camera));
    }
  };

  const handleToggleCamera = () => handleToggleMedia(StreamType.Camera, currentVideoDevice);
  const handleToggleAudio = () => handleToggleMedia(StreamType.Audio, currentAudioDevice);

  const handleChooseCamera = (id) => {
    setShowCameraList(false);
    handleToggleMedia(StreamType.Camera, id);
  };

  const handleChooseAudio = (id) => {
    setShowAudioList(false);
    handleToggleMedia(StreamType.Audio, id);
  };

  const onCreateScreenStreamButton = () => {
    if (room.flipchartId) {
      setOpeningScreenStreamModalOpen(true);
    } else {
      handleCreateScreenStream();
    }
  };

  const handleCreateScreenStream = () => {
    const localScreenStreaming = localStreamsTypes.includes(StreamType.Screen);
    const remoteScreenStreaming = remoteStreamsTypes.includes(StreamType.Screen);

    if (closingFlipchartModalOpen) closeClosingFlipchartModal();

    if (room.flipchartId) {
      onFlipchartToggle();
    }

    if (room.type === RoomType.MeetingGroup) {
      if (remoteScreenStreaming) {
        openScreenStreamModal();
      } else {
        if (!localScreenStreaming) {
          dispatch(WebinarsActions.createStream(StreamType.Screen));
        } else {
          dispatch(WebinarsActions.handleToggleMedia(room, StreamType.Screen));
        }
      }
    } else {
      if (localScreenStreaming) {
        dispatch(WebinarsActions.handleToggleMedia(room, StreamType.Screen));
      } else {
        if (isCurrentUserPresenter || (roomAccess.shareScreen && !remoteScreenStreaming)) {
          dispatch(WebinarsActions.createStream(StreamType.Screen));
        } else {
          dispatch(WebinarsActions.handleSendPermissionRequest(StreamType.Screen));
        }
      }
    }
  };

  const handleRecordClick = () => {
    if (recording ? recordingPaused : true) {
      dispatch(WebinarsActions.handleStartRecording());
    } else {
      dispatch(WebinarsActions.handlePauseRecording());
    }
  };

  const handleLeaveButton = () => {
    if (inPrivateGroup) {
      if (isCurrentUserPresenter) {
        const { id } = room.parent;
        navigate(`/webinar/${id}`, { replace: true });
        dispatch(WebinarsActions.handleLeaveRoom());
        dispatch(WebinarsActions.joinRoom(id));
        closeLeaveModal();
      } else {
        dispatch(WebinarsActions.handleLeaveGroup());
      }
    } else {
      goToTrainingPage();
    }
  };

  const handleFlipchartToggle = () => {
    setMoreDropdownOpen(false);

    if (remoteStreamsTypes.includes(StreamType.Screen)) {
      setFlipchartDenyModalOpen(true);
    } else {
      if (localStreamsTypes.includes(StreamType.Screen)) {
        dispatch(WebinarsActions.handleToggleMedia(room, StreamType.Screen));
        setFlipchartTimeoutId(Date.now());
      } else {
        if (room.flipchartId) {
          openClosingFlipchartModal();
        } else {
          onFlipchartToggle();
        }
      }
    }
  };

  const renderStreamButton = renderWhenTrue(() => {
    if (room.status === RoomStatus.Ended
      || (room.live && !roomAccess.endLive)
      || (!room.live && !roomAccess.startLive)
    ) return null;
    const label = room.live ? t("webinars.stopStreaming") : t("webinars.startStreaming");
    const icon = room.live ? <StopIcon /> : <PlayIcon />;

    return (
      <OvalButtonWrapper>
        <Circle onClick={onOpenStreamModal} stopButton={room.live}>
          {icon}
        </Circle>
        <StreamButtonLabel>{label}</StreamButtonLabel>
      </OvalButtonWrapper>
    );
  });

  const renderPresenterName = renderWhenTrue(() => (
    <Presenter fullScreen={fullScreen}>
      {t("webinars.chat.presenter")}:
      {" "}
      <DarkText>{presenter.name}</DarkText>
    </Presenter>
  ));

  const renderStartButton = renderWhenTrueOtherwise(
    renderStreamButton,
    () => renderPresenterName(!!presenter && fullScreen),
  );

  const renderCameraList = renderWhenTrue(() => {
    const devices = videoDevices.filter((device) => !!device.deviceId);
    return (
      <DeviceList
        devices={devices}
        onChooseDevice={handleChooseCamera}
        label={t("webinars.chooseCamera")}
        currentDeviceId={currentVideoDevice}
      />
    );
  });

  const renderAudioList = renderWhenTrue(() => {
    const devices = audioDevices.filter((device) => !!device.deviceId);
    return (
      <DeviceList
        devices={devices}
        onChooseDevice={handleChooseAudio}
        label={t("webinars.chooseMicrophone")}
        currentDeviceId={currentAudioDevice}
      />
    )
  });

  const renderToggleMediaButtons = renderWhenTrue(() => {
    const cameraDetected = !!videoDevices.length;
    const cameraRegistered = !!videoDevices.filter((device) => !!device.deviceId).length;
    const cameraOn = localStreamsTypes.includes(StreamType.Camera);
    const cameraIcon = cameraDetected && cameraOn ? <CameraIcon /> : <CameraOffIcon />;
    const cameraTooltipText = cameraDetected ? t("webinars.toggleCamera") : t("webinars.noCameraDetected");
    const audioDetected = !!audioDevices.length;
    const audioRegistered = !!audioDevices.filter((device) => !!device.deviceId).length;
    const audioOn = localStreamsTypes.includes(StreamType.Audio);
    const audioIcon = audioDetected && audioOn ? <MicrophoneIcon /> : <MicrophoneOffIcon />;
    const audioTooltipText = audioDetected ? t("webinars.toggleMicrophone") : t("webinars.noAudioDetected");

    return (
      <>
        <MediaButtonWrapper>
          <Tooltip title={cameraTooltipText}>
            <PanelButton
              onClick={handleToggleCamera}
              deactivated={!cameraDetected}
              disabled={isCameraLoading}
            >
              <Icon>
                {isCameraLoading ? <Loader size={22} /> : cameraIcon}
              </Icon>
            </PanelButton>
          </Tooltip>
          <ListButton onClick={toggleCameraList} small disabled={!cameraRegistered}>
            <Icon>
              <ArrowIcon open={showCameraList} deactivated={(!cameraRegistered).toString()} />
            </Icon>
          </ListButton>
          {renderCameraList(showCameraList)}
        </MediaButtonWrapper>
        <MediaButtonWrapper>
          <Tooltip title={audioTooltipText}>
            <PanelButton
              onClick={handleToggleAudio}
              deactivated={!audioDetected}
              disabled={isAudioLoading}
            >
              <Icon>
                {isAudioLoading ? <Loader size={22} /> : audioIcon}
              </Icon>
            </PanelButton>
          </Tooltip>
          <ListButton onClick={toggleAudioList} small disabled={!audioRegistered}>
            <Icon>
              <ArrowIcon open={showAudioList} deactivated={(!audioRegistered).toString()} />
            </Icon>
          </ListButton>
          {renderAudioList(showAudioList)}
        </MediaButtonWrapper>
      </>
    );
  });

  const renderRecordButton = renderWhenTrue(() => (
    <Tooltip title={t("webinars.record")}>
      <PanelButton deactivated={recordingPaused} onClick={handleRecordClick}>
        <Icon>
          <RecordIcon />
        </Icon>
      </PanelButton>
    </Tooltip>
  ));

  const toggleBrowserFullscreen = () => {
    setMoreDropdownOpen(false);
    let element = document.body;

    if (event instanceof HTMLElement) {
      element = event;
    }

    element.requestFullScreen = element.requestFullScreen
      || element.webkitRequestFullScreen
      || element.mozRequestFullScreen
      || function() {
        return false;
      };

    document.cancelFullScreen = document.cancelFullScreen
      || document.webkitCancelFullScreen
      || document.mozCancelFullScreen
      || function() {
        return false;
      };

    isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
  };

  const toggleMoreDropdown = () => {
    setMoreDropdownOpen(!moreDropdownOpen);
  };

  const hideMoreDropdown = () => {
    setMoreDropdownOpen(false);
  };

  const renderMoreButton = renderWhenTrue(() => (
    <ClickAwayListener onClickAway={hideMoreDropdown}>
      <MoreButtonWrapper>
        <Tooltip title={t("webinars.buttonPanel.options")}>
          <WideScreenButton onClick={toggleMoreDropdown}>
            <Icon>
              <DotsIcon />
            </Icon>
          </WideScreenButton>
        </Tooltip>
        <MoreDropdown open={moreDropdownOpen} onClick={(e) => e.stopPropagation()}>
          <DropdownOption selected={isFullscreen} onClick={toggleBrowserFullscreen}>
            {isFullscreen ? (
              <>
                <ShrinkScreenIcon />
                <DropdownText>
                  {t("webinars.buttonPanel.closeFullScreen")}
                </DropdownText>
              </>
            ) : (
              <>
                <FullScreenIcon />
                <DropdownText>
                  {t("webinars.buttonPanel.fullScreen")}
                </DropdownText>
              </>
            )}
          </DropdownOption>
          {flipchartButtonVisible && (
            <DropdownOption selected={!!room.flipchartId} onClick={handleFlipchartToggle}>
              <FlipchartIcon />
              <DropdownText>
                {t(`webinars.buttonPanel.${room.flipchartId ? "closeFlipchart" : "openFlipchart"}`)}
              </DropdownText>
            </DropdownOption>
          )}
        </MoreDropdown>
      </MoreButtonWrapper>
    </ClickAwayListener>
  ));

  const renderStreamScreenButton = renderWhenTrue(() => {
    const screenStreamOn = localStreamsTypes.includes(StreamType.Screen);

    return (
      <Tooltip title={t("webinars.shareScreen")}>
        <WideScreenButton onClick={onCreateScreenStreamButton}>
          <Icon>
            <ShareIcon deactivated={(!screenStreamOn).toString()} />
          </Icon>
        </WideScreenButton>
      </Tooltip>
    );
  });

  const renderInfoPanel = () => (
    <InfoPanel fullScreen={fullScreen}>
      <Title fullScreen={fullScreen}>
        <LiveInfo isLive={room.live}>{t("webinars.live")}</LiveInfo>
        {room.name}
      </Title>
      {renderPresenterName(!!presenter && !fullScreen && !classesMode)}
    </InfoPanel>
  );

  const renderWideScreenButton = renderWhenTrue(() => (
    <Tooltip title={t("webinars.fullscreen")}>
      <WideScreenButton onClick={onFullScreen}>
        <Icon>
          <FullScreenIcon />
        </Icon>
      </WideScreenButton>
    </Tooltip>
  ));

  const renderShowRoomsButton = renderWhenTrue(() => (
    <>
      <Separator />
      <Tooltip title={t("webinars.classes.manageRooms")}>
        <RoomsButton onClick={onRoomsButtonClick}>
          {t("webinars.rooms")}
          <RoomsIcon />
        </RoomsButton>
      </Tooltip>
    </>
  ));

  const renderLeaveMeetingButton = renderWhenTrue(() => (
    <>
      <Separator />
      <Button
        color={inPrivateGroup ? ButtonColor.Default : ButtonColor.Primary}
        onClick={openLeaveModal}
      >
        {t(`webinars.${inPrivateGroup ? "leaveGroup" : "leaveMeeting"}`)}
      </Button>
      <ConfirmationModal
        open={leaveModalVisible}
        onConfirm={handleLeaveButton}
        onDecline={closeLeaveModal}
        confirmText={inPrivateGroup ? t("webinars.leaveGroup") : t("webinars.classes.leave")}
        declineText={t("global.cancel")}
        title={inPrivateGroup ? t("webinars.leaveGroup") : t("webinars.classes.leavingMeeting")}
        HeaderIcon={LeaveIcon}
      >
        <ModalText>
          {inPrivateGroup ? (
            <>
              {t("webinars.wantToLeaveGroup")}
              {" "}
              <Bold>{room.name}</Bold>?
            </>
          ) : (
            <>
              {t("webinars.wantToLeaveMeeting")}
            </>
          )}
        </ModalText>
      </ConfirmationModal>
    </>
  ));

  const renderCameraMutedModal = () => (
    <Modal
      title={t("webinars.buttonPanel.cameraMuted")}
      HeaderIcon={CameraOffIcon}
      open={streamsMuted.includes(StreamType.Camera)}
      onClose={hideCameraMutedModal}
    >
      <ModalContent>
        {t("webinars.buttonPanel.mutedCameraInfo")}
      </ModalContent>
      <ButtonWrapper>
        <Button onClick={hideCameraMutedModal} color={ButtonColor.Primary}>
          {t("webinars.buttonPanel.close")}
        </Button>
      </ButtonWrapper>
    </Modal>
  );

  const renderMicrophoneMutedModal = () => (
    <Modal
      title={t("webinars.buttonPanel.microphoneMuted")}
      HeaderIcon={MicrophoneOffIcon}
      open={streamsMuted.includes(StreamType.Audio)}
      onClose={hideMicrophoneMutedModal}
    >
      <ModalContent>
        {t("webinars.buttonPanel.mutedMicrophoneInfo")}
      </ModalContent>
      <ButtonWrapper>
        <Button onClick={hideMicrophoneMutedModal} color={ButtonColor.Primary}>
          {t("webinars.buttonPanel.close")}
        </Button>
      </ButtonWrapper>
    </Modal>
  );

  const renderCloseGroupButton = renderWhenTrue(() => (
    <>
      <CloseGroupButton
        onClick={openCloseGroupModal}
        variant={ButtonVariant.Outlined}
      >
        {t("webinars.buttonPanel.closeGroup")}
      </CloseGroupButton>
      <ConfirmationModal
        open={closeGroupModalVisible}
        onConfirm={closeGroup}
        onDecline={hideCloseGroupModal}
        confirmText={t("webinars.buttonPanel.closeGroup")}
        declineText={t("global.cancel")}
        title={t("webinars.buttonPanel.closeGroup")}
        HeaderIcon={SquaresIcon}
      >
        {t("webinars.buttonPanel.wantToCloseRoom")}
        {" "}
        <Bold>{room.name}</Bold>?
      </ConfirmationModal>
    </>
  ));

  const renderOpeningScreenStreamModal = () => (
    <ConfirmationModal
      onConfirm={handleCreateScreenStream}
      onDecline={() => setOpeningScreenStreamModalOpen(false)}
      onClose={() => setOpeningScreenStreamModalOpen(false)}
      title={t("webinars.buttonPanel.screenSharing")}
      open={openingScreenStreamModalOpen}
      HeaderIcon={ShareIcon}
      confirmText={t("webinars.buttonPanel.share")}
      declineText={t("global.cancel")}
    >
      {t("webinars.buttonPanel.screenStreamingQuestion")}
    </ConfirmationModal>
  );

  const renderClosingFlipchartModal = () => (
    <ConfirmationModal
      title={t("webinars.buttonPanel.turningOffFlipchart")}
      onConfirm={() => {
        dispatch(WebinarsActions.closeFlipchart());
        setClosingFlipchartModalOpen(false);
      }}
      onDecline={() => setClosingFlipchartModalOpen(false)}
      onClose={() => setClosingFlipchartModalOpen(false)}
      open={closingFlipchartModalOpen}
      confirmText={t("webinars.classes.turnOff")}
      declineText={t("global.cancel")}
      HeaderIcon={FlipchartModalIcon}
    >
      {t("webinars.classes.closeFlipchartQuestion")}
    </ConfirmationModal>
  );

  const renderScreenStreamModal = () => (
    <Modal
      title={t("webinars.buttonPanel.optionBlocked")}
      HeaderIcon={ExitIcon}
      open={screenStreamModalOpen}
      onClose={closeScreenStreamModal}
    >
      <ModalContent>
        {t("webinars.buttonPanel.blockedStreamingReason")}
      </ModalContent>
      <ButtonWrapper>
        <Button onClick={closeScreenStreamModal} color={ButtonColor.Primary}>
          {t("webinars.buttonPanel.close")}
        </Button>
      </ButtonWrapper>
    </Modal>
  );

  const renderFlipchartDenyModal = () => (
    <Modal
      title={t("webinars.buttonPanel.optionBlocked")}
      HeaderIcon={ExitIcon}
      open={flipchartDenyModalOpen}
      onClose={closeFlipchartDenyModal}
    >
      <ModalContent>
        {t("webinars.buttonPanel.blockedFlipchartReason")}
      </ModalContent>
      <ButtonWrapper>
        <Button onClick={closeFlipchartDenyModal} color={ButtonColor.Primary}>
          {t("webinars.buttonPanel.close")}
        </Button>
      </ButtonWrapper>
    </Modal>
  );

  const renderDevicePermissionErrorModal = () => (
    <Modal
      title={t("webinars.buttonPanel.optionBlocked")}
      HeaderIcon={ExitIcon}
      open={!!devicePermissionError}
      onClose={closeDevicePermissionErrorModal}
    >
      <ModalContent>
        {devicePermissionError === StreamType.Screen
          ? t("webinars.buttonPanel.screenShareErrorReason")
          : devicePermissionError === StreamType.Camera
            ? t("webinars.buttonPanel.cameraDeviceErrorReason")
            : t("webinars.buttonPanel.audioDeviceErrorReason")
        }
      </ModalContent>
      <ButtonWrapper>
        <Button onClick={closeDevicePermissionErrorModal} color={ButtonColor.Primary}>
          {t("webinars.buttonPanel.close")}
        </Button>
      </ButtonWrapper>
    </Modal>
  );

  return (
    <Container className={className} fullScreen={fullScreen}>
      <Buttons>
        {renderStartButton(!classesMode && isCurrentUserPresenter)}
        <RightSideTools hidden={room.status === RoomStatus.Ended && room.type !== RoomType.MeetingGroup}>
          {renderRecordButton(recording && isCurrentUserPresenter)}
          {renderToggleMediaButtons(classesMode ? canToggleMedia && room.live : canToggleMedia)}
          {renderStreamScreenButton(classesMode ? streamScreenButtonVisible && room.live : streamScreenButtonVisible)}
          {renderWideScreenButton(!classesMode)}
          {renderMoreButton(classesMode)}
          {renderShowRoomsButton(classesMode && isCurrentUserPresenter && room.live)}
        </RightSideTools>
        {renderLeaveMeetingButton(classesMode)}
        {renderCloseGroupButton(inPrivateGroup && isCurrentUserPresenter)}
      </Buttons>
      {classesMode && isCurrentUserPresenter
        ? renderStreamButton(room.type !== RoomType.MeetingGroup)
        : renderInfoPanel()
      }
      {renderScreenStreamModal()}
      {renderFlipchartDenyModal()}
      {renderMicrophoneMutedModal()}
      {renderCameraMutedModal()}
      {renderOpeningScreenStreamModal()}
      {renderClosingFlipchartModal()}
      {renderDevicePermissionErrorModal()}
    </Container>
  );
};

ButtonPanel.propTypes = {
  className: PropTypes.string,
  fullScreen: PropTypes.bool,
  onOpenStreamModal: PropTypes.func,
  onFullScreen: PropTypes.func,
  onRoomsButtonClick: PropTypes.func,
};
