import styled from "styled-components";
import { hexToRgba } from "../../../../helpers/colors";

import { Color, FontWeight } from "../../../../theme";
import { ReactComponent as StripesSvg } from "../../../../images/stripes.svg";

export const Container = styled.button`
  display: flex;
  width: 160px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: ${() => Color.brandBasic};
  box-shadow: 0 0 20px ${() => hexToRgba(Color.brandBasic, 0.03)};
  border-radius: 3px;
`;

export const StripesIcon = styled(StripesSvg)`
  height: 15px;
  flex-shrink: 0;

  path {
    fill: ${Color.white};
  }
`;

export const Label = styled.span`
  text-transform: uppercase;
  font-size: 10px;
  font-weight: ${FontWeight.Bold};
  margin-right: 10px;
  color: ${Color.white};
`;
