import styled, { css } from "styled-components";
import { propEq } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color } from "../../../../theme";

import { ColorType } from "./bulletList.constants";

export const Bullet = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${() => Color.brandBasic};
  margin: 9px 15px 0 0;
  flex-shrink: 0;

  ${styleWhenTrue(propEq(ColorType.Gray, "colorType"), css`
    background-color: ${Color.gray9};
  `)}
`;

export const ListItem = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 22px;
  color: ${Color.gray9};

  &:not(:first-of-type) {
    margin-top: 10px;
  }
`;
