import React, { useCallback, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Color, SectionHeader } from "../../theme";
import { renderWhenTrue } from "../../helpers/rendering";
import { ChartIcon, ChartUnit } from "../progressChart/progressChart.constants";
import { ChartColorArray } from "../ovalProgressChart/ovalProgressChart.constants";

import { StatisticsBox } from "./components/statisticsBox/statisticsBox.component";
import { AwardsLabelKey } from "./userStatistics.constants";
import {
  Container,
  ProgressChartsBox,
  ProgressChart,
  PrevButton,
  NextButton,
  ArrowSvg,
} from "./userStatistics.styled";

export const UserStatistics = ({
  className,
  learningProgress,
  awards,
  iddStats,
  statistics,
}) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);

  const chartsData = useMemo(() => {
    const data = [];

    if (iddStats) {
      data.push({
        key: "idd",
        value: iddStats.collectedMinutes || 0,
        unit: ChartUnit.Minutes,
        data: [{
          title: t("components.userStatistics.minutesCollected"),
          value: iddStats.collectedMinutes,
          color: Color.maroon,
        }, {
          title: t("components.userStatistics.minutesTotal"),
          value: iddStats.totalMinutes - iddStats.collectedMinutes,
          color: ChartColorArray[1],
        }],
      });
    }

    if (awards.length && !!awards[0].total) {
      data.push({
        key: "awards",
        icon: ChartIcon.GoldMedal,
        unit: ChartUnit.Percent,
        data: awards.map((award, index) => {
          return {
            title: t(AwardsLabelKey[index]),
            value: Math.round(award.percent * 100),
            color: ChartColorArray[index],
          };
        }),
      });
    } else {
      data.push({
        key: "awards",
        icon: ChartIcon.GoldMedal,
        data: [{ title: "", value: 1, color: ChartColorArray[1] }],
        placeholderVisible: true,
      });
    }

    return data;
  }, [learningProgress, awards, iddStats, t]);

  const handleOnPrevClick = useCallback(() => {
    setActiveIndex(activeIndex - 1);
  }, [activeIndex]);

  const handleOnNextClick = useCallback(() => {
    setActiveIndex(activeIndex + 1);
  }, [activeIndex]);

  const renderPrevButton = renderWhenTrue(() => (
    <PrevButton onClick={handleOnPrevClick}>
      <ArrowSvg />
    </PrevButton>
  ));

  const renderNextButton = renderWhenTrue(() => (
    <NextButton onClick={handleOnNextClick}>
      <ArrowSvg />
    </NextButton>
  ));

  const renderProgressCharts = useCallback(() => (
    chartsData.slice(activeIndex, activeIndex + 1).map((chart) => (
      <ProgressChart
        key={chart.key}
        label={chart.label}
        value={chart.value}
        type={chart.type}
        color={chart.color}
        icon={chart.icon}
        unit={chart.unit}
        data={chart.data}
        placeholderVisible={chart.placeholderVisible}
      />
    ))
  ), [chartsData, activeIndex]);

  if (!awards.length) {
    return null;
  }

  return (
    <Container className={className}>
      <SectionHeader>
        {t("components.userStatistics.statistics")}
      </SectionHeader>
      <ProgressChartsBox>
        {renderProgressCharts()}
        {renderPrevButton(!!activeIndex)}
        {renderNextButton(activeIndex + 1 < chartsData.length)}
      </ProgressChartsBox>
      <StatisticsBox statistics={statistics} />
    </Container>
  );
};

UserStatistics.propTypes = {
  className: PropTypes.string,
  learningProgress: PropTypes.number,
  awards: PropTypes.array,
  iddStats: PropTypes.shape({
    collectedMinutes: PropTypes.number,
    progress: PropTypes.number,
    totalMinutes: PropTypes.number,
  }),
  statistics: PropTypes.shape({
    surveyStatistics: PropTypes.object,
    lessonStatistics: PropTypes.object,
    certificateStatistics: PropTypes.object,
  })
};
