import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Media } from "../../../../theme/media";
import { Color } from "../../../../theme/colors";
import { FontWeight } from "../../../../theme/fonts";
import { hexToRgba } from "../../../../helpers/colors";
import { styleWhenTrue } from "../../../../helpers/rendering";

export const Container = styled.div`
  width: 100%;
  min-height: 230px;
  background-color: ${Color.white};
  box-shadow: 0 5px 10px 0 ${hexToRgba(Color.deepGray, 0.01)};
  padding: 35px 30px 27px;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  ${Media.tablet`
    width: calc((100% - 13px) / 3);
    margin-left: 20px;
    margin-bottom: 0;
  `}
`;

export const DateInfo = styled.p`
  display: inline-block;
  background-color: ${() => Color.brandBasic};
  color: ${Color.white};
  font-size: 10px;
  line-height: 20px;
  border-radius: 14px;
  padding: 4px 16px;
  font-weight: ${FontWeight.Bold};
  margin-bottom: 18px;
`;

export const Title = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: ${Color.deepGray};
  margin-bottom: 30px;

  ${Media.tablet`
    margin-bottom: 48px;
  `}
`;

export const Infos = styled.div`
  position: absolute;
  bottom: 27px;
  left: 30px;
`;

export const Info = styled.p`
  margin-top: 2px;
  font-size: 12px;
  line-height: 22px;
  color: ${Color.deepGray};

  svg {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    fill: ${() => Color.brandBasic};
  }

  ${styleWhenTrue(prop("hidden"), css`
    display: none;
  `)}
`;
