import React from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useNavigate } from "react-router";

import { EntryType } from "../../../../../components/articleForm/articleForm.constants";
import { Avatar } from "../../../../../components/avatar/avatar.component";

import {
  Container,
  TitleWrapper,
  AvatarWrapper,
  TextWrapper,
  Title,
  Type,
  Subtitle,
  Author,
  Date,
  SocialWrapper,
  ArrowIcon,
  LikeIcon,
  CommentIcon,
  Counter,
  Row,
} from "./newsElement.styled";

export const NewsElement = ({
  id,
  publisher,
  timestamp,
  likeCounter,
  commentCounter,
  title,
  type,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container onClick={() => navigate(`/gamification/news/${id}`)}>
      <TitleWrapper>
        <AvatarWrapper>
          <Avatar
            src={publisher.avatar}
            name={`${publisher.firstName} ${publisher.lastName}`}
            dark={publisher.isCurrentUser}
          />
        </AvatarWrapper>
        <TextWrapper>
          <Title>
            <Type>
              {type === EntryType.Article
                ? t("gamification.challengeList.article")
                : t("gamification.challengeList.post")
              }
            </Type>
            {title}
          </Title>
          <Subtitle>
            <Author>{`${publisher.firstName} ${publisher.lastName}`}</Author>
            <Date>{dayjs.unix(timestamp).format("DD.MM.YYYY, HH:mm")}</Date>
          </Subtitle>
        </TextWrapper>
      </TitleWrapper>
      <Row>
        <SocialWrapper>
          <LikeIcon />
          <Counter>{likeCounter}</Counter>
          <CommentIcon />
          <Counter>{commentCounter}</Counter>
        </SocialWrapper>
        <ArrowIcon />
      </Row>
    </Container>
  );
};
