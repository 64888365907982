import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  DefaultButtonStyles,
  OutlinedButtonStyles,
} from "../../../../components/button/button.styled";
import { Button } from "../../../../components/button/button.component";
import { BackButton as BackButtonBase } from "../../../../components/backButton/backButton.component";
import { ReactComponent as StarSvg } from "../../../../images/star.svg";
import {
  Media,
  FontWeight,
  Color,
  Separator as SeparatorBase,
  ContentMaxWidth,
} from "../../../../theme";

export const Container = styled.div`
  padding: 30px 20px 20px;

  ${Media.tablet`
    padding: 30px 25px 40px;
  `}

  ${Media.desktop`
    padding: 30px 80px 100px;
  `}
`;

export const Wrapper = styled.div`
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StarIcon = styled(StarSvg)`
  margin-right: 15px;
  width: 20px;
  height: 20px;
  background-color: ${() => Color.brandBasic};
  border-radius: 50%;
  padding: 5px;
  fill: ${Color.white};
`;

export const Title = styled.p`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.6px;
  font-weight: ${FontWeight.Bold};
  text-transform: uppercase;
`;

export const HeaderSeparator = styled(SeparatorBase)`
  && {
    margin: 26px 0 30px;
  }
`;

export const QuestionWrapper = styled.div``;

export const QuestionSeparator = styled(SeparatorBase)`
  && {
    margin: 50px 0 30px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;

  ${Media.mobile`
    flex-direction: row-reverse;
  `}
`;

export const CancelLink = styled(Link)`
  ${DefaultButtonStyles}
  ${OutlinedButtonStyles}
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SendButton = styled(Button)`
  margin-bottom: 20px;

  ${Media.mobile`
    margin-left: 20px;
    margin-bottom: 0;
  `}
`;

export const BackButton = styled(BackButtonBase)`
  margin-bottom: 50px;
`;
