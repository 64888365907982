import { handleApiError, phpApi } from "../../../api";

export const getThumbnailListsAndTags = () => {
  return phpApi.get("/api/v2/learning").catch(handleApiError);
};

export const getCompetenceItems = () => {
  return phpApi.get("/api/competence/items").catch(handleApiError);
};

export const getTagFilters = () => {
  return phpApi.get("/api/competence").catch(handleApiError);
};
