import styled from "styled-components";

import { Color, FontWeight } from "../../../theme";

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: ${Color.deepGray};
`;

export const Label = styled.span`
  color: ${Color.white};
  font-size: 12px;
  line-height: 16px;
  font-weight: ${FontWeight.Bold};
`;

export const Value = styled(Label)`
  font-weight: ${FontWeight.Normal};
  color: ${Color.gray1};
  margin-left: 4px;
`;

export const VerticalSeparator = styled.div`
  width: 1px;
  height: 9px;
  background-color: ${Color.gray1};
  margin: 0 10px;
`;
