import styled from "styled-components";

import { ReactComponent as BaseCloseIcon } from "../../../../images/close.svg";
import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.div`
  padding: 120px 20px 50px;

  ${Media.tablet`
    padding: 147px 80px 80px 50px;
  `}
`;

export const CloseButton = styled.button`
  padding: 15px;
  background-color: ${Color.transparent};
  border: none;
  position: absolute;
  top: 15px;
  right: 15px;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.5;
  }
`;

export const CloseIcon = styled(BaseCloseIcon)`
  width: 22px;
  height: 22px;
  
  g {
    stroke: ${Color.black};
  }
`;

export const Title = styled.h3`
  font-size: 33px;
  font-weight: ${FontWeight.Light};
  margin-bottom: 60px;
  margin-right: 20px;
  color: ${Color.black};
`;

export const List = styled.ul``;

export const OptionWrapper = styled.li``;

export const OptionButton = styled.button`
  display: flex;
  text-align: left;
  border: none;
  background-color: ${Color.transparent};
  transition: opacity 0.2s ease-in-out;
  margin-bottom: 28px;

  &:hover {
    opacity: 0.5;
  }
`;

export const OptionTitle = styled.p`
  font-size: 14px;
  line-height: 20px;
`;

export const OptionId = styled(OptionTitle)`
  width: 34px;
`;
