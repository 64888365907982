import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

import { renderWhenTrue } from "../../helpers/rendering";
import { Loader } from "../loader/loader.component";
import { InputStyle } from "../input/input.constants";

import {
  Container,
  InputButton,
  OptionButton,
  ArrowWrapper,
  ArrowIcon,
  OptionsWrapper,
  Options,
  OptionText,
  CheckIcon,
  MultipleCheckIcon,
  SearchWrapper,
  SearchField,
  SearchIcon,
  SelectedLabel,
  EmptyMessage,
} from "./dropdownInput.styled";

export const DropdownInput = ({
  className,
  options,
  value,
  valueId,
  name,
  placeholder,
  onChange,
  onOpen = Function.prototype,
  withSearchField,
  disabled,
  small = false,
  multiple,
  dropdownHeight,
  styleType = InputStyle.Default,
  loadingFinished,
  emptyMessage,
}) => {
  const { t } = useTranslation();
  const selected = multiple
    ? options.filter((option) => value.includes(option.label))
    : options.find((option) => option.id === valueId);
  const selectedLabel = selected ? selected.label : placeholder;
  const multipleSelectedLabel = selected && selected.length
    ? selected.map((item) => item.label).toString().replaceAll(",", ", ")
    : placeholder;
  const label = multiple ? multipleSelectedLabel : selectedLabel;
  const showPlaceholder = multiple
    ? value && !value.length
    : !value;
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleOpen = () => {
    if (!open) {
      onOpen();
    }
    setOpen(!open);
  };

  const handleClose = () => setOpen(false);

  const handleSelect = (option) => {
    if (!multiple) {
      setOpen(false);
    }

    setSearchValue("");
    onChange({
      target: {
        name,
        value: option.id,
      },
    });
  };

  const onSearchValueChange = ({ target }) => {
    setSearchValue(target.value);
  };

  const filterOptions = (option) => {
    if (searchValue) {
      return option.label.toLowerCase().includes(searchValue.toLowerCase());
    } else {
      return option;
    }
  };

  const renderSearchField = renderWhenTrue(() => (
    <SearchWrapper>
      <SearchIcon />
      <SearchField
        type="text"
        placeholder={t("dropdownInput.search")}
        onChange={onSearchValueChange}
        autoFocus
      />
    </SearchWrapper>
  ));

  const renderLoader = renderWhenTrue(() => <Loader />);

  const renderItems = renderWhenTrue(() => options.filter(filterOptions).map((option) => {
    const isSelected = multiple ? value.includes(option.label) : option.id === valueId;
    const Check = multiple ? MultipleCheckIcon : CheckIcon;
    return (
      <OptionButton
        key={option.id}
        onClick={() => handleSelect(option)}
        selected={isSelected}
        type="button"
      >
        <Check selected={isSelected} />
        <OptionText small={small}>{option.label}</OptionText>
      </OptionButton>
    );
  }));

  const renderOptions = renderWhenTrue(() => (
    <OptionsWrapper dropdownHeight={dropdownHeight} $styleType={styleType}>
      {renderSearchField(withSearchField)}
      <Options>
        {renderItems(!!options.length)}
        {renderLoader(!options.length && !loadingFinished)}
        {!options.length && loadingFinished && (
          <EmptyMessage small={small}>
            <OptionText small={small}>{emptyMessage}</OptionText>
          </EmptyMessage>
        )}
      </Options>
    </OptionsWrapper>
  ));

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Container
        className={className}
        disabled={disabled}
        small={small}
        $styleType={styleType}
      >
        <ArrowWrapper small={small}>
          <ArrowIcon open={open} small={small.toString()} />
        </ArrowWrapper>
        <InputButton
          type="button"
          onClick={handleOpen}
          disabled={!value && open}
          small={small}
          showPlaceholder={showPlaceholder}
        >
          <SelectedLabel $styleType={styleType} disabled={disabled}>
            {label}
          </SelectedLabel>
        </InputButton>
        {renderOptions(open)}
      </Container>
    </ClickAwayListener>
  );
};

DropdownInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  valueId: PropTypes.number,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  onOpen: PropTypes.func,
  withSearchField: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  multiple: PropTypes.bool,
  styleType: PropTypes.string,
};
