import React from "react";
import { useTranslation } from "react-i18next";

import { Container } from "./questionTypeError.styled";

const QuestionTypeError = () => {
  const { t } = useTranslation();

  return (
    <Container>
      {t("interactions.quizQuestions.error")}
    </Container>
  );
};

export default QuestionTypeError;
