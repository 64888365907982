import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DropdownInput } from "../../../../components/dropdownInput/dropdownInput.component";
import { currentLanguage } from "../../../../helpers/translations";
import { selectCategories, selectDateRange, selectTags } from "../../redux/trainings.selectors";

import {
  Container,
  Header,
  InputRow,
  TextInputWrapper,
  Input,
  DropdownWrapper,
  DropdownInputs,
  DateInputs,
  DateInput,
  DateSeparator,
  DateInputIcon,
  PrevArrowIcon,
  NextArrowIcon,
  SearchIcon,
} from "./search.styled";

export const Search = ({
  searchText,
  selectedCategories,
  selectedTypes,
  fromDate,
  toDate,
  onSearchTextChange,
  onCategoryChange,
  onTypeChange,
  onFromDateChange,
  onToDateChange,
}) => {
  const { t } = useTranslation();
  const categories = useSelector(selectCategories);
  const types = useSelector(selectTags);
  const dateRange = useSelector(selectDateRange);
  const categoryOptions = categories.map((category) => ({
    ...category,
    label: category.name,
  }));
  const typeOptions = types.map((type) => ({
    ...type,
    label: type.name,
  }));
  const dateRangeFrom = new Date(dateRange.from);
  const dateRangeTo = new Date(dateRange.to);

  const handleSearchTextChange = ({ target }) => {
    onSearchTextChange(target.value);
  };

  const handleCategoryChange = ({ target }) => {
    if (selectedCategories.find((category) => category.id === target.value)) {
      onCategoryChange(selectedCategories.filter((category) => category.id !== target.value));
    } else {
      onCategoryChange([...selectedCategories, categoryOptions.find((category) => category.id === target.value)]);
    }
  };

  const handleTrainingTypeChange = ({ target }) => {
    if (selectedTypes.find((type) => type.id === target.value)) {
      onTypeChange(selectedTypes.filter((type) => type.id !== target.value));
    } else {
      onTypeChange([...selectedTypes, typeOptions.find((type) => type.id === target.value)]);
    }
  };

  return (
    <Container>
      <Header>{t("trainings.search.search")}</Header>
      <InputRow>
        <TextInputWrapper>
          <SearchIcon />
          <Input
            placeholder={t("trainings.search.searchText")}
            value={searchText}
            onChange={handleSearchTextChange}
          />
        </TextInputWrapper>
      </InputRow>
      <InputRow>
        <DropdownInputs>
          <DropdownWrapper>
            <DropdownInput
              name="category"
              onChange={handleCategoryChange}
              value={selectedCategories.map((category) => category.label)}
              placeholder={t("trainings.search.category")}
              options={categoryOptions}
              emptyMessage={t("trainings.search.noResults")}
              loadingFinished={!!categories}
              multiple
              small
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <DropdownInput
              name="trainingType"
              onChange={handleTrainingTypeChange}
              value={selectedTypes.map((type) => type.label)}
              placeholder={t("trainings.search.trainingType")}
              options={typeOptions}
              emptyMessage={t("trainings.search.noResults")}
              loadingFinished={!!types}
              multiple
              small
            />
          </DropdownWrapper>
        </DropdownInputs>
        <DateInputs>
          <DateInput
            onChange={onFromDateChange}
            value={fromDate}
            locale={currentLanguage}
            clearIcon={null}
            minDate={dateRangeFrom}
            maxDate={dateRangeTo}
            format="d/M/y"
            calendarIcon={<DateInputIcon />}
            prev2Label={null}
            next2Label={null}
            prevLabel={<PrevArrowIcon />}
            nextLabel={<NextArrowIcon />}
            showNeighboringMonth={false}
            showLeadingZeros
          />
          <DateSeparator />
          <DateInput
            onChange={onToDateChange}
            value={toDate}
            locale={currentLanguage}
            clearIcon={null}
            minDate={dateRangeFrom}
            maxDate={dateRangeTo}
            returnValue="end"
            format="d/M/y"
            calendarIcon={<DateInputIcon />}
            prev2Label={null}
            next2Label={null}
            prevLabel={<PrevArrowIcon />}
            nextLabel={<NextArrowIcon />}
            showNeighboringMonth={false}
            showLeadingZeros
          />
        </DateInputs>
      </InputRow>
    </Container>
  );
};
