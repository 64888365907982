import React from "react";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";

import { renderWhenTrue } from "../../helpers/rendering";

import {
  Container,
  StarIcon,
  GreyStarIcon,
  EmptyStarIcon,
  Label,
} from "./starsRate.styled";

export const StarsRate = ({ rate, onlyStars }) => {
  const { t } = useTranslation();

  const renderRate = renderWhenTrue(() => <Label>{rate}</Label>);

  if (rate === null) {
    return (
      <Container>
        <Label>{t("starsRate.noRating")}</Label>
      </Container>
    );
  }

  if (rate === 0) {
    return (
      <Container>
        {renderRate(!onlyStars)}
        {[...Array(5)].map((_, index) => (
          <GreyStarIcon key={index} />
        ))}
      </Container>
    );
  }
  return (
    <Container>
      {renderRate(!onlyStars)}
      {[...Array(rate)].map((_, index) => (
        <StarIcon key={index} />
      ))}
      {[...Array(5 - rate)].map((_, index) => (
        <EmptyStarIcon key={index} />
      ))}
    </Container>
  );
};

StarsRate.propTypes = {
  rate: PropTypes.number,
  onlyStars: PropTypes.bool,
};
