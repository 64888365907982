import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import {
  selectCompetenceItemsRouteAvailable,
  selectVideoForCodeRouteAvailable,
} from "../../userContext/redux/userContext.selectors";
import { VideoForCode } from "../../components/videoForCode/videoForCode.component";
import { Loader } from "../../components/loader/loader.component";
import { TagFilteringTheme } from "../../components/competenceTagFiltersList/competenceTagFiltersList.constants";
import {
  CompetenceTagFiltersList,
} from "../../components/competenceTagFiltersList/competenceTagFiltersList.component";
import { TagFiltersList } from "../../components/tagFiltersList/tagFiltersList.component";
import { FavouritesList } from "../../components/favouritesList/favouritesList.component";
import { CookieName } from "../../app/cookiePopup/cookiePopup.constants";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { isCookieEnabled } from "../../helpers/isCookieEnabled";
import { renderWhenTrue } from "../../helpers/rendering";
import { Theme } from "../../appConfig";
import { useModuleConfig, ModuleType } from "../";

import { KnowledgeBaseActions } from "./redux/knowledgeBase.reducer";
import {
  selectIsInitialDataPending,
  selectThumbnailLists,
  selectCompetences,
  selectCompetenceFilters,
  selectTags,
} from "./redux/knowledgeBase.selectors";
import {
  Container,
  Wrapper,
  Header,
  PageHeader,
  GridViewIcon,
  ListViewIcon,
  ToggleViewButton,
  ToggleViewButtons,
  ViewText,
  HeaderRightSide,
  RightDrawer,
} from "./knowledgeBase.styled";
import { Filters } from "./components/filters/filters.component";
import { FiltersToggle } from "./components/filtersToggle/filtersToggle.component";
import { ThumbnailLists } from "./components/thumbnailLists/thumbnailLists.component";
import { KnowledgeBaseView, KnowledgeBaseViewStorageKey } from "./knowledgeBase.constants";
import { countItems } from "./utils/filterHelper";
import { TypeFilters } from "./components/typeFilters/typeFilters.component";
import { FilterType } from "./components/filters/filters.constants";

const KnowledgeBase = () => {
  const { t } = useTranslation();
  const config = useModuleConfig(ModuleType.KnowledgeBase);
  const dispatch = useDispatch();
  const isPending = useSelector(selectIsInitialDataPending);
  const competences = useSelector(selectCompetences);
  const competenceFilters = useSelector(selectCompetenceFilters);
  const videoForCodeRouteAvailable = useSelector(
    selectVideoForCodeRouteAvailable,
  );
  const competenceItemsRouteAvailable = useSelector(
    selectCompetenceItemsRouteAvailable,
  );
  const thumbnailLists = useSelector(selectThumbnailLists);
  const tagFilters = useSelector(selectTags);
  const [viewTypeStorageName, setViewTypeStorageName] = useLocalStorage(KnowledgeBaseViewStorageKey);
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [chosenCompetenceTagFilters, setChosenCompetenceTagFilters] = useState();
  const [chosenTagFilters, setChosenTagFilters] = useState([]);
  const [viewType, setViewType] = useState(viewTypeStorageName || KnowledgeBaseView.Grid);

  useEffect(() => {
    dispatch(KnowledgeBaseActions.getThumbnailListsAndTags());

    if (competenceItemsRouteAvailable) {
      dispatch(KnowledgeBaseActions.getTagFilters());
      dispatch(KnowledgeBaseActions.getCompetenceItems());
    }

    return () => {
      setFilters([]);
    };
  }, [dispatch, competenceItemsRouteAvailable]);

  const handleCloseDrawer = () => setIsOpen(false);

  const handleOpenDrawer = () => setIsOpen(true);

  const handleSwitchFilter = (filter) => {
    const currentFilter = filters.find(
      ({ name, value }) => value === filter.value && name === filter.name
    );
    setFilters(currentFilter ? filters.filter((item) => item !== filter) : [...filters, filter]);
  };

  const clearTagFilters = () => {
    setChosenTagFilters([]);
  };

  const clearCategoryFilters = () => {
    setFilters(filters.filter(
      (item) => item !== FilterType.Contents && item !== FilterType.Files && item !== FilterType.Lesson
    ));
  };

  const handleClearFilters = () => {
    setFilters([]);
    clearTagFilters();
  };

  const handleSetViewType = (viewType) => {
    setViewType(viewType);
    if (isCookieEnabled(CookieName.Functional)) setViewTypeStorageName(viewType);
  };

  const renderVideoForCode = renderWhenTrue(() => <VideoForCode />);

  const renderThumbnailLists = renderWhenTrue(() => {
    const lists = chosenTagFilters ? thumbnailLists : [...thumbnailLists, competences];
    return (
      <ThumbnailLists
        lists={lists}
        filters={filters}
        competenceTagFilterIds={chosenCompetenceTagFilters}
        tagFilters={chosenTagFilters}
        viewType={viewType}
      />
    );
  });

  const renderFilters = renderWhenTrue(() => (
    <FiltersToggle openFilters={handleOpenDrawer} />
  ));

  const renderLoader = renderWhenTrue(() => <Loader />);

  const renderContent = renderWhenTrue(() => (
    <Wrapper>
      <Header>
        <PageHeader>{t(config.label)}</PageHeader>
        <HeaderRightSide>
          <ToggleViewButtons>
            <ViewText>{t("learning.view")}</ViewText>
            <ToggleViewButton onClick={() => handleSetViewType(KnowledgeBaseView.Grid)} active={viewType === KnowledgeBaseView.Grid}>
              <GridViewIcon />
            </ToggleViewButton>
            <ToggleViewButton onClick={() => handleSetViewType(KnowledgeBaseView.List)} active={viewType === KnowledgeBaseView.List}>
              <ListViewIcon />
            </ToggleViewButton>
          </ToggleViewButtons>
          {renderFilters(Theme.CompetenceTagFiltering === TagFilteringTheme.Hidden)}
        </HeaderRightSide>
      </Header>
      {!!tagFilters?.length && (
        <TagFiltersList
          tags={tagFilters}
          chosenTags={chosenTagFilters}
          setChosen={setChosenTagFilters}
        />
      )}
      {Theme.CompetenceTagFiltering === TagFilteringTheme.Visible && !!competenceFilters && (
        <CompetenceTagFiltersList competences={competenceFilters} setChosen={setChosenCompetenceTagFilters} />
      )}
      <TypeFilters
        switchFilter={handleSwitchFilter}
        clearFilters={clearCategoryFilters}
        selected={filters}
      />
      {!filters.length && !chosenTagFilters.length && <FavouritesList viewType={viewType} />}
      {renderVideoForCode(videoForCodeRouteAvailable && !chosenTagFilters?.length)}
      {renderThumbnailLists(!!thumbnailLists.length)}
    </Wrapper>
  ));

  return (
    <>
      <ThemeProvider theme={config.theme}>
        <Container>
          {renderLoader(isPending)}
          {renderContent(!isPending)}
        </Container>
      </ThemeProvider>
      <RightDrawer open={isOpen} onClose={handleCloseDrawer}>
        <Filters
          filters={filters}
          thumbnailLists={thumbnailLists}
          closeFilters={handleCloseDrawer}
          switchFilter={handleSwitchFilter}
          selectedTagFilters={chosenTagFilters}
          setTagFilters={setChosenTagFilters}
          clearFilters={handleClearFilters}
          filteredItemsLength={countItems(thumbnailLists, filters, chosenTagFilters)}
        />
      </RightDrawer>
    </>
  );
};

export default KnowledgeBase;
