import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";

import {
  Container,
  MagnifierIcon,
  SearchInput,
} from "./searchParticipantInput.styled";

export const SearchParticipantInput = ({
  participants,
  setFilteredParticipants,
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [filtered, setFiltered] = useState(participants);

  useEffect(() => {
    const filteredParticipants = participants.filter((participant) => {
      const name = `${participant.name}`.toLowerCase();
      return name.includes(searchValue.toLowerCase());
    });
    if (!isEqual(filteredParticipants, filtered)) {
      setFiltered(filteredParticipants);
      setFilteredParticipants(filteredParticipants);
    }
  }, [searchValue, participants]);

  const handleChangeText = ({ target }) => {
    setSearchValue(target.value);
  };

  return (
    <Container>
      <MagnifierIcon />
      <SearchInput
        placeholder={t("webinars.findParticipant")}
        value={searchValue}
        onChange={handleChangeText}
      />
    </Container>
  );
};
