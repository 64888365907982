import { all, put, takeLatest } from "redux-saga/effects";

import * as searchApi from "../services/api";

import { SearchTypes, SearchActions } from "./search.reducer";

function* getData({ searchValue }) {
  const { data } = yield searchApi.getData(searchValue);
  yield put(SearchActions.getDataSuccess(data));
}

function* getNewsData({ searchValue }) {
  const { data } = yield searchApi.getNewsData(searchValue);
  yield put(SearchActions.getNewsDataSuccess(data));
}

export function* watchSearch() {
  yield all([
    yield takeLatest(SearchTypes.GET_DATA, getData),
    yield takeLatest(SearchTypes.GET_NEWS_DATA, getNewsData),
  ]);
}
