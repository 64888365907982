import { phpApi, nodeApi, handleApiError } from "../../../api";

export const fetchEditData = () => {
  return nodeApi.get("/profile/form");
};

export const fetchEditCompanies = () => {
  return nodeApi.get("/profile/form/companies");
};

export const fetchEditPositions = (companyId) => {
  return nodeApi.get(`/profile/form/companies/${companyId}/positions`);
};

export const fetchEditBrands = (companyId) => {
  return nodeApi.get(`/profile/form/companies/${companyId}/brands`);
};

const createAvatarFormData = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return formData;
};

export const addAvatar = (file) => {
  return phpApi.post("/api/user/avatar/upload", createAvatarFormData(file)).catch((error) => {
    if (error.response.status === 403) {
      return {};
    } else {
      handleApiError(error);
    }
  });
};

export const deleteAvatar = () => {
  return phpApi.post("/api/user/avatar/delete").catch(handleApiError);
};

export const sendEditForm = (values) => {
  return nodeApi.post("/profile/form", values).catch(handleApiError);
};

export const fetchHeaderInfo = () => {
  return nodeApi.get("/profile").catch(handleApiError);
};

export const fetchCareerHistory = () => {
  return phpApi.get("/api/user/experiences");
};

export const fetchIddHistory = () => {
  return phpApi.get("/api/user/idd?limit=10&page=1");
};

export const loadIddNextPage = (nextPage) => {
  return phpApi.get(nextPage);
};

export const fetchUserAgreements = () => {
  return nodeApi.get("/agreements");
};
