import styled from "styled-components";

import { hexToRgba } from "../../../../helpers/colors";
import { Color, ContentMaxWidth, Media } from "../../../../theme";

export const Container = styled.div`
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
`;

export const CompetenceListWrapper = styled.div`
  overflow-y: hidden;
  margin: 0 20px;

  ${Media.tablet`
    margin: 0;
  `}

  ${Media.desktop`
    overflow-x: hidden;
  `}
`;

export const CompetenceListGrid = styled.div`
  display: flex;
  flex-wrap: nowrap;
  transition: all 0.8s;

  ${Media.desktop`
    transform:
      translateX(${({ page }) => `${page * -100}%`})
      translateX(${({ page }) => `${page * -20}px`});
  `}
`;

export const Section = styled.div`
  margin: 60px auto 0;
  max-width: ${ContentMaxWidth};
  position: relative;
`;

export const Button = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${Color.white};
  position: absolute;
  top: 115px;
  border: 0;
  cursor: pointer;
  display: none;
  box-shadow: 0 2px 4px ${hexToRgba(Color.gray6, 0.2)}, 0 0 20px ${hexToRgba(Color.deepGray, 0.03)};

  svg {
    fill: ${Color.gray9};
    height: 14px;
  }

  &:hover {
    svg {
      fill: ${Color.gray4};
    }
  }

  ${Media.desktop`
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const PrevButton = styled(Button)`
  left: -25px;
`;

export const NextButton = styled(Button)`
  right: -25px;

  svg {
    transform: rotate(180deg);
  }
`;
