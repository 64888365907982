export const CanvasId = "canv";
export const UserLabelId = "userLabel";

export const ToolType = {
  Circle: "circle",
  Rectangle: "rect",
  Triangle: "triangle",
  Text: "textbox",
  Path: "path",
  Line: "line",
};

export const FlipchartAction = {
  Add: "add",
  Modify: "modify",
  RemoveElement: "removeElement",
  ClearCanvas: "clearCanvas",
};
