import React from "react";
import PropTypes from "prop-types";

import { Theme } from "../../../../appConfig";
import { TagFilteringTheme } from "../../../../components/competenceTagFiltersList/competenceTagFiltersList.constants";
import { ThumbnailList } from "../../../../components/thumbnailList/thumbnailList.component";
import { Separator } from "../../../../theme/typography";
import { filterItems, groupFilters } from "../../utils/filterHelper";

import { Container } from "./thumbnailLists.styled";

export const ThumbnailLists = ({
  className,
  lists,
  filters,
  competenceTagFilterIds,
  tagFilters,
  viewType,
}) => {
  const filterList = (item) => filterItems(groupFilters(filters), item, tagFilters);

  const filterMultiFileItem = (item) => {
    if (item.competences.length && findCompetenceTagFilterId(item.competences)) {
      return item;
    }
    return null;
  };

  const findCompetenceTagFilterId = (competences) => {
    const competenceIds = competences.map((competence) => competence.id);
    const skillIds = competences.reduce((previousIds, currentCompetence) => {
      return [ ...previousIds, ...currentCompetence.skills.map(({ id }) => id)];
    }, []);

    return !!competenceTagFilterIds.find((filter) => [...competenceIds, ...skillIds].includes(filter));
  };

  const filterListByCompetenceTag = (item) => {
    if (!item.competences) {
      const filteredMultiFileItems = item.items.filter(filterMultiFileItem);
      if (filteredMultiFileItems.length) {
        return {
          ...item,
          items: filteredMultiFileItems,
        }
      }
      return null;
    }

    if (item.competences.length && findCompetenceTagFilterId(item.competences)) {
      return item;
    }

    return null;
  };

  return (
    <Container className={className}>
      {lists.map((list, index) => {
        if (!list) return null;

        const filteredList = Theme.CompetenceTagFiltering === TagFilteringTheme.Visible
          ? competenceTagFilterIds
            ? list.items.filter(filterListByCompetenceTag)
            : list.items.filter(filterList)
          : list.items.filter(filterList);

        if (!filteredList.length) return null;

        const filteredItemsInGroup = filteredList.map((item) => {
          if (item.items?.length && tagFilters?.length) {
            return {
              ...item,
              items: item.items.filter((itemInGroup) => tagFilters.some(
                (tag) => !!itemInGroup.tags.some((itemTag) => itemTag.id === tag.id))
              ),
            };
          }
          return item;
        });

        return (
          <div key={index}>
            <ThumbnailList
              viewType={viewType}
              title={list.title}
              thumbnails={filteredItemsInGroup}
            />
            <Separator />
          </div>
        );
      })}
    </Container>
  );
};

ThumbnailLists.propTypes = {
  className: PropTypes.string,
  lists: PropTypes.array,
  filters: PropTypes.array,
  tagFilterIds: PropTypes.array,
  viewType: PropTypes.string,
};
