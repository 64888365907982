import i18n from "i18next";

import { ReactComponent as StatisticsIcon } from "../../images/statistics.svg";
// TODO: use context or hook
import { AppConfig, Theme } from "../../appConfig";

import { GamificationTheme } from "./components/navItem.constants";

export const NavRoute = {
  Default: "/",
  Onboarding: "/onboarding",
  Dashboard: "/pulpit",
  Lessons: "/lekcje",
  Trainings: "/szkolenia",
  Certificates: "/certyfikaty",
  Forms: "/formularze",
  Profile: "/profil",
  Statistics: "/statystyki",
  Notifications: "/powiadomienia",
  News: "/news",
};

export const NavRouteName = {
  Onboarding: "api_onboarding_assign",
  Dashboard: "api_dashboard",
  Trainings: "api_events_default_request",
  Certificates: "api_certificate_list",
  KnowledgeBase: "api_learning_list",
  Forms: "api_survey_list",
  Profile: "api_user_profile",
  Statistics: "api_user_statistic_index",
  Management: "management_action_list",
  Notifications: "api_notification_list",
  News: "api_news",
};

export const DefaultNavItems = [
  ...AppConfig.modules.map(({ urls, Icon, label, routeName }) => ({
    href: urls[0],
    Icon,
    label: label === GamificationTheme.Gamification ? i18n.t(Theme.Gamification) : i18n.t(label),
    urls,
    routeName,
  })),
  {
    href: NavRoute.Statistics,
    Icon: StatisticsIcon,
    label: i18n.t("app.mainNavigation.statistics"),
    urls: [NavRoute.Statistics],
    routeName: NavRouteName.Statistics,
  },
];

export const CompetenceRouteName = "api_competence_user_list";
