import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

import { hexToRgba } from "../../helpers/colors";
import { styleWhenTrue } from "../../helpers/rendering";
import { Color } from "../../theme";
import { ChartColorType } from "../simpleProgressChart/simpleProgressChart.constants";

export const Container = styled(CircularProgressbarWithChildren)`
  width: 50px;
  height: 50px;

  ${styleWhenTrue(prop("large"), css`
    width: 86px;
    height: 86px;
  `)}

  && {
    .CircularProgressbar-path {
      stroke: ${() => Color.brandBasic};
      stroke-linecap: butt;

      ${styleWhenTrue(propEq(ChartColorType.Gold, "variant"), css`
        stroke: ${Color.gold};
      `)}
    }

    .CircularProgressbar-trail {
      stroke: ${() => hexToRgba(Color.brandBasic, 0.25)};
      stroke-linecap: butt;

      ${styleWhenTrue(propEq(ChartColorType.Gold, "variant"), css`
        stroke: ${Color.gold};
      `)}
    }
  }
`;
