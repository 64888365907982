import styled from "styled-components";

import { ReactComponent as AddIconBase } from "../../../../images/add.svg";
import { Color } from "../../../../theme/colors";
import { Media } from "../../../../theme/media";
import { FontWeight } from "../../../../theme/fonts";

export const IconWrapper = styled.div`
  margin: 0 auto 20px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Color.gray8};
`;

export const AddIcon = styled(AddIconBase)`
  width: 34px;
  fill: ${() => Color.brandBasic};
`;

export const HeaderText = styled.p`
  font-weight: ${FontWeight.Bold};
  font-size: 16px;
  margin-bottom: 5px;
`;

export const LightText = styled.p`
  font-weight: ${FontWeight.Light};
  font-size: 12px;
  line-height: 20px;
  color: ${Color.gray4};

  ${Media.mobile`
    display: none;
  `}

  ${Media.desktop`
    display: block;
  `}
`;

export const Container = styled.button`
  cursor: pointer;
  padding: 28px;
  margin-right: 20px;
  max-width: 285px;
  width: 75%;
  background-color: ${Color.white};
  border: 1px solid ${Color.gray5};
  text-align: center;
  flex-shrink: 0;

  &:hover {
    ${IconWrapper} {
      background-color: ${() => Color.brandBasic};
    }
    ${AddIcon} {
      fill: ${Color.white};
    }
    ${HeaderText} {
      color: ${() => Color.brandBasic};
    }
  }

  ${Media.mobile`
    width: 40%;
  `};

  ${Media.tablet`
    width: 28%;
  `}

  ${Media.desktop`
    width: calc(25% - 15px);
  `}
`;
