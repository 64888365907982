export const AchievementAnimations = [{
  id: "diligent_student",
  animationPromise: import("./anims/diligentStudent.json"),
}, {
  id: "image_master",
  animationPromise: import("./anims/prMaster.json"),
}, {
  id: "comprehender",
  animationPromise: import("./anims/comprehender.json"),
}, {
  id: "super_comprehender",
  animationPromise: import("./anims/superComprehender.json"),
}, {
  id: "forum_master",
  animationPromise: import("./anims/forumMaster.json"),
}, {
  id: "active_commentator",
  animationPromise: import("./anims/activeCommentator.json"),
}, {
  id: "model_to_follow",
  animationPromise: import("./anims/modelToFollow.json"),
}, {
  id: "on_the_way_up",
  animationPromise: import("./anims/onTheWayUp.json"),
}, {
  id: "titan_of_work",
  animationPromise: import("./anims/titanOfWork.json"),
}, {
  id: "work_leader",
  animationPromise: import("./anims/workLeader.json"),
}];
