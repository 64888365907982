import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { hexToRgba } from "../../../../helpers/colors";
import { Color, FontWeight } from "../../../../theme";

export const Container = styled.button`
  padding: 25px 20px;
  border: 1px solid ${Color.dirtyWhite};
  background-color: transparent;
  display: flex;
  gap: 15px;
  text-align: left;

  &:hover {
    background-color: ${Color.whiteHover};
  }

  ${styleWhenTrue(prop("$selected"), css`
    border: 1px solid ${() => Color.brandBasic};
    background-color: ${() => hexToRgba(Color.brandBasic, 0.1)};

    &:hover {
      background-color: ${() => hexToRgba(Color.brandBasic, 0.1)};
    }
  `)}
`;

export const Text = styled.p`
  font-size: 15px;
  color: ${Color.deepGray};

  ${styleWhenTrue(prop("$selected"), css`
    font-weight: ${FontWeight.Bold};
    color: ${() => Color.brandBasic};
  `)}
`;

export const RadioButton = styled.div`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 2px solid ${Color.dirtyWhite};

  ${styleWhenTrue(prop("$selected"), css`
    border: 6px solid ${() => Color.brandBasic};
    background-color: ${Color.white};
  `)}
`;
