import React from "react";
import dayjs from "dayjs";

import { AvatarType } from "../../../../components/avatar/avatar.constants";
import { Avatar } from "../../../../components/avatar/avatar.component";
import { OptionsDropdown } from "../../../../components/optionsDropdown/optionsDropdown.component";
import { EntryType } from "../../../../components/articleForm/articleForm.constants";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { isMobile } from "../../../../theme/media";

import {
  Container,
  AvatarWrapper,
  Title,
  Publisher,
  Date,
  ArticleTitle,
  TextWrapper,
  OptionsWrapper,
} from "./entryHeader.styled";

export const EntryHeader = (props) => {
  const {
    type,
    publisher,
    permissions,
    title,
    timestamp,
    className,
    onEditClick,
    onDeleteClick,
  } = props;
  const renderOptionsDropdown = renderWhenTrue(() => (
    <OptionsWrapper>
      <OptionsDropdown
        editPermission={permissions.edit && !(type === EntryType.Article && isMobile())}
        deletePermission={permissions.delete}
        onEdit={onEditClick}
        onDelete={onDeleteClick}
      />
    </OptionsWrapper>
  ));

  return (
    <Container className={className}>
      {type === EntryType.Article && (
        <ArticleTitle>{title}</ArticleTitle>
      )}
      <TextWrapper>
        <AvatarWrapper>
          <Avatar
            src={publisher.avatar}
            name={`${publisher.firstName} ${publisher.lastName}`}
            dark={publisher.isCurrentUser}
            type={type === EntryType.Article ? AvatarType.Small : AvatarType.Default}
          />
        </AvatarWrapper>
        <div>
          {type === EntryType.Post && (
            <Title>{title}</Title>
          )}
          <p>
            <Publisher>{publisher.firstName} {publisher.lastName}</Publisher>
            <Date>{dayjs.unix(timestamp).format("DD.MM.YYYY, HH:mm")}</Date>
          </p>
        </div>
      </TextWrapper>
      {renderOptionsDropdown(!!permissions && (permissions.edit || permissions.delete))}
    </Container>
  );
};
