import styled from "styled-components";
import DrawerBase from "@mui/material/Drawer";
import { Link as LinkBase } from "react-router-dom";

import { Color, FontWeight, Media } from "../../theme";

export const Drawer = styled(DrawerBase)`
  .MuiDrawer-paper {
    background-color: ${Color.semiDarkGray};
  }

  .MuiDrawer-paperAnchorLeft {
    right: 55px;
    max-width: 472px;
  }
`;

export const Title = styled.p`
  font-size: 12px;
  font-weight: ${FontWeight.Bold};
  line-height: 14px;
  color: ${Color.white};
`;

export const Text = styled.p`
  font-size: 10px;
  line-height: 1.4;
  color: ${Color.white};
`;

export const Link = styled(LinkBase)`
  color: ${Color.white};
`;

export const Buttons = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${Media.tablet`
    flex-direction: row;
  `}
`;

const Button = styled.button`
  padding: 10px 18px;
  color: ${Color.white};
  font-weight: ${FontWeight.Bold};
  text-transform: uppercase;
  border: none;

  ${Media.tablet`
    min-width: 145px;
  `}
`;

export const ColorButton = styled(Button)`
  background-color: ${Color.blue5};
  font-size: 10px;
  line-height: 20px;
  border-radius: 3px;
  box-shadow: 0 0 20px 0 rgba(51, 143, 206, 0.03);
`;

export const TransparentButton = styled(Button)`
  background-color: ${Color.transparent};
  font-size: 12px;
  letter-spacing: 0.6px;
`;
