import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { Color, Media } from "../../../../../../../theme";
import { styleWhenTrue } from "../../../../../../../helpers/rendering";
import { ReactComponent as CheckBaseIcon } from "../../../../../../../images/check2.svg";
import { ReactComponent as CloseBaseIcon } from "../../../../../../../images/close2.svg";

export const Container = styled.div`
  width: 140px;
  height: 130px;
  background-color: ${Color.white};
  border: 2px solid ${Color.gray5};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  & + & {
    margin-left: 10px;
  }

  ${Media.tablet`
    width: 300px;
    height: 180px;
    & + & {
      margin-left: 40px;
    }
  `}

  ${styleWhenTrue(prop("correctTheme"), css`
    border: 2px solid ${Color.green};
    color: ${Color.green};
  `)}
  
  ${styleWhenTrue(prop("wrongTheme"), css`
    border: 2px solid ${Color.red4};
    color: ${Color.red4};
  `)}

  ${styleWhenTrue(prop("faded"), css`
    color: ${Color.gray5};
  `)}
`;

export const Text = styled.span`
  margin-top: 14px;
  font-size: 16px;

  ${Media.tablet`
    font-size: 20px;
  `}
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: ${Color.almostWhite};
  border: 2px solid ${Color.gray5};

  ${styleWhenTrue(prop("correctTheme"), css`
    border: 2px solid ${Color.green};
    background-color: ${Color.lightGreen};
  `)}
  
  ${styleWhenTrue(prop("wrongTheme"), css`
    border: 2px solid ${Color.red4};
    background-color: ${Color.lightPink};
  `)}

  ${styleWhenTrue(prop("faded"), css`
    background-color: ${Color.transparent};
  `)}
`;

export const CheckIcon = styled(CheckBaseIcon)`
  height: 20px;
  width: 20px;
  fill: ${Color.blue};

  ${styleWhenTrue(propEq("true", "correct"), css`
    fill: ${Color.green};
  `)}

  ${styleWhenTrue(propEq("true", "wrong"), css`
    fill: ${Color.red4};
  `)}

  ${styleWhenTrue(propEq("true", "faded"), css`
    fill: ${Color.gray5};
  `)}
`;

export const CloseIcon = styled(CloseBaseIcon)`
  height: 18px;
  width: 18px;
  fill: ${Color.blue};

  ${styleWhenTrue(propEq("true", "correct"), css`
    fill: ${Color.green};
  `)}

  ${styleWhenTrue(propEq("true", "wrong"), css`
    fill: ${Color.red4};
  `)}

  ${styleWhenTrue(propEq("true", "faded"), css`
    fill: ${Color.gray5};
  `)}
`;
