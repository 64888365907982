export const secondsToHours = (durationInSeconds, long = false) => {
  const secondsValue = Math.floor(durationInSeconds % 60);
  const seconds = secondsValue > 9 ? secondsValue : `0${secondsValue}`;
  const minutesValue = Math.floor((durationInSeconds / 60) % 60);
  const minutes = minutesValue > 9 ? minutesValue : `0${minutesValue}`;
  let hoursValue = Math.floor(durationInSeconds / (60 * 60));
  if (!long) {
    hoursValue = hoursValue % 24;
  }
  const hours = hoursValue > 9 ? hoursValue : `0${hoursValue}`;
  return `${hours} : ${minutes} : ${seconds}`;
}
