import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ChallengeElement } from "../../../../../components/challengeElement/challengeElement.component";
import { ArticleForm } from "../../../../../components/articleForm/articleForm.component";
import { EntryType } from "../../../../../components/articleForm/articleForm.constants";
import { DropdownInput } from "../../../../../components/dropdownInput/dropdownInput.component";
import { PostForm } from "../../../../../components/postForm/postForm.component";
import { SectionHeader } from "../../../../../theme";
import { GamificationActions } from "../../../redux/gamification.reducer";
import { selectUserChallenges, selectActiveChallenges } from "../../../redux/gamification.selectors";
import { Pages } from "../../pages/pages.component";
import { NewsElement } from "../newsElement/newsElement.component";

import { DropdownOptions, ElementType } from "./challengesList.constants";
import {
  Container,
  Filters,
  HeaderLabel,
  DropdownWrapper,
  ListHeader,
  AddNews,
  AddNewsButton,
  PostIcon,
  ArticleIcon,
  NewsWrapper,
} from "./challengesList.styled";

export const ChallengesList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userChallenges = useSelector(selectUserChallenges);
  const activeChallenges = useSelector(selectActiveChallenges);
  const [activityFilter, setActivityFilter] = useState(DropdownOptions[0]);
  const [activeEntry, setActiveEntry] = useState(null);
  const [challengesLoading, setChallengesLoading] = useState(true);

  const parsedLinkedChallenges = activeChallenges.map((challenge) => ({
    id: challenge.data.challenge.id,
    label: challenge.data.challenge.label,
  }));

  useEffect(() => {
    dispatch(GamificationActions.getUserChallenges(1));
    dispatch(GamificationActions.getActiveChallenges(() => {
      setChallengesLoading(false);
    }));
  }, [dispatch]);

  const resetActiveEntry = () => {
    setActiveEntry(null);
  };

  const handleAddEntryClick = (entryType) => {
    if (activeEntry === entryType) {
      resetActiveEntry();
    } else {
      setActiveEntry(entryType);
    }
  };

  const handleAddArticleClick = () => handleAddEntryClick(EntryType.Article);
  const handleAddPostClick = () => handleAddEntryClick(EntryType.Post);

  const fetchChallengesPage = (page) => {
    dispatch(GamificationActions.getUserChallenges(page, activityFilter.value));
  };

  const fetchPrevPage = () => {
    dispatch(GamificationActions.getUserChallenges(userChallenges.page - 1, activityFilter.value));
  };

  const fetchNextPage = () => {
    dispatch(GamificationActions.getUserChallenges(userChallenges.page + 1, activityFilter.value));
  };

  const handleActivityFilterChange = ({ target }) => {
    const activity = DropdownOptions.find((option) => option.id === target.value);
    setActivityFilter(activity);
    dispatch(GamificationActions.getUserChallenges(1, activity.value));
  };

  const handleSaveNews = (onFinish) => {
    onFinish();
    resetActiveEntry();
    dispatch(GamificationActions.getUserChallenges(userChallenges.page, activityFilter.value));
  };

  const handleSavePost = (values, onFinish) => {
    dispatch(GamificationActions.savePost(values, () => handleSaveNews(onFinish)));
  };

  const handleSaveArticle = (values, onFinish) => {
    dispatch(GamificationActions.saveArticle(values, () => handleSaveNews(onFinish)));
  };

  return (
    <Container>
      <SectionHeader>
        {t("gamification.challengeList.yourChallenges")}
      </SectionHeader>
      <ListHeader>
        <AddNews>
          <HeaderLabel>{t("gamification.challengeList.add")}</HeaderLabel>
          <AddNewsButton
            onClick={handleAddPostClick}
            active={activeEntry === EntryType.Post}
          >
            <PostIcon />
            {t("gamification.challengeList.post")}
          </AddNewsButton>
          <AddNewsButton
            onClick={handleAddArticleClick}
            active={activeEntry === EntryType.Article}
          >
            <ArticleIcon />
            {t("gamification.challengeList.article")}
          </AddNewsButton>
        </AddNews>
        <Filters>
          <HeaderLabel>
            {t("gamification.challengeList.show")}
          </HeaderLabel>
          <DropdownWrapper>
            <DropdownInput
              small
              options={DropdownOptions}
              value={activityFilter.name}
              valueId={activityFilter.id}
              onChange={handleActivityFilterChange}
            />
          </DropdownWrapper>
        </Filters>
      </ListHeader>
      {activeEntry === EntryType.Post && (
        <NewsWrapper>
          <PostForm
            onSubmit={resetActiveEntry}
            onCancel={resetActiveEntry}
            onSave={handleSavePost}
            challenges={parsedLinkedChallenges}
            challengesLoadingFinished={!challengesLoading}
            inGamification
          />
        </NewsWrapper>
      )}
      {activeEntry === EntryType.Article && (
        <NewsWrapper>
          <ArticleForm
            onSubmit={resetActiveEntry}
            onCancel={resetActiveEntry}
            onSave={handleSaveArticle}
            challenges={parsedLinkedChallenges}
            challengesLoadingFinished={!challengesLoading}
            inGamification
          />
        </NewsWrapper>
      )}
      {userChallenges.data.map(({ data, type }, index) => {
        if (type === ElementType.News) {
          return (
            <NewsElement
              key={index + 5 * userChallenges.page}
              id={data.id}
              publisher={data.publisher}
              timestamp={data.timestamp}
              likeCounter={data.likeCounter}
              commentCounter={data.comments.length}
              title={data.title}
              type={data.type}
            />
          );
        } else {
          return (
            <ChallengeElement
              key={index + 5 * userChallenges.page}
              availableFrom={data.challenge?.dateFrom}
              expirationTimestamp={data.challenge?.dateTo}
              isNew={data.challenge?.isNew}
              points={data.challenge?.points}
              title={data.challenge?.label}
              item={data.challenge?.item}
              doneTimestamp={data.attemptDate}
              earnedPoints={data.points}
              percentageScore={Math.floor(data.progress * 100)}
              status={data.status}
              type={data.type || data.challenge?.type}
              active={data.challenge?.active}
              description={data.challenge?.description}
            />
          );
        }
      })}
      <Pages
        currentPage={userChallenges.page}
        maxPage={userChallenges.maxPage}
        onPrevClick={fetchPrevPage}
        onNextClick={fetchNextPage}
        onPageClick={fetchChallengesPage}
      />
    </Container>
  );
};
