import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { cond, always, equals } from "ramda";

import { CommonActions } from "../../redux/common/common.reducer";
import { selectTutorialCurrentStep } from "../../redux/common/common.selectors";
import { selectUserData } from "../../userContext/redux/userContext.selectors";
import { useMediaListener } from "../../hooks/useMediaListener";
import { TutorialStep } from "../tutorialPopup/tutorialPopup.constants";

import {
  Background,
  Container,
  StepContent,
  StepHeader,
  StepTitle,
  StepText,
  Separator,
  StepButtons,
  NavButtons,
  PrevButton,
  PrevArrow,
  NextButton,
  NextArrow,
  StepSkipButton,
  StepTarget,
  Avatar,
  ProfileImg,
  SearchImg,
  BaseImg,
  TrainingsImg,
  SearchIcon,
  BaseIcon,
  TrainingsIcon,
  MobileMenuTarget,
  ProfileIcon,
  MobileMenuTitle,
} from "./tutorialStepPopup.styled";

export const TutorialStepPopup = ({
  topPositionInPx,
  leftPositionInPx,
  rightPositionInPx,
  targetTopInPx,
  targetLeftInPx,
  targetRightInPx,
  targetBottomInPx,
  arrowTopInPx,
  arrowBottomInPx,
  arrowLeftInPx,
  arrowRightInPx,
  smallTarget,
  targetPosition,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isTablet, isTabletWide } = useMediaListener();
  const userData = useSelector(selectUserData);
  const currentStep = useSelector(selectTutorialCurrentStep);
  const renderStepImg = cond([
    [equals(TutorialStep.Profile), always(<ProfileImg />)],
    [equals(TutorialStep.Search), always(<SearchImg />)],
    [equals(TutorialStep.KnowledgeBase), always(<BaseImg />)],
    [equals(TutorialStep.Trainings), always(<TrainingsImg />)],
  ]);

  const skipTutorial = () => {
    dispatch(CommonActions.setTutorialCurrentStep(null));
    document.body.style.overflow = "unset";
  };

  const setPrevStep = () => {
    switch (currentStep) {
    case TutorialStep.Profile:
      dispatch(CommonActions.setTutorialCurrentStep(TutorialStep.Init));
      break;
    case TutorialStep.Search:
      dispatch(CommonActions.setTutorialCurrentStep(TutorialStep.Profile));
      break;
    case TutorialStep.KnowledgeBase:
      dispatch(CommonActions.setTutorialCurrentStep(TutorialStep.Search));
      break;
    case TutorialStep.Trainings:
      dispatch(CommonActions.setTutorialCurrentStep(TutorialStep.KnowledgeBase));
      break;
    }
  };

  const setNextStep = () => {
    switch (currentStep) {
    case TutorialStep.Profile:
      dispatch(CommonActions.setTutorialCurrentStep(TutorialStep.Search));
      break;
    case TutorialStep.Search:
      dispatch(CommonActions.setTutorialCurrentStep(TutorialStep.KnowledgeBase));
      break;
    case TutorialStep.KnowledgeBase:
      dispatch(CommonActions.setTutorialCurrentStep(TutorialStep.Trainings));
      break;
    case TutorialStep.Trainings:
      skipTutorial();
      break;
    }
  };

  return (
    <>
      <Background />
      <Container
        top={topPositionInPx}
        left={leftPositionInPx}
        right={rightPositionInPx}
        arrowRight={arrowRightInPx}
        arrowLeft={arrowLeftInPx}
        arrowTop={arrowTopInPx}
        arrowBottom={arrowBottomInPx}
        targetPosition={targetPosition}
      >
        <StepContent>
          <StepHeader>
            {renderStepImg(currentStep)}
            <StepTitle>{t(`tutorial.${currentStep}.title`)}</StepTitle>
          </StepHeader>
          <StepText>{t(`tutorial.${currentStep}.text`)}</StepText>
        </StepContent>
        <Separator />
        <StepButtons>
          <StepSkipButton onClick={skipTutorial}>
            {t("tutorial.init.skip")}
          </StepSkipButton>
          <NavButtons>
            <PrevButton onClick={setPrevStep}>
              <PrevArrow />
            </PrevButton>
            <NextButton onClick={setNextStep}>
              <NextArrow />
            </NextButton>
          </NavButtons>
        </StepButtons>
        <StepTarget
          top={targetTopInPx}
          left={targetLeftInPx}
          right={targetRightInPx}
          bottom={targetBottomInPx}
          small={smallTarget}
        >
          {currentStep === TutorialStep.Profile && (
            isTabletWide ? (
              <Avatar $background="white" $border="dirtyWhite">
                {!!userData.user.avatar && <img src={userData.user.avatar} alt="avatar"/>}
                {!userData.user.avatar && userData.user.name[0] + userData.user.surname[0]}
              </Avatar>
            ) : isTablet ? (
              <ProfileIcon />
            ) : (
              <MobileMenuTarget>
                <ProfileIcon />
                <MobileMenuTitle>{t("app.mainNavigation.profile")}</MobileMenuTitle>
              </MobileMenuTarget>
            )
          )}
          {currentStep === TutorialStep.Search && (
            <SearchIcon />
          )}
          {currentStep === TutorialStep.KnowledgeBase && (
            isTablet ? (
              <BaseIcon />
            ) : (
              <MobileMenuTarget>
                <BaseIcon />
                <MobileMenuTitle>{t("app.mainNavigation.knowledge")}</MobileMenuTitle>
              </MobileMenuTarget>
            )
          )}
          {currentStep === TutorialStep.Trainings && (
            isTablet ? (
              <TrainingsIcon />
            ) : (
              <MobileMenuTarget>
                <TrainingsIcon />
                <MobileMenuTitle>{t("app.mainNavigation.trainings")}</MobileMenuTitle>
              </MobileMenuTarget>
            )
          )}
        </StepTarget>
      </Container>
    </>
  );
};

TutorialStepPopup.propTypes = {
  topPositionInPx: PropTypes.number.isRequired,
  targetPosition: PropTypes.string.isRequired,
  rightPositionInPx: PropTypes.number,
  leftPositionInPx: PropTypes.number,
  targetTopInPx: PropTypes.number,
  targetLeftInPx: PropTypes.number,
  targetRightInPx: PropTypes.number,
  targetBottomInPx: PropTypes.number,
  arrowTopInPx: PropTypes.number,
  arrowBottomInPx: PropTypes.number,
  arrowLeftInPx: PropTypes.number,
  arrowRightInPx: PropTypes.number,
  smallTarget: PropTypes.bool,
};
