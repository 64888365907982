import { nodeApi, handleApiError, phpApi } from "../../../api";

export const startGamification = () => {
  return nodeApi.post("/gamification/join").catch(handleApiError);
};

export const fetchCurrentLevel = () => {
  return nodeApi.get("/gamification").catch(handleApiError);
};

export const fetchUpcomingChallenge = () => {
  return nodeApi.get("/gamification/challenges/expire").catch(handleApiError);
};

export const getUserChallenges = (page, filter) => {
  const active = filter === true ? "true" : filter === false ? "false" : undefined;
  const listType = filter === "news" ? "news" : active ? "challenge" : undefined;
  return nodeApi.get("/gamification/challenges", {
    params: { page, active, listType },
  }).catch(handleApiError);
};

export const fetchUserBadges = () => {
  return nodeApi.get("/gamification/achievements").catch(handleApiError);
};

export const fetchRanking = (page = 1) => {
  return nodeApi.get("/gamification/ranking", {
    params: { limit: 3, page },
  }).catch(handleApiError);
};

export const fetchGamificationLevels = () => {
  return nodeApi.get("/gamification/levels").catch(handleApiError);
};

export const fetchOpenQuestion = (id) => {
  return nodeApi.get(`/tasks/${id}`).catch(handleApiError);
};

const createValuesFormData = (values) => {
  const formData = new FormData();
  for (let prop in values) {
    if (Object.prototype.hasOwnProperty.call(values, prop) && values[prop]) {
      if (prop === "files") {
        for (const attachment of values[prop]) {
          formData.append(prop, attachment);
        }
      } else {
        formData.append(prop, values[prop]);
      }
    }
  }
  return formData;
};

export const sendOpenQuestion = (values, id) => {
  const formData = createValuesFormData(values);
  return nodeApi.post(`/tasks/${id}`, formData).catch(handleApiError);
};

export const fetchSurvey = (id) => {
  return phpApi.get(`/api/survey/${id}/init`).catch(handleApiError);
};

export const sendSurveyAnswers = (surveyId, answers) => {
  return phpApi.get(`/api/survey/${surveyId}/save`, { data: { answers } }).catch(handleApiError);
};

export const finishSurvey = (id) => {
  return phpApi.get(`/api/survey/${id}/finish`).catch(handleApiError);
};

export const sendProgramManagerMessage = (message) => {
  const formData = new FormData();
  formData.append("message", message);
  return phpApi.post(
    "/api/gamification/contact",
    formData,
    { sendInBody: true }
  ).catch((error) => error.response);
};

export const getCalendarMeetings = () => {
  return nodeApi.get("/gamification/meetings").catch(handleApiError);
};

export const getUpcomingMeeting = () => {
  return nodeApi.get("/gamification/meetings/next").catch(handleApiError);
};

export const createPost = (post) => {
  return nodeApi.post("/gamification/news/post", { ...post, challengeId: post.challenge?.id });
};

const createArticleFormData = (article) => {
  const formData = new FormData();
  for (let prop in article) {
    if (Object.prototype.hasOwnProperty.call(article, prop) && article[prop]) {
      if (prop === "attachments") {
        for (const attachment of article[prop]) {
          formData.append(prop, attachment);
        }
      } else {
        formData.append(prop, article[prop]);
      }
    }
  }
  return formData;
};

export const createArticle = (article) => {
  const formData = createArticleFormData({ ...article, challengeId: article.challenge?.id });
  return nodeApi.post("/gamification/news/article", formData);
};

export const getKnowledgeBaseItems = () => {
  return phpApi.get("/api/gamification/items").catch(handleApiError);
};

export const getEntryDetails = (id) => {
  return nodeApi.get(`/gamification/news/${id}`).catch(handleApiError);
};

export const getActiveChallenges = () => {
  return nodeApi.get("/gamification/challenges", {
    params: { page: 1, limit: 1000, active: true, listType: "challenge" },
  }).catch(handleApiError);
};

export const getPointsHistory = (page) => {
  return nodeApi.get("/gamification/points", {
    params: { page, limit: 6 },
  }).catch(handleApiError);
};
