import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { AppConfig } from "../../appConfig";
import { LoadingWrapper } from "../../UIElements";
import { AppSettingsActions } from "../redux/appSettings.reducer";
import { selectBrands, selectIsLoading } from "../redux/appSettings.selector";

import {
  Container,
  SelectHeader,
  ButtonsContainer,
  BrandButton,
  BrandImage,
} from "./brandSelector.styled";

export const BrandSelector = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoading = useSelector(selectIsLoading);
  const brands = useSelector(selectBrands);

  useEffect(() => {
    dispatch(AppSettingsActions.getBrands());
  }, []);

  const handleSelectBrand = (brandId) => {
    dispatch(AppSettingsActions.selectBrand(brandId));
  };

  return (
    <Container>
      <LoadingWrapper isLoading={isLoading}>
        <SelectHeader>{t("appSettings.brandSelector.selectBrand")}</SelectHeader>
        <ButtonsContainer>
          {brands.map((brand) => (
            <BrandButton
              key={brand.id}
              onClick={() => handleSelectBrand(brand.id)}
            >
              <BrandImage alt={brand.name} src={AppConfig.content + brand.logo} />
            </BrandButton>
          ))}
        </ButtonsContainer>
      </LoadingWrapper>
    </Container>
  );
};
