import React from "react";
import { useTranslation } from "react-i18next";

import { breakpointsArray } from "../../UIGlobals";
import { Icon, Popup } from "../../UIElements";
import { Color } from "../../theme";

import {
  HeadlineBig,
  HeadlineSmall,
  Item,
  Navigation,
  LessonIcon,
  BtnList,
} from "./lessonsNav.styled";

export const LessonsNav = ({
  toc,
  title,
  opened,
  onHide,
  onOpen,
  hiddenButton,
}) => {
  const { t } = useTranslation();

  const handleOnItemClick = (tocItem) => {
    onHide();
    tocItem.click();
  };

  return (
    <>
      <BtnList hidden={hiddenButton} onClick={onOpen}>
        <Icon
          icon="list"
          size={21}
          color={window.innerWidth > breakpointsArray.l ? Color.almostBlack : Color.white}
        />
      </BtnList>
      <Popup width={600} show={opened} closeAction={onHide}>
        <HeadlineSmall>{title}</HeadlineSmall>
        <HeadlineBig>{t("lesson.lessonNav.issuesList")}</HeadlineBig>
        <Navigation>
          {toc.map((tocItem) => {
            const clickAction = tocItem.available && !tocItem.current
              ? {
                onClick: () => handleOnItemClick(tocItem),
              } : {};
            return (
              <Item
                {...clickAction}
                key={tocItem.id}
                $isAvailable={tocItem.available}
                $isCurrent={tocItem.current}
                cursor="pointer"
              >
                <LessonIcon $isCurrent={tocItem.current}>
                  <Icon icon="caret_right" size={12} color={Color.brandBasic} />
                </LessonIcon>
                {tocItem.title}
              </Item>
            );
          })}
        </Navigation>
      </Popup>
    </>
  );
};
