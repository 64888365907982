import React, { useState, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { isMobile } from "../../../../../../../theme";
import {
  renderWhenTrue,
  renderWhenTrueOtherwise,
} from "../../../../../../../helpers/rendering";

import {
  Container,
  Circle,
  Label,
  DropdownContent,
  DropdownOption,
  DropdownOptionWrapper,
  ArrowIcon,
  CorrectIcon,
  WrongIcon,
  Wrapper,
} from "./dropdown.styled";

export const Dropdown = ({
  className,
  option,
  answers,
  addAnswer,
  removeAnswer,
  isDropdownOpen,
  toggleDropdown,
}) => {
  const { t } = useTranslation();
  const [selectedAnswer, setSelectedAnswer] = useState();

  useLayoutEffect(() => {
    return () => setSelectedAnswer(null);
  }, [option, answers]);

  const isCorrectTheme = selectedAnswer
    ? selectedAnswer.id === option.answerId
    : null;

  const isWrongTheme = selectedAnswer
    ? selectedAnswer.id !== option.answerId
    : null;

  const shouldRenderCircle = (isCorrectTheme || isWrongTheme) && isMobile();

  const isArrowCorrect =
    isCorrectTheme !== null ? isCorrectTheme.toString() : "";

  const isArrowWrong = isWrongTheme !== null ? isWrongTheme.toString() : "";

  const renderLabel = () => {
    const label = selectedAnswer
      ? selectedAnswer.text
      : isMobile()
        ? t("interactions.interactionDropdown.selectFromList")
        : t("interactions.interactionDropdown.select");
    return <Label>{label}</Label>;
  };

  const renderCorrectIcon = renderWhenTrue(() => <CorrectIcon />);

  const renderWrongIcon = renderWhenTrue(() => <WrongIcon />);

  const renderArrow = () => (
    <ArrowIcon correct={isArrowCorrect} wrong={isArrowWrong} />
  );

  const renderCircle = renderWhenTrue(() => (
    <Circle correctTheme={isCorrectTheme} wrongTheme={isWrongTheme}>
      {renderCorrectIcon(isCorrectTheme)}
      {renderWrongIcon(isWrongTheme)}
    </Circle>
  ));

  const renderCircleOrArrow = renderWhenTrueOtherwise(
    () => renderCircle(isMobile()),
    renderArrow
  );

  const renderAnswers = renderWhenTrue(() =>
    answers.map((answer) => (
      <DropdownOptionWrapper key={answer.id}>
        <DropdownOption onClick={() => handleSelectAnswer(answer)}>
          {answer.text}
        </DropdownOption>
      </DropdownOptionWrapper>
    ))
  );

  const handleSelectAnswer = (answer) => {
    setSelectedAnswer(answer);
    if (answer.id === option.answerId) {
      addAnswer(option);
    } else {
      removeAnswer(option);
    }
  };

  return (
    <Container
      className={className}
      correctTheme={isCorrectTheme}
      wrongTheme={isWrongTheme}
      onClick={toggleDropdown}
    >
      <Wrapper>
        {renderCircle(!isMobile())}
        {renderLabel(isMobile())}
      </Wrapper>
      <DropdownContent show={isDropdownOpen}>
        {renderAnswers(!!answers.length)}
      </DropdownContent>
      {renderCircleOrArrow(shouldRenderCircle)}
    </Container>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  option: PropTypes.object,
  answers: PropTypes.array,
  addAnswer: PropTypes.func,
  removeAnswer: PropTypes.func,
};
