import styled, { css } from "styled-components";
import { prop } from "ramda";

import { ReactComponent as InfoIconSvg } from "../../../../images/info.svg";
import { Color } from "../../../../theme";
import { styleWhenTrue } from "../../../../helpers/rendering";

export const Container = styled.div`
  background-color: ${Color.almostWhite};
  padding: 8px 13px 8px 9px;
  border-radius: 3px;
  display: flex;
  align-items: center;

  ${styleWhenTrue(prop("hidden"), css`
    display: none;
  `)}
`;

export const Text = styled.p`
  color: ${Color.deepGray};
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.5px;
  margin-left: 8px;
`;

export const InfoIcon = styled(InfoIconSvg)`
  g {
    fill: ${() => Color.brandBasic};
  }
`;
