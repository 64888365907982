import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ThumbnailList } from "../../../../components/thumbnailList/thumbnailList.component";
import { TrainingItem } from "../../../../components/trainingItem/trainingItem.component";
import { Loader } from "../../../../components/loader/loader.component";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { SectionHeader } from "../../../../theme";
import { CompetenceActions } from "../../redux/competence.reducer";
import {
  selectResourceStatistic,
  selectResourceItems,
} from "../../redux/competence.selectors";
import { ResourceSummary } from "../resourceSummary/resourceSummary.component";

import {
  Container,
  Wrapper,
  ThumbnailsWrapper,
  Separator,
  TrainingsWrapper,
} from "./resources.styled";

export const Resources = ({ className, competenceId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const statistic = useSelector(selectResourceStatistic);
  const items = useSelector(selectResourceItems);
  const resources = items.filter((item) => !item.course);
  const trainings = items.filter((item) => !!item.course);
  const trainingSections = trainings.reduce((sections, training) => {
    if (sections.includes(training.category.name)) {
      return sections;
    }
    return [...sections, training.category.name];
  }, []);

  useEffect(() => {
    dispatch(CompetenceActions.getResources(competenceId));
  }, [dispatch, competenceId]);

  const handleEnroll = (id) => {
    dispatch(CompetenceActions.enrollEvent(id));
  };

  const handleDisenroll = (id) => {
    dispatch(CompetenceActions.disenrollEvent(id));
  };

  const handleSurveyDone = (id) => {
    dispatch(CompetenceActions.enrollEventSuccess(id));
  };

  const renderThumbnails = renderWhenTrue(() => (
    <ThumbnailsWrapper>
      <ThumbnailList title={t("competence.resource.resources")} thumbnails={resources} />
      <Separator />
    </ThumbnailsWrapper>
  ));

  const renderTrainings = (section) => {
    const trainingsInSection = trainings.filter(
      (training) => training.category.name === section
    );

    const trainingIDs = trainingsInSection.reduce((ids, course) => {
      if (ids.includes(course.course.id)) {
        return ids;
      }
      return [...ids, course.course.id];
    }, []);

    return trainingIDs.map((id) => (
      <TrainingItem
        key={id}
        courses={trainingsInSection.filter((training) => training.course.id === id)}
        onEnroll={handleEnroll}
        onDisenroll={handleDisenroll}
        onSurveyDone={handleSurveyDone}
      />
    ));
  };

  const renderTrainingSections = renderWhenTrue(() => (
    trainingSections.map((section) => (
      <TrainingsWrapper key={section}>
        <SectionHeader>{section}</SectionHeader>
        {renderTrainings(section)}
      </TrainingsWrapper>
    ))
  ));

  if (!statistic) {
    return (
      <Container isPending>
        <Loader />
      </Container>
    );
  }

  return (
    <Container className={className}>
      <Wrapper>
        <ResourceSummary
          total={statistic.total}
          done={statistic.count}
        />
        {renderThumbnails(!!resources.length)}
        {renderTrainingSections(!!trainingSections.length)}
      </Wrapper>
    </Container>
  );
};

Resources.propTypes = {
  className: PropTypes.string,
  competenceId: PropTypes.string,
};
