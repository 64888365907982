import Olek from "../../images/olek.png";
import BlurredOlek from "../../images/blurredOlek.png";
import Szymon from "../../images/szymon.png";
import BlurredSzymon from "../../images/blurredSzymon.png";
import Tomek from "../../images/tomek.png";
import BlurredTomek from "../../images/blurredTomek.png";
import Zenek from "../../images/zenek.png";
import BlurredZenek from "../../images/blurredZenek.png";
import Wirus from "../../images/wirus.png";
import BlurredWirus from "../../images/blurredWirus.png";

export const Monsters = [{
  name: "Troll Tomek",
  image: Tomek,
  blurredImage: BlurredTomek,
}, {
  name: "Ogr Olek",
  image: Olek,
  blurredImage: BlurredOlek,
}, {
  name: "Ork Zenek",
  image: Zenek,
  blurredImage: BlurredZenek,
}, {
  name: "Smok Szymon",
  image: Szymon,
  blurredImage: BlurredSzymon,
}, {
  name: "Wirus Niewiedzy",
  image: Wirus,
  blurredImage: BlurredWirus,
}];
