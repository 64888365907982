import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { prop, filter, pipe, keys, map } from "ramda";

import { CommonActions } from "../../redux/common/common.reducer";
import { selectVisibilityList } from "../../redux/common/common.selectors";
import { convertListToBoolMap } from "../../helpers/transform";
import { renderWhenTrue } from "../../helpers/rendering";
import { Asterisk } from "../../theme/typography";

import { Checkbox } from "../checkbox/checkbox.component";
import { Container, Label, CheckboxList, ErrorMessage } from "./visibilityField.styled";

export const VisibilityField = ({ className, onChange, onBlur, name, value = [], error }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(convertListToBoolMap(value, true));
  const [isAllSelected, setIsAllSelected] = useState(false);
  const visibilityList = useSelector(selectVisibilityList);

  useEffect(() => {
    dispatch(CommonActions.fetchVisibility());
  }, [dispatch]);

  useEffect(() => {
    setSelected(convertListToBoolMap(value, true));
  }, [value]);

  const handleChange = useCallback((selectedOptions) => {
    setSelected(selectedOptions);
    const selectedOptionsList = pipe(
      filter(Boolean),
      keys,
      map(Number)
    )(selectedOptions);

    const event = { target: { name, value: selectedOptionsList }};
    onBlur(event);
    onChange(event);
  }, [name, onChange, onBlur]);

  const handleCheckboxChange = useCallback((event) => {
    const { target } = event;
    const selectedOptions = { ...selected, [target.value]: target.checked };
    setIsAllSelected(selected.length === visibilityList.length);
    handleChange(selectedOptions);
  }, [handleChange, selected, visibilityList]);

  const handleAllClick = useCallback(() => {
    setIsAllSelected(!isAllSelected);
    handleChange(convertListToBoolMap(visibilityList.map(prop("id")), !isAllSelected))
  }, [handleChange, isAllSelected, visibilityList]);

  const renderCheckbox = ({ id, name }) => (
    <Checkbox
      invalid={!!error}
      key={id}
      checked={isAllSelected || selected[id]}
      value={id}
      label={name}
      onChange={handleCheckboxChange}
      name={name}
    />
  );

  const renderAllCheckbox = () => (
    <Checkbox
      invalid={!!error}
      checked={isAllSelected}
      label={t("components.visibilityField.optionAll")}
      onChange={handleAllClick}
    />
  );

  const renderErrorMessage = renderWhenTrue(() => (
    <ErrorMessage>{error}</ErrorMessage>
  ));

  return (
    <Container className={className}>
      <Label>
        {t("components.visibilityField.label")}
        <Asterisk />
      </Label>
      <CheckboxList>
        {renderAllCheckbox()}
        {visibilityList.map(renderCheckbox)}
      </CheckboxList>
      {renderErrorMessage(!!error)}
    </Container>
  );
};


VisibilityField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};
