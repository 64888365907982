export const ProfileTabType = {
  Details: "details",
  Edit: "edit",
  IDD: "idd",
  Agreements: "agreements",
  Cookies: "cookies",
  Passwords: "pass",
};

export const CareerTheme = {
  Visible: "visible",
  Hidden: "hidden",
};
