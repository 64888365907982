import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useLocation, useParams } from "react-router";

import { Modal } from "../../components/modal/modal.component";
import { ButtonColor } from "../../components/button/button.constants";
import { renderWhenTrue } from "../../helpers/rendering";
import { useModuleConfig, ModuleType } from "..";

import { BasicWebinars } from "./basicWebinars/basicWebinars.component";
import { ClassesWebinars } from "./classesWebinars/classesWebinars.component";
import {
  ModalText,
  ButtonsContainer,
  Button,
  BorderedPlayIcon,
} from "./webinars.styled";
import { selectUserAuthorized, selectCurrentRoom } from "./redux/webinars.selectors";
import { WebinarsActions } from "./redux/webinars.reducer";
import { RoomType } from "./webinars.constants";

const Webinars = () => {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const config = useModuleConfig(ModuleType.Webinars);
  const dispatch = useDispatch();
  const userAuthorized = useSelector(selectUserAuthorized);
  const room = useSelector(selectCurrentRoom);
  const [soundModalOpen, setSoundModalOpen] = useState(true);

  useEffect(() => {
    return () => dispatch(WebinarsActions.handleLeaveRoom());
  }, [dispatch]);

  const handleCloseSoundModal = () => {
    if (userAuthorized) {
      dispatch(WebinarsActions.joinRoom(params.id, location.state?.from));
    }
    setSoundModalOpen(false);
  };

  const renderPageInteractionModal = renderWhenTrue(() => (
    <Modal
      open
      title={t("webinars.soundModal.header")}
      onClose={handleCloseSoundModal}
      HeaderIcon={BorderedPlayIcon}
    >
      <ModalText>{t("webinars.soundModal.text")}</ModalText>
      <ButtonsContainer>
        <Button onClick={handleCloseSoundModal} color={ButtonColor.Primary}>
          {t("webinars.soundModal.button")}
        </Button>
      </ButtonsContainer>
    </Modal>
  ));

  const renderWebinars = () => {
    if (soundModalOpen || !userAuthorized) return <></>;

    if ((room.type === RoomType.Meeting) || (room.type === RoomType.MeetingGroup)) {
      return <ClassesWebinars />;
    }

    return <BasicWebinars />;
  };

  return (
    <ThemeProvider theme={config.theme}>
      {renderPageInteractionModal(soundModalOpen)}
      {renderWebinars()}
    </ThemeProvider>
  );
};

export default Webinars;
