import styled, { css } from "styled-components";
import { prop } from "ramda";
import { Link } from "react-router-dom";

import { hexToRgba } from "../../../../helpers/colors";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { Media, FontWeight, Color, ContentMaxWidth } from "../../../../theme";
import { ReactComponent as StarBadgeSvg } from "../../images/starBadge.svg";

export const Container = styled.div``;

export const HeaderWrapper = styled.div`
  padding-top: 30px;
  box-shadow: 0px 2px 4px ${hexToRgba(Color.gray1, 0.25)};

  ${Media.tablet`
    padding: 30px 80px 0;
  `}

  ${Media.desktop`
    padding: 30px 160px 0;
  `}
`;

export const Header = styled.div`
  display: flex;
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
  align-items: center;
  padding: 0 20px 54px;

  ${Media.tablet`
    padding: 0 0 54px;
  `}
`;

export const BackButtonWrapper = styled(Header)``;

export const TextWrapper = styled.div`
  margin-left: 41px;
`;

export const Title = styled.p`
  font-weight: ${FontWeight.Bold};
  font-size: 26px;
  line-height: 30px;
  color: ${Color.deepGray};
`;

export const Level = styled.p`
  margin-top: 8px;
  color: ${Color.gray9};
  font-size: 20px;
  line-height: 22px;
`;

export const StrategicIconWrapper = styled.div`
  display: none;

  ${Media.tablet`
    margin-left: auto;
    display: flex;
    align-items: center;
    position: relative;
    top: -40px;
  `}
`;

export const StrategicIconText = styled.span`
  text-transform: uppercase;
  font-size: 10px;
  line-height: 21px;
  color: ${Color.gray9};
  font-weight: ${FontWeight.SemiBold};
  margin-right: 10px;
`;

export const StrategicIcon = styled(StarBadgeSvg)`
  width: 46px;
  height: 46px;
  border: 1px solid ${Color.gray5};
  border-radius: 50%;
  padding: 10px;
`;

export const Tabs = styled.div`
  display: flex;
  overflow-x: scroll;
  -ms-overflow-style: none;
  overflow-y: hidden;
  scrollbar-width: none;
  max-width: ${ContentMaxWidth};
  margin: 0 auto;

  &::-webkit-scrollbar {
    display: none;
  }

  ${Media.tablet`
    position: relative;
    left: -20px;
  `}
`;

export const TabText = styled.span`
  font-size: 14px;
  font-weight: ${FontWeight.SemiBold};
  white-space: nowrap;
  color: ${Color.gray9};
  padding: 13px 0;

  ${styleWhenTrue(prop("active"), css`
    color: ${() => Color.brandBasic};
    border-bottom: 2px solid ${() => Color.brandBasic};
  `)}
`;

export const Tab = styled(Link)`
  text-decoration: none;
  background-color: ${Color.transparent};
  border: none;
  padding: 17px 15px;
  
  &:first-of-type {
    padding-left: 20px;
  }

  &:last-of-type {
    padding-right: 20px;
  }

  ${styleWhenTrue(prop("disabled"), css`
    pointer-events: none;
  `)}
`;
