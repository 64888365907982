import { createActions, createReducer } from "reduxsauce";

import { deepClone } from "../../../helpers";

export const {
  Types: KnowledgeBaseTypes,
  Creators: KnowledgeBaseActions,
} = createActions(
  {
    getThumbnailListsAndTags: null,
    getThumbnailListsSuccess: ["lists"],
    setIsPending: ["isPending"],
    getCompetenceItems: null,
    getCompetenceItemsSuccess: ["competences"],
    getTagFilters: null,
    getTagFiltersSuccess: ["competenceFilters"],
    getTags: null,
    getTagsSuccess: ["tags"],
    setListItemFavorite: ["itemId", "favorite"],
  },
  { prefix: "KNOWLEDGE_BASE/" }
);

const INITIAL_STATE = {
  isPending: true,
  lists: [],
  competenceFilters: null,
  competences: null,
  tags: null,
};

const getThumbnailListsSuccess = (state, { lists }) => ({
  ...state,
  lists,
});

const setListItemFavorite = (state, { itemId, favorite }) => {
  if (!state.lists.length) return state;

  const listIndex = state.lists.findIndex((list) => !!list.items.find((item) => item.id === itemId));
  if (listIndex === -1) return state;
  const itemIndex = state.lists[listIndex].items.findIndex((item) => item.id === itemId);
  const newState = deepClone(state);
  newState.lists[listIndex].items[itemIndex].favorite = favorite;

  return newState;
};

const setIsPending = (state, { isPending }) => ({ ...state, isPending });

const getCompetenceItemsSuccess = (state, { competences }) => ({
  ...state,
  competences,
});

const getTagFiltersSuccess = (state, { competenceFilters }) => ({
  ...state,
  competenceFilters,
});

const getTagsSuccess = (state, { tags }) => ({
  ...state,
  tags,
});

export const reducer = createReducer(INITIAL_STATE, {
  [KnowledgeBaseTypes.GET_THUMBNAIL_LISTS_SUCCESS]: getThumbnailListsSuccess,
  [KnowledgeBaseTypes.SET_IS_PENDING]: setIsPending,
  [KnowledgeBaseTypes.GET_COMPETENCE_ITEMS_SUCCESS]: getCompetenceItemsSuccess,
  [KnowledgeBaseTypes.GET_TAG_FILTERS_SUCCESS]: getTagFiltersSuccess,
  [KnowledgeBaseTypes.GET_TAGS_SUCCESS]: getTagsSuccess,
  [KnowledgeBaseTypes.SET_LIST_ITEM_FAVORITE]: setListItemFavorite,
});
