import { createSelector } from "reselect";
import { prop } from "ramda";

export const selectCertificatesDomain = prop("certificates");

export const selectIsInitialDataPending = createSelector(
  selectCertificatesDomain, prop("isPending"),
);

export const selectStatistics = createSelector(
  selectCertificatesDomain, prop("statistics"),
);

export const selectRanking = createSelector(
  selectCertificatesDomain, prop("ranking"),
);

export const selectLessons = createSelector(
  selectCertificatesDomain, prop("lessons"),
);

export const selectSurveys = createSelector(
  selectCertificatesDomain, prop("surveys"),
);

export const selectInProgressCertificatePaths = createSelector(
  selectCertificatesDomain, prop("inProgressPaths"),
);

export const selectFinishedCertificatePaths = createSelector(
  selectCertificatesDomain, prop("finishedPaths"),
);

export const selectPathConfirmations = createSelector(
  selectCertificatesDomain, prop("pathConfirmations"),
);

export const selectIndividuals = createSelector(
  selectCertificatesDomain, prop("individuals"),
);

export const selectEvents = createSelector(
  selectCertificatesDomain, prop("events"),
);

export const selectCertificateData = createSelector(
  selectCertificatesDomain, prop("certificate"),
);
