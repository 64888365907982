import React from "react";
import PropTypes from "prop-types";

import { Container, CheckBox, Label } from "./option.styled";

export const Option = ({
  className,
  option,
  toggleOption,
  correctTheme,
  wrongTheme,
}) => {
  const toggle = () => toggleOption(option);
  return (
    <Container
      className={className}
      correctTheme={correctTheme}
      wrongTheme={wrongTheme}
      onClick={toggle}
    >
      <CheckBox correctTheme={correctTheme} wrongTheme={wrongTheme} />
      <Label>{option.text}</Label>
    </Container>
  );
};

Option.propTypes = {
  className: PropTypes.string,
  option: PropTypes.object,
  toggleOption: PropTypes.func,
  correctTheme: PropTypes.bool,
  wrongTheme: PropTypes.bool,
};
