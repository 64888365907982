import styled from "styled-components";
import { styled as styledMUI, TextField as TextFieldBase } from "@mui/material";

export const Container = styled.div``;

export const TextField = styledMUI(TextFieldBase)({
  width: "100%",

  "& .MuiFormLabel-root": {
    fontSize: 16,
  },
  "& .MuiInputBase-root": {
    fontSize: 16,
  },
});
