import styled from "styled-components";

import { Media } from "../../../../theme";
import AdditionalAnimBase from "../additionalAnim/additionalAnim.component";

export const Container = styled.div``;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${Media.tablet`
    flex-direction: row;
  `}
`;

export const VideoBox = styled.div`
  width: 100%;
  margin-top: 20px;

  ${Media.tablet`
    width: 56%;
    margin-top: 0;
  `}
`;

export const AdditionalAnim = styled(AdditionalAnimBase)`
  margin-bottom: 25px;

  ${Media.tablet`
    margin-top: -100px;
    margin-bottom: 0;
  `}
`;
