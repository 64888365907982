import { createSelector } from "reselect";
import { prop } from "ramda";

export const selectLessonDomain = prop("lesson");

export const selectNav = createSelector(
  selectLessonDomain, prop("nav"),
);

export const selectLessonInstanceId = createSelector(
  selectLessonDomain, prop("instanceId"),
);
