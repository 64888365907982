import styled from "styled-components";

import { Media } from "../../../../../theme";

export const Container = styled.div`
  margin-top: 60px;

  ${Media.tabletWide`
    width: calc(40% - 10px);
  `}
`;
