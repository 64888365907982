import React from "react";

const Modal = ({callProceed, hidden, back}) => (
  <div className={ hidden ? "hidden" : "" }>
    <div className="modal-container">
      <div className="modal-header">
        <div className="icon-wrap">
          <svg width="65px" height="65px" viewBox="0 0 65 65">
            <path fill="currentColor" d="M55.063,9.938C49.032,3.91,41.021,0.596,32.5,0.596S15.967,3.91,9.938,9.938S0.596,23.979,0.596,32.5
                        c0,8.521,3.314,16.532,9.343,22.563c6.028,6.026,14.041,9.342,22.562,9.342s16.532-3.315,22.563-9.342
                        c6.026-6.03,9.342-14.042,9.342-22.563C64.404,23.979,61.089,15.967,55.063,9.938z M32.5,60.882
                        c-15.646,0-28.381-12.736-28.381-28.382c0-15.646,12.735-28.381,28.381-28.381S60.882,16.854,60.882,32.5
                        C60.882,48.146,48.146,60.882,32.5,60.882z"/>
            <path fill="currentColor" d="M39.95,25.141L28.977,36.129L25.05,32.2c-0.692-0.691-1.802-0.691-2.492,0
                        c-0.693,0.692-0.693,1.801,0,2.493l5.18,5.18c0.339,0.339,0.796,0.523,1.24,0.523s0.9-0.168,1.239-0.523l12.227-12.225
                        c0.694-0.692,0.694-1.802,0-2.493C41.766,24.449,40.645,24.449,39.95,25.141z"/>
          </svg>
        </div>
        <div className="modal-title-wrap">
          <h2>Obejrzałeś obowiązkową liczbę materiałów</h2>
        </div>
      </div>
      <div className="modal-events">
        <div className="modal-events-wrap">
          <h3 onClick={ callProceed } >Przejdź dalej lub wybierz slajd z listy
            <span>
              <svg className="arrow arrow__next" width="23px" height="20px" viewBox="0 0 23 40">
                <path fill="currentColor" d="M3.333,39.288c-0.271,0.271-0.61,0.408-0.985,0.408c-0.373,0-0.713-0.137-0.984-0.408
                                c-0.543-0.544-0.543-1.427,0-1.97L18.682,20L1.363,2.68c-0.543-0.543-0.543-1.426,0-1.97c0.543-0.542,1.427-0.542,1.969,0
                                l18.304,18.305c0.543,0.543,0.543,1.426,0,1.97L3.333,39.288z"/>
              </svg>
            </span>
          </h3>
          <h3 onClick={ back } className="border-bottom">Wróć do oglądania <span>
            <svg className="arrow arrow__next" width="23px" height="20px" viewBox="0 0 23 40">
              <path fill="currentColor" d="M3.333,39.288c-0.271,0.271-0.61,0.408-0.985,0.408c-0.373,0-0.713-0.137-0.984-0.408
                                c-0.543-0.544-0.543-1.427,0-1.97L18.682,20L1.363,2.68c-0.543-0.543-0.543-1.426,0-1.97c0.543-0.542,1.427-0.542,1.969,0
                                l18.304,18.305c0.543,0.543,0.543,1.426,0,1.97L3.333,39.288z"/>
            </svg>
          </span>
          </h3>
        </div>
      </div>
    </div>
  </div>
);

export default Modal;
