import dayjs from "dayjs";

export const getDateLabel = ({ cyclicalInfo, date }) => {
  const startTimestamp = cyclicalInfo ? cyclicalInfo.nextTimestampStart : date.from;
  return dayjs(startTimestamp * 1000).format("DD-MM-YYYY");
};

export const compareDateLabels = (labelA, labelB) => {
  const dateFormat = "DD-MM-YYYY";
  return dayjs(labelA, dateFormat).unix() - dayjs(labelB, dateFormat).unix();
};

export const getDurationInDays = (timestampStart, timestampEnd) => {
  const start = dayjs(timestampStart * 1000);
  const end = dayjs(timestampEnd * 1000);
  return end.startOf("day").diff(start.startOf("day"), "day") + 1;
};

const filterByText = (searchText, event) => {
  if (!searchText) return true;

  const text = searchText.toLowerCase();

  return (
    event.course.description?.toLowerCase().includes(text)
      || event.course.category?.name.toLowerCase().includes(text)
      || event.course.name.toLowerCase().includes(text)
      || event.course.type?.name.toLowerCase().includes(text)
      || event.tags?.some((tag) => tag.name.toLowerCase().includes(text))
      || event.trainers?.some((trainer) => trainer.toLowerCase().includes(text))
      || event.agenda?.some((agendaPart) => agendaPart.toLowerCase().includes(text))
      || event.location?.city?.toLowerCase().includes(text)
  );
};

const filterByDate = (fromDate, toDate, event) => {
  const eventDateFrom = (event.cyclicalInfo ? event.cyclicalInfo.nextTimestampStart : event.date.from) * 1000;
  const eventDateTo = (event.cyclicalInfo ? event.cyclicalInfo.nextTimestampEnd : event.date.to) * 1000;
  return (eventDateFrom >= fromDate && eventDateTo <= toDate)
    || (eventDateFrom <= toDate && eventDateTo >= fromDate);
};

const filterByCategories = (selectedCategories, category) => {
  return (
    selectedCategories.length
      ? selectedCategories.map((selectedCategory) => selectedCategory.id).includes(category?.id)
      : true
  );
};

const filterByTypes = (selectedTypes, tags) => {
  return (
    selectedTypes.length
      ? tags.some((tag) => selectedTypes.map((type) => type.id).includes(tag.id))
      : true
  );
};

export const filterEvents = (
  events,
  searchText,
  fromDate,
  toDate,
  selectedCategories,
  selectedTypes,
) => events?.filter((event) => (
  filterByText(searchText, event)
  && filterByCategories(selectedCategories, event.course.category)
  && filterByTypes(selectedTypes, event.tags)
  && filterByDate(fromDate, toDate, event)
));
