import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";

import { EnrollForm } from "../../../../modules/trainings/components/enrollForm/enrollForm.component";
import { RightDrawer } from "../../../rightDrawer/rightDrawer.component";
import { simpleDateFromTimestamp } from "../../../../helpers/date";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { ButtonColor } from "../../../button/button.constants";

import {
  Container,
  Date,
  AvailableEnrolments,
  CalendarIcon,
  EnrolmentsText,
  Location,
  PinIcon,
  City,
  Button,
  Buttons,
  MobileWrapper,
} from "./courseRow.styled";

export const CourseRow = ({
  className,
  course,
  onEnroll,
  onDisenroll,
  onSurveyDone,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const startDate = simpleDateFromTimestamp(course.timestampStart);
  const { isEnrolled, canBeEnrolled, canBeDisenrolled } = course.userFlags;
  const showEnrollButton = canBeEnrolled || canBeDisenrolled;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [openEnrollForm, setOpenEnrollForm] = useState(false);

  useEffect(() => {
    setButtonDisabled(false);
  }, [isEnrolled]);

  const directToDetails = () => {
    navigate(`/szkolenia/${course.id}`);
  };

  const handleEnroll = () => {
    if (course.withEntrySurvey) {
      setOpenEnrollForm(true);
    } else {
      onEnroll(course.id);
      setButtonDisabled(true);
    }
  };

  const handleDisenroll = () => {
    onDisenroll(course.id);
    setButtonDisabled(true);
  };

  const handleSurveyDone = () => {
    setOpenEnrollForm(false);
    onSurveyDone(course.id);
  };

  const renderEnrollButton = renderWhenTrue(() => (
    <Button
      color={ButtonColor.Primary}
      onClick={isEnrolled ? handleDisenroll : handleEnroll}
      disabled={buttonDisabled}
    >
      {t(isEnrolled ? "global.disenroll": "global.enroll")}
    </Button>
  ));

  return (
    <Container className={className}>
      <MobileWrapper>
        <Date>{startDate}</Date>
        <AvailableEnrolments>
          <CalendarIcon />
          <EnrolmentsText>
            {course.availableEnrolments}
            {" "}
            {t("trainings.trainings.freePlaces")}
          </EnrolmentsText>
        </AvailableEnrolments>
        <Location>
          <PinIcon />
          <City>{course.city || t("training.details.trainingLocationNone")}</City>
        </Location>
      </MobileWrapper>
      <Buttons>
        <Button color={ButtonColor.Primary} onClick={directToDetails}>
          {t("trainings.trainings.details")}
        </Button>
        {renderEnrollButton(showEnrollButton)}
      </Buttons>
      <RightDrawer open={openEnrollForm} onClose={() => setOpenEnrollForm(false)}>
        <EnrollForm
          onClose={() => setOpenEnrollForm(false)}
          eventId={course.id}
          withAccommodation={course.withAccommodation}
          withParking={course.withParking}
          withVege={course.withVege}
          onEnrollClick={handleSurveyDone}
        />
      </RightDrawer>
    </Container>
  );
};

CourseRow.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object,
  onEnroll: PropTypes.func,
  onDisenroll: PropTypes.func,
  onSurveryDone: PropTypes.func,
};
