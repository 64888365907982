import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Modal } from "../../../../components/modal/modal.component";

import { Video, MovieIcon } from "./videoModal.styled";

export const VideoModal = ({ open, handleCloseModal, videoSrc }) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      title={t("dashboard.dashboard.video")}
      HeaderIcon={MovieIcon}
      onClose={handleCloseModal}
    >
      <Video
        src={videoSrc}
        id="video-element"
        controls
        disablePictureInPicture
        controlsList="nodownload"
      />
    </Modal>
  );
};

VideoModal.propTypes = {
  open: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  videoSrc: PropTypes.string,
};
