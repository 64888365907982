import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import { renderWhenTrue } from "../../helpers/rendering";

import { CourseRow } from "./components/courseRow/courseRow.component";
import { FinancialTrainingCategory } from "./trainingItem.constants";
import {
  Container,
  Header,
  TextWrapper,
  Title,
  Duration,
  ArrowWrapper,
  ArrowIcon,
  Content,
  DatesHeader,
  Separator,
  CoinIcon,
  UmbrellaIcon,
} from "./trainingItem.styled";

export const TrainingItem = ({
  className,
  courses,
  onEnroll,
  onDisenroll,
  onSurveyDone,
}) => {
  const { t } = useTranslation();
  const startDate = dayjs.unix(courses[0].timestampStart);
  const endDate = dayjs.unix(courses[0].timestampEnd);
  const durationInDays = endDate.diff(startDate, "day") || 1;
  const durationString = `${durationInDays} ${durationInDays > 1 ? t("global.days") : t("global.day")}`;
  const [open, setOpen] = useState(false);
  const icon =  courses[0].category.name === FinancialTrainingCategory ? <CoinIcon /> : <UmbrellaIcon />;

  const toggleOpen = () => setOpen(!open);

  const renderDuration = renderWhenTrue(() => (
    <Duration>
      {t("trainings.trainings.duration")}:
      {" "}
      {durationString}
    </Duration>
  ));

  const renderCourses = () => courses.map((course) => (
    <React.Fragment key={course.id}>
      <CourseRow
        course={course}
        onEnroll={onEnroll}
        onDisenroll={onDisenroll}
        onSurveyDone={onSurveyDone}
      />
      <Separator />
    </React.Fragment>
  ));

  return (
    <Container className={className}>
      <Header onClick={toggleOpen}>
        {icon}
        <TextWrapper>
          <Title>{courses[0].course.name}</Title>
          {renderDuration(!!courses[0].timestampEnd)}
        </TextWrapper>
        <ArrowWrapper>
          <ArrowIcon open={open} />
        </ArrowWrapper>
      </Header>
      <Content open={open}>
        <DatesHeader>
          {t("trainings.trainings.coursesDates")}
        </DatesHeader>
        <Separator />
        {renderCourses()}
      </Content>
    </Container>
  );
};

TrainingItem.propTypes = {
  className: PropTypes.string,
  courses: PropTypes.array,
  onEnroll: PropTypes.func,
  onDisenroll: PropTypes.func,
  onSurveyDone: PropTypes.func,
};
