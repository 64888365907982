import styled from "styled-components";

import { Color, FontWeight } from "../../../theme";

export const Container = styled.div`
  position: relative;
  border-right: 1px solid ${Color.gray5};
`;

export const AvatarContainer = styled.div`
  height: 70px;
  padding-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextAvatar = styled.div`
  margin-bottom: 20px;
  margin-right: 15px;
  float: left;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${Color.gray5};
  color: ${() => Color.brandBasic};
  font-size: 23px;
  line-height: 27px;
`;

export const Menu = styled.div`
  display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
  position: absolute;
  z-index: 3;
  top: calc(100% - 1px);
  right: -1px;
  width: 360px;
  max-width: 100vw;
  height: 230px;
  padding: 30px;
  box-shadow: 0 10px 50px 0 rgba(117, 123, 150, 0.1);
  background: ${Color.whiteHover};
  border: 1px solid ${Color.gray5};
`;

export const User = styled.p`
  margin-top: 10px;
  color: ${Color.almostBlack};
  font-size: 16px;
  font-weight: ${FontWeight.SemiBold};
  line-height: 25px;
`;

export const Email = styled.p`
  color: ${Color.gray2};
  font-size: 12px;
  font-weight: ${FontWeight.Medium};
  line-height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MenuItems = styled.div`
  padding-top: 10px;
  border-top: 1px solid ${Color.gray5};
  clear: both;
`;

export const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: ${FontWeight.SemiBold};
  text-transform: uppercase;

  & > *:first-child {
    cursor: pointer;
    color: ${Color.almostBlack};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const NotificationsNumber = styled.div`
  display: inline-block;
  margin-right: 10px;
  color: ${Color.gray4};
`;

export const NotificationIndicator = styled.div`
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: ${Color.gray4};
`;
