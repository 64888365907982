import styled from "styled-components";

import { Color } from "../../../../../theme";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Level = styled.p`
  font-size: 13px;
  line-height: 24px;
  color: ${Color.gray9};
`;

export const Label = styled.span`
  color: ${Color.deepGray};
`;

export const Percentage = styled(Level)`
  color: ${Color.deepGray};
`;
