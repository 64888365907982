import React from "react";
import { useTranslation } from "react-i18next";

import {
  Container,
  Dropdown,
  ArrowIcon,
  DropdownElement,
} from "./roomDropdown.styled";

export const RoomDropdown = ({
  selected,
  selectRoom,
  rooms,
  isOpen,
  toggleDropdown,
}) => {
  const { t } = useTranslation();

  const onElementClick = (room) => {
    selectRoom(room);
    toggleDropdown(false);
  };

  return (
    <Container
      onClick={() => toggleDropdown(!isOpen)}
      selected={!!selected}
    >
      {selected || t("webinars.roomDropdown.chooseRoom")}
      <ArrowIcon open={isOpen} />
      <Dropdown open={isOpen}>
        {rooms.map((room, index) => (
          <DropdownElement
            onClick={() => onElementClick(room)}
            key={index}
          >
            {room.name}
          </DropdownElement>
        ))}
      </Dropdown>
    </Container>
  );
};
