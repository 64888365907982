import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import { AvatarType } from "../../components/avatar/avatar.constants";
import { Avatar } from "../../components/avatar/avatar.component";
import { Loader } from "../../components/loader/loader.component";
import { selectUserIddRouteAvailable } from "../../userContext/redux/userContext.selectors";
import { renderWhenTrue } from "../../helpers/rendering";
import { Theme } from "../../appConfig";
import { useModuleConfig, ModuleType } from "../";

import { ProfileDetails } from "./components/profileDetails/profileDetails.component";
import { ProfileEdit } from "./components/profileEdit/profileEdit.component";
import { IDDTab } from "./components/iddTab/iddTab.component";
import { AgreementsTab } from "./components/agreementsTab/agreementsTab.component";
import { CookiesTab } from "./components/cookiesTab/cookiesTab.component";
import { PasswordsTab } from "./components/passwordsTab/passwordsTab.component";
import { ProfileActions } from "./redux/profile.reducer";
import {
  selectIsPending,
  selectAvatarSrc,
  selectFullName,
  selectPosition,
} from "./redux/profile.selectors";
import { ProfileTabType } from "./profile.constants";
import {
  Container,
  HeaderWrapper,
  Header,
  TextWrapper,
  Name,
  Position,
  Tabs,
  TabText,
  Tab,
} from "./profile.styled";

const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const config = useModuleConfig(ModuleType.Profile);
  const dispatch = useDispatch();
  const isPending = useSelector(selectIsPending);
  const avatarSrc = useSelector(selectAvatarSrc);
  const name = useSelector(selectFullName);
  const position = useSelector(selectPosition);
  const userIddRouteAvailable = useSelector(selectUserIddRouteAvailable);
  const [activeTab, setActiveTab] = useState(ProfileTabType.Details);

  useEffect(() => {
    dispatch(ProfileActions.fetchHeaderInfo());
  }, [dispatch]);

  useEffect(() => {
    if (config.urls.includes(location.pathname)) {
      navigate(ProfileTabType.Details, { replace: true });
    }
  }, [navigate, location]);

  useEffect(() => {
    const tabFromUrl = location.pathname.replace(`${config.urls[1]}/`, "");

    if (Object.values(ProfileTabType).includes(tabFromUrl)) {
      setActiveTab(tabFromUrl);
    } else {
      setActiveTab(ProfileTabType.Details);
    }
  }, [location.pathname]);

  const renderTabButton = (tab) => (
    <Tab onClick={() => setActiveTab(tab)} to={tab}>
      <TabText $active={activeTab === tab}>
        {t(`profile.tabs.${tab}`)}
      </TabText>
    </Tab>
  );

  const renderIddRoute = renderWhenTrue(() => (
    <Route path={config.routes.idd} element={<IDDTab />} />
  ));

  const renderTabContent = () => (
    <Routes>
      <Route path={config.routes.details} element={<ProfileDetails />} />
      <Route path={config.routes.edit} element={<ProfileEdit />} />
      {renderIddRoute(userIddRouteAvailable)}
      <Route path={config.routes.agreements} element={<AgreementsTab />} />
      <Route path={config.routes.cookies} element={<CookiesTab />} />
      {Theme.AdvancedPasswordActive && (
        <Route path={config.routes.passwords} element={<PasswordsTab />} />
      )}
    </Routes>
  );

  const renderLoader = renderWhenTrue(() => <Loader />);

  const renderIddTab = renderWhenTrue(() => renderTabButton(ProfileTabType.IDD));

  const renderContent = renderWhenTrue(() => (
    <>
      <HeaderWrapper>
        <Header>
          <Avatar src={avatarSrc} name={name} type={AvatarType.ExtraLarge} />
          <TextWrapper>
            <Name>{name}</Name>
            <Position>{position}</Position>
          </TextWrapper>
        </Header>
        <Tabs>
          {renderTabButton(ProfileTabType.Details)}
          {renderTabButton(ProfileTabType.Edit)}
          {renderIddTab(userIddRouteAvailable)}
          {renderTabButton(ProfileTabType.Agreements)}
          {renderTabButton(ProfileTabType.Cookies)}
          {Theme.AdvancedPasswordActive && renderTabButton(ProfileTabType.Passwords)}
        </Tabs>
      </HeaderWrapper>
      {renderTabContent()}
    </>
  ));

  return (
    <ThemeProvider theme={config.theme}>
      <Container>
        {renderContent(!isPending)}
        {renderLoader(isPending)}
      </Container>
    </ThemeProvider>
  );
};

export default Profile;
