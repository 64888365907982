import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { ButtonVariant } from "../../../../components/button/button.constants";

import {
  Container,
  Content,
  Text,
  StyledButton,
} from "./incomingEventEmpty.styled";

export const IncomingEventEmpty = ({ topWidgetEnabled }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Container $topWidgetEnabled={topWidgetEnabled}>
      <Content>
        <Text>{t("dashboard.incomingEventEmpty.title")}</Text>
        <StyledButton onClick={() => navigate("/szkolenia")} variant={ButtonVariant.Outlined}>
          {t("dashboard.incomingEventEmpty.list")}
        </StyledButton>
      </Content>
    </Container>
  );
};
