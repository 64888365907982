import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Button as ButtonBase } from "../../../../../components/button/button.component";
import { ReactComponent as NextBaseIcon } from "../../../../../images/next.svg";
import { Media, Color } from "../../../../../theme";
import { styleWhenTrue } from "../../../../../helpers/rendering";

export const Container = styled(ButtonBase)`
  border-radius: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: ${Color.blue};
  color: ${Color.white};
  margin-top: 10px;
  &:hover {
    background-color: ${Color.blue};
    opacity: 0.8;
  }

  ${Media.tablet`
    width: 200px;
    margin-left: 10px;
    margin-top: 0;
  `}

  ${styleWhenTrue(prop("disabled"), css`
    &:hover {
      background-color: ${Color.dirtyWhite};
    }
  `)}
`;

export const NextIcon = styled(NextBaseIcon)`
  display: none;
  fill: ${Color.white};
  height: 20px;
  width: 20px;

  ${Media.tablet`
    display: inline-block;
  `}
`;
