import { handleApiError, phpApi } from "../../../../api";

export const getTaskData = (instanceId, taskId) => {
  return phpApi.get(
    `/api/content/${instanceId}/task/init`,
    { data: { task: taskId } },
  ).catch(handleApiError);
};

export const saveAnswers = (instanceId, taskAttemptId, taskId, answers) => {
  return phpApi.post(
    `/api/content/${instanceId}/task/${taskAttemptId}/save`,
    {
      answers,
      task: taskId,
    },
  ).catch(handleApiError);
};
