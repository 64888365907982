import React from "react";

import { SectionHeader } from "../../../../theme";
import { TrainingsView } from "../../trainings.constants";
import { getDateLabel } from "../../trainings.utils";
import { TrainingItem } from "../trainingItem/trainingItem.component";

import { Container } from "./trainingList.styled";

export const TrainingList = ({ events, viewType }) => {
  const courses = events.reduce((acc, curr) => {
    return acc.includes(curr.course.id) ? acc : [...acc, curr.course.id];
  }, []);

  if (!events.length) return null;

  return (
    <Container>
      <SectionHeader>
        {viewType === TrainingsView.DateList ? getDateLabel(events[0]) : events[0].course.type.name}
      </SectionHeader>
      {courses.map((course) => (
        <TrainingItem key={course} events={events.filter((event) => event.course.id === course)} />
      ))}
    </Container>
  );
};
