import React, { useCallback } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { StarsRate } from "../../../../components/starsRate/starsRate.component";
import { simpleDateFromTimestamp } from "../../../../helpers/date";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { useMediaListener } from "../../../../hooks/useMediaListener";
import { CompetenceActions } from "../../redux/competence.reducer";

import {
  Container,
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableBodyRow,
  TableBodyElement,
  ListTitle,
  ListItem,
  ListItemTitle,
  ListItemValue,
  EmptyHistoryWrapper,
  DisabledIcon,
} from "./competenceHistoryPanel.styled";

export const CompetenceHistoryPanel = ({ competenceHistory }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile } = useMediaListener();
  const { data, nextPage } = competenceHistory;

  const loadHistory = useCallback(
    () => dispatch(CompetenceActions.getCompetenceHistoryNextPage(nextPage)),
    [dispatch, nextPage],
  );

  const renderHistory = renderWhenTrue(() => (
    <InfiniteScroll
      loadMore={loadHistory}
      hasMore={!!nextPage}
    >
      {renderHistoryDesktopView(!isMobile)}
      {renderHistoryMobileView(isMobile)}
    </InfiniteScroll>
  ));

  const renderTableBody = () => {
    return data.map(({ skill, rate }, index) => (
      <TableBodyRow key={index}>
        <TableBodyElement>{simpleDateFromTimestamp(rate.date)}</TableBodyElement>
        <TableBodyElement>{skill.name}</TableBodyElement>
        <TableBodyElement>{skill.rateType.label}</TableBodyElement>
        <TableBodyElement>{rate.author}</TableBodyElement>
        <TableBodyElement>
          <StarsRate rate={rate.rate} />
        </TableBodyElement>
      </TableBodyRow>
    ));
  };

  const renderHistoryDesktopView = renderWhenTrue(() => (
    <Table>
      <TableHead>
        <tr>
          <TableHeader>{t("competence.competenceHistoryPanel.date")}</TableHeader>
          <TableHeader>{t("competence.competenceHistoryPanel.competenceMeasure")}</TableHeader>
          <TableHeader>{t("competence.competenceHistoryPanel.type")}</TableHeader>
          <TableHeader>{t("competence.competenceHistoryPanel.rater")}</TableHeader>
          <TableHeader>{t("competence.competenceHistoryPanel.rate")}</TableHeader>
        </tr>
      </TableHead>
      <TableBody>
        {renderTableBody()}
      </TableBody>
    </Table>
  ));

  const renderHistoryMobileView = renderWhenTrue(() => {
    return data.map(({ skill, rate }, index) => (
      <React.Fragment key={index}>
        <ListTitle>{skill.name}</ListTitle>
        <ListItem>
          <ListItemTitle>{t("competence.competenceHistoryPanel.date")}</ListItemTitle>
          <ListItemValue>{simpleDateFromTimestamp(rate.date)}</ListItemValue>
        </ListItem>
        <ListItem>
          <ListItemTitle>{t("competence.competenceHistoryPanel.rate")}</ListItemTitle>
          <StarsRate rate={rate.rate} />
        </ListItem>
        <ListItem>
          <ListItemTitle>{t("competence.competenceHistoryPanel.type")}</ListItemTitle>
          <ListItemValue>{skill.rateType.label}</ListItemValue>
        </ListItem>
        <ListItem>
          <ListItemTitle>{t("competence.competenceHistoryPanel.rater")}</ListItemTitle>
          <ListItemValue>{rate.author}</ListItemValue>
        </ListItem>
      </React.Fragment>
    ));
  });

  const renderEmptyHistory = renderWhenTrue(() => (
    <EmptyHistoryWrapper>
      <DisabledIcon />
      {t("competence.competenceHistoryPanel.noRatingYet")}
    </EmptyHistoryWrapper>
  ))

  return (
    <Container>
      {renderHistory(!!data.length)}
      {renderEmptyHistory(!data.length)}
    </Container>
  );
};
