import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { renderWhenTrueOtherwise } from "../../../../../../../helpers/rendering";

import {
  Container,
  Text,
  Circle,
  CheckIcon,
  CloseIcon,
} from "./answerBox.styled";

export const AnswerBox = ({
  className,
  type,
  correctTheme,
  wrongTheme,
  faded,
  onClick,
}) => {
  const { t } = useTranslation();

  const renderIcon = renderWhenTrueOtherwise(
    () => (
      <CheckIcon
        correct={correctTheme.toString()}
        wrong={wrongTheme.toString()}
        faded={faded.toString()}
      />
    ),
    () => (
      <CloseIcon
        correct={correctTheme.toString()}
        wrong={wrongTheme.toString()}
        faded={faded.toString()}
      />
    )
  );

  return (
    <Container
      className={className}
      onClick={onClick}
      correctTheme={correctTheme}
      wrongTheme={wrongTheme}
      faded={faded}
    >
      <Circle correctTheme={correctTheme} wrongTheme={wrongTheme} faded={faded}>
        {renderIcon(type)}
      </Circle>
      <Text>{t(`interactions.interactionTrueOrFalse.${type.toString()}`)}</Text>
    </Container>
  );
};

AnswerBox.propTypes = {
  className: PropTypes.string,
  type: PropTypes.bool,
  correctTheme: PropTypes.bool,
  wrongTheme: PropTypes.bool,
  faded: PropTypes.bool,
  onClick: PropTypes.func,
};
