import styled, { css } from "styled-components";
import { prop } from "ramda";

import { ReactComponent as ArrowSvg } from "../../../../images/arrow.svg";
import { ReactComponent as CloseSvg } from "../../../../images/close.svg";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { hexToRgba } from "../../../../helpers/colors";
import { Color } from "../../../../theme";
import { ReactComponent as TaskMessageSvg } from "../../images/taskMessage.svg";

export const Container = styled.div`
  position: fixed;
  background-color: ${Color.white};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  ${styleWhenTrue(prop("semiDarkBg"), css`
    background-color: ${Color.veryLightViolet2};
  `)}

  ${styleWhenTrue(prop("darkBg"), css`
    background-color: ${Color.violet};
  `)}
`;

export const GameContainer = styled.div`
  height: 600px;
  width: 946px;
  min-width: 946px;
  position: relative;
`;

export const BackgroundWrapper = styled.div`
  width: 100%;
`;

export const AnimationWrapper = styled.div`
  position: absolute;
  top: 35%;
  left: 10%;
  height: 300px;
  width: 185px;
  transform: scaleX(-1);
  transition: left 3s linear;
  z-index: 1;

  ${styleWhenTrue(prop("mirror"), css`
    transform: scaleX(1);
  `)}

  ${styleWhenTrue(prop("$onRightSide"), css`
    left: 70%;
  `)}

  ${styleWhenTrue(prop("resetAnim"), css`
    display: none;
  `)}
`;

export const ArrowImg = styled(ArrowSvg)`
  width: 12px;
  height: 19px;
  fill: ${Color.white};
  position: relative;
`;

export const LeftButton = styled.button`
  width: 60px;
  height: 60px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.blue2};
  border-radius: 50%;
  border: none;
  box-shadow: 0 10px 15px ${hexToRgba(Color.blue2, 0.15)};
  position: relative;
  flex-shrink: 0;

  ${ArrowImg} {
    left: -2px;
  }

  ${styleWhenTrue(prop("disabled"), css`
    pointer-events: none;
    background-color: ${Color.gray1};
    cursor: default;
  `)}

  ${styleWhenTrue(prop("isHidden"), css`
    visibility: hidden;
  `)}
`;

export const RightButton = styled(LeftButton)`
  ${styleWhenTrue(prop("isBlocked"), css`
    pointer-events: auto;
    cursor: default;
    background-color: ${Color.gray1};

    &:active, ${ArrowImg} {
      pointer-events: none;
    }
  `)}

  ${ArrowImg} {
    transform: rotate(180deg);
    left: 2px;
  }
`;

export const AnimTextWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const SmokWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(-1);
  width: 100%;
`;

export const TransitionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  pointer-events: none;

  ${styleWhenTrue(prop("isHidden"), css`
    display: none;
  `)}
`;

export const CloseIcon = styled(CloseSvg)`
  width: 14px;
  height: 14px;

  g {
    stroke: ${Color.deepGray};
  }
`;

export const CloseButton = styled.button`
  display: flex;
  padding: 0;
  margin: 0;
  background-color: ${Color.transparent};
  border: none;
  position: absolute;
  right: 31px;
  top: 34px;
`;

export const TaskMessage = styled(TaskMessageSvg)`
  position: absolute;
  right: 20px;
  top: 80px;
  z-index: 3;

  ${styleWhenTrue(prop("hidden"), css`
    display: none;
  `)}
`;
