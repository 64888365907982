import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { renderWhenTrue } from "../../../../../helpers/rendering";
import {
  Header,
  LightHeader,
  Subheader,
  HeaderContainer,
} from "../../sequentialQuizInteractions.styled";
import { ButtonText, ThemeType } from "../../sequentialQuizInteractions.constants";
import { NextButton } from "../nextButton/nextButton.component";

import {
  Container,
  AnswerTile,
  TileCircle,
  ButtonContainer,
  ErrorText,
} from "./quiz.styled";

export const Quiz = ({
  question,
  subquestion,
  selectedAnswers,
  setSelectedAnswers,
  answers,
  questionNumber,
  onNext,
  multiselectType,
  complianceTheme,
  isAnswerCorrect,
  restartQuestion,
  correctAnswersId,
  correctAnswersLength,
}) => {
  const { t } = useTranslation();
  const [theme, setTheme] = useState(ThemeType.DefaultTheme);
  const [buttonText, setButtonText] = useState(ButtonText.Accept);
  const [invalidMessage, setInvalidMessage] = useState("");

  const handleAnswerClick = (selected) => {
    if (multiselectType) {
      if (selectedAnswers.includes(selected)) {
        setSelectedAnswers(selectedAnswers.filter((answers) => answers !== selected));
      } else {
        setSelectedAnswers([...selectedAnswers, selected]);
      }
    } else {
      setSelectedAnswers([selected]);
    }
  };

  const renderAnswers = () => answers.map(
    ({text, id}) => (
      <AnswerTile
        selected={selectedAnswers.includes(id)}
        isCorrect={correctAnswersId.includes(id)}
        greyed={!multiselectType && !!selectedAnswers.length && !selectedAnswers.includes(id)}
        validated={theme !== ThemeType.DefaultTheme}
        onClick={() => handleAnswerClick(id)}
        key={id}
      >
        <TileCircle
          validated={theme !== ThemeType.DefaultTheme}
          isCorrect={correctAnswersId.includes(id)}
          selected={selectedAnswers.includes(id)}
        />
        {text}
      </AnswerTile>
    )
  );

  const handleNextClick = () => {
    if (complianceTheme) {
      setInvalidMessage("");
      if (theme === ThemeType.CorrectAnswerTheme) {
        setTheme(ThemeType.DefaultTheme);
        onNext();
      } else if (theme === ThemeType.WrongAnswerTheme) {
        setTheme(ThemeType.DefaultTheme);
        setSelectedAnswers([]);
        setButtonText(ButtonText.Accept);
        restartQuestion();
      } else {
        const newTheme = isAnswerCorrect ? ThemeType.CorrectAnswerTheme : ThemeType.WrongAnswerTheme;
        const newButtonText = isAnswerCorrect ? ButtonText.Next : ButtonText.TryAgain;
        setTheme(newTheme);
        setButtonText(newButtonText);
        if (selectedAnswers.length < correctAnswersLength) {
          setInvalidMessage(t("interactions.sequentialQuiz.quiz.notEnoughSelected"));
        }
      }
    } else {
      onNext();
    }
  };

  const renderSubheader = renderWhenTrue(() => (
    <Subheader>{subquestion}</Subheader>
  ));

  const renderLightHeader = renderWhenTrue(() => (
    <LightHeader>{t("interactions.sequentialQuiz.task")} {questionNumber}</LightHeader>
  ));

  const renderInvalidMessage = renderWhenTrue(() => (
    <ErrorText>{invalidMessage}</ErrorText>
  ));

  return (
    <Container>
      <HeaderContainer>
        {renderLightHeader(!!questionNumber)}
        <Header>{question}</Header>
        {renderSubheader(!!subquestion)}
      </HeaderContainer>
      {renderAnswers()}
      <ButtonContainer>
        {renderInvalidMessage(!!invalidMessage.length)}
        <NextButton
          onClick={handleNextClick}
          disabled={!selectedAnswers.length}
          complianceTheme={complianceTheme}
          text={buttonText}
        />
      </ButtonContainer>
    </Container>
  );
};

Quiz.propTypes = {
  question: PropTypes.string,
  selectedAnswers: PropTypes.array,
  setSelectedAnswers: PropTypes.func,
  answers: PropTypes.array,
  questionNumber: PropTypes.string,
  onNext: PropTypes.func,
  multiselectType: PropTypes.bool,
  complianceTheme: PropTypes.bool,
  isAnswerCorrect: PropTypes.bool,
  restartQuestion: PropTypes.func,
  correctAnswersId: PropTypes.array,
  correctAnswersLength: PropTypes.number,
};
