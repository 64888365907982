import React from "react";
import PropTypes from "prop-types";
import Picker from "@emoji-mart/react";
import EmojiData from "@emoji-mart/data";

import { currentLanguage } from "../../helpers/translations";

import { Container } from "./emojiPicker.styled";

export const EmojiPicker = ({ onSelect = Function.prototype }) => {
  return (
    <Container>
      <Picker
        data={EmojiData}
        onEmojiSelect={onSelect}
        previewPosition="none"
        searchPosition="none"
        locale={currentLanguage}
        theme="light"
        dynamicWidth={true}
      />
    </Container>
  );
};

EmojiPicker.propTypes = {
  className: PropTypes.string,
};
