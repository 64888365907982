import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { ThumbnailList } from "../../../../components/thumbnailList/thumbnailList.component";
import { Loader } from "../../../../components/loader/loader.component";
import { ModuleType, useModuleConfig } from "../../..";
import {
  selectIsPending,
  selectIsStarted,
  selectKnowledgeBaseItems,
} from "../../redux/gamification.selectors";
import { GamificationActions } from "../../redux/gamification.reducer";

import { ChallengesList } from "./challengesList/challengesList.component";
import { UpcomingMeeting } from "./upcomingMeeting/upcomingMeeting.component";
import { RulesBox } from "./rulesBox/rulesBox.component";
import { Ranking } from "./ranking/ranking.component";
import { Badges } from "./badges/badges.component";
import { Calendar } from "./calendar/calendar.component";
import { PointsBox } from "./pointsBox/pointsBox.component";
import {
  Container,
  Title,
  Row,
  Column,
  Thumbnails,
} from "./dashboard.styled";

export const Dashboard = ({ className }) => {
  const config = useModuleConfig(ModuleType.Gamification);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isPending = useSelector(selectIsPending);
  const started = useSelector(selectIsStarted);
  const knowledgeBaseItems = useSelector(selectKnowledgeBaseItems);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!isPending && !started) {
      navigate(`${config.urls[1]}/start`);
    } else {
      dispatch(GamificationActions.getKnowledgeBaseItems());
    }
  }, [navigate, isPending, started]);

  return (
    <Container className={className}>
      {isPending || !started ? (
        <Loader />
      ) : (
        <>
          <Title>
            {t("gamification.pageTitle")}
          </Title>
          <Row>
            <Column>
              <PointsBox />
              <UpcomingMeeting />
            </Column>
            <Column>
              <Calendar />
            </Column>
          </Row>
          <RulesBox parentPath={config.urls[1]} />
          <ChallengesList />
          {!!knowledgeBaseItems?.length && (
            <Thumbnails>
              <ThumbnailList
                title={knowledgeBaseItems[0].title}
                thumbnails={knowledgeBaseItems[0].items}
              />
            </Thumbnails>
          )}
          <Row>
            <Badges />
            <Ranking />
          </Row>
        </>
      )}
    </Container>
  );
};

Dashboard.propTypes = {
  className: PropTypes.string,
};
