import styled, { css } from "styled-components";
import { prop } from "ramda";

import { FontWeight, Color, Media } from "../../../../../../../theme"
import { hexToRgba } from "../../../../../../../helpers/colors";
import { styleWhenTrue } from "../../../../../../../helpers/rendering";
import { ReactComponent as ArrowBaseIcon } from "../../../../images/arrow.svg";

export const Container = styled.button`
  position: relative;
  width: 100%;
  height: 74px;
  background-color: ${Color.almostWhite};
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  font-size: 16px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.blue4};

  ${styleWhenTrue(prop("enableValidating"), css`
    background-color: ${Color.green};
    color: ${Color.white};
    pointer-events: none;

    ${styleWhenTrue(prop("wrongTheme"), css`
      background-color: ${Color.red4};
    `)}
  `)}

  ${Media.tablet`
    width: 254px;
  `}
`;

export const Label = styled.span`
  letter-spacing: 0;
  line-height: 22px;

  ${Media.tablet`
    line-height: 26px;
  `}
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  left: 0;
  top: 101%;
  background-color: ${Color.white};
  width: 100%;
  box-shadow: 0 1px 10px 0px ${hexToRgba(Color.black, 0.2)};
  z-index: 1;

  ${styleWhenTrue(prop("show"), css`
    display: block;
  `)}
`;

export const DropdownOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DropdownOption = styled.div`
  display: flex;
  align-items: center;
  min-height: 60px;
  font-size: 18px;
  font-weight: ${FontWeight.Normal};
  color: ${Color.almostBlack};
  cursor: pointer;
  padding-left: 22px;

  &:not(:first-of-type) {
    border-top: 2px solid ${Color.gray5};
  }

  &:hover {
    background-color: ${Color.whiteHover};
  }
`;

export const ArrowIcon = styled(ArrowBaseIcon)`
  height: 10px;
  width: 10px;
  fill: ${Color.blue4};

  ${styleWhenTrue(prop("enableValidating"), css`
    fill: ${Color.white};
  `)}

  ${styleWhenTrue(prop("open"), css`
    transform: rotate(-180deg);
  `)}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
