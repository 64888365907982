import { createActions, createReducer } from "reduxsauce";

export const { Types: VideoPlayerTypes, Creators: VideoPlayerActions } = createActions(
  {
    setIsPending: ["isPending"],
    getInitialData: ["contentId"],
    getInitialDataSuccess: ["data"],
    getRelatedVideos: ["contentId"],
    getRelatedVideosSuccess: ["relatedVideos"],
    getComments: ["contentId"],
    getCommentsSuccess: ["comments"],
    sendComment: ["comment", "onFinish"],
    sendCommentSuccess: ["comment"],
    deleteComment: ["commentId"],
    deleteCommentSuccess: ["comments"],
    editComment: ["comment"],
    editCommentSuccess: ["comments"],
    sendFinishAction: ["contentId", "actionString"],
    clearData: null,
  },
  { prefix: "VIDEO_PLAYER/" }
);

const INITIAL_STATE = {
  isPending: false,
  info: {
    name: "",
    tags: [],
    description: "",
  },
  structure: {
    action: "",
    passed: false,
    url: "",
  },
  comments: [],
  relatedVideos: [],
  instanceId: null,
};

const setIsPending = (state, { isPending }) => ({ ...state, isPending });

const getInitialDataSuccess = (state, { data }) => {
  const params = data.structure.params.reduce(
    (params, param) => ({
      ...params,
      [param.name]: param.value
    }),
    {}
  );

  return {
    ...state,
    info: data.info,
    structure: params,
    instanceId: data.instanceId,
  };
};

const getRelatedVideosSuccess = (state, { relatedVideos }) => ({
  ...state,
  relatedVideos,
});

const getCommentsSuccess = (state, { comments }) => ({
  ...state,
  comments,
});

const sendCommentSuccess = (state, { comment }) => ({
  ...state,
  comments: [...state.comments, comment],
});

const deleteCommentSuccess = (state, { comments }) => ({
  ...state,
  comments,
});

const editCommentSuccess = (state, { comments }) => ({
  ...state,
  comments,
});

const clearData = () => INITIAL_STATE;

export const reducer = createReducer(INITIAL_STATE, {
  [VideoPlayerTypes.SET_IS_PENDING]: setIsPending,
  [VideoPlayerTypes.GET_INITIAL_DATA_SUCCESS]: getInitialDataSuccess,
  [VideoPlayerTypes.GET_RELATED_VIDEOS_SUCCESS]: getRelatedVideosSuccess,
  [VideoPlayerTypes.GET_COMMENTS_SUCCESS]: getCommentsSuccess,
  [VideoPlayerTypes.SEND_COMMENT_SUCCESS]: sendCommentSuccess,
  [VideoPlayerTypes.DELETE_COMMENT_SUCCESS]: deleteCommentSuccess,
  [VideoPlayerTypes.EDIT_COMMENT_SUCCESS]: editCommentSuccess,
  [VideoPlayerTypes.CLEAR_DATA]: clearData,
});
