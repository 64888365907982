import React, { useMemo } from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";

import { TagList } from "../../../../components/tagList/tagList.component";
import { Float } from "../../../../components/float/float.component";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { Color } from "../../../../theme";
import { AppConfig } from "../../../../appConfig";

import {
  Container,
  Counter,
  BoldCounter,
  HeaderText,
  Separator,
  Text,
  FloatWrapper,
  TagListWrapper,
} from "./textBox.styled";

export const TextBox = ({
  className,
  currentCounter,
  overallCounter,
  tags,
  headerText,
  htmlText,
  onFloatClick = Function.prototype,
  wide = false,
}) => {
  const { t } = useTranslation();
  const isLessonHeader = currentCounter === 0;
  const text = useMemo(() => {
    return htmlText
      .replaceAll("#brand", Color.brandBasic)
      .replaceAll("#contentURL", AppConfig.content);
  }, [htmlText]);

  const renderCounter = renderWhenTrue(() => (
    <Counter>
      <BoldCounter>{currentCounter + 1}</BoldCounter>
      {" "}
      ({overallCounter})
    </Counter>
  ));

  const renderTags = renderWhenTrue(() => (
    <TagListWrapper>
      <TagList tags={tags} />
    </TagListWrapper>
  ));

  const renderSeparator = renderWhenTrue(() => <Separator />);

  const renderHeader = renderWhenTrue(() => (
    <>
      <HeaderText
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(headerText, { ADD_ATTR: ["target"] }) }}
        isLessonHeader={isLessonHeader}
      />
      {renderSeparator(!isLessonHeader || !tags.length)}
      {renderTags(isLessonHeader && !!tags.length)}
    </>
  ));

  const renderFloat = renderWhenTrue(() => (
    <FloatWrapper>
      <Float
        currentSection={currentCounter}
        sectionsOverall={overallCounter}
        title={t("lesson.scrollContent.introduction")}
        onClick={onFloatClick}
      />
    </FloatWrapper>
  ));

  return (
    <Container className={className} isLessonHeader={isLessonHeader} wide={wide}>
      {renderCounter(!!currentCounter)}
      {renderHeader(!!headerText)}
      <Text dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text, { ADD_ATTR: ["target"] }) }} />
      {renderFloat(isLessonHeader)}
    </Container>
  );
};

TextBox.propTypes = {
  className: PropTypes.string,
  currentCounter: PropTypes.number,
  overallCounter: PropTypes.number,
  tags: PropTypes.array.isRequired,
  headerText: PropTypes.string,
  htmlText: PropTypes.string.isRequired,
  onFloatClick: PropTypes.func,
};
