import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ButtonVariant, ButtonColor } from "../../../../components/button/button.constants";
import { useMediaListener } from "../../../../hooks/useMediaListener";
import { DropdownInput } from "../../../../components/dropdownInput/dropdownInput.component";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { Theme } from "../../../../appConfig";
import { filterItems, groupFilters } from "../../utils/filterHelper";
import { selectTags } from "../../redux/knowledgeBase.selectors";
import { Filter } from "../filter/filter.component";

import {
  Container,
  IconContainer,
  CloseIcon,
  HeadlineSmall,
  HeadlineBig,
  FiltersContainer,
  FiltersColumn,
  FilterCategory,
  ButtonRow,
  Button,
  TagFilters,
  Tag,
} from "./filters.styled";
import { IDDFilterTheme, FilterType } from "./filters.constants";

export const Filters = ({
  filters,
  closeFilters,
  switchFilter,
  clearFilters,
  setTagFilters,
  selectedTagFilters,
  filteredItemsLength,
  thumbnailLists,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaListener();
  const tagFilters = useSelector(selectTags);

  const isActiveFilter = (filter) =>
    !!filters.find(
      (el) => el.type === filter.type && el.value === filter.value
    );

  const isActiveTagFiler = (filter) => !!selectedTagFilters.find((tag) => tag.id === filter.id);

  const isFilteredListEmpty = (list, filter) =>
    !list.some((item) => filterItems(groupFilters([filter]), item));

  const isFilteredThumbnailsListEmpty = (filter) =>
    thumbnailLists.every(({ items }) => isFilteredListEmpty(items, filter));

  const handleClearFilters = () => {
    closeFilters();
    clearFilters();
  };

  const iddFilter = renderWhenTrue(() => renderFilter(FilterType.IDD));

  const renderFilter = (filter) => (
    <Filter
      name={t(filter.name)}
      disabled={isFilteredThumbnailsListEmpty(filter)}
      isActive={isActiveFilter(filter)}
      switchFilter={() => switchFilter(filter)}
      circleBackground={filter.circleBackground}
    />
  );

  const switchTagFilter = (filter) => {
    if (selectedTagFilters.find((tag) => tag.id === filter.id)) {
      const newTagFilters = selectedTagFilters.filter((tag) => tag.id !== filter.id);
      setTagFilters(newTagFilters);
    } else {
      setTagFilters([...selectedTagFilters, filter]);
    }
  };

  const onTagFilterChange = ({ target }) => {
    const filterToChange = tagFilters.find((filter) => filter.id === target.value);
    switchTagFilter(filterToChange);
  };

  const renderTagFilters = () => isMobile ? (
    <DropdownInput
      value={selectedTagFilters.map((filter) => filter.name)}
      options={tagFilters.map((filter) => {
        return { id: filter.id, label: filter.name };
      })}
      onChange={onTagFilterChange}
      name="tagfilters"
      placeholder={t("learning.filters.filters.chooseTags")}
      dropdownHeight={160}
      withSearchField
      multiple
    />
  ) : (
    <>
      <HeadlineSmall>{t("learning.filters.filters.tags")}</HeadlineSmall>
      <TagFilters>
        {tagFilters.map((filter) => (
          <Tag
            key={filter.id}
            onClick={() => switchTagFilter(filter)}
            selected={isActiveTagFiler(filter)}
          >
            {filter.name}
          </Tag>
        ))}
      </TagFilters>
    </>
  );

  const getCorrectMaterialsWord = () => {
    if (filteredItemsLength === 1) {
      return t("learning.filters.filters.materialsLabel_1");
    }
    if ([2, 3, 4].includes(filteredItemsLength % 10)) {
      return t("learning.filters.filters.materialsLabel_2");
    }
    return t("learning.filters.filters.materialsLabel_3");
  };

  return (
    <Container>
      <HeadlineBig>{t("learning.filters.filters.filterMaterials")}</HeadlineBig>
      <IconContainer onClick={closeFilters}>
        <CloseIcon />
      </IconContainer>
      <FiltersContainer>
        <FiltersColumn>
          <HeadlineSmall>{t("learning.filters.filters.type")}</HeadlineSmall>
          <FilterCategory>
            {renderFilter(FilterType.Cyclical)}
            {renderFilter(FilterType.Required)}
            {iddFilter(IDDFilterTheme.Visible === Theme.IDDFilter)}
          </FilterCategory>
        </FiltersColumn>
        <FiltersColumn>
          <HeadlineSmall>{t("learning.filters.filters.status")}</HeadlineSmall>
          <FilterCategory>
            {renderFilter(FilterType.StatusNotStarted)}
            {renderFilter(FilterType.StatusComplete)}
            {renderFilter(FilterType.StatusInProgress)}
            {renderFilter(FilterType.StatusFailed)}
          </FilterCategory>
        </FiltersColumn>
        <FiltersColumn>
          <HeadlineSmall>
            {t("learning.filters.filters.category")}
          </HeadlineSmall>
          <FilterCategory>
            {renderFilter(FilterType.Lesson)}
            {renderFilter(FilterType.Contents)}
            {renderFilter(FilterType.Files)}
          </FilterCategory>
        </FiltersColumn>
      </FiltersContainer>

      {!!tagFilters?.length && renderTagFilters()}

      <ButtonRow>
        <Button color={ButtonColor.Primary} $large onClick={closeFilters}>
          {t("learning.filters.filters.show")} {filteredItemsLength}{" "}
          {getCorrectMaterialsWord()}
        </Button>
        <Button variant={ButtonVariant.Outlined} $large onClick={handleClearFilters}>
          {t("learning.filters.filters.clearFilters")}
        </Button>
      </ButtonRow>
    </Container>
  );
};

Filters.propTypes = {
  filters: PropTypes.array,
  closeFilters: PropTypes.func,
  switchFilter: PropTypes.func,
  clearFilters: PropTypes.func,
  setTagFilters: PropTypes.func,
  filteredItemsLength: PropTypes.number,
  thumbnailLists: PropTypes.array,
};
