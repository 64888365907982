import React from "react";
import PropTypes from "prop-types";

import { Container, Text } from "./question.styled";

export const Question = ({ className, question }) => {
  return (
    <Container className={className}>
      <Text>{question.text}</Text>
    </Container>
  );
};

Question.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object,
};
