import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { ButtonColor } from "../../../../components/button/button.constants";
import { renderWhenTrue } from "../../../../helpers/rendering";

import {
  Container,
  CheckIcon,
  TextWrapper,
  BoldText,
  Text,
  Button,
} from "./finishBox.styled";

export const FinishBox = ({ className, isLastSlide, onClick }) => {
  const { t } = useTranslation();
  const buttonText = t(isLastSlide ? "interactions.watched" : "lesson.scrollContent.startTest");

  const renderDoTestText = renderWhenTrue(() => (
    <Text>{t("lesson.scrollContent.doTest")}</Text>
  ));

  return (
    <Container className={className}>
      <CheckIcon />
      <TextWrapper>
        <BoldText>{t("lesson.scrollContent.congratulations")}</BoldText>
        {renderDoTestText(!isLastSlide)}
      </TextWrapper>
      <Button onClick={onClick} color={ButtonColor.Primary}>
        {buttonText}
      </Button>
    </Container>
  );
};

FinishBox.propTypes = {
  className: PropTypes.string,
  isLastSlide: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};
