import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { ConfirmationModal } from "../../../../components/confirmationModal/confirmationModal.component";
import { ReactComponent as PlayCircleSvg } from "../../../../images/playCircle.svg";
import { selectRoomLive } from "../../redux/webinars.selectors";

export const StreamModal = ({ className, onConfirm, onClose, open }) => {
  const { t } = useTranslation();
  const roomLive = useSelector(selectRoomLive);
  const title = roomLive
    ? t("webinars.streamModal.stopStreaming")
    : t("webinars.streamModal.startStreaming");
  const modalText = roomLive
    ? t("webinars.streamModal.modalTextEnd")
    : t("webinars.streamModal.modalTextStart");

  const handleConfirmation = () => {
    onClose();
    onConfirm();
  };

  return (
    <ConfirmationModal
      open={open}
      title={title}
      HeaderIcon={PlayCircleSvg}
      onClose={onClose}
      onConfirm={handleConfirmation}
      className={className}
    >
      {modalText}
    </ConfirmationModal>
  );
};

StreamModal.propTypes = {
  className: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
