import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Button } from "../../../../components/button/button.component";
import { ButtonVariant } from "../../../../components/button/button.constants";

import {
  Container,
  Header,
  MonstersImg,
  MonstersGraph,
  MonsterOvals,
  MonstersBell,
  Clock,
  WirusImg,
} from "./monstersVideo.styled";

export const MonstersVideo = ({ className, onButtonClick }) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <MonstersGraph />
      <MonsterOvals />
      <MonstersBell />
      <Clock />
      <Header>{t("onboarding.monstersVideo.saveWorld")}</Header>
      <Button
        variant={ButtonVariant.Outlined}
        $large
        onClick={onButtonClick}
      >
        {t("onboarding.monstersVideo.watchVideo")}
      </Button>
      <MonstersImg />
      <WirusImg />
    </Container>
  );
};

MonstersVideo.propTypes = {
  className: PropTypes.string,
  onButtonClick: PropTypes.func,
};
