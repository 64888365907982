import React, { useState } from "react";
import PropTypes from "prop-types";

import { renderWhenTrue } from "../../../../../helpers/rendering";
import { transformTitle } from "../../onboardingInteractions.helpers";
import { ButtonRow, Title } from "../../onboardingInteractions.styled";
import { NextButton } from "../nextButton/nextButton.component";
import { InfoTip } from "../infoTip/infoTip.component";

import {
  BorderedTile,
  Circle,
  Container,
  TitleAndAnswersWrapper,
  CheckIcon,
  CloseIcon,
} from "./textAnswers.styled";

const AnswerTile = ({ onClick, correctTheme, wrongTheme, text }) => {
  const renderCheckIcon = renderWhenTrue(() => <CheckIcon />);
  const renderCloseIcon = renderWhenTrue(() => <CloseIcon />);

  return (
    <BorderedTile
      correctTheme={correctTheme}
      onClick={onClick}
      wrongTheme={wrongTheme}
    >
      <Circle correctTheme={correctTheme} wrongTheme={wrongTheme}>
        {renderCheckIcon(correctTheme)}
        {renderCloseIcon(wrongTheme)}
      </Circle>
      {text}
    </BorderedTile>
  );
};

export const TextAnswers = ({
  title,
  buttonText,
  answers,
  correctAnswerId,
  next,
  infotipText
}) => {
  const [clickedIndex, setClickedIndex] = useState(null);
  const [disabledButton, setDisabledButton] = useState(true);

  const renderInfoTip = renderWhenTrue(() => (
    <InfoTip sentences={infotipText} />
  ));

  const onOptionClick = (index) => {
    setDisabledButton(correctAnswerId !== index);
    setClickedIndex(index);
  };

  const renderAnswers = () =>
    answers.map(({ id, text }) => (
      <AnswerTile
        onClick={() => onOptionClick(id)}
        wrongTheme={clickedIndex === id && id !== correctAnswerId}
        correctTheme={clickedIndex === id && id === correctAnswerId}
        text={text}
        key={text}
      />
    ));

  return (
    <Container>
      {renderInfoTip(!!infotipText)}
      <TitleAndAnswersWrapper>
        <Title>{transformTitle(title)}</Title>
        {renderAnswers()}
      </TitleAndAnswersWrapper>
      <ButtonRow>
        <NextButton
          onClick={next}
          disabled={disabledButton}
          text={buttonText}
        />
      </ButtonRow>
    </Container>
  );
};

TextAnswers.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  answers: PropTypes.array,
  correctAnswerId: PropTypes.number,
  next: PropTypes.func,
};
