import React from "react";

const TASK = "task";
const FAILURE = "failure";


const FAILURE_MESSAGES = [
  "SPRÓBUJ JESZCZE RAZ!", "NIE, TO NIE TAK", "ZNOWU POMYŁKA",
  "PRZYŁÓŻ SIĘ BARDZIEJ", ["NIE WIESZ, SPRAWDŹ", "W MATERIAŁACH SZKOLENIOWYCH"]
];

export default class TaskScreen extends React.Component {
  constructor() {
    super();
    this.state = {
      screen: TASK,
      failureMessages: FAILURE_MESSAGES,
      radios: []
    };
  }

  onFailure() {
    const { failureMessages: [message, ...rest] } = this.state;

    this.setState({
      screen: FAILURE,
      failureMessage: message,
      failureMessages: rest.length > 0 ? rest : [message]
    });
  }

  checkAnswers(){
    const correctAnswer = [1,1,1,0,1,0,1,0,1];
    const answers = this.state.radios;
    return JSON.stringify(correctAnswer) === JSON.stringify(answers);
  }

  verify() {
    const { onSuccess, onFailure } = this.props;
    if (this.checkAnswers()) {
      onSuccess();
    } else {
      onFailure();
    }
  }

  radioChange(event){
    let newState = this.state;
    newState.radios[parseInt(event.target.name.split("-")[1],10)] = parseInt(event.target.value,10);
    this.setState(newState);
  }

  render() {
    const elements = this.props.content.map((element, index) => {
      return (
        <div key={index} className="js-element radio-element" type={"text"} >
          <p>
            {element.content}{" "}
          </p>
          <div className={"radio-group"}>
            <label>
                            Tak
              <input type="radio" name={"check-"+index} value="1" onChange={this.radioChange.bind(this)}/>
            </label>
            <label>
                            Nie
              <input type="radio" name={"check-"+index} value="0" onChange={this.radioChange.bind(this)}/>
            </label>
          </div>
        </div>
      );
    });

    const header = this.props.header;
    const instructions = this.props.instructions;

    return (
      <div>
        <div className={"header"}>
          <h3>
            {header}
            <div className={"bolded"}>{instructions}</div>
          </h3>
        </div>
        {elements}
        <div className="button-wrap">
          <button className="btn-check" onClick={ () => this.verify() }></button>
        </div>
      </div>
    );
  }
}
