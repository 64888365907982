import styled, { css } from "styled-components";
import { prop } from "ramda";

import { ReactComponent as UploadIconBase } from "../../../../../images/upload.svg";
import { ReactComponent as CloseIconBase } from "../../../../../images/close2.svg";
import { styleWhenTrue } from "../../../../../helpers/rendering";
import { Color, FontWeight, Media } from "../../../../../theme";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 10px 0;
  position: relative;

  ${Media.tablet`
    height: 550px;
    padding: 0;
  `}
`;

export const CheckboxContainer = styled.div`
  padding: 0 30px 20px;

  label {
    height: auto;
  }
`;

export const CircleIconWrapper = styled.div`
  height: 140px;
  width: 140px;
  border-radius: 100%;
  background-color: ${Color.almostWhite};
  display: flex;
  justify-content: center;
  align-items: center;

  ${styleWhenTrue(prop("isDragActive"), css`
    opacity: 0.5;
  `)}
`;

export const UploadIcon = styled(UploadIconBase)`
  width: 140px;
  fill: ${() => Color.brandBasic};
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  ${Media.tablet`
    width: 375px;
    margin-bottom: 50px;
  `}
`;

export const Text = styled.span`
  margin-top: 20px;
  font-size: 14px;
  line-height: 22px;
  color: ${Color.deepGray};
  text-align: center;

  ${Media.tablet`
    font-size: 16px;
  `}
`;

export const Subtext = styled.span`
  margin: 5px 0 25px;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.17px;
  color: ${Color.gray4};
  text-align: center;

  ${Media.tablet`
    font-size: 14px;
  `}
`;

export const FileInput = styled.input`
  visibility: hidden;
`;

export const FileInputLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 145px;
  height: 43px;
  border-radius: 3px;
  background-color: ${() => Color.brandBasic};
  color: ${Color.white};
  cursor: pointer;
  transition: background-color 150ms ease-in-out;
  font-size: 10px;
  font-weight: ${FontWeight.Bold};
  text-transform: uppercase;

  &:hover {
    opacity: 0.6;
  }

  ${styleWhenTrue(prop("disabled"), css`
    background-color: ${Color.gray5};
    pointer-events: none;
  `)}
`;

export const HiddenCanvas = styled.canvas`
  display: none;
`;

export const HiddenVideo = styled.video`
  display: none;
`;

export const InputWrapper = styled.div`
  position: relative;
`;


export const Alert = styled.div`
  margin: 20px;
  background-color: ${Color.lightPink};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 24px;
`

export const AlertText = styled.span`
  color: ${Color.error};
  font-size: 10px;
  text-align: center;
`

export const CloseIcon = styled(CloseIconBase)`
  height: 10px;
  width: 10px;
  fill: ${Color.error};
  position: absolute;
  top: 19px;
  right: 13px;
  cursor: pointer;
`;
