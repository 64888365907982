/* eslint-disable @typescript-eslint/no-explicit-any */
import { prop } from 'ramda';
import { createSelector, Selector } from 'reselect';

// TODO: remove this ANY
export const selectEmailVerificationDomain: any = prop('emailVerification');

export const selectIsTokenValid: Selector = createSelector(
  selectEmailVerificationDomain, prop('tokenVerifiedStatus'),
);
