import React, { Component } from "react";
import i18n from "i18next";
import { connect } from "react-redux";

import { withLocation, withNavigation } from "../../helpers/hocs";
import { UserRoleName } from "../../userContext/userContext.constants";
import { LessonActions } from "../redux/lesson.reducer";
import { Button } from "../../UIElements";
import Interaction from "../interaction";

import { TimeoutPopup, Message } from "./slide.styled";

class Slide extends Component {
  constructor() {
    super();
    this.state = {
      showTimeout: false,
      timer: 30,
      isCompleted: null
    };
    this.timeout = false;
  }

  showTimeout() {
    const { passed } = this.props.slide.params;

    this.setState({
      timer: 30,
      showTimeout: true,
      isCompleted: passed
    });

    if(!passed) {
      this.timeout = window.setInterval(() => {
        let timer = this.state.timer - 1;
        this.setState({ ...this.state, timer });
        if (timer === 0) {
          window.clearInterval(this.timeout);
        }
      }, 1000);
    }
  }

  showButtons() {
    this.setState({
      showTimeout: true,
      isCompleted: true
    });
  }

  hideTimeout() {
    this.fixBuggyPlayAgainCase();

    window.clearInterval(this.timeout);
    this.setState({ ...this.state, showTimeout: false });
  }

  setInteractionComplete(id, slideId) {
    const {
      markCompleted,
      slidesOverall,
      currentSlideIndex,
      user,
      navigate,
      location,
    } = this.props;
    const { role } = user.data.user;
    const avoidSummary = currentSlideIndex === slidesOverall - 1
      && role.name === UserRoleName.Onboarding
      && id === slideId.children[slideId.children.length - 1].id;

    markCompleted(id, slideId.parentId);

    if (avoidSummary) {
      const { isLastOnboardingTest } = location.state;
      navigate(
        `/onboarding${isLastOnboardingTest ? "" : "#next"}`, {
          state: isLastOnboardingTest ? undefined : {
            currentView: location.state?.currentView,
            currentStage: location.state?.currentStage,
          }
        }
      );
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!newProps.slide.children.find(interaction => interaction.completed === false)) {
      this.props.callComplete();
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.timeout);
  }

  fixBuggyPlayAgainCase() {
    const interactions = this.props.slide.children;
    const hasInteractionAfterVideo = interactions[0].type === "video" && interactions[1] && interactions[1].type === "interaction";
    if (hasInteractionAfterVideo) this.props.setSlideInteraction(interactions[0].id);
  }

  render() {
    const { isCompleted } = this.state;
    const {
      baseUrl,
      nav,
      ui,
      slide,
      onListClick,
      pageTitle,
      currentSlideIndex,
      slidesOverall,
      tags,
    } = this.props;
    const currentInteraction =
      this.props.slide.children.find(interaction => interaction.id === nav.currentInteraction) ||
      this.props.slide.children[0];
    const currentInteractionIndex =
      this.props.slide.children.findIndex(interaction => interaction.id === nav.currentInteraction) || 0;
    const hasNextInteraction = this.props.slide.children[currentInteractionIndex + 1] ? true : false;
    const isVideo = currentInteraction.type === "video";

    return (
      <div style={{ position: "relative", width: "100%", minHeight: "100%" }}>
        {this.state.showTimeout && (
          <div style={{ position: "relative", display: "flex", flexFlow: "column", justifyContent: "center" }}>
            <TimeoutPopup isVideoLesson={isVideo}>
              <Button
                marginTop={"0px"}
                width={"150"}
                marginRight={"unset"}
                marginLeft={"unset"}
                onClick={() => {
                  this.hideTimeout();
                }}
              >
                { i18n.t("lesson.slide.slide.playAgain") }
              </Button>

              { !isCompleted && this.state.timer > 0 && <Message>{ i18n.t("lesson.slide.slide.playAgain") } {this.state.timer} </Message>}

              {this.state.timer > 0 && (
                <Button
                  marginTop={"0px"}
                  width={"150"}
                  marginRight={"unset"}
                  marginLeft={"unset"}
                  onClick={() => {
                    this.setInteractionComplete(currentInteraction.id, slide, true);
                  }}
                >
                  { i18n.t("global.next") }
                </Button>
              )}
            </TimeoutPopup>
          </div>
        )}
        {currentInteraction &&
          !this.state.showTimeout && (
          <div>
            <Interaction
              type={currentInteraction.type}
              key={currentInteraction.id}
              id={currentInteraction.id}
              params={currentInteraction.params}
              data={currentInteraction.children}
              baseUrl={baseUrl}
              ui={ui}
              callComplete={() => {
                const shouldShowPrompt = hasNextInteraction;
                shouldShowPrompt
                  ? this.setInteractionComplete(currentInteraction.id, slide)
                  : this.showTimeout(currentInteraction.id);
              }}
              onListClick={onListClick}
              title={pageTitle}
              passed={slide.params.passed}
              subtitle={slide.params.title}
              currentSlideIndex={currentSlideIndex}
              slidesOverall={slidesOverall}
              tags={tags}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  nav: state.lesson.nav,
  ui: state.ui,
  user: state.userContext,
});

const mapDispatchToProps = dispatch => ({
  markCompleted: (id, pageId) => dispatch(LessonActions.setInteractionCompleted(id, pageId)),
  setSlideInteraction: (interactionId) => dispatch(LessonActions.setSlideInteraction(interactionId))
});

export default withNavigation(withLocation(connect(mapStateToProps, mapDispatchToProps)(Slide)));
