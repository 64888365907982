import React from "react";
import PropTypes from "prop-types";

import { getIcon } from "./icon.utils";
import { Container } from "./icon.styled";

export const Icon = ({ className, name }) => {
  const isMedal = name.startsWith("award");
  const Svg = getIcon(name);

  return (
    <Container className={className} noAward={!isMedal}>
      <Svg />
    </Container>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
};
