import styled from "styled-components";

import { Button as ButtonBase } from "../../../../components/button/button.component";
import { Media } from "../../../../theme/media";
import { TextWrapper, Content as ContentBase } from "../entry/entry.styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${Media.tablet`
    min-width: 600px;
  `}
`;

export const Content = styled(ContentBase)`
  padding: 0 20px 40px;
  overflow: auto;
  flex: none;
  flex-shrink: 1;
  border: none;

  ${Media.tablet`
    padding: 0 30px 40px;
  `}
`;

export const EntryWrapper = styled.div`
  margin: 15px 0;
`;

export const Wrapper = styled(TextWrapper)`
  overflow: auto;
`;

export const Actions = styled.div`
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${Media.tablet`
    padding: 30px;
  `}
`;

export const Button = styled(ButtonBase)`
  & + & {
    margin-left: 10px;
  }
`;
