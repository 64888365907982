import styled, { css } from "styled-components";
import { propEq } from "ramda";

import { ReactComponent as DragSvg } from "../../../../images/drag.svg";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color, Media } from "../../../../theme";
import { AnswerStatus } from "../../../interactions.constants";

export const Container = styled.div`
  border: 1px solid ${Color.blue5};
  background-color: ${Color.white};
  padding: 0 20px;
  height: 90px;
  display: flex;
  gap: 15px;
  align-items: center;

  &:hover {
    background-color: ${Color.whiteHover};
  }

  ${styleWhenTrue(propEq(AnswerStatus.CorrectChecked, "$status"), css`
    border: 1px solid ${Color.green};
    background-color: ${Color.lightGreen};
  `)}

  ${styleWhenTrue(propEq(AnswerStatus.WrongChecked, "$status"), css`
    border: 1px solid ${Color.red};
    background-color: ${Color.lightPink};
  `)}

  ${Media.tablet`
    height: 70px;
  `}
`;

export const DragIcon = styled(DragSvg)`
  flex-shrink: 0;
  width: 8px;
  height: 16px;
`;
