import dayjs from "dayjs";

export const getCalendarEvent = (trainingData, isIcsFile) => {
  const locationString = `${trainingData.location?.name}, ${trainingData.location?.address}, ${trainingData.location?.postcode} ${trainingData.location?.city}`;
  const webinarLink = trainingData.videoId
    ? `${window.location.origin}/wideo/${trainingData.videoId}`
    : trainingData.roomId
      ? `${window.location.origin}/webinar/${trainingData.roomId}`
      : trainingData.webinarUrl;

  return {
    title: trainingData.course?.name,
    description: trainingData.course.description?.replaceAll("\r", ""),
    start: dayjs.unix(trainingData.date.from),
    end: dayjs.unix(trainingData.date.to || trainingData.date.from),
    location: trainingData.location
      ? isIcsFile
        // eslint-disable-next-line no-useless-escape
        ? locationString.replaceAll(",", "\\\,")
        : locationString
      : webinarLink,
  };
};
