import React from "react";
import PropTypes from "prop-types";

import { Bullet, ListItem } from "./bulletList.styled";

export const BulletList = ({ text, colorType }) => {
  const textSentences = text.split("\n").filter((point) => point.length > 0);

  return textSentences.map((sentence, index) => (
    <ListItem key={index}>
      <Bullet colorType={colorType} />
      {sentence}
    </ListItem>
  ));
};

BulletList.propTypes = {
  text: PropTypes.string,
  colorType: PropTypes.string,
};
