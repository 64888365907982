import { AppConfig } from "../../../../appConfig";

export const getData = (url, setData, setIsPending, setAvatarAnimation, setWinnerAnimation) => {
  fetch(url)
    .then(response => response.json())
    .then(jresp => {
      setData(jresp);
      setIsPending(false);
      fetchAvatar(jresp.opponentAvatar, setAvatarAnimation);
      fetchAnimation(jresp.opponentAnimation, setWinnerAnimation);
    });
};

export const fetchAvatar = (avatar, setAvatarAnimation) => {
  fetch(`${AppConfig.content}/assets${avatar}`)
    .then(response => response.json())
    .then(jresp => {
      setAvatarAnimation(jresp);
    });
};

export const fetchAnimation = (animation, setWinnerAnimation) => {
  fetch(`${AppConfig.content}/assets${animation}`)
    .then(response => response.json())
    .then(jresp => {
      setWinnerAnimation(jresp);
    });
};