import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import { Avatar } from "../../../../components/avatar/avatar.component";
import { AvatarType } from "../../../../components/avatar/avatar.constants";
import { ConfirmationModal } from "../../../../components/confirmationModal/confirmationModal.component";
import { Tooltip } from "../../../../components/tooltip/tooltip.component";
import { WebinarsActions } from "../../redux/webinars.reducer";
import { selectParticipants } from "../../redux/webinars.selectors";
import { RoomType } from "../../webinars.constants";
import { BroadcastType } from "../video/video.constants";

import {
  RoomRow,
  Dropdown,
  ArrowIcon,
  RoomName,
  Amount,
  GoToRoomButton,
  AddUsersButton,
  AddUsersIcon,
  RemoveButton,
  TrashIcon,
  UserRow,
  FullName,
  ExcludeIcon,
  ExcludeButton,
  ExitIcon,
  SquaresIcon,
} from "./roomDetailsDropdown.styled";

export const RoomDetailsDropdown = ({
  room,
  showAddUsersView,
  removeRoom,
  excludeUser,
  isRoomOpen,
}) => {
  const { t } = useTranslation();
  const matchParams = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const participants = useSelector(selectParticipants);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const roomParticipants = room.participants
    ? room.participants.list
    : participants.filter((participant) => room.participantIds?.includes(participant.id));
  const filteredParticipants = roomParticipants.filter(
    (participant) => participant.role !== BroadcastType.Presenter && participant.connected
  );
  const goToRoomEnabled = room.type === RoomType.MeetingGroup;

  const openRemoveModal = (event) => {
    event.stopPropagation();
    setRemoveModalOpen(true);
  };

  const closeRemoveModal = () => {
    setRemoveModalOpen(false);
  };

  const goToRoom = () => {
    if (room.id === matchParams.id) return;

    navigate(`/webinar/${room.id}`);
    dispatch(WebinarsActions.handleLeaveRoom());
    dispatch(WebinarsActions.joinRoom(room.id));
  };

  return (
    <>
      <RoomRow onClick={() => setDropdownOpen(!dropdownOpen)}>
        <ArrowIcon open={dropdownOpen} />
        <RoomName>{room.name}</RoomName>
        <Amount>({filteredParticipants.length})</Amount>
        {goToRoomEnabled && (
          <Tooltip title={t("webinars.roomDetailsDropdown.goToRoom")}>
            <GoToRoomButton onClick={goToRoom}>
              {t("webinars.roomDetailsDropdown.go")}
            </GoToRoomButton>
          </Tooltip>
        )}
        <Tooltip title={t("webinars.roomDetailsDropdown.addUserToRoom")}>
          <AddUsersButton onClick={showAddUsersView}>
            <AddUsersIcon />
          </AddUsersButton>
        </Tooltip>
        {isRoomOpen ? (
          <Tooltip title={t("webinars.roomDetailsDropdown.closeRoom")}>
            <RemoveButton onClick={openRemoveModal}>
              <ExcludeIcon />
            </RemoveButton>
          </Tooltip>
        ) : (
          <Tooltip title={t("webinars.roomDetailsDropdown.remove")}>
            <RemoveButton onClick={openRemoveModal}>
              <TrashIcon />
            </RemoveButton>
          </Tooltip>
        )}
      </RoomRow>
      {dropdownOpen && (
        <Dropdown>
          {filteredParticipants.map((participant) => (
            <UserRow key={participant.id}>
              <Avatar
                src={participant.user.avatar}
                name={`${participant.name}`}
                type={AvatarType.Small}
              />
              <FullName>{participant.name}</FullName>
              <Tooltip title={t("webinars.roomDetailsDropdown.removeUser")}>
                <ExcludeButton onClick={() => excludeUser(participant)}>
                  <ExcludeIcon />
                </ExcludeButton>
              </Tooltip>
            </UserRow>
          ))}
        </Dropdown>
      )}
      <ConfirmationModal
        open={removeModalOpen}
        title={isRoomOpen
          ? t("webinars.roomDetailsDropdown.closeRoom")
          : t("webinars.roomDetailsDropdown.roomRemoving")
        }
        HeaderIcon={isRoomOpen ? SquaresIcon : ExitIcon}
        onClose={closeRemoveModal}
        onConfirm={removeRoom}
        confirmText={isRoomOpen
          ? t("webinars.roomDetailsDropdown.closeRoom")
          : t("webinars.roomDetailsDropdown.remove")
        }
        declineText={t("global.cancel")}
      >
        {isRoomOpen
          ? `${t("webinars.roomDetailsDropdown.closeRoomQuestion")} ${room.name}?`
          : t("webinars.roomDetailsDropdown.removeQuestion")
        }
      </ConfirmationModal>
    </>
  );
};
