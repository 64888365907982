import { all, put, takeLatest } from "redux-saga/effects";

import { deepClone } from "../../helpers";
import { store } from "../../store";
import * as lessonApi from "../services/api";
import { findQuestionAnswers, verifyInteractionParent } from "../lesson.utils";

import { LessonTypes, LessonActions } from "./lesson.reducer";

function* getInitialData({ id }) {
  yield put(LessonActions.setIsLoading(true));
  const initialData = yield lessonApi.getInitialData(id);
  const relatedData = yield lessonApi.getRelated(id);
  const commentsData = yield lessonApi.getComments(id);
  yield put(LessonActions.getInitialDataSuccess(id, initialData.data));
  yield put(LessonActions.getRelatedSuccess(relatedData.data));
  yield put(LessonActions.setComments(commentsData.data.data));
  yield put(LessonActions.setIsLoading(false));
}

function* addComment({ comment }) {
  const { data } = yield lessonApi.addComment(comment);
  yield put(LessonActions.setComments(data.data));
}

function* setInteractionCompleted({ id, pageId, findNext = true }) {
  const { pages, instanceId } = store.getState().lesson;
  const completedSlide = verifyInteractionParent(deepClone(pages), id);

  yield put(LessonActions.setInteractionCompletedSuccess(id, pageId, findNext));

  if (completedSlide) {
    const { data } = yield lessonApi.saveActions(instanceId, completedSlide);
    yield put(LessonActions.setActionsQueue(data || ""));
  } else {
    yield put(LessonActions.setActionsQueue(""));
  }
}

function* setQuestionCompleted({ id }) {
  const { quizes, instanceId } = store.getState().lesson;
  const completedSlide = findQuestionAnswers(quizes[0], id);
  yield put(LessonActions.setQuestionCompletedSuccess(id));

  if (completedSlide) {
    yield lessonApi.setQuestionCompleted(instanceId, quizes, completedSlide);
    yield put(LessonActions.setQuizCompleted());
  }
}

function* setQuizCompleted() {
  const { quizes, instanceId } = store.getState().lesson;
  const quiz = quizes[0];
  const shouldCheckQuiz = quiz.children.find((question) => !question.completed) ? false : true;

  if (shouldCheckQuiz) {
    const { data } = yield lessonApi.saveQuiz(instanceId, quiz.id);
    yield put(LessonActions.setQuizCompletedSuccess(data));
  } else {
    yield put(LessonActions.setActionsQueue(""));
  }
}

export function* watchLesson() {
  yield all([
    yield takeLatest(LessonTypes.GET_INITIAL_DATA, getInitialData),
    yield takeLatest(LessonTypes.ADD_COMMENT, addComment),
    yield takeLatest(LessonTypes.SET_INTERACTION_COMPLETED, setInteractionCompleted),
    yield takeLatest(LessonTypes.SET_QUESTION_COMPLETED, setQuestionCompleted),
    yield takeLatest(LessonTypes.SET_QUIZ_COMPLETED, setQuizCompleted),
  ]);
}
