import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Container, NextIcon } from "./nextButton.styled";

export const NextButton = ({ className, onClick, disabled, text }) => {
  const { t } = useTranslation();

  return (
    <Container
      className={className}
      onClick={onClick}
      disabled={disabled}
      $large
    >
      {t(`nextButton.nextButton.${text}`)}
      <NextIcon />
    </Container>
  );
};

NextButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  text: PropTypes.string,
};
