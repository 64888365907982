import React from "react";
import PropTypes from "prop-types";

import { Container } from "./progressWithChildren.styled";

export const ProgressWithChildren = ({
  className,
  large,
  value,
  maxValue,
  strokeWidth,
  children,
  variant,
}) => (
  <Container
    className={className}
    large={large}
    value={value}
    maxValue={maxValue}
    strokeWidth={strokeWidth}
    variant={variant}
  >
    {children}
  </Container>
);

ProgressWithChildren.propTypes = {
  className: PropTypes.string,
  large: PropTypes.bool,
  value: PropTypes.number,
  maxValue: PropTypes.number,
  strokeWidth: PropTypes.number,
  children: PropTypes.node,
  variant: PropTypes.string,
};
