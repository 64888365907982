import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import plLocale from "@fullcalendar/core/locales/pl";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import EventTooltip from "@mui/material/Tooltip";

import { currentLanguage } from "../../../../helpers/translations";
import { useMediaListener } from "../../../../hooks/useMediaListener";
import { Color, SectionHeader } from "../../../../theme";
import { CalendarPopup } from "../calendarPopup/calendarPopup.component";

import {
  Container,
  CalendarBox,
  EventContainer,
  EventTitle,
  EventTime,
  EventTooltipProps,
} from "./calendar.styled";

export const Calendar = ({ events }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isTabletWide } = useMediaListener();
  const [timeEventVisible, setTimeEventVisible] = useState(!isTabletWide);
  const calendarRef = useRef();

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.changeView(isTabletWide ? "dayGridMonth" : "timeGridDay");
    setTimeEventVisible(!isTabletWide);
  }, [isTabletWide]);

  const handleViewChange = ({ view }) => {
    setTimeEventVisible(view.type === "timeGridDay");
  };

  return (
    <Container>
      <SectionHeader>{t("trainings.calendar.calendar")}</SectionHeader>
      <CalendarBox>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin]}
          initialView={isTabletWide ? "dayGridMonth" : "timeGridDay"}
          locale={currentLanguage === "pl" ? plLocale : undefined}
          headerToolbar={{
            start: "prev,next today title",
            center: "",
            end: isTabletWide ? "timeGridDay timeGridWeek dayGridMonth" : "",
          }}
          buttonText={{
            today: t("trainings.calendar.today"),
          }}
          events={events}
          displayEventTime={false}
          eventColor={Color.brandBasic}
          eventDisplay="block"
          contentHeight={780}
          eventContent={({ event }) => (
            <EventContainer onClick={() => navigate(event.id)}>
              <EventTooltip
                title={
                  <CalendarPopup
                    title={event.title}
                    start={event.extendedProps.startDate}
                    end={event.extendedProps.endDate}
                    seatsAvailable={event.extendedProps.seatsAvailable}
                    location={event.extendedProps.location}
                    category={event.extendedProps.categoryEnum}
                  />
                }
                placement="bottom-start"
                componentsProps={EventTooltipProps}
              >
                <EventTitle>
                  {timeEventVisible && (
                    <EventTime>{dayjs(event.extendedProps.startDate).format("HH:mm")}</EventTime>
                  )}
                  {" "}
                  {event.title}
                </EventTitle>
              </EventTooltip>
            </EventContainer>
          )}
          datesSet={handleViewChange}
          nowIndicator={true}
        />
      </CalendarBox>
    </Container>
  );
};
