import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { cond, equals, always } from "ramda";
import { useDispatch } from "react-redux";

import { ReactComponent as ArticleIcon } from "../../../../images/article.svg";
import { ReactComponent as PostIcon } from "../../images/addPost.svg";
import { Icon } from "../../../../components/icon/icon.component";
import { PostForm } from "../../../../components/postForm/postForm.component";
import { ArticleForm } from "../../../../components/articleForm/articleForm.component";
import { NewsActions } from "../../redux/news.reducer";

import { TabType } from "./addNews.constants";
import { Container, Tabs, Tab, Content, Label } from "./addNews.styled";

export const AddNews = ({ className }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState();

  const handlePostTabClick = useCallback(() => setCurrentTab(TabType.AddPost), []);
  const handleArticleTabClick = useCallback(() => setCurrentTab(TabType.AddArticle), []);

  const handleFormClose = useCallback(() => setCurrentTab(null), []);

  const handleEditPost = (values, onFinish) => {
    dispatch(NewsActions.editPost(values, onFinish));
  };

  const handleSavePost = (values, onFinish) => {
    dispatch(NewsActions.savePost(values, onFinish));
  };

  const handleEditArticle = (id, article, onFinish) => {
    dispatch(NewsActions.editArticle(id, article, onFinish));
  };

  const handleSaveArticle = (values, onFinish) => {
    dispatch(NewsActions.saveArticle(values, onFinish));
  };

  const getTabContent = cond([
    [equals(TabType.AddPost), always(() => (
      <PostForm
        onSubmit={handleFormClose}
        onCancel={handleFormClose}
        onEdit={handleEditPost}
        onSave={handleSavePost}
      />)
    )],
    [equals(TabType.AddArticle), always(() => (
      <ArticleForm
        onSubmit={handleFormClose}
        onCancel={handleFormClose}
        onEdit={handleEditArticle}
        onSave={handleSaveArticle}
      />
    ))]
  ]);

  const TabContent = currentTab ? getTabContent(currentTab) : React.Fragment;

  return (
    <Container className={className}>
      <Tabs>
        <Tab
          active={currentTab === TabType.AddPost}
          onClick={handlePostTabClick}
        >
          <Icon size={16}>
            <PostIcon />
          </Icon>
          <Label>{t("news.addNews.post.label")}</Label>
        </Tab>
        <Tab
          active={currentTab === TabType.AddArticle}
          onClick={handleArticleTabClick}
          desktopOnly
        >
          <Icon size={16}>
            <ArticleIcon />
          </Icon>
          <Label>{t("news.addNews.article.label")}</Label>
        </Tab>
      </Tabs>
      <Content>
        <TabContent />
      </Content>
    </Container>
  );
};


AddNews.propTypes = {
  className: PropTypes.string,
};
