import React from "react";
import PropTypes from "prop-types";

import {
  Container,
  EyeImage,
  LockImage,
  TickImage,
  TaskImage,
  Title,
} from "./task.styled";

export const Task = ({
  className,
  title,
  onTaskClick,
  top,
  left,
  isDisabled,
  passed,
}) => {
  const isTask = title.includes("Zadanie") || title.includes("TEST");
  const image = isDisabled
    ? <LockImage />
    : passed
      ? <TickImage />
      : isTask
        ? <TaskImage />
        : <EyeImage />;
  const parsedTitle = title.length > 40 ? title.substring(0, 40) + "..." : title;

  return (
    <Container
      className={className}
      onClick={onTaskClick}
      top={top}
      left={left}
      isDisabled={isDisabled}
      passed={passed}
      isTask={isTask}
    >
      {image}
      <Title>{parsedTitle}</Title>
    </Container>
  );
};

Task.propTypes = {
  title: PropTypes.string.isRequired,
  onTaskClick: PropTypes.func,
  top: PropTypes.number,
  left: PropTypes.number,
  isDisabled: PropTypes.bool,
  passed: PropTypes.bool,
};
