import styled from "styled-components";
import { Link } from "react-router-dom";

import { Color } from "../../../../theme/colors";
import { FontWeight } from "../../../../theme/fonts";

export const Container = styled.div`
  border-bottom: 1px solid ${Color.dirtyWhite};
  padding-bottom: 31px;
  margin-top: 30px;

  &:first-of-type {
    margin-top: 25px;
  }

  &:last-of-type {
    border-bottom: 0;
  }
`;

export const Image = styled.img`
  width: 100%;
`;

export const Title = styled.p`
  margin-top: 20px;
  font-size: 16px;
  line-height: 25px;
  color: ${Color.deepGray};
  font-weight: ${FontWeight.Bold};
  text-transform: uppercase;
  word-break: break-word;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 22px;
  margin-top: 10px;
`;

export const Date = styled.span`
  color: ${Color.gray4};
`;

export const ReadMore = styled(Link)`
  color: ${() => Color.brandBasic};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
