import { createActions, createReducer } from "reduxsauce";

import { FieldType, SectionName } from "../components/editForm/editForm.constants";

export const { Types: ProfileTypes, Creators: ProfileActions } = createActions(
  {
    setIsPending: ["isPending"],
    setEditTabPending: ["isEditTabPending"],
    fetchEditData: null,
    fetchEditDataSuccess: ["editData"],
    fetchEditCompanies: null,
    fetchEditCompaniesSuccess: ["editFormCompanies"],
    fetchEditPositions: ["companyId", "namespace"],
    fetchEditPositionsSuccess: ["editFormPositions", "namespace"],
    fetchEditBrands: ["companyId", "namespace"],
    fetchEditBrandsSuccess: ["editFormBrands", "namespace"],
    addExperienceFields: ["namespace"],
    addAvatar: ["file", "onError"],
    addAvatarSuccess: ["data"],
    deleteAvatar: null,
    deleteAvatarSuccess: null,
    sendEditForm: ["values", "onFinish"],
    sendEditFormSuccess: ["editForm"],
    sendEditFormFailure: null,
    fetchHeaderInfo: null,
    fetchHeaderInfoSuccess: ["data"],
    fetchCareerHistory: null,
    fetchCareerHistorySuccess: ["experience"],
    fetchIddHistory: null,
    fetchIddHistorySuccess: ["elements", "iddNextPage"],
    fetchUserAgreements: null,
    fetchUserAgreementsSuccess: ["agreements"],
    updateUserAgreements: ["agreements", "onLogout"],
    clearForm: null,
    loadIddNextPage: ["iddNextPage"],
    loadIddNextPageSuccess: ["elements", "iddNextPage"],
  },
  { prefix: "PROFILE/" }
);

const INITIAL_STATE = {
  isPending: false,
  isEditTabPending: false,
  avatarSrc: null,
  fullName: "",
  experience: [],
  iddHistory: {},
  iddNextPage: null,
  agreements: [],
  editForm: [],
  editFormCompanies: [],
  editFormPositions: {},
  editFormBrands: {},
  position: "",
  editFormInVerification: false,
  isEditFormError: false,
};

const setIsPending = (state, { isPending }) => ({ ...state, isPending });

const setEditTabPending = (state, { isEditTabPending }) => ({
  ...state,
  isEditTabPending,
});

const fetchEditDataSuccess = (state, { editData }) => ({
  ...state,
  editForm: editData.sections,
  editFormInVerification: editData.inVerification,
});

const fetchEditCompaniesSuccess = (state, { editFormCompanies }) => ({
  ...state,
  editFormCompanies,
});

const fetchEditPositionsSuccess = (state, { editFormPositions, namespace }) => ({
  ...state,
  editFormPositions: {
    ...state.editFormPositions,
    [namespace]: editFormPositions,
  },
});

const fetchEditBrandsSuccess = (state, { editFormBrands, namespace }) => ({
  ...state,
  editFormBrands: {
    ...state.editFormBrands,
    [namespace]: editFormBrands,
  }
});

const addAvatarSuccess = (state, { data }) => ({
  ...state,
  avatarSrc: data.path,
});

const deleteAvatarSuccess = (state) => ({
  ...state,
  avatarSrc: null,
});

const sendEditFormSuccess = (state, { editForm }) => {
  const fullName = !!editForm.sections.length && editForm.sections[0].name === SectionName.personalData
    ? `${editForm.sections[0].fields[0].value} ${editForm.sections[0].fields[1].value}`
    : state.fullName;
  return {
    ...state,
    fullName,
    editForm: editForm.sections,
    editFormInVerification: editForm.inVerification,
    isEditFormError: false,
  };
};

const sendEditFormFailure = (state) => ({
  ...state,
  editFormInVerification: false,
  isEditFormError: true,
});

const addExperienceFields = (state, { namespace }) => ({
  ...state,
  editForm: state.editForm.map((section) => {
    if (section.name !== SectionName.experiences) return section;

    return {
      ...section,
      sections: [
        ...section.sections,
        {
          ...section.sections[section.sections.length - 1],
          name: namespace,
          fields: section.sections[section.sections.length - 1].fields.map((field) => ({
            ...field,
            value: field.type === FieldType.Checkbox
              ? false
              : field.type === FieldType.DropdownMultiple
                ? []
                : null,
          })),
        },
      ],
    };
  }),
});

const fetchHeaderInfoSuccess = (state, { data }) => ({
  ...state,
  avatarSrc: data.avatar,
  fullName: `${data.name} ${data.surname}`,
  position: data.position,
});

const fetchCareerHistorySuccess = (state, { experience }) => ({ ...state, experience });

const fetchIddHistorySuccess = (state, { elements, iddNextPage }) => ({
  ...state,
  iddHistory: elements,
  iddNextPage,
});

const loadIddNextPageSuccess = (state, { elements, iddNextPage }) => ({
  ...state,
  iddHistory: [...state.iddHistory, ...elements],
  iddNextPage,
});

const fetchUserAgreementsSuccess = (state, { agreements }) => ({ ...state, agreements });

const clearForm = (state) => ({
  ...state,
  editForm: [],
});

export const reducer = createReducer(INITIAL_STATE, {
  [ProfileTypes.SET_IS_PENDING]: setIsPending,
  [ProfileTypes.SET_EDIT_TAB_PENDING]: setEditTabPending,
  [ProfileTypes.FETCH_EDIT_DATA_SUCCESS]: fetchEditDataSuccess,
  [ProfileTypes.FETCH_EDIT_COMPANIES_SUCCESS]: fetchEditCompaniesSuccess,
  [ProfileTypes.FETCH_EDIT_POSITIONS_SUCCESS]: fetchEditPositionsSuccess,
  [ProfileTypes.FETCH_EDIT_BRANDS_SUCCESS]: fetchEditBrandsSuccess,
  [ProfileTypes.SEND_EDIT_FORM_SUCCESS]: sendEditFormSuccess,
  [ProfileTypes.SEND_EDIT_FORM_FAILURE]: sendEditFormFailure,
  [ProfileTypes.ADD_EXPERIENCE_FIELDS]: addExperienceFields,
  [ProfileTypes.ADD_AVATAR_SUCCESS]: addAvatarSuccess,
  [ProfileTypes.DELETE_AVATAR_SUCCESS]: deleteAvatarSuccess,
  [ProfileTypes.FETCH_CAREER_HISTORY_SUCCESS]: fetchCareerHistorySuccess,
  [ProfileTypes.FETCH_IDD_HISTORY_SUCCESS]: fetchIddHistorySuccess,
  [ProfileTypes.FETCH_HEADER_INFO_SUCCESS]: fetchHeaderInfoSuccess,
  [ProfileTypes.FETCH_USER_AGREEMENTS_SUCCESS]: fetchUserAgreementsSuccess,
  [ProfileTypes.CLEAR_FORM]: clearForm,
  [ProfileTypes.LOAD_IDD_NEXT_PAGE_SUCCESS]: loadIddNextPageSuccess,
});
