import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../helpers/rendering";
import { Color, FontWeight, Transitions } from "../../theme";
import { breakpoints } from "../../UIGlobals";

export const HeadlineSmall = styled.h3`
  padding-left: 34px;
  font-size: 1.2rem;
  font-weight: ${FontWeight.Bolder};
  text-transform: uppercase;
  letter-spacing: 0.6px;
  color: ${Color.black};
`;

export const HeadlineBig = styled.h1`
  padding: 37px 0 61px 34px;
  font-size: 3.3rem;
  font-weight: ${FontWeight.Light};
  color: ${Color.almostBlack};
`;

export const Navigation = styled.ol`
  line-height: 2;
  counter-reset: item;
  list-style-type: none;
  padding-left: 20px;

  @media (${breakpoints().maxL}) {
    margin-right: 10px;
  }
`;

export const Item = styled.li`
  position: relative;
  display: block;
  font-size: 1.4rem;
  line-height: 3;
  font-weight: ${FontWeight.Normal};
  padding-left: 34px;
  cursor: ${({ cursor }) => (cursor ? cursor : "auto")};
  color: ${({ $isCurrent, $isAvailable }) => ($isCurrent ? Color.brandBasic : $isAvailable ? Color.black : Color.gray1)};

  &:before {
    content: counter(item) " ";
    counter-increment: item;
    position: absolute;
    left: 1px;
  }
`;

export const LessonIcon = styled.i`
  position: absolute;
  ${({ $isCurrent }) => !$isCurrent && "display: none"};
  left: -23px;
`;

export const BtnList = styled.a`
  width: 52px;
  height: 52px;
  background-color: ${Color.almostWhite};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${Transitions.custom};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  @media (${breakpoints().maxL}) {
    background: ${Color.gray6};
    margin-left: auto;
    margin-right: 10px;
  }

  ${styleWhenTrue(prop("hidden"), css`
    display: none;
  `)}
`;
