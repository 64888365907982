export const HeroTitle = {
  Steven: "Steven sprzedawca",
  George: "George od wszystkiego",
  Kate: "Kate od leasingu",
  Doris: "Doris od ubezpieczeń",
};

export const HeroName = {
  Steven: "hero_1",
  George: "hero_2",
  Kate: "hero_3",
  Doris: "hero_4",
};

export const OnboardingAllMode = {
  Enabled: "enabled",
  Disabled: "disabled",
};
