import { handleApiError, phpApi } from "../../../api";

export const getData = (id) => {
  return phpApi.get(`/api/survey/${id}/init`).catch(handleApiError);
};

export const saveData = (id, answers) => {
  return phpApi.get(`/api/survey/${id}/save`, { data: { answers } }).catch(handleApiError);
};

export const finishForm = (id) => {
  return phpApi.get(`/api/survey/${id}/finish`).catch(handleApiError);
};
