import React from "react";
import { useTranslation } from "react-i18next";

import {
  Container,
  BackButton,
  CityImg,
  Stats,
  Result,
  ResultLabel,
} from "./statistics.styled";

export const Statistics = ({
  onGoBack,
  gameResult,
  stageResult,
  isLastStage,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <BackButton onClick={onGoBack}>
        <CityImg />
        {t("onboarding.game.map")}
      </BackButton>
      <Stats>
        <Result>{gameResult}%</Result>
        <ResultLabel isLastStage={isLastStage}>
          {t("onboarding.game.game")}
        </ResultLabel>
      </Stats>
      <Stats>
        <Result>{stageResult}%</Result>
        <ResultLabel isLastStage={isLastStage}>
          {t("onboarding.game.stage")}
        </ResultLabel>
      </Stats>
    </Container>
  );
};
