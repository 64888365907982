import React from "react";
import { colors } from "../UIGlobals";
import styled from "styled-components";

class ProgressRing extends React.Component {
  constructor(props) {
    super(props);

    const { radius, stroke } = this.props;

    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

  render() {
    const { radius, stroke, progress, percent, color } = this.props;

    const strokeDashoffset = (percent = 1, animated = true) => {
      const path = animated ? progress : this.circumference;
      return this.circumference - (path / 100) * this.circumference * percent;
    };

    return (
      <StyledSVG height={radius * 2} width={radius * 2}>
        <circle
          stroke="#ebecf0"
          fill="transparent"
          strokeWidth={stroke}
          r={radius - stroke * 2}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke={color ? colors()[color] : colors().black}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={this.circumference + " " + this.circumference}
          style={{ strokeDashoffset: strokeDashoffset(percent / 100) }}
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </StyledSVG>
    );
  }
}
const StyledSVG = styled.svg`
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
`;

class ProgressCircle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      progress: 0
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({ progress: this.state.progress + 1 });
      if (this.state.progress === 100) clearInterval(this.interval);
    }, 10);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    const stroke = 2;

    const { color, percent, radius, positionLeft } = this.props;
    const ProgressWrap = styled.div`
      position: relative;
      &:after {
        content: "${percent}%";
        position: absolute;
        font-size: 11px;
        color: ${props => (props.color ? props.color : colors().darkGray)};
        top: 48%;
        font-weight: 600;
        left: ${props => (props.positionLeft ? props.positionLeft + "%" : "50%")};
        transform: translate(-50%, -50%);
      }
    `;

    return (
      <ProgressWrap percent={percent} positionLeft={positionLeft}>
        <ProgressRing
          radius={radius}
          stroke={stroke}
          progress={this.state.progress}
          percent={percent}
          color={color}
          positionLeft={positionLeft}
        />
      </ProgressWrap>
    );
  }
}

export { ProgressCircle };
