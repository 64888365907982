import * as Yup from "yup";

import i18n from "../../../../translations/i18n";

import { AnswerLength } from "./openQuestion.constants";

export const QuestionSchema = Yup.object().shape({
  answer: Yup
    .string()
    .max(AnswerLength, i18n.t("validationMessage.maxLength")),
});
