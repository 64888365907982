import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

import {
  Container,
  PrevButton,
  NextButton,
  TagsContainer,
  ScrollableContainer,
  ArrowIcon,
} from "./tagFiltersList.styled";
import { TagFilter } from "./components/tagFilter/tagFilter.component";

export const TagFiltersList = ({ tags, chosenTags, setChosen }) => {
  const scrollValue = 300;
  const containerRef = useRef();
  const tagsContainerRef = useRef();
  const scrollableContainerRef = useRef();
  const [showNextButton, setShowNextButton] = useState(true);
  const [filtersPosition, setFiltersPosition] = useState(0);

  useEffect(() => {
    if (!tagsContainerRef.current) return;

    const tagsContainerWidth = tagsContainerRef.current.offsetWidth;
    const scrollableContainerWidth = scrollableContainerRef.current?.scrollWidth;
    const scrolledToRightSide = tagsContainerWidth + filtersPosition >= scrollableContainerWidth;

    if (scrolledToRightSide) {
      setShowNextButton(false);
    } else {
      setShowNextButton(true);
    }
  }, [filtersPosition, tagsContainerRef]);

  const clearFilters = () => {
    setChosen([]);
  };

  const handlePrevClick = () => {
    if (filtersPosition < scrollValue) {
      setFiltersPosition(0);
    } else {
      setFiltersPosition(filtersPosition - scrollValue);
    }
  };

  const handleNextClick = () => {
    const tagsContainerWidth = tagsContainerRef.current?.offsetWidth;
    const scrollableContainerWidth = scrollableContainerRef.current?.scrollWidth;
    const leftSpace = scrollableContainerWidth - (tagsContainerWidth + filtersPosition);

    if (leftSpace < scrollValue) {
      setFiltersPosition(filtersPosition + leftSpace);
    } else {
      setFiltersPosition(filtersPosition + scrollValue);
    }
  };

  const handleSelectTag = (tag) => {
    if (chosenTags.includes(tag)) {
      setChosen(chosenTags.filter((filteredTag) => tag.id !== filteredTag.id));
    } else {
      setChosen([...chosenTags, tag]);
    }
  };

  return (
    <Container
      ref={containerRef}
      filtersPosition={filtersPosition}
      withLeftPadding={!!filtersPosition}
    >
      <TagsContainer ref={tagsContainerRef}>
        <ScrollableContainer ref={scrollableContainerRef}>
          <TagFilter
            clearFilters={clearFilters}
            selectedFilters={chosenTags}
            isClearTag
          />
          {tags.map((tag) => (
            <TagFilter
              key={tag.id}
              tag={tag}
              selectedFilters={chosenTags}
              setChosen={handleSelectTag}
            />
          ))}
        </ScrollableContainer>
      </TagsContainer>
      {!!filtersPosition && (
        <PrevButton onClick={handlePrevClick}>
          <ArrowIcon brandcolor="true" />
        </PrevButton>
      )}
      {showNextButton && (
        <NextButton onClick={handleNextClick}>
          <ArrowIcon brandcolor="true" />
        </NextButton>
      )}
    </Container>
  )
};

TagFiltersList.propTypes = {
  tags: PropTypes.array,
  setChosen: PropTypes.func,
};
