import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import {
  Media,
  Color,
  FontWeight,
  Separator as SeparatorBase,
} from "../../../../theme";

export const Container = styled.div`
  padding: 20px;
  background-color: ${Color.white};
  z-index: 1;

  ${Media.tablet`
    width: 530px;
    padding: 25px;

    ${styleWhenTrue(prop("isLessonHeader"), css`
      margin-top: 165px;
    `)}

    ${styleWhenTrue(prop("wide"), css`
      width: 100%;
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;
    `)}
  `}

  ${Media.desktop`
    ${styleWhenTrue(prop("isLessonHeader"), css`
      background-color: ${Color.transparent};
    `)}
  `}

  & + & {
    margin-top: 100vh;
  }
`;

export const Counter = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
`;

export const BoldCounter = styled.span`
  font-weight: ${FontWeight.SemiBold};
`;

export const HeaderText = styled.p`
  font-weight: ${FontWeight.Bold};
  font-size: 24px;
  line-height: 32px;
  color: ${Color.deepGray};

  ${styleWhenTrue(prop("isLessonHeader"), css`
    font-size: 38px;
    line-height: 51px;
  `)}
`;

export const Separator = styled(SeparatorBase)`
  && {
    margin-top: 15px;
    margin-bottom: 20px;
    background-color: ${() => Color.brandBasic};
    height: 2px;
    width: calc(100% - 20px);
  }
`;

export const Text = styled.p`
  font-size: 18px;
  line-height: 28px;

  b {
    font-weight: ${FontWeight.Bold};
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
`;

export const FloatWrapper = styled.div`
  margin-top: 30px;
`;

export const TagListWrapper = styled.div`
  margin-bottom: 25px;
`;
