// DEPRECATED

import styled from "styled-components";
import { CircularProgress, Typography, styled as styledMUI } from "@mui/material";

import { Color } from "../../theme";

export const BottomCircularProgress = styled(CircularProgress)`
  color: ${Color.gray4} !important;
  position: absolute;
  left: 0;
`;

export const TopCircularProgress = styled(CircularProgress)`
  z-index: 1;
`;

export const StyledTypography = styledMUI(Typography)({
  fontSize: 10,
});
