import styled from "styled-components";

import { FontWeight, Media } from "../../../../../theme";

export const Container = styled.div`
  flex: 1;
  margin-top: 60px;

  ${Media.tablet`
    margin-top: 0;
    padding-right: 25px;
  `}
`;

export const AnimationWrapper = styled.div`
  width: 80px;
  height: 80px;
`;

export const Text = styled.p`
  margin-top: 5px;
  font-size: 16px;
  line-height: 26px;

  ${Media.tablet`
    font-size: 18px;
    line-height: 30px;
  `}
`;

export const Title = styled.p`
  margin-top: 20px;
  font-weight: ${FontWeight.Bold};
  font-size: 18px;
  line-height: 30px;
`;
