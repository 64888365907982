import { useState } from "react";

export const useLocalStorage = (key) => {
  const [item, setItemValue] = useState(() => {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key));
    }
    return null;
  });

  const setValue = (value) => {
    setItemValue(value);
    localStorage.setItem(key, JSON.stringify(value));
  };

  const removeItem = () => {
    localStorage.removeItem(key);
  };

  return [item, setValue, removeItem];
};
