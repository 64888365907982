import { all, put, takeLatest } from "redux-saga/effects";

import { LessonActions } from "../../../lesson/redux/lesson.reducer";
import { store } from "../../../store";
import * as scrollLessonApi from "../services/api";

import { ScrollLessonTypes } from "./scrollLesson.reducer";

function* setQuestionCompleted({ answers, onFinish }) {
  const { quizes, instanceId } = store.getState().lesson;

  if (answers?.length) {
    const data = yield scrollLessonApi.setQuestionCompleted(instanceId, quizes[0].id, answers);
    yield put(LessonActions.setQuizCompleted());

    if (data !== "error") {
      onFinish();
    }
  }
}

function* saveQuiz() {
  const { quizes, instanceId } = store.getState().lesson;
  const { data } = yield scrollLessonApi.saveQuiz(instanceId, quizes[0]. id);

  if (data) {
    yield put(LessonActions.setQuizCompletedSuccess(data));
  }
}

export function* watchScrollLesson() {
  yield all([
    yield takeLatest(ScrollLessonTypes.SET_QUESTION_COMPLETED, setQuestionCompleted),
    yield takeLatest(ScrollLessonTypes.SAVE_QUIZ, saveQuiz),
  ]);
}
