import styled from "styled-components";

import { Color, Media } from "../../../../../../../theme";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background-color: ${Color.white};
  color: ${Color.almostBlack};
  font-size: 16px;
  padding: 20px;
  border: 1px solid ${Color.gray5};
  border-bottom: none;
  box-sizing: border-box;

  ${Media.tablet`
    width: 60%;
    margin-bottom: 5px;
    border: 1px solid ${Color.gray5};
    border-right: none;
  `}

  ${Media.desktop`
    font-size: 18px;
  `}
`;

export const Text = styled.span`
  letter-spacing: 0;
  line-height: 26px;

  ${Media.tablet`
    line-height: 22px;
  `}
`;
