import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { prop } from "ramda";

import { ReactComponent as WarningIconSvg } from "../../../../images/warning2.svg";
import { Button as ButtonBase } from "../../../../components/button/button.component";
import { Icon as IconBase } from "../../../../components/icon/icon.component";
import { DefaultButtonStyles, OutlinedButtonStyles } from "../../../../components/button/button.styled";
import { hexToRgba } from "../../../../helpers/colors";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { Separator, FontWeight, Color, Media } from "../../../../theme";

export const Container = styled.div`
  max-width: 1360px;
  margin: 0 auto;
  padding: 20px;

  ${Media.tablet`
    padding: 20px 25px;
  `}

  ${Media.desktop`
    padding: 20px 80px;
  `}
`;

export const WarningIcon = styled(WarningIconSvg)`
  path {
    stroke: ${() => Color.brandBasic};  
  }
  circle {
    fill: ${() => Color.brandBasic};
  }
`;

export const Header = styled.div`
  ${Media.mobile`
    display: flex;
    align-items: center;
  `}
`;

export const HeaderText = styled.span`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.6px;
  font-weight: ${FontWeight.Bold};
  text-transform: uppercase;
`;

export const QuestionType = styled(HeaderText)`
  color: ${Color.gray1};
`;

export const VerticalSeparator = styled.div`
  width: 1px;
  height: 9px;
  background-color: ${Color.gray1};
  margin: 0 12px;
  display: inline-block;
`;

export const HorizontalSeparator = styled(Separator)`
  && {
    margin-top: 20px;
    margin-bottom: 50px;
  }
`;

export const ButtonSeparator = styled(Separator)`
  && {
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

export const Activity = styled.p`
  margin-left: auto;
  color: ${() => Color.brandBasic};
  font-size: 10px;
  line-height: 20px;
  padding: 7px 15px 6px;
  border-radius: 17px;
  background-color: ${() => hexToRgba(Color.brandBasic, 0.08)};
  text-align: center;
`;

export const Title = styled.p`
  font-size: 20px;
  line-height: 30px;
  font-weight: ${FontWeight.Bold};
  margin-bottom: 50px;
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Button = styled(ButtonBase)`
  margin-left: 10px;

  ${Media.mobile`
    margin-left: 20px;
  `}
`;

export const BackLink = styled(Link)`
  ${DefaultButtonStyles}
  ${OutlinedButtonStyles}
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Warning = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${Color.deepGray};
  font-weight: ${FontWeight.Bold};
  margin-bottom: 10px;
  text-align: center;
`;

export const ModalText = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${Color.gray9};
  text-align: center;
  max-width: 462px;
  margin: 0 auto;

  & + & {
    margin-top: 30px;
  }
`;

export const AddButton = styled(ButtonBase)`
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding-left: 15px;
`;

export const Icon = styled(IconBase)`
  margin-right: 15px;
  color: ${Color.gray4};
`;

export const Files = styled.div`
  padding: 30px 0 25px;
  font-size: 12px;
  line-height: 17px;
  display: flex;

  ${styleWhenTrue(prop("withError"), css`
    padding-bottom: 11px;
  `)}
`;

export const FilesLabel = styled.label`
  font-weight: ${FontWeight.Bold};
  color: ${Color.deepGray};
  margin-right: 30px;
`;

export const FilesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;
  margin-left: -5px;
`;

export const FilesEmpty = styled.div`
  color: ${Color.gray4};
`;

export const FileErrorText = styled.p`
  color: ${() => Color.brandBasic};
  font-size: 10px;
  line-height: 17px;

  &:last-of-type {
    margin-bottom: 15px;
  }
`;

export const ErrorFileName = styled.span`
  font-weight: ${FontWeight.Bold};
`;
