import styled from "styled-components";

import { ReactComponent as ArrowSvg } from "../../../../../images/arrow.svg";
import { hexToRgba } from "../../../../../helpers/colors";
import { Color, FontWeight, Media } from "../../../../../theme";
import { ReactComponent as CoinsSvg } from "../../../images/coins.svg";
import { ReactComponent as CupSvg } from "../../../images/cup.svg";
import { ReactComponent as AmbassadorSvg } from "../../../images/ambassadorLogo.svg";
import { ReactComponent as HeroSvg } from "../../../images/hero.svg";

export const Container = styled.div`
  background-color: ${() => Color.brandBasic};
  margin-bottom: 20px;
  padding: 43px 20px 20px;
  position: relative;

  ${Media.tablet`
    padding: 43px 40px 30px;
  `}
`;

export const CoinsIcon = styled(CoinsSvg)``;

export const CupIcon = styled(CupSvg)``;

export const Stats = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-top: 26px;
  }
`;

export const Counter = styled.p`
  width: 112px;
  text-align: center;
  font-size: 40px;
  line-height: 55px;
  color: ${Color.white};
  font-weight: ${FontWeight.Bold};
  letter-spacing: 1px;
`;

export const Text = styled.p`
  font-size: 11px;
  line-height: 16px;
  font-weight: ${FontWeight.Bold};
  color: ${hexToRgba(Color.white, 0.8)};
  width: 85px;
  text-transform: uppercase;
`;

export const HistoryButton = styled.button`
  background-color: ${hexToRgba(Color.white, 0.8)};
  color: ${() => Color.brandBasic};
  border: none;
  height: 32px;
  border-radius: 1px;
  padding: 6px 15px;
  display: flex;
  align-items: center;
  margin-top: 33px;
  font-size: 10px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: ${FontWeight.Bold};
`;

export const ArrowIcon = styled(ArrowSvg)`
  fill: ${() => Color.brandBasic};
  width: 9px;
  margin-left: 16px;
  transform: rotate(180deg);
`;

export const AmbassadorLogo = styled(AmbassadorSvg)`
  position: absolute;
  height: 50px;
  right: 0;
  top: 20px;

  ${Media.tablet`
    height: auto;
    top: 30px;
    right: 30px;
  `}
`;

export const HeroIcon = styled(HeroSvg)`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 150px;

  ${Media.tablet`
    left: 320px;
    right: unset;
    height: auto;
  `}

  ${Media.tabletWide`
    display: none;
  `}

  ${Media.desktopWide`
    display: block;
    left: 320px;
  `}
`;
