import { AnyAction, Reducer } from 'redux';
import { createReducer } from 'reduxsauce';

import { EmailVerificationTypes } from './emailVerification.actions';
import { initState } from './emailVerification.state';
import { State } from './emailVerification.types';

const setTokenVerifiedStatus: Reducer = (state: State, { tokenVerifiedStatus }: AnyAction): State => ({
  ...state,
  tokenVerifiedStatus,
});

const clearData: Reducer = (state: State): State => ({
  ...state,
  tokenVerifiedStatus: null,
});

export const reducer: Reducer = createReducer(initState, {
  [EmailVerificationTypes.SET_TOKEN_VERIFIED_STATUS]: setTokenVerifiedStatus,
  [EmailVerificationTypes.CLEAR_DATA]: clearData,
});
