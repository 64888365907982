import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { ReactComponent as CheckSvg } from "../../../../images/check2.svg";
import { FontWeight, Media, Color } from "../../../../theme";
import { hexToRgba } from "../../../../helpers/colors";

export const Container = styled.div``;

export const Question = styled.div`
  display: flex;
`;

const questionStyles = css`
  font-weight: ${FontWeight.Bold};
  font-size: 14px;
  line-height: 20px;

  ${Media.tablet`
    font-size: 18px;
    line-height: 26px;
  `}
`;

export const Counter = styled.p`
  ${questionStyles}
  width: 35px;
  flex-shrink: 0;
`;

export const QuestionText = styled.p`
  ${questionStyles}
  max-width: 800px;
  margin-bottom: 30px;
`;

export const Answers = styled.div``;

export const AnswerCircle = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid ${Color.gray1};
  border-radius: 50%;

  ${styleWhenTrue(prop("selected"), css`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${() => Color.brandBasic};
    background-color: ${() => Color.brandBasic};
  `)}
`;

export const Answer = styled.button`
  border: 1px solid ${Color.almostWhite};
  padding: 25px 20px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  background-color: ${Color.almostWhite};
  width: 100%;

  &:hover {
    border: 1px solid ${() => Color.brandBasic};
    background-color: ${Color.white};

    ${AnswerCircle} {
      border: 2px solid ${() => Color.brandBasic};
    }
  }

  ${styleWhenTrue(prop("unactive"), css`
    background-color: ${Color.white};
    border: 1px solid ${Color.gray5};
  `)}

  ${styleWhenTrue(prop("selected"), css`
    border: 1px solid ${() => Color.brandBasic};
    background-color: ${() => hexToRgba(Color.brandBasic, 0.1)};

    &:hover {
      border: 1px solid ${() => Color.brandBasic};
      background-color: ${() => hexToRgba(Color.brandBasic, 0.1)};
    }
  `)}
`;

export const AnswerText = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${Color.deepGray};
  margin-left: 15px;

  ${Media.tablet`
    font-size: 15px;
    line-height: 20px;
  `}
`;

export const SelectIcon = styled(CheckSvg)`
  fill: ${Color.white};
  width: 10px;
  height: 10px;
`;

export const CommentField = styled.textarea`
  background-color: ${Color.almostWhite};
  border: none;
  width: 100%;
  height: 120px;
  padding: 15px 20px;
  font-size: 15px;
  line-height: 20px;
  color: ${Color.deepGray};
  resize: none;

  ::placeholder {
    color: ${Color.gray9};
  }
`;
