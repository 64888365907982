import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { LessonActions } from "../lesson/redux/lesson.reducer";
import { selectNav } from "../lesson/redux/lesson.selectors";

import QuizToc from "./quizToc/quizToc.component";
import Question from "./question/question.component";
import { Container } from "./quiz.styled";

const Quiz = ({ questions, children }) => {
  const dispatch = useDispatch();
  const nav = useSelector(selectNav);
  const questionIndex = questions.findIndex((question) => question.id === nav.currentChild);
  const currentQuestionIndex = questionIndex >= 0 ? questionIndex : 0;
  const currentQuestion = questions[currentQuestionIndex];
  const prevQuestion = currentQuestionIndex > 0 ? questions[currentQuestionIndex - 1] : false;

  const setCurrentQuestion = (id) => {
    dispatch(LessonActions.setCurrentQuestion(id));
  };

  const markAnswerSelected = (questionId, answerId) => {
    dispatch(LessonActions.setAnswerSelected(questionId, answerId));
  };

  const markQuestionCompleted = (id) => {
    dispatch(LessonActions.setQuestionCompleted(id));
  };

  return (
    <Container>
      {children}
      <QuizToc nav={nav} questions={questions} />
      <Question
        question={currentQuestion}
        navPrev={() => setCurrentQuestion(prevQuestion.id)}
        isFirst={currentQuestionIndex === 0}
        callComplete={() => {
          currentQuestion.children.filter((event) => event.selected === true).length > 0 &&
            markQuestionCompleted(currentQuestion.id);
        }}
        markAnswerSelected={markAnswerSelected}
      />
    </Container>
  );
};

export default Quiz;
