const breakpointsArray = {
  s: 480,
  m: 768,
  l: 1024,
  xl: 1200
};

const breakpoints = () => {
  return {
    maxS: `max-width: ${breakpointsArray.s}px`,
    minS: `min-width: ${breakpointsArray.s + 1 }px`,
    maxM: `max-width: ${breakpointsArray.m}px`,
    minM: `min-width: ${breakpointsArray.m + 1 }px`,
    maxL: `max-width: ${breakpointsArray.l}px`,
    minL: `min-width: ${breakpointsArray.l + 1 }px`,
    maxXL: `max-width: ${breakpointsArray.xl}px`,
    minXL: `min-width: ${breakpointsArray.xl + 1 }px`
  };
};

export default breakpoints;
export { breakpointsArray };
