import styled from "styled-components";

import { ErrorMessage as ErrorMessageBase } from "../../theme/typography";
import { Color } from "../../theme/colors";
import { FontWeight } from "../../theme/fonts";
import { Media } from "../../theme/media";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Label = styled.label`
  color: ${Color.deepGray};
  font-size: 12px;
  font-weight: ${FontWeight.Bold};
  line-height: 17px;
  margin-bottom: 20px;
`;

export const CheckboxList = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    padding-right: 10px;
    margin-bottom: 15px;
    flex: 50%;

    ${Media.tablet`
      flex: none;
      width: 180px;
    `}
  }
`;

export const ErrorMessage = styled(ErrorMessageBase)`
  position: absolute;
  bottom: -7px;
  left: 0;
`;
