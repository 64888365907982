import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { ConfirmationModal } from "../../components/confirmationModal/confirmationModal.component";
import { selectGlobalErrorVisible } from "../../redux/common/common.selectors";
import { CommonActions } from "../../redux/common/common.reducer";
import { AppConfig } from "../../appConfig";

export const LogoutErrorModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const globalErrorVisible = useSelector(selectGlobalErrorVisible);

  const clearGlobalError = () => {
    dispatch(CommonActions.setGlobalErrorVisible(false));
    window.location.reload();
  };

  return (
    <ConfirmationModal
      open={!!globalErrorVisible}
      confirmText={t("global.ok")}
      withoutDecline
      onClose={clearGlobalError}
    >
      {t("global.undefinedAppError", { contactEmail: AppConfig.platformContactEmail })}
    </ConfirmationModal>
  );
};
