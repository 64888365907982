import React, { useEffect } from "react";

import { QuestionOpen } from "../questionOpen/questionOpen.component";
import { Choice } from "../questionChoice/questionChoice.component";

export const Question = ({ question, setAnswer, section, submitted, valid, setValid }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isValid = (value) => {
    const { pattern } = question.answers[0];

    if (pattern.length > 0) {
      return new RegExp(pattern).test(String(value).toUpperCase());
    } else if (question.required) {
      return (value.length > 2 && value.length < 128);
    } else {
      return true;
    }
  }

  useEffect(() => {
    if (valid["v_" + question.id] == null) {
      const newValidState = [...valid];
      newValidState["v_" + question.id] = question.type === "open"
        ? isValid(question.answers[0].answer)
        : question.type === "single_choice"
          ? question.answers.filter((answer) => answer.selected === true).length > 0
          : true;

      setValid(newValidState);
    }
  }, [valid, setValid, question]);

  const boundSetAnswer = ({ target }) => {
    setAnswer(question.answers[0].id, target.value);

    const resp = isValid(target.value);
    const newValidState = [...valid];
    newValidState["v_" + question.id] = resp;

    setValid(newValidState);
  };

  const setChecked = (selectedAnswers) => {
    const newValidState = [...valid];
    newValidState["v_" + question.id] = question.type === "multiple_choice" && selectedAnswers === 0
      ? false : true;
    setValid(newValidState);
  };

  switch (question.type) {
  case "open":
    return (
      <QuestionOpen
        question={question}
        setAnswer={boundSetAnswer}
        error={submitted ? !isValid(question.answers[0].answer) : false}
      />
    );
  case "single_choice":
    return (
      <Choice
        title={question.question}
        answers={question.answers}
        setAnswer={setAnswer}
        section={section}
        setChecked={setChecked}
        error={submitted ? !valid["v_" + question.id] : false}
      />
    );
  case "multiple_choice":
    return (
      <Choice
        setChecked={setChecked}
        title={question.question}
        answers={question.answers}
        setAnswer={setAnswer}
        multi={true}
        section={section}
        error={submitted ? !valid["v_" + question.id] : false}
      />
    );
  case "open_choice":
    return null;
  default:
    return <div>{question.type}</div>;
  }
};
