import styled from "styled-components";

import { Color, FontWeight } from "../../../../theme";

export const Container = styled.div``;

export const CalendarBox = styled.div`
  background-color: ${Color.white};
  padding: 22px;

  .fc-header-toolbar.fc-toolbar {
    margin-bottom: 24px;
  }

  .fc-toolbar-chunk {
    display: flex;
    align-items: center;
  }

  .fc-toolbar-title {
    text-transform: capitalize;
    font-size: 20px;
    line-height: 25px;
    font-weight: ${FontWeight.SemiBold};
  }

  .fc-scrollgrid-liquid {
    border: none;
  }

  .fc-scrollgrid-section-liquid > td {
    border: none;
  }

  .fc-scrollgrid-section-header > th {
    border: none;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    font-weight: ${FontWeight.SemiBold};
  }

  .fc-daygrid-day-top {
    flex-direction: row;
    font-size: 14px;
    line-height: 19px;
    font-weight: ${FontWeight.SemiBold};
  }

  .fc-day-sat, .fc-day-sun {
    background-color: ${Color.whiteHover};
  }

  .fc-prev-button, .fc-next-button {
    background-color: ${Color.white};
    border: none;
    outline: none;

    &:hover {
      background-color: ${Color.gray8};
    }

    &:active {
      background-color: ${Color.gray8} !important;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .fc .fc-button .fc-icon {
    font-size: 16px;
  }

  .fc-icon {
    color: ${() => Color.brandBasic};
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: ${Color.almostWhite};
  }

  .fc-today-button {
    background-color: ${Color.white};
    color: ${Color.deepGray};
    text-transform: uppercase;
    border-color: ${Color.dirtyWhite};
    padding: 8px 16px;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 0.6px;
    font-weight: ${FontWeight.Bold};

    &:hover {
      background-color: ${Color.dirtyWhite};
      color: ${Color.deepGray};
      border-color: ${Color.dirtyWhite};
    }

    &:disabled {
      background-color: ${Color.dirtyWhite};
      color: ${Color.deepGray};
      border-color: ${Color.dirtyWhite};
    }

    &:active {
      background-color: ${Color.dirtyWhite} !important;
      color: ${Color.deepGray} !important;
      border-color: ${Color.dirtyWhite} !important;
    }
  }

  .fc-timeGridDay-button,
  .fc-timeGridWeek-button,
  .fc-dayGridMonth-button {
    background-color: ${Color.white};
    font-size: 12px;
    line-height: 16px;
    color: ${Color.gray9};
    padding: 10px 24px;
    font-weight: ${FontWeight.SemiBold};
    border-color: ${Color.dirtyWhite};
    outline: none;

    &:hover {
      background-color: ${Color.dirtyWhite};
      color: ${Color.gray9};
      border-color: ${Color.dirtyWhite};
    }

    &:focus {
      box-shadow: none !important;
    }
  }
`;

export const EventContainer = styled.div`
  cursor: pointer;
`;

export const EventTitle = styled.p`
  font-size: 10px;
  line-height: 13px;
  padding: 2px 8px;
`;

export const EventTime = styled.span`
  font-weight: ${FontWeight.SemiBold};
`;

export const EventTooltipProps = {
  tooltip: {
    sx: {
      backgroundColor: Color.white,
      color: Color.deepGray,
      boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.02), 0px 2px 40px rgba(48, 54, 60, 0.1);",
      minWidth: "390px",
      zIndex: 10001,
    },
  },
};
