import { all, put, takeLatest } from "redux-saga/effects";

import * as trainingsApi from "../services/api";

import { TrainingsTypes, TrainingsActions } from "./trainings.reducer";

function* fetchEvents() {
  yield put(TrainingsActions.setIsPending(true));
  const { data } = yield trainingsApi.getEventList();
  yield put(TrainingsActions.fetchEventsSuccess(data.elements, data.filters));
  yield put(TrainingsActions.setIsPending(false));
}

function* enrollEvent({ eventId, onFinish }) {
  const { data } = yield trainingsApi.enrollEvent(eventId);

  if (data.success) {
    if (data.nextCourses) {
      yield put(TrainingsActions.setEventCheck({ eventId, nextCourses: data.nextCourses }));
    }

    const { data } = yield trainingsApi.getEventList();
    yield put(TrainingsActions.fetchEventsSuccess(data.elements, data.filters));
    yield onFinish();
  }
}

function* enrollEventWithForm({ eventId, userData, onFinish }) {
  const { data } = yield trainingsApi.enrollEvent(eventId, userData);

  if (data.success) {
    if (data.nextCourses) {
      yield put(TrainingsActions.setEventCheck({ eventId, nextCourses: data.nextCourses }));
    }

    const events = yield trainingsApi.getEventList();
    yield put(TrainingsActions.fetchEventsSuccess(events.data.elements, events.data.filters));
    yield onFinish();
  }
}

function* disenrollEvent({ eventId, onFinish }) {
  const { data } = yield trainingsApi.disenrollEvent(eventId);

  if (data.success) {
    const events = yield trainingsApi.getEventList();
    yield put(TrainingsActions.fetchEventsSuccess(events.data.elements, events.data.filters));
    yield onFinish();
  }
}

function* checkEventAccess({ eventId, onFinish }) {
  const { missingCourses, blockingEvent } = yield trainingsApi.checkEventAccess(eventId);

  if (missingCourses || blockingEvent) {
    yield put(TrainingsActions.setEventCheck({ eventId, missingCourses, blockingEvent }));
  } else {
    onFinish();
  }
}

function* checkEventDisenroll({ eventId, onFinish }) {
  const { data } = yield trainingsApi.checkEventDisenroll(eventId);

  if (data?.toRemoveCourses?.length || data?.showTrainingRegulation) {
    yield put(TrainingsActions.setEventCheck({
      eventId,
      toRemoveCourses: data.toRemoveCourses,
      showTrainingRegulation: data.showTrainingRegulation,
    }));
  } else {
    onFinish();
  }
}

function* getEventDetails ({ id }) {
  yield put(TrainingsActions.setIsPending(true));
  const { data } = yield trainingsApi.getEventDetails(id);
  yield put(TrainingsActions.getEventDetailsSuccess(data));
  yield put(TrainingsActions.setIsPending(false));
}

function* fetchTrainerMetric({ trainerId }) {
  const { data } = yield trainingsApi.fetchTrainerMetric(trainerId);
  yield put(TrainingsActions.fetchTrainerMetricSuccess(data));
}

export function* watchTrainings() {
  yield all([
    yield takeLatest(TrainingsTypes.FETCH_EVENTS, fetchEvents),
    yield takeLatest(TrainingsTypes.ENROLL_EVENT, enrollEvent),
    yield takeLatest(TrainingsTypes.ENROLL_EVENT_WITH_FORM, enrollEventWithForm),
    yield takeLatest(TrainingsTypes.DISENROLL_EVENT, disenrollEvent),
    yield takeLatest(TrainingsTypes.CHECK_EVENT_ACCESS, checkEventAccess),
    yield takeLatest(TrainingsTypes.CHECK_EVENT_DISENROLL, checkEventDisenroll),
    yield takeLatest(TrainingsTypes.GET_EVENT_DETAILS, getEventDetails),
    yield takeLatest(TrainingsTypes.FETCH_TRAINER_METRIC, fetchTrainerMetric),
  ]);
}
