import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { ArticleForm } from "../../../../components/articleForm/articleForm.component";
import { PostForm } from "../../../../components/postForm/postForm.component";
import { EntryType } from "../../../../components/articleForm/articleForm.constants";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { NewsActions } from "../../redux/news.reducer";

import { Container } from "./entryEdit.styled";

export const EntryEdit = (props) => {
  const {
    className,
    onCancel,
    onSave,
    id,
    type,
    title,
    image,
    imagePositionX,
    imagePositionY,
    content,
    visibility,
    attachments,
  } = props;
  const dispatch = useDispatch();
  const values = { id, title, image, imagePositionX, imagePositionY, content, visibility, attachments };

  const handleEditPost = (values, onFinish) => {
    dispatch(NewsActions.editPost(values, onFinish));
  };

  const handleSavePost = (values, onFinish) => {
    dispatch(NewsActions.savePost(values, onFinish));
  };

  const handleEditArticle = (id, article, onFinish) => {
    dispatch(NewsActions.editArticle(id, article, onFinish));
  };

  const handleSaveArticle = (values, onFinish) => {
    dispatch(NewsActions.saveArticle(values, onFinish));
  };

  const renderPostForm = renderWhenTrue(() => (
    <PostForm
      values={values}
      onSubmit={onSave}
      onCancel={onCancel}
      onEdit={handleEditPost}
      onSave={handleSavePost}
    />
  ));

  const renderArticleForm = renderWhenTrue(() => (
    <ArticleForm
      values={values}
      onSubmit={onSave}
      onCancel={onCancel}
      onEdit={handleEditArticle}
      onSave={handleSaveArticle}
    />
  ));

  return (
    <Container className={className}>
      {renderPostForm(type === EntryType.Post)}
      {renderArticleForm(type === EntryType.Article)}
    </Container>
  );
};


EntryEdit.propTypes = {
  className: PropTypes.string,
};
