import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

export const downloadAll = (title, items, onFinish) => {
  const zip = new JSZip();
  let promises = [];

  for (let item of items) {
    const itemPromise = new JSZip.external.Promise((resolve, reject) => {
      JSZipUtils.getBinaryContent(
        item.url,
        (err, data) => err ? reject(err) : resolve(data),
      );
    }).then((data) => {
      const filename = item.url.replace(/^.*[\\\\/]/, "").replace(/ /g, "_");
      zip.file(filename, data);
    });

    promises.push(itemPromise);
  }

  Promise.all(promises).then(() => {
    zip.generateAsync({ type: "blob" }).then((blob) => {
      saveAs(blob, `${title.replace(/ /g, "_")}.zip`);
      onFinish();
    });
  });
};
