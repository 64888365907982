import { createSelector } from "reselect";
import { prop } from "ramda";

export const selectAnonSurveyDomain = prop("anonSurvey");

export const selectIsPending = createSelector(
  selectAnonSurveyDomain, prop("isPending"),
);

export const selectQuestions = createSelector(
  selectAnonSurveyDomain, prop("questions"),
);

export const selectStartHeader = createSelector(
  selectAnonSurveyDomain, prop("startHeader"),
);

export const selectStartText = createSelector(
  selectAnonSurveyDomain, prop("startText"),
);

export const selectSurveyCompleted = createSelector(
  selectAnonSurveyDomain, prop("completed"),
);

export const selectSurveyNotFound = createSelector(
  selectAnonSurveyDomain, prop("notFound"),
);
