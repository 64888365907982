import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Container } from "./nextButton.styled";

export const NextButton = ({
  className,
  onClick,
  disabled,
  text,
  complianceTheme,
}) => {
  const { t } = useTranslation();

  return (
    <Container
      className={className}
      onClick={onClick}
      disabled={disabled}
      complianceTheme={complianceTheme}
    >
      {t(`interactions.sequentialQuiz.nextButton.${text}`)}
    </Container>
  );
};

NextButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  complianceTheme: PropTypes.bool,
};
