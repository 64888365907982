import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { OpenQuestionMaxLength, QuestionType } from "./question.constants";
import {
  Container,
  Title,
  QuestionText,
  Buttons,
  PrevButton,
  NextButton,
  ArrowPrev,
  ArrowNext,
  Answers,
  SingleChoiceAnswer,
  InputWrapper,
  TextAreaInput,
  InputCounter,
  Description,
} from "./question.styled";

export const Question = ({
  sectionTitle,
  question,
  answers,
  onNextClick = Function.prototype,
  onPrevClick = Function.prototype,
  setValue = Function.prototype,
  value,
  answerId,
  type,
}) => {
  const { t } = useTranslation();

  const handleSingleChoiceValueChange = (id) => {
    setValue({ id });
  };

  const handleInputValueChange = (id, value) => {
    setValue({ id, value });
  };

  return (
    <Container>
      <Title>{sectionTitle}</Title>
      <QuestionText>{question}</QuestionText>
      <Answers>
        {type === QuestionType.SingleChoice && answers.map((answer) => (
          <SingleChoiceAnswer
            key={answer.id}
            onClick={() => handleSingleChoiceValueChange(answer.id)}
            active={answer.id === answerId}
          >
            {answer.answer}
          </SingleChoiceAnswer>
        ))}
        {type === QuestionType.Open && (
          <InputWrapper>
            <TextAreaInput
              placeholder={t("anonSurvey.writeAnswer")}
              value={value}
              onChange={({ target }) => handleInputValueChange(answers[0].id, target.value)}
              autoFocus
            />
            <InputCounter error={value?.length > OpenQuestionMaxLength}>
              {value?.length}/{OpenQuestionMaxLength}
            </InputCounter>
          </InputWrapper>
        )}
      </Answers>
      {type === QuestionType.SingleChoice && (
        <>
          <Description>
            {answers[0].answer} - {answers[0].description}
          </Description>
          <Description>
            {answers[answers.length - 1].answer} - {answers[answers.length - 1].description}
          </Description>
        </>
      )}
      <Buttons>
        <PrevButton onClick={onPrevClick}>
          <ArrowPrev />
        </PrevButton>
        <NextButton
          onClick={onNextClick}
          disabled={type === QuestionType.Open ? !value || value.length > OpenQuestionMaxLength : !answerId}
        >
          {t("global.next")}
          <ArrowNext />
        </NextButton>
      </Buttons>
    </Container>
  );
};

Question.propTypes = {
  sectionTitle: PropTypes.string,
  question: PropTypes.string,
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
  type: PropTypes.string,
  answers: PropTypes.array,
  setValue: PropTypes.func,
  value: PropTypes.string,
  answerId: PropTypes.number,
};
