import React, { useCallback } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Color } from "../../../../theme/colors";
import { ItemType, ContentItemType } from "./videosSection.constants";
import { AppConfig } from "../../../../appConfig";

import { Thumbnail, ThumbnailListRow } from "../../../../UIElements"; // TODO: change thumbnailList after new dashboard merge
import { Container, ContentWrapper, VideosWrapper } from "./videosSection.styled";
import { OnboardingActions } from "../../redux/onboarding.reducer";

const handleThumbnailClick = (item, navigate, onFileDownload) => {
  if (item.type.name === ItemType.Download) {
    onFileDownload(item.id);
    window.open(`${AppConfig.content}${item.url}`);
  } else if (item.contentType) {
    if (item.contentType === ContentItemType.Lesson || item.contentType === ContentItemType.ScrollLesson) {
      navigate(`/lekcje/${item.id}`);
    } else {
      navigate(`/wideo/${item.id}`);
    }
  }
};

const VideosSection = ({ content }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFileDownload = useCallback(
    (id) => {
      dispatch(OnboardingActions.registerFileDownloading(id));
    },
    [dispatch],
  );

  const renderRows = () => (
    content.map((row, index) => (
      <ThumbnailListRow
        paddingBottom={36}
        marginBottom={60}
        borderBottom
        title={row.title}
        key={index}
      >
        {row.items.map((item, index) => renderThumbnail(item, index))}
      </ThumbnailListRow>
    ))
  );

  const renderThumbnail = (item, index) => {
    const badges = [];
    const progress = item.progress && item.progress > 0 ? item.progress : null;
    const date = item.contentValidity && dayjs.unix(item.contentValidity).format("DD.MM.YYYY");
    const uri = item.type.name === ItemType.Download ? "/assets/img/files.jpg" : item.image;
    const background = item.type.name === ItemType.Download ? Color.lightYellow : false;

    if (item.award) {
      badges.push(item.award);
    } else if (!item.award && item.completed) {
      badges.push("completed");
    }

    if (item.type.name === ItemType.Download) {
      badges.push("download");
    }

    return (
      <Thumbnail
        key={index}
        action={() => handleThumbnailClick(item, navigate, onFileDownload)}
        title={item.name}
        badges={badges}
        itemsType={item.type}
        progress={progress}
        date={date}
        required={item.required}
        download={item.type.name === ItemType.Download}
        uri={uri}
        background={background}
      />
    );
  };

  return (
    <Container>
      <ContentWrapper>
        <VideosWrapper>
          {renderRows()}
        </VideosWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default VideosSection;
