import { createSelector } from "reselect";
import { prop } from "ramda";

export const selectSurveysDomain = prop("surveys");

export const selectIsPending = createSelector(
  selectSurveysDomain, prop("isPending"),
);

export const selectTests = createSelector(
  selectSurveysDomain, prop("tests"),
);

export const selectSurveys = createSelector(
  selectSurveysDomain, prop("surveys"),
);

export const selectCompetenceMeasures = createSelector(
  selectSurveysDomain, prop("competenceMeasures"),
);
