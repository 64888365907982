import styled, { css } from "styled-components";
import { prop } from "ramda";

import { hexToRgba } from "../../../../helpers/colors";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { ReactComponent as CheckSvg } from "../../../../images/check3.svg";
import { Color, FontWeight } from "../../../../theme";

export const Container = styled.div`
  position: absolute;
  bottom: 185%;
  right: 0;
  background-color: ${Color.deepGray};
  width: 280px;
  z-index: 1;
`;


export const List = styled.ul`
  margin-bottom: 10px;
`;

export const Label = styled.p`
  text-transform: uppercase;
  color: ${Color.gray4};
  font-size: 10px;
  line-height: 22px;
  letter-spacing: 0.6px;
  font-weight: ${FontWeight.SemiBold};
  border-bottom: 1px solid ${hexToRgba(Color.almostWhite, 0.1)};
  margin: 10px 20px;
  padding-bottom: 4px;
`;

export const Item = styled.li`
  margin: 10px 0;
`;

export const CheckIcon = styled(CheckSvg)`
  margin-right: 10px;
  flex-shrink: 0;
  visibility: hidden;
`;

export const Button = styled.button`
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  color: ${Color.gray4};
  background-color: ${Color.transparent};
  border: none;
  text-align: left;
  padding: 0 20px;
  display: flex;

  &:hover {
    color: ${Color.white};
  }

  ${styleWhenTrue(prop("activated"), css`
    color: ${Color.white};

    ${CheckIcon} {
      visibility: visible;
    }
  `)}
`;