import { phpApi, nodeApi, handleApiError } from "./default";

export const getBrands = () => {
  return phpApi.get("/api/brands").catch(handleApiError);
};

export const getVisibility = () => {
  return nodeApi.get("/visibility");
};

export const unlockVideo = (code) => {
  return phpApi.post("/api/limited/content/unlock", {
    code,
  }).catch((error) => error.response);
};

export const getPaidVideos = () => {
  return phpApi.get("/api/limited/content").catch(handleApiError);
};

export const fetchNotifications = () => {
  return phpApi.get("/api/notification/list").catch(handleApiError);
};

export const userProfileEditConfirm = () => {
  return nodeApi.post("/verification/data").catch(handleApiError);
};

export const sendRegulationsConfirmation = (agreementChangesId) => {
  return phpApi.post("api/user/agreement/changes", {
    agreementChangesId,
  }).catch(handleApiError);
};

export const updateUserAgreements = (agreements) => {
  return nodeApi.post(
    "/agreements",
    agreements,
    { sendInBody: true },
  ).catch(handleApiError);
};

export const fetchFavouriteItems = () => {
  return phpApi.get("/api/user/favorite").catch(handleApiError);
};

export const addFavouriteItem = (type, id) => {
  return phpApi.post(`/api/user/favorite/${type}/${id}`).catch(handleApiError);
};

export const removeFavouriteItem = (type, id) => {
  return phpApi.delete(`/api/user/favorite/${type}/${id}`).catch(handleApiError);
};

export const fetchPermissions = () => {
  return phpApi.get("/api/user/permissions").catch(handleApiError);
};

export const fetchUserData = () => {
  return phpApi.get("/api/user/info").catch(handleApiError);
}

export const deleteComment = (commentId) => {
  return nodeApi.delete(`/comments/${commentId}`);
};

export const editComment = (comment) => {
  return nodeApi.put(`/comments/${comment.id}`, comment);
};

export const changeUserPhoneNumber = (phoneNumber) => {
  return nodeApi.put("/profile/phone", { phone: phoneNumber });
};

export const updatePassword = (oldPassword, newPassword) => {
  return phpApi.post(
    "/api/user/update-password",
    { password: oldPassword, newPassword },
    { removeDataBody: true },
  ).catch((error) => {
    return error.response;
  });
};
