import styled, { css } from "styled-components";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import { ReactComponent as ArrowSvg } from "../../../../images/arrow.svg";
import { ReactComponent as SearchSvg } from "../../../../images/search.svg";
import { Color, FontWeight, Media, SectionHeader } from "../../../../theme";
import { ReactComponent as CalendarSvg } from "../../images/calendar.svg";

export const Container = styled.div`
  background-color: ${Color.white};
  padding: 40px 20px;

  ${Media.tablet`
    padding: 40px 35px;
  `}
`;

export const Header = styled(SectionHeader)`
  margin-left: 0;
`;

export const InputRow = styled.div`
  margin-top: 15px;

  ${Media.tablet`
    display: flex;
    align-items: center;
    margin-top: 25px;
    gap: 15px;
    flex-wrap: wrap;
  `}
`;

export const TextInputWrapper = styled.div`
  width: 100%;
  border: 1px solid ${Color.dirtyWhite};
  padding: 15px;
  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  font-size: 14px;
  line-height: 19px;
  width: 100%;
  border: none;

  ::placeholder {
    color: ${Color.gray9};
  }
`;

export const DropdownInputs = styled.div`
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  ${Media.tablet`
    flex-direction: row;
    margin-bottom: 0;
    flex: 1;
    flex-shrink: 0;
  `}
`;

export const DateInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;

  ${Media.tablet`
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex: 1;
    flex-shrink: 0;
  `}
`;

const arrowStyles = css`
  fill: ${() => Color.brandBasic};
  width: 7px;
  height: 13px;
`;

export const PrevArrowIcon = styled(ArrowSvg)`
  ${arrowStyles}
`;

export const NextArrowIcon = styled(ArrowSvg)`
  ${arrowStyles}
  transform: rotateY(180deg);
`;


export const DateInput = styled(DatePicker)`
  flex: 1;
  min-width: 200px;
  width: 100%;

  .react-date-picker__wrapper {
    border: 1px solid ${Color.dirtyWhite};
    height: 36px;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .react-date-picker__inputGroup__input,
  .react-date-picker__inputGroup__divider,
  .react-date-picker__inputGroup__leadingZero {
    font-size: 12px;
    line-height: 16px;
    font-weight: ${FontWeight.SemiBold};
  }

  .react-calendar__navigation__label {
    pointer-events: none;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: ${FontWeight.SemiBold};
  }

  .react-calendar__month-view__weekdays__weekday {
    font-size: 14px;
    line-height: 19px;
    font-weight: ${FontWeight.SemiBold};
    color: ${Color.gray1};
    text-transform: capitalize;

    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__navigation__arrow:disabled {
    background-color: ${Color.transparent};
    pointer-events: none;

    ${PrevArrowIcon}, ${NextArrowIcon} {
      fill: ${Color.gray9};
    }
  }

  .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background-color: ${Color.transparent};
  }

  .react-calendar {
    border: none;
    box-shadow: 0px 2px 40px rgba(48, 54, 60, 0.1), 0px 4px 25px rgba(31, 27, 22, 0.02);
    padding: 20px;
  }

  .react-date-picker__calendar {
    max-width: auto;
  }

  .react-calendar__tile abbr {
    font-size: 14px;
    line-height: 19px;
    font-weight: ${FontWeight.SemiBold};
  }

  .react-date-picker__inputGroup__input {
    min-width: unset;
  }

  .react-date-picker__calendar-button {
    margin-right: 12px;
  }
`;

export const DropdownWrapper = styled.div`
  flex: 1;
  min-width: 200px;
`;

export const DateSeparator = styled.div`
  display: none;

  ${Media.tablet`
    display: block;
    width: 8px;
    height: 1px;
    background-color: ${Color.gray1};
    margin-bottom: 10px;
  `}
`;

export const DateInputIcon = styled(CalendarSvg)`
  fill: ${() => Color.brandBasic};
`;

export const SearchIcon = styled(SearchSvg)`
  flex-shrink: 0;
  fill: ${() => Color.brandBasic};
  margin-right: 15px;
`;
