import styled, { css } from "styled-components";
import { propEq } from "ramda";

import { Button } from "../../../../components/button/button.component";
import { ReactComponent as SeatsSvg } from "../../../../images/trainings.svg";
import { ReactComponent as PlaceSvg } from "../../../../images/pin.svg";
import { hexToRgba } from "../../../../helpers/colors";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { ReactComponent as ArrowSvg } from "../../../../images/arrow.svg";
import { ReactComponent as CloseSvg } from "../../../../images/close.svg";
import { Color, FontWeight, Media, Separator } from "../../../../theme";

import { ReactComponent as ChecklistSvg } from "../../images/checklist.svg";
import { ReactComponent as DotsSvg } from "../../images/dots.svg";
import { ReactComponent as TableSvg } from "../../images/table.svg";
import { ReactComponent as TargetSvg } from "../../images/target.svg";
import { ReactComponent as PeopleSvg } from "../../images/people.svg";
import { ReactComponent as MoneySvg } from "../../images/money.svg";
import { ReactComponent as UmbrellaSvg } from "../../images/umbrella.svg";
import { ReactComponent as ProductTrainingSvg } from "../../images/productTraining.svg";
import { ReactComponent as SystemSvg } from "../../images/system.svg";
import { ReactComponent as CalendarEventSvg } from "../../images/calendarEvent.svg";

export const Container = styled.div`
  background-color: ${Color.white};
  box-shadow: 0 5px 10px ${hexToRgba(Color.deepGray, 0.01)};
  margin-bottom: 2px;
`;

export const ArrowIcon = styled(ArrowSvg)`
  fill: ${Color.gray9};
  width: 10px;
  height: 16px;
  transform: rotate(90deg);
  transition: transform 0.3s;
  flex-shrink: 0;
  margin-left: auto;

  ${styleWhenTrue(propEq("true", "collapsed"), css`
    transform: rotate(90deg) scaleX(-1);
  `)}
`;

export const Header = styled.div`
  padding: 50px 20px;
  cursor: pointer;

  ${Media.tablet`
    display: flex;
    align-items: center;
    padding: 50px 40px;
  `}
`;

export const HeaderTextWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const HeaderText = styled.div``;

export const HeaderTitle = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: ${FontWeight.SemiBold};
`;

export const HeaderSubtitle = styled.p`
  margin-top: 5px;
  font-size: 13px;
  line-height: 17px;
  color: ${Color.gray9};
`;

export const Schedule = styled.div`
  padding: 0 20px 50px;

  ${Media.tablet`
    padding: 0 40px 50px;
  `}

  ${Media.desktop`
    padding: 0 115px 50px;
  `}
`;

export const ScheduleTitle = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: ${Color.deepGray};
  letter-spacing: 0.6px;
  text-transform: uppercase;
  font-weight: ${FontWeight.Bolder};
`;

export const ScheduleSeparator = styled(Separator)`
  margin: 20px 0;
`;

export const ScheduleItem = styled.div`
  ${Media.tablet`
    display: flex;
    align-items: center;
  `}
`;

export const ScheduleTime = styled.div`
  background-color: ${() => hexToRgba(Color.brandBasic, 0.1)};
  color: ${() => Color.brandBasic};
  font-weight: ${FontWeight.Bold};
  font-size: 10px;
  line-height: 20px;
  padding: 4px 16px;
  border-radius: 17px;
  margin-right: 5px;
  text-align: center;
  display: inline-block;
`;

export const InfoTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;

  ${Media.tablet`
    margin: 0 10px;
  `}
`;

export const SeatsIcon = styled(SeatsSvg)`
  stroke: ${() => Color.brandBasic};
  width: 14px;
  height: 14px;
  margin-right: 10px;
  flex-shrink: 0;
`;

export const PlaceIcon = styled(PlaceSvg)`
  fill: ${() => Color.brandBasic};
  height: 18px;
  width: 12px;
  margin-right: 10px;
  flex-shrink: 0;
`;

export const InfoText = styled.div`
  font-size: 12px;
  line-height: 17px;
`;

export const Buttons = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const EnrollButton = styled(Button)`
  min-width: unset;
  max-height: 40px;
  padding: 10px 20px;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

export const DetailsButton = styled(Button)`
  min-width: unset;
  max-height: 40px;
  padding: 10px 20px;
  white-space: nowrap;
  margin-left: 10px;
`;

export const CloseIcon = styled(CloseSvg)`
  margin-left: 14px;
  width: 13px;
  height: 13px;
  flex-shrink: 0;

  g {
    stroke: ${Color.white};
  }
`;

const itemIconStyles = css`
  margin-right: 35px;
  margin-bottom: 25px;
  flex-shrink: 0;

  path {
    fill: ${Color.black};
  }

  ${Media.tablet`
    margin-bottom: 0;
  `}
`;

export const ExamsIcon = styled(ChecklistSvg)`
  ${itemIconStyles}
`;

export const ProductOthersIcon = styled(DotsSvg)`
  ${itemIconStyles}
`;

export const OthersIcon = styled(TableSvg)`
  ${itemIconStyles}
`;

export const DevelopmentIcon = styled(TargetSvg)`
  ${itemIconStyles}
`;

export const HRIcon = styled(PeopleSvg)`
  ${itemIconStyles}
`;

export const MoneyIcon = styled(MoneySvg)`
  ${itemIconStyles}
`;

export const InsuranceIcon = styled(UmbrellaSvg)`
  ${itemIconStyles}
`;

export const SystemIcon = styled(SystemSvg)`
  ${itemIconStyles}
`;

export const ProductTrainingIcon = styled(ProductTrainingSvg)`
  ${itemIconStyles}
`;

export const InfoEventsIcon = styled(CalendarEventSvg)`
  ${itemIconStyles}
`;
