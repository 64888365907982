import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import ReactCrop, { makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { ButtonVariant } from "../button/button.constants";
import { CropSettings, FileMaxSizeInMB, ImageAspectRatio } from "./imageUpload.constants";
import {
  ImageContainer,
  DropzoneContainer,
  IconBackground,
  Title,
  Subtitle,
  RemoveButton,
  ErrorMessage,
  CameraIcon,
} from "./imageUpload.styled";

export const ImageUpload = ({
  onChange,
  name,
  value,
  onPositionChange,
  positionX,
  positionY,
  className,
}) => {
  const { t } = useTranslation();
  const [imageSrc, setImageSrc] = useState(typeof value === "string" ? value : URL.createObjectURL(value));
  const [crop, setCrop] = useState({ ...CropSettings, x: positionX, y: positionY });
  const imgRef = useRef();

  useEffect(() => {
    setImageSrc(typeof value === "string" ? value : URL.createObjectURL(value));
  }, [value]);

  useEffect(() => {
    if (crop.width < 100) {
      setCrop({ ...crop, width: 100 });
    }
  }, [crop]);

  useEffect(() => {
    setTimeout(() => {
      if (imageSrc) {
        const aspectCrop = makeAspectCrop(
          {
            unit: "%",
            width: 100,
          },
          ImageAspectRatio,
          imgRef.current.naturalWidth,
          imgRef.current.naturalHeight,
        );

        setCrop({ ...aspectCrop, x: positionX, y: positionY });
      }
    }, 1);
  }, [imageSrc]);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length) {
      const image = acceptedFiles[0];
      setErrorMode(false);
      if (image.size > FileMaxSizeInMB * 1024 * 1024) {
        setErrorMode(true);
      } else {
        const event = { target: { name, value: image }};
        onChange(event);
      }
    } else {
      setErrorMode(true);
    }
  }, [name, onChange]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/png, image/jpg, image/jpeg",
  });
  const [errorMode, setErrorMode] = useState(false);

  const handleRemoveImage = useCallback(() => {
    const event = { target: { name, value: "" }};
    onChange(event);
    setCrop(CropSettings);
  }, [name, onChange]);

  const handleSetCrop = (crop) => {
    const aspectCrop = makeAspectCrop(
      crop,
      ImageAspectRatio,
      imgRef.current.naturalWidth,
      imgRef.current.naturalHeight,
    );
    setCrop(aspectCrop);
    onPositionChange(crop.x, crop.y);
  };

  if (value) {
    return (
      <ImageContainer>
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => handleSetCrop(percentCrop)}
          locked
          keepSelection
          disabled={crop.width < 100}
        >
          <img src={imageSrc} ref={imgRef} />
        </ReactCrop>
        <RemoveButton variant={ButtonVariant.Outlined} onClick={handleRemoveImage}>
          {t("components.imageUpload.removeImage")}
        </RemoveButton>
      </ImageContainer>
    );
  }

  return (
    <DropzoneContainer {...getRootProps()} className={className} errorMode={errorMode}>
      <input
        {...getInputProps()}
        name={name}
        value={value}
        accept="image/png, image/jpg, image/jpeg"
        multiple={false}
      />
      <IconBackground>
        <CameraIcon />
      </IconBackground>
      <Title>{t("components.imageUpload.addImage")}</Title>
      <Subtitle>
        {t("components.imageUpload.imageTypes", { maxSize: FileMaxSizeInMB })}
      </Subtitle>
      <ErrorMessage errorMode={errorMode}>
        {t("components.imageUpload.errorMsg")}
      </ErrorMessage>
    </DropzoneContainer>
  );
};


ImageUpload.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  onPositionChange: PropTypes.func,
  positionX: PropTypes.number,
  positionY: PropTypes.number,
};
