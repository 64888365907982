import styled from "styled-components";

import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.div`
  margin-top: 20px;
  max-width: 290px;
  flex: 1;

  ${Media.tablet`
    margin-right: 10px;
  `}
`;

export const InputLabel = styled.p`
  font-size: 15px;
  line-height: 22px;
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.gray9};
  margin-bottom: 10px;
`;
