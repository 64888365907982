import { prop, propEq } from "ramda";
import styled, { css } from "styled-components";

import { hexToRgba } from "../../../../helpers/colors";
import { styleIfElse, styleWhenTrue } from "../../../../helpers/rendering";
import { Color, FontWeight } from "../../../../theme";
import { ReactComponent as VoiceSvg } from "../../images/voice.svg";
import { ReactComponent as StarSvg } from "../../images/star.svg";

export const Video = styled.video`
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  min-height: 100%;

  ${styleWhenTrue(prop("hidden"), css`
    visibility: hidden;
  `)}

  ${styleWhenTrue(prop("isCurrentUser"), css`
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
  `)}
`;

export const VideoBox = styled.div`
  position: relative;
  background-color: ${Color.deepGray};

  ${styleWhenTrue(prop("inGrid"), css`
    border: 1px solid ${hexToRgba(Color.white, 0.1)};
    aspect-ratio: 16/9;
    place-self: center;
    grid-column: span 2;
    order: 1;

    ${props => props.shiftNumber && `margin-left: calc(100% * ${props.shiftNumber})`};

    ${styleIfElse(prop("setHighest"), css`
      height: 100%;
    `, css`
      width: 100%;
    `)}

    ${Video} {
      aspect-ratio: 16/9;
    }
  `)}

  ${styleWhenTrue(propEq(false, "inGrid"), css`
    ${styleWhenTrue(prop("isMainStream"), css`
      position: absolute;
      top: 60px;
      left: 0;
      bottom: 60px;
      right: 267px;

      ${styleWhenTrue(prop("wideRightPanel"), css`
        right: 320px;
      `)}
    `)}
  `)}

  ${styleWhenTrue(prop("isSpeaking"), css`
    &:after {
      content: "";
      position: absolute;
      left: -1px;
      right: 0;
      top: -1px;
      bottom: -1px;
      border: 4px solid ${() => Color.brandBasic};
    }
  `)}
  
  ${styleWhenTrue(prop("isRightPanelStream"), css`
    border-left: 1px solid ${Color.gray5};
    border-bottom: 1px solid ${Color.gray5};
    height: 150px;

    ${Video} {
      height: 100%;
    }
  `)}

  ${styleWhenTrue(prop("isHidden"), css`
    width: 0;
    height: 0;
    border: none;
    visibility: hidden;
    
    ${styleWhenTrue(prop("inGrid"), css`
      order: 2;
    `)}
  `)}
`;

export const Initials = styled.div`
  display: grid;
  place-content: center;
  width: 80px;
  height: 80px;
  border: 1px solid ${() => Color.brandBasic};
  border-radius: 50%;
  font-weight: bold;
  font-size: 16px;
  color: ${Color.white};
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);

  ${styleWhenTrue(prop("small"), css`
    width: 55px;
    height: 55px;
    font-size: 14px;
  `)}

  ${styleWhenTrue(prop("isMainStream"), css`
    width: 160px;
    height: 160px;
    font-size: 32px;
  `)}
`;

export const Indicators = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 10px;
  left: 10px;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: ${hexToRgba(Color.black, 0.4)};
  border-radius: 2px;
  margin: 0 2px;

  path {
    color: ${Color.red};
  }
`;

export const NameBox = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 10px;
  height: 28px;
  background-color: ${hexToRgba(Color.black, 0.4)};
  border-radius: 2px;
  color: ${Color.white};
  font-weight: ${FontWeight.SemiBold};
  font-size: 9px;
  margin-right: 6px;
`;

export const Voice = styled(VoiceSvg)``;

export const StarIcon = styled(StarSvg)`
  width: 28px;
  height: 28px;
  padding: 8px;
  background-color: ${Color.yellow2};
  border-radius: 2px;
  margin-right: 2px;
`;
