import styled from "styled-components";

import { breakpoints } from "../../UIGlobals";
import { Button } from "../../UIElements";
import { Color } from "../../theme";

export const ModalContent = styled.div`
  padding-top: 100px;
  padding-bottom: 60px;

  @media (${breakpoints().maxL}) {
    padding-top: 50px;
    padding-bottom: 30px;
  }
`;

export const ErrorText = styled.p`
  font-size: 16px;
  padding: 40px 0;
  margin: 0 60px 60px;
  border-top: 1px solid ${Color.gray2};
  border-bottom: 1px solid ${Color.gray2};
  color: ${Color.black};
  text-align: center;

  @media (${breakpoints().maxL}) {
    margin: 0 20px 60px;
    padding: 30px 0;
  }
`;

export const ConfirmButton = styled(Button)`
  margin: 0 auto;
  width: 180px;
  text-transform: uppercase;
`;
