export const FieldType = {
  String: "string",
  Email: "email",
  Dropdown: "dropdown",
  LongDropdown: "long_dropdown",
  DropdownMultiple: "dropdown_multiple",
  Date: "timestamp_date",
  Integer: "integer",
  StringArray: "string_array",
  Checkbox: "checkbox",
};

export const SectionName = {
  personalData: "personal_data",
  experiences: "experiences",
};

export const FieldName = {
  Company: "company",
  Position: "position",
  EmploymentDateFrom: "employment_date_from",
  EmploymentDateTo: "employment_date_to",
  PositionFinish: "position_finish",
  Brands: "brands",
};

export const ProfileEditTheme = {
  Default: "default",
  WithCompany: "withCompany",
};

export const MinNameLength = 2;
export const MaxNameLength = 64;
export const MinSurnameLength = 2;
export const MaxSurnameLength = 128;
