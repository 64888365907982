import styled from "styled-components";

import { Button as ButtonBase } from "../../../../components/button/button.component";
import { Color, FontWeight } from "../../../../theme";
import { ReactComponent as AddSvg } from "../../images/add.svg";
import { ReactComponent as TickSvg } from "../../images/tick.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  letter-spacing: 0.6px;
  text-transform: uppercase;
  font-weight: ${FontWeight.Bold};
  font-size: 12px;
`;

export const TickIcon = styled(TickSvg)`
  border: 1px solid ${Color.green};
  border-radius: 3px;
  width: 20px;
  height: 20px;
  padding: 4px 3px 3px 3px;
  margin-left: auto;

  path {
    fill: ${Color.green};
  }
`;

export const Separator = styled.div`
  margin: 0 15px;
  height: 20px;
  width: 1px;
  background-color: ${Color.gray1};
`;

export const GroupName = styled(Title)`
  color: ${Color.gray1};
`;

export const AddButton = styled.button`
  outline: none;
  border: none;
  margin-left: auto;
  background-color: ${Color.transparent};
  padding: 0;
`;

export const AddIcon = styled(AddSvg)`
  path {
    fill: ${() => Color.brandBasic};
  }
`;

export const UserRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;

  & + & {
    margin-top: 14px;
  }
`;

export const UserName = styled.p`
  font-weight: ${FontWeight.SemiBold};
  font-size: 12px;
  color: ${Color.gray9};
  margin-left: 10px;
`;

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  height: 123px;
  flex-shrink: 0;
  align-items: center;
`;

export const Button = styled(ButtonBase)`
  &:disabled {
    color: ${Color.gray1};
    background-color: ${Color.almostWhite};
  }

  & + & {
    margin-left: 10px;
  }
`;
