import styled from "styled-components";

import { Color } from "../../../../theme";
import { ReactComponent as MagnifierSvg } from "../../images/magnifier.svg";

export const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${Color.gray8};
  flex-shrink: 0;
  width: 100%;
  height: 40px;
  margin-top: 15px;
  padding-left: 15px;
`;

export const MagnifierIcon = styled(MagnifierSvg)``;

export const SearchInput = styled.input`
  border: none;
  padding-left: 10px;
  width: 100%;
  color: ${Color.deepGray};

  ::placeholder {
    color: ${Color.gray9};
  }
`;
