import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Modal } from "../../../../components/modal/modal.component";
import { Button as ButtonBase } from "../../../../components/button/button.component";
import { ReactComponent as AddSvg } from "../../../../images/addInCircle.svg";
import { ReactComponent as CloseSvg } from "../../../../images/closeInCircle.svg";
import { ReactComponent as UploadIconBase } from "../../../../images/upload.svg";
import { hexToRgba } from "../../../../helpers/colors";
import { styleWhenTrue } from "../../../../helpers/rendering";
import {
  Color,
  Separator as SeparatorBase,
  SectionHeader as HeaderBase,
  FontWeight,
  Media,
} from "../../../../theme";

export const Container = styled(Modal)``;

export const SectionHeader = styled(HeaderBase)`
  margin-left: 0;
  
  &:last-of-type {
    margin-top: 35px;
  }
`;

export const AddIcon = styled(AddSvg)`
  g {
    stroke: ${() => Color.brandBasic};
  }
`;

export const Fields = styled.div`
  padding: 35px 20px 60px;

  ${Media.tablet`
    padding: 35px 30px 60px;
  `}
`;

export const Button = styled(ButtonBase)`
  &:disabled {
    color: ${Color.gray1};
  }
`;

export const FileInfo = styled.p`
  margin-top: 15px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: ${Color.gray4};
`;

export const AddedFile = styled.div`
  background-color: ${Color.almostWhite};
  display: inline-flex;
  align-items: center;
`;

export const FileName = styled.p`
  font-weight: ${FontWeight.SemiBold};
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.17px;
  color: ${Color.deepGray};
  margin: 12px 20px;
  word-break: break-word;
`;

export const RemoveButton = styled.button`
  background-color: ${Color.transparent};
  border: none;
  padding: 14px;
  display: flex;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

export const RemoveIcon = styled(CloseSvg)`
  fill: ${Color.almostBlack};
`;

export const FileErrorWrapper = styled.div`
  display: flex;
`;

export const FileError = styled.p`
  border-radius: 2px;
  padding: 12px 15px;
  font-size: 12px;
  line-height: 16px;
  color: ${Color.deepGray};
  background-color: ${Color.lightYellow};
  margin-top: 5px;
`;

export const Separator = styled(SeparatorBase)``;

export const Actions = styled.div`
  padding: 30px 20px;
  display: flex;
  justify-content: flex-end;

  ${Media.tablet`
    padding: 30px;
  `}
`;

export const FileDropContainer = styled.div`
  height: 270px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${hexToRgba(Color.gray5, 0.1)};
  border: 0.4px dashed ${Color.gray1};
  border-radius: 3px;
`;

export const CircleIconWrapper = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 100%;
  margin-bottom: 20px;
  background-color: ${() => hexToRgba(Color.brandBasic, 0.05)};
  display: flex;
  justify-content: center;
  align-items: center;

  ${styleWhenTrue(prop("isDragActive"), css`
    opacity: 0.5;
  `)}
`;

export const UploadIcon = styled(UploadIconBase)`
  width: 24px;
  fill: ${() => Color.brandBasic};
`;

export const DropzoneText = styled.h1`
  font-size: 16px;
  color: ${Color.deepgray};
`;

export const DropzoneLightText = styled.p`
  font-size: 12px;
  line-height: 22px;
  color: ${Color.gray4};
`;

export const DropzoneLink = styled.span`
  position: relative;
  color: ${() => Color.brandBasic};
  cursor: pointer;
  padding-bottom: 2px;
  border-bottom: 1px solid ${() => Color.brandBasic};
`;
