import styled, { css, keyframes } from "styled-components";
import { prop } from "ramda";
import Lottie from "lottie-react";

import { hexToRgba } from "../../../../helpers/colors";
import { ReactComponent as ArrowSvg } from "../../../../images/arrow.svg";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.div``;

export const ContentWrapper = styled.div`
  ${Media.tablet`
    display: flex;
    position: relative;

    ${styleWhenTrue(prop("$isFullAnim"), css`
      background-color: ${Color.white};
    `)}

    ${styleWhenTrue(prop("$withGradientBgStyle"), css`
      background-color: ${Color.transparent};
    `)}
  `}
`;

export const AnimationWrapper = styled.div`
  width: 100%;

  ${Media.tablet`
    width: 56%;
    min-width: 56%;
  `}
`;

export const Title = styled.p`
  font-size: 38px;
  line-height: 51px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.deepGray};
`;

export const TitleSeparator = styled.div`
  max-width: 480px;
  height: 2px;
  background-color: ${() => Color.brandBasic};
  margin: 20px 0;
`;

export const Description = styled.p`
  font-size: 18px;
  line-height: 28px;
  ${Color.deepGray};
`;

export const ArrowIcon = styled(ArrowSvg)`
  fill: ${Color.gray9};
  height: 15px;
  width: 9px;
  transform: rotate(-90deg);
  position: relative;
  top: 4px;
  transition: all 0.2s ease-in-out;
`;

const beating = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100%  { transform: scale(1); }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ScrollButton = styled.button`
  margin-top: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: ${Color.white};
  box-shadow: 0 2px 4px ${hexToRgba(Color.darkGray, 0.2)}, 0 0 20px ${hexToRgba(Color.deepGray, 0.03)};
  flex-shrink: 0;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  animation: ${beating} 1s infinite;

  ${Media.tablet`
    margin-top: 0;
  `}

  &:hover {
    background-color: ${() => Color.brandBasic};

    ${ArrowIcon} {
      fill: ${Color.white};
    }
  }
`;

export const Placeholder = styled.img`
  width: 100%;
`;

export const StyledLottie = styled(Lottie)`
  ${Media.tablet`
    ${styleWhenTrue(prop("$isFullAnim"), css`
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -150px;
    `)}
  `}
`;
