import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../helpers/rendering";
import { Media } from "../../theme";

export const SummaryWrapper = styled.div`
  ${styleWhenTrue(prop("wrapped"), css`
    max-width: 980px;
    margin: 0 auto;
    padding: 0;

    ${Media.tablet`
      padding: 0 20px;
    `}
  `)}
`;
