import styled from "styled-components";

import { ReactComponent as StarSvg } from "../../../../images/star.svg";
import { Color, FontWeight } from "../../../../theme";

export const Container = styled.div`
  background-color: ${Color.white};
  width: 100%;
  padding: 30px 30px 35px;
  position: relative;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const StarIcon = styled(StarSvg)`
  background-color: ${Color.gold};
  fill: ${Color.white};
  padding: 6px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 16px;
`;

export const MainLabel = styled.p`
  font-weight: ${FontWeight.Bold};
  font-size: 14px;
  line-height: 20px;
`;

export const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 320px;
  margin: 30px -20px 40px;
  overflow: hidden;
`;

export const DotsMenuButton = styled.button`
  position: absolute;
  right: 13px;
  top: 6px;
  border: none;
  background-color: ${Color.transparent};
`;

export const DotsMenuDropdown = styled.div`
  position: absolute;
  right: 5px;
  top: 23px;
  z-index: 2;
`;

export const DotsMenuItem = styled.div`
  background-color: ${Color.deepGray};
  color: ${Color.white};
  font-weight: ${FontWeight.SemiBold};
  font-size: 12px;
  text-align: center;
  padding: 10px;
  border-radius: 2px;
`;
