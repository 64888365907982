import styled from "styled-components";

import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  ${Media.tablet`
    gap: 40px;
  `}
`;

export const AvatarBox = styled.div`
  width: 64px;
  height: 64px;
  padding: 4px;
  border: 1px solid ${() => Color.brandBasic};
  border-radius: 50%;
  flex-shrink: 0;

  ${Media.tablet`
    width: 126px;
    height: 126px;
    padding: 8px;
    border: 2px solid ${() => Color.brandBasic};
  `}
`;

export const Avatar = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

export const TextBox = styled.div`
  word-break: break-all;
`;

export const Name = styled.p`
  font-size: 20px;
  line-height: 27px;
  font-weight: ${FontWeight.Bold};
  letter-spacing: 0.38px;

  ${Media.tablet`
    font-size: 30px;
    line-height: 40px;
  `}
`;

export const Email = styled.a`
  margin-top: 6px;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.16px;
  color: ${() => Color.brandBasic};
  text-decoration: none;
`;
