import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Color, Media } from "../../../../../../../theme";
import { styleWhenTrue } from "../../../../../../../helpers/rendering";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: ${Color.almostBlack};
  margin-bottom: 5px;
  font-size: 14px;
  width: 100%;
  padding: 22px 17px;
  border: 1px solid ${Color.gray5};
  box-sizing: border-box;

  ${styleWhenTrue(prop("correctTheme"), css`
    border: 2px solid ${Color.green};
    color: ${Color.green};
    padding: 21px 16px;
  `)}
  
  ${styleWhenTrue(prop("wrongTheme"), css`
    border: 2px solid ${Color.red4};
    color: ${Color.red4};
    padding: 21px 16px;
  `)}

  ${Media.tablet`
    width: 80%;
    font-size: 16px;
  `}
`;

export const CheckBox = styled.div`
  min-height: 40px;
  min-width: 40px;
  background-color: ${Color.transparent};
  border: 1px solid ${Color.gray5};
  margin-right: 20px;
  position: relative;
  &:after {
    content: "";
    height: 20px;
    width: 20px;
    background-color: ${Color.transparent};
    position: absolute;
    top: 9px;
    left: 9px;
  }

  ${Media.tablet`
    margin-right: 38px;
    min-height: 50px;
    min-width: 50px;
    &:after {
      content: "";
      height: 24px;
      width: 24px;
      position: absolute;
      top: 12px;
      left: 12px;
    }
  `}

  ${styleWhenTrue(prop("correctTheme"), css`
    &:after {
      background-color: ${Color.green};
    }
  `)}
  
  ${styleWhenTrue(prop("wrongTheme"), css`
    &:after {
      background-color: ${Color.red4};
    }
  `)}
`;

export const Label = styled.span`
  letter-spacing: 0;
  line-height: 26px;

  ${Media.tablet`
    line-height: 22px;
  `}
`;