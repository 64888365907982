import styled from "styled-components";

import { Color, FontWeight, Media } from "../../../../../theme";
import { HeaderContainer as HeaderContainerBase } from "../../sequentialQuizInteractions.styled";

export const Container = styled.div`
  min-height: 650px;
  height: 100%;
  padding: 50px 25px;
  margin-top: 40px;

  ${Media.tablet`
    padding: 30px;
  `}

  ${Media.desktop`
    padding: 30px 0;
  `}
`;

export const HeaderContainer = styled(HeaderContainerBase)`
  border-bottom: 1px solid ${Color.blue4};
  padding-bottom: 30px;

  ${Media.tablet`
    border: none;
    padding-bottom: 0;
  `}
`;

export const TableContainer = styled.div`
  display: flex;
  padding-top: 20px;
  border-top: 1px solid ${Color.blue4};
`;

export const Column = styled.div`
  &:not(:last-of-type) {
    margin-right: 2px;
  }
`;

export const LabelBox = styled.div`
  height: 128px;
  width: 198px;
  background-color: ${Color.almostWhite};
  display: flex;
  align-items: center;
  font-weight: ${FontWeight.Bold};
  color: ${Color.blue4};
  font-size: 16px;
  margin-bottom: 2px;
  padding: 0 20px;
`;

export const Title = styled.h1`
  color: ${Color.blue4};
  font-weight: ${FontWeight.Bold};
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 15px;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  ${Media.tablet`
    flex-direction: row;
    justify-content: flex-end;
  `}
`;
