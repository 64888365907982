import { combineReducers } from "redux";

import { reducer as emailVerification } from "../pages/emailVerification/redux/emailVerification.reducers";
import { reducer as registration } from "../pages/registration/redux/registration.reducers";
import { reducer as appSettings } from "../appSettings/redux/appSettings.reducer";
import { reducer as common } from "../redux/common/common.reducer";
import { reducer as userContext } from "../userContext/redux/userContext.reducer";
import { reducer as userRegistration } from "../userRegistration/redux/userRegistration.reducer";
import { reducer as lesson } from "../lesson/redux/lesson.reducer";
import errorNetwork from  "../app/networkErrorModal/reducer";
import { reducer as ui } from "../helpers/global.reducer";
import { reducers } from "../modules";

export const createRootReducer = () => combineReducers({
  emailVerification,
  registration,
  lesson,
  appSettings,
  ui,
  userRegistration,
  errorNetwork,
  common,
  userContext,
  ...reducers,
});
