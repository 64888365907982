import React from "react";
import i18n from "i18next";

import { Color } from "../../../../theme/colors";
import { Icon } from "../../../../components/icon/icon.component";

import {
  Container,
  Avatar,
  Name,
  Description,
  DataWrapper,
  Data,
  ContactInfoWrapper,
} from "./contactItem.styled";
import { ReactComponent as PhoneIcon } from "../../images/phone.svg";
import { ReactComponent as MailIcon } from "../../images/mail.svg";

const ContactItem = (props) => {
  const {
    name,
    avatarUrl,
    description,
    phone,
    email
  } = props;

  return (
    <Container>
      <Avatar src={avatarUrl} />
      <Name>{name}</Name>
      <Description>{description}</Description>
      <ContactInfoWrapper>
        <DataWrapper>
          <Icon size={16}>
            <PhoneIcon color={() => Color.brandBasic} />
          </Icon>
          <Data>{i18n.t("onboarding.contact.phone")} {phone}</Data>
        </DataWrapper>
        <DataWrapper>
          <Icon size={16}>
            <MailIcon color={() => Color.brandBasic} />
          </Icon>
          <Data lowercase>{email}</Data>
        </DataWrapper>
      </ContactInfoWrapper>
    </Container>
  );
};

export default ContactItem;
