import styled from "styled-components";

import { ReactComponent as FileSvg } from "../../../../images/folderLocked.svg";
import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 30px;
  background-color: ${Color.white};
  text-align: center;

  ${Media.tablet`
    padding: 80px;
  `}
`;

export const FileImage = styled(FileSvg)``;

export const Title = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: ${FontWeight.Bold};
  margin-top: 30px;
`;

export const Subtitle = styled.p`
  margin-top: 10px;
  font-size: 14px;
  line-height: 22px;
  color: ${Color.gray9};
`;
