import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { ChartColorType } from "../../../../components/simpleProgressChart/simpleProgressChart.constants";

import {
  Container,
  TextWrapper,
  BoldText,
  Chart,
} from "./resourceSummary.styled";

export const ResourceSummary = ({ className, total, done }) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <Chart
        value={done}
        maxValue={total}
        chartColor={ChartColorType.Gold}
      />
      <TextWrapper>
        <BoldText>
          {done} / {total}
        </BoldText>
        {" "}
        {t("competence.resource.done")}
      </TextWrapper>
    </Container>
  );
};

ResourceSummary.propTypes = {
  className: PropTypes.string,
  total: PropTypes.number,
  done: PropTypes.number,
};
