import styled from "styled-components";

import { Media } from "../../../../theme";

export const ModalText = styled.p`
  text-align: center;
  padding: 50px 0;

  ${Media.tablet`
    padding: 80px 75px;
  `}
`;
