import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Color } from "../../../../../theme";
import { Icon, InlineBox, InlineBoxText, Col, RankAvatar } from "../../../../../UIElements";
import { selectStatsTeam, selectStatsTeamCount } from "../../../redux/teamIndicators.selectors";
import { TeamIndicatorsActions } from "../../../redux/teamIndicators.reducer";
import { Row } from "../statistics.styled";

import { TeamActivityHeader, ExpandButtonText } from "./teamActivity.styled";

export const TeamActivity = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const team = useSelector(selectStatsTeam);
  const teamCount = useSelector(selectStatsTeamCount);

  const handleUpdateTeam = () => {
    dispatch(TeamIndicatorsActions.updateStatsTeam(team.length, 10));
  };

  const handleReduceTeam = () => {
    dispatch(TeamIndicatorsActions.reduceStatsTeam());
  };

  if (!team) {
    return <div />;
  }

  return (
    <Row>
      <Col width="100%" marginLeft="0" paddingBottom="0">
        <TeamActivityHeader>
          {t("statistics.teamActivity.lastWeekTeamActivity")}
        </TeamActivityHeader>
        <Col width="auto" mobileMarginBottom={27}>
          {team.map((teamUser) => (
            <InlineBox justifyContent="flex-start" key={teamUser.name}>
              <Row alignItems="center">
                <InlineBoxText width="30px">{teamUser.id}</InlineBoxText>
                <RankAvatar rankItem={teamUser} />
              </Row>
              <InlineBoxText fontWeight="400" textAlign="center">
                {teamUser.name}
              </InlineBoxText>
            </InlineBox>
          ))}
        </Col>
        <Row
          padding="20px 0 0"
          mobilePadding="0 25px"
          justifyContent="flex-end"
          alignItems="center"
        >
          {team.length > 3 && (
            <>
              <ExpandButtonText onClick={handleReduceTeam}>
                {t("statistics.teamActivity.showLess")}
              </ExpandButtonText>
              <Icon
                cursor="pointer"
                icon="arrow_left"
                size={8}
                color={Color.gray7}
                rotation="90deg"
              />
            </>
          )}
          {team.length < teamCount && (
            <>
              <ExpandButtonText onClick={handleUpdateTeam}>
                {t("statistics.teamActivity.showMore")}
              </ExpandButtonText>
              <Icon
                cursor="pointer"
                icon="arrow_left"
                size={8}
                color={Color.gray7}
                rotation="270deg"
              />
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};
