import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSwipeable } from "react-swipeable";
import { useNavigate } from "react-router-dom";

import { selectBannersAvailable } from "../../../../userContext/redux/userContext.selectors";
import { useMediaListener } from "../../../../hooks/useMediaListener";
import { AppConfig } from "../../../../appConfig";
import { DashboardActions } from "../../redux/dashboard.reducer";
import { selectBanners } from "../../redux/dashboard.selectors";

import {
  Container,
  TimelineBg,
  Timeline,
  NextButton,
  PrevButton,
  Image,
  Dots,
  Dot,
} from "./banner.styled";

export const Banner = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const banners = useSelector(selectBanners);
  const { isAdditionalMobile, isDesktop } = useMediaListener();
  const bannersAvailable = useSelector(selectBannersAvailable);
  const [currentBanner, setCurrentBanner] = useState(null);
  const [timelineFired, setTimelineFired] = useState(false);

  useEffect(() => {
    if (bannersAvailable) {
      dispatch(DashboardActions.fetchBanners());
    }
  }, []);

  useEffect(() => {
    if (banners?.length) {
      setCurrentBanner(banners[0]);
    }
  }, [banners]);

  const goBack = () => {
    const current = banners.indexOf(currentBanner);

    if (banners[current - 1]) {
      setCurrentBanner(banners[current - 1]);
    } else {
      setCurrentBanner(banners[banners.length - 1]);
    }
  };

  const goNext = () => {
    const current = banners.indexOf(currentBanner);

    if (banners[current + 1]) {
      setCurrentBanner(banners[current + 1]);
    } else {
      setCurrentBanner(banners[0]);
    }
  };

  useEffect(() => {
    let changeTimeout;

    if (currentBanner) {
      setTimelineFired(false);
      setTimeout(() => setTimelineFired(true), 100);
      changeTimeout = setTimeout(() => goNext(), 8000);
    }

    return () => {
      clearTimeout(changeTimeout);
    };
  }, [currentBanner]);

  const swipeHandlers = useSwipeable({
    onSwipedRight: goBack,
    onSwipedLeft: goNext,
    preventDefaultTouchmoveEvent: true,
  });

  const handleRedirect = () => {
    if (currentBanner.url) {
      const internalDomain = AppConfig.api.split("//")[1];
      if (currentBanner.url.includes(internalDomain)
        && !currentBanner.url.split(internalDomain)[1].startsWith("/management")) {
        navigate(currentBanner.url.split(internalDomain)[1]);
      } else {
        window.open(currentBanner.url, "_blank");
      }
    }
  };

  if (!banners?.length) {
    return null;
  }

  return (
    <Container>
      {!!currentBanner && (
        <Image
          src={
            isDesktop
              ? currentBanner.imageDesktopUrl
              : isAdditionalMobile
                ? currentBanner.imageTabletUrl
                : currentBanner.imagePhoneUrl
          }
          onClick={handleRedirect}
          $withLink={!!currentBanner.url}
          {...swipeHandlers}
        />
      )}
      {banners.length > 1 && (
        <>
          <PrevButton onClick={goBack} />
          <NextButton onClick={goNext} />
          <TimelineBg>
            <Timeline $fired={timelineFired} />
          </TimelineBg>
          <Dots>
            {banners.map((banner) => (
              <Dot key={banner.id} $active={currentBanner === banner} />
            ))}
          </Dots>
        </>
      )}
    </Container>
  );
};
