import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { CookieName } from "../../app/cookiePopup/cookiePopup.constants";
import { CommonActions } from "../../redux/common/common.reducer";
import { selectTutorialCurrentStep } from "../../redux/common/common.selectors";
import { ButtonColor, ButtonVariant } from "../../components/button/button.constants";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { isCookieEnabled } from "../../helpers/isCookieEnabled";
import { AppConfig } from "../../appConfig";

import WelcomeImage from "./img/welcome.png";
import {
  Container,
  WelcomeImg,
  Header,
  Text,
  Buttons,
  SkipButton,
  StartButton,
} from "./tutorialPopup.styled";
import { TutorialLocalStorageKey, TutorialStep } from "./tutorialPopup.constants";

export const TutorialPopup = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentStep = useSelector(selectTutorialCurrentStep);
  const [tutorialViewed, setTutorialViewed] = useLocalStorage(TutorialLocalStorageKey);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const skipTutorial = () => {
    dispatch(CommonActions.setTutorialCurrentStep(null));
    setTimeout(() => document.body.style.overflow = "unset", 100);
  };

  useEffect(() => {
    if (tutorialViewed) {
      skipTutorial();
    } else {
      if (isCookieEnabled(CookieName.Functional)) setTutorialViewed(true);
      dispatch(CommonActions.setTutorialCurrentStep(TutorialStep.Init));
    }
  }, []);

  const goToFirstStep = () => {
    dispatch(CommonActions.setTutorialCurrentStep(TutorialStep.Profile));
  };

  if (currentStep !== TutorialStep.Init) return null;

  return (
    <Container open onClose={skipTutorial}>
      <WelcomeImg src={WelcomeImage} />
      <Header>{t("tutorial.init.welcome", { name: AppConfig.platformName })}</Header>
      <Text>{t("tutorial.init.text")}</Text>
      <Buttons>
        <SkipButton
          variant={ButtonVariant.Outlined}
          $large
          onClick={skipTutorial}
        >
          {t("tutorial.init.skip")}
        </SkipButton>
        <StartButton
          color={ButtonColor.Primary}
          onClick={goToFirstStep}
          $large
        >
          {t("tutorial.init.start")}
        </StartButton>
      </Buttons>
    </Container>
  );
};
