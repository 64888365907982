import * as Yup from "yup";

import i18n from "../../../../translations/i18n";
import { Theme } from "../../../../appConfig";

import {
  MinNameLength,
  MaxNameLength,
  MinSurnameLength,
  MaxSurnameLength,
  ProfileEditTheme,
} from "./editForm.constants";

const PersonalSchemaObject = {
  name: Yup.string()
    .min(MinNameLength, i18n.t("validationMessage.shortMinLength", { length: MinNameLength }))
    .max(MaxNameLength, i18n.t("validationMessage.shortMaxLength", { length: MaxNameLength }))
    .required(i18n.t("validationMessage.required")),
  surname: Yup.string()
    .min(MinSurnameLength, i18n.t("validationMessage.shortMinLength", { length: MinSurnameLength }))
    .max(MaxSurnameLength, i18n.t("validationMessage.shortMaxLength", { length: MaxSurnameLength }))
    .required(i18n.t("validationMessage.required")),
  email: Yup.string()
    .email(i18n.t("validationMessage.email"))
    .required(i18n.t("validationMessage.required")),
  phone: Yup.string()
    .matches(/^\d{9}$/, i18n.t("validationMessage.phone"))
    .required(i18n.t("validationMessage.required")),
};

const CompanySchemaObject = (namespaces) => {
  if (Theme.ProfileEdit === ProfileEditTheme.WithCompany) {
    let schemaObject = {};

    for (const namespace of namespaces) {
      schemaObject = {
        ...schemaObject,
        [namespace]: Yup.object().shape({
          company: Yup.object().required(i18n.t("validationMessage.required")),
          position: Yup.object().required(i18n.t("validationMessage.required")),
          employment_date_from: Yup.string().required(i18n.t("validationMessage.required")),
          brands: Yup.array().required(i18n.t("validationMessage.required")).min(1),
        }),
      };
    }

    return schemaObject;
  } else {
    return {};
  }
};

export const FormSchema = (namespaces = []) => Yup.object().shape({
  ...PersonalSchemaObject,
  ...CompanySchemaObject(namespaces),
});
