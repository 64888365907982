export const UriType = {
  Certificates: "certificate",
  Survey: "survey",
  Content: "content",
  Individual: "individual",
  Event: "event",
};

export const CertificateStatusId = {
  InProgress: 1,
  Passed: 2,
  Failed: 3,
  Renew: 4,
  Complete: 5,
  BeforeExpired: 6,
  Expired: 7,
  Blocked: 8,
  Unassigned: 9,
};
