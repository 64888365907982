import React from "react";
import { Offline, Online, Detector } from "react-detect-offline";

const pollingConfig = { enabled: false };

const OnlineHandler = ({ children }) => (
  <Online polling={pollingConfig}>
    {children}
  </Online>
);

const OfflineHandler = ({ children }) => (
  <Offline polling={pollingConfig}>
    {children}
  </Offline>
);

const DetectorHandler = ({ render, onChange }) => (
  <Detector
    polling={pollingConfig}
    render={render}
    onChange={onChange}
  />
);

export {
  OnlineHandler,
  OfflineHandler,
  DetectorHandler,
};
