import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectCompetenceScales } from "../../redux/competence.selectors";
import { CompetenceLevelItem } from "../competenceLevelItem/competenceLevelItem.component";

import {
  Box,
  Description,
  Container,
} from "./competenceLevelsBox.styled";

export const CompetenceLevelsBox = () => {
  const { t } = useTranslation();
  const competenceScales = useSelector(selectCompetenceScales);

  const renderCompetenceLevels = () =>
    competenceScales.map(({ rate, name, description }, index) => (
      <CompetenceLevelItem
        key={index}
        rate={rate}
        name={name}
        description={description}
      />
    ));

  if (competenceScales) {
    return (
      <Container>
        <Box>
          <Description>
            {t("competence.competenceLevelsBox.description")}
          </Description>
        </Box>
        {renderCompetenceLevels()}
      </Container>
    );
  }

  return <div />;
};
