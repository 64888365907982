import styled, { css } from "styled-components";

import { Color, FontWeight } from "../../../../../theme";
import { hexToRgba } from "../../../../../helpers/colors";

export const Container = styled.div`
  margin-bottom: 30px;
`;

export const Question = styled.p`
  font-weight: ${FontWeight.Bold};
  font-size: 18px;
  line-height: 26px;
  color: ${Color.deepGray};
  margin-bottom: 10px;
`;

export const AnswerLabel = styled.p`
  z-index: 1;
  margin-right: 20px;
`;

export const Percentage = styled.p`
  margin-left: auto;
  font-weight: ${FontWeight.SemiBold};
  font-size: 14px;
  z-index: 1;
  color: ${Color.gray9};
`;

export const LightText = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: ${Color.gray9};
  margin-bottom: 30px;
`;

export const AnswerBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 25px 20px;
  width: 100%;
  font-size: 15px;
  line-height: 20px;
  color: ${Color.deepGray};
  background-color: ${Color.almostWhite};
  border: 1px solid ${Color.transparent};
  cursor: pointer;

  ${({ selected }) => selected && css`
    border-color: ${() => Color.brandBasic};

    ${AnswerBoxCircle} {
      border: 6px solid ${() => Color.brandBasic};
      box-shadow: 0 2px 10px ${() => hexToRgba(Color.brandBasic, 0.1)};
    }
  `}

  ${({ disabled }) => disabled && css`
    pointer-events: none;
  `}

  ${({ whiteTheme }) => whiteTheme && css`
    background-color: ${Color.white};
    border: 1px solid ${Color.dirtyWhite};
  `}

  ${({ brandTheme }) => brandTheme && css`
    background-color: ${() => hexToRgba(Color.brandBasic, 0.05)};
  `}

  & + & {
    margin-top: 2px;
  }
`;

export const ResultBackground = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${Color.white};
`;

export const ResultBackgroundInner = styled.div`
  width: ${({ width }) => `${width}%`};
  height: 100%;
  background-color: ${() => Color.brandBasic};
  opacity: 0.05;
`;

export const AnswerBoxCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${Color.white};
  border: 2px solid ${Color.dirtyWhite};
  margin-right: 15px;
  flex-shrink: 0;
  z-index: 1;
`;
