import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { TooltipPlacement } from "../../../../components/tooltip/tooltip.constants";
import { InteractionAnswerLabel } from "../../../../theme";
import { AnswerStatus } from "../../../interactions.constants";
import { YourAnswerInfo } from "../answers/answers.styled";
import AnswerDisc from "../answerDisc/answerDisc.component";

import {
  Container,
  Checkbox,
  CheckIcon,
  Tooltip,
  InfoIcon,
} from "./multipleChoiceAnswer.styled";

const MultipleChoiceAnswer = ({
  data,
  checked,
  confirmed,
  isResultsView,
  onSelect,
}) => {
  const { id, label, value, infotip } = data;
  const { t } = useTranslation();

  const status = useMemo(() => {
    if (confirmed && checked && value === true) {
      return AnswerStatus.CorrectChecked;
    } else if (confirmed && checked && value === false) {
      return AnswerStatus.WrongChecked;
    } else if (confirmed && !checked && value === true) {
      return AnswerStatus.WrongUnchecked;
    } else if (checked) {
      return AnswerStatus.Checked;
    } else {
      return AnswerStatus.Default;
    }
  }, [checked, confirmed, value]);

  const handleOnClick = () => {
    onSelect(id);
  };

  return (
    <Container status={status} onClick={isResultsView ? undefined : handleOnClick}>
      {confirmed ? (
        <AnswerDisc status={status} />
      ) : (
        <Checkbox checked={checked}>
          {checked && <CheckIcon />}
        </Checkbox>
      )}
      <InteractionAnswerLabel status={status}>{label}</InteractionAnswerLabel>
      {infotip && (
        <Tooltip title={infotip} placement={TooltipPlacement.Right}>
          <InfoIcon />
        </Tooltip>
      )}
      {(status === AnswerStatus.CorrectChecked || status === AnswerStatus.WrongChecked) && (
        <YourAnswerInfo status={status}>
          {t("interactions.quizQuestions.yourAnswer")}
        </YourAnswerInfo>
      )}
    </Container>
  );
};

export default MultipleChoiceAnswer;
