import styled from "styled-components";

import { ReactComponent as ArrowSvg } from "../../../../images/arrow.svg";
import { hexToRgba } from "../../../../helpers/colors";
import {
  Color,
  Media,
  FontWeight,
  PageHeader as PageHeaderBase,
  ContentMaxWidth,
} from "../../../../theme";

export const Container = styled.div`
  padding: 54px 0;
  background-color: ${Color.almostWhite};

  ${Media.tablet`
    padding: 54px 80px;
  `}

  ${Media.desktop`
    padding: 54px 160px;
  `}
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: ${ContentMaxWidth};
  width: 100%;
`;

export const PageHeader = styled(PageHeaderBase)`
  margin-left: 20px;
  
  ${Media.tablet`
    margin-top: 56px;
    margin-left: 0;
  `}
`;

export const Section = styled.div`
  margin-bottom: 60px;
`;

export const SectionTitle = styled.p`
  margin-bottom: 30px;
  margin-left: 20px;
  font-weight: ${FontWeight.Bold};
  font-size: 18px;
  line-height: 20px;

  ${Media.tablet`
    margin-left: 0;
  `}
`;

export const CompetenceItem = styled.button`
  border: none;
  width: 100%;
  text-align: left;
  background-color: ${Color.white};
  padding: 25px 23px 25px 20px;
  display: flex;
  align-items: center;
  box-shadow: 0px 5px 10px ${hexToRgba(Color.deepGray, 0.01)};
  margin-bottom: 2px;

  ${Media.tablet`
    padding: 45px 43px 45px 35px;
  `}
`;

export const TextWrapper = styled.div`
  margin-left: 29px;
`;

export const Title = styled.p`
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.deepGray};
  font-size: 16px;
  line-height: 20px;
`;

export const Level = styled.p`
  font-size: 13px;
  line-height: 17px;
  color: ${Color.gray9};
  margin-top: 3px;
`;

export const ArrowIcon = styled(ArrowSvg)`
  fill: ${Color.gray9};
  height: 15px;
  width: 9px;
  transform: rotate(180deg);
  margin-left: auto;
`;
