import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { useMediaListener } from "../../hooks/useMediaListener";
import { renderWhenTrue } from "../../helpers/rendering";
import {
  DialogContainer,
  DrawerContainer,
  Header,
  Content,
  Icon,
  CloseIcon,
} from "./modal.styled";

export const Modal = (props) => {
  const {
    open,
    children,
    title,
    HeaderIcon,
    onClose = Function.prototype,
    ...dialogProps
  } = props;

  const [isOpen, setIsOpen] = useState(open);
  const { isMobile } = useMediaListener();

  const handleClose = useCallback(() => {
    setIsOpen(false);
    onClose();
  }, [onClose]);

  useEffect(() => setIsOpen(open), [open]);

  const renderIcon = renderWhenTrue(() => (
    <Icon size={16}>
      <HeaderIcon />
    </Icon>
  ));

  const renderHeader = renderWhenTrue(() => (
    <Header>
      {renderIcon(!!HeaderIcon)}
      {title}
      <CloseIcon onClick={handleClose} />
    </Header>
  ));

  const Container = isMobile ? DrawerContainer : DialogContainer;

  return (
    <Container
      open={isOpen}
      onClose={handleClose}
      transitionDuration={150}
      {...dialogProps}
    >
      {renderHeader(!!title)}
      <Content>{children}</Content>
    </Container>
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  HeaderIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  children: PropTypes.node,
};
