import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { ConfirmationModal } from "../../../../components/confirmationModal/confirmationModal.component";
import { ReactComponent as ExitSvg } from "../../images/exit.svg";

export const ConnectionModal = ({ className, open }) => {
  const { t } = useTranslation();

  const onClose = () => window.location.reload(true);

  return (
    <ConfirmationModal
      open={open}
      confirmText={t("global.ok")}
      onClose={onClose}
      className={className}
      withoutDecline
      HeaderIcon={ExitSvg}
      title={t("webinars.connectionModal.problem")}
    >
      {t("webinars.connectionModal.networkChange")}
    </ConfirmationModal>
  );
};

ConnectionModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
};
