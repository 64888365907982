import { ReactComponent as ModuleIcon } from "../../images/knowledgeBase.svg";

export const Config = {
  moduleName: "knowledgeBase",
  dependencies: [],
  label: "learning.pageTitle",
  Icon: ModuleIcon,
  urls: ["/lekcje"],
  routeName: "api_learning_list",
  routes: {
    entry: "/:id"
  },
  options: {},
  theme: {}
};
