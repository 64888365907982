import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { renderWhenTrue } from "../../helpers/rendering";
import { OnboardingMode } from "../../app/app.constants";
import { LoadingWrapper } from "../../UIElements";

import { OnboardingHR } from "./components/onboardingHR/onboardingHR.component";
import { OnboardingDefault } from "./components/onboardingDefault/onboardingDefault.component";
import { selectIsInitialDataPending, selectOnboardingMode } from "./redux/onboarding.selectors";
import { OnboardingActions } from "./redux/onboarding.reducer";
import { Container } from "./onboarding.styled";

const Onboarding = () => {
  const dispatch = useDispatch();
  const isPending = useSelector(selectIsInitialDataPending);
  const onboardingMode = useSelector(selectOnboardingMode);

  useEffect(() => {
    dispatch(OnboardingActions.getInitialData());
  }, [dispatch]);

  const renderOnboardingDefault = renderWhenTrue(() => (
    <OnboardingDefault />
  ));

  const renderOnboardingHR = renderWhenTrue(() => (
    <OnboardingHR />
  ));

  return (
    <Container isPending={isPending}>
      <LoadingWrapper isLoading={isPending}>
        {renderOnboardingDefault(
          onboardingMode === OnboardingMode.Default
            || onboardingMode === OnboardingMode.LeasinGo
            || onboardingMode === OnboardingMode.OnboardingAll
            || onboardingMode === OnboardingMode.OnboardingVWFS
        )}
        {renderOnboardingHR(onboardingMode === OnboardingMode.HR)}
      </LoadingWrapper>
    </Container>
  );
};

export default Onboarding;
