import React, { useCallback, useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { UploadVideoModal } from "../../modules/dashboard/components/uploadVideoModal/uploadVideoModal.component";
import { FormsAndTestsThumbnail } from "../../modules/dashboard/components/formsAndTestsThumbnail/formsAndTestsThumbnail.component";
import {
  renderWhenTrue,
  renderWhenTrueOtherwise,
} from "../../helpers/rendering";
import { KnowledgeBaseView } from "../../modules/knowledgeBase/knowledgeBase.constants";
import { CodeThumbnail } from "../videoForCode/components/codeThumbnail/codeThumbnail.component";
import { Thumbnail } from "../thumbnail/thumbnail.component";

import { ThumbnailListType } from "./thumbnailList.constants";
import {
  Container,
  ThumbnailsGrid,
  ThumbnailsList,
  PrevButton,
  NextButton,
  ThumbnailsWrapper,
  SectionHeader,
} from "./thumbnailList.styled";

export const ThumbnailList = (props) => {
  const {
    className,
    type = ThumbnailListType.Default,
    title,
    thumbnails = [],
    onCodeThumbnailClick,
    viewType = KnowledgeBaseView.Grid,
  } = props;
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [thumbnailHeight, setThumbnailHeight] = useState(null);
  const arrowTop = thumbnailHeight ? thumbnailHeight / 2 - 25 : null;
  const thumbnailsLength = useMemo(() => {
    if (
      type === ThumbnailListType.VideoForCode ||
      type === ThumbnailListType.AmbassadorsBusinessCards
    ) {
      return thumbnails.length + 1;
    }
    return thumbnails.length;
  }, [thumbnails, type]);

  useEffect(() => {
    if (!!thumbnailsLength && thumbnailsLength <= activePage * 4) {
      setActivePage(activePage - 1);
    }
  }, [thumbnails, activePage]);

  const handlePrevClick = useCallback(() => {
    setActivePage(activePage - 1);
  }, [activePage]);

  const handleNextClick = useCallback(() => {
    setActivePage(activePage + 1);
  }, [activePage]);

  const handleOpenModal = () => setIsOpen(true);

  const handleCloseModal = () => setIsOpen(false);

  const renderVideoForCodeBox = renderWhenTrue(() => (
    <CodeThumbnail
      onClick={onCodeThumbnailClick}
      headerText={t("components.videoForCode.addNewCodeBox.addNewCode")}
      lightText={t("components.videoForCode.addNewCodeBox.activationBenefits")}
    />
  ));

  const renderAmbassadorBusinessCardsBox = renderWhenTrue(() => (
    <CodeThumbnail
      onClick={handleOpenModal}
      headerText={t("dashboard.uploadVideo.sayHello")}
      lightText={t("dashboard.uploadVideo.addBusinessCard")}
    />
  ));

  const renderDefaultThumbnails = useCallback(
    () => thumbnails.map((item) => {
      const key = type === ThumbnailListType.AmbassadorsBusinessCards
        ? item.user.id
        : `${item.category}${item.id}`;

      return (
        <Thumbnail
          key={item.key ?? key}
          id={item.id}
          type={item.type}
          title={item.name || `${item.user.name} ${item.user.surname}`}
          image={item.image}
          required={item.required}
          minutes={item.minutes}
          completed={item.completed}
          award={item.award}
          items={item.items}
          favorite={item.favorite}
          progress={item.progress}
          category={item.category || item.title}
          description={item.description}
          validityTimestamp={item.validity}
          urlMiniature={item.urlMiniature}
          url={item.url}
          listView={viewType === KnowledgeBaseView.List}
          videoTitle={item.title}
          setThumbnailHeight={setThumbnailHeight}
        />
      );
    }),
    [thumbnails, type, viewType]
  );

  const renderFormsAndTestsThumbnails = () =>
    thumbnails.map(({ id, name, category, result, award }) => (
      <FormsAndTestsThumbnail
        key={id}
        id={id}
        name={name}
        category={category}
        result={result}
        award={award}
      />
    ));

  const renderThumbnails = renderWhenTrueOtherwise(
    renderFormsAndTestsThumbnails,
    renderDefaultThumbnails,
  );

  return (
    <>
      <SectionHeader>{title}</SectionHeader>
      <Container className={className}>
        {viewType === KnowledgeBaseView.Grid ? (
          <ThumbnailsWrapper className="thumbnailList">
            <ThumbnailsGrid $page={activePage}>
              {renderVideoForCodeBox(type === ThumbnailListType.VideoForCode)}
              {renderAmbassadorBusinessCardsBox(type === ThumbnailListType.AmbassadorsBusinessCards)}
              {renderThumbnails(type === ThumbnailListType.FormsAndTests)}
            </ThumbnailsGrid>
            {!!activePage && <PrevButton top={arrowTop} onClick={handlePrevClick} />}
            {thumbnailsLength - activePage * 4 > 4 && <NextButton top={arrowTop} onClick={handleNextClick} />}
          </ThumbnailsWrapper>
        ) : (
          <ThumbnailsList>
            {renderVideoForCodeBox(type === ThumbnailListType.VideoForCode)}
            {renderAmbassadorBusinessCardsBox(type === ThumbnailListType.AmbassadorsBusinessCards)}
            {renderThumbnails(type === ThumbnailListType.FormsAndTests)}
          </ThumbnailsList>
        )}
        <UploadVideoModal open={isOpen} onClose={handleCloseModal} />
      </Container>
    </>
  );
};
