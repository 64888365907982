export const ParticipantsAssignment = {
  Auto: "auto",
  Manual: "manual",
};

export const ValidationError = {
  RoomDuplicates: "webinars.roomsModal.roomDuplicates",
  MinutesError: "webinars.roomsModal.minutesError",
  MaxLengthError: "webinars.roomsModal.maxLengthError",
};
