import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { renderWhenTrue } from "../../../../helpers/rendering";
import { Icon } from "../../../../components/icon/icon.component";
import { ReactComponent as CommentIcon } from "../../../../images/note.svg";
import { ReactComponent as LikeIcon } from "../../../../images/heart2.svg";
import { ReactComponent as FilledLikeIcon } from "../../images/heart_filled.svg";

import { LikeState } from "./socials.constants";
import {
  Container,
  SocialButton,
  ButtonText,
} from "./socials.styled";

export const Socials = (props) => {
  const {
    liked,
    likeCounter,
    commentCounter,
    handleToggleComments,
    handleLikeClick,
    className
  } = props;
  const { t } = useTranslation();
  const [entryLiked, setEntryLiked] = useState(liked);

  const onLikeClick = useCallback(() => {
    const updatedLikeState = entryLiked ? LikeState.Unlike : LikeState.Like;
    handleLikeClick(updatedLikeState);
    setEntryLiked(!entryLiked);
  }, [entryLiked, handleLikeClick]);

  const renderLikeCounter = renderWhenTrue(() => ` (${likeCounter})`);

  const renderCommentCounter = renderWhenTrue(() => ` (${commentCounter})`);

  return (
    <Container className={className}>
      <SocialButton onClick={onLikeClick}>
        <Icon>
          {entryLiked ? <FilledLikeIcon /> : <LikeIcon />}
        </Icon>
        <ButtonText liked={entryLiked}>
          {t("news.entry.like")}
          {renderLikeCounter(!!likeCounter)}
        </ButtonText>
      </SocialButton>
      <SocialButton
        likeCounterVisible={!!likeCounter}
        onClick={handleToggleComments}
      >
        <Icon>
          <CommentIcon />
        </Icon>
        <ButtonText>
          {t("news.entry.comment")}
          {renderCommentCounter(!!commentCounter)}
        </ButtonText>
      </SocialButton>
    </Container>
  );
};


Socials.propTypes = {};
