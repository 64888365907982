import React, { useState } from "react";
import dayjs from "dayjs";
import { cond, equals, always, T } from "ramda";
import { useTranslation } from "react-i18next";

import { ConfirmationModal } from "../../../../../components/confirmationModal/confirmationModal.component";
import { TooltipPlacement } from "../../../../../components/tooltip/tooltip.constants";
import { IconType } from "../../../../../components/challengeElement/challengeElement.constants";
import { Tooltip } from "../../../../../components/tooltip/tooltip.component";
import { useMediaListener } from "../../../../../hooks/useMediaListener";
import { ReactComponent as InfoSvg } from "../../../../../images/infoIcon2.svg";

import {
  Container,
  Title,
  Date,
  Points,
  CoinIcon,
  IconWrapper,
  MessageIcon,
  VideoIcon,
  SurveyAndTestIcon,
  QuestionnaireIcon,
  InfoIcon,
  TextWrapper,
  PointsWrapper,
  ModalTitle,
  ModalWrapper,
} from "./pointsItem.styled";

export const PointsItem = ({
  title,
  timestamp,
  points,
  type,
  comment,
}) => {
  const { t } = useTranslation();
  const { isTablet } = useMediaListener();
  const [popupOpen, setPopupOpen] = useState(false);
  const date = dayjs.unix(timestamp).format("DD.MM.YYYY, HH:mm");

  const getIcon = cond([
    [equals(IconType.Content), always(VideoIcon)],
    [equals(IconType.Survey), always(SurveyAndTestIcon)],
    [equals(IconType.Questionnaire), always(QuestionnaireIcon)],
    [T, always(MessageIcon)],
  ]);

  const Icon = getIcon(type);

  const toggleCommentPopup = () => {
    setPopupOpen(!popupOpen);
  };

  return (
    <Container>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <TextWrapper>
        <Title>
          {title}
        </Title>
        {!!comment && isTablet && (
          <Tooltip placement={TooltipPlacement.Right} title={comment}>
            <InfoIcon />
          </Tooltip>
        )}
        <Date>{date}</Date>
      </TextWrapper>
      <PointsWrapper>
        {!!comment && !isTablet && (
          <InfoIcon onClick={toggleCommentPopup} />
        )}
        <Points>
          <CoinIcon />
          {points}
        </Points>
      </PointsWrapper>
      <ConfirmationModal
        open={popupOpen}
        confirmText={t("global.close")}
        withoutDecline
        onClose={toggleCommentPopup}
        title={t("gamification.pointsBox.comment")}
        HeaderIcon={InfoSvg}
      >
        <ModalWrapper>
          <ModalTitle>{title} - {date}</ModalTitle>
          {comment}
        </ModalWrapper>
      </ConfirmationModal>
    </Container>
  );
};
