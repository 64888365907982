export const AwardType = {
  Gold: "gold",
  Silver: "silver",
  Bronze: "bronze",
  Completed: "completed",
};

export const ItemType = {
  Lesson: "lesson",
  Video: "video",
  Files: "files",
  Contents: "contents",
  Items: "items",
  ScrollLesson: "lesson_slide_scroll",
};

export const FavouriteItemType = {
  ContentGroup: "content_group",
  FileGroup: "file_group",
  Content: "content",
  Competence: "competence",
};
