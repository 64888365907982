import React, { useState } from "react";
import PropTypes from "prop-types";

import { renderWhenTrue } from "../../../../../helpers/rendering";
import { NextButton } from "../nextButton/nextButton.component";
import { InfoTip } from "../infoTip/infoTip.component";

import {
  Container,
  Content,
  Title,
  Row,
  ButtonRow,
} from "./interactionDropdown.styled";
import { Question } from "./components/question/question.component";
import { Dropdown } from "./components/dropdown/dropdown.component";

export const InteractionDropdown = ({
  className,
  title,
  buttonText,
  options,
  answers,
  infotipText,
  next,
}) => {
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [dropdownsShowState, setDropdownsShowState] = useState(Array(options.length).fill(false));

  const renderInfoTip = renderWhenTrue(() => (
    <InfoTip sentences={infotipText} />
  ));

  const toggleDropdown = (index) => {
    const newArray = Array(dropdownsShowState.length).fill(false);
    newArray[index] = !dropdownsShowState[index];
    setDropdownsShowState(newArray);
  };

  const isOptionCorrectlyAnswered = (answer) =>
    correctAnswers.find(({ id }) => id === answer.id);

  const addAnswer = (answer) => {
    if (!isOptionCorrectlyAnswered(answer)) {
      setCorrectAnswers([...correctAnswers, answer]);
    }
  };

  const removeAnswer = (answer) =>
    setCorrectAnswers(correctAnswers.filter(({ id }) => answer.id !== id));

  const renderTitle = renderWhenTrue(() => <Title>{title}</Title>);

  const renderRows = renderWhenTrue(() =>
    options.map((option, index) => (
      <Row key={option.id}>
        <Question question={option} />
        <Dropdown
          answers={answers}
          option={option}
          addAnswer={addAnswer}
          removeAnswer={removeAnswer}
          isDropdownOpen={dropdownsShowState[index]}
          toggleDropdown={() => toggleDropdown(index)}
        />
      </Row>
    ))
  );

  const nextInteraction = () => {
    setCorrectAnswers([]);
    next();
  };

  const isButtonDisabled = correctAnswers.length !== options.length;

  return (
    <Container className={className}>
      {renderInfoTip(!!infotipText)}
      <Content>
        {renderTitle(!!title)}
        {renderRows(!!options.length)}
      </Content>
      <ButtonRow>
        <NextButton
          onClick={nextInteraction}
          disabled={isButtonDisabled}
          text={buttonText}
        />
      </ButtonRow>
    </Container>
  );
};

InteractionDropdown.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  options: PropTypes.array,
  answers: PropTypes.array,
  next: PropTypes.func,
};
