import styled from "styled-components";

import { hexToRgba } from "../../../../../helpers/colors";
import { ReactComponent as BaseCloseIcon } from "../../../../../images/close.svg";
import { Color, FontWeight, Media } from "../../../../../theme";

export const CompetenceBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 190px;
  background-color: ${Color.white};
  margin-right: 20px;
  min-width: 250px;

  ${Media.desktop`
    min-width: calc(100% / 3 - 9px);
  `}
`;

export const Title = styled.p`
  font-weight: ${FontWeight.SemiBold};
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 2px;
  color: ${Color.deepGray};
`;

export const Level = styled.p`
  font-size: 13px;
  line-height: 17px;
  color: ${Color.gray9};
`;

export const InfoButton = styled.button`
  position: absolute;
  right: 15px;
  top: 15px;
  border: none;
  background-color: ${Color.transparent};
`;

export const ModalContent = styled.div`
  padding: 120px 30px 50px;

  ${Media.tablet`
    padding: 120px 100px;
  `}

  ${Media.tabletWide`
    padding: 200px 180px;  
  `}
`;

export const CloseButton = styled.button`
  padding: 15px;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`;

export const CloseIcon = styled(BaseCloseIcon)`
  width: 22px;
  height: 22px;
  
  g {
    stroke: ${Color.black};
  }
`;

export const TextWrapper = styled.div`
  margin-top: 10px;
  text-align: center;
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 46px;

  ${TextWrapper} {
    margin: 0 0 0 30px;
    text-align: left;
  }

  ${Media.tabletWide`
    left: -80px;
  `}
`;

export const ModalDescription = styled.div`
  border-top: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  padding: 20px 0 35px;
  font-size: 15px;
  line-height: 26px;
  color: ${Color.deepGray};
`;

export const ModalTitle = styled.h1`
  font-weight: ${FontWeight.ExtraBold};
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const ModalButtons = styled.div`
  button {
    width: 100%;
    margin: 0 0 5px;

    ${Media.tablet`
      margin: 0 10px 0 0;
      width: inherit;
    `}
  }
`;
