import styled from "styled-components";

import { ReactComponent as DownloadSvg } from "../../../../images/download.svg";
import { FontWeight, Color, Media, ContentMaxWidth } from "../../../../theme";

export const Container = styled.div`
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
  padding: 60px 20px 80px;

  ${Media.tablet`
    padding: 60px 50px 80px;
  `}
`;

export const Title = styled.p`
  font-weight: ${FontWeight.Bold};
  font-size: 18px;
  line-height: 20px;
  color: ${Color.black};
  margin-bottom: 35px;
`;

export const File = styled.a`
  padding: 17px 20px;
  background-color: ${Color.almostWhite};
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 2px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: ${Color.gray8};
  }

  ${Media.tablet`
    padding: 17px 35px;
  `}
`;

export const Filename = styled.span`
  font-weight: ${FontWeight.SemiBold};
  font-size: 13px;
  line-height: 22px;
  color: ${Color.gray4};
`;

export const DownloadIcon = styled(DownloadSvg)`
  padding: 0 11px;
  width: 36px;
  height: 36px;
  fill: ${() => Color.brandBasic};
  background-color: ${Color.white};
  border-radius: 50%;
  margin-right: 13px;
  border: 1px solid ${Color.dirtyWhite};
`;
