import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { Color, Media, Transitions, FontSize, FontWeight } from "../../../../theme";
import { Button as ButtonBase } from "../../../../components/button/button.component";
import { ReactComponent as CloseSvg } from "../../../../images/close.svg";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { ButtonVariant } from "../../../../components/button/button.constants";

export const Container = styled.div`
  padding: 70px 20px 15px;

  ${Media.mobile`
    padding: 120px 50px 50px;
  `}

  ${Media.desktop`
    padding: 147px 80px 80px 50px;
  `}
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  transition: ${Transitions.custom};
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }

  ${Media.tablet`
    top: 15px;
    right: 15px;
    padding: 15px;
  `}
`;

export const TagFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 60px;
  margin-bottom: 40px;
  border-bottom: 1px solid ${Color.gray5};
`;

export const Tag = styled.button`
  padding: 6px 10px;
  border-radius: 39px;
  background-color: ${Color.almostWhite};
  border: none;
  outline: none;
  color: ${Color.deepGray};
  font-size: 10px;
  margin-right: 5px;
  margin-bottom: 10px;

  ${styleWhenTrue(prop("selected"), css`
    background-color: ${Color.deepGray};
    color: ${Color.white};
  `)}
`;

export const CloseIcon = styled(CloseSvg)`
  height: 12px;
  width: 12px;
  g {
    stroke: ${Color.almostBlack};
  }

  ${Media.tablet`
    height: 22px;
    width: 22px;
  `}
`;

export const HeadlineBig = styled.h3`
  font-size: 28px;
  color: ${Color.deepGray};
  font-weight: 300;
  padding: 0 0 40px;
  margin-left: -6px;

  ${Media.tablet`
    font-size: ${FontSize.HeadlineBig};
    margin-left: 0;
    padding: 50px 0;
  `}

  ${Media.desktop`
    padding: 89px 0 70px;
  `}
`;
export const HeadlineSmall = styled.h5`
  font-size: ${FontSize.HeadlineSmall};
  color: ${Color.deepGray};
  font-weight: 600;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Color.gray5};
  margin-bottom: 20px;
  
  ${Media.tablet`
    border: none;
    margin: 0;
    padding-bottom: 37px;
  `}
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  ${Media.tablet`
    flex-flow: row nowrap;
    align-items: space-between;
    justify-content: flex-start;
    width: 100%;
  `}
`;

export const FiltersColumn = styled.div`
  width: 100%;
  padding-right: 0;
  &:not(:first-child) {
    margin-top: 40px;
  }

  ${Media.tablet`
    width: 30%;
    min-height: 190px;
    padding-right: 35px;
    &:not(:first-child) {
      margin-top: 0;
    }
  `}
`;

export const FilterCategory = styled.ul`
  flex: 0 0 40%;
  margin-left: auto;
  padding-right: 0;

  ${Media.tablet`
    margin-left: 0;
  `}
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  ${Media.tablet`
    margin-top: 85px;
    flex-direction: row;
  `}
`;

export const Button = styled(ButtonBase)`
  width: 100%;
  font-size: 10px;
  font-weight: ${FontWeight.Bold};
  
  ${styleWhenTrue(propEq(ButtonVariant.Outlined, "variant"), `
    border: none;
  `)}

  & + & {
    margin-top: 10px;

    ${Media.tablet`
      margin-top: 0;
    `}
  }

  ${Media.tablet`
    width: unset;
    font-size: 10px;
    margin-bottom: 0;
    & + & {
      margin-left: 10px;
    }

    ${styleWhenTrue(propEq(ButtonVariant.Outlined, "variant"), `
      border: 1px solid ${Color.dirtyWhite};
    `)}
  `}
`;
