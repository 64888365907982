import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { renderWhenTrue } from "../../../../../helpers/rendering";
import { Header, LightHeader, HeaderContainer } from "../../sequentialQuizInteractions.styled";
import { ButtonText, ThemeType } from "../../sequentialQuizInteractions.constants";
import { NextButton } from "../nextButton/nextButton.component";

import {
  Container,
  Content,
  Row,
  ButtonRow,
} from "./dropdownQuiz.styled";
import { Question } from "./components/question/question.component";
import { Dropdown } from "./components/dropdown/dropdown.component";

export const DropdownQuiz = ({
  className,
  title,
  options,
  answers,
  onNext,
  questionNumber,
  restartQuestion,
}) => {
  const { t } = useTranslation();
  const [enableValidating, setEnableValidating] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [wrongAnswers, setWrongAnswers] = useState(new Set());
  const [dropdownsShowState, setDropdownsShowState] = useState(Array(options.length).fill(false));
  const [buttonText, setButtonText] = useState(ButtonText.Accept);
  const [theme, setTheme] = useState(ThemeType.DefaultTheme);

  const toggleDropdown = (index) => {
    const newArray = Array(dropdownsShowState.length).fill(false);
    newArray[index] = !dropdownsShowState[index];
    setDropdownsShowState(newArray);
  };

  const isOptionCorrectlyAnswered = (answer) =>
    correctAnswers.find(({ id }) => id === answer.id);

  const addAnswer = (answer) => {
    if (!isOptionCorrectlyAnswered(answer)) {
      setCorrectAnswers([...correctAnswers, answer]);
    }
  };

  const removeAnswer = (answer) =>
    setCorrectAnswers(correctAnswers.filter(({ id }) => answer.id !== id));

  const isWrongAnswer = (id) => wrongAnswers.has(id);

  const renderTitle = renderWhenTrue(() => <Header>{title}</Header>);

  const renderRows = renderWhenTrue(() =>
    options.map((option, index) => (
      <Row key={option.id}>
        <Question
          question={option}
          enableValidating={enableValidating}
          isWrong={isWrongAnswer(option.id)}
        />
        <Dropdown
          answers={answers}
          option={option}
          addAnswer={addAnswer}
          removeAnswer={removeAnswer}
          setWrongAnswers={setWrongAnswers}
          wrongAnswers={wrongAnswers}
          isDropdownOpen={dropdownsShowState[index]}
          enableValidating={enableValidating}
          toggleDropdown={() => toggleDropdown(index)}
        />
      </Row>
    ))
  );

  const handleNextClick = () => {
    if (theme === ThemeType.CorrectAnswerTheme) {
      onNext();
    } else if (theme === ThemeType.WrongAnswerTheme) {
      setEnableValidating(false);
      setButtonText(ButtonText.Accept);
      setTheme(ThemeType.DefaultTheme);
      restartQuestion();
    } else {
      const newTheme = wrongAnswers.size ? ThemeType.WrongAnswerTheme : ThemeType.CorrectAnswerTheme;
      const newButtonText = wrongAnswers.size ? ButtonText.TryAgain : ButtonText.Next;
      setEnableValidating(true);
      setTheme(newTheme);
      setButtonText(newButtonText);
    }
  };

  const allSelected = (correctAnswers.length + wrongAnswers.size) === options.length;

  return (
    <Container className={className}>
      <Content>
        <HeaderContainer>
          <LightHeader>{t("interactions.sequentialQuiz.question")} {questionNumber}</LightHeader>
          {renderTitle(!!title)}
        </HeaderContainer>
        {renderRows(!!options.length)}
      </Content>
      <ButtonRow>
        <NextButton
          onClick={handleNextClick}
          disabled={!allSelected}
          text={buttonText}
        />
      </ButtonRow>
    </Container>
  );
};

DropdownQuiz.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.array,
  answers: PropTypes.array,
  onNext: PropTypes.func,
  restartQuestion: PropTypes.func,
};
