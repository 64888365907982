import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { Icon } from "../icon/icon.component";
import { Color } from "../../theme/colors";
import { Media } from "../../theme/media";
import { unorderedListStyles, ErrorMessage as ErrorMessageBase } from "../../theme/typography";
import { styleIfElse, styleWhenTrue } from "../../helpers/rendering";

export const editorCustomStyles = {
  STRIKETHROUGH: {
    textDecoration: "underline",
  },
};

export const Container = styled.div`
  background-color: ${Color.whiteHover};
  border: 1px solid ${Color.gray8};
  font-size: 16px;
  line-height: 22px;

  ${styleWhenTrue(prop("$oneLine"), css`
    font-size: 14px;
  `)}

  ${styleWhenTrue(prop("$invalid"), css`
    border-color: ${() => Color.brandBasic};
  `)}

  ${styleWhenTrue(prop("classesMode"), css`
    height: 94px;
  `)}
`;

export const Toolbar = styled.div`
  background-color: ${Color.whiteHover};
  border-bottom: 1px solid ${Color.gray8};
  height: 49px;
  padding: 0 20px;
  display: flex;
  align-items: center;

  ${Media.tablet`
    padding: 0 30px;
  `}
`;

export const Content = styled.div`
  padding: 20px 60px 55px 20px;
  outline: none;
  position: relative;

  ${styleWhenTrue(prop("$oneLine"), css`
    padding: 12px 60px 12px 20px;

    .DraftEditor-root {
      max-height: 92px;
      overflow-x: auto;
    }
  `)}

  ${styleWhenTrue(prop("classesMode"), css`
    .DraftEditor-root {
      max-height: 80px;
    }
  `)}

  ${styleWhenTrue(prop("$error"), css`
    padding: 12px 60px 55px 20px;
  `)}

  ${Media.tablet`
    padding: 20px 60px 60px 30px;

    ${styleWhenTrue(prop("$oneLine"), css`
      padding: 12px 60px 12px 20px;
    `)}

    ${styleWhenTrue(prop("$error"), css`
      padding: 12px 60px 60px 20px;
    `)}
  `}

  .DraftEditor-root {
    word-break: break-word;

    ul {
      ${unorderedListStyles};
    }
  }

  .public-DraftEditorPlaceholder-root {
    color: ${Color.gray4};
  }

  ${styleIfElse(propEq("auto", "height"), css``, css`
    height: ${prop("height")}px;
    overflow-y: auto;
    padding: 20px 60px 0 20px;

    .DraftEditor-root {
      height: auto;
    }

    ${Media.tablet`
      padding: 20px 60px 0 20px;
    `}
  `)}
`;

export const OptionButton = styled.button`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 7px;
  margin: 0 2px;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
  background: none;
  border: none;
  color: ${Color.deepGray};

  ${styleWhenTrue(prop("active"), css`
    color: ${() => Color.brandBasic};
  `)}

  &:first-child {
    margin-left: -4px;
  }
`;

export const EmojiPickerContainer = styled.div`
  width: 100%;
`;

export const EmojiPickerButton = styled(Icon)`
  position: absolute;
  cursor: pointer;
  bottom: 20px;
  right: 20px;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  color: ${Color.gray4};

  ${styleWhenTrue(prop("oneLine"), css`
    bottom: 12px;
  `)}

  ${styleWhenTrue(prop("classesMode"), css`
    top: 12px;
    bottom: auto;
  `)}

  &:hover {
    color: ${Color.deepGray};
  }

  ${styleWhenTrue(prop("active"), css`
    color: ${() => Color.brandBasic};
  `)}

  ${Media.tablet`
    right: 30px;

    ${styleWhenTrue(prop("oneLine"), css`
      right: 20px;
    `)}
  `}
`;

export const ErrorMessage = styled(ErrorMessageBase)`
  position: absolute;
  bottom: 20px;
  left: 20px;

  ${Media.tablet`
    left: 30px;
  `}
`;
