import React from "react";
import { useTranslation } from "react-i18next";

import {
  Container,
  FileImage,
  Title,
  Subtitle,
} from "./contentBlocked.styled";

export const ContentBlocked = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <FileImage />
      <Title>{t("scrollLesson.contentBlocked.contentBlocked")}</Title>
      <Subtitle>{t("scrollLesson.contentBlocked.showMore")}</Subtitle>
    </Container>
  );
};
