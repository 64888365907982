import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { isCookieEnabled } from "../../../../helpers/isCookieEnabled";
import {
  Drawer,
  Buttons,
  ColorButton,
  Title,
  Text,
  Link,
} from "../../cookiePopup.styled";
import { CookieName } from "../../cookiePopup.constants";
import { DetailsItem } from "../detailsItem/detailsItem.component";

import { Container, Header, Content } from "./detailsPopup.styled";
import { CookieInfoData } from "./detailsPopup.constants";

export const DetailsPopup = ({ acceptCookies, setPopupAccepted }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [functionalCookieEnabled, setFunctionalCookieEnabled] = useState(
    isCookieEnabled(CookieName.Functional)
  );
  const [analyticalCookieEnabled, setAnalyticalCookieEnabled] = useState(
    isCookieEnabled(CookieName.Analytical)
  );

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleItemEdit = (cookieName, value) => {
    if (cookieName === CookieName.Functional) {
      setFunctionalCookieEnabled(value);
    }

    if (cookieName === CookieName.Analytical) {
      setAnalyticalCookieEnabled(value);
    }
  }

  const acceptEditedCookies = () => {
    if (functionalCookieEnabled) {
      localStorage.setItem(CookieName.Functional, true);
    } else {
      localStorage.removeItem(CookieName.Functional);
    }

    if (analyticalCookieEnabled) {
      localStorage.setItem(CookieName.Analytical, true);
    } else {
      localStorage.removeItem(CookieName.Analytical);
    }

    setPopupAccepted(true);
  };

  return (
    <Drawer open={isOpen}>
      <Container>
        <Header>
          <Content>
            <Title>{t("cookiePopup.mainTitle")}</Title>
            <Text>
              {t("cookiePopup.detailsText")}
              <Link to="/polityka-prywatnosci" target="_blank">
                {t("cookiePopup.detailsText2")}
              </Link>
            </Text>
          </Content>
          <Buttons>
            <ColorButton onClick={() => acceptCookies(true)}>
              {t("cookiePopup.accept")}
            </ColorButton>
            <ColorButton onClick={() => acceptCookies(false)}>
              {t("cookiePopup.noAccept")}
            </ColorButton>
          </Buttons>
        </Header>
        {CookieInfoData.map(({ cookieName, titleKey, textKey, editable }) => (
          <DetailsItem
            key={cookieName}
            title={t(titleKey)}
            text={t(textKey)}
            editable={editable}
            initValue={isCookieEnabled(cookieName)}
            onEdit={(value) => handleItemEdit(cookieName, value)}
          />
        ))}
        <Buttons>
          <ColorButton onClick={acceptEditedCookies}>
            {t("cookiePopup.saveAndClose")}
          </ColorButton>
        </Buttons>
      </Container>
    </Drawer>
  );
};
