import { all, put, takeLatest } from "redux-saga/effects";

import * as competenceApi from "../services/api";

import { CompetenceTypes, CompetenceActions } from "./competence.reducer";

function* getUserCompetenceList() {
  yield put(CompetenceActions.setIsPending(true));
  const { data } = yield competenceApi.getUserCompetenceList();
  yield put(CompetenceActions.getUserCompetenceListSuccess(data));
  yield put(CompetenceActions.setIsPending(false));
}

function* getCompetenceOverview({ id }) {
  yield put(CompetenceActions.setIsPending(true));
  const { data } = yield competenceApi.getCompetenceOverview(id);
  yield put(CompetenceActions.getCompetenceOverviewSuccess(data));
  yield put(CompetenceActions.setIsPending(false));
}

function* getCompetenceSkills({ id }) {
  const { data } = yield competenceApi.getCompetenceSkills(id);
  yield put(CompetenceActions.getCompetenceSkillsSuccess(data));
}

function* getCompetenceStatistics({ id }) {
  const { data } = yield competenceApi.getCompetenceStatistics(id);
  yield put(CompetenceActions.getCompetenceStatisticsSuccess(data));
}

function* getCompetenceScales({ id }) {
  const { data } = yield competenceApi.getCompetenceScales(id);
  yield put(CompetenceActions.getCompetenceScalesSuccess(data));
}

function* getCompetenceHistory({ id }) {
  const { data } = yield competenceApi.getCompetenceHistory(id);
  yield put(CompetenceActions.getCompetenceHistorySuccess(data));
}

function* getCompetenceHistoryNextPage({ endpoint }) {
  const { data } = yield competenceApi.getCompetenceHistoryNextPage(endpoint);
  yield put(CompetenceActions.appendCompetenceHistory(data));
}

function* getResources({ id }) {
  const { data } = yield competenceApi.getResources(id);
  yield put(CompetenceActions.getResourcesSuccess(data));
}

function* enrollEvent({ id }) {
  const { data } = yield competenceApi.enrollEvent(id);

  if (data.success) {
    yield put(CompetenceActions.enrollEventSuccess(id));
  }
}

function* disenrollEvent({ id }) {
  const { data } = yield competenceApi.disenrollEvent(id);

  if (data.success) {
    yield put(CompetenceActions.disenrollEventSuccess(id));
  }
}

function* getSelfMarkSurvey({ measureId }) {
  yield put(CompetenceActions.setIsPending(true));
  const { data } = yield competenceApi.getSelfMarkSurvey(measureId);
  yield put(CompetenceActions.getSelfMarkSurveySuccess(data));
  yield put(CompetenceActions.setIsPending(false));
}

function* sendSelfMarkSurvey({ measureId, answers, onFinish = Function.prototype }) {
  yield put(CompetenceActions.setIsPending(true));
  yield competenceApi.sendSelfMarkSurvey(measureId, answers);
  yield onFinish();
  yield put(CompetenceActions.setIsPending(false));
}

function* getConfirmationCategories() {
  const { data } = yield competenceApi.getConfirmationCategories();
  yield put(CompetenceActions.getConfirmationCategoriesSuccess(data));
}

function* sendConfirmation({ competenceId, values, onFinish = Function.prototype }) {
  const { data } = yield competenceApi.sendConfirmation(competenceId, values);
  onFinish();
  yield put(CompetenceActions.sendConfirmationSuccess(data));
}

function* getConfirmationList({ competenceId }) {
  const { data } = yield competenceApi.getConfirmationList({ competenceId });
  yield put(CompetenceActions.getConfirmationListSuccess(data.data));
}

function* sendSuggestion({ values, onFinish = Function.prototype }) {
  yield competenceApi.sendSuggestion(values);
  onFinish();
}

export function* watchCompetence() {
  yield all([
    yield takeLatest(CompetenceTypes.GET_USER_COMPETENCE_LIST, getUserCompetenceList),
    yield takeLatest(CompetenceTypes.GET_COMPETENCE_OVERVIEW, getCompetenceOverview),
    yield takeLatest(CompetenceTypes.GET_COMPETENCE_SKILLS, getCompetenceSkills),
    yield takeLatest(CompetenceTypes.GET_COMPETENCE_HISTORY, getCompetenceHistory),
    yield takeLatest(CompetenceTypes.GET_COMPETENCE_HISTORY_NEXT_PAGE, getCompetenceHistoryNextPage),
    yield takeLatest(CompetenceTypes.GET_COMPETENCE_STATISTICS, getCompetenceStatistics),
    yield takeLatest(CompetenceTypes.GET_COMPETENCE_SCALES, getCompetenceScales),
    yield takeLatest(CompetenceTypes.GET_RESOURCES, getResources),
    yield takeLatest(CompetenceTypes.ENROLL_EVENT, enrollEvent),
    yield takeLatest(CompetenceTypes.DISENROLL_EVENT, disenrollEvent),
    yield takeLatest(CompetenceTypes.GET_SELF_MARK_SURVEY, getSelfMarkSurvey),
    yield takeLatest(CompetenceTypes.SEND_SELF_MARK_SURVEY, sendSelfMarkSurvey),
    yield takeLatest(CompetenceTypes.GET_CONFIRMATION_CATEGORIES, getConfirmationCategories),
    yield takeLatest(CompetenceTypes.SEND_CONFIRMATION, sendConfirmation),
    yield takeLatest(CompetenceTypes.GET_CONFIRMATION_LIST, getConfirmationList),
    yield takeLatest(CompetenceTypes.SEND_SUGGESTION, sendSuggestion),
  ]);
}
