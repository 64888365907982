import i18n from "i18next";

export const getPeopleCounterLabel = (counter) => {
  if (counter === 1) {
    return i18n.t("people.counter1");
  } else if (counter > 1 && counter < 5) {
    return i18n.t("people.counter2");
  } else {
    return i18n.t("people.counter5");
  }
};
