import styled from "styled-components";
import { Form as BaseForm } from "formik";

import { Color } from "../../../../theme/colors";
import { Media } from "../../../../theme/media";
import { Button as BaseButton } from "../../../button/button.component";

export const Container = styled.div`
  border-top: 1px solid ${Color.gray8};
  padding: 20px 0 0;
  display: flex;

  ${Media.tablet`
    padding: 25px 30px 25px 40px;
  `}
`;

export const AvatarWrapper = styled.div`
  display: none;
  
  ${Media.tablet `
    display: block;
    flex-shrink: 0;
  `}
`;

export const Form = styled(BaseForm)`
  width: 100%;

  ${Media.tablet `
    margin-left: 15px;
  `}
`;

export const FieldWrapper = styled.div`
  padding: 0 20px;

  ${Media.tablet`
    padding: 0;
  `}
`;

export const Actions = styled.div`
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${Media.tablet`
    padding: 30px 0;
  `}
`;

export const Button = styled(BaseButton)`
  & + & {
    margin-left: 10px;
  }
`;

export const MobileButton = styled(BaseButton)`
  width: 100%;
  margin-top: 20px;
  border: 0;
  border-top: 1px solid ${Color.gray8};
  border-radius: 0;
  color: ${() => Color.brandBasic};

  ${Media.tablet`
    display: none;
  `}
`;

export const KeyEventWrapper = styled.div``;
