import * as Yup from "yup";

import i18n from "../../../../translations/i18n";

export const min12charsRegex = /^.{12,}$/;
export const capitalLettersRegex = /^(?=.*[a-ząćęłńóśżź])(?=.*[A-ZĄĆĘŁŃÓŚŻŹ]).+$/;
export const decimalRegex = /\d/;
export const specialCharsRegex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\]/;
export const min4RepeatedCharsRegex = /(.).*\1.*\1.*\1/;
export const disallowedCharsRegex = /[^a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/;
export const simplePasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/
export const advancedPasswordRegex = /^(?=(?:.*[a-ząćęłńóśżź]){1,})(?=(?:.*[A-ZĄĆĘŁŃÓŚŻŹ]){1,})(?=(?:.*\d){1,})(?=(?:.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\]){1,})(?!.*(.).*\1.*\1.*\1)[A-ZĄĆĘŁŃÓŚŻŹa-ząćęłńóśżź\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\]{12,}$/;

export const FormSchema = Yup.object().shape({
  oldPassword: Yup.string().required(i18n.t("validationMessage.required")),
  newPassword: Yup.string()
    .min(12, i18n.t("validationMessage.shortMinLength", { length: 12 }))
    .matches(advancedPasswordRegex, i18n.t("validationMessage.incorrectPassword"))
    .required(i18n.t("validationMessage.required")),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], i18n.t("validationMessage.matchPasswords"))
    .required(i18n.t("validationMessage.required"))
});
