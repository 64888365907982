import styled from "styled-components";

import { Color } from "../../theme";
import { breakpoints } from "../../UIGlobals";

export const Container = styled.div`
  position: fixed;
  bottom: 30px;
  left: 110px;
  z-index: 999;
  background-color: ${(props) => props.$isOnline ? Color.lightGreen : Color.black};
  color: ${(props) => props.$isOnline ? Color.green : Color.white};
  width: 160px;
  height: 90px;
  border-radius: 3px;
  border: 1px solid ${props => props.$isOnline ? Color.green2 : Color.black};
  display: flex;
  flex-direction: column;
  transition: opacity 0.5s ease-in-out;
  opacity: ${props => props.$isVisible ? "1" : "0"};
  pointer-events: none;

  @media (${breakpoints().maxL}) {
    left: 30px;
  }

  @media (${breakpoints().maxM}) {
    bottom: 80px;
  }

  @media (${breakpoints().maxS}) {
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 42px;
  }
`;

export const Text = styled.p`
  flex: 1;
  padding: 9px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
`;

export const IconWrapper = styled.div`
  flex: 1;
  padding: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.$isOnline ? Color.green2 : Color.gray6};

  @media (${breakpoints().maxS}) {
    display: none;
  }
`;
