import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ButtonColor, ButtonVariant } from "../../../../components/button/button.constants";
import { Loader } from "../../../../components/loader/loader.component";
import { deepClone } from "../../../../helpers";
import { GamificationActions } from "../../redux/gamification.reducer";
import { selectSurvey, selectSurveyPending } from "../../redux/gamification.selectors";

import { SurveyQuestion } from "./surveyQuestion/surveyQuestion.component";
import {
  Container,
  Title,
  ButtonsRow,
  Button,
} from "./gamificationSurvey.styled";
import { SurveyViewType } from "./gamificationSurvey.constants";

export const GamificationSurvey = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const isPending = useSelector(selectSurveyPending);
  const survey = useSelector(selectSurvey);
  const [surveyResults, setSurveyResults] = useState([]);
  const [questions, setQuestions] = useState([]);
  const buttonDisabled = !questions?.every((question) => question.answers.some((answer) => answer.selected));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(GamificationActions.fetchSurvey(params.id));
  }, [dispatch, params.id]);

  useEffect(() => {
    if (survey) {
      if (survey.viewType === SurveyViewType.ShowSummaryQuestionnaire) {
        setSurveyResults(survey.surveyResult.questions);
      } else {
        setQuestions(survey.pages[0].sections[0].questions);
      }
    }
  }, [survey]);

  const setAnswer = (questionId, answerId) => {
    const updatedQuestions = [...questions];
    const questionIndex = updatedQuestions.findIndex((question) => question.id === questionId);
    if (questionIndex === -1) return;

    const answerIndex = updatedQuestions[questionIndex].answers.findIndex((answer) => {
      return answer.id === answerId;
    });
    if (answerIndex === -1) return;

    updatedQuestions[questionIndex].answers = updatedQuestions[questionIndex].answers.map(
      (answer) => ({ ...answer, selected: answer.id === answerId })
    );
    setQuestions(updatedQuestions);
  };

  const handleSubmit = () => {
    const updatedSurvey = deepClone(survey);
    updatedSurvey.pages[0].sections[0].questions = questions;
    dispatch(GamificationActions.sendSurveyAnswers(updatedSurvey));
  };

  if (isPending || !survey) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  return (
    <Container>
      <Title>{t("gamification.gamificationSurvey.survey")}</Title>
      {survey.viewType === SurveyViewType.ShowSummaryQuestionnaire
        ? surveyResults.map((questionResults, index) => (
          <SurveyQuestion
            key={index}
            question={questionResults.question}
            answers={questionResults.answers}
            answersCount={questionResults.answersCount}
            resultsView
          />
        ))
        : questions.map((question) => (
          <SurveyQuestion
            key={question.id}
            question={question.question}
            answers={question.answers}
            setAnswer={(answerId) => setAnswer(question.id, answerId)}
          />
        ))}
      {survey.viewType === SurveyViewType.ShowSummaryQuestionnaire ? (
        <Button
          onClick={() => navigate("/gamification")}
          variant={ButtonVariant.Outlined}
        >
          {t("gamification.gamificationSurvey.back")}
        </Button>
      ) : (
        <ButtonsRow>
          <Button
            onClick={() => navigate("/gamification")}
            variant={ButtonVariant.Outlined}
          >
            {t("gamification.gamificationSurvey.cancel")}
          </Button>
          <Button
            disabled={buttonDisabled}
            onClick={handleSubmit}
            color={ButtonColor.Primary}
          >
            {t("gamification.gamificationSurvey.accept")}
          </Button>
        </ButtonsRow>
      )}
    </Container>
  );
};
