import { createActions, createReducer } from "reduxsauce";

export const { Types: SurveysTypes, Creators: SurveysActions } = createActions(
  {
    setIsPending: ["isPending"],
    fetchLists: null,
    fetchListsSuccess: ["lists"],
    clearLists: null,
  },
  { prefix: "SURVEYS/" }
);

const INITIAL_STATE = {
  isPending: false,
  tests: [],
  competenceMeasures: [],
  surveys: [],
};

const setIsPending = (state, { isPending }) => ({ ...state, isPending });

const sortSurveys = (item1, item2) => item2.createdAt - item1.createdAt;

const fetchListsSuccess = (state, { lists }) => ({
  ...state,
  tests: lists.exams.sort(sortSurveys),
  surveys: lists.questionnaires.sort(sortSurveys),
  competenceMeasures: lists.competenceExams.sort(sortSurveys),
});

const clearLists = () => INITIAL_STATE;

export const reducer = createReducer(INITIAL_STATE, {
  [SurveysTypes.SET_IS_PENDING]: setIsPending,
  [SurveysTypes.FETCH_LISTS_SUCCESS]: fetchListsSuccess,
  [SurveysTypes.CLEAR_LISTS]: clearLists,
});
