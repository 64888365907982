import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Container } from "../../../../theme/containers";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { CompetenceActions } from "../../redux/competence.reducer";
import { selectCompetenceHistory, selectCompetenceStatistics } from "../../redux/competence.selectors";
import { CompetenceStatisticsChart } from "../competenceStatisticsChart/competenceStatisticsChart.component";
import { CompetenceHistoryPanel } from "../competenceHistoryPanel/competenceHistoryPanel.component";

import { Title } from "./statisticsTab.styled";

export const StatisticsTab = ({ className, competenceId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const competenceStatistics = useSelector(selectCompetenceStatistics);
  const competenceHistory = useSelector(selectCompetenceHistory);

  useEffect(() => {
    dispatch(CompetenceActions.getCompetenceStatistics(competenceId));
    dispatch(CompetenceActions.getCompetenceHistory(competenceId));
  }, [dispatch, competenceId]);

  const renderChart = renderWhenTrue(() => (
    <>
      <Title>{t("competence.statisticsTab.competenceStatistics")}</Title>
      <CompetenceStatisticsChart competenceStatistics={competenceStatistics} />
    </>
  ));

  const renderHistory = renderWhenTrue(() => (
    <>
      <Title>{t("competence.statisticsTab.competenceHistory")}</Title>
      <CompetenceHistoryPanel competenceHistory={competenceHistory} />
    </>
  ));

  return (
    <Container className={className}>
      {renderChart(!!competenceStatistics)}
      {renderHistory(!!competenceHistory)}
    </Container>
  );
};

StatisticsTab.propTypes = {
  className: PropTypes.string,
  competenceId: PropTypes.number,
};
