import styled from "styled-components";

import { Separator as SeparatorBase } from "../../../../theme";

export const Container = styled.div``;

export const Separator = styled(SeparatorBase)`
  && {
    margin-top: 100px;
    margin-bottom: 100px;
  }
`;
