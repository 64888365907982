import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { ScrollLessonActions } from "../../redux/scrollLesson.reducer";

import Question from "./question.component";
import { Container, Separator } from "./finalQuiz.styled";

const FinalQuiz = ({ data }) => {
  const dispatch = useDispatch();
  const [currentQuestionIndexCounter, setCurrentQuestionIndexCounter] = useState(0);

  useEffect(() => {
    if (currentQuestionIndexCounter >= data.children.length) {
      dispatch(ScrollLessonActions.saveQuiz());
    }
  }, [data, currentQuestionIndexCounter]);

  return (
    <Container>
      {data.children.map((questionData, index) => (
        index <= currentQuestionIndexCounter && (
          <Fragment key={questionData.id}>
            <Question
              questionIndex={index}
              allQuestionsCounter={data.children.length}
              data={questionData}
              setCompleted={() => setCurrentQuestionIndexCounter(index + 1)}
            />
            <Separator />
          </Fragment>
        )
      ))}
    </Container>
  );
};

export default FinalQuiz;
