import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { currentLanguage } from "../../helpers/translations";
import { InputStyle } from "../input/input.constants";

import { Picker, Error, PickerProps } from "./datePicker.styled";
import { getErrorText } from "./datePicker.utils";
import { pickerDateFormat } from "./datePicker.constants";

export const DatePicker = ({
  className,
  selectedDate,
  onChange,
  name,
  disabled,
  onBlur,
  onFocus,
  minDate,
  maxDate,
  styleType = InputStyle.Default,
}) => {
  const [error, setError] = useState();
  const [pickerTouched, setPickerTouched] = useState(false);
  const errorText = getErrorText(error, minDate, maxDate);

  const handleOnChange = (event) => {
    onChange(event);
    setPickerTouched(true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={currentLanguage}>
      <Picker
        className={className}
        format={pickerDateFormat}
        name={name}
        value={dayjs(selectedDate)}
        onChange={handleOnChange}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        minDate={minDate}
        maxDate={dayjs(maxDate)}
        onError={setError}
        $styleType={styleType}
        $active={pickerTouched || !error}
        slotProps={PickerProps}
      />
      {pickerTouched && errorText && <Error>{errorText}</Error>}
    </LocalizationProvider>
  );
};

DatePicker.propTypes = {
  className: PropTypes.string,
  selectedDate: PropTypes.number,
  onChange: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  minDate: PropTypes.number,
  maxDate: PropTypes.number,
  styleType: PropTypes.string,
};
