import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { cond, equals, always, T } from "ramda";

import { downloadAll } from "../../../../helpers/zipDownload";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { ButtonColor } from "../../../button/button.constants";
import { RightDrawer } from "../../../rightDrawer/rightDrawer.component";
import { Icon } from "../../../icon/icon.component";
import { ItemType } from "../../thumbnail.constants";
import { MultiFilesVideo } from "../multiFilesVideo/multiFilesVideo.component";
import { MultiFilesDownload } from "../multiFilesDownload/multiFilesDownload.component";
import { MultiFilesLesson } from "../multiFilesLesson/multiFilesLesson.component";
import { MultiFilesTraining } from "../multiFilesTraining/multiFilesTraining.component";

import {
  Container,
  DrawerType,
  TitleWrapper,
  DrawerTitle,
  CloseButton,
  CloseIcon,
  DownloadIcon,
  Button,
} from "./multiFilesDrawer.styled";

export const MultiFilesDrawer = (props) => {
  const {
    className,
    title,
    items,
    onClose,
    type,
    open,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const getDrawerTypeLabel = cond([
    [equals(ItemType.Contents), always(t("global.video"))],
    [equals(ItemType.Files), always(t("global.filesToDownload"))],
    [equals(ItemType.Items), always(t("competence.competence"))],
    [T, always("")],
  ]);

  const handleMultiFileVideoClick = useCallback((id) => {
    navigate(`/wideo/${id}`);
  }, [navigate]);

  const handleMultiFileLessonClick = useCallback((id) => {
    navigate(`/lekcje/${id}`);
  }, [navigate]);

  const handleMultiFileTrainingClick = useCallback((id) => {
    navigate(`/szkolenia/${id}`);
  }, [navigate]);

  const handleFinishDownload = () => setDownloadInProgress(false);

  const handleDownloadAll = () => {
    setDownloadInProgress(true);
    downloadAll(title, items, handleFinishDownload);
  };

  const renderVideoItems = renderWhenTrue(() => (
    items.map((item) => (
      <MultiFilesVideo
        key={item.id}
        id={item.id}
        name={item.name}
        imageSrc={item.image}
        onClick={handleMultiFileVideoClick}
      />
    ))
  ));

  const renderDownloadAllButton = renderWhenTrue(() => (
    <Button
      onClick={handleDownloadAll}
      disabled={downloadInProgress}
      color={ButtonColor.Primary}
    >
      <Icon>
        <DownloadIcon />
      </Icon>
      {t("learning.files.files.downloadAll")}
    </Button>
  ));

  const renderDownloadItems = renderWhenTrue(() => (
    items.map((item) => (
      <MultiFilesDownload
        key={item.id}
        name={item.name}
        url={item.url}
      />
    ))
  ));

  const renderVariousItems = renderWhenTrue(() => (
    items.map((item) => {
      if (item.type === ItemType.Video) {
        return (
          <MultiFilesVideo
            key={item.id}
            id={item.id}
            name={item.name}
            imageSrc={item.image}
            onClick={handleMultiFileVideoClick}
          />
        );
      } else if (item.type === ItemType.Lesson || item.type === ItemType.ScrollLesson) {
        return (
          <MultiFilesLesson
            key={item.id}
            id={item.id}
            name={item.name}
            imageSrc={item.image}
            onClick={handleMultiFileLessonClick}
          />
        );
      } else if (item.type === ItemType.Files) {
        return (
          <MultiFilesDownload
            key={item.id}
            name={item.name}
            url={item.url}
          />
        );
      } else if (item.course) {
        return (
          <MultiFilesTraining
            key={item.id}
            id={item.id}
            name={item.course.name}
            onClick={handleMultiFileTrainingClick}
          />
        );
      }

      return null;
    })
  ));

  return (
    <RightDrawer open={open} onClose={onClose}>
      <Container className={className}>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <DrawerType>{getDrawerTypeLabel(type)}</DrawerType>
        <TitleWrapper>
          <DrawerTitle>{title}</DrawerTitle>
          {renderDownloadAllButton(type === ItemType.Files)}
        </TitleWrapper>
        {renderVideoItems(type === ItemType.Contents)}
        {renderDownloadItems(type === ItemType.Files)}
        {renderVariousItems(type === ItemType.Items)}
      </Container>
    </RightDrawer>
  );
};

MultiFilesDrawer.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  imageSrc: PropTypes.string,
  onClick: PropTypes.func,
};
