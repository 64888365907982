import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

import { Icon } from "../../components/icon/icon.component";
import { ReactComponent as PenIcon } from "../../images/pencil.svg";
import { ReactComponent as TrashIcon } from "../../images/trash2.svg";
import { renderWhenTrue } from "../../helpers/rendering";

import {
  Container,
  OptionsButton,
  Dropdown,
  DropdownOption,
  OptionIconWrapper,
  OptionsIcon,
} from "./optionsDropdown.styled";

export const OptionsDropdown = (props) => {
  const {
    className,
    editPermission,
    deletePermission,
    onDelete,
    onEdit,
  } = props;
  const { t } = useTranslation();
  const [optionsVisible, setOptionsVisible] = useState(false);

  const handleToggleOptions = useCallback(() => {
    setOptionsVisible(!optionsVisible);
  }, [optionsVisible]);

  const handleDeleteClick = useCallback((event) => {
    handleToggleOptions();
    onDelete(event);
  }, [handleToggleOptions, onDelete]);

  const handleEditClick = useCallback((event) => {
    handleToggleOptions();
    onEdit(event);
  }, [handleToggleOptions, onEdit]);

  const renderEditOption = renderWhenTrue(() => (
    <DropdownOption onClick={handleEditClick}>
      <OptionIconWrapper>
        <Icon size={16}>
          <PenIcon />
        </Icon>
      </OptionIconWrapper>
      {t("news.entry.edit")}
    </DropdownOption>
  ));

  const renderDeleteOption = renderWhenTrue(() => (
    <DropdownOption onClick={handleDeleteClick}>
      <OptionIconWrapper>
        <Icon size={16}>
          <TrashIcon />
        </Icon>
      </OptionIconWrapper>
      {t("news.entry.delete")}
    </DropdownOption>
  ));

  const renderDropdown = renderWhenTrue(() => (
    <ClickAwayListener onClickAway={handleToggleOptions}>
      <Dropdown>
        {renderEditOption(editPermission)}
        {renderDeleteOption(deletePermission)}
      </Dropdown>
    </ClickAwayListener>
  ));

  return (
    <Container className={className}>
      <OptionsButton onClick={handleToggleOptions}>
        <Icon size={15}>
          <OptionsIcon active={optionsVisible.toString()} />
        </Icon>
      </OptionsButton>
      {renderDropdown(optionsVisible)}
    </Container>
  );
};

OptionsDropdown.propTypes = {
  className: PropTypes.string,
};
