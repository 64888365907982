import styled from "styled-components";

import { breakpoints, colors } from "../../../UIGlobals";

export const Container = styled.div`
  display: ${props => (props.$noFlex ? "block" : "flex")};
  ${props => props.reversed && "flex-direction: row-reverse;"}
  ${props => props.flexColumn && "flex-direction: column;"}
  ${props => props.flexColumn && "align-items: center;"}
  ${props => props.flexColumn && "justify-content: center;"}
  width: 100%;
  height: 650px;
  background: ${props => (props.$dark ? colors().gray6 : colors().white)};
  color: ${props => (props.$dark ? colors().white : colors().deepGray)};
  overflow: hidden;
  ${props => props.$bgImage && `background-image: url(${props.$bgImage});`}
  ${props => props.$bgImage && "background-position: center right;"}
  ${props => props.$bgImage && "background-repeat: no-repeat;"}
  @media (${breakpoints().maxM}) {
    height: 570px;
    flex-direction: column;
  }
`;

export const Header = styled.p`
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 20px;
  @media (${breakpoints().maxM}) {
    font-size: 16px;
    line-height: 20px;
    margin: 30px 0 0;
  }
`;

export const TextColumn = styled.div`
  padding: ${props => (props.textColumnType ? "0 100px" : "0 0 0 100px")};
  width: ${props => (props.textColumnType ? "100%" : "70%")};
  flex: 3;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  @media (${breakpoints().maxM}) {
    padding: 0 20px;
    min-width: 95%;
  }
`;

export const Paragraph = styled.p`
  margin-top: 30px;
  line-height: 30px;
  font-size: 20px;
  @media (${breakpoints().maxM}) {
    margin: ${props => (props.bottom ? "0 0 10px" : "0")};
    line-height: 17px;
    font-size: 12px;
  }
`;

export const ItalicParagraph = styled(Paragraph)`
  font-style: italic;
  font-weight: bold;
`;

export const ImageColumn = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  ${props => props.bgIcon && "position: relative;"}
  ${props => !props.bgIcon && "justify-content: center;"}
`;

export const Image = styled.img`
  max-height: ${props => (props.bigIcon ? "380px" : "300px")};
  ${props => props.bigIcon && "position: absolute;"}
  ${props => props.bigIcon && "right: 0;"}
  @media (${breakpoints().maxM}) {
    display: none;
  }
`;

export const TextColumnIcon = styled.img`
  width: 100%;
  max-width: 600px;
  max-height: 300px;
  margin: 40px auto 0;
`;

export const ListText = styled.span`
  font-size: 12px;
  line-height: 15px;
  margin-left: 22px;
`;

export const CounterSpan = styled.span`
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid ${colors().maroon};
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;
