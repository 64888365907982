import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";

import { Container, Checkmark, Label } from "./checkbox.styled";

export const Checkbox = ({
  className,
  checked,
  onChange,
  disabled,
  name,
  label,
  value,
  invalid,
  type,
  withLinks,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleClick = useCallback((event) => {
    if (!withLinks) {
      event.preventDefault();
    }

    if (event.target.localName !== "a") {
      setIsChecked(!isChecked);
      onChange({ target: {
        name,
        value,
        checked: !isChecked,
      }});
    }
  }, [isChecked, name, onChange, value, withLinks]);

  return (
    <Container
      className={className}
      onClick={handleClick}
      invalid={invalid}
      checked={isChecked}
      type={type}
      disabled={disabled}
    >
      <Label dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(label, { ADD_ATTR: ["target"] }) }} />
      <Checkmark type={type} />
    </Container>
  );
};


Checkbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  withLinks: PropTypes.bool,
};
