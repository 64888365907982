/* eslint-disable react/no-string-refs */
import React, { Component } from "react";
import i18n from "i18next";

import { Icon } from "../../../UIElements";
import { ElearningButton } from "../common.styled";

import { Container } from "./tableTextImage.styled";

export default class TableTextImage extends Component {
  constructor() {
    super();
    this.state = { elements: [], footer: [] };
  }

  componentDidMount() {
    this.attempts = null;
    if (this.props.config) {
      fetch(this.props.baseUrl + this.props.config)
        .then(data => data.json())
        .then(data => this.setState({ ...data }));
    }
  }

  imageHtml(index, element, baseUrl) {
    const elementStyle = {
      left: element.pos ? element.pos.left : "",
      top: element.pos ? element.pos.top : "",
      position: element.pos ? element.pos.position : ""
    };
    const delay = element.delay !== undefined ? `${element.delay}s` : `0.${index * 2}s`;
    const AnimationClass = `bg-img slide-img ${(element.className !== undefined ? element.className : "show1")}`;
    const imageStyle = {
      animationDelay: delay
    };
    const src = baseUrl + element.content;
    return (
      <div key={index} className="col js-element" type="image" style={elementStyle}>
        <img className={AnimationClass} src={src} style={imageStyle} alt="interaction background" />
      </div>
    );
  }

  textHtml(index, element) {
    const elementStyle = {
      left: element.pos ? element.pos.left : "",
      top: element.pos ? element.pos.top : "",
      position: element.pos ? element.pos.position : "",
      transform: element.transform ? element.transform : "",
      opacity: element.opacity ? element.opacity : ""
    };
    const HeadlineClass = `headline ${(element.className !== undefined ? element.className : "show1")}`;
    const imageStyle = {
      animationDelay: `0.${index * 2}s`,
      marginTop: element.marginTop ? element.marginTop : ""
    };
    return (
      <div key={index} className="js-element" type="text" style={elementStyle}>
        <p className={HeadlineClass} style={imageStyle} dangerouslySetInnerHTML={{ __html: `${element.content} ` }} />
      </div>
    );
  }

  headerHtml(index, element, baseUrl = "") {
    const elementStyle = {
      position: element.pos ? element.pos.position : "",
      transform: element.transform ? element.transform : "",
      opacity: element.opacity ? element.opacity : ""
    };
    const HeadlineClass = `headline ${(element.className !== undefined ? element.className : "show1")}`;
    const AnimationClass = `bg-img slide-img ${(element.imgClassName !== undefined ? element.imgClassName : "show1")}`;
    const src = baseUrl + element.image;
    const imageStyle = {
      left: element.imgPos ? `${element.imgPos.left}px` : "",
      bottom: element.imgPos ? `${element.imgPos.bottom}px` : "",
      animationDelay: `0.${index * 2}s`,
      marginTop: element.marginTop ? element.marginTop : "",
      position: element.imgPos ? element.imgPos.position : "",
      height: element.imgPos && element.imgPos.height ? `${element.imgPos.height}px` : ""
    };

    return (
      <div key={index} className="js-element guarantee-headline-box" type="text" style={elementStyle}>
        <img className={AnimationClass} src={src} style={imageStyle} alt="slide background" />
        <p className={HeadlineClass} >
          {element.content}{" "}
        </p>
      </div>
    );
  }

  render() {
    let imageHtml = this.imageHtml;
    let textHtml = this.textHtml;
    let headerHtml = this.headerHtml;

    const elements = this.state.elements.map((col, index) => {
      return <div key={index} className={`col-${index}`}>
        <div>
          {
            col.map((item, index) => {
              switch (item.type) {
              case "header":
                return headerHtml(index, item, this.props.baseUrl, item.contentType);
              case "image":
                return imageHtml(index, item, this.props.baseUrl);
              case "text":
                return textHtml(index, item);
              default:
                return textHtml(index, item);
              }
            })
          }
        </div>
      </div>;

    });
    const footer = this.state.footer.map((col, index) => {
      return <div key={index} className={`col-${index}`}>
        <div>
          {
            col.map((item, index) => {
              return textHtml(index, item);
            })
          }
        </div>
      </div>;
    });

    const data = this.state.mobile_table_structure ? (window.innerWidth > 768 ? this.state.table_structure : this.state.mobile_table_structure) : this.state.table_structure;
    const lessonId = this.state.lesson_id;
    const styleType = this.state.style_type || "classic-header";
    const additionalLessonId = this.state.table_structure ? styleType : "";
    const alignment = this.state.alignment ? this.state.alignment : "";
    let table;
    if (data) {
      table = data.map((itemRow, index) => {
        const cell = itemRow.map((itemCell, index) => {
          let colSpan = itemCell.colspan !== undefined ? itemCell.colspan : "";
          let rowSpan = itemCell.rowspan !== undefined ? itemCell.rowspan : "";
          let mark = itemCell.mark !== undefined ? itemCell.mark : "";
          let texts = itemCell.texts !== undefined ? itemCell.texts : null;
          let paragraph = null;
          if (texts) {
            paragraph = texts.map((itemText, index) => {
              return <p key={index}>{itemText}</p>;
            });
          } else {
            paragraph = itemCell.text;
          }

          return (
            <td key={index} rowSpan={rowSpan} className={mark} colSpan={colSpan}>
              {paragraph}
            </td>
          );
        });
        return <tr key={index}>{cell}</tr>;
      });
    }
    return (
      <Container>
        <div className={`${lessonId} ${additionalLessonId}`}>
          <div className={`wrapper table-wrap ${alignment}`} ref="WrapNode">
            {elements}
            {table && table.length > 0 &&
              <div className="table-container-guarantee">
                <div className="table-wrap">
                  <table className={styleType}>
                    <tbody>{table}</tbody>
                  </table>
                </div>
              </div>
            }
            {footer && footer.length > 0 &&
              <div className="table-footer">
                {footer}
              </div>
            }
          </div>
          <div className="button-wrap">
            <ElearningButton className="btn-pos-abs" onClick={this.props.callComplete}>
              { lessonId === "single-image-arrow" ?
                <Icon icon="arrow_left" size={12} rotation={"-180deg"} /> :
                i18n.t("interactions.watched")
              }
            </ElearningButton>
          </div>
        </div>
      </Container>
    );
  }
}
