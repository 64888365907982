import styled from "styled-components";

import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
`;

export const Answer = styled.div`
  height: 90px;
  border: 1px solid ${Color.gray5};
  background-color: ${Color.white};
  box-shadow: 0 0 20px 0 rgba(51, 143, 206, 0.03);
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  font-weight: ${FontWeight.Bold};
  line-height: 1.8;
  text-transform: uppercase;
  color: ${Color.deepGray};
  text-align: center;

  ${Media.tablet`
    font-size: 11px;
    padding: 0 18px;
    height: 70px;
  `}
`;
