import React from "react";
import { useTranslation } from "react-i18next";

import {
  Drawer,
  Title,
  Text,
  Buttons,
  ColorButton,
  TransparentButton,
  Link,
} from "../../cookiePopup.styled";

import { Container, Wrapper, Content } from "./mainPopup.styled";

export const MainPopup = ({ setDetailsView, acceptCookies }) => {
  const { t } = useTranslation();

  return (
    <Drawer open anchor="bottom">
      <Container>
        <Wrapper>
          <Content>
            <Title>{t("cookiePopup.mainTitle")}</Title>
            <Text>
              {t("cookiePopup.mainText")}
              <Link to="/polityka-prywatnosci" target="_blank">
                {t("cookiePopup.mainText2")}
              </Link>
            </Text>
          </Content>
          <Buttons>
            <ColorButton onClick={() => acceptCookies(true)}>
              {t("cookiePopup.accept")}
            </ColorButton>
            <ColorButton onClick={() => acceptCookies(false)}>
              {t("cookiePopup.noAccept")}
            </ColorButton>
            <TransparentButton onClick={() => setDetailsView(true)}>
              {t("cookiePopup.changeSettings")}
            </TransparentButton>
          </Buttons>
        </Wrapper>
      </Container>
    </Drawer>
  );
};