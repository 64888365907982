import styled, { css } from "styled-components";

import { ReactComponent as EyeOffSvg } from "../../../images/eyeOff.svg";
import { ReactComponent as EyeOnSvg } from "../../../images/eyeOn.svg";
import { Color } from "../../../theme";

export const Container = styled.button`
  background-color: ${Color.transparent};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
`;

const eyeImgStyles = css`
  width: 22px;
  height: 22px;
  fill: ${() => Color.brandBasic};
`;

export const EyeOffImg = styled(EyeOffSvg)`
  ${eyeImgStyles}
`;

export const EyeOnImg = styled(EyeOnSvg)`
  ${eyeImgStyles}
`;
