import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../../../helpers/rendering";
import { Media, FontWeight, Color } from "../../../../../theme";
import introImage from "../../images/intro.svg";
import trueAnswerImage from "../../images/endTrue.svg";
import falseAnswerImage from "../../images/endFalse.svg";
import questionMarkImage from "../../images/questionImage.svg";
import paragraphImage from "../../images/paragraphImage.svg";

export const Container = styled.div`
  display: flex;
  min-height: 650px;
  height: 100%;
  ${Media.tablet`
    padding: 30px 0;
  `}
  ${Media.tabletWide`
    padding: 0;
  `}
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 25px 20px;
  width: 100%;
  
  ${Media.tablet`
    padding: 50px 50px 30px;
    align-items: flex-start;
  `}

  ${Media.desktop`
    padding: 115px 125px 30px;
  `}
`;

export const Text = styled.p`
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 30px;
  color: ${Color.gray6};

  &:last-of-type {
    flex-grow: 1;
  }

  ${styleWhenTrue(prop("light"), css`
    font-weight: ${FontWeight.Light};
  `)}
`;

export const RightPanel = styled.div`
  display: none;

  ${Media.tablet`
    display: block;
    width: 100%;
    max-width: 350px;
    flex-shrink: 0;
    background-image: url(${introImage});
  
    ${styleWhenTrue(prop("correctTheme"), css`
      background-image: url(${trueAnswerImage});
    `)}

    ${styleWhenTrue(prop("wrongTheme"), css`
      background-image: url(${falseAnswerImage});
    `)}

    ${styleWhenTrue(prop("complianceTheme"), css`
      background-image: url(${questionMarkImage});
      background-color: ${Color.blue4};
      background-repeat: no-repeat;
      background-position: center;

      ${styleWhenTrue(prop("summaryView"), css`
        background-image: url(${paragraphImage});
        background-position: right;
      `)}
    `)}
  `}
`;
