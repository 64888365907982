import styled from "styled-components";

import { ReactComponent as StatisticsMan } from "../../images/statisticsMan.svg";
import { Color } from "../../theme";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ImageWrapper = styled.div`
  margin-top: 18px;
  width: 100%;
  height: 100%;
  background-color: ${Color.gray8};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StatisticImage = styled(StatisticsMan)``;
