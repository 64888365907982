import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { NewChartPopup } from "../newChartPopup/newChartPopup.component";

import {
  Container,
  Title,
  Text,
  IconWrapper,
  AddIcon,
} from "./newChartBox.styled";

export const NewChartBox = ({ competenceType, onAddNew }) => {
  const { t } = useTranslation();
  const [popupOpen, setPopupOpen] = useState(false);

  const handleClosePopup = () => setPopupOpen(false);

  const handleOpenPopup = () => setPopupOpen(true);

  return (
    <>
      <Container onClick={handleOpenPopup}>
        <IconWrapper>
          <AddIcon />
        </IconWrapper>
        <Title>{t("teamIndicators.newChartBox.addNewChart")}</Title>
        <Text>{t("teamIndicators.newChartBox.addNewChartDescription")}</Text>
      </Container>
      <NewChartPopup
        isOpen={popupOpen}
        competenceType={competenceType}
        handleClose={handleClosePopup}
        onAddNew={onAddNew}
      />
    </>
  );
};

NewChartBox.propTypes = {
  competenceType: PropTypes.string,
  onAddNew: PropTypes.func,
};
