import React, { useState, useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { selectUserDuties, selectOnboardingMode } from "../../../userContext/redux/userContext.selectors";
import { Loader } from "../../../components/loader/loader.component";
import { renderWhenTrueOtherwise } from "../../../helpers/rendering";
import { OnboardingMode } from "../../../app/app.constants";
import { Theme } from "../../../appConfig";

import { Winner } from "./components/winner/winner.component";
import { getData } from "./services/api";
import { AllDuties, VWHRDuties } from "./opponentTest.constants";
import { Container, Opponent, Question } from "./opponentTest.styled";

export const OpponentTest = ({ className, baseUrl, config, callComplete }) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [questionCounter, setQuestionCounter] = useState(0);
  const [isWinner, setIsWinner] = useState(false);
  const [avatarAnimation, setAvatarAnimation] = useState(null);
  const [winnerAnimation, setWinnerAnimation] = useState(null);
  const onboardingMode = useSelector(selectOnboardingMode);
  const userType = useSelector(selectUserDuties);
  const duties = onboardingMode === OnboardingMode.OnboardingAll || onboardingMode === OnboardingMode.LeasinGo
    ? AllDuties
    : onboardingMode === OnboardingMode.OnboardingVWFS
      ? VWHRDuties
      : userType;
  const questions = useMemo(() => {
    if (data) {
      if (Theme.OnboardingWithDuties) {
        return data.questions.filter((question) => (
          duties.some((type) => question.userType.includes(type.name))
        ));
      }
      return data.questions;
    }

    return [];
  }, [data, duties]);

  useEffect(() => {
    getData(`${baseUrl}${config}`, setData, setIsPending, setAvatarAnimation, setWinnerAnimation);
  }, [baseUrl, config]);

  const handleNextQuestion = useCallback(() => {
    setQuestionCounter(questionCounter + 1);
  }, [questionCounter]);

  const handleWin = useCallback(() => setIsWinner(true), [setIsWinner]);

  const renderQuestions = () => (
    <>
      <Opponent
        questionCounter={questionCounter}
        allQuestionsCounter={questions.length}
        monsterName={data.opponentName}
        stage={data.stage}
        monsterAvatar={avatarAnimation}
      />
      <Question
        questionCounter={questionCounter}
        allQuestionsCounter={questions.length}
        onNextQuestion={handleNextQuestion}
        onWin={handleWin}
        question={questions[questionCounter].question}
        answers={questions[questionCounter].answers}
        correctAnswer={questions[questionCounter].correctIndex}
      />
    </>
  );

  const renderWinner = () => (
    <Winner
      monsterName={data.opponentName}
      stage={data.stage}
      monsterAnimation={winnerAnimation}
      monsterText={data.opponentText}
      callComplete={callComplete}
    />
  );

  const renderInteraction = renderWhenTrueOtherwise(
    renderWinner,
    renderQuestions,
  );

  const renderLoader = () => <Loader />;

  const renderContent = renderWhenTrueOtherwise(
    () => renderInteraction(isWinner),
    renderLoader,
  );

  return (
    <Container className={className} $withBackground={isWinner}>
      {renderContent(!isPending && !!questions.length)}
    </Container>
  );
};

OpponentTest.propTypes = {
  className: PropTypes.string,
};
