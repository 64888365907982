import React, { useRef, useEffect, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { cond, propEq, T, always } from "ramda";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Editor, RichUtils, EditorState, Modifier } from "draft-js";
import "draft-js/dist/Draft.css";

import { ReactComponent as EmojiSVG } from "../../images/emoji.svg";
import { renderWhenTrue } from "../../helpers/rendering";
import { Icon } from "../icon/icon.component";
import { Tooltip } from "../tooltip/tooltip.component";
import { EmojiPicker } from "../emojiPicker/emojiPicker.component";

import { OptionType, OptionsList } from "./contentEditor.constants";
import { useEditorState } from "./contentEditor.hooks";
import {
  editorCustomStyles,
  Container,
  Content,
  Toolbar,
  OptionButton,
  EmojiPickerContainer,
  EmojiPickerButton,
  ErrorMessage,
} from "./contentEditor.styled";

export const ContentEditor = (props) => {
  const {
    value = "",
    name,
    id,
    withToolbar,
    placeholder,
    error,
    oneLine,
    noEmoji,
    height = "auto",
    classesMode,
    onChange = Function.prototype,
    onBlur = Function.prototype,
    onFocus = Function.prototype,
  } = props;

  const { t } = useTranslation();
  const [isEmojiActive, setIsEmojiActive] = useState(false);
  const editorRef = useRef();

  const {
    editorState,
    activeOptions,
    setEditorState,
    content,
    handleKeyCommand,
    setEmptyState,
  } = useEditorState(value);

  const sourceEvent = useMemo(() => ({ target: { name, id, value: content }}), [name, id, content]);

  const handleBlur = useCallback(() => {
    onBlur(sourceEvent);
  }, [sourceEvent, onBlur]);

  const handleFocus = useCallback(() => {
    onFocus(sourceEvent);
    setIsEmojiActive(false);
  }, [sourceEvent, onFocus]);

  const handleBlockTypeChange = (option) => (event) => {
    event.preventDefault();
    setEditorState(RichUtils.toggleBlockType(editorState, option.key));
  }

  const handleInlineStylesChange = (option) => (event) => {
    event.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, option.key.toUpperCase()));
  }

  const handleToggleEmoji = useCallback((event) => {
    event.preventDefault();
    setIsEmojiActive(!isEmojiActive);
  }, [isEmojiActive]);

  useEffect(() => {
    if (!value) {
      setEmptyState();
    }
  }, [setEmptyState, value]);

  useEffect(() => {
    onChange(sourceEvent, content);

    if (content) {
      const selection = editorState.getSelection();
      const contentState = editorState.getCurrentContent();
      const modify = selection.isCollapsed() ? Modifier.insertText : Modifier.replaceText;
      const nextContentState = modify(contentState, selection, "");
      setEditorState(EditorState.push(
        editorState,
        nextContentState,
        "insert-characters"
      ));
    }
  }, [sourceEvent, onChange, content]);

  const getOptionClickHandler = (option) => cond([
    [propEq(OptionType.BlockType, "type"), always(handleBlockTypeChange(option))],
    [T, always(handleInlineStylesChange(option))],
  ])(option);

  const handleAddEmoji = (emoji) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const modify = selection.isCollapsed() ? Modifier.insertText : Modifier.replaceText;
    const nextContentState = modify(contentState, selection, emoji.native);
    setEditorState(EditorState.push(
      editorState,
      nextContentState,
      "insert-characters"
    ));
  }

  const renderOption = cond([
    [T, ({ Icon: OptionIcon, type, key, description }, index) => (
      <Tooltip key={index} title={t(description)}>
        <OptionButton
          type="button"
          onMouseDown={getOptionClickHandler({ type, key })}
          active={activeOptions[key]}
        >
          <Icon size={12}>
            <OptionIcon />
          </Icon>
        </OptionButton>
      </Tooltip>
    )],
  ]);

  const renderToolbar = renderWhenTrue(() => (
    <Toolbar>
      {OptionsList.map(renderOption)}
    </Toolbar>
  ));

  const renderEmojiPanel = renderWhenTrue(() => (
    <ClickAwayListener onClickAway={handleToggleEmoji}>
      <EmojiPickerContainer>
        <EmojiPicker onSelect={handleAddEmoji} />
      </EmojiPickerContainer>
    </ClickAwayListener>
  ));

  const renderErrorMessage = renderWhenTrue(() => (
    <ErrorMessage>{error}</ErrorMessage>
  ));

  return (
    <>
      <Container $invalid={!!error} $oneLine={oneLine} classesMode={classesMode}>
        {renderToolbar(!!withToolbar)}
        <Content
          $oneLine={oneLine}
          $error={!!error}
          height={height}
          classesMode={classesMode}
        >
          <Editor
            ref={editorRef}
            customStyleMap={editorCustomStyles}
            editorState={editorState}
            onChange={setEditorState}
            onBlur={handleBlur}
            onFocus={handleFocus}
            handleKeyCommand={handleKeyCommand}
            placeholder={placeholder}
          />
          {!noEmoji && (
            <EmojiPickerButton
              onClick={handleToggleEmoji}
              active={isEmojiActive}
              oneLine={oneLine}
              classesMode={classesMode}
            >
              <Icon>
                <EmojiSVG />
              </Icon>
            </EmojiPickerButton>
          )}
          {renderErrorMessage(!!error)}
        </Content>
      </Container>
      {renderEmojiPanel(isEmojiActive)}
    </>
  );
};

ContentEditor.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.string,
  withToolbar: PropTypes.bool,
  placeholder: PropTypes.string,
  noEmoji: PropTypes.bool,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  oneline: PropTypes.bool,
  classesMode: PropTypes.bool,
};
