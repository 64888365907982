import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { animateScroll } from "react-scroll";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { AvatarType } from "../../../../components/avatar/avatar.constants";
import { Avatar } from "../../../../components/avatar/avatar.component";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { renderWithLinks } from "../../../../helpers";
import { MessageType } from "../../webinars.constants";

import { ContainerId } from "./conversation.constants";
import {
  Container,
  MessageWrapper,
  Message,
  TextWrapper,
  Name,
  MessageText,
  Dot,
  Time,
  NameLine,
  FileBox,
  FileText,
  FileName,
  DownloadLink,
  DownloadIcon,
  ReceiverLine,
  Receiver,
} from "./conversation.styled";

export const Conversation = ({
  className,
  fullScreen,
  cameraOn,
  messages,
  classesMode,
  liveEnded,
}) => {
  const { t } = useTranslation();
  const [messagesGroups, setMessagesGroups] = useState([]);
  const messagesContainerRef = useRef(null);
  const messagesBottomRef = useRef(null);
  const [scrollAnim, setScrollAnim] = useState(false);

  useEffect(() => {
    messagesBottomRef.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    const observer = new window.ResizeObserver(() =>
      animateScroll.scrollToBottom({
        containerId: ContainerId,
        ignoreCancelEvents: true,
        duration: scrollAnim ? undefined : 0,
      })
    );

    if (messagesContainerRef?.current) {
      observer.observe(messagesContainerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [messagesContainerRef]);

  const groupMessages = () => {
    if (messages.length) {
      let newMessagesGroups = [];
      let messagesGroup = [];
      for (let i = 0; i < messages.length; i++) {
        if (!messagesGroup.length) {
          messagesGroup.push(messages[i]);
        } else {
          const shouldBeGrouped =
            messages[i].timestamp - messagesGroup[messagesGroup.length - 1].timestamp <= 300
            && messagesGroup[messagesGroup.length - 1].firstName === messages[i].firstName
            && messagesGroup[messagesGroup.length - 1].lastName === messages[i].lastName
            && !!messagesGroup[messagesGroup.length - 1].file === !!messages[i].file
            && !!messagesGroup[messagesGroup.length - 1].type === !!messages[i].type
            && messagesGroup[messagesGroup.length - 1].recipient?.id === messages[i].recipient?.id;
          if (shouldBeGrouped) {
            messagesGroup.push(messages[i]);
          } else {
            newMessagesGroups.push(messagesGroup);
            messagesGroup = [messages[i]];
          }
        }
        if (i === messages.length - 1 && messagesGroup.length) {
          newMessagesGroups.push(messagesGroup);
        }
      }
      setMessagesGroups(newMessagesGroups)
    }
  };

  useEffect(() => {
    groupMessages();
  }, [messages]);

  useEffect(() => {
    animateScroll.scrollToBottom({
      containerId: ContainerId,
      ignoreCancelEvents: true,
      duration: scrollAnim ? undefined : 0,
    });
    setTimeout(() => setScrollAnim(true), 1);
  }, [messagesGroups]);

  const renderMessage = (message, index) => {
    const userMessageText = message.message;
    let systemMessageText;
    switch (message.type) {
    case MessageType.ParticipantConnected:
      systemMessageText = t("webinars.chat.userConnected");
      break;
    case MessageType.ParticipantDisconnected:
      systemMessageText = t("webinars.chat.userDisconnected");
      break;
    default:
      systemMessageText = "";
      break;
    }
    const messageText = userMessageText || systemMessageText;
    const fileArray = message.file?.split("/");
    const fileName = fileArray?.length ? decodeURI(fileArray[fileArray.length - 1]) : "";

    if (!messageText && !message.file) return null;

    return (
      <React.Fragment key={index}>
        {!!messageText.length && (
          <MessageText systemMsg={message.fromSystem}>
            {renderWithLinks(messageText)}
          </MessageText>
        )}
        {!!message.file && (
          <FileBox movedLower={!classesMode}>
            <FileText>
              <FileName>{fileName}</FileName>
            </FileText>
            <DownloadLink
              href={message.file}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DownloadIcon />
            </DownloadLink>
          </FileBox>
        )}
      </React.Fragment>
    );
  };

  const renderMessages = renderWhenTrue(() => (
    messagesGroups.map((messagesGroup, index) => {
      const firstMessage = messagesGroup[0];
      const username = `${firstMessage.firstName} ${firstMessage.lastName}`;
      const time = dayjs.unix(firstMessage.timestamp).format("HH:mm");

      return (
        <MessageWrapper key={index}>
          <Message>
            <Avatar
              src={firstMessage.avatar}
              name={username}
              type={AvatarType.Small}
              dark={firstMessage.isCurrentUser && !firstMessage.fromSystem}
            />
            <TextWrapper>
              <NameLine>
                <Name>{username}</Name>
                <Dot />
                <Time>{time}</Time>
              </NameLine>
              {classesMode && !firstMessage.fromSystem && (
                <ReceiverLine>
                  {t("webinars.chat.messageTo")}
                  {" "}
                  <Receiver>
                    {firstMessage.recipient?.name || t("webinars.chat.all")}
                  </Receiver>
                </ReceiverLine>
              )}
              {messagesGroup.map((message, messageIndex) => renderMessage(message, `${index}_${messageIndex}`))}
            </TextWrapper>
          </Message>
        </MessageWrapper>
      );
    })
  ));

  return (
    <Container
      className={className}
      id={ContainerId}
      fullScreen={fullScreen}
      cameraOn={cameraOn}
      ref={messagesContainerRef}
      classesMode={classesMode}
      liveEnded={liveEnded}
    >
      {renderMessages(!!messagesGroups.length)}
      <div ref={messagesBottomRef} />
    </Container>
  );
};

Conversation.propTypes = {
  className: PropTypes.string,
  fullScreen: PropTypes.bool,
  cameraOn: PropTypes.bool,
  messages: PropTypes.array,
  liveEnded: PropTypes.bool,
};
