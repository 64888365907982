export const StreamType = {
  Camera: "camera",
  Screen: "screen",
  Audio: "audio",
};

export const StreamDirection = {
  In: "in",
  Out: "out",
};

export const DeviceKind = {
  Video: "videoinput",
  Audio: "audioinput",
};

export const VideoQuality = {
  Width: 1280,
  Height: 1280,
};

export const ScreenVideoQuality = {
  Width: 1440,
  Height: 1440,
};
