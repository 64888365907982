import styled from "styled-components";

import { Button as ButtonBase } from "../../../../components/button/button.component";
import { hexToRgba } from "../../../../helpers/colors";
import { Color, ContentMaxWidth, Media } from "../../../../theme";

export const Container = styled.div`
  padding: 40px 20px;
  background-color: ${Color.almostWhite};
  box-shadow: 0 5px 10px ${hexToRgba(Color.deepGray, 0.01)};

  ${Media.tablet`
    padding: 40px 80px;
  `}

  ${Media.desktop`
    padding: 40px 160px;
  `}
`;

export const AgreementsContainer = styled.div`
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
  background-color: ${Color.white};
  padding: 35px;

  ${Media.tablet`
    padding: 60px 100px 100px;
  `}

  ${Media.tabletWide`
    padding: 60px 120px 100px;
  `}

  ${Media.desktop`
    padding: 60px 180px 100px;
  `}
`;

export const Title = styled.h1`
  font-weight: 800;
  font-size: 12px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  padding-bottom: 15px;
  margin-bottom: 20px;
  width: 100%;
  border-bottom: 1px solid ${hexToRgba(Color.gray5, 0.8)};

  &:not(:first-of-type) {
    margin-top: 60px;
  }
`;

export const Agreement = styled.div`
  margin-bottom: 30px;
`;

export const CheckboxesRow = styled.div`
  display: flex;
`;

export const Text = styled.p`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: ${Color.gray4};
  margin-bottom: 20px;

  a {
    color: ${Color.deepGray};
  }
`;

export const Button = styled(ButtonBase)`
  margin-top: 10px;
  width: 100%;

  ${Media.tablet`
    width: inherit;
  `}
`;
