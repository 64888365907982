import styled from "styled-components";

import { OvalProgressChart } from "../../components/ovalProgressChart/ovalProgressChart.component";
import { Color } from "../../theme/colors";
import { Media } from "../../theme/media";
import { hexToRgba } from "../../helpers/colors";
import { ReactComponent as ArrowBase } from "../../images/arrow.svg";

export const Container = styled.div`
  width: 100%;
`;

export const ProgressChartsBox = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  min-height: 278px;
`;

export const ProgressChart = styled(OvalProgressChart)`
  box-shadow: 0 5px 10px 0 ${hexToRgba(Color.deepGray, 0.01)};
  margin-left: 2px;

  &:first-of-type {
    margin-left: 0;
  }
`;

export const Button = styled.button`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  background-color: ${Color.white};
  border-radius: 50%;
  border: 0;
  transform: translateY(-25px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  ${Media.tablet`
    &:hover {
      background-color: ${Color.almostWhite};
    }
  `}
`;

export const ArrowSvg = styled(ArrowBase)`
  height: 14px;
  fill: ${Color.gray1};
`;

export const PrevButton = styled(Button)`
  left: 10px;
`;

export const NextButton = styled(Button)`
  right: 10px;

  ${ArrowSvg} {
    transform: rotate(180deg);
  }
`;
