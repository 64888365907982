import React, { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ButtonColor } from "../../../../components/button/button.constants";
import { PageDataContext } from "../page/page.context";

import {
  Container,
  SlidesCounter,
  CurrentSlide,
  HeaderText,
  Separator,
  Question,
  HelperText,
  Answers,
  Answer,
  ButtonWrapper,
  ConfirmButton,
  Circle,
  CheckIcon,
  CloseIcon,
  ErrorMessage,
} from "./chooseAnswer.styled";

export const ChooseAnswer = ({ callComplete }) => {
  const { t } = useTranslation();
  const {
    title,
    helperText,
    question,
    answers,
    multipleChoice,
    currentSlideCounter,
    slidesOverallCounter,
  } = useContext(PageDataContext);
  const [answersTouched, setAnswersTouched] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState(false);
  const [taskFinished, setTaskFinished] = useState(false);
  const [answersChosen, setAnswersChosen] = useState([]);
  const [areAnswersConfirmed, setAreAnswersConfirmed] = useState(false);
  const answeredCorrectly = useMemo(() => {
    return answers.reduce((correct, answer) => {
      if (!correct) return false;

      return (answer.correct && answersChosen.includes(answer.id))
        || (!answer.correct && !answersChosen.includes(answer.id));
    }, true);
  }, [answers, answersChosen]);

  const handleAnswerChoice = (answerId) => {
    setAreAnswersConfirmed(false);

    if (answersChosen.find((answer) => answer === answerId)) {
      const newAnswers = answersChosen.filter((answer) => answer !== answerId);
      setAnswersChosen(newAnswers);

      if (!newAnswers.length) {
        setAnswersTouched(false);
      }
    } else {
      const newAnswers = multipleChoice ? [...answersChosen, answerId] : [answerId];
      setAnswersChosen(newAnswers);
      setAnswersTouched(true);
    }
  };

  const confirmAnswers = () => {
    setAreAnswersConfirmed(true);

    if (!answeredCorrectly) {
      setIsErrorMessage(true);
      return;
    }

    setIsErrorMessage(false);
    setTaskFinished(true);
    callComplete();
  };

  return (
    <Container>
      <SlidesCounter>
        <CurrentSlide>{currentSlideCounter}</CurrentSlide>
        {` (${slidesOverallCounter})`}
      </SlidesCounter>
      <HeaderText>{title}</HeaderText>
      <Separator />
      <Question>{question}</Question>
      <HelperText>{helperText}</HelperText>
      <Answers>
        {answers.map((answer) => {
          const answerChecked = answersChosen.includes(answer.id);
          const answerCorrect = areAnswersConfirmed && answerChecked && answer.correct;
          const answerWrong = areAnswersConfirmed && answerChecked && !answer.correct;
          return (
            <Answer
              key={answer.id}
              onClick={() => handleAnswerChoice(answer.id)}
              isChecked={answerChecked}
              isCorrect={answerCorrect}
              isWrong={answerWrong}
              disabled={taskFinished}
            >
              <Circle
                isChecked={answerChecked}
                isCorrect={answerCorrect}
                isWrong={answerWrong}
              >
                {answerCorrect && <CheckIcon />}
                {answerWrong && <CloseIcon />}
              </Circle>
              {answer.text}
            </Answer>
          );
        })}
      </Answers>
      <ButtonWrapper>
        <ConfirmButton
          onClick={confirmAnswers}
          $large
          color={ButtonColor.Primary}
          disabled={!answersTouched || taskFinished}
        >
          {t("scrollLesson.chooseAnswer.confirm")}
        </ConfirmButton>
      </ButtonWrapper>
      {isErrorMessage && <ErrorMessage>{t("scrollLesson.finishBox.error")}</ErrorMessage>}
    </Container>
  );
};

ChooseAnswer.propTypes = {
  callComplete: PropTypes.func,
};
