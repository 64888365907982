import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { cond, always, T, equals } from "ramda";
import { useParams } from "react-router";

import { Theme } from "../../../../appConfig";
import { ChartColorType } from "../../../../components/simpleProgressChart/simpleProgressChart.constants";
import { Loader } from "../../../../components/loader/loader.component";
import { ProgressMark } from "../../../../components/progressMark/progressMark.component";
import { BackButton } from "../../../../components/backButton/backButton.component";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { CompetenceActions } from "../../redux/competence.reducer";
import { selectCompetenceOverview, selectIsPending } from "../../redux/competence.selectors";
import { CompetenceType } from "../competenceList/competenceList.constants";
import { Resources } from "../resources/resources.component";
import { StatisticsTab } from "../statisticsTab/statisticsTab.component";
import { CompetenceTab } from "../competenceTab/competenceTab.component";
import { MarkScaleTab } from "../markScaleTab/markScaleTab.component";

import { CompetenceTabURI, CompetenceTabName } from "./competenceDetails.constants";
import {
  Container,
  HeaderWrapper,
  BackButtonWrapper,
  Header,
  TextWrapper,
  Title,
  Level,
  StrategicIconWrapper,
  StrategicIconText,
  StrategicIcon,
  Tabs,
  Tab,
  TabText,
} from "./competenceDetails.styled";

export const CompetenceDetails = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const competenceOverview = useSelector(selectCompetenceOverview);
  const isPending = useSelector(selectIsPending);
  const rate = competenceOverview && competenceOverview.rate;
  const competence = competenceOverview && competenceOverview.competence;
  const isStrategic = competence && competence.type.enum === CompetenceType.Strategic;
  const activeTab = params.tab;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(CompetenceActions.getCompetenceOverview(params.id));
  }, [dispatch, params.id]);

  const renderLevelText = renderWhenTrue(() => {
    if (rate) {
      return `${t("competence.level")} ${t(rate.name)}`;
    }
    return t("competence.ratingInProgress");
  });

  const renderProgressMark = () => {
    if (!rate) return <ProgressMark large />;
    return (
      <ProgressMark
        rate={rate.rate}
        maxRate={rate.maxRate}
        large
        variant={ChartColorType.Gold}
      />
    );
  };

  const renderStrategicIcon = renderWhenTrue(() => (
    <StrategicIconWrapper>
      <StrategicIconText>{t(`competence.details.${Theme.CompetenceType}`)}</StrategicIconText>
      <StrategicIcon />
    </StrategicIconWrapper>
  ));

  if (!competenceOverview) {
    return null;
  }

  const renderTabLink = (tab) => {
    const currentTab = activeTab === tab;

    return (
      <Tab to={`/competence/${params.id}/${tab}`} disabled={currentTab}>
        <TabText active={currentTab}>
          {t(`competence.details.${CompetenceTabName[tab]}`)}
        </TabText>
      </Tab>
    );
  };

  const renderTabContent = cond([
    [equals(CompetenceTabURI.Resources), always(<Resources competenceId={params.id} />)],
    [equals(CompetenceTabURI.Statistics), always(<StatisticsTab competenceId={competence.id} />)],
    [equals(CompetenceTabURI.MarkScale), always(<MarkScaleTab competenceId={competence.id} />)],
    [equals(CompetenceTabURI.Competence), always(<CompetenceTab competenceId={competence.id} />)],
    [T, always(null)],
  ]);

  const renderLoader = renderWhenTrue(() => <Loader />);

  const renderContent = renderWhenTrue(() => (
    <>
      <HeaderWrapper>
        <BackButtonWrapper>
          <BackButton defaultPath="/competence" />
        </BackButtonWrapper>
        <Header>
          {renderProgressMark()}
          <TextWrapper>
            <Title>{competence.name}</Title>
            <Level>{renderLevelText(!!rate)}</Level>
          </TextWrapper>
          {renderStrategicIcon(isStrategic)}
        </Header>
        <Tabs>
          {renderTabLink(CompetenceTabURI.Competence)}
          {renderTabLink(CompetenceTabURI.Resources)}
          {renderTabLink(CompetenceTabURI.Statistics)}
          {renderTabLink(CompetenceTabURI.MarkScale)}
        </Tabs>
      </HeaderWrapper>
      {renderTabContent(activeTab)}
    </>
  ));

  return (
    <Container className={className}>
      {renderContent(!isPending)}
      {renderLoader(isPending)}
    </Container>
  );
};

CompetenceDetails.propTypes = {
  className: PropTypes.string,
};
