import { useCallback, useEffect, useState } from "react";

import {
  isSmallMobile,
  isMobile,
  isAdditionalMobile,
  isTablet,
  isDesktop,
  isDesktopWide,
  isTabletWide,
} from "../theme/media";

import { useWindowEvent } from "./useWindowEvent";

export const useMediaListener = () => {
  const [media, setMedia] = useState({});

  const handleResize = useCallback(() => {
    setMedia({
      isSmallMobile: isSmallMobile(),
      isMobile: isMobile(),
      isAdditionalMobile: isAdditionalMobile(),
      isTablet: isTablet(),
      isTabletWide: isTabletWide(),
      isDesktop: isDesktop(),
      isDesktopWide: isDesktopWide(),
    });
  }, []);

  useEffect(handleResize, [handleResize]);
  useWindowEvent("resize", handleResize);

  return media;
}
