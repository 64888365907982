import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { CookieName } from "../app/cookiePopup/cookiePopup.constants";
import { isCookieEnabled } from "../helpers/isCookieEnabled";
import { Theme } from "../appConfig";

import { LanguageTheme, ResourcesMultilingual, ResourcesPL } from "./i18n.constants";

const options = {
  order: ["localStorage"],
  lookupLocalStorage: "Lng"
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: Theme.Language === LanguageTheme.Multi ? ResourcesMultilingual : ResourcesPL,
    detection: isCookieEnabled(CookieName.Functional) ? options : { caches: [] },
    languages: ["pl", "en"],
    fallbackLng: "pl", // use pl if detected lng is not available
    interpolation: {
      escapeValue: false
    },
  });

export default i18n;
