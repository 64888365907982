import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../helpers/rendering";
import { Color, FontWeight, Media } from "../../theme";

export const SummaryWrapper = styled.div`
  ${styleWhenTrue(prop("wrapped"), css`
    max-width: 980px;
    margin: 0 auto;
    padding: 0;

    ${Media.tablet`
      padding: 0 20px;
    `}
  `)}
`;

export const QuizStatus = styled.h4`
  font-size: 1.6rem;
  font-weight: ${FontWeight.SemiBold};
  text-align: left;
  color: ${Color.error};
  margin: 50px 0;
`;

export const QuizSummary = styled.h1`
  font-size: 2.6rem;
  font-weight: bold;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: ${Color.black};
  margin: ${({ offsetTop }) => (offsetTop ? "30px" : "0")} 0 10px 0;
`;
