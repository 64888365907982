import React from "react";
import PropTypes from "prop-types";
import { equals, always, T, cond } from "ramda";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { ConfirmationModal } from "../../../../components/confirmationModal/confirmationModal.component";
import { ReactComponent as WarningIcon } from "../../../../images/warning2.svg";
import { ReactComponent as CheckIcon } from "../../../../images/check4.svg";
import { ReactComponent as ExitIcon } from "../../../../images/exit.svg";

import { CheckModalType } from "./checkModal.constants";
import {
  CheckModalContent,
  PopupHeader,
  BoldText,
  CourseList,
  CourseItem,
  ModalText,
  Bullet,
  RegulationsLink,
} from "./checkModal.styled";

export const CheckModal = ({
  modalType,
  eventName,
  eventDate,
  courses,
  showTrainingRegulation,
  blockingEvent,
  open,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();

  const getTitle = cond([
    [equals(CheckModalType.Error), always(t("trainings.checkPopup.eventEnrollment"))],
    [equals(CheckModalType.Success), always(t("trainings.checkPopup.eventEnrollment"))],
    [equals(CheckModalType.Disenroll), always(t("trainings.checkPopup.eventDisenroll"))],
    [T, always("")],
  ]);

  const getIcon = cond([
    [equals(CheckModalType.Error), always(WarningIcon)],
    [equals(CheckModalType.Success), always(CheckIcon)],
    [equals(CheckModalType.Disenroll), always(ExitIcon)],
    [T, always(null)],
  ]);

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      title={getTitle(modalType)}
      HeaderIcon={getIcon(modalType)}
      withoutDecline={modalType !== CheckModalType.Disenroll}
      confirmText={t(
        modalType === CheckModalType.Disenroll
          ? "disenroll.confirm"
          : "trainings.checkPopup.close"
      )}
      declineText={t("global.cancel")}
      onConfirm={modalType === CheckModalType.Disenroll ? onConfirm : undefined}
    >
      <CheckModalContent>
        {modalType === CheckModalType.BlockingEvent && (
          <>
            <PopupHeader>{t("trainings.checkPopup.error")}</PopupHeader>
            <ModalText>
              {t("trainings.checkPopup.blockingEventText")}
              {" "}
              <BoldText>{`${eventName} (${dayjs.unix(eventDate).format("DD.MM.YYYY")})`}</BoldText>
              {" "}
              {t("trainings.checkPopup.blockingEventText2")}
              {" "}
              <BoldText>
                {`${blockingEvent.name} (${dayjs.unix(blockingEvent.date).format("DD.MM.YYYY")})`}
              </BoldText>
              {t("trainings.checkPopup.blockingEventText3")}
            </ModalText>
          </>
        )}
        {modalType === CheckModalType.Error && (
          <>
            <PopupHeader>{t("trainings.checkPopup.error")}</PopupHeader>
            <ModalText>
              {t("trainings.checkPopup.missingCoursesText")}
              {" "}
              <BoldText>{eventName}</BoldText>
              {" "}
              {t("trainings.checkPopup.missingCoursesText2")}
            </ModalText>
          </>
        )}
        {modalType === CheckModalType.Success && (
          <>
            <ModalText>
              {t("trainings.checkPopup.nextCoursesText")}
              {" "}
              <BoldText>{eventName}</BoldText>
            </ModalText>
            <ModalText>
              {t("trainings.checkPopup.nextCoursesText2")}
            </ModalText>
          </>
        )}
        {modalType === CheckModalType.Disenroll && (
          <>
            <PopupHeader>{t("trainings.checkPopup.warning")}</PopupHeader>
            <ModalText>
              {t("trainings.checkPopup.toRemoveCoursesText")}
            </ModalText>
            <ModalText>
              <BoldText>{eventName}</BoldText>
            </ModalText>
            {!!courses?.length && (
              <ModalText>
                {t("trainings.checkPopup.toRemoveCoursesText2")}
              </ModalText>
            )}
          </>
        )}
        {!!courses?.length && (
          <CourseList>
            {courses?.map((item) => (
              <CourseItem key={item}>
                <Bullet />
                {item}
              </CourseItem>
            ))}
          </CourseList>
        )}
        {showTrainingRegulation && (
          <ModalText $withMargin>
            <BoldText>{t("trainings.checkPopup.importantText")}</BoldText>
            {" "}
            {t("trainings.checkPopup.importantText2")}
            {" "}
            <RegulationsLink to="/regulamin-szkolen" target="_blank" rel="noopener noreferrer">
              {t("trainings.checkPopup.importantText3")}
            </RegulationsLink>
            {t("trainings.checkPopup.importantText4")}
          </ModalText>
        )}
      </CheckModalContent>
    </ConfirmationModal>
  );
};

CheckModal.propTypes = {
  modalType: PropTypes.string,
  eventName: PropTypes.string,
  eventDate: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  courses: PropTypes.array,
  onConfirm: PropTypes.func,
  blockingEvent: PropTypes.object,
};
