import styled from "styled-components";

import { Media } from "../../../../theme/media";

export const Container = styled.div``;

export const Events = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${Media.tablet`
    flex-direction: row;
    min-height: 250px;
  `}
`;
