export const MessageType = {
  WebsocketMessage: "WS_MESSAGE",
  IceCandidate: "webinar.iceCandidate",
  StreamConnected: "webinar.stream.connected",
  StreamDisconnected: "webinar.stream.disconnected",
  StopStream: "webinar.stop.stream",
  ParticipantConnected: "webinar.participant.connected",
  ParticipantDisconnected: "webinar.participant.disconnected",
  RoomRedirecting: "webinar.room.redirecting",
  RoomUpdate: "webinar.room.update",
  RoomDisconnected: "webinar.room.disconnected",
  ToggleCamera: "webinar.room.toggleCamera",
  Authorized: "Authorized",
  SendChatMessage: "webinar.chat.message.send",
  ReceiveChatMessage: "webinar.chat.message.sended",
  ReceiveChatActivation: "webinar.chat.activated",
  PermissionRequest: "webinar.stream.create.request",
  PermissionResponse: "webinar.stream.create.response",
  PermissionsUpdate: "webinar.room.access.update",
  StartLive: "webinar.room.live.start",
  EndLive: "webinar.room.live.end",
  StartRecording: "webinar.record.start",
  PauseRecording: "webinar.record.pause",
  LiveStarted: "webinar.room.live.started",
  LiveEnded: "webinar.room.live.ended",
  UpdateStream: "webinar.stream.update",
  StreamUpdated: "webinar.stream.updated",
  LeaveRoom: "webinar.room.leave",
  GetRoomInfo: "webinar.room.info",
  GroupAssigned: "webinar.group.assigned",
  AcceptGroupAssign: "webinar.group.assigned.accept",
  RoomRedirect: "webinar.room.redirect",
  FlipchartCreated: "webinar.flipchart.created",
  FlipchartClosed: "webinar.flipchart.close",
  FlipchartDraw: "webinar.flipchart.draw",
  FlipchartState: "webinar.flipchart.save",
  StreamMuted: "webinar.stream.muted",
  TunnelConnected: "webinar.tunnel.connected",
  TunnelDisconnected: "webinar.tunnel.disconnected",
  Ping: "ping",
  Pong: "PONG",
};

export const RequestName = {
  ShareCamera: "shareCamera",
  ShareScreen: "shareScreen",
};

export const RoomType = {
  OneToOne: "one2one",
  PeerToPeer: "p2p",
  Meeting: "meeting",
  MeetingGroup: "meeting_group",
  Audience: "audience",
  Recorded: "recorded",
  Classes: "meeting",
};

export const RoomStatus = {
  InPreparation: "in_preparation",
  Available: "available",
  Started: "started",
  Canceled: "canceled",
  Ended: "ended",
};
