import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { TutorialStepPopup } from "../../components/tutorialStepPopup/tutorialStepPopup.component";
import { TutorialStep } from "../../components/tutorialPopup/tutorialPopup.constants";
import { TargetPosition } from "../../components/tutorialStepPopup/tutorialStepPopup.constants";
import { CommonActions } from "../../redux/common/common.reducer";
import {
  selectSearchPopupActive,
  selectTutorialCurrentStep,
  selectUnreadNotifications,
} from "../../redux/common/common.selectors";
import { selectUserBrands } from "../../userContext/redux/userContext.selectors";
import { UserRoleName } from "../../userContext/userContext.constants";
import { Color } from "../../theme/colors";
import { Icon, SmallAvatar } from "../../UIElements";
import { Logout } from "../../userContext";

import { UserMenu } from "./userMenu/userMenu.component";
import {
  RightNavContainer,
  RightNavIcon,
  NotificationIndicator,
  SearchButton,
  SearchIcon,
  TutorialWrapper,
} from "./rightNav.styled";

export const RightNav = ({ user }) => {
  const dispatch = useDispatch();
  const searchPopupActive = useSelector(selectSearchPopupActive);
  const tutorialCurrentStep = useSelector(selectTutorialCurrentStep);
  const unreadNotifications = useSelector(selectUnreadNotifications);
  const lessZIndex = tutorialCurrentStep === TutorialStep.Trainings
    || tutorialCurrentStep === TutorialStep.KnowledgeBase;
  const brands = useSelector(selectUserBrands);

  const toggleSearchPopup = () => {
    dispatch(CommonActions.setSearchPopupActive(!searchPopupActive));
  };

  return (
    <RightNavContainer $lessZIndex={lessZIndex}>
      {!user.loading && (
        <TutorialWrapper>
          <UserMenu user={user.data.user} multiBrands={brands.length > 1}>
            <RightNavIcon>
              <SmallAvatar $background="white" $border="dirtyWhite">
                {!!unreadNotifications && <NotificationIndicator />}
                {!!user.data.user.avatar && <img src={user.data.user.avatar} alt="avatar" />}
                {!user.data.user.avatar && user.data.user.name[0] + user.data.user.surname[0]}
              </SmallAvatar>
            </RightNavIcon>
          </UserMenu>
          {tutorialCurrentStep === TutorialStep.Profile && (
            <TutorialStepPopup
              topPositionInPx={100}
              rightPositionInPx={5}
              targetTopInPx={-97}
              targetRightInPx={20}
              arrowRightInPx={35}
              targetPosition={TargetPosition.Top}
            />
          )}
        </TutorialWrapper>
      )}
      {user.data.user.role.name !== UserRoleName.Onboarding && (
        <TutorialWrapper>
          <SearchButton onClick={toggleSearchPopup}>
            <SearchIcon active={searchPopupActive?.toString()} />
          </SearchButton>
          {tutorialCurrentStep === TutorialStep.Search && (
            <TutorialStepPopup
              topPositionInPx={100}
              rightPositionInPx={-18}
              targetTopInPx={-97}
              targetRightInPx={20}
              arrowRightInPx={35}
              targetPosition={TargetPosition.Top}
            />
          )}
        </TutorialWrapper>
      )}
      <RightNavIcon>
        <Logout>
          <Icon icon="logout" color={Color.almostBlack} size={17} />
        </Logout>
      </RightNavIcon>
    </RightNavContainer>
  );
}
