import * as Yup from "yup";

import i18n from "../../../../translations/i18n";

export const CommentSchema = Yup.object().shape({
  content: Yup.string()
    .stripMarkdown()
    .required(i18n.t("validationMessage.required"))
    .max(500, i18n.t("validationMessage.maxLength")),
});
