import styled from "styled-components";

import { Color } from "../../../../theme";

export const Container = styled.div`
  padding: 40px 25px;
`;

export const Header = styled.div`
  padding-bottom: 25px;
  border-bottom: 1px solid ${Color.gray9};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
