import styled, { css } from "styled-components";
import { propEq } from "ramda";

import { hexToRgba } from "../../../../helpers/colors";
import { Color, FontWeight, Media } from "../../../../theme";
import { styleWhenTrue } from "../../../../helpers/rendering";


export const DropdownToggler = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  height: 105px;
`;
export const Container = styled.div`
  cursor: pointer;
  padding: 0 25px;
  background-color: ${Color.white};

  ${Media.tablet`
    padding: 0 35px;
  `}

  &:last-of-type {
    padding-bottom: 35px;

    ${DropdownToggler} {
      ${styleWhenTrue(propEq(false, "hiddenBorder"), css`
        border-bottom: 1px solid ${hexToRgba(Color.gray5, 0.8)};
      `)}

      & + div {
        border-bottom: 1px solid ${hexToRgba(Color.gray5, 0.8)};
      }
    }
  }
`;

export const PanelLeftSide = styled.div``;

export const LevelName = styled.p`
  padding-top: 8px;
  font-weight: ${FontWeight.SemiBold};
  font-size: 15px;
  color: ${Color.deepGray};
`;
