import React, { Component } from "react";

class Expandable extends Component {
  constructor() {
    super();
    this.state = { open: false };
  }
  toggle() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const { children } = this.props;
    const { open } = this.state;
    const toggle = this.toggle.bind(this);
    const childrenWithProps = React.Children.map(children, child => React.cloneElement(child, { open, toggle }));
    return <>{childrenWithProps}</>;
  }
}

export { Expandable };
