import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { UserStatistics } from "../../../../components/userStatistics/userStatistics.component";
import { Loader } from "../../../../components/loader/loader.component";
import { selectIndividualsAvailable, selectSurveysAvailable } from "../../../../userContext/redux/userContext.selectors";
import { SectionHeader } from "../../../../theme";
import {
  selectIsInitialDataPending,
  selectStatistics,
  selectRanking,
  selectLessons,
  selectInProgressCertificatePaths,
  selectFinishedCertificatePaths,
  selectPathConfirmations,
  selectEvents,
  selectIndividuals,
  selectSurveys,
} from "../../redux/certificates.selectors";
import { CertificatesActions } from "../../redux/certificates.reducer";
import { UriType } from "../../certificates.constants";
import { ExpandableBox } from "../expandableBox/expandableBox.component";
import { FileItem } from "../fileItem/fileItem.component";
import { PathBox } from "../pathBox/pathBox.component";

import {
  Container,
  PageHeader,
  Wrapper,
  Statistics,
  Row,
  MainContent,
  UserRanking,
} from "./certificateList.styled";

export const CertificateList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPending = useSelector(selectIsInitialDataPending);
  const statistics = useSelector(selectStatistics);
  const ranking = useSelector(selectRanking);
  const lessons = useSelector(selectLessons);
  const inProgressPaths = useSelector(selectInProgressCertificatePaths);
  const finishedPaths = useSelector(selectFinishedCertificatePaths);
  const pathConfirmations = useSelector(selectPathConfirmations);
  const surveys = useSelector(selectSurveys);
  const events = useSelector(selectEvents);
  const individuals = useSelector(selectIndividuals);
  const surveysAvailable = useSelector(selectSurveysAvailable);
  const individualsAvailable = useSelector(selectIndividualsAvailable);

  useEffect(() => {
    dispatch(CertificatesActions.fetchStatistics());
    dispatch(CertificatesActions.fetchRanking());
    dispatch(CertificatesActions.fetchCertificatePaths());
    dispatch(CertificatesActions.fetchPathConfirmations());
    dispatch(CertificatesActions.fetchLessons());
    dispatch(CertificatesActions.fetchEvents());

    if (surveysAvailable) {
      dispatch(CertificatesActions.fetchSurveys());
    }

    if (individualsAvailable) {
      dispatch(CertificatesActions.fetchIndividuals());
    }
  }, []);

  const renderFilesToDownload = (items, header, uriType) => (
    <Wrapper>
      <ExpandableBox header={header}>
        {[...items].sort(
          (itemA, itemB) => itemB.completionTimestamp - itemA.completionTimestamp
        ).map((item) => (
          <FileItem
            key={`${item.completionTimestamp}${item.id}`}
            id={item.id}
            name={item.name}
            timestamp={item.completionTimestamp}
            uriType={uriType}
          />
        ))}
      </ExpandableBox>
    </Wrapper>
  );

  return (
    <Container>
      {isPending ? (
        <Loader />
      ) : (
        <>
          <Statistics>
            <PageHeader>{t("certificates.certificates.certificatesHeader")}</PageHeader>
            <Row>
              {!!statistics && (
                <UserStatistics
                  learningProgress={statistics.learningProgress}
                  awards={statistics.awards}
                  iddStats={statistics.iddStats}
                  statistics={statistics.statistics}
                />
              )}
              <UserRanking rankingPositions={ranking} />
            </Row>
          </Statistics>
          <MainContent>
            {(!!inProgressPaths?.length || !!finishedPaths?.length) && (
              <Wrapper>
                <SectionHeader>{t("certificates.certificates.paths")}</SectionHeader>
                {inProgressPaths?.map((item) => (
                  <PathBox
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    description={item.description}
                    expirationDate={item.validateTo}
                    statusId={item.status.id}
                  />
                ))}
                {finishedPaths?.map((item) => (
                  <PathBox
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    description={item.description}
                    expirationDate={item.validateTo}
                    statusId={item.status.id}
                  />
                ))}
              </Wrapper>
            )}
            {!!pathConfirmations?.length && renderFilesToDownload(
              pathConfirmations,
              t("certificates.certificates.certificatePathConfirmations"),
              UriType.Certificates
            )}
            {!!lessons?.length && renderFilesToDownload(
              lessons,
              t("certificates.certificates.lessonPass"),
              UriType.Content,
            )}
            {!!surveys?.length && renderFilesToDownload(
              surveys,
              t("certificates.certificates.testPass"),
              UriType.Survey,
            )}
            {!!events?.length && renderFilesToDownload(
              events,
              t("certificates.certificates.eventPass"),
              UriType.Event,
            )}
            {!!individuals?.length && renderFilesToDownload(
              individuals,
              t("certificates.certificates.individualEvents"),
              UriType.Individual,
            )}
          </MainContent>
        </>
      )}
    </Container>
  );
};
