import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";
import { cond, always, equals, T } from "ramda";

import { ButtonColor } from "../../components/button/button.constants";
import { RegulationsType } from "../../components/regulations/regulations.constants";
import { CommonActions } from "../../redux/common/common.reducer";
import { selectAgreementToConfirm, selectUserName } from "../../userContext/redux/userContext.selectors";

import {
  Container,
  Popup,
  Title,
  WelcomeText,
  TextDiv,
  Separator,
  Button,
  Checkbox,
} from "./regulationsModal.styled";

export const RegulationsModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const requiredAgreement = useSelector(selectAgreementToConfirm);
  const userName = useSelector(selectUserName);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const welcomeTextKey = cond([
    [equals(RegulationsType.Regulations), always("regulations.regulationsWelcome")],
    [equals(RegulationsType.PrivacyPolicy), always("regulations.privacyPolicyWelcome")],
    [equals(RegulationsType.InfoClause), always("regulations.infoClauseWelcome")],
    [equals(RegulationsType.TrainingRegulations), always("regulations.trainingRegulationsWelcome")],
    [T, always("")],
  ]);

  const titleKey = cond([
    [equals(RegulationsType.Regulations), always("regulations.regulationsUpdate")],
    [equals(RegulationsType.PrivacyPolicy), always("regulations.privacyPolicyUpdate")],
    [equals(RegulationsType.InfoClause), always("regulations.infoClauseUpdate")],
    [equals(RegulationsType.TrainingRegulations), always("regulations.trainingRegulationsUpdate")],
    [T, always("")],
  ]);

  const checkboxKey = cond([
    [equals(RegulationsType.Regulations), always("regulations.regulationsCheckbox")],
    [equals(RegulationsType.PrivacyPolicy), always("regulations.regulationsCheckbox")],
    [equals(RegulationsType.InfoClause), always("regulations.infoClauseCheckbox")],
    [equals(RegulationsType.TrainingRegulations), always("regulations.trainingRegulationsCheckbox")],
    [T, always("")],
  ]);

  const handleClick = () => {
    setButtonDisabled(true);
    dispatch(CommonActions.sendRegulationsConfirmation(requiredAgreement));
  };

  const handleCheckboxChange = ({ target }) => {
    setCheckboxChecked(target.checked);
  };

  return (
    <Container open>
      <Popup>
        <Title>{t(titleKey(requiredAgreement?.type))}</Title>
        <Separator />
        <WelcomeText>{t("regulations.hello")} {userName},</WelcomeText>
        <WelcomeText>{t(welcomeTextKey(requiredAgreement?.type))}</WelcomeText>
        {!!requiredAgreement?.changes && (
          <TextDiv dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(requiredAgreement.changes) }} />
        )}
        <Checkbox
          label={t(checkboxKey(requiredAgreement?.type))}
          onChange={handleCheckboxChange}
          withLinks
        />
        <Button
          color={ButtonColor.Primary}
          onClick={handleClick}
          $large
          disabled={!checkboxChecked || buttonDisabled}
        >
          {t("regulations.confirm")}
        </Button>
      </Popup>
    </Container>
  );
};
