import styled from "styled-components";

import { Switch as SwitchBase } from "../../../../components/switch/switch.component";
import { Color } from "../../../../theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 0;
  border-bottom: 1px solid ${Color.gray9};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const Switch = styled(SwitchBase)`
  .switchBase.checked + .track {
    background-color: ${Color.blue5};
  }
`;
