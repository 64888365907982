export const FontWeight = {
  Light: 300,
  Normal: 400,
  Medium: 500,
  SemiBold: 600,
  Bold: 700,
  Bolder: 800,
  ExtraBold: 900,
};

export const FontSize = {
  HeadlineBig: "33px",
  HeadlineSmall: "17px",
};
