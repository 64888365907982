/* eslint-disable react/no-string-refs */
import React, { Component } from "react";

import { ElearningButton } from "../common.styled";

import { Container } from "./changeOnHover.styled";

export default class ChangeOnHover extends Component {
  constructor() {
    super();
    this.state = { elements: [], step: 1 };
  }

  componentDidMount() {
    this.attempts = null;
    if (this.props.config) {
      fetch(this.props.baseUrl + this.props.config)
        .then(data => data.json())
        .then(data => this.setState({ ...data }));
    }
  }

  imageHtml(index, element, baseUrl, that) {
    const elementStyle = {
      left: element.pos.left,
      top: element.pos.top,
      position: element.pos.position
    };
    const delay = element.delay !== undefined ? element.delay + "s" : "0." + index * 2 + "s";
    const AnimationClass = "bg-img slide-img " + (element.className !== undefined ? element.className : "show1");
    const imageStyle = {
      animationDelay: delay
    };
    const src = baseUrl + element.content;
    const srcHover = baseUrl + element.contentHover;

    return (
      <div key={index} className="col js-element" type={"image"} style={elementStyle}>
        <img
          className={AnimationClass}
          src={src} style={imageStyle}
          onMouseOver={e => {if(window.innerWidth > 768) e.target.src=srcHover;}}
          onMouseOut={e => {if(window.innerWidth > 768) e.target.src=src;}}
          onClick={e => {
            if(window.innerWidth <= 768){
              if(that.state.step === 1){
                e.target.classList.add("hover");
                e.target.src=srcHover;
                that.setState({step: 2})
              }
              else{
                e.target.src=src;
                e.target.classList.remove("hover");
                that.setState({step: 1})
              }
            }
          }}
          alt="" />
      </div>
    );
  }

  textHtml(index, element) {
    const elementStyle = {
      left: element.pos.left,
      top: element.pos.top,
      position: element.pos.position
    };
    const HeadlineClass = "headline " + (element.className !== undefined ? element.className : "show1");
    const imageStyle = {
      animationDelay: "0." + index * 2 + "s"
    };
    return (
      <div key={index} className="js-element" type={"text"} style={elementStyle}>
        <p className={HeadlineClass} style={imageStyle}>
          {element.content}{" "}
        </p>
      </div>
    );
  }

  render() {
    let imageHtml = this.imageHtml;
    let textHtml = this.textHtml;

    const elements = this.state.elements.map((item, index) => {
      switch (item.type) {
      case "image":
        return imageHtml(index, item, this.props.baseUrl, this);
      case "text":
        return textHtml(index, item);
      default:
        return textHtml(index, item);
      }
    });

    const data = this.state.table_structure;
    const styleType = this.state.style_type || "classic-header";
    let table;
    if (data) {
      table = data.map((itemRow, index) => {
        const cell = itemRow.map((itemCell, index) => {
          let colSpan = itemCell.colspan !== undefined ? itemCell.colspan : "";
          let rowSpan = itemCell.rowspan !== undefined ? itemCell.rowspan : "";
          let mark = itemCell.mark !== undefined ? itemCell.mark : "";
          let texts = itemCell.texts !== undefined ? itemCell.texts : null;
          let paragraph = null;
          if (texts) {
            paragraph = texts.map((itemText, index) => {
              return <p key={index}>{itemText}</p>;
            });
          } else {
            paragraph = itemCell.text;
          }

          return (
            <td key={index} rowSpan={rowSpan} className={mark} colSpan={colSpan}>
              {paragraph}
            </td>
          );
        });
        return <tr key={index}>{cell}</tr>;
      });
    }
    return (
      <Container>
        <div className="wrapper table-wrap" ref="WrapNode">
          {elements}
          <div className="table-container-guarantee">
            <div className="table-wrap">
              <table className={styleType}>
                <tbody>{table}</tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="button-wrap">
          <ElearningButton onClick={this.props.callComplete}>
            Obejrzałem
          </ElearningButton>
        </div>
      </Container>
    );
  }
}
