import React from "react";
import styled from "styled-components";

import PartsWrapper from "./common/partsWrapper";
import BorderedList from "./common/borderedList";
import { colors, breakpoints, breakpointsArray } from "../../../UIGlobals";
import * as UI from "./timerSlidesUI";

const ColorHeader = styled.span`
  color: ${colors().maroon};
`;

const ImageReversed = styled.img`
  max-height: 550px;
  position: absolute;
  bottom: 0;
  @media (${breakpoints().maxM}) {
    max-height: 300px;
    position: relative;
    top: 50px;
  }
`;

const TextWithIcon = ({ data, bigIcon, reversed, textColumnType, parts, activePart }) => (
  <UI.Container reversed={reversed}>
    <UI.TextColumn reversed={reversed} textColumnType={textColumnType}>
      {data.showParts && <PartsWrapper parts={parts} activePart={activePart} />}
      {data.header && (
        <UI.Header>
          {data.receiver && <ColorHeader>{data.receiver} </ColorHeader>}
          {data.header}
          {data.subheader && <ColorHeader> {data.subheader}</ColorHeader>}
        </UI.Header>
      )}
      {data.italicParagraphs && data.italicParagraphs.map((paragraph, index) => (
        <UI.ItalicParagraph key={index}>{paragraph}</UI.ItalicParagraph>
      ))}
      {data.paragraphs && data.paragraphs.map((paragraph, index) => (
        <UI.Paragraph key={index}>{paragraph}</UI.Paragraph>
      ))}
      {data.elements && <BorderedList elements={data.elements} />}
      {data.counteredElements && <BorderedList elements={data.counteredElements} counter />}
      {data.textColumnIconUrl && <UI.TextColumnIcon src={data.iconBaseUrl + data.textColumnIconUrl} />}
      {data.bottomParagraphs && data.bottomParagraphs.map((paragraph, index) => (
        <UI.Paragraph bottom key={index}>{paragraph}</UI.Paragraph>
      ))}
    </UI.TextColumn>
    {data.iconUrl && window.innerWidth > breakpointsArray.m && (
      <UI.ImageColumn bgIcon={bigIcon}>
        {reversed ? (
          <ImageReversed src={data.iconBaseUrl + data.iconUrl} alt="icon" />
        ) : (
          <UI.Image src={data.iconBaseUrl + data.iconUrl} alt="icon" bigIcon={bigIcon} />
        )}
      </UI.ImageColumn>
    )}
  </UI.Container>
);

export default TextWithIcon;