import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";

import { Input } from "../../../../components/input/input.component";
import { ButtonColor, ButtonVariant } from "../../../../components/button/button.constants";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { CompetenceActions } from "../../redux/competence.reducer";

import { FormSchema } from "./suggestionForm.schema";
import {
  Container,
  Fields,
  Separator,
  Actions,
  Button,
  AddIcon,
  ModalText,
} from "./suggestionForm.styled";

export const SuggestionForm = ({
  className,
  open,
  onClose = Function.prototype }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [confirmationVisible, setConfirmationVisible] = useState(false);

  const initialValues = {
    title: "",
    notes: "",
  };

  const handleSubmitCallback = (actions) => {
    setConfirmationVisible(true);
    actions.setSubmitting(false);
    actions.resetForm();
  };

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    dispatch(CompetenceActions.sendSuggestion(
      values,
      () => handleSubmitCallback(actions),
    ));
  };

  const formik = useFormik({
    validationSchema: FormSchema,
    initialValues,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();

    setTimeout(() => {
      setConfirmationVisible(false);
    }, 500);
  };

  const renderForm = renderWhenTrue(() => (
    <form onSubmit={formik.handleSubmit}>
      <Fields>
        <Input
          name="title"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={formik.handleFocus}
          value={formik.values.title}
          error={formik.touched.title && formik.errors.title}
          placeholder={t("competence.suggestionForm.titlePlaceholder")}
          autofocus
        />
        <Input
          name="notes"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={formik.handleFocus}
          value={formik.values.notes}
          error={formik.touched.notes && formik.errors.notes}
          placeholder={t("competence.suggestionForm.notesPlaceholder")}
        />
      </Fields>
      <Separator $narrow />
      <Actions>
        <Button onClick={handleClose} type={ButtonVariant.Outlined}>
          {t("competence.suggestionForm.cancel")}
        </Button>
        <Button
          color={ButtonColor.Primary}
          disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          onClick={formik.handleSubmit}
        >
          {t("competence.suggestionForm.send")}
        </Button>
      </Actions>
    </form>
  ));

  const renderConfirmation = renderWhenTrue(() => (
    <ModalText>{t("competence.suggestionForm.sentCorrectly")}</ModalText>
  ));

  return (
    <Container
      className={className}
      title={t("competence.suggestionForm.suggestCompetence")}
      HeaderIcon={AddIcon}
      open={open}
      onClose={handleClose}
    >
      {renderForm(!confirmationVisible)}
      {renderConfirmation(confirmationVisible)}
    </Container>
  );
};

SuggestionForm.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
