import styled, { css } from "styled-components";
import { prop } from "ramda";

import { CommentList as CommentListBase } from "../components/commentList/commentList.component";
import { BackButton as BackButtonBase } from "../components/backButton/backButton.component";
import { hexToRgba } from "../helpers/colors";
import { styleWhenTrue } from "../helpers/rendering";
import { breakpoints } from "../UIGlobals";
import { Media, FontWeight, Color, sizes, Breakpoint } from "../theme";
import { FavouriteListButton as FavouriteListButtonBase } from "../components/thumbnail/thumbnail.styled";

export const CommentsWrapper = styled.div`
  max-width: 980px;
  margin: 0 -25px;
  padding: 0 0 70px;

  ${Media.tablet`
    padding: 0 20px 70px;
    margin: 0 auto;
  `}
`;

export const CommentCounter = styled.p`
  font-size: 17px;
  line-height: 20px;
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.black};
  margin: 70px 20px 32px;

  ${Media.tablet`
    margin: 70px 0 32px;
  `}
`;

export const CommentList = styled(CommentListBase)`
  ${Media.tablet`
    border: 1px solid ${Color.gray8};
    box-shadow: 0 5px 10px 0 ${hexToRgba(Color.deepGray, 0.01)};
  `}
`;

export const FavouriteListButton = styled(FavouriteListButtonBase)`
  margin-right: 30px;
`;

export const RelatedVideosWrapper = styled.div`
  padding-top: 70px;
`;

export const BackButton = styled(BackButtonBase)`
  ${styleWhenTrue(prop("isScrollContent"), css`
    ${Media.tablet`
      margin-left: 30px;
    `}
  `)}
`;

export const ButtonsWrapper = styled.div`
  position: relative;
  top: -30px;
  height: 50px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Media.tablet`
    height: 108px;
  `}

  @media (min-width: ${sizes[Breakpoint.TabletWide] + 1}px) {
    height: 78px;
    top: -15px;
  }

  ${Media.desktop`
    height: 108px;
    top: 0;
  `}
`;

export const TagListWrapper = styled.div`
  margin-bottom: 10px;
`;

export const Description = styled.p`
  margin-top: 25px;
  font-size: 14px;
  line-height: 22px;
  color: ${Color.deepGray};
  font-weight: ${FontWeight.Light};
  max-width: 646px;

  ${styleWhenTrue(prop("$quizStyle"), css`
    margin: 25px 20px 0;

    ${Media.tablet`
      margin: 25px 45px 0;
    `}

    @media (${breakpoints().minL}) {
      margin: 25px 0 0 25px;
    }
  `)}
`;

export const BoxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${Media.tablet`
    flex-direction: row;
  `}
`;

export const LessonInfo = styled.div`
  display: flex;
  flex-flow: row wrap;
  font-size: 1.8rem;
  border-bottom: ${({ $borderDisabled }) => ($borderDisabled ? "none" : `1px solid ${Color.dirtyWhite}`) };
  margin-bottom: 25px;
  padding: ${({ padding }) => (padding !== null ? padding : "35px 0")};
  margin: ${({ margin }) => (margin ? margin : "")};
  margin-top: 11px;
  align-items: center;
  height: ${({ height }) => height ? `${height}px` : "unset"};

  @media (${breakpoints().maxL}) {
    flex-flow: row nowrap;
    margin: ${({ margin }) => (margin ? margin : "0")};
    align-items: center;
    background-color: ${({ colorReverse }) => (colorReverse ? Color.white : Color.gray6)};
    color:  ${({ colorReverse }) => (colorReverse ? Color.gray6 : Color.white)};
    padding: ${({ padding }) => (padding ? padding : "5px 25px")};
    border: ${({ colorReverse }) => (colorReverse ? "1px solid #cfcaca" : "")};
    height: 100%;
  }
`;

export const Current = styled.span`
  padding-right: 10px;
  font-weight: 600;
`;

export const Total = styled.span`
  font-weight: 300;

  @media (${breakpoints().maxL}) {
    margin-right: 10px;
  }
`;

export const CurrentName = styled.span`
  color: ${Color.darkGray};
  font-weight: 600;
  padding-left: 40px;
  width: calc(100% - ${({ isVideo }) => isVideo ? "80px" : "115px"});

  @media (${breakpoints().maxL}) {
    color:  ${({ colorReverse }) => (colorReverse ? Color.darkGray : Color.white)};
    padding-left: 25px;
    margin-right: 10px;
  }
`;
