import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { renderWhenTrue } from "../../../../helpers/rendering";
import { ReactComponent as ArrowImg } from "../../../../images/arrow.svg";
import { SectionHeader } from "../../../../theme";
import { CompetenceActions } from "../../redux/competence.reducer";
import { selectUserCompetenceList } from "../../redux/competence.selectors";
import { CompetenceType } from "../competenceList/competenceList.constants";

import { CompetenceItem } from "./competenceItem/competenceItem.component";
import {
  CompetenceListWrapper,
  CompetenceListGrid,
  Section,
  PrevButton,
  NextButton,
} from "./profileCompetenceList.styled";

export const ProfileCompetenceList = () => {
  const dispatch = useDispatch();
  const competences = useSelector(selectUserCompetenceList);
  const strategicCompetences = competences.filter((item) => (
    item.competence.type.enum === CompetenceType.Strategic
  ));
  const commonCompetences = competences.filter((item) => (
    item.competence.type.enum === CompetenceType.Common
  ));
  const filteredCompetences = {
    [CompetenceType.Strategic]: strategicCompetences,
    [CompetenceType.Common]: commonCompetences,
  };
  const [activePage, setActivePage] = useState(0);

  useEffect(() => {
    dispatch(CompetenceActions.getUserCompetenceList());
  }, [dispatch]);

  const handlePrevClick = useCallback(() => {
    setActivePage(activePage - 1);
  }, [activePage]);

  const handleNextClick = useCallback(() => {
    setActivePage(activePage + 1);
  }, [activePage]);

  const renderPrevButton = renderWhenTrue(() => (
    <PrevButton onClick={handlePrevClick}>
      <ArrowImg />
    </PrevButton>
  ));

  const renderNextButton = renderWhenTrue(() => (
    <NextButton onClick={handleNextClick}>
      <ArrowImg />
    </NextButton>
  ));

  const renderCompetenceItem = ({ competence, rate }) => (
    <CompetenceItem
      key={competence.id}
      competence={competence}
      rate={rate}
    />
  );

  const renderList = (type) => {
    if (!filteredCompetences[type].length) return null;

    return (
      <Section>
        <SectionHeader>{filteredCompetences[type][0].competence.type.name}</SectionHeader>
        <CompetenceListWrapper>
          <CompetenceListGrid page={activePage}>
            {filteredCompetences[type].map(renderCompetenceItem)}
          </CompetenceListGrid>
          {renderPrevButton(!!activePage)}
          {renderNextButton(filteredCompetences[type].length - activePage * 3 > 3)}
        </CompetenceListWrapper>
      </Section>
    );
  };

  return (
    <>
      {renderList(CompetenceType.Strategic)}
      {renderList(CompetenceType.Common)}
    </>
  );
};

ProfileCompetenceList.propTypes = {
  competences: PropTypes.array,
  type: PropTypes.string,
};
