import dayjs from "dayjs";

export const timestampToWeekday = (timestamp) => {
  return dayjs.unix(timestamp).format("dddd");
};

export const timestampToDate = (timestamp) => {
  const currentTimestamp = dayjs();
  const unixDate = dayjs.unix(timestamp);

  if (currentTimestamp.diff(unixDate) > 0 && !currentTimestamp.isSame(unixDate, "day")) {
    return unixDate.format("DD MMMM YYYY");
  }

  if (currentTimestamp.diff(unixDate, "hour", true) >= 1.5) {
    return unixDate.format("HH:mm");
  }

  return dayjs().to(unixDate);
};

export const simpleDateFromTimestamp = (timestamp) => {
  return dayjs.unix(timestamp).format("DD.MM.YYYY");
};
