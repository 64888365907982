export const BgAnimPromise = {
  Bg1_1: import("../../animations/bg1-1.json"),
  Bg1_2: import("../../animations/bg1-2.json"),
  Bg1_3: import("../../animations/bg1-3.json"),
  Bg1_4: import("../../animations/bg1-4.json"),
  Bg2_1: import("../../animations/bg2-1.json"),
  Bg2_2: import("../../animations/bg2-2.json"),
  Bg2_3: import("../../animations/bg2-3.json"),
  Bg3_1: import("../../animations/bg3-1.json"),
  Bg3_2: import("../../animations/bg3-2.json"),
  Bg3_3: import("../../animations/bg3-3.json"),
  Bg3_4: import("../../animations/bg3-4.json"),
  Bg3_5: import("../../animations/bg3-5.json"),
  Bg4_1: import("../../animations/bg4-1.json"),
  Bg4_2: import("../../animations/bg4-2.json"),
  Bg4_3: import("../../animations/bg4-3.json"),
  Bg4_4: import("../../animations/bg4-4.json"),
  Bg4_5: import("../../animations/bg4-5.json"),
  Bg5_1: import("../../animations/bg5-1.json"),
  Bg5_2: import("../../animations/bg5-2.json"),
  Bg5_3: import("../../animations/bg5-3.json"),
};

export const LeasinGoBgAnimPromise = {
  Bg1_1: import("../../animations/bg1-1.json"),
  Bg1_2: import("../../animations/bg1-2.json"),
  Bg1_3: import("../../animations/bg1-3.json"),
  Bg1_4: import("../../animations/bg1-4.json"),
  Bg2_1: import("../../animations/bg2-1.json"),
  Bg2_2: import("../../animations/bg2-2.json"),
  Bg2_3: import("../../animations/bg2-3.json"),
  Bg3_1: import("../../animations/bg5-1.json"),
  Bg3_2: import("../../animations/bg5-2.json"),
  Bg3_3: import("../../animations/bg5-3.json"),
};

export const TextAnimPromise = {
  stage1: import("../../animations/text1.json"),
  stage2: import("../../animations/text2.json"),
  stage3: import("../../animations/text3.json"),
  stage4: import("../../animations/text4.json"),
  stage5: import("../../animations/text5.json"),
};

export const LeasinGoTextAnimPromise = {
  stage1: import("../../animations/text1.json"),
  stage2: import("../../animations/text2.json"),
  stage3: import("../../animations/text5.json"),
};

export const HeroAnimPromise = {
  GeorgeWalk: import("../../animations/georgeWalk.json"),
  GeorgeStatic: import("../../animations/georgeStand.json"),
  KateWalk: import("../../animations/kateWalk.json"),
  KateStatic: import("../../animations/kateStand.json"),
  DorisWalk: import("../../animations/dorisWalk.json"),
  DorisStatic: import("../../animations/dorisStand.json"),
  StevenWalk: import("../../animations/stevenWalk.json"),
  StevenStatic: import("../../animations/stevenStand.json"),
};

export const SmokAnimPromise = import("../../animations/smokGame.json");

export const OrdinaryMaxStageView = {
  stage1: 4,
  stage2: 3,
  stage3: 5,
  stage4: 5,
  stage5: 3,
};

export const LeasinGoMaxStageView = {
  stage1: 4,
  stage2: 3,
  stage3: 3,
};

export const TaskPointers = [
  [
    { top: 200, left: 240, view: 1 },
    { top: 330, left: 310, view: 1 },
    { top: 130, left: 570, view: 1 },
    { top: 320, left: 780, view: 1 },
    { top: 370, left: 780, view: 1 },
    { top: 210, left: 220, view: 2 },
    { top: 240, left: 460, view: 2 },
    { top: 80, left: 630, view: 2 },
    { top: 300, left: 640, view: 2 },
    { top: 400, left: 840, view: 2 },
    { top: 390, left: 180, view: 3 },
    { top: 120, left: 370, view: 3 },
    { top: 275, left: 400, view: 3 },
    { top: 410, left: 700, view: 3 },
    { top: 420, left: 790, view: 3 },
    { top: 350, left: 555, view: 4 },
  ],
  [
    { top: 200, left: 110, view: 1 },
    { top: 360, left: 120, view: 1 },
    { top: 220, left: 285, view: 1 },
    { top: 360, left: 460, view: 1 },
    { top: 440, left: 530, view: 1 },
    { top: 160, left: 630, view: 1 },
    { top: 360, left: 700, view: 1 },
    { top: 210, left: 50, view: 2 },
    { top: 200, left: 380, view: 2 },
    { top: 320, left: 380, view: 2 },
    { top: 450, left: 400, view: 2 },
    { top: 110, left: 540, view: 2 },
    { top: 140, left: 580, view: 2 },
    { top: 375, left: 600, view: 2 },
    { top: 320, left: 640, view: 2 },
    { top: 440, left: 680, view: 2 },
    { top: 70, left: 600, view: 3 },
  ],
  [
    { top: 380, left: 80, view: 1 },
    { top: 430, left: 120, view: 1 },
    { top: 440, left: 420, view: 1 },
    { top: 240, left: 460, view: 1 },
    { top: 110, left: 525, view: 1 },
    { top: 270, left: 580, view: 1 },
    { top: 210, left: 610, view: 1 },
    { top: 270, left: 670, view: 1 },
    { top: 360, left: 710, view: 1 },
    { top: 440, left: 770, view: 1 },
    { top: 410, left: 160, view: 2 },
    { top: 420, left: 250, view: 2 },
    { top: 200, left: 300, view: 2 },
    { top: 170, left: 380, view: 2 },
    { top: 370, left: 530, view: 2 },
    { top: 420, left: 590, view: 2 },
    { top: 410, left: 650, view: 2 },
    { top: 120, left: 670, view: 2 },
    { top: 330, left: 740, view: 2 },
    { top: 190, left: 770, view: 2 },
    { top: 420, left: 820, view: 2 },
    { top: 360, left: 110, view: 3 },
    { top: 410, left: 110, view: 3 },
    { top: 250, left: 260, view: 3 },
    { top: 300, left: 290, view: 3 },
    { top: 100, left: 360, view: 3 },
    { top: 250, left: 370, view: 3 },
    { top: 130, left: 540, view: 3 },
    { top: 230, left: 840, view: 3 },
    { top: 440, left: 110, view: 4 },
    { top: 260, left: 220, view: 4 },
    { top: 220, left: 280, view: 4 },
    { top: 410, left: 310, view: 4 },
    { top: 430, left: 400, view: 4 },
    { top: 330, left: 500, view: 4 },
    { top: 120, left: 550, view: 4 },
    { top: 380, left: 600, view: 4 },
    { top: 440, left: 600, view: 4 },
    { top: 240, left: 680, view: 4 },
    { top: 440, left: 760, view: 4 },
    { top: 440, left: 840, view: 4 },
    { top: 80, left: 570, view: 5 },
  ],
  [
    { top: 300, left: 60, view: 1 },
    { top: 230, left: 100, view: 1 },
    { top: 330, left: 230, view: 1 },
    { top: 270, left: 480, view: 1 },
    { top: 310, left: 560, view: 1 },
    { top: 110, left: 640, view: 1 },
    { top: 290, left: 720, view: 1 },
    { top: 390, left: 790, view: 1 },
    { top: 360, left: 40, view: 2 },
    { top: 300, left: 90, view: 2 },
    { top: 370, left: 120, view: 2 },
    { top: 240, left: 240, view: 2 },
    { top: 400, left: 310, view: 2 },
    { top: 170, left: 350, view: 2 },
    { top: 370, left: 590, view: 2 },
    { top: 80, left: 680, view: 2 },
    { top: 160, left: 680, view: 2 },
    { top: 300, left: 750, view: 2 },
    { top: 370, left: 750, view: 2 },
    { top: 360, left: 70, view: 3 },
    { top: 150, left: 160, view: 3 },
    { top: 420, left: 250, view: 3 },
    { top: 280, left: 550, view: 3 },
    { top: 350, left: 570, view: 3 },
    { top: 380, left: 650, view: 3 },
    { top: 120, left: 700, view: 3 },
    { top: 60, left: 775, view: 3 },
    { top: 300, left: 775, view: 3 },
    { top: 260, left: 120, view: 4 },
    { top: 350, left: 120, view: 4 },
    { top: 430, left: 120, view: 4 },
    { top: 360, left: 305, view: 4 },
    { top: 280, left: 645, view: 4 },
    { top: 390, left: 645, view: 4 },
    { top: 100, left: 655, view: 4 },
    { top: 430, left: 760, view: 4 },
    { top: 180, left: 850, view: 4 },
    { top: 400, left: 890, view: 4 },
    { top: 340, left: 540, view: 5 },
  ],
  [
    { top: 370, left: 100, view: 1 },
    { top: 150, left: 210, view: 1 },
    { top: 340, left: 250, view: 1 },
    { top: 445, left: 480, view: 1 },
    { top: 300, left: 550, view: 1 },
    { top: 390, left: 620, view: 1 },
    { top: 170, left: 670, view: 1 },
    { top: 280, left: 670, view: 1 },
    { top: 340, left: 760, view: 1 },
    { top: 400, left: 760, view: 1 },
    { top: 370, left: 100, view: 2 },
    { top: 200, left: 220, view: 2 },
    { top: 360, left: 270, view: 2 },
    { top: 400, left: 350, view: 2 },
    { top: 410, left: 590, view: 2 },
    { top: 300, left: 650, view: 2 },
    { top: 380, left: 680, view: 2 },
    { top: 150, left: 790, view: 2 },
    { top: 340, left: 820, view: 2 },
    { top: 250, left: 850, view: 2 },
    { top: 340, left: 590, view: 3 },
  ],
];
