import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";

import { renderWhenTrue } from "../../../../../helpers/rendering";
import { useMediaListener } from "../../../../../hooks/useMediaListener";
import { ButtonText } from "../../sequentialQuizInteractions.constants";
import {
  Header,
  LightHeader,
  Subheader,
} from "../../sequentialQuizInteractions.styled";
import { ArrowSelectBox } from "../arrowSelectBox/arrowSelectBox.component";
import { MatchToLabelBox } from "../matchToLabelBox/matchToLabelBox.component";
import { NextButton } from "../nextButton/nextButton.component";

import {
  Container,
  TableContainer,
  Column,
  Title,
  LabelBox,
  ButtonRow,
  HeaderContainer,
} from "./arrowQuiz.styled";

export const ArrowQuiz = ({
  question,
  subquestion,
  yAxis,
  columns,
  questionNumber,
  onNext,
}) => {
  const { t } = useTranslation();
  const [columnsCorrectness, setColumnsCorrectness] =
    useState(Array.from({ length: columns.length }, () => Array(yAxis.labels.length).fill(null)));
  const [enableValidating, setEnableValidating] = useState(false);
  const [buttonText, setButtonText] = useState(ButtonText.Accept);
  const { isMobile } = useMediaListener();

  const checkColumnsValidity = useCallback(() => {
    let isValid = true;
    columnsCorrectness.forEach((column) => {
      column.forEach((field) => {
        if (!field) isValid = false;
      });
    });
    return isValid;
  }, [columnsCorrectness]);

  useEffect(() => {
    if (enableValidating && checkColumnsValidity()) {
      onNext();
    }
  }, [enableValidating, checkColumnsValidity, onNext]);

  const isButtonDisabled = useMemo(() => columnsCorrectness.flat().some((value) => value === null) && isMobile,
    [columnsCorrectness, isMobile]);

  const handleButtonClick = () => {
    if (!enableValidating) {
      setEnableValidating(true);
      setButtonText(ButtonText.TryAgain);
    } else {
      setEnableValidating(false);
      setButtonText(ButtonText.Accept);
    }
  };

  const renderLabelsColumn = () => (
    <Column>
      <Title>{yAxis.title}</Title>
      {yAxis.labels.map(
        ({ text, id }) => (
          <LabelBox key={id}>{text}</LabelBox>
        )
      )}
    </Column>
  );

  const renderDataColumn = (title, values, columnIndex, grayTheme) => (
    <Column key={columnIndex}>
      <Title>{title}</Title>
      {[...Array(yAxis.labels.length)].map(
        (_, index) => (
          <ArrowSelectBox
            key={index}
            boxIndex={index}
            columnIndex={columnIndex}
            options={values}
            grayTheme={grayTheme}
            columnsCorrectness={columnsCorrectness}
            setColumnsCorrectness={setColumnsCorrectness}
            enableValidating={enableValidating}
          />
        )
      )}
    </Column>
  );

  const renderDataColumns = () => columns.map(
    ({ title, values }, index) => {
      const grayTheme = index % 2 !== 0;
      return renderDataColumn(title, values, index, grayTheme);
    }
  );

  const renderSubheader = renderWhenTrue(() => (
    <Subheader>{subquestion}</Subheader>
  ));

  const renderMobileView = renderWhenTrue(() =>
    yAxis.labels.map(({ text, id }) => (
      <MatchToLabelBox
        key={id}
        labelIndex={id}
        title={text}
        dropdowns={columns}
        enableValidating={enableValidating}
        columnsCorrectness={columnsCorrectness}
        setColumnsCorrectness={setColumnsCorrectness}
        category={yAxis.title}
      />
    ))
  );

  const renderDesktopView = renderWhenTrue(() => (
    <TableContainer>
      {renderLabelsColumn()}
      {renderDataColumns()}
    </TableContainer>
  ));

  return (
    <Container>
      <HeaderContainer>
        <LightHeader>{t("interactions.sequentialQuiz.task")} {questionNumber}</LightHeader>
        <Header>{question}</Header>
        {renderSubheader(!!subquestion && !isMobile)}
      </HeaderContainer>
      {renderMobileView(isMobile)}
      {renderDesktopView(!isMobile)}
      <ButtonRow>
        <NextButton
          onClick={handleButtonClick}
          disabled={isButtonDisabled}
          text={buttonText}
        />
      </ButtonRow>
    </Container>
  );
};
