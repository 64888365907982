import { createSelector } from "reselect";
import { prop } from "ramda";

export const selectFormDomain = prop("form");

export const selectIsLoading = createSelector(
  selectFormDomain, prop("isLoading"),
);

export const selectFormData = createSelector(
  selectFormDomain, prop("form"),
);

export const selectFormNav = createSelector(
  selectFormDomain, prop("nav"),
);
