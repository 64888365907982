import { createActions, createReducer } from "reduxsauce";

import { deepClone } from "./clone";

export const { Types: GlobalTypes, Creators: GlobalActions } = createActions(
  {
    setUI: ["param", "value"],
  },
  { prefix: "GLOBAL/" }
);

const INITIAL_STATE = {
  volume: 50,
  progress: 0,
  isPlayed: true,
  duration: 0,
  changedProgress: false
};

const setUI = (state, { param, value }) => {
  const ui = deepClone(state);
  ui[param] = value;
  return { ...ui };
};

export const reducer = createReducer(INITIAL_STATE, {
  [GlobalTypes.SET_UI]: setUI,
});
