import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { secondsToHours } from "../../../../helpers/time";
import { renderWhenTrueOtherwise } from "../../../../helpers/rendering";
import { Icon } from "../../../../components/icon/icon.component";
import { Tooltip } from "../../../../components/tooltip/tooltip.component";
import { ReactComponent as PlayIcon } from "../../../../images/play.svg";
import { ReactComponent as PauseIcon } from "../../images/pause.svg";
import { WebinarsActions } from "../../redux/webinars.reducer";
import {
  selectRecordingTime,
  selectRecordingPaused,
  selectParticipants,
} from "../../redux/webinars.selectors";
import { BroadcastType } from "../video/video.constants";

import {
  Container,
  RecordingIcon,
  Text,
  Button
} from "./recordingTimer.styled";

export const RecordingTimer = ({ fullScreen, className }) => {
  const recordingPaused = useSelector(selectRecordingPaused);
  const recordingTime = useSelector(selectRecordingTime);
  const participants = useSelector(selectParticipants);
  const presenter = participants.find((participant) => participant.role === BroadcastType.Presenter);
  const isCurrentUserPresenter = presenter && presenter.user.isCurrentUser;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const text = recordingPaused
    ? t("webinars.recordingTimer.paused")
    : t("webinars.recordingTimer.recording");

  const [recordingTimeCounter, setRecordingTimeCounter] = useState(recordingTime);

  useEffect(() => {
    setRecordingTimeCounter(recordingTime);
  }, [recordingTime]);

  useEffect(() => {
    const interval = !recordingPaused && setInterval(() => {
      setRecordingTimeCounter((value) => value + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [recordingPaused]);

  const handleIconClick = () => {
    if (!recordingPaused) {
      dispatch(WebinarsActions.handlePauseRecording());
    } else {
      dispatch(WebinarsActions.handleStartRecording());
    }
  };

  const renderIcon = renderWhenTrueOtherwise(
    () => <PauseIcon />,
    () => <PlayIcon />,
  );

  const RecordingButton = () => (
    <Tooltip title={t(recordingPaused ? "webinars.classes.continueRecording" : "webinars.classes.pause")}>
      <Button recording={!recordingPaused} onClick={handleIconClick}>
        <Icon>
          {renderIcon(!recordingPaused)}
        </Icon>
      </Button>
    </Tooltip>
  );

  const PresenterContent = () => (
    <>
      <RecordingIcon recording={!recordingPaused} />
      <Text>{text}</Text>
      {secondsToHours(recordingTimeCounter)}
      <RecordingButton />
    </>
  );

  const UserContent = () => (
    <>
      <Text isViewer>{t("webinars.recordingTimer.meetingBeingRecorded")}</Text>
      <RecordingIcon recording={!recordingPaused} isViewer/>
    </>
  );

  const renderContent = renderWhenTrueOtherwise(
    PresenterContent,
    UserContent
  )

  return (
    <Container
      recording={!recordingPaused}
      fullScreen={fullScreen}
      isViewer={!isCurrentUserPresenter}
      className={className}
    >
      {renderContent(isCurrentUserPresenter)}
    </Container>
  )
}
