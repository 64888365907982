import styled from "styled-components";
import { Form as BaseForm } from "formik";

import { DropdownInput as DropdownBase } from "../../../../components/dropdownInput/dropdownInput.component";
import { ReactComponent as AttachmentSvg } from "../../../../images/attachment.svg";
import { ReactComponent as CloseSvg } from "../../../../images/close2.svg";
import { Color, FontWeight } from "../../../../theme";

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Form = styled(BaseForm)`
  width: 100%;
`;

export const FieldWrapper = styled.div``;

export const AdditionalOptions = styled.div`
  display: flex;
  background-color: ${Color.whiteHover};
  padding: 9px 13px 9px 20px;
  border-top: 1px solid ${Color.gray8};
  align-items: center;
`;

export const Label = styled.p`
  font-weight: ${FontWeight.SemiBold};
  font-size: 12px;
  line-height: 16px;
  margin-right: 10px;
  flex-shrink: 0;
`;

export const AttachmentButton = styled.button`
  background-color: ${Color.transparent};
  border: none;
  margin-left: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${Color.almostWhite};
  }
`;

export const AttachmentIcon = styled(AttachmentSvg)`
  fill: ${Color.gray9};
  height: 16px;
  width: 16px;
`;

export const FileError = styled.div`
  padding: 10px 20px;
  background-color: ${Color.red2};
  color: ${Color.deepGray};
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FileProgress = styled(FileError)`
  background-color: ${Color.veryLightGreen};
`;

export const DropdownInput = styled(DropdownBase)`
  margin-bottom: 0;
  width: 183px;
`;

export const CloseIcon = styled(CloseSvg)`
  width: 12px;
  height: 12px;
  fill: ${Color.gray9};
`;

export const CloseButton = styled.button`
  border: none;
  background-color: ${Color.transparent};
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    ${CloseIcon} {
      fill: ${Color.deepGray};
    }
  }
`;

export const KeyEventWrapper = styled.div``;
