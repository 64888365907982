import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { OnboardingMode } from "../../../../app/app.constants";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { selectOnboardingMode } from "../../redux/onboarding.selectors";

import {
  Container,
  ContentWrapper,
  LeftColumn,
  RightColumn,
  Title,
  Text,
  ContactButton,
} from "./actionSection.styled";

export const ActionSection = ({ onAction }) => {
  const { t } = useTranslation();
  const mode = useSelector(selectOnboardingMode);

  const renderContactButton = renderWhenTrue(() => (
    <ContactButton onClick={onAction}>
      {t("onboarding.action.contact")}
    </ContactButton>
  ));

  return (
    <Container>
      <ContentWrapper>
        <LeftColumn>
          <Title>{t("onboarding.action.prepareVideo")}</Title>
        </LeftColumn>
        <RightColumn>
          <Text>{t("onboarding.action.default")}</Text>
          {renderContactButton(mode === OnboardingMode.HR)}
        </RightColumn>
      </ContentWrapper>
    </Container>
  );
};
