import styled from "styled-components";

import { Color, Media } from "./";

export const ContentMinWidth = "960px";
export const ContentMaxWidth = "1200px";

export const Container = styled.div`
  padding: 40px 0;
  background-color: ${Color.almostWhite};

  ${Media.tablet`
    padding: 60px 80px 100px;
  `}

  ${Media.desktop`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 160px 100px;

    & > * {
      width: 100%;
      max-width: ${ContentMaxWidth};
    }
  `}
`;

export const ContentWrapper = styled.div`
  padding: 30px 0;
  min-height: calc(100vh - 70px - 299px);
  background: ${Color.dirtyWhite};
  padding: 30px 0px;
  max-width: 100%;
  overflow-x: hidden;

  ${Media.tabletWide`
    min-height: calc(100vh - 70px - 339px);
  `}

  ${Media.desktop`
    min-height: calc(100vh - 70px - 376px);
  `}
`;

export const ContentInner = styled.div`
  width: 100%;
  min-width: 100%;
  padding: 0 25px;

  ${Media.desktop`
    min-width: ${ContentMinWidth};
    width: ${ContentMaxWidth};
    max-width: 80%;
    padding: 0;
    margin: 0 auto;
  `}
`;
