export const convertParams = (params) => (
  params.reduce((obj, val) => ({ ...obj, [val.name]: val.value }), {})
);

export const findNextSlide = (newPages, newQuizes, pageId) => {
  const pageIndex = newPages.findIndex((page) => pageId === page.id);
  const flag = newPages.find((page) => !page.completed)
    ? pageIndex >= 0 && !newPages[pageIndex].seen
      ? "seen"
      : "completed"
    : "seen";

  const nextParent = flag === "completed"
    ? (pageId ? newPages[pageIndex + 1] : newPages.find((page) => !page.completed))
    : pageIndex >= 0
      ? newPages.length !== pageIndex + 1
        ? newPages[pageIndex].seen
          ? newPages[pageIndex + 1]
          : newPages[pageIndex]
        : newPages[pageIndex].seen
          ? newQuizes.length > 0
            ? newQuizes[0]
            : newPages[pageIndex]
          : newPages[pageIndex]
      : newQuizes.length === 0
        ? newPages[0]
        : newQuizes[0];

  const nextChild = nextParent.children.find((child) => !child[flag]) || nextParent.children[0];
  const nextInteraction = nextChild?.children.find((interaction) => !interaction[flag]) || nextChild?.children[0];

  return {
    currentType: nextParent.type === "page" ? "slide" : "quiz",
    currentParent: nextParent.id,
    currentChild: nextChild?.id,
    currentInteraction: nextInteraction?.id,
  };
};

export const verifyInteractionParent = (pages, id) => {
  let isSlideCompleted = false;

  pages.forEach((page) => {
    page.children.forEach((slide) => {
      slide.children.forEach((interaction) => {
        if (interaction.id === id) {
          interaction.completed = true;

          isSlideCompleted = slide.children.find((interaction) => !interaction.completed)
            ? false
            : slide.params.action;
        }
      });
    });
  });

  return isSlideCompleted;
};

export const findQuestionAnswers = (quiz, id) => {
  const question = quiz.children.find((question) => question.id === id);

  return question?.children.find((answer) => answer.selected)
    ? question.children.filter((answer) => answer.selected).map((answer) => answer.id)
    : null;
};
