import { prop } from "ramda";
import styled, { css } from "styled-components";

import { Button as ButtonBase } from "../../../../../components/button/button.component";
import { styleWhenTrue } from "../../../../../helpers/rendering";
import { Color, FontWeight } from "../../../../../theme";

export const CheckboxWrapper = styled.div`
  ${styleWhenTrue(prop("checked"), css`
    pointer-events: none;
  `)}
  
  &:not(:first-of-type) {
    margin-left: 20px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid ${Color.gray8};
  padding: 30px;
`;

export const Button = styled(ButtonBase)`
  &:first-of-type {
    margin-right: 10px;
  }
`;

export const WarningWrapper = styled.div`
  padding: 60px 20px;
  max-width: 450px;
  margin: 0 auto;
  text-align: center;
`;

export const ModalTitle = styled.h1`
  margin-bottom: 10px;
  color: ${Color.deepGray};
  font-size: 18px;
  font-weight: ${FontWeight.Bold};
`;

export const ModalText = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${Color.gray9};
`;
