import { all, put, takeLatest } from "redux-saga/effects";

import * as knowledgeBaseApi from "../services/api";

import { KnowledgeBaseTypes, KnowledgeBaseActions } from "./knowledgeBase.reducer";

function* getThumbnailListsAndTags() {
  yield put(KnowledgeBaseActions.setIsPending(true));
  const { data } = yield knowledgeBaseApi.getThumbnailListsAndTags();

  if (data) {
    yield put(KnowledgeBaseActions.getThumbnailListsSuccess(data.elements));
    yield put(KnowledgeBaseActions.getTagsSuccess(data.tags));
  }
  yield put(KnowledgeBaseActions.setIsPending(false));
}

function* getTagFilters() {
  const { data } = yield knowledgeBaseApi.getTagFilters();
  yield put(KnowledgeBaseActions.getTagFiltersSuccess(data));
}

function* getCompetenceItems() {
  const { data } = yield knowledgeBaseApi.getCompetenceItems();
  yield put(KnowledgeBaseActions.getCompetenceItemsSuccess(data));
}

export function* watchKnowledgeBase() {
  yield all([
    yield takeLatest(KnowledgeBaseTypes.GET_THUMBNAIL_LISTS_AND_TAGS, getThumbnailListsAndTags),
    yield takeLatest(KnowledgeBaseTypes.GET_COMPETENCE_ITEMS, getCompetenceItems),
    yield takeLatest(KnowledgeBaseTypes.GET_TAG_FILTERS, getTagFilters),
  ]);
}
