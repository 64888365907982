import styled from "styled-components";

import { ReactComponent as CheckSvg } from "../../../../images/check.svg";
import { FontWeight } from "../../../../theme";

export const Container = styled.div`
  padding: 42px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const TextBox = styled.div`
  font-size: 18px;
  line-height: 1.1;
  font-weight: ${FontWeight.SemiBold};
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const CheckIcon = styled(CheckSvg)`
  width: 50px;
  height: 50px;
`;
