import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  CloseIcon,
  CloseButton,
  TooltipHeader,
  TooltipText,
  TooltipTitle,
  Container,
} from "./populationTooltip.styled";

export const PopulationTooltip = ({ handleClose }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <TooltipHeader>
        <TooltipTitle>{t("teamIndicators.indicatorsTab.competencePopulation")}</TooltipTitle>
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
      </TooltipHeader>
      <TooltipText>
        {t("teamIndicators.indicatorsTab.haveConfirmation")}
      </TooltipText>
      <TooltipText>
        {t("teamIndicators.indicatorsTab.addCertificates")}
      </TooltipText>
    </Container>
  );
};

PopulationTooltip.propTypes = {
  handleClose: PropTypes.func,
};
