import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { selectPasswordReminderDaysLeft } from "../../userContext/redux/userContext.selectors";
import { Button } from "../../components/button/button.component";
import { ButtonColor, ButtonVariant } from "../../components/button/button.constants";
import { UserContextActions } from "../../userContext/redux/userContext.reducer";

import {
  Container,
  Popup,
  Header,
  HeaderIcon,
  HeaderText,
  Content,
  Title,
  Text,
  TextBold,
  Buttons,
} from "../changePasswordModal/changePasswordModal.styled";

export const PasswordReminder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const daysLeft = useSelector(selectPasswordReminderDaysLeft);
  const [isOpen, setIsOpen] = useState(true);

  const handleCancel = () => {
    setIsOpen(false);
    dispatch(UserContextActions.turnOffRemindPasswordModal());
  };

  const handleClick = () => {
    navigate("/profil/pass");
    setIsOpen(false);
    dispatch(UserContextActions.turnOffRemindPasswordModal());
  };

  return (
    <Container open={isOpen}>
      <Popup>
        <Header>
          <HeaderIcon />
          <HeaderText>
            {t("app.passwordReminder.passwordUpdate")}
          </HeaderText>
        </Header>
        <Content>
          <Title>{t("app.passwordReminder.title")}</Title>
          <Text>
            {t("app.passwordReminder.text1")}
            <TextBold>{` ${daysLeft} ${t("app.passwordReminder.text2")} `}</TextBold>
            {t("app.passwordReminder.text3")}
          </Text>
        </Content>
        <Buttons>
          <Button
            color={ButtonColor.Primary}
            onClick={handleClick}
          >
            {t("app.passwordReminder.goToProfile")}
          </Button>
          <Button
            variant={ButtonVariant.Outlined}
            onClick={handleCancel}
          >
            {t("app.passwordReminder.remindLater")}
          </Button>
        </Buttons>
      </Popup>
    </Container>
  );
};
