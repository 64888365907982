import { createSelector } from "reselect";
import { prop } from "ramda";

export const selectNotificationsDomain = prop("notifications");

export const selectIsPending = createSelector(
  selectNotificationsDomain, prop("isPending"),
);

export const selectNotifications = createSelector(
  selectNotificationsDomain, prop("notifications"),
);

export const selectNextPage = createSelector(
  selectNotificationsDomain, prop("nextPage"),
);
