// DEPRECATED

import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

import {
  BottomCircularProgress,
  TopCircularProgress,
  StyledTypography,
} from "./circularProgressWithLabel.styled";

export const CircularProgressWithLabel = (props) => (
  <Box position="relative" display="inline-flex">
    <BottomCircularProgress
      variant="determinate"
      size={50}
      thickness={1.5}
      {...props}
      value={100}
    />
    <TopCircularProgress
      variant="static"
      size={50}
      thickness={1.5}
      {...props}
    />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <StyledTypography variant="caption" component="div" color="textSecondary">
        {`${Math.round(props.value)}%`}
      </StyledTypography>
    </Box>
  </Box>
);

CircularProgressWithLabel.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number.isRequired,
};
