import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { renderWhenTrue } from "../../../../helpers/rendering";

import { AnswerType } from "./surveyQuestion.constants";
import {
  Container,
  Question,
  Counter,
  QuestionText,
  Answers,
  Answer,
  AnswerCircle,
  AnswerText,
  SelectIcon,
  CommentField,
} from "./surveyQuestion.styled";

export const SurveyQuestion = ({
  className,
  question,
  questionId,
  answers,
  counter,
  onChange,
  value,
}) => {
  const { t } = useTranslation();

  const handleChangeAnswer = (answer) => {
    onChange(questionId, answer);
  };

  const handleChangeComment = ({ target }, answerId) => {
    const answer = {
      type: AnswerType.Comment,
      value: target.value,
    };
    onChange(answerId, answer);
  };

  const renderSelectedIcon = renderWhenTrue(() => <SelectIcon />);

  const renderSelectAnswer = (answer) => {
    const selected = value === answer.id;

    return (
      <Answer
        key={answer.id}
        onClick={() => handleChangeAnswer(answer)}
        selected={selected}
        unactive={value !== undefined}
      >
        <AnswerCircle selected={selected}>
          {renderSelectedIcon(selected)}
        </AnswerCircle>
        <AnswerText>{answer.value}</AnswerText>
      </Answer>
    );
  };

  const renderCommentAnswer = (answer) => {
    return (
      <CommentField
        key={answer.id}
        placeholder={t("competence.survey.addComment")}
        onChange={(event) => handleChangeComment(event, answer.id)}
        value={value || ""}
        maxLength={500}
      />
    );
  };

  const renderAnswers = () => answers.map((answer) => {
    if (answer.type === AnswerType.Selected) {
      return renderSelectAnswer(answer);
    }
    return renderCommentAnswer(answer);
  });

  return (
    <Container className={className}>
      <Question>
        <Counter>{counter}.</Counter>
        <QuestionText>
          {question}
        </QuestionText>
      </Question>
      <Answers>
        {renderAnswers()}
      </Answers>
    </Container>
  );
};

SurveyQuestion.propTypes = {
  className: PropTypes.string,
  question: PropTypes.string.isRequired,
  questionId: PropTypes.number.isRequired,
  answers: PropTypes.array.isRequired,
  counter: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};
