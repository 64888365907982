import styled from "styled-components";

import { Button } from "../../../../components/button/button.component";
import { ReactComponent as CheckSvg } from "../../../../images/check.svg";
import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.div`
  background-color: ${Color.white};
  padding: 45px 20px;

  ${Media.tablet`
    display: flex;
    padding: 42px 35px;
    align-items: center;
  `}
`;

export const CheckIcon = styled(CheckSvg)`
  width: 50px;
  height: 50px;
  margin-right: 30px;
  flex-shrink: 0;
  display: none;

  ${Media.tablet`
    display: block;
  `}
`;

export const TextWrapper = styled.div``;

export const Title = styled.p`
  font-weight: ${FontWeight.SemiBold};
  font-size: 18px;
  line-height: 20px;
`;

export const Subtitle = styled.p`
  margin-top: 20px;
  font-size: 15px;
  line-height: 17px;
  color: ${Color.gray9};

  ${Media.tablet`
    margin-top: 10px;
  `}
`;

export const GoButton = styled(Button)`
  margin-left: auto;
  margin-top: 30px;
  width: 100%;

  ${Media.tablet`
    width: auto;
    min-width: 200px;
    margin-top: 0;
  `}
`;
