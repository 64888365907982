import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../helpers/rendering";
import { Media } from "../../theme/media";
import { Color } from "../../theme/colors";
import { ContentMaxWidth } from "../../theme";


export const Container = styled.div`
  padding: 20px 0;
  background-color: ${Color.almostWhite};
  display: flex;
  justify-content: center;

  ${Media.tablet`
    padding: 40px 25px;
  `}

  ${Media.desktop`
    padding: 67px 80px;
  `}
`;

export const Wrapper = styled.div`
  max-width: ${ContentMaxWidth};
  width: 100%;
`;

export const NewsActivityContainer = styled.div`
  flex: 1;
  ${Media.desktop`
    max-width: 700px;
  `}
`;

export const TopRow = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 60px;

  ${Media.desktop`
    align-items: flex-start;
    flex-direction: row;
  `}
`;

export const TopColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  width: 100%;

  ${Media.tablet`
    flex-direction: row;
    justify-content: space-between;

    > * {
      width: calc(50% - 10px);
    }
  `}

  ${styleWhenTrue(prop("$atRightSide"), css`
    ${Media.desktop`
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 20px;
      margin-bottom: 0;
      max-width: 480px;

      > * {
        width: 100%;
      }
    `}
  `)}
`;

export const MiddleRow = styled.div``;

export const BottomColumn = styled(TopColumn)`
  margin-top: 60px;

  ${Media.tablet`
    > * {
      width: 100%;
    }
  `}

  ${Media.desktop`
    margin: 0 0 0 20px;
    min-width: 480px;
    flex-direction: column;
    justify-content: flex-start;
    width: 30%;
  `}
`;

export const BottomRow = styled(TopRow)`
  margin-bottom: 0;
  margin-top: 60px;
  flex-direction: column;

  ${Media.desktop`
    align-items: flex-start;
    flex-direction: row;
  `}
`;
