import styled from "styled-components";

import { Media, FontWeight } from "../../../../../theme";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${Media.desktop`
    height: 600px;
  `}
`;

export const Content = styled.div`
  width: 100%;
  padding: 40px 0 30px;
`;

export const Title = styled.h1`
  font-size: 26px;
  font-weight: ${FontWeight.Bold};
  line-height: 40px;
  margin-bottom: 20px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  margin-bottom: 20px;

  ${Media.tablet`
    flex-direction: row;
    margin-bottom: 2px;
  `}
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${Media.tablet`
    flex-direction: row;
    justify-content: flex-end;
  `}

  ${Media.desktop`
    width: 90%;
  `}
`;
