import React from "react";
import PropTypes from "prop-types";

import { renderWhenTrueOtherwise } from "../../../../helpers/rendering";

import { Container, Circle, Check, CheckIcon } from "./filter.styled";

export const Filter = ({
  name,
  isActive,
  switchFilter,
  circleBackground,
  disabled,
}) => {
  const renderCheck = () => (
    <Circle $background={circleBackground} active={isActive} />
  );
  const renderCircle = () => (
    <Check active={isActive}>
      <CheckIcon />
    </Check>
  );
  const renderToggle = renderWhenTrueOtherwise(renderCheck, renderCircle);

  return (
    <Container
      onClick={switchFilter}
      active={isActive}
      disabled={disabled}
    >
      {renderToggle(!!circleBackground)}
      {name}
    </Container>
  );
};

Filter.propTypes = {
  name: PropTypes.string,
  isActive: PropTypes.bool,
  switchFilter: PropTypes.func,
  circleBackground: PropTypes.string,
  disabled: PropTypes.bool,
};
