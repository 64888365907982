import styled from "styled-components";
import Lottie from "lottie-react";

import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${Media.tablet`
    aspect-ratio: 1200 / 470;
    padding: 0 20px;
  `}

  ${Media.tabletWide`
    padding: 0;
  `}
`;

export const Title = styled.p`
  font-size: 32px;
  line-height: 1.4;
  font-weight: ${FontWeight.Bolder};
  color: ${() => Color.brandBasic};

  ${Media.tablet`
    font-size: 40px;
    line-height: 1.3;
  `}
`;

export const AnimWrapper = styled.div`
  display: none;

  ${Media.tablet`
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  `}
`;

export const StyledLottie = styled(Lottie)`
  width: 100%;
`;

export const SlideCounter = styled.p`
  font-size: 14px;
  line-height: 1.4;
  font-weight: ${FontWeight.Light};
  color: ${Color.deepGray};
  margin-bottom: 10px;

  ${Media.tablet`
    margin-bottom: 20px;
  `}
`;

export const CurrentSlideCounter = styled.span`
  font-weight: ${FontWeight.SemiBold}
`;
