import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ButtonColor } from "../../../../../components/button/button.constants";
import { RightDrawer } from "../../../../../components/rightDrawer/rightDrawer.component";

import {
  ModalContent,
  CloseIcon,
  CloseButton,
  ModalDiagramIcon,
  ModalBoldText,
  ModalTitle,
  ModalText,
  Button,
} from "./populationModal.styled";

export const PopulationModal = ({
  isOpen,
  handleClose,
}) => {
  const { t } = useTranslation();

  return (
    <RightDrawer open={isOpen} onClose={handleClose}>
      <ModalContent>
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <ModalDiagramIcon />
        <ModalBoldText>{t("teamIndicators.pageTitle")}</ModalBoldText>
        <ModalTitle>{t("teamIndicators.indicatorsTab.competencePopulation")}</ModalTitle>
        <ModalText>
          {t("teamIndicators.indicatorsTab.haveConfirmation")}
        </ModalText>
        <ModalText>
          {t("teamIndicators.indicatorsTab.addCertificates")}
        </ModalText>
        <Button color={ButtonColor.Primary} onClick={handleClose}>
          {t("teamIndicators.indicatorsTab.close")}
        </Button>
      </ModalContent>
    </RightDrawer>
  );
};

PopulationModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};
