import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { ReactComponent as WrongSign } from "../../images/wrongSign.svg";

import {
  Container,
  Counter,
  Text,
  Button,
} from "./wrongAnswer.styled";

export const WrongAnswer = ({ className, onTryAgain, currentQuestion }) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <WrongSign />
      <Counter>
        {t("interactions.opponentTest.questionCounter", { counter: currentQuestion })}
      </Counter>
      <Text>{t("interactions.opponentTest.wrongAnswer")}</Text>
      <Button onClick={onTryAgain}>
        {t("interactions.opponentTest.tryAgain")}
      </Button>
    </Container>
  );
};

WrongAnswer.propTypes = {
  className: PropTypes.string,
  onTryAgain: PropTypes.func,
  currentQuestion: PropTypes.number,
};
