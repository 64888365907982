export const ButtonVariant = {
  Default: "default",
  Outlined: "outlined",
};

export const ButtonColor = {
  Default: "default",
  Primary: "primary",
  Action: "action",
};
