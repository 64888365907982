import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { FilterType } from "../filters/filters.constants";

import {
  Container,
  Bold,
  TypeFilter,
  Filters,
} from "./typeFilters.styled";

export const TypeFilters = ({ switchFilter, clearFilters, selected }) => {
  const { t } = useTranslation();

  const handleSwitchFilter = (filter) => {
    if (filter === FilterType.All) {
      clearFilters();
    } else {
      switchFilter(filter);
    }
  };

  const renderFilter = (filterType) => (
    <TypeFilter
      onClick={() => handleSwitchFilter(filterType)}
      selected={
        filterType === FilterType.All
          ? !(
            selected.includes(FilterType.Contents)
            || selected.includes(FilterType.Lesson)
            || selected.includes(FilterType.Files)
          )
          : selected.includes(filterType)
      }
    >
      {t(filterType.name)}
    </TypeFilter>
  );

  return (
    <Container>
      <Bold>{t("learning.filters.filters.show")}:</Bold>
      <Filters>
        {renderFilter(FilterType.All)}
        {renderFilter(FilterType.Contents)}
        {renderFilter(FilterType.Lesson)}
        {renderFilter(FilterType.Files)}
      </Filters>
    </Container>
  );
};

TypeFilters.propTypes = {
  switchFilter: PropTypes.func,
  clearFilters: PropTypes.func,
};
