import styled from "styled-components";

import { breakpoints } from "../UIGlobals";
import { Color } from "../theme";

export const Wrapper = styled.div`
  padding-left: 80px;
  
  @media (${breakpoints().maxL}) {
    padding-left: 0;
  }
`;

export const RightWrapper = styled.div``;

export const MainContent = styled.div`
  min-height: 30vh;
  display: flex;
  flex-direction: column;

  @media (${breakpoints().minM}) {
    min-height: 70vh;
  }
`;

export const RemainingPlace = styled.div`
  background-color: ${Color.almostWhite};
  flex-grow: 1;
`;

export const MobileHeader = styled.div`
  height: env(safe-area-inset-top);
  background-color: ${() => Color.brandBasic};
  position: sticky;
  top: 0;
  z-index: 99999;
`;

export const SearchPopupWrapper = styled.div`
  position: absolute;
  top: calc(0px + env(safe-area-inset-top));
  left: 0;
  right: 0;
  bottom: calc(70px + env(safe-area-inset-bottom));
  background-color: ${Color.almostWhite};
  z-index: 100;
  overflow-y: auto;

  @media (${breakpoints().minM}) {
    top: 70px;
    bottom: 0;
  }

  @media (${breakpoints().minL}) {
    left: 80px;
  }
`;
