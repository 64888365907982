import React from "react";
import styled from "styled-components";

import * as UI from "./timerSlidesUI";
import { colors, breakpoints } from "../../../UIGlobals";

const ContentWrapper = styled.div`
  flex: 3;
  @media (${breakpoints().maxM}) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;  
  }
`;

const Header = styled(UI.Header)`
  margin: 100px 20px 20px;
  text-align: center;
`;

const ColumnsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const IconColumnWrapper = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  margin: 0 30px;
  flex: 1;
  @media (${breakpoints().maxM}) {
    margin: 0 10px;
  }
`;

const Icon = styled.img`
  ${props => props.isBig && "position: absolute;"}
  ${props => props.isBig && "left: -20px;"}
  ${props => props.isBig && props.isSingleIcon && "width: 70%"};
  ${props =>
  !props.isBig &&
    (props.isSingleIcon
      ? "max-height: 500px; max-width: 100%;"
      : "max-width: 300px;")}
  margin-bottom: 40px;
  @media (${breakpoints().maxM}) {
    max-width: ${props => props.bigIconTheme || props.isSingleIcon ? "100%" : "50px"};
    margin: ${props => props.isSingleIcon ? "10px 0" : "20px 0 10px"};
  }
`;

const IconText = styled.p`
  font-size: 24px;
  line-height: 40px;
  width: 100%;
  ${props => !props.light && "font-weight: bold;"}
  ${props => props.center && "text-align: center;"}
  @media (${breakpoints().maxM}) {
    font-size: 10px;
    line-height: 13px;
  }
`;

const TextColumn = styled.div`
  flex: 1;
  background: ${colors().almostWhite};
  padding: 120px 45px;
  @media (${breakpoints().maxM}) {
    padding: 0 20px 10px;
    background: none;
  }
`;

const TextHeader = styled(UI.Paragraph)`
  font-weight: bold;
`;

const IconColumns = ({ data, hasTextColumn }) => (
  <UI.Container>
    <ContentWrapper>
      <Header>{data.header}</Header>
      <ColumnsWrapper>
        {data.elements.map((element, index) => (
          <IconColumnWrapper key={index} hasBigIcon={element.bigIconUrl}>
            {element.iconUrl && (
              <Icon
                isBig={hasTextColumn}
                src={data.iconBaseUrl + element.iconUrl}
                alt="icon"
                isSingleIcon={data.elements.length === 1}
              />
            )}
            {element.bigIconUrl && (
              <Icon
                bigIconTheme
                src={data.iconBaseUrl + element.bigIconUrl}
                alt="icon"
                isSingleIcon={data.elements.length === 1}
              />
            )}
            {element.textLeft && <IconText>{element.textLeft}</IconText>}
            {element.text && <IconText center>{element.text}</IconText>}
            {element.textLight && (
              <IconText center light>
                {element.textLight}
              </IconText>
            )}
          </IconColumnWrapper>
        ))}
      </ColumnsWrapper>
    </ContentWrapper>
    {hasTextColumn && (
      <TextColumn>
        {data.textHeader && <TextHeader>{data.textHeader}</TextHeader>}
        {data.paragraphs &&
          data.paragraphs.map((paragraph, index) => (
            <UI.Paragraph key={index}>{paragraph}</UI.Paragraph>
          ))}
      </TextColumn>
    )}
  </UI.Container>
);

export default IconColumns;
