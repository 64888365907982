import { handleApiError, phpApi } from "../../../api";
import { AppConfig } from "../../../appConfig";

export const getAnimation = (src, setAnimationData) => {
  fetch(`${AppConfig.content}${src}`)
    .then((response) => response.json())
    .then((jresp) => setAnimationData(jresp));
};

export const getJsonData = (src, setJsonData) => {
  fetch(`${AppConfig.content}${src}`).then((response) => response.json()).then((data) => setJsonData(data));
};

export const setQuestionCompleted = (instanceId, quizId, answers) => {
  return phpApi.post(`/api/content/${instanceId}/save-answers`, {
    quiz: quizId,
    answers,
  }).catch(() => "error");
};

export const saveQuiz = (instanceId, quizId) => {
  return phpApi.post(`/api/content/${instanceId}/save-quiz`, { quiz: quizId }).catch(handleApiError);
};
