import React from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { cond, equals, always, T } from "ramda";

import { CategoryEnum } from "../../trainings.constants";

import {
  Container,
  Title,
  DateTitle,
  Text,
  Separator,
  TextWrapper,
  SeatsIcon,
  PlaceIcon,
  IconWrapper,
  ExamsIcon,
  DevelopmentIcon,
  MoneyIcon,
  InsuranceIcon,
  OthersIcon,
  ProductOthersIcon,
  HRIcon,
} from "./calendarPopup.styled";

export const CalendarPopup = ({ title, start, end, seatsAvailable, location, category }) => {
  const { t } = useTranslation();

  const categoryIcon = cond([
    [equals(CategoryEnum.Exams), always(<ExamsIcon />)],
    [equals(CategoryEnum.Development), always(<DevelopmentIcon />)],
    [equals(CategoryEnum.ProductFinancing), always(<MoneyIcon />)],
    [equals(CategoryEnum.ProductInsurance), always(<InsuranceIcon />)],
    [equals(CategoryEnum.ProductService), always(<OthersIcon />)],
    [equals(CategoryEnum.ProductOthers), always(<ProductOthersIcon />)],
    [equals(CategoryEnum.SystemOperations), always(<OthersIcon />)],
    [equals(CategoryEnum.HRTraining), always(<HRIcon />)],
    [equals(CategoryEnum.InformationTraining), always(<OthersIcon />)],
    [equals(CategoryEnum.Other), always(<OthersIcon />)],
    [T, always(<OthersIcon />)],
  ]);

  return (
    <Container>
      <Title>{title}</Title>
      <DateTitle>{t("trainings.calendar.startDate")}</DateTitle>
      <Text>
        {dayjs(start).format("dddd, DD.MM.YYYY")}
        {", "}
        {t("trainings.calendar.hour")}
        {" "}
        {dayjs(start).format("HH:mm")}
      </Text>
      <DateTitle>{t("trainings.calendar.endDate")}</DateTitle>
      <Text>
        {dayjs(end).format("dddd, DD.MM.YYYY")}
        {", "}
        {t("trainings.calendar.hour")}
        {" "}
        {dayjs(end).format("HH:mm")}
      </Text>
      <Separator />
      <TextWrapper>
        <SeatsIcon />
        <Text>
          {`${seatsAvailable} ${t("trainings.calendar.placesAvailable")}`}
        </Text>
      </TextWrapper>
      <TextWrapper>
        <PlaceIcon />
        <Text>
          {location}
        </Text>
      </TextWrapper>
      <IconWrapper>
        {categoryIcon(category)}
      </IconWrapper>
    </Container>
  );
};

CalendarPopup.propTypes = {
  title: PropTypes.string.isRequired,
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  location: PropTypes.string.isRequired,
  seatsAvailable: PropTypes.number.isRequired,
  category: PropTypes.string,
};
