/* eslint-disable react/no-string-refs */
import React from "react";

import Player from "./player.component";
import { Container } from "./pathChoice.styled";

const CHOICE = "choice";
const WRONG_REACTION = "wrongReaction";
const REPLAY_SCREEN = "replayScreen";
let WAITING = null;

export default class PathChoice extends React.Component {

  constructor() {
    super();
    this.state = {
      screen: CHOICE,
      wrong: WRONG_REACTION,
      correct: null
    };
  }

  componentDidMount() {
    this.attempts = null;
    if (this.props.config) {
      fetch(this.props.baseUrl + this.props.config)
        .then(data => data.json())
        .then(data => this.setState({ ...data }));
    }
  }

  setWaiting(callback, arg){
    if(WAITING) clearTimeout(WAITING);
    WAITING = setTimeout(function(){
      if(callback) callback(arg);
    }, 12);
  }

  getConfig() {
    fetch(this.props.baseUrl + this.props.config)
      .then(response => response.json())
      .then(jresp => {
        this.initialState = {
          correct: jresp.correct,
          wrong: jresp.wrong
        };
        this.setState(this.initialState);

      });
    return this.initialState;
  }

  setPaused(value) {
    this.setState({ paused: value });
  }

  renderChoice() {
    const { correct, wrong: { choice: wrong } } = this.state;

    return (
      <Container>
        <div className="path-choice" ref="pathChoice">
          <h1 className="path-choice--header hidden">Wybór</h1>
          <ChoiceItem text={ correct } onClick={ () => this.props.callComplete() } />
          <ChoiceItem text={ wrong } onClick={ screenChanger(this, WRONG_REACTION) } />
        </div>
      </Container>
    );
  }
  renderWrongReaction() {
    const { wrong: { video } } = this.state;
    const { baseUrl  } = this.props;
    const { paused } = this.state;

    return (
      <Container>
        <Player
          url={baseUrl + video}
          onEnded={screenChanger(this, REPLAY_SCREEN)}
          paused={paused}
        />
      </Container>
    );
  }
  renderReplayScreen() {
    const { wrong: { summary } } = this.state;

    return (
      <Container>
        <div className="path-choice path-choice--replay-screen" ref="pathChoice">
          <h1 className="path-choice--header hidden">Kiepściutko...</h1>
          <div className="path-choice--summary hidden" dangerouslySetInnerHTML={ { __html: summary } } />
          <button className="path-choice--summary--button hidden" onClick={ screenChanger(this, CHOICE) }>Wróć</button>
        </div>
      </Container>
    );
  }
  componentDidUpdate(){
    if(this.refs.pathChoice !== undefined && this.refs.pathChoice) {
      this.setWaiting(this.showScreenElements, this.refs.pathChoice);
    }
  }

  showScreenElements(node){
    const elements = node.querySelectorAll(".hidden");
    for(let i = 0; i < elements.length; i++){
      let element = elements[i];
      if(!element.classList.contains("updated")){
        // ustawienie większego opóźnienia dla kolejnych elementów
        element.style.transitionDelay = "0." + i + "s";
        element.classList.remove("hidden");
        element.classList.add("updated");
      }
    }
  }

  render() {

    switch (this.state.screen) {
    case CHOICE:
      return this.renderChoice();
    case WRONG_REACTION:
      return this.renderWrongReaction();
    case REPLAY_SCREEN:
      return this.renderReplayScreen();
    default:
      return null;
    }

  }
}


const ChoiceItem = ({ text, onClick }) => (
  <div className="path-choice--choice-item">
    <p className="path-choice--choice-item--text hidden">{ text }</p>
    <button className="path-choice--choice-item--button hidden" onClick={ onClick }></button>
  </div>
);

function screenChanger(self, screen) {
  return () => self.setState({ screen });
}


