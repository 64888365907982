import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { styleWhenTrue } from "../../../../../helpers/rendering";
import { ReactComponent as GoldMedalSvg } from "../../../../../images/goldMedal.svg";
import { ReactComponent as SilverMedalSvg } from "../../../../../images/silverMedal.svg";
import { ReactComponent as BronzeMedalSvg } from "../../../../../images/bronzeMedal.svg";
import { ReactComponent as EyeSvg } from "../../../../../images/eye.svg";
import { ReactComponent as LockSvg } from "../../../../../images/lock.svg";
import { ReactComponent as LessonSwitchLockSvg } from "../../../../../images/lock3.svg";
import { ReactComponent as CheckSvg } from "../../../../../images/check.svg";
import { ReactComponent as ArrowSvg } from "../../../../../images/arrow.svg";
import { FontWeight } from "../../../../../theme/fonts";
import { Color } from "../../../../../theme/colors";
import { Media } from "../../../../../theme/media";

export const Container = styled.div`
  background-color: ${Color.white};

  ${styleWhenTrue(prop("disabled"), css`
    background-color: ${Color.transparent};
    border: 1px solid ${Color.gray5};
  `)}
`;

export const Title = styled.h4`
  font-size: 16px;
  line-height: 22px;
  font-weight: ${FontWeight.ExtraBold};
  text-transform: uppercase;
  width: 195px;

  ${Media.mobile`
    width: 250px;
  `}

  ${Media.tablet`
    font-size: 19px;
    line-height: 26px;
    width: auto;
  `}
`;

export const TabletRow = styled.div`
  ${Media.tablet`
    display: flex;
    align-items: center;
  `}
`;

export const EyeImgWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Color.gray3};
  border-radius: 50%;
  margin-left: 10px;
  flex-shrink: 0;

  ${styleWhenTrue(prop("small"), css`
    width: 36px;
    height: 36px;
    margin-left: 0;
  `)}
`;

export const LessonSwitchLockImg = styled(LessonSwitchLockSvg)`
  flex-shrink: 0;

  ${EyeImgWrapper} + & {
    margin-left: 5px;
  }
`;

export const SelectedLessonTitle = styled.p`
  font-size: 15px;
  font-weight: ${FontWeight.SemiBold};
  line-height: 22px;
  color: ${Color.deepGray};
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;

  * + & {
    margin-left: 18px;
  }

  ${styleWhenTrue(prop("greyed"), css`
    color: ${Color.gray9};
  `)}
`;

export const LessonSwitchWrapper = styled.div`
  padding: 25px 20px;
  border-top: 2px solid ${Color.almostWhite};
`;

export const CheckImgBig = styled(CheckSvg)`
  width: 30px;
  height: 30px;
  margin-right: 5px;

  ${EyeImgWrapper} + &,
  ${LessonSwitchLockImg} + & {
    margin-left: 5px;
  }

  ${Media.tablet`
    width: 50px;
    height: 50px;
    margin-left: 10px;
    margin-right: 0;
  `}
`;

export const LockImgWrapper = styled(EyeImgWrapper)`
  background-color: ${Color.almostWhite};
  border: none;
  flex-shrink: 0;
`;

export const ButtonsRow = styled.div`
  display: flex;

  ${Media.tablet`
    flex: 3;
  `}
`;

export const LockImg = styled(LockSvg)`
  width: 12px;
  height: 16px;
`;

export const SelectedLessonRow = styled.div`
  display: flex;
  min-height: 66px;
  align-items: center;

  ${CheckImgBig} {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
  }
`;

export const GoToLessonButton = styled.button`
  border: none;
  outline: none;
  color: ${Color.white};
  font-weight: ${FontWeight.ExtraBold};
  background-color: ${Color.lighterViolet};
  margin: 20px 0 15px;
  text-transform: uppercase;
  height: 45px;
  font-size: 10px;
  width: 100%;

  ${styleWhenTrue(prop("disabled"), css`
    background-color: ${Color.almostWhite};
    pointer-events: none;
    color: ${Color.gray1};
  `)}

  ${Media.tablet`
    margin: 0 5px 0 0;
    flex: 4;
  `}
`;

export const BackIcon = styled(ArrowSvg)`
  fill: ${Color.lighterViolet};
  margin-right: 15px;
`;

export const NextIcon = styled(BackIcon)`
  margin-right: 0;
  margin-left: 15px;
  transform: rotate(180deg);
`;

export const SwitchButton = styled.button`
  border: 1px solid ${Color.gray8};
  outline: none;
  color: ${Color.deepGray};
  font-weight: ${FontWeight.ExtraBold};
  font-size: 10px;
  background-color: ${Color.transparent};
  width: 100%;
  height: 45px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin-left: 5px;
  }

  ${styleWhenTrue(prop("disabled"), css`
    background-color: ${Color.almostWhite};
    color: ${Color.gray1};
    pointer-events: none;

    ${BackIcon} {
      fill: ${Color.gray1};
    }
  `)}
`;

export const ArrowWrapper = styled.div`
  transform: rotate(-90deg);
  flex-shrink: 0;
  margin-left: 10px;
  margin-right: auto;

  ${styleWhenTrue(prop("withLeftMargin"), css`
    margin-left: auto;
    margin-right: 0;
  `)}

  ${Media.mobile`
    margin-left: 20px;

    ${styleWhenTrue(prop("withLeftMargin"), css`
      margin-left: auto;
      margin-right: 0;
    `)}
  `}
`;

export const ArrowImg = styled(ArrowSvg)`
  height: 14px;
  width: 8px;
  fill: ${() => Color.brandBasic};
  transition: transform 0.3s;

  ${styleWhenTrue(propEq("true", "expanded"), css`
    transform: rotateY(180deg);
  `)}
`;

export const LessonsProgress = styled.span`
  margin-left: 20px;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 1.71px;
  font-weight: ${FontWeight.Bold};
  border: 1px solid ${Color.gray5};
  width: 80px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 17px;
`;

export const TitleWrapper = styled.button`
  display: flex;
  border: none;
  background-color: ${Color.transparent};
  width: 100%;
  padding: 24px 25px;
  align-items: center;

  ${Media.tablet`
    padding: 43px 40px;
  `}

  ${styleWhenTrue(prop("disabled"), css`
    cursor: default;
  `)}

  ${styleWhenTrue(prop("isMapView"), css`
    padding: 30px 20px;

    ${LessonsProgress} {
      display: none;
    }

    ${ArrowImg} {
      margin-left: auto;
      margin-right: unset;
    }

    ${Title} {
      width: auto;
      padding-right: 20px;
      text-align: left;
    }
  `)}
`;

export const Description = styled.p`
  font-size: 17px;
  line-height: 23px;
  font-weight: ${FontWeight.Bold};
  margin-top: 35px;
`;

export const Lesson = styled.button`
  display: flex;
  margin: 29px 0;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0;
  width: 100%;

  ${styleWhenTrue(prop("disabled"), css`
    cursor: default;
  `)}
`;

export const LessonText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  text-align: left;
  justify-content: center;

  ${Media.tablet`
    margin-left: 25px;
  `}

  ${styleWhenTrue(prop("locked"), css`
    opacity: 50%;
  `)}
`;

export const Name = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${Color.deepGray};

  ${Media.tablet`
    font-size: 16px;
  `}
`;

export const Type = styled.p`
  font-size: 10px;
  line-height: 20px;
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.gray3};
  text-transform: uppercase;
  display: none;

  ${Media.tablet`
    display: block;
  `}
`;

export const ExpandWrapper = styled.div`
  padding: 0 25px 40px;

  ${Media.tablet`
    padding: 0 40px 40px;
  `}
`;

export const PassedText = styled.span`
  font-size: 10px;
  line-height: 14px;
  font-weight: ${FontWeight.ExtraBold};
  color: ${Color.green};
  margin-left: auto;
  text-transform: uppercase;
`;

export const CheckImg = styled(CheckSvg)`
  width: 33px;
  height: 33px;
  margin-left: 10px;
`;

export const Thumbnail = styled.img`
  height: 82px;

  ${Media.tablet`
    height: 94px;
  `}

  ${styleWhenTrue(prop("locked"), css`
    opacity: 50%;
  `)}
`;

export const PDFBadge = styled.span`
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: ${FontWeight.ExtraBold};
  font-size: 6px;
  line-height: 8px;
  border: 1px solid ${Color.gray5};
  border-radius: 50%;
  color: ${Color.gray3};
  margin-right: 5px;

  ${Media.tablet`
    width: 50px;
    height: 50px;
    font-size: 11px;
    line-height: 15px;
    margin-right: 0;
  `}
`;

export const Badges = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-top: 10px;

  ${Media.tablet`
    margin-left: auto;
    margin-top: 0;
    justify-content: flex-end;
  `}
`;

export const MedalStyles = css`
  width: 30px;
  height: 30px;
  margin-right: 5px;

  ${Media.tablet`
    width: 50px;
    height: 50px;
    margin-left: 10px;
    margin-right: 0;
  `}
`;

export const GoldMedal = styled(GoldMedalSvg)`
  ${MedalStyles}
`;

export const SilverMedal = styled(SilverMedalSvg)`
  ${MedalStyles}
`;

export const BronzeMedal = styled(BronzeMedalSvg)`
  ${MedalStyles}
`;

export const StateText = styled.span`
  font-weight: ${FontWeight.ExtraBold};
  font-size: 10px;
  line-height: 14px;
  margin-left: 10px;
  color: ${Color.gray3};
  text-transform: uppercase;
`;

export const EyeImg = styled(EyeSvg)`
  width: 18px;
  height: 13px;
`;

export const LessonContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${Media.tablet`
    flex-direction: row;
  `}
`;
