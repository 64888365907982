import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { Media } from "../../theme/media";
import { Color } from "../../theme/colors";
import { FontWeight } from "../../theme";
import { styleWhenTrue, styleIfElse } from "../../helpers/rendering";

import { CheckboxType } from "./checkbox.constants";

export const Checkmark = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: ${Color.transparent};
  border: 1px solid ${Color.gray4};

  ${styleWhenTrue(propEq(CheckboxType.Black, "type"), css`
    border-color: ${Color.gray9};
  `)}

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 8px;
    top: 4px;
    width: 5px;
    height: 9px;
    border: solid ${Color.white};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  ${Media.tablet`
    height: 16px;
    width: 16px;

    ${styleWhenTrue(propEq(CheckboxType.Black, "type"), css`
      height: 18px;
      width: 18px;
    `)}

    &:after {
      left: 5px;
      top: 2px;
      width: 3px;
      height: 6px;

      ${styleWhenTrue(propEq(CheckboxType.Black, "type"), css`
        width: 4px;
        height: 9px;
        top: 1px;
      `)}
    }
  `}
`;

export const Container = styled.label`
  display: block;
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  font-size: 12px;
  line-height: 17px;
  user-select: none;
  display: flex;
  align-items: center;
  min-height: 25px;
  color: ${Color.gray4};

  ${Media.tablet`
    padding-left: 26px;
  `}

  &:hover {
    color: ${Color.deepGray};
  }

  ${styleWhenTrue(propEq(CheckboxType.Black, "type"), css`
    font-weight: ${FontWeight.SemiBold};
  `)}

  ${styleWhenTrue(prop("checked"), css`
    color: ${Color.deepGray};

    ${Checkmark} {
      background-color: ${() => Color.brandBasic};
      border-color: ${() => Color.brandBasic};

      ${styleWhenTrue(propEq(CheckboxType.Black, "type"), css`
        background-color: ${Color.deepGray};
        border-color: ${Color.deepGray};
      `)}

      &:after {
        display: block;

        ${styleWhenTrue(prop("disabled"), css`
          ${styleWhenTrue(propEq(CheckboxType.Black, "type"), css`
            border-color: ${Color.gray9};
          `)}
        `)}
      }
    }
  `)}

  ${styleWhenTrue(prop("invalid"), css`
    ${Checkmark} {
      border-color: ${() => Color.brandBasic};
    }
  `)}

  ${styleWhenTrue(prop("disabled"), css`
    color: ${Color.gray1} !important;
    pointer-events: none;

    ${Checkmark} {
      border-color: ${Color.dirtyWhite};
      background-color: ${styleIfElse(prop("checked"), Color.dirtyWhite, Color.transparent)};
    }

    ${styleWhenTrue(propEq(CheckboxType.Black, "type"), css`
      color: ${Color.gray9} !important;
    `)}
  `)}
`;

export const Label = styled.div`
  a {
    color: ${() => Color.brandBasic};
  }
`;
