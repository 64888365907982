import { createSelector } from "reselect";
import { prop } from "ramda";

export const selectVideoPlayerDomain = prop("videoPlayer");

export const selectIsPending = createSelector(
  selectVideoPlayerDomain, prop("isPending"),
);

export const selectVideoInfo = createSelector(
  selectVideoPlayerDomain, prop("info"),
);

export const selectVideoName = createSelector(
  selectVideoInfo, prop("name"),
);

export const selectVideoComments = createSelector(
  selectVideoPlayerDomain, prop("comments"),
);

export const selectRelatedVideos = createSelector(
  selectVideoPlayerDomain, prop("relatedVideos"),
);

export const selectVideoStructure = createSelector(
  selectVideoPlayerDomain, prop("structure"),
);

export const selectInstanceId = createSelector(
  selectVideoPlayerDomain, prop("instanceId"),
);

export const selectVideoTags = createSelector(
  selectVideoInfo, prop("tags"),
);

export const selectVideoDescription = createSelector(
  selectVideoInfo, prop("description"),
);
