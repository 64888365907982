import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { cond, equals, always } from "ramda";

import { PageHeader } from "../../theme";

import { InfoClauseContent } from "./content/infoClauseContent";
import { PrivacyPolicyContent } from "./content/privacyPolicyContent";
import { RegulationsContent } from "./content/regulationsContent";
import { TrainingRegulationsContent } from "./content/trainingRegulationsContent";
import { Container, Wrapper } from "./regulations.styled";
import { RegulationsType } from "./regulations.constants";

export const Regulations = ({ className, type }) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = cond([
    [equals(RegulationsType.InfoClause), always(<InfoClauseContent />)],
    [equals(RegulationsType.PrivacyPolicy), always(<PrivacyPolicyContent />)],
    [equals(RegulationsType.Regulations), always(<RegulationsContent />)],
    [equals(RegulationsType.TrainingRegulations), always(<TrainingRegulationsContent />)],
  ]);

  return (
    <Container className={className}>
      <Wrapper>
        <PageHeader>{t(`regulations.${type}`)}</PageHeader>
        {content(type)}
      </Wrapper>
    </Container>
  );
};

Regulations.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
};
