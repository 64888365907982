import { createActions, createReducer } from "reduxsauce";

import { deepClone } from "../../helpers";
import { convertParams, findNextSlide } from "../lesson.utils";

export const { Types: LessonTypes, Creators: LessonActions } = createActions(
  {
    setIsLoading: ["isLoading"],
    getInitialData: ["id"],
    getInitialDataSuccess: ["id", "data"],
    getRelatedSuccess: ["related"],
    setComments: ["data"],
    addComment: ["comment"],
    editCommentSuccess: ["comments"],
    deleteCommentSuccess: ["comments"],
    setCurrentSlide: ["pageId", "slideId"],
    setSlideInteraction: ["currentInteraction"],
    setInteractionCompleted: ["id", "pageId", "findNext"],
    setInteractionCompletedSuccess: ["id", "pageId", "findNext"],
    setActionsQueue: ["data"],
    setCurrentQuestion: ["id"],
    setAnswerSelected: ["questionId", "answerId"],
    setQuestionCompleted: ["id"],
    setQuestionCompletedSuccess: ["id"],
    setQuizCompleted: null,
    setQuizCompletedSuccess: ["data"],
    clearData: null,
  },
  { prefix: "LESSON/" }
);

const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  errorMessage: "",
  isPushing: false,
  params: {},
  pages: [],
  quizes: [],
  nav: {},
  actionsQueue: [],
  finished: false,
  related: [],
  comments: [],
  id: null,
  instanceId: null,
  favorite: null,
  type: null,
  tags: [],
};

const setIsLoading = (state, { isLoading }) => ({ ...state, isLoading });

const getInitialDataSuccess = (state, { id, data }) => {
  const dataStructure = data.structure;
  const instanceId = data.instanceId;
  const favorite = data.info.favorite;
  const type = data.info.type;
  const tags = data.info.tags;
  const description = data.info.description;
  const fastForward = data.info.fastForward;

  const params = convertParams(dataStructure.params);

  const pages = dataStructure.children.filter((child) => child.type === "page").map((page) => ({
    ...page,
    active: true,
    completed: false,
  }));

  pages.forEach((page) => {
    page.children.forEach((slide) => {
      const params = convertParams(slide.params);

      slide.children.forEach((interaction) => {
        interaction.completed = params.passed;
      });
      slide.completed = params.passed;
      slide.params = params;
    });

    page.completed = page.children.find((slide) => !slide.completed) ? false : true;
  });

  const quizes = dataStructure.children.filter((child) => child.type === "quiz").map((quiz) => ({
    ...quiz,
    completed: false,
  }));

  quizes.forEach((quiz) => {
    quiz.children.forEach((question) => {
      question.params = convertParams(question.params);
      question.completed = false;

      question.children.forEach((answer) => {
        answer.params = convertParams(answer.params);
        answer.selected = answer.params.selected;
        answer.params.selected = undefined;
      });
    });
  });

  const nav = data.completed
    ? {
      currentType: "slide",
      currentParent: pages[0].id,
      currentChild: pages[0].children[0].id,
      currentInteraction: undefined
    }
    : findNextSlide(pages, quizes);

  return {
    ...state,
    id,
    params,
    pages,
    nav,
    quizes,
    finished: false,
    instanceId,
    favorite,
    type,
    tags,
    description,
    fastForward,
  };
};

const getRelatedSuccess = (state, { related }) => ({ ...state, related });

const setComments = (state, { data }) => {
  const comments = state.comments.concat(data);
  return { ...state, comments };
};

const setCurrentSlide = (state, { pageId, slideId }) => {
  const newPages = deepClone(state.pages);

  return {
    ...state,
    nav: {
      currentType: "slide",
      currentParent: pageId,
      currentChild: slideId,
      currentInteraction: newPages.find((page) => page.id === pageId).children.find((slide) => (
        slide.id === slideId
      )).children[0].id,
    }
  };
};

const editCommentSuccess = (state, { comments }) => ({ ...state, comments });

const deleteCommentSuccess = (state, { comments }) => ({ ...state, comments });

const setSlideInteraction = (state, { currentInteraction }) => ({
  ...state,
  nav: {
    ...state.nav,
    currentInteraction,
  }
});

const setActionsQueue = (state, { data }) => {
  const actionsQueue = [...state.actionsQueue];
  actionsQueue.push(data);
  return {
    ...state,
    actionsQueue,
    isPushing: false,
  };
};

const setInteractionCompletedSuccess = (state, { id, pageId, findNext }) => {
  const newPages = deepClone(state.pages);
  const newQuizes = deepClone(state.quizes);

  newPages.forEach((page) => {
    page.children.forEach((slide) => {
      slide.children.forEach((interaction) => {
        if (interaction.id === id) {
          interaction.completed = true;
          interaction.seen = true;
        }
      });
      slide.completed = !slide.children.find((interaction) => !interaction.completed);
      slide.seen = !slide.children.find((interaction) => !interaction.seen);
    });

    page.completed = !page.children.find((slide) => !slide.completed);
    page.seen = !page.children.find((slide) => !slide.seen);
  });

  if (!findNext) {
    return ({
      ...state,
      pages: newPages,
    });
  }

  const pageIndex = newPages ? newPages.findIndex((page) => pageId === page.id) : 0;

  const nextParent = newQuizes.length > 0
    ? newQuizes[0]
    : newPages[pageIndex].seen
      ? newPages[pageIndex + 1]
      : newPages[pageIndex];

  const newNav = nextParent ? findNextSlide(newPages, newQuizes, pageId) : null;

  return newNav
    ? {
      ...state,
      nav: newNav,
      pages: newPages,
      isPushing: true,
    }
    : {
      ...state,
      finished: true,
    };
};

const setCurrentQuestion = (state, { id }) => ({
  ...state,
  nav: {
    currentType: "quiz",
    currentParent: state.nav.currentParent,
    currentChild: id,
    currentInteraction: undefined,
  }
});

const setAnswerSelected = (state, { questionId, answerId }) => {
  const newQuizes = deepClone(state.quizes);

  newQuizes.forEach((quiz) => (
    quiz.children.forEach((question) => {
      if (question.params.type === "multiple_choice") {
        const selectedQuestion = question.children.find((answer) => (answer.id === answerId));

        if (selectedQuestion) {
          selectedQuestion.selected = !selectedQuestion.selected;
        }
      } else if (question.id === questionId) {
        question.children.forEach((answer) => (answer.selected = answer.id === answerId));
      }
    })
  ));

  return {
    ...state,
    quizes: newQuizes,
  };
};

const setQuestionCompletedSuccess = (state, { id }) => {
  const newQuizes = deepClone(state.quizes);

  newQuizes.forEach((quiz) => (
    quiz.children.forEach((question) => {
      if (question.id === id) {
        question.completed = true;
      }
      quiz.completed = quiz.children.find((question) => !question.completed) ? false : true;
    })
  ));

  const currentQuiz = newQuizes.find((quiz) => !quiz.completed)
    ? newQuizes.find((quiz) => !quiz.completed)
    : newQuizes[0];
  const currentQustion = currentQuiz.children.find((question) => !question.completed)
    ? currentQuiz.children.find((question) => !question.completed)
    : currentQuiz.children[0];

  return {
    ...state,
    nav: {
      currentType: "quiz",
      currentParent: currentQuiz.id,
      currentChild: currentQustion.id,
      currentInteraction: undefined,
    },
    quizes: newQuizes,
    isPushing: true,
  };
};

const setQuizCompletedSuccess = (state, { data }) => {
  const newQuiz = {
    ...data.quiz,
    passed: data.passed,
    result: data.result,
  };

  newQuiz.children.forEach((question) => {
    question.params = convertParams(question.params);
    question.children.forEach((answer) => {
      answer.params = convertParams(answer.params);
    });
  });

  return {
    ...state,
    quizes: [newQuiz],
    finished: true,
  };
};

const clearData = () => ({ ...INITIAL_STATE });

export const reducer = createReducer(INITIAL_STATE, {
  [LessonTypes.SET_IS_LOADING]: setIsLoading,
  [LessonTypes.GET_INITIAL_DATA_SUCCESS]: getInitialDataSuccess,
  [LessonTypes.GET_RELATED_SUCCESS]: getRelatedSuccess,
  [LessonTypes.SET_COMMENTS]: setComments,
  [LessonTypes.EDIT_COMMENT_SUCCESS]: editCommentSuccess,
  [LessonTypes.DELETE_COMMENT_SUCCESS]: deleteCommentSuccess,
  [LessonTypes.SET_CURRENT_SLIDE]: setCurrentSlide,
  [LessonTypes.SET_SLIDE_INTERACTION]: setSlideInteraction,
  [LessonTypes.SET_ACTIONS_QUEUE]: setActionsQueue,
  [LessonTypes.SET_INTERACTION_COMPLETED_SUCCESS]: setInteractionCompletedSuccess,
  [LessonTypes.SET_CURRENT_QUESTION]: setCurrentQuestion,
  [LessonTypes.SET_ANSWER_SELECTED]: setAnswerSelected,
  [LessonTypes.SET_QUESTION_COMPLETED_SUCCESS]: setQuestionCompletedSuccess,
  [LessonTypes.SET_QUIZ_COMPLETED_SUCCESS]: setQuizCompletedSuccess,
  [LessonTypes.CLEAR_DATA]: clearData,
});
