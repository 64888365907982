import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectUnreadNotifications } from "../../../redux/common/common.selectors";
import { UserRoleName } from "../../../userContext/userContext.constants";
import { selectUserRole } from "../../../userContext/redux/userContext.selectors";
import { Logout } from "../../../userContext";
import { ReactComponent as SignOutIcon } from "../../../images/signOut.svg";
import { CompetenceRouteName } from "../../mainNavigation/mainNavigation.constants";
import { DropdownRoutes } from "../mobileNavigationMenu.constants";
import { NavTitle } from "../mobileNavigationMenu.styled";

import {
  Background,
  Container,
  NavItem,
  ArrowIcon,
  ActiveIndicator,
  InnerContainer,
  IconWrapper,
  Separator,
} from "./navigationDropdown.styled";

export const NavigationDropdown = ({
  closeDropdown,
  onNavItemClick,
  availableRoutes,
  open,
}) => {
  const { t } = useTranslation();
  const unreadNotifications = useSelector(selectUnreadNotifications);
  const userRole = useSelector(selectUserRole);
  const isOnboardingUser = userRole.name === UserRoleName.Onboarding;

  return (
    <>
      <Background open={open} onClick={closeDropdown} />
      <Container open={open}>
        <InnerContainer>
          {DropdownRoutes.map(({
            label,
            Icon,
            urls,
            routeName,
          }) => {
            if (label === t("notifications.pageTitle") || availableRoutes?.[routeName]) {
              if (isOnboardingUser && routeName === CompetenceRouteName) {
                return null;
              }

              return (
                <NavItem
                  key={label}
                  to={urls[0]}
                  onClick={() => onNavItemClick(label)}
                >
                  <IconWrapper>
                    <Icon />
                  </IconWrapper>
                  <NavTitle>{t(label)}</NavTitle>
                  {routeName === "notifications.pageTitle" && !!unreadNotifications && <ActiveIndicator />}
                  <ArrowIcon />
                </NavItem>
              )
            }
          })}
          <Separator />
          <Logout padding="0">
            <NavItem to="#">
              <IconWrapper>
                <SignOutIcon />
              </IconWrapper>
              <NavTitle>{t("app.mainNavigation.logout")}</NavTitle>
              <ArrowIcon />
            </NavItem>
          </Logout>
        </InnerContainer>
      </Container>
    </>
  );
};
