import { prop, propEq } from "ramda";
import styled, { css } from "styled-components";

import { styleWhenTrue, styleIfElse } from "../../../helpers/rendering";
import { RightDrawer as RightDrawerBase } from "../../../components/rightDrawer/rightDrawer.component";
import { hexToRgba } from "../../../helpers/colors";
import { Color, FontWeight, Media } from "../../../theme";
import { ButtonPanel as ButtonPanelBase } from "../components/buttonPanel/buttonPanel.component";
import { OvalButtonWrapper, RightSideTools } from "../components/buttonPanel/buttonPanel.styled";
import { RecordingTimer as RecordingTimerBase } from "../components/recordingTimer/recordingTimer.component";
import { Chat as ChatBase } from "../components/chat/chat.component";
import { Container as messageFieldContainer } from "../components/messageField/messageField.styled";
import { ReactComponent as ChatSvg } from "../images/chat.svg";
import { ReactComponent as UsersSvg } from "../images/users.svg";
import { ReactComponent as GridLayoutSvg } from "../images/gridLayout.svg";
import { ReactComponent as ColumnsLayoutSvg } from "../images/columnsLayout.svg";
import { ReactComponent as ArrowSvg } from "../images/arrow.svg";
import { ReactComponent as MicrophoneSvg } from "../images/microphone.svg";
import { ReactComponent as MicrophoneOffSvg } from "../images/microphoneOff.svg";

export const Container = styled.div`
  position: fixed;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-template-rows: 60px 45px repeat(6, minmax(0, 1fr)) 45px 60px;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  height: 100%;
  background-color: ${Color.deepGray};
  overflow-y: auto;
`;

export const Chat = styled(ChatBase)`
  padding: 0 15px 20px 20px;
  grid-area: ${styleIfElse(prop("isLive"), "2", "1")} / 15 / 11 / 16;
  border-left: 1px solid ${Color.gray5};
  border-top: 1px solid ${Color.gray5};
  width: 320px;

  ${messageFieldContainer} {
    margin: 0;
  }
`;

export const MicrophoneIcon = styled(MicrophoneSvg)`
  width: 11px;
  fill: ${() => Color.brandBasic};
`;

export const MicrophoneOffIcon = styled(MicrophoneOffSvg)`
  width: 11px;
  height: auto;
  fill: ${Color.error};
`;

export const StreamsContainer = styled.div`
  ${styleWhenTrue(prop("gridView"), css`
    grid-area: 3 / 2 / 9 / 14;
    display: grid;
    grid-gap: 2px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(3, minmax(0, 1fr));
    ${({ columns }) => !!columns && `grid-template-columns: repeat(${columns * 2}, minmax(0, 1fr))`};
    ${({ rows }) => !!rows && `grid-template-rows: repeat(${rows}, minmax(0, 1fr))`};

    ${styleWhenTrue(prop("withOffset"), css`
      &:before {
        content: "";
        grid-area: 1 / 1 / 2 / 2;
      }
    `)}
  `)}

  ${styleWhenTrue(propEq(false, "gridView"), css`
    grid-area: 2 / 15 / 11 / 16;
    background-color: ${Color.gray5};
    overflow-y: auto;
    width: ${styleIfElse(prop("isWide"), "320px", "267px")};
  `)}
`;

export const UpperButtons = styled.div`
  display: flex;
`;

export const ArrowIcon = styled(ArrowSvg)`
  position: relative;
  top: 2px;
`;

export const RecordingTimer = styled(RecordingTimerBase)`
  place-self: unset;
  grid-area: unset;
  margin-left: auto;

  ${styleWhenTrue(prop("withMargin"), css`
    margin-right: 100px;
  `)}
`;

export const ArrowButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: ${hexToRgba(Color.white, 0.1)};
  place-self: center;
  margin: 0 30px;
  flex-shrink: 0;
  grid-area: 5 / 14 / 7 / 15;

  &:hover {
    background-color: ${hexToRgba(Color.white, 0.15)};
  }

  ${styleWhenTrue(prop("disabled"), css`
    background-color: ${hexToRgba(Color.white, 0.04)};
    pointer-events: none;

    ${ArrowIcon} {
      path {
        fill: ${Color.gray9};
      }
    }
  `)}
`;

export const PreviousButton = styled(ArrowButton)`
  grid-area: 5 / 1 / 7 / 2;

  ${ArrowIcon} {
    transform: rotate(180deg);
  }
`;

export const NextButton = styled(ArrowButton)``;

export const Separator = styled.div`
  background-color: ${Color.gray5};
  width: 1px;
  height: 100%;
  border-radius: 3px;
  margin: 0 20px;
`;

const iconButtonStyles = css`
  border: none;
  background-color: ${Color.transparent};
  display: flex;
  padding: 0;
`;

const iconStyles = css`
  ${styleWhenTrue(prop("selected"), css`
    path {
      fill: ${() => Color.brandBasic};
    }
  `)}
`;

export const ChatButton = styled.button`
  ${iconButtonStyles}
  position: relative;

  ${styleWhenTrue(prop("unread"), css`
    &:after {
      content: "";
      position: absolute;
      right: -2px;
      top: -2px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: ${() => Color.brandBasic};
      animation: blinking 1s infinite;

      @keyframes blinking {
        0% { opacity: 0; }
        50% { opacity: 1; }
        100% { opacity: 0; }
      }
    }
  `)}
`;

export const UsersButton = styled.button`
  ${iconButtonStyles}
  margin-left: 15px;
`;

export const GridLayoutButton = styled.button`
  ${iconButtonStyles}
  ${({ disabled }) => disabled ? "cursor: default;" : ""}
`;

export const ColumnsLayoutButton = styled.button`
  ${iconButtonStyles}
  margin-left: 15px;
`;

export const ChatIcon = styled(ChatSvg)`
  ${iconStyles}
`;

export const UsersIcon = styled(UsersSvg)`
  ${iconStyles}
`;

export const GridLayoutIcon = styled(GridLayoutSvg)`
  ${iconStyles}
`;

export const ColumnsLayoutIcon = styled(ColumnsLayoutSvg)`
  ${iconStyles}
`;

export const ButtonPanel = styled(ButtonPanelBase)`
  grid-area: 10 / 1 / 11 / 15;
  justify-content: space-between;
  flex-direction: row-reverse;

  ${OvalButtonWrapper} {
    margin-left: 20px;
  }

  ${RightSideTools} {
    align-items: center;
  }
`;

export const PanelLeftSide = styled.div`
  display: flex;
  background-color: ${Color.white};
  height: 100%;
  padding: 15px 20px;
  grid-area: 1 / 1 / 2 / 14;
  align-items: center;

  ${styleWhenTrue(prop("rightPanelOpen"), css`
    grid-area: 1 / 1 / 2 / 15;
  `)}
`;

export const PanelRightSide = styled(PanelLeftSide)`
  grid-area: 1 / 12 / 2 / 15;
  justify-content: flex-end;

  ${styleWhenTrue(prop("rightPanelOpen"), css`
    border-left: 1px solid ${Color.gray5};
    grid-area: 1 / 15 / 2 / 16;
  `)}
`;

export const LiveInfo = styled.p`
  color: ${Color.error};
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  border: 1px solid ${Color.error};
  border-radius: 3px;
  padding: 4px;
  margin-right: 10px;
`;

export const Title = styled.p`
  font-weight: ${FontWeight.Bold};
  font-size: 12px;
  color: ${Color.deepGray};
`;

const titleStyle = css`
  font-weight: ${FontWeight.Bold};
  font-size: 12px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

export const RoomTitle = styled.h1`
  ${titleStyle}
  padding-right: 15px;
  border-right: 1px solid ${Color.gray1};
  margin-right: 15px;
`;

export const GreyedTitle = styled.h1`
  ${titleStyle}
  color: ${Color.gray1};
`;

export const RightDrawer = styled(RightDrawerBase)`
  .MuiPaper-root {
    ${Media.desktop`
      width: 550px;
    `}
  }
`;
