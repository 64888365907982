import { ReactComponent as ModuleIcon } from "./images/moduleIcon.svg";

export const Config = {
  moduleName: "notifications",
  dependencies: [],
  label: "notifications.pageTitle",
  Icon: ModuleIcon,
  urls: ["/powiadomienia"],
  routeName: "",
  options: {},
  theme: {},
};
