import { createSelector } from "reselect";
import { prop } from "ramda";

export const selectTeamIndicatorsDomain = prop("teamIndicators");

export const selectIsPending = createSelector(
  selectTeamIndicatorsDomain, prop("isPending"),
);

export const selectChartList = createSelector(
  selectTeamIndicatorsDomain, prop("charts"),
);

export const selectStatsLoading = createSelector(
  selectTeamIndicatorsDomain, prop("statsLoading"),
);

export const selectStatsData = createSelector(
  selectTeamIndicatorsDomain, prop("statsData"),
);

export const selectStatsTeam = createSelector(
  selectTeamIndicatorsDomain, prop("statsTeam"),
);

export const selectStatsTeamCount = createSelector(
  selectTeamIndicatorsDomain, prop("statsTeamCount"),
);
