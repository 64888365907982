import styled from "styled-components";

import { breakpoints } from "../../UIGlobals";
import { Color, FontWeight, Transitions } from "../../theme";

export const QuestionTitle = styled.h1`
  font-size: 20px;
  line-height: 25px;
  font-weight: ${FontWeight.Bold};
`;

export const QuestionSubtitle = styled.p`
  font-size: 14px;
  margin-top: 10px;
  color: ${Color.gray9};
`;

export const WarnText = styled.p`
  position: absolute;
  top: -25px;
  color: ${Color.error};
  font-size: 12px;
  font-weight: ${FontWeight.SemiBold};
`;

export const Header = styled.div`
  margin: 50px 0;
`;

export const QuestionAnswer = styled.div`
  font-size: 1.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1.43;
  color: ${({ selected }) => (selected ? Color.black : Color.deepGray)};
  background: ${props =>
  props.error ? Color.lightPink
    : props.selected ? (props.preview ? (props.correct ? Color.veryLightGreen : Color.lightPink) : "#e4e5e9") : Color.almostWhite};
  padding: 44px 80px 41px 80px;
  margin-bottom: 2px;
  width: 100%;
  cursor: ${({ preview }) => (preview ? "default" : "pointer")};
  position: relative;
  transition: ${Transitions.custom};

  &:hover {
    opacity: ${({ selected, preview }) => (selected && !preview ? 1 : 0.7)};
  }

  &:before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    background: ${Color.white};
    border: 1px solid #e2e2e2;
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: ${props => (props.multi ? "" : "16px")};
  }

  &:after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background: ${Color.black};
    opacity: ${props => (props.selected ? 1 : 0)};
    position: absolute;
    left: 31px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: ${props => (props.multi ? "" : "16px")};
  }

  i {
    position: absolute;
    right: 55px;
    top: 47px;
  }
`;

export const QuestionButtons = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 4rem 0;

  @media (${breakpoints().maxM}) {
    width: 100%;
  }
`;
