import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { ButtonColor } from "../../../../components/button/button.constants";
import { selectCurrentRoom } from "../../redux/webinars.selectors";

import {
  Container,
  DevicesIcon,
  IconContainer,
  Content,
  Header,
  Text,
  Button,
} from "./mobilePlaceholder.styled";

export const MobilePlaceholder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const room = useSelector(selectCurrentRoom);

  const goToTrainingPage = () => {
    navigate(`/szkolenia/${room.eventId}`);
  };

  return (
    <Container>
      <IconContainer>
        <DevicesIcon />
      </IconContainer>
      <Content>
        <Header>{t("webinars.classes.attention")}</Header>
        <Text>{t("webinars.classes.changeDevice")}</Text>
        <Button color={ButtonColor.Primary} $large onClick={goToTrainingPage}>
          {t("webinars.classes.close")}
        </Button>
      </Content>
    </Container>
  );
};
