import React from "react";

import { Container } from "./switch.styled";

export const Switch = ({ className, checked, disabled, onChange }) => {
  return (
    <Container
      className={className}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
  );
};
