import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { PointsMark, PointsMarkIcon } from "../../../../../components/challengeElement/challengeElement.styled";
import { ButtonColor } from "../../../../../components/button/button.constants";
import { useMediaListener } from "../../../../../hooks/useMediaListener";

import { TaskType } from "./challengeDropdownContent.constants";
import {
  DropdownContent,
  DropdownRow,
  RowTitle,
  RowValue,
  DescriptionWrapper,
  DescriptionHeader,
  DescriptionLabel,
  Button,
} from "./challengeDropdownContent.styled";

export const ChallengeDropdownContent = ({
  points,
  earnedPoints,
  description,
  item,
  type,
  isButtonDisabled,
  customButtonText,
}) => {
  const { t } = useTranslation();
  const { isTablet } = useMediaListener();
  const navigate = useNavigate();
  const descriptionFormatted = description
    ? description.split("\n").map((text, index) => (
      <React.Fragment key={index}>
        {text}
        <br />
      </React.Fragment>
    ))
    : "";

  const handleButtonClick = () => {
    switch (item.type) {
    case TaskType.Task:
      navigate(`/gamification/task/${item.id}`);
      break;
    case TaskType.Survey:
      navigate(`/formularze/${item.id}`);
      break;
    case TaskType.Questionnaire:
      navigate(`/gamification/survey/${item.id}`);
      break;
    default:
      break;
    }
  };

  return (
    <DropdownContent>
      {isTablet && (
        <DescriptionWrapper>
          <DescriptionHeader>{t("gamification.challengeElement.description")}</DescriptionHeader>
          <DropdownRow>
            <DescriptionLabel>{descriptionFormatted}</DescriptionLabel>
            <Button disabled={isButtonDisabled} onClick={handleButtonClick} color={ButtonColor.Primary}>
              {customButtonText || t("gamification.challengeElement.start")}
            </Button>
          </DropdownRow>
        </DescriptionWrapper>
      )}
      {!isTablet && (
        <>
          <DropdownRow>
            <RowTitle>{t("gamification.challengeElement.coins")}</RowTitle>
            <PointsMark bright={!!earnedPoints}>
              <PointsMarkIcon />
              {earnedPoints ? `+${earnedPoints}` : points}
            </PointsMark>
          </DropdownRow>
          <DropdownRow>
            <RowTitle>{t("gamification.challengeElement.type")}</RowTitle>
            <RowValue>{type}</RowValue>
          </DropdownRow>
          <DescriptionWrapper>
            <DescriptionHeader>{t("gamification.challengeElement.description")}</DescriptionHeader>
            <DescriptionLabel>{descriptionFormatted}</DescriptionLabel>
          </DescriptionWrapper>
          <Button disabled={isButtonDisabled} onClick={handleButtonClick} color={ButtonColor.Primary}>
            {customButtonText || t("gamification.challengeElement.start")}
          </Button>
        </>
      )}
    </DropdownContent>
  );
};

ChallengeDropdownContent.propTypes = {
  description: PropTypes.string,
  points: PropTypes.number,
  earnedPoints: PropTypes.number,
  item: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string.isRequired,
  }),
  type: PropTypes.string,
  isButtonDisabled: PropTypes.bool,
  customButtonText: PropTypes.string,
};
