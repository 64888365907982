import { all, put, takeLatest } from "redux-saga/effects";

import { KnowledgeBaseActions } from "../../modules/knowledgeBase/redux/knowledgeBase.reducer";
import { DashboardActions } from "../../modules/dashboard/redux/dashboard.reducer";
import { AppSettingsActions } from "../../appSettings/redux/appSettings.reducer";
import { UserContextActions } from "../../userContext/redux/userContext.reducer";
import { LessonActions } from "../../lesson/redux/lesson.reducer";
import { store } from "../../store";
import { common } from "../../api";

import { CommonTypes, CommonActions } from "./common.reducer";

function* fetchVisibility() {
  const { data } = yield common.getVisibility();
  yield put(CommonActions.fetchVisibilitySuccess(data.data));
}

function* userProfileEditConfirm() {
  yield common.userProfileEditConfirm();
  yield put(UserContextActions.confirmEditProfileSuccess());
}

function* sendRegulationsConfirmation({ agreement }) {
  yield common.sendRegulationsConfirmation(agreement.id);
  yield put(UserContextActions.confirmRegulationsSuccess(agreement.agreementType));
}

function* updateUserAgreements({ agreements }) {
  yield common.updateUserAgreements(agreements);
  yield put(UserContextActions.confirmAgreementsSuccess());
}

function* fetchBrands() {
  const { data } = yield common.getBrands();
  yield put(CommonActions.fetchBrandsSuccess(data));

  const currentBrand = store.getState().appSettings.currentBrand;

  if (
    data?.length === 1
    || currentBrand !== null && data?.filter((brand) => brand.id === currentBrand).length !== 1
  ) {
    yield put(AppSettingsActions.selectBrand(data[0].id));
  }
}

function* fetchPaidVideos() {
  const { data } = yield common.getPaidVideos();
  yield put(CommonActions.fetchPaidVideosSuccess(data));
}

function* unlockVideo({ code }) {
  const { data } = yield common.unlockVideo(code);
  if (data.message) {
    yield put(CommonActions.unlockVideoFailure(data.message));
  } else {
    yield put(CommonActions.unlockVideoSuccess(data));
  }
}

function* fetchNotifications() {
  const { data } = yield common.fetchNotifications();
  yield put(CommonActions.fetchNotificationsSuccess(data));
}

function* fetchFavouriteItems() {
  const { data } = yield common.fetchFavouriteItems();
  yield put(CommonActions.fetchFavouriteItemsSuccess(data));
}

function* addFavouriteItem({ materialType, id }) {
  const { data } = yield common.addFavouriteItem(materialType, id);
  if (data?.success) {
    yield fetchFavouriteItems();
    yield put(KnowledgeBaseActions.setListItemFavorite(id, true));
    yield put(DashboardActions.setListItemFavorite(id, true));
    yield put(CommonActions.setFavouriteToastStatus(true));
  }
}

function* removeFavouriteItem({ materialType, id }) {
  const { data } = yield common.removeFavouriteItem(materialType, id);
  if (data?.success) {
    yield fetchFavouriteItems();
    yield put(KnowledgeBaseActions.setListItemFavorite(id, false));
    yield put(DashboardActions.setListItemFavorite(id, false));
    yield put(CommonActions.setFavouriteToastStatus(false));
  }
}

function* fetchPermissions() {
  const { data } = yield common.fetchPermissions();
  if (data) {
    yield put(UserContextActions.fetchPermissionsSuccess(data));
  }
}

function* fetchUserData() {
  const { data } = yield common.fetchUserData();
  if (data) {
    yield put(UserContextActions.fetchUserDataSuccess(data));
  }
}

function* editLessonComment({ comment }) {
  const { data } = yield common.editComment(comment);
  yield put(LessonActions.editCommentSuccess(data.data));
}

function* deleteLessonComment({ commentId }) {
  const { data } = yield common.deleteComment(commentId);
  yield put(LessonActions.deleteCommentSuccess(data.data));
}

function* changeUserPhoneNumber({ phoneNumber }) {
  const data = yield common.changeUserPhoneNumber(phoneNumber);
  if (data?.status === 204) {
    yield put(UserContextActions.changeUserPhoneNumberSuccess(phoneNumber));
  }
}

function* setNewPassword({ oldPassword, newPassword, onSuccess, onFailure }) {
  const { data } = yield common.updatePassword(oldPassword, newPassword);

  if (data.success) {
    onSuccess();
  } else {
    onFailure(data.errors ? Object.values(data.errors)[0] : data.message);
  }
}

export function* watchCommon() {
  yield all([
    takeLatest(CommonTypes.FETCH_VISIBILITY, fetchVisibility),
    takeLatest(CommonTypes.FETCH_BRANDS, fetchBrands),
    takeLatest(CommonTypes.UNLOCK_VIDEO, unlockVideo),
    takeLatest(CommonTypes.FETCH_PAID_VIDEOS, fetchPaidVideos),
    takeLatest(CommonTypes.FETCH_NOTIFICATIONS, fetchNotifications),
    takeLatest(CommonTypes.USER_PROFILE_EDIT_CONFIRM, userProfileEditConfirm),
    takeLatest(CommonTypes.SEND_REGULATIONS_CONFIRMATION, sendRegulationsConfirmation),
    takeLatest(CommonTypes.UPDATE_USER_AGREEMENTS, updateUserAgreements),
    takeLatest(CommonTypes.FETCH_FAVOURITE_ITEMS, fetchFavouriteItems),
    takeLatest(CommonTypes.ADD_FAVOURITE_ITEM, addFavouriteItem),
    takeLatest(CommonTypes.REMOVE_FAVOURITE_ITEM, removeFavouriteItem),
    takeLatest(CommonTypes.FETCH_PERMISSIONS, fetchPermissions),
    takeLatest(CommonTypes.FETCH_USER_DATA, fetchUserData),
    takeLatest(CommonTypes.EDIT_LESSON_COMMENT, editLessonComment),
    takeLatest(CommonTypes.DELETE_LESSON_COMMENT, deleteLessonComment),
    takeLatest(CommonTypes.CHANGE_USER_PHONE_NUMBER, changeUserPhoneNumber),
    takeLatest(CommonTypes.SET_NEW_PASSWORD, setNewPassword),
  ]);
}
