import React, { useEffect, useCallback } from "react";
import { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { Loader } from "../../components/loader/loader.component";
import { ThumbnailList } from "../../components/thumbnailList/thumbnailList.component";
import { renderWhenTrue, renderWhenTrueOtherwise } from "../../helpers/rendering";
import { Separator } from "../../theme";
import { AppConfig } from "../../appConfig";
import { useModuleConfig, ModuleType } from "../";

import { VideoSection } from "./components/videoSection/videoSection.component";
import { VideoPlayerActions } from "./redux/videoPlayer.reducer";
import {
  selectRelatedVideos,
  selectVideoStructure,
  selectVideoComments,
  selectInstanceId,
  selectVideoName,
  selectVideoTags,
  selectIsPending,
} from "./redux/videoPlayer.selectors";
import {
  Container,
  CommentsWrapper,
  CommentCounter,
  CommentList,
  RelatedVideosWrapper,
} from "./videoPlayer.styled";

const VideoPlayer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const config = useModuleConfig(ModuleType.VideoPlayer);
  const relatedVideos = useSelector(selectRelatedVideos);
  const videoStructure = useSelector(selectVideoStructure);
  const comments = useSelector(selectVideoComments);
  const instanceId = useSelector(selectInstanceId);
  const title = useSelector(selectVideoName);
  const isPending = useSelector(selectIsPending);
  const tags = useSelector(selectVideoTags);
  const videoSrc = videoStructure.url.startsWith("http")
    ? videoStructure.url
    : videoStructure.url.startsWith("/")
      ? `${AppConfig.content}${videoStructure.url}`
      : `${AppConfig.content}/${videoStructure.url}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const contentId = params.id;
    dispatch(VideoPlayerActions.getInitialData(contentId));
    dispatch(VideoPlayerActions.getRelatedVideos(contentId));
    dispatch(VideoPlayerActions.getComments(contentId));

    return () => dispatch(VideoPlayerActions.clearData());
  }, [dispatch, params.id]);

  const submitComment = useCallback((values, actions) => {
    const comment = { ...values, contentId: params.id };
    dispatch(VideoPlayerActions.sendComment(comment, () => {
      actions.setSubmitting(false);
      actions.resetForm();
    }));
  }, [dispatch, params.id]);

  const editComment = (comment) => {
    dispatch(VideoPlayerActions.editComment(comment));
  };

  const deleteComment = (commentId) => {
    dispatch(VideoPlayerActions.deleteComment(commentId));
  };

  const handleFinishAction = () => {
    dispatch(VideoPlayerActions.sendFinishAction(instanceId, videoStructure.action));
  };

  const renderRelatedVideos = renderWhenTrue(() => (
    <RelatedVideosWrapper>
      <ThumbnailList title={t("lesson.related.alsoCheck")} thumbnails={relatedVideos} />
      <Separator />
    </RelatedVideosWrapper>
  ));

  const renderCommentCounter = () => {
    const label = comments.length === 0 || comments.length > 4
      ? t("lesson.comments.commentsLabel_1")
      : comments.length === 1
        ? t("lesson.comments.commentsLabel_2")
        : t("lesson.comments.commentsLabel_3");

    return `${comments.length} ${label}`;
  };

  const renderContent = () => (
    <>
      <VideoSection
        videoSrc={videoSrc}
        onFinish={handleFinishAction}
        title={title}
        timelineDisabled={!videoStructure.passed}
        tags={tags}
      />
      <CommentsWrapper $withRelatedVideos={!!relatedVideos.length}>
        {renderRelatedVideos(!!relatedVideos.length)}
        <CommentCounter>
          {renderCommentCounter()}
        </CommentCounter>
        <CommentList
          comments={comments}
          hideDisabled
          onSubmit={submitComment}
          onEdit={editComment}
          onDelete={deleteComment}
        />
      </CommentsWrapper>
    </>
  );

  const renderPage = renderWhenTrueOtherwise(
    () => <Loader isLesson />,
    renderContent,
  );

  return (
    <ThemeProvider theme={config.theme}>
      <Container>
        {renderPage(isPending)}
      </Container>
    </ThemeProvider>
  );
};

export default VideoPlayer;
