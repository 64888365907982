export const IconType = {
  Task: "task",
  Survey: "survey",
  Content: "content",
  Questionnaire: "questionnaire",
};

export const ChallengeType = {
  Survey: "survey",
  Task: "task",
  Questionnaire: "questionnaire",
};

export const ChallengeStatus = {
  InVerification: "in_verification",
  Rated: "rated",
  Available: "available",
};
