import styled from "styled-components";

import { Button } from "../../../../components/button/button.component";
import { FontWeight, Media, Separator as SeparatorBase } from "../../../../theme";

export const Container = styled.div`
  padding: 25px 20px;

  ${Media.tablet`
    padding: 86px 100px;
  `}
`;

export const Title = styled.p`
  font-weight: ${FontWeight.Light};
  font-size: 32px;
  line-height: 43px;

  ${Media.tablet`
    font-size: 47px;
    line-height: 57px;
  `}
`;

export const Section = styled.div`
  margin-top: 30px;

  ${Media.tablet`
    margin-top: 40px;
  `}
`;

export const SectionHeader = styled.p`
  font-weight: ${FontWeight.Bolder};
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

export const Separator = styled(SeparatorBase)`
  margin-top: 0;
  margin-bottom: 0;
`;

export const InputRow = styled.div`
  ${Media.tablet`
    display: flex;
  `}
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const SwitchLabel = styled.p`
  font-size: 14px;
  line-height: 17px;
  margin-right: 20px;
`;

export const Buttons = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column-reverse;

  ${Media.tablet`
    margin-top: 60px;
    flex-direction: row;
    align-items: center;
  `}
`;

export const CancelButton = styled(Button)`
  margin-top: 20px;
  width: 100%;

  ${Media.tablet`
    margin-top: 0;
    margin-right: 10px;
    min-width: 200px;
    width: auto;
  `}
`;

export const EnrollButton = styled(Button)`
  min-width: 200px;
  width: 100%;

  ${Media.tablet`
    width: auto;
  `}
`;
