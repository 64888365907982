import React, { useState } from "react";
import PropTypes from "prop-types";

import { ProgressMark } from "../../../../components/progressMark/progressMark.component";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { ArrowIcon } from "../competenceTab/competenceTab.styled";
import { CompetenceMeasureDropdown } from "../competenceMeasureDropdown/competenceMeasureDropdown.component";

import { DropdownToggler, Title } from "./competenceMeasureItem.styled";

export const CompetenceMeasureItem = ({ skill, rate, competenceId }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const skillRate = rate && rate.rate;
  const skillMaxRate = rate && rate.maxRate;

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const renderDropdown = renderWhenTrue(() => (
    <CompetenceMeasureDropdown
      skill={skill}
      rate={rate}
      competenceId={competenceId}
    />
  ));

  return (
    <>
      <DropdownToggler onClick={toggleDropdown}>
        <ProgressMark rate={skillRate} maxRate={skillMaxRate} />
        <Title>{skill.name}</Title>
        <ArrowIcon open={dropdownVisible} />
      </DropdownToggler>
      {renderDropdown(dropdownVisible)}
    </>
  );
};

CompetenceMeasureItem.propTypes = {
  skill: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    rateType: PropTypes.object,
    weight: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
  }),
  rate: PropTypes.shape({
    rate: PropTypes.number.isRequired,
    maxRate: PropTypes.number.isRequired,
    date: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    author: PropTypes.string.isRequired,
  }),
  competenceId: PropTypes.number.isRequired,
};
