import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Color } from "../../../theme/colors";
import { Media } from "../../../theme/media";
import { styleWhenTrue } from "../../../helpers/rendering";

import { Opponent as OpponentBase } from "./components/opponent/opponent.component";
import { Question as QuestionBase } from "./components/question/question.component";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${Color.gray5};
  margin-top: 60px;

  ${Media.tablet`
    flex-direction: row;
    min-height: 650px;
  `}

  ${styleWhenTrue(prop("$withBackground"), css`
    background-color: ${Color.almostWhite};
  `)}
`;

export const Opponent = styled(OpponentBase)`
  flex: 1;
  border-right: 1px solid ${Color.gray5};
`;

export const Question = styled(QuestionBase)`
  flex: 3;
`;
