import styled from "styled-components";

import { FontWeight, Color, Media } from "../../../../theme";
import { hexToRgba } from "../../../../helpers/colors";

export const Box = styled.div`
  padding: 35px 25px 0;
  background-color: ${Color.white};
  box-shadow: 0px 5px 10px ${hexToRgba(Color.deepGray, 0.01)};
  width: 100%;

  ${Media.tablet`
    padding: 35px 35px 0;
  `}
`;

export const BoxText = styled.p`
  font-size: 16px;
  line-height: 26px;
  color: ${Color.deepGray};
  margin-bottom: 35px;
`;

export const DropdownTogglePanel = styled.div`
  border-top: 1px solid ${Color.gray5};
  padding: 35px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Text = styled.p`
  font-size: 15px;
  line-height: 20px;
  font-weight: ${FontWeight.SemiBold};
  margin: 0 30px 0 20px;
  color: ${Color.blue3};

  ${Media.tablet`
    margin: 0 20px;
  `}
`;
