/* eslint-disable react/no-string-refs */
import React, { Component } from "react";
import i18n from "i18next";

import { Container } from "./showTextImage.styled";

export default class ShowTextImage extends Component {
  constructor() {
    super();
    this.state = { elements: [] };
  }

  getInitialState() {
    let configData = this.props.resources.config;

    const interactionsConfig = (this.interactionsConfig = configData.elements);
    this.attempts = null;
    this.initialState = {
      elements: interactionsConfig,
      slideType: configData.slideType,
      boxStyle: interactionsConfig[0].boxStyle
    };

    return Object.assign({}, this.initialState);
  }

  parseElements() {
    const { resolveUrl } = this.props.resources;
    const images = this.state.elements;

    images.map((item, index) => {
      if (item.type === "image" && item.parsed === undefined) {
        images[index].content = resolveUrl("assets/" + item.content);
      }
      item.parsed = true;
      return 0;
    });
    this.setState({ elements: images });
  }

  componentDidMount() {
    fetch(this.props.baseUrl + this.props.config)
      .then(response => response.json())
      .then(jresp => {
        this.initialState = {
          elements: jresp.elements,
          boxStyle: jresp.elements[0].boxStyle,
          alignment: jresp.alignment
        };
        this.setState(this.initialState);
      });
  }

  imageHtml(index, element) {
    const elementStyle = {
      left: element.pos.left,
      top: element.pos.top,
      position: element.pos.position,
      width: element.pos.width
    };
    const delay = element.delay !== undefined ? element.delay + "s" : "0." + index * 2 + "s";

    const AnimationClass = "bg-img slide-img " + (element.className !== undefined ? element.className : "show1");

    const imageStyle = {
      animationDelay: delay
    };
    return (
      <div key={index} className="col js-element" type="image" style={elementStyle}>
        {element.link ? (
          <a href={element.link} target="_blank" rel="noopener noreferrer">
            <img
              className={AnimationClass}
              src={this.props.baseUrl + "assets/" + element.content}
              style={imageStyle}
              alt=""
            />
          </a>
        ) : (
          <img
            className={AnimationClass}
            src={this.props.baseUrl + "assets/" + element.content}
            style={imageStyle}
            alt=""
          />
        )}
      </div>
    );
  }

  textHtml(index, element) {
    const elementStyle = {
      left: element.pos.left,
      top: element.pos.top
    };

    const HeadlineClass = "headline " + (element.className !== undefined ? element.className : "show1");

    const imageStyle = {
      animationDelay: "0." + index * 2 + "s"
    };
    return (
      <div key={index} className="col-12 js-element" type={"text"} style={elementStyle}>
        <h3 className={HeadlineClass} style={imageStyle}>
          {element.content}{" "}
        </h3>
      </div>
    );
  }

  paragraphHtml(index, element) {

    const imageStyle = {
      animationDelay: "0." + index * 2 + "s"
    };
    return (
      <div key={index} className="paragraph-container js-element" type={"text"} >
        <h3 className={"paragraph-header"} style={imageStyle}>
          {element.header}{" "}
        </h3>
        <p className={"paragraph-content"}>
          {element.content}
        </p>
      </div>
    );
  }

  render() {
    let imageHtml = this.imageHtml;
    let textHtml = this.textHtml;
    let paragraphHtml = this.paragraphHtml;
    const elements = this.state.elements.map((item, index) => {
      switch (item.type) {
      case "image":
        return imageHtml.bind(this)(index, item);
      case "text":
        return textHtml.bind(this)(index, item);
      case "paragraph":
        return paragraphHtml.bind(this)(index, item);
      default:
        return textHtml.bind(this)(index, item);
      }
    });

    return (
      <Container>
        <div className={"wrapper " + this.state.alignment}  ref="WrapNode">
          {elements}
        </div>
        <div className="button-wrap">
          <button className="elearning-button btn-pos-abs" onClick={this.props.callComplete}>
            { i18n.t("interactions.watched") }
          </button>
        </div>
      </Container>
    );
  }
}
