import styled from "styled-components";

import { Button as ButtonBase } from "../../../../components/button/button.component";
import { hexToRgba } from "../../../../helpers/colors";
import { Color, ContentMaxWidth, Media, Separator as SeparatorBase } from "../../../../theme";

export const Container = styled.div`
  padding: 40px 20px;
  background-color: ${Color.almostWhite};
  box-shadow: 0 5px 10px ${hexToRgba(Color.deepGray, 0.01)};

  ${Media.tablet`
    padding: 40px 80px;
  `}

  ${Media.desktop`
    padding: 40px 160px;
  `}
`;

export const FieldsContainer = styled.div`
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
  background-color: ${Color.white};
  padding: 35px;

  ${Media.tablet`
    padding: 60px 100px 100px;
  `}

  ${Media.tabletWide`
    padding: 60px 120px 100px;
  `}

  ${Media.desktop`
    padding: 60px 180px 100px;
  `}
`;

export const FieldsWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

export const SectionTitle = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: ${Color.deepGray};
  letter-spacing: 0.6px;
  font-weight: 800;
  text-transform: uppercase;
`;

export const Separator = styled(SeparatorBase)`
  && {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 600px;
  }
`;

export const SwitchRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  max-width: 600px;
  justify-content: space-between;
`;

export const SwitchLabel = styled.p`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  font-weight: 300;
`;

export const Button = styled(ButtonBase)`
  margin-top: 20px;
  min-width: 200px;
`;

export const Message = styled.p`
  margin-top: 5px;
  display: flex;
  width: fit-content;
  background-color: ${Color.lightYellow};
  font-size: 12px;
  line-height: 16px;
  padding: 12px 15px;
  border-radius: 2px;
`;
