import { createActions, createReducer } from "reduxsauce";

export const { Types: AnonSurveyTypes, Creators: AnonSurveyActions } = createActions(
  {
    getQuestions: ["token"],
    getQuestionsSuccess: ["data"],
    setIsPending: ["isPending"],
    sendAnswers: ["token", "answers"],
    sendAnswersSuccess: null,
    setNotFoundPage: null,
  },
  { prefix: "ANON_SURVEY/" }
);

const INITIAL_STATE = {
  isPending: false,
  startHeader: "",
  startText: "",
  questions: [],
  completed: false,
  notFound: false,
};

const getQuestionsSuccess = (state, { data }) => ({
  ...state,
  startHeader: data[0].title,
  startText: data[0].text,
  questions: data,
});

const sendAnswersSuccess = () => ({
  ...INITIAL_STATE,
  completed: true,
});

const setNotFoundPage = () => ({
  ...INITIAL_STATE,
  notFound: true,
});

const setIsPending = (state, { isPending }) => ({ ...state, isPending });

export const reducer = createReducer(INITIAL_STATE, {
  [AnonSurveyTypes.GET_QUESTIONS_SUCCESS]: getQuestionsSuccess,
  [AnonSurveyTypes.SET_IS_PENDING]: setIsPending,
  [AnonSurveyTypes.SEND_ANSWERS_SUCCESS]: sendAnswersSuccess,
  [AnonSurveyTypes.SET_NOT_FOUND_PAGE]: setNotFoundPage,
});
