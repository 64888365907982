import React from "react";
import MediaQuery from "react-responsive";
import { useTranslation } from "react-i18next";

import { breakpoints } from "../../../../UIGlobals";

import { Row, Col, Text } from "./agenda.styled";

export const Agenda = ({ agenda }) => {
  const { t } = useTranslation();

  return agenda.map((details, index) => (
    <React.Fragment key={index}>
      <MediaQuery query={`(${breakpoints().maxM})`}>
        <Row alignItems="flex-start" $flexDirection="column">
          <Col width="100%">
            <Text color="black" fontSize={1.4} paddingBottom={30} paddingTop={30}>
              {t("training.agenda.day")} {index + 1}
            </Text>
          </Col>
          <Col width="100%" $flexDirection="column">
            {details.map((detail, id) => (
              <Row key={id}>
                <Col width={detail.displayTimeStart && detail.displayTimeEnd ? "85px" : "0"}>
                  <Text fontSize={1.4} color="gray4">
                    {detail.displayTimeStart && detail.displayTimeEnd && (
                      <span>{detail.from} - {detail.to}</span>
                    )}
                  </Text>
                </Col>
                <Col width="calc(100% - 105px)" marginLeft={detail.displayTimeStart && detail.displayTimeEnd ? 20 : 0}>
                  <Text fontSize={1.4} color="gray1">
                    {detail.name}
                  </Text>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </MediaQuery>
      <MediaQuery query={`(${breakpoints().minM})`}>
        <Row alignItems="flex-start">
          <Col width="25%">
            <Text color="black" fontSize={1.4}>
              {t("training.agenda.day") } {index + 1}
            </Text>
          </Col>
          <Col width="75%" $flexDirection="column">
            {details.map((detail,id) => (
              <Row key={id} >
                <Col width="80">
                  <Text fontSize={1.4} color="gray4">
                    {detail.displayTimeStart && detail.displayTimeEnd && (
                      <span>{detail.from} - {detail.to}</span>
                    )}
                  </Text>
                </Col>
                <Col width="calc(75% - 80px)" marginLeft={detail.displayTimeStart && detail.displayTimeEnd ? 20 : 0}>
                  <Text fontSize={1.4} color="gray4">
                    {detail.name}
                  </Text>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </MediaQuery>
    </React.Fragment>
  ));
};
