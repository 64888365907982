import styled from "styled-components";

import {
  ContentMaxWidth,
  Media,
  FontWeight,
  Separator as SeparatorBase,
} from "../../../../../theme";

export const Container = styled.div`
  padding: 60px 20px 0;

  ${Media.tablet`
    padding: 40px 25px 0;
  `}

  ${Media.desktop`
    padding: 87px 80px 0;
  `}
`;

export const Wrapper = styled.div`
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;

  ${Media.tablet`
    flex-direction: row;
  `}
`;

export const SectionTitle = styled.h4`
  font-weight: ${FontWeight.ExtraBold};
  font-size: 24px;
  line-height: 20px;
  flex: 1;

  ${Media.tablet`
    font-size: 34px;
    line-height: 46px;
  `}
`;

export const Text = styled.p`
  flex: 1.5;
  font-size: 16px;
  line-height: 26px;
  margin-top: 30px;

  ${Media.tablet`
    margin-top: 0;
    font-size: 20px;
    line-height: 34px;
  `}
`;

export const Separator = styled(SeparatorBase)`
  && {
    margin: 60px 0;

    ${Media.tablet`
      margin: 120px 0;
    `}
  }
`;

export const RulesWrapper = styled.div`
  flex: 1.5;
  margin-bottom: 50px;
`;

export const RulesRow = styled.div`
  display: flex;
  flex-direction: column;

  ${Media.tablet`
    flex-direction: row;
    margin-bottom: 75px;
  `}
`;
