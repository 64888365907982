import styled from "styled-components";

import { ReactComponent as MovieIconBase } from "../../../../images/video2.svg";
import { Color } from "../../../../theme";

export const Video = styled.video`
  max-height: unset;
`;
export const MovieIcon = styled(MovieIconBase)`
  polygon {
    fill: ${() => Color.brandBasic};
  }
  path {
    stroke: ${() => Color.brandBasic};
  }
`;
