import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { selectUserToken } from "../../../../userContext/redux/userContext.selectors";
import NetworkErrorModal from "../../../../app/networkErrorModal";
import { DetectorHandler, OfflineHandler } from "../../../../helpers/connectionHandler";
import { AppConfig } from "../../../../appConfig";

import {
  Container,
  Title,
  IconWrapper,
  Icon,
  Date,
  TextWrapper,
} from "./fileItem.styled";

export const FileItem = ({ id, name, timestamp, uriType }) => {
  const userToken = useSelector(selectUserToken);
  const [offlineMode, setOfflineMode] = useState(false);

  const handleOnClick = (online) => {
    if (online) {
      window.open(`${AppConfig.api}/download/${uriType}/${id}?X-AUTH-TOKEN=${userToken}`);
    } else {
      setOfflineMode(true);
    }
  };

  return (
    <DetectorHandler render={({ online }) => (
      <Container onClick={() => handleOnClick(online)}>
        {offlineMode && (
          <OfflineHandler>
            <NetworkErrorModal isOpen />
          </OfflineHandler>
        )}
        <IconWrapper>
          <Icon />
        </IconWrapper>
        <TextWrapper>
          <Title>{name}</Title>
          <Date>{dayjs.unix(timestamp).format("DD.MM.YYYY")}</Date>
        </TextWrapper>
      </Container>
    )} />
  );
};

FileItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
  uriType: PropTypes.string.isRequired,
};
