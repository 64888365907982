import translationPL from "./pl/default.json";
import rodoInformationPL from "./pl/rodoInformation.json";
import translationEN from "./en/default.json";
import rodoInformationEN from "./en/rodoInformation.json";

export const LanguageTheme = {
  Multi: "multi",
  PL: "pl",
};

export const ResourcesMultilingual = {
  pl: {
    translation: translationPL,
    rodoInformation: rodoInformationPL,
  },
  en: {
    translation: translationEN,
    rodoInformation: rodoInformationEN,
  },
};

export const ResourcesPL = {
  pl: {
    translation: translationPL,
    rodoInformation: rodoInformationPL,
  },
};
