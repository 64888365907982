import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { Loader } from "../../components/loader/loader.component";
import { CookieName } from "../../app/cookiePopup/cookiePopup.constants";
import { isCookieEnabled } from "../../helpers/isCookieEnabled";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useModuleConfig, ModuleType } from "../";

import { TrainingList } from "./components/trainingList/trainingList.component";
import { Calendar } from "./components/calendar/calendar.component";
import { Search } from "./components/search/search.component";
import { EmptyResult } from "./components/emptyResult/emptyResult.component";
import { TrainingsActions } from "./redux/trainings.reducer";
import { selectDateRange, selectEvents, selectIsInitialDataPending } from "./redux/trainings.selectors";
import { TrainingsView, TrainingsViewStorageKey } from "./trainings.constants";
import {
  Container,
  Wrapper,
  Header,
  PageHeader,
  HeaderRightSide,
  ToggleViewButtons,
  ViewText,
  ToggleViewButton,
  SquaresIcon,
  ListIcon,
  CalendarIcon,
  SearchWrapper,
} from "./trainings.styled";
import { compareDateLabels, filterEvents, getDateLabel, getDurationInDays } from "./trainings.utils";

const Trainings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const config = useModuleConfig(ModuleType.Trainings);
  const [viewTypeStorageName, setViewTypeStorageName] = useLocalStorage(TrainingsViewStorageKey);
  const isPending = useSelector(selectIsInitialDataPending);
  const events = useSelector(selectEvents);
  const dateRange = useSelector(selectDateRange);
  const [viewType, setViewType] = useState(viewTypeStorageName || TrainingsView.Default);
  const [searchText, setSearchText] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const filteredEvents = useMemo(() => (
    filterEvents(events, searchText, fromDate, toDate, selectedCategories, selectedTypes)
  ), [events, searchText, fromDate, toDate, selectedCategories, selectedTypes]);
  const eventCategories = useMemo(() => filteredEvents?.reduce((categories, event) => (
    categories.includes(event.course.type.id) ? categories : [...categories, event.course.type.id]
  ), []), [filteredEvents]);
  const eventDates = useMemo(() => filteredEvents?.reduce((dates, event) => {
    const date = getDateLabel(event);
    return dates.includes(date) ? dates : [...dates, date];
  }, []).sort(compareDateLabels), [filteredEvents]);
  const calendarEvents = useMemo(() => events?.map(({
    id,
    course,
    cyclicalInfo,
    date,
    location,
    availableEnrolments,
  }) => {
    const timestampStart = cyclicalInfo ? cyclicalInfo.nextTimestampStart : date.from;
    const timestampEnd = cyclicalInfo ? cyclicalInfo.nextTimestampEnd : date.to;
    const isMultipleDays = getDurationInDays(timestampStart, timestampEnd) > 1;

    return {
      title: course.name,
      allDay: isMultipleDays,
      start: timestampStart * 1000,
      end: isMultipleDays ? (timestampEnd + 86400) * 1000 : timestampEnd * 1000,
      id,
      extendedProps: {
        location: course.isOnline ? t("trainings.calendar.online") : location?.city || t("trainings.eventList.noPlace"),
        seatsAvailable: availableEnrolments,
        categoryEnum: course.category?.enum,
        startDate: timestampStart * 1000,
        endDate: timestampEnd * 1000,
      }
    };
  }), [events]);

  useEffect(() => {
    dispatch(TrainingsActions.fetchEvents());

    return () => {
      dispatch(TrainingsActions.clearEvents());
    };
  }, []);

  useEffect(() => {
    if (dateRange) {
      setFromDate(new Date(dateRange.from));
      setToDate(new Date(dateRange.to));
    }
  }, [dateRange]);

  const handleSetViewType = (viewType) => {
    setViewType(viewType);
    if (isCookieEnabled(CookieName.Functional)) setViewTypeStorageName(viewType);
  };

  return (
    <ThemeProvider theme={config.theme}>
      <Container>
        {isPending || !events ? (
          <Loader />
        ) : (
          <Wrapper>
            <Header>
              <PageHeader>{t(config.label)}</PageHeader>
              <HeaderRightSide>
                <ToggleViewButtons>
                  <ViewText>{t("trainings.view")}</ViewText>
                  <ToggleViewButton onClick={() => handleSetViewType(TrainingsView.Default)} active={viewType === TrainingsView.Default}>
                    <SquaresIcon />
                  </ToggleViewButton>
                  <ToggleViewButton onClick={() => handleSetViewType(TrainingsView.DateList)} active={viewType === TrainingsView.DateList}>
                    <ListIcon />
                  </ToggleViewButton>
                  <ToggleViewButton onClick={() => handleSetViewType(TrainingsView.Calendar)} active={viewType === TrainingsView.Calendar}>
                    <CalendarIcon />
                  </ToggleViewButton>
                </ToggleViewButtons>
              </HeaderRightSide>
            </Header>
            {viewType !== TrainingsView.Calendar && (
              <SearchWrapper>
                <Search
                  searchText={searchText}
                  selectedCategories={selectedCategories}
                  selectedTypes={selectedTypes}
                  fromDate={fromDate}
                  toDate={toDate}
                  onSearchTextChange={setSearchText}
                  onCategoryChange={setSelectedCategories}
                  onTypeChange={setSelectedTypes}
                  onFromDateChange={setFromDate}
                  onToDateChange={setToDate}
                />
              </SearchWrapper>
            )}
            {viewType !== TrainingsView.Calendar && !filteredEvents?.length && (
              <EmptyResult />
            )}
            {viewType === TrainingsView.Default && eventCategories.map((category) => (
              <TrainingList
                key={category}
                events={filteredEvents.filter((event) => event.course.type.id === category)}
                viewType={TrainingsView.Default}
              />
            ))}
            {viewType === TrainingsView.DateList && eventDates.map((date) => (
              <TrainingList
                key={date}
                events={filteredEvents.filter((event) => getDateLabel(event) === date)}
                viewType={TrainingsView.DateList}
              />
            ))}
            {viewType === TrainingsView.Calendar && (
              <Calendar events={calendarEvents} />
            )}
          </Wrapper>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default Trainings;
