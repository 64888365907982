import styled from "styled-components";

import { Color, Media, SectionHeader } from "../../../../theme";
import { ReactComponent as DiagramSVG } from "../../images/diagram.svg";
import { ReactComponent as WarningIconSvg } from "../../images/warningIcon.svg";

export const Container = styled.div``;

export const Header = styled(SectionHeader)``;

export const DescriptionPanel = styled.div`
  width: 100%;
  padding: 20px 25px 25px;
  margin-top: 40px;
  background-color: ${Color.white};

  ${Media.tablet`
    display: flex;
    justify-content: space-between;
    padding: 20px 35px;
  `}
`;

export const WarningIcon = styled(WarningIconSvg)`
  margin-right: 15px;
`;

export const ErrorPanel = styled.div`
  padding: 25px 20px;
  display: flex;
  align-items: center;
  background-color: ${Color.white};
  font-size: 14px;
  line-height: 22px;
  height: 88px;
  color: ${Color.gray9};

  ${Media.tablet`
    padding: 35px;
  `}
`;

export const PanelRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: ${Color.deepGray};

  :first-of-type {
    border-bottom: 1px solid ${Color.gray5};
    padding-bottom: 20px;
    margin-bottom: 20px;

    ${Media.tablet`
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    `}
  }
`;

export const DiagramIcon = styled(DiagramSVG)`
  margin-right: 12px;

  ${Media.tablet`
    margin-right: 16px;
  `}
`;

export const IndicatorList = styled.div`
  display: grid;
  gap: 18px;

  ${Media.tablet`
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  `}
`;
