import React from "react";
import { connect } from "react-redux";

import { UserRegistrationActions } from "./redux/userRegistration.reducer";
import { Password } from "./password";

const PasswordReset = props => <Password {...props} />;

const mapStateToProps = (state, ownProps) => ({ ...ownProps,state: state.userRegistration });

const mapDispatchToProps = dispatch => ({
  setPassword: (token, password, onError) => dispatch(UserRegistrationActions.setPassword(token, password, onError)),
  remindPassword: (login) => dispatch(UserRegistrationActions.remindPassword(login))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordReset);
