import React from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectUserRole } from "../../userContext/redux/userContext.selectors";
import { UserRoleName } from "../../userContext/userContext.constants";

import { Container, ArrowIcon } from "./backButton.styled";

export const BackButton = ({ className, defaultPath = "/", strictPath }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const userRole = useSelector(selectUserRole);

  const handleOnClick = () => {
    if (strictPath) {
      navigate(strictPath);
    } else if (location.state?.from === UserRoleName.Onboarding || userRole.name === UserRoleName.Onboarding) {
      navigate("/onboarding#next", {
        state: {
          currentView: location.state?.currentView,
          currentStage: location.state?.currentStage,
        },
      });
    } else {
      window.history.state?.idx ? navigate(-1) : navigate(defaultPath);
    }
  };

  return (
    <Container
      className={className}
      onClick={handleOnClick}
    >
      <ArrowIcon />
      {t("backButton.back")}
    </Container>
  );
};

BackButton.propTypes = {
  className: PropTypes.string,
  defaultPath: PropTypes.string,
  strictTo: PropTypes.string,
};
