import styled from "styled-components";

import { ReactComponent as CloseSvg } from "../../../../images/close.svg";
import { Color, FontWeight, Media } from "../../../../theme";
import { TrainerPopupHeader } from "../trainerPopupHeader/trainerPopupHeader.component";
import { TrainerPopupStats } from "../trainerPopupStats/trainerPopupStats.component";
import { TrainerPopupDescription } from "../trainerPopupDescription/trainerPopupDescription.component";

export const Container = styled.div`
  position: relative;
  padding: 70px 20px 90px;

  ${Media.tablet`
    padding: 70px 90px 90px;
  `}
`;

export const Header = styled(TrainerPopupHeader)`
  margin-bottom: 15px;

  ${Media.tablet`
    margin-bottom: 40px;
  `}
`;

export const Stats = styled(TrainerPopupStats)`
  margin: 15px 0;
`;

export const Description = styled(TrainerPopupDescription)`
  margin-top: 15px;
`;

export const Label = styled.p`
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.6px;
  font-weight: ${FontWeight.Bolder};
  text-transform: uppercase;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 14px;
  right: 34px;
  border: none;
  background-color: ${Color.transparent};
  padding: 0;
  margin: 0;
`;

export const CloseIcon = styled(CloseSvg)`
  width: 14px;
  height: 14px;
  
  g {
    stroke: ${Color.deepGray};
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
