import React, { useState, useEffect } from "react";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

import { Container } from "./rightDrawer.styled";

export const RightDrawer = ({ className, children, open, onClose, variant }) => {
  const [opened, setOpened] = useState(open);

  useEffect(() => {
    setTimeout(() => setOpened(open), 1);
  }, [open]);

  const handleClose = () => {
    if (opened) {
      onClose();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Container
        className={className}
        anchor="right"
        onClose={onClose}
        open={open}
        variant={variant}
      >
        {children}
      </Container>
    </ClickAwayListener>
  );
};
