import styled from "styled-components";

import { Color, FontWeight, Media } from "../../../../theme";
import { Button as ButtonBase } from "../../../../components/button/button.component";

export const Container = styled.div`
  max-width: 1360px;
  margin: 0 auto;
  padding: 20px;

  ${Media.tablet`
    padding: 20px 25px;
  `}

  ${Media.desktop`
    padding: 20px 80px;
  `}
`;

export const Title = styled.p`
  font-weight: ${FontWeight.Bolder};
  font-size: 12px;
  line-height: 28px;
  padding: 10px 0 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid ${Color.dirtyWhite};
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: ${Color.deepGray};
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${Media.tablet`
    justify-content: flex-end;
    flex-direction: row;
  `}
`;

export const Button = styled(ButtonBase)`
  width: 100%;
  
  & + & {
    margin-bottom: 10px;
  }

  ${Media.tablet`
    width: 200px;

    & + & {
      margin-bottom: 0;
      margin-left: 10px;
    }
  `}
`;
