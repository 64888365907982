import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Loader } from "../../../../components/loader/loader.component";
import { renderWhenTrueOtherwise } from "../../../../helpers/rendering";
import { selectNewsLatestArticles, selectAreLatestPending } from "../../redux/news.selectors";
import { NewsActions } from "../../redux/news.reducer";
import { ReactComponent as DocumentPageImg } from "../../images/documentPage.svg";
import { LatestArticle } from "../latestArticle/latestArticle.component";
import { Container, Title, EmptyStateBox, EmptyStateMessage } from "./latestNews.styled";

export const LatestNews = ({ className, withPadding }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const articles = useSelector(selectNewsLatestArticles);
  const isPending = useSelector(selectAreLatestPending);

  const loadArticles = useCallback(
    () => dispatch(NewsActions.getLatestArticles()),
    [dispatch],
  );

  useEffect(() => {
    loadArticles();
  }, [loadArticles]);

  const renderEmptyState = () => (
    <EmptyStateBox>
      <DocumentPageImg />
      <EmptyStateMessage>{t("news.latestArticles.emptyList")}</EmptyStateMessage>
    </EmptyStateBox>
  );

  const renderLatestArticles = () => (
    articles.map((item) => (
      <LatestArticle
        key={item.id}
        id={item.id}
        image={item.image}
        imagePositionX={item.imagePosition?.x}
        imagePositionY={item.imagePosition?.y}
        title={item.title}
        timestamp={item.timestamp}
      />
    ))
  );

  const renderList = renderWhenTrueOtherwise(
    renderLatestArticles,
    renderEmptyState,
  );

  const renderLoader = () => (
    <EmptyStateBox>
      <Loader />
    </EmptyStateBox>
  );

  const renderContent = renderWhenTrueOtherwise(
    renderLoader,
    () => renderList(!!articles.length),
  );

  return (
    <Container className={className} withPadding={withPadding}>
      <Title>{t("news.latestArticles.latestArticles")}</Title>
      {renderContent(isPending)}
    </Container>
  );
};


LatestNews.propTypes = {};
