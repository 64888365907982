import React, { useState } from "react";
import PropTypes from "prop-types";

import { NextButton } from "../nextButton/nextButton.component";

import {
  Container,
  TextWrapper,
  Text,
  ButtonRow,
  AnswersContainer,
} from "./interactionTrueOrFalse.styled";
import { AnswerBox } from "./components/answerBox/answerBox.component";

export const InteractionTrueOrFalse = ({
  className,
  buttonText,
  question,
  answer,
  next,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState();
  const [selectedBox, setSelectedBox] = useState();

  const setFalse = () => {
    setSelectedBox("false");
    setSelectedAnswer(false);
  };

  const setTrue = () => {
    setSelectedBox("true");
    setSelectedAnswer(true);
  };

  const isCorrectTheme = (value) =>
    answer === value && selectedBox === value.toString();

  const isWrongTheme = (value) =>
    !answer === value && selectedBox === value.toString();

  const isFaded = (value) =>
    (selectedBox && isCorrectTheme(value)) || isWrongTheme(value);

  const nextInteraction = () => {
    setSelectedAnswer(null);
    setSelectedBox(null);
    next();
  };

  const isButtonDisabled = selectedAnswer !== answer;

  return (
    <Container className={className}>
      <TextWrapper>
        <Text>{question}</Text>
      </TextWrapper>
      <AnswersContainer>
        <AnswerBox
          onClick={setTrue}
          type={true}
          correctTheme={isCorrectTheme(true)}
          wrongTheme={isWrongTheme(true)}
          faded={isFaded(false)}
        />
        <AnswerBox
          onClick={setFalse}
          type={false}
          correctTheme={isCorrectTheme(false)}
          wrongTheme={isWrongTheme(false)}
          faded={isFaded(true)}
        />
      </AnswersContainer>
      <ButtonRow>
        <NextButton
          onClick={nextInteraction}
          disabled={isButtonDisabled}
          text={buttonText}
        />
      </ButtonRow>
    </Container>
  );
};

InteractionTrueOrFalse.propTypes = {
  className: PropTypes.string,
  buttonText: PropTypes.string,
  question: PropTypes.string,
  answer: PropTypes.bool,
  next: PropTypes.func,
};
