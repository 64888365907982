import React from "react";
import PropTypes from "prop-types";

import { CommentForm } from "../commentForm/commentForm.component";
import { FormType } from "../commentForm/commentForm.constants";

import { Container } from "./commentEdit.styled";

export const CommentEdit = (props) => {
  const {
    className,
    onCancel,
    onSave,
    id,
    content,
  } = props;
  const values = { id, content };

  return (
    <Container className={className}>
      <CommentForm
        values={values}
        onSubmit={onSave}
        onCancel={onCancel}
        type={FormType.Edit}
      />
    </Container>
  );
};

CommentEdit.propTypes = {
  className: PropTypes.string,
};
