import React from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";

import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import "./helpers/yupExtensions";

import { GlobalStyle } from "./theme/global";
import { store, persistor } from "./store";
import i18n  from "./translations/i18n";

import { UserRoleName } from "./userContext/userContext.constants";
import { Color } from "./theme/colors";
import App from "./app";
import { AppConfig } from "./appConfig";
import * as Modules from "./modules";

Modules.bootstrap(AppConfig.modules);

store.subscribe(() => {
  const { appSettings, common, userContext } = store.getState();
  const currentBrandId = appSettings.currentBrand;
  if (!currentBrandId) return;
  const brand = appSettings.brands.concat(common.brands).find((brandItem) => brandItem?.id === currentBrandId);
  const userData = userContext.data.user;

  if (userData?.role.name === UserRoleName.Onboarding) {
    Color.brandBasic = Color.blue;
  } else {
    Color.brandBasic = brand && brand.color ? `#${brand.color}` : Color.brandBasic;
  }
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <BrowserRouter>
          <PersistGate loading={<div>Loading</div>} persistor={persistor}>
            <GlobalStyle />
            <App />
          </PersistGate>
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>
);
