import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { ButtonColor } from "../button/button.constants";
import { renderWhenTrue } from "../../helpers/rendering";
import { CommentForm } from "./components/commentForm/commentForm.component";
import { Comment } from "./components/comment/comment.component";
import { Container, Comments, CommentWrapper, HideCommentsButton } from "./commentList.styled";

export const CommentList = (props) => {
  const {
    className,
    comments = [],
    hidden,
    onHideClick,
    onSubmit,
    onEdit,
    onDelete,
    hideDisabled,
    hidePermissions,
  } = props;
  const { t } = useTranslation();

  const renderHideButton = renderWhenTrue(() => (
    <HideCommentsButton
      color={ButtonColor.Action}
      onClick={onHideClick}
    >
      {t("news.comments.hideComments")}
    </HideCommentsButton>
  ));

  const renderComments = renderWhenTrue(() => (
    <Comments>
      {renderHideButton(!hideDisabled)}
      {comments.map((comment) => (
        <CommentWrapper key={comment.id}>
          <Comment
            key={comment.id}
            id={comment.id}
            publisher={comment.publisher}
            content={comment.content}
            timestamp={comment.timestamp}
            permissions={!hidePermissions && comment.permissions}
            onDelete={onDelete}
            onEdit={onEdit}
            isDeleted={comment.deleted}
            isEdited={comment.edited}
          />
        </CommentWrapper>
      ))}
    </Comments>
  ));

  return (
    <Container className={className} hidden={hidden}>
      {renderComments(!!comments.length)}
      <CommentForm onSubmit={onSubmit} />
    </Container>
  );
};


CommentList.propTypes = {
  className: PropTypes.string,
  comments: PropTypes.array,
  hidden: PropTypes.bool,
  onHideClick: PropTypes.func,
  onSubmit: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  hideDisabled: PropTypes.bool,
  hidePermissions: PropTypes.bool,
};
