import styled from "styled-components";

import { Color, Media, Transitions } from "../../../../theme";
import { Button } from "../../../../UIElements";

export const GhostButton = styled.button`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0;
  cursor: pointer;
`;

export const HeadlineSmall = styled.h5`
  font-size: 1.2rem;
  font-weight: 800;
  color: ${Color.black};
  text-transform: uppercase;
`;

export const HeaderWrapper = styled.div`
  ${Media.tabletWide`
    display: flex;
    justify-content: space-between;
  `}
`;

export const HeadlineBig = styled.h3`
  font-size: 3.3rem;
  color: ${Color.black};
  font-weight: 300;
  padding: 37px 0 60px;

  ${Media.tabletWide`
    padding: 37px 0 110px;
  `}
`;

export const SaveButton = styled(Button)`
  margin: 0 0 60px;
  display: flex;
  align-items: center;
  max-width: 200px;

  ${Media.tabletWide`
    margin: 26px 0 0;
    max-width: unset;
  `}
`;

export const RowText = styled.div`
  font-size: ${({ $fontSize }) => ($fontSize ? $fontSize + "rem" : "1.6rem")};
  color: ${({ $color }) => ($color ? Color[$color] : Color.deepGray)};
  padding-bottom: ${({ $paddingBottom }) => ($paddingBottom ? $paddingBottom + "px" : "0")};
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid ${Color.dirtyWhite};
  padding-bottom: 20px;
  margin-bottom: 20px;
  text-decoration: none;
  cursor: pointer;
  transition: ${Transitions.custom};

  &:hover {
    opacity: 0.7;
  }
`;

export const BadgeWrap = styled.div`
  margin-right: 20px;
`;
