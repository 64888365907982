import { all, put, takeLatest } from "redux-saga/effects";

import * as gamificationApi from "../services/api";

import { GamificationTypes, GamificationActions } from "./gamification.reducer";

function* startGamification({ onFinish }) {
  const startData = yield gamificationApi.startGamification();
  if (startData?.status === 204) {
    const { data } = yield gamificationApi.fetchCurrentLevel();
    yield put(GamificationActions.fetchCurrentLevelSuccess(data));
    yield onFinish();
  }
}

function* getUserChallenges({ page, activityFilter }) {
  const { data } = yield gamificationApi.getUserChallenges(page, activityFilter);
  yield put(GamificationActions.getUserChallengesSuccess(data));
}

function* fetchCurrentLevel() {
  yield put(GamificationActions.setIsPending(true));
  const { data } = yield gamificationApi.fetchCurrentLevel();
  yield put(GamificationActions.fetchCurrentLevelSuccess(data));
  yield put(GamificationActions.setIsPending(false));
}

function* fetchUpcomingChallenge() {
  const { data } = yield gamificationApi.fetchUpcomingChallenge();
  yield put(GamificationActions.fetchUpcomingChallengeSuccess(data));
}

function* fetchUserBadges() {
  const { data } = yield gamificationApi.fetchUserBadges();
  yield put(GamificationActions.fetchUserBadgesSuccess(data));
}

function* fetchRanking({ page }) {
  const { data } = yield gamificationApi.fetchRanking(page);
  yield put(GamificationActions.fetchRankingSuccess(data));
}

function* fetchGamificationLevels() {
  const { data } = yield gamificationApi.fetchGamificationLevels();
  yield put(GamificationActions.fetchGamificationLevelsSuccess(data));
}

function* fetchOpenQuestion({ id }) {
  yield put(GamificationActions.setOpenQuestionPending(true));
  const { data } = yield gamificationApi.fetchOpenQuestion(id);
  yield put(GamificationActions.fetchOpenQuestionSuccess(data));
  yield put(GamificationActions.setOpenQuestionPending(false));
}

function* sendOpenQuestion({ values, onFinish = Function.prototype, id }) {
  const data = yield gamificationApi.sendOpenQuestion(values, id);
  if (data) {
    onFinish();
  }
}

function* sendProgramManagerMessage({ message }) {
  yield put(GamificationActions.setProgramManagerMessagePending(true));
  const { data } = yield gamificationApi.sendProgramManagerMessage(message);
  if (data.success) {
    yield put(GamificationActions.sendProgramManagerMessageSuccess(data.message));
  } else {
    yield put(GamificationActions.sendProgramManagerMessageFailure(data));
  }
  yield put(GamificationActions.setProgramManagerMessagePending(false));
}

function* getCalendarMeetings() {
  const { data } = yield gamificationApi.getCalendarMeetings();
  yield put(GamificationActions.getCalendarMeetingsSuccess(data));
}

function* getUpcomingMeeting() {
  const { data } = yield gamificationApi.getUpcomingMeeting();

  if (data) {
    yield put(GamificationActions.getUpcomingMeetingSuccess(data));
  }
}

function* fetchSurvey({ id }) {
  yield put(GamificationActions.setSurveyPending(true));
  const { data } = yield gamificationApi.fetchSurvey(id);
  yield put(GamificationActions.fetchSurveySuccess(data));
  yield put(GamificationActions.setSurveyPending(false));
}

function* sendSurveyAnswers({ survey }) {
  const answers = survey.pages[0].sections[0].questions.map((question) => {
    return question.answers.map((answer) => ({
      id: answer.id,
      value: answer.selected,
    }));
  }).reduce((answers, answer) => [...answers, ...answer], []);

  const { data } = yield gamificationApi.sendSurveyAnswers(survey.id, answers);

  if (data.success) {
    const finishData = yield gamificationApi.finishSurvey(survey.id);
    if (finishData.data) {
      yield put(GamificationActions.fetchSurveySuccess(finishData.data));
    }
  }
}

function* savePost({ post, onFinish = Function.prototype }) {
  const { data } = yield gamificationApi.createPost(post);

  if (data.status) {
    onFinish();
  }
}

function* saveArticle({ article, onFinish = Function.prototype }) {
  const { data } = yield gamificationApi.createArticle(article);

  if (data.status) {
    onFinish();
  }
}

function* getKnowledgeBaseItems() {
  const { data } = yield gamificationApi.getKnowledgeBaseItems();
  yield put(GamificationActions.getKnowledgeBaseItemsSuccess(data));
}

function* getEntryDetails({ entryId }) {
  const { data } = yield gamificationApi.getEntryDetails(entryId);

  if (data.status) {
    yield put(GamificationActions.getEntryDetailsSuccess(data.data));
  }
}

function* getActiveChallenges({ onFinish }) {
  const { data } = yield gamificationApi.getActiveChallenges();
  yield put(GamificationActions.getActiveChallengesSuccess(data.data));
  onFinish();
}

function* getPointsHistory({ page }) {
  const { data } = yield gamificationApi.getPointsHistory(page);
  yield put(GamificationActions.getPointsHistorySuccess(data));
}

export function* watchGamification() {
  yield all([
    yield takeLatest(GamificationTypes.START_GAMIFICATION, startGamification),
    yield takeLatest(GamificationTypes.GET_USER_CHALLENGES, getUserChallenges),
    yield takeLatest(GamificationTypes.FETCH_CURRENT_LEVEL, fetchCurrentLevel),
    yield takeLatest(GamificationTypes.SEND_SURVEY_ANSWERS, sendSurveyAnswers),
    yield takeLatest(GamificationTypes.FETCH_UPCOMING_CHALLENGE, fetchUpcomingChallenge),
    yield takeLatest(GamificationTypes.FETCH_USER_BADGES, fetchUserBadges),
    yield takeLatest(GamificationTypes.FETCH_RANKING, fetchRanking),
    yield takeLatest(GamificationTypes.FETCH_GAMIFICATION_LEVELS, fetchGamificationLevels),
    yield takeLatest(GamificationTypes.FETCH_OPEN_QUESTION, fetchOpenQuestion),
    yield takeLatest(GamificationTypes.FETCH_SURVEY, fetchSurvey),
    yield takeLatest(GamificationTypes.SEND_OPEN_QUESTION, sendOpenQuestion),
    yield takeLatest(GamificationTypes.SEND_PROGRAM_MANAGER_MESSAGE, sendProgramManagerMessage),
    yield takeLatest(GamificationTypes.GET_CALENDAR_MEETINGS, getCalendarMeetings),
    yield takeLatest(GamificationTypes.GET_UPCOMING_MEETING, getUpcomingMeeting),
    yield takeLatest(GamificationTypes.SAVE_ARTICLE, saveArticle),
    yield takeLatest(GamificationTypes.SAVE_POST, savePost),
    yield takeLatest(GamificationTypes.GET_KNOWLEDGE_BASE_ITEMS, getKnowledgeBaseItems),
    yield takeLatest(GamificationTypes.GET_ENTRY_DETAILS, getEntryDetails),
    yield takeLatest(GamificationTypes.GET_ACTIVE_CHALLENGES, getActiveChallenges),
    yield takeLatest(GamificationTypes.GET_POINTS_HISTORY, getPointsHistory),
  ]);
}
