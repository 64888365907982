import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { Stage } from "./stage/stage.component";
import { NextLessonHash } from "./stage/stage.constants";
import {
  Container,
  Title,
  Separator,
  ScrollProvider,
} from "./defaultStages.styled";
import { StageGroupType } from "./defaultStages.constants";

export const DefaultStages = ({
  className,
  title,
  stages,
  expandedStageId,
}) => {
  const scrollOptions = {
    block: "start",
    behavior: "smooth",
  };

  const renderStages = useCallback(() => (
    stages.map((stage, index) => {
      const lessons = stage.itemGroup[StageGroupType.Content].items;
      const lessonsPassed = lessons.filter((item) => !!item.passed).length;
      const hasNextLessonHash = window.location.hash === NextLessonHash;
      const shouldShowNextLesson = hasNextLessonHash && stage.isEnabled && !stage.passed;
      const hasExpandedStageId = index === expandedStageId;

      return (
        <ScrollProvider
          key={stage.name}
          active={hasExpandedStageId}
          options={scrollOptions}
        >
          <Stage
            title={stage.name}
            description={stage.description}
            lessons={lessons}
            lessonsPassed={lessonsPassed}
            disabled={!stage.isEnabled}
            hasExpandedStageId={hasExpandedStageId}
            shouldShowNextLesson={shouldShowNextLesson}
          />
        </ScrollProvider>
      );
    })
  ), [stages, expandedStageId, scrollOptions]);

  return (
    <Container className={className}>
      {title && (
        <>
          <Title>{title}</Title>
          <Separator />
        </>
      )}
      {renderStages()}
    </Container>
  );
};

DefaultStages.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  stages: PropTypes.array,
};
