import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color, Media, SectionHeader } from "../../../../theme";
import { ReactComponent as ArrowImg } from "../../../../images/arrow.svg";

export const Title = styled(SectionHeader)`
  &:not(:first-of-type) {
    margin-top: 60px;
  }

  ${Media.tablet`
    width: 100%;
  `}
`;

export const ArrowIcon = styled(ArrowImg)`
  fill: ${Color.gray9};
  height: 15px;
  margin-left: auto;
  transform: rotate(270deg);

  ${styleWhenTrue(prop("open"), css`
    transform: rotate(90deg);
  `)}
`;
