import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { RightDrawer } from "../../../../components/rightDrawer/rightDrawer.component";
import { Button } from "../../../../UIElements";
import { CheckModal } from "../../../trainings/components/checkModal/checkModal.component";
import { CheckModalType } from "../../../trainings/components/checkModal/checkModal.constants";
import { EnrollForm } from "../../../trainings/components/enrollForm/enrollForm.component";
import { TrainingsActions } from "../../../trainings/redux/trainings.reducer";
import {
  selectCheckPopupOpenedId,
  selectMissingCourses,
  selectBlockingEvent,
} from "../../../trainings/redux/trainings.selectors";

export const Enroll = ({
  buttonProps,
  eventId,
  entrySurvey,
  eventName,
  eventDate,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openEnrollForm, setOpenEnrollForm] = useState(false);
  const checkPopupOpenedId = useSelector(selectCheckPopupOpenedId);
  const missingCourses = useSelector(selectMissingCourses);
  const blockingEvent = useSelector(selectBlockingEvent);

  const handleEnroll = () => {
    dispatch(TrainingsActions.checkEventAccess(eventId, () => {
      if (entrySurvey) {
        setOpenEnrollForm(true);
      } else {
        dispatch(TrainingsActions.enrollEvent(eventId));
      }
    }));
  };

  const handleCloseCheckPopup = () => {
    dispatch(TrainingsActions.closeCheckPopup());

    setTimeout(() => {
      dispatch(TrainingsActions.clearCourses());
    }, 500);
  };

  return (
    <>
      <Button {...buttonProps} onClick={handleEnroll}>
        {t("global.enroll")}
      </Button>
      {!!entrySurvey && (
        <RightDrawer open={openEnrollForm} onClose={() => setOpenEnrollForm(false)}>
          <EnrollForm
            onClose={() => setOpenEnrollForm(false)}
            withAccommodation={entrySurvey.withAccommodation}
            withParking={entrySurvey.withParking}
            withVege={entrySurvey.withVege}
            eventId={eventId}
            inDetailsView
          />
        </RightDrawer>
      )}
      <CheckModal
        modalType={blockingEvent ? CheckModalType.BlockingEvent : CheckModalType.Error}
        eventName={eventName}
        eventDate={eventDate}
        courses={missingCourses}
        blockingEvent={blockingEvent}
        open={checkPopupOpenedId === eventId && (!!missingCourses?.length || !!blockingEvent)}
        onClose={handleCloseCheckPopup}
      />
    </>
  );
};
