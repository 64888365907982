import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isIE } from "react-device-detect";
import { useParams } from "react-router";

import { Loader } from "../../../components/loader/loader.component";
import {
  selectIsInitialDataPending,
  selectCurrentRoom,
  selectRemoteCameraEnabled,
  selectRecording,
  selectParticipants,
  selectLiveStartTimestamp,
  selectRequests,
  selectRequestSentType,
  selectPongReceived,
} from "../redux/webinars.selectors";
import { WebinarsActions } from "../redux/webinars.reducer";
import { BroadcastType } from "../components/video/video.constants";
import { DeviceKind, StreamDirection, StreamType } from "../services/streams.constants";
import { Chat } from "../components/chat/chat.component";
import { Video } from "../components/video/video.component";
import { ButtonPanel } from "../components/buttonPanel/buttonPanel.component";
import { Files } from "../components/files/files.component";
import { BrowserModal } from "../components/browserModal/browserModal.component";
import { StreamModal } from "../components/streamModal/streamModal.component";
import { ShareModal } from "../components/shareModal/shareModal.component";
import { WaitingModal } from "../components/waitingModal/waitingModal.component";
import { RecordingTimer } from "../components/recordingTimer/recordingTimer.component";
import { StreamTimer } from "../components/streamTimer/streamTimer.component";
import { ConnectionModal } from "../components/connectionModal/connectionModal.component";
import { RequestName, RoomStatus, RoomType } from "../webinars.constants";
import { initVideoStreams } from "../services/streams";

import {
  Container,
  Grid,
} from "./basicWebinars.styled";

export const BasicWebinars = () => {
  const matchParams = useParams();
  const dispatch = useDispatch();
  const isPending = useSelector(selectIsInitialDataPending);
  const room = useSelector(selectCurrentRoom);
  const remoteCameraEnabled = useSelector(selectRemoteCameraEnabled);
  const recording = useSelector(selectRecording);
  const participants = useSelector(selectParticipants);
  const startTimestamp = useSelector(selectLiveStartTimestamp);
  const requests = useSelector(selectRequests);
  const requestSentType = useSelector(selectRequestSentType);
  const pongReceived = useSelector(selectPongReceived);
  const [fullScreen, setFullScreen] = useState(false);
  const [streamModalVisible, setStreamModalVisible] = useState(false);
  const [shareScreenModalVisible, setShareScreenModalVisible] = useState(false);
  const [shareCameraModalVisible, setShareCameraModalVisible] = useState(false);
  const [browserModalVisible, setBrowserModalVisible] = useState(false);
  const [connectionModalVisible, setConnectionModalVisible] = useState(false);
  const localCameraVideoRef = useRef(null);
  const localScreenVideoRef = useRef(null);
  const remoteCameraVideoRef = useRef(null);
  const remoteScreenVideoRef = useRef(null);
  const localAudioRef = useRef(null);
  const remoteAudioRef = useRef(null);
  const remoteStreamsTypes = room.streams.filter((stream) => stream.direction === StreamDirection.In).map((stream) => stream.type);
  const localStreamsTypes = room.streams.filter((stream) => stream.direction === StreamDirection.Out).map((stream) => stream.type);
  const bothMiniVideoActive = localStreamsTypes.includes(StreamType.Camera)
    && remoteStreamsTypes.includes(StreamType.Camera)
    && (localStreamsTypes.includes(StreamType.Screen)
    || remoteStreamsTypes.includes(StreamType.Screen));
  const showRemoteCamera = remoteStreamsTypes.includes(StreamType.Camera) && room.live && remoteCameraEnabled;
  const showRemoteScreen = remoteStreamsTypes.includes(StreamType.Screen) && room.live;
  const showTimer = !room.live;
  const isAudienceRoom = room.type === RoomType.Audience;
  const presenter = participants.find((participant) => participant.role === BroadcastType.Presenter);
  const isCurrentUserPresenter = presenter && presenter.user.isCurrentUser;
  const showRecordingTimer = recording && (isAudienceRoom ? isCurrentUserPresenter : true);
  const miniVideoActive =
    localStreamsTypes.includes(StreamType.Camera)
    || ((localStreamsTypes.includes(StreamType.Camera)
    || (remoteStreamsTypes.includes(StreamType.Camera)
    && (remoteStreamsTypes.includes(StreamType.Screen)
    || localStreamsTypes.includes(StreamType.Screen))))
    && !bothMiniVideoActive);
  const videoRequests = requests.filter((request) => request.access.name === RequestName.ShareCamera);
  const screenRequests = requests.filter((request) => request.access.name === RequestName.ShareScreen);

  useEffect(() => {
    if (videoRequests.length) {
      setShareCameraModalVisible(true);
    } else if (screenRequests.length) {
      setShareScreenModalVisible(true);
    }
  }, [videoRequests, screenRequests]);

  const escCallback = useCallback(() => {
    window.onkeydown = (event) => {
      if (event.keyCode === 27) {
        setFullScreen(false);
      }
    };
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escCallback);

    return () => {
      document.removeEventListener("keydown", escCallback);
    };
  }, [escCallback]);

  const updateDeviceLists = useCallback((devices) => {
    const videoDevices = devices.filter(
      (device) => device.kind === DeviceKind.Video
    );
    const audioDevices = devices.filter(
      (device) => !!device.groupId && device.kind === DeviceKind.Audio
    );

    dispatch(WebinarsActions.updateDevices(videoDevices, audioDevices));
  }, [dispatch]);

  useEffect(() => {
    if (isIE) {
      showBrowserModal();
      return;
    }

    initVideoStreams(
      localCameraVideoRef,
      localScreenVideoRef,
      remoteCameraVideoRef,
      remoteScreenVideoRef,
      localAudioRef,
      remoteAudioRef,
    );

    if (navigator.mediaDevices) {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        updateDeviceLists(devices);
        const defaultVideoDevice = devices.find(
          (device) => device.kind === DeviceKind.Video
        );
        const defaultAudioDevice = devices.find(
          (device) => !!device.groupId && device.kind === DeviceKind.Audio
        );

        if (defaultVideoDevice) {
          dispatch(WebinarsActions.setCurrentVideo(defaultVideoDevice.deviceId));
        }
        if (defaultAudioDevice) {
          dispatch(WebinarsActions.setCurrentAudio(defaultAudioDevice.deviceId));
        }
      });

      navigator.mediaDevices.addEventListener("devicechange", () => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          updateDeviceLists(devices);
        });
      });
    }
  }, [dispatch, matchParams.id, updateDeviceLists]);

  useEffect(() => {
    const waitingTime = 5000;
    let timeout = null;
    if (pongReceived) {
      timeout = setTimeout(() => dispatch(WebinarsActions.sendPing()), waitingTime);
    } else {
      timeout = setTimeout(() => setConnectionModalVisible(true), waitingTime);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, pongReceived]);

  const showBrowserModal = () => setBrowserModalVisible(true);

  const handleOpenStreamModal = () => setStreamModalVisible(true);
  const handleCloseStreamModal = () => setStreamModalVisible(false);

  const toggleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  const handleToggleStream = () => {
    if (room.live) {
      dispatch(WebinarsActions.handleEndLive());
    } else {
      dispatch(WebinarsActions.handleStartLive());
    }
  };

  const handleShareCameraResponseModal = (accept) => {
    dispatch(
      WebinarsActions.handleShareResponse(
        StreamType.Camera,
        videoRequests[0].participant.id,
        accept
      )
    );
  };

  const handleCloseShareCameraModal = () => {
    setShareCameraModalVisible(false);
  };

  const handleCloseShareScreenModal = () => setShareScreenModalVisible(false);

  const handleShareScreenResponseModal = (accept) => {
    dispatch(
      WebinarsActions.handleShareResponse(
        StreamType.Screen,
        screenRequests[0].participant.id,
        accept
      )
    );
  };

  return (
    <>
      <Container>
        <Grid fullScreen={fullScreen}>
          {!!isPending && <Loader />}
          <Video
            videoRef={localCameraVideoRef}
            show={localStreamsTypes.includes(StreamType.Camera)}
            fullScreen={fullScreen}
            isSecondVideo={bothMiniVideoActive}
            mobileHidden
          />
          <Video
            videoRef={localScreenVideoRef}
            show={localStreamsTypes.includes(StreamType.Screen)}
            large
            fullScreen={fullScreen}
            mobileHidden
            showPlayInfo={!room.live}
          />
          <Video
            videoRef={remoteCameraVideoRef}
            show={showRemoteCamera && !showTimer}
            large={
              !remoteStreamsTypes.includes(StreamType.Screen)
              && !localStreamsTypes.includes(StreamType.Screen)
              && room.live
            }
            fullScreen={fullScreen}
            mobileHidden={remoteStreamsTypes.includes(StreamType.Screen)}
          />
          <Video
            videoRef={remoteScreenVideoRef}
            show={showRemoteScreen && !showTimer}
            large={remoteStreamsTypes.includes(StreamType.Screen) && room.live}
            fullScreen={fullScreen}
            mobileHidden={!remoteStreamsTypes.includes(StreamType.Screen)}
          />
          {!!showRecordingTimer && <RecordingTimer fullScreen={fullScreen} />}
          <audio ref={localAudioRef} autoPlay />
          <audio ref={remoteAudioRef} autoPlay />
          {!!showTimer && !localStreamsTypes.includes(StreamType.Screen) && (
            <StreamTimer
              fullScreen={fullScreen}
              startTimestamp={startTimestamp}
              liveEnded={room.status === RoomStatus.Ended}
            />
          )}
          <ButtonPanel
            fullScreen={fullScreen}
            onOpenStreamModal={handleOpenStreamModal}
            onFullScreen={toggleFullScreen}
          />
          <Chat
            fullScreen={fullScreen}
            cameraOn={miniVideoActive}
            secondCameraOn={bothMiniVideoActive}
            liveEnded={room.status === RoomStatus.Ended}
          />
        </Grid>
      </Container>
      <Files files={room.files} />
      <StreamModal
        onConfirm={handleToggleStream}
        onClose={handleCloseStreamModal}
        open={streamModalVisible}
      />
      <ShareModal
        onConfirm={() => handleShareCameraResponseModal(true)}
        onDecline={() => handleShareCameraResponseModal(false)}
        onClose={handleCloseShareCameraModal}
        open={shareCameraModalVisible}
        type={StreamType.Camera}
      />
      <ShareModal
        onConfirm={() => handleShareScreenResponseModal(true)}
        onDecline={() => handleShareScreenResponseModal(false)}
        onClose={handleCloseShareScreenModal}
        open={shareScreenModalVisible}
        type={StreamType.Screen}
      />
      <BrowserModal open={browserModalVisible} />
      <WaitingModal type={requestSentType} />
      <ConnectionModal open={connectionModalVisible} />
    </>
  );
};
