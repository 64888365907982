import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";

import { UserContextActions } from "../../userContext/redux/userContext.reducer";
import { Modal } from "../../components/modal/modal.component";
import { Button } from "../../components/button/button.component";
import { ButtonColor } from "../../components/button/button.constants";

import { AchievementAnimations } from "./gamificationBadgeModal.constants";
import {
  AnimationWrapper,
  Title,
  Subtitle,
  ButtonContainer,
  MedalIcon,
} from "./gamificationBadgeModal.styled";

export const GamificationBadgeModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const achievements = useSelector((state) => state.userContext.data.achievements);
  const [animation, setAnimation] = useState();
  const achievement = !!achievements && achievements[0];

  useEffect(() => {
    if (achievement) {
      AchievementAnimations
        .find((element) => element.id === achievement.enum)?.animationPromise
        .then(setAnimation);
    }
  }, [achievement]);

  useEffect(() => {
    setIsOpen(!!achievement);
  }, [achievement]);

  const handleClose = () => {
    if (!achievement) setIsOpen(false);
    dispatch(UserContextActions.hideAchievementModal(achievement));
  };

  return (
    <Modal
      title={t("gamification.gamificationBadgeModal.badgeEarned")}
      HeaderIcon={MedalIcon}
      open={isOpen}
      onClose={handleClose}
    >
      <AnimationWrapper>
        {!!animation && (
          <Lottie
            animationData={animation}
            loop
            rendererSettings={{
              preserveAspectRatio: "xMidYMid slice",
            }}
          />
        )}
      </AnimationWrapper>
      {!!achievement && (
        <>
          <Title>{achievement.label}</Title>
          <Subtitle>{achievement.popup}</Subtitle>
        </>
      )}
      <ButtonContainer>
        <Button
          color={ButtonColor.Primary}
          onClick={handleClose}
        >
          {t("gamification.gamificationBadgeModal.close")}
        </Button>
      </ButtonContainer>
    </Modal>
  )
};
