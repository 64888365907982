import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { renderWhenTrue } from "../../../../helpers/rendering";
import { useMediaListener } from "../../../../hooks/useMediaListener";
import { simpleDateFromTimestamp } from "../../../../helpers/date";
import { CompetenceActions } from "../../redux/competence.reducer";
import { selectConfirmationItems } from "../../redux/competence.selectors";

import { ConfirmationStatus } from "./confirmationList.constants";
import {
  Container,
  Table,
  TableHead,
  TableBody,
  HeaderRow,
  Header,
  ItemRow,
  Cell,
  MobileHeader,
  // TODO: enable it when deleting confirmations will be available
  // DeleteButton,
  // TrashIcon,
  DocumentIcon,
  FileWrapper,
  DownloadIcon,
} from "./confirmationList.styled";

export const ConfirmationList = ({ className, competenceId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useMediaListener();
  const items = useSelector(selectConfirmationItems);

  useEffect(() => {
    dispatch(CompetenceActions.getConfirmationList(competenceId));
  }, [dispatch, competenceId]);

  const getStatus = (item) => {
    if (item.status.name === ConfirmationStatus.Accepted) {
      return simpleDateFromTimestamp(item.createdAt);
    } else {
      return item.status.label;
    }
  };

  const renderHeader = renderWhenTrue(() => (
    <TableHead>
      <HeaderRow>
        <Header>{t("competence.competenceTab.confirmationName")}</Header>
        <Header>{t("competence.competenceTab.confirmationType")}</Header>
        <Header>{t("competence.competenceTab.confirmationDate")}</Header>
        <Header>{t("competence.competenceTab.confirmationFile")}</Header>
        {/* TODO: enable it when deleting confirmations will be available */}
        {/* <Header></Header> */}
      </HeaderRow>
    </TableHead>
  ));

  const renderMobileItem = (item) => (
    <React.Fragment key={item.id}>
      <ItemRow>
        <Cell>
          <DocumentIcon />
          {item.title}
        </Cell>
        <Cell></Cell>
      </ItemRow>
      <ItemRow>
        <MobileHeader>
          {t("competence.competenceTab.confirmationType")}
        </MobileHeader>
        <Cell>{item.category.label}</Cell>
      </ItemRow>
      <ItemRow>
        <MobileHeader>
          {t("competence.competenceTab.confirmationDate")}
        </MobileHeader>
        <Cell>{getStatus(item)}</Cell>
      </ItemRow>
      <ItemRow>
        <MobileHeader>
          {t("competence.competenceTab.confirmationFile")}
        </MobileHeader>
        <Cell>
          <FileWrapper href={item.file} target="_blank" rel="noopener noreferrer">
            <DownloadIcon />
          </FileWrapper>
        </Cell>
      </ItemRow>
    </React.Fragment>
  );

  const renderDesktopItem = (item) => (
    <React.Fragment key={item.id}>
      <ItemRow>
        <Cell>
          <DocumentIcon />
          {item.title}
        </Cell>
        <Cell>{item.category.label}</Cell>
        <Cell>{getStatus(item)}</Cell>
        <Cell>
          <FileWrapper href={item.file} target="_blank" rel="noopener noreferrer">
            <DownloadIcon />
          </FileWrapper>
        </Cell>
        {/* TODO: enable it when deleting confirmations will be available */}
        {/* <Cell>
          <DeleteButton>
            <TrashIcon />
          </DeleteButton>
        </Cell> */}
      </ItemRow>
    </React.Fragment>
  );

  const renderItems = () => items.map((item) => {
    return isMobile ? renderMobileItem(item) : renderDesktopItem(item);
  });

  if (!items.length) {
    return null;
  }

  return (
    <Container className={className}>
      <Table>
        {renderHeader(!isMobile)}
        <TableBody>
          {renderItems()}
        </TableBody>
      </Table>
    </Container>
  );
};

ConfirmationList.propTypes = {
  className: PropTypes.string,
  competenceId: PropTypes.number,
};
