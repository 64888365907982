import styled from "styled-components";

import { ReactComponent as DownloadSvg } from "../../../../images/download.svg";
import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.button`
  width: calc(100% - 40px);
  background-color: ${Color.almostWhite};
  padding: 17px 46px 17px 35px;
  margin: 0 auto 2px;
  border: none;
  text-align: left;
  display: flex;
  align-items: center;
  transition: 0.3s;

  ${Media.tablet`
    width: 100%;
  `}

  &:hover {
    background-color: ${Color.gray8};
  }
`;

export const Title = styled.p`
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.gray9};
  font-size: 13px;
  line-height: 22px;
`;

export const IconWrapper = styled.div`
  width: 35px;
  height: 35px;
  padding: 11px;
  background-color: ${Color.white};
  border-radius: 50%;
  margin-right: 13px;
`;

export const Icon = styled(DownloadSvg)``;

export const Date = styled.p`
  font-size: 13px;
  line-height: 22px;
  color: ${Color.gray9};

  ${Media.tablet`
    margin-left: auto;
  `}
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${Media.tablet`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
`;
