/* eslint-disable react/no-string-refs */
import React, { Component } from "react";

import { Container } from "./simpleClickEnd.styled";

export default class SimpleClickEnd extends Component {
  constructor() {
    super();
    this.state = { elements: [] };
  }
  componentDidMount() {
    fetch(this.props.baseUrl + this.props.config)
      .then(response => response.json())
      .then(jresp => {
        this.initialState = {
          elements: jresp.elements,
          boxStyle: jresp.elements[0].boxStyle
        };

        this.setState(this.initialState);
      });
  }

  imageHtml(index, element) {
    const elementStyle = {
      left: element.pos.left,
      top: element.pos.top,
      position: element.pos.position,
      height: "auto"
    };
    const delay = element.delay !== undefined ? element.delay + "s" : "0." + index * 2 + "s";

    const AnimationClass = element.className !== undefined ? element.className : "show-simple";

    const imageStyle = {
      animationDelay: delay
    };
    return (
      <div key={index} className="col-12 js-element" type={"image"} style={elementStyle}>
        <img
          className={AnimationClass}
          src={this.props.baseUrl + element.content}
          style={imageStyle}
          alt={"icon"}
        />
      </div>
    );
  }

  textHtml(index, element) {
    const elementStyle = {
      left: element.pos.x
    };
    const HeadlineClass = element.className !== undefined ? element.className : "show-simple";

    const imageStyle = {
      animationDelay: "0." + index * 2 + "s"
    };
    return (
      <div key={index} className="col-12 js-element" type={"text"} style={elementStyle}>
        <h3 className={HeadlineClass} style={imageStyle}>
          {element.content}{" "}
        </h3>
      </div>
    );
  }

  render() {
    let imageHtml = this.imageHtml;
    let textHtml = this.textHtml;
    const elements = this.state.elements.map((item, index) => {
      switch (item.type) {
      case "image":
        return imageHtml.bind(this)(index, item);
      case "text":
        return textHtml.bind(this)(index, item);
      default:
        return textHtml.bind(this)(index, item);
      }
    });

    return (
      <Container onClick={this.props.callComplete}>
        <div className="wrapper  simple-click" ref="WrapNode">
          {elements}
        </div>
      </Container>
    );
  }
}
