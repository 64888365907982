import styled, { css } from "styled-components";
import { prop } from "ramda";

import { ReactComponent as ArrowSvg } from "../../../../images/arrow.svg";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { hexToRgba } from "../../../../helpers/colors";
import { Media, Color, Separator as SeparatorBase, ContentMaxWidth } from "../../../../theme";

import { FadeDurationInSeconds } from "./slide.constants";

export const Container = styled.div``;

export const ContentWrapper = styled.div`
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
  font-size: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;

  ${Media.tablet`
    ${styleWhenTrue(prop("isVideoPlayer"), css`
      flex-direction: row;
      gap: 30px;
      align-items: center;
    `)}
  `}
`;

export const AnimationWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 70px;
  opacity: 0;
  width: 100%;

  ${styleWhenTrue(prop("$withFade"), css`
    transition: all ${FadeDurationInSeconds}s ease-in-out 0s;
  `)}

  ${styleWhenTrue(prop("$visible"), css`
    opacity: 1;

    ${styleWhenTrue(prop("$withFade"), css`
      transition: all ${FadeDurationInSeconds}s ease-in-out ${FadeDurationInSeconds}s;
    `)}
  `)}

  ${styleWhenTrue(prop("$needFix"), css`
    width: 180%;
  `)}

  ${Media.tablet`
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
    width: 80%;

    ${styleWhenTrue(prop("$wide"), css`
      width: 100%;
    `)}
  `}

  ${Media.desktop`
    left: 50%;
    transform: translateY(-50%) translateX(-80%);
    width: 50%;
    max-width: 1180px;

    ${styleWhenTrue(prop("$wide"), css`
      width: calc(100% - 80px);
      left: 80px;
      right: 0;
      max-width: none;
      transform: translateY(-50%) translateX(0);
    `)}
  `}
`;

export const Video = styled.video`
  width: 100%;
  max-height: unset;

  ${Media.tablet`
    width: 80%;
  `}

  ${Media.desktop`
    width: 120%;
  `}
`;

export const ScrollButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const ArrowIcon = styled(ArrowSvg)`
  fill: ${Color.gray9};
  height: 15px;
  width: 9px;
  transform: rotate(-90deg);
  position: relative;
  top: 4px;
  transition: all 0.2s ease-in-out;
`;

export const ScrollButton = styled.button`
  margin-top: 100px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: ${Color.white};
  box-shadow: 0 2px 4px ${hexToRgba(Color.darkGray, 0.2)}, 0 0 20px ${hexToRgba(Color.deepGray, 0.03)};
  flex-shrink: 0;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  animation: beating 1s infinite;

  &:hover {
    background-color: ${() => Color.brandBasic};

    ${ArrowIcon} {
      fill: ${Color.white};
    }
  }

  @keyframes beating {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100%  { transform: scale(1); }
  }
`;

export const Separator = styled(SeparatorBase)``;

export const FinishBoxWrapper = styled.div`
  width: 100%;
  margin: 50vh auto 0;
  z-index: 1;
`;

export const StaticImage = styled.img`
  width: 100%;
`;

export const VideoBox = styled.div`
  width: 100%;
  margin-top: 20px;
  z-index: 1;

  ${Media.tablet`
    width: 100%;
    max-width: 50%;
    margin-top: 0;
  `}
`;
