import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../helpers/rendering";
import { Media, Color } from "../../theme";

export const Container = styled.div`
  min-height: 100vh;

  ${styleWhenTrue(prop("withBackground"), css`
    background-color: ${Color.almostWhite};
  `)}
`;

export const LoaderWrapper = styled.div`
  display: flex;
  height: 95vh;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  position: relative;

  ${Media.tablet`
    display: flex;
    flex-direction: row-reverse;
  `}
`;

export const PageImage = styled.img`
  object-fit: cover;
  width: 100%;
  max-height: 170px;

  ${styleWhenTrue(prop("lastImage"), css`
    max-height: 300px;
  `)}

  ${Media.tablet`
    max-height: unset;
    height: 100%;
    flex: 1;
  `}
`;

export const QuestionCounter = styled.div`
  display: flex;
  margin: 20px 20px 0;

  ${Media.tablet`
    margin: 0;
    flex-direction: column;
    position: absolute;
    top: 200px;
    left: 20px;
  `}
`;

export const CounterItem = styled.div`
  border-radius: 10px;
  background-color: ${Color.almostWhite};
  width: 17px;
  height: 4px;

  &:not(:last-child) {
    margin-right: 5px;
  }

  ${styleWhenTrue(prop("active"), css`
    background-color: ${Color.blue5};
  `)}

  ${Media.tablet`
    height: 24px;
    width: 4px;

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 5px;
    }
  `}
`;
