import styled, { css } from "styled-components";
import { propEq } from "ramda";
import { CircularProgressbar } from "react-circular-progressbar";

import { styleWhenTrue } from "../../helpers/rendering";
import { hexToRgba } from "../../helpers/colors";
import { Color } from "../../theme";

import { ChartColorType } from "./simpleProgressChart.constants";

export const Container = styled(CircularProgressbar)`
  width: 26px;
  height: 26px;
  flex-shrink: 0;

  && {
    .CircularProgressbar-path {
      stroke: ${() => Color.brandBasic};
      stroke-linecap: butt;

      ${styleWhenTrue(propEq(ChartColorType.Gold, "chartColor"), css`
        stroke: ${() => Color.gold};
      `)}
    }

    .CircularProgressbar-trail {
      stroke: ${() => hexToRgba(Color.brandBasic, 0.25)};
      stroke-linecap: butt;

      ${styleWhenTrue(propEq(ChartColorType.Gold, "chartColor"), css`
        stroke: ${hexToRgba(Color.gold, 0.25)};
      `)}
    }
  }
`;
