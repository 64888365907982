import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { cond, equals, always } from "ramda";

import {
  renderWhenTrueOtherwise,
  renderWhenTrue,
} from "../../../../helpers/rendering";
import { ReactComponent as GoldMedal } from "../../../../images/goldMedal.svg";
import { ReactComponent as SilverMedal } from "../../../../images/silverMedal.svg";
import { ReactComponent as BronzeMedal } from "../../../../images/bronzeMedal.svg";
import { AwardType } from "../../../../components/thumbnail/thumbnail.constants";
import { CircularProgressWithLabel } from "../../../../components/circularProgressWithLabel/circularProgressWithLabel.component";

import {
  Container,
  Category,
  Name,
  ProgressWrapper,
} from "./formsAndTestsThumbnail.styled";
import { CategoryType } from "./formsAndTestsThumbnail.constants";

export const FormsAndTestsThumbnail = ({
  id,
  name,
  category,
  result,
  award,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const progressValue = Math.round(result * 100);
  const isCompleted = progressValue === 100;
  const isTest = category === CategoryType.Test;

  const handleOnClick = () => navigate(`/formularze/${id}`);

  const renderCompletedMark = renderWhenTrueOtherwise(
    () => getCompletedMark(award),
    () => <CircularProgressWithLabel value={progressValue} />
  );

  const renderProgress = renderWhenTrue(() => (
    <ProgressWrapper>{renderCompletedMark(isCompleted)}</ProgressWrapper>
  ));

  const getCompletedMark = cond([
    [equals(AwardType.Gold), always(<GoldMedal />)],
    [equals(AwardType.Silver), always(<SilverMedal />)],
    [equals(AwardType.Bronze), always(<BronzeMedal />)],
  ]);

  return (
    <Container onClick={handleOnClick}>
      <Category>{t(`dashboard.dashboard.${category}`)}</Category>
      <Name>{name}</Name>
      {renderProgress(isTest)}
    </Container>
  );
};

FormsAndTestsThumbnail.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  category: PropTypes.string,
  result: PropTypes.number,
  award: PropTypes.string,
};
