import styled from "styled-components";

import { FontWeight, Color, Media } from "../../../../../theme";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  ${Media.tablet`
    height: 600px;
  `}
`;

export const Content = styled.div`
  width: 100%;
  flex: 70%;
  padding-top: 40px;
  position: relative;
`;

export const ContentHeader = styled.h3`
  font-size: 16px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.blue};
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const Options = styled.div`
  width: 100%;

  ${Media.tablet`
    max-height: 300px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 10px;
      background-color: #DADADC;
      border-radius: 15px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${Color.blue};
      border-radius: 15px;
    }
  `}
`;
