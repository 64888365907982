import styled from "styled-components";
import { prop } from "ramda";

import { ProgressChart as ChartBase } from "../../../../components/progressChart/progressChart.component";
import { styleIfElse } from "../../../../helpers/rendering";
import { ContentMaxWidth, FontWeight, Media, Color } from "../../../../theme";
import { ReactComponent as LukeSvg } from "../../images/luke.svg";
import { ReactComponent as TimerSvg } from "../../images/timer.svg";

export const Container = styled.div`
  background-color: ${styleIfElse(prop("light"), Color.white, Color.almostWhite)};
  display: flex;
  justify-content: center;
  padding: 50px 25px 75px;
  overflow: hidden;

  ${Media.tablet`
    padding: 105px 25px;
  `}
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${Media.tablet`
    flex-direction: row;
    max-width: ${ContentMaxWidth};
  `}
`;

export const LeftColumn = styled.div`
  ${Media.tablet`
    order: 1;
    width: 50%;
    margin-right: 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`;

export const RightColumn = styled.div`
  ${Media.tablet`
    order: 2;
    width: 50%;
    margin-left: 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`;

export const ImageWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const Image = styled.img`
  height: 213px;
  margin: 0 auto 50px;

  ${Media.tablet`
    margin: 0;
    height: 300px;
  `}

  ${Media.desktopWide`
    margin: 0;
    height: 459px;
  `}
`;

export const Title = styled.h4`
  color: ${Color.black};
  font-size: 28px;
  line-height: 38px;
  font-weight: ${FontWeight.ExtraBold};
  margin-bottom: 20px;

  ${Media.tablet`
    font-size: 45px;
    line-height: 61px;
    margin-bottom: 25px;
  `}
`;

export const Text = styled.p`
  font-size: 18px;
  line-height: 26px;
  color: ${Color.gray6};
  margin-bottom: 50px;

  ${Media.tablet`
    font-size: 20px;
    line-height: 34px;
  `}
`;

export const BarsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Media.mobile`
    flex-direction: row;
    margin-left: 10px;
  `}
`;

export const ProgressChart = styled(ChartBase)`
  ${Media.mobile`
    & + & {
      margin-left: 20px;
    }
  `}
`;

export const WinnerTip = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${Color.gray4};
  max-width: 458px;
`;

export const TipBold = styled.span`
  color: ${Color.blue};
  font-weight: ${FontWeight.SemiBold};
`;

export const LukeImage = styled(LukeSvg)`
  width: 100%;
  height: 213px;
  margin-bottom: 10px;

  ${Media.tablet`
    margin-bottom: 0;
    height: 300px;
  `}

  ${Media.desktopWide`
    margin: 0;
    height: 459px;
  `}
`;

export const TimerImage = styled(TimerSvg)`
  margin-bottom: 20px;
  width: 30px;
  height: 30px;
  fill: ${Color.blue};
`;
