import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { selectFavouriteListRouteAvailable } from "../../userContext/redux/userContext.selectors";
import { selectFavouriteItems } from "../../redux/common/common.selectors";
import { CommonActions } from "../../redux/common/common.reducer";
import { Separator } from "../../theme/typography";
import { ThumbnailListType } from "../thumbnailList/thumbnailList.constants";
import { ThumbnailList } from "../thumbnailList/thumbnailList.component";

import { Container } from "./favouritesList.styled";

export const FavouritesList = ({ className, viewType }) => {
  const dispatch = useDispatch();
  const favouriteListRouteAvailable = useSelector(selectFavouriteListRouteAvailable);
  const favouriteItems = useSelector(selectFavouriteItems);

  useEffect(() => {
    if (favouriteListRouteAvailable) {
      dispatch(CommonActions.fetchFavouriteItems());
    }
  }, []);

  if (!favouriteItems?.items?.length) {
    return null;
  }

  return (
    <Container className={className}>
      <ThumbnailList
        title={favouriteItems.title}
        thumbnails={favouriteItems.items}
        type={ThumbnailListType.Default}
        viewType={viewType}
      />
      <Separator />
    </Container>
  );
};

FavouritesList.propTypes = {
  className: PropTypes.string,
  viewType: PropTypes.string,
};
