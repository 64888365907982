import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";

import {
  FormSchema,
  capitalLettersRegex,
  decimalRegex,
  min4RepeatedCharsRegex,
  min12charsRegex,
  specialCharsRegex,
  disallowedCharsRegex,
} from "../../modules/profile/components/passwordsTab/passwordsTab.schema";
import { CommonActions } from "../../redux/common/common.reducer";
import { UserContextActions } from "../../userContext/redux/userContext.reducer";
import { ButtonColor, ButtonVariant } from "../../components/button/button.constants";
import { Button } from "../../components/button/button.component";
import { InputStyle } from "../../components/input/input.constants";
import { clearApiCache } from "../../helpers/clearApiCache";

import {
  Container,
  Popup,
  Header,
  AlertIcon,
  HeaderText,
  Content,
  Title,
  Text,
  Buttons,
  Input,
  InfoWrapper,
  CheckIcon,
  ErrorIcon,
  InfoText,
  InfoTextBold,
  Error,
} from "./changePasswordModal.styled";
import { ConfirmationContent } from "./confirmationContent.component";

export const ChangePasswordModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const initialValues = useMemo(() => ({
    oldPassword: "",
    newPassword: "",
    repeatPassword: "",
  }), []);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    return () => {
      if (showConfirmationMessage) {
        dispatch(UserContextActions.turnOffChangePasswordModal());
      }
    };
  }, [showConfirmationMessage]);

  const handleSuccessCallback = (actions) => {
    actions.setSubmitting(false);
    setShowConfirmationMessage(true);
  };

  const handleFailureCallback = (error, actions) => {
    setErrorMessage(error);
    actions.setSubmitting(false);
  };

  const handleSubmit = (values, actions) => {
    setErrorMessage("");
    actions.setSubmitting(true);
    dispatch(CommonActions.setNewPassword(
      values.oldPassword,
      values.newPassword,
      () => handleSuccessCallback(actions),
      (error) => handleFailureCallback(error, actions),
    ));
  };

  const handleLogout = () => {
    dispatch(UserContextActions.setLoggedOut());
    clearApiCache();
  };

  const handleFinish = () => {
    setIsOpen(false);
    dispatch(UserContextActions.turnOffChangePasswordModal());
  };

  const formik = useFormik({
    validationSchema: FormSchema,
    initialValues,
    onSubmit: handleSubmit,
  });

  return (
    <Container open={isOpen} $blurred>
      <Popup>
        {showConfirmationMessage ? (
          <ConfirmationContent handleFinish={handleFinish} />
        ) : (
          <>
            <Header>
              <AlertIcon />
              <HeaderText>
                {t("app.changePassword.changePassword")}
              </HeaderText>
            </Header>
            <Content>
              <Title>{t("app.changePassword.title")}</Title>
              <Text>{t("app.changePassword.text")}</Text>
              <Input
                type="password"
                name="oldPassword"
                placeholder={t("profile.passwordsTab.oldPassword")}
                value={formik.values.oldPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.oldPassword && formik.errors.oldPassword}
                styleType={InputStyle.ProfileForm}
                eyeButtonVisible
              />
              <Input
                type="password"
                name="newPassword"
                placeholder={t("profile.passwordsTab.newPassword")}
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.newPassword && formik.errors.newPassword}
                styleType={InputStyle.ProfileForm}
                eyeButtonVisible
              />
              <InfoWrapper>
                <InfoTextBold>
                  {t("profile.passwordsTab.info")}
                </InfoTextBold>
              </InfoWrapper>
              <InfoWrapper>
                {formik.values.newPassword.match(min12charsRegex) ? <CheckIcon /> : <ErrorIcon />}
                <InfoText>
                  {t("profile.passwordsTab.minChars")}
                </InfoText>
              </InfoWrapper>
              <InfoWrapper>
                {formik.values.newPassword.match(capitalLettersRegex) ? <CheckIcon /> : <ErrorIcon />}
                <InfoText>
                  {t("profile.passwordsTab.capitalLetters")}
                </InfoText>
              </InfoWrapper>
              <InfoWrapper>
                {formik.values.newPassword.match(decimalRegex) ? <CheckIcon /> : <ErrorIcon />}
                <InfoText>
                  {t("profile.passwordsTab.decimals")}
                </InfoText>
              </InfoWrapper>
              <InfoWrapper>
                {formik.values.newPassword.match(specialCharsRegex) ? <CheckIcon /> : <ErrorIcon />}
                <InfoText>
                  {t("profile.passwordsTab.specialChars")}
                </InfoText>
              </InfoWrapper>
              <InfoWrapper>
                {!formik.values.newPassword.match(min4RepeatedCharsRegex) ? <CheckIcon /> : <ErrorIcon />}
                <InfoText>
                  {t("profile.passwordsTab.maxRepeatedChars")}
                </InfoText>
              </InfoWrapper>
              <InfoWrapper>
                {!formik.values.newPassword.match(disallowedCharsRegex) ? <CheckIcon /> : <ErrorIcon />}
                <InfoText>
                  {t("profile.passwordsTab.disallowedChars")}
                </InfoText>
              </InfoWrapper>
              <Input
                type="password"
                name="repeatPassword"
                placeholder={t("profile.passwordsTab.repeatPassword")}
                value={formik.values.repeatPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.repeatPassword && formik.errors.repeatPassword}
                styleType={InputStyle.ProfileForm}
                eyeButtonVisible
              />
              {errorMessage && <Error>{errorMessage}</Error>}
            </Content>
            <Buttons>
              <Button
                color={ButtonColor.Primary}
                onClick={formik.handleSubmit}
                disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
              >
                {t("app.changePassword.update")}
              </Button>
              <Button
                variant={ButtonVariant.Outlined}
                onClick={handleLogout}
              >
                {t("app.changePassword.logout")}
              </Button>
            </Buttons>
          </>
        )}
      </Popup>
    </Container>
  );
};
