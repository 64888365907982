export const BoardFieldShape = {
  Square: "square",
  Oval: "oval",
};

export const BoardFieldType = {
  Elearning: "elearning",
  Task: "task",
  InProgress: "inProgress",
  Passed: "passed",
  Test: "test",
};

export const BoardFieldColor = {
  [BoardFieldType.Elearning]: "#eeeff3",
  [BoardFieldType.Task]: "#cad4ef",
  [BoardFieldType.InProgress]: "#252122",
  [BoardFieldType.Passed]: "#708be6",
  [BoardFieldType.Test]: "#c6c6c6",
};

export const BoardFields = [{
  type: BoardFieldType.Elearning,
  labelKey: `onboarding.stages.${BoardFieldType.Elearning}`,
  color: BoardFieldColor[BoardFieldType.Elearning],
  shape: BoardFieldShape.Square,
}, {
  type: BoardFieldType.Task,
  labelKey: `onboarding.stages.${BoardFieldType.Task}`,
  color: BoardFieldColor[BoardFieldType.Task],
  shape: BoardFieldShape.Square,
}, {
  type: BoardFieldType.InProgress,
  labelKey: `onboarding.stages.${BoardFieldType.InProgress}`,
  color: BoardFieldColor[BoardFieldType.InProgress],
  shape: BoardFieldShape.Square,
}, {
  type: BoardFieldType.Passed,
  labelKey: `onboarding.stages.${BoardFieldType.Passed}`,
  color: BoardFieldColor[BoardFieldType.Passed],
  shape: BoardFieldShape.Square,
}, {
  type: BoardFieldType.Test,
  labelKey: `onboarding.stages.${BoardFieldType.Test}`,
  color: BoardFieldColor[BoardFieldType.Test],
  shape: BoardFieldShape.Oval,
}];
