import styled from "styled-components";

import { FontWeight, Color, Media } from "../../../../theme";

export const DropdownToggler = styled.div`
  padding: 35px 25px;
  width: 100%;
  margin-top: 2px;
  background-color: ${Color.white};
  cursor: pointer;
  display: flex;
  align-items: center;

  ${Media.tablet`
    padding: 45px 35px;
  `}
`;

export const Title = styled.p`
  font-weight: ${FontWeight.SemiBold};
  font-size: 16px;
  color: ${Color.deepGray};
  margin-left: 20px;
  line-height: 20px;
  padding-right: 10px;

  ${Media.tablet`
    margin-left: 30px;
  `}
`;
