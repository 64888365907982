import React from "react";
import i18n from "i18next";
import { TextField as TextFieldBase, styled as styledMUI } from "@mui/material";
import styled, { css } from "styled-components";
import { prop } from "ramda";


import { styleWhenTrue } from "../helpers/rendering";
import { FormQuestionTitle } from "../theme";

const QuestionLabel = styled(FormQuestionTitle)`
  margin-bottom: 16px;
`;

const TextField = styledMUI(TextFieldBase)({
  width: "100%",

  "& .MuiFormLabel-root": {
    fontSize: 16,
  },
  "& .MuiInputBase-root": {
    fontSize: 16,
  },
});

const Field = styled.div`
  margin-bottom: 30px;
  ${styleWhenTrue(prop("$newTheme"), css`max-width: 380px;`)}
`;

const ToggleSwitch = styled.div``;

const Input = ({
  className,
  label,
  value,
  title,
  onChange,
  onKeyDown,
  onBlur,
  readonly = false,
  disabled,
  type = "text",
  min,
  max,
  placeholder = i18n.t("UIElements.form.answer"),
  error = false,
  shrink
}) => (
  <>
    {title && <QuestionLabel>{title}</QuestionLabel>}
    <TextField
      className={className}
      variant="standard"
      value={value ? value : ""}
      label={!title ? label : ""}
      placeholder={placeholder}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      readOnly={readonly}
      disabled={disabled}
      type={type}
      inputProps={{ min: min, max: max, step: "1" }}
      InputLabelProps={{ shrink: shrink }}
      autoComplete="new-password"
      error={error}
    />
  </>
);

export {
  Input,
  Field,
  ToggleSwitch,
};
