import styled from "styled-components";

export const Container = styled.div`
  padding: 0 20px;

  & .headline {
    color: #003263;
    font-size: 1.5em;
    text-align: center;
    margin: 0;
    padding: 20px 0;
  }

  & .box {
    display: inline-block;
  }

  & .col-12 {
    width: 100%;
  }

  & .pl_shape4 {
    position: relative;
  }

  & .pl_shape4:after {
    position: absolute;
    bottom: 3px;
    content: '';
    width: 2px;
    height: 5px;
    background-color: #003263;
  }

  & .pos-ab {
    position: absolute;
  }

  & .pl_shape5 {
    position: relative;
    width: 155px;
    margin: 0 auto;
  }

  & .pl_shape5:after {
    left: 0;
    position: absolute;
    bottom: 3px;
    content: '';
    width: 2px;
    height: 5px;
    background-color: #003263;
  }

  & .pl_shape5:before {
    right: 0;
    position: absolute;
    bottom: 3px;
    content: '';
    width: 2px;
    height: 5px;
    background-color: #003263;
  }

  & .slider {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .margin-top-text {
    margin-top: 50px !important;
  }

  & .padding-top {
    padding-top: 7%;
  }

  & .padding-top-2 {
    padding-top: 11%;
  }

  & .padding-text-1 {
    padding-left: 100px;
    padding-right: 100px;
  }

  & .padding-text-2 {
    padding-left: 50px;
    padding-right: 50px;
  }

  & .wrapper {
    height: 500px;
  }

  & .paragraph {
    width: 100%;
    margin-top: 100px;
  }

  & .small-margin-bottom {
    margin-bottom: 8px;
  }

  & .small-margin-top {
    margin-top: 8px;
  }

  & .space-right {
    margin-right: 1.2em;
  }

  & .space-left {
    margin-left: 1.2em;
  }

  & .center-wrap {
    text-align: center;
  }

  & .space-between {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  & .btn-pos-abs {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  & .button-wrap {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  & .button-wrap .btn-orange {
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    color: white;
    border: 0;
    font-size: 16px;
    box-shadow: none;
    letter-spacing: 1px;
    padding: 0.6em 0.5em;
    border-radius: 6px;
    display: block;
    text-decoration: none;
    font-family: 'VWHeadTabPLNormal';
  }

  & .button-wrap .btn-orange:hover {
    cursor: pointer;
  }

  & .button-wrap .orange-gradient {
    background: #ef7401;
    background: -moz-linear-gradient(left,#ef7401 0%,#ed9e54 100%);
    background: -webkit-gradient(left top,right top,color-stop(0%,#ef7401),color-stop(100%,#ed9e54));
    background: -webkit-linear-gradient(left,#ef7401 0%,#ed9e54 100%);
    background: -o-linear-gradient(left,#ef7401 0%,#ed9e54 100%);
    background: -ms-linear-gradient(left,#ef7401 0%,#ed9e54 100%);
    background: linear-gradient(to right,#ef7401 0%,#ed9e54 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ef7401',endColorstr='#ed9e54',GradientType=1);
  }

  & .wrapper {
    height: 670px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  & .wrapper.flex-flow-column {
    flex-flow: column;
  }

  & .col {
    width:25%;
    text-align: center
  }

  & .col img {
    max-width: 100%;
  }

  & .btn-pos-abs {
    margin-bottom: 10px;
  }

  & .paragraph-header {
    background: #004563;
    color: #fff;
    font-weight: bold;
    width: 220px;
    text-align: center;
  }

  & .paragraph-content {
    background: #e4f5fb;
    color: #000;
    width: calc(100% - 220px);
  }

  & .paragraph-content, .show-text-image .paragraph-header {
    padding: 8px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 17px;
    font-size: 13px;
  }

  & .paragraph-container {
    display: flex;
    padding: 0 25px;
    margin: 15px 0;
    width: 100%;
  }

  @media screen and (min-width: 1025px) {
    & .paragraph-container:last-of-type {
      margin-bottom: 80px;
    }
  }

  @media screen and (max-width: 1024px) {
    & .col {
      width: 33.33%;
      padding-top: 25px;
    }

    & .wrapper {
      height: auto;
      padding-bottom: 70px;
    }
  }

  @media screen and (max-width: 768px) {
    & .col {
      width: 50%;
    }

    & .paragraph-header {
      width: 150px;
    }

    & .paragraph-content {
      width: calc(100% - 150px);
    }
  }

  @media screen and (max-width: 468px) {
    & .col {
        width: 100%;
    }

    & .paragraph-header {
      width: 110px;
    }

    & .paragraph-content {
      width: calc(100% - 110px);
    }
  }
`;
