import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { SectionHeader } from "../../theme";

import { RankingPosition } from "./rankingPosition/rankingPosition.component";
import {
  Container,
  ImageWrapper,
  StatisticImage,
} from "./userRanking.styled";

export const UserRanking = ({ className, rankingPositions }) => {
  const { t } = useTranslation();

  const renderRankingPositions = () => (
    rankingPositions.map((position) => (
      <RankingPosition
        key={position.position}
        position={position.position}
        avatar={position.avatar}
        name={position.name}
        points={position.points}
        isCurrentUser={position.own}
      />
    ))
  );

  if (!rankingPositions.length) {
    return null;
  }

  return (
    <Container className={className}>
      <SectionHeader>
        {t("components.userRanking.ranking")}
      </SectionHeader>
      {renderRankingPositions()}
      <ImageWrapper>
        <StatisticImage />
      </ImageWrapper>
    </Container>
  );
};

UserRanking.propTypes = {
  className: PropTypes.string,
  rankingPositions: PropTypes.array,
};
