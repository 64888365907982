import { prop } from "ramda";
import styled, { css } from "styled-components";

import { styleWhenTrue } from "../../../../../../helpers/rendering";
import { Color, FontWeight } from "../../../../../../theme";
import { ReactComponent as ArrowSVG} from "../../../images/arrow.svg";

export const DropdownToggler = styled.div`
  border: 2px solid ${Color.gray5};
  border-bottom: none;
  min-height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px 0 20px;

  &:last-of-type {
    border-bottom: 2px solid ${Color.gray5};
  }

  ${styleWhenTrue(prop("setValidatingTheme"), css`
    pointer-events: none;
    border-color: ${Color.green};
    color: ${Color.green};

    &:last-of-type {
      border-color: ${Color.green};
    }

    ${styleWhenTrue(prop("wrongTheme"), css`
      border-color: ${Color.red4};
      color: ${Color.red4};

      &:last-of-type {
        border-color: ${Color.red4};
      }
    `)}  
  `)}
`;

export const Title = styled.p`
  font-weight: ${FontWeight.Bold};
  color: ${Color.blue4};
  text-transform: uppercase;
  font-size: 10px;
  margin-bottom: 10px;

  ${styleWhenTrue(prop("setValidatingTheme"), css`
    color: ${Color.green};

    ${styleWhenTrue(prop("wrongTheme"), css`
      color: ${Color.red4};
    `)}
  `)}
`;

export const TextColumn = styled.div`
  padding: 20px 0 25px;
`;

export const Text = styled.p`
  font-size: 15px;
  font-weight: ${FontWeight.Light};
  line-height: 20px;

  ${styleWhenTrue(prop("italic"), css`
    font-style: italic;
  `)}
`;

export const DropdownContent = styled.div`
  border: 2px solid ${Color.gray5};
  border-top: none;
  background-color: ${Color.almostWhite};
  padding: 0 18px;

  & + ${DropdownToggler} {
    border-top: none;
  }
`;

export const DropdownOption = styled.div`
  font-size: 15px;
  line-height: 20px;
  font-weight: ${FontWeight.Light};
  cursor: pointer;
  padding: 20px 0;

  &:not(:first-of-type) {
    border-top: 1px solid ${Color.gray1};
  }
`;

export const ArrowButton = styled.button`
  border: none;
  width: 40px;
  height: 70px;
  flex-shrink: 0;
  background-color: ${Color.almostWhite};
  display: flex;
  justify-content: center;
  align-items: center;

  ${styleWhenTrue(prop("open"), css`
    transform: rotate(180deg);
    background-color: ${Color.blue4};

    svg {
      fill: ${Color.white};
    }
  `)}
`;

export const ArrowIcon = styled(ArrowSVG)`
  fill: ${Color.blueLighter};
`;
