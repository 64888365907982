import styled from "styled-components";

import { ReactComponent as BaseDownloadIcon } from "../../../../images/download.svg";
import {
  Color,
  Media,
  FontWeight,
  Separator as BaseSeparator,
} from "../../../../theme";
import { hexToRgba } from "../../../../helpers/colors";

export const Container = styled.div``;

export const Wrapper = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
`;

export const IconWrapper = styled.div`
  width: 33px;
  height: 33px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 1px solid ${hexToRgba(Color.brown, 0.15)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

export const DownloadIcon = styled(BaseDownloadIcon)`
  width: 15px;
  height: 15px;
  fill: ${Color.yellow};
`;

export const Title = styled.p`
  font-size: 13px;
  color: ${Color.deepGray};
`;

export const Separator = styled(BaseSeparator)`
  ${Media.tablet`
    margin: 20px 0;
  `}
`;

export const TextWrapper = styled.div``;

export const Type = styled.p`
  font-size: 10px;
  line-height: 20px;
  font-weight: ${FontWeight.SemiBold};
  text-transform: uppercase;
  color: ${Color.gray9};
`;