import { all, put, takeLatest } from "redux-saga/effects";

import * as notificationsApi from "../services/api";

import { NotificationsTypes, NotificationsActions } from "./notifications.reducer";

function* getInitialData() {
  yield put(NotificationsActions.setIsPending(true));
  const { data } = yield notificationsApi.getInitialData();
  yield put(NotificationsActions.getInitialDataSuccess(data.data, data.nextPage));
  yield put(NotificationsActions.setIsPending(false));
}

function* setReadMessage({ id }) {
  yield notificationsApi.setReadMessage(id);
}

function* deleteNotification({ id }) {
  yield notificationsApi.deleteNotification(id);
}

function* loadNextPage({ nextPage }) {
  const { data } = yield notificationsApi.loadNextPage(nextPage);
  yield put(NotificationsActions.loadNextPageSuccess(data.data, data.nextPage));
}

export function* watchNotifications() {
  yield all([
    yield takeLatest(NotificationsTypes.GET_INITIAL_DATA, getInitialData),
    yield takeLatest(NotificationsTypes.SET_READ_MESSAGE, setReadMessage),
    yield takeLatest(NotificationsTypes.DELETE_NOTIFICATION, deleteNotification),
    yield takeLatest(NotificationsTypes.LOAD_NEXT_PAGE, loadNextPage),
  ]);
}
