import styled from "styled-components";

import { ReactComponent as CloseSVG } from "../../../../../images/close2.svg";
import { Color, FontWeight, Media } from "../../../../../theme";

export const Container = styled.div`
  position: fixed;
  bottom: 75px;
  left: 10px;
  right: 10px;
  border-radius: 2px;
  background-color: ${() => Color.brandBasic};
  color: ${Color.white};
  padding: 25px 20px;
  z-index: 2;

  ${Media.tablet`
    position: absolute;
    right: 20px;
    bottom: 20px;
    left: unset;
    width: 400px;
  `}
`;

export const CloseIcon = styled(CloseSVG)`
  height: 12px;
  width: 12px;
  fill: ${Color.white};
`;

export const CloseButton = styled.button`
  background-color: ${Color.transparent};
  border: none;
  position: relative;
  top: -13px;
  right: -6px;
`;

export const TooltipHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TooltipTitle = styled.h1`
  margin-bottom: 15px;
  font-weight: ${FontWeight.Bold};
  font-size: 14px;
`;

export const TooltipText = styled.p`
  font-size: 13px;
  line-height: 18px;

  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
`;
