export const CookiePopupLocalStorageKey = "platform-cookies-accepted";

export const CookieType = {
  Necessary: "necessary",
  Functional: "functional",
  Analytical: "analytical",
};

export const CookieName = {
  Necessary: `${CookieType.Necessary}CookiesEnabled`,
  Functional: `${CookieType.Functional}CookiesEnabled`,
  Analytical: `${CookieType.Analytical}CookiesEnabled`,
};
