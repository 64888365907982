import styled, { css } from "styled-components";
import { prop, complement } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color } from "../../../../theme/colors";
import { Media } from "../../../../theme/media";
import { FontWeight } from "../../../../theme/fonts";

export const Container = styled.div`
  background-color: ${Color.white};
  border: 1px solid ${Color.gray8};
`;

export const Tabs = styled.div`
  display: flex;
`;

export const Label = styled.span`
  font-size: 10px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: ${FontWeight.Bold};
  color: ${Color.deepGray};
  margin-left: 15px;
`;

export const Tab = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  align-items: center;
  color: ${Color.deepGray};
  padding: 20px;
  display: none;
  justify-content: center;
  font-weight: ${FontWeight.Bold};
  flex: 1;

  ${styleWhenTrue(prop("active"), css`
    color: ${() => Color.brandBasic};
  `)}

  ${styleWhenTrue(complement(prop("desktopOnly")), css`
    display: flex;
  `)}

  ${Media.tablet`
    display: flex;
  `}

  & + & {
    border-left: 1px solid ${Color.gray8};
  }
`;

export const Content = styled.div`
  border-top: 1px solid ${Color.gray8};

  &:empty {
    display: none;
  }
`;
