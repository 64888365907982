import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { ReactComponent as ArrowImg } from "../../images/arrow.svg";
import { Color, Media } from "../../theme";
import { styleWhenTrue } from "../../helpers/rendering";

export const ScrollableContainer = styled.div`
  display: flex;
`;

export const Container = styled.div`
  position: relative;
  border-bottom: 1px solid ${Color.gray5};
  border-top: 1px solid ${Color.gray5};
  padding: 0 20px;
  margin-bottom: 60px;

  // TODO: Remove when Trainings View will be a module
  ${styleWhenTrue(prop("isTrainingView"), css`
    padding: 0 25px;
  `)}

  ${Media.tablet`
    padding: 0;

    // TODO: Remove when Trainings View will be a module
    ${styleWhenTrue(prop("isTrainingView"), css`
      padding: 0 25px;
    `)}
  `}

  ${Media.tabletWide`
    padding: 0;
  `}

  ${Media.desktop`
    padding-right: 30px;

    ${ScrollableContainer} {
      transition: all .4s ease;
      transform: translateX(${({ filtersPosition }) => `-${filtersPosition}px`});
    }

    ${styleWhenTrue(prop("withLeftPadding"), css`
      padding-left: 30px;
    `)}
  `}
`;

export const TagsContainer = styled.div`
  overflow-x: auto;
  padding: 20px 0;

  ${Media.desktop`
    overflow-x: hidden;
  `}
`;

export const ArrowIcon = styled(ArrowImg)`
  fill: ${Color.gray9};
  height: 14px;

  &:hover {
    opacity: 0.8;
  }

  ${styleWhenTrue(propEq("brandcolor", "true"), css`
    fill: ${() => Color.brandBasic};
  `)}
`;

export const ArrowButton = styled.button`
  position: absolute;
  border: none;
  background-color: ${Color.transparent};
  display: none;

  ${Media.desktop`
    display: block;
  `}
`;

export const PrevButton = styled(ArrowButton)`
  left: -5px;
  top: 32px;
`;

export const NextButton = styled(ArrowButton)`
  right: -3px;
  top: 28px;
  transform: rotate(180deg);
`;
