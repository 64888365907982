import styled from "styled-components";

import { Button as ButtonBase } from "../../../../components/button/button.component";
import { Color, FontWeight } from "../../../../theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  height: 123px;
  align-items: center;
`;

export const Button = styled(ButtonBase)`
  &:disabled {
    color: ${Color.gray1};
    background-color: ${Color.almostWhite};
  }

  & + & {
    margin-left: 10px;
  }
`;

export const User = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.p`
  font-weight: ${FontWeight.ExtraBold};
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

export const UserRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 25px;

  & + & {
    margin-top: 10px;
  }
`;

export const FullName = styled.p`
  font-weight: ${FontWeight.SemiBold};
  font-size: 12px;
  line-height: 22px;
  color: ${Color.gray9};
  margin-left: 10px;
`;
