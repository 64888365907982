import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { ReactComponent as ArrowSvg } from "../../../../images/arrow.svg";
import { Color, Media, FontWeight } from "../../../../theme";

export const Container = styled.div`
  margin-top: 3px;
  padding: 22px 35px 20px;
  background-color: ${Color.white};
  display: flex;
  align-items: center;
  justify-content: center;

  ${Media.tablet`
    justify-content: flex-end;
  `}
`;

export const PrevArrowIcon = styled(ArrowSvg)`
  fill: ${Color.black};
  width: 7px;
  height: 12px;
`;

export const NextArrowIcon = styled(PrevArrowIcon)`
  transform: rotate(180deg);
`;

export const Button = styled.button`
  border: none;
  background-color: ${Color.transparent};
  font-size: 12px;
  line-height: 20px;
  width: 26px;
  height: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin-left: 10px;
  }

  ${styleWhenTrue(prop("isCurrent"), css`
    color: ${() => Color.brandBasic};
    font-weight: ${FontWeight.ExtraBold};
    pointer-events: none;
  `)}

  ${styleWhenTrue(prop("disabled"), css`
    pointer-events: none;

    ${PrevArrowIcon}, ${NextArrowIcon} {
      fill: ${Color.gray1};
    }
  `)}

  &:hover {
    color: ${() => Color.brandBasic};

    ${PrevArrowIcon}, ${NextArrowIcon} {
      fill: ${() => Color.brandBasic};
    }
  }
`;
