import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import { Input } from "../../../../components/input/input.component";
import { InputStyle } from "../../../../components/input/input.constants";
import { DatePicker } from "../../../../components/datePicker/datePicker.component";
import { DropdownInput } from "../../../../components/dropdownInput/dropdownInput.component";
import { ButtonColor } from "../../../../components/button/button.constants";
import { Checkbox } from "../../../../components/checkbox/checkbox.component";
import { simpleDateFromTimestamp } from "../../../../helpers/date";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { Theme } from "../../../../appConfig";
import {
  selectEditSections,
  selectEditCompanies,
  selectEditPositions,
  selectEditBrands,
  selectEditFormInVerification,
  selectIsEditFormError,
} from "../../redux/profile.selectors";
import { ProfileActions } from "../../redux/profile.reducer";

import { FormSchema } from "./editForm.schema";
import { FieldName, FieldType, ProfileEditTheme, SectionName } from "./editForm.constants";
import {
  Container,
  Section,
  SectionHeader,
  Separator,
  InputWrapper,
  LongInputWrapper,
  Label,
  StaticFieldWrapper,
  StaticLabel,
  StaticValue,
  SaveButton,
  Message,
  UpperInfo,
  CheckboxWrapper,
  AddButton,
} from "./editForm.styled";

export const EditForm = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sections = useSelector(selectEditSections);
  const companies = useSelector(selectEditCompanies);
  const positions = useSelector(selectEditPositions);
  const brands = useSelector(selectEditBrands);
  const verificationMessageVisible = useSelector(selectEditFormInVerification);
  const errorMessageVisible = useSelector(selectIsEditFormError);
  const [fieldsSavedMessageVisible, setFieldsSavedMessageVisible] = useState(false);
  const [dateValid, setDateValid] = useState({});
  const companyOptions = companies.map((company) => {
    const city = company.city ? `, ${company.city}` : "";
    const address = company.address ? `, ${company.address}` : "";
    const postcode = company.postcode ? `, ${company.postcode}` : "";
    return {
      ...company,
      label: `${company.name}${city}${address}${postcode}`,
    };
  });
  const positionOptions = Object.entries(positions).reduce((prev, curr) => {
    return {
      ...prev,
      [curr[0]]: curr[1].map((position) => ({ ...position, label: position.name })),
    };
  }, {});
  const namespaces = sections.find(
    (section) => section.name === SectionName.experiences
  )?.sections.map(
    (section) => section.name
  ) || [];

  const initialValues = useMemo(() => {
    const fields = sections.reduce((currentFields, section) => {
      const newFields = {};

      for (const field of section.fields) {
        switch (field.type) {
        case FieldType.StringArray:
          newFields[field.name] = field.value.toString().replaceAll(",", ", ");
          break;
        case FieldType.Date:
          newFields[field.name] = field.value ? Number(field.value) : null;
          break;
        case FieldType.Dropdown:
        case FieldType.LongDropdown:
        case FieldType.DropdownMultiple:
          newFields[field.name] = field.value;
          break;
        case FieldType.Checkbox:
          newFields[section.name][field.name] = field.value || false;
          break;
        default:
          newFields[field.name] = field.value || "";
        }
      }

      section.sections?.forEach((nestedSection) => {
        newFields[nestedSection.name] = {};

        for (const field of nestedSection.fields) {
          switch (field.type) {
          case FieldType.StringArray:
            newFields[nestedSection.name][field.name] = field.value.toString().replaceAll(",", ", ");
            break;
          case FieldType.Date:
            newFields[nestedSection.name][field.name] = field.value ? Number(field.value) : null;
            break;
          case FieldType.Dropdown:
          case FieldType.LongDropdown:
          case FieldType.DropdownMultiple:
            newFields[nestedSection.name][field.name] = field.value;
            break;
          case FieldType.Checkbox:
            newFields[nestedSection.name][field.name] = field.value || false;
            break;
          default:
            newFields[nestedSection.name][field.name] = field.value || "";
          }
        }
      });

      return { ...currentFields, ...newFields };
    }, {});

    return fields;
  }, [sections]);

  const getValuesInSections = (values) => {
    const newSections = {};

    for (const section of sections) {
      newSections[section.name] = section.name === SectionName.experiences ? [] : {};

      for (const field of section.fields) {
        switch (field.type) {
        case FieldType.Dropdown:
        case FieldType.LongDropdown:
          newSections[section.name][field.name] = values[field.name].id;
          break;
        case FieldType.DropdownMultiple:
          newSections[section.name][field.name] = values[field.name].map((value) => value.id);
          break;
        default:
          newSections[section.name][field.name] = values[field.name];
        }
      }

      section.sections?.forEach((nestedSection) => {
        const experienceSection = {};

        for (const field of nestedSection.fields) {
          switch (field.type) {
          case FieldType.Dropdown:
          case FieldType.LongDropdown:
            experienceSection[field.name] = values[nestedSection.name][field.name].id;
            break;
          case FieldType.DropdownMultiple:
            experienceSection[field.name] = values[nestedSection.name][field.name].map((value) => value.id);
            break;
          default:
            experienceSection[field.name] = values[nestedSection.name][field.name];
          }
        }

        newSections[SectionName.experiences].push(experienceSection);
      });
    }

    return newSections;
  };

  const fetchCompanies = () => {
    if (!companies.length) {
      dispatch(ProfileActions.fetchEditCompanies());
    }
  };

  const fetchPositions = (namespace) => {
    if (!positions[namespace]?.length) {
      dispatch(ProfileActions.fetchEditPositions(formik.values[namespace][FieldName.Company].id, namespace));
    }
  };

  const fetchBrands = (namespace) => {
    if (!brands[namespace]?.length) {
      dispatch(ProfileActions.fetchEditBrands(formik.values[namespace][FieldName.Company].id, namespace));
    }
  };

  const handleSubmitCallback = (actions) => {
    actions.setSubmitting(false);
  };

  const checkIfNonVerificableChanged = () => {
    Object.keys(formik.values).forEach((key) => {
      if (initialValues[key] !== formik.values[key]) {
        sections.forEach((section) => {
          section.fields.map((field) => {
            if (field.name === key) {
              if (!field.verificable) {
                setFieldsSavedMessageVisible(true);
              }
            }
          });
        });
      }
    });
  };

  const handleSubmit = (values, actions) => {
    checkIfNonVerificableChanged();
    actions.setSubmitting(true);
    dispatch(ProfileActions.sendEditForm(
      getValuesInSections(values),
      () => handleSubmitCallback(actions),
    ));
  };

  const formik = useFormik({
    validationSchema: FormSchema(namespaces),
    initialValues,
    onSubmit: handleSubmit,
  });

  const handleDateChange = (fieldName, date, namespace) => {
    formik.setFieldValue(namespace ? `${namespace}.${fieldName}` : fieldName, date / 1000);

    setDateValid({
      ...dateValid,
      [fieldName]: date === 0 || (!!date && date < Date.now() && date >= -2208993840000), // ==> 01.01.1900
    });
  };

  const handleCompanyChange = ({ target }, namespace) => {
    const company = companies.find((company) => company.id === target.value);
    formik.setFieldValue(`${namespace}.${FieldName.Company}`, company);
    formik.setFieldValue(`${namespace}.${FieldName.Position}`, null);
    formik.setFieldValue(`${namespace}.${FieldName.EmploymentDateFrom}`, null);
    formik.setFieldValue(`${namespace}.${FieldName.EmploymentDateTo}`, null);
    formik.setFieldValue(`${namespace}.${FieldName.Brands}`, []);
    dispatch(ProfileActions.fetchEditPositions(company.id, namespace));
    dispatch(ProfileActions.fetchEditBrands(company.id, namespace));
  };

  const handlePositionChange = ({ target }, namespace) => {
    const position = positions[namespace].find((position) => position.id === target.value);
    formik.setFieldValue(`${namespace}.${FieldName.Position}`, position);
    formik.setFieldValue(`${namespace}.${FieldName.EmploymentDateFrom}`, null);
    formik.setFieldValue(`${namespace}.${FieldName.EmploymentDateTo}`, null);
  };

  const handleBrandChange = ({ target }, namespace) => {
    const selectedBrands = formik.values[namespace][FieldName.Brands];

    if (selectedBrands.find((brand) => brand.id === target.value)) {
      const newSelectedBrands = [...selectedBrands.filter((brand) => brand.id !== target.value)];
      if (!newSelectedBrands.find((brand) => brand.name === "leasingo")) {
        formik.setFieldValue(
          `${namespace}.${FieldName.Brands}`,
          [...newSelectedBrands.filter((brand) => !brand.parent)],
        );
      } else {
        formik.setFieldValue(`${namespace}.${FieldName.Brands}`, newSelectedBrands);
      }
    } else {
      const brand = brands[namespace].find((brand) => brand.id === target.value);
      formik.setFieldValue(`${namespace}.${FieldName.Brands}`, [...selectedBrands, brand]);
    }
  };

  const handlePositionFinishChange = ({ target }, namespace) => {
    formik.setFieldValue(`${namespace}.${FieldName.PositionFinish}`, target.checked);

    if (!target.checked) {
      formik.setFieldValue(`${namespace}.${FieldName.EmploymentDateTo}`, null);
    }
  };

  const validateLeasingoBrands = () => {
    let validationFailed = false;

    for (const namespace of namespaces) {
      if (!!formik.values[namespace][FieldName.Brands]?.find((brand) => brand.name === "leasingo")
      && !formik.values[namespace][FieldName.Brands]?.find((brand) => !!brand.parent)) {
        validationFailed = true;
      }
    }

    return !validationFailed;
  };

  const handleAddExperience = (namespace) => {
    formik.setFieldValue(`${namespace}.${FieldName.Company}`, null);
    formik.setFieldValue(`${namespace}.${FieldName.Position}`, null);
    formik.setFieldValue(`${namespace}.${FieldName.EmploymentDateFrom}`, null);
    formik.setFieldValue(`${namespace}.${FieldName.EmploymentDateTo}`, null);
    formik.setFieldValue(`${namespace}.${FieldName.Brands}`, []);
    formik.setFieldValue(`${namespace}.${FieldName.PositionFinish}`, false);
    dispatch(ProfileActions.addExperienceFields(namespace));
    setTimeout(formik.validateForm, 1);
  };

  const renderTextInput = (field) => {
    const fieldType = field.type === FieldType.Email ? field.type : "text";

    return (
      <InputWrapper key={field.name}>
        <Label>
          {field.label}
          {field.required && !field.disabled && "*"}
        </Label>
        <Input
          type={fieldType}
          name={field.name}
          value={formik.values[field.name]}
          disabled={field.disabled}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={formik.handleFocus}
          error={formik.touched[field.name] && formik.errors[field.name]}
          styleType={InputStyle.ProfileForm}
        />
      </InputWrapper>
    );
  };

  const renderDropdown = (field, namespace) => {
    const value = namespace ? formik.values[namespace][field.name]?.name : formik.values[field.name]?.name;
    const valueId = namespace ? formik.values[namespace][field.name]?.id : formik.values[field.name]?.id;

    return (
      <InputWrapper key={field.name}>
        <Label>
          {field.label}
          {field.required && !field.disabled && "*"}
        </Label>
        <DropdownInput
          name={field.name}
          onChange={handlePositionChange}
          value={value}
          valueId={valueId}
          placeholder={value}
          options={positionOptions[namespace] || []}
          onOpen={() => fetchPositions(namespace)}
          disabled={field.disabled}
          styleType={InputStyle.ProfileForm}
        />
      </InputWrapper>
    );
  };

  const renderLongDropdown = (field, namespace) => {
    const isCompanyField = field.name === FieldName.Company;
    const value = namespace ? formik.values[namespace][field.name]?.name : formik.values[field.name]?.name;
    const valueId = namespace ? formik.values[namespace][field.name]?.id : formik.values[field.name]?.id;

    return (
      <React.Fragment key={field.name}>
        <LongInputWrapper>
          <Label>
            {field.label}
            {field.required && !field.disabled && "*"}
          </Label>
          <DropdownInput
            name={field.name}
            onChange={(event) => isCompanyField ? handleCompanyChange(event, namespace) : handlePositionChange(event, namespace)}
            value={value}
            valueId={valueId}
            placeholder={value}
            onOpen={() => isCompanyField ? fetchCompanies() : fetchPositions(namespace)}
            options={isCompanyField ? companyOptions : positionOptions[namespace] || []}
            disabled={field.disabled || (namespace && !isCompanyField && !formik.values[namespace][FieldName.Company])}
            withSearchField={isCompanyField}
            styleType={InputStyle.ProfileForm}
          />
        </LongInputWrapper>
        <InputWrapper hidden />
      </React.Fragment>
    );
  };

  const renderDropdownMultiple = (field, namespace) => {
    const values = namespace ? formik.values[namespace][field.name] : formik.values[field.name];
    const value = values.filter((brand) => !brand.parent).map(
      (brand) => brand.label
    );
    const brandValues = namespace ? formik.values[namespace][FieldName.Brands] : formik.values[FieldName.Brands];
    const leasingoValue = brandValues.filter((brand) => !!brand.parent).map(
      (brand) => brand.label
    );
    const placeholder = value.toString().replaceAll(",", ", ");
    const leasingoPlaceholder = leasingoValue.toString().replaceAll(",", ", ");

    return (
      <React.Fragment key={field.name}>
        <LongInputWrapper>
          <Label>
            {field.label}
            {field.required && !field.disabled && "*"}
          </Label>
          <DropdownInput
            name={field.name}
            onChange={(event) => handleBrandChange(event, namespace)}
            onOpen={() => fetchBrands(namespace)}
            value={value}
            placeholder={placeholder || t("profile.edit.choose")}
            options={brands[namespace]?.filter((brand) => !brand.parent) || []}
            disabled={field.disabled || (namespace && !formik.values[namespace][FieldName.Company])}
            styleType={InputStyle.ProfileForm}
            multiple
          />
        </LongInputWrapper>
        {!!brandValues.find((brand) => brand.name === "leasingo") && (
          <LongInputWrapper>
            <Label>{t("profile.edit.leasingo")}</Label>
            <DropdownInput
              name="leasingo"
              onChange={(event) => handleBrandChange(event, namespace)}
              value={leasingoValue}
              placeholder={leasingoPlaceholder || t("profile.edit.choose")}
              options={brands[namespace]?.filter((brand) => !!brand.parent) || []}
              disabled={field.disabled}
              styleType={InputStyle.ProfileForm}
              multiple
            />
          </LongInputWrapper>
        )}
        <InputWrapper hidden />
      </React.Fragment>
    );
  };

  const renderDateInput = (field, namespace) => {
    const value = namespace ? formik.values[namespace][field.name] : formik.values[field.name];
    const selectedDate = value ? Number(value * 1000) : null;
    const checkboxChecked = namespace && field.name === FieldName.EmploymentDateTo
      ? formik.values[namespace][FieldName.PositionFinish] === true
      : true;

    return (
      <InputWrapper key={field.name}>
        <Label>
          {field.label}
          {field.required && !field.disabled && "*"}
        </Label>
        <DatePicker
          selectedDate={selectedDate}
          onChange={(date) => handleDateChange(field.name, date, namespace)}
          disabled={field.disabled || !checkboxChecked}
          name={field.name}
          onBlur={formik.handleBlur}
          onFocus={formik.handleFocus}
          maxDate={Date.now()}
          styleType={InputStyle.ProfileForm}
        />
      </InputWrapper>
    );
  };

  const renderStaticField = (field) => {
    const value = field.type === FieldType.Date
      ? simpleDateFromTimestamp(field.value)
      : field.value;

    return (
      <StaticFieldWrapper key={field.name}>
        <StaticLabel>{field.label}:</StaticLabel>
        <StaticValue>{value}</StaticValue>
      </StaticFieldWrapper>
    );
  };

  const renderCheckbox = (field, namespace) => {
    const value = namespace ? formik.values[namespace][field.name] : formik.values[field.name];

    if (field.disabled) return null;

    return (
      <React.Fragment key={field.name}>
        <CheckboxWrapper>
          <Checkbox
            name={field.name}
            label={field.label}
            checked={value}
            onChange={(target) => handlePositionFinishChange(target, namespace)}
          />
        </CheckboxWrapper>
        <InputWrapper hidden />
      </React.Fragment>
    );
  }

  const renderFields = (fields, namespace) => fields.map((field) => {
    if (field.static) {
      return renderStaticField(field);
    } else {
      switch (field.type) {
      case FieldType.String:
      case FieldType.Email:
        return renderTextInput(field);
      case FieldType.Dropdown:
        return renderDropdown(field, namespace);
      case FieldType.LongDropdown:
        return renderLongDropdown(field, namespace);
      case FieldType.DropdownMultiple:
        return renderDropdownMultiple(field, namespace);
      case FieldType.Date:
        return renderDateInput(field, namespace);
      case FieldType.StringArray:
        return renderTextInput(field);
      case FieldType.Checkbox:
        return namespace !== namespaces[namespaces.length - 1] ? renderCheckbox(field, namespace) : null;
      default:
        return null;
      }
    }
  });

  const renderSections = () => sections.map((section) => (
    <Section key={section.name}>
      <SectionHeader>{section.label}</SectionHeader>
      <Separator $narrow />
      <InputWrapper hidden />
      {renderFields(section.fields)}
      {section.sections?.map((nestedSection, index) => (
        <React.Fragment key={nestedSection.name}>
          {renderFields(nestedSection.fields, nestedSection.name)}
          {index === section.sections.length - 1
            && (
              section.sections.length > 1
              || !nestedSection.fields.find((field) => field.name === FieldName.Brands && field.value.find((brand) => brand.name === "leasingo"))
            ) ? (
              <AddButton
                color={ButtonColor.Primary}
                onClick={() => handleAddExperience(`${SectionName.experiences}_${section.sections.length}`)}
              >
                {t("profile.edit.addPosition")}
              </AddButton>
            ) : (
              <>
                <Separator $narrow />
                <InputWrapper hidden />
              </>
            )
          }
        </React.Fragment>
      ))}
    </Section>
  ));

  const renderVerificationMessage = renderWhenTrue(() => (
    <Message>{t("profile.edit.verificationInProgress")}</Message>
  ));

  const renderErrorMessage = renderWhenTrue(() => (
    <Message>{t("profile.edit.error")}</Message>
  ));

  const renderFieldsSavedMessage = renderWhenTrue(() => (
    <Message>{t("profile.edit.fieldsSaved")}</Message>
  ));

  return (
    <Container className={className}>
      <UpperInfo>* - {t("profile.edit.fieldRequired")}</UpperInfo>
      <form onSubmit={formik.handleSubmit}>
        {renderSections()}
        <SaveButton
          color={ButtonColor.Primary}
          onClick={formik.handleSubmit}
          disabled={
            !formik.dirty
              || !formik.isValid
              || formik.isSubmitting
              || !(Object.values(dateValid).every((item) => item))
              || (Theme.ProfileEdit === ProfileEditTheme.WithCompany && !validateLeasingoBrands())
          }
        >
          {t("profile.edit.save")}
        </SaveButton>
        {renderFieldsSavedMessage(fieldsSavedMessageVisible)}
        {renderVerificationMessage(verificationMessageVisible)}
        {renderErrorMessage(errorMessageVisible)}
      </form>
    </Container>
  );
};

EditForm.propTypes = {
  className: PropTypes.string,
};
