import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Dropdown } from "./dropdown/dropdown.component";
import {
  Container,
  Title,
  TitleContainer,
  Category,
} from "./matchToLabelBox.styled";

export const MatchToLabelBox = ({
  title,
  dropdowns,
  labelIndex,
  columnsCorrectness,
  setColumnsCorrectness,
  enableValidating,
  category,
}) => {
  const renderDropdowns = () => dropdowns.map(
    ({ title, values }, index) => {

      const updateValidityState = (value) => {
        const newArray = _.cloneDeep(columnsCorrectness)
        newArray[index][labelIndex - 1] = value;
        setColumnsCorrectness(newArray);
      };

      return (
        <Dropdown
          key={index}
          dropdownIndex={index}
          labelIndex={labelIndex}
          updateValidityState={updateValidityState}
          enableValidating={enableValidating}
          title={title}
          values={values}
        />
      );
    }
  );

  return (
    <Container>
      <TitleContainer>
        <Category>{category}</Category>
        <Title>{title}</Title>
      </TitleContainer>
      {renderDropdowns()}
    </Container>
  );
};

MatchToLabelBox.propTypes = {
  title: PropTypes.string,
  dropdowns: PropTypes.array,
  labelIndex: PropTypes.number,
  columnsCorrectness: PropTypes.array,
  setColumnsCorrectness: PropTypes.func,
  enableValidating: PropTypes.bool,
  category: PropTypes.string,
};
