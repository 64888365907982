import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { styleWhenTrue } from "../../helpers/rendering";
import { Color } from "../../theme/colors";
import { FontWeight } from "../../theme/fonts";
import { Media, hasHoverOtherwise } from "../../theme/media";
import { hexToRgba } from "../../helpers/colors";
import { ButtonColor } from "./button.constants";

const hoverColor = hexToRgba(Color.deepGray, .55);

const smallButtonStyles = css`
  height: 20px;
  padding: 0 10px;
  min-width: auto;
  background-color: ${Color.transparent} !important;
  border: none !important;
  color: ${Color.deepGray};

  ${hasHoverOtherwise(css`
    color: ${hoverColor};
  `, css`
    color: ${Color.deepGray};
  `)}

  &:disabled {
    color: ${Color.dirtyWhite};
  }

  ${styleWhenTrue(propEq(ButtonColor.Primary, "color"), css`
    color: ${() => Color.brandBasic};

    ${hasHoverOtherwise(css`
      color: ${() => hexToRgba(Color.brandBasic, .55)};
    `, css`
      color: ${() => Color.brandBasic};
    `)}
  `)}
`;

export const DefaultButtonStyles = css`
  min-width: 162px;
  padding: 0 24px;
  height: 43px;
  font-size: 10px;
  border-radius: 3px;
  line-height: 20px;
  font-weight: ${FontWeight.Bold};
  background-color: ${Color.deepGray};
  color: ${Color.white};
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 150ms ease-in-out;
  border: none;

  ${Media.tablet`
    min-width: 145px;
  `}

  ${hasHoverOtherwise(css`
    background-color: ${hoverColor};
  `, css`
    background-color: ${Color.deepGray};
  `)}

  &:disabled {
    cursor: default;
    background-color: ${Color.dirtyWhite};
    color: ${Color.white};
    border-color:${Color.dirtyWhite};
  }

  ${styleWhenTrue(prop("$large"), css`
    height: 56px;
    font-size: 14px;
    font-weight: ${FontWeight.SemiBold};
  `)}

  ${styleWhenTrue(propEq(ButtonColor.Primary, "color"), css`
    background-color: ${() => Color.brandBasic};

    ${hasHoverOtherwise(css`
      background-color: ${() => hexToRgba(Color.brandBasic, .55)};
    `, css`
      background-color: ${() => Color.brandBasic};
    `)}
  `)}

  ${styleWhenTrue(propEq(ButtonColor.Action, "color"), css`
    background-color: ${Color.almostWhite};
    color: ${Color.gray4};
  `)}

  ${styleWhenTrue(prop("small"), smallButtonStyles)}
`;

export const DefaultContainer = styled.button`
  ${DefaultButtonStyles}
`;

export const OutlinedButtonStyles = css`
  border: 1px solid ${Color.dirtyWhite};
  background-color: ${Color.transparent};
  color: ${Color.deepGray};
  border-color:${Color.dirtyWhite};

  ${hasHoverOtherwise(css`
    background-color: ${Color.almostWhite};
  `, css`
    background-color: ${Color.transparent};
  `)}

  &:disabled {
    background-color: ${Color.transparent};
    color:${Color.dirtyWhite};
  }
`;

export const OutlinedContainer = styled(DefaultContainer)`
  ${OutlinedButtonStyles}
`;
