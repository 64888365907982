import { AppConfig } from "../../../../appConfig";

export const getData = (url, setData, setIsPending) => {
  fetch(url)
    .then(response => response.json())
    .then((jresp) => {
      setData(jresp);
      setIsPending(false);
    });
};

export const getAnimation = (src, setAnimationData) => {
  fetch(`${AppConfig.content}${src}`)
    .then((response) => response.json())
    .then((jresp) => setAnimationData(jresp));
};
