export const CoordsLeasinGo = [
  [
    { x: 128, y: 20 },
    { x: 128, y: 44 },
    { x: 128, y: 68 },
    { x: 128, y: 92 },
    { x: 128, y: 116 },
    { x: 128, y: 140 },
    { x: 128, y: 164 },
    { x: 152, y: 164 },
    { x: 176, y: 164 },
    { x: 176, y: 188 },
    { x: 200, y: 188 },
    { x: 224, y: 190 },
  ],
  [
    { x: 20, y: 212 },
    { x: 44, y: 212 },
    { x: 68, y: 212 },
    { x: 92, y: 212 },
    { x: 116, y: 212 },
    { x: 140, y: 212 },
    { x: 140, y: 188 },
    { x: 140, y: 164 },
    { x: 116, y: 164 },
    { x: 92, y: 164 },
    { x: 68, y: 164 },
    { x: 68, y: 140 },
    { x: 68, y: 118 },
    { x: 50, y: 20},
  ],
  [
    { x: 86, y: 330 },
    { x: 86, y: 306 },
    { x: 110, y: 306 },
    { x: 134, y: 306 },
    { x: 158, y: 306 },
    { x: 182, y: 306 },
    { x: 182, y: 282 },
    { x: 182, y: 258 },
    { x: 182, y: 234 },
    { x: 182, y: 212 },
    { x: 150, y: 106 },
  ],
];
