import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";
import { Link as BaseLink } from "react-router-dom";

import { Button } from "../../../../components/button/button.component";
import { hexToRgba } from "../../../../helpers/colors";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { ReactComponent as ArrowSvg } from "../../../../images/arrow.svg";
import { ReactComponent as TrashSvg } from "../../../../images/trash.svg";
import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.div`
  padding: 20px 30px 20px 20px;
  background-color: ${Color.white};
  margin-bottom: 2px;
  width: 100%;
  text-align: left;
  box-shadow: 0 5px 10px ${hexToRgba(Color.deepGray, 0.01)};
  cursor: pointer;

  ${Media.tablet`
    padding: 38px 45px 38px 35px;
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 20px;
  flex-shrink: 0;

  ${Media.tablet`
    width: 40px;
    height: 40px;
    margin-right: 39px;
  `}
`;

export const TitleWrapper = styled.div`
  margin-right: 20px;
`;

export const Title = styled.p`
  font-weight: ${FontWeight.SemiBold};
  font-size: 14px;
  line-height: 20px;
  color: ${Color.deepGray};
  margin-bottom: 2px;
`;

export const DateInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const Date = styled.span`
  font-size: 13px;
  line-height: 17px;
  color: ${Color.gray9};

  ${styleWhenTrue(prop("unread"), css`
    color: ${() => Color.brandBasic};
    font-weight: ${FontWeight.Bold};
  `)}
`;

export const ArrowWrapper = styled.div`
  margin-left: auto;
  transform: rotate(-90deg);
  flex-shrink: 0;
`;

export const ArrowIcon = styled(ArrowSvg)`
  fill: ${Color.gray9};
  width: 10px;
  height: 16px;
  transition: transform 0.3s ease-in-out;

  ${styleWhenTrue(propEq("true", "rotated"), css`
    transform: rotateY(180deg);
  `)}
`;

export const Dot = styled.div`
  width: 5px;
  height: 5px;
  background-color: ${() => Color.brandBasic};
  margin-right: 5px;
  border-radius: 50%;
`;

export const Content = styled.div`
  margin-top: 20px;
  margin-left: 0;

  ${Media.tablet`
    margin-left: 80px;
  `}
`;

export const Text = styled.p`
  max-width: 680px;
  font-size: 14px;
  line-height: 24px;
  color: ${Color.gray9};
`;

export const TrashButton = styled.button`
  background-color: ${Color.transparent};
  border: none;
  margin-left: -6px;
  margin-top: 20px;
`;

export const TrashIcon = styled(TrashSvg)`
  width: 14px;
  height: 18px;
  fill: ${() => Color.brandBasic};
`;

export const Link = styled(BaseLink)`
  color: ${() => Color.brandBasic};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const DeleteButton = styled(Button)`
  margin-top: 20px;
  width: calc(100% + 10px);
  color: ${() => Color.brandBasic};
`;
