import { ReactComponent as ModuleIcon } from "./images/moduleIcon.svg";

export const Config = {
  moduleName: "competence",
  dependencies: [],
  label: "competence.pageTitle",
  Icon: ModuleIcon,
  urls: ["/competence/*", "/competence"],
  routeName: "api_competence_user_list",
  routes: {
    list: "/list",
    entry: "/:id/:tab",
    selfMarkSurvey: "/:id/self-mark/:measureId",
  },
  options: {},
  theme: {}
};
