export const ScrollOptions = {
  block: "start",
  behavior: "smooth",
};

export const IntroBgAnimPromise = import("../../animations/introBg.json");

export const BgAnimPromises = [
  import("../../animations/stage1Bg.json"),
  import("../../animations/stage2Bg.json"),
  import("../../animations/stage3Bg.json"),
  import("../../animations/stage4Bg.json"),
  import("../../animations/stage5Bg.json"),
];

export const AvatarAnimPromises = [
  import("../../animations/troll.json"),
  import("../../animations/ogr.json"),
  import("../../animations/ork.json"),
  import("../../animations/smok.json"),
  import("../../animations/wirus.json"),
];

export const LeasinGoAnimPromises = [
  import("../../animations/troll.json"),
  import("../../animations/ogr.json"),
  import("../../animations/wirus.json"),
];
