import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { InteractionAnswerLabel } from "../../../../theme";
import { AnswerStatus } from "../../../interactions.constants";
import { YourAnswerInfo } from "../answers/answers.styled";
import AnswerDisc from "../answerDisc/answerDisc.component";

import { Container } from "./singleChoiceAnswer.styled";

const SingleChoiceAnswer = ({
  data,
  checked,
  confirmed,
  onSelect,
}) => {
  const { id, label, value } = data;
  const { t } = useTranslation();

  const status = useMemo(() => {
    if (confirmed && checked && value === true) {
      return AnswerStatus.CorrectChecked;
    } else if (confirmed && checked && value === false) {
      return AnswerStatus.WrongChecked;
    } else if (confirmed && !checked && value === true) {
      return AnswerStatus.WrongUnchecked;
    } else if (checked) {
      return AnswerStatus.Checked;
    } else {
      return AnswerStatus.Default;
    }
  }, [checked, confirmed, value]);

  const handleOnClick = () => {
    onSelect(id);
  };

  return (
    <Container status={status} onClick={handleOnClick}>
      <AnswerDisc status={status} />
      <InteractionAnswerLabel status={status}>{label}</InteractionAnswerLabel>
      {status === AnswerStatus.CorrectChecked || status === AnswerStatus.WrongChecked && (
        <YourAnswerInfo status={status}>
          {t("interactions.quizQuestions.yourAnswer")}
        </YourAnswerInfo>
      )}
    </Container>
  );
};

export default SingleChoiceAnswer;
