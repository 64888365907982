import styled from "styled-components";

import {
  ContentInner as ContentInnerBase,
  ContentWrapper as ContentWrapperBase,
  Color,
  FontWeight,
  Media,
} from "../../../../theme";
import { ReactComponent as FinishedSvg } from "../../images/finishedScreen.svg";

export const ContentWrapper = styled(ContentWrapperBase)`
  background-color: ${Color.white};
  padding: 60px 0;

  ${Media.tablet`
    padding: 115px 0 125px;
  `}
`;

export const ContentInner = styled(ContentInnerBase)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Media.tablet`
    flex-direction: row;
  `}
`;

export const Row = styled.div`
  width: 100%;

  ${Media.tablet`
    width: 50%;

    &:not(:last-of-type) {
      margin-right: 50px;
    }
  `}
`;

export const Title = styled.h1`
  font-weight: ${FontWeight.Bolder};
  margin-bottom: 25px;
  color: ${Color.deepGray};
  font-size: 30px;
  line-height: 41px;

  ${Media.tablet`
    font-size: 45px;
    line-height: 61px;
  `}
`;

export const Text = styled.p`
  color: ${Color.deepGray};
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 50px;

  ${Media.tablet`
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 34px;
  `}
`;

export const FinishedImage = styled(FinishedSvg)`
  max-width: 100%;
`;
