import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Button } from "../../../../components/button/button.component";
import { hexToRgba } from "../../../../helpers/colors";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color, Media } from "../../../../theme";
import { ReactComponent as CalendarSvg } from "../../images/calendar.svg";

export const Container = styled.div`
  position: relative;
  margin: 10px 0;
  padding: 0 11px;

  ${Media.tablet`
    margin: 0;
    padding: 0;
  `}
`;

export const StyledButton = styled(Button)`
  height: 56px;
  font-size: 14px;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  ${styleWhenTrue(prop("$isOpen"), css`
    box-shadow: 0 -2px 6px 0 ${hexToRgba(Color.black, 0.02)}, 0 -6px 12px 0 ${hexToRgba(Color.black, 0.02)};
  `)}

  ${Media.tablet`
    ${styleWhenTrue(prop("$isOpen"), css`
      box-shadow: 0 2px 6px 0 ${hexToRgba(Color.black, 0.02)}, 0 6px 12px 0 ${hexToRgba(Color.black, 0.02)};
    `)}
  `}
`;

export const Options = styled.ul`
  display: none;
  position: absolute;
  bottom: 55px;
  background-color: ${Color.whiteHover};
  border: 1px solid ${Color.dirtyWhite};
  width: calc(100% - 22px);

  ${styleWhenTrue(prop("$visible"), css`
    display: block;
  `)}

  ${Media.tablet`
    top: 55px;
    bottom: unset;
    width: 100%;
  `}
`;

export const Option = styled.li``;

export const CalendarLink = styled.a`
  display: flex;
  height: 48px;
  padding: 0 12px;
  gap: 15px;
  font-size: 14px;
  line-height: 1.6;
  color: ${Color.gray9};
  align-items: center;
  text-decoration: none;

  &:hover {
    background-color: ${Color.dirtyWhite};
  }
`;

const iconStyles = css`
  width: 20px;
  height: auto;
  flex-shrink: 0;
`;

export const CalendarIcon = styled(CalendarSvg)`
  ${iconStyles}
`;

export const OptionIcon = styled.img`
  ${iconStyles}
  display: block;
`;
