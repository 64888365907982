import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";
import Lottie from "lottie-react";
import { useSelector } from "react-redux";

import { DutiesEnum } from "../../../../interactions/external/opponentTest/opponentTest.constants";
import { useMediaListener } from "../../../../hooks/useMediaListener";
import { selectUserDuties } from "../../../../userContext/redux/userContext.selectors";
import { OnboardingMode } from "../../../../app/app.constants";
import { selectOnboardingMode } from "../../redux/onboarding.selectors";
import { Stage } from "../defaultStages/stage/stage.component";
import { NextLessonHash } from "../defaultStages/stage/stage.constants";
import { StageGroupType } from "../defaultStages/defaultStages.constants";
import { BoardLegend } from "../boardLegend/boardLegend.component";
import { BoardStage } from "../boardStage/boardStage.component";
import {
  LottieWrapper,
  StageBox,
  StageTitle,
  Container as StageWrapper,
  StartButton,
  StartButtonAddon,
} from "../boardStage/boardStage.styled";

import { CoordsAll } from "./coords/all";
import { CoordsNew } from "./coords/new";
import { CoordsUsed } from "./coords/used";
import { CoordsInsurance } from "./coords/insurance";
import { CoordsNewAndUsed } from "./coords/newAndUsed";
import { CoordsNewAndInsurance } from "./coords/newAndInsurance";
import { CoordsUsedAndInsurance } from "./coords/usedAndInsurance";
import { CoordsVWHR } from "./coords/vw-hr";
import { CoordsCompetenceHR } from "./coords/competence-hr";
import { CoordsLeasinGo } from "./coords/leasinGo";
import { Container, BoardWrapper, ScrollProvider } from "./board.styled";
import { IntroBgAnimPromise, ScrollOptions } from "./board.constants";

export const Board = ({
  stages,
  expandedStageId,
  onTutorialClick,
  onGameModeClick,
}) => {
  const { isTabletWide } = useMediaListener();
  const { t } = useTranslation();
  const duties = useSelector(selectUserDuties);
  const onboardingMode = useSelector(selectOnboardingMode);
  const [hasNextLessonHash, setHasNextLessonHash] = useState(false);
  const [introBg, setIntroBg] = useState();
  const coords = useMemo(() => {
    if (onboardingMode === OnboardingMode.OnboardingAll) {
      return CoordsAll;
    } else if (onboardingMode === OnboardingMode.LeasinGo) {
      return CoordsLeasinGo;
    } else if (onboardingMode === OnboardingMode.OnboardingVWFS) {
      return CoordsVWHR;
    } else if (duties?.length === 1) {
      if (duties[0].name === DutiesEnum.FinancingNew) {
        return CoordsNew;
      } else if (duties[0].name === DutiesEnum.FinancingUsed) {
        return CoordsUsed;
      } else if (duties[0].name === DutiesEnum.Insurance) {
        return CoordsInsurance;
      } else if (duties[0].name === DutiesEnum.HR) {
        return CoordsCompetenceHR;
      }
    } else if (duties?.length === 2) {
      if (
        duties.some((type) => type.name === DutiesEnum.FinancingNew)
        && duties.some((type) => type.name === DutiesEnum.FinancingUsed)
      ) {
        return CoordsNewAndUsed;
      } else if (
        duties.some((type) => type.name === DutiesEnum.FinancingNew)
        && duties.some((type) => type.name === DutiesEnum.Insurance)
      ) {
        return CoordsNewAndInsurance;
      } else if (
        duties.some((type) => type.name === DutiesEnum.FinancingUsed)
        && duties.some((type) => type.name === DutiesEnum.Insurance)
      ) {
        return CoordsUsedAndInsurance;
      }
    }

    return CoordsAll;
  }, [duties]);

  useEffect(() => {
    IntroBgAnimPromise.then(setIntroBg);
  }, []);

  useEffect(() => {
    // Timeout needed for proper scrolling to the section
    setTimeout(() => setHasNextLessonHash(window.location.hash === NextLessonHash), 1);
  }, []);

  return (
    <Container>
      <ScrollIntoViewIfNeeded
        active={hasNextLessonHash}
        options={ScrollOptions}
      >
        <BoardLegend />
        {isTabletWide ? (
          <BoardWrapper leasinGoMode={onboardingMode === OnboardingMode.LeasinGo}>
            <StageWrapper>
              <StageTitle passed>
                {t("onboarding.stages.intro")}
              </StageTitle>
              <LottieWrapper>
                <Lottie
                  animationData={introBg}
                  loop
                  rendererSettings={{
                    preserveAspectRatio: "xMidYMid slice",
                  }}
                />
              </LottieWrapper>
              <StageBox>
                <StartButton onClick={onTutorialClick}>
                  {t("onboarding.stages.start")}
                  <StartButtonAddon />
                </StartButton>
              </StageBox>
            </StageWrapper>
            {stages.map((stage, stageIndex) => (
              <BoardStage
                key={stageIndex}
                stage={stage}
                stageIndex={stageIndex}
                isLastStage={stageIndex === stages.length - 1}
                coords={coords}
                onGameModeClick={() => onGameModeClick(stageIndex)}
              />
            ))}
          </BoardWrapper>
        ) : (
          <>
            <ScrollProvider active={false} options={ScrollOptions}>
              <Stage
                title={t("onboarding.stages.intro")}
                hasExpandedStageId={false}
                shouldShowNextLesson={false}
                isMapView
                isIntro
              >
                <StageWrapper isIntro>
                  <StageTitle passed>
                    {t("onboarding.stages.intro")}
                  </StageTitle>
                  <LottieWrapper>
                    <Lottie
                      animationData={introBg}
                      loop
                      rendererSettings={{
                        preserveAspectRatio: "xMidYMid slice",
                      }}
                    />
                  </LottieWrapper>
                  <StageBox>
                    <StartButton onClick={onTutorialClick}>
                      {t("onboarding.stages.start")}
                      <StartButtonAddon />
                    </StartButton>
                  </StageBox>
                </StageWrapper>
              </Stage>
            </ScrollProvider>
            {stages.map((stage, index) => {
              const lessons = stage.itemGroup[StageGroupType.Content].items;
              const lessonsPassed = lessons.filter((item) => !!item.passed).length;
              const shouldShowNextLesson = hasNextLessonHash && stage.isEnabled && !stage.passed;
              const hasExpandedStageId = index === expandedStageId;

              return (
                <ScrollProvider
                  key={stage.name}
                  active={hasExpandedStageId}
                  options={ScrollOptions}
                >
                  <Stage
                    title={stage.name}
                    description={stage.description}
                    lessons={lessons}
                    lessonsPassed={lessonsPassed}
                    disabled={!stage.isEnabled}
                    hasExpandedStageId={hasExpandedStageId}
                    shouldShowNextLesson={shouldShowNextLesson}
                    isMapView
                    stageIndex={index}
                    stage={stage}
                    coords={coords}
                  />
                </ScrollProvider>
              );
            })}
          </>
        )}
      </ScrollIntoViewIfNeeded>
    </Container>
  );
};

Board.propTypes = {
  stages: PropTypes.arrayOf(PropTypes.object),
  expandedStageId: PropTypes.number,
  onTutorialClick: PropTypes.func,
  onGameModeClick: PropTypes.func,
};
