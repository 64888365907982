import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { Modal } from "../../../../components/modal/modal.component";
import { StreamType } from "../../services/streams.constants";
import { selectIsResponseNegative } from "../../redux/webinars.selectors";
import { WebinarsActions } from "../../redux/webinars.reducer";

import { ShareScreenContent, ShareCameraContent } from "./waitingModal.constants";
import { ModalText } from "./waitingModal.styled";

export const WaitingModal = ({ className, type }) => {
  const dispatch = useDispatch();
  const isResponseNegative = useSelector(selectIsResponseNegative);
  const content = type === StreamType.Camera ? ShareCameraContent : ShareScreenContent;
  const modalText = isResponseNegative ? content.negativeText : content.text;

  const handleClose = () => {
    setTimeout(() => {
      dispatch(WebinarsActions.receivePermissionResponse({ accepted: true }));
    }, 500);
  };

  return (
    <Modal
      open={!!type || isResponseNegative}
      title={content.title}
      HeaderIcon={content.icon}
      className={className}
      onClose={handleClose}
    >
      <ModalText>{modalText}</ModalText>
    </Modal>
  );
};

WaitingModal.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
};
