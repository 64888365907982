import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { GamificationTheme } from "../../../../../app/mainNavigation/components/navItem.constants";
import { Theme } from "../../../../../appConfig";
import { selectUserRank } from "../../../redux/gamification.selectors";

import {
  Container,
  CoinsIcon,
  CupIcon,
  Stats,
  Counter,
  Text,
  HistoryButton,
  ArrowIcon,
  AmbassadorLogo,
  HeroIcon,
} from "./pointsBox.styled";

export const PointsBox = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userRanking = useSelector(selectUserRank);

  const navigateToPointsHistory = () => {
    navigate("/gamification/points");
  };

  return (
    <Container>
      {Theme.Gamification === GamificationTheme.Ambassador && <AmbassadorLogo />}
      <HeroIcon />
      <Stats>
        <CupIcon />
        <Counter>{userRanking?.rank || 0}</Counter>
        <Text>{t("gamification.pointsBox.ranking")}</Text>
      </Stats>
      <Stats>
        <CoinsIcon />
        <Counter>{userRanking?.points || 0}</Counter>
        <Text>{t("gamification.pointsBox.points")}</Text>
      </Stats>
      <HistoryButton onClick={navigateToPointsHistory}>
        {t("gamification.pointsBox.history")}
        <ArrowIcon />
      </HistoryButton>
    </Container>
  );
};
