import { phpApi } from '../../../api';
import { ApiResponse } from '../../../api/api.types';

export const checkToken: (
  token: string,
) => Promise<ApiResponse<void>> = (
  token: string,
): Promise<ApiResponse<void>> => (
  phpApi
    .post(`/api/user/register/confirm/${token}`)
    .catch((error: ApiResponse<void>): ApiResponse<void> => error)
);
