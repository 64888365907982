import React from "react";
import styled from "styled-components";

import { breakpoints, colors } from "../../../../UIGlobals";
import * as UI from "../timerSlidesUI";

const List = styled.ul`
  font-size: 20px;
  line-height: 22px;
  margin: 20px 0;
  @media (${breakpoints().maxM}) {
    margin: 10px 0;
    font-size: 10px;
    line-height: 11px;
  }
`;

const ListRow = styled.li`
  display: flex;
  align-items: center;
  margin: 7px 0;
  border: 1px solid ${colors().dirtyWhite};
  padding: 20px;
  @media (${breakpoints().maxM}) {
    padding: 10px;
  }
`;

const DotSpan = styled.span`
  min-width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 4px solid ${colors().maroon};
`;

const BorderedList = ({ elements, counter }) => (
  <List>
    {elements.map((element, index) => (
      <ListRow key={index}>
        {counter ? (
          <UI.CounterSpan>{index + 1}</UI.CounterSpan>
        ) : (
          <DotSpan />
        )}
        <UI.ListText>{element}</UI.ListText>
      </ListRow>
    ))}
  </List>
);

export default BorderedList;