import { datadogLogs } from "@datadog/browser-logs";

import { AppConfig } from "../appConfig";
import { store } from "../store";

export const logger = (errorName, errorDetails) => {
  const errorNameWithPrefix = `[Competence]: ${errorName}`;

  if (AppConfig.logsSystemClientToken) {
    const profileData = store.getState().userContext?.data.user;
    datadogLogs.logger.error(errorNameWithPrefix, {
      userId: profileData ? profileData.id : null,
      appErrorDetails: errorDetails,
    });
  }

  if (process.env.NODE_ENV !== "production") {
    console.error(`${errorNameWithPrefix} | ${errorDetails}`);
  }
}
