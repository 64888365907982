import styled from "styled-components";

import { ReactComponent as BaseDownloadIcon } from "../../../../images/download.svg";
import { ReactComponent as BaseCloseIcon } from "../../../../images/close.svg";
import { Color } from "../../../../theme/colors";
import { Media } from "../../../../theme/media";
import { FontWeight } from "../../../../theme/fonts";
import { Button as BaseButton } from "../../../button/button.component";

export const Container = styled.div`
  padding: 120px 20px 50px;

  ${Media.tablet`
    padding: 147px 80px 80px 50px;
  `}
`;

export const DrawerType = styled.p`
  font-size: 12px;
  text-transform: uppercase;
  font-weight: ${FontWeight.Bold};
  margin-bottom: 37px;
  color: ${Color.black};
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Media.tablet`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const DrawerTitle = styled.h3`
  font-size: 33px;
  font-weight: ${FontWeight.Light};
  margin-bottom: 60px;
  margin-right: 20px;
  color: ${Color.black};
`;

export const CloseButton = styled.button`
  padding: 15px;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`;

export const CloseIcon = styled(BaseCloseIcon)`
  width: 22px;
  height: 22px;
  
  g {
    stroke: ${Color.black};
  }
`;

export const DownloadIcon = styled(BaseDownloadIcon)`
  height: 15px;
  margin-right: 19px;
  fill: ${() => Color.brandBasic};
`;

export const Button = styled(BaseButton)`
  min-width: 194px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  background-color: ${() => Color.brandBasic};

  ${DownloadIcon} {
    width: 15px;
  }
`;
