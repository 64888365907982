import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { ReactComponent as CheckSvg } from "../../../../images/check2.svg";
import { Color } from "../../../../theme";
import { ReactComponent as FastingSvg } from "../../images/fasting.svg";

export const Container = styled.div`
  position: relative;
`;

export const FastingIcon = styled(FastingSvg)`
  fill: ${Color.gray1};

  ${styleWhenTrue(prop("$open"), css`
    fill: ${() => Color.brandBasic};
  `)}
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.transparent};
  border: none;

  &:hover {
    ${FastingIcon} {
      fill: ${() => Color.brandBasic};
    }
  }
`;

export const Popup = styled.div`
  position: absolute;
  bottom: calc(100% + 11px);
  left: -50px;
  width: 130px;
  border-bottom: 1px solid ${Color.dirtyWhite};
  display: flex;
  flex-direction: column;
`;

export const Item = styled.button`
  font-size: 10px;
  line-height: 1.2;
  font-weight: 600;
  color: ${Color.gray9};
  padding: 15px 29px;
  background-color: ${Color.white};
  border: none;
  width: 100%;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${Color.almostWhite};
  }

  ${styleWhenTrue(prop("$current"), css`
    background-color: ${Color.almostWhite};
    padding: 15px 12px;
    color: ${Color.almostBlack};
  `)}
`;

export const CheckIcon = styled(CheckSvg)`
  width: 12px;
  height: 12px;
  margin-right: 5px;
  padding: 1px;
  fill: ${() => Color.brandBasic};
`;
