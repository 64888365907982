import { createSelector } from "reselect";
import { prop, path } from "ramda";

export const selectWebinarsDomain = prop("webinars");

export const selectIsInitialDataPending = createSelector(
  selectWebinarsDomain, prop("isPending"),
);

export const selectRemoteCameraEnabled = createSelector(
  selectWebinarsDomain, prop("remoteCameraEnabled"),
);

export const selectUserAuthorized = createSelector(
  selectWebinarsDomain, prop("userAuthorized"),
);

export const selectRoomAccess = createSelector(
  selectWebinarsDomain, prop("permissions"),
);

export const selectStreamsMuted = createSelector(
  selectWebinarsDomain, prop("streamsMuted"),
);

export const selectMessages = createSelector(
  selectWebinarsDomain, prop("messages"),
);

export const selectCurrentRoom = createSelector(
  selectWebinarsDomain, prop("room"),
);

export const selectJoinToRoomModalData = createSelector(
  selectWebinarsDomain, prop("joinToRoomModalData"),
);

export const selectRedirectScreenOpen = createSelector(
  selectWebinarsDomain, prop("redirectScreenOpen"),
);

export const selectRoomLive = createSelector(
  selectCurrentRoom, prop("live"),
);

export const selectRequests = createSelector(
  selectWebinarsDomain, prop("requests"),
);

export const selectRequestSentType = createSelector(
  selectWebinarsDomain, prop("requestSentType"),
);

export const selectIsResponseNegative = createSelector(
  selectWebinarsDomain, prop("isResponseNegative"),
);

export const selectGroups = createSelector(
  selectCurrentRoom, prop("groups"),
);

export const selectParticipants = createSelector(
  selectCurrentRoom, path(["participants", "list"]),
);

export const selectChatActivated = createSelector(
  selectCurrentRoom, prop("chatActivate"),
);

export const selectChatStartTimestamp = createSelector(
  selectCurrentRoom, prop("chatStartTime"),
);

export const selectLiveStartTimestamp = createSelector(
  selectCurrentRoom, prop("liveStartTime"),
);

export const selectIsCyclicalMeeting = createSelector(
  selectCurrentRoom, prop("cyclical"),
);

export const selectVideoDevices = createSelector(
  selectWebinarsDomain, prop("videoDevices"),
);

export const selectAudioDevices = createSelector(
  selectWebinarsDomain, prop("audioDevices"),
);

export const selectRecording = createSelector(
  selectCurrentRoom, prop("recording"),
);

export const selectRecordingTime = createSelector(
  selectCurrentRoom, prop("recordingTime"),
);

export const selectRecordingPaused = createSelector(
  selectCurrentRoom, prop("recordingPaused"),
);

export const selectCurrentVideoDevice = createSelector(
  selectWebinarsDomain, prop("currentVideo"),
);

export const selectCurrentAudioDevice = createSelector(
  selectWebinarsDomain, prop("currentAudio"),
);

export const selectSpeakingUser = createSelector(
  selectWebinarsDomain, prop("speaking"),
);

export const selectPongReceived = createSelector(
  selectWebinarsDomain, prop("pongReceived"),
);

export const selectMainStreamUser = createSelector(
  selectWebinarsDomain, prop("mainStream"),
);

export const selectIsCameraLoading = createSelector(
  selectWebinarsDomain, prop("isCameraLoading"),
);

export const selectIsAudioLoading = createSelector(
  selectWebinarsDomain, prop("isAudioLoading"),
);

export const selectFileErrorStatus = createSelector(
  selectWebinarsDomain, prop("fileErrorStatus"),
);

export const selectFileUploadProgress = createSelector(
  selectWebinarsDomain, prop("fileUploadProgress"),
);

export const selectDevicePermissionError = createSelector(
  selectWebinarsDomain, prop("devicePermissionError"),
);
