import styled from "styled-components";

import { HeaderBadge as HeaderBadgeBase } from "../../../../UIElements";
import { Color, FontWeight, Media } from "../../../../theme";
import WomanImgDesktop from "../../images/womanWithCarDesktop.jpg";
import { ReactComponent as ClockSvg } from "../../images/clock.svg";

export const Container = styled.div``;

export const HeaderBadge = styled(HeaderBadgeBase)`
  display: flex;
  flex-direction: column;

  ${Media.tablet`
    flex-direction: row;
  `}
`;

export const IconWrapper = styled.div`
  display: flex;
  margin-top: 20px;

  ${Media.tablet`
    margin-top: 0;
  `}
`;

export const ListHeader = styled.p`
  margin-bottom: 35px;
  color: ${Color.deepGray};
  font-size: 12px;
  font-weight: ${FontWeight.Bolder};
  line-height: 1.6;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

export const MinutesBox = styled.div`
  margin-bottom: 10px;

  ${Media.additionalMobile`
    height: 218px;
    background-image: url(${WomanImgDesktop});
    background-size: cover;
    background-position: center left 30%;
    display: flex;
    align-items: center;
  `}
`;

export const MinutesImage = styled.img`
  display: block;
  width: 100%;

  ${Media.additionalMobile`
    display: none;
  `}
`;

export const MinutesContent = styled.div`
  background-color: ${Color.deepGray};
  color: ${Color.white};
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${Media.additionalMobile`
    background-color: ${Color.transparent};
    padding: 0 20px;
  `}

  ${Media.tabletWide`
    padding: 0 40px;
    gap: 30px;
  `}
`;

export const MinutesTitle = styled.p`
  font-size: 19px;
  font-weight: ${FontWeight.ExtraBold};
  text-transform: uppercase;
`;

export const MinutesTextBox = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  ${Media.tabletWide`
    gap: 20px;
  `}
`;

export const MinutesIcon = styled(ClockSvg)`
  width: 52px;
  height: 52px;
  padding: 10px;
  border-radius: 2px;
  background-color: ${Color.gray6};

  ${Media.tabletWide`
    width: 68px;
    height: 68px;
    padding: 13px;
  `}
`;

export const MinutesText = styled.div``;

export const MinutesValue = styled.p`
  font-size: 24px;
`;

export const MinutesValueLarge = styled.span`
  ${Media.tabletWide`
    font-size: 36px;
  `}
`;

export const MinutesLabel = styled.p`
  margin-top: 8px;
  font-size: 10px;
  font-weight: ${FontWeight.SemiBold};
  text-transform: uppercase;
  letter-spacing: 0.2px;
  line-height: 2;
`;

