import styled from "styled-components";

import { Modal } from "../../components/modal/modal.component";
import { Button } from "../../components/button/button.component";
import { Color, Media } from "../../theme";

export const Container = styled(Modal)`
  && {
    .MuiDialogContent-root {
      padding-top: 0;
    }
  }
`;

export const WelcomeImg = styled.img`
  width: 100%;
`;

export const Header = styled.p`
  font-weight: 300;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  max-width: 610px;
  margin: 40px auto 0;

  ${Media.tablet`
    font-size: 42px;
    line-height: 57px;
  `}
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: ${Color.gray9};
  text-align: center;
  margin: 20px auto 0;
  max-width: 610px;
`;

export const Buttons = styled.div`
  margin: 48px 20px 60px;
  display: flex;
  flex-direction: column-reverse;

  ${Media.tablet`
    flex-direction: row;
    justify-content: center;
    margin: 48px 0 60px;
  `}
`;

export const SkipButton = styled(Button)`
  min-width: 200px;
  margin-top: 20px;

  ${Media.tablet`
    margin-top: 0;
    margin-right: 10px;
  `}
`;

export const StartButton = styled(Button)`
  min-width: 200px;
`;

export const StepButtons = styled.div`
  padding: 30px 35px;
  display: flex;
  justify-content: space-between;
`;
