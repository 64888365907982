import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

import { secondsToHours } from "../../../../helpers/time";

import { Container } from "./timer.styled";

export const Timer = ({
  className,
  startTimestamp,
  streamTimer,
  onEnd = Function.prototype,
}) => {
  const [counter, setCounter] = useState(Math.floor(startTimestamp - (Date.now() / 1000)));

  const countDown = useCallback(() => {
    const currentDate = Date.now() / 1000;
    const newCounter = Math.floor(startTimestamp - currentDate);

    if (newCounter) {
      setCounter(newCounter);
    } else {
      onEnd();
    }
  }, [startTimestamp, onEnd]);

  useEffect(() => {
    const timer = !!counter && setTimeout(countDown, 1000);
    return () => clearTimeout(timer);
  }, [counter, startTimestamp, countDown]);

  return (
    <Container streamTimer={streamTimer} className={className}>
      {secondsToHours(counter)}
    </Container>
  );
};

Timer.propTypes = {
  className: PropTypes.string,
  startTimestamp: PropTypes.number,
  streamTimer: PropTypes.bool,
  onEnd: PropTypes.func,
};
