import styled, { css } from "styled-components";
import { prop } from "ramda";

import { ReactComponent as BaseMultiFilesIcon } from "../../images/videoList.svg";
import { ReactComponent as BaseClockIcon } from "../../images/clock.svg";
import { ReactComponent as BaseRequiredIcon } from "../../images/warning.svg";
import { ReactComponent as HeartIcon } from "../../images/heart.svg";
import { hexToRgba } from "../../helpers/colors";
import { FontWeight } from "../../theme/fonts";
import { Color } from "../../theme/colors";
import { Media } from "../../theme/media";
import { styleWhenTrue } from "../../helpers/rendering";

export const Image = styled.img`
  width: 100%;
  height: 100%;
  transition: all .8s ease-in-out;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  max-height: 180px;
  overflow: hidden;
  transition: all .8s ease-in-out;
  position: relative;

  ${styleWhenTrue(prop("$listView"), css`
    width: 150px;
    height: 94px;
    margin-right: 10px;
    max-height: unset;
    flex-shrink: 0;

    ${Media.tablet`
      width: 228px;
      height: 144px;
      margin-right: 30px;
    `}
  `)}
`;

export const Description = styled.p`
  font-weight: ${FontWeight.Light};
  font-size: 12px;
  line-height: 20px;
  color: ${Color.deepGray};
  margin-top: 6px;
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${Media.tablet`
    -webkit-line-clamp: 3;
    font-size: 14px;
    line-height: 22px
  `}
`;

export const TitleAndCategory = styled.div`
  display: flex;
  flex-direction: column;

  ${styleWhenTrue(prop("$listView"), css`
    flex-direction: column-reverse;
  `)}
`;

export const Title = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${Color.deepGray};

  ${styleWhenTrue(prop("$listView"), css`
    font-size: 14px;
    line-height: 16px;
    font-weight: ${FontWeight.SemiBold};

    ${Media.tablet`
      font-size: 16px;
      margin-top: 10px;
    `}
  `)}
`;

export const Category = styled.p`
  text-transform: uppercase;
  margin: 16px 0 11px;
  font-size: 10px;
  line-height: 20px;
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.gray9};

  ${styleWhenTrue(prop("$listView"), css`
    margin: 0;
  `)}
`;

export const Container = styled.div`
  width: 75%;
  max-width: 285px;
  margin-right: 20px;
  cursor: pointer;
  flex-shrink: 0;
  position: relative;

  ${Media.mobile`
    width: 40%;
  `};

  ${Media.tablet`
    width: 28%;
  `}

  ${Media.desktop`
    width: calc(25% - 15px);

    &:hover {
      ${ImageWrapper} {
        background-color: ${Color.black};
      }

      ${Image} {
        transform: scale(1.2);
        opacity: 0.6;
      }
    }
  `}

  ${styleWhenTrue(prop("$listView"), css`
    display: flex;
    max-width: unset;
    margin: 0 0 30px;
    width: 100%;

    ${Media.mobile`
      width: 100%;
      max-width: unset;
    `}

    ${Media.tablet`
      display: flex;
      width: 100%;
      max-width: unset;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 40px;
      }
    `}
  `)}
`;

export const RequiredIcons = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
`;

export const WarningIcon = styled(BaseRequiredIcon)`
  width: 28px;
  height: 28px;
  margin-left: 5px;
  background-color: ${Color.gray1};
  border-radius: 50%;
`;

export const ClockIcon = styled(BaseClockIcon)`
  width: 28px;
  height: 28px;
  background-color: ${Color.gray1};
  border-radius: 50%;
`;

export const MarksWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;

  svg {
    height: 32px;
    width: 32px;
  }
`;

export const TextsWrapper = styled.div``;

export const MultiFilesOverlay = styled.div`
  position: absolute;
  width: 100px;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: ${hexToRgba(Color.black, 0.4)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const MultiFilesCounter = styled.span`
  font-size: 20px;
  color: ${Color.white};
`;

export const ProgressBarBackground = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${Color.gray4};
`;

export const ProgressBar = styled.div`
  width: ${prop("value")}%;
  background-color: ${() => Color.brandBasic};
  height: 4px;
`;

export const MultiFilesIcon = styled(BaseMultiFilesIcon)`
  margin-top: 15px;
  width: 20px;
  height: 14px;
  fill: ${Color.white};
`;

const IconWrapperStyles = css`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DownloadIconWrapper = styled.div`
  ${IconWrapperStyles}
  background-color: ${Color.white};
  border: 1px solid ${hexToRgba(Color.brown, 0.15)};

  svg {
    width: 14px;
    height: 14px;
    fill: ${Color.yellow};
  }
`;

export const FavouriteIcon = styled(HeartIcon)``;

export const FavouriteListButton = styled.button`
  ${IconWrapperStyles}
  border: none;
  background-color: ${hexToRgba(Color.black, 0.3)};
  margin-right: 5px;

  ${FavouriteIcon} {
    width: 14px;
    height: 14px;

    ${styleWhenTrue(prop("$isFavourite"), css`
      path {
        fill: ${Color.white};
      }
    `)}
  }
`;
