import styled from "styled-components";

import { Media } from "../../theme";
import { colors } from "../../UIGlobals";

export const Slide = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Download = styled.a`
  font-size: 15px;
  color: ${colors().brandBasic};
  margin: 10px 0px;
  text-decoration: none;
  text-align: center;
  line-height: 20px;
  padding: 0 20px;
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
  }

  ${Media.tablet`
    font-size: 30px;
    line-height: 40px;
  `}
`;
