import React from "react";
import PropTypes from "prop-types";

import { BadgeIcon } from "./badge.constants";
import {
  Container,
  DisabledIcon,
  IconWrapper,
  Title,
  Subtitle,
} from "./badge.styled";

export const Badge = ({ className, title, subtitle, id, done }) => {
  const IconData = BadgeIcon.find((element) => element.enum === id);
  const Icon = IconData?.icon;
  const iconColor = IconData?.color;

  return (
    <Container className={className}>
      {!!Icon &&
        (done ? (
          <IconWrapper color={iconColor}>
            <Icon />
          </IconWrapper>
        ) : (
          <DisabledIcon />
        ))}
      <Title done={done}>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Container>
  );
};

Badge.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  done: PropTypes.bool.isRequired,
};
