import styled, { css } from "styled-components";
import { propEq } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { ReactComponent as CupSvg } from "../../../../images/cup.svg";
import { ReactComponent as ArrowSvg } from "../../../../images/arrow.svg";
import { Color, FontWeight, Media } from "../../../../theme";
import { CertificateStatusId } from "../../certificates.constants";

export const Container = styled.button`
  display: flex;
  padding: 47px 20px 47px 25px;
  border: 1px solid ${Color.dirtyWhite};
  width: calc(100% - 40px);
  margin: 0 auto;
  align-items: center;
  text-align: left;
  background-color: ${Color.white};

  & + & {
    margin-top: 2px;
  }

  ${styleWhenTrue(propEq(CertificateStatusId.Complete, "$status"), css`
    border: 1px solid ${Color.veryLightGreen};
    background-color: ${Color.veryLightGreen};
  `)}

  ${styleWhenTrue(propEq(CertificateStatusId.Failed, "$status"), css`
    border: 1px solid ${Color.red2};
    background-color: ${Color.red2};
  `)}

  ${Media.tablet`
    width: 100%;
    padding: 47px 43px 47px 35px;
  `}
`;

export const CupIcon = styled(CupSvg)`
  fill: ${Color.black};
  width: 30px;
  height: 30px;
  margin-right: 20px;
  flex-shrink: 0;

  ${styleWhenTrue(propEq(CertificateStatusId.Complete, "$status"), css`
    fill: ${Color.green};
  `)}

  ${styleWhenTrue(propEq(CertificateStatusId.Failed, "$status"), css`
    fill: ${Color.red};
  `)}

  ${Media.tablet`
    width: 46px;
    height: 46px;
    margin-right: 33px;
  `}
`;

export const NameWrapper = styled.div``;

export const Name = styled.p`
  color: ${Color.deepGray};
  font-size: 16px;
  line-height: 20px;
`;

export const Subname = styled.p`
  margin-top: 3px;
  font-size: 13px;
  line-height: 17px;
  color: ${Color.gray3};
`;

export const ExpirationDate = styled.p`
  font-weight: ${FontWeight.SemiBold};
  font-size: 13px;
  line-height: 17px;
  margin-top: 3px;

  ${styleWhenTrue(propEq(CertificateStatusId.Complete, "$status"), css`
    color: ${Color.green};
  `)}

  ${styleWhenTrue(propEq(CertificateStatusId.Failed, "$status"), css`
    color: ${Color.red};
  `)}
`;

export const ArrowIcon = styled(ArrowSvg)`
  fill: ${Color.deepGray};
  transform: rotate(180deg);
  margin-left: auto;
  flex-shrink: 0;

  ${styleWhenTrue(propEq(CertificateStatusId.Passed, "$status"), css`
    fill: ${Color.green};
  `)}

  ${styleWhenTrue(propEq(CertificateStatusId.Failed, "$status"), css`
    fill: ${Color.red};
  `)}
`;
