import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { SimpleProgressChart } from "../../../../../components/simpleProgressChart/simpleProgressChart.component";
import { PointsMark, PointsMarkIcon } from "../../../../../components/challengeElement/challengeElement.styled";
import { ChallengeType } from "../../../../../components/challengeElement/challengeElement.constants";

import {
  Container,
  ProgressLabel,
  VerificationLabel,
  HourglassIcon,
  NewLabel,
} from "./challengeItemContent.styled";

export const ChallengeItemContent = ({
  className,
  isNew,
  challengeType,
  percentageScore,
  points,
  earnedPoints,
  duringVerification,
}) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      {isNew && <NewLabel>{t("gamification.challengeElement.new")}</NewLabel>}
      {percentageScore !== null && ![ChallengeType.Task, ChallengeType.Questionnaire].includes(challengeType) && (
        <>
          <SimpleProgressChart value={percentageScore} />
          <ProgressLabel>
            {percentageScore}%
            {" "}
            {t("gamification.challengeElement.correctAnswers")}
          </ProgressLabel>
        </>
      )}
      {duringVerification && challengeType !== ChallengeType.Survey && (
        <>
          <VerificationLabel>
            {t("gamification.challengeElement.duringVerification")}
          </VerificationLabel>
          <HourglassIcon />
        </>
      )}
      <PointsMark bright={!!earnedPoints}>
        <PointsMarkIcon />
        {earnedPoints ? `+${earnedPoints}` : points}
      </PointsMark>
    </Container>
  );
};

ChallengeItemContent.propTypes = {
  className: PropTypes.string,
  isNew: PropTypes.bool,
  challengeType: PropTypes.string,
  percentageScore: PropTypes.number,
  points: PropTypes.number,
  earnedPoints: PropTypes.number,
  duringVerification: PropTypes.bool,
};
