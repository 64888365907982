export const FileMaxSizeInMB = 20;
export const FilesSumMaxSizeInMB = 35;
export const FileMaxSize = FileMaxSizeInMB * 1024 * 1024;
export const FilesSumMaxSize = FilesSumMaxSizeInMB * 1024 * 1024;

export const EntryType = {
  Article: "article",
  Post: "post",
};

export const EntryLength = {
  ArticleShort: 500,
  ArticleFull: 5000,
  Post: 500,
  Title: 100,
};
