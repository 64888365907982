import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { hexToRgba } from "../../../../helpers/colors";
import { ReactComponent as BaseCalendarIcon } from "../../../../images/calendar.svg";
import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.div`
  background-color: ${Color.dirtyWhite};
  padding: 25px;
  height: 230px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  margin-top: 20px;

  ${styleWhenTrue(prop("$topWidgetEnabled"), css`
    margin-bottom: 20px;
  `)}

  ${Media.tablet`
    margin-top: 0;
  `}

  ${Media.desktop`
    margin-bottom: 0;

    ${styleWhenTrue(prop("$topWidgetEnabled"), css`
      margin-top: 20px;
    `)}
  `}
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`;

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${() => Color.brandBasic};
  border-radius: 50%;
  margin-right: 8px;
`;

export const HeaderText = styled.p`
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: ${Color.deepGray};
  font-weight: ${FontWeight.Bold};
  text-transform: uppercase;
`;

export const Title = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${hexToRgba(Color.deepGray, 0.5)};
`;

export const DateWrapper = styled.div`
  position: absolute;
  left: 25px;
  bottom: 25px;
`;

export const StartDate = styled.p`
  font-size: 25px;
  line-height: 34px;
  color: ${Color.black};
  font-weight: ${FontWeight.Light};
  z-index: 1;
`;

export const CalendarIcon = styled(BaseCalendarIcon)`
  position: absolute;
  bottom: -32px;
  right: 27px;
  width: 128px;
  height: 128px;
  opacity: 0.9;
  fill: ${Color.gray7};
`;
