import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import fileDialog from "file-dialog";

import { Loader } from "../../../../components/loader/loader.component";
import { AvatarType } from "../../../../components/avatar/avatar.constants";
import { Avatar } from "../../../../components/avatar/avatar.component";
import { ButtonColor } from "../../../../components/button/button.constants";
import { ConfirmationModal } from "../../../../components/confirmationModal/confirmationModal.component";
import { renderWhenTrue, renderWhenTrueOtherwise } from "../../../../helpers/rendering";
import { selectIsEditTabPending, selectAvatarSrc, selectFullName } from "../../redux/profile.selectors";
import { ProfileActions } from "../../redux/profile.reducer";
import { EditForm } from "../editForm/editForm.component";

import { AvatarMinDimension, AvatarMaxSize, AvatarMaxSizeInMB } from "./profileEdit.constants";
import {
  Container,
  FormWrapper,
  FormBox,
  Form,
  AvatarWrapper,
  AddButton,
  RemoveButton,
  TrashIcon,
  AvatarAlert,
  Link,
} from "./profileEdit.styled";

export const ProfileEdit = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPending = useSelector(selectIsEditTabPending);
  const avatarSrc = useSelector(selectAvatarSrc);
  const fullName = useSelector(selectFullName);
  const [avatarAlertVisible, setAvatarAlertVisible] = useState(false);
  const [permissionsPopupVisible, setPermissionsPopupVisible] = useState(false);

  useEffect(() => {
    dispatch(ProfileActions.fetchEditData());

    return () => {
      dispatch(ProfileActions.clearForm())
    };
  }, [dispatch]);

  const handlePermissionsError = () => {
    setPermissionsPopupVisible(true);
  };

  const handleSendingFile = (file) => {
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      const image = new Image();

      image.onload = () => {
        const isValid = file.size <= AvatarMaxSize
          && image.height >= AvatarMinDimension
          && image.width >= AvatarMinDimension;

        if (isValid) {
          setAvatarAlertVisible(false);
          dispatch(ProfileActions.addAvatar(file, handlePermissionsError));
        } else {
          setAvatarAlertVisible(true);
        }
      };

      image.src = reader.result;
    }, false);

    reader.readAsDataURL(file);
  };

  const addAvatar = () => {
    fileDialog({ accept: "image/png, image/jpg" }).then((files) => {
      handleSendingFile(files[0]);
    });
  };

  const removeAvatar = () => {
    dispatch(ProfileActions.deleteAvatar());
  };

  const renderAvatarAlert = renderWhenTrue(() => {
    const text = t("profile.edit.avatarAlert", { maxSize: AvatarMaxSizeInMB, dimension: AvatarMinDimension });
    return (
      <AvatarAlert>{text}</AvatarAlert>
    );
  });

  const renderForm = renderWhenTrueOtherwise(
    () => <EditForm />,
    () => <Loader />,
  );

  return (
    <Container className={className}>
      <FormWrapper>
        <FormBox>
          <AvatarWrapper>
            <Avatar src={avatarSrc} name={fullName} type={AvatarType.Large} />
            <AddButton color={ButtonColor.Primary} onClick={addAvatar}>
              {t("profile.profile.uploadAvatar")}
            </AddButton>
            <RemoveButton onClick={removeAvatar}>
              <TrashIcon />
            </RemoveButton>
          </AvatarWrapper>
          {renderAvatarAlert(avatarAlertVisible)}
          <Form>
            {renderForm(!isPending)}
          </Form>
        </FormBox>
      </FormWrapper>
      <ConfirmationModal
        open={permissionsPopupVisible}
        confirmText={t("global.ok")}
        withoutDecline
        onClose={() => setPermissionsPopupVisible(false)}
      >
        {t("profile.edit.addPhoto")}
        <Link to="/profil/agreements">{t("profile.edit.agreementTab")}</Link>
        {t("profile.edit.changePermissions")}
      </ConfirmationModal>
    </Container>
  );
};

ProfileEdit.propTypes = {
  className: PropTypes.string,
};
