import { createTheme } from "@mui/material";

import { Color } from "./colors";

export const getTheme = (config = {}) => {
  const brandColor = config.color ? `#${config.color}` : Color.brandBasic;

  return createTheme({
    typography: {
      fontFamily: "Open Sans",
      fontSize: -1, // disable global fontSize
    },
    palette: {
      primary: {
        light: brandColor,
        main: brandColor,
        dark: brandColor,
        contrastText: brandColor,
      },
      secondary: {
        light: brandColor,
        main: brandColor,
        contrastText: brandColor,
      },
      action: {
        active: Color.black,
      }
    },
  });
};
