export const DutiesEnum = {
  FinancingNew: "seller_financing_new",
  FinancingUsed: "seller_financing_used",
  Insurance: "seller_insurance",
  HR: "human_resources", // Do not remove, needed for Competence platform
};

export const AllDuties = [{
  name: DutiesEnum.FinancingNew,
}, {
  name: DutiesEnum.FinancingUsed,
}, {
  name: DutiesEnum.Insurance,
}];

export const VWHRDuties = [{
  name: "vwhr",
}];
