import React, { Component } from "react";
import styled from "styled-components";

import { colors, breakpoints } from "../UIGlobals";
import { Icon } from "../UIElements";
import { Transitions } from "../theme";

const ThumbnailListWrapper = styled.div`
  position: relative;
  ${props => props.width && "width: " + props.width};
`;

const ThumbnailRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Arrow = styled.button`
  width: 50px;
  height: 50px;
  background: ${colors().white};
  border: none;
  border-radius: 50px;
  outline: none;
  box-shadow: 0 2px 10px 0 rgba(31, 27, 22, 0.02), 0 0 20px 0 rgba(31, 27, 22, 0.03);
  bottom: calc(50% - 25px);
  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  z-index: 10;
  transition: ${Transitions.custom};
  &:hover {
    background: ${colors().almostWhite};
    cursor: pointer;
  }
  box-shadow: 1px 1.732px 0px 0px rgba(31, 27, 22, 0.08),0px 2px 10px 0px rgba(31, 27, 22, 0.02),0px 0px 20px 0px rgba(31, 27, 22, 0.03);
`;

const ArrowLeft = styled(Arrow)`
  left: 0%;
  transform: ${props => (props.transform ? props.transform : "translate3d(-50%, -50%, 0)")};
  @media (${breakpoints().maxL}) {
    left: 25px;
    top: 48%;
    box-shadow: none;
  }
`;

const ArrowRight = styled(Arrow)`
  right: 0%;
  transform: ${props => (props.transform ? props.transform : "translate3d(-50%, -50%, 0)")};
  @media (${breakpoints().maxL}) {
    right: 25px;
    top: 48%;
    box-shadow: none;
  }
`;

const ThumbnailRow = ({ children }) => <ThumbnailRowWrapper>{children}</ThumbnailRowWrapper>;

class ThumbnailList extends Component {
  constructor() {
    super();
    this.state = { start: 0 };
  }

  next() {
    this.setState({ start: this.state.start + 1 });
  }

  prev() {
    this.setState({ start: this.state.start - 1 });
  }

  render() {
    const children = React.Children.toArray(this.props.children);

    const perPage = this.props.perPage || 4;

    const { start } = this.state;
    const { width, coloredArrow } = this.props;
    const currentItems = children.slice(start, start + perPage);
    const nextArrow = children.length > start + perPage;
    const prevArrow = start > 0;

    const arrowColor = coloredArrow ? colors().brandBasic : colors().gray1;

    return (
      <ThumbnailListWrapper width={width}>
        {prevArrow && (
          <ArrowLeft transform="translate3d(-50%, -50%, 0)" onClick={() => this.prev()}>
            <Icon icon="arrow_left" size="14" color={arrowColor} />
          </ArrowLeft>
        )}
        <ThumbnailRow>
          {currentItems.map(child => child)}
        </ThumbnailRow>
        {nextArrow && (
          <ArrowRight transform="translate3d(50%, -50%, 0)" onClick={() => this.next()}>
            <Icon icon="arrow_left" size="14" color={arrowColor} rotation="-180deg" />
          </ArrowRight>
        )}
      </ThumbnailListWrapper>
    );
  }
}

export { ThumbnailList };
