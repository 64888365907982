import { EntryType } from "../../../../components/articleForm/articleForm.constants";

import {
  StrongText,
  ItalicText,
  UnderlinedText,
  UnorderedList,
} from "../../../../theme/typography";

export const ModalType = {
  Edit: "edit",
  Delete: "delete",
};

export const MarkdownRenderers = {
  strong: StrongText,
  em: ItalicText,
  del: UnderlinedText,
  ul: UnorderedList,
};

export const EntryEditTitle = {
  [EntryType.Post]: "news.entryEdit.editPost",
  [EntryType.Article]: "news.entryEdit.editArticle",
};
