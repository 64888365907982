import { prop } from "ramda";
import styled, { css } from "styled-components";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color, FontWeight } from "../../../../theme";
import { ReactComponent as HourglassSvg } from "../../images/hourglass.svg";

export const HourglassIcon = styled(HourglassSvg)`
  margin-right: 10px;
  stroke: ${() => Color.brandBasic};
`;

export const LightText = styled.p`
  font-weight: ${FontWeight.Light};
  font-size: 12px;
  margin-right: 5px;
`;

export const BoldText = styled(LightText)`
  font-weight: ${FontWeight.SemiBold};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  color: ${Color.deepGray};

  ${styleWhenTrue(prop("redTheme"), css`
    color: ${Color.red};

    ${HourglassIcon} {
      stroke: ${Color.red};
    }
  `)}
`;
