import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { useModuleConfig, ModuleType } from "../";

import { CompetenceList } from "./components/competenceList/competenceList.component";
import { CompetenceDetails } from "./components/competenceDetails/competenceDetails.component";
import { SelfMarkSurvey } from "./components/selfMarkSurvey/selfMarkSurvey.component";
import { Container } from "./competence.styled";

const Competence = () => {
  const config = useModuleConfig(ModuleType.Competence);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (config.urls.includes(location.pathname)) {
      navigate(`${config.urls[1]}${config.routes.list}`, { replace: true });
    }
  }, [location.pathname, navigate]);

  return (
    <ThemeProvider theme={config.theme}>
      <Container>
        <Routes>
          <Route path={config.routes.list} exact element={<CompetenceList />} />
          <Route path={config.routes.selfMarkSurvey} exact element={<SelfMarkSurvey />} />
          <Route path={config.routes.entry} exact element={<CompetenceDetails />} />
        </Routes>
      </Container>
    </ThemeProvider>
  );
};

export default Competence;
