import React from "react";
import PropTypes from "prop-types";

import { ConfirmationModal } from "../../../../components/confirmationModal/confirmationModal.component";
import { StreamType } from "../../services/streams.constants";

import { ShareCameraContent, ShareScreenContent } from "./shareModal.constants";

export const ShareModal = ({
  className,
  onConfirm,
  onClose,
  onDecline,
  open,
  type,
}) => {
  const content = type === StreamType.Camera ? ShareCameraContent : ShareScreenContent;

  return (
    <ConfirmationModal
      open={open}
      title={content.title}
      HeaderIcon={content.icon}
      onClose={onClose}
      onConfirm={onConfirm}
      onDecline={onDecline}
      className={className}
    >
      {content.text}
    </ConfirmationModal>
  );
};

ShareModal.propTypes = {
  className: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  type: PropTypes.string,
};
