import { createActions, createReducer } from "reduxsauce";

export const { Types: TrainingsTypes, Creators: TrainingsActions } = createActions(
  {
    setIsPending: ["isPending"],
    fetchEvents: null,
    fetchEventsSuccess: ["events", "filters"],
    enrollEvent: ["eventId", "onFinish"],
    enrollEventWithForm: ["eventId", "userData", "onFinish"],
    disenrollEvent: ["eventId", "onFinish"],
    checkEventAccess: ["eventId", "onFinish"],
    checkEventDisenroll: ["eventId", "onFinish"],
    setEventCheck: ["data"],
    fetchTrainerMetric: ["trainerId"],
    fetchTrainerMetricSuccess: ["trainerMetric"],
    clearTrainerMetric: null,
    clearCourses: null,
    closeCheckPopup: null,
    getEventDetails: ["id"],
    getEventDetailsSuccess: ["eventDetails"],
    clearEvents: null,
  },
  { prefix: "TRAININGS/" }
);

const INITIAL_STATE = {
  isPending: true,
  events: null,
  categories: null,
  tags: null,
  dateRange: null,
  trainerMetric: null,
  blockingEvent: null,
  missingCourses: null,
  checkPopupOpenedId: null,
  nextCourses: null,
  toRemoveCourses: null,
  showTrainingRegulation: false,
  eventDetails: null,
};

const setIsPending = (state, { isPending }) => ({ ...state, isPending });

const fetchEventsSuccess = (state, { events, filters }) => ({
  ...state,
  events,
  categories: filters.categories,
  tags: filters.tags,
  dateRange: {
    from: filters.dateRange.from * 1000,
    to: filters.dateRange.to * 1000,
  },
});

const setEventCheck = (state, { data }) => ({
  ...state,
  checkPopupOpenedId: data.eventId,
  blockingEvent: data.blockingEvent || null,
  missingCourses: data.missingCourses || null,
  toRemoveCourses: data.toRemoveCourses || null,
  showTrainingRegulation: data.showTrainingRegulation || false,
  nextCourses: data.nextCourses || null,
});

const getEventDetailsSuccess = (state, { eventDetails }) => ({ ...state, eventDetails });

const fetchTrainerMetricSuccess = (state, { trainerMetric }) => ({ ...state, trainerMetric });

const clearTrainerMetric = (state) => ({
  ...state,
  trainerMetric: null,
});

const closeCheckPopup = (state) => ({
  ...state,
  checkPopupOpenedId: null,
});

const clearCourses = (state) => ({
  ...state,
  blockingEvent: null,
  missingCourses: null,
  nextCourses: null,
  toRemoveCourses: null,
  showTrainingRegulation: false,
});

const clearEvents = () => ({ ...INITIAL_STATE });

export const reducer = createReducer(INITIAL_STATE, {
  [TrainingsTypes.SET_IS_PENDING]: setIsPending,
  [TrainingsTypes.FETCH_EVENTS_SUCCESS]: fetchEventsSuccess,
  [TrainingsTypes.SET_EVENT_CHECK]: setEventCheck,
  [TrainingsTypes.GET_EVENT_DETAILS_SUCCESS]: getEventDetailsSuccess,
  [TrainingsTypes.FETCH_TRAINER_METRIC_SUCCESS]: fetchTrainerMetricSuccess,
  [TrainingsTypes.CLEAR_TRAINER_METRIC]: clearTrainerMetric,
  [TrainingsTypes.CLOSE_CHECK_POPUP]: closeCheckPopup,
  [TrainingsTypes.CLEAR_COURSES]: clearCourses,
  [TrainingsTypes.CLEAR_EVENTS]: clearEvents,
});
