import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/button/button.component";
import { ButtonColor } from "../../components/button/button.constants";

import {
  Header,
  AcceptIcon,
  HeaderText,
  Content,
  Title,
  Text,
  Buttons,
} from "./changePasswordModal.styled";

export const ConfirmationContent = ({ handleFinish }) => {
  const { t } = useTranslation();

  return (
    <>
      <Header>
        <AcceptIcon />
        <HeaderText>
          {t("app.changePassword.passwordChanged")}
        </HeaderText>
      </Header>
      <Content>
        <Title>{t("app.changePassword.passwordUpdatedTitle")}</Title>
        <Text>{t("app.changePassword.passwordUpdatedText")}</Text>
      </Content>
      <Buttons>
        <Button
          color={ButtonColor.Primary}
          onClick={handleFinish}
        >
          {t("app.changePassword.goToPlatform")}
        </Button>
      </Buttons>
    </>
  );
};
