import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { GamificationActions } from "../../redux/gamification.reducer";
import { selectIsStarted } from "../../redux/gamification.selectors";

import { StartHeader } from "./startHeader/startHeader.component";
import { Rules } from "./rules/rules.component";
import { AcceptBox } from "./acceptBox/acceptBox.component";
import { scrollOptions } from "./startScreen.constants";
import { Container } from "./startScreen.styled";

export const StartScreen = ({ className }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const started = useSelector(selectIsStarted);
  const [scrollActive, setScrollActive] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleScroll = () => {
    setScrollActive(true);
    setTimeout(() => setScrollActive(false), 1);
  };

  const handleParticipate = () => {
    if (started) {
      navigate("/gamification");
    } else {
      dispatch(GamificationActions.startGamification(() => {
        navigate("/gamification");
      }));
    }
  };

  return (
    <Container className={className}>
      <StartHeader handleScroll={handleScroll} />
      <ScrollIntoViewIfNeeded
        active={scrollActive}
        options={scrollOptions}
      >
        <Rules />
      </ScrollIntoViewIfNeeded>
      <AcceptBox onParticipate={handleParticipate} />
    </Container>
  );
};

StartScreen.propTypes = {
  className: PropTypes.string,
};
