import { nodeApi, handleApiError, phpApi } from "../../../api";

export const fetchChartsData = (chartIDs) => {
  return nodeApi.get(`/competences/population/${chartIDs}`).catch(handleApiError);
};

export const getStats = () => {
  return phpApi.get("/api/user/statistic").catch(handleApiError);
};

export const setStatsTeam = (offset, limit) => {
  return phpApi.get("/api/user/team/activity", { data: { offset, limit } }).catch(handleApiError);
};
