import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color, Separator as SeparatorBase } from "../../../../theme";
import { hexToRgba } from "../../../../helpers/colors";
import { ReactComponent as CursorSvg } from "../../images/cursor.svg";
import { ReactComponent as PencilSvg } from "../../images/pencil.svg";
import { ReactComponent as LineSvg } from "../../images/line.svg";
import { ReactComponent as TextSvg } from "../../images/text.svg";
import { ReactComponent as DeleteSvg } from "../../images/delete.svg";
import { ReactComponent as RemoveSvg } from "../../images/remove.svg";
import { ReactComponent as ColorsSvg } from "../../images/colors.svg";
import { ReactComponent as ShapesSvg } from "../../images/shapes.svg";
import { ReactComponent as EmptyElipseSvg } from "../../images/emptyElipse.svg";
import { ReactComponent as EmptyTriangleSvg } from "../../images/emptyTriangle.svg";
import { ReactComponent as EmptyRectangleSvg } from "../../images/emptyRectangle.svg";
import { ReactComponent as ElipseSvg } from "../../images/elipse.svg";
import { ReactComponent as TriangleSvg } from "../../images/triangle.svg";
import { ReactComponent as RectangleSvg } from "../../images/rectangle.svg";
import { ReactComponent as PdfSvg } from "../../images/pdf.svg";

export const Container = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  right: 267px;
  bottom: 60px;
  z-index: 1;
  overflow: scroll;
  background-color: ${Color.darkGray};

  ${styleWhenTrue(prop("wideRightPanel"), css`
    right: 320px;
  `)}
`;

export const Canvas = styled.canvas``;

export const Button = styled.button`
  padding: 10px 0;
  width: 100%;
  background-color: ${Color.white};
  border: none;

  &:hover {
    svg {
      fill: ${() => Color.brandBasic};
    }
  }
`;

export const Separator = styled(SeparatorBase)`
  && {
    width: calc(100% - 30px);
    margin-left: 15px;
  }
`;

export const Buttons = styled.div`
  position: fixed;
  top: 200px;
  left: 20px;
  width: 60px;
  background-color: ${Color.white};
  border: 1px solid ${Color.almostWhite};
  border-radius: 2px;
  box-shadow: 0 2px 4px ${hexToRgba(Color.gray7, 0.15)}, 0 5px 10px ${hexToRgba(Color.gray7, 0.1)};
  z-index: 1;
  padding: 10px 0;
`;

const IconStyle = css`
  fill: ${Color.gray1};

  ${styleWhenTrue(propEq("true", "isactive"), css`
    fill: ${() => Color.brandBasic};
  `)}
`;

export const CursorIcon = styled(CursorSvg)`
  ${IconStyle}
`;

export const PencilIcon = styled(PencilSvg)`
  ${IconStyle}
`;

export const LineIcon = styled(LineSvg)`
  ${IconStyle}
`;

export const ShapesIcon = styled(ShapesSvg)`
  ${IconStyle}
`;

export const TextIcon = styled(TextSvg)`
  ${IconStyle}
`;

export const ColorsIcon = styled(ColorsSvg)`
  ${IconStyle}
`;

export const DeleteIcon = styled(DeleteSvg)`
  ${IconStyle}
`;

export const RemoveIcon = styled(RemoveSvg)`
  ${IconStyle}
`;

export const PdfIcon = styled(PdfSvg)`
  ${IconStyle}
`;

export const ShapesButton = styled.button`
  padding: 12px;
  width: 50%;
  background-color: ${Color.white};
  border: none;

  &:hover {
    svg {
      fill: ${() => Color.brandBasic};
    }
  }
`;

export const EmptyElipse = styled(EmptyElipseSvg)`
  ${IconStyle}
`;

export const EmptyTriangle = styled(EmptyTriangleSvg)`
  ${IconStyle}
`;

export const EmptyRectangle = styled(EmptyRectangleSvg)`
  ${IconStyle}
`;

export const Rectangle = styled(RectangleSvg)`
  ${IconStyle}
`;

export const Triangle = styled(TriangleSvg)`
  ${IconStyle}
`;

export const Elipse = styled(ElipseSvg)`
  ${IconStyle}
`;

export const ShapesBox = styled.div`
  position: absolute;
  left: calc(100% + 2px);
  top: 125px;
  width: 106px;
  display: flex;
  flex-wrap: wrap;
  background-color: ${Color.white};
  border: 1px solid ${Color.almostWhite};
  border-radius: 2px;
  box-shadow: 0 2px 4px ${hexToRgba(Color.gray7, 0.15)}, 0 5px 10px ${hexToRgba(Color.gray7, 0.1)};
  z-index: 1;
  padding: 13px 1px;
`;

export const ColorInput = styled.input`
  margin: 10px;
  width: calc(100% - 20px);
  cursor: pointer;
  border: none;
  background-color: ${Color.transparent};
  height: 30px;
`;
