import styled from "styled-components";

import { Media } from "../../../../../theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 650px;
  height: 100%;
  padding: 50px 25px;

  ${Media.tablet`
    padding: 80px 125px 60px;
  `}
`;

export const Content = styled.div`
  width: 100%;
  padding-bottom: 30px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  margin-bottom: 20px;

  ${Media.tablet`
    flex-direction: row;
    margin-bottom: 2px;
  `}
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${Media.tablet`
    flex-direction: row;
    justify-content: flex-end;
  `}
`;
