import styled from "styled-components";

import { SimpleProgressChart } from "../../../../components/simpleProgressChart/simpleProgressChart.component";
import { Color, FontWeight } from "../../../../theme";

export const Container = styled.div`
  background-color: ${Color.white};
  padding: 22px 20px;
  display: flex;
`;

export const TextWrapper = styled.p`
  color: ${Color.deepGray};
  font-size: 13px;
  line-height: 22px;
  margin-left: 15px;
`;

export const BoldText = styled.span`
  font-weight: ${FontWeight.Bold};
`;

export const Chart = styled(SimpleProgressChart)``;
