import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { EyeButton } from "./eyeButton/eyeButton.component";
import { InputStyle } from "./input.constants";
import { Container, InputComponent, ErrorMessage } from "./input.styled";

export const Input = ({
  className,
  error,
  autofocus,
  styleType = InputStyle.Default,
  disabled,
  eyeButtonVisible,
  ...inputProps
}) => {
  const inputRef = useRef();
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    if (autofocus) {
      inputRef.current.focus();
    }
  }, [autofocus]);

  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);

  return (
    <Container
      className={className}
      $invalid={!!error}
      $styleType={styleType}
      disabled={disabled}
    >
      <InputComponent
        ref={inputRef}
        $styleType={styleType}
        disabled={disabled}
        {...inputProps}
        type={passwordVisible ? "text" : inputProps.type}
      />
      {!!error && (
        <ErrorMessage $styleType={styleType}>{error}</ErrorMessage>
      )}
      {eyeButtonVisible && (
        <EyeButton
          passwordVisible={passwordVisible}
          togglePasswordVisibility={togglePasswordVisibility}
        />
      )}
    </Container>
  );
};


Input.propTypes = {
  className: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  styleType: PropTypes.string,
  disabled: PropTypes.bool,
  eyeButtonVisible: PropTypes.bool,
};
