import React, { Component } from "react";
import i18n from "i18next";

import { ElearningButton } from "../common.styled";

import { Container } from "./interactionError.styled";

class InteractionError extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <Container>
        { i18n.t("interactions.external.external.lessonDownloadError") }
        <ElearningButton className="btn-pos-abs" onClick={this.props.callComplete}>
          { i18n.t("interactions.watched") }
        </ElearningButton>
      </Container>
    );
  }
}

export default InteractionError;
