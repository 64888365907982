import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { CookieName } from "../../../../app/cookiePopup/cookiePopup.constants";
import { OnboardingMode, StorageName } from "../../../../app/app.constants";
import { useMediaListener } from "../../../../hooks/useMediaListener";
import { Separator } from "../../../../theme/typography";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { isCookieEnabled } from "../../../../helpers/isCookieEnabled";
import {
  selectOnboardingHero,
  selectCertificatePathStages,
  selectOnboardingPathPassed,
  selectOnboardingMode,
} from "../../redux/onboarding.selectors";
import { OnboardingActions } from "../../redux/onboarding.reducer";
import { ReactComponent as MapSvg } from "../../images/map.svg";
import { ReactComponent as ListSvg } from "../../images/list.svg";
import { Heroes } from "../chooseHero/chooseHero.constants";
import { ChooseHero } from "../chooseHero/chooseHero.component";
import { Header } from "../header/header.component";
import { ActionSection } from "../actionSection/actionSection.component";
import { MonsterStages } from "../monsterStages/monsterStages.component";
import { MonstersVideo } from "../monstersVideo/monstersVideo.component";
import { VideoModal } from "../videoModal/videoModal.component";
import { OnboardingVideoUrl } from "../videoModal/videoModal.constants";
import { Board } from "../board/board.component";
import { DefaultStages } from "../defaultStages/defaultStages.component";
import { Game } from "../game/game.component";
import { LeasinGoMaxStageView, OrdinaryMaxStageView, TaskPointers } from "../game/game.constants";

import {
  StagesWrapper,
  SeparatorWrapper,
  StageTitle,
  StagesHeader,
  StagesView,
  StagesViewLabel,
  StagesBox,
  StagesHeaderSeparator,
  ViewButton,
  MobileViewButton,
  MobileViewToggler,
} from "./onboardingDefault.styled";

export const OnboardingDefault = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { isSmallMobile, isTabletWide } = useMediaListener();
  const dispatch = useDispatch();
  const stages = useSelector(selectCertificatePathStages);
  const selectedHero = useSelector(selectOnboardingHero);
  const allStagesFinished = useSelector(selectOnboardingPathPassed);
  const onboardingMode = useSelector(selectOnboardingMode);
  const animationId = Heroes.findIndex((hero) => hero.name === selectedHero?.name);
  const [animationIndex, setAnimationIndex] = useState(animationId !== -1 ? animationId : null);
  const [expandedStageId, setExpandedStageId] = useState(null);
  const [modalVisible, setModalVisible] = useState(animationIndex === null);
  const [tutorialVisible, setTutorialVisible] = useState(false);
  const [gameVisible, setGameVisible] = useState(!!location.state?.currentStage);
  const [initialGameStage, setInitialGameStage] = useState(location.state?.currentStage || 1);
  const [boardViewStorageState, setBoardViewStorageState] = useLocalStorage(
    StorageName.OnboardingBoardView
  );
  const getInitialBoardViewState = () => boardViewStorageState !== false;
  const [boardStageMode, setBoardStageMode] = useState(getInitialBoardViewState);
  const welcomeMessage = allStagesFinished
    ? t("onboarding.header.welcomeMessageWinner")
    : t("onboarding.header.welcomeMessageDefault");
  const isLeasinGoMode = onboardingMode === OnboardingMode.LeasinGo;
  const maxStageView = isLeasinGoMode ? LeasinGoMaxStageView : OrdinaryMaxStageView;

  useEffect(() => {
    if (isSmallMobile) {
      setBoardStageMode(false);
    } else if (boardViewStorageState !== false) {
      setBoardStageMode(true);
    }
  }, [isSmallMobile, boardViewStorageState]);

  useEffect(() => {
    if (gameVisible && isTabletWide) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [gameVisible, isTabletWide]);

  const chooseAnimation = useCallback((animationIndex, heroId) => {
    setAnimationIndex(animationIndex);
    dispatch(OnboardingActions.setHero(heroId));
  }, [dispatch]);

  const handleScrollToStage = (index) => {
    setExpandedStageId(index);
  };

  const openVideoModal = () => setModalVisible(true);
  const closeVideoModal = () => setModalVisible(false);
  const openTutorialModal = () => setTutorialVisible(true);
  const closeTutorialModal = () => setTutorialVisible(false);

  const handleSetBoardViewStorageState = (state) => {
    if (isCookieEnabled(CookieName.Functional)) setBoardViewStorageState(state);
  };

  const handleBoardStageModeON = () => {
    setBoardStageMode(true);
    handleSetBoardViewStorageState(true);
  };

  const handleBoardStageModeOFF = () => {
    setBoardStageMode(false);
    handleSetBoardViewStorageState(false);
  };

  const handleGameModeON = (stageIndex) => {
    setInitialGameStage(stageIndex + 1);
    setGameVisible(true);
  };

  const handleGameModeOFF = () => {
    setGameVisible(false);
  };

  const setInitialView = () => {
    if (stages[initialGameStage - 1].passed) return 1;

    for (let currentView = 1; currentView <= 5; currentView++) {
      const tasksDoneInThisView = stages[initialGameStage - 1].itemGroup["1"].items.filter((item, index) => {
        const shouldTaskBeVisibleOnCurrentView = (TaskPointers[initialGameStage - 1][index]?.view === currentView && item.name !== "TEST")
        || (item.name === "TEST" && currentView === maxStageView[`stage${initialGameStage}`]);
        return shouldTaskBeVisibleOnCurrentView && item.isEnabled && item.passed;
      }).length;
      const allTasksInThisView = stages[initialGameStage - 1].itemGroup["1"].items.filter((item, index) => {
        const shouldTaskBeVisibleOnCurrentView = (TaskPointers[initialGameStage - 1][index]?.view === currentView && item.name !== "TEST")
        || (item.name === "TEST" && currentView === maxStageView[`stage${initialGameStage}`]);
        return shouldTaskBeVisibleOnCurrentView;
      }).length;

      if (tasksDoneInThisView !== allTasksInThisView) return currentView;
    }

    return 1;
  };

  if (animationIndex === null || !selectedHero) {
    return (
      <>
        {modalVisible && <VideoModal onClose={closeVideoModal} videoUrl={OnboardingVideoUrl.Intro} />}
        <ChooseHero onChoose={chooseAnimation} />
      </>
    );
  }

  return (
    <>
      <Header
        welcomeMessage={welcomeMessage}
        animationPromise={Heroes[animationIndex].animation}
      />
      <ActionSection />
      <MonsterStages onClick={handleScrollToStage} disabled={!!boardStageMode} />
      {!!stages && (
        <StagesWrapper>
          <StagesBox>
            <StagesHeader>
              <StageTitle>{t("onboarding.stages.title")}</StageTitle>
              <MobileViewToggler>
                <MobileViewButton
                  selected={boardStageMode}
                  onClick={handleBoardStageModeON}
                >
                  {t("onboarding.stages.map")}
                </MobileViewButton>
                <MobileViewButton
                  selected={!boardStageMode}
                  onClick={handleBoardStageModeOFF}
                >
                  {t("onboarding.stages.list")}
                </MobileViewButton>
              </MobileViewToggler>
              <StagesView>
                <StagesViewLabel>{t("onboarding.stages.view")}</StagesViewLabel>
                <ViewButton onClick={handleBoardStageModeON} active={boardStageMode}>
                  <MapSvg />
                </ViewButton>
                <ViewButton onClick={handleBoardStageModeOFF} active={!boardStageMode}>
                  <ListSvg />
                </ViewButton>
              </StagesView>
            </StagesHeader>
            <StagesHeaderSeparator />
            {boardStageMode && !modalVisible && !tutorialVisible && !(gameVisible && isTabletWide) ? (
              <Board
                stages={stages}
                expandedStageId={expandedStageId}
                onTutorialClick={openTutorialModal}
                onGameModeClick={(stageIndex) => handleGameModeON(stageIndex)}
              />
            ) : (
              <DefaultStages
                stages={stages}
                expandedStageId={expandedStageId}
              />
            )}
          </StagesBox>
        </StagesWrapper>
      )}
      <MonstersVideo onButtonClick={openVideoModal} />
      <SeparatorWrapper>
        <Separator />
      </SeparatorWrapper>
      {modalVisible && (
        <VideoModal onClose={closeVideoModal} videoUrl={OnboardingVideoUrl.Intro} />
      )}
      {tutorialVisible && (
        <VideoModal
          onClose={closeTutorialModal}
          videoUrl={isTabletWide ? OnboardingVideoUrl.TutorialGame : OnboardingVideoUrl.Tutorial}
        />
      )}
      {gameVisible && isTabletWide && (
        <Game
          hero={selectedHero}
          goBack={handleGameModeOFF}
          stages={stages}
          initialStage={initialGameStage}
          initialView={location.state?.currentView || setInitialView()}
          onTutorialClick={openTutorialModal}
        />
      )}
    </>
  );
};
