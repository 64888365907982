import styled from "styled-components";

import { Button as ButtonBase } from "../../../../components/button/button.component";
import { hexToRgba } from "../../../../helpers/colors";
import {
  Color,
  Media,
  SectionHeader as HeaderBase,
} from "../../../../theme";
import { ReactComponent as PlusSvg } from "../../images/documentPlus.svg";

export const Container = styled.div`
  width: 100%;
  margin-top: 60px;
`;

export const SectionHeader = styled(HeaderBase)``;

export const Info = styled.div`
  background-color: ${Color.white};
  padding: 35px 20px 35px;

  ${Media.tablet`
    padding: 35px 45px 35px 35px; 
  `}
`;

export const InfoText = styled.p`
  font-size: 16px;
  line-height: 26px;
  color: ${Color.deepGray};
`;

export const AddButtonWrapper = styled.div`
  margin-top: 2px;
  background-color: ${Color.white};
  padding: 20px;
  display: flex;
  align-items: center;
  box-shadow: 0px 5px 10px ${hexToRgba(Color.deepGray, 0.01)};

  ${Media.tablet`
    padding: 20px 35px;
  `}
`;

export const PlusIcon = styled(PlusSvg)`
  stroke: ${() => Color.brandBasic};
  flex-shrink: 0;
`;

export const AddButtonText = styled.p`
  font-size: 13px;
  line-height: 18px;
  color: ${Color.deepGray};
  margin: 0 17px;

  ${Media.tablet`
    line-height: 24px;
  `}
`;

export const AddButton = styled(ButtonBase)`
  margin-left: auto;
`;
