import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Button } from "../../../../components/button/button.component";
import { styleWhenTrue } from "../../../../helpers/rendering";
import {
  FontWeight,
  Color,
  Media,
  Separator as SeparatorBase,
} from "../../../../theme";

export const Container = styled.div``;

export const Section = styled.div`
  margin-bottom: 60px;

  &:last-of-type {
    margin-bottom: 40px;
  }
`;

export const SectionHeader = styled.p`
  font-size: 12px;
  line-height: 20px;
  font-weight: ${FontWeight.ExtraBold};
  text-transform: uppercase;
  letter-spacing: 0.6px;
  margin-bottom: 15px;
`;

export const UpperInfo = styled.p`
  margin-bottom: 15px;
  font-size: 11px;
`;

export const Separator = styled(SeparatorBase)``;

export const InputWrapper = styled.div`
  vertical-align: top;
  
  ${styleWhenTrue(prop("hidden"), css`
    display: none;
  `)}

  ${Media.tablet`
    width: calc(50% - 10px);
    display: inline-block;

    &:nth-of-type(odd) {
      margin-right: 20px;
    }

    ${styleWhenTrue(prop("hidden"), css`
      display: none;
    `)}
  `}
`;

export const LongInputWrapper = styled.div``;

export const Label = styled.p`
  margin: 30px 0 10px;
  font-weight: ${FontWeight.SemiBold};
  font-size: 15px;
  line-height: 22px;
  color: ${Color.gray9};
`;

export const StaticFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  & + & {
    margin-top: 21px;
  }

  ${Media.mobile`
    flex-direction: row;
  `}
`;

export const StaticLabel = styled.p`
  flex: 1;
  font-size: 14px;
  line-height: 20px;
`;

export const StaticValue = styled.p`
  flex: 1;
  font-size: 14px;
  line-height: 20px;
  color: ${Color.gray9};
  margin-top: 5px;

  ${Media.mobile`
    margin-top: 0;
  `}
`;

export const SaveButton = styled(Button)`
  display: block;
`;

export const Message = styled.p`
  margin-top: 5px;
  display: flex;
  width: fit-content;
  background-color: ${Color.lightYellow};
  font-size: 12px;
  line-height: 16px;
  padding: 12px 15px;
  border-radius: 2px;
`;

export const CheckboxWrapper = styled.div`
  margin: 22px 0 30px;

  label {
    font-size: 14px;
    line-height: 16px;
    min-height: auto;
  }
`;

export const AddButton = styled(Button)``;
