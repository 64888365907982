import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";

import { InputStyle } from "../../../../components/input/input.constants";
import { ButtonColor } from "../../../../components/button/button.constants";
import { CommonActions } from "../../../../redux/common/common.reducer";

import {
  Container,
  FieldsContainer,
  FieldsWrapper,
  SectionTitle,
  Separator,
  Label,
  Input,
  Button,
  Message,
  InfoWrapper,
  CheckIcon,
  ErrorIcon,
  InfoText,
  InfoTextBold,
  Error,
} from "./passwordsTab.styled";
import {
  FormSchema,
  disallowedCharsRegex,
  capitalLettersRegex,
  decimalRegex,
  min12charsRegex,
  min4RepeatedCharsRegex,
  specialCharsRegex,
} from "./passwordsTab.schema";

export const PasswordsTab = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const initialValues = useMemo(() => ({
    oldPassword: "",
    newPassword: "",
    repeatPassword: "",
  }), []);

  const handleSuccessCallback = (actions) => {
    setShowConfirmationMessage(true);
    actions.setSubmitting(false);
  };

  const handleFailureCallback = (error, actions) => {
    setErrorMessage(error);
    actions.setSubmitting(false);
  };

  const handleSubmit = (values, actions) => {
    setErrorMessage("");
    setShowConfirmationMessage(false);
    actions.setSubmitting(true);
    dispatch(CommonActions.setNewPassword(
      values.oldPassword,
      values.newPassword,
      () => handleSuccessCallback(actions),
      (error) => handleFailureCallback(error, actions),
    ));
  };

  const formik = useFormik({
    validationSchema: FormSchema,
    initialValues,
    onSubmit: handleSubmit,
  });

  return (
    <Container className={className}>
      <FieldsContainer>
        <FieldsWrapper>
          <SectionTitle>
            {t("profile.passwordsTab.yourPassword")}
          </SectionTitle>
          <Separator />
          <Label>
            {t("profile.passwordsTab.password")}
          </Label>
          <Input
            type="password"
            name="oldPassword"
            placeholder={t("profile.passwordsTab.oldPassword")}
            value={formik.values.oldPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.oldPassword && formik.errors.oldPassword}
            styleType={InputStyle.ProfileForm}
            eyeButtonVisible
          />
          <Input
            type="password"
            name="newPassword"
            placeholder={t("profile.passwordsTab.newPassword")}
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.newPassword && formik.errors.newPassword}
            styleType={InputStyle.ProfileForm}
            eyeButtonVisible
          />
          <InfoWrapper>
            <InfoTextBold>
              {t("profile.passwordsTab.info")}
            </InfoTextBold>
          </InfoWrapper>
          <InfoWrapper>
            {formik.values.newPassword.match(min12charsRegex) ? <CheckIcon /> : <ErrorIcon />}
            <InfoText>
              {t("profile.passwordsTab.minChars")}
            </InfoText>
          </InfoWrapper>
          <InfoWrapper>
            {formik.values.newPassword.match(capitalLettersRegex) ? <CheckIcon /> : <ErrorIcon />}
            <InfoText>
              {t("profile.passwordsTab.capitalLetters")}
            </InfoText>
          </InfoWrapper>
          <InfoWrapper>
            {formik.values.newPassword.match(decimalRegex) ? <CheckIcon /> : <ErrorIcon />}
            <InfoText>
              {t("profile.passwordsTab.decimals")}
            </InfoText>
          </InfoWrapper>
          <InfoWrapper>
            {formik.values.newPassword.match(specialCharsRegex) ? <CheckIcon /> : <ErrorIcon />}
            <InfoText>
              {t("profile.passwordsTab.specialChars")}
            </InfoText>
          </InfoWrapper>
          <InfoWrapper>
            {!formik.values.newPassword.match(min4RepeatedCharsRegex) ? <CheckIcon /> : <ErrorIcon />}
            <InfoText>
              {t("profile.passwordsTab.maxRepeatedChars")}
            </InfoText>
          </InfoWrapper>
          <InfoWrapper>
            {!formik.values.newPassword.match(disallowedCharsRegex) ? <CheckIcon /> : <ErrorIcon />}
            <InfoText>
              {t("profile.passwordsTab.disallowedChars")}
            </InfoText>
          </InfoWrapper>
          <Input
            type="password"
            name="repeatPassword"
            placeholder={t("profile.passwordsTab.repeatPassword")}
            value={formik.values.repeatPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.repeatPassword && formik.errors.repeatPassword}
            styleType={InputStyle.ProfileForm}
            eyeButtonVisible
          />
          <Button
            $large
            color={ButtonColor.Primary}
            onClick={formik.handleSubmit}
            disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          >
            {t("profile.passwordsTab.updatePassword")}
          </Button>
          {errorMessage && <Error>{errorMessage}</Error>}
          {showConfirmationMessage && <Message>{t("profile.passwordsTab.passwordUpdated")}</Message>}
        </FieldsWrapper>
      </FieldsContainer>
    </Container>
  );
};