import React, { useEffect } from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Thumbnail } from "../../../../components/thumbnail/thumbnail.component";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { useMediaListener } from "../../../../hooks/useMediaListener";
import { selectIddHistory, selectIddNextPage } from "../../redux/profile.selectors";
import { ProfileActions } from "../../redux/profile.reducer";

import {
  Container,
  TableWrapper,
  Table,
  ThumbnailCell,
  TableHead,
  HeaderRow,
  HeaderCell,
  BodyRow,
  Cell,
  ClockIcon,
  ListItem,
  TextWrapper,
  MobileCell,
  CellTitle,
  BoxTitle,
  EmptyHistoryWrapper,
  DisabledIcon,
  Minutes,
} from "./iddTab.styled";

export const IDDTab = ({ className }) => {
  const { isMobile } = useMediaListener();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const iddHistory = useSelector(selectIddHistory);
  const nextPage = useSelector(selectIddNextPage);

  useEffect(() => {
    dispatch(ProfileActions.fetchIddHistory());
  }, [dispatch]);

  const handleNextPage = () => {
    if (nextPage) {
      dispatch(ProfileActions.loadIddNextPage(nextPage));
    }
  };

  const renderTableBody = renderWhenTrue(() =>
    <InfiniteScroll
      loadMore={handleNextPage}
      hasMore={!!nextPage}
      element="tbody"
    >
      {iddHistory.map((item) => (
        <BodyRow key={item.id}>
          <Cell>
            <ThumbnailCell>
              <Thumbnail
                itemsType={item.type.type}
                image={item.image}
                disableCursor
              />
              {item.name}
            </ThumbnailCell>
          </Cell>
          <Cell>
            <ClockIcon />
            <Minutes>
              {item.completedMinutes} {t("profile.iddHistoryTable.minutes")}
            </Minutes>
          </Cell>
          <Cell>{item.type.label}</Cell>
          <Cell>{item.completeDate}</Cell>
        </BodyRow>
      ))}
    </InfiniteScroll>
  );

  const renderDesktopTable = renderWhenTrue(() => (
    <Table>
      <TableHead>
        <HeaderRow>
          <HeaderCell>{t("profile.iddHistoryTable.material")}</HeaderCell>
          <HeaderCell>{t("profile.iddHistoryTable.time")}</HeaderCell>
          <HeaderCell>{t("profile.iddHistoryTable.type")}</HeaderCell>
          <HeaderCell>{t("profile.iddHistoryTable.passDate")}</HeaderCell>
        </HeaderRow>
      </TableHead>
      {renderTableBody(!!iddHistory)}
    </Table>
  ));

  const renderMobileList = renderWhenTrue(() =>
    <InfiniteScroll
      loadMore={handleNextPage}
      hasMore={!!nextPage}
    >
      {iddHistory.map((item) => (
        <ListItem key={item.id}>
          <ThumbnailCell>
            <Thumbnail
              itemsType={item.type.type}
              image={item.image}
              disableCursor
            />
          </ThumbnailCell>
          <TextWrapper>
            <MobileCell>
              <ClockIcon />
              <Minutes>{item.completedMinutes} {t("profile.iddHistoryTable.minutes")}</Minutes>
            </MobileCell>
            <MobileCell>
              <BoxTitle>{item.name}</BoxTitle>
            </MobileCell>
            <MobileCell>
              <CellTitle>{t("profile.iddHistoryTable.type")}</CellTitle>
              {item.type.label}
            </MobileCell>
            <MobileCell>
              <CellTitle>{t("profile.iddHistoryTable.passDate")}</CellTitle>
              {item.completeDate}
            </MobileCell>
          </TextWrapper>
        </ListItem>
      ))}
    </InfiniteScroll>
  );

  const renderEmptyHistory = renderWhenTrue(() => (
    <EmptyHistoryWrapper>
      <DisabledIcon />
      {t("profile.iddHistoryTable.notPassedYet")}
    </EmptyHistoryWrapper>
  ));

  const renderHistory = renderWhenTrue(() => (
    <>
      {renderDesktopTable(!isMobile)}
      {renderMobileList(isMobile)}
    </>
  ));

  if (!iddHistory) return <div />;

  return (
    <Container className={className}>
      <TableWrapper>
        {renderHistory(!!iddHistory.length)}
        {renderEmptyHistory(!iddHistory.length)}
      </TableWrapper>
    </Container>
  );
};

IDDTab.propTypes = {
  className: PropTypes.string,
};
