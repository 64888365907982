import React from "react";
import { useTranslation } from "react-i18next";

import { Container, Marker } from "../../../../quiz/quizToc/quizToc.styled";

export const QuizToc = ({ current, length }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Marker>{t("global.question")} {current}</Marker> | {length}
    </Container>
  );
};
