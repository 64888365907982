import styled from "styled-components";

import { FontWeight } from "../../../../theme";
import { Button as ButtonBase } from "../../../../components/button/button.component";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Title = styled.h1`
  font-weight: ${FontWeight.Light};
  font-size: 32px;
  margin-bottom: 36px;
`;

export const ButtonWrapper = styled.div`
  margin-top: auto;
  height: 123px;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Button = styled(ButtonBase)`
  flex-shrink: 0;
`;
