import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { cond, equals, always } from "ramda";

import { renderWhenTrue } from "../../helpers/rendering";

import { RadialSeparator } from "./components/radialSeparator/radialSeparator.component";
import { ChartType, ChartColorType, ChartIcon, ChartUnit } from "./progressChart.constants";
import {
  Container,
  ChartWrapper,
  Chart,
  Label,
  Value,
  ValueWrapper,
  ChartIconWrapper,
  GoldMedalSvg,
  ClockSvg,
  ColumnGraphSvg,
} from "./progressChart.styled";

export const ProgressChart = (props) => {
  const {
    className,
    label,
    value,
    maxValue,
    unit = ChartUnit.Percent,
    type = ChartType.Line,
    color = ChartColorType.Brand,
    iconColor = ChartColorType.Brand,
    icon,
  } = props;
  const [isFirstRender, setFirstRender] = useState(true);
  const [chartValue, setChartValue] = useState(0);
  const strokeWidth = useMemo(() => type === ChartType.Dashed ? 6 : 2, [type]);

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false);
    } else if (unit === ChartUnit.Unit) {
      setChartValue(value / maxValue * 100);
    } else {
      setChartValue(value);
    }
  }, [value, maxValue, unit, isFirstRender]);

  const renderIcon = useCallback(cond([
    [equals(ChartIcon.GoldMedal), always(<GoldMedalSvg />)],
    [equals(ChartIcon.Clock), always(<ClockSvg />)],
    [equals(ChartIcon.ColumnGraph), always(<ColumnGraphSvg />)],
  ]), []);

  const renderRadialSeparators = renderWhenTrue(() => (
    <RadialSeparator strokeWidth={strokeWidth} />
  ));

  const renderValue = useCallback(() => {
    if (unit === ChartUnit.Percent) {
      return <Value>{Math.floor(value)}%</Value>;
    } else {
      return <Value>{value} / {maxValue}</Value>;
    }
  }, [unit, value, maxValue]);

  return (
    <Container className={className}>
      <ChartWrapper>
        <ChartIconWrapper color={iconColor}>
          {renderIcon(icon)}
        </ChartIconWrapper>
        <Chart
          value={chartValue}
          circleRatio={0.75}
          strokeWidth={strokeWidth}
          type={type}
          color={color}
        >
          {renderRadialSeparators(type === ChartType.Dashed)}
          <ValueWrapper>
            {renderValue()}
          </ValueWrapper>
        </Chart>
      </ChartWrapper>
      <Label>{label}</Label>
    </Container>
  );
};


ProgressChart.propTypes = {
  className: PropTypes.string,
};
