import React from "react";

import {
  InfotipWrapper,
  InfotipIcon,
  InfotipText,
} from "./infoTip.styled";

export const Infotip = ({ children, position = "right" }) => (
  <InfotipWrapper>
    <InfotipIcon />
    <InfotipText position={position}>
      {children}
    </InfotipText>
  </InfotipWrapper>
);
