import styled, { css } from "styled-components";

import { Media } from "../../theme";
import { SectionHeader as SectionHeaderBase } from "../../theme/typography";
import { ListButton } from "../listButton/listButton.component";

export const Container = styled.div`
  position: relative;
`;

export const ThumbnailsWrapper = styled.div`
  overflow-y: hidden;
  margin: 0 20px;

  ${Media.tablet`
    margin: 0;
  `}

  ${Media.desktop`
    overflow-x: hidden;
  `}
`;

export const SectionHeader = styled(SectionHeaderBase)`
  margin-right: 20px;
`;

export const ThumbnailsGrid = styled.div`
  display: flex;
  flex-wrap: nowrap;
  transition: all 0.8s;

  ${Media.desktop`
    transform:
      translateX(${({ $page }) => `${$page * -100}%`})
      translateX(${({ $page }) => `${$page * -20}px`});
  `}
`;

export const ThumbnailsList = styled.div`
  margin: 0 20px;

  ${Media.tablet`
    margin: 0;
  `}
`;

const ListButtonStyles = css`
  position: absolute;
  top: ${({ top }) => top ? `${top}px` : "calc(50% - 30px)"};
`;

export const PrevButton = styled(ListButton)`
  ${ListButtonStyles}
  left: -25px;
`;

export const NextButton = styled(ListButton)`
  ${ListButtonStyles}
  right: -25px;

  svg {
    transform: rotate(180deg);
  }
`;
