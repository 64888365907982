import { createActions, createReducer } from "reduxsauce";

import { deepClone } from "../../../helpers";
import { HostSex } from "../components/welcomeMessage/welcomeMessage.constants";

export const {
  Types: DashboardTypes,
  Creators: DashboardActions,
} = createActions(
  {
    getInitialData: null,
    getInitialDataSuccess: ["data"],
    setIsPending: ["isPending"],
    getHostSex: null,
    switchHostSex: null,
    switchHostSexSuccess: ["hostSex"],
    createUserVideo: ["video"],
    getAllUserVideos: null,
    getAllUserVideosSuccess: ["data"],
    getCompetenceItems: null,
    getCompetenceItemsSuccess: ["competences"],
    fetchBanners: null,
    fetchBannersSuccess: ["banners"],
    setListItemFavorite: ["itemId", "favorite"],
  },
  { prefix: "DASHBOARD/" }
);

const INITIAL_STATE = {
  isPending: true,
  data: {
    hostSex: HostSex.Woman,
    events: { incoming: null, recommended: [] },
    content: {
      renewal: [],
      incomplete: [],
      required: [],
      recommended: [],
      paid: [],
      ambassadorsBusinessCards: [],
      formsAndTests: [],
      competences: [],
    },
    learningProgress: 0,
    awards: [{ percent: 0 }],
    statistics: {},
    ranking: [],
    // iddStats works only with VW. Do not remove.
    iddStats: null,
    banners: [],
  },
};

const getInitialDataSuccess = (state, { data }) => {
  const {
    ranking,
    awards,
    content,
    events,
    statistics,
    learningProgress,
    iddStats,
    surveys,
  } = data;

  return {
    ...state,
    data: {
      ...state.data,
      ranking,
      awards,
      content: {
        ...state.data.content,
        ...content,
        formsAndTests: surveys,
      },
      events,
      statistics,
      learningProgress,
      iddStats,
    },
  };
};

const setIsPending = (state, { isPending }) => ({ ...state, isPending });

const switchHostSexSuccess = (state, { hostSex }) => ({
  ...state,
  data: { ...state.data, hostSex },
});

const getAllUserVideosSuccess = (state, { data }) => ({
  ...state,
  data: {
    ...state.data,
    content: { ...state.data.content, ambassadorsBusinessCards: data },
  },
});

const getCompetenceItemsSuccess = (state, { competences }) => ({
  ...state,
  data: {
    ...state.data,
    content: {
      ...state.data.content,
      competences,
    },
  },
});

const fetchBannersSuccess = (state, { banners }) => ({
  ...state,
  data: {
    ...state.data,
    banners,
  }
});

const setListItemFavorite = (state, { itemId, favorite }) => {
  if (!state.data.content) return state;

  const newState = deepClone(state);

  for (const property in newState.data.content) {
    const currentItem = newState.data.content[property].find((item) => item.id === itemId);
    if (currentItem) {
      currentItem.favorite = favorite;
    }
  }

  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [DashboardTypes.GET_INITIAL_DATA_SUCCESS]: getInitialDataSuccess,
  [DashboardTypes.SET_IS_PENDING]: setIsPending,
  [DashboardTypes.GET_ALL_USER_VIDEOS_SUCCESS]: getAllUserVideosSuccess,
  [DashboardTypes.SWITCH_HOST_SEX_SUCCESS]: switchHostSexSuccess,
  [DashboardTypes.GET_COMPETENCE_ITEMS_SUCCESS]: getCompetenceItemsSuccess,
  [DashboardTypes.FETCH_BANNERS_SUCCESS]: fetchBannersSuccess,
  [DashboardTypes.SET_LIST_ITEM_FAVORITE]: setListItemFavorite,
});
