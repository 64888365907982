const SET_NETWORK_ERROR = "COMMON/SET_NETWORK_ERROR";

const errorHandler = error => ({ type: SET_NETWORK_ERROR, error });

const actionCreators = { errorHandler };

export {
  SET_NETWORK_ERROR,
  actionCreators,
};
