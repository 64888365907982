import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import fileDialog from "file-dialog";
import { useSelector, useDispatch } from "react-redux";
import Dropzone from "react-dropzone";

import { Input } from "../../../../components/input/input.component";
import { DropdownInput } from "../../../../components/dropdownInput/dropdownInput.component";
import { ButtonColor } from "../../../../components/button/button.constants";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { selectConfirmationCategories } from "../../redux/competence.selectors";
import { CompetenceActions } from "../../redux/competence.reducer";

import { FormSchema } from "./confirmationForm.schema";
import { FileMaxSize, FileMaxSizeInMB } from "./confirmationForm.constants";
import {
  Container,
  SectionHeader,
  AddIcon,
  Fields,
  Button,
  FileInfo,
  AddedFile,
  FileName,
  RemoveButton,
  RemoveIcon,
  FileErrorWrapper,
  FileError,
  Separator,
  Actions,
  FileDropContainer,
  CircleIconWrapper,
  UploadIcon,
  DropzoneText,
  DropzoneLightText,
  DropzoneLink,
} from "./confirmationForm.styled";

export const ConfirmationForm = ({ className, open, onClose, competenceId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const categories = useSelector(selectConfirmationCategories);
  const [fileError, setFileError] = useState();

  const initialValues = {
    title: "",
    category: { name: "", label: "", id: null },
    file: null,
  };

  const handleSubmitCallback = (actions) => {
    onClose();
    setTimeout(() => {
      actions.setSubmitting(false);
      actions.resetForm();
    }, 500);
  };

  const handleSubmit = (values, actions) => {
    const newValues = {
      ...values,
      category: values.category.name,
    };

    actions.setSubmitting(true);
    dispatch(CompetenceActions.sendConfirmation(
      competenceId,
      newValues,
      () => handleSubmitCallback(actions),
    ));
  };

  const formik = useFormik({
    validationSchema: FormSchema,
    initialValues,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const handleAddFile = (file) => {
    if (file.size > FileMaxSize) {
      setFileError(t("competence.competenceTab.largeFile", { maxSize: FileMaxSizeInMB }));
    }
    else if (!["image/png", "image/jpg", "application/pdf", "image/jpeg"].includes(file.type)) {
      setFileError(t("competence.competenceTab.invalidFileType"));
    }
    else {
      setFileError();
      formik.setFieldValue("file", file);
    }
  };

  const handleChooseFile = () => {
    fileDialog({ accept: "image/png, image/jpg, .pdf" }).then((files) => {
      const uploadedFile = files[0];
      handleAddFile(uploadedFile);
    });
  };

  const removeFile = () => {
    formik.setFieldValue("file", null);
  };

  const handleCategoryChange = ({ target }) => {
    const category = categories.find((category) => category.id === target.value);
    formik.setFieldValue("category", category);
  };

  const renderFileError = renderWhenTrue(() => (
    <FileErrorWrapper>
      <FileError>
        {fileError}
      </FileError>
    </FileErrorWrapper>
  ));

  const renderAddFileButton = renderWhenTrue(() => (
    <>
      <Dropzone onDrop={(acceptedFiles) => handleAddFile(acceptedFiles[0])}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <FileDropContainer {...getRootProps({
            onClick: event => event.stopPropagation(),
          })}>
            <CircleIconWrapper isDragActive={isDragActive}>
              <input {...getInputProps()} multiple={false} />
              <UploadIcon />
            </CircleIconWrapper>
            <DropzoneText>{t("competence.competenceTab.dropFile")}</DropzoneText>
            <DropzoneLightText>
              {t("competence.competenceTab.or")} {" "}
              <DropzoneLink onClick={handleChooseFile}>
                {t("competence.competenceTab.chooseFile")}
              </DropzoneLink>
              {" "} {t("competence.competenceTab.fromDisk")}
            </DropzoneLightText>
          </FileDropContainer>
        )}
      </Dropzone>
      {renderFileError(!!fileError)}
    </>
  ));

  const renderAddedFile = renderWhenTrue(() => (
    <AddedFile>
      <FileName>{formik.values.file.name}</FileName>
      <RemoveButton onClick={removeFile}>
        <RemoveIcon />
      </RemoveButton>
    </AddedFile>
  ));

  return (
    <Container
      className={className}
      title={t("competence.competenceTab.addFile")}
      HeaderIcon={AddIcon}
      open={open}
      onClose={handleClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <Fields>
          <SectionHeader>
            {t("competence.competenceTab.fill")}
          </SectionHeader>
          <Input
            name="title"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={formik.handleFocus}
            value={formik.values.title}
            error={formik.touched.title && formik.errors.title}
            placeholder={t("competence.competenceTab.titlePlaceholder")}
            autofocus
          />
          <DropdownInput
            name="category"
            onChange={handleCategoryChange}
            value={formik.values.category && formik.values.category.name}
            valueId={formik.values.category && formik.values.category.id}
            placeholder={t("competence.competenceTab.chooseCategory")}
            options={categories}
          />
          <SectionHeader>
            {t("competence.competenceTab.addFile")}
          </SectionHeader>
          {renderAddFileButton(!formik.values.file)}
          {renderAddedFile(!!formik.values.file)}
          <FileInfo>{t("competence.competenceTab.verifying")}</FileInfo>
        </Fields>
        <Separator $narrow />
        <Actions>
          <Button
            color={ButtonColor.Primary}
            disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
            onClick={formik.handleSubmit}
          >
            {t("competence.competenceTab.sendFile")}
          </Button>
        </Actions>
      </form>
    </Container>
  );
};

ConfirmationForm.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  competenceId: PropTypes.number,
};
