import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { StarsRate } from "../../../../components/starsRate/starsRate.component";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { isMobile } from "../../../../theme";
import { selectCompetenceSkills, selectCompetenceOverview } from "../../redux/competence.selectors";

import {
  Container,
  MeasureTable,
  Description,
  Bold,
  TableHead,
  TableBody,
  TableWeightField,
  WeightField,
  SkillTitle,
  Title,
  SkillDetails,
  SkillDetailsItem,
  SkillList,
  Chart
} from "./levelMeasureDropdown.styled";

export const LevelMeasureDropdown = () => {
  const competenceSkills = useSelector(selectCompetenceSkills);
  const competenceOverview = useSelector(selectCompetenceOverview);
  const { competence } = competenceOverview;
  const { t } = useTranslation();

  const getSkillScore = useCallback((rate, weight) => {
    if (rate === null) {
      return <>-</>;
    }
    const weightInPercent = weight * 100;

    return `${rate}*${weightInPercent}% = ${Number((rate * weight).toFixed(2))}`;
  }, []);

  const renderTableBody = renderWhenTrue(() => {
    return competenceSkills.map(({ rate, skill }) => {
      const { id, name, weight } = skill;
      const skillRate = rate && rate.rate;
      const weightInPercent = weight * 100;

      return (
        <tr key={id}>
          <td>{name}</td>
          <td>
            <StarsRate rate={skillRate} />
          </td>
          <TableWeightField>
            <Chart value={weightInPercent} />
            <p>{weightInPercent}%</p>
          </TableWeightField>
          <td>{getSkillScore(skillRate, weight)}</td>
        </tr>
      );
    });
  });

  const renderSkillsDesktopView = renderWhenTrue(() => (
    <MeasureTable>
      <TableHead>
        <tr>
          <th>{t("competence.levelMeasureDropdown.competencyMeasure")}</th>
          <th>{t("competence.levelMeasureDropdown.rate")}</th>
          <th>{t("competence.levelMeasureDropdown.weight")}</th>
          <th>{t("competence.levelMeasureDropdown.score")}</th>
        </tr>
      </TableHead>
      <TableBody>{renderTableBody(!!competenceSkills.length)}</TableBody>
    </MeasureTable>
  ));

  const renderSkillsMobileView = renderWhenTrue(() => (
    <SkillList>
      <Title bordered>{t("competence.levelMeasureDropdown.competenceMeasures")}</Title>
      {competenceSkills.map(({ rate, skill }) => {
        const { id, name, weight } = skill;
        const skillRate = rate && rate.rate;
        const weightInPercent = weight * 100;

        return (
          <SkillDetails key={id}>
            <SkillTitle>{name}</SkillTitle>
            <SkillDetailsItem>
              <h1>{t("competence.levelMeasureDropdown.rate")}</h1>
              <StarsRate rate={skillRate} />
            </SkillDetailsItem>
            <SkillDetailsItem>
              <h1>{t("competence.levelMeasureDropdown.weight")}</h1>
              <WeightField>
                <Chart value={weightInPercent} />
                <p>{weightInPercent}%</p>
              </WeightField>
            </SkillDetailsItem>
            <SkillDetailsItem>
              <h1>{t("competence.levelMeasureDropdown.score")}</h1>
              {getSkillScore(skillRate, weight)}
            </SkillDetailsItem>
          </SkillDetails>
        );
      })}
    </SkillList>
  ));

  if (!competenceSkills) return;

  return (
    <Container>
      <Description>
        {t("competence.levelMeasureDropdown.description_1")}
        {" "}
        <Bold>{competence.name}</Bold>
        {" "}
        {t("competence.levelMeasureDropdown.description_2")}
      </Description>
      {renderSkillsDesktopView(!isMobile())}
      {renderSkillsMobileView(isMobile())}
    </Container>
  );
};
