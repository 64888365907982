import { prop } from "ramda";
import styled, { css } from "styled-components";

import { hexToRgba } from "../../helpers/colors";
import { styleWhenTrue } from "../../helpers/rendering";
import { Color, FontWeight, Media } from "../../theme";

export const Container = styled.div`
  display: none;

  ${styleWhenTrue(prop("open"), css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${hexToRgba(Color.black, 0.4)};
    z-index: 102;
  `)}
`;

export const Popup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  background-color: ${Color.white};
  box-shadow: 0 8px 10px -5px ${hexToRgba(Color.black, 0.2)},
    0 16px 24px 2px ${hexToRgba(Color.black, 0.14)},
    0 6px 30px 5px ${hexToRgba(Color.black, 0.12)};
  width: 600px;
  height: 400px;

  ${Media.tablet`
    padding: 0 100px;
  `}
`;

export const Title = styled.h1`
  font-weight: ${FontWeight.Bold};
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: ${Color.deepGray};
  margin: 22px 0 10px;
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${Color.gray9};
  text-align: center;
  margin-bottom: 30px;
`;
