import React, { useContext, useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { InView } from "react-intersection-observer";

import { getAnimation } from "../../services/api";
import { PageDataContext } from "../page/page.context";

import {
  Container,
  SlideCounter,
  CurrentSlideCounter,
  Title,
  AnimWrapper,
  StyledLottie,
} from "./additionalAnim.styled";

const AdditionalAnim = ({ className }) => {
  const {
    additionalTitle,
    additionalBgAnimSrc,
    currentSlideCounter,
    slidesOverallCounter,
  } = useContext(PageDataContext);
  const [animationData, setAnimationData] = useState();

  useEffect(() => {
    if (additionalBgAnimSrc) {
      getAnimation(additionalBgAnimSrc, setAnimationData);
    }
  }, [additionalBgAnimSrc]);

  if (!additionalTitle) return null;

  return (
    <Container className={className}>
      <SlideCounter>
        <CurrentSlideCounter>{currentSlideCounter}</CurrentSlideCounter>
        {` (${slidesOverallCounter})`}
      </SlideCounter>
      <Title dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(additionalTitle) }} />
      {!!animationData && (
        <InView>
          {({ inView, ref }) => (
            <AnimWrapper ref={ref}>
              <StyledLottie
                animationData={inView ? animationData : undefined}
                loop={false}
                rendererSettings={{
                  preserveAspectRatio: "xMidYMid slice",
                }}
              />
            </AnimWrapper>
          )}
        </InView>
      )}
    </Container>
  );
};

export default AdditionalAnim;
