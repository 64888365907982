import React from "react";
import { useTranslation } from "react-i18next";

import {
  Row,
  Title,
  Text,
  FinishedImage,
  ContentInner,
  ContentWrapper,
} from "./finishedScreen.styled";

export const FinishedScreen = () => {
  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <ContentInner>
        <Row>
          <Title>{t("gamification.finishedScreen.gamificationFinished")}</Title>
          <Text>{t("gamification.finishedScreen.thanks")}</Text>
        </Row>
        <Row>
          <FinishedImage />
        </Row>
      </ContentInner>
    </ContentWrapper>
  )
};
