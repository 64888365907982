import { Color } from "../../theme";

export const modalStyles = {
  overlay: {
    background: "rgba(0, 0, 0, .15)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  content: {
    width: "700px",
    maxWidth: "90vw",
    top: "none",
    right: "none",
    bottom: "none",
    left: "none",
    background: Color.white,
    borderRadius: 0,
    border: "none",
    textAlign: "center"
  }
};
