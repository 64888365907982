export const removeUnfinishedMarkdowns = (source) => {
  let text = source;

  const underlineMarkdowns = text.match(/~/g);
  if (underlineMarkdowns && underlineMarkdowns.length % 4 !== 0) {
    const sliceIndex = text.lastIndexOf("~") - 1;
    text = text.slice(0, sliceIndex - 1);
  }

  const boldMarkdowns = text.match(/\*/g);
  if (boldMarkdowns && boldMarkdowns.length % 4 !== 0) {
    const sliceIndex = text.lastIndexOf("*") - 1;
    text = text.slice(0, sliceIndex);
  }

  return text;
};
