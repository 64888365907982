import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import { ImageAspectRatio } from "../../../../components/imageUpload/imageUpload.constants";

import { Container, Image } from "./articleImage.styled";

export const ArticleImage = ({ className, src, alt, positionX, positionY }) => {
  const imageRef = useRef();
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  const handleOnLoad = () => {
    setWidth(imageRef.current.clientWidth);
    setHeight(imageRef.current.clientHeight);
  };

  return (
    <Container className={className} withAspectRatio={width / height <= ImageAspectRatio}>
      <Image
        ref={imageRef}
        src={src}
        alt={alt}
        positionX={positionX}
        positionY={positionY}
        onLoad={handleOnLoad}
      />
    </Container>
  );
};

ArticleImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  positionX: PropTypes.number,
  positionY: PropTypes.number,
};
