import { all, put, takeLatest } from "redux-saga/effects";
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";

import { NavRoute } from "../../app/mainNavigation/mainNavigation.constants";
import { AppSettingsActions } from "../../appSettings/redux/appSettings.reducer";
import i18n from "../../translations/i18n";
import { logger } from "../../helpers/logger";
import { AppConfig } from "../../appConfig";
import * as userContextApi from "../services/api";
import { UserRoleName } from "../userContext.constants";

import { UserContextTypes, UserContextActions } from "./userContext.reducer";

function* connectFirebase() {
  if (!firebase.apps.length) {
    firebase.initializeApp(AppConfig.firebaseConfig);
  }

  try {
    const messaging = firebase.messaging();

    messaging.getToken().then((currentToken) => {
      if (currentToken) {
        userContextApi.pushToken(currentToken);
      } else {
        logger("Firebase", "No Instance ID token available. Request permission to generate one.");
      }
    }).catch((error) => {
      console.log(`Firebase error: ${error}`);
    });
  } catch (error) {
    logger("Firebase", `[Competence]: Firebase error: ${error}`);
  }

  yield put(UserContextActions.tryConnectWebsocket());
}

function* tryLogin({ login, password, recaptchaResponse }) {
  yield put(UserContextActions.setLoading(true));
  const { data } = yield userContextApi.tryLogin(login, password, recaptchaResponse);

  if (data?.success) {
    if (data.user.role.name === UserRoleName.Onboarding) {
      window.history.pushState(null, "", NavRoute.Onboarding);
    } else {
      window.history.pushState(null, "", window.location.href.split("#")[0]);
    }

    i18n.changeLanguage(data.user.language);
    yield put(UserContextActions.tryLoginSuccess(data));
    yield put(UserContextActions.connectFirebase());
  } else {
    yield put(UserContextActions.setLoggedOut(data));
  }

  yield put(UserContextActions.setLoading(false));
}

function* updateUserInfo() {
  const { data } = yield userContextApi.updateUserInfo();
  yield put(UserContextActions.updateUserInfoSuccess(data));
  yield window.location.replace("/");
}

function* setLoggedOut({ data }) {
  yield put(AppSettingsActions.clearData());
  yield put(UserContextActions.setLoggedOutSuccess(data));
}

export function* watchUserContext() {
  yield all([
    yield takeLatest(UserContextTypes.CONNECT_FIREBASE, connectFirebase),
    yield takeLatest(UserContextTypes.TRY_LOGIN, tryLogin),
    yield takeLatest(UserContextTypes.UPDATE_USER_INFO, updateUserInfo),
    yield takeLatest(UserContextTypes.SET_LOGGED_OUT, setLoggedOut),
  ]);
}
