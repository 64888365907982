import { ReactComponent as ModuleIcon } from "./images/moduleIcon.svg";

export const Config = {
  moduleName: "idd",
  dependencies: [],
  label: "IDD",
  Icon: ModuleIcon,
  urls: ["/idd"],
  routeName: "api_user_idd_dashboard",
  routes: {},
  options: {},
  theme: {}
};
