import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Lottie from "lottie-react";
import { useDispatch, useSelector } from "react-redux";

import { selectHeroesList } from "../../redux/onboarding.selectors";
import { OnboardingActions } from "../../redux/onboarding.reducer";

import { Heroes } from "./chooseHero.constants";
import {
  Container,
  ContentWrapper,
  Pages,
  Part,
  Hero,
  TextWrapper,
  AnimationMobileWrapper,
  Header,
  Title,
  Description,
  AnimationDesktopWrapper,
  PrevButton,
  NextButton,
  ArrowImage,
  ChooseButton,
  Tip,
} from "./chooseHero.styled";

export const ChooseHero = ({ className, onChoose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const heroesList = useSelector(selectHeroesList);
  const [animationIndex, setAnimationIndex] = useState(0);
  const [anim, setAnim] = useState();

  const loadHeroes = useCallback(
    () => dispatch(OnboardingActions.getHeroesList()),
    [dispatch],
  );

  useEffect(() => {
    loadHeroes();
  }, [loadHeroes]);

  useEffect(() => {
    Heroes[animationIndex].animation.then(setAnim);
  }, [animationIndex]);

  const handleChooseClick = useCallback(() => {
    const heroName = Heroes[animationIndex].name;
    const heroId = heroesList.find((hero) => hero.name === heroName).id;
    onChoose(animationIndex, heroId);
  }, [animationIndex, onChoose, t, heroesList]);

  const handlePrevIndex = useCallback(() => {
    setAnimationIndex(animationIndex ? animationIndex - 1 : Heroes.length - 1);
  }, [animationIndex]);

  const handleNextIndex = useCallback(() => {
    setAnimationIndex(animationIndex < Heroes.length - 1 ? animationIndex + 1 : 0);
  }, [animationIndex]);

  const renderParts = useCallback(() => (
    Heroes.map((item, index) => (
      <Part key={item.title} active={index === animationIndex} />
    ))
  ), [animationIndex]);

  return (
    <Container className={className}>
      <ContentWrapper>
        <Pages>
          {renderParts()}
        </Pages>
        <Hero>
          <TextWrapper>
            <Header>{t("onboarding.hero.chooseHero")}</Header>
            <AnimationMobileWrapper>
              <PrevButton onClick={handlePrevIndex}>
                <ArrowImage />
              </PrevButton>
              <Lottie
                animationData={anim}
                loop
                rendererSettings={{
                  preserveAspectRatio: "xMidYMid slice",
                }}
              />
              <NextButton onClick={handleNextIndex}>
                <ArrowImage />
              </NextButton>
            </AnimationMobileWrapper>
            <Title>{t(Heroes[animationIndex].title)}</Title>
            <Description>{t(Heroes[animationIndex].description)}</Description>
            <Description>{t(Heroes[animationIndex].description2)}</Description>
            <ChooseButton onClick={handleChooseClick}>
              {t("onboarding.hero.choose")}
            </ChooseButton>
            <Tip>{t("onboarding.hero.cannotChange")}</Tip>
          </TextWrapper>
          <AnimationDesktopWrapper>
            <PrevButton onClick={handlePrevIndex}>
              <ArrowImage />
            </PrevButton>
            <Lottie
              animationData={anim}
              loop
              rendererSettings={{
                preserveAspectRatio: "xMidYMid slice",
              }}
            />
            <NextButton onClick={handleNextIndex}>
              <ArrowImage />
            </NextButton>
          </AnimationDesktopWrapper>
        </Hero>
      </ContentWrapper>
    </Container>
  );
};

ChooseHero.propTypes = {
  className: PropTypes.string,
  onChoose: PropTypes.func,
};
