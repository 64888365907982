import styled from "styled-components";

import { BackButton as BackButtonBase } from "../../components/backButton/backButton.component";
import { Media, Color, PageHeader as PageHeaderBase, ContentMaxWidth } from "../../theme";

export const Container = styled.div`
  padding: 54px 0;
  background-color: ${Color.almostWhite};
  display: flex;
  justify-content: center;

  ${Media.tablet`
    padding: 54px 80px;
  `}

  ${Media.desktop`
    padding: 54px 160px;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${ContentMaxWidth};
  width: 100%;
  margin: 0;
`;

export const Content = styled.div`
  display: flex;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;

  ${Media.tablet`
    padding-right: 20px;
  `}
`;

export const Sidebar = styled.aside`
  display: none;

  ${Media.tablet`
    display: block;
    width: 381px;
    max-width: 50%;
  `}
`;

export const PageHeader = styled(PageHeaderBase)`
  margin: 13px 20px 60px;

  ${Media.tablet`
    margin: 13px 0 60px;
  `}
`;

export const BackButton = styled(BackButtonBase)`
  margin-bottom: 40px;
  margin-left: 20px;

  ${Media.tablet`
    margin-left: 0;
  `}
`;
