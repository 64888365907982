import { ReactComponent as ModuleIcon } from "../../images/gamification.svg";

export const Config = {
  moduleName: "gamification",
  dependencies: [],
  label: "gamification.pageTitle",
  Icon: ModuleIcon,
  urls: ["/gamification/*", "/gamification"],
  routeName: "api_gamification_read",
  routes: {
    init: "init",
    start: "start",
    survey: "survey/:id",
    task: "task/:id",
    news: "news/:id",
    points: "points",
  },
  options: {},
  theme: {}
};
