import { createActions, createReducer } from "reduxsauce";

export const { Types: NewsTypes, Creators: NewsActions } = createActions(
  {
    getEntries: ["nextPage"],
    setAreActivitiesPending: ["areActivitiesPending"],
    getLatestArticlesSuccess: ["latestArticles"],
    getLatestActivities: null,
    getLatestActivitiesSuccess: ["latestActivities"],
    getEntriesSuccess: ["entries", "nextPage"],
    clearEntries: null,
    clearEntriesSuccess: null,
    getEntry: ["id"],
    getEntrySuccess: ["entryDetails"],
    getEntryFailure: null,
    deleteEntry: ["id"],
    deleteEntrySuccess: ["entry"],
    savePost: ["post", "onFinish"],
    editPost: ["post", "onFinish"],
    saveArticle: ["article", "onFinish"],
    editArticle: ["id", "article", "onFinish"],
    saveEntrySuccess: ["entry"],
    editEntrySuccess: ["entry"],
    saveComment: ["comment", "isGamification", "onFinish"],
    saveCommentSuccess: ["entry"],
    editComment: ["comment"],
    editCommentSuccess: ["entry"],
    deleteComment: ["id"],
    deleteCommentSuccess: ["entry"],
    likeEntry: ["id", "likeState", "isGamification"],
    likeEntrySuccess: ["entry"],
    setIsSavePending: ["isSavePending"],
    setAreEntriesPending: ["areEntriesPending"],
    setIsEntryPending: ["isEntryPending"],
    setAreLatestPending: ["areLatestPending"],
    getLatestArticles: null,
  },
  { prefix: "NEWS/" }
);

const INITIAL_STATE = {
  isSavePending: false,
  areActivitiesPending: true,
  areLatestPending: true,
  isEntryPending: true,
  areEntriesPending: true,
  entries: [],
  nextPage: "",
  latestArticles: [],
  entryDetails: null,
  latestActivities: [],
};

const setAreActivitiesPending = (state, { areActivitiesPending }) => ({ ...state, areActivitiesPending });
const getLatestActivitiesSuccess = (state, { latestActivities }) => ({ ...state, latestActivities });

const getEntriesSuccess = (state, { entries, nextPage }) => {
  return {
    ...state,
    entries: state.entries.concat(entries),
    nextPage,
  };
};

const clearEntriesSuccess = (state) => {
  return {
    ...state,
    entries: [],
    nextPage: "",
  }
};

const setIsSavePending = (state, { isSavePending }) => ({ ...state, isSavePending });
const setAreEntriesPending = (state, { areEntriesPending }) => ({ ...state, areEntriesPending });
const setIsEntryPending = (state, { isEntryPending }) => ({ ...state, isEntryPending });
const setAreLatestPending = (state, { areLatestPending }) => ({ ...state, areLatestPending });

const saveEntrySuccess = (state, { entry }) => {
  return {
    ...state,
    entries: [entry, ...state.entries],
  };
};

const editEntrySuccess = (state, { entry }) => {
  const entries = state.entries.map((item) => (
    item.id === entry.data.id ? entry.data : item
  ));

  return {
    ...state,
    entries,
  };
};

const saveCommentSuccess = (state, { entry }) => {
  const entries = state.entries.map((item) => (
    item.id === entry.data.id ? entry.data : item
  ));
  const entryDetails = state.entryDetails?.id === entry.data.id ? entry.data : state.entryDetails;

  return {
    ...state,
    entries,
    entryDetails,
  };
};

const editCommentSuccess = (state, { entry }) => {
  const entries = state.entries.map((item) => (
    item.id === entry.data.id ? entry.data : item
  ));
  const entryDetails = state.entryDetails?.id === entry.data.id ? entry.data : state.entryDetails;

  return {
    ...state,
    entries,
    entryDetails,
  };
};

const deleteCommentSuccess = (state, { entry }) => {
  const entries = state.entries.map((item) => (
    item.id === entry.data.id ? entry.data : item
  ));
  const entryDetails = state.entryDetails?.id === entry.data.id ? entry.data : state.entryDetails;

  return {
    ...state,
    entries,
    entryDetails,
  };
};

const likeEntrySuccess = (state, { entry }) => {
  const entries = state.entries.map((item) => (
    item.id === entry.data.id ? entry.data : item
  ));
  const entryDetails = state.entryDetails?.id === entry.data.id ? entry.data : state.entryDetails;

  return {
    ...state,
    entries,
    entryDetails,
  };
};

const getLatestArticlesSuccess = (state, { latestArticles }) => {
  return {
    ...state,
    latestArticles,
  };
};

const getEntrySuccess = (state, { entryDetails }) => ({
  ...state,
  entryDetails: entryDetails.data,
});

const getEntryFailure = (state) => ({
  ...state,
  entryDetails: null,
});

const deleteEntrySuccess = (state, { entry }) => {
  return {
    ...state,
    entries: state.entries.filter((item) => item.id !== entry.data.id),
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [NewsTypes.GET_ENTRIES_SUCCESS]: getEntriesSuccess,
  [NewsTypes.CLEAR_ENTRIES_SUCCESS]: clearEntriesSuccess,
  [NewsTypes.SAVE_ENTRY_SUCCESS]: saveEntrySuccess,
  [NewsTypes.EDIT_ENTRY_SUCCESS]: editEntrySuccess,
  [NewsTypes.SAVE_COMMENT_SUCCESS]: saveCommentSuccess,
  [NewsTypes.EDIT_COMMENT_SUCCESS]: editCommentSuccess,
  [NewsTypes.DELETE_COMMENT_SUCCESS]: deleteCommentSuccess,
  [NewsTypes.LIKE_ENTRY_SUCCESS]: likeEntrySuccess,
  [NewsTypes.GET_ENTRY_SUCCESS]: getEntrySuccess,
  [NewsTypes.GET_ENTRY_FAILURE]: getEntryFailure,
  [NewsTypes.DELETE_ENTRY_SUCCESS]: deleteEntrySuccess,
  [NewsTypes.SET_IS_SAVE_PENDING]: setIsSavePending,
  [NewsTypes.SET_ARE_LATEST_PENDING]: setAreLatestPending,
  [NewsTypes.SET_ARE_ENTRIES_PENDING]: setAreEntriesPending,
  [NewsTypes.SET_IS_ENTRY_PENDING]: setIsEntryPending,
  [NewsTypes.GET_LATEST_ARTICLES_SUCCESS]: getLatestArticlesSuccess,
  [NewsTypes.SET_ARE_ACTIVITIES_PENDING]: setAreActivitiesPending,
  [NewsTypes.GET_LATEST_ACTIVITIES_SUCCESS]: getLatestActivitiesSuccess,
});
