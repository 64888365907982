import styled, { css } from "styled-components";
import { prop } from "ramda";

import { ReactComponent as LockSvg } from "../../../../../images/lock2.svg";
import { hexToRgba } from "../../../../../helpers/colors";
import { styleWhenTrue } from "../../../../../helpers/rendering";
import { Color, FontWeight, Media } from "../../../../../theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 210px;
  padding: 42px 20px 0;
  margin-top: 2px;
  background-color: ${Color.white};
  width: 100%;

  ${Media.tablet`
    width: calc(50% - 1px);
  `}
`;

export const IconWrapper = styled.div`
  display: grid;
  place-items: center;
  width: 70px;
  height: 70px;
  overflow: hidden;
  background-color: ${(props) => props.color};
  border: 5px solid ${Color.white};
  border-radius: 50%;
  box-shadow: 0 0 4px ${hexToRgba(Color.black, 0.05)},
    0 12px 20px -8px ${hexToRgba(Color.gray6, 0.1)},
    0 12px 32px -4px ${hexToRgba(Color.black, 0.0367414)};
`;

export const DisabledIcon = styled(LockSvg)`
  width: 70px;
  height: 70px;
  border: 1px solid ${Color.gray8};
  border-radius: 50%;
  background-color: ${Color.whiteHover};
  fill: ${Color.gray5};
  padding: 28px 0;
`;

export const Title = styled.p`
  margin-top: 15px;
  font-size: 14px;
  line-height: 20px;
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.gray9};
  text-align: center;

  ${styleWhenTrue(prop("done"), css`
    color: ${Color.deepGray};
  `)}
`;

export const Subtitle = styled.p`
  font-size: 13px;
  line-height: 17px;
  color: ${Color.gray9};
  margin-top: 2px;
  text-align: center;
`;
