import styled from "styled-components";

import { Color, FontWeight } from "../../theme";

export const Container = styled.div`
  font-size: 1.2rem;
  line-height: 1.67;
  letter-spacing: 0.6px;
  text-align: left;
  color: ${Color.gray1};
`;

export const Marker = styled.strong`
  font-weight: ${FontWeight.Bolder};
  color: ${Color.black};
`;
