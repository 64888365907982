import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { renderWhenTrue } from "../../../../../../../helpers/rendering";
import { isMobile } from "../../../../../../../theme";

import {
  Container,
  Label,
  DropdownContent,
  DropdownOption,
  DropdownOptionWrapper,
  ArrowIcon,
  Wrapper,
} from "./dropdown.styled";

export const Dropdown = ({
  className,
  option,
  answers,
  addAnswer,
  removeAnswer,
  isDropdownOpen,
  toggleDropdown,
  wrongAnswers,
  setWrongAnswers,
  enableValidating,
}) => {
  const { t } = useTranslation();
  const [selectedAnswer, setSelectedAnswer] = useState();

  useEffect(() => {
    return () => setSelectedAnswer(null);
  }, [option, answers]);

  useEffect(() => {
    const isWrongSelected = selectedAnswer
      ? selectedAnswer.id !== option.answerId
      : null;

    const newSet = new Set(wrongAnswers);

    if (isWrongSelected && !wrongAnswers.has(option.id)) {
      newSet.add(option.id);
    } else {
      if (wrongAnswers.has(option.id)) {
        newSet.delete(option.id);
      }
    }
    setWrongAnswers(newSet);

    // eslint-disable-next-line
  }, [selectedAnswer, option]);

  const renderLabel = () => {
    const label = selectedAnswer
      ? selectedAnswer.text
      : isMobile()
        ? t("interactions.interactionDropdown.selectFromList")
        : t("interactions.interactionDropdown.select");
    return <Label>{label}</Label>;
  };

  const isWrong = () => wrongAnswers.has(option.id);

  const renderArrow = () => (
    <ArrowIcon
      enableValidating={enableValidating}
      open={isDropdownOpen}
    />
  );

  const renderAnswers = renderWhenTrue(() =>
    answers.map((answer) => (
      <DropdownOptionWrapper key={answer.id}>
        <DropdownOption onClick={() => handleSelectAnswer(answer)}>
          {answer.text}
        </DropdownOption>
      </DropdownOptionWrapper>
    ))
  );

  const handleSelectAnswer = (answer) => {
    setSelectedAnswer(answer);
    if (answer.id === option.answerId) {
      addAnswer(option);
    } else {
      removeAnswer(option);
    }
  };

  return (
    <Container
      className={className}
      wrongTheme={isWrong()}
      enableValidating={enableValidating}
      onClick={toggleDropdown}
    >
      <Wrapper>
        {renderLabel(isMobile())}
      </Wrapper>
      <DropdownContent show={isDropdownOpen}>
        {renderAnswers(!!answers.length)}
      </DropdownContent>
      {renderArrow()}
    </Container>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  option: PropTypes.object,
  answers: PropTypes.array,
  addAnswer: PropTypes.func,
  removeAnswer: PropTypes.func,
  isDropdownOpen: PropTypes.bool,
  toggleDropdown: PropTypes.func,
  wrongAnswers: PropTypes.object,
  setWrongAnswers: PropTypes.func,
  enableValidating: PropTypes.bool,
};
