import styled from "styled-components";

import { ReactComponent as ArrowSvg } from "../../images/arrow-3.svg";
import { Color, FontWeight } from "../../theme";

export const ArrowIcon = styled(ArrowSvg)`
  fill: ${() => Color.brandBasic};
  width: 16px;
  height: 10px;
  margin-right: 15px;
  transform: rotate(90deg);
  position: relative;
  top: -1px;
`;

export const Container = styled.button`
  border: none;
  background-color: ${Color.transparent};
  text-transform: uppercase;
  font-weight: ${FontWeight.Bold};
  color: ${Color.deepGray};
  letter-spacing: 0.6px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  padding: 0;
  z-index: 1;

  &:hover {
    color: ${() => Color.brandBasic};
  }
`;
