import { useState, useCallback, useEffect, useMemo } from "react";
import { EditorState, RichUtils, ContentState, convertToRaw, convertFromHTML } from "draft-js";
import { draftToMarkdown } from "markdown-draft-js";
import { marked } from "marked";

const getSelectedBlocksList = (editorState) => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const blockMap = contentState.getBlockMap();
  return blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]])
    .toList();
};

const getSelectedBlocksType = (editorState) => {
  const blocks = getSelectedBlocksList(editorState);
  const hasMultipleBlockTypes = blocks.some(
    block => block.type !== blocks.get(0).type
  );
  if (!hasMultipleBlockTypes) {
    return blocks.get(0).type;
  }
  return undefined;
};

export const useEditorState = (initialContent = "") => {
  const initialMarkdownContent = useMemo(() => {
    const rawMarkdownContent = marked(initialContent);
    return convertFromHTML(rawMarkdownContent);
  }, [initialContent]);

  const state = ContentState.createFromBlockArray(
    initialMarkdownContent.contentBlocks,
    initialMarkdownContent.entityMap,
  );
  const [draftEditorState, setDraftEditorState] = useState(EditorState.createWithContent(state));
  const [activeOptions, setActiveOptions] = useState({});
  const [content, setContent] = useState(initialContent);

  const setEditorState = useCallback((draftEditorState) => {
    setDraftEditorState(draftEditorState);
    const rawContentObject = convertToRaw(draftEditorState.getCurrentContent());
    const markdownContent = draftToMarkdown(rawContentObject);
    setContent(markdownContent);
  }, []);

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  }

  const setEmptyState = useCallback(() => setDraftEditorState(EditorState.createEmpty()), []);

  useEffect(() => {
    const currentActiveOptions = [...draftEditorState.getCurrentInlineStyle()].reduce((options, style) => {
      options[style.toLowerCase()] = true;
      return options;
    }, {});

    const blockType = getSelectedBlocksType(draftEditorState);
    currentActiveOptions[blockType] = true;

    setActiveOptions(currentActiveOptions);
  }, [draftEditorState]);

  return {
    editorState: draftEditorState,
    activeOptions,
    setEditorState,
    content,
    handleKeyCommand,
    setEmptyState,
  };
};
