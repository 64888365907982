import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { Button as ButtonBase } from "../../../../components/button/button.component";
import { styleIfElse, styleWhenTrue } from "../../../../helpers/rendering";
import { hexToRgba } from "../../../../helpers/colors";
import { Color, Media, FontWeight } from "../../../../theme";
import { ReactComponent as PlaySVG } from "../../../../images/play.svg";
import { ReactComponent as ArrowSVG } from "../../../../images/dropdown.svg";
import { ReactComponent as FullScreenSvg } from "../../../../images/fullscreen.svg";
import { ReactComponent as StopSVG } from "../../images/stop.svg";
import { ReactComponent as SquaresSvg } from "../../images/squares.svg";
import { ReactComponent as MicrophoneSvg } from "../../images/microphone.svg";
import { ReactComponent as CameraOffSvg } from "../../images/cameraOff.svg";
import { ReactComponent as ShareSvg } from "../../images/share.svg";
import { ReactComponent as MicrophoneOffSvg } from "../../images/microphoneOff.svg";
import { ReactComponent as RoomsSvg } from "../../images/rooms.svg";
import { ReactComponent as ExitSvg } from "../../images/exit.svg"
import { ReactComponent as FlipchartSvg } from "../../images/flipchart.svg";
import { ReactComponent as DotsSvg } from "../../images/verticalDots.svg";
import { ReactComponent as ShrinkScreenSvg } from "../../images/shrinkScreen.svg";

export const ModalContent = styled.div`
  padding: 80px 50px;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
`;

export const MoreButtonWrapper = styled.div`
  position: relative;
`;

const deactivatedIconStyle = css`
  ${styleWhenTrue(propEq("true", "deactivated"), css`
    path {
      fill: ${Color.gray1};
    }
  `)}
`;

export const ShareIcon = styled(ShareSvg)`
  ${deactivatedIconStyle}
`;

export const FlipchartIcon = styled(FlipchartSvg)`
  ${deactivatedIconStyle}
  width: 17px;
`;

export const FullScreenIcon = styled(FullScreenSvg)`
  ${deactivatedIconStyle}
`;

export const DotsIcon = styled(DotsSvg)`
  path {
    fill: ${() => Color.brandBasic};
  }
`;

export const ShrinkScreenIcon = styled(ShrinkScreenSvg)``;

export const MoreDropdown = styled.div`
  display: none;

  ${styleWhenTrue(prop("open"), css`
    display: block;
    position: absolute;
    z-index: 1;
    bottom: 41px;
    right: -20px;
    min-width: 290px;
    background-color: ${Color.white};
    padding: 15px 0;
    color: ${Color.deepGray};
    box-shadow: 0 2px 4px ${hexToRgba(Color.gray7, 0.15)}, 0 5px 10px ${hexToRgba(Color.deepGray, 0.05)};
    cursor: default;
  `)}
`;

export const DropdownText = styled.p`
  margin-left: 12px;
  color: ${Color.deepGray};
`;

export const DropdownOption = styled.button`
  display: flex;
  border: none;
  outline: none;
  background-color: ${Color.transparent};
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  padding: 10px 25px;
  width: 100%;

  svg {
    path {
      fill: ${Color.gray1};
    }
  }

  ${FullScreenIcon}, ${ShrinkScreenIcon} {
    width: 15px;
  }

  ${styleWhenTrue(prop("selected"), css`
    ${DropdownText} {
      color: ${() => Color.brandBasic};
    }

    svg {
      path {
        fill: ${() => Color.brandBasic};
      }
    }
  `)}
`;

export const ButtonWrapper = styled.div`
  padding: 30px;
  display: flex;
  justify-content: center;
  border-top: 1px solid ${Color.dirtyWhite};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.white};
  box-shadow: 0 1px 0 0 ${Color.gray8};
  max-height: 185px;

  ${Media.tablet`
    grid-area: 4 / 1 / 5 / 2;

    ${styleWhenTrue(prop("fullScreen"), css`
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-right: 1px solid ${Color.gray5};
    `)}
  `}
`;

export const SquaresIcon = styled(SquaresSvg)`
  path {
    fill: ${() => Color.brandBasic};
  }
`;

export const CloseGroupButton = styled(ButtonBase)`
  margin-left: 10px;
`;

export const PlayIcon = styled(PlaySVG)`
  height: 20px;
  fill: ${Color.white};
  margin-left: 2px;
`;

export const ExitIcon = styled(ExitSvg)`
  path {
    fill: ${() => Color.brandBasic};
  }
`;

export const StopIcon = styled(StopSVG)``;

export const OvalButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Circle = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: ${() => Color.brandBasic};
  width: 36px;
  height: 36px;
  box-shadow: 0 4px 4px 0 ${hexToRgba(Color.brandBasic, 0.1)},
    0 0 10px 0 ${hexToRgba(Color.brandBasic, 0.1)};
  transition: opacity 0.5s ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  ${styleWhenTrue(prop("stopButton"), css`
    background-color: ${Color.black};
  `)}
`;

export const StreamButtonLabel = styled.div`
  font-weight: bold;
  width: 60px;
  margin-left: 15px;
  line-height: 15px;
`;

export const Presenter = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: ${Color.gray4};
  width: 100%;
`;

export const DarkText = styled.span`
  color: ${Color.deepGray};
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 20px;
  height: 60px;
`;

export const RightSideTools = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  ${styleWhenTrue(prop("hidden"), css`
    display: none;
  `)}
`;

export const MediaButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const MicrophoneIcon = styled(MicrophoneSvg)`
  width: 16px;
  path {
    fill: ${() => Color.brandBasic};
  }
`;

export const PanelButton = styled.button`
  background-color: ${Color.transparent};
  border: none;
  color: ${() => Color.brandBasic};
  margin-left: 10px;

  ${Media.tablet`
    margin-left: 10px;
  `}

  ${Media.desktop`
    margin-left: 18px;
  `}

  &:hover {
    color: ${Color.gray4};

    ${MicrophoneIcon} {
      path {
        fill: ${Color.gray4};
      }
    }
  }

  ${styleWhenTrue(prop("deactivated"), css`
    color: ${Color.gray4};

    ${MicrophoneIcon} {
      path {
        fill: ${Color.gray4};
      }
    }
  `)}

  ${styleWhenTrue(prop("disabled"), css`
    cursor: default;
  `)}
`;

export const Separator = styled.div`
  background-color: ${Color.gray5};
  width: 1px;
  height: 30px;
  border-radius: 3px;
  margin: 0 20px;
  flex-shrink: 0;
`;

export const WideScreenButton = styled(PanelButton)`
  display: none;
  position: relative;

  ${Media.tablet`
    display: inline;
  `}
`;

export const ArrowIcon = styled(ArrowSVG)`
  g {
    fill: ${() => Color.brandBasic};
  }

  ${styleWhenTrue(prop("open"), css`
    transform: rotate(180deg);
  `)}

  ${styleWhenTrue(propEq("true", "deactivated"), css`
    g {
      fill: ${Color.gray4};
    }
  `)}
`;

export const ListButton = styled(ButtonBase)`
  color: ${() => Color.brandBasic};
  width: 16px;
  min-width: unset;
  padding: 0 3px;

  ${styleWhenTrue(prop("hidden"), css`
    display: none;
  `)}

  &:hover {
    ${ArrowIcon} {
      g {
        fill: ${Color.gray4};
      }
    }
  }
`;

export const InfoPanel = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${Color.gray5};
  justify-content: center;
  padding: 20px;

  ${Media.tablet`
    ${styleWhenTrue(prop("fullScreen"), css`
      position: fixed;
      top: 0;
      left: 0;
      border-top: none;
    `)}
  `}
`;

export const Title = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: ${Color.black};
  font-weight: ${FontWeight.Bold};
  display: flex;
  align-items: center;

  & + ${Presenter} {
    margin-top: 4px;
  }

  ${Media.tablet`
    font-size: 20px;

    ${styleWhenTrue(prop("fullScreen"), css`
      color: ${Color.white};
      font-size: 12px;
    `)}

    & + ${Presenter} {
      margin-top: 10px;
    }
  `}
`;

export const LiveInfo = styled.span`
  font-size: 10px;
  line-height: 14px;
  color: ${styleIfElse(prop("isLive"), Color.error, Color.gray1)};
  border: 1px solid ${styleIfElse(prop("isLive"), Color.error, Color.gray1)};
  padding: 2px 4px;
  border-radius: 3px;
  margin-right: 10px;
  text-transform: uppercase;
`;

export const CameraOffIcon = styled(CameraOffSvg)`
  path {
    fill: ${Color.error};
  }
`;

export const MicrophoneOffIcon = styled(MicrophoneOffSvg)`
  path {
    fill: ${Color.error};
  }
`;

export const RoomsButton = styled.button`
  border: none;
  display: flex;
  outline: none;
  background-color: ${Color.transparent};
  color: ${Color.gray1};
  font-weight: ${FontWeight.ExtraBold};
  font-size: 10px;
  align-items: center;
  text-transform: uppercase;
`;

export const RoomsIcon = styled(RoomsSvg)`
  margin-left: 10px;
  fill: ${Color.gray1};
`;

export const ModalText = styled.p`
  color: ${Color.gray9};
`;

export const Bold = styled.span`
  font-weight: ${FontWeight.Bold};
`;
