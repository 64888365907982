import * as Yup from "yup";

import i18n from "../../translations/i18n";

import { EntryLength } from "./articleForm.constants";

export const ArticleSchema = Yup.object().shape({
  title: Yup.string()
    .required(i18n.t("validationMessage.required"))
    .max(EntryLength.Title, i18n.t("validationMessage.maxLength")),
  content: Yup.string().max(EntryLength.ArticleFull, i18n.t("validationMessage.maxLength")),
  visibility: Yup.array().min(1, i18n.t("validationMessage.atLeastOneOption")),
});
