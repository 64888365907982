import styled from "styled-components";

import { Button as ButtonBase } from "../../../../components/button/button.component";
import { Color } from "../../../../theme";

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid ${Color.gray8};
  padding: 30px;
`;

export const ModalButton = styled(ButtonBase)`
  :first-of-type {
    margin-right: 10px;
  }
`;

export const WarningWrapper = styled.div`
  padding: 60px 20px;
  max-width: 450px;
  margin: 0 auto;
  text-align: center;
`;

export const ModalText = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: ${Color.gray9};
`;
