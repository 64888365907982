import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { ButtonColor } from "../../../../components/button/button.constants";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { selectParticipants, selectIsCyclicalMeeting } from "../../redux/webinars.selectors";
import { BroadcastType } from "../video/video.constants";
import { Timer } from "../timer/timer.component";

import {
  Container,
  Text,
  Header,
  PlayInfo,
  Clock,
  Button,
} from "./classesStreamTimer.styled";

export const ClassesStreamTimer = ({ startTimestamp, liveEnded }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const participants = useSelector(selectParticipants);
  const isCyclicalMeeting = useSelector(selectIsCyclicalMeeting);
  const presenter = participants.find((participant) => participant.role === BroadcastType.Presenter);
  const isCurrentUserPresenter = presenter?.user.isCurrentUser;
  const timeToStream = startTimestamp - Date.now() / 1000;
  const days = Math.floor((timeToStream / (60 * 60 * 24)) % 24);
  const [isTimeOver, setIsTimeOver] = useState(timeToStream <= 0);
  const counterHeaderText = isCurrentUserPresenter || isCyclicalMeeting
    ? t("webinars.streamTimer.waitingForMeeting")
    : t("webinars.streamTimer.waitingForPresenter");

  const handleTimeOver = () => setIsTimeOver(true);

  const handleLeave = () => navigate("/");

  const renderContent = renderWhenTrue(() => (
    <>
      <Clock />
      {isTimeOver ? (
        <Header>{t("webinars.streamTimer.meetingStartsSoon")}</Header>
      ) : (
        <Header>{counterHeaderText}</Header>
      )}
      {days === 0 && !isTimeOver && (
        <>
          <Text>{t("webinars.streamTimer.meetingStartsIn")}</Text>
          <Timer
            streamTimer
            startTimestamp={startTimestamp}
            onEnd={handleTimeOver}
          />
        </>
      )}
      {days === 1 && <Text>{t("webinars.streamTimer.meetingStartsTomorrow")}</Text>}
      {days > 1 && (
        <Text>
          {t("webinars.streamTimer.meetingStartsIn")} {days} {t("webinars.streamTimer.days")}
        </Text>
      )}
      {!isCyclicalMeeting && <PlayInfo />}
    </>
  ));

  return (
    <Container>
      {renderContent(!liveEnded)}
      {liveEnded && (
        <Header>
          {t("webinars.streamTimer.meetingEnded")}
          <Button color={ButtonColor.Primary} onClick={handleLeave}>
            {t("webinars.streamTimer.leave")}
          </Button>
        </Header>
      )}
    </Container>
  );
};
