import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Button as ButtonBase } from "../../../../components/button/button.component";
import { styleWhenTrue, styleIfElse } from "../../../../helpers/rendering";
import {
  FontWeight,
  Color,
  Media,
} from "../../../../theme";
import { ReactComponent as ConversationSvg } from "../../images/conversation.svg";
import { ReactComponent as CloseSvg } from "../../images/close.svg";

export const CloseButton = styled.button`
  background-color: ${Color.transparent};
  border: none;
  outline: none;
  margin-left: auto;
`;

export const CloseIcon = styled(CloseSvg)``;

export const Container = styled.div`
  background-color: ${Color.white};
  margin-top: 1px;

  ${styleWhenTrue(prop("open"), css`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid ${Color.gray5};
    margin-bottom: 70px;
  `)}

  ${Media.tablet`
    border-top: none;
    margin-top: 0;
    position: relative;
    grid-area: 1 / 2 / 5 / 3;

    ${styleWhenTrue(prop("cameraOn"), css`
      grid-area: 2 / 2 / 5 / 3;
    `)}

    ${styleWhenTrue(prop("secondCameraOn"), css`
      grid-area: 3 / 2 / 5 / 3;
    `)}

    ${styleWhenTrue(prop("fullScreen"), css`
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      padding: 16px 20px;
      margin-bottom: 0;
    `)}

    ${styleWhenTrue(prop("inactive"), css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    `)}
  `}
`;

export const Participants = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: ${Color.deepGray};
  text-transform: uppercase;
  padding: 15px 20px;

  ${Media.tablet`
    justify-content: flex-start;

    ${styleWhenTrue(prop("fullScreen"), css`
      padding: 15px 0;
    `)}
  `}
`;

export const BoldText = styled.span`
  font-weight: ${FontWeight.Bold};
  margin-right: 5px;
`;

export const PlaceholderWrapper = styled.div`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding: 20px;

  ${Media.tablet`
    font-size: 16px;
    padding: 0;
    flex-direction: column;
    align-items: center;
  `}
`;

export const PlaceholderImg = styled(ConversationSvg)`
  display: none;

  ${Media.tablet`
    display: block;
  `}
`;

export const PlaceholderText = styled.p`
  color: ${Color.gray4};

  ${Media.tablet`
    margin-top: 17px;
  `}
`;

export const Button = styled(ButtonBase)`
  margin-left: auto;

  ${Media.tablet`
    display: none;
  `}
`;

export const MobileWrapper = styled.div`
  display: ${styleIfElse(prop("mobileVisible"), "block", "none")};

  ${Media.tablet`
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px);

    ${styleWhenTrue(prop("fullScreen"), css`
      display: flex;
      flex-direction: column;
      flex: 1;
    `)}
  `}
`;
