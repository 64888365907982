export const Status = {
  Read: "read",
  Received: "received",
};

export const ParseTag = {
  News: "[link=news:",
  Event: "[link=event:",
  Url: "[link=url:",
  Gamification: "[link=gamification:",
  GamificationNews: "[link=gamificationNews:",
  Survey: "[link=survey:",
};

export const ParseTagModule = {
  [ParseTag.News]: "/news/",
  [ParseTag.Event]: "/szkolenia/",
  [ParseTag.Url]: "",
  [ParseTag.Gamification]: "/gamification/init",
  [ParseTag.GamificationNews]: "/gamification/news/",
  [ParseTag.Survey]: "/formularze/",
};
