import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../helpers/rendering";
import { Color, ContentMaxWidth, Media } from "../../../theme";
import { ReactComponent as BorderedPlaySVG } from "../../../images/borderedPlay.svg";
import { ReactComponent as PlayCircleSVG } from "../../../images/playCircle.svg";
import { Button as ButtonBase } from "../../../components/button/button.component";

export const Container = styled.div`
  background-color: ${Color.almostWhite};
  padding-bottom: 10px;
  min-height: 50vh;

  ${Media.tablet`
    padding-bottom: 0;
  `}
`;

export const Grid = styled.div`
  max-width: ${ContentMaxWidth};
  margin: 0 auto;

  video {
    background-color: ${Color.black};
  }

  ${Media.tablet`
    padding: 50px;
    display: grid;
    grid-template-columns: auto 287px;
    grid-template-rows: 181px 181px minmax(auto, 181px) minmax(auto, 181px);
    column-gap: 20px;
    
    ${styleWhenTrue(prop("fullScreen"), css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      grid-template-rows: 181px 141px auto auto;
      background-color: ${Color.white};
      max-width: 100%;
      z-index: 101;
      padding: 0;
      column-gap: 0;
      height: 100%;
    `)}
  `}
`;

export const BorderedPlayIcon = styled(BorderedPlaySVG)``;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${Color.gray5};
  padding: 30px;
`;

export const PlayCircleIcon = styled(PlayCircleSVG)`
  height: 16px;
`;

export const Button = styled(ButtonBase)`
  padding: 12px 65px;
  margin-left: 10px;
`;

export const ModalText = styled.div`
  font-size: 16px;
  color: ${Color.deepGray};
  text-align: center;
  padding: 50px 0;
`;
