import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../helpers/rendering";

export const Container = styled.div`
  pointer-events: none;

  ${styleWhenTrue(prop("$active"), css`
    cursor: pointer;
    pointer-events: auto;
  `)}
`;
