import { ReactComponent as ModuleIcon } from "./images/moduleIcon.svg";

export const Config = {
  moduleName: "search",
  dependencies: [
    /*
      If the module relates on other module (widgets), place relation definition here.
      E.g "@news/WidgetName"
    */
  ],
  label: "Search",
  Icon: ModuleIcon,
  urls: ["/search"],
  routeName: {},
  routes: {},
  options: {},
  theme: {}
};
