import styled from "styled-components";

import { hexToRgba } from "../../../../helpers/colors";
import { Color, FontWeight } from "../../../../theme";
import { ReactComponent as RedirectSvg } from "../../images/redirectIcon.svg"

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${hexToRgba(Color.black, 0.1)};
  z-index: 999;
`;

export const Modal = styled.div`
  width: 600px;
  height: 345px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Color.white};
`;

export const RedirectIcon = styled(RedirectSvg)`
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  font-weight: ${FontWeight.Bold};
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${Color.gray9};
`;

export const Bold = styled.span`
  font-weight: ${FontWeight.SemiBold};
`;

export const BrandColorText = styled.span`
  color: ${() => Color.brandBasic};
`;
