import styled from "styled-components";

import { ReactComponent as StarSvg } from "../../images/star.svg";
import { ReactComponent as EmptyStarSvg } from "../../images/emptyStar.svg";
import { Color, FontWeight } from "../../theme";

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const StarIcon = styled(StarSvg)`
  fill: ${() => Color.brandBasic};
  width: 16px;
  height: 15px;

  &:not(:first-of-type) {
    margin-left: 5px;
  }
`;

export const GreyStarIcon = styled(StarIcon)`
  fill: ${Color.gray5};
`;

export const EmptyStarIcon = styled(EmptyStarSvg)`
  &:not(:first-of-type) {
    margin-left: 5px;
  }

  ${StarIcon} + & {
    margin-left: 5px;
  }
`;

export const Label = styled.p`
  font-weight: ${FontWeight.SemiBold};
  font-size: 14px;
  line-height: 22px;
  color: ${Color.deepGray};

  & + svg {
    margin-left: 12px;
  }
`;
