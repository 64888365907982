import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useModuleConfig, ModuleType } from "../";

import { StartScreen } from "./components/startScreen/startScreen.component";
import { Dashboard } from "./components/dashboard/dashboard.component";
import { OpenQuestion } from "./components/openQuestion/openQuestion.component";
import { GamificationActions } from "./redux/gamification.reducer";
import { Container } from "./gamification.styled";
import { selectDateTo } from "./redux/gamification.selectors";
import { FinishedScreen } from "./components/finishedScreen/finishedScreen.component";
import { GamificationSurvey } from "./components/gamificationSurvey/gamificationSurvey.component";
import { EntryDetails } from "./components/entryDetails/entryDetails.component";
import { PointsHistory } from "./components/pointsHistory/pointsHistory.component";

const Gamification = () => {
  const config = useModuleConfig(ModuleType.Gamification);
  const location = useLocation();
  const navigate = useNavigate();
  const dateTo = useSelector(selectDateTo);
  const gamificationAvailable = dateTo
    ? Date.now() / 1000 - dateTo <= 0
    : dateTo === null;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GamificationActions.fetchCurrentLevel());
  }, [dispatch]);

  useEffect(() => {
    if (config.urls.includes(location.pathname)) {
      navigate(config.routes.init, { replace: true });
    }
  }, [navigate]);

  if (dateTo === undefined) return <></>;

  if (!gamificationAvailable) return <FinishedScreen />;

  return (
    <ThemeProvider theme={config.theme}>
      <Container>
        <Routes>
          <Route path={config.routes.start} exact element={<StartScreen />} />
          <Route path={config.routes.init} exact element={<Dashboard />} />
          <Route path={config.routes.task} element={<OpenQuestion />} />
          <Route path={config.routes.survey} element={<GamificationSurvey />} />
          <Route path={config.routes.news} element={<EntryDetails />} />
          <Route path={config.routes.points} element={<PointsHistory />} />
        </Routes>
      </Container>
    </ThemeProvider>
  );
};

export default Gamification;
