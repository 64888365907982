import styled from "styled-components";
import { Link as LinkBase } from "react-router-dom";

import { ReactComponent as ArrowSvg } from "../../images/arrow.svg";
import { Color, FontWeight, Media } from "../../theme";

export const Container = styled.div`
  background-color: ${Color.almostWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 20px;

  ${Media.tablet`
    padding: 50px 40px;
  `}
`;

export const Text = styled.p`
  font-size: 28px;
  line-height: 38px;
  font-weight: ${FontWeight.Light};
`;

export const Link = styled(LinkBase)`
  display: flex;
  align-items: center;
  margin-top: 23px;
  text-decoration: none;
`;

export const ArrowImage = styled(ArrowSvg)`
  fill: ${() => Color.brandBasic};
  width: 9px;
  height: 16px;
  margin-right: 13px;
`;

export const LinkText = styled.span`
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: ${FontWeight.Bold};
  letter-spacing: 0.6px;
  color: ${Color.deepGray};
`;
