export const RightPanelView = {
  Chat: "chat",
  Users: "users",
  Streams: "streams",
};

export const StreamsView = {
  Default: "default",
  Grid: "grid",
};
