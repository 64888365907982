import styled from "styled-components";
import { prop } from "ramda";

import { Color } from "../../../../theme/colors";

export const Separator = styled.div`
  position: absolute;
  height: 100%;
  transform: rotate(${prop("turn")}turn);
`;

export const Part = styled.div`
  background: ${Color.white};
  width: 2px;
  height: ${prop("strokeWidth")}%;
`;
