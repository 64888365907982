import * as Yup from "yup";

import i18n from "../../../../translations/i18n";

import { TitleLength } from "./confirmationForm.constants";

export const FormSchema = Yup.object().shape({
  title: Yup.string()
    .required(i18n.t("validationMessage.required"))
    .max(TitleLength, i18n.t("validationMessage.maxLength")),
  category: Yup.object()
    .required(i18n.t("validationMessage.required")),
  file: Yup.string()
    .required(i18n.t("validationMessage.required")),
});
