export const ViewType = {
  Question: "question",
  Quiz: "quiz",
  Summary: "summary",
};

export const QuizType = {
  Dropdown: "dropdown",
  SelectWithArrowsQuiz: "selectWithArrowsQuiz",
};

export const ThemeType = {
  CorrectAnswerTheme: "correctAnswerTheme",
  WrongAnswerTheme: "wrongAnswerTheme",
  DefaultTheme: "defaultTheme",
};

export const ButtonText = {
  Accept: "accept",
  TryAgain: "tryAgain",
  Next: "next",
};
