import { put, takeEvery, select } from "redux-saga/effects";

import { UserContextActions } from "../../userContext/redux/userContext.reducer";

import { ActionType, MessageType } from "./websocket.constants";

const getUserToken = (state) => state.userContext.data.token;

const handleAuthorize = (token) => ({
  type: ActionType.WS_SEND_MESSAGE,
  value: JSON.stringify({
    command: MessageType.Authorize,
    data: { token },
  }),
});

function* message(action) {
  const message = action.value;
  if (message.command === MessageType.Connected) {
    const deviceToken = message.data.token;
    yield put(UserContextActions.setDeviceToken(deviceToken));
    const token = yield select(getUserToken);
    yield put(handleAuthorize(token));
  }
  if (message.command === MessageType.AchievementReceive) {
    const { data } = message;
    yield put(UserContextActions.showAchievementModal(data));
  }
  if (message.command === MessageType.UserRefresh) {
    yield put(UserContextActions.updateUserInfo());
  }
}

export function* watchWebsockets() {
  yield takeEvery(ActionType.WS_MESSAGE, message);
}
