import styled from "styled-components";

import { hexToRgba } from "../../../../helpers/colors";
import { ReactComponent as CalendarSvg } from "../../../../images/calendar.svg";
import { Color, Media, FontWeight } from "../../../../theme";

export const Container = styled.div``;

export const Wrapper = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 100%;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95px;
  height: 60px;
  background-color: ${() => hexToRgba(Color.brandBasic, 0.1)};

  ${Media.tablet`
    width: 145px;
    height: 92px;
  `}
`;

export const CalendarIcon = styled(CalendarSvg)`
  width: 36px;
  height: 36px;
  fill: ${() => Color.brandBasic};
`;

export const TextWrapper = styled.div`
  text-align: left;
  margin: 0 5px;

  ${Media.tablet`
    margin-left: 30px;
  `}
`;

export const Title = styled.p`
  font-size: 14px;
  line-height: 19px;
  color: ${Color.deepGray};
  text-align: left;

  ${Media.tablet`
    font-size: 16px;
  `}
`;

export const Type = styled.p`
  font-size: 10px;
  line-height: 20px;
  font-weight: ${FontWeight.SemiBold};
  text-transform: uppercase;
  color: ${Color.gray9};
`;
