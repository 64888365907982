import styled from "styled-components";

import { FontWeight, Media } from "../../../../../theme";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-size: cover;
  flex-direction: column;
  height: 600px;
`;

export const TextWrapper = styled.div`
  margin-top: 30px;
  width: 100%;

  ${Media.tablet`
    margin-top: 100px;
    display: flex;
    align-items: center;
  `}
`;

export const Text = styled.h1`
  font-weight: ${FontWeight.Bold};
  font-size: 20px;
  line-height: 27px;
  text-align: left;

  ${Media.tablet`
    font-size: 22px;
    line-height: 40px;
    text-align: center;  
  `}
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  ${Media.tablet`
    flex-direction: row;
    justify-content: flex-end;
  `}
`;

export const AnswersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 60px;
  width: 100%;

  ${Media.tablet`
    justify-content: center;
  `}
`;
