import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Modal } from "../modal/modal.component";
import { ButtonColor, ButtonVariant } from "../button/button.constants";
import { Content, Actions, Button } from "./confirmationModal.styled";

export const ConfirmationModal = (props) => {
  const { t } = useTranslation();
  const {
    children,
    onConfirm = Function.prototype,
    onClose = Function.prototype,
    onDecline = Function.prototype,
    confirmText = t("components.confirmationModal.confirmText"),
    declineText = t("components.confirmationModal.declineText"),
    withoutDecline,
    isConfirmButtonDisabled,
    ...modalProps
  } = props;

  const handleConfirm = useCallback((event) => {
    onConfirm(event);
    onClose(event);
  }, [onClose, onConfirm]);

  const handleDecline = useCallback((event) => {
    onDecline(event);
    onClose(event);
  }, [onClose, onDecline]);

  return (
    <Modal {...modalProps} onClose={handleDecline}>
      <Content>{children}</Content>
      <Actions>
        <Button
          onClick={handleDecline}
          variant={ButtonVariant.Outlined}
          hidden={withoutDecline}
        >
          {declineText}
        </Button>
        <Button
          onClick={handleConfirm}
          disabled={isConfirmButtonDisabled}
          color={ButtonColor.Primary}
        >
          {confirmText}
        </Button>
      </Actions>
    </Modal>
  )
};

ConfirmationModal.propTypes = {
  className: PropTypes.string,
  withoutDecline: PropTypes.bool,
};
