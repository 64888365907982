import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { renderWhenTrue, renderWhenTrueOtherwise } from "../../../../helpers/rendering";
import { Loader } from "../../../../components/loader/loader.component";
import {
  selectNewsEntries,
  selectNewsNextPage,
  selectAreEntriesPending,
} from "../../redux/news.selectors";
import { NewsActions } from "../../redux/news.reducer";
import { Entry } from "../entry/entry.component";

import { Container, EmptyMessage } from "./newsFeed.styled";

export const NewsFeed = ({ className }) => {
  const { t } = useTranslation();
  const entries = useSelector(selectNewsEntries);
  const isPending = useSelector(selectAreEntriesPending);
  const nextPage = useSelector(selectNewsNextPage);
  const dispatch = useDispatch();

  const loadEntries = useCallback(
    () => dispatch(NewsActions.getEntries(nextPage)),
    [dispatch, nextPage],
  );

  const clearEntries = useCallback(() => {
    dispatch(NewsActions.clearEntries());
  }, [dispatch]);

  useEffect(() => {
    if (entries.length) {
      clearEntries();
    }

    dispatch(NewsActions.getEntries(""));
    // eslint-disable-next-line
  }, []);

  const renderEntry = (item) => (
    <Entry
      key={item.id}
      id={item.id}
      type={item.type}
      publisher={item.publisher}
      permissions={item.permissions}
      title={item.title}
      timestamp={item.timestamp}
      content={item.content}
      visibility={item.visibility}
      likeCounter={item.likeCounter}
      image={item.image}
      imagePositionX={item.imagePosition?.x}
      imagePositionY={item.imagePosition?.y}
      attachments={item.attachments}
      liked={item.liked}
      comments={item.comments}
    />
  );

  const renderEntries = () => (
    <InfiniteScroll
      loadMore={loadEntries}
      hasMore={!!nextPage}
    >
      {entries.map(renderEntry)}
    </InfiniteScroll>
  );

  const renderEmptyState = renderWhenTrue(() => (
    <Container>
      <EmptyMessage>{t("news.newsFeed.empty")}</EmptyMessage>
    </Container>
  ));

  const renderList = renderWhenTrueOtherwise(
    renderEntries,
    () => renderEmptyState(!isPending),
  );

  const renderLoader = renderWhenTrue(() => <Loader />);

  return (
    <Container className={className}>
      {renderList(!!entries.length)}
      {renderLoader(isPending)}
    </Container>
  );
};


NewsFeed.propTypes = {
  className: PropTypes.string,
};
