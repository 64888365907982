import React, { Component } from "react";

export default class Player extends Component {
  constructor(props) {
    super(props);

    this.paused = props.paused;
  }

  componentWillUnmount() {
    this.video.pause();
    this.video.src = "";
  }

  UNSAFE_componentWillReceiveProps({ paused }) {
    this.paused = paused;
    this.updatePaused();
  }

  setVideo(video) {
    this.video = video;
    this.updatePaused();
  }

  updatePaused() {
    if (!this.video) {
      return;
    }
    if (this.paused === this.video.paused) {
      return;
    }
    if (this.paused) {
      this.video.pause();
    } else {
      this.video.play();
    }
  }

  render() {
    const { url, onEnded } = this.props;
    return (
      <div>
        <video ref={ (video) => this.setVideo(video) } preload="auto" autoPlay={ true } onEnded={ onEnded }>
          <source src={ url } type="video/mp4" />
        </video>
      </div>
    );
  }
}

