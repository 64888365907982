import styled from "styled-components";

import { hexToRgba } from "../../../../helpers/colors";
import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.div`
  background-color: ${Color.white};
  width: 100%;
  box-shadow: 0px 5px 10px ${hexToRgba(Color.deepGray, 0.01)};
  padding: 45px 0 50px 25px;

  ${Media.tablet`
    padding: 45px 0 50px 35px;
  `}
`;

export const ScrollableContainer = styled.div`
  overflow-x: auto;
`;

export const CanvasContainer = styled.div`
  min-width: 700px;
  width: 100%;
  padding-right: 30px;
  position: relative;
`;

export const ScrollableChartWrapper = styled.div`
  position: relative;

  & > canvas {
    position: absolute;
    background-color: ${Color.white};
    top: 0;
    pointer-events: none;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const TextWrapper = styled.div`
  margin: 5px 0 0 25px;
`;

export const Title = styled.p`
  font-weight: ${FontWeight.Bold};
  font-size: 16px;
  line-height: 20px;
  color: ${Color.deepGray};
`;

export const Level = styled.p`
  margin-top: 3px;
  color: ${Color.gray9};
  font-size: 13px;
  line-height: 17px;
`;
