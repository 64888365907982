import styled from "styled-components";

export const ElearningButton = styled.button`
  background: #f5f6fa;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  border: none;
  border-radius: 10px;
  color: #000;
  outline: none;
  cursor: pointer;
  margin-bottom: 20px;
`;
