import React from "react";

import { StatisticTile } from "../../../../../UIElements";

import { icons } from "./numericalStatistics.constants";
import { ListHeader, Grid } from "./numericalStatistics.styled";

export const NumericalStatistics = ({
  statistics,
  title,
  isBlack,
  threeColumns,
  marginTop,
}) => {
  return (
    <>
      <ListHeader marginTop={marginTop}>{title}</ListHeader>
      <Grid threeColumns={threeColumns}>
        {Object.keys(statistics).map((key) => statistics[key].type ? (
          <StatisticTile
            isBlack={isBlack}
            key={statistics[key].label}
            icon={icons[statistics[key].type]}
            statisticNumber={statistics[key].count}
            statisticText={statistics[key].label}
          />
        ) : (
          <div key={key} />
        ))}
      </Grid>
    </>
  );
};
