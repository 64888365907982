import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { secondsToHours } from "../../../../helpers/time";

import {
  Container,
  HourglassIcon,
  LightText,
  BoldText,
} from "./closeRoomTimer.styled";

export const CloseRoomTimer = ({ timestamp }) => {
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState(secondsToHours(timestamp - (Date.now() / 1000)));

  useEffect(() => {
    if (timeLeft === "00 : 00 : 00") return;

    const timeout = setTimeout(() => {
      const secondsLeft = timestamp - (Date.now() / 1000);
      if (secondsLeft <= 0) setTimeLeft("00 : 00 : 00");
      else setTimeLeft(secondsToHours(secondsLeft));
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [timeLeft]);

  return (
    <Container redTheme={(timestamp - (Date.now() / 1000)) < 60}>
      <HourglassIcon />
      <LightText>{t("webinars.closeRoomTimer.timeLeft")}:</LightText>
      {" "}
      <BoldText>{timeLeft}</BoldText>
    </Container>
  );
};
