import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Separator } from "../../../../theme/typography";
import { AppConfig } from "../../../../appConfig";

import {
  Container,
  Wrapper,
  Image,
  Title,
  TextWrapper,
  Type,
} from "./multiFilesVideo.styled";

export const MultiFilesVideo = ({
  className,
  id,
  name,
  imageSrc,
  onClick,
}) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  return (
    <Container className={className}>
      <Wrapper onClick={handleClick}>
        <Image src={`${AppConfig.content}${imageSrc}`} />
        <TextWrapper>
          <Title>{name}</Title>
          <Type>{t("global.video")}</Type>
        </TextWrapper>
      </Wrapper>
      <Separator />
    </Container>
  );
};

MultiFilesVideo.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  imageSrc: PropTypes.string,
  onClick: PropTypes.func,
};
