import i18n from "i18next";
import dayjs from "dayjs";

import { pickerDateFormat } from "./datePicker.constants";

export const getErrorText = (errorType, minDate, maxDate) => {
  switch (errorType) {
  case "minDate":
    return i18n.t("datePicker.minDateMessage", { date: dayjs(minDate).format(pickerDateFormat) });
  case "maxDate":
    return i18n.t("datePicker.maxDateMessage", { date: dayjs(maxDate).format(pickerDateFormat) });
  case "invalidDate":
    return i18n.t("datePicker.dateInvalid");
  default:
    return "";
  }
};
