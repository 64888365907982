import { prop } from "ramda";
import styled, { css } from "styled-components";

import { Input as InputBase } from "../../components/input/input.component";
import { hexToRgba } from "../../helpers/colors";
import { styleWhenTrue } from "../../helpers/rendering";
import { ReactComponent as InfoSvg } from "../../images/infoIcon2.svg";
import { ReactComponent as AlertSvg } from "../../images/warning2.svg";
import { ReactComponent as AcceptSvg } from "../../images/check4.svg";
import { ReactComponent as ErrorSvg } from "../../images/closeInCircle.svg";
import { Color, FontWeight, Media } from "../../theme";

export const Container = styled.div`
  display: none;

  ${styleWhenTrue(prop("open"), css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 102;
    background-color: ${hexToRgba(Color.black, 0.4)};

    ${styleWhenTrue(prop("$blurred"), css`
      background-color: ${hexToRgba(Color.almostWhite, 0.8)};
      backdrop-filter: blur(25px);
    `)}
  `)}
`;

export const Popup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Color.white};
  box-shadow: 0 8px 10px -5px ${hexToRgba(Color.black, 0.2)},
    0 16px 24px 2px ${hexToRgba(Color.black, 0.14)},
    0 6px 30px 5px ${hexToRgba(Color.black, 0.12)};
  width: 600px;
  min-height: 400px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 22px;
  gap: 10px;
  border-bottom: 1px solid ${Color.dirtyWhite};
`;

export const HeaderIcon = styled(InfoSvg)`
  width: 22px;
  height: 22px;
  fill: ${() => Color.brandBasic};
`;

export const AlertIcon = styled(AlertSvg)`
  width: 22px;
  height: 22px;

  path {
    stroke: ${Color.error};  
  }
  circle {
    fill: ${Color.error};
  }
`;

export const AcceptIcon = styled(AcceptSvg)`
  width: 22px;
  height: 22px;
  fill: ${() => Color.brandBasic};
`;

export const HeaderText = styled.p`
  font-size: 10px;
  font-weight: ${FontWeight.Bold};
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${Color.deepGray};
`;

export const Content = styled.div`
  padding: 35px 25px;
  max-width: 544px;
  width: 100%;
  margin: 0 auto;
`;

export const Title = styled.h1`
  font-weight: ${FontWeight.Bold};
  font-size: 18px;
  line-height: 25px;
  color: ${Color.deepGray};
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${Color.gray9};
  margin-top: 10px;
`;

export const TextBold = styled.span`
  font-weight: ${FontWeight.Bold};
  color: ${() => Color.brandBasic};
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 25px;
  border-top: 1px solid ${Color.dirtyWhite};

  ${Media.tablet`
    flex-direction: row-reverse;
  `}
`;

export const Input = styled(InputBase)`
  &:first-of-type {
    margin-top: 20px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 7px;

  &:nth-last-of-type(2) {
    margin-bottom: 20px;
  }
`;

const iconStyles = css`
  flex-shrink: 0;
  width: 22px;
  height: 22px;
`;

export const CheckIcon = styled(AcceptSvg)`
  ${iconStyles}
  fill: ${Color.green};
`;

export const ErrorIcon = styled(ErrorSvg)`
  ${iconStyles}
  fill: ${Color.error};
  padding: 2px;
`;

export const InfoText = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: ${Color.gray9};
`;

export const InfoTextBold = styled(InfoText)`
  font-weight: ${FontWeight.Bold};
  color: ${Color.almostBlack};
`;

export const Error = styled.p`
  margin-top: 5px;
  display: flex;
  width: fit-content;
  background-color: ${Color.error};
  color: ${Color.white};
  font-size: 12px;
  line-height: 16px;
  padding: 12px 15px;
  border-radius: 2px;
`;
