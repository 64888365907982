import styled from "styled-components";

import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.div`
  ${Media.tablet`
    display: flex;
    justify-content: space-between;
  `}
`;

export const StatsWrapper = styled.div`
  display: flex;
  align-items: center;

  ${Media.tablet`
    align-items: flex-start;
  `}
`;

export const StatBox = styled.div`
  flex: 1;
  margin-right: 20px;
  margin-top: 0;
`;

export const StatValue = styled.p`
  font-size: 32px;
  line-height: 43px;
  font-weight: ${FontWeight.Bold};
  letter-spacing: 0.4px;
`;

export const StatLabel = styled.p`
  margin-top: 3px;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.17px;
  color: ${Color.gray9};
`;

export const TextBox = styled.div`
  margin-top: 20px;

  ${Media.tablet`
    margin-top: 0;
    flex: 2;
  `}
`;

export const Specialization = styled.p`
  margin-top: 14px;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.17px;
  color: ${Color.gray9};
`;

export const Separator = styled.div`
  display: none;

  ${Media.tablet`
    display: block;
    width: 1px;
    background-color: ${Color.gray4};
    min-height: 100%;
    margin-right: 20px;
    opacity: 0.3;
  `}
`;
