import React from "react";

import { Container, Answer } from "./answerList.styled";

const AnswerList = ({ answers }) => {
  return (
    <Container>
      {answers.map(({ id, label }) => (
        <Answer key={id}>{label}</Answer>
      ))}
    </Container>
  );
};

export default AnswerList;
