import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Button } from "../../../../../components/button/button.component";
import { styleWhenTrue } from "../../../../../helpers/rendering";
import { ReactComponent as ArticleSvg } from "../../../../../images/article.svg";
import { ReactComponent as PostSvg } from "../../../../../images/post.svg";
import { Color, FontWeight, Media } from "../../../../../theme";

export const Container = styled.div`
  margin-top: 60px;
`;

export const ListHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Color.white};
  padding: 10px 20px 9px;

  ${Media.tablet`
    flex-direction: row;
    padding: 10px 35px 9px;
    align-items: center;
  `}
`;

export const AddNews = styled.div`
  display: flex;
  align-items: center;
`;

const IconStyles = css`
  height: 16px;
  margin-right: 12px;
  fill: ${() => Color.brandBasic};
`;

export const PostIcon = styled(PostSvg)`
  ${IconStyles}
`;

export const ArticleIcon = styled(ArticleSvg)`
  ${IconStyles}
`;


export const AddNewsButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.almostWhite};
  color: ${Color.deepGray};
  border-radius: 3px;
  min-width: 120px;

  & + & {
    margin-left: 4px;
  }

  &:hover {
    color: ${Color.white};

    ${PostIcon}, ${ArticleIcon} {
      fill: ${Color.white};
    }
  }

  ${styleWhenTrue(prop("active"), css`
    background-color: ${() => Color.brandBasic};
    color: ${Color.white};

    ${PostIcon}, ${ArticleIcon} {
      fill: ${Color.white};
    }

    &:hover {
      background-color: ${() => Color.brandBasic};
    }
  `)}
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;  
`;

export const HeaderLabel = styled.p`
  font-size: 11px;
  line-height: 20px;
  font-weight: ${FontWeight.Bold};
  margin-right: 20px;
`;

export const DropdownWrapper = styled.div`
  width: 258px;
  margin-top: 10px;
`;

export const NewsWrapper = styled.div`
  background-color: ${Color.white};
  margin-top: 10px;
  margin-bottom: 30px;
`;
