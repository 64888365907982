import { ReactComponent as BoldIcon } from "../../images/optionBold.svg";
import { ReactComponent as ItalicIcon } from "../../images/optionItalic.svg";
import { ReactComponent as UnderlineIcon } from "../../images/optionUnderline.svg";
import { ReactComponent as ListIcon } from "../../images/optionList.svg";

export const OptionType = {
  Command: "command",
  BlockType: "block-type"
};

export const Option = {
  Bold: {
    key: "bold",
    type: OptionType.Command,
    Icon: BoldIcon,
    description: "components.contentEditor.toolbar.bold",
  },
  Italic: {
    key: "italic",
    type: OptionType.Command,
    Icon: ItalicIcon,
    description: "components.contentEditor.toolbar.italic",
  },
  Underline: {
    key: "strikethrough",
    type: OptionType.Command,
    Icon: UnderlineIcon,
    description: "components.contentEditor.toolbar.underline",
  },
  List: {
    key: "unordered-list-item",
    type: OptionType.BlockType,
    Icon: ListIcon,
    description: "components.contentEditor.toolbar.list",
  },
};

export const OptionsList = [
  Option.Bold,
  Option.Italic,
  Option.Underline,
  Option.List,
];