import React, { useCallback, useMemo } from "react";
import { useTranslation, } from "react-i18next";
import { useFormik } from "formik";
import PropTypes from "prop-types";

import { Separator } from "../../theme/typography";
import { ButtonVariant } from "../button/button.constants";
import { ContentEditor } from "../contentEditor/contentEditor.component";
import { Input } from "../input/input.component";
import { EntryType } from "../articleForm/articleForm.constants";
import { DropdownInput } from "../dropdownInput/dropdownInput.component";
import { Loader } from "../loader/loader.component";

import { PostSchema } from "./postForm.schema";
import {
  Container,
  Fields,
  Actions,
  Button,
  VisibilityField,
  ChallengeField,
  ChallengeText,
  DropdownWrapper,
} from "./postForm.styled";

export const PostForm = ({
  className,
  onSubmit,
  onCancel,
  onEdit,
  onSave,
  values = {},
  inGamification,
  challenges = [],
  challengesLoadingFinished,
}) => {
  const { t } = useTranslation();
  const initialValues = useMemo(() => ({
    id: values.id || null,
    type: EntryType.Post,
    title: values.title || "",
    content: values.content || "",
    visibility: values.visibility || (inGamification ? [0] : []),
    challenge: values.challenge,
  }), [values.id, values.title, values.content, values.visibility, values.challenge]);

  const handleSubmitCallback = useCallback((actions) => {
    actions.setSubmitting(false);
    actions.resetForm();
    onSubmit();
  }, [onSubmit]);

  const handleSubmit = useCallback((values, actions) => {
    if (values.id) {
      onEdit(values, () => handleSubmitCallback(actions));
    } else {
      onSave(values, () => handleSubmitCallback(actions));
    }
  }, [handleSubmitCallback, values]);

  const formik = useFormik({
    validationSchema: PostSchema,
    initialValues,
    onSubmit: handleSubmit,
  });

  const handleCancel = useCallback((event) => {
    event.persist();
    onCancel(event);
  }, [onCancel]);

  const handleChallengeChange = ({ target }) => {
    const challenge = challenges.find((challenge) => challenge.id === target.value);
    formik.setFieldValue("challenge", challenge);
  };

  return (
    <Container className={className}>
      <form onSubmit={formik.handleSubmit}>
        <Fields>
          <Input
            name="title"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.title || ""}
            placeholder={t("news.addNews.post.titlePlaceholder")}
            error={formik.touched.title && formik.errors.title}
            autofocus
          />
          <ContentEditor
            name="content"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.content || ""}
            placeholder={t("news.addNews.post.contentPlaceholder")}
            error={formik.errors.content}
          />
          {inGamification ? (
            <ChallengeField>
              <ChallengeText>{t("news.addNews.post.challenge")}</ChallengeText>
              <DropdownWrapper>
                <DropdownInput
                  name="challenge"
                  onChange={handleChallengeChange}
                  value={formik.values.challenge?.name}
                  valueId={formik.values.challenge?.id}
                  placeholder={t("news.addNews.post.choose")}
                  options={challenges}
                  small
                  loadingFinished={challengesLoadingFinished}
                  emptyMessage={t("news.addNews.post.noChallenges")}
                />
              </DropdownWrapper>
            </ChallengeField>
          ) : (
            <VisibilityField
              name="visibility"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={formik.handleFocus}
              value={formik.values.visibility}
              placeholder={t("news.addNews.post.contentPlaceholder")}
              error={formik.touched.visibility && formik.errors.visibility}
            />
          )}
        </Fields>
        <Separator $narrow />
        <Actions>
          <Button
            onClick={handleCancel}
            variant={ButtonVariant.Outlined}
          >
            {t("news.addNews.post.cancel")}
          </Button>
          <Button
            onClick={formik.handleSubmit}
            disabled={!formik.isValid || formik.isSubmitting}
          >
            {formik.isSubmitting ? <Loader size={22} /> : t("news.addNews.post.save")}
          </Button>
        </Actions>
      </form>
    </Container>
  );
};

PostForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  inGamification: PropTypes.bool,
  challenges: PropTypes.array,
};
