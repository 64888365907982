import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { DetectorHandler } from "../../helpers/connectionHandler";
import { Icon } from "../../UIElements";
import { Color } from "../../theme";

import { Container, Text, IconWrapper } from "./offlineToast.styled";

const OfflineToast = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const toggleMode = () => {
    clearTimeout(timeoutId);
    setIsVisible(true);
    const newTimeoutId = setTimeout(() => setIsVisible(false), 5000);
    setTimeoutId(newTimeoutId);
  }

  return (
    <DetectorHandler onChange={toggleMode} render={({ online }) => (
      <Container $isVisible={isVisible} $isOnline={online}>
        <IconWrapper $isOnline={online}>
          <Icon
            icon={online ? "toast_online" : "toast_offline"}
            color={online ? Color.green2 : Color.white}
            size={22}
          />
        </IconWrapper>
        <Text>
          {online ? (
            t("app.offlineToast.youAreOnline")
          ) : (
            t("app.offlineToast.youAreOffline")
          )}
        </Text>
      </Container>
    )} />
  );
};

export default OfflineToast;
