import styled from "styled-components";

import { Color } from "../../../../theme/colors";
import { Media } from "../../../../theme/media";
import { FontWeight } from "../../../../theme/fonts";

export const Container = styled.div`
  padding: 30px 13px 0 20px;
  position: relative;

  ${Media.tablet`
    padding: 30px 33px 0 40px;
  `}
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AvatarWrapper = styled.div`
  margin-right: 14px;
`;

export const Title = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${Color.deepGray};
  font-weight: ${FontWeight.Bold};
  word-break: break-word;
`;

export const Publisher = styled.span`
  font-size: 12px;
  line-height: 22px;
  color: ${Color.deepGray};
  margin-right: 5px;
`;

export const Date = styled.span`
  display: inline-block;
  font-size: 12px;
  line-height: 22px;
  color: ${Color.gray4};
`;

export const ArticleTitle = styled.h3`
  font-size: 22px;
  line-height: 33px;
  color: ${Color.deepGray};
  font-weight: ${FontWeight.Bold};
  text-transform: uppercase;
  margin-bottom: 15px;

  ${Media.tablet`
    font-size: 26px;
    line-height: 39px;
  `}
`;

export const OptionsWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
`;
