export const TranslateSummaryData = (questions) => {
  return questions.map((question) => {
    const newQuestion = { children: [] };
    newQuestion.children.push({ type: "body", value: question.label });

    question.answers.forEach((answer) => {
      newQuestion.children.push({
        type: "answer",
        value: answer.label,
        params: {
          correct: answer.correct,
          selected: answer.selected
        },
      });
    });

    return newQuestion;
  });
};
