import React from "react";
import i18n from "i18next";

import { AppConfig } from "../../appConfig";
import { ElearningButton } from "../external/common.styled";

import { Slide, Download } from "./simpleDownload.styled";

const SimpleDownload = props => {
  return (
    <>
      <Slide>
        {props.data.map((file, index) => (
          <Download
            key={index}
            href={file.params.find(param => param.name === "url").value.substr(0,4) === "http"
              ? file.params.find(param => param.name === "url").value
              : AppConfig.content + file.params.find(param => param.name === "url").value
            }
            target="_blank"
          >
            <svg
              viewBox="0 0 60 60"
              style={{
                width: "30px",
                height: "30px",
                marginRight: "10px",
                transform: "translate(-3px, 3px)"
              }}
            >
              <g>
                <path d="M37,22H12c-0.552,0-1,0.448-1,1s0.448,1,1,1h25c0.552,0,1-0.448,1-1S37.552,22,37,22z" />
                <path d="M12,16h10c0.552,0,1-0.448,1-1s-0.448-1-1-1H12c-0.552,0-1,0.448-1,1S11.448,16,12,16z" />
                <path d="M38,31c0-0.552-0.448-1-1-1H12c-0.552,0-1,0.448-1,1s0.448,1,1,1h25C37.552,32,38,31.552,38,31z" />
                <path d="M30,39c0-0.552-0.448-1-1-1H12c-0.552,0-1,0.448-1,1s0.448,1,1,1h17C29.552,40,30,39.552,30,39z" />
                <path d="M12,46c-0.552,0-1,0.448-1,1s0.448,1,1,1h14c0.552,0,1-0.448,1-1s-0.448-1-1-1H12z" />
                <path d="M3,2h29v14h14v17h2V14.586L33.414,0H1v60h31v-2H3V2z M34,3.414L44.586,14H34V3.414z" />
                <path
                  d="M35,36v24h24V36H35z M57,58H37V38h9v12.586l-4.293-4.293l-1.414,1.414L47,54.414l6.707-6.707l-1.414-1.414L48,50.586V38h9
    V58z"
                />
              </g>
            </svg>
            {file.value}
          </Download>
        ))}
      </Slide>
      <ElearningButton
        onClick={props.callComplete}
        style={{ position: "absolute", right: "0px", bottom: "0px" }}
      >
        { i18n.t("interactions.watched") }
      </ElearningButton>
    </>
  );
};

export default SimpleDownload;
