import React from "react";
import { useTranslation } from "react-i18next";

import { Container, Placeholder, Title, Text } from "./emptyResult.styled";

export const EmptyResult = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Placeholder />
      <Title>{t("trainings.eventList.notFound")}</Title>
      <Text>{t("trainings.eventList.tryDifferent")}</Text>
    </Container>
  );
};
