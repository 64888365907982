import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { renderWhenTrue } from "../../../../../helpers/rendering";
import { Header, HeaderContainer } from "../../sequentialQuizInteractions.styled";
import { ButtonText } from "../../sequentialQuizInteractions.constants";
import { LightHeader } from "../../sequentialQuizInteractions.styled";
import { NextButton } from "../nextButton/nextButton.component";

import {
  Container,
  QuestionContainer,
  Text,
  RightPanel,
} from "./description.styled";

export const Description = ({
  text,
  onNext,
  questionNumber,
  correctTheme,
  wrongTheme,
  complianceTheme,
}) => {
  const { t } = useTranslation();
  const questionNumberText = `${complianceTheme ?
    t("interactions.sequentialQuiz.task") : t("interactions.sequentialQuiz.question")} ${questionNumber}`;

  const title = correctTheme
    ? t("interactions.description.correctAnswer")
    : wrongTheme
      ? t("interactions.description.wrongAnswer")
      : questionNumberText;

  const renderLightHeader = renderWhenTrue(() => (
    <LightHeader>{questionNumberText}</LightHeader>
  ));

  const renderText = () => text.map(
    (textPart, index) => (
      <Text
        key={index}
        light={wrongTheme || correctTheme}
      >
        {textPart}
      </Text>
    )
  );

  return (
    <Container>
      <QuestionContainer>
        <HeaderContainer>
          {renderLightHeader(wrongTheme || correctTheme)}
          <Header complianceTheme={complianceTheme}>{title}</Header>
        </HeaderContainer>
        {renderText()}
        <NextButton
          complianceTheme={complianceTheme}
          onClick={onNext}
          text={ButtonText.Next}
        />
      </QuestionContainer>
      <RightPanel
        wrongTheme={wrongTheme}
        correctTheme={correctTheme}
        summaryView={wrongTheme || correctTheme}
        complianceTheme={complianceTheme}
      />
    </Container>
  );
};

Description.propTypes = {
  text: PropTypes.array,
  onNext: PropTypes.func,
  questionNumber: PropTypes.string,
  correctTheme: PropTypes.bool,
  wrongTheme: PropTypes.bool,
  complianceTheme: PropTypes.bool,
};
