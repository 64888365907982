import React from "react";
import styled from "styled-components";

import { Icon } from "../../../UIElements/icons";
import { breakpoints, colors } from "../../../UIGlobals";
import * as UI from "./timerSlidesUI";

const Header = styled.div`
  display: flex;
  border-bottom: 1px solid ${colors().gray7};
  @media (${breakpoints().maxM}) {
    display: none;
  }
`;

const HeaderInner = styled.div`
  padding: 30px 100px;
  font-weight: 800;
  letter-spacing: 0.77px;
  font-size: 12px;
  width: 50%;
  &:first-child {
    border-right: 1px solid ${colors().gray7};
  }
`;

const Content = styled.div`
  display: flex;
  height: 577px;
  @media (${breakpoints().maxM}) {
    flex-direction: column;
    height: unset;
  }
`;

const TitleColumn = styled.div`
  padding-left: 100px;
  width: 50%;
  margin-top: 100px;
  @media (${breakpoints().maxM}) {
    padding-left: 20px;
    margin-top: 50px;
  }
`;

const Title = styled.div`
  font-size: 60px;
  line-height: 90px;
  font-weight: 300;
  @media (${breakpoints().maxM}) {
    font-size: 30px;
    line-height: 40px;
  }
`;

const IconWrapper = styled.div`
  margin-top: 100px;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  align-items: center;
  @media (${breakpoints().maxM}) {
    margin-top: 20px;
  }
`;

const IconText = styled.div`
  margin-left: 16px;
`;

const ImageColumn = styled.div`
  position: relative;
`;

const Image = styled.img`
  position: absolute;
  bottom: 0;
  max-height: 550px;
  @media (${breakpoints().maxM}) {
    position: relative;
    top: 50px;
    max-height: 300px;
  }
`;

const TitleWithTime = ({ data }) => (
  <UI.Container $dark $noFlex>
    <Header>
      <HeaderInner>{data.header}</HeaderInner>
    </Header>
    <Content>
      <TitleColumn>
        <Title>{data.title}</Title>
        <IconWrapper>
          <Icon icon="clock" width={30} size={30} />
          <IconText>{data.time}</IconText>
        </IconWrapper>
      </TitleColumn>
      <ImageColumn>
        <Image src={data.iconBaseUrl + data.iconUrl} alt="icon" />
      </ImageColumn>
    </Content>
  </UI.Container>
);

export default TitleWithTime;