import styled, { css } from "styled-components";
import { prop } from "ramda";
import { Link } from "react-router-dom";

import { styleWhenTrue } from "../../helpers/rendering";
import { hexToRgba } from "../../helpers/colors";
import { PageHeader, Media, FontWeight, Color, ContentMaxWidth } from "../../theme";

export const Container = styled.div``;

export const HeaderWrapper = styled.div`
  box-shadow: 0 2px 4px ${hexToRgba(Color.gray1, 0.25)};
  padding-top: 67px;

  ${Media.tablet`
    padding: 67px 80px 0;
  `}

  ${Media.desktop`
    padding: 67px 160px 0;
  `}
`;

export const HeaderBox = styled.div`
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
`;

export const ContentWrapper = styled.div`
  position: relative;
  padding: 30px 0;
  background-color: ${Color.almostWhite};

  ${Media.tablet`
    padding: 67px 80px;
  `}

  ${Media.desktop`
    padding: 67px 160px;

    > * {
      max-width: ${ContentMaxWidth};
      margin: 0 auto;
    }
  `}
`;

export const Header = styled(PageHeader)`
  margin-left: 20px;

  ${Media.tablet`
    margin-left: 0;
  `}
`;

export const Tabs = styled.div`
  display: flex;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-width: ${ContentMaxWidth};
  margin: 0 auto;

  &::-webkit-scrollbar {
    display: none;
  }

  ${Media.tablet`
    position: relative;
    left: -20px;
  `}
`;

export const TabText = styled.span`
  font-size: 14px;
  font-weight: ${FontWeight.SemiBold};
  white-space: nowrap;
  color: ${Color.gray9};
  padding: 13px 0;

  ${styleWhenTrue(prop("active"), css`
    color: ${() => Color.brandBasic};
    border-bottom: 2px solid ${() => Color.brandBasic};
  `)}
`;

export const Tab = styled(Link)`
  text-decoration: none;
  background-color: ${Color.transparent};
  border: none;
  padding: 17px 15px;
  
  &:first-of-type {
    padding-left: 20px;
  }

  &:last-of-type {
    padding-right: 20px;
  }

  ${styleWhenTrue(prop("disabled"), css`
    pointer-events: none;
  `)}
`;
