import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";

import { Label } from "../trainerPopup/trainerPopup.styled";

import {
  Container,
  Wrapper,
  TextDiv,
  Dot,
} from "./trainerPopupDescription.styled";

export const TrainerPopupDescription = ({ className, aboutMe, awards }) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <Wrapper>
        <Label>{t("trainings.trainerPopup.aboutMe")}</Label>
        <TextDiv dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(aboutMe) }} />
      </Wrapper>
      {!!awards?.length && (
        <Wrapper>
          <Label>{t("trainings.trainerPopup.awards")}</Label>
          {awards.map((award) => (
            <TextDiv key={award}>
              <Dot />
              {award}
            </TextDiv>
          ))}
        </Wrapper>
      )}
    </Container>
  );
};

TrainerPopupDescription.propTypes = {
  aboutMe: PropTypes.string.isRequired,
  awards: PropTypes.array.isRequired,
  className: PropTypes.string,
};
