import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  Container,
  CloseButton,
  CloseIcon,
  Title,
  List,
  OptionWrapper,
  OptionButton,
  OptionId,
  OptionTitle,
} from "./sectionList.styled";

export const SectionList = ({
  className,
  onClose,
  options,
  onSelectOption,
}) => {
  const { t } = useTranslation();

  const removeHtmlTags = (string) => string.replace(/<\/?[^>]+(>|$)/g, "");

  const renderOptions = () => (
    options.map((option) => (
      <OptionWrapper key={option.id}>
        <OptionButton onClick={() => onSelectOption(option.id)}>
          <OptionId>{option.id + 1}</OptionId>
          <OptionTitle>{removeHtmlTags(option.title)}</OptionTitle>
        </OptionButton>
      </OptionWrapper>
    ))
  );

  return (
    <Container className={className}>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      <Title>{t("lesson.lessonNav.issuesList")}</Title>
      <List>
        {renderOptions()}
      </List>
    </Container>
  );
};

SectionList.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  onSelectOption: PropTypes.func.isRequired,
};
