import React from "react";
import styled from "styled-components";

import PartsWrapper from "./common/partsWrapper";
import BorderedList from "./common/borderedList";

import { breakpoints, breakpointsArray, colors } from "../../../UIGlobals";
import * as UI from "./timerSlidesUI";

const IconColumn = styled.div`
  display: flex;
  ${props => props.title && "flex-direction: column;"}
  align-items: ${props => (props.title ? "center" : "flex-end")};
  justify-content: ${props => (props.title ? "space-between" : "center")};
  background-color: ${props => (props.columnColor ? props.columnColor : colors().gray6)};
  color: ${props => (props.lightTheme ? colors().deepGray : colors().white)};
  flex: 1;
  padding: ${props => (props.hasIconList ? "70px 20px 0" : "100px 20px")};
  @media (${breakpoints().maxM}) {
    padding: 0 0 0 5px;
    max-height: 0;
    align-items: flex-start;
    justify-content: flex-start;
    ${props => props.lightTheme && "margin-top: 60px;"}
    ${props => props.title && "flex-direction: row;"}
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  @media (${breakpoints().maxM}) {
    font-size: 16px;
    line-height: 18px;
  }
`;

const Subtitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  margin: 40px 20px 20px;
  @media (${breakpoints().maxM}) {
    margin: 20px;
  }
`;

const Image = styled.img`
  ${props => props.lightTheme && "bottom: 0; left: 0; position: absolute;"}
  margin-top: 20px;
  max-height: ${props => (props.maxHeight ? props.maxHeight : "140px")};
  max-width: ${props => (props.maxWidth ? props.maxWidth : "140px")};
  @media (${breakpoints().maxM}) {
    max-height: ${props => (props.hasIconList ? "350px" : "40px")};
    ${props => props.hasIconList && "max-width: 40px;"}
    ${props => props.bigIcon && "display: none;"}
    margin-top: 0;
  }
`;

const ListColumn = styled.div`
  position: relative;
  margin-left: 40px;
  border-left: 1px solid ${colors().maroon};
  display: flex;
  align-items: center;
  flex: 3;
  @media (${breakpoints().maxM}) {
    margin-left: 25px;
  }
`;

const List = styled.ul`
  font-size: 20px;
  line-height: 30px;
  width: 100%;
  @media (${breakpoints().maxM}) {
    font-size: 10px;
    line-height: 11px;
  }
`;

const ListRow = styled.li`
  display: flex;
  align-items: center;
  margin: 22px 0;
  @media (${breakpoints().maxM}) {
    margin: 7px 0;
  }
`;

const RowDot = styled.span`
  position: absolute;
  left: -4px;
  transform: translateY(-3px);
  min-width: 8px;
  height: 8px;
  background: ${colors().maroon};
  border-radius: 50%;
`;

const ListHeaderText = styled(UI.Header)`
  margin-left: 110px;
  @media (${breakpoints().maxM}) {
    margin: 0 15px 10px;
  }
`;

const ListText = styled.span`
  margin: 0 10px;
  font-size: 11px;
  @media (${breakpoints().maxM}) {
    line-height: 13px;
  }
`;

const DownloadLink = styled.a`
  color: ${colors().deepGray};
  text-decoration: none;
  font-size: 12px;
  line-height: 15px;
  margin: 0 10px;
`;

const CounterSpan = styled(UI.CounterSpan)`
  margin-left: 17px;
`;

const IconWrapper = styled.div`
  min-width: 50px;
  text-align: center;
`;

const IconImage = styled.img`
  width: 30px;
  margin-left: 8px;
`;

const MapColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 3;
  padding: 50px;
  @media (${breakpoints().maxM}) {
    flex: 1;
    flex-direction: column;
    padding: 60px 50px;
  }
`;

const MapImage = styled.img`
  max-width: 500px;
  @media (${breakpoints().maxM}) {
    max-width: 100%;
    @media (orientation:landscape) {
      max-height: 200px;
    }
  }

`;

const MapLegend = styled.div`
  position: absolute;
  bottom: 60px;
  left: 60px;
  min-width: 200px;
  @media (${breakpoints().maxM}) {
    position: static;
  }
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

const LegendItemCircle = styled.span`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${props => (props.color ? props.color : colors().white)};
  ${props => props.borderWidth
    && `border: ${props.borderWidth}px solid ${props.borderColor ? props.borderColor : colors().white}`};    
`;

const LegendItemText = styled.span`
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  margin-left: 18px;
`;

const TextColumnIcon = styled(UI.TextColumnIcon)`
  width: auto;
  max-width: 430px;
  margin-left: 22px;
  @media (${breakpoints().maxM}) {
    max-width: 90%;
    max-height: 120px;
    margin-left: 10px;
  }
`;

const HeaderItems = styled.div`
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  ${props => props.hasLeftMargin && "margin-left: 20px"};
`;

const IconInColumnAndText = ({
  data,
  lightTheme,
  columnColor,
  iconList,
  counterList,
  mapType,
  parts,
  activePart
}) => (
  <UI.Container $bgImage={data.iconBaseUrl + data.iconRightUrl}>
    <IconColumn
      title={data?.title}
      columnColor={columnColor}
      lightTheme={lightTheme}
      hasIconList={iconList}
    >
      {(data.title || data.subtitle) && (
        <HeaderItems hasLeftMargin={!!iconList}>
          {iconList && data.showParts && <PartsWrapper parts={parts} activePart={activePart} />}
          {data.title && <Title>{data.title}</Title>}
          {data.subtitle && <Subtitle>{data.subtitle}</Subtitle>}
          {data.showPartsBottom && <PartsWrapper parts={parts} activePart={activePart} bottom />}
        </HeaderItems>
      )}
      {data.iconUrl && window.innerWidth >= breakpointsArray.m && (
        <Image
          src={data.iconBaseUrl + data.iconUrl}
          alt="icon"
          hasIconList={iconList}
          maxHeight={iconList ? "380px" : "140px"}
          maxWidth={iconList ? "250px" : "140px"}
        />
      )}
      {data.bigIconUrl && (
        <Image
          lightTheme={lightTheme}
          src={data.iconBaseUrl + data.bigIconUrl}
          alt="icon"
          hasIconList={iconList}
          maxHeight="100%"
          maxWidth={lightTheme ? "100%" : "265px"}
        />
      )}
    </IconColumn>
    {iconList && (
      <ListColumn>
        <List>
          {data.header && (
            <ListRow>
              <ListHeaderText>{data.header}</ListHeaderText>
            </ListRow>
          )}
          {data.elements &&
            data.elements.map((element, index) => (
              <ListRow key={index}>
                <RowDot />
                {counterList && <CounterSpan>{index + 1}</CounterSpan>}
                {element.textColumnIconUrl && (
                  <TextColumnIcon src={data.iconBaseUrl + element.textColumnIconUrl} />
                )}
                {element.iconUrl && (
                  <IconWrapper>
                    <IconImage src={data.iconBaseUrl + element.iconUrl} alt="icon" />
                  </IconWrapper>
                )}
                {element.text && <ListText>{element.text}</ListText>}
                {element.downloadText && (
                  <DownloadLink
                    target="_blank"
                    rel="noopener noreferrer"
                    href={data.fileBaseUrl + element.fileUrl}
                  >
                    {element.downloadText}
                  </DownloadLink>
                )}
              </ListRow>
            ))}
        </List>
      </ListColumn>
    )}
    {mapType && (
      <MapColumn>
        <MapImage src={data.iconBaseUrl + data.mapUrl} />
        <MapLegend>
          {data.legendItems &&
            data.legendItems.map((item, index) => (
              <LegendItem key={index}>
                <LegendItemCircle
                  borderColor={item.borderColor}
                  color={item.color}
                  borderWidth={item.borderWidth}
                />
                <LegendItemText>{item.text}</LegendItemText>
              </LegendItem>
            ))}
        </MapLegend>
      </MapColumn>
    )}
    {!iconList && !mapType && (
      <UI.TextColumn>
        {!data.title && !iconList && data.showParts && (
          <PartsWrapper parts={parts} activePart={activePart} />
        )}
        {data.header && <UI.Header>{data.header}</UI.Header>}
        {data.paragraphs &&
          data.paragraphs.map((paragraph, index) => (
            <UI.Paragraph key={index}>{paragraph}</UI.Paragraph>
          ))}
        {data.elements && <BorderedList elements={data.elements} />}
        {data.counteredElements && <BorderedList elements={data.counteredElements} counter />}
        {data.textColumnIconUrl && (
          <UI.TextColumnIcon src={data.iconBaseUrl + data.textColumnIconUrl} />
        )}
        {data.italicParagraphs &&
          data.italicParagraphs.map((paragraph, index) => (
            <UI.ItalicParagraph key={index}>{paragraph}</UI.ItalicParagraph>
          ))}
        {data.bottomParagraphs &&
          data.bottomParagraphs.map((paragraph, index) => (
            <UI.Paragraph key={index}>{paragraph}</UI.Paragraph>
          ))}
        {data.title && data.showParts && (
          <PartsWrapper parts={parts} activePart={activePart} bottom />
        )}
      </UI.TextColumn>
    )}
  </UI.Container>
);

export default IconInColumnAndText;
