import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Container, Label, StripesIcon } from "./filtersToggle.styled";

export const FiltersToggle = ({ openFilters }) => {
  const { t } = useTranslation();
  return (
    <Container onClick={openFilters}>
      <Label>{t("learning.filters.filters.filters")}</Label>
      <StripesIcon />
    </Container>
  );
};

FiltersToggle.propTypes = {
  openFilters: PropTypes.func,
};
