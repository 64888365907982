import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../components/button/button.component";
import { ButtonColor } from "../../../../components/button/button.constants";

import { Container, TextBox, CheckIcon } from "./finishInfo.styled";

export const FinishInfo = ({ callComplete }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <TextBox>
        <CheckIcon />
        {t("interactions.quizQuestions.congratulations")}
      </TextBox>
      <Button onClick={callComplete} color={ButtonColor.Primary}>
        {t("interactions.quizQuestions.goNext")}
      </Button>
    </Container>
  );
};
