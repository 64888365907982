import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Color, Media } from "../../../../theme";
import { styleIfElse, styleWhenTrue } from "../../../../helpers/rendering";
import { ReactComponent as RecordingSVG } from "../../images/recording.svg";

export const RecordingIcon = styled(RecordingSVG)`
  width: 20px;
  margin-right: 7px;
  g {
    fill: ${styleIfElse(prop("recording"), Color.gray8, Color.gray7)}
  }

  ${styleWhenTrue(prop("isViewer"), css`
    margin: 0;
  `)}
`;

export const Text = styled.p`
  margin-right: auto;

  ${styleWhenTrue(prop("isViewer"), css`
    margin-right: 0;
    padding: 0 10px 0 0;
  `)}
`;

export const Container = styled.div`
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  z-index: 999;
  width: calc(100% - 40px);
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  border-radius: 3px;
  color: ${Color.deepGray};
  background-color: ${Color.white};
  g {
    fill: ${Color.error};
  }

  ${Media.tablet`
    position: static;
    width: 204px;
    margin: 10px;
    place-self: start end;
    grid-area: 1 / 1 / ${styleIfElse(prop("fullScreen"), 5, 4)} / 2;

    ${styleWhenTrue(prop("isViewer"), css`
      width: auto;
    `)}
  `}

  ${styleWhenTrue(prop("recording"), css`
    color: ${Color.white};
    background-color: ${Color.error};
    g {
      fill: ${Color.gray8};
    }
  `)}

  ${styleWhenTrue(prop("isViewer"), css`
    justify-content: space-between;
  `)}
`;

export const Button = styled.button`
  margin-left: 7px;
  padding: 0 0 0 10px;
  width: 20px;
  border: none;
  height: 100%;
  background: transparent;
  border-left: 1px solid ${styleIfElse(prop("recording"), Color.red6, Color.gray8)};
`;
