import { Color } from "../../theme";

export const TooltipProps = {
  tooltip: {
    sx: {
      backgroundColor: Color.black,
      zIndex: 10001,
      fontSize: 10,
    },
  },
  arrow: {
    sx: {
      color: Color.black,
    },
  },
};
