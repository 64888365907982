import styled, { css } from "styled-components";

import { ReactComponent as CupBase } from "../../../../images/cup.svg";
import { ReactComponent as TargetBase } from "../../../../images/target.svg";
import { hexToRgba } from "../../../../helpers/colors";
import { Color } from "../../../../theme/colors";
import { Media } from "../../../../theme/media";
import { FontWeight } from "../../../../theme/fonts";

export const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  ${Media.tablet`
    flex-direction: row;
  `}
`;

export const StatsBox = styled.div`
  background-color: ${Color.white};
  box-shadow: 0 5px 10px 0 ${hexToRgba(Color.deepGray, 0.01)};
  padding: 46px 35px 40px;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 180px;

  ${Media.desktop`
    min-width: 250px;
  `}
  
  &:last-of-type {
    margin-top: 20px;

    ${Media.tablet`
      margin-top: 0;
      margin-left: 2px;
    `}
  }
`;

export const StatsCounter = styled.p`
  font-size: 30px;
  line-height: 22px;
  color: ${() => Color.brandBasic};
  font-weight: ${FontWeight.SemiBold};
`;

export const StatsText = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${Color.deepGray};
  font-weight: ${FontWeight.SemiBold};
  margin-top: 8px;
`;

export const IconStyles = css`
  width: 40px;
  height: 40px;
  fill: ${Color.gray7};
  margin-right: 40px;
  flex-shrink: 0;
`;

export const CupIcon = styled(CupBase)`
  ${IconStyles}
`;

export const TargetIcon = styled(TargetBase)`
  ${IconStyles}
`;
