import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import { ArticleImage } from "../articleImage/articleImage.component";

import {
  Container,
  Title,
  Date,
  Footer,
  ReadMore,
} from "./latestArticle.styled";

export const LatestArticle = ({
  image,
  imagePositionX = 0,
  imagePositionY = 0,
  title,
  timestamp,
  id,
  className,
}) => {
  const { t } = useTranslation();
  const date = dayjs.unix(timestamp).format("DD MMMM YYYY, HH:mm");

  return (
    <Container className={className}>
      {!!image && (
        <ArticleImage src={image} alt="" positionX={imagePositionX} positionY={imagePositionY} />
      )}
      <Title>{title}</Title>
      <Footer>
        <Date>{date}</Date>
        <ReadMore to={`/news/${id}`}>{t("news.latestArticles.read")}</ReadMore>
      </Footer>
    </Container>
  );
};


LatestArticle.propTypes = {
  className: PropTypes.string,
};
