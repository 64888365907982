import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Modal } from "../../../../components/modal/modal.component";
import { ButtonColor } from "../../../../components/button/button.constants";
import { ReactComponent as WarningIcon } from "../../../../images/warning2.svg";
import { TrainingsActions } from "../../../trainings/redux/trainings.reducer";

import {
  WarningWrapper,
  ModalText,
  ButtonsWrapper,
  ModalButton,
} from "./disenrollPopup.styled";

export const DisenrollPopup = ({
  handleCloseModal,
  modalOpen,
  eventId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);

  const disenrollEvent = () => {
    setIsPending(true);
    dispatch(TrainingsActions.disenrollEvent(eventId, () => {
      dispatch(TrainingsActions.getEventDetails(eventId));
    }));
  };

  return (
    <Modal
      onClose={handleCloseModal}
      title={t("disenroll.resignation")}
      HeaderIcon={WarningIcon}
      open={modalOpen}
    >
      <WarningWrapper>
        <ModalText>
          {t("disenroll.modalText")}
        </ModalText>
      </WarningWrapper>
      <ButtonsWrapper>
        <ModalButton
          onClick={handleCloseModal}
          color={ButtonColor.Action}
          disabled={isPending}
        >
          {t("global.cancel")}
        </ModalButton>
        <ModalButton onClick={disenrollEvent} disabled={isPending}>
          {t("disenroll.confirm")}
        </ModalButton>
      </ButtonsWrapper>
    </Modal>
  );
};
