import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color, FontWeight } from "../../../../theme";
import { AnswerStatus } from "../../../interactions.constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  ${styleWhenTrue(prop("disabled"), css`
    pointer-events: none;
  `)}
`;

export const YourAnswerInfo = styled.p`
  text-transform: uppercase;
  margin-left: auto;
  font-weight: ${FontWeight.Bold};
  text-align: right;

  ${styleWhenTrue(propEq(AnswerStatus.CorrectChecked, "status"), css`
    color: ${Color.green};
  `)}

  ${styleWhenTrue(propEq(AnswerStatus.WrongChecked, "status"), css`
    color: ${Color.red};
  `)}
`;
