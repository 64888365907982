import { groupBy } from "ramda";
import { FilterValue } from "../components/filters/filters.constants";

export const groupFilters = (filters) => {
  if (!filters.length) return {};
  const byType = groupBy(({ type }) => type);
  const filtersGroupedByType = byType(filters);
  return Object.keys(filtersGroupedByType)
    .map((type) => ({
      [type]: filtersGroupedByType[type].reduce(
        (prev, curr) => [...prev, curr.value],
        []
      ),
    }))
    .reduce((prev, curr) => ({ ...prev, ...curr }), {});
};

export const filterItems = (filters, item, tagFilters) => {
  let filtersMatch = [];
  Object.keys(filters).forEach((filterType) => {
    const filter = filters[filterType];
    if (filter && filter.length) {
      const itemValue =
        item[filterType] === FilterValue.Video
          ? FilterValue.Contents
          : item[filterType];
      filtersMatch.push(
        filter.includes(itemValue) ||
          (filter.includes(FilterValue.Minutes) && !!item.minutes)
      );
    }
  });

  if (tagFilters?.length) {
    if (item.items) {
      const itemHasTag = tagFilters.some(
        (tag) => (
          !!item.items.some(
            (itemInGroup) => itemInGroup.tags.some((tagInItem) => tagInItem.id === tag.id)
          )
        )
      );
      if (!itemHasTag) {
        filtersMatch.push(tagFilters.some((tag) => !!item.tags?.find((itemTag) => itemTag.id === tag.id)));
      }
    } else {
      filtersMatch.push(
        tagFilters.some((tag) => !!item.tags?.find((itemTag) => itemTag.id === tag.id))
      );
    }
  }
  return !filtersMatch.includes(false);
};

export const countItems = (lists, filters, tagFilters) => {
  if (!lists.length) return 0;
  const filterList = (item) => filterItems(groupFilters(filters), item, tagFilters);
  return lists.reduce(
    (sum, list) => sum + list.items.filter(filterList).length,
    0
  );
};
