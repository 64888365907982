import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import { AppConfig } from "../../../../appConfig";
import { getAnimation } from "../../services/api";
import { TextBox } from "../textBox/textBox.component";
import { PageDataContext } from "../page/page.context";

import {
  Container,
  ContentWrapper,
  AnimationWrapper,
  ScrollButton,
  ArrowIcon,
  ButtonWrapper,
  Placeholder,
  StyledLottie,
} from "./animWithText.styled";

export const AnimWithText = ({
  className,
  withGradientBgStyle,
  onFloatClick,
  completed,
  callComplete,
  onScrollButtonClick,
}) => {
  const {
    assetSrc,
    placeholderSrc,
    fullSizeAnim,
    currentSlideCounter,
  } = useContext(PageDataContext);
  const [animationData, setAnimationData] = useState();
  const isHeader = currentSlideCounter === 1;

  useEffect(() => {
    if (!completed) {
      callComplete();
    }
  }, []);

  useEffect(() => {
    if (assetSrc) {
      getAnimation(assetSrc, setAnimationData);
    }
  }, [assetSrc]);

  return (
    <Container className={className}>
      <ContentWrapper $isFullAnim={fullSizeAnim} $withGradientBgStyle={withGradientBgStyle}>
        <AnimationWrapper>
          {!!animationData && (
            <StyledLottie
              animationData={animationData}
              loop
              rendererSettings={{
                preserveAspectRatio: "xMidYMid slice",
              }}
              $isFullAnim={fullSizeAnim}
            />
          )}
          {!!placeholderSrc && (
            <Placeholder src={`${AppConfig.content}${placeholderSrc}`} />
          )}
        </AnimationWrapper>
        <TextBox
          isHeader={isHeader}
          fluidHeight
          onFloatClick={onFloatClick}
        />
      </ContentWrapper>
      {isHeader && (
        <ButtonWrapper>
          <ScrollButton onClick={onScrollButtonClick}>
            <ArrowIcon />
          </ScrollButton>
        </ButtonWrapper>
      )}
    </Container>
  );
};

AnimWithText.propTypes = {
  className: PropTypes.string,
  withGradientBgStyle: PropTypes.bool,
  onFloatClick: PropTypes.func,
  callComplete: PropTypes.func,
  completed: PropTypes.bool,
  onScrollButtonClick: PropTypes.func,
};
