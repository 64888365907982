import React, { useMemo, useState, useEffect } from "react";
import { DateRange } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { pl, enGB } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { ConfirmationModal } from "../../../../../components/confirmationModal/confirmationModal.component";
import { TooltipPlacement } from "../../../../../components/tooltip/tooltip.constants";
import { Tooltip } from "../../../../../components/tooltip/tooltip.component";
import { useMediaListener } from "../../../../../hooks/useMediaListener";
import { currentLanguage } from "../../../../../helpers/translations";
import { ReactComponent as InfoIcon } from "../../../../../images/infoIcon2.svg";
import { Color } from "../../../../../theme";
import { GamificationActions } from "../../../redux/gamification.reducer";
import { selectCalendarMeetings } from "../../../redux/gamification.selectors";

import {
  Container,
  CustomDate,
  CustomDateSpan,
  TooltipContainer,
  TooltipSubtitle,
  TooltipTitle,
  MeetingTitle,
  MeetingInfo,
} from "./calendar.styled";

export const Calendar = () => {
  const { t } = useTranslation();
  const { isTablet } = useMediaListener();
  const locale = currentLanguage === "pl" ? pl : enGB;
  const dispatch = useDispatch();
  const brandColor = useMemo(() => Color.brandBasic, []);
  const meetings = useSelector(selectCalendarMeetings);
  const currentDate = new Date();
  const selection = meetings.map((meeting) => {
    const startDate = new Date(meeting.startedAt * 1000);
    const endDate = new Date(meeting.finishedAt * 1000);
    return {
      startDate,
      endDate,
      key: "selection",
      color: endDate > currentDate ? brandColor : Color.gray9,
      meetingName: meeting.name,
      meetingPlace: meeting.place,
    }});
  const [modalInfo, setModalInfo] = useState(null);

  useEffect(() => {
    dispatch(GamificationActions.getCalendarMeetings());

    return () => {
      dispatch(GamificationActions.clearCalendarMeetings());
    };
  }, []);

  const toggleMeetingPopup = (title, date, place) => {
    if (title || date || place) {
      setModalInfo({ title, date, place });
    } else {
      setModalInfo(null);
    }
  };

  return (
    <Container>
      <DateRange
        ranges={selection}
        shownDate={currentDate}
        onChange={() => {}}
        showMonthAndYearPickers={false}
        showDateDisplay={false}
        showPreview={false}
        locale={locale}
        dragSelectionEnabled={false}
        dayContentRenderer={(date) => {
          const meetingDay = selection.find(
            (selected) => {
              const startDate = new Date(selected.startDate);
              const endDate = new Date(selected.endDate);
              return startDate.setHours(0, 0, 0, 0) <= date && endDate.setHours(0, 0, 0, 0) >= date;
            }
          );
          if (meetingDay) {
            return (
              <Tooltip
                title={
                  <TooltipContainer>
                    <TooltipTitle>{meetingDay.meetingName}</TooltipTitle>
                    <TooltipSubtitle>
                      {t("gamification.calendar.time")}
                      {" "}
                      {dayjs(meetingDay.startDate).format("HH:mm")}
                    </TooltipSubtitle>
                    <TooltipSubtitle>
                      {t("gamification.calendar.place")}
                      {" "}
                      {meetingDay.meetingPlace}
                    </TooltipSubtitle>
                  </TooltipContainer>
                }
                placement={TooltipPlacement.Bottom}
              >
                <CustomDate
                  onClick={() => toggleMeetingPopup(
                    meetingDay.meetingName,
                    meetingDay.startDate,
                    meetingDay.meetingPlace
                  )}
                >
                  <CustomDateSpan>{date.getDate()}</CustomDateSpan>
                </CustomDate>
              </Tooltip>
            );
          }
        }}
      />
      {!isTablet && (
        <ConfirmationModal
          open={!!modalInfo}
          onClose={() => toggleMeetingPopup()}
          withoutDecline
          title={t("gamification.calendar.details")}
          confirmText={t("global.close")}
          HeaderIcon={InfoIcon}
        >
          <MeetingTitle>{modalInfo?.title}</MeetingTitle>
          <MeetingInfo>
            {t("gamification.calendar.time")}
            {" "}
            {modalInfo?.date ? dayjs(modalInfo.date).format("HH:mm") : ""}
          </MeetingInfo>
          <MeetingInfo>
            {t("gamification.calendar.place")}
            {" "}
            {modalInfo?.place}
          </MeetingInfo>
        </ConfirmationModal>
      )}
    </Container>
  );
};
