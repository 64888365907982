import { Breakpoint, DisclaimerVariant, IconName, Steps, UseState } from '@elearning-platform/ui';
import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import { Path } from '../../enums';
import LogoDark from '../../images/logo.svg';
import LogoLight from '../../images/logoLight.svg';

import { Step1 } from './components/step1/step1.component';
import { Step2 } from './components/step2/step2.component';
import BgDesktop from './images/bgDesktop.jpg';
import { RegistrationActions } from './redux/registration.actions';
import {
  BottomBar,
  Container,
  ContentWrapper,
  DesktopImage,
  Logo,
  MobileImage,
  StyledDisclaimer,
  TopBar,
} from './registration.styled';

export const Registration: React.FC = (): JSX.Element => {
  const dispatch: Dispatch<AnyAction> = useDispatch();
  const { t }: TransProps<never> = useTranslation();
  const isSmallDesktop: boolean = useMediaQuery({ query: Breakpoint.SmallDesktop });
  const [currentStep, setCurrentStep]: UseState<number> = useState<number>(1);

  useEffect((): void => {
    dispatch(RegistrationActions.fetchForm());
  }, [dispatch]);

  return (
    <Container>
      {!isSmallDesktop && currentStep === 1 && (
        <MobileImage>
          <TopBar $noBorder>
            <Link to={Path.Main}>
              <Logo src={LogoLight} />
            </Link>
            <Steps currentStep={currentStep} maxSteps={2} stepText={t('registration.topBar.step')} />
          </TopBar>
        </MobileImage>
      )}
      {(!!isSmallDesktop || currentStep === 2) && (
        <TopBar>
          <Link to={Path.Main}>
            <Logo src={LogoDark} />
          </Link>
          <Steps currentStep={currentStep} maxSteps={2} stepText={t('registration.topBar.step')} />
        </TopBar>
      )}
      <ContentWrapper>
        {!!isSmallDesktop && <DesktopImage src={BgDesktop} />}
        {currentStep === 1 ? (
          <Step1 goNext={(): void => setCurrentStep(2)} />
        ) : (
          <Step2 goBack={(): void => setCurrentStep(1)} />
        )}
      </ContentWrapper>
      <BottomBar>
        <StyledDisclaimer
          // TODO: change to internal links
          content={t('registration.disclaimer.content', { mainPath: Path.Main, remindPasswordPath: Path.RemindPassword })}
          icon={IconName.Flare}
          title={t('registration.disclaimer.title')}
          variant={DisclaimerVariant.Brand}
          vertical={!isSmallDesktop}
        />
      </BottomBar>
    </Container>
  );
};
