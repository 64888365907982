import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../helpers/rendering";
import { Media } from "../../theme/media";
import { Color } from "../../theme/colors";
import { Button } from "../button/button.component";

export const Container = styled.div`
  border-top: 1px solid ${Color.gray8};

  ${styleWhenTrue(prop("hidden"), css`
    display: none;
  `)}
`;

export const Comments = styled.div`
  background-color: ${Color.whiteHover};
  padding: 20px 20px 25px;

  ${Media.tablet`
    padding: 20px 30px 25px;
  `}
`;

export const CommentWrapper = styled.div`
  margin: 15px 0;
`;

export const HideCommentsButton = styled(Button)`
  width: 100%;
  margin-bottom: 10px;
`;
