import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { OnboardingMode } from "../../../../../app/app.constants";
import { selectOnboardingMode } from "../../../redux/onboarding.selectors";

import { Container, Stage } from "./stages.styled";

export const Stages = ({
  stages,
  activeStage,
  setActiveStage,
  onTutorialClick,
}) => {
  const { t } = useTranslation();
  const onboardingMode = useSelector(selectOnboardingMode);
  const isLeasinGoMode = onboardingMode === OnboardingMode.LeasinGo;

  return (
    <Container>
      <Stage onClick={onTutorialClick}>
        {t("onboarding.stages.intro")}
      </Stage>
      {stages.map((stage, index) => (
        <Stage
          key={stage.name}
          disabled={!stage.isEnabled}
          active={activeStage === index + 1}
          onClick={() => setActiveStage(index + 1)}
          isLastStage={activeStage === (isLeasinGoMode ? 3 : 5)}
        >
          {stage.name}
        </Stage>
      ))}
    </Container>
  );
};

Stages.propTypes = {
  stages: PropTypes.arrayOf(PropTypes.object),
  activeStage: PropTypes.number,
  setActiveStage: PropTypes.func,
};
