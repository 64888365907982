import styled, { css } from "styled-components";
import { prop } from "ramda";

import { ReactComponent as SearchIconBase } from "../../images/searchSmall.svg";
import { ReactComponent as CloseSvg } from "../../images/close2.svg";
import { styleWhenTrue } from "../../helpers/rendering";
import { Color, FontWeight, Media } from "../../theme";

export const SearchBarContainer = styled.div`
  max-width: 900px;
  height: ${({ height }) => height || "70px"};
  font-family: Open Sans;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Color.gray5};
  margin-top: 40px;

  ${Media.tablet`
    margin-top: 0;
    margin-right: 100px;
  `}
`;

export const SearchIcon = styled(SearchIconBase)`
  width: ${({ size }) => (size || 22) + "px"};
  height: ${({ size }) => (size || 22) + "px"};
  stroke: ${() => Color.brandBasic};
`;

export const SearchBarInput = styled.input`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "35px")};
  width: ${({ width }) => (width ? width : "calc(100% - 230px)")};
  font-style: normal;
  color: ${() => Color.brandBasic};
  padding-left: 20px;
  background: transparent;
  border: 0;
  margin-right: 10px;

  ::placeholder {
    color: ${Color.gray9};
  }
`;

export const SearchBarResults = styled.div`
  font-family: Open Sans;
  font-size: 13px;
  font-weight: normal;
  line-height: 2.69;
  text-align: left;
  color: ${Color.gray3};
`;

export const CloseButton = styled.button`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s;
  margin-left: auto;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: ${Color.transparent};

  ${Media.tablet`
    position: relative;
    left: 80px;
    top: auto;
    right: auto;
    background-color: ${Color.deepGray};

    &:hover {
      background-color: ${Color.gray9};
    }
  `}

  ${Media.desktop`
    left: 160px;
  `}
`;

export const CloseIcon = styled(CloseSvg)`
  fill: ${Color.deepGray};
  width: 14px;
  height: 14px;

  ${Media.tablet`
    fill: ${Color.white};
  `}
`;

export const ThumbnailRow = styled.div`
  margin-top: 40px;
  display: flex;
  max-width: 900px; 

  ${Media.tablet`
    margin-top: 30px;
  `}
`;

export const ThumbnailDetails = styled.div`
  display: flex;
  padding-top: 3px;
  width: ${({ width }) => (width ? width : "auto")};
  padding-left: 10px;

  ${Media.tablet`
    padding-top: 15px;
    padding-left: 40px;
  `}
`;

export const ThumbnailDetailsContent = styled.div`
  display: flex;
  flex-flow: column;
`;

export const ThumbnailTitle = styled.a`
  font-family: Open Sans;
  text-decoration: none;
  color: ${Color.deepGray};
  font-size: 14px;
  line-height: 18px;
  font-weight: ${FontWeight.SemiBold};
  cursor: pointer;
  
  ${Media.tablet`
    font-size: 16px;
  `}

  ${styleWhenTrue(prop("disabled"), css`
    cursor: default;
  `)}
`;

export const ThumbnailCategory = styled.div`
  font-family: Open Sans;
  font-size: 10px;
  line-height: 2;
  text-align: left;
  color: ${Color.gray3};
  display: flex;
  margin-top: 3px;
  align-items: center;
  text-transform: uppercase;
  font-weight: ${FontWeight.SemiBold};

  svg {
    margin-right:5px;
    width: 18px;
  }
`;

export const ThumbnailDescription = styled.p`
  font-weight: ${FontWeight.Light};
  font-size: 12px;
  line-height: 20px;
  margin-top: 6px;
  color: ${Color.deepGray};
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  
  ${Media.tablet`
    line-height: 22px;
    -webkit-line-clamp: 3;
    font-size: 14px;
  `}
`;

export const AuthorName = styled.div`
  font-weight: ${FontWeight.Normal};
  color: ${Color.gray9};
  line-height: 18px;

  ${Media.desktop`
    display: inline;
  `}
`;
