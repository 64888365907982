import React, { useCallback, useMemo } from "react";
import { cond, equals, always } from "ramda";
import { PieChart } from "react-minimal-pie-chart";
import { useTranslation } from "react-i18next";

import { Color } from "../../theme";
import { ChartIcon, ChartUnit } from "../progressChart/progressChart.constants";

import {
  Container,
  ChartWrapper,
  CenterWrapper,
  GoldMedalSvg,
  ColumnGraphSvg,
  MinutesValue,
  Data,
  DataItem,
  Dot,
  Value,
  Label,
  NoCupsTitle,
  NoCupsSubtitle,
} from "./ovalProgressChart.styled";

export const OvalProgressChart = ({
  data,
  icon,
  unit,
  value,
  placeholderVisible,
}) => {
  const { t } = useTranslation();
  const renderIcon = useCallback(cond([
    [equals(ChartIcon.GoldMedal), always(<GoldMedalSvg />)],
    [equals(ChartIcon.ColumnGraph), always(<ColumnGraphSvg />)],
  ]), []);
  const parsedData = useMemo(() => data?.map((item) => ({
    ...item,
    color: item.color === "brand" ? Color.brandBasic : item.color,
  })), [data]);

  if (!data) {
    return null;
  }

  return (
    <Container>
      <ChartWrapper>
        <PieChart
          data={parsedData}
          lineWidth={30}
          startAngle={90}
        />
        <CenterWrapper>
          {unit === ChartUnit.Minutes ? (
            <MinutesValue>{`${value} ${t("components.userStatistics.minutes")}`}</MinutesValue>
          ) : (
            renderIcon(icon)
          )}
        </CenterWrapper>
      </ChartWrapper>
      <Data>
        {placeholderVisible ? (
          <>
            <NoCupsTitle>{t("components.userStatistics.noCups")}</NoCupsTitle>
            <NoCupsSubtitle>{t("components.userStatistics.achieveCups")}</NoCupsSubtitle>
          </>
        ) : (
          parsedData.map((item) => (
            <DataItem key={item.color}>
              <Dot color={item.color} />
              <Value>
                {item.value}
                {unit === ChartUnit.Percent && "%"}
              </Value>
              <Label>{item.title}</Label>
            </DataItem>
          ))
        )}
      </Data>
    </Container>
  );
};
