import styled from "styled-components";

import { Color, Media } from "../../../../theme";
import { hexToRgba } from "../../../../helpers/colors";

export const Container = styled.div``;

export const Box = styled.div`
  padding: 35px 25px;
  background-color: ${Color.white};
  box-shadow: 0px 5px 10px ${hexToRgba(Color.deepGray, 0.01)};

  ${Media.tablet`
    padding: 35px;
  `}
`;

export const Description = styled.p`
  color: ${Color.deepGray};
  font-size: 15px;
  line-height: 26px;
`;
