import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { ButtonColor } from "../../../../components/button/button.constants";
import { renderWhenTrueOtherwise, renderWhenTrue } from "../../../../helpers/rendering";
import { Separator, isMobile } from "../../../../theme";
import {
  selectChatActivated,
  selectChatStartTimestamp,
  selectParticipants,
  selectMessages,
} from "../../redux/webinars.selectors";
import { Conversation } from "../conversation/conversation.component";
import { Timer } from "../timer/timer.component";
import { MessageField } from "../messageField/messageField.component";

import {
  Container,
  Participants,
  BoldText,
  PlaceholderWrapper,
  PlaceholderImg,
  PlaceholderText,
  Button,
  MobileWrapper,
  CloseButton,
  CloseIcon,
} from "./chat.styled";

export const Chat = ({
  className,
  fullScreen,
  cameraOn,
  secondCameraOn,
  liveEnded,
  classesMode,
  handleClose,
}) => {
  const { t } = useTranslation();
  const participants = useSelector(selectParticipants);
  const participantCounter = participants.filter(
    (participant) => !!participant.connected
  ).length;
  const chatActivated = useSelector(selectChatActivated);
  const startTimestamp = useSelector(selectChatStartTimestamp);
  const messages = useSelector(selectMessages);
  const [chatOpen, setChatOpen] = useState(false);
  const [unreadCounter, setUnreadCounter] = useState(0);
  const timeToActivation = startTimestamp - Date.now() / 1000;
  const days = Math.floor((timeToActivation / (60 * 60 * 24)) % 24);

  useEffect(() => {
    if (messages.length) {
      if (isMobile()) {
        setUnreadCounter((unreadCounter) => unreadCounter + 1);
      } else {
        setUnreadCounter(0);
      }
    }
  }, [messages]);

  const toggleChat = () => {
    setUnreadCounter(0);
    setChatOpen(!chatOpen);
  };

  const renderDays = () => {
    const text = days === 1 ? t("webinars.chat.tomorrow") : `${days} ${t("webinars.chat.days")}`;

    return (
      <PlaceholderText>{text}</PlaceholderText>
    );
  };

  const renderTimer = renderWhenTrueOtherwise(
    renderDays,
    () => <Timer startTimestamp={startTimestamp} />,
  );

  const renderCountDown = renderWhenTrue(() => renderTimer(!!days));

  const renderPlaceholder = () => {
    const message = startTimestamp
      ? days === 1
        ? t("webinars.chat.openingTomorrow")
        : t("webinars.chat.opening")
      : t("webinars.chat.inactive");

    return (
      <PlaceholderWrapper hidden={!startTimestamp}>
        <PlaceholderImg />
        <PlaceholderText>{message}</PlaceholderText>
        {renderCountDown(!!startTimestamp)}
      </PlaceholderWrapper>
    );
  };

  const renderMessageField = renderWhenTrue(() => <MessageField classesMode={classesMode} fullScreen={fullScreen} />);

  const renderContent = () => {
    const buttonText = chatOpen ? t("webinars.chat.close") : t("webinars.chat.open");
    const messageCounter = unreadCounter && !chatOpen ? ` (${unreadCounter})` : null;

    return (
      <>
        <Participants fullScreen={fullScreen}>
          <BoldText>
            {t(`webinars.${classesMode ? "chat.chat" : "participants"}`)}
          </BoldText>
          ({participantCounter})
          <Button
            small
            color={ButtonColor.Primary}
            onClick={toggleChat}
          >
            {buttonText}
            {messageCounter}
          </Button>
          {!!handleClose && (
            <CloseButton onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
          )}
        </Participants>
        <MobileWrapper mobileVisible={chatOpen} fullScreen={fullScreen}>
          <Separator $narrow />
          <Conversation
            fullScreen={fullScreen}
            cameraOn={cameraOn}
            messages={messages}
            classesMode={classesMode}
            liveEnded={liveEnded}
          />
          {renderMessageField(!liveEnded)}
        </MobileWrapper>
      </>
    );
  };

  const renderChat = renderWhenTrueOtherwise(
    renderContent,
    renderPlaceholder,
  );

  return (
    <Container
      className={className}
      inactive={!chatActivated}
      open={chatOpen}
      fullScreen={fullScreen}
      cameraOn={cameraOn}
      secondCameraOn={secondCameraOn}
    >
      {renderChat(chatActivated)}
    </Container>
  );
};

Chat.propTypes = {
  className: PropTypes.string,
  fullScreen: PropTypes.bool,
  cameraOn: PropTypes.bool,
  secondCameraOn: PropTypes.bool,
  liveEnded: PropTypes.bool,
  handleClose: PropTypes.func,
};
