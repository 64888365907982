export const BoxType = {
  Suggestion: "suggestion",
  Confirmation: "confirmation",
};

export const SuggestionData = {
  headerId: "competence.addSuggestion",
  infoId: "competence.suggestionInfo",
  buttonInfoId: "competence.suggestNew",
  buttonTextId: "competence.suggest",
};

export const ConfirmationData = {
  headerId: "competence.competenceTab.confirmations",
  infoId: "competence.competenceTab.confirmationsInfo",
  buttonInfoId: "competence.competenceTab.addText",
  buttonTextId: "competence.competenceTab.add",
};
