/* eslint-disable react/no-string-refs */
import React, { Component } from "react";

import { ElearningButton } from "../common.styled";

import { Container } from "./calculator.styled";

const messageOk = "Gratulujemy, Twój wynik jest poprawny, kliknij \"Obejrzałem\"."
const messageFail = ["Spróbuj ponownie.", "Nie udało Ci się. Przyjrzyj się, jak to powinno wyglądać."];
const fieldsMap = ["input1", "input2", "input3"];
const proper = [98820.74, 83333.81, 118.58];

export default class Calculator extends Component {
  constructor() {
    super();
    this.state = {
      elements: [],
      valid: false,
      attempts: 0,
      message: null,
      locked: false,
      forced: false
    };
  }

  forcedComplete() {
    this.setState({
      locked: true,
      valid: true,
      forced: true
    });
  }

  check() {
    if (!this.validate()) {
      this.setState({
        message: messageFail[Math.min(1, this.state.attempts)],
        attempts: (this.state.attempts + 1),
        valid: false
      });

      if (this.state.attempts >= 1) {
        fieldsMap.forEach((k, i) => this.refs[k].value = proper[i].toString().replace(".", ","));
        this.forcedComplete();
      }
    } else {
      this.setState({
        attempts: 0
      });
    }
  }

  validate() {
    const result = fieldsMap.filter((k, i)=> {
      const v = parseFloat(this.refs[k].value.replace(",", "."));
      return v === proper[i];
    });

    const valid = (result.length === proper.length);

    this.setState({
      valid: valid,
      message: valid ? messageOk : this.state.message,
      locked: valid ? true : this.state.locked
    });

    return this.state.valid;
  }

  render() {
    const actionClassnames = ["elearning-calc__actions", !this.state.valid || this.state.forced ? "elearning-calc__actions--failed" : "elearning-calc__actions--ok"].join(" ");
    return (
      <Container>
        <div className="elearning-calc__task">
          <div>
            Wylicz koszt leasingu uwzględniając poniższe dane, a otrzymane wartości wpisz do poniższej formuły i
            sprawdź swój wynik.<br />
            <div className="row"><span className="large">Cena auta brutto:</span> <span>100 000 zł</span></div>
            <div className="row"><span className="large">Cena auta netto:</span> <span>81 300,81 zł</span></div>
            <div className="row"><span className="large">Opłata wstępna:</span> <span>20%</span></div>
            <div className="row"><span className="large">Wykup:</span> <span>1%</span></div>
            <div className="row"><span className="large">Okres leasingu:</span> <span>48 m-cy</span></div>
            <div className="row"><span className="large">Wysokość raty netto:</span> <span>1739,31</span></div>
            <div className="row"><span className="large">Składka ubezpieczenia bankowego włączona do finansowania:</span><span>2033 zł</span></div>
            <div className="row">
              <strong>Podpowiedź do obliczeń:</strong> <span className="large">Ilość rat</span> <span>= okres leasingu -1</span>
            </div>
          </div>
        </div>
        <form>
          <div className="elearning-calc__col">
            <span>Koszt leasingu =</span>
          </div>
          <div className="elearning-calc__col">
            <div className="elearning-calc__input">
              <input type="text" ref="input1" onChange={this.validate.bind(this)} disabled={this.state.locked}/>
            </div>
            <div className="elearning-calc__label">
              <span>suma opłat leasingowych</span>
              <span>format xxxxx,xx</span>
            </div>
          </div>
          <div className="elearning-calc__col">
            <span className="elearning-calc__col--scaled">/</span>
          </div>
          <div className="elearning-calc__col">
            <div className="elearning-calc__input">
              <input type="text" ref="input2" onChange={this.validate.bind(this)} disabled={this.state.locked}/>
            </div>
            <div className="elearning-calc__label">
              <span>cena netto z ubezpieczeniami</span>
              <span>format xxxxx,xx</span>
            </div>
          </div>
          <div className="elearning-calc__col">
            <span>* 100% =</span>
          </div>
          <div className="elearning-calc__col">
            <div className="elearning-calc__input">
              <input type="text" ref="input3" onChange={this.validate.bind(this)} disabled={this.state.locked}/>
            </div>
            <div className="elearning-calc__label">
              <span>Twój wynik</span>
              <span>format xxx,xx%</span>
            </div>
          </div>
        </form>
        <div className={actionClassnames}>
          <ElearningButton disabled={this.state.locked} onClick={this.check.bind(this)}>Sprawdź wynik</ElearningButton>
          <div>
            <p>{this.state.message}</p>
          </div>
          <ElearningButton disabled={!this.state.valid} onClick={this.props.callComplete}>
            Obejrzałem
          </ElearningButton>
        </div>
      </Container>
    )
  }
}
