import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { useMediaListener } from "../../../../../hooks/useMediaListener";
import { SimpleProgressChart } from "../../../../../components/simpleProgressChart/simpleProgressChart.component";
import { SectionHeader } from "../../../../../theme";
import {
  selectBadgesAchieved,
  selectBadgesOverall,
  selectBadgesList,
} from "../../../redux/gamification.selectors";
import { GamificationActions } from "../../../redux/gamification.reducer";
import { Badge } from "../badge/badge.component";

import {
  Container,
  CounterWrapper,
  Counter,
  CounterLabel,
  BadgesList,
  LeftArrowButton,
  RightArrowButton,
  ArrowIcon,
} from "./badges.styled";

export const Badges = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useMediaListener();
  const badgesAchieved = useSelector(selectBadgesAchieved);
  const badgesOverall = useSelector(selectBadgesOverall);
  const badgesList = useSelector(selectBadgesList);
  const badgesLimit = useMemo(() => isMobile ? 1 : 2, [isMobile]);
  const maxPage = useMemo(() => isMobile
    ? badgesList.length - 1
    : (badgesList.length - ((badgesList.length + 1) % 2) - 1) / 2,
  [isMobile, badgesList]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    dispatch(GamificationActions.fetchUserBadges());
  }, [dispatch, isMobile]);

  useEffect(() => {
    if (badgesList.length && currentPage > maxPage) {
      setCurrentPage(maxPage);
    }
  }, [isMobile, badgesList]);

  const increaseCurrentPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const decreaseCurrentPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <Container className={className}>
      <SectionHeader>{t("gamification.badges.badges")}</SectionHeader>
      <CounterWrapper>
        <SimpleProgressChart value={badgesAchieved} maxValue={badgesOverall} />
        <CounterLabel>
          <Counter>
            {`${badgesAchieved} / ${badgesOverall}`}
          </Counter>
          {" "}
          {t("gamification.badges.availableBadges")}
        </CounterLabel>
      </CounterWrapper>

      <BadgesList>
        {badgesList
          .slice(currentPage * badgesLimit, currentPage * badgesLimit + badgesLimit)
          .map(({ achievement, gainDate }) => (
            <Badge
              key={achievement.label}
              title={achievement.label}
              subtitle={achievement.description}
              id={achievement.enum}
              done={!!gainDate}
            />
          ))
        }
        {currentPage > 0 && (
          <LeftArrowButton onClick={decreaseCurrentPage}>
            <ArrowIcon />
          </LeftArrowButton>
        )}
        {currentPage < maxPage && (
          <RightArrowButton onClick={increaseCurrentPage}>
            <ArrowIcon />
          </RightArrowButton>
        )}
      </BadgesList>
    </Container>
  );
};

Badges.propTypes = {
  className: PropTypes.string,
};
