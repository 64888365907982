import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";
import { Link as LinkBase } from "react-router-dom";

import { StarsRate as StarsRateBase } from "../../../../components/starsRate/starsRate.component";
import {
  ProgressWithChildren as ProgressWithChildrenBase,
} from "../../../../components/progressWithChildren/progressWithChildren.component";
import { DefaultButtonStyles, OutlinedButtonStyles } from "../../../../components/button/button.styled";
import { SimpleProgressChart } from "../../../../components/simpleProgressChart/simpleProgressChart.component";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { hexToRgba } from "../../../../helpers/colors";
import { ReactComponent as ArrowSvg } from "../../../../images/arrow.svg";
import { ReactComponent as GoldMedalBase } from "../../../../images/goldMedal.svg";
import { ReactComponent as SilverMedalBase } from "../../../../images/silverMedal.svg";
import { ReactComponent as BronzeMedalBase } from "../../../../images/bronzeMedal.svg";
import { ReactComponent as BaseClockIcon } from "../../../../images/clock.svg";
import {
  Color,
  FontWeight,
  Media,
  Separator as SeparatorBase,
} from "../../../../theme";

export const Container = styled.div`
  background-color: ${Color.white};
  margin-bottom: 3px;
  box-shadow: 0 5px 10px ${hexToRgba(Color.deepGray, 0.01)};
  padding: 0;
`;

export const ClockIcon = styled(BaseClockIcon)`
  width: 28px;
  height: 28px;
  background-color: ${Color.gray1};
  border-radius: 50%;
  margin-right: 20px;
`;

export const Header = styled.button`
  border: none;
  background-color: ${Color.transparent};
  padding: 30px 23px 30px 20px;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;

  ${Media.tablet`
    padding: 45px 43px 45px 35px;
  `}
`;

export const TitleWrapper = styled.div`
  margin: 0 18px;

  ${Media.tablet`
    margin: 0 28px;
  `}
`;

export const Icons = styled.div`
  display: flex;
  margin-left: auto;
`;

export const Title = styled.p`
  font-size: 16px;
  font-weight: ${FontWeight.SemiBold};
  line-height: 20px;
  color: ${Color.deepGray};
`;

export const Date = styled.p`
  font-size: 13px;
  line-height: 17px;
  color: ${Color.gray9};
  margin-top: 4px;
`;

export const ArrowWrapper = styled.div`
  transform: rotate(-90deg);
`;

export const ArrowIcon = styled(ArrowSvg)`
  width: 10px;
  height: 16px;
  fill: ${Color.gray9};
  transition: transform 0.3s ease-in-out;

  ${styleWhenTrue(prop("open"), css`
    transform: rotateY(180deg);
  `)}
`;

export const Content = styled.div`
  opacity: 0;
  height: 0;
  transition: opacity 0.3s ease-in-out;
  overflow: hidden;

  ${styleWhenTrue(prop("open"), css`
    height: auto;
    padding: 0 20px 60px;
    opacity: 1;

    ${Media.tablet`
      padding: 0 115px 60px;
    `}
  `)}
`;

export const ContentHeader = styled.p`
  font-weight: ${FontWeight.ExtraBold};
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

export const Separator = styled(SeparatorBase)`
  && {
    margin: 30px 0;

    ${Media.mobile`
      margin: 20px 0;
    `}
  }
`;

export const TestRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${Media.mobile`
    flex-wrap: nowrap;
  `}
`;

export const TestDate = styled.p`
  background-color: ${() => hexToRgba(Color.brandBasic, 0.1)};
  padding: 6px 24px;
  border-radius: 17px;
  font-size: 10px;
  line-height: 20px;
  margin-right: 0;
  margin-bottom: 20px;
  color: ${() => Color.brandBasic};
  font-weight: ${FontWeight.Bold};

  ${Media.mobile`
    margin-right: 20px;
    margin-bottom: 0;
  `}

  ${Media.desktop`
    margin-right: 49px;
  `}
`;

export const Chart = styled(SimpleProgressChart)`
  margin-right: 10px;
  flex-shrink: 0;
`;

export const ChartWrapper = styled.div`
  display: flex;
  margin: 0 0 20px auto;
  align-items: center;

  ${Media.mobile`
    margin: 0 20px 0 0;
  `}

  ${Media.desktop`
    margin: 0 49px 0 0;
  `}

  ${styleWhenTrue(prop("hidden"), css`
    visibility: hidden;
  `)}
`;

export const CorrectAnswers = styled.p`
  font-size: 12px;
  line-height: 22px;
`;

export const Link = styled(LinkBase)`
  ${DefaultButtonStyles}
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 100%;

  ${styleWhenTrue(propEq("true", "outlined"), css`
    ${OutlinedButtonStyles}
  `)}

  ${Media.mobile`
    width: auto;
  `}
`;

export const ProgressWithChildren = styled(ProgressWithChildrenBase)``;

export const OverallResult = styled.span`
  font-weight: ${FontWeight.SemiBold};
  font-size: 11px;
  color: ${Color.almostBlack};
`;

export const MedalStyles = css`
  flex-shrink: 0;
  margin-right: 10px;
  width: 24px;
  height: 24px;

  ${styleWhenTrue(propEq("true", "large"), css`
    width: 50px;
    height: 50px;
  `)}
`;

export const GoldMedal = styled(GoldMedalBase)`
  ${MedalStyles}
`;

export const SilverMedal = styled(SilverMedalBase)`
  ${MedalStyles}
`;

export const BronzeMedal = styled(BronzeMedalBase)`
  ${MedalStyles}
`;

export const StarsRate = styled(StarsRateBase)``;
