import styled, { css } from "styled-components";
import { prop } from "ramda";

import { ReactComponent as ListSvg } from "../../images/list.svg";
import { hexToRgba } from "../../helpers/colors";
import { styleWhenTrue } from "../../helpers/rendering";
import { Color, FontWeight, Media } from "../../theme";

export const IconWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${() => Color.brandBasic};
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 15px;
  transition: transform 0.3s ease-in-out;
`;

export const Container = styled.button`
  background-color: ${Color.white};
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  border: none;
  padding: 0 30px 0 0;
  text-align: left;
  width: ${({ $textWidth }) => 150 + $textWidth}px;
  max-width: calc(100% - 30px);
  transition: all 0.3s ease-in-out;
  z-index: 2;
  box-shadow: 0 2px 4px ${hexToRgba(Color.darkGray, 0.2)}, 0 0 20px ${hexToRgba(Color.almostBlack, 0.03)};

  &:hover {
    ${IconWrapper} {
      transform: scale(1.2);
    }
  }

  ${styleWhenTrue(prop("$fixed"), css`
    position: fixed;
    right: 15px;
    bottom: 15px;
  `)}

  ${styleWhenTrue(prop("hidden"), css`
    opacity: 0;
  `)}

  ${Media.tablet`
    max-width: unset;
  `}
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const Counter = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${Color.darkGray};
  flex-shrink: 0;
`;

export const Bold = styled.span`
  color: ${Color.black};
  font-weight: ${FontWeight.SemiBold};
`;

export const Title = styled(Counter)`
  margin-left: 10px;
  transition: width 2s;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 215px;

  ${Media.tablet`
    max-width: unset;
  `}
`;

export const ListIcon = styled(ListSvg)`
  width: 22px;
  height: 16px;
  fill: ${Color.white};
`;
