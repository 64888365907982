import React, { Fragment } from "react";

import SingleChoiceAnswer from "../singleChoiceAnswer/singleChoiceAnswer.component";
import MultipleChoiceAnswer from "../multipleChoiceAnswer/multipleChoiceAnswer.component";
import TrueFalseAnswer from "../trueFalseAnswer/trueFalseAnswer.component";
import { QuestionType } from "../quizQuestions.constants";

import { Container } from "./answers.styled";

const Answers = ({
  className,
  type,
  answers,
  checkedAnswerIDs,
  checkedTrueFalseStatementIDs,
  areAnswersConfirmed,
  isResultsView,
  disabled,
  onAnswerSelect,
}) => {
  return (
    <Container className={className} disabled={disabled}>
      {answers.map((answer) => (
        <Fragment key={answer.id}>
          {type === QuestionType.SingleChoice && (
            <SingleChoiceAnswer
              data={answer}
              checked={checkedAnswerIDs.includes(answer.id)}
              confirmed={areAnswersConfirmed}
              onSelect={onAnswerSelect}
            />
          )}
          {type === QuestionType.MultipleChoice && (
            <MultipleChoiceAnswer
              data={answer}
              checked={checkedAnswerIDs.includes(answer.id) || answer.checked}
              confirmed={areAnswersConfirmed || isResultsView}
              isResultsView={isResultsView}
              onSelect={onAnswerSelect}
            />
          )}
          {type === QuestionType.TrueFalse && (
            <TrueFalseAnswer
              data={answer}
              trueSelected={checkedAnswerIDs.includes(answer.id)}
              buttonsTouched={checkedTrueFalseStatementIDs.has(answer.id)}
              confirmed={areAnswersConfirmed}
              onSelect={onAnswerSelect}
            />
          )}
        </Fragment>
      ))}
    </Container>
  );
};

export default Answers;
