import styled from "styled-components";

import { colors, breakpoints } from "../../../UIGlobals";
import backgroundImage from "../../assets/bg.jpg";

const InteractionDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    height: 670px;
    background-image: url(${backgroundImage});
    background-position: center;
    background-size: cover;
    color: ${colors().white};
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const TaskScreen = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    @media (${breakpoints().maxL}) {
    display: none;
    }
`;

const HeaderDiv = styled.div`
    width: 30%;
`;

const Header = styled.h3`
    margin-bottom: 20px;
    color: ${colors().white};
    width: 100%;
    line-height: 30px;
    font-size: 24px;
    &:first-child {
    font-weight: 700;
  }
`;

const MainButton = styled.button`
    width: 200px;
    height: 46px;
    background: ${colors().aquamarine};
    border: none;
    color: ${colors().blue};
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
`;

const ItemsDiv = styled.div`
    width: 70%;
`;

const Item = styled.div`
    display: flex;
    border: 1px solid ${colors().white};
    color: ${colors().white};
    font-size: 14px;
    margin: 0 15px;
    line-height: 20px;
    height: 88px;
`;

const ItemContent = styled.div`
    padding: 20px;
    display: flex;
    align-items: center;
`;

const ItemArrows = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-left: 1px solid ${colors().white};
    margin-left: auto;
    padding: 15px;
`;

const ArrowButton = styled.button`
    background: transparent;
    border: 0;
    cursor: pointer;
`;

const Separator = styled.div`
    color: ${colors().aquamarine};
    border-top: 3px dashed #6ce6ef;
    padding: 15px;
    margin-top: 15px;
    text-align: center;
    font-weight: 700;
`;

const Mobile = styled.div`
    display: none;
    @media (${breakpoints().maxL}) {
    display: flex;
        flex-direction: column;
        max-width: 260px;
  }
`;

const ResultScreenDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    h3 {
        text-align: center;
    }
`;

export {
  InteractionDiv,
  Container,
  TaskScreen,
  HeaderDiv,
  Header,
  MainButton,
  ItemsDiv,
  Item,
  ItemContent,
  ItemArrows,
  ArrowButton,
  Separator,
  Mobile,
  ResultScreenDiv
};