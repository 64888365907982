import styled from "styled-components";

import { ReactComponent as TrashSvg } from "../../../../images/trash.svg";
import { ReactComponent as DownloadSvg } from "../../../../images/download.svg";
import { hexToRgba } from "../../../../helpers/colors";
import {
  FontWeight,
  Color,
  Media,
  Separator as SeparatorBase,
} from "../../../../theme";
import { ReactComponent as DocumentSvg } from "../../images/document.svg";

export const Container = styled.div`
  margin-top: 20px;
  background-color: ${Color.white};
  box-shadow: 0px 5px 10px ${hexToRgba(Color.deepGray, 0.01)};
  padding: 0 20px;

  ${Media.tablet`
    padding: 0 35px;
  `}
`;

export const Table = styled.table`
  width: 100%;
`;

export const TableHead = styled.thead``;

export const TableBody = styled.tbody``;

export const HeaderRow = styled.tr`
  border-bottom: 2px solid ${Color.almostWhite};
`;

export const Header = styled.th`
  font-weight: ${FontWeight.ExtraBold};
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.92px;
  color: ${Color.gray9};
  text-transform: uppercase;
  text-align: left;
  padding: 14px 0;

  ${Media.tablet`
    padding: 14px 20px 14px 0;
    min-width: 100px;
  `}
`;

export const ItemRow = styled.tr`
  border-bottom: 1px solid ${Color.gray5};

  &:last-of-type {
    border-bottom: none;
  }
`;

export const Cell = styled.td`
  font-size: 14px;
  line-height: 22px;
  padding: 13px 0;
  vertical-align: middle;
  color: ${Color.gray9};

  &:first-of-type {
    display: flex;
    align-items: center;
    color: ${Color.deepGray};
  }

  &:last-of-type {
    text-align: right;
  }

  ${Media.tablet`
    padding: 35px 20px 35px 0;
    font-size: 15px;
    line-height: 20px;
  `}
`;

export const DocumentIcon = styled(DocumentSvg)`
  fill: ${() => Color.brandBasic};
  margin-right: 25px;
  flex-shrink: 0;
`;

export const DeleteButton = styled.button`
  border: none;
  background-color: ${Color.transparent};
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

export const TrashIcon = styled(TrashSvg)`
  width: 14px;
  height: 18px;
  fill: ${() => Color.brandBasic};
`;

export const Separator = styled(SeparatorBase)``;

export const MobileHeader = styled.td`
  font-size: 10px;
  line-height: 14px;
  font-weight: ${FontWeight.ExtraBold};
  letter-spacing: 0.6px;
  color: ${Color.gray9};
  text-transform: uppercase;
  vertical-align: middle;
`;

export const FileWrapper = styled.a`
  display: flex;
  width: 25px;
  margin-left: auto;

  ${Media.tablet`
    margin-left: 0;
  `}
`;

export const DownloadIcon = styled(DownloadSvg)`
  width: 25px;
  height: 25px;
  background-color: ${() => Color.brandBasic};
  padding: 7px;
  border-radius: 3px;
  fill: ${Color.white};
`;
