import React, { useState } from "react";

import { MainPopup } from "./components/mainPopup/mainPopup.component";
import { DetailsPopup } from "./components/detailsPopup/detailsPopup.component";
import { CookieName, CookiePopupLocalStorageKey } from "./cookiePopup.constants";

export const CookiePopup = () => {
  const popupAcceptedCookie = localStorage.getItem(CookiePopupLocalStorageKey);
  const [popupAccepted, setPopupAccepted] = useState(popupAcceptedCookie);
  const [detailsView, setDetailsView] = useState(false);

  const handleSetPopupAccepted = () => {
    localStorage.setItem(CookiePopupLocalStorageKey, true);
    localStorage.setItem(CookieName.Necessary, true);
    setPopupAccepted(true);
  };

  const handleAcceptCookies = (acceptedAll) => {
    if (acceptedAll) {
      localStorage.setItem(CookieName.Functional, true);
      localStorage.setItem(CookieName.Analytical, true);
    } else {
      localStorage.removeItem(CookieName.Functional);
      localStorage.removeItem(CookieName.Analytical);
    }

    handleSetPopupAccepted();
  };

  if (popupAccepted) return null;

  if (detailsView) {
    return <DetailsPopup acceptCookies={handleAcceptCookies} setPopupAccepted={handleSetPopupAccepted} />;
  }

  return <MainPopup setDetailsView={setDetailsView} acceptCookies={handleAcceptCookies} />;
};
