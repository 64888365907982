import React from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Tooltip";

import { TooltipPlacement } from "./tooltip.constants";
import { TooltipProps } from "./tooltip.styled";

export const Tooltip = ({ children, className, title, placement = TooltipPlacement.Top }) => {
  return (
    <Container
      className={className}
      title={title}
      placement={placement}
      arrow
      componentsProps={TooltipProps}
    >
      {children}
    </Container>
  );
};

Tooltip.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  placement: PropTypes.string,
};
