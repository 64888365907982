import styled, { css } from "styled-components";

import { ListHeader as ListHeaderBase } from "../../../../../UIElements";
import { breakpoints } from "../../../../../UIGlobals";

export const ListHeader = styled(ListHeaderBase)`
  margin-bottom: 35px;
  margin-top: ${({ marginTop }) => marginTop}px;

  @media (${breakpoints().maxM}) {
    padding: 0 27px;
  }
`;

const createIEGrid = (columns) => {
  const styles = [];

  const gridElementsNumber = 6;
  const isSingleColumnGrid = columns === 1;
  const isTwoColumnsGrid = columns === 2;
  const isThreeColumnsGrid = columns === 3;

  for (let i = 1; i <= gridElementsNumber; i++) {
    const multiGridRow = isTwoColumnsGrid ? Math.ceil(i / 2) : isThreeColumnsGrid ? Math.ceil(i / 3) : null;
    const gridRow = isSingleColumnGrid ? i : multiGridRow;
    const multiGridColumn = isTwoColumnsGrid ? 2 - (i % 2) : isThreeColumnsGrid ? 1 + ((i - 1) % 3) : null;
    const gridColumn = isSingleColumnGrid ? 1 : multiGridColumn;
    styles.push(`
      &:nth-child(${i}) {
        width: calc(100% - 2px);
        margin-bottom: 2px;
        -ms-grid-row: ${gridRow};
        -ms-grid-column: ${gridColumn};
      }
    `);
  }

  return css`
    ${styles.join(" ")}
  `;
};

export const Grid = styled.div`
  display: grid;
  display: -ms-grid;
  width: 100%;
  ${({ marginBottom }) => marginBottom && "margin-bottom: " + marginBottom + "px"};
  grid-gap: 2px;
  grid-template-columns: ${({ threeColumns }) => `repeat(${threeColumns ? 3 : 2}, minmax(222px, 1fr))`};
  -ms-grid-columns: (minmax(222px, 1fr))[${({ threeColumns }) => threeColumns ? 3 : 2}];

  & > div {
    ${createIEGrid(2)}
  }

  ${({ threeColumns }) => threeColumns && `
    & > div {
      ${createIEGrid(3)}
    }
  `}

  @media (${breakpoints().maxL}) {
    grid-template-columns: repeat(2, minmax(250px, 1fr));
    -ms-grid-columns: (minmax(250px, 1fr))[2];

    & > div {
      ${createIEGrid(2)}
    }
  }

  @media (${breakpoints().maxS}) {
    grid-template-columns: repeat(1, minmax(250px, 1fr));
    -ms-grid-columns: minmax(250px, 1fr);

    & > div {
      ${createIEGrid(1)}
    }
  }
`;
