import { all, put, takeLatest } from "redux-saga/effects";

import * as anonSurveyApi from "../services/api";

import { AnonSurveyTypes, AnonSurveyActions } from "./anonSurvey.reducer";

function* getQuestions({ token }) {
  yield put(AnonSurveyActions.setIsPending(true));
  const data = yield anonSurveyApi.getQuestions(token);

  if (data.data) {
    yield put(AnonSurveyActions.getQuestionsSuccess(data.data.pages));
  } else if (data.status === 404) {
    yield put(AnonSurveyActions.setNotFoundPage());
  } else {
    yield put(AnonSurveyActions.sendAnswersSuccess());
  }

  yield put(AnonSurveyActions.setIsPending(false));
}

function* sendAnswers({ token, answers }) {
  yield put(AnonSurveyActions.setIsPending(true));
  const success = yield anonSurveyApi.sendAnswers(token, answers);

  if (success) {
    yield put(AnonSurveyActions.sendAnswersSuccess());
  }

  yield put(AnonSurveyActions.setIsPending(false));
}

export function* watchAnonSurvey() {
  yield all([
    yield takeLatest(AnonSurveyTypes.GET_QUESTIONS, getQuestions),
    yield takeLatest(AnonSurveyTypes.SEND_ANSWERS, sendAnswers),
  ]);
}
