import { phpApi, handleApiError } from "../../api";
import { stateToData } from "../userRegistration.utils";

export const getFormData = (token) => {
  return phpApi.get(`/api/user/register${token ? `/${token}` : ""}`).catch(handleApiError);
};

export const sendFormData = (token, formData) => {
  return phpApi.post(
    `/api/user/register${token ? `/${token}` : ""}`,
    stateToData(formData),
  ).catch((error) => error);
};

export const setPassword = (token, password) => {
  return phpApi.post(
    "/api/user/set-password",
    { token, password },
    { removeDataBody: true },
  ).catch((error) => error.response);
};

export const remindPassword = (login) => {
  return phpApi.post("/api/user/remind-password", { login }).catch(handleApiError);
};
