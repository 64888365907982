import styled from "styled-components";

export const Container = styled.div`
  width: 854px;
  height: 500px;
  margin: 50px auto;
  padding: 20px;
  text-align: center;
  position: relative;

  & .btn-pos-abs {
    position: absolute;
    right: 10px;
    bottom: 0px;
  }
`;
