import styled, { css } from "styled-components";
import ReactScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";
import { prop } from "ramda";

import { hexToRgba } from "../../../../helpers/colors";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color, Media } from "../../../../theme";

export const Container = styled.div`
  width: 100%;

  ${Media.tabletWide`
    overflow: hidden;
    margin: 0 -30px;
    width: calc(100% + 60px);
  `}

  @media (min-width: 1260px) {
    margin: 0;
    width: 100%;
  }
`;

export const BoardWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${hexToRgba(Color.gray1, 0.1)};
  padding: 32px 0;

  ${Media.tablet`
    margin-top: 40px;
  `}

  ${Media.tabletWide`
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin: 40px -25px 0;

    ${styleWhenTrue(prop("leasinGoMode"), css`
      margin: 40px 0 0;
      padding: 32px 100px;
    `)}
  `}

  ${Media.desktopWide`
    padding: 32px 31px 32px 32px;
    margin: 40px 0 0;

    ${styleWhenTrue(prop("leasinGoMode"), css`
      padding: 32px 100px;
    `)}
  `}
`;

export const ScrollProvider = styled(ReactScrollIntoViewIfNeeded)`
  margin-top: 20px;

  & + & {
    margin-top: 4px;
  }
`;
