import styled from "styled-components";

import { FontWeight, Color, Media } from "../../../../../theme";
import { Button as ButtonBase } from "../../../../../components/button/button.component";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 500px;

  ${Media.tablet`
    height: 600px;
  `}
`;

export const TextWrapper = styled.div`
  margin-top: 100px;
  width: 100%;

  ${Media.tablet`
    margin-top: 30px;
    flex: 90%;
    display: flex;
    align-items: center;
  `}
`;

export const Text = styled.h1`
  font-weight: ${FontWeight.Bold};
  font-size: 20px;
  line-height: 27px;
  width: 100%;
  text-align: left;

  ${Media.tablet`
    font-size: 26px;
    line-height: 40px;
    text-align: center;  
  `}
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  ${Media.tablet`
    flex: 10%;
    flex-direction: row;
    justify-content: flex-end;
  `}
`;

export const OutlinedButton = styled(ButtonBase)`
  border-radius: 0;
  width: 100%;
  border: 4px solid ${Color.blue};
  background-color: ${Color.white};
  color: ${Color.blue};

  ${Media.tablet`
    width: 200px;
  `}
`;
