import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { Route, Routes, useLocation, useNavigate } from "react-router";

import { useModuleConfig, ModuleType } from "../";

import { CertificateList } from "./components/certificateList/certificateList.component";
import { CertificatePath } from "./components/certificatePath/certificatePath.component";
import { Container } from "./certificates.styled";

const Certificates = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const config = useModuleConfig(ModuleType.Certificates);

  useEffect(() => {
    if (config.urls.includes(location.pathname)) {
      navigate(config.routes.init, { replace: true });
    }
  }, [location.pathname]);

  return (
    <ThemeProvider theme={config.theme}>
      <Container>
        <Routes>
          <Route path={config.routes.init} exact element={<CertificateList />} />
          <Route path={config.routes.certificatePath} exact element={<CertificatePath />} />
        </Routes>
      </Container>
    </ThemeProvider>
  );
};

export default Certificates;
