import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Loader } from "../../../../components/loader/loader.component";
import { Separator } from "../../../../theme";
import { TrainingsActions } from "../../redux/trainings.reducer";
import { selectTrainerMetric } from "../../redux/trainings.selectors";

import {
  Container,
  Header,
  Stats,
  Description,
  CloseButton,
  CloseIcon,
  LoaderContainer,
} from "./trainerPopup.styled";

export const TrainerPopup = ({ onClose, trainerId }) => {
  const dispatch = useDispatch();
  const trainer = useSelector(selectTrainerMetric);

  useEffect(() => {
    dispatch(TrainingsActions.fetchTrainerMetric(trainerId));
  }, []);

  return (
    <Container>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      {trainer ? (
        <>
          <Header
            avatarUrl={trainer.image}
            name={`${trainer.name} ${trainer.surname}`}
            email={trainer.email}
          />
          <Separator $narrow />
          <Stats
            yearsOfExperience={trainer.workedYears}
            trainingsDone={trainer.trainingsCount}
            specializations={trainer.specializations}
          />
          <Separator $narrow />
          <Description aboutMe={trainer.aboutMe} awards={trainer.awards} />
        </>
      ) : (
        <LoaderContainer>
          <Loader size={50} />
        </LoaderContainer>
      )}
    </Container>
  );
};

TrainerPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  trainerId: PropTypes.number.isRequired,
};
