import React, { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { timestampToDate, timestampToWeekday } from "../../../../helpers/date";
import {
  selectIncomingEventTitle,
  selectIncomingEventStartTimestamp,
  selectIncomingEventId,
} from "../../redux/dashboard.selectors";
import { IncomingEventEmpty } from "../incomingEventEmpty/incomingEventEmpty.component";

import {
  Container,
  Header,
  Dot,
  HeaderText,
  Title,
  StartDate,
  DateWrapper,
  CalendarIcon,
} from "./incomingEvent.styled";

export const IncomingEvent = ({ className, topWidgetEnabled }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const id = useSelector(selectIncomingEventId);
  const title = useSelector(selectIncomingEventTitle);
  const startTimestamp = useSelector(selectIncomingEventStartTimestamp);
  const weekDay = useMemo(() => timestampToWeekday(startTimestamp), [startTimestamp]);
  const startIn = useMemo(() => timestampToDate(startTimestamp), [startTimestamp]);

  const handleOnClick = useCallback(() => {
    navigate(`/szkolenia/${id}`);
  }, [navigate, id]);

  if (!id) {
    return <IncomingEventEmpty topWidgetEnabled={topWidgetEnabled} />;
  }

  return (
    <Container
      className={className}
      $topWidgetEnabled={topWidgetEnabled}
      onClick={handleOnClick}
    >
      <Header>
        <Dot />
        <HeaderText>{t("dashboard.dashboard.upcomingCourse")}</HeaderText>
      </Header>
      <Title>{title}</Title>
      <DateWrapper>
        <StartDate>{weekDay}</StartDate>
        <StartDate>{startIn}</StartDate>
      </DateWrapper>
      <CalendarIcon />
    </Container>
  );
};

IncomingEvent.propTypes = {
  className: PropTypes.string,
  topWidgetEnabled: PropTypes.bool,
};
