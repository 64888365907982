import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Container,
  TextWrapper,
  Counter,
  Title,
  Bold,
  IconWrapper,
  ListIcon,
} from "./float.styled";

export const Float = ({
  className,
  currentSection,
  sectionsOverall,
  title,
  fixed,
  onClick,
  hidden,
}) => {
  const [textWidth, setTextWidth] = useState(0);
  const titleRef = useRef();

  useEffect(() => {
    if (titleRef && titleRef.current) {
      setTextWidth(titleRef.current.clientWidth);
    }
  }, [titleRef, title]);

  return (
    <Container
      className={className}
      $textWidth={textWidth}
      onClick={onClick}
      $fixed={fixed}
      hidden={hidden}
    >
      <IconWrapper>
        <ListIcon />
      </IconWrapper>
      <TextWrapper>
        <Counter>
          <Bold>{currentSection + 1}</Bold>
          {" "}
          ({sectionsOverall})
        </Counter>
        <Title ref={titleRef}>{title}</Title>
      </TextWrapper>
    </Container>
  );
};

Float.propTypes = {
  className: PropTypes.string,
  currentSection: PropTypes.number.isRequired,
  sectionsOverall: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  fixed: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
};
