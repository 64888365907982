import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";

import { Loader } from "../../../../../components/loader/loader.component";
import {
  renderWhenTrue,
  renderWhenTrueOtherwise,
} from "../../../../../helpers/rendering";
import { Checkbox } from "../../../../../components/checkbox/checkbox.component";
import { videoType, maxVideoSize } from "../uploadVideoModal.constants";

import {
  Container,
  CircleIconWrapper,
  UploadIcon,
  Box,
  Text,
  Subtext,
  FileInput,
  FileInputLabel,
  HiddenVideo,
  HiddenCanvas,
  Alert,
  AlertText,
  CloseIcon,
  CheckboxContainer,
} from "./modalContentStep1.styled";

export const ModalContentStep1 = ({
  setFrames,
  setStep,
  setVideo,
  checkboxState,
  setCheckboxState,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [videoSrc, setVideoSrc] = useState();
  const [error, setError] = useState(null);
  const videoFileRef = useRef();
  const canvasRef = useRef();
  let tempFrames = [];

  const drawFrame = () => {
    const canvas = canvasRef.current;
    const video = videoFileRef.current;
    const offset = Math.floor(video.duration) / 3;
    video.pause();
    canvas.getContext("2d").drawImage(video, 0, 0, 200, 200);
    tempFrames.push(canvas.toDataURL());
    if (tempFrames.length < 6) {
      video.currentTime += offset;
    } else {
      setFrames(tempFrames.slice(3));
      cleanCaptureFrame();
      setStep(2);
    }
  };

  const initCaptureFrame = () => {
    const canvas = canvasRef.current;
    const video = videoFileRef.current;
    canvas.width = 200;
    canvas.height = 200;
    video.play();
  };

  const cleanCaptureFrame = () => {
    const video = videoFileRef.current;
    URL.revokeObjectURL(video.src);
    video.removeEventListener("loadedmetadata", initCaptureFrame);
    video.removeEventListener("timeupdate", drawFrame);
    setIsLoading(false);
  };

  // TODO, update those when designs for handling errors will be available
  const handleValidateVideo = (video) => {
    if (video.type !== videoType) {
      setError(t("dashboard.uploadVideo.videoTypeValidation"));
      return false;
    }
    if (video.size > maxVideoSize) {
      setError(t("dashboard.uploadVideo.videoSizeValidation"));
      return false;
    }
    return true;
  };

  const handleUploadVideo = (tempVideo) => {
    setError(null);
    const isValid = handleValidateVideo(tempVideo);
    if (!isValid) {
      return;
    }
    setIsLoading(true);
    setVideo(tempVideo);
    setVideoSrc(URL.createObjectURL(tempVideo));
    const video = videoFileRef.current;
    video.muted = true;
    video.addEventListener("loadedmetadata", initCaptureFrame);
    video.addEventListener("timeupdate", drawFrame);
  };

  const closeAlert = () => setError(null);

  const handleCheckboxClick = () => setCheckboxState(!checkboxState);

  const renderAgreementCheckbox = () => (
    <CheckboxContainer>
      <Checkbox
        label={t("dashboard.uploadVideo.clause")}
        onChange={handleCheckboxClick}
        checked={checkboxState}
      />
    </CheckboxContainer>
  );

  const BoxContent = () => (
    <>
      <Dropzone onDrop={(acceptedFiles) => handleUploadVideo(acceptedFiles[0])}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div {...getRootProps()}>
            <CircleIconWrapper isDragActive={isDragActive}>
              <input {...getInputProps()} />

              <UploadIcon />
            </CircleIconWrapper>
          </div>
        )}
      </Dropzone>
      <Text>{t("dashboard.uploadVideo.dropVideo")}</Text>
      <Subtext>{t("dashboard.uploadVideo.selectTitleAndThumbnail")}</Subtext>
      <FileInput
        id="dragndrop"
        name="dragndrop"
        type="file"
        accept={videoType}
        onChange={(event) => handleUploadVideo(event.target.files[0])}
      />
      <FileInputLabel disabled={!checkboxState} htmlFor="dragndrop">
        {t("dashboard.uploadVideo.selectFile")}
      </FileInputLabel>
    </>
  );

  const renderBoxContent = renderWhenTrueOtherwise(
    () => <BoxContent />,
    () => <Loader />
  );

  const renderErrorAlert = renderWhenTrue(() => (
    <Alert>
      <AlertText>{error}</AlertText>
      <CloseIcon onClick={closeAlert} />
    </Alert>
  ));

  return (
    <>
      {renderErrorAlert(!!error)}

      <Container>
        <Box>
          <HiddenCanvas ref={canvasRef} />
          {renderBoxContent(!isLoading)}
          <HiddenVideo ref={videoFileRef} src={videoSrc} />
        </Box>
        {renderAgreementCheckbox()}
      </Container>
    </>
  );
};

ModalContentStep1.propTypes = {
  setFrames: PropTypes.func,
  setStep: PropTypes.func,
  setVideo: PropTypes.func,
  checkboxState: PropTypes.bool,
  setCheckboxState: PropTypes.func,
};
