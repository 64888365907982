import styled, { css } from "styled-components";
import { propEq } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color } from "../../../../theme";
import { AnswerStatus } from "../../../interactions.constants";

export const Container = styled.button`
  background-color: ${Color.transparent};
  border: 1px solid ${Color.dirtyWhite};
  padding: 25px 20px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 15px;

  ${styleWhenTrue(propEq(AnswerStatus.Checked, "status"), css`
    border: 1px solid ${Color.blue5};
    background-color: ${Color.blue6};
  `)}

  ${styleWhenTrue(propEq(AnswerStatus.CorrectChecked, "status"), css`
    border: 2px solid ${Color.green};
    background-color: ${Color.lightGreen};
  `)}

  ${styleWhenTrue(propEq(AnswerStatus.WrongChecked, "status"), css`
    border: 2px solid ${Color.red};
    background-color: ${Color.lightPink};
  `)}

  ${styleWhenTrue(propEq(AnswerStatus.WrongUnchecked, "status"), css`
    border: 1px solid ${Color.lightGreen};
    background-color: ${Color.lightGreen};
  `)}
`;
