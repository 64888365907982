import React from "react";
import PropTypes from "prop-types";

import {
  Container,
  SurveyIcon,
  Title,
  ArrowIcon,
} from "./surveyItem.styled";

export const SurveyItem = ({ className, title, id }) => (
  <Container className={className} to={`/formularze/${id}`}>
    <SurveyIcon />
    <Title>{title}</Title>
    <ArrowIcon />
  </Container>
);

SurveyItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.number,
};
