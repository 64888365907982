import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { renderWhenTrue } from "../../../../../helpers/rendering";
import { AppConfig } from "../../../../../appConfig";
import { Title, ButtonRow } from "../../onboardingInteractions.styled";
import { NextButton } from "../nextButton/nextButton.component";
import { InfoTip } from "../infoTip/infoTip.component";

import { Container, Tile, TileButton } from "./downloadTiles.styled";

const Tiles = ({ tiles }) => {
  const { t } = useTranslation();

  const handleDownloadClick = (fileUrl) => {
    window.open(`${AppConfig.content}${fileUrl}`, "_blank");
  }

  return tiles.map(({ text, fileUrl }) => (
    <Tile key={text}>
      {text}
      <TileButton onClick={() => handleDownloadClick(fileUrl)}>
        {t("downloadTiles.downloadTiles.downloadFile")}
      </TileButton>
    </Tile>
  ))
};

export const DownloadTiles = ({ title, buttonText, next, tiles, infotipText }) => {
  const renderInfoTip = renderWhenTrue(() => (
    <InfoTip sentences={infotipText} />
  ));

  return (
    <Container>
      {renderInfoTip(!!infotipText)}
      <Title>{title}</Title>
      <Tiles tiles={tiles} />
      <ButtonRow>
        <NextButton
          onClick={next}
          text={buttonText}
        />
      </ButtonRow>
    </Container>
  )};

DownloadTiles.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  onNextButtonClick: PropTypes.func,
  tiles: PropTypes.array,
};
