import { all, put, takeLatest } from "redux-saga/effects";

import { GamificationActions } from "../../gamification/redux/gamification.reducer";
import * as newsApi from "../services/api";

import { NewsTypes, NewsActions } from "./news.reducer";

function* getEntries({ nextPage }) {
  yield put(NewsActions.setAreEntriesPending(true));
  const { data } = yield newsApi.getNewsList(nextPage);
  yield put(NewsActions.getEntriesSuccess(data.data, data.nextPage));
  yield put(NewsActions.setAreEntriesPending(false));
}

function* clearEntries() {
  yield put(NewsActions.clearEntriesSuccess());
}

function* getEntry({ id }) {
  yield put(NewsActions.setIsEntryPending(true));
  const { data } = yield newsApi.getEntry(id);

  if (data) {
    yield put(NewsActions.getEntrySuccess(data));
  } else {
    yield put(NewsActions.getEntryFailure());
  }

  yield put(NewsActions.setIsEntryPending(false));
}

function* deleteEntry({ id }) {
  yield put(NewsActions.setIsEntryPending(true));
  const { data } = yield newsApi.deleteEntry(id);
  yield put(NewsActions.deleteEntrySuccess(data));
}

function* savePost({ post, onFinish = Function.prototype }) {
  yield put(NewsActions.setIsSavePending(true));

  const { data } = yield newsApi.createPost(post);

  onFinish();
  yield put(NewsActions.saveEntrySuccess(data.data));
  yield put(NewsActions.setIsSavePending(false));
}

function* editPost({ post, onFinish = Function.prototype }) {
  yield put(NewsActions.setIsSavePending(true));
  const { data } = yield newsApi.editPost(post);
  onFinish();
  yield put(NewsActions.editEntrySuccess(data));
  yield put(NewsActions.setIsSavePending(false));
}

function* saveArticle({ article, onFinish = Function.prototype }) {
  yield put(NewsActions.setIsSavePending(true));

  const { data } = yield newsApi.createArticle(article);
  onFinish();
  yield put(NewsActions.saveEntrySuccess(data.data));
  yield put(NewsActions.setIsSavePending(false));
}

function* editArticle({ id, article, onFinish = Function.prototype }) {
  yield put(NewsActions.setIsSavePending(true));
  const { data } = yield newsApi.editArticle(id, article);
  onFinish();
  yield put(NewsActions.editEntrySuccess(data));
  yield put(NewsActions.setIsSavePending(false));
}

function* saveComment({ comment, isGamification, onFinish = Function.prototype }) {
  yield put(NewsActions.setIsSavePending(true));
  const { data } = yield newsApi.createComment(comment, isGamification);
  onFinish();
  if (isGamification) {
    yield put(GamificationActions.getEntryDetailsSuccess(data.data));
  } else {
    yield put(NewsActions.saveCommentSuccess(data));
  }
  yield put(NewsActions.setIsSavePending(false));
}

function* editComment({ comment }) {
  const { data } = yield newsApi.editComment(comment);
  yield put(NewsActions.editCommentSuccess(data));
}

function* deleteComment({ id }) {
  const { data } = yield newsApi.deleteComment(id);
  yield put(NewsActions.deleteCommentSuccess(data));
}

function* likeEntry({ id, likeState, isGamification }) {
  const { data } = yield newsApi.likeEntry(id, likeState, isGamification);
  if (isGamification) {
    yield put(GamificationActions.getEntryDetailsSuccess(data.data));
  } else {
    yield put(NewsActions.likeEntrySuccess(data));
  }
}

function* getLatestArticles() {
  yield put(NewsActions.setAreLatestPending(true));
  const { data } = yield newsApi.getLatestArticles();
  yield put(NewsActions.getLatestArticlesSuccess(data.data));
  yield put(NewsActions.setAreLatestPending(false));
}

function* getLatestActivities() {
  yield put(NewsActions.setAreActivitiesPending(true));
  const { data } = yield newsApi.getLatestActivities();
  yield put(NewsActions.getLatestActivitiesSuccess(data.data));
  yield put(NewsActions.setAreActivitiesPending(false));
}

export function* watchNews() {
  yield all([
    takeLatest(NewsTypes.GET_ENTRIES, getEntries),
    takeLatest(NewsTypes.GET_LATEST_ARTICLES, getLatestArticles),
    takeLatest(NewsTypes.GET_ENTRY, getEntry),
    takeLatest(NewsTypes.GET_LATEST_ACTIVITIES, getLatestActivities),
    takeLatest(NewsTypes.CLEAR_ENTRIES, clearEntries),
    takeLatest(NewsTypes.SAVE_POST, savePost),
    takeLatest(NewsTypes.EDIT_POST, editPost),
    takeLatest(NewsTypes.SAVE_ARTICLE, saveArticle),
    takeLatest(NewsTypes.EDIT_ARTICLE, editArticle),
    takeLatest(NewsTypes.SAVE_COMMENT, saveComment),
    takeLatest(NewsTypes.EDIT_COMMENT, editComment),
    takeLatest(NewsTypes.DELETE_COMMENT, deleteComment),
    takeLatest(NewsTypes.LIKE_ENTRY, likeEntry),
    takeLatest(NewsTypes.DELETE_ENTRY, deleteEntry),
  ]);
}
