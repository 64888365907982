import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Button } from "../../../../components/button/button.component";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color, FontWeight, Media } from "../../../../theme";
import BgImg from "../../images/woman-with-car.jpg";

export const Container = styled.div`
  height: 230px;
  background-image: url(${BgImg});
  background-size: cover;
  background-position: left center;
  margin-top: 20px;
  padding: 30px;
  display: flex;
  align-items: flex-end;

  ${styleWhenTrue(prop("$topWidgetEnabled"), css`
    margin-bottom: 20px;
  `)}

  ${Media.tablet`
    margin-top: 0;
    padding: 25px;
  `}

  ${Media.desktop`
    margin-bottom: 0;

    ${styleWhenTrue(prop("$topWidgetEnabled"), css`
      margin-top: 20px;
    `)}
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 285px;
`;

export const Text = styled.p`
  font-size: 20px;
  line-height: 1.2;
  font-weight: ${FontWeight.Bold};
  color: ${Color.white};
  text-shadow: 0 0 10px ${Color.gray6};
`;

export const StyledButton = styled(Button)`
  width: fit-content;
  background-color: ${Color.white};
`;
