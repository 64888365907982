import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Icon } from "../../../../components/icon/icon.component";
import { selectParticipants } from "../../redux/webinars.selectors";
import { BroadcastType } from "../video/video.constants";

import { Container, Text, InfoIcon } from "./playInfo.styled";

export const PlayInfo = ({ className }) => {
  const { t } = useTranslation();
  const participants = useSelector(selectParticipants);
  const presenter = participants.find((participant) => participant.role === BroadcastType.Presenter);
  const isCurrentUserPresenter = presenter && presenter.user.isCurrentUser;

  return (
    <Container className={className} hidden={!isCurrentUserPresenter}>
      <Icon>
        <InfoIcon />
      </Icon>
      <Text>{t("webinars.playInfo")}</Text>
    </Container>
  );
};

PlayInfo.propTypes = {
  className: PropTypes.string,
};
