import React from "react";
import i18n from "i18next";

import TaskScreen from "./taskScreen.js";
import ResultScreen from "./resultScreen.js";
import { InteractionDiv } from "./trueFalseOrderUI";

const TASK = "task";
const SUCCESS = "success";
const FAILURE = "failure";

const FAILURE_MESSAGES = [
  "SPRÓBUJ JESZCZE RAZ!", "NIE, TO NIE TAK", "ZNOWU POMYŁKA",
  "PRZYŁÓŻ SIĘ BARDZIEJ", ["NIE WIESZ, SPRAWDŹ", "W MATERIAŁACH SZKOLENIOWYCH"]
];

class TrueFalseOrder extends React.Component {
  state = {
    screen: TASK,
    failureMessages: FAILURE_MESSAGES,
    loading: true,
    sentences: null
  };

  componentDidMount() {
    fetch(`${this.props.baseUrl}${this.props.config}`)
      .then(response =>
        response.json().then(resp =>
          this.setState({
            sentences: resp.sentences,
            loading: false
          })
        )
      );
  }

  render() {
    return (
      <InteractionDiv>
        { this.state.loading ? i18n.t("lesson.slide.slide.loading") : this.renderContent() }
      </InteractionDiv>
    );
  }

  renderContent() {
    const { screen } = this.state;

    switch (screen) {
    case TASK:
      return (<TaskScreen sentences={this.state.sentences} onSuccess={this.screenChanger(SUCCESS)} onFailure={this.onFailure} />);
    case SUCCESS:
      return (<ResultScreen message="SUPER!!!" action="Dalej" onAction={this.passAndProceed} />);
    case FAILURE:
      return (<ResultScreen message={this.state.failureMessage} action="Wróć" onAction={this.screenChanger(TASK)} />);
    default:
      return null;
    }
  }

  screenChanger = (screen) => () => this.setState({ screen });

  onFailure = () => {
    const { failureMessages: [message, ...rest] } = this.state;

    this.setState({
      screen: FAILURE,
      failureMessage: message,
      failureMessages: rest.length > 0 ? rest : [message]
    });
  }

  passAndProceed = () => {
    this.props.callComplete();
  }
}

export default TrueFalseOrder;
