import GameAnim from "../../../anims/game.json";
import CoinAnim from "../../../anims/coin.json";
import DiamondAnim from "../../../anims/diamond.json";
import BadgeAnim from "../../../anims/badge.json";

export const AnimName = {
  Game: "game",
  Coin: "coin",
  Diamond: "diamond",
  Badge: "badge",
};

export const Anim = {
  [AnimName.Game]: GameAnim,
  [AnimName.Coin]: CoinAnim,
  [AnimName.Diamond]: DiamondAnim,
  [AnimName.Badge]: BadgeAnim,
};
