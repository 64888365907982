import styled from "styled-components";

import { Media } from "../../../theme";

export const Container = styled.div`
  padding: 25px 25px 0;

  ${Media.tabletWide`
    padding: 0;
  `}
`;
