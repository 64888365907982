import React, { Component } from "react";
import MediaQuery from "react-responsive";
import i18n from "i18next";

import { breakpoints } from "../../../UIGlobals";
import { ElearningButton } from "../common.styled";

import { Container } from "./selectArea.styled";

const messageOk = "Sukces!";
const infoText = "Za chwilę rozpocznie się quiz. Zapoznaj się z poleceniem i klikając wybierz odpowiednią ścieżkę.";
const messageFail = ["Spróbuj ponownie.", "Nie udało Ci się", "To nie tak, spróbuj jeszcze raz."];
const tipBase = "Aby przejść dalej wybierz ";

const maxAttempts = 3;

export default class SelectArea extends Component {
  constructor() {
    super();
    this.state = {
      valid: false,
      attempts: null,
      step: 1,
      message: null,
      locked: false,
      forced: false,
      loading: true,
    };
  }

  componentDidMount() {
    fetch(this.props.baseUrl + this.props.config)
      .then(response => response.json())
      .then(jresp => {
        this.initialState = {
          startInfo: jresp.instruction,
          data: jresp.data
        };
        this.setState(this.initialState, () => {
          this.setState({
            right_answer: this.state.data[0].right_answer,
            bg_img: this.state.data[0].bg_img,
            boxStyle: this.state.data[0].boxStyle,
            stepsSize: this.state.data.length
          }, () => {
            this.setState({
              loading: false
            });
            this.prepareData();
          })
        });
      });
  }

  onInfoTextNextClick() {
    const testarray = document.getElementsByClassName("modal__infoText");
    for (let i = 0; i < testarray.length; i++) {
      testarray[i].classList.add("modal__hidden");
    }
  }

  onModalInfoNextClick() {
    const testarray = document.getElementsByClassName("modal__info");
    for (let i = 0; i < testarray.length; i++) {
      testarray[i].classList.add("modal__hidden");
    }
  }

  forceEnd() {
    var testarray = document.getElementsByClassName("modal__success");
    for (var i = 0; i < testarray.length; i++) {
      testarray[i].classList.add("modal__hidden");
    }
    this.props.callComplete();
    this.showBgImg(0);
  }

  propNewData() {
    this.setState({
      attempts: 0
    });

    if (typeof this.state.data[this.state.step] !== "undefined") {
      this.showBgImg(this.state.step);
      this.setState({
        boxStyle: this.state.data[this.state.step].boxStyle,
        right_answer: this.state.data[this.state.step].right_answer
      });
    } else {
      this.setState({
        locked: true,
        valid: true
      });
      this.initSuccessModal();
    }
  }

  increaseStep() {
    this.setState({
      step: (this.state.step + 1)
    });
  }

  showBgImg(index) {
    this.setState({
      bg_img: this.props.baseUrl + "assets/" + this.state.data[index].bg_img
    });
  }

  prepareData() {
    this.showBgImg(this.state.step - 1);
  }

  initSuccessModal() {
    this.setState({
      message: messageOk
    });
    var testarray = document.getElementsByClassName("modal__success");
    for (var i = 0; i < testarray.length; i++) {
      testarray[i].classList.remove("modal__hidden");
    }
  }

  rightAnswerEvent() {
    this.increaseStep();
    this.propNewData();
  }

  getRandomInt(xmin, xmax) {
    return Math.floor(Math.random() * (xmax + 1 - xmin) + xmin);
  }

  increaseAttempts() {
    this.setState({
      attempts: this.state.attempts + 1
    });
  }

  wrongAnswerEvent() {
    this.increaseAttempts();
    if (this.state.attempts >= maxAttempts) {
      this.setState({
        message: tipBase + this.state.right_answer
      });
    } else {
      this.setState({
        message: messageFail[this.getRandomInt(0, 2)],
        valid: false
      });
    }
    var testarray = document.getElementsByClassName("modal__wrong");
    for (var i = 0; i < testarray.length; i++) {
      testarray[i].classList.remove("modal__hidden");
    }

  }

  hideModal() {
    var testarray = document.getElementsByClassName("modal__wrong");
    for (var i = 0; i < testarray.length; i++) {
      testarray[i].classList.add("modal__hidden");
    }
  }

  render() {
    const height = this.state.loading ? null : this.state.boxStyle.height + "px";
    const width = this.state.loading ? null : this.state.boxStyle.width + "px";
    const top = this.state.loading ? null : this.state.boxStyle.top + "px";
    const left = this.state.loading ? null : this.state.boxStyle.left + "px";
    return (

      this.state.loading ? i18n.t("lesson.slide.slide.loading") :
        (
          <Container>
            <MediaQuery query={`(${breakpoints().maxL})`}>
              <div className="mobile-error">
                <p>Quiz nie jest dostępny na wersji mobilnej platformy Volkswagen Financial Services</p>
                <ElearningButton onClick={this.props.callComplete}>
                  Obejrzałem
                </ElearningButton>
              </div>

            </MediaQuery>
            <MediaQuery query={`(${breakpoints().minL})`}>
              <div className="window">
                <img className="bg-img" src={this.state.bg_img} onClick={this.wrongAnswerEvent.bind(this)} alt="" />
                <div style={{ height: height, width: width, top: top, left: left }} id="hitbox" className="hitbox hitbox__example" onClick={this.rightAnswerEvent.bind(this)} >
                </div>
              </div>
              <div className="modal modal__wrong modal__hidden">
                <h2>{this.state.message}</h2>
                <button onClick={this.hideModal}>wróć</button>
              </div>

              <div className="modal modal__success modal__hidden">
                <h2>{this.state.message}</h2>
                <button onClick={this.forceEnd.bind(this)} >Przejdź dalej</button>
              </div>

              <div className="modal modal__info">
                <h3>{this.state.startInfo}</h3>
                <button className="button__next" onClick={this.onModalInfoNextClick}>Dalej</button>
              </div>

              <div className="modal modal__infoText">
                <h3>{infoText}</h3>
                <button className="button__next" onClick={this.onInfoTextNextClick}>Dalej</button>
              </div>
            </MediaQuery>
          </Container>
        )
    )
  }
}