import styled, { css } from "styled-components";
import { prop } from "ramda";

import { BackButton as UpperBackBtn } from "../../../../components/backButton/backButton.component";
import { Button as ButtonBase } from "../../../../components/button/button.component";
import { Loader as LoaderBase } from "../../../../components/loader/loader.component";
import { ReactComponent as PlaySvg } from "../../../../images/play.svg";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { hexToRgba } from "../../../../helpers/colors";
import { Color, Media } from "../../../../theme";

export const Container = styled.div`
  ${styleWhenTrue(prop("$withBgColor"), css`
    background-color: ${Color.almostWhite};
  `)}
`;

export const VideoBox = styled.div`
  max-width: 980px;
  margin: 0 auto;
  padding: 30px 0 25px;

  ${styleWhenTrue(prop("noPadding"), css`
    padding: 0;
  `)}

  ${Media.tablet`
    padding: 30px 20px;

    ${styleWhenTrue(prop("noPadding"), css`
      padding: 0;
    `)}
  `}
`;

export const UpperSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 20px;
`;

export const VideoStyles = css`
  width: 100%;
  background-color: ${Color.black};

  ${Media.mobile`
    height: 350px;
  `}

  ${Media.tablet`
    height: ${({ isScrollContent }) => isScrollContent ? "365px" : "529px"};
    max-height: ${({ isScrollContent }) => isScrollContent ? "365px" : "529px"};
  `}
`;

export const Video = styled.video`
  ${VideoStyles}

  ${styleWhenTrue(prop("$timelineDisabled"), css`
    &::-webkit-media-controls-timeline {
      pointer-events: none;
    }
  `)}

  ${Media.mobile`
    ${styleWhenTrue(prop("$fullscreen"), css`
      height: 100%;
      max-height: unset;
    `)}
  `}

  ${Media.tablet`
    ${styleWhenTrue(prop("$fullscreen"), css`
      height: 100%;
      max-height: unset;
    `)}
  `}
`;

export const VideoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${styleWhenTrue(prop("$fullscreen"), css`
    height: calc(100% - 61px);
  `)}
`;

export const PlayButton = styled.button`
  background-color: ${() => Color.brandBasic};
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  position: absolute;
  box-shadow: 0 4px 4px 0 ${hexToRgba(Color.brandBasic, 0.1)},
    0 0 10px 0 ${hexToRgba(Color.brandBasic, 0.1)};

  ${styleWhenTrue(prop("hidden"), css`
    display: none;
  `)}
`;

export const PlayIcon = styled(PlaySvg)`
  fill: ${Color.white};
  position: relative;
  left: 2px;
  width: 16px;
  height: 16px;
`;

export const FinishScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: ${Color.white};
  min-height: 200px;
  ${VideoStyles}
`;

export const BackButton = styled(ButtonBase)`
  margin-top: 20px;
`;

export const UpperBackButton = styled(UpperBackBtn)``;

export const Loader = styled(LoaderBase)`
  position: absolute;
`;

export const FullscreenWrapper = styled.div``;
