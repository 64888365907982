import React, { Component } from "react";
import i18n from "i18next";

import { AppConfig } from "../../../appConfig";
import { ElearningButton } from "../common.styled";

import { Container } from "./download.styled";

export default class Download extends Component {
  constructor() {
    super();
    this.state = { elements: [] };
  }

  componentDidMount() {
    fetch(this.props.baseUrl + this.props.config)
      .then(response => response.json())
      .then(jresp => {
        this.initialState = {
          elements: jresp.elements,
          boxStyle: jresp.elements[0].boxStyle
        };

        this.setState(this.initialState);
      });
  }

  imageHtml(index, element) {
    const elementStyle = {
      left: element.pos.left,
      top: element.pos.top,
      position: element.pos.position
    };
    const delay = element.delay !== undefined ? element.delay + "s" : "0." + index * 2 + "s";

    const AnimationClass = "bg-img slide-img " + (element.className !== undefined ? element.className : "show1");

    const imageStyle = {
      animationDelay: delay
    };
    return (
      <div key={index} className="col " style={elementStyle}>
        <a href={
          (element.source.substr(0,4) === "http" || element.source.substr(0,5) === "https")
            ? element.source
            : AppConfig.content + element.source
        }
        target="_blank"
        rel="noopener noreferrer"
        >
          {" "}
          <img
            className={AnimationClass}
            src={
              (this.props.baseUrl.substr(0,4) === "http" || this.props.baseUrl.substr(0,5) === "https")
                ? this.props.baseUrl + element.content
                : AppConfig.content + this.props.baseUrl + element.content
            }
            style={imageStyle}
            alt=""
          />
        </a>
      </div>
    );
  }

  textHtml(index, element) {
    const elementStyle = {
      left: element.pos.x
    };
    const HeadlineClass = "headline-download " + (element.className !== undefined ? element.className : "show1");

    const imageStyle = {
      animationDelay: "0." + index * 2 + "s"
    };
    return (
      <div key={index} className="col-12" style={elementStyle}>
        <h3 className={HeadlineClass} style={imageStyle}>
          {element.content}{" "}
        </h3>
      </div>
    );
  }

  render() {
    let imageHtml = this.imageHtml;
    let textHtml = this.textHtml;
    const elements = this.state.elements.map((item, index) => {
      switch (item.type) {
      case "image":
        return imageHtml.bind(this)(index, item);
      case "text":
        return textHtml(index, item);
      default:
        return textHtml(index, item);
      }
    });
    return (
      <Container>
        <div className="wrapper">{elements}</div>
        <div className="button-wrap">
          <ElearningButton onClick={this.props.callComplete}>
            { i18n.t("interactions.watched") }
          </ElearningButton>
        </div>
      </Container>
    );
  }
}