import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Button as ButtonBase } from "../../../../components/button/button.component";
import { styleIfElse, styleWhenTrue } from "../../../../helpers/rendering";
import { Color, Media } from "../../../../theme";
import { PlayInfo as PlayInfoBase } from "../playInfo/playInfo.component";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 291px;
  font-size: 16px;
  background-color: ${Color.black};
  grid-area: 1 / 1 / ${styleIfElse(prop("fullScreen"), 4, 3)} / 2;
  position: relative;

  ${Media.tablet`
    height: 543px;

    ${styleWhenTrue(prop("fullScreen"), css`
      height: 80vh;
    `)}
  `}
`;

export const Header = styled.div`
  font-size: 20px;
  margin: 20px;
  color: ${Color.white};
  text-align: center;
  line-height: 25px;
`;

export const Text = styled.div`
  color: ${Color.gray4};
  margin-bottom: 4px;
`;

export const Button = styled(ButtonBase)`
  display: block;
  margin: 20px auto 0;
`;

export const PlayInfo = styled(PlayInfoBase)`
  position: absolute;
  left: 30px;
  bottom: 20px;

  ${styleWhenTrue(prop("fullScreen"), css`
    display: none;
  `)}
`;
