import styled from "styled-components";

import { ReactComponent as BaseCloseIcon } from "../../images/close.svg";
import { Color } from "../../theme/colors";
import { FontWeight } from "../../theme/fonts";

export const Container = styled.div`
  padding: 4px 20px;
  border-radius: 14px;
  background-color: ${Color.almostWhite};
  font-size: 10px;
  color: ${Color.gray4};
  line-height: 20px;
  font-weight: ${FontWeight.SemiBold};
  flex: none;
  margin: 5px;
  max-width: max-content;
  transition: background-color 150ms ease-in-out;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${Color.gray5};
  }
`;

export const RemoveButton = styled.button`
  border: none;
  cursor: pointer;
  background: transparent;
  margin-right: -10px;
  margin-left: 5px;
`;

export const CloseIcon = styled(BaseCloseIcon)`
  g {
    stroke: ${Color.gray4};
  }
`;
