/* eslint-disable @typescript-eslint/no-explicit-any */
import { put } from 'redux-saga/effects';

import * as emailVerificationApi from '../services/api';

import { EmailVerificationActions } from './emailVerification.actions';
import { CheckTokenAction } from './emailVerification.types';

export function* checkToken({ token }: CheckTokenAction): Generator {
  // TODO: remove this any
  const { status }: any = yield emailVerificationApi.checkToken(token);
  yield put(EmailVerificationActions.setTokenVerifiedStatus(status === 200));
}
