import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { hexToRgba } from "../../helpers/colors";
import { styleWhenTrue } from "../../helpers/rendering";
import { Color, FontWeight } from "../../theme";
import { ReactComponent as StarSvg } from "../../images/star.svg";
import { ProgressWithChildren } from "../progressWithChildren/progressWithChildren.component";
import { ChartColorType } from "../simpleProgressChart/simpleProgressChart.constants";

export const ProgressBorder = styled(ProgressWithChildren)``;

export const MarkCircle = styled.div`
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${styleWhenTrue(propEq(ChartColorType.Gold, "variant"), css`
    background-color: ${hexToRgba(Color.lightYellow, 0.5)};
  `)}

  ${styleWhenTrue(propEq("true", "large"), css`
    width: 80px;
    height: 80px;
  `)}

  ${styleWhenTrue(prop("noRate"), css`
    background-color: ${Color.almostWhite};
    border: none;
    width: 50px;
    height: 50px;

    ${styleWhenTrue(prop("large"), css`
      width: 86px;
      height: 86px;
    `)}
  `)}
`;

export const StarIcon = styled(StarSvg)`
  fill: ${Color.gray1};
`;

export const MarkIcon = styled(StarSvg)`
  position: absolute;
  right: -4px;
  top: -4px;
  width: 20px;
  height: 20px;
  background-color: ${() => Color.brandBasic};
  border-radius: 50%;
  fill: ${Color.white};
  padding: 5px;

  ${styleWhenTrue(propEq("true", "large"), css`
    width: 34px;
    height: 34px;
    padding: 9px;
    top: -9px;
    right: -9px;
  `)}

  ${styleWhenTrue(propEq(ChartColorType.Gold, "variant"), css`
    background-color: ${Color.gold};
  `)}
`;

export const Mark = styled.span`
  color: ${Color.almostBlack};
  font-size: 19px;
  font-weight: ${FontWeight.SemiBold};

  ${styleWhenTrue(prop("large"), css`
    font-size: 32px;
  `)}
`;

export const MaxMark = styled.span`
  font-size: 11px;
  color: ${Color.gray9};

  ${styleWhenTrue(prop("large"), css`
    font-size: 18px;
  `)}
`;
