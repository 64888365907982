import styled, { css } from "styled-components";
import { prop } from "ramda";
import { Link as LinkBase } from "react-router-dom";

import { styleWhenTrue } from "../../helpers/rendering";
import { hexToRgba } from "../../helpers/colors";
import { FontWeight, Color, Media, ContentMaxWidth } from "../../theme";

export const Container = styled.div``;

export const HeaderWrapper = styled.div`
  padding-top: 40px;
  box-shadow: 0 2px 4px ${hexToRgba(Color.gray1, 0.25)};

  ${Media.tablet`
    padding: 40px 80px 0;
  `}

  ${Media.desktop`
    padding: 40px 160px 0;
  `}
`;

export const Header = styled.div`
  display: flex;
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
  align-items: center;
  padding: 0 20px 30px;

  ${Media.tablet`
    padding: 0 0 30px;
  `}
`;

export const TextWrapper = styled.div`
  margin-left: 20px;

  ${Media.mobile`
    margin-left: 30px;
  `}

  ${Media.tablet`
    margin-left: 37px;
  `}
`;

export const Name = styled.p`
  color: ${Color.deepGray};
  font-weight: ${FontWeight.Bold};
  font-size: 20px;
  line-height: 24px;

  ${Media.tablet`
    font-size: 26px;
    line-height: 30px;
  `}
`;

export const Position = styled.p`
  margin-top: 8px;
  color: ${Color.gray9};
  font-size: 14px;
  line-height: 18px;

  ${Media.tablet`
    font-size: 20px;
    line-height: 22px;
  `}
`;

export const Tabs = styled.div`
  display: flex;
  overflow-x: scroll;
  -ms-overflow-style: none;
  overflow-y: hidden;
  scrollbar-width: none;
  max-width: ${ContentMaxWidth};
  margin: 0 auto;

  &::-webkit-scrollbar {
    display: none;
  }

  ${Media.tablet`
    position: relative;
    left: -20px;
  `}
`;

export const TabText = styled.p`
  font-size: 14px;
  font-weight: ${FontWeight.SemiBold};
  white-space: nowrap;
  color: ${Color.gray9};
  padding-bottom: 13px;

  ${styleWhenTrue(prop("$active"), css`
    color: ${() => Color.brandBasic};
    border-bottom: 2px solid ${() => Color.brandBasic};
  `)}
`;

export const Tab = styled(LinkBase)`
  background-color: ${Color.transparent};
  text-decoration: none;
  padding: 15px 15px 0;

  &:first-of-type {
    padding-left: 20px;
  }

  &:last-of-type {
    padding-right: 20px;
  }
`;
