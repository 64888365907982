import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

import { AppConfig } from "../../../../appConfig";
import { TextBox } from "../textBox/textBox.component";
import { PageDataContext } from "../page/page.context";

import { Container, ImageWrapper, Image } from "./imageWithText.styled";

export const ImageWithText = ({ completed, callComplete }) => {
  const { assetSrc } = useContext(PageDataContext);

  useEffect(() => {
    if (!completed) {
      callComplete();
    }
  }, []);

  return (
    <Container>
      <ImageWrapper>
        <Image src={`${AppConfig.content}${assetSrc}`} />
      </ImageWrapper>
      <TextBox />
    </Container>
  );
};

ImageWithText.propTypes = {
  imageSrc: PropTypes.string,
  completed: PropTypes.bool,
  callComplete: PropTypes.func,
};
