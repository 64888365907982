import React from "react";
import PropTypes from "prop-types";

import {
  Container,
  IconWrapper,
  AddIcon,
  HeaderText,
  LightText,
} from "./codeThumbnail.styled";

export const CodeThumbnail = ({
  className,
  onClick,
  headerText,
  lightText,
}) => (
  <Container className={className} onClick={onClick}>
    <IconWrapper>
      <AddIcon />
    </IconWrapper>
    <HeaderText>{headerText}</HeaderText>
    <LightText>{lightText}</LightText>
  </Container>
);

CodeThumbnail.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  headerText: PropTypes.string,
  lightText: PropTypes.string,
};
