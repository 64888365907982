import styled from "styled-components";

import { Modal } from "../../../../components/modal/modal.component";
import { Button as ButtonBase } from "../../../../components/button/button.component";
import {
  Media,
  Color,
  Separator as SeparatorBase } from "../../../../theme";
import { ReactComponent as FolderSvg } from "../../images/folderPlus.svg";

export const Container = styled(Modal)``;

export const Fields = styled.div`
  padding: 35px 20px 60px;

  ${Media.tablet`
    padding: 35px 30px 60px;
  `}
`;

export const Button = styled(ButtonBase)`
  & + & {
    margin-left: 10px;
  }
`;

export const Separator = styled(SeparatorBase)``;

export const Actions = styled.div`
  padding: 30px 20px;
  display: flex;
  justify-content: flex-end;

  ${Media.tablet`
    padding: 30px;
  `}
`;

export const AddIcon = styled(FolderSvg)`
  stroke: ${() => Color.brandBasic};

  && {
    fill: ${Color.transparent};
  }
`;

export const ModalText = styled.p`
  text-align: center;
  padding: 50px 0;
`;
