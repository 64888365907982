import { all, put, takeLatest } from "redux-saga/effects";

import { UserContextActions } from "../../../userContext/redux/userContext.reducer";
import * as commonApi from "../../../api/common";
import * as profileApi from "../services/api";

import { ProfileTypes, ProfileActions } from "./profile.reducer";

function* fetchEditData() {
  yield put(ProfileActions.setEditTabPending(true));
  const { data } = yield profileApi.fetchEditData();
  yield put(ProfileActions.fetchEditDataSuccess(data));
  yield put(ProfileActions.setEditTabPending(false));
}

function* fetchEditCompanies() {
  const { data } = yield profileApi.fetchEditCompanies();
  yield put(ProfileActions.fetchEditCompaniesSuccess(data));
}

function* fetchEditPositions({ companyId, namespace }) {
  const { data } = yield profileApi.fetchEditPositions(companyId);
  yield put(ProfileActions.fetchEditPositionsSuccess(data, namespace));
}

function* fetchEditBrands({ companyId, namespace }) {
  const { data } = yield profileApi.fetchEditBrands(companyId);
  yield put(ProfileActions.fetchEditBrandsSuccess(data, namespace));
}

function* addAvatar({ file, onError }) {
  const { data } = yield profileApi.addAvatar(file);

  if (data) {
    yield put(ProfileActions.addAvatarSuccess(data));
    yield put(UserContextActions.addAvatarSuccess(data.path));
  } else {
    onError();
  }
}

function* deleteAvatar() {
  yield profileApi.deleteAvatar();
  yield put(ProfileActions.deleteAvatarSuccess());
  yield put(UserContextActions.deleteAvatarSuccess());
}

function* sendEditForm({ values, onFinish }) {
  const { data } = yield profileApi.sendEditForm(values);

  if (data) {
    yield put(ProfileActions.sendEditFormSuccess(data));
    yield put(UserContextActions.sendEditFormSuccess(data.sections));
  } else {
    yield put(ProfileActions.sendEditFormFailure());
  }
  yield onFinish();
}

function* fetchHeaderInfo() {
  yield put(ProfileActions.setIsPending(true));
  const { data } = yield profileApi.fetchHeaderInfo();
  yield put(ProfileActions.fetchHeaderInfoSuccess(data));
  yield put(ProfileActions.setIsPending(false));
}

function* fetchCareerHistory() {
  const { data } = yield profileApi.fetchCareerHistory();
  yield put(ProfileActions.fetchCareerHistorySuccess(data.experiences));
}

function* fetchIddHistory() {
  const { data } = yield profileApi.fetchIddHistory();
  yield put(ProfileActions.fetchIddHistorySuccess(data.elements, data.nextPage));
}

function* loadIddNextPage({ iddNextPage }) {
  yield put(ProfileActions.loadIddNextPageSuccess([], null));
  const { data } = yield profileApi.loadIddNextPage(iddNextPage);
  yield put(ProfileActions.loadIddNextPageSuccess(data.elements, data.nextPage));
}

function* fetchUserAgreements() {
  const { data } = yield profileApi.fetchUserAgreements();
  yield put(ProfileActions.fetchUserAgreementsSuccess(data));
}

function* updateUserAgreements({ agreements, onLogout = Function.prototype }) {
  const { data } = yield commonApi.updateUserAgreements(agreements);
  const logoutAgreement = data.find((agreement) => agreement.logout && !agreement.agree);
  const ambassadorAgreement = data.find(
    (agreement) => agreement.name === "leader_agreement" && agreement.agree
  );
  if (logoutAgreement) {
    onLogout();
  } else if (ambassadorAgreement) {
    yield put(UserContextActions.confirmAmbassador());
  }
  yield put(ProfileActions.fetchUserAgreementsSuccess(data));
}

export function* watchProfile() {
  yield all([
    yield takeLatest(ProfileTypes.FETCH_EDIT_DATA, fetchEditData),
    yield takeLatest(ProfileTypes.ADD_AVATAR, addAvatar),
    yield takeLatest(ProfileTypes.DELETE_AVATAR, deleteAvatar),
    yield takeLatest(ProfileTypes.SEND_EDIT_FORM, sendEditForm),
    yield takeLatest(ProfileTypes.FETCH_HEADER_INFO, fetchHeaderInfo),
    yield takeLatest(ProfileTypes.FETCH_CAREER_HISTORY, fetchCareerHistory),
    yield takeLatest(ProfileTypes.FETCH_IDD_HISTORY, fetchIddHistory),
    yield takeLatest(ProfileTypes.LOAD_IDD_NEXT_PAGE, loadIddNextPage),
    yield takeLatest(ProfileTypes.FETCH_USER_AGREEMENTS, fetchUserAgreements),
    yield takeLatest(ProfileTypes.UPDATE_USER_AGREEMENTS, updateUserAgreements),
    yield takeLatest(ProfileTypes.FETCH_EDIT_COMPANIES, fetchEditCompanies),
    yield takeLatest(ProfileTypes.FETCH_EDIT_POSITIONS, fetchEditPositions),
    yield takeLatest(ProfileTypes.FETCH_EDIT_BRANDS, fetchEditBrands),
  ]);
}
