import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ButtonColor } from "../../../../components/button/button.constants";
import { Tooltip } from "../../../../components/tooltip/tooltip.component";
import { Infotip } from "../infoTip/infoTip.component";
import { MinutesSetter } from "../minutesSetter/minutesSetter.component";

import { ParticipantsAssignment, ValidationError } from "./roomsModalFirstView.constants";
import {
  Container,
  RoomRow,
  Input,
  RemoveButton,
  TrashIcon,
  Title,
  Separator,
  Row,
  GrayText,
  IconText,
  SmallButton,
  RadioGroup,
  Label,
  Checkbox,
  TickIcon,
  CheckboxText,
  Button,
  ButtonWrapper,
  Error,
} from "./roomsModalFirstView.styled";

export const RoomsModalFirstView = ({
  rooms,
  updateRooms,
  handleNextButton,
  open,
  participantsAssignment,
  setParticipantsAssignment,
}) => {
  const { t } = useTranslation();
  const [closeRoomsTimeoutEnabled, setRoomsTimeoutEnabled] = useState(false);
  const [screenSharingEnabled, setScreenSharingEnabled] = useState(true);
  const [toggleFlipchartEnabled, setToggleFlipchartEnabled] = useState(true);
  const [roomsTimeout, setRoomsTimeout] = useState(5);
  const [minutesError, setMinutesError] = useState(false);

  useEffect(() => {
    const integerRegex = /^(?:\d*)$/;

    if (closeRoomsTimeoutEnabled) {
      if (integerRegex.test(roomsTimeout) && roomsTimeout >= 1 && roomsTimeout <= 60) {
        setMinutesError(false);
      } else {
        setMinutesError(true);
      }
    } else {
      setMinutesError(false);
    }

  }, [roomsTimeout, closeRoomsTimeoutEnabled]);

  const onNext = () => {
    updateRooms(rooms.map((room) => {
      room.endTime = closeRoomsTimeoutEnabled ? roomsTimeout * 60 : null;
      room.canShareScreen = screenSharingEnabled;
      room.canToggleFlipchart = toggleFlipchartEnabled;
      return room;
    }));

    handleNextButton();
  };

  const isButtonDisabled = !participantsAssignment
    || !rooms.length
    || rooms.some((room) => room.name.length === 0)
    || minutesError;

  const createNewRoom = () => updateRooms(
    [...rooms, { name: "", participantIds: [] }]
  );

  const onRoomNameChange = (target, index) => {
    const newRoomsList = [...rooms];
    newRoomsList[index].name = target.value;
    updateRooms(newRoomsList);
  };

  const removeRoom = (index) => {
    const newRoomsList = [...rooms];
    newRoomsList.splice(index, 1);
    updateRooms(newRoomsList);
  };

  const handleRadioButtonChange = ({ target }) => {
    setParticipantsAssignment(target.value);
  };

  const RadioInputs = useCallback(() => (
    <RadioGroup onChange={handleRadioButtonChange} withTopPadding={!!rooms.length}>
      <Label>
        <input
          type="radio"
          value={ParticipantsAssignment.Auto}
          name="participantsAssignment"
        />
        {t("webinars.roomsModal.assignUsersAutomatically")}
        <Infotip>
          {t("webinars.roomsModal.autoAssignDescription")}
        </Infotip>
      </Label>
      <Label>
        <input
          type="radio"
          value={ParticipantsAssignment.Manual}
          name="participantsAssignment"
        />
        {t("webinars.roomsModal.assignUsersManually")}
        <Infotip>
          {t("webinars.roomsModal.manualAssignDescription")}
        </Infotip>

      </Label>
    </RadioGroup>
  ), [ParticipantsAssignment, rooms]);

  const toggleTimeoutCheckbox = () => setRoomsTimeoutEnabled(!closeRoomsTimeoutEnabled);

  const toggleScreenSharingCheckbox = () => setScreenSharingEnabled(!screenSharingEnabled);

  const toggleAllowFlipchartCheckbox = () => setToggleFlipchartEnabled(!toggleFlipchartEnabled);

  const renderNewRooms = () => {
    return rooms.map((room, index) => {
      const maxLengthError = room.name.length > 255;
      let duplicateError = false;
      if (room.name.length) {
        for (let i = 0; i < index; i++) {
          if (rooms[i].name === room.name) {
            duplicateError = true;
          }
        }
      }

      return (
        <React.Fragment key={index}>
          <RoomRow>
            <Input
              placeholder={t("webinars.roomsModal.typeRoomName")}
              value={room.name}
              onChange={({ target }) => onRoomNameChange(target, index)}
            />
            <Tooltip title={t("webinars.roomsModalFirstView.remove")}>
              <RemoveButton onClick={removeRoom}>
                <TrashIcon />
              </RemoveButton>
            </Tooltip>
          </RoomRow>
          {maxLengthError && <Error>{t(ValidationError.MaxLengthError)}</Error>}
          {duplicateError && <Error>{t(ValidationError.RoomDuplicates)}</Error>}
        </React.Fragment>
      )
    });
  };

  return (
    <Container visible={open}>
      <Title>{t("webinars.rooms")}</Title>
      <Separator />
      <Row>
        <IconText>{t("webinars.roomsModal.createNewRoom")}</IconText>
        <SmallButton
          color={ButtonColor.Primary}
          onClick={createNewRoom}
        >
          {t("webinars.roomsModal.add")}
        </SmallButton>
      </Row>
      <Separator />
      <GrayText>{t("webinars.roomsModal.creatingRoomPurpose")}</GrayText>
      {renderNewRooms()}
      <RadioInputs />
      <Separator />
      <Row>
        <Checkbox checked={closeRoomsTimeoutEnabled} onClick={toggleTimeoutCheckbox}>
          <TickIcon />
        </Checkbox>
        <CheckboxText greyed={!closeRoomsTimeoutEnabled} onClick={toggleTimeoutCheckbox}>
          {t("webinars.roomsModal.closeRoomsAfter")}
        </CheckboxText>
        <MinutesSetter
          minutes={roomsTimeout}
          setMinutes={setRoomsTimeout}
          disabled={!closeRoomsTimeoutEnabled}
        />
        <Infotip position="left">
          {t("webinars.roomsModal.minutesSetterDescription")}
        </Infotip>
      </Row>
      <Row onClick={toggleScreenSharingCheckbox}>
        <Checkbox checked={screenSharingEnabled}>
          <TickIcon />
        </Checkbox>
        <CheckboxText greyed={!screenSharingEnabled}>
          {t("webinars.roomsModal.allowUsersToShareScreen")}
        </CheckboxText>
      </Row>
      <Row onClick={toggleAllowFlipchartCheckbox}>
        <Checkbox checked={toggleFlipchartEnabled}>
          <TickIcon />
        </Checkbox>
        <CheckboxText greyed={!toggleFlipchartEnabled}>
          {t("webinars.roomsModal.allowUsersToToggleFlipchart")}
        </CheckboxText>
      </Row>
      {minutesError && <Error>{t(ValidationError.MinutesError)}</Error>}
      <ButtonWrapper>
        <Button
          onClick={onNext}
          disabled={isButtonDisabled}
          color={ButtonColor.Primary}
        >
          {t("webinars.roomsModal.next")}
        </Button>
      </ButtonWrapper>
    </Container>
  );
};
