import React from "react";
import MediaQuery from "react-responsive";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import {
  BoxTitle,
  Badge,
  BoxInner,
  BoxTextSmall,
  RequiredOption,
} from "../../../../UIElements";
import { breakpointsArray } from "../../../../UIGlobals";

import {
  InlineBox,
  ThumbnailWithText,
  Thumbnail,
  IconWrapper,
} from "./pathLearningItem.styled";

export const PathLearningItem = ({ props }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const badges = props.required ? ["required"] : [];

  const redirect = () => {
    if (!props.isEnabled) return;

    if (props.contentType === "video") {
      navigate(`/wideo/${props.id}`);
    } else if (props.contentType === "lesson" || props.contentType === "lesson_slide_scroll") {
      navigate(`/lekcje/${props.id}`);
    }
  }

  return (
    <InlineBox
      $background="inherit"
      borderBottom="dirtyWhite"
      width="100%"
      key={props.id}
      hover={props.isEnabled}
      onClick={redirect}
    >
      <ThumbnailWithText>
        <Thumbnail
          key={props}
          badges={badges}
          itemsType={props.contentType}
          progress={props.progress && props.progress > 0 ? props.progress : null}
          uri={props.image}
          height="100px"
          size="20"
          flexShrink="0"
          containerMaxWidth="none"
          disableCursor={!props.isEnabled}
        />
        <MediaQuery query={`(max-width: ${breakpointsArray.m - 1}px)`}>
          <BoxInner
            paddingLeft={0}
            maxWidthMobile="285px"
            paddingBottom={0}
            paddingTop={15}
            maxWidth="285px"
          >
            <BoxTitle fontWeight={400} paddingBottom={8} marginRight={8}>
              {props.name}
              {props.required && (
                <RequiredOption> ({t("certificates.certificate.learning.requiredToPass")})</RequiredOption>
              )}
            </BoxTitle>
            <BoxTextSmall color="gray4">
              {props.description}
            </BoxTextSmall>
          </BoxInner>
        </MediaQuery>
      </ThumbnailWithText>
      <MediaQuery query={`(min-width: ${breakpointsArray.m}px)`}>
        <BoxInner
          paddingLeft={29}
          maxWidthMobile="285px"
          paddingBottom={25}
          paddingTop={25}
        >
          <BoxTitle fontWeight={400} paddingBottom={8} marginRight={8}>
            {props.name}
            {props.required && (
              <RequiredOption> ({t("certificates.certificate.learning.requiredToPass")})</RequiredOption>
            )}
          </BoxTitle>
          <BoxTextSmall color="gray4">
            {props.description}
          </BoxTextSmall>
        </BoxInner>
      </MediaQuery>
      {(props.passed || props.progress || props.award) && (
        <IconWrapper>
          {props.passed && (
            <Badge
              size="45"
              marginRight="10"
              name="completed">
            </Badge>
          )}
          {props.progress && (
            <Badge
              name="eye"
              size="45"
              marginRight="10">
            </Badge>
          )}
          {props.award && (
            <Badge
              name={props.award}
              size="45"
              marginRight="10">
            </Badge>
          )}
        </IconWrapper>
      )}
    </InlineBox>
  );
};
