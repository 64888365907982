import { prop } from "ramda";
import styled, { css } from "styled-components";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.div`
  display: flex;
  align-items: baseline;
  margin: 0 20px 50px;

  ${Media.tablet`
    margin: 0 0 50px;
  `}
`;

export const Bold = styled.p`
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.deepGray};
  font-size: 14px;
  margin-right: 20px;
  padding-bottom: 5px;
`;

export const TypeFilter = styled.button`
  border: none;
  outline: none;
  background-color: ${Color.transparent};
  padding: 0;
  color: ${Color.gray9};
  font-size: 14px;
  padding-bottom: 4px;
  margin-bottom: 10px;
  margin-right: 15px;
  border-bottom: 1px solid ${Color.transparent};

  ${styleWhenTrue(prop("selected"), css`
    color: ${() => Color.brandBasic};
    border-bottom: 1px solid ${() => Color.brandBasic};
  `)}
`;

export const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
