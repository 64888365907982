import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleIfElse } from "../../helpers/rendering";
import { Color } from "../../theme";
import { breakpoints } from "../../UIGlobals";

export const TimeoutPopup = styled.div`
  width: 100%;
  height: 500px;
  position: relative;
  background: rgba(255, 255, 255, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0 25px;

  ${styleIfElse(prop("isVideoLesson"), css`
    background: ${Color.almostWhite};
    height: 766px;
  `)}

  > button {
    margin: 0 5px;
  }

  @media (${breakpoints().maxM}) {
    height: 350px;
  }

  @media (${breakpoints().maxS}) {
    height: 220px;
  }
`;

export const Message = styled.div`
  font-size: 16px;
  color: ${Color.black};
  margin: 0 15px;
  line-height: 20px;
  text-align: center;
`;
