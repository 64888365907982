import { createSelector } from "reselect";
import { prop } from "ramda";

export const selectTrainingsDomain = prop("trainings");

export const selectIsInitialDataPending = createSelector(
  selectTrainingsDomain, prop("isPending"),
);

export const selectEvents = createSelector(
  selectTrainingsDomain, prop("events"),
);

export const selectCategories = createSelector(
  selectTrainingsDomain, prop("categories"),
);

export const selectTags = createSelector(
  selectTrainingsDomain, prop("tags"),
);

export const selectDateRange = createSelector(
  selectTrainingsDomain, prop("dateRange"),
);

export const selectTrainerMetric = createSelector(
  selectTrainingsDomain, prop("trainerMetric"),
);

export const selectBlockingEvent = createSelector(
  selectTrainingsDomain, prop("blockingEvent"),
);

export const selectMissingCourses = createSelector(
  selectTrainingsDomain, prop("missingCourses"),
);

export const selectCheckPopupOpenedId = createSelector(
  selectTrainingsDomain, prop("checkPopupOpenedId"),
);

export const selectNextCourses = createSelector(
  selectTrainingsDomain, prop("nextCourses"),
);

export const selectToRemoveCourses = createSelector(
  selectTrainingsDomain, prop("toRemoveCourses"),
);

export const selectShowTrainingRegulation = createSelector(
  selectTrainingsDomain, prop("showTrainingRegulation"),
);

export const selectEventDetails = createSelector(
  selectTrainingsDomain, prop("eventDetails"),
);
