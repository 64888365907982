import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  Container,
  FinishIcon,
  FinishText,
  Wrapper,
  Header,
  HeaderIcon,
  Title,
  StartText,
  Button,
} from "./infoScreen.styled";

export const InfoScreen = ({
  title,
  text,
  onNextClick = Function.prototype,
  finished,
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      {finished ? (
        <Wrapper>
          <FinishIcon />
          <FinishText>{t("anonSurvey.thankYou")}</FinishText>
        </Wrapper>
      ) : (
        <Wrapper>
          <Header>
            <HeaderIcon />
            <Title>{title}</Title>
          </Header>
          <StartText>{text}</StartText>
          <Button onClick={onNextClick} $large>
            {t("nextButton.nextButton.start")}
          </Button>
        </Wrapper>
      )}
    </Container>
  );
};

InfoScreen.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  onNextClick: PropTypes.func,
  finished: PropTypes.bool,
};
