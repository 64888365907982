import styled from "styled-components";

import { Color, FontWeight } from "../../theme";
import { ReactComponent as MedalSvg } from "../../images/medalIcon.svg"

export const AnimationWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 26px;

  & > div {
    width: 360px !important;
    height: 156px !important;
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-weight: ${FontWeight.Bold};
  font-size: 18px;
  line-height: 25px;
  color: ${Color.deepGray};
  margin-bottom: 10px;
`;

export const Subtitle = styled.h2`
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  color: ${Color.gray9};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid ${Color.gray8};
  width: 100%;
  margin-top: 40px;
  padding: 30px 0;
`;

export const MedalIcon = styled(MedalSvg)`
  * {
    fill: ${() => Color.brandBasic};
  }
`;
