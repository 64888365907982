import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { Separator } from "../../theme/typography";

import { AddNews } from "./components/addNews/addNews.component";
import { LatestNews } from "./components/latestNews/latestNews.component";
import { NewsFeed } from "./components/newsFeed/newsFeed.component";
import { EntryDetails } from "./components/entryDetails/entryDetails.component";
import { Config } from "./news.config";
import {
  Content,
  Container,
  Sidebar,
  PageHeader,
  Wrapper,
  Section,
  BackButton,
} from "./news.styled";

const News = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (Config.urls.includes(location.pathname)) {
      navigate(Config.routes.init, { replace: true });
    }
  }, [location.pathname]);

  const StartPage = () => (
    <>
      <AddNews />
      <Separator />
      <NewsFeed />
    </>
  );

  const RenderHeader = () => <PageHeader>{t(Config.label)}</PageHeader>;

  const RenderDetails = () => (
    <>
      <BackButton defaultPath="/news" />
      <EntryDetails />
    </>
  );

  return (
    <Container>
      <Wrapper>
        <Routes>
          <Route path={Config.routes.init} exact element={<RenderHeader />} />
        </Routes>
        <Content>
          <Section>
            <Routes>
              <Route path={Config.routes.init} exact element={<StartPage />} />
              <Route path={Config.routes.entry} exact element={<RenderDetails />} />
            </Routes>
          </Section>
          <Sidebar>
            <Routes>
              <Route path={Config.routes.init} exact element={<LatestNews />} />
              <Route path={Config.routes.entry} exact element={<LatestNews withPadding />} />
            </Routes>
          </Sidebar>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default News;
