import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";

import { Icon } from "../icon/icon.component";
import { Container, RemoveButton, CloseIcon } from "./chip.styled";

export const Chip = memo(({ className, children, onRemove, maxLength = children.length }) => {
  const clampedText = useMemo(() => {
    let text = children;
    const lengthDiff = children.length - maxLength;
    if (lengthDiff > 0) {
      const clampPoint = Math.round((children.length - lengthDiff - 3) / 2);
      return `${text.substring(0, clampPoint).trim()}...${text.substring(clampPoint + lengthDiff).trim()}`;
    }

    return text;
  }, [children, maxLength]);

  return (
    <Container className={className}>
      {clampedText}
      {onRemove && (
        <RemoveButton onClick={onRemove}>
          <Icon>
            <CloseIcon />
          </Icon>
        </RemoveButton>
      )}
    </Container>
  );
});

Chip.displayName = "Chip";

Chip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onRemove: PropTypes.func,
};
