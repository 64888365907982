import { createSelector } from "reselect";
import { prop } from "ramda";

export const selectSearchDomain = prop("search");

export const selectData = createSelector(
  selectSearchDomain, prop("data"),
);

export const selectNewsData = createSelector(
  selectSearchDomain, prop("newsData"),
);
