import { nodeApi, phpApi, handleApiError } from "../../../api";

export const getUserCompetenceList = () => {
  return nodeApi.get("/competences").catch(handleApiError);
};

export const getCompetenceOverview = (id) => {
  return nodeApi.get(`/competences/${id}`);
};

export const getCompetenceSkills = (id) => {
  return nodeApi.get(`/competences/${id}/skills`);
};

export const getCompetenceHistory = (id) => {
  return nodeApi.get(`/competences/${id}/history`);
};

export const getCompetenceHistoryNextPage = (endpoint) => {
  return nodeApi.get(endpoint);
}

export const getCompetenceStatistics = (id) => {
  return nodeApi.get(`/competences/${id}/statistic`);
}

export const getCompetenceScales = (id) => {
  return nodeApi.get(`/competences/${id}/scales`);
};

export const getResources = (id) => {
  return phpApi.get(`/api/competence/${id}/items`);
};

export const enrollEvent = (id) => {
  return phpApi.post(`/api/events/${id}/enroll`)
};

export const disenrollEvent = (id) => {
  return phpApi.post(`/api/events/${id}/disenroll`);
};

export const getSelfMarkSurvey = (id) => {
  return phpApi.get(`/api/card/self-assessment/create/${id}`);
};

export const sendSelfMarkSurvey = (id, answers) => {
  return phpApi.post(`/api/card/self-assessment/create/${id}`, { answers }).catch(handleApiError);
};

export const getConfirmationCategories = () => {
  return nodeApi.get("/competences/confirmations/categories");
};

export const getConfirmationList = ({ competenceId }) => {
  return nodeApi.get(`/competences/${competenceId}/confirmations`);
};

const createConfirmationFormData = (confirmation) => {
  const formData = new FormData();
  for (let prop in confirmation) {
    formData.append(prop, confirmation[prop]);
  }
  return formData;
};

export const sendConfirmation = (competenceId, confirmation) => {
  const formData = createConfirmationFormData(confirmation);
  return nodeApi.post(
    `/competences/${competenceId}/confirmations`,
    formData,
  ).catch(handleApiError);
};

export const sendSuggestion = ({ title, notes }) => {
  return nodeApi.post("/competences/propositions", {
    name: title,
    notes,
  }).catch(handleApiError);
};
