import React from "react";
import { Loader } from "../components/loader/loader.component";
import { renderWhenTrueOtherwise } from "../helpers/rendering";

const LoadingWrapper = ({ isLoading = true, isLesson, children }) => renderWhenTrueOtherwise(
  () => <Loader size={50} isLesson={isLesson} />,
  () => children
)(isLoading);

export { LoadingWrapper };
