import React from "react";
import { useTranslation } from "react-i18next";

import { BoardFields } from "./boardLegend.constants";
import {
  Container,
  UpperContent,
  Label,
  Tip,
  InfoText,
  InfoIcon,
  InfoSpan,
  Fields,
  FieldType,
  FieldIcon,
  FieldLabel,
} from "./boardLegend.styled";

export const BoardLegend = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <UpperContent>
        <Label>{t("onboarding.stages.legend")}</Label>
        <Fields>
          {BoardFields.map(({ type, labelKey, color }) => (
            <FieldType key={labelKey}>
              <FieldIcon color={color} type={type} />
              <FieldLabel>{t(labelKey)}</FieldLabel>
            </FieldType>
          ))}
        </Fields>
      </UpperContent>
      <Tip>
        <InfoIcon />
        <InfoText>
          <InfoSpan>{t("onboarding.stages.tip")}</InfoSpan>
          {" "}
          {t("onboarding.stages.tipText")}
        </InfoText>
      </Tip>
    </Container>
  );
};
