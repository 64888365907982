import { createActions, createReducer } from "reduxsauce";

export const { Types: SearchTypes, Creators: SearchActions } = createActions(
  {
    getData: ["searchValue"],
    getDataSuccess: ["data"],
    getNewsData: ["searchValue"],
    getNewsDataSuccess: ["newsData"],
    cleanData: null,
  },
  { prefix: "SEARCH/" }
);

const INITIAL_STATE = {
  data: [],
  newsData: [],
};

const getDataSuccess = (state, { data }) => ({ ...state, data });

const getNewsDataSuccess = (state, { newsData }) => ({ ...state, newsData });

const cleanData = (state) => ({
  ...state,
  data: [],
  newsData: [],
});

export const reducer = createReducer(INITIAL_STATE, {
  [SearchTypes.GET_DATA_SUCCESS]: getDataSuccess,
  [SearchTypes.GET_NEWS_DATA_SUCCESS]: getNewsDataSuccess,
  [SearchTypes.CLEAN_DATA]: cleanData,
});
