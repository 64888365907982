import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ButtonColor } from "../../../../../components/button/button.constants";
import { renderWhenTrue } from "../../../../../helpers/rendering";
import { SectionHeader } from "../../../../../theme";
import { ProfileActions } from "../../../redux/profile.reducer";
import { selectCareerHistory } from "../../../redux/profile.selectors";

import {
  Container,
  CareerBoxContainer,
  CareerElement,
  CareerLeftSide,
  Position,
  Company,
  Period,
  ExpandButton,
} from "./careerHistory.styled";

export const CareerHistory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const experience = useSelector(selectCareerHistory);
  const [expanded, setExpanded] = useState(false);
  const [togglerText, setTogglerText] = useState(t("profile.careerHistory.showMore"));

  useEffect(() => {
    dispatch(ProfileActions.fetchCareerHistory());
  }, [dispatch]);

  const renderCareerElement = (careerElement, index) => {
    const {
      company,
      position,
      from,
      to,
    } = careerElement;

    return (
      <CareerElement key={index}>
        <CareerLeftSide>
          <Position>{position.name}</Position>
          <Company>{company.name}</Company>
        </CareerLeftSide>
        <Period>
          {from}
          {" - "}
          {to || t("profile.careerHistory.actually")}
        </Period>
      </CareerElement>
    );
  };

  const renderCareerHistory = renderWhenTrue(() => {
    const positionsToRender = expanded ? experience : experience.slice(0, 2);
    return positionsToRender.map(renderCareerElement);
  });

  const toggleExpanded = () => {
    if (expanded) {
      setTogglerText(t("profile.careerHistory.showMore"));
    } else {
      setTogglerText(t("profile.careerHistory.showLess"));
    }
    setExpanded(!expanded);
  };

  const renderExpandButton = renderWhenTrue(() => (
    <ExpandButton
      small
      color={ButtonColor.Primary}
      onClick={toggleExpanded}
    >
      {togglerText}
    </ExpandButton>
  ));

  return (
    <Container>
      <SectionHeader>
        {t("profile.careerHistory.experience")}
      </SectionHeader>
      <CareerBoxContainer>
        {renderCareerHistory(!!experience.length)}
        {renderExpandButton(experience.length > 2)}
      </CareerBoxContainer>
    </Container>
  );
};
