import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Button } from "../../../../components/button/button.component";
import { ReactComponent as BaseArrow } from "../../../../images/arrow.svg";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { hexToRgba } from "../../../../helpers/colors";
import { ContentMaxWidth, Media, FontWeight, Color } from "../../../../theme";

export const Container = styled.div`
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  position: relative;

  ${Media.tablet`
    padding: 54px 80px;
  `}

  ${Media.desktop`
    padding: 54px 160px;
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${ContentMaxWidth};
  width: 100%;
  margin: 0;
`;

export const Pages = styled.div`
  display: none;
  margin-bottom: 119px;

  ${Media.tablet`
    display: flex;
  `}
`;

export const Part = styled.div`
  width: 30px;
  height: 5px;
  border-radius: 2px;
  background-color: ${Color.gray5};

  & + & {
    margin-left: 10px;
  }

  ${styleWhenTrue(prop("active"), css`
    background-color: ${Color.gold};
  `)}
`;

export const Hero = styled.div`
  display: flex;
  flex-direction: column;

  ${Media.tablet`
    flex-direction: row;
  `}
`;

export const TextWrapper = styled.div`
  flex: 1;
  text-align: center;

  ${Media.tablet`
    text-align: left;
  `}
`;

export const AnimationMobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;

  ${Media.tablet`
    display: none;
  `}
`;

export const Header = styled.p`
  font-weight: ${FontWeight.ExtraBold};
  margin-bottom: 35px;
  font-size: 28px;
  line-height: 38px;

  ${Media.tablet`
    margin-bottom: 39px;
  `}

  ${Media.desktop`
    font-size: 45px;
    line-height: 62px;
  `}
`;

export const Title = styled.p`
  font-size: 18px;
  line-height: 30px;
  color: ${Color.deepGray};
  font-weight: ${FontWeight.Bold};
`;

export const Description = styled.p`
  margin: 15px auto 0;
  font-size: 16px;
  line-height: 24px;
  color: ${Color.gray6};
  max-width: 400px;

  ${Media.tablet`
    margin: 15px 0 0;
  `}
`;

export const AnimationDesktopWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  flex: 1.5;
  margin-left: 22px;
  max-height: 500px;

  ${Media.tablet`
    display: flex;
  `}
`;

export const ChooseButton = styled(Button)`
  margin-top: 40px;
  width: 100%;
  background-color: ${Color.blue};

  ${Media.tablet`
    width: auto;
  `}
`;

export const Tip = styled.p`
  margin: 20px auto 0;
  font-size: 14px;
  line-height: 20px;
  color: ${Color.gray3};
  max-width: 200px;

  ${Media.tablet`
    display: block;
    margin: 104px 0 0;
    max-width: unset;
  `}
`;

const ArrowButton = styled.button`
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 50%;
  border: none;
  background-color: ${Color.blue};
  box-shadow: 0 10px 15px 0 ${hexToRgba(Color.blue, 0.15)};
  display: flex;
  align-items: center;
  justify-content: center;

  ${Media.desktop`
    width: 82px;
    height: 82px;
  `}
`;

export const ArrowImage = styled(BaseArrow)`
  height: 19px;
  fill: ${Color.white};

  ${Media.tablet`
    height: 23px;
  `}
`;

export const PrevButton = styled(ArrowButton)``;

export const NextButton = styled(ArrowButton)`
  ${ArrowImage} {
    transform: rotate(180deg);
  }
`;
