import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { deepClone } from "../../../../helpers/clone";
import { Avatar } from "../../../../components/avatar/avatar.component";
import { AvatarType } from "../../../../components/avatar/avatar.constants";
import { ButtonColor, ButtonVariant } from "../../../../components/button/button.constants";
import { selectParticipants } from "../../redux/webinars.selectors";
import { RoomDropdown } from "../roomDropdown/roomDropdown.component";
import { SearchParticipantInput } from "../searchParticipantInput/searchParticipantInput.component";
import { ParticipantsAssignment } from "../roomsModalFirstView/roomsModalFirstView.constants";
import { BroadcastType } from "../video/video.constants";

import {
  Container,
  ButtonsRow,
  Button,
  Title,
  UserRow,
  FullName,
  User,
} from "./assignUsersView.styled";

export const AssignUsersView = ({
  goBack,
  rooms,
  updateRooms,
  goToNextPage,
  participantsAssignment,
}) => {
  const { t } = useTranslation();
  const participantsWithPresenter = useSelector(selectParticipants);
  const participants = participantsWithPresenter.filter(
    ({ role, connected }) => role !== BroadcastType.Presenter && connected
  );
  const [filteredParticipants, setFilteredParticipants] = useState(participants);
  const [assignedRooms, setAssignedRooms] = useState({});
  const [dropdownsOpenState, setDropdownsOpenState] = useState({});
  const autoAssignRooms = participantsAssignment === ParticipantsAssignment.Auto;

  const isButtonDisabled = participants.some((participant) => !assignedRooms[participant.id]);

  useEffect(() => {
    if (autoAssignRooms) {
      const usersPerRoom = Math.ceil(participants.length / rooms.length);
      const newAssignedRooms = {};
      let userIds = participants.map(({ id }) => id);
      rooms.forEach((room) => {
        for (let i = 0; i < usersPerRoom; i++) {
          const randomUserId = userIds[userIds.length * Math.random() | 0];
          if (randomUserId) {
            newAssignedRooms[randomUserId] = room.name;
            userIds = userIds.filter((id) => id !== randomUserId);
          }
        }
      })
      setAssignedRooms(newAssignedRooms);
    }
  }, [autoAssignRooms]);

  const toggleDropdown = (id, open) => {
    const newState = deepClone(dropdownsOpenState);
    if (open) {
      Object.keys(newState).forEach((key) => newState[key] = false)
    }
    newState[id] = open;
    setDropdownsOpenState(newState);
  };

  const handleSave = useCallback(() => {
    Object.keys(assignedRooms).forEach((userId) => {
      const newRooms = [...rooms];
      const roomIndex = rooms.findIndex((room) => room.name === assignedRooms[userId]);
      newRooms[roomIndex].participantIds.push(Number(userId));
      updateRooms(newRooms);
    });
    goToNextPage();
  }, [assignedRooms, rooms]);

  return (
    <Container>
      <Title>{t("webinars.addParticipants")}</Title>
      <SearchParticipantInput
        participants={participants}
        setFilteredParticipants={setFilteredParticipants}
        filteredParticipants={filteredParticipants}
      />
      {filteredParticipants.map((participant, index) => (
        <UserRow key={index}>
          <User>
            <Avatar
              src={participant.user?.avatar}
              name={`${participant.name}`}
              type={AvatarType.Small}
            />
            <FullName>{participant.name}</FullName>
          </User>
          <RoomDropdown
            selected={assignedRooms[participant.id]}
            rooms={rooms}
            isOpen={dropdownsOpenState[participant.id]}
            toggleDropdown={(open) => toggleDropdown(participant.id, open)}
            selectRoom={(selectedRoom) => {
              setAssignedRooms({ ...assignedRooms, [participant.id]: selectedRoom.name });
            }}
          />
        </UserRow>
      ))}
      <ButtonsRow>
        <Button variant={ButtonVariant.Outlined} onClick={goBack}>
          {t("webinars.AssignUsersView.undo")}
        </Button>
        <Button
          color={ButtonColor.Primary}
          onClick={handleSave}
          disabled={isButtonDisabled}
        >
          {t("global.save")}
        </Button>
      </ButtonsRow>
    </Container>
  );
};
