import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import DOMPurify from "dompurify";

import { Tooltip } from "../../../../components/tooltip/tooltip.component";
import { ButtonVariant } from "../../../../components/button/button.constants";
import { useMediaListener } from "../../../../hooks/useMediaListener";
import { timestampToDate } from "../../../../helpers/date";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { NotificationsActions } from "../../redux/notifications.reducer";
import { DeletedNotification } from "../deletedNotification/deletedNotification.component";
import { Icon } from "../icon/icon.component";

import { ParseTag, ParseTagModule, Status } from "./notifications.constants";
import {
  Container,
  Header,
  IconWrapper,
  TitleWrapper,
  Title,
  DateInfo,
  Date,
  ArrowWrapper,
  ArrowIcon,
  Dot,
  Content,
  Text,
  TrashButton,
  TrashIcon,
  DeleteButton,
  Link,
} from "./notification.styled";

export const Notification = ({ className, content }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isTablet } = useMediaListener();
  const [open, setOpen] = useState(false);
  const [unread, setUnread] = useState(content.status.name === Status.Received);
  const [deleted, setDeleted] = useState(false);
  const date = timestampToDate(content.timestamp);

  const toggleOpen = () => {
    setOpen(!open);

    if (unread) {
      dispatch(NotificationsActions.setReadMessage(content.id));
      setUnread(false);
    }
  };

  const handleDeleteAction = () => {
    dispatch(NotificationsActions.deleteNotification(content.id));
    setDeleted(true);
  };

  const parseMessageWithTag = (message, parseTag) => {
    const texts = message.split(parseTag);
    const linkPart = texts[1];
    const title = linkPart.slice(linkPart.indexOf("]") + 1, linkPart.indexOf("[/link]"));
    // // TODO: temporary code specific for gamification until gamification id is handled
    const link = parseTag === ParseTag.Gamification
      ? ParseTagModule[parseTag]
      : `${ParseTagModule[parseTag]}${linkPart.slice(0, linkPart.indexOf("]"))}`;
    const textAfterLink = message.match(/link\]([\s\S]*)/)[1];

    return (
      <>
        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(texts[0]) }} />
        <Link to={link}>{title}</Link>
        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(textAfterLink) }} />
      </>
    );
  };

  const parseMessage = (message) => {
    if (message.includes(ParseTag.News)) {
      return parseMessageWithTag(message, ParseTag.News);
    } else if (message.includes(ParseTag.Event)) {
      return parseMessageWithTag(message, ParseTag.Event);
    } else if (message.includes(ParseTag.Url)) {
      return parseMessageWithTag(message, ParseTag.Url);
    } else if (message.includes(ParseTag.Gamification)) {
      return parseMessageWithTag(message, ParseTag.Gamification);
    } else if (message.includes(ParseTag.GamificationNews)) {
      return parseMessageWithTag(message, ParseTag.GamificationNews);
    } else if (message.includes(ParseTag.Survey)) {
      return parseMessageWithTag(message, ParseTag.Survey);
    }

    return message.replace(/<[^>]+>/g, "");
  };

  const renderIcon = (name) => <Icon name={name} />;

  const renderDot = renderWhenTrue(() => <Dot />);

  if (deleted) {
    return <DeletedNotification />;
  }

  return (
    <Container
      className={className}
      open={open}
      onClick={toggleOpen}
    >
      <Header>
        <IconWrapper>
          {renderIcon(content.type.name)}
        </IconWrapper>
        <TitleWrapper>
          <Title>{content.title}</Title>
          <DateInfo>
            {renderDot(unread)}
            <Date unread={unread}>{date}</Date>
          </DateInfo>
        </TitleWrapper>
        <ArrowWrapper>
          <ArrowIcon rotated={open.toString()} />
        </ArrowWrapper>
      </Header>
      {open && (
        <Content>
          <Text>{parseMessage(content.message)}</Text>
          {isTablet ? (
            <Tooltip title={t("notifications.notifications.delete")}>
              <TrashButton onClick={handleDeleteAction}>
                <TrashIcon />
              </TrashButton>
            </Tooltip>
          ) : (
            <DeleteButton variant={ButtonVariant.Outlined} onClick={handleDeleteAction}>
              {t("notifications.notifications.delete")}
            </DeleteButton>
          )}
        </Content>
      )}
    </Container>
  );
};

Notification.propTypes = {
  className: PropTypes.string,
  content: PropTypes.object,
};
