import styled from "styled-components";

import { Color } from "../../../../theme/colors";
import { Media } from "../../../../theme/media";
import { FontWeight } from "../../../../theme/fonts";

export const Container = styled.div`
  margin-bottom: 40px;
  
  ${Media.tablet`
    margin-bottom: 100px;
  `}
`;

export const EmptyMessage = styled.p`
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  color: ${Color.gray1};
  font-weight: ${FontWeight.SemiBold};
`;
