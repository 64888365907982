import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

import {
  TipIcon,
  TipContent,
  Container,
  TipTitle
} from "./infoTip.styled";

export const InfoTip = ({ sentences }) => {
  const { t } = useTranslation();

  const renderSentences = (sentences) =>
    sentences.map(sentence => <p key={sentence}>{sentence}</p>);

  return (
    <Container>
      <TipTitle>{t("interactions.infoTip.help")}</TipTitle>
      <TipIcon />
      <TipContent>
        {renderSentences(sentences)}
      </TipContent>
    </Container>
  );
};

InfoTip.propTypes = {
  sentences: PropTypes.array,
};
