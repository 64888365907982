import styled from "styled-components";

import { Button as ButtonBase } from "../../../components/button/button.component";
import { Color, Media } from "../../../theme";

export const Container = styled.div`
  padding: 50px 25px 50px;
  
  ${Media.tabletWide`
    padding: 25px 0 50px;
  `}
`;

export const OptionsWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  gap: 10px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled(ButtonBase)`
  min-width: 200px;
  background-color: ${Color.blue5};

  &:hover {
    background-color: ${Color.blue};
  }

  &:disabled {
    pointer-events: none;
  }
`;

export const ErrorMessage = styled.p`
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.2;
  text-align: right;
  color: ${Color.error};
`;
