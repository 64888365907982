import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { renderWhenTrueOtherwise } from "../../../../helpers/rendering";

import {
  Container,
  StatsBox,
  StatsCounter,
  StatsText,
  CupIcon,
  TargetIcon,
} from "./statisticsBox.styled";

export const StatisticsBox = ({
  className,
  statistics,
}) => {
  const {
    survey,
    lesson,
    certificate,
  } = statistics;

  const renderStatsBox = useCallback((stats, icon) => {
    if (!stats) {
      return null;
    }

    const { label, count, total } = stats;
    const counter = total ? `${count}/${total}` : count;

    return (
      <StatsBox>
        {icon}
        <div>
          <StatsCounter>{counter}</StatsCounter>
          <StatsText>{label}</StatsText>
        </div>
      </StatsBox>
    );
  }, []);

  const renderRightBox = renderWhenTrueOtherwise(
    () => renderStatsBox(survey, <TargetIcon />),
    () => renderStatsBox(lesson, <TargetIcon />),
  );

  return (
    <Container className={className}>
      {renderStatsBox(certificate, <CupIcon />)}
      {renderRightBox(!!survey)}
    </Container>
  );
};

StatisticsBox.propTypes = {
  className: PropTypes.string,
  statistics: PropTypes.shape({
    survey: PropTypes.object,
    lesson: PropTypes.object,
    certificate: PropTypes.object,
  })
};
