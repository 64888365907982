import { prop } from "ramda";
import styled, { css } from "styled-components";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color } from "../../../../theme";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${styleWhenTrue(prop("disabled"), css`
    pointer-events: none;
    color: ${Color.gray4};
  `)}
`;

export const Input = styled.input`
  padding: 7px 11px 7px 15px;
  border: 1px solid ${Color.dirtyWhite};
  width: 70px;

  ${styleWhenTrue(prop("disabled"), css`
    pointer-events: none;
    color: ${Color.gray4};
  `)}
`;

export const Label = styled.p`
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;
`;
