import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { CheckboxType } from "../../../../../components/checkbox/checkbox.constants";
import { Checkbox } from "../../../../../components/checkbox/checkbox.component";
import { Modal } from "../../../../../components/modal/modal.component";
import { ButtonColor, ButtonVariant } from "../../../../../components/button/button.constants";
import { ReactComponent as WarningIcon } from "../../../../../images/warning2.svg";

import {
  CheckboxWrapper,
  WarningWrapper,
  ButtonsWrapper,
  Button,
  ModalTitle,
  ModalText,
} from "./agreementCheckbox.styled";

export const AgreementCheckbox = ({
  label,
  value,
  onChange,
  checked,
  popup,
  disabled,
}) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => setModalOpen(false);

  const handleUndoChange = () => {
    onChange();
    handleCloseModal();
  };

  const handleChange = () => {
    if (popup) {
      setModalOpen(true);
    }
  };

  return (
    <>
      <CheckboxWrapper
        checked={checked}
        onClick={handleChange}
        disabled={disabled}
      >
        <Checkbox
          label={label}
          value={value}
          type={CheckboxType.Black}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        />
      </CheckboxWrapper>
      <Modal
        onClose={handleUndoChange}
        title={t("profile.agreementCheckbox.undoAgreement")}
        HeaderIcon={WarningIcon}
        open={modalOpen}
      >
        <WarningWrapper>
          <ModalTitle>{t("profile.agreementCheckbox.warning")}</ModalTitle>
          <ModalText>{popup}</ModalText>
        </WarningWrapper>
        <ButtonsWrapper>
          <Button onClick={handleUndoChange} type={ButtonVariant.Outlined}>
            {t("profile.agreementCheckbox.cancel")}
          </Button>
          <Button onClick={handleCloseModal} color={ButtonColor.Primary}>
            {t("profile.agreementCheckbox.undoAgreement")}
          </Button>
        </ButtonsWrapper>
      </Modal>
    </>
  );
};

AgreementCheckbox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  popup: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  disabled: PropTypes.bool,
};
