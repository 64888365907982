import styled from "styled-components";
import { Link } from "react-router-dom";

import { Color, FontWeight } from "../../theme";

export const Tag = styled(Link)`
  color: ${() => Color.brandBasic};
  font-size: 12px;
  font-weight: ${FontWeight.SemiBold};
  line-height: 16px;
  text-decoration: none;

  &:not(:last-of-type) {
    margin-right: 10px;
  }
`;
