import styled from "styled-components";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { Color } from "../../../../../theme/colors";
import { FontWeight } from "../../../../../theme/fonts";
import { Media } from "../../../../../theme/media";
import { ReactComponent as ShapesSvg } from "../../images/opponentShapes.svg";
import { ReactComponent as HeartSvg } from "../../images/heart.svg";

export const Container = styled.div`
  display: flex;
  position: relative;
  background-color: ${Color.almostWhite};
  min-height: 146px;

  ${Media.tablet`
    align-items: center;
    flex-direction: column;
  `}
`;

export const MonsterWrapper = styled.div`
  margin: 25px 20px 15px 37px;
  position: relative;
  width: 108px;

  ${Media.tablet`
    width: 200px;
    margin: 94px 50px 0;
  `}
`;

export const Chart = styled(CircularProgressbarWithChildren)`
  transform: rotate(250deg);
  transform-origin: center center;
  
  ${Media.tablet`
    height: 200px;
  `}

  && {
    .CircularProgressbar-path {
      stroke: ${Color.violet};
      transition: stroke-dashoffset 1.5s ease 0s;
      stroke-linecap: butt;
    }

    .CircularProgressbar-trail {
      stroke: ${Color.lightViolet};
      stroke-linecap: butt;
    }
  }
`;

export const Heart = styled(HeartSvg)`
  position: absolute;
  top: -10px;
  left: -10px;
  width: 23px;
  height: 23px;

  ${Media.tablet`
    top: -15px;
    left: -15px;
    width: 44px;
    height: 44px;
  `}
`;

export const AnimationWrapper = styled.div`
  pointer-events: none;
  width: 86px;
  height: 86px;

  ${Media.tablet`
    width: 160px;
    height: 160px;
  `}
`;

export const Name = styled.p`
  font-size: 16px;
  line-height: 22px;
  font-weight: ${FontWeight.Bold};
  margin-bottom: 5px;
`;

export const Stage = styled.p`
  font-size: 12px;
  line-height: 17px;
  color: ${Color.blue};
  text-transform: uppercase;
  font-weight: ${FontWeight.ExtraBold};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Shapes = styled(ShapesSvg)`
  display: none;
  width: 65%;
  position: absolute;
  bottom: 50px;
  left: 60px;
  right: 60px;

  ${Media.tablet`
    display: block;
  `}
`;
