import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { prop } from "ramda";

import { DefaultButtonStyles } from "../../../../components/button/button.styled";
import { hexToRgba } from "../../../../helpers/colors";
import { FontWeight, Color, Media } from "../../../../theme";
import { styleWhenTrue } from "../../../../helpers/rendering";

export const Container = styled.div`
  padding: 0 25px 70px;
  width: 100%;
  background-color: ${Color.white};

  ${Media.tablet`
    padding: 0 115px 50px;
  `}
`;

export const Table = styled.table`
  width: 100%;
`;

export const TableHead = styled.thead`
  font-size: 10px;
  color: ${Color.gray9};
  font-weight: ${FontWeight.ExtraBold};
  border-bottom: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  border-top: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  text-align: left;
  letter-spacing: 0.6px;
  text-transform: uppercase;

  th {
    padding: 14px 0;
    width: 200px;
  }
`;

export const TableBody = styled.tbody`
  tr {
    border-bottom: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  }

  td {
    font-size: 14px;
    color: ${Color.gray9};
    padding: 30px 0;

    &:not(:last-of-type) {
      padding-right: 10px;
    }
  }
`;

export const Title = styled.p`
  font-size: 12px;
  font-weight: ${FontWeight.Bold};
  letter-spacing: 0.6px;
  border-bottom: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  padding-bottom: 20px;
  width: 100%;
  text-transform: uppercase;
  margin: 50px 0 20px;
`;

export const ScoreOverviewItem = styled.div`
  color: ${Color.gray9};
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  padding: 14px 0;

  &:last-of-type {
    border-bottom: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  }
`;

export const ScoreOverviewHeader = styled.h1`
  font-size: 10px;
  font-weight: ${FontWeight.Bold};
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

export const ScoreOverviewText = styled.p`
  font-size: 14px;
`;

export const ScoreOverview = styled.div``;

export const ButtonCell = styled.td`
  display: flex;
  justify-content: flex-end;
`;

export const TestLink = styled(Link)`
  ${DefaultButtonStyles}
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  ${Media.tablet`
    margin-top: 0;
  `}

  ${styleWhenTrue(prop("hidden"), css`
    display: none;

    ${Media.tablet`
      display: flex;
      visibility: hidden;
      pointer-events: none;
    `}
  `)}
`;
