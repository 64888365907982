import React from "react";

const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

export const renderWithLinks = (txt, linkText) => txt.split(" ").map((part, index) =>
  urlRegex.test(part)
    ? (
      <a
        href={`${/^https?:\/\//.test(part) ? "" : "https://"}${part}`}
        target="_blank"
        rel="noreferrer noopener"
        key={index}
      >
        {linkText || part}
      </a>
    ) : part + " "
);
