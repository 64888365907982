import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 620px;

  & .modal button {
    color: #fff;
  }

  & .window {
    width: 854px;
    height: 500px;
    position: relative;
  }

  & .bg-img {
    max-width: 100%;
    max-height: 500px;
    z-index: 999;
  }

  & .hitbox {
    position: absolute;
    top: 0;
    left: 0;
    background: #a5a5a5;
    opacity: 0;
    z-index: 999;
  }

  & .modal {
    width: 100%;
    height: 100%;
    min-height: 500px;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 50px;
    color: #fff;
  }

  & .modal h2 {
    font-size: 55px;
    line-height: 65px;
    margin-bottom: 50px;
    text-align: center;
    max-width: 600px;
  }

  & .modal button {
    width: 220px;
    height: 53px;
    padding-bottom: 2px;
    background: #004666;
    color: #fff;
    border: 0;
    outline: 0;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    font-weight: 800;
    cursor: pointer;
  }
  & .modal__wrong {
    background: rgba(169, 59, 53, 0.7);
  }

  & .modal__success {
    background: rgba(104, 191, 39, 0.7);
  }

  & .modal__info,
  & .modal__infoText {
    background: #2f438d;
    opacity: 1;
  }

  & .modal__info h3,
  & .modal__infoText h3 {
    text-align: center;
    line-height: 50px;
    font-size: 38px;
    max-width: 80%;
  }

  & .modal__hidden {
    opacity: 0;
    pointer-events: none;
    z-index: 0 !important;
  }

  & .button__next {
    margin-top: 30px;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes slideX {
    0% {
      transform: translateX(0);
      opacity: 1;
    }

    49% {
      transform: translateX(30px);
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    51% {
      transform: translateX(-30px);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slideY {
    0% {
      transform: translateY(0);
      opacity: 1;
    }

    49% {
      transform: translateY(30px);
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    51% {
      transform: translateY(-30px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes showUpY {
    0% {
      opacity: 0;
      transform: rotateX(30deg) translateY(20px) translateZ(-100px);
    }

    100% {
      opacity: 1;
      transform: rotateX(0deg) translateY(0) translateZ(0);
    }
  }

  & .modal__info h3, & .modal__infoText h3 {
    text-align: center;
    line-height: 50px;
    font-size: 38px;
    max-width: 80%;
  }

  .mobile-error {
    font-size: 18px;
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    flex-flow: column;
    justify-content: center;
  }

  .mobile-error button {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .mobile-error p{
    padding: 0 25px;
    margin: 0 auto;
    line-height: 30px;
    text-align: center;
  }
`;
