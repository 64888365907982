import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { InView } from "react-intersection-observer";

import { getJsonData } from "../../services/api";
import { VideoSlide } from "../videoSlide/videoSlide.component";
import { AnimWithText } from "../animWithText/animWithText.component";
import { ChooseAnswer } from "../chooseAnswer/chooseAnswer.component";
import { ImageWithText } from "../imageWithText/imageWithText.component";
import { TextBox } from "../textBox/textBox.component";

import { Container } from "./page.styled";
import { SlideType } from "./page.constants";
import { PageDataContext } from "./page.context";

export const Page = ({
  data,
  baseUrl,
  callComplete,
  currentSlideCounter,
  slidesOverallCounter,
  onFloatClick,
  onVisibleChange,
  onScrollButtonClick,
  withGradientBgStyle,
  setGradientBg,
}) => {
  const dataSrc = data.children[0].children[0].params.find((param) => param.name === "config").value;
  const [jsonData, setJsonData] = useState();
  const pageData = {
    title: jsonData?.headerText,
    description: jsonData?.htmlText,
    helperText: jsonData?.helperText,
    assetSrc: jsonData?.src ? baseUrl + jsonData.src : undefined,
    placeholderSrc: jsonData?.placeholderSrc ? baseUrl + jsonData.placeholderSrc : undefined,
    fullSizeAnim: jsonData?.fullSizeAnim,
    additionalTitle: jsonData?.additionalTitle,
    additionalBgAnimSrc: jsonData?.additionalBgAnimSrc ? baseUrl + jsonData?.additionalBgAnimSrc : undefined,
    question: jsonData?.question,
    answers: jsonData?.answers,
    multipleChoice: jsonData?.multipleChoice,
    currentSlideCounter,
    slidesOverallCounter,
  };

  useEffect(() => {
    getJsonData(baseUrl + dataSrc, setJsonData);
  }, []);

  useEffect(() => {
    if (jsonData?.withGradientBgStyle) {
      setGradientBg(true);
    }
  }, [jsonData]);

  const handleInViewChange = (inView) => {
    if (inView) {
      onVisibleChange();
    }
  };

  const handleComplete = () => {
    const interactionId = data.children[0].children[0].id;
    callComplete(interactionId, data.id, currentSlideCounter === slidesOverallCounter);
  };

  if (!jsonData) {
    return null;
  }

  return (
    <PageDataContext.Provider value={pageData}>
      <InView onChange={handleInViewChange}>
        {({ ref }) => {
          return (
            <Container ref={ref}>
              {jsonData.type === SlideType.Video && (
                <VideoSlide callComplete={handleComplete} completed={data.completed} />
              )}
              {jsonData.type === SlideType.AnimWithText && (
                <AnimWithText
                  withGradientBgStyle={withGradientBgStyle}
                  onFloatClick={onFloatClick}
                  completed={data.completed}
                  callComplete={handleComplete}
                  onScrollButtonClick={onScrollButtonClick}
                />
              )}
              {jsonData.type === SlideType.ImageWithText && (
                <ImageWithText completed={data.completed} callComplete={handleComplete} />
              )}
              {jsonData.type === SlideType.ChooseAnswer && (
                <ChooseAnswer callComplete={handleComplete} />
              )}
              {jsonData.type === SlideType.TextBox && (
                <TextBox
                  isInteraction
                  completed={data.completed}
                  callComplete={handleComplete}
                />
              )}
            </Container>
          );
        }}
      </InView>
    </PageDataContext.Provider>
  );
};

Page.propTypes = {
  data: PropTypes.object,
  baserUrl: PropTypes.string,
  callComplete: PropTypes.func,
  currentSlideCounter: PropTypes.number,
  slidesOverallCounter: PropTypes.number,
  onFloatClick: PropTypes.func,
  onVisibleChange: PropTypes.func,
  onScrollButtonClick: PropTypes.func,
  withGradientBgStyle: PropTypes.bool,
};
