import { all, put, takeLatest, select, call } from "redux-saga/effects";

import { HostSex } from "../components/welcomeMessage/welcomeMessage.constants";
import * as dashboardApi from "../services/api";

import { selectHostSex } from "./dashboard.selectors";
import { DashboardTypes, DashboardActions } from "./dashboard.reducer";

function* getInitialData() {
  yield put(DashboardActions.setIsPending(true));
  const { data } = yield dashboardApi.getInitialData();
  if (data) {
    yield put(DashboardActions.getInitialDataSuccess(data));
  }
  yield put(DashboardActions.setIsPending(false));
}

function* getHostSex() {
  const { data } = yield dashboardApi.getHostSex();
  yield put(DashboardActions.switchHostSexSuccess(data.data.gender));
}

function* switchHostSex() {
  const currentHostSex = yield select(selectHostSex);
  const newSex = currentHostSex === HostSex.Man ? HostSex.Woman : HostSex.Man;
  const { data } = yield dashboardApi.switchHostSex(newSex);
  yield put(DashboardActions.switchHostSexSuccess(data.data.gender));
}

function* getAllUserVideos() {
  const { data } = yield dashboardApi.getAllUserVideos();
  yield put(DashboardActions.getAllUserVideosSuccess(data.data));
}

function* createUserVideo({ video }) {
  yield put(DashboardActions.setIsPending(true));
  try {
    yield dashboardApi.deleteUserVideo();
    yield dashboardApi.createUserVideo(video);
  } catch (err) {
    yield dashboardApi.createUserVideo(video);
  }
  yield call(getAllUserVideos);
  yield put(DashboardActions.setIsPending(false));
}

function* getCompetenceItems() {
  const { data } = yield dashboardApi.getCompetenceItems();
  yield put(DashboardActions.getCompetenceItemsSuccess(data.items));
}

function* fetchBanners() {
  const { data } = yield dashboardApi.fetchBanners();
  if (data) {
    yield put(DashboardActions.fetchBannersSuccess(data.data));
  }
}

export function* watchDashboard() {
  yield all([
    yield takeLatest(DashboardTypes.GET_INITIAL_DATA, getInitialData),
    yield takeLatest(DashboardTypes.GET_HOST_SEX, getHostSex),
    yield takeLatest(DashboardTypes.SWITCH_HOST_SEX, switchHostSex),
    yield takeLatest(DashboardTypes.CREATE_USER_VIDEO, createUserVideo),
    yield takeLatest(DashboardTypes.GET_ALL_USER_VIDEOS, getAllUserVideos),
    yield takeLatest(DashboardTypes.GET_COMPETENCE_ITEMS, getCompetenceItems),
    yield takeLatest(DashboardTypes.FETCH_BANNERS, fetchBanners),
  ]);
}
