import styled from "styled-components";

import { Color } from "../../../../theme/colors";
import { FontWeight } from "../../../../theme/fonts";

import { Button as ButtonBase } from "../../../button/button.component";

export const Container = styled.div`
  padding: 0 20px;
  width: 100%;
  max-width: 380px;
  margin: 80px auto 129px;
  display: flex;
  flex-direction: column;
`;

export const ErrorText = styled.div`
  font-size: 10px;
  line-height: 14px;
  padding-top: 7px;
  color: ${Color.red};
`;

export const SuccessText = styled(ErrorText)`
  color: ${Color.green};
`;

export const BoldText = styled.span`
  font-weight: ${FontWeight.Bold};
`;

export const Button = styled(ButtonBase)`
  margin-top: 50px;
`;

export const KeyEventWrapper = styled.div``;
