import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { styleWhenTrue } from "../../helpers/rendering";
import { Color, Media, FontWeight } from "../../theme";
import { ErrorMessage as ErrorMessageBase } from "../../theme/typography";

import { InputStyle } from "./input.constants";

export const Container = styled.div`
  height: 50px;
  background-color: ${Color.whiteHover};
  border: 1px solid ${Color.gray8};
  display: flex;
  margin-bottom: 10px;

  ${styleWhenTrue(propEq(InputStyle.ProfileForm, "$styleType"), css`
    background-color: ${Color.white};
  `)}

  ${styleWhenTrue(prop("disabled"), css`
    background-color: ${Color.whiteHover};
  `)}

  ${styleWhenTrue(prop("$invalid"), css`
    border-color: ${() => Color.brandBasic};
  `)}
`;

export const InputComponent = styled.input`
  font-size: 16px;
  height: 100%;
  border: none;
  flex: 1;
  padding: 0 20px;
  background: ${Color.transparent};

  &::placeholder {
    color: ${Color.gray4};
  }

  ${Media.tablet`
    padding: 0 30px;
  `}

  ${styleWhenTrue(prop("disabled"), css`
    font-weight: ${FontWeight.Normal};
    font-size: 14px;
  `)}

  ${styleWhenTrue(propEq(InputStyle.ProfileForm, "$styleType"), css`
    font-weight: ${FontWeight.SemiBold};
    padding: 14px 15px;

    ${Media.tablet`
      padding: 14px 15px;
    `}

    ${styleWhenTrue(prop("disabled"), css`
      font-weight: ${FontWeight.Normal};
      color: ${Color.gray9};
    `)}
  `)}
`;

export const ErrorMessage = styled(ErrorMessageBase)`
  padding-right: 30px;
  height: 100%;

  ${styleWhenTrue(propEq(InputStyle.ProfileForm, "$styleType"), css`
    padding-right: 15px;
  `)}
`;
