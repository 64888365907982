import { prop } from "ramda";
import styled, { css } from "styled-components";

import { Button as ButtonBase } from "../../components/button/button.component";
import { Checkbox as CheckboxBase } from "../../components/checkbox/checkbox.component";
import { hexToRgba } from "../../helpers/colors";
import { styleWhenTrue } from "../../helpers/rendering";
import { Color, FontWeight, Media, Separator as SeparatorBase } from "../../theme";

export const Container = styled.div`
  display: none;

  ${styleWhenTrue(prop("open"), css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${Color.almostWhite};
    z-index: 999999;
  `)}
`;

export const Popup = styled.div`
  padding: 35px 25px 30px;
  margin: 20px;
  background-color: ${Color.white};
  box-shadow: 0 8px 10px -5px ${hexToRgba(Color.black, 0.2)},
    0 16px 24px 2px ${hexToRgba(Color.black, 0.14)},
    0 6px 30px 5px ${hexToRgba(Color.black, 0.12)};

  ${Media.tablet`
    padding: 50px 50px 30px;
    width: 600px;
  `}
`;

export const Title = styled.p`
  font-weight: ${FontWeight.SemiBold};
  font-size: 16px;
  line-height: 20px;
  max-width: 240px;

  ${Media.tablet`
    max-width: 340px;
    font-size: 18px;
    line-height: 24px;
  `}
`;

export const Separator = styled(SeparatorBase)`
  margin: 25px 0 30px;

  ${Media.tablet`
    margin: 40px 0 30px;
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled(ButtonBase)`
  margin-left: auto;
`;

export const Checkbox = styled(CheckboxBase)`
  margin-top: 30px;

  ${Media.tablet`
    font-size: 14px;
    line-height: 22px;
  `}

  ol {
    list-style-type: decimal;
    margin-top: 10px;
    margin-left: 25px;
  }
`;
