import { ReactComponent as CertificatesIcon } from "../../../../images/certificates.svg";
import { ReactComponent as FormsIcon } from "../../../../images/forms.svg";
import { ReactComponent as NewsIcon } from "../../../../images/news.svg";
import { ReactComponent as TrainingsIcon } from "../../../../images/trainings.svg";
import { ReactComponent as CompetencesIcon } from "../../../../images/competences.svg";
import { ReactComponent as KnowledgeBaseIcon } from "../../../../images/knowledgeBase.svg";
import { ReactComponent as GoldMedal } from "../../../../images/goldMedal.svg";
import { ReactComponent as SilverMedal } from "../../../../images/silverMedal.svg";
import { ReactComponent as BronzeMedal } from "../../../../images/bronzeMedal.svg";
import { ReactComponent as GamificationIcon } from "../../../../images/gamification.svg";

export const getIcon = (name) => {
  switch (name) {
  case "certificate_assign":
  case "certificate_failed":
  case "certificate_success":
  case "new_certificate":
  case "certificate_remind": return CertificatesIcon;
  case "verification_rejected":
  case "verification_accepted":
  case "survey_share": return FormsIcon;
  case "news_new_comment":
  case "news_new_like":
  case "news_new": return NewsIcon;
  case "competence_confirmation_accept":
  case "competence_confirmation_reject": return CompetencesIcon;
  case "webinar_cancel":
  case "webinar_created":
  case "webinar_remind_presenter":
  case "webinar_remind_viewer":
  case "webinar_thanks":
  case "webinar_enroll":
  case "webinar_invite":
  case "event_enroll": return TrainingsIcon;
  case "content_expire":
  case "content_finish": return KnowledgeBaseIcon;
  case "award_gold": return GoldMedal;
  case "award_silver": return SilverMedal;
  case "award_bronze": return BronzeMedal;
  case "gamification_task_passed":
  case "gamification_new_level":
  case "gamification_challenge_remind":
  case "gamification_challenge_new":
  case "achievement_received":
  case "gamification_news_new":
  case "gamification_news_new_comment":
  case "gamification_news_new_like":
  case "gamification_meeting_new":
  case "gamification_challenge_passed": return GamificationIcon;
  default: return () => null;
  }
};
