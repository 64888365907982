import styled from "styled-components";

import { Button } from "../../../../components/button/button.component";
import { Media, Color, ContentMaxWidth } from "../../../../theme";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 60px 25px 45px;
  background-color: ${Color.almostWhite};
  width: 100%;

  ${Media.tablet`
    padding: 100px 25px 85px;
  `}
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: ${ContentMaxWidth};
  display: flex;
  flex-direction: column;

  ${Media.tablet`
    flex-direction: row;
  `}
`;

export const LeftColumn = styled.div`
  flex: 1;

  ${Media.tablet`
    margin-right: 44px;
  `}
`;

export const RightColumn = styled.div`
  flex: 1;

  ${Media.tablet`
    margin-left: 44px;
  `}
`;

export const Title = styled.h4`
  color: ${Color.black};
  font-size: 24px;
  font-weight: 800;
  line-height: 33px;
  margin-bottom: 20px;

  ${Media.tablet`
    font-size: 34px;
    line-height: 46px;
  `}
`;

export const Text = styled.p`
  font-size: 18px;
  line-height: 31px;
  color: ${Color.gray6};
  margin-bottom: 30px;
`;

export const ContactButton = styled(Button)`
  background-color: ${Color.blue};
`;
