import React from "react";

import { Icon } from "../../../components/icon/icon.component";
import { IconVariant } from "../../../components/icon/icon.constants";
import { Color } from "../../../theme/colors";
import { Icon as OldIcon } from "../../../UIElements";

import {
  LinkContainer,
  DisabledContainer,
  NavIcon,
  NavLabel,
} from "./navItem.styled";

const NavItem = (props) => {
  const {
    href,
    onClick = () => {},
    Icon: IconComponent,
    active,
    children,
  } = props;

  const renderIcon = () => (
    <Icon
      variant={IconVariant.Navigation}
      active={!!active}
      size={22}
    >
      <IconComponent />
    </Icon>
  );

  if (!href) {
    return (
      <DisabledContainer>
        <NavIcon disabled>
          <OldIcon
            icon="lock2"
            size="20"
            color={Color.gray4}
          />
        </NavIcon>
        <NavLabel>{children}</NavLabel>
      </DisabledContainer>
    );
  }

  return (
    <LinkContainer to={href} onClick={onClick}>
      <NavIcon $active={!!active}>
        {renderIcon(!!IconComponent)}
      </NavIcon>
      <NavLabel>{children}</NavLabel>
    </LinkContainer>
  );
};

export default NavItem;
