import { handleApiError, phpApi } from "../../../api";

export const fetchLessons = () => {
  return phpApi.get("/api/certificate").catch(handleApiError);
};

export const fetchCertificatePaths = () => {
  return phpApi.get("/api/certificate-path").catch(handleApiError);
};

export const fetchSurveys = () => {
  return phpApi.get("/api/certificate/survey").catch(handleApiError);
};

export const fetchPathConfirmations = () => {
  return phpApi.get("/api/certificate/path").catch(handleApiError);
};

export const fetchIndividuals = () => {
  return phpApi.get("/api/certificate/individual").catch(handleApiError);
};

export const fetchEvents = () => {
  return phpApi.get("/api/certificate/event").catch(handleApiError);
};

export const fetchStatistics = () => {
  return phpApi.get("/api/user/stats").catch(handleApiError);
};

export const fetchRanking = () => {
  return phpApi.get("/api/user/ranking").catch(handleApiError);
};

export const fetchCertificate = (id) => {
  return phpApi.get(`/api/certificate-path/${id}`).catch(handleApiError);
};
