import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import _ from "lodash";

import { renderWhenTrue } from "../../../../helpers/rendering";
import { BubbleChart } from "../../../../components/bubbleChart/bubbleChart.component";
import { DropdownInput } from "../../../../components/dropdownInput/dropdownInput.component";
import { ReactComponent as ThreeDotsIcon } from "../../images/threeDots.svg";

import { StatsItem } from "./statsItem/statsItem.component";
import {
  Container,
  TextWrapper,
  StarIcon,
  MainLabel,
  ChartWrapper,
  DotsMenuButton,
  DotsMenuDropdown,
  DotsMenuItem,
} from "./indicatorBox.styled";

export const IndicatorBox = ({
  className,
  label,
  order,
  competenceId,
  chartData,
  measures,
  selectedSkill,
  updateMeasure,
  removeChart,
}) => {
  const { t } = useTranslation();
  const competenceOption = {
    label: t("teamIndicators.indicatorBox.allMeasures"),
    name: t("teamIndicators.indicatorBox.allMeasures"),
    id: competenceId,
  };
  const [dotsDropdownOpen, setDotsDropdownOpen] = useState(false);
  const [chosenOption, setChosenOption] = useState(
    selectedSkill ? selectedSkill.competence : competenceOption
  );
  const formattedMeasures = measures.map((measure) => ({
    label: measure.name,
    name: measure.name,
    id: measure.id,
  }));
  const options = [
    competenceOption,
    ...formattedMeasures,
  ];

  const formattedChartData = chartData.map((data) => ({
    label: data.scale.name,
    value: data.value,
    percent: data.valuePercent,
  }));

  const handleChangeMeasure = ({ target }) => {
    const skill = options.find((option) => option.id === target.value);
    setChosenOption(skill);
    updateMeasure(order, competenceId, skill.id === competenceId ? null : skill.id);
  };

  const handleDelete = () => {
    removeChart(order);
  };

  const toggleDotsDropdown = () => setDotsDropdownOpen(!dotsDropdownOpen);

  const renderDotsMenuDropdown = renderWhenTrue(() => (
    <DotsMenuDropdown>
      <DotsMenuItem onClick={handleDelete}>
        {t("teamIndicators.indicatorBox.remove")}
      </DotsMenuItem>
    </DotsMenuDropdown>
  ));

  return (
    <Container className={className}>
      <DotsMenuButton onClick={toggleDotsDropdown}>
        <ThreeDotsIcon />
        {renderDotsMenuDropdown(dotsDropdownOpen)}
      </DotsMenuButton>
      <TextWrapper>
        <StarIcon />
        <MainLabel>{_.capitalize(label)}</MainLabel>
      </TextWrapper>
      <DropdownInput
        value={chosenOption.name}
        valueId={chosenOption.id}
        options={options}
        onChange={handleChangeMeasure}
        small
      />
      <ChartWrapper>
        <BubbleChart data={formattedChartData} />
      </ChartWrapper>
      {formattedChartData.map((level) => (
        <StatsItem
          key={level.label}
          label={level.label}
          value={level.value}
          percent={level.percent}
        />
      ))}
    </Container>
  );
};

IndicatorBox.propTypes = {
  className: PropTypes.string,
  order: PropTypes.number.isRequired,
  competenceId: PropTypes.number.isRequired,
  label: PropTypes.string,
  chartData: PropTypes.array,
  measures: PropTypes.array,
  selectedSkill: PropTypes.object,
  updateMeasure: PropTypes.func,
  removeChart: PropTypes.func,
};
