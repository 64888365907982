import styled from "styled-components";

import { colors, breakpoints } from "../UIGlobals";

const Tiles = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${colors().white};
  box-shadow: 0 0 20px 0 rgba(31, 27, 22, 0.03);
  width: ${props => (props.width ? props.width + "px" : "100%")};
  height: ${props => (props.height ? props.height + "px" : "auto")};
  margin-left: ${props => (props.marginLeft ? props.marginLeft + "px" : "0")};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom + "px" : "0")};
  padding: 37px 30px;
  overflow: hidden;
  @media (${breakpoints().maxM}) and (${breakpoints().minS}) {
    padding: 20px 0 20px 27px;
  }
`;

const TilesText = styled.span`
  font-size: ${props => (props.fontSize ? props.fontSize + "rem" : "1.2rem")};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft + "px" : "auto")};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 400)};
  color: ${props => (props.color ? colors()[props.color] : colors().deepGray)};
  ${props => (props.paddingBottom && "padding-bottom: " + props.paddingBottom + "px")};
  ${props => props.lineHeight && "line-height: " + props.lineHeight + "px"};
  ${props => props.landscapeLeftPadding && `
    @media (${breakpoints().maxM}) and (${breakpoints().minS}) {
      padding-left: ${props.landscapeLeftPadding}px;
    }
  `}
`;

const IconText = styled.span`
  color: ${colors().deepGray};
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  padding-top: 9px;
  ${props => props.lineHeight && "line-height: " + props.lineHeight + "px"};
`;

export {
  Tiles,
  TilesText,
  IconText,
};
