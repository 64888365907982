import { prop, propEq } from "ramda";
import styled, { css } from "styled-components";

import { hexToRgba } from "../../helpers/colors";
import { ReactComponent as ArrowSvg } from "../../images/arrow-3.svg";
import { ReactComponent as MessageSvg } from "../../images/message.svg";
import { ReactComponent as SurveyAndTestSvg } from "../../images/surveyAndTest.svg";
import { ReactComponent as VideoSvg } from "../../images/video3.svg";
import { ReactComponent as QuestionnaireSvg } from "../../images/questionnaire.svg";
import { ReactComponent as HourglassSvg } from "../../images/hourglass.svg";
import { ReactComponent as PointsSvg } from "../../images/pointsIcon.svg";
import { styleWhenTrue } from "../../helpers/rendering";
import { Color, FontWeight, Media } from "../../theme";

export const Container = styled.div`
  padding: 36px 20px 25px;
  border-top: 10px solid ${Color.almostWhite};
  background-color: ${Color.white};

  ${Media.tablet`
    padding: 50px 35px 8px 40px;
    border-top: 2px solid ${Color.almostWhite};
  `}
`;

export const HeaderWrapper = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 100%;
  background-color: ${Color.transparent};
  border: none;
  text-align: left;
  padding-bottom: 34px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  ${Media.tablet`
    width: auto;
    padding-right: 20px;
  `}
`;

export const Arrow = styled(ArrowSvg)`
  fill: ${Color.gray9};
  margin-left: auto;
  transition: 0.3s;
  flex-shrink: 0;

  ${Media.tablet`
    margin-left: 60px;
  `}

  ${styleWhenTrue(prop("open"), css`
    transform: rotateX(180deg);
  `)}

  ${styleWhenTrue(prop("hidden"), css`
    display: none;
  `)}
`;

export const IconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${() => hexToRgba(Color.brandBasic, 0.1)};

  &:after {
    ${styleWhenTrue(prop("isNew"), css`
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      top: 0;
      right: 0;
      border-radius: 50%;
      background-color: ${Color.orange};
    `)}
  }

  ${Media.tablet`
    width: 32px;
    border-radius: 0;
    background-color: ${Color.transparent};
  `}
`;

const iconStyles = css`
  position: relative;
  fill: ${() => Color.brandBasic};
  width: 26px;

  ${styleWhenTrue(propEq("true", "inactive"), css`
    fill: ${Color.gray7};
  `)}
`;

export const MessageIcon = styled(MessageSvg)`
  ${iconStyles}
`;

export const SurveyAndTestIcon = styled(SurveyAndTestSvg)`
  ${iconStyles}
`;

export const VideoIcon = styled(VideoSvg)`
  ${iconStyles}
`;

export const QuestionnaireIcon = styled(QuestionnaireSvg)`
  ${iconStyles}
`;

export const TitleAndDateColumn = styled.div`
  margin: 0 20px 0 35px;
`;

export const Title = styled.h1`
  font-weight: ${FontWeight.SemiBold};
  font-size: 16px;
  line-height: 23px;
  color: ${Color.deepGray};
`;

export const DateText = styled.p`
  font-weight: ${FontWeight.SemiBold};
  font-size: 13px;
  line-height: 24px;
  color: ${() => Color.brandBasic};

  ${styleWhenTrue(prop("inactive"), css`
    color: ${Color.gray9};
  `)}
`;

export const HourglassIcon = styled(HourglassSvg)``;

export const ProgressChildren = styled.p`
  font-size: 12px;
  color: ${Color.deepGray};
`;

export const PointsMark = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  height: 33px;
  background-color: ${Color.almostWhite};
  border-radius: 3px;
  font-weight: bold;
  font-size: 14px;
  color: ${Color.deepGray};
  margin-left: 20px;
  flex-shrink: 0;

  ${styleWhenTrue(prop("bright"), css`
    background-color: ${Color.lightYellow};
    color: ${Color.yellow};
  `)}
`;

export const PointsMarkIcon = styled(PointsSvg)`
  margin-right: 5px;
`;
