import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { renderWhenTrue } from "../../../../../../helpers/rendering";

import {
  DropdownToggler,
  DropdownContent,
  DropdownOption,
  TextColumn,
  Title,
  Text,
  ArrowButton,
  ArrowIcon,
} from "./dropdown.styled";

export const Dropdown = ({
  title,
  values,
  labelIndex,
  updateValidityState,
  enableValidating,
}) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [italicText, setItalicText] = useState(true);
  const [isCorrectSelected, setIsCorrectSelected] = useState(false);

  const answerSelected = useMemo(() => selectedIndex !== null, [selectedIndex]);

  const wrongTheme = useMemo(() => enableValidating && !isCorrectSelected,
    [enableValidating, isCorrectSelected]);

  const handleOptionClick = (index) => {
    if (italicText) {
      setItalicText(false);
    }
    setSelectedIndex(index);
    const isCorrect = values[index].yAxisLabel.includes(labelIndex);
    setIsCorrectSelected(isCorrect);
    updateValidityState(isCorrect);
    setDropdownOpen(false);
  };

  const renderDropdownContent = renderWhenTrue(() => (
    <DropdownContent>
      {values.map((value, index) => (
        <DropdownOption
          key={index}
          onClick={() => handleOptionClick(index)}
        >
          {value.text}
        </DropdownOption>
      ))}
    </DropdownContent>
  ));

  const text = answerSelected ? values[selectedIndex].text : t("interactions.matchToLabelBox.dropdown.select");

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <>
      <DropdownToggler
        wrongTheme={wrongTheme}
        setValidatingTheme={enableValidating}
        onClick={toggleDropdown}
      >
        <TextColumn>
          <Title
            wrongTheme={wrongTheme}
            setValidatingTheme={enableValidating}
          >
            {title}
          </Title>
          <Text italic={italicText}>{text}</Text>
        </TextColumn>
        <ArrowButton open={dropdownOpen}>
          <ArrowIcon />
        </ArrowButton>
      </DropdownToggler>
      {renderDropdownContent(dropdownOpen)}
    </>
  );
};

Dropdown.propTypes = {
  title: PropTypes.string,
  values: PropTypes.array,
  labelIndex: PropTypes.number,
  updateValidityState: PropTypes.func,
  enableValidating: PropTypes.bool,
};
