import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Loader } from "../../../../components/loader/loader.component";
import { Button } from "../../../../components/button/button.component";
import { ButtonVariant } from "../../../../components/button/button.constants";
import { selectUserId } from "../../../../userContext/redux/userContext.selectors";
import { CookieName } from "../../../../app/cookiePopup/cookiePopup.constants";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { isCookieEnabled } from "../../../../helpers/isCookieEnabled";
import { selectUserCompetenceList } from "../../../competence/redux/competence.selectors";
import { TeamIndicatorsActions } from "../../redux/teamIndicators.reducer";
import { selectIsPending, selectChartList } from "../../redux/teamIndicators.selectors";
import { CompetenceType } from "../../teamIndicators.constants";
import { IndicatorBox } from "../indicatorBox/indicatorBox.component";

import { PopulationTooltip } from "./populationTooltip/populationTooltip.component";
import { PopulationModal } from "./populationModal/populationModal.component";
import { NewChartBox } from "./newChartBox/newChartBox.component";
import {
  StrategicPopulationsItemKey,
  CommonPopulationsItemKey,
  TooltipOpenStorageKey,
} from "./indicatorsTab.constants";
import {
  Container,
  Header,
  DescriptionPanel,
  PanelRow,
  DiagramIcon,
  IndicatorList,
  ErrorPanel,
  WarningIcon,
} from "./indicatorsTab.styled";

export const IndicatorsTab = ({ className, competenceType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPending = useSelector(selectIsPending);
  const userId = useSelector(selectUserId);
  const chartList = useSelector(selectChartList);
  const competences = useSelector(selectUserCompetenceList);
  const tabCompetences = competences.filter(
    ({ competence }) => competence.type.enum === competenceType
  );
  const [storageStrategicCharts, setStorageStrategicCharts] = useLocalStorage(`${StrategicPopulationsItemKey}${userId}`);
  const [storageCommonCharts, setStorageCommonCharts] = useLocalStorage(`${CommonPopulationsItemKey}${userId}`);
  const [tooltipInitialState, setTooltipInitialState] = useLocalStorage(TooltipOpenStorageKey);
  const getInitialTooltipState = () => tooltipInitialState === false ? false : true;
  const [tooltipOpen, setTooltipOpen] = useState(getInitialTooltipState);
  const [modalOpen, setModalOpen] = useState(false);
  const [chartStorageChanged, setChartStorageChanged] = useState(false);

  const storageCharts = competenceType === CompetenceType.Strategic
    ? storageStrategicCharts
    : storageCommonCharts;
  const isStrategicTab = competenceType === CompetenceType.Strategic;

  useEffect(() => {
    return () => {
      dispatch(TeamIndicatorsActions.clearCharts());
    };
  }, [dispatch]);

  useEffect(() => {
    setChartStorageChanged(false);
  }, [competenceType]);

  const setStorageCharts = (charts) => {
    if (isCookieEnabled(CookieName.Functional)) {
      if (isStrategicTab) {
        setStorageStrategicCharts(charts);
      } else {
        setStorageCommonCharts(charts);
      }
    }
  }

  useEffect(() => {
    if (storageCharts && !chartStorageChanged) {
      if (storageCharts.length) {
        dispatch(TeamIndicatorsActions.fetchChartsData(storageCharts));
      }
      setChartStorageChanged(true);
    } else if (tabCompetences.length && !chartStorageChanged) {
      const chartIDs = tabCompetences.map(
        ({ competence }) => ({ competenceId: competence.id, selectedSkill: null })
      );
      dispatch(TeamIndicatorsActions.fetchChartsData(chartIDs));
      setStorageCharts(chartIDs);
      setChartStorageChanged(true);
    }
  }, [
    dispatch,
    storageCharts,
    setStorageCharts,
    chartStorageChanged,
    competenceType,
    tabCompetences,
  ]);

  const handleOpenModal = () => setModalOpen(true);

  const handleCloseModal = () => setModalOpen(false);

  const handleCloseTooltip = () => {
    setTooltipOpen(false);
    if (isCookieEnabled(CookieName.Functional)) setTooltipInitialState(false);
  };

  const updateMeasure = (order, competenceId, skillId) => {
    dispatch(TeamIndicatorsActions.updateChartMeasure(order, competenceId, skillId));
    const newStorageCharts = [...storageCharts];
    newStorageCharts[order] = { competenceId, selectedSkill: skillId };
    setStorageCharts(newStorageCharts);
  };

  const removeChart = (order) => {
    dispatch(TeamIndicatorsActions.removeChart(order));
    const newStorageCharts = [...storageCharts];
    newStorageCharts.splice(order, 1);
    setStorageCharts(newStorageCharts);
  };

  const handleAddNewChart = (charts) => {
    setStorageCharts(charts);
  };

  const renderChartBox = (data, index) => {
    const { competence, population, selectedSkill } = data;
    const formattedSelectedSkill = selectedSkill && {
      ...selectedSkill,
      competence: {
        ...selectedSkill.competence,
        label: selectedSkill.competence.name,
      },
    };

    return (
      <IndicatorBox
        key={`${index}${competence.id}${selectedSkill && selectedSkill.competence.id}`}
        order={index}
        competenceId={competence.id}
        label={competence.name}
        chartData={formattedSelectedSkill ? formattedSelectedSkill.population : population}
        measures={competence.skills}
        selectedSkill={formattedSelectedSkill}
        updateMeasure={updateMeasure}
        removeChart={removeChart}
      />
    );
  };

  const renderChartBoxes = () => (
    <IndicatorList>
      {chartList.map(renderChartBox)}
      <NewChartBox competenceType={competenceType} onAddNew={handleAddNewChart} />
    </IndicatorList>
  );

  const renderTooltip = renderWhenTrue(() => <PopulationTooltip handleClose={handleCloseTooltip} />);

  const renderLoader = renderWhenTrue(() => <Loader />);

  const renderContent = renderWhenTrue(() => (
    <>
      <Header>
        {t("teamIndicators.indicatorsTab.population")}
      </Header>
      {renderChartBoxes()}
      <DescriptionPanel>
        <PanelRow>
          <DiagramIcon />
          {t("teamIndicators.indicatorsTab.populationQuestion")}
        </PanelRow>
        <PanelRow>
          <Button type={ButtonVariant.Outlined} onClick={handleOpenModal}>
            {t("teamIndicators.indicatorsTab.check")}
          </Button>
        </PanelRow>
      </DescriptionPanel>
      <PopulationModal isOpen={modalOpen} handleClose={handleCloseModal} />
      {renderTooltip(tooltipOpen)}
    </>
  ));

  const renderNoCompetencesAvailablePanel = renderWhenTrue(() => (
    <>
      <Header>
        {t("teamIndicators.indicatorsTab.population")}
      </Header>
      <ErrorPanel>
        <WarningIcon />
        {t("teamIndicators.indicatorsTab.noCompetencesAvailable")}
      </ErrorPanel>
    </>
  ));

  return (
    <Container className={className}>
      {renderNoCompetencesAvailablePanel(!isPending && !tabCompetences.length)}
      {renderContent(!isPending && !!tabCompetences.length)}
      {renderLoader(isPending)}
    </Container>
  );
};

IndicatorsTab.propTypes = {
  className: PropTypes.string,
  competenceType: PropTypes.string,
};
