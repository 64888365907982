import { createActions, createReducer } from "reduxsauce";

export const { Types: OnboardingTypes, Creators: OnboardingActions } = createActions(
  {
    getInitialData: null,
    getInitialDataSuccess: ["data"],
    getOnboardingModeSuccess: ["user"],
    registerFileDownloading: ["id"],
    setIsPending: ["isPending"],
    getHeroesList: null,
    getHeroesListSuccess: ["heroesList"],
    setHero: ["id"],
    setHeroSuccess: ["hero"],
  },
  { prefix: "ONBOARDING/" }
);

const INITIAL_STATE = {
  isPending: true,
  data: {
    certificatePath: [],
    contactInfo: [],
    statistic: {
      items: {
        count: null,
        label: null,
        total: null,
        type: null,
      },
      stages: {
        count: null,
        label: null,
        total: null,
        type: null,
      }
    },
    user: {
      availableRoutes: {},
      avatar: null,
      brands: [],
      email: null,
      experience: {
        company: {
          id: null,
          name: null,
          parents: [],
        },
        from: null,
        position: {
          id: null,
          name: null,
        },
        to: null,
      },
      firstLogin: null,
      id: null,
      language: null,
      logins: [],
      name: null,
      onboardingHero: null,
      onboardingMode: null,
      phone: null,
      role: {
        value: null,
        id: null,
        name: null,
      },
      surname: null,
    },
    heroesList: [],
  },
};

const getInitialDataSuccess = (state, { data }) => {
  return ({
    ...state,
    data,
  });
};

const getOnboardingModeSuccess = (state, { user }) => {
  return ({
    ...state,
    data: {
      ...state.data,
      user,
    },
  });
};

const getHeroesListSuccess = (state, { heroesList }) => {
  return ({
    ...state,
    heroesList,
  });
};

const setHeroSuccess = (state, { hero }) => {
  return ({
    ...state,
    data: {
      ...state.data,
      user: {
        ...state.data.user,
        onboardingHero: {
          name: hero.hero,
        },
      }
    }
  })
}

const setIsPending = (state, { isPending }) => ({ ...state, isPending });

export const reducer = createReducer(INITIAL_STATE, {
  [OnboardingTypes.GET_INITIAL_DATA_SUCCESS]: getInitialDataSuccess,
  [OnboardingTypes.GET_ONBOARDING_MODE_SUCCESS]: getOnboardingModeSuccess,
  [OnboardingTypes.GET_HEROES_LIST_SUCCESS]: getHeroesListSuccess,
  [OnboardingTypes.SET_HERO_SUCCESS]: setHeroSuccess,
  [OnboardingTypes.SET_IS_PENDING]: setIsPending,
});
