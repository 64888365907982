import styled, { css } from "styled-components";

import { FontWeight, Color, Media } from "../../../../../theme";
import { ReactComponent as ArrowSvg } from "../../../../../images/arrow.svg";

export const Container = styled.div`
  width: 100%;
  margin-top: 60px;

  ${Media.tabletWide`
    width: calc(60% - 10px);
  `}
`;

export const CounterWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 21px 35px;
  background-color: ${Color.white};
`;

export const CounterLabel = styled.p`
  margin-left: 15px;
  font-size: 13px;
  line-height: 22px;
`;

export const Counter = styled.span`
  font-weight: ${FontWeight.Bold};
`;

export const BadgesList = styled.div`
  padding: 20px;
  position: relative;

  ${Media.tablet`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    margin-top: 1px;
  `}
`;

export const ArrowIcon = styled(ArrowSvg)`
  fill: ${Color.gray1};
`;

const arrowButtonStyle = css`
  border: none;
  background-color: ${Color.transparent};
  position: absolute;
  top: 50%;
`;

export const LeftArrowButton = styled.button`
  ${arrowButtonStyle}
  transform: translateY(-50%);
  left: 25px;
`;

export const RightArrowButton = styled.button`
  ${arrowButtonStyle}
  transform: translateY(-50%);
  right: 25px;

  ${ArrowIcon} {
    transform: rotate(180deg);
  }
`;
