import styled from "styled-components";

import { Button as ButtonBase } from "../../../../../components/button/button.component";
import { FontWeight } from "../../../../../theme/fonts";
import { Color } from "../../../../../theme/colors";
import { Media } from "../../../../../theme/media";
import { ReactComponent as ShapesSvg } from "../../images/opponentShapes.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 40px 25px;
  width: 100%;

  ${Media.tablet`
    flex-direction: row;
    padding: 80px;
    min-height: 650px;
  `}
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  height: 100%;

  ${Media.tablet`
    text-align: left;
  `}
`;

export const Name = styled.p`
  font-size: 12px;
  line-height: 17px;
  font-weight: ${FontWeight.ExtraBold};
  color: ${Color.blue};
  text-transform: uppercase;
  margin-top: 40px;

  ${Media.tablet`
    margin-top: 0;
  `}
`;

export const Text = styled.p`
  max-width: 350px;
  font-weight: ${FontWeight.SemiBold};
  margin: 10px 0 30px;
  font-size: 20px;
  line-height: 27px;

  ${Media.tablet`
    font-size: 32px;
    line-height: 43px;
  `}
`;

export const Shapes = styled(ShapesSvg)`
  width: 159px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;

  ${Media.tablet`
    display: block;
  `}
`;

export const RightColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  z-index: 1;
`;

export const Button = styled(ButtonBase)`
  width: auto;
  background-color: ${Color.blue};
`;

export const AnimationWrapper = styled.div`
  pointer-events: none;
  width: 260px;
  height: 260px;

  ${Media.tablet`
    height: auto;
    width: auto;

    svg {
      min-height: 260px;
      min-width: 300px;
    }
  `}
`;
