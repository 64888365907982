import React from "react";
import { useTranslation } from "react-i18next";

import { Container, Input, Label } from "./minutesSetter.styled";

export const MinutesSetter = ({
  minutes,
  setMinutes,
  disabled,
}) => {
  const { t } = useTranslation();

  const handleChange = ({ target }) => {
    setMinutes(target.value);
  };

  return (
    <Container disabled={disabled}>
      <Input
        type="text"
        disabled={disabled}
        onChange={handleChange}
        value={minutes}
      />
      <Label>{t("webinars.classes.minutes")}</Label>
    </Container>
  );
};
