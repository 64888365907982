import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { OnboardingMode } from "../../../../app/app.constants";
import { Separator } from "../../../../theme/typography";
import { selectFinishedUserStages, selectOnboardingMode } from "../../redux/onboarding.selectors";

import { Monsters } from "./monsterStages.constants";
import {
  Container,
  Content,
  MonstersWrapper,
  Monster,
  ImageWrapper,
  Image,
  Name,
  Stage,
} from "./monsterStages.styled";

export const MonsterStages = ({ className, onClick, disabled }) => {
  const { t } = useTranslation();
  const userStagesFinished = useSelector(selectFinishedUserStages);
  const onboardingMode = useSelector(selectOnboardingMode);
  const leasinGoMode = onboardingMode === OnboardingMode.LeasinGo;
  const filteredMonsters = leasinGoMode
    ? Monsters.filter((monster, index) => {
      if (index === 2 || index === 3) return null;
      return monster;
    })
    : Monsters;

  const handleMonsterClick = (index) => {
    onClick(index);
    setTimeout(() => onClick(null), 1); // deactivate scroll behavior
  };

  const renderMonsters = () => (
    filteredMonsters.map((monster, index) => {
      const isActive = index <= userStagesFinished;
      const imageSrc = isActive ? monster.image : monster.blurredImage;

      return (
        <Monster
          key={monster.name}
          onClick={() => handleMonsterClick(index)}
          disabled={!isActive}
          cursorDefault={disabled}
        >
          <ImageWrapper>
            <Image src={imageSrc} />
          </ImageWrapper>
          <Name disabled={!isActive}>{monster.name}</Name>
          <Stage disabled={!isActive}>
            {t("onboarding.monsterStages.stage", { stage: index + 1 })}
          </Stage>
        </Monster>
      );
    })
  );

  return (
    <Container className={className}>
      <Content>
        <Separator $narrow />
        <MonstersWrapper leasinGoMode={leasinGoMode}>
          {renderMonsters()}
        </MonstersWrapper>
        <Separator $narrow />
      </Content>
    </Container>
  );
};

MonsterStages.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
