import axios from "axios";
import * as CryptoJS from "crypto-js";

import { UserContextTypes } from "../userContext/redux/userContext.reducer";
import { CookieName } from "../app/cookiePopup/cookiePopup.constants";
import { isCookieEnabled } from "../helpers/isCookieEnabled";
import { store } from "../store";
import { AppConfig } from "../appConfig";
import { SET_NETWORK_ERROR } from "../app/networkErrorModal/actions";
import { clearApiCache } from "../helpers/clearApiCache";

const keyhex = "22102013131120152210201313112015";
let logoutTimeout = null;

const encodeRequest = (plainText) => {
  const salt = CryptoJS.lib.WordArray.random(16);
  const iv = CryptoJS.lib.WordArray.random(16);
  const key = CryptoJS.PBKDF2(keyhex, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 128 });
  const encrypted = CryptoJS.AES.encrypt(plainText, key, { iv: iv });

  return (
    CryptoJS.enc.Hex.stringify(iv) +
    CryptoJS.enc.Hex.stringify(salt) +
    CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
  );
}

const decodeResponse = (responseData) => {
  const response = {
    iv: responseData.substr(0,32),
    salt: responseData.substr(32,32),
    ciphertext: responseData.substr(64),
  };
  const encrypted = response.ciphertext;
  const salt = CryptoJS.enc.Hex.parse(response.salt);
  const iv = CryptoJS.enc.Hex.parse(response.iv);
  const key = CryptoJS.PBKDF2(keyhex, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 128 });
  const decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });
  const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedString);
};

const requestInterceptor = (config) => {
  const state = store.getState();
  const { token = false, deviceToken = false } = state.userContext.data;
  const brand = state.appSettings.currentBrand;

  if (config.params && !config.sendInBody) {
    const stringifiedParams = JSON.stringify(config.params);
    const payload = AppConfig.encryptMode ? encodeRequest(stringifiedParams) : encodeURIComponent(stringifiedParams);
    config.url += `?payload=${payload}`;
    delete config.params;
  } else if (config.data && !config.sendInBody) {
    const stringifiedData = JSON.stringify(config.data);
    const payload = AppConfig.encryptMode ? encodeRequest(stringifiedData) : encodeURIComponent(stringifiedData);
    config.url += `?payload=${payload}`;

    if (config.removeDataBody) {
      delete config.data;
    }
  }

  config.headers["LANGUAGE"] = isCookieEnabled(CookieName.Functional) && localStorage.getItem("Lng")
    ? localStorage.getItem("Lng")
    : "pl";

  if (token) {
    config.headers["X-AUTH-TOKEN"] = token;
  }

  if (deviceToken) {
    config.headers["X-DEVICE-TOKEN"] = deviceToken;
  }

  if (brand) {
    config.headers["BRAND"] = brand;
  }

  return config;
};

const responseInterceptor = (response) => {
  if (response && response.data && AppConfig.encryptMode) {
    response.data = decodeResponse(response.data);
  }
  return response;
};

const responseErrorInterceptor = (error) => {
  if(error.response && error.response.data && AppConfig.encryptMode){
    error.response.data = decodeResponse(error.response.data);
  }
  if (error.toString().indexOf("Network Error") !== -1) {
    store.dispatch({ type: SET_NETWORK_ERROR, error: true });
  }
  return Promise.reject(error);
};

export const nodeApi = axios.create({
  baseURL: AppConfig.nodeApi,
  responseType: "json",
});

nodeApi.interceptors.request.use(requestInterceptor);
nodeApi.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

export const phpApi = axios.create({
  baseURL: AppConfig.api,
  responseType: "json",
});

phpApi.interceptors.request.use(requestInterceptor);
phpApi.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

export const handleApiError = (error, redirectTo) => {
  const { response } = error;
  if (response) {
    if (response.status !== 401 && response.data?.message) {
      alert(response.data.message);
    }
    if (response.status === 401 || response.status === 422) {
      store.dispatch({ type: UserContextTypes.SET_LOGGED_OUT, data: {} });
      clearTimeout(logoutTimeout);
      logoutTimeout = setTimeout(() => {
        window.location.reload();
      }, 300);
    }
    if (response.status === 403) {
      window.location.replace(redirectTo || "/");
    }
    if (response.status === 409) {
      window.location.reload();
    }
  }
  if (error.toString().indexOf("Network Error") !== -1) {
    store.dispatch({ type: SET_NETWORK_ERROR, error: true });
  }
  return [];
};

export const handleOnboardingApiError = (error) => {
  const { response } = error;
  if (response && response.status === 403) {
    if (response.data && response.data.message) {
      alert(response.data.message);
    }
    store.dispatch({ type: UserContextTypes.SET_LOGGED_OUT, data: {} });
    clearApiCache();
    window.location.replace("/");
  } else {
    handleApiError(error);
  }

  return [];
}

export const handleWebinarApiError = (error, redirectTarget) => {
  const { response } = error;
  if (response?.status === 400) {
    if (response.data?.data) {
      window.location.replace(`/webinar/${response.data.data.roomId}`);
    }
  } else {
    handleApiError(error, redirectTarget);
  }

  return [];
}
