import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { styleWhenTrue } from "../../helpers/rendering";
import { ReactComponent as SearchSvg } from "../../images/searchSmall.svg";
import { Color } from "../../theme";
import { breakpoints } from "../../UIGlobals";

export const RightNavContainer = styled.div`
  width: auto;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 120px;
  z-index: 101;

  ${styleWhenTrue(prop("$lessZIndex"), css`
    z-index: 100;
  `)}
`;

export const RightNavIcon = styled.i`
  position: relative;
  width: 70px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NotificationIndicator = styled.div`
  position: absolute;
  right: 24px;
  top: 10px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${() => Color.brandBasic};
`;

export const SearchIcon = styled(SearchSvg)`
  width: 18px;
  height: 18px;
  stroke: ${Color.black};
  transition: 0.2s;

  ${styleWhenTrue(propEq("true", "active"), css`
    stroke: ${() => Color.brandBasic};
  `)}
`;

export const SearchButton = styled.button`
  background-color: ${Color.transparent};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 68px;
  height: 70px;
  position: absolute;
  right: 0;

  &:hover {
    ${SearchIcon} {
      opacity: 0.7;
    }
  }

  @media (${breakpoints().minL}) {
    border-right: 1px solid ${Color.gray5};
    position: static;
  }
`;

export const TutorialWrapper = styled.div`
  position: relative;
`;
