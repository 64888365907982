import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Button } from "../../../../../components/button/button.component";
import { renderWhenTrue } from "../../../../../helpers/rendering";

import {
  Header,
  Container,
  Box,
  ThumbnailHeader,
  ImageThumbnailContainer,
  ImageThumbnail,
  InfoText,
  ModalFooter,
  MovieIcon,
  TransferInfoWrapper,
  RequiredField,
  InputWrapper,
  Input,
} from "./modalContentStep2.styled";
import { dataURItoBlob } from "./modalContentStep2.helpers";

export const ModalContentStep2 = ({
  frames,
  title,
  selectedThumbnail,
  handleChangeInput,
  handleChangeThumbnail,
  handleSendFile,
}) => {
  const { t } = useTranslation();

  const isThumbnailSelected = (index) =>
    selectedThumbnail ? index === selectedThumbnail.id : false;

  const renderFrames = renderWhenTrue(() =>
    frames.map((frameUrl, index) => (
      <ImageThumbnail
        key={index}
        onClick={() =>
          handleChangeThumbnail({ id: index, blob: dataURItoBlob(frameUrl) })
        }
        selected={isThumbnailSelected(index)}
        src={frameUrl}
      />
    ))
  );

  return (
    <Container onSubmit={handleSendFile}>
      <Box>
        <Header>{t("dashboard.uploadVideo.completeTheInformation")}</Header>
        <InputWrapper>
          <Input
            required
            onChange={handleChangeInput}
            value={title}
            name="title"
            placeholder={t("dashboard.uploadVideo.addTitle")}
          />
          <RequiredField>
            {t("dashboard.uploadVideo.requiredField")}
          </RequiredField>
        </InputWrapper>
        <ThumbnailHeader>
          {t("dashboard.uploadVideo.selectThumbnail")}
        </ThumbnailHeader>
        <ImageThumbnailContainer>
          {renderFrames(!!frames.length)}
        </ImageThumbnailContainer>
      </Box>
      <ModalFooter>
        <TransferInfoWrapper>
          <MovieIcon />
          <InfoText>
            {t("dashboard.uploadVideo.fileTransferCompleted")}
          </InfoText>
        </TransferInfoWrapper>
        <Button disabled={!title} type="submit">
          {t("dashboard.uploadVideo.sendFile")}
        </Button>
      </ModalFooter>
    </Container>
  );
};

ModalContentStep2.propTypes = {
  frames: PropTypes.array,
  title: PropTypes.string,
  selectedThumbnail: PropTypes.object,
  handleChangeInput: PropTypes.func,
  handleChangeThumbnail: PropTypes.func,
  handleSendFile: PropTypes.func,
};
