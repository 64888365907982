import { prop } from "ramda";
import styled, { css } from "styled-components";

import { hexToRgba } from "../../../../helpers/colors";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color, FontWeight } from "../../../../theme";
import { ReactComponent as ArrowSvg } from "../../images/dropdownArrow.svg";
import { ReactComponent as AddUsersSvg } from "../../images/addUser.svg";
import { ReactComponent as TrashSvg } from "../../images/trash.svg";
import { ReactComponent as ExcludeSvg } from "../../images/exclude.svg";
import { ReactComponent as ExitSvg } from "../../images/exit.svg"
import { ReactComponent as SquaresSvg } from "../../images/squares.svg"

export const RoomRow = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 66px;
  border-top: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  border-bottom: 1px solid ${hexToRgba(Color.gray5, 0.8)};

  & + & {
    border-top: none;  
  }
`;

export const Dropdown = styled.div`
  margin-bottom: 30px;
`;

export const ArrowIcon = styled(ArrowSvg)`
  path {
    fill: ${() => Color.brandBasic};
  }

  ${styleWhenTrue(prop("open"), css`
    transform: rotate(180deg);
  `)}
`;

export const GoToRoomButton = styled.button`
  border: none;
  text-transform: uppercase;
  background-color: ${Color.transparent};
  color: ${() => Color.brandBasic};
  font-weight: ${FontWeight.Bold};
  margin-right: 15px;
  padding: 0;
`;

export const RoomName = styled.p`
  font-size: 14px;
  color: ${Color.deepGray};
  font-weight: ${FontWeight.Bold};
  margin: 0 5px 0 20px;
`;

export const Amount = styled.p`
  letter-spacing: 2px;
  color: ${Color.gray9};
  margin-right: auto;
`;

export const AddUsersButton = styled.button`
  width: 36px;
  height: 36px;
  background-color: ${() => hexToRgba(Color.brandBasic, 0.1)};
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
`;

export const AddUsersIcon = styled(AddUsersSvg)`
  path {
    fill: ${() => Color.brandBasic};
  }
`;

export const RemoveButton = styled(AddUsersButton)`
  background-color: ${Color.almostWhite};
  margin-left: 10px;
`;

export const TrashIcon = styled(TrashSvg)`
  path {
    fill: ${() => Color.brandBasic};
  }
`;

export const UserRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  & + & {
    margin-top: 10px;
  }
`;

export const FullName = styled.p`
  margin-left: 10px;
  font-weight: ${FontWeight.SemiBold};
  font-size: 12px;
  line-height: 16px;
  color: ${Color.darkGray};
`;

export const ExcludeIcon = styled(ExcludeSvg)`
  path {
    fill: ${() => Color.brandBasic};
  }
`;

export const ExcludeButton = styled(RemoveButton)`
  margin-left: auto;
`;

export const ExitIcon = styled(ExitSvg)`
  path {
    fill: ${() => Color.brandBasic};
  }
`;

export const SquaresIcon = styled(SquaresSvg)`
  path {
    fill: ${() => Color.brandBasic};
  }
`;
