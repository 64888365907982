import styled, { css }  from "styled-components";
import { prop, propEq } from "ramda";

import { Media, Color, Transitions, FontWeight } from "../../../../theme";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { ReactComponent as CheckSvg } from "../../images/check.svg";
import { CircleColorType } from "../filters/filters.constants";

export const Container = styled.li`
  position: relative;
  font-size: 15px;
  text-align: left;
  font-weight: ${FontWeight.Normal};
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: ${Transitions.custom};
  
  & + & {
    margin-top: 17px;
  }
  
  &:hover {
    opacity: 0.7;
  }

  ${styleWhenTrue(prop("disabled"), css`
    pointer-events: none;
    color: ${Color.gray1};
  `)}
`;

export const Check = styled.div`
  opacity: 0;
  margin-right: 10px;
  transition: ${Transitions.custom};
  
  ${styleWhenTrue(prop("active"), css`
    opacity: 1;
  `)}
  
  ${Media.tablet`  
    margin-right: 0;
    position: absolute;
    left: -20px;
    top: -1px;
  `}
`;

export const CheckIcon = styled(CheckSvg)``;

export const Circle = styled.div`
  position: relative;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  flex: 0 0 17px;
  background-color: ${Color.dirtyWhite};
  margin-right: 8px;
  margin-left: -25px;
  &:after {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    width: 21px;
    height: 21px;
    background-color: ${Color.transparent};
    border-radius: 50%;
    border: 1px solid ${Color.transparent};
    transition: ${Transitions.custom};

    ${styleWhenTrue(prop("active"), css`
      border: 1px solid ${Color.gray7};
    `)}
  }

  ${styleWhenTrue(propEq(CircleColorType.Gold, "$background"), css`
    background-color: ${Color.gold};
  `)}

  ${styleWhenTrue(propEq(CircleColorType.Silver, "$background"), css`
    background-color: ${Color.silver};
  `)}

  ${styleWhenTrue(propEq(CircleColorType.Bronze, "$background"), css`
    background-color: ${Color.brown};
  `)}

  ${styleWhenTrue(propEq(CircleColorType.Empty, "$background"), css`
    background-color: ${Color.almostWhite};
  `)}

  ${Media.tablet`
    margin-left: 0;
  `}
`;
