import React from "react";
import PropTypes from "prop-types";

import {
  Container,
  OptionsSelectedCounter,
  OptionsCounter,
  ArrowUpIcon,
  ArrowDownIcon,
} from "./optionCounter.styled";

export const OptionCounter = ({
  className,
  availableOptionsLength,
  selectedOptionsLength,
  scrollUp,
  scrollDown,
}) => {
  return (
    <Container className={className}>
      <ArrowUpIcon onClick={scrollUp} />
      <OptionsSelectedCounter>
        {selectedOptionsLength}
      </OptionsSelectedCounter>
      {" "}
      <OptionsCounter>({availableOptionsLength})</OptionsCounter>
      <ArrowDownIcon onClick={scrollDown} />
    </Container>
  );
};

OptionCounter.propTypes = {
  className: PropTypes.string,
  availableOptionsLength: PropTypes.number,
  selectedOptionsLength: PropTypes.number,
  scrollUp: PropTypes.func,
  scrollDown: PropTypes.func,
};
