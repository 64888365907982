import React, { Component } from "react";
import i18n from "i18next";

import Player from "./player.component";
import Menu from "./menu.component";
import Modal from "./modal.component";
import { Container } from "./videos.styled";

export default class Videos extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      watched: [],
      modalHidden: false,
      styles: null,
      isRotatable: false,
      activityFinished: true,
      position: 0,
    };
  }

  componentDidMount() {
    fetch(this.props.baseUrl + this.props.config)
      .then(response => response.json())
      .then(jresp => {
        this.initialState = {
          passingViews: jresp.passingViews,
          videos: jresp.videos,
          instruction: jresp.instruction
        };
        this.setState(this.initialState, () => {
          this.state.videos.length > 3
            ? this.setState({ isLoading: false, playing: false, isRotatable: true})
            : this.setState({ isLoading: false, playing: false, isRotatable: false});
        });
      });
  }

  getElementsCount() {
    if (window.innerWidth < 768) {
      return 1;
    } else if (window.innerWidth < 1024) {
      return 2;
    } else {
      return 3;
    }
  }

  getMoveNextStyles() {
    const { position } = this.state;
    const elementsCount = this.getElementsCount();
    const box = document.getElementsByClassName("single-video-box");
    if (position !== box.length - elementsCount) {
      this.setState({ position: position + 1 });
      const step = box[0].offsetWidth;
      return `translate3d(${-step * (position + 1)}px, 0, 0)`;
    } else {
      this.setState({ position: 0 });
      return "translate3d(0, 0, 0)"
    }
  }

  moveNext() {
    const styles = this.getMoveNextStyles();
    this.setState({
      styles: { transform: styles },
    });
  }

  getMovePrevStyles() {
    const { position } = this.state;
    const box = document.getElementsByClassName("single-video-box");
    const step = box[0].offsetWidth;
    const elementsCount = this.getElementsCount();

    if (position !== 0) {
      this.setState({ position: position - 1 });
      return `translate3d(${-step * (position - 1)}px, 0, 0)`;
    } else {
      const newPosition = box.length - elementsCount;
      this.setState({ position: newPosition });
      return `translate3d(${-step * newPosition}px, 0, 0)`;
    }
  }

  movePrev() {
    const styles = this.getMovePrevStyles();
    this.setState({
      styles: { transform: styles },
    });
  }

  finish(id) {
    const { watched } = this.state;

    if (watched.indexOf(id) === -1) {
      const nextWatched = [...watched, id];
      this.setState({ watched: nextWatched });
      this.setState({ watched: nextWatched });

      if (nextWatched.length === this.state.passingViews) {
        this.props.callComplete();
      }
    }
    this.setState({ playing: false });
  }

  renderModal() {
    const { activityFinished } = this.state;
    const { callComplete } = this.props;

    if (!activityFinished) {
      return null;
    }

    return (
      <Modal
        callProceed={callComplete}
        hidden={this.state.modalHidden}
        back={() => this.setState({ modalHidden: true })}
        styles={this.state.styles}
      />
    );
  }

  render() {
    const { playing, isLoading, videos, videoId } = this.state;

    if (isLoading) {
      return (
        <Container>
          <span>{i18n.t("lesson.slide.slide.loading")}</span>
          {this.renderModal()}
        </Container>
      );
    }

    if (playing) {
      return (
        <Container>
          <Player url={this.props.baseUrl+videos[videoId].url}
            back={() => this.setState({ playing: false })}
            finished={() => this.finish(videoId)}
          />
          {this.renderModal()}
        </Container>
      );
    }

    return (
      <Container>
        <Menu
          description={this.state.description}
          instruction={this.state.instruction}
          videos={ menuVideos(this.state) }
          play={ id => this.setState({ playing: true, videoId: id }) }
          moveNext={this.moveNext.bind(this)}
          movePrev={this.movePrev.bind(this)}
          styles={this.state.styles}
          isRotatable={this.state.isRotatable}
        />
        {this.renderModal()}
      </Container>
    );
  }
}

function menuVideos({ videos, watched }) {
  return values(videos)
    .map(video => Object.assign({}, video, { watched: watched.indexOf(video.id) !== -1 }))
}

function values(map) {
  return Object.keys(map)
    .map(key => map[key]);
}
