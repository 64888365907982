import React from "react";
const ResultScreen = ({ message, action, onAction }) => (
  <div className="put-in-order--result-screen">
    <h3 className="put-in-order--header hidden">{
      Array.isArray(message)
        ? message.map((line, i) => [line, <br key={ `br-${i}` } />])
        : message }
    </h3>
    <button className="put-in-order--button hidden" onClick={ onAction }>{ action }</button>
  </div>
);
export default ResultScreen;
