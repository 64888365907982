import React from "react";
import styled from "styled-components";

import { colors, breakpoints } from "../UIGlobals";
import { Transitions } from "../theme";

import { Icon } from "./";

const BlurLayer = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 199;
  background: ${colors().semiDarkGray};
  opacity: ${props => (props.$show ? 0.3 : 0)};
  pointer-events: ${props => (props.$show ? "auto" : "none")};
  transition: ${Transitions.custom};
  display: ${props => (props.$show ? "block" : "none")};
`;

const CloseIcon = styled.a`
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 15px;
  transition: ${Transitions.custom};
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const PopupContainer = styled.div`
  position: fixed;
  pointer-events: ${props => (props.$show ? "auto" : "none")};
  top: 0;
  right: ${props => (props.$show ? "0" : "-100%")};
  bottom: 0;
  z-index: 200;
  width: auto;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  transition: ${Transitions.custom};
  display: ${props => (!props.$show ? "none" : "block")};
  @media (${breakpoints().maxL}) {
    width: 85%;
  }
`;

const PopupInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${colors().white};
  overflow: auto;
  overflow-y: auto;
  padding: 147px 80px 80px 50px;
  width: ${props => (props.width ? props.width + "px" : "auto")};
  min-height: 100vh;
  transition: ${Transitions.custom};
  @media (${breakpoints().maxL}) {
    width: 100%;
    padding: 120px 50px 50px 50px;
  }
  @media (${breakpoints().maxS}) {
    width: 100%;
    padding: 120px 20px 50px 20px;
  }
`;

const Popup = ({
  children,
  width,
  show,
  closeAction,
  className,
}) => (
  <div className={className}>
    <BlurLayer $show={show} onClick={() => closeAction()} />
    <PopupContainer $show={show} onClick={() => closeAction()}>
      <PopupInner width={width} onClick={e => e.stopPropagation()}>
        <CloseIcon onClick={() => closeAction()}>
          <Icon icon="close_thin" size={18} color={colors().almostBlack} />
        </CloseIcon>
        {children}
      </PopupInner>
    </PopupContainer>
  </div>
);

export {
  Popup,
  PopupInner,
  CloseIcon,
};
