import styled from "styled-components";

import { Button } from "../../../../components/button/button.component";
import { ReactComponent as PlaySvg } from "../../../../images/play.svg";
import { ReactComponent as MuteSvg } from "../../../../images/mute.svg";
import { ReactComponent as UnmuteSvg } from "../../../../images/unmute.svg";
import { Color, Media } from "../../../../theme";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Color.white};
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Video = styled.video`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  && {
    max-height: 100%;
  }
`;

export const PlayButton = styled.button`
  width: 140px;
  height: 140px;
  background-color: ${Color.blue};
  border-radius: 50%;
  border: none;
  position: absolute;
  transition: opacity 0.2s ease-in-out;

  ${Media.tablet`
    width: 160px;
    height: 160px;
  
    &:hover {
      opacity: 0.8;
    }
  `}
`;

export const PlayIcon = styled(PlaySvg)`
  width: 38px;
  height: 44px;
  fill: ${Color.white};
  position: relative;
  left: 5px;
`;

export const OptionButtons = styled.div`
  position: absolute;
  bottom: 25px;
  left: 25px;
  right: 25px;
  display: flex;

  ${Media.mobile`
    top: 30px;
    right: 30px;
    left: unset;
  `}
`;

export const SkipButton = styled(Button)`
  background-color: ${Color.blue};
  width: 100%;

  ${Media.mobile`
    width: auto;
  `}
`;

export const MuteButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 42px;
  padding: 0;
  background-color: ${Color.white};
`;

export const MuteIcon = styled(MuteSvg)``;

export const UnmuteIcon = styled(UnmuteSvg)``;

export const MuteButtonWrapper = styled.div`
  margin-right: 10px;
  height: 43px;
`;
