import _ from "lodash";
import kurentoUtils from "kurento-utils";

import { ActionType } from "../../../redux/websocket/websocket.constants";
import { store } from "../../../store";
import { logger } from "../../../helpers/logger";
import { nodeApi, handleWebinarApiError } from "../../../api";
import { MessageType } from "../webinars.constants";
import { WebinarsTypes } from "../redux/webinars.reducer";

import {
  StreamType,
  StreamDirection,
  DeviceKind,
  VideoQuality,
  ScreenVideoQuality,
} from "./streams.constants";
import { stopStream, muteAudio } from "./api";

let lastId = 0;
const webRtcPeers = new Map();
const queueIceSend = new Map();
let queueIceCandidates = [];
let localCameraVideo,
  localScreenVideo,
  remoteCameraVideos,
  remoteScreenVideo,
  localAudio,
  remoteAudios;

export function initVideoStreams(
  localCameraVideoRef,
  localScreenVideoRef,
  remoteCameraVideoRefs,
  remoteScreenVideoRef,
  localAudioRef,
  remoteAudioRefs,
) {
  localCameraVideo = localCameraVideoRef && localCameraVideoRef.current;
  localScreenVideo = localScreenVideoRef && localScreenVideoRef.current;
  remoteCameraVideos = remoteCameraVideoRefs && remoteCameraVideoRefs.current;
  remoteScreenVideo = remoteScreenVideoRef && remoteScreenVideoRef.current;
  localAudio = localAudioRef && localAudioRef.current;
  remoteAudios = remoteAudioRefs && remoteAudioRefs.current;
}

function doSend(message) {
  store.dispatch({ type: ActionType.WS_SEND_MESSAGE, value: message });
}

function onError(error) {
  logger("Webinars stream onerror", `Webinars stream error: ${error}`);
}

function checkQueue() {
  _.remove(queueIceCandidates, (o) => iceCandidate(o.stream, o.candidate));
}

export function iceCandidate(stream, candidate) {
  const webRtcPeer = webRtcPeers.get(stream.id);
  if (webRtcPeer) {
    webRtcPeer.addIceCandidate(candidate);
  } else {
    queueIceCandidates.push({
      stream,
      candidate,
    });
  }
  return !!webRtcPeer;
}

function onIceCandidate(candidate, streamId) {
  let message = {
    command: MessageType.IceCandidate,
    data: {
      candidate: candidate,
      stream: streamId,
    },
  };
  if (streamId.toString().includes("my_stream")) {
    const arr = queueIceSend.get(streamId) || [];
    arr.push(message);
    queueIceSend.set(streamId, arr);
  } else {
    doSend(JSON.stringify(message));
  }
}

export function createStream(type, roomId, deviceId, participantId, turnServerConfig) {
  const configuration = turnServerConfig?.enabled ? {
    iceServers: [
      {
        urls: turnServerConfig.turnURL,
        username: turnServerConfig.username,
        credential: turnServerConfig.credentials,
      }
    ],
    iceTransportPolicy: turnServerConfig.forceRelay ? "relay" : "all"
  } : undefined;
  const constraints = {
    audio: type === StreamType.Audio,
    video: type === StreamType.Camera || type === StreamType.Screen
      ? {
        deviceId,
        width: type === StreamType.Screen ? ScreenVideoQuality.Width : VideoQuality.Width,
        height: type === StreamType.Screen ? ScreenVideoQuality.Height : VideoQuality.Height,
      }
      : false,
  };

  const media =
    type === StreamType.Camera || type === StreamType.Audio
      ? navigator.mediaDevices.getUserMedia
      : navigator.mediaDevices.getDisplayMedia;

  media.call(navigator.mediaDevices, constraints).then((stream) => {
    const tmpKey = `my_stream_${++lastId}`;
    let streamId = tmpKey;

    const options = {
      videoStream: stream,
      mediaConstraints: constraints,
      localVideo: type === StreamType.Camera
        ? localCameraVideo
        : type === StreamType.Audio
          ? localAudio
          : localScreenVideo,
      onicecandidate: (candidate) => onIceCandidate(candidate, streamId),
      configuration,
    };

    navigator.mediaDevices.enumerateDevices().then((devices) => {
      store.dispatch({
        type: WebinarsTypes.UPDATE_DEVICES,
        videoDevices: devices.filter(
          (device) => device.kind === DeviceKind.Video
        ),
        audioDevices: devices.filter(
          (device) => !!device.groupId && device.kind === DeviceKind.Audio
        ),
      });
    });

    const webRtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options, function (error) {
      if (error) return onError(error);
      this.generateOffer((error, offer) => {
        if (error) return onError(error);

        nodeApi.post(
          `rooms/streams/create/${roomId}`,
          { type, offer },
          { sendInBody: true }
        ).then((res) => {
          const data = res.data;
          const receivedStream = data.data;
          streamId = receivedStream.id;
          webRtcPeers.set(streamId, this);
          store.dispatch({ type: WebinarsTypes.HANDLE_ADD_LOCAL_STREAM, stream: receivedStream });

          const messageQueue = queueIceSend.get(tmpKey) ?? [];
          queueIceSend.set(tmpKey, []);

          messageQueue.forEach((msg) => {
            msg.data.stream = receivedStream.id;
            doSend(JSON.stringify(msg));
          });

          checkQueue();

          this.processAnswer(data.answer);

          if (type === StreamType.Screen) {
            stream.getVideoTracks()[0].onended = () => toggleMedia(streamId, undefined, undefined, undefined, turnServerConfig);
          }
        });
      });
    });

    setTimeout(() => {
      const localStream = webRtcPeer.getLocalStream();
      if (localStream?.getAudioTracks().length) {
        const speechEvent = kurentoUtils.WebRtcPeer.hark(localStream, { threshold: -50 });
        speechEvent.on("speaking", () => {
          store.dispatch({
            type: WebinarsTypes.SET_SPEAKING_USER,
            speaking: participantId,
          });
        });
        speechEvent.on("stopped_speaking", () => {
          store.dispatch({
            type: WebinarsTypes.SET_SPEAKING_USER,
            speaking: null,
          });
        });
      }
    }, 1000);
  }).catch((err) => {
    store.dispatch({ type: WebinarsTypes.SET_DEVICE_PERMISSION_ERROR, devicePermissionError: type });
    logger("Webinars stream error", `Webinars stream error: ${err}`);
  });
}

export function connectToStream(stream, turnServerConfig) {
  const configuration = turnServerConfig?.enabled ? {
    iceServers: [
      {
        urls: turnServerConfig.turnURL,
        username: turnServerConfig.username,
        credential: turnServerConfig.credentials,
      }
    ],
    iceTransportPolicy: turnServerConfig.forceRelay ? "relay" : "all"
  } : undefined;

  const options = {
    remoteVideo: stream.type === StreamType.Camera
      ? remoteCameraVideos?.autoplay ? remoteCameraVideos : remoteCameraVideos[stream.participantId]
      : stream.type === StreamType.Audio
        ? remoteAudios?.autoplay ? remoteAudios : remoteAudios[stream.participantId]
        : remoteScreenVideo,
    mediaConstraints: {
      video: stream.type === StreamType.Camera || stream.type === StreamType.Screen,
      audio: stream.type === StreamType.Audio,
    },
    onicecandidate: (candidate) => onIceCandidate(candidate, stream.id),
    configuration,
  };

  const webRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(
    options,
    function (error) {
      if (error) return onError(error);

      this.generateOffer((error, offer) => {
        if (error) return onError(error);

        nodeApi.post(
          `rooms/streams/connect/${stream.id}`,
          { type: stream.type, offer },
          { sendInBody: true }
        ).then((res) => this.processAnswer(res.data.answer));
      });
    }
  );
  webRtcPeers.set(stream.id, webRtcPeer);

  setTimeout(() => {
    const remoteStream = webRtcPeer.getRemoteStream();

    if (remoteStream?.getAudioTracks().length) {
      const speechEvent = kurentoUtils.WebRtcPeer.hark(remoteStream, { threshold: -50 });
      speechEvent.on("speaking", () => {
        store.dispatch({
          type: WebinarsTypes.SET_SPEAKING_USER,
          speaking: stream.participantId,
          mainStream: stream.participantId,
        });
      });
      speechEvent.on("stopped_speaking", () => {
        store.dispatch({
          type: WebinarsTypes.SET_SPEAKING_USER,
          speaking: null,
          mainStream: stream.participantId,
        });
      });
    }
  }, 1000);
}

export function updateStreams(room, turnServerConfig) {
  room.streams.forEach((stream) => {
    if (!webRtcPeers.get(stream.id) && stream.direction === StreamDirection.In) {
      connectToStream(stream, turnServerConfig);
    }
  });
  const streamsId = room.streams.map((s) => s.id);
  for (const key of webRtcPeers.keys()) {
    if (!streamsId.includes(key)) {
      webRtcPeers.delete(key);
    }
  }
}

export function updateRoom(room) {
  updateStreams(room);
}

export function closeStream(streamId, passedPeer) {
  const peer = passedPeer ? passedPeer : webRtcPeers.get(streamId);
  peer.dispose();
  webRtcPeers.delete(streamId);
  stopStream(streamId);
}

export function switchDevice(track, deviceId, streamType, room, turnServerConfig, streamId, passedPeer) {
  const peer = passedPeer;
  track.enabled = false;
  // This setTimeout is obligatory to blank ViewerVideo. It's important.
  setTimeout(() => {
    closeStream(streamId, peer);
    if (deviceId && streamType) {
      setTimeout(() => {
        createStream(streamType, room.id, deviceId, undefined, turnServerConfig);
      }, 300);
    }
  }, 50);
}

export function toggleMedia(streamId, room, deviceId, streamType, turnServerConfig) {
  const peer = webRtcPeers.get(streamId);
  if (peer) {
    let localStream = null;

    try {
      localStream = peer.getLocalStream();
    } catch {
      // This console error is obligatory to Safari browser. It's important.
      console.error("No local stream at the moment.");
    }

    if (localStream?.getVideoTracks().length && deviceId) {
      const track = localStream.getVideoTracks()[0];
      switchDevice(track, deviceId, streamType, room, turnServerConfig, streamId, peer);
    } else if (localStream?.getVideoTracks().length) {
      closeStream(streamId, peer);
    } else if (localStream?.getAudioTracks().length && deviceId) {
      const track = localStream.getAudioTracks()[0];
      switchDevice(track, deviceId, streamType, room, turnServerConfig, streamId, peer);
    } else if (localStream?.getAudioTracks().length && peer.audioEnabled) {
      peer.audioEnabled = false;
      muteAudio(true);
    } else if (localStream?.getAudioTracks().length && !peer.audioEnabled) {
      peer.audioEnabled = true;
      muteAudio(false);
    }
  }
}

export const joinRoom = (id, from) => {
  return nodeApi.post(`/rooms/join/${id}`).catch((error) => handleWebinarApiError(error, from));
};

export const authorizeInTurnServer = () => {
  return nodeApi.post("/rooms/turn/auth");
};

export const getRoomAccess = () => {
  return nodeApi.get("/rooms/access");
};
