import styled, { css } from "styled-components";
import { Checkbox, styled as styledMUI } from "@mui/material";
import { prop } from "ramda";

import { Button } from "../components/button/button.component";
import { styleIfElse, styleWhenTrue } from "../helpers/rendering";
import {
  ListHeader as ListHeaderBase,
  ContentInner as ContentInnerBase,
  Field,
} from "../UIElements";
import {
  colors,
  breakpoints
} from "../UIGlobals";
import { hexToRgba } from "../helpers/colors";
import { ReactComponent as AcceptSvg } from "../images/check4.svg";
import { ReactComponent as ErrorSvg } from "../images/closeInCircle.svg";
import { ContentMaxWidth, FontWeight, Media, Color } from "../theme";

const ContentInner = styled(ContentInnerBase)`
  @media (${breakpoints().maxXL}) {
    padding: 0;
  }
`;

const HeaderSVG = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors().dirtyWhite};
  margin-top: 80px;

  ${Media.tabletWide`
    margin-top: 0;
  `}
`;

const StepCounter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 225px;
  margin: 0 auto;
  width: 100%;
  padding: 0 25px;

  ${Media.desktop`
    width: ${ContentMaxWidth};
    max-width: 80%;
    padding: 0;
  `}
`;

const StepCounterTitle = styled.h1`
  font-weight: ${FontWeight.Bold};
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 8px;
  color: ${colors().deepGray};
`;

const StepCounterText = styled.p`
  font-size: 20px;
  line-height: 22px;
  color: ${colors().gray9};
`;

const Wrapper = styled.div`
  max-width: 380px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (${breakpoints().maxS}) {
    width: 100%;
    max-width: 100%;
    margin: 30px 25px;
    height: 100%;
  }
`;

const ListHeader = styled(ListHeaderBase)`
  font-size: 1.4rem;
  color: ${colors().brandBasic};
  margin-top: 30px;
  margin-bottom: 0;
  text-transform: uppercase;
`;

const Logo = styled.svg`
  display: block;
  width: 300px;
  height: 30px;
`;

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const TabContainer = styled.div`
  width: 100%;
  height: 188px;
  display: flex;
  align-items: center;
  justify-content: ${styleIfElse(prop("$newTheme"), css``, css`space-evenly`)};
  padding: 0 25px;
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
`;
const Headline = styled.div`
  width: 100%;
  font-size: 27px;
  color: ${colors().deepGray};
  font-weight: 300;
  margin: 0 auto;
  display: flex;
  align-items: center;
  min-width: 960px;
  width: 1200px;
  justify-content: space-around;
  @media (${breakpoints().maxM}) {
    min-width: auto;
    width: auto;
    display: flex;
    flex-flow: column;
  }
`;

const NewHeadline = styled.div`
  font-weight: ${FontWeight.Bold};
  font-size: 26px;
  line-height: 30px;
  color: ${Color.deepGray};
  max-width: 940px;
  width: 100%;
  margin: 0 auto 8px;
`;

const Column = styled.div`
  width: ${props => (props.width ? props.width : "100%")};
  display: flex;
  flex-direction: ${props => (props.$flexDirection ? props.$flexDirection : "row")};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom + "px" : "0")};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : "0")};
  &:not(:first-child) {
    margin-left: 50px;
  }
  @media (${breakpoints().maxL}) {
    width: 100%;
    margin-left: 0 !important;
    padding: 0;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (${breakpoints().maxL}) {
    margin-left: -12px;
    padding-top: 30px;
  }
`;

const FieldlineInner = styled.div``;

const Row = styled.div`
  display: flex;
  @media (${breakpoints().maxL}) {
    flex-direction: column;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => (props.padding ? props.padding : "80px 167px")};
  @media (${breakpoints().maxL}) {
    padding: 50px 0px;
  }
`;

const CheckboxText = styled.div`
  font-size: 11px;
  line-height: 1.2;
  padding-top: ${props => (props.paddingTop ? props.paddingTop + "px" : "0")};
  margin-left: 5px;
  display: flex;
  align-items: left;
  color: ${Color.gray2};
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  flex-basis: 100%;

  ${styleWhenTrue(prop("$error"), css`
    color: ${Color.red5};
  `)}

  ${styleWhenTrue(prop("$onlyInfo"), css`
    margin-bottom: 20px;
    cursor: default;
  `)}

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  b {
    font-weight: ${FontWeight.Bold};
  }
`;

const Label = styled.label`
  position: relative;
  font-size: ${props => (props.fontSize ? props.fontSize + "px" : "16px")};
  color: ${props => (props.color ? colors()[props.color] : "rgba(0, 0, 0, 0.87)")};
  width: 100%;
  display: block;
  padding-bottom: ${props => (props.$paddingBottom ? props.$paddingBottom + "px" : "8px")};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom + "px" : "0px")};
  margin-top: ${props => (props.$marginTop ? props.$marginTop + "px" : "0")};
  padding-top: ${props => (props.$paddingTop ? props.$paddingTop : "26px")};
  display: flex;
  align-items: ${props => (props.$alignItems ? props.$alignItems : "center")};
  justify-content: flex-start;
  flex-flow: ${props => (props.$flexFlow ? props.$flexFlow : "")};
  transition: border-bottom 200ms cubic-bezier(0.4, 0, 0.2, 1);

  &:before {
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    transition: border-bottom 200ms cubic-bezier(0.4, 0, 0.2, 1);
    border-bottom: 1px solid ${hexToRgba(Color.black, 0.42)};
  }

  &:hover {
    &:before {
      border-bottom: 2px solid ${hexToRgba(Color.black, 0.87)};
    }
  }

  .react-date-picker__inputGroup__input:invalid {
    background: ${Color.transparent};
  }

  ${styleWhenTrue(prop("$errorTheme"), css`
    border-bottom: 2px solid ${Color.red5};
    color: ${Color.red5};

    &:hover {
      border-bottom: 2px solid ${Color.red5};
    }

    .react-date-picker__inputGroup__input::placeholder {
      color: ${Color.red5};
    }

    .react-date-picker__button {
      &:hover {
        .react-date-picker__button__icon {
          stroke: ${Color.red5} !important;
        }
      }
      .react-date-picker__button__icon {
        stroke: ${Color.red5} !important;
        &:hover {
          stroke: ${Color.red5} !important;
        }
      }
    }
  `)}

  ${styleWhenTrue(prop("$isInvalid"), css`
    border-bottom: 2px solid ${Color.red5};
  `)}
`;

const Date = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: ${props => (props.bottom ? props.bottom : "4px")};
`;

const Text = styled.p`
  font-size: 1.4rem;
  text-align: center;
  line-height:  ${props => (props.lineHeight ? props.lineHeight : "")};
`;

const Fieldline = styled.div`
  display: flex;
  flex-direction: ${props => (props.flexDirection ? props.flexDirection : "row")};
  align-items: center;
  &:not(:first-of-type) {
    margin-top: 5px;
  }
`;

const MultiSelect = styled.div`
  width: 100%;
  margin-top:10px;
`;

const CheckboxRegister = styledMUI(Checkbox)({
  width: "44px",
  height: "44px",

  "& .MuiSvgIcon-root": {
    width: "24px",
    height: "24px",
  },
});

const Hint = styled.p`
  font-size: 15px;
  line-height: 26px;
  color: ${Color.darkGray};
  margin-bottom: 50px;
  max-width: 625px;
`;

const BoldText = styled.span`
  font-weight: ${FontWeight.Bold};
`;

const Buttons = styled.div`
  ${Media.mobile`
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
    padding-top: 30px;
    border-top: 2px solid ${Color.almostWhite};
  `}
`;

const NewButton = styled(Button)`
  width: 100%;

  & + & {
    margin-top: 10px;
  }

  ${Media.mobile`
    width: auto;
    min-width: 200px;

    & + & {
      margin-top: 0;
      margin-left: 10px;
    }
  `}
`;

const OldButton = styled(NewButton)`
  width: 200px;
  margin: 40px auto 0;
`;

const ValidationHint = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 7px;
`;

const iconStyles = css`
  flex-shrink: 0;
  width: 22px;
  height: 22px;
`;

const CheckIcon = styled(AcceptSvg)`
  ${iconStyles}
  fill: ${Color.green};
`;

const ErrorIcon = styled(ErrorSvg)`
  ${iconStyles}
  fill: ${Color.error};
  padding: 2px;
`;

const ValidationText = styled.p`
  font-size: 15px;
  color: ${Color.darkGray};
  max-width: 625px;
`;

const PasswordField = styled(Field)`
  display: flex;
  align-items: flex-end;
`;

export {
  ContentInner,
  HeaderSVG,
  StepCounter,
  StepCounterTitle,
  StepCounterText,
  Logo,
  RegisterContainer,
  TabContainer,
  Headline,
  NewHeadline,
  Column,
  FieldWrapper,
  FieldlineInner,
  Row,
  Date,
  Inner,
  CheckboxText,
  Text,
  Fieldline,
  Wrapper,
  Label,
  MultiSelect,
  CheckboxRegister,
  ListHeader,
  Hint,
  BoldText,
  Buttons,
  NewButton,
  OldButton,
  CheckIcon,
  ErrorIcon,
  ValidationHint,
  ValidationText,
  PasswordField,
};
