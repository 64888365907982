import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { Tooltip as TooltipBase } from "../../../../components/tooltip/tooltip.component";
import { ReactComponent as CheckSvg } from "../../../../images/check2.svg";
import { ReactComponent as InfoSvg } from "../../../../images/infoTip2.svg";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color } from "../../../../theme";
import { AnswerStatus } from "../../../interactions.constants";

export const Container = styled.button`
  background-color: ${Color.transparent};
  border: 1px solid ${Color.dirtyWhite};
  padding: 25px 20px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 15px;

  ${styleWhenTrue(propEq(AnswerStatus.Checked, "status"), css`
    border: 1px solid ${Color.blue5};
    background-color: ${Color.blue6};
  `)}

  ${styleWhenTrue(propEq(AnswerStatus.CorrectChecked, "status"), css`
    border: 2px solid ${Color.green};
    background-color: ${Color.lightGreen};
  `)}

  ${styleWhenTrue(propEq(AnswerStatus.WrongChecked, "status"), css`
    border: 2px solid ${Color.red};
    background-color: ${Color.lightPink};
  `)}

  ${styleWhenTrue(propEq(AnswerStatus.WrongUnchecked, "status"), css`
    border: 1px solid ${Color.lightGreen};
    background-color: ${Color.lightGreen};
  `)}
`;

export const Checkbox = styled.div`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border: 1px solid ${Color.gray1};
  background-color: ${Color.white};
  display: flex;
  align-items: center;
  justify-content: center;

  ${styleWhenTrue(prop("checked"), css`
    border: 1px solid ${Color.deepGray};
    background-color: ${Color.deepGray};
  `)}
`;

export const CheckIcon = styled(CheckSvg)`
  width: 12px;
  height: 12px;
  fill: ${Color.white};
`;

export const Tooltip = styled(TooltipBase)`
  pointer-events: auto;
  flex-shrink: 0;
`;

export const InfoIcon = styled(InfoSvg)``;
