import styled from "styled-components";

import { Button as ButtonBase } from "../../../../components/button/button.component";
import { Color, FontWeight } from "../../../../theme";
import { ReactComponent as DevicesSvg } from "../../images/devices.svg";


export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 70px;
  background-color: ${Color.whiteHover};
  z-index: 1000;
`;

export const DevicesIcon = styled(DevicesSvg)`
  fill: ${() => Color.brandBasic};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 396px);
`;

export const Content = styled.div`
  background-color: ${Color.white};
  height: 326px;
  padding: 49px 20px 20px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.p`
  color: ${Color.deepGray};
  font-weight: ${FontWeight.Bold};
  text-align: center;
  font-size: 18px;
  line-height: 24px;
`;

export const Text = styled.p`
  margin-top: 20px;
  font-size: 14px;
  line-height: 22px;
  color: ${Color.gray9};
  text-align: center;
`;

export const Button = styled(ButtonBase)`
  margin-top: auto;
  width: 100%;
`;
