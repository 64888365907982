import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../UIElements";
import { CheckModal } from "../../../trainings/components/checkModal/checkModal.component";
import { CheckModalType } from "../../../trainings/components/checkModal/checkModal.constants";
import {
  selectNextCourses,
  selectCheckPopupOpenedId,
  selectToRemoveCourses,
  selectShowTrainingRegulation,
} from "../../../trainings/redux/trainings.selectors";
import { TrainingsActions } from "../../../trainings/redux/trainings.reducer";
import { DisenrollPopup } from "../disenrollPopup/disenrollPopup.component";

export const Disenroll = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const nextCourses = useSelector(selectNextCourses);
  const toRemoveCourses = useSelector(selectToRemoveCourses);
  const showTrainingRegulation = useSelector(selectShowTrainingRegulation);
  const checkPopupOpenedId = useSelector(selectCheckPopupOpenedId);
  const [modalOpen, setModalOpen] = useState(false);

  const handleDisenroll = () => {
    if (props.withPopup) {
      setModalOpen(true);
    } else {
      dispatch(TrainingsActions.disenrollEvent(props.eventId, () => {
        dispatch(TrainingsActions.getEventDetails(props.eventId));
      }));
    }
  };

  const handleClick = () => {
    dispatch(TrainingsActions.checkEventDisenroll(props.eventId, handleDisenroll));
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCloseCheckPopup = () => {
    dispatch(TrainingsActions.closeCheckPopup());

    setTimeout(() => {
      dispatch(TrainingsActions.clearCourses());
    }, 500);
  };

  return (
    <>
      <Button {...props} onClick={handleClick}>
        {t("global.disenroll")}
      </Button>
      <DisenrollPopup
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        eventId={props.eventId}
      />
      <CheckModal
        modalType={CheckModalType.Success}
        eventName={props.eventName}
        courses={nextCourses}
        open={checkPopupOpenedId === props.eventId && !!nextCourses?.length}
        onClose={handleCloseCheckPopup}
      />
      <CheckModal
        modalType={CheckModalType.Disenroll}
        eventName={props.eventName}
        courses={toRemoveCourses}
        showTrainingRegulations={showTrainingRegulation}
        open={checkPopupOpenedId === props.eventId && (!!toRemoveCourses?.length || showTrainingRegulation)}
        onClose={handleCloseCheckPopup}
        onConfirm={handleDisenroll}
      />
    </>
  );
};
