import { createActions, createReducer } from "reduxsauce";

export const { Types: GamificationTypes, Creators: GamificationActions } = createActions(
  {
    startGamification: ["onFinish"],
    getUserChallenges: ["page", "activityFilter"],
    getUserChallengesSuccess: ["userChallenges"],
    setIsPending: ["isPending"],
    fetchCurrentLevel: null,
    fetchCurrentLevelSuccess: ["data"],
    fetchUpcomingChallenge: null,
    fetchUpcomingChallengeSuccess: ["upcoming"],
    fetchUserBadges: [],
    fetchUserBadgesSuccess: ["badges"],
    fetchRanking: ["page"],
    fetchRankingSuccess: ["ranking"],
    fetchGamificationLevels: null,
    fetchGamificationLevelsSuccess: ["gamificationLevels"],
    fetchOpenQuestion: ["id"],
    fetchOpenQuestionSuccess: ["openQuestion"],
    setSurveyPending: ["surveyPending"],
    fetchSurvey: ["id"],
    fetchSurveySuccess: ["survey"],
    sendSurveyAnswers: ["survey"],
    setOpenQuestionPending: ["openQuestionPending"],
    sendOpenQuestion: ["values", "onFinish", "id"],
    sendProgramManagerMessage: ["message"],
    clearQuestion: null,
    sendProgramManagerMessageSuccess: ["successMessage"],
    sendProgramManagerMessageFailure: ["errorData"],
    setProgramManagerMessagePending: ["programManagerMessagePending"],
    getCalendarMeetings: null,
    getCalendarMeetingsSuccess: ["meetings"],
    clearCalendarMeetings: null,
    getUpcomingMeeting: null,
    getUpcomingMeetingSuccess: ["upcomingMeeting"],
    savePost: ["post", "onFinish"],
    saveArticle: ["article", "onFinish"],
    getKnowledgeBaseItems: null,
    getKnowledgeBaseItemsSuccess: ["items"],
    getEntryDetails: ["entryId"],
    getEntryDetailsSuccess: ["entryDetails"],
    getActiveChallenges: ["onFinish"],
    getActiveChallengesSuccess: ["activeChallenges"],
    getPointsHistory: ["page"],
    getPointsHistorySuccess: ["pointsHistory"],
    clearResponseMessage: null,
    clearEntryDetails: null,
  },
  { prefix: "GAMIFICATION/" }
);

const INITIAL_STATE = {
  isPending: true,
  userChallenges: {
    data: [],
    page: 0,
    maxPage: 0,
  },
  gamificationStarted: false,
  gamificationLevels: [],
  level: null,
  coins: null,
  upcoming: {
    label: "",
    dateTo: 0,
    type: null,
  },
  badges: {
    data: [],
    userTotal: 0,
    count: 0,
    page: 0,
    maxPage: 0,
  },
  ranking: {
    data: [],
    userRank: null,
    page: 0,
    maxPage: 0,
  },
  openQuestionPending: false,
  openQuestion: null,
  surveyPending: false,
  survey: null,
  programManagerMessagePending: null,
  meetings: [],
  items: [],
  upcomingMeeting: null,
  successMessage: null,
  errorData: null,
  entryDetails: null,
  activeChallenges: [],
  pointsHistory: null,
};

const getUserChallengesSuccess = (state, { userChallenges }) => ({
  ...state,
  userChallenges,
});

const setIsPending = (state, { isPending }) => ({ ...state, isPending });

const fetchCurrentLevelSuccess = (state, { data }) => ({
  ...state,
  gamificationStarted: !!data.gamification && data.points !== null,
  coins: data.points,
  dateTo: data.gamification.dateTo,
});

const fetchUpcomingChallengeSuccess = (state, { upcoming }) => ({
  ...state,
  upcoming: upcoming ? upcoming.challenge : null,
});

const fetchUserBadgesSuccess = (state, { badges }) => ({
  ...state,
  badges,
});

const fetchRankingSuccess = (state, { ranking }) => ({
  ...state,
  ranking,
});

const fetchGamificationLevelsSuccess = (state, { gamificationLevels }) => ({
  ...state,
  gamificationLevels,
});

const fetchOpenQuestionSuccess = (state, { openQuestion }) => ({
  ...state,
  openQuestion,
});

const fetchSurveySuccess = (state, { survey }) => ({
  ...state,
  survey,
});

const setOpenQuestionPending = (state, { openQuestionPending }) => ({
  ...state,
  openQuestionPending,
});

const setSurveyPending = (state, { surveyPending }) => ({
  ...state,
  surveyPending,
});

const clearQuestion = (state) => ({
  ...state,
  openQuestion: null,
});

const setProgramManagerMessagePending = (state, { programManagerMessagePending }) => ({
  ...state,
  programManagerMessagePending,
});

const sendProgramManagerMessageSuccess = (state, { successMessage }) => ({
  ...state,
  successMessage,
  errorData: null,
});

const sendProgramManagerMessageFailure = (state, { errorData }) => ({
  ...state,
  errorData,
  successMessage: null,
});

const getCalendarMeetingsSuccess = (state, { meetings }) => ({
  ...state,
  meetings,
});

const clearCalendarMeetings = (state) => ({ ...state, meetings: [] });

const getUpcomingMeetingSuccess = (state, { upcomingMeeting }) => ({
  ...state,
  upcomingMeeting,
});

const getKnowledgeBaseItemsSuccess = (state, { items }) => ({
  ...state,
  items,
});

const getEntryDetailsSuccess = (state, { entryDetails }) => ({
  ...state,
  entryDetails,
});

const getActiveChallengesSuccess = (state, { activeChallenges }) => ({
  ...state,
  activeChallenges,
});

const getPointsHistorySuccess = (state, { pointsHistory }) => ({
  ...state,
  pointsHistory,
});

const clearResponseMessage = (state) => ({
  ...state,
  errorData: null,
  successMessage: null,
});

const clearEntryDetails = (state) => ({
  ...state,
  entryDetails: null,
});

export const reducer = createReducer(INITIAL_STATE, {
  [GamificationTypes.GET_USER_CHALLENGES_SUCCESS]: getUserChallengesSuccess,
  [GamificationTypes.SET_IS_PENDING]: setIsPending,
  [GamificationTypes.FETCH_CURRENT_LEVEL_SUCCESS]: fetchCurrentLevelSuccess,
  [GamificationTypes.FETCH_UPCOMING_CHALLENGE_SUCCESS]: fetchUpcomingChallengeSuccess,
  [GamificationTypes.FETCH_USER_BADGES_SUCCESS]: fetchUserBadgesSuccess,
  [GamificationTypes.FETCH_RANKING_SUCCESS]: fetchRankingSuccess,
  [GamificationTypes.FETCH_GAMIFICATION_LEVELS_SUCCESS]: fetchGamificationLevelsSuccess,
  [GamificationTypes.FETCH_OPEN_QUESTION_SUCCESS]: fetchOpenQuestionSuccess,
  [GamificationTypes.FETCH_SURVEY_SUCCESS]: fetchSurveySuccess,
  [GamificationTypes.SET_OPEN_QUESTION_PENDING]: setOpenQuestionPending,
  [GamificationTypes.SET_SURVEY_PENDING]: setSurveyPending,
  [GamificationTypes.CLEAR_QUESTION]: clearQuestion,
  [GamificationTypes.SEND_PROGRAM_MANAGER_MESSAGE_SUCCESS]: sendProgramManagerMessageSuccess,
  [GamificationTypes.SEND_PROGRAM_MANAGER_MESSAGE_FAILURE]: sendProgramManagerMessageFailure,
  [GamificationTypes.SET_PROGRAM_MANAGER_MESSAGE_PENDING]: setProgramManagerMessagePending,
  [GamificationTypes.GET_CALENDAR_MEETINGS_SUCCESS]: getCalendarMeetingsSuccess,
  [GamificationTypes.CLEAR_CALENDAR_MEETINGS]: clearCalendarMeetings,
  [GamificationTypes.GET_UPCOMING_MEETING_SUCCESS]: getUpcomingMeetingSuccess,
  [GamificationTypes.GET_KNOWLEDGE_BASE_ITEMS_SUCCESS]: getKnowledgeBaseItemsSuccess,
  [GamificationTypes.GET_ENTRY_DETAILS_SUCCESS]: getEntryDetailsSuccess,
  [GamificationTypes.GET_ACTIVE_CHALLENGES_SUCCESS]: getActiveChallengesSuccess,
  [GamificationTypes.GET_POINTS_HISTORY_SUCCESS]: getPointsHistorySuccess,
  [GamificationTypes.CLEAR_RESPONSE_MESSAGE]: clearResponseMessage,
  [GamificationTypes.CLEAR_ENTRY_DETAILS]: clearEntryDetails,
});
