import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Tooltip } from "../../../../components/tooltip/tooltip.component";
import { ButtonVariant } from "../../../../components/button/button.constants";
import { TooltipPlacement } from "../../../../components/tooltip/tooltip.constants";
import { AppConfig } from "../../../../appConfig";

import {
  Container,
  PlayButton,
  PlayIcon,
  Video,
  OptionButtons,
  SkipButton,
  MuteButton,
  UnmuteIcon,
  MuteIcon,
  MuteButtonWrapper,
} from "./videoModal.styled";

export const VideoModal = ({ className, videoUrl, onClose }) => {
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const handlePlay = () => {
    if (videoRef && videoRef.current) {
      setIsPlaying(true);
      videoRef.current.play();
    }
  };

  const handlePause = () => {
    setIsPlaying(false);
    videoRef.current.pause();
  };

  const handleMute = () => {
    videoRef.current.volume = isMuted ? 1 : 0;
    setIsMuted(!isMuted);
  };

  return (
    <Container className={className}>
      <Video
        ref={videoRef}
        src={`${AppConfig.content}${videoUrl}`}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={onClose}
      />
      <PlayButton onClick={handlePlay} hidden={isPlaying}>
        <PlayIcon />
      </PlayButton>
      <OptionButtons>
        <Tooltip
          title={isMuted ? t("onboarding.monstersVideo.unmute") : t("onboarding.monstersVideo.mute")}
          placement={TooltipPlacement.Bottom}
          open
        >
          <MuteButtonWrapper>
            <MuteButton onClick={handleMute} variant={ButtonVariant.Outlined}>
              {isMuted ? <MuteIcon /> : <UnmuteIcon />}
            </MuteButton>
          </MuteButtonWrapper>
        </Tooltip>
        <SkipButton onClick={onClose}>
          {t("onboarding.monstersVideo.skip")}
        </SkipButton>
      </OptionButtons>
    </Container>
  );
};

VideoModal.propTypes = {
  className: PropTypes.string,
  videoUrl: PropTypes.string,
  onClose: PropTypes.func,
};
