import { createSelector } from "reselect";
import { prop } from "ramda";

export const selectGamificationDomain = prop("gamification");

export const selectIsPending = createSelector(
  selectGamificationDomain, prop("isPending"),
);

export const selectIsStarted = createSelector(
  selectGamificationDomain, prop("gamificationStarted"),
);

export const selectDateTo = createSelector(
  selectGamificationDomain, prop("dateTo"),
);

export const selectGamificationLevels = createSelector(
  selectGamificationDomain, prop("gamificationLevels"),
);

export const selectUserChallenges = createSelector(
  selectGamificationDomain, prop("userChallenges"),
);

export const selectLevel = createSelector(
  selectGamificationDomain, prop("level"),
);

export const selectCoins = createSelector(
  selectGamificationDomain, prop("coins"),
);

const selectUpcomingChallenge = createSelector(
  selectGamificationDomain, prop("upcoming"),
);

export const selectUpcomingLabel = createSelector(
  selectUpcomingChallenge, prop("label"),
);

export const selectUpcomingCloseTime = createSelector(
  selectUpcomingChallenge, prop("dateTo"),
);

export const selectUpcomingType = createSelector(
  selectUpcomingChallenge, prop("type"),
);

export const selectUpcomingTask = createSelector(
  selectUpcomingChallenge, prop("item"),
);

const selectBadges = createSelector(
  selectGamificationDomain, prop("badges"),
);

export const selectBadgesAchieved = createSelector(
  selectBadges, prop("userTotal"),
);

export const selectBadgesOverall = createSelector(
  selectBadges, prop("count"),
);

export const selectBadgesList = createSelector(
  selectBadges, prop("data"),
);

export const selectBadgesCurrentPage = createSelector(
  selectBadges, prop("page"),
);

export const selectBadgesMaxPage = createSelector(
  selectBadges, prop("maxPage"),
);

const selectRankingPath = createSelector(
  selectGamificationDomain, prop("ranking"),
);

export const selectRanking = createSelector(
  selectRankingPath, prop("data"),
);

export const selectRankingCurrentPage = createSelector(
  selectRankingPath, prop("page"),
);

export const selectRankingMaxPage = createSelector(
  selectRankingPath, prop("maxPage"),
);

export const selectUserRank = createSelector(
  selectRankingPath, prop("userRank"),
);

export const selectOpenQuestionPending = createSelector(
  selectGamificationDomain, prop("openQuestionPending"),
);

export const selectSurveyPending = createSelector(
  selectGamificationDomain, prop("surveyPending"),
);

export const selectSurvey = createSelector(
  selectGamificationDomain, prop("survey"),
);

export const selectOpenQuestion = createSelector(
  selectGamificationDomain, prop("openQuestion"),
);

export const selectSuccessMessage = createSelector(
  selectGamificationDomain, prop("successMessage"),
);

export const selectErrorData = createSelector(
  selectGamificationDomain, prop("errorData"),
);

export const selectProgramManagerMessagePending = createSelector(
  selectGamificationDomain, prop("programManagerMessagePending"),
);

export const selectCalendarMeetings = createSelector(
  selectGamificationDomain, prop("meetings"),
);

export const selectUpcomingMeeting = createSelector(
  selectGamificationDomain, prop("upcomingMeeting"),
);

export const selectKnowledgeBaseItems = createSelector(
  selectGamificationDomain, prop("items"),
);

export const selectEntryDetails = createSelector(
  selectGamificationDomain, prop("entryDetails"),
);

export const selectActiveChallenges = createSelector(
  selectGamificationDomain, prop("activeChallenges"),
);

export const selectPointsHistory = createSelector(
  selectGamificationDomain, prop("pointsHistory"),
);
