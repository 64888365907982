import styled from "styled-components";
import { prop } from "ramda";

import { styleIfElse } from "../../helpers/rendering";
import {
  HeaderTitle as HeaderTitleBase,
  ListHeader as ListHeaderBase,
} from "../../UIElements";
import { Color, Media } from "../../theme";

import { Disenroll } from "./components/disenroll/disenroll.component";

export const HeaderTitle = styled(HeaderTitleBase)`
  width: 100%;
`;

export const HeaderWithTagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  a + ${HeaderTitle} {
    margin-top: 10px;
  }
`;

export const ListHeader = styled(ListHeaderBase)`
  margin-bottom: 35px;
`;

export const BoxWrapper = styled.div`
  width: ${({ width }) => (width ? width : "100%")};
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? paddingBottom : "59px")};

  ${Media.tablet`
    flex-direction: row;
  `}
`;

export const Box = styled.div`
  background: ${Color.almostWhite};
  height: 174px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2px;

  ${Media.tablet`
    margin-bottom: 0;
    width: ${styleIfElse(prop("cyclical"), "calc(50% - 2px)", "calc(33.33% - 2px)")};

    &:not(:first-child) {
      margin-left: 2px;
    }
  `}
`;

export const Text = styled.span`
  font-size: ${({ fontSize }) => (fontSize ? fontSize + "rem" : "1.2rem")};
  color: ${({ color }) => (color ? Color[color] : Color.deepGray)};
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop + "px" : "17px")};
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? paddingBottom + "px" : "0")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "400")};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "1")};
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const BorderedListHeader = styled(ListHeader)`
  border-bottom: 1px solid ${Color.dirtyWhite};
  padding-bottom: 30px;
`;

export const MapPlaceholder = styled.div`
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.gray1};
`;

export const Col = styled.div`
  width: ${({ width }) => (width ? width : "100%")};
  display: flex;
  flex-direction: ${({ $flexDirection }) => ($flexDirection ? $flexDirection : "TableRow")};
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : "initial")};
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? paddingBottom + "px" : "0")};
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft + "px" : "0")};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "initial")};
`;

export const StyledDisenroll = styled(Disenroll)`
  ${Media.tablet`
    margin-left: 10px;
  `}
`;
