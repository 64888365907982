import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color, FontWeight } from "../../../../theme";
import { ReactComponent as CameraSvg } from "../../images/camera.svg";
import { ReactComponent as CameraOffSvg } from "../../images/cameraOff.svg";
import { ReactComponent as StarSvg } from "../../images/star.svg";
import { ReactComponent as ExcludeSvg } from "../../images/exclude.svg";
import { ReactComponent as CloseSvg } from "../../images/close.svg";

export const CloseButton = styled.button`
  background-color: ${Color.transparent};
  border: none;
  outline: none;
  margin-left: auto;
`;

export const CloseIcon = styled(CloseSvg)``;

export const Container = styled.div`
  position: relative;
  padding: 15px 20px 20px;
  height: 100%;
  grid-area: 2 / 15 / 11 / 16;
  background-color: ${Color.white};
  border-left: 1px solid ${Color.gray5};
  border-top: 1px solid ${Color.gray5};
  width: 320px;
`;

export const TextWrapper = styled.div`
  display: flex;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${Color.deepGray};
`;

export const ExcludeIcon = styled(ExcludeSvg)`
  path {
    fill: ${() => Color.brandBasic};
  }
`;

export const ExcludeButton = styled.button`
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  background-color: ${Color.transparent};

  &:disabled {
    pointer-events: none;

    ${ExcludeIcon} {
      path {
        fill: ${Color.almostWhite};
      }
    }
  }
`;

export const Title = styled.p`
  font-weight: ${FontWeight.Bold};
`;

export const IconWrapper = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Color.almostWhite};
  border-radius: 2px;
  margin-left: 2px;
  cursor: pointer;

  ${styleWhenTrue(prop("disabled"), css`
    pointer-events: none;
  `)}
`;

export const StarIcon = styled(StarSvg)`
  width: 28px;
  height: 28px;
  padding: 8px;
  background-color: ${Color.yellow2};
  border-radius: 2px;
  margin-right: 2px;
`;

export const CameraIcon = styled(CameraSvg)`
  width: 15px;
  height: auto;

  path {
    fill: ${() => Color.brandBasic};
  }
`;

export const CameraOffIcon = styled(CameraOffSvg)`
  width: 15px;
  height: auto;
  position: relative;
  left: -1px;
  fill: ${Color.error};
`;

export const Amount = styled.p`
  margin-left: 3px;
`;

export const List = styled.div`
  margin-top: 30px;
  overflow-y: auto;
  height: calc(100% - 90px);
`;

export const UserRow = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-top: 20px;
  }
`;

export const FullName = styled.p`
  margin-left: 10px;
  font-weight: ${FontWeight.SemiBold};
  font-size: 12px;
  line-height: 16px;
  color: ${Color.darkGray};
  word-break: break-word;
  margin-right: 5px;
`;

export const Indicators = styled.div`
  margin-left: auto;
  display: flex;
`;
