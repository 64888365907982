import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { InteractionAnswerLabel } from "../../../../theme";
import { AnswerStatus } from "../../../interactions.constants";

import { Container, Buttons, Button } from "./trueFalseAnswer.styled";

const TrueFalseAnswer = ({
  data,
  trueSelected,
  buttonsTouched,
  confirmed,
  onSelect,
}) => {
  const { id, label, value } = data;
  const { t } = useTranslation();
  const [trueStatus, setTrueStatus] = useState(AnswerStatus.Default);
  const [falseStatus, setFalseStatus] = useState(AnswerStatus.Default);

  useEffect(() => {
    if (confirmed && trueSelected && value === true) {
      setTrueStatus(AnswerStatus.CorrectChecked);
      setFalseStatus(AnswerStatus.Default);
    } else if (confirmed && trueSelected && value === false) {
      setTrueStatus(AnswerStatus.WrongChecked);
      setFalseStatus(AnswerStatus.Default);
    } else if (confirmed && !trueSelected && value === true) {
      setTrueStatus(AnswerStatus.Default);
      setFalseStatus(AnswerStatus.WrongChecked);
    } else if (confirmed && !trueSelected && value === false) {
      setTrueStatus(AnswerStatus.Default);
      setFalseStatus(AnswerStatus.CorrectChecked);
    } else if (trueSelected) {
      setTrueStatus(AnswerStatus.Checked);
      setFalseStatus(AnswerStatus.Default);
    } else if (buttonsTouched && !trueSelected) {
      setTrueStatus(AnswerStatus.Default);
      setFalseStatus(AnswerStatus.Checked);
    }
  }, [buttonsTouched, trueSelected, confirmed, value]);

  const handleAnswerClick = (option) => {
    onSelect(id, option);
  };

  return (
    <Container>
      <InteractionAnswerLabel>{label}</InteractionAnswerLabel>
      <Buttons>
        <Button status={trueStatus} onClick={() => handleAnswerClick(true)}>
          {t("interactions.quizQuestions.true")}
        </Button>
        <Button status={falseStatus} onClick={() => handleAnswerClick(false)}>
          {t("interactions.quizQuestions.false")}
        </Button>
      </Buttons>
    </Container>
  );
};

export default TrueFalseAnswer;
