import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../helpers/rendering";
import { breakpoints } from "../../UIGlobals";
import { Color, Transitions } from "../../theme";

export const Sidebar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  border-right: 1px solid ${Color.dirtyWhite};
  overflow: hidden;
  height: 100vh;
  width: 80px;
  background: ${Color.white};
  transition: ${Transitions.custom};
  max-height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  align-items: center;

  @media (${breakpoints().maxL}) {
    left: -300px;
    width: 300px;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid ${Color.dirtyWhite};
    padding-bottom: 20px;

    ${styleWhenTrue(prop("$loggedOut"), css`
      left: 0;
      width: 100%;
      height: 80px;
      flex-direction: row;
      border-right: 0;
      padding-bottom: 0;
  `)}
  }
`;

export const LanguageIconWrapper = styled.div`
  height: 26px;
  width: 26px;
  border: 1px solid ${Color.gray1};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 25px;

  @media (${breakpoints().minL}) {
    margin-right: 0;
  }
`;

export const LogoWrapper = styled.div`
  margin-left: 25px;

  @media (${breakpoints().minL}) {
    margin: 13px 0 0;
  }
`;

export const Inner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (${breakpoints().minL}) and (${breakpoints().maxXL}) {
    margin-left: 80px;
    width: calc(100% - 80px);
  }
`;
