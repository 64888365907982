import styled from "styled-components";

import { Color, FontWeight } from "../../../../theme";
import { ReactComponent as PlaceholderSvg } from "../../images/emptyPlaceholder.svg";

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Color.white};
  padding: 50px 20px;
`;

export const Placeholder = styled(PlaceholderSvg)``;

export const Title = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: ${FontWeight.SemiBold};
  text-align: center;
  margin-top: 25px;
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 22px;
  margin-top: 1px;
  font-weight: ${FontWeight.Light};
  text-align: center;
`;
