import React from "react";
import styled from "styled-components";

import { colors, breakpoints } from "../../../../UIGlobals";

const Wrapper = styled.div`
  display: flex;
`;

const PartBlock = styled.div`
  height: 4px;
  width: 35px;
  margin: ${props => props.bottom ? "50px 10px 0 0" : "0 10px 50px 0"};
  background-color: ${props => props.active ? colors().maroon : colors().gray1};
  @media (${breakpoints().maxM}) {
    margin: 5px 0;
  }
`;

const PartsWrapper = ({ parts, activePart, bottom }) => {
  const iterateParts = (parts, activePart) => {
    const array = Array.from(Array(parts), () => 0);

    return array.map((element, index) => (
      <PartBlock key={index} active={index === activePart} bottom={bottom} />
    ));
  }

  return (
    <Wrapper>
      {iterateParts(parts, activePart, bottom)}
    </Wrapper>
  );
}

export default PartsWrapper;