import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { ReactComponent as ArrowSvg } from "../../../../images/arrow.svg";
import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.div`
  padding: 40px 20px;

  ${Media.tablet`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    flex: 2;
    flex-shrink: 0;
    padding: 40px;
  `}
`;

export const Title = styled.p`
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 1px;
  color: ${Color.blue5};
  margin-bottom: 15px;
  font-weight: ${FontWeight.Bold};
`;

export const QuestionText = styled.p`
  font-weight: ${FontWeight.SemiBold};
  font-size: 34px;
  line-height: 44px;
  color: ${Color.deepGray};
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 60px;
`;

export const PrevButton = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border: none;
  background-color: ${Color.almostWhite};
  border-radius: 3px;
  flex-shrink: 0;
`;

export const NextButton = styled.button`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: ${Color.gray6};
  color: ${Color.white};
  text-transform: uppercase;
  font-size: 10px;
  line-height: 20px;
  font-weight: ${FontWeight.Bold};
  border-radius: 3px;
  width: 100%;

  ${styleWhenTrue(prop("disabled"), css`
    opacity: 0.3;
    cursor: default;
  `)}

  ${Media.tablet`
    width: auto;
    min-width: 145px;
  `}
`;

export const ArrowPrev = styled(ArrowSvg)`
  fill: ${Color.gray1};
  height: 12px;
  width: 7px;
`;

export const ArrowNext = styled(ArrowSvg)`
  fill: ${Color.white};
  height: 12px;
  width: 7px;
  transform: rotate(180deg);
  margin-left: 16px;
`;

export const Answers = styled.div`
  margin-top: 36px;
  display: flex;
  flex-wrap: wrap;
`;

export const SingleChoiceAnswer = styled.button`
  width: 57px;
  height: 50px;
  background-color: ${Color.white};
  border: 1px solid ${Color.dirtyWhite};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Color.deepGray};
  font-size: 16px;
  line-height: 24px;
  margin-right: 4px;
  margin-top: 4px;

  ${styleWhenTrue(prop("active"), css`
    background-color: ${Color.blue5};
    border: 1px solid ${Color.blue5};
    color: ${Color.white};
  `)}
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const TextAreaInput = styled.textarea`
  width: 100%;
  height: 120px;
  background-color: ${Color.whiteHover};
  border: 1px solid ${Color.dirtyWhite};
  color: ${Color.deepGray};
  padding: 20px 50px 20px 20px;
  font-size: 16px;
  line-height: 22px;
  resize: none;
`;

export const InputCounter = styled.p`
  position: absolute;
  color: ${Color.gray1};
  font-size: 10px;
  line-height: 13px;
  font-weight: ${FontWeight.SemiBold};
  bottom: 10px;
  right: 30px;

  ${styleWhenTrue(prop("error"), css`
    color: ${Color.red};
  `)}
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: ${Color.gray9};
  margin-top: 40px;

  & + & {
    margin-top: 5px;
  }
`;
