import styled from "styled-components";

import { BackButton as BackButtonBase } from "../../../../components/backButton/backButton.component";
import { hexToRgba } from "../../../../helpers/colors";
import { Color, FontWeight, Media, SectionHeader as SectionHeaderBase } from "../../../../theme";
import { ReactComponent as ManWithBoxSvg } from "../../images/manWithBox.svg";

export const Container = styled.div`
  background-color: ${Color.almostWhite};
  width: 100%;
  padding-top: 20px;
  padding-bottom: 30px;

  ${Media.tablet`
    padding: 20px;
  `}
`;

export const Wrapper = styled.div`
  max-width: 940px;
  margin: 0 auto;
`;

export const SectionHeader = styled(SectionHeaderBase)`
  margin-top: 68px;
`;

export const BackButton = styled(BackButtonBase)`
  margin-left: 20px;

  ${Media.tablet`
    margin-left: 0;
  `}
`;

export const Placeholder = styled.div`
  background-color: ${Color.white};
  box-shadow: 0px 2px 10px ${hexToRgba(Color.gray9, 0.0424497)};
  position: relative;
  padding: 40px 20px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Media.tabletWide`
    flex-direction: row;
    justify-content: space-between;
    padding: 135px 80px;
  `}
`;

export const PlaceholderText = styled.div`
  font-size: 16px;
  max-width: 310px;
`;

export const Title = styled.p`
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: 10px;
  line-height: 22px;
  color: ${Color.deepGray};
`;

export const Text = styled.p`
  line-height: 26px;
  color: ${Color.gray6};
  font-weight: ${FontWeight.Light};
`;

export const PlaceholderImage = styled(ManWithBoxSvg)`
  margin: 40px 20px 0;

  ${Media.tabletWide`
    position: absolute;
    bottom: 0;
    right: 80px;
  `}
`;
