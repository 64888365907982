import styled, { css } from "styled-components";
import { prop } from "ramda";
import { CircularProgressbar } from "react-circular-progressbar";

import { hexToRgba } from "../../../../helpers/colors";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { FontWeight, Color, Media } from "../../../../theme";

export const Container = styled.div`
  padding: 10px 0 35px;
`;

export const Description = styled.p`
  font-size: 15px;
  line-height: 26px;
  color: ${Color.deepGray};
  margin-bottom: 35px;
`;

const weightFieldStyle = css`
  display: flex;
  align-items: center;
  font-size: 12px;

  p {
    margin-left: 10px;
  }

  ${Media.tablet`
    position: relative;
    top: 6px;
  `}
`;

export const WeightField = styled.div`
  ${weightFieldStyle}
`;

export const TableWeightField = styled.td`
  ${weightFieldStyle}
`;

export const Bold = styled.span`
  font-weight: ${FontWeight.Bold};
`;

export const MeasureTable = styled.table`
  width: 100%;
`;

export const TableHead = styled.thead`
  font-size: 10px;
  color: ${Color.gray9};
  font-weight: ${FontWeight.ExtraBold};
  border-bottom: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  border-top: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.6px;

  th {
    &:nth-child(1) {
      width: 40%;
    }
    &:nth-child(2) {
      width: 25%;
    }
  }
  th {
    padding: 14px 0;
    
    &:last-of-type {
    text-align: right;
  }
  }
`;

export const TableBody = styled.tbody`
  tr {
    border-bottom: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  }

  td {
    font-size: 14px;
    color: ${Color.gray9};
    line-height: 20px;

    &:not(${WeightField}) {
      padding: 30px 0;
    }

    &:first-of-type {
      color: ${Color.deepGray};
      font-weight: ${FontWeight.SemiBold};
      padding-right: 20px;
    }

    &:last-of-type {
      text-align: right;
      font-weight: ${FontWeight.SemiBold};
    }
  }
`;

export const Title = styled.p`
  color: ${Color.gray9};
  letter-spacing: 0.6px;
  text-transform: uppercase;
  font-weight: ${FontWeight.ExtraBold};
  margin: 20px 0 7px;
  font-size: 10px;

  ${styleWhenTrue(prop("bordered"), css`
    border-bottom: 1px solid ${hexToRgba(Color.gray5, 0.8)};
    border-top: 1px solid ${hexToRgba(Color.gray5, 0.8)};
    padding: 14px 0;  
  `)}
`;

export const SkillTitle = styled.h1`
  padding: 8px 0 15px;
  font-size: 14px;
  font-weight: 600;
  color: ${Color.deepGray};
  margin-top: 23px;

  ${Media.tablet`
    padding: 8px 0;
  `}
`;

export const SkillDetails = styled.div``;

export const SkillDetailsItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  padding: 15px 0;
  font-size: 14px;
  color: ${Color.gray9};

  &:last-of-type {
    border-bottom: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  }

  h1 {
    font-weight: ${FontWeight.Bold};
    letter-spacing: 0.6px;
    font-size: 10px;
    line-height: 20px;
    text-transform: uppercase;
  }
`;

export const SkillList = styled.div`
  & > ${Title} {
    text-transform: uppercase;
  }
`;

export const Chart = styled(CircularProgressbar)`
  width: 23px;
  height: 23px;

  && {
    .CircularProgressbar-path {
      stroke: ${Color.blue3};
      stroke-linecap: butt;
    }

    .CircularProgressbar-trail {
      stroke: ${hexToRgba(Color.gray1, 0.25)};
      stroke-linecap: butt;
    }
  }
`;
