import React from "react";
import MediaQuery from "react-responsive";

import { breakpoints } from "../../../../../UIGlobals";
import { AwardsProgressBox, ThumbnailList } from "../../../../../UIElements";
import { Row } from "../statistics.styled";

export const ProgressBoxes = ({ awards }) => (
  <Row marginBottom={60} marginTop={20} mobileMarginTop="0">
    <MediaQuery query={`(${breakpoints().maxM})`}>
      <ThumbnailList perPage={1} width="100%">
        <AwardsProgressBox marginRight={2} medalType="gold" award={awards[0]} />
        <AwardsProgressBox marginRight={2} medalType="silver" award={awards[1]} />
        <AwardsProgressBox medalType="bronze" award={awards[2]} />
      </ThumbnailList>
    </MediaQuery>
    <MediaQuery query={`(${breakpoints().minM})`}>
      <AwardsProgressBox marginRight={2} medalType="gold" award={awards[0]} />
      <AwardsProgressBox marginRight={2} medalType="silver" award={awards[1]} />
      <AwardsProgressBox medalType="bronze" award={awards[2]} />
    </MediaQuery>
  </Row>
);
