export const CoordsUsed = [
  [
    { x: 128, y: 20 },
    { x: 128, y: 44 },
    { x: 128, y: 68 },
    { x: 152, y: 68 },
    { x: 152, y: 92 },
    { x: 152, y: 116 },
    { x: 152, y: 140 },
    { x: 128, y: 140 },
    { x: 128, y: 164 },
    { x: 128, y: 188 },
    { x: 128, y: 212 },
    { x: 152, y: 212 },
    { x: 176, y: 212 },
    { x: 220, y: 181 },
  ],
  [
    { x: 20, y: 212 },
    { x: 44, y: 212 },
    { x: 68, y: 212 },
    { x: 92, y: 212 },
    { x: 116, y: 212 },
    { x: 116, y: 188 },
    { x: 116, y: 164 },
    { x: 92, y: 164 },
    { x: 68, y: 164 },
    { x: 68, y: 140 },
    { x: 35, y: 30},
  ],
  [
    { x: 38, y: 331 },
    { x: 38, y: 307,},
    { x: 38, y: 283 },
    { x: 62, y: 283 },
    { x: 86, y: 283 },
    { x: 86, y: 307 },
    { x: 110, y: 307 },
    { x: 134, y: 307 },
    { x: 158, y: 307 },
    { x: 158, y: 283 },
    { x: 158, y: 259 },
    { x: 182, y: 259 },
    { x: 206, y: 259 },
    { x: 230, y: 259 },
    { x: 254, y: 259 },
    { x: 278, y: 259 },
    { x: 278, y: 235 },
    { x: 278, y: 211 },
    { x: 254, y: 211 },
    { x: 230, y: 211 },
    { x: 206, y: 211 },
    { x: 206, y: 187 },
    { x: 206, y: 163 },
    { x: 206, y: 139 },
    { x: 236, y: 82 },
  ],
  [
    { x: 30, y: 115 },
    { x: 54, y: 115 },
    { x: 78, y: 115 },
    { x: 78, y: 91 },
    { x: 78, y: 67 },
    { x: 102, y: 67 },
    { x: 126, y: 67 },
    { x: 150, y: 67 },
    { x: 174, y: 67 },
    { x: 198, y: 67 },
    { x: 198, y: 91 },
    { x: 198, y: 115 },
    { x: 198, y: 139 },
    { x: 198, y: 163 },
    { x: 198, y: 187 },
    { x: 198, y: 211 },
    { x: 174, y: 211 },
    { x: 150, y: 211 },
    { x: 150, y: 187 },
    { x: 126, y: 187 },
    { x: 102, y: 187 },
    { x: 78, y: 187 },
    { x: 54, y: 187 },
    { x: 54, y: 211 },
    { x: 54, y: 235 },
    { x: 45, y: 279 },
  ],
  [
    { x: 78, y: 20 },
    { x: 78, y: 44 },
    { x: 102, y: 44 },
    { x: 126, y: 44 },
    { x: 150, y: 44 },
    { x: 174, y: 44 },
    { x: 174, y: 68 },
    { x: 174, y: 92 },
    { x: 150, y: 92 },
    { x: 150, y: 116 },
    { x: 150, y: 140 },
    { x: 150, y: 164 },
    { x: 174, y: 164 },
    { x: 189, y: 208 },
  ],
];
