import styled from "styled-components";

import { Color, FontWeight } from "../../../../../theme";

export const Container = styled.div`
  margin-bottom: 20px;
`;

export const TitleContainer = styled.div`
  background-color: ${Color.almostWhite};
  height: 90px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h1`
  font-weight: ${FontWeight.Bold};
  font-size: 16px;
  color: ${Color.blue4};
`;

export const Category = styled.h1`
  color: ${Color.blue4};
  font-weight: ${FontWeight.Bold};
  text-transform: uppercase;
  margin-bottom: 5px;
`;
