import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color } from "../../../../theme";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  ${styleWhenTrue(prop("noAward"), css`
    border: 1px solid ${Color.dirtyWhite};
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg {
      path {
        fill: ${Color.gray1};
      }
    }
  `)}
`;
