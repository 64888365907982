import styled, { css } from "styled-components";
import { prop } from "ramda";
import { NavLink } from "react-router-dom";

import { Color, FontWeight, Media } from "../../theme";
import { ReactComponent as DotsSvg } from "../../images/dots.svg";
import { styleWhenTrue } from "../../helpers/rendering";

export const Container = styled.div`
  height: calc(70px + env(safe-area-inset-bottom));
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px calc(12px + env(safe-area-inset-bottom));
  background-color: ${Color.white};
  border-top: 1px solid ${Color.dirtyWhite};
  z-index: 101;

  ${Media.tablet`
    display: none;
  `}
`;

export const NavTitle = styled.p`
  color: ${Color.gray9};
  font-weight: ${FontWeight.SemiBold};
  font-size: 12px;
`;

const navItemStyle = css`
  outline: none;
  border: none;
  background-color: ${Color.transparent};

  ${styleWhenTrue(prop("selected"), css`
    path {
      fill: ${() => Color.brandBasic};
    }
    circle {
      stroke: ${() => Color.brandBasic};
    }
    ${NavTitle} {
      color: ${() => Color.brandBasic};
    }
  `)}
`;

export const NavItem = styled(NavLink)`
  ${navItemStyle}
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

export const MoreButton = styled.button`
  ${navItemStyle}
`;

export const DotsIcon = styled(DotsSvg)`
  height: 22px;
`;

export const TutorialWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
