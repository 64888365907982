import styled, { css } from "styled-components";
import { prop } from "ramda";

import { ImageAspectRatio } from "../../../../components/imageUpload/imageUpload.constants";
import { styleWhenTrue } from "../../../../helpers/rendering";

export const Container = styled.div`
  width: 100%;
  overflow: hidden;

  ${styleWhenTrue(prop("withAspectRatio"), css`
    aspect-ratio: ${ImageAspectRatio};
  `)}
`;

export const Image = styled.img`
  width: 100%;

  ${({ positionX, positionY }) => `
    transform: translate(${-positionX}%, ${-positionY}%);
  `}
`;
