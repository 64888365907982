import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sortBy } from "lodash";

import { ConfirmationModal } from "../../../../components/confirmationModal/confirmationModal.component";
import { WebinarsActions } from "../../redux/webinars.reducer";
import { selectParticipants } from "../../redux/webinars.selectors";
import { AddUsersToRoomView } from "../addUsersToRoomView/addUsersToRoomView.component";
import { RoomDetailsDropdown } from "../roomDetailsDropdown/roomDetailsDropdown.component";
import { BroadcastType } from "../video/video.constants";

import {
  Container,
  Title,
  ButtonWrapper,
  Button,
} from "./roomsList.styled";

export const RoomsList = ({ rooms }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [roomToAddUsers, setRoomToAddUsers] = useState();
  const [closeRoomsModalOpen, setCloseRoomsModalOpen] = useState(false);
  const participants = useSelector(selectParticipants);
  const participantsWithoutPresenter = participants.filter(({ role }) => role !== BroadcastType.Presenter);
  const usersInPrivateRooms = rooms
    .reduce((prevValue, room) => room.participants ? [...prevValue, ...room.participants.list] : [...prevValue], [])
    .filter((user) => user.connected);
  const usersWithoutRoom = participantsWithoutPresenter.filter((participant) =>
    !usersInPrivateRooms.map((user) => user.user.id).includes(participant.user.id)
  );

  const openCloseRoomsModal = () => {
    setCloseRoomsModalOpen(true);
  };

  const hideCloseRoomsModal = () => {
    setCloseRoomsModalOpen(false);
  };

  const closeAllRooms = () => {
    rooms.forEach((room) => dispatch(WebinarsActions.closeRoom(room.id)));
  };

  const closeRoom = (id) => {
    dispatch(WebinarsActions.closeRoom(id))
  };

  const excludeUser = (roomId, user)  => {
    dispatch(WebinarsActions.kickUser(roomId, user.user.id));
  };

  const addUsersToRoom = (userIds) => {
    userIds.forEach((id) => dispatch(WebinarsActions.assignUserToRoom(roomToAddUsers.id, id)));
  };

  const renderRoomDropdowns = () => sortBy(rooms, "name").map((room, index) => (
    <RoomDetailsDropdown
      key={index}
      room={room}
      removeRoom={() => closeRoom(room.id)}
      excludeUser={(userId) => excludeUser(room.id, userId)}
      showAddUsersView={() => setRoomToAddUsers(room)}
      isRoomOpen
    />
  ));

  return (
    <Container>
      {roomToAddUsers ? (
        <AddUsersToRoomView
          roomName={roomToAddUsers.name}
          addUsersToRoom={addUsersToRoom}
          goBack={() => setRoomToAddUsers(null)}
          usersWithoutRoom={usersWithoutRoom}
        />
      ) : (
        <>
          <Title>{t("webinars.rooms")}</Title>
          {renderRoomDropdowns()}
          <ButtonWrapper>
            <Button onClick={openCloseRoomsModal}>
              {t("webinars.roomsList.closeAllRooms")}
            </Button>
          </ButtonWrapper>
          <ConfirmationModal
            open={closeRoomsModalOpen}
            title={t("webinars.roomsList.closeRooms")}
            onClose={hideCloseRoomsModal}
            onConfirm={closeAllRooms}
            confirmText={t("webinars.roomsList.closeRooms")}
            declineText={t("global.cancel")}
          >
            {t("webinars.roomsList.closeRoomsModalText")}
          </ConfirmationModal>
        </>
      )}
    </Container>
  );
};
