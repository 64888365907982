import { handleApiError, nodeApi, phpApi } from "../../api";

export const getInitialData = (id) => {
  return phpApi.get(`/api/content/${id}/init`).catch(handleApiError);
};

export const getRelated = (id) => {
  return phpApi.get(`/api/content/${id}/related`).catch(handleApiError);
};

export const getComments = (id) => {
  return nodeApi.get(`/comments/content/${id}`).catch(handleApiError);
};

export const addComment = (comment) => {
  return nodeApi.post(`/comments/content/${comment.contentId}`, comment).catch(handleApiError);
};

export const saveActions = (instanceId, actions) => {
  return phpApi.post(`/api/content/${instanceId}/save-actions`, { actions }).catch(() => ({ data: actions }));
};

export const setQuestionCompleted = (instanceId, quizes, completedSlide) => {
  return phpApi.post(`/api/content/${instanceId}/save-answers`, {
    quiz: quizes[0].id,
    answers: completedSlide,
  }).catch(() => "error");
};

export const saveQuiz = (instanceId, quiz) => {
  return phpApi.post(`/api/content/${instanceId}/save-quiz`, { quiz }).catch(handleApiError);
};
