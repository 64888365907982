import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ReactComponent as ReminderIcon } from "../../images/reminderIcon.svg";
import { Button } from "../../components/button/button.component";
import { ButtonColor } from "../../components/button/button.constants";
import { CommonActions } from "../../redux/common/common.reducer";

import {
  Container,
  Popup,
  Title,
  Text,
} from "./editProfileReminder.styled";

export const EditProfileReminder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  const handleClick = () => {
    dispatch(CommonActions.userProfileEditConfirm());
    navigate("/profil/edit");
    setIsOpen(false);
  };

  return (
    <Container open={isOpen}>
      <Popup>
        <ReminderIcon />
        <Title>{t("app.editProfileReminder.sentence_1")}</Title>
        <Text>{t("app.editProfileReminder.sentence_2")}</Text>
        <Button
          color={ButtonColor.Primary}
          onClick={handleClick}
        >
          {t("app.editProfileReminder.goToProfile")}
        </Button>
      </Popup>
    </Container>
  );
};
