import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { Container } from "../../../../theme/containers";
import { CompetenceActions } from "../../redux/competence.reducer";
import { CompetenceLevelsBox } from "../competenceLevelsBox/competenceLevelsBox.component";

import { Title } from "./markScaleTab.styled";

export const MarkScaleTab = ({ className, competenceId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(CompetenceActions.getCompetenceScales(competenceId));
  }, [competenceId, dispatch]);

  return (
    <Container className={className}>
      <Title>{t("competence.markScale.competenceLevels")}</Title>
      <CompetenceLevelsBox />
    </Container>
  );
};

MarkScaleTab.propTypes = {
  className: PropTypes.string,
  competenceId: PropTypes.number,
};
