import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { secondsToHours } from "../../../../../helpers/time";
import { GamificationActions } from "../../../redux/gamification.reducer";
import { selectUpcomingMeeting } from "../../../redux/gamification.selectors";

import {
  Container,
  TimeIcon,
  Text,
  Time,
  TimeColumn,
  TimeValue,
  TimeName,
  Colon,
} from "./upcomingMeeting.styled";

export const UpcomingMeeting = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const upcomingMeeting = useSelector(selectUpcomingMeeting);
  const startTime = upcomingMeeting?.startedAt;
  const [timeLeft, setTimeLeft] = useState(null);
  const days = Math.floor(timeLeft / (3600 * 24));
  const hours = Math.floor(timeLeft % (3600 * 24) / 3600);
  const minutes = Math.floor(timeLeft % 3600 / 60);

  useEffect(() => {
    dispatch(GamificationActions.getUpcomingMeeting());
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => setTimeLeft(
      dayjs(startTime * 1000).diff(dayjs(Date.now()), "second")
    ), 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [timeLeft, startTime]);

  return (
    <Container>
      <TimeIcon />
      <Text>
        {timeLeft
          ? t("gamification.upcomingMeeting.upcomingIn")
          : t("gamification.upcomingMeeting.noUpcoming")
        }
      </Text>
      {!!timeLeft && (
        <>
          {days >= 1 ? (
            <Time>
              <TimeColumn>
                <TimeValue>{days}</TimeValue>
                <TimeName>{t("gamification.upcoming.days")}</TimeName>
              </TimeColumn>
              <Colon>:</Colon>
              <TimeColumn>
                <TimeValue>{hours > 9 ? hours : `0${hours}`}</TimeValue>
                <TimeName>{t("gamification.upcoming.hours")}</TimeName>
              </TimeColumn>
              <Colon>:</Colon>
              <TimeColumn>
                <TimeValue>{minutes > 9 ? minutes : `0${minutes}`}</TimeValue>
                <TimeName>{t("gamification.upcoming.minutes")}</TimeName>
              </TimeColumn>
            </Time>
          ) : (
            <Time>{timeLeft < 0 ? "00 : 00 : 00" : secondsToHours(timeLeft, true)}</Time>
          )}
        </>
      )}
    </Container>
  );
};
