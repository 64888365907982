import styled, { css } from "styled-components";
import { prop } from "ramda";

import { ChallengeElement } from "../../../../components/challengeElement/challengeElement.component";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { hexToRgba } from "../../../../helpers/colors";
import { FontWeight, Media, Color } from "../../../../theme";
import DownloadCover from "../../images/downloadCover.svg";

export const Container = styled.div`
  background-color: ${Color.white};
  box-shadow: 0 5px 10px 0 ${hexToRgba(Color.deepGray, 0.01)};
  border: 1px solid ${Color.gray8};
  margin-bottom: 10px;
`;

export const ArticleWrapper = styled.div``;

export const Content = styled.div`
  font-size: 16px;
  line-height: 25px;
  word-break: break-word;
`;

export const ArticleTitle = styled.h3`
  font-size: 24px;
  line-height: 33px;
  color: ${Color.deepGray};
  font-weight: ${FontWeight.Bold};
  text-transform: uppercase;
  margin-bottom: 30px;
`;

export const TextWrapper = styled.div`
  overflow: auto;
  padding: 50px 20px 60px;

  ${Media.tablet`
    padding: 50px 40px 60px;
  `}
`;

export const AttachmentsWrapper = styled.div`
  margin: 20px 20px 0;
  font-size: 12px;
  line-height: 17px;

  ${Media.tablet`
    margin: 20px 40px 0;
  `}
`;

export const SectionHeader = styled.p`
  margin-bottom: 12px;
  color: ${Color.deepGray};
  font-weight: ${FontWeight.Bold};
`;

export const AttachmentList = styled.div`
  display: flex;
  margin-bottom: 50px;
  flex-wrap: wrap;
`;

export const AttachmentCover = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${hexToRgba(Color.blue5, 0.2)};
  transition: 0.2s;
  background-image: url(${({ imageSrc }) => imageSrc});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
  margin-right: 10px;
`;

export const AttachmentSize = styled.p`
  color: ${Color.gray9};
  transition: 0.2s;
`;

export const AttachmentLink = styled.a`
  cursor: pointer;
  background-color: ${Color.almostWhite};
  padding: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${Color.deepGray};
  width: 100%;
  transition: 0.2s;
  margin-bottom: 10px;

  ${styleWhenTrue(prop("disabled"), css`
    pointer-events: none;
  `)}

  &:hover {
    background-color: ${Color.blue5};
    color: ${Color.white};

    ${AttachmentCover} {
      background-color: ${Color.transparent};
      background-image: url(${DownloadCover});
    }

    ${AttachmentSize} {
      color: ${Color.white};
    }
  }

  ${Media.mobile`
    width: 280px;
    margin-right: 10px;
  `}
`;

export const AttachmentText = styled.div`
  font-size: 12px;
  line-height: 20px;
  overflow: hidden;
`;

export const AttachmentName = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;

  ${Media.mobile`
    white-space: nowrap;
  `}
`;

export const DeleteModalContent = styled.p``;

export const ReadMore = styled.button`
  margin-top: 15px;
  font-size: 16px;
  line-height: 30px;
  color: ${() => Color.brandBasic};
  background-color: ${Color.transparent};
  border: 0;
  cursor: pointer;
  padding: 0;

  ${styleWhenTrue(prop("noDisplay"), css`
    display: none;
  `)}

  &:hover {
    text-decoration: underline;
  }
`;

export const ChallengeContainer = styled.div`
  margin: 70px 20px 50px;

  ${Media.tablet`
    margin: 70px 40px 50px;
  `}
`;

export const ChallengeItem = styled(ChallengeElement)`
  border: 1px solid ${Color.dirtyWhite};
  padding: 34px 34px 0;
`;
