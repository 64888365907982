import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { ReactComponent as CheckSvg } from "../../images/check2.svg";
import { ReactComponent as ArrowSvg } from "../../images/arrow.svg";
import { ReactComponent as SearchSvg } from "../../images/searchSmall.svg";
import { styleIfElse, styleWhenTrue } from "../../helpers/rendering";
import { Color, FontWeight, Media } from "../../theme";
import { InputStyle } from "../input/input.constants";

export const Container = styled.div`
  height: 50px;
  background-color: ${Color.whiteHover};
  border: 1px solid ${Color.gray8};
  display: flex;
  margin-bottom: 10px;
  position: relative;

  ${styleWhenTrue(prop("small"), css`
    height: 36px;
  `)}

  ${styleWhenTrue(prop("disabled"), css`
    pointer-events: none;
  `)}

  ${styleWhenTrue(propEq(InputStyle.WebinarMessage, "$styleType"), css`
    background-color: ${Color.white};
  `)}
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  right: 20px;
  transform: rotate(-90deg);
  flex-shrink: 0;

  ${styleWhenTrue(prop("small"), css`
    right: 15px;
  `)}

  ${Media.tablet`
    right: 30px;

    ${styleWhenTrue(prop("small"), css`
      right: 15px;
    `)}
  `}
`;

export const ArrowIcon = styled(ArrowSvg)`
  fill: ${Color.gray9};
  width: 10px;
  height: 16px;
  transition: transform 0.3s ease-in-out;

  ${styleWhenTrue(propEq("true", "small"), css`
    width: 8px;
    height: 13px;
  `)}

  ${styleWhenTrue(prop("open"), css`
    transform: rotateY(180deg);
  `)}
`;

export const InputButton = styled.button`
  font-size: 16px;
  height: 100%;
  border: none;
  flex: 1;
  padding: 0 60px 0 20px;
  background: ${Color.transparent};
  z-index: 1;
  text-align: left;
  overflow: hidden;

  ${styleWhenTrue(prop("small"), css`
    padding: 0 30px 0 15px;
    font-size: 12px;
  `)}

  ${Media.tablet`
    padding: 0 60px 0 30px;

    ${styleWhenTrue(prop("small"), css`
      padding: 0 30px 0 15px;
    `)}
  `}

  ${styleWhenTrue(prop("showPlaceholder"), css`
    color: ${Color.gray4};
  `)}

  ${styleWhenTrue(prop("disabled"), css`
    cursor: default;
  `)}
`;

export const SelectedLabel = styled.div`
  white-space: nowrap;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  ${styleWhenTrue(propEq(InputStyle.ProfileForm, "$styleType"), css`
    font-weight: ${FontWeight.SemiBold};
    ${styleWhenTrue(prop("disabled"), css`
      font-weight: ${FontWeight.Normal};
      color: ${Color.gray9};
      font-size: 14px;
    `)}
  `)}

  ${styleWhenTrue(propEq(InputStyle.WebinarMessage, "$styleType"), css`
    font-weight: ${FontWeight.SemiBold};
    font-size: 12px;
    color: ${() => Color.brandBasic};
  `)}
`;

export const OptionsWrapper = styled.div`
  position: absolute;
  left: -1px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: calc(100% + 2px);
  border: 1px solid ${Color.gray8};
  ${({ dropdownHeight }) => !!dropdownHeight && `max-height: ${dropdownHeight}px`};

  ${styleIfElse(propEq(InputStyle.WebinarMessage, "$styleType"), css`
    bottom: 100%;
  `, css`
    top: 100%;
  `)}
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${Color.gray8};
  background-color: ${Color.almostWhite};
  padding: 7px 0;
  max-height: 240px;
  overflow-y: scroll;
`;

export const OptionButton = styled.button`
  border: none;
  color: ${Color.gray9};
  text-align: left;
  background-color: ${Color.transparent};
  padding: 7px 20px;
  display: flex;
  align-items: center;

  &:hover {
    color: ${Color.black};
  }

  ${styleWhenTrue(prop("selected"), css`
    color: ${Color.black};
  `)}

  ${Media.tablet`
    padding: 7px 30px;
  `}
`;

export const EmptyMessage = styled.p`
  padding: 7px 20px;

  ${styleWhenTrue(prop("small"), css`
    padding: 7px 15px;
    font-size: 12px;
  `)}

  ${Media.tablet`
    padding: 7px 30px;

    ${styleWhenTrue(prop("small"), css`
      padding: 7px 15px;
    `)}
  `}
`;

export const OptionText = styled.span`
  font-size: 16px;
  line-height: 22px;

  ${styleWhenTrue(prop("small"), css`
    font-size: 12px;
    line-height: 16px;
  `)}
`;

export const CheckIcon = styled(CheckSvg)`
  visibility: hidden;
  width: 15px;
  height: 12px;
  margin-right: 15px;
  flex-shrink: 0;

  ${styleWhenTrue(prop("selected"), css`
    visibility: visible;
  `)}
`;

export const MultipleCheckIcon = styled(CheckSvg)`
  border: 1px solid ${Color.gray9};
  width: 20px;
  height: 20px;
  margin-right: 12px;
  flex-shrink: 0;
  padding: 3px;
  fill: ${Color.transparent};

  ${styleWhenTrue(prop("selected"), css`
    fill: ${Color.white};
    background-color: ${Color.deepGray};
  `)}
`;

export const SearchWrapper = styled.div`
  border: 1px solid ${Color.gray8};
  background-color: ${Color.almostWhite};
  padding: 16px 20px;
  display: flex;
  align-items: center;

  ${Media.tablet`
    padding: 16px 30px;
  `}
`;

export const SearchField = styled.input`
  font-size: 16px;
  line-height: 22px;
  border: none;
  width: 100%;
  background-color: ${Color.transparent};
`;

export const SearchIcon = styled(SearchSvg)`
  stroke: ${Color.gray9};
  margin-right: 12px;
`;
