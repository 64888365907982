import React, { useEffect, useState } from "react";
import i18n from "i18next";

import { AppConfig } from "../../../../appConfig";
import { sizes } from "../../../../theme/media";
import { renderWhenTrue } from "../../../../helpers/rendering";

import { ThumbnailList } from "../../../../UIElements"; // TODO: change thumbnailList after new dashboard merge
import ContactItem from "../contactItem";
import {
  Container,
  Title,
  ContentWrapper,
  ItemWrapper,
  ContactsWrapper,
} from "./contactsSection.styled";

const ContactsSection = ({ contacts }) => {
  const [contactsInRow, setContactsInRow] = useState(1);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    if (window.innerWidth > sizes.desktopwide) {
      setContactsInRow(3);
    } else if (window.innerWidth > sizes.desktop) {
      setContactsInRow(2);
    } else {
      setContactsInRow(1);
    }
  };

  const renderContacts = () => (
    contacts.map((contact) => (
      <ItemWrapper key={contact.email}>
        <ContactItem
          name={contact.name}
          avatarUrl={`${AppConfig.content}/files${contact.avatar}`}
          description={contact.description}
          phone={contact.phone}
          email={contact.email}
        />
      </ItemWrapper>
    ))
  );

  const renderVerticalList = renderWhenTrue(() => (
    <ContactsWrapper>
      {renderContacts()}
    </ContactsWrapper>
  ));

  const renderHorizontalList = renderWhenTrue(() => (
    <ThumbnailList perPage={contactsInRow} coloredArrow>
      {renderContacts()}
    </ThumbnailList>
  ));

  return (
    <Container>
      <ContentWrapper>
        <Title>{i18n.t("onboarding.contact.contactWithUs")}</Title>
        {renderVerticalList(contactsInRow === 1)}
        {renderHorizontalList(contactsInRow > 1)}
      </ContentWrapper>
    </Container>
  );
};

export default ContactsSection;
