import React, { Component } from "react";
import styled from "styled-components";
import i18n from "i18next";

import TitleWithTime from "./titleWithTime";
import TitleWithIcon from "./titleWithIcon";
import ListWithBgIcon from "./listWithBgIcon";
import TextWithIcon from "./textWithIcon";
import IconInColumnAndText from "./iconInColumnAndText";
import IconColumnsAndText from "./iconColumnsAndText";
import { Icon } from "../../../UIElements/icons.js";
import { colors, breakpoints } from "../../../UIGlobals";
import { AppConfig } from "../../../appConfig";

const TimerSlidesDiv = styled.div`
  position: relative;
  margin-bottom: 80px;
`;

const ButtonsWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 2;
`;

const NavButton = styled.button`
  width: ${props => props.nextChapter ? "200px" : "50px"};
  height: 50px;
  background: transparent;
  border-radius: 25px;
  border: 2px solid ${props => props.nextChapter ? colors().maroon : colors().gray1};
  margin-left: 7px;
  color: ${colors().maroon};
  font-size: 14px;
  cursor: pointer;
  &:disabled {
    cursor: default;
    opacity: 0.15;
  }
`;

const TimerContainer = styled.div`
  position: absolute;
  top: 0;
  right: 20px;
  display: flex;
  align-items: center;
  @media (${breakpoints().maxM}) {
    margin-top: 8px;
  }
`;

const TimerMessage = styled.span`
  color: ${props => props.darkBg ? colors().white : colors().deepGray};
  font-size: 14px;
  line-height: 40px;
  margin-left: 10px;
  z-index: 2;
  @media (${breakpoints().maxM}) {
    font-size: 11px;
  }
`;

const Timer = styled(TimerMessage)`
  font-weight: bold;
  margin: 0;
`;

const IconText = styled.span`
  margin-right: 10px;
`;

class TimerSlides extends Component {
  state = {
    loading: true,
    activeInteraction: 0,
    activePart: 0
  };
  timeout = false;

  componentDidMount() {
    if (this.props.baseUrl + this.props.config) {
      fetch(this.props.baseUrl + this.props.config)
        .then(data => data.json())
        .then(data => this.setState({ ...data }, this.setState({ loading: false })));
    }
  }

  componentWillUnmount() {
    this.timeout = false;
  }

  renderInteraction(interaction) {
    const { activeInteraction, activePart, interactions } = this.state;
    const data = interactions[activeInteraction].parts[activePart];
    data.iconBaseUrl = `${this.props.baseUrl}icons/`;
    data.fileBaseUrl = `${AppConfig.content}/files/`;

    switch (interaction) {
    case "titleWithTime":
      return <TitleWithTime data={data} />;
    case "titleWithIcon":
      return <TitleWithIcon data={data} />;
    case "listWithBgIcon":
      return <ListWithBgIcon data={data} />;
    case "textWithBgIcon":
      return (
        <TextWithIcon
          data={data}
          activePart={activePart}
          parts={interactions[activeInteraction].parts.length}
          bigIcon
        />
      );
    case "textWithIcon":
      return (
        <TextWithIcon
          data={data}
          activePart={activePart}
          parts={interactions[activeInteraction].parts.length}
        />
      );
    case "textWithIconReversed":
      return (
        <TextWithIcon
          data={data}
          activePart={activePart}
          parts={interactions[activeInteraction].parts.length}
          bigIcon
          reversed
        />
      );
    case "textColumn":
      return (
        <TextWithIcon
          data={data}
          activePart={activePart}
          parts={interactions[activeInteraction].parts.length}
          textColumnType
        />
      );
    case "iconInColumnAndText":
      return (
        <IconInColumnAndText
          data={data}
          activePart={activePart}
          parts={interactions[activeInteraction].parts.length}
        />
      );
    case "iconInColumnAndTextLight":
      return (
        <IconInColumnAndText
          data={data}
          activePart={activePart}
          parts={interactions[activeInteraction].parts.length}
          columnColor={colors().dirtyWhite}
          lightTheme
        />
      );
    case "iconInColumnAndTextWhite":
      return (
        <IconInColumnAndText
          data={data}
          activePart={activePart}
          parts={interactions[activeInteraction].parts.length}
          columnColor={colors().white}
          lightTheme
        />
      );
    case "iconInColumnAndTextColor":
      return (
        <IconInColumnAndText
          data={data}
          activePart={activePart}
          parts={interactions[activeInteraction].parts.length}
          columnColor={colors().maroon}
        />
      );
    case "iconInColumnAndIconList":
      return (
        <IconInColumnAndText
          data={data}
          activePart={activePart}
          parts={interactions[activeInteraction].parts.length}
          iconList
        />
      );
    case "iconInColumnAndIconListWhite":
      return (
        <IconInColumnAndText
          data={data}
          activePart={activePart}
          parts={interactions[activeInteraction].parts.length}
          columnColor={colors().white}
          lightTheme
          iconList
        />
      );
    case "iconInColumnAndIconListLight":
      return (
        <IconInColumnAndText
          data={data}
          activePart={activePart}
          parts={interactions[activeInteraction].parts.length}
          columnColor={colors().dirtyWhite}
          lightTheme
          iconList
        />
      );
    case "iconInColumnAndIconListCounter":
      return (
        <IconInColumnAndText
          data={data}
          activePart={activePart}
          parts={interactions[activeInteraction].parts.length}
          iconList
          counterList
        />
      );
    case "iconInColumnAndIconListCounterWhite":
      return (
        <IconInColumnAndText
          data={data}
          activePart={activePart}
          parts={interactions[activeInteraction].parts.length}
          columnColor={colors().white}
          iconList
          counterList
          lightTheme
        />
      );
    case "iconInColumnAndMap":
      return (
        <IconInColumnAndText
          data={data}
          activePart={activePart}
          parts={interactions[activeInteraction].parts.length}
          columnColor={colors().dirtyWhite}
          lightTheme
          mapType
        />
      );
    case "iconColumnsAndText":
      return <IconColumnsAndText data={data} hasTextColumn />;
    case "iconColumns":
      return <IconColumnsAndText data={data} />;
    default:
      return <div>{i18n.t("interactions.external.external.lessonDownloadError")}</div>;
    }
  }

  goNext() {
    const { activeInteraction, activePart, interactions } = this.state;

    this.setState({
      activePart: activePart >= interactions[activeInteraction].parts.length - 1
        ? 0
        : activePart + 1,
      activeInteraction: activePart >= interactions[activeInteraction].parts.length - 1
        ? activeInteraction + 1
        : activeInteraction
    });
    if(!this.timeout) this.showTimeout();
  }

  goPrev() {
    const { activeInteraction, activePart, interactions } = this.state;

    this.setState({
      activePart: activePart <= 0
        ? interactions[activeInteraction - 1].parts.length - 1
        : activePart - 1,
      activeInteraction: activePart <= 0
        ? activeInteraction - 1
        : activeInteraction
    });
  }

  showTimeout() {
    this.timeout = setInterval(() => {
      const timer = this.state.timer - 1;
      this.setState({ ...this.state, timer });
      if (timer === 0) {
        clearInterval(this.timeout);
      }
    }, 1000);
  }

  render() {
    const { loading, activeInteraction, activePart, interactions } = this.state;
    const activeInteractionType = interactions ? interactions[activeInteraction].parts[activePart].type : "";
    const darkBg = interactions
      && (activeInteractionType === "titleWithIcon"
      || activeInteractionType === "titleWithTime");

    return (
      loading ? i18n.t("lesson.slide.slide.loading") :
        <TimerSlidesDiv>
          <TimerContainer>
            {this.timeout && (
              this.state.timer > 0 ? (
                <>
                  <Icon icon="clock" size={13} width={13} color={colors().gray1} />
                  <TimerMessage darkBg={darkBg}>
                    {i18n.t("interactions.external.external.skipChapterFor")}
                  </TimerMessage>
                  <span>&nbsp;</span>
                  <Timer darkBg={darkBg}>
                    {`${this.state.timer} ${i18n.t("interactions.external.external.timerUnit")}`}
                  </Timer>
                </>
              ) : (
                <TimerMessage darkBg={darkBg}>
                  {i18n.t("interactions.external.external.skipChapter")}
                </TimerMessage>
              )
            )}
          </TimerContainer>
          {interactions && this.renderInteraction(activeInteractionType)}
          {interactions && (
            <ButtonsWrapper>
              <NavButton
                onClick={() => this.goPrev()}
                disabled={activeInteraction <= 0 && activePart <= 0}
              >
                <Icon icon="interaction_arrow_left" size={14} width={14} color={colors().gray1} />
              </NavButton>
              {(activeInteraction < interactions.length - 1 || activePart < interactions[activeInteraction].parts.length - 1) &&
                <NavButton
                  onClick={() => this.goNext()}
                >
                  <Icon icon="interaction_arrow_right" size={14} width={14} color={colors().gray1} />
                </NavButton>
              }
              {(activeInteraction === interactions.length - 1 && activePart === interactions[activeInteraction].parts.length - 1) &&
                <NavButton
                  nextChapter
                  onClick={this.props.callComplete}
                  disabled={this.state.timer > 0}
                >
                  <IconText>{i18n.t("interactions.external.external.nextChapter")}</IconText>
                  <Icon icon="interaction_arrow_right" size={14} width={14} color={colors().maroon} />
                </NavButton>
              }
            </ButtonsWrapper>
          )}
        </TimerSlidesDiv>
    );
  }
}

export default TimerSlides;
