import styled from "styled-components";

import { Color, FontWeight } from "../../../../../theme";

export const Container = styled.div`
  width: 100%;
  background-color: ${Color.white};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-width: 335px;
  min-height: 328px;
`;

export const CustomDate = styled.div`
  width: 40px;
`;

export const CustomDateSpan = styled.span``;

export const TooltipContainer = styled.div`
  font-size: 12px;
  line-height: 16px;
`;

export const TooltipTitle = styled.p`
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: 5px;
`;

export const TooltipSubtitle = styled.p``;

export const MeetingTitle = styled.p`
  font-size: 14px;
  line-height: 21px;
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: 10px;
  color: ${Color.deepGray};
  text-align: left;
`;

export const MeetingInfo = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: ${Color.deepGray};
  text-align: left;
`;
