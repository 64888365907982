import { all, put, takeLatest } from "redux-saga/effects";

import * as appSettingsApi from "../services/api";

import { AppSettingsTypes, AppSettingsActions } from "./appSettings.reducer";

function* getBrands() {
  yield put(AppSettingsActions.setIsLoading(true));
  const { data } = yield appSettingsApi.getBrands();
  yield put(AppSettingsActions.getBrandsSuccess(data));
  yield put(AppSettingsActions.setIsLoading(false));
}

export function* watchAppSettings() {
  yield all([
    yield takeLatest(AppSettingsTypes.GET_BRANDS, getBrands),
  ]);
}
