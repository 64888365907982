import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

import { AppConfig } from "../../../../appConfig";

export const downloadAllFiles = (items, header, onFinish) => {
  const zip = new JSZip();
  const promises = [];

  for (const item of items) {
    let itemPromise = new JSZip.external.Promise((resolve, reject) => {
      JSZipUtils.getBinaryContent(
        AppConfig.content + item.url,
        (err, data) => err ? reject(err) : resolve(data));
    }).then(data => {
      // eslint-disable-next-line no-useless-escape
      const filename = item.url.replace(/^.*[\\\/]/, "").replace(/ /g, "_");
      zip.file(filename, data);
    });

    promises.push(itemPromise);
  }

  Promise.all(promises).then(() => {
    zip.generateAsync({ type: "blob" })
      .then(blob => {
        saveAs(blob, `${header.replace(/ /g, "_")}.zip`);
        onFinish();
      });
  });
};
