import React, { Fragment, useState } from "react";
import dayjs from "dayjs";
import MediaQuery from "react-responsive";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Modal } from "../../../../components/modal/modal.component";
import { renderWithLinks } from "../../../../helpers";
import { breakpoints } from "../../../../UIGlobals";
import { TrainerPopup } from "../../../trainings/components/trainerPopup/trainerPopup.component";
import { TrainingsActions } from "../../../trainings/redux/trainings.reducer";

import {
  Link,
  ExternalLink,
  TextWithLink,
  TrainerName,
  Col,
  Row,
  Text,
} from "./details.styled";

export const Details = ({
  category,
  description,
  start,
  end,
  location,
  days,
  trainers,
  status,
  webinarUrl,
  roomId,
  author,
  videoId,
  modules,
  finished,
  isCyclical,
}) => {
  const historyLocation = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [trainerPopupId, setTrainerPopupId] = useState();
  const isOnline = !!webinarUrl || !!roomId || !!videoId;
  const formattedDescription = description ? description.split("\n") : [];

  const handleCloseTrainerPopup = () => {
    setTrainerPopupId();
    dispatch(TrainingsActions.clearTrainerMetric());
  };

  return (
    <Col width="100%" $flexDirection="column" paddingBottom={56}>
      <MediaQuery query={`(${breakpoints().maxM})`}>
        {!!modules.length && (
          <Row $flexDirection="column">
            <Col width="100%">
              <Text color="black" fontSize={1.4} fontWeight={600}>
                {t("training.details.module")}:
              </Text>
            </Col>
            <Col width="100%" $flexDirection="column">
              <Text paddingTop={11} fontSize={1.4} color="deepGray" lineHeight={1.4}>
                {modules.map((module) => (
                  <React.Fragment key={module.id}>
                    {module.name}
                    <br />
                  </React.Fragment>
                ))}
              </Text>
            </Col>
          </Row>
        )}
        <Row paddingTop={!!modules.length && 17} $flexDirection="column">
          <Col width="100%">
            <Text paddingTop={0} color="black" fontSize={1.4} fontWeight={600}>
              {t("training.details.category")}:
            </Text>
          </Col>
          <Col width="100%" $flexDirection="column">
            <Text paddingTop={11} fontSize={1.4} color="deepGray" lineHeight={1.4}>
              {category}
            </Text>
          </Col>
        </Row>
        <Row paddingTop={17} $flexDirection="column">
          <Col width="100%">
            <Text paddingTop={0} color="black" fontSize={1.4} fontWeight={600}>
              {t("training.details.description")}:
            </Text>
          </Col>
          <Col width="100%" $flexDirection="column">
            {formattedDescription.map((descriptionPart, index) => (
              <Text key={index} paddingTop={11} fontSize={1.4} color="deepGray" lineHeight={1.4}>
                {descriptionPart}
              </Text>
            ))}
          </Col>
        </Row>
        <Row paddingTop={17} $flexDirection="column">
          <Col width="100%" $flexDirection="column">
            <Text paddingTop={0} color="black" fontSize={1.4} fontWeight={600}>
              {t("training.details.date")}:
            </Text>
          </Col>
          <Col width="100%" $flexDirection="column">
            <Text paddingTop={11} fontSize={1.4} color="deepGray" lineHeight={1.4}>
              {start && (
                dayjs.unix(start).locale("pl").format("DD MMMM YYYY HH:mm")
              )}
              {!!end && (
                <>
                  {" - "}
                  {dayjs.unix(start).format("DD MM YYYY") === dayjs.unix(end).format("DD MM YYYY")
                    ? dayjs.unix(end).locale("pl").format("HH:mm")
                    : dayjs.unix(end).locale("pl").format("DD MMMM YYYY HH:mm")
                  }
                </>
              )}
            </Text>
          </Col>
        </Row>
        <Row paddingTop={17} $flexDirection="column">
          <Col width="100%" $flexDirection="column">
            <Text paddingTop={0} color="black" fontSize={1.4} fontWeight={600}>
              {t("training.details.location")}:
            </Text>
          </Col>
          <Col width="100%" $flexDirection="column">
            <Text paddingTop={11} fontSize={1.4} color="deepGray" lineHeight={1.4}>
              {location
                ? `${location.name}, ${location.address}, ${location.postcode} ${location.city}`
                : isCyclical || isOnline
                  ? t("training.details.online2")
                  : t("training.details.trainingLocationNone")
              }
            </Text>
          </Col>
        </Row>
        <Row paddingTop={17} $flexDirection="column">
          <Col width="100%" $flexDirection="column">
            <Text paddingTop={0} color="black" fontSize={1.4} fontWeight={600}>
              {t("training.details.duration")}:
            </Text>
          </Col>
          <Col width="100%" $flexDirection="column">
            <Text paddingTop={11} fontSize={1.4} color="deepGray" lineHeight={1.4}>
              {isCyclical ? (
                t("training.details.cyclicalEvent")
              ) : (
                <>{days} {days > 1 ? t("global.days") : t("global.day")}</>
              )}
            </Text>
          </Col>
        </Row>
        <Row paddingTop={17} $flexDirection="column">
          <Col width="100%" $flexDirection="column">
            <Text paddingTop={0} color="black" fontSize={1.4} fontWeight={600}>
              {t("training.details.trainers")}:
            </Text>
          </Col>
          <Col width="100%" $flexDirection="column">
            <Text paddingTop={11} fontSize={1.4} color="deepGray" lineHeight={1.4}>
              {trainers.map((trainer, index) => (
                <Fragment key={trainer.id}>
                  {index !== 0 && ", "}
                  <TrainerName
                    onClick={() => setTrainerPopupId(trainer.id)}
                    disabled={!trainer.hasMetric}
                  >
                    {trainer.name}
                  </TrainerName>
                  <Modal open={trainerPopupId === trainer.id} onClose={handleCloseTrainerPopup}>
                    <TrainerPopup trainerId={trainer.id} onClose={handleCloseTrainerPopup} />
                  </Modal>
                </Fragment>
              ))}
            </Text>
          </Col>
        </Row>
        <Row paddingTop={17} flexDirection="column">
          <Col width="100%" flexDirection="column">
            <Text paddingTop="0" color="black" fontSize={1.4} fontWeight={600}>
              {t("training.details.status")}:
            </Text>
          </Col>
          <Col width="100%" flexDirection="column">
            <Text paddingTop={11} fontSize={1.4} color="deepGray" lineHeight={1.4}>{status}</Text>
          </Col>
        </Row>
        {!!author && (
          <Row paddingTop={17} $flexDirection="column">
            <Col width="100%" $flexDirection="column">
              <Text paddingTop={0} color="black" fontSize={1.4} fontWeight={600}>
                {t("training.details.author")}:
              </Text>
            </Col>
            <Col width="100%" $flexDirection="column">
              <Text paddingTop={11} fontSize={1.4} color="deepGray" lineHeight={1.4}>
                {`${author.name} ${author.surname}`}
                {!!author.email && (
                  <>
                    <br />
                    {t("training.details.email")}:
                    {" "}
                    <ExternalLink href={`mailto:${author.email}`}>{author.email}</ExternalLink>
                  </>
                )}
                {!!author.phone && (
                  <>
                    <br />
                    {t("training.details.phone")}:
                    {" "}
                    <ExternalLink href={`tel:${author.phone}`}>{author.phone}</ExternalLink>
                  </>
                )}
              </Text>
            </Col>
          </Row>
        )}
        {isOnline && !finished && (
          <Row paddingTop={17} $flexDirection="column">
            <Col width="100%" $flexDirection="column">
              <Text paddingTop={0} color="black" fontSize={1.4} fontWeight={600}>
                {t("training.details.webinarLink")}:
              </Text>
            </Col>
            <Col width="100%" $flexDirection="column">
              <Text paddingTop={11} fontSize={1.4} color="deepGray" lineHeight={1.4}>
                {roomId || videoId ? (
                  <Link
                    to={videoId ? `/wideo/${videoId}` : `/webinar/${roomId}`}
                    state={{ from: historyLocation.pathname }}
                  >
                    {t("training.details.goToMeeting")}
                  </Link>
                ) : (
                  <TextWithLink>
                    {renderWithLinks(webinarUrl, t("training.details.goToMeeting"))}
                  </TextWithLink>
                )}
              </Text>
            </Col>
          </Row>
        )}
      </MediaQuery>
      <MediaQuery query={`(${breakpoints().minM})`}>
        {!!modules.length && (
          <Row alignItems="flex-start">
            <Col width="25%">
              <Text color="black" fontSize={1.4} lineHeight={1.4}>
                {t("training.details.module")}:
              </Text>
            </Col>
            <Col width="75%">
              <Text fontSize={1.4} color="deepGray" lineHeight={1.4}>
                {modules.map((module) => (
                  <React.Fragment key={module.id}>
                    {module.name}
                    <br />
                  </React.Fragment>
                ))}
              </Text>
            </Col>
          </Row>
        )}
        <Row paddingTop={!!modules.length && 17}>
          <Col width="25%">
            <Text paddingTop="0" color="black" fontSize={1.4}>
              {t("training.details.category")}:
            </Text>
          </Col>
          <Col width="75%">
            <Text paddingTop="0" fontSize={1.4} color="deepGray" lineHeight={1.4}>
              {category}
            </Text>
          </Col>
        </Row>
        <Row alignItems="flex-start" paddingTop={17}>
          <Col width="25%">
            <Text paddingTop="0" color="black" fontSize={1.4}>
              {t("training.details.description")}:
            </Text>
          </Col>
          <Col width="75%" $flexDirection="column">
            {formattedDescription.map((descriptionPart, index) => (
              <Text
                key={index}
                paddingTop={index === 0 ? "0" : 11}
                fontSize={1.4}
                color="deepGray"
                lineHeight={1.4}
              >
                {descriptionPart}
              </Text>
            ))}
          </Col>
        </Row>
        <Row paddingTop={17}>
          <Col width="25%">
            <Text paddingTop="0" color="black" fontSize={1.4}>
              {t("training.details.date")}:
            </Text>
          </Col>
          <Col width="75%">
            <Text paddingTop="0" fontSize={1.4} color="deepGray" lineHeight={1.4}>
              {start && (
                dayjs.unix(start).locale("pl").format("DD MMMM YYYY HH:mm")
              )}
              {!!end && (" - ")}
              {!!end && (
                dayjs.unix(start).format("DD MM YYYY") === dayjs.unix(end).format("DD MM YYYY") ? (
                  dayjs.unix(end).locale("pl").format("HH:mm")
                ) : (
                  dayjs.unix(end).locale("pl").format("DD MMMM YYYY HH:mm")
                )
              )}
            </Text>
          </Col>
        </Row>
        <Row paddingTop={17}>
          <Col width="25%">
            <Text paddingTop="0" color="black" fontSize={1.4}>
              {t("training.details.location")}:
            </Text>
          </Col>
          <Col width="75%">
            <Text paddingTop="0" fontSize={1.4} color="deepGray" lineHeight={1.4}>
              {location
                ? `${location.name}, ${location.address}, ${location.postcode} ${location.city}`
                : isCyclical
                  ? t("training.details.online2")
                  : t("training.details.trainingLocationNone")
              }
            </Text>
          </Col>
        </Row>
        {!!days && (
          <Row paddingTop={17}>
            <Col width="25%">
              <Text paddingTop="0" color="black" fontSize={1.4}>
                {t("training.details.duration")}:
              </Text>
            </Col>
            <Col width="75%">
              <Text paddingTop="0" fontSize={1.4} color="deepGray" lineHeight={1.4}>
                {isCyclical ? (
                  t("training.details.cyclicalEvent")
                ) : (
                  <>{days} {days > 1 ? t("global.days") : t("global.day")}</>
                )}
              </Text>
            </Col>
          </Row>
        )}
        <Row paddingTop={17}>
          <Col width="25%">
            <Text paddingTop="0" color="black" fontSize={1.4}>
              {t("training.details.trainers")}:
            </Text>
          </Col>
          <Col width="75%">
            <Text paddingTop="0" fontSize={1.4} color="deepGray" lineHeight={1.4}>
              {trainers.map((trainer, index) => (
                <Fragment key={trainer.id}>
                  {index !== 0 && ", "}
                  <TrainerName
                    onClick={() => setTrainerPopupId(trainer.id)}
                    disabled={!trainer.hasMetric}
                  >
                    {trainer.name}
                  </TrainerName>
                  <Modal open={trainerPopupId === trainer.id} onClose={handleCloseTrainerPopup}>
                    <TrainerPopup trainerId={trainer.id} onClose={handleCloseTrainerPopup} />
                  </Modal>
                </Fragment>
              ))}
            </Text>
          </Col>
        </Row>
        <Row paddingTop={17}>
          <Col width="25%">
            <Text paddingTop="0" color="black" fontSize={1.4}>
              {t("training.details.status")}:
            </Text>
          </Col>
          <Col width="75%">
            <Text paddingTop="0" fontSize={1.4} color="deepGray" lineHeight={1.4}>{status}</Text>
          </Col>
        </Row>
        {!!author && (
          <Row paddingTop={17}>
            <Col width="25%">
              <Text paddingTop="0" color="black" fontSize={1.4}>
                {t("training.details.author")}:
              </Text>
            </Col>
            <Col width="75%">
              <Text paddingTop="0" fontSize={1.4} color="deepGray" lineHeight={1.4}>
                {`${author.name} ${author.surname}`}
                {!!author.email && (
                  <>
                    <br />
                    {t("training.details.email")}:
                    {" "}
                    <ExternalLink href={`mailto:${author.email}`}>{author.email}</ExternalLink>
                  </>
                )}
                {!!author.phone && (
                  <>
                    <br />
                    {t("training.details.phone")}:
                    {" "}
                    <ExternalLink href={`tel:${author.phone}`}>{author.phone}</ExternalLink>
                  </>
                )}
              </Text>
            </Col>
          </Row>
        )}
        {isOnline && !finished && (
          <Row paddingTop={17}>
            <Col width="25%">
              <Text paddingTop="0" color="black" fontSize={1.4}>
                {t("training.details.webinarLink")}:
              </Text>
            </Col>
            <Col width="75%">
              <Text paddingTop="0" fontSize={1.4} color="deepGray" lineHeight={1.4}>
                {roomId || videoId ? (
                  <Link
                    to={videoId ? `/wideo/${videoId}` : `/webinar/${roomId}`}
                    state={{ from: historyLocation.pathname }}
                  >
                    {t("training.details.goToMeeting")}
                  </Link>
                ) : (
                  <TextWithLink>
                    {renderWithLinks(webinarUrl, t("training.details.goToMeeting"))}
                  </TextWithLink>
                )}
              </Text>
            </Col>
          </Row>
        )}
      </MediaQuery>
    </Col>
  );
};
