import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Separator } from "../../../../theme/typography";

import {
  Container,
  Wrapper,
  ImageWrapper,
  CalendarIcon,
  Title,
  TextWrapper,
  Type,
} from "./multiFilesTraining.styled";

export const MultiFilesTraining = ({ className, id, name, onClick }) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  return (
    <Container className={className}>
      <Wrapper onClick={handleClick}>
        <ImageWrapper>
          <CalendarIcon />
        </ImageWrapper>
        <TextWrapper>
          <Title>{name}</Title>
          <Type>{t("learning.files.training")}</Type>
        </TextWrapper>
      </Wrapper>
      <Separator />
    </Container>
  );
};

MultiFilesTraining.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  onClick: PropTypes.func,
};
