import styled from "styled-components";

import { Color } from "../../../../theme";

export const GhostButton = styled.button`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0;
  cursor: pointer;
`;

export const HeadlineSmall = styled.h5`
  font-size: 1.2rem;
  font-weight: 800;
  color: ${Color.black};
  text-transform: uppercase;
`;

export const HeadlineBig = styled.h3`
  font-size: 3.3rem;
  color: #020202;
  font-weight: 300;
  padding: 37px 0 60px;
`;

export const Row = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${Color.dirtyWhite};
  padding-bottom: 30px;
  margin-bottom: 30px;
  text-decoration: none;

  &:hover img {
    transform: scale(1.2);
    opacity: 0.6;
  }
`;

export const Col = styled.div`
  width: ${({ width }) => (width ? width : "78%")};
  display: flex;
  flex-direction: ${({ $flexDirection }) => ($flexDirection ? $flexDirection : "row")};
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? paddingBottom + "px" : "0")};
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft + "px" : "0")};
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft + "px" : "0")};
  overflow: hidden;
  background: ${({ $background }) => ($background ? $background : "")};
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : 0)};
  padding-right: ${({ paddingRight }) => (paddingRight ? `${paddingRight}px` : "")};
  align-items:  ${({ alignItems }) => (alignItems ? alignItems : "")};
  flex-shrink: ${({ flexShrink }) => (flexShrink ? flexShrink : "1")};
`;

export const Thumbnail = styled.img`
  background-color: ${Color.dirtyWhite};
  text-decoration: none;
  transition: all .8s ease-in-out;
  width: ${({ width }) => (width ? width : "145px")};
`;

export const RowText = styled.div`
  font-size: ${({ fontSize }) => (fontSize ? fontSize + "rem" : "1.6rem")};
  color: ${({ color }) => (color ? Color[color] : Color.deepGray)};
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? paddingBottom + "px" : "0")};
  line-height: 1.9rem;
`;
