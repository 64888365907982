import React from "react";
import PropTypes from "prop-types";

import { Input } from "../../../../components/input/input.component";
import { InputStyle } from "../../../../components/input/input.constants";

import { Container, InputLabel } from "./formInput.styled";

export const FormInput = ({
  name,
  title,
  placeholder,
  type,
  value,
  onChange,
  onBlur,
  error,
  disabled,
}) => {
  return (
    <Container>
      <InputLabel>{title}</InputLabel>
      <Input
        type={type}
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        styleType={InputStyle.ProfileForm}
        placeholder={placeholder}
      />
    </Container>
  );
};

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string,
};
