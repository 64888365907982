import { Breakpoint, Color, Disclaimer, StyledComponent } from '@elearning-platform/ui';
import { css, RuleSet, styled } from 'styled-components';

import BgMobile from './images/bgMobile.jpg';
import { TopBarProps } from './registration.types';

export const Container: StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const MobileImage: StyledComponent = styled.div`
  background-image: url(${BgMobile});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  aspect-ratio: 1125 / 708;
  min-height: 242px;
  max-height: 400px;
  width: 100%;
`;

export const TopBar: StyledComponent<TopBarProps> = styled.div<TopBarProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 16px;
  border-bottom: 1px solid ${Color.Gray300};
  border-top: 1px solid transparent;

  ${({ $noBorder }: TopBarProps): RuleSet<TopBarProps> | false => !!$noBorder && css`
    border-bottom: 1px solid transparent;
  `}

  @media ${Breakpoint.SmallDesktop} {
    padding: 23px 40px;
  }
`;

export const Logo: StyledComponent = styled.img`
  display: block;
  height: 28px;

  @media ${Breakpoint.SmallDesktop} {
    height: 32px;
  }
`;

export const ContentWrapper: StyledComponent = styled.div`
  display: flex;
`;

export const DesktopImage: StyledComponent = styled.img`
  display: block;
  width: 50%;
  max-width: 640px;
  object-fit: cover;
  object-position: center;
`;

export const Buttons: StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media ${Breakpoint.SmallDesktop} {
    flex-direction: row;
    gap: 28px;
    align-items: center;
    position: relative;
  }
`;

export const BottomBar: StyledComponent = styled.div`
  padding: 24px 16px;
  border-top: 1px solid ${Color.Gray300};
  display: flex;
  align-items: center;
  margin-top: auto;

  @media ${Breakpoint.SmallDesktop} {
    padding: 12px 40px;
    min-height: 80px;
  }
`;

export const StyledDisclaimer: StyledComponent = styled(Disclaimer)`
  & a {
    color: ${Color.Gray800};
  }
`;
