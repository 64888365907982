import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Button as ButtonBase } from "../../../../components/button/button.component";
import { ReactComponent as CloseSVG } from "../../../../images/close2.svg";
import { hexToRgba } from "../../../../helpers/colors";
import { Color, FontWeight } from "../../../../theme";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { ReactComponent as TrashSvg } from "../../images/trash.svg";
import { ReactComponent as TickSvg } from "../../images/tick.svg";

export const Container = styled.div`
  display: none;

  ${styleWhenTrue(prop("visible"), css`
    display: flex;
    height: 100%;
    flex-direction: column;
  `)}
`;

export const CloseIcon = styled(CloseSVG)`
  height: 12px;
  width: 12px;
  fill: ${Color.deepGray};
`;

export const Title = styled.h1`
  font-weight: ${FontWeight.Light};
  font-size: 32px;
  line-height: 44px;
  margin-bottom: 36px;
`;

export const Row = styled.div`
  display: flex;
  padding: 20px 0 15px;
  align-items: center;

  & + & {
    padding-top: 0;
  }
`;

export const IconText = styled.p`
  font-weight: ${FontWeight.SemiBold};
  font-size: 13px;
  line-height: 17px;
  color: ${Color.deepGray};
`;

export const SmallButton = styled(ButtonBase)`
  margin-left: auto;
  padding: 10px 18px;
  min-width: unset;
`;

export const GrayText = styled.p`
  font-size: 13px;
  line-height: 21px;
  color: ${Color.gray9};
  margin: 20px 0;
`;

export const RoomRow = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const Input = styled.input`
  width: 100%;
  border: 1px solid ${Color.dirtyWhite};
  padding: 14px 20px;
  color: ${Color.deepGray};

  ::placeholder {
    color: ${Color.gray4};
  }
`;

export const RemoveButton = styled.button`
  width: 50px;
  height: 50px;
  margin-left: 10px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TrashIcon = styled(TrashSvg)`
  path {
    fill: ${() => Color.brandBasic};
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  font-weight: ${FontWeight.Light};
  font-size: 12px;
  line-height: 16px;
  color: ${Color.deepGray};
  cursor: pointer;
  margin-top: 5px;

  input[type="radio"] {
    margin: 0 10px 0 0;

    &:after {
        position: relative;
        display: inline-block;
        content: '';
        cursor: pointer;
        box-sizing: border-box;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        top: -2px;
        left: -1px;
        background-color: ${Color.white};
        border: 1px solid ${Color.dirtyWhite};
    }

    &:checked:after {
        position: relative;
        display: inline-block;
        content: '';
        cursor: pointer;
        box-sizing: border-box;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        top: -2px;
        left: -1px;
        border: 6px solid ${() => Color.brandBasic};
    }
  }
`;

export const RadioGroup = styled.div`
  padding-bottom: 30px;

  ${styleWhenTrue(prop("withTopPadding"), css`
    padding-top: 25px;
  `)}
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${hexToRgba(Color.gray5, 0.8)};
`;

export const TickIcon = styled(TickSvg)`
  display: none;
`;

export const Checkbox = styled.div`
  width: 18px;
  height: 18px;
  border: 1px solid ${Color.gray9};
  display: grid;
  place-items: center;
  cursor: pointer;
  margin-right: 10px;

  ${styleWhenTrue(prop("checked"), css`
    background-color: ${Color.black};
    border: none;

    ${TickIcon} {
      display: block;
    }
  `)}
`;

export const CheckboxText = styled.p`
  font-weight: ${FontWeight.SemiBold};
  font-size: 12px;
  line-height: 16px;
  color: ${Color.deepGray};
  cursor: pointer;
  margin-right: 20px;

  ${styleWhenTrue(prop("greyed"), css`
    color: ${Color.gray4};
  `)}
`;

export const Button = styled(ButtonBase)`
  flex-shrink: 0;
  
  &:disabled {
    color: ${Color.gray1};
    background-color: ${Color.almostWhite};
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: auto;
  height: 123px;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Error = styled.p`
  color: ${Color.error};

  ${RoomRow} + & {
    margin-top: 8px;
  }
`;
