import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Lottie from "lottie-react";

import { useMediaListener } from "../../../../../hooks/useMediaListener";
import StartAnimation from "../../../anims/startAnim.json";

import {
  Container,
  Wrapper,
  TextBox,
  Title,
  Text,
  AnimationBox,
  RoundButton,
  ArrowIcon,
} from "./startHeader.styled";

export const StartHeader = ({ className, handleScroll }) => {
  const { t } = useTranslation();
  const { isTablet } = useMediaListener();

  return (
    <Container className={className}>
      <Wrapper>
        <TextBox>
          <Title>{t("gamification.start.comeIn")}</Title>
          <Text>{t("gamification.start.reachMore")}</Text>
          <RoundButton hidden={!isTablet} onClick={handleScroll}>
            <ArrowIcon />
          </RoundButton>
        </TextBox>
        <AnimationBox>
          <Lottie
            animationData={StartAnimation}
            loop
            rendererSettings={{
              preserveAspectRatio: "xMidYMid slice",
            }}
          />
        </AnimationBox>
        <RoundButton hidden={isTablet} onClick={handleScroll}>
          <ArrowIcon />
        </RoundButton>
      </Wrapper>
    </Container>
  );
};

StartHeader.propTypes = {
  className: PropTypes.string,
  handleScroll: PropTypes.func,
};
