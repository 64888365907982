import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Media, Color } from "../../../../../theme";
import { ReactComponent as ThumbUpSvg } from "../../../../../images/thumbUp.svg";
import { ReactComponent as ThumbDownSvg } from "../../../../../images/thumbDown.svg";
import { styleWhenTrue } from "../../../../../helpers/rendering";


export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;

  ${Media.mobile`
    padding-top: 100px;
  `}

  ${Media.tablet`
    height: 600px;
    padding-top: 220px;
  `}
`;

const commonIconStyle = css`
  fill: ${Color.blue};
  width: 40px;

  ${styleWhenTrue(prop("faded"), css`
    fill: ${Color.gray1};
  `)}
`;

export const ThumbUpIcon = styled(ThumbUpSvg)`
  ${commonIconStyle}
`;

export const ThumbDownIcon = styled(ThumbDownSvg)`
  ${commonIconStyle}
`;

export const ThumbButton = styled.div`
  width: 133px;
  height: 133px;
  border: 1px solid ${Color.gray1};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 40px;
  flex-shrink: 0;

  ${styleWhenTrue(prop("selected"), css`
    border: none;
    svg {
      fill: ${Color.white};
    }
  `)}

  ${styleWhenTrue(prop("wrongTheme"), css`
    background-color: ${Color.red4};
  `)}

  ${styleWhenTrue(prop("correctTheme"), css`
    background-color: ${Color.blue};
  `)}
`;

export const ThumbsContainer = styled.div`
  display: flex;
  flex-shrink: 0;

  ${ThumbButton} + ${ThumbButton} {
    margin: 0 20px;

    ${Media.tablet`
      margin: 0 80px 0 20px;
    `}
  }
`;

export const ThumbsAndTexts = styled.div`
  display: flex;
  max-width: 1000px;
  align-items: flex-start;
  justify-content: center;
  margin-top: 40px;
  align-self: center;

  ${Media.tablet`
    margin-top: 0;
    justify-content: flex-start;
  `}
`;

export const Text = styled.span`
  font-size: 20px;
  line-height: 33px;
  text-align: center;

  ${Media.tablet`
    text-align: left;
  `}
`;

export const WrongAnswerComment = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: ${Color.red4};
  text-align: center;
  margin: 40px 0 80px;

  ${Media.tablet`
    text-align: left
  `}
  
  ${Media.tabletWide`
    margin-top: 16px;
    text-align: center;
  `}
`;

export const CorrectAnswerComment = styled(WrongAnswerComment)`
  color: ${Color.blue2};
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  align-items: center;
  position: absolute;
  bottom: 20px;

  ${Media.tablet`
    flex-direction: row;
    justify-content: flex-end;
    position: static;
  `}
`;
