import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";

import { OfflineHandler } from "../../../../helpers/connectionHandler";
import NetworkErrorModal from "../../../../app/networkErrorModal";
import { Popup, LoadingWrapper, Badge } from "../../../../UIElements";
import { AppConfig } from "../../../../appConfig";

import {
  GhostButton,
  HeadlineSmall,
  HeaderWrapper,
  HeadlineBig,
  SaveButton,
  RowText,
  Link,
  BadgeWrap,
} from "./filesPopup.styled";
import { modalStyles } from "./filesPopup.constants";
import { downloadAllFiles } from "./filesPopup.utils";

export const FilesPopup = ({ items, header }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadAllFiles = () => {
    setIsLoading(true);
    downloadAllFiles(items, header, () => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {isOpen && (
        <OfflineHandler>
          <NetworkErrorModal isOpen />
        </OfflineHandler>
      )}
      <GhostButton onClick={() => setIsOpen(true)}>
        {t("learning.files.files.files")}
      </GhostButton>
      <Modal
        isOpen={isOpen}
        style={modalStyles}
        onRequestClose={() => setIsOpen(false)}
      >
        <Popup width={974} show={isOpen} closeAction={() => setIsOpen(false)}>
          <HeadlineSmall>{t("learning.files.files.filesToDownload")}</HeadlineSmall>
          <HeaderWrapper>
            <HeadlineBig>{header}</HeadlineBig>
            <SaveButton
              color="brandBasic"
              $background="white"
              border="brandBasic"
              $text="normal"
              $weight="normal"
              padding="0 22px 0 10px"
              marginBottom={60}
              hoverColor="white"
              hoverBackground="brandBasic"
              onClick={handleDownloadAllFiles}>
              <LoadingWrapper isLoading={isLoading}>
                <Badge
                  size={34}
                  name="download"
                  border="0"
                  marginRight={10}
                  hover="pointer"
                  customBackground="transparent"
                  className="badge"
                  noColor={true}
                />
              </LoadingWrapper>
              <RowText $fontSize={1.3} $color="unset">
                {t("learning.files.files.downloadAll")}
              </RowText>
            </SaveButton>
          </HeaderWrapper>
          {items.map((item) => (
            <Link href={AppConfig.content + item.url} target="_blank" key={item.name}>
              <BadgeWrap>
                <Badge size={33} name="download" />
              </BadgeWrap>
              <RowText fontSize={1.3}>{item.name}</RowText>
            </Link>
          ))}
        </Popup>
      </Modal>
    </>
  );
};
