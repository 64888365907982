import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 500px;
  margin: 50px auto;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;

  .simple-click {
    text-align: center;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .simple-click .hover-icon {
    width: 250px;
    height: 50%;
    transition: transform 0.3s ease-in-out;
  }

  .simple-click .hover-icon:hover {
    cursor: pointer;
    transform: scale(1.3);
    box-shadow: none;
  }

  .simple-click .wrapper {
    width: 100%;
  }
`;
