import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { styleWhenTrue } from "../../../../../helpers/rendering";
import { Media, Color, FontWeight } from "../../../../../theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 650px;
  height: 100%;
  padding: 50px 25px;

  ${Media.tablet`
    padding: 80px 125px 60px;
  `}
`;

export const AnswerTile = styled.div`
  display: flex;
  border: 1px solid ${Color.gray5};
  font-size: 16px;
  line-height: 22px;
  font-weight: ${FontWeight.Light};
  cursor: pointer;
  padding: 10px;

  &:not(:first-of-type) {
    margin-top: 2px;
  }

  ${styleWhenTrue(prop("greyed"), css`
    background-color: ${Color.dirtyWhite};
    color: ${Color.black};
    border: none;
    padding: 11px;
  `)}

  ${styleWhenTrue(prop("selected"), css`
    padding: 9px;
    border: 2px solid ${Color.blue2};
    color: ${Color.blue4};
  `)}

  ${styleWhenTrue(prop("validated"), css`
    pointer-events: none;
    cursor: default;

    ${styleWhenTrue(prop("selected"), css`
      ${styleWhenTrue(propEq(true, "isCorrect"), css`
        border: 2px solid ${Color.green};
        color: ${Color.green};
      `)}

      ${styleWhenTrue(propEq(false, "isCorrect"), css`
        border: 2px solid ${Color.red4};
        color: ${Color.red4};
      `)}
    `)}
  `)}

  ${Media.tablet`
    padding: 28px 72px 28px 28px;
  `}
`;

export const TileCircle = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid ${Color.gray5};
  border-radius: 50%;
  margin-right: 15px;
  flex-shrink: 0;
  background-color: ${Color.white};

  ${styleWhenTrue(prop("selected"), css`
    &:after {
      content: "";
      position: absolute;
      left: 2px;
      top: 2px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${Color.blue2};

      ${styleWhenTrue(prop("validated"), css`
        ${styleWhenTrue(propEq(true, "isCorrect"), css`
          background-color: ${Color.green};
        `)}

        ${styleWhenTrue(propEq(false, "isCorrect"), css`
          background-color: ${Color.red4};
        `)}
      `)}
    }
  `)}
`;

export const ButtonContainer = styled.div`
  margin-top: 30px;
  width: 100%;

  ${Media.tablet`
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
  `}
`;

export const ErrorText = styled.p`
  color: ${Color.red4};
  font-weight: ${FontWeight.SemiBold};
  font-size: 13px;
  margin: 0 0 20px;

  ${Media.tablet`
    margin: 0 30px 0 0;
  `}
`;
