import { Breakpoint, Color, Drawer, Shape, StyledComponent, Typography, ZIndex } from '@elearning-platform/ui';
import { styled } from 'styled-components';

export const StyledDrawer: StyledComponent = styled(Drawer)`
  z-index: ${ZIndex.Min};
`;

export const DrawerBox: StyledComponent = styled.div`
  max-width: calc(100vw - 16px);
  overflow: hidden;
  background-color: ${Color.Gray100};
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  @media ${Breakpoint.SmallDesktop} {
    max-width: 900px;
  }
`;

export const DrawerContent: StyledComponent = styled.div`
  padding: 40px 16px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media ${Breakpoint.Tablet} {
    padding: 40px;
  }
`;

export const CloseButton: StyledComponent = styled.button`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  margin-left: auto;
  position: relative;
  z-index: ${ZIndex.Min};
`;

export const DrawerTitle: StyledComponent = styled.div``;

export const TitleUpperText: StyledComponent = styled(Typography)`
  color: ${Color.Gray500};
`;

export const TitleLowerText: StyledComponent = styled(Typography)`
  color: ${Color.Gray700};
`;

export const DutyGroups: StyledComponent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 28px;
  margin-top: auto;

  @media ${Breakpoint.SmallDesktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const DutyGroup: StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const GroupTitleWrapper: StyledComponent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Dot: StyledComponent = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${Color.Brand100};
  border-radius: 50%;
  flex-shrink: 0;
`;

export const Duties: StyledComponent = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
`;

export const Buttons: StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  border-top: 1px solid ${Color.Gray300};
  padding-top: 40px;

  @media ${Breakpoint.Tablet} {
    flex-direction: row;
  }
`;

export const StyledShape: StyledComponent<typeof Shape> = styled(Shape)`
  width: 250px;
  height: 250px;
  position: absolute;
  top: -110px;
  right: -110px;

  @media ${Breakpoint.SmallDesktop} {
    width: 276px;
    height: 276px;
    right: -40px;
    top: -90px;
  }
`;
