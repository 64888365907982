import { all, put, takeLatest } from "redux-saga/effects";

import * as teamIndicatorsApi from "../services/api";

import { TeamIndicatorsTypes, TeamIndicatorsActions } from "./teamIndicators.reducer";

function* fetchChartsData({ chartIDs }) {
  const ids = chartIDs.map((id) => id.selectedSkill || id.competenceId);
  const competenceIDs = chartIDs.map((id) => id.competenceId);
  yield put(TeamIndicatorsActions.setIsPending(true));
  const { data } = yield teamIndicatorsApi.fetchChartsData(ids);
  const competences = yield teamIndicatorsApi.fetchChartsData(competenceIDs);
  const fetchedData = data.map((chart) => {
    const competenceChart = chartIDs.find((findChart) => chart.competence.id === findChart.competenceId);
    if (competenceChart) {
      return chart;
    } else {
      const skillChart = chartIDs.find((findChart) => chart.competence.id === findChart.selectedSkill);
      const competence = competences.data.find((comp) => comp.competence.id === skillChart.competenceId);
      return { ...competence, selectedSkill: chart };
    }
  });
  yield put(TeamIndicatorsActions.fetchChartsDataSuccess(fetchedData));
  yield put(TeamIndicatorsActions.setIsPending(false));
}

function* createNewChart({ competenceId, skillId }) {
  const { data } = yield teamIndicatorsApi.fetchChartsData(competenceId);
  if (skillId) {
    const skills = yield teamIndicatorsApi.fetchChartsData(skillId);
    data[0].selectedSkill = skills.data[0];
  }
  yield put(TeamIndicatorsActions.createNewChartSuccess(data[0]));
}

function* updateChartMeasure({ order, competenceId, skillId }) {
  const { data } = yield teamIndicatorsApi.fetchChartsData(competenceId);
  if (skillId) {
    const skills = yield teamIndicatorsApi.fetchChartsData(skillId);
    data[0].selectedSkill = skills.data[0];
  }
  yield put(TeamIndicatorsActions.updateChartMeasureSuccess(order, data[0]));
}

function* getStats() {
  yield put(TeamIndicatorsActions.setStatsLoading(true));
  const { data } = yield teamIndicatorsApi.getStats();
  yield put(TeamIndicatorsActions.getStatsSuccess(data));
  yield put(TeamIndicatorsActions.setStatsLoading(false));
}

function* updateStatsTeam({ offset, limit }) {
  const { data } = yield teamIndicatorsApi.setStatsTeam(offset, limit);
  yield put(TeamIndicatorsActions.updateStatsTeamSuccess(data));
}

export function* watchTeamIndicators() {
  yield all([
    yield takeLatest(TeamIndicatorsTypes.FETCH_CHARTS_DATA, fetchChartsData),
    yield takeLatest(TeamIndicatorsTypes.CREATE_NEW_CHART, createNewChart),
    yield takeLatest(TeamIndicatorsTypes.UPDATE_CHART_MEASURE, updateChartMeasure),
    yield takeLatest(TeamIndicatorsTypes.GET_STATS, getStats),
    yield takeLatest(TeamIndicatorsTypes.UPDATE_STATS_TEAM, updateStatsTeam),
  ]);
}
