import React from "react"
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { selectCompetenceSkills } from "../../redux/competence.selectors";
import { CompetenceMeasureItem } from "../competenceMeasureItem/competenceMeasureItem.component";

export const CompetenceMeasuresBox = ({ competenceId }) => {
  const competenceSkills = useSelector(selectCompetenceSkills);

  if (competenceSkills) {
    return competenceSkills.map(({ skill, rate }) => (
      <CompetenceMeasureItem
        key={skill.id}
        skill={skill}
        rate={rate}
        competenceId={competenceId}
      />
    ));
  }

  return <div />;
};

CompetenceMeasuresBox.propTypes = {
  competenceId: PropTypes.number.isRequired,
};
