import styled from "styled-components";

import { Button as ButtonBase } from "../../../../components/button/button.component";
import { Color, FontWeight, Media } from "../../../../theme";
import { ReactComponent as HeaderIconSvg } from "../../images/headerIcon.svg";
import { ReactComponent as FinishIconSvg } from "../../images/finishIcon.svg";

export const Container = styled.div`
  padding: 40px 20px;

  ${Media.tablet`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    flex: 2;
    flex-shrink: 0;
  `}
`;

export const Wrapper = styled.div`
  max-width: 670px;
  margin: 0 auto;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const Title = styled.p`
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 1px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.blue5};
`;

export const StartText = styled.p`
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: ${Color.deepGray};

  ${Media.tablet`
    font-size: 28px;
    line-height: 38px;
  `}
`;

export const FinishText = styled.p`
  max-width: 555px;
  font-size: 32px;
  line-height: 44px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.deepGray};

  ${Media.tablet`
    font-size: 45px;
    line-height: 63px;
  `}
`;

export const HeaderIcon = styled(HeaderIconSvg)`
  margin-right: 15px;
`;

export const FinishIcon = styled(FinishIconSvg)`
  margin-bottom: 32px;
`;

export const Button = styled(ButtonBase)`
  margin-top: 60px;
  width: 100%;

  ${Media.tablet`
    width: auto;
    min-width: 200px;
  `}
`;
