import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";

import { UserRoleName } from "../../../../userContext/userContext.constants";
import {
  selectUserName,
  selectUserStartOfWork,
  selectUserFirstLogin,
  selectUserRole,
} from "../../../../userContext/redux/userContext.selectors";
import { OnboardingMode } from "../../../../app/app.constants";
import { renderWhenTrue, renderWhenTrueOtherwise } from "../../../../helpers/rendering";
import {
  ChartUnit,
  ChartColorType,
  ChartType,
  ChartIcon,
} from "../../../../components/progressChart/progressChart.constants";
import WomanWithCarImage from "../../images/womanWithCar.png";
import {
  selectFinishedUserStages,
  selectTotalUserStages,
  selectUserContentPassed,
  selectOnboardingPathPassed,
  selectOnboardingMode,
} from "../../redux/onboarding.selectors";

import {
  Container,
  ContentWrapper,
  RightColumn,
  LeftColumn,
  ImageWrapper,
  Image,
  Title,
  Text,
  BarsWrapper,
  ProgressChart,
  WinnerTip,
  TipBold,
  LukeImage,
  TimerImage,
} from "./header.styled";

export const Header = ({ contentWatchedInPercent, welcomeMessage, animationPromise }) => {
  const { t } = useTranslation();
  const username = useSelector(selectUserName);
  const userStartOfWork = useSelector(selectUserStartOfWork);
  const userFirstLogin = useSelector(selectUserFirstLogin);
  const userStagesFinished = useSelector(selectFinishedUserStages);
  const userStagesTotal = useSelector(selectTotalUserStages);
  const userContentPassed = useSelector(selectUserContentPassed);
  const mode = useSelector(selectOnboardingMode);
  const userRole = useSelector(selectUserRole);
  const onboardingPathPassed = useSelector(selectOnboardingPathPassed);
  const [anim, setAnim] = useState();
  const startOfWork = dayjs.unix(userStartOfWork);
  const firstLogin = dayjs.unix(userFirstLogin);
  const welcomeHeader = onboardingPathPassed
    ? t("onboarding.header.done")
    : `${t("onboarding.header.welcome")} ${username}`;
  const today = dayjs();
  const daysLeft = startOfWork.diff(today, "days");
  const daysFromFirstLogin = startOfWork.diff(firstLogin, "days");
  const workStartProgress = (daysFromFirstLogin - daysLeft) / daysFromFirstLogin;

  useEffect(() => {
    animationPromise.then(setAnim);
  }, []);

  const renderAnimation = renderWhenTrueOtherwise(
    () => <LukeImage />,
    () => (
      <Lottie
        animationData={anim}
        loop
        rendererSettings={{
          preserveAspectRatio: "xMidYMid slice",
        }}
      />
    ),
  );

  const renderImageContent = renderWhenTrueOtherwise(
    () => renderAnimation(onboardingPathPassed),
    () => <Image src={WomanWithCarImage} />,
  );

  const renderDefaultCharts = () => {
    if (!userContentPassed) return;

    const contentPassedValue = userContentPassed.count / userContentPassed.total * 100;
    return (
      <BarsWrapper>
        <ProgressChart
          label={t("onboarding.header.stagesFinished")}
          value={userStagesFinished}
          maxValue={userStagesTotal}
          unit={ChartUnit.Unit}
          type={ChartType.Dashed}
          icon={ChartIcon.ColumnGraph}
          color={ChartColorType.Blue}
          iconColor={ChartColorType.Blue}
        />
        <ProgressChart
          label={t("onboarding.header.contentPassed")}
          value={contentPassedValue}
          color={ChartColorType.Gold}
          icon={ChartIcon.GoldMedal}
        />
      </BarsWrapper>
    );
  };

  const renderHRCharts = () => (
    <BarsWrapper>
      <ProgressChart
        label={t("onboarding.header.toWorkStart")}
        value={workStartProgress}
        unit={ChartUnit.Unit}
        type={ChartType.Dashed}
        icon={ChartIcon.ColumnGraph}
      />
      <ProgressChart
        label={t("onboarding.header.contentWatched")}
        value={contentWatchedInPercent}
        color={ChartColorType.Gold}
        icon={ChartIcon.GoldMedal}
      />
    </BarsWrapper>
  );

  const renderOnboardingCharts = renderWhenTrueOtherwise(
    renderHRCharts,
    renderDefaultCharts,
  );

  const renderWinnerMessage = renderWhenTrue(() => (
    <>
      <TimerImage />
      <WinnerTip>
        <span>{t("onboarding.header.winnerTip_1")}</span>
        {" "}
        <TipBold>{t("onboarding.header.tipBold")}</TipBold>
        <span>{t("onboarding.header.winnerTip_2")}</span>
      </WinnerTip>
    </>
  ));

  const renderCharts = renderWhenTrueOtherwise(
    () => renderOnboardingCharts(mode === OnboardingMode.HR),
    () => renderWinnerMessage(userRole.name === UserRoleName.Onboarding),
  );

  return (
    <Container light={mode === OnboardingMode.Default || mode === OnboardingMode.OnboardingAll}>
      <ContentWrapper>
        <RightColumn>
          <ImageWrapper>
            {renderImageContent(mode === OnboardingMode.Default || mode === OnboardingMode.OnboardingAll)}
          </ImageWrapper>
        </RightColumn>
        <LeftColumn>
          <Title>{welcomeHeader}</Title>
          <Text>{welcomeMessage}</Text>
          {renderCharts(!onboardingPathPassed)}
        </LeftColumn>
      </ContentWrapper>
    </Container>
  );
};
