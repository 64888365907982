import { css } from "styled-components";

export const Breakpoint = {
  SmallMobile: "smallMobile",
  Mobile: "mobile",
  AdditionalMobile: "additionalMobile",
  Tablet: "tablet",
  TabletWide: "tabletWide",
  Desktop: "desktop",
  DesktopWide: "desktopWide",
};

export const sizes = {
  [Breakpoint.SmallMobile]: 375,
  [Breakpoint.Mobile]: 480,
  [Breakpoint.AdditionalMobile]: 570,
  [Breakpoint.Tablet]: 768,
  [Breakpoint.TabletWide]: 1024,
  [Breakpoint.Desktop]: 1200,
  [Breakpoint.DesktopWide]: 1440,
};

const getWindowWidth = () => window.innerWidth;

export const getBreakpointMediaQuery = (sizeLabel) => `(min-width: ${sizes[sizeLabel]}px)`;

export const Media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `;
  acc[`${label}Retina`] = (...args) => css`
    @media (min-width: ${sizes[label]}px) and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      ${css(...args)}
    }
  `;
  acc[`${label}Landscape`] = (...args) => css`
    @media (min-width: ${sizes[label]}px) and (orientation: landscape) {
      ${css(...args)}
    }
  `;
  acc[`${label}Portrait`] = (...args) => css`
    @media (min-width: ${sizes[label]}px) and (orientation: portrait) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

Media.portait = (...args) => css`
  @media (orientation: portrait) {
    ${css(...args)}
  }
`;

Media.landscape = (...args) => css`
  @media (orientation: landscape) {
    ${css(...args)}
  }
`;

Media.noHover = (...args) => css`
  @media (hover: none) {
    &:hover {
      ${css(...args)};
    }
  }
`;

export const hasHoverOtherwise = (hover, noHover) => css`
  &:hover {
    ${hover}
  }

  ${Media.noHover`
    ${noHover}
  `}
`;

export const isSmallMobile = () => {
  const width = getWindowWidth();
  return width < sizes.smallMobile;
};

export const isMobile = () => {
  const width = getWindowWidth();
  return width < sizes.tablet;
};

export const isAdditionalMobile = () => {
  const width = getWindowWidth();
  return width >= sizes.additionalMobile;
};

export const isTablet = () => {
  const width = getWindowWidth();
  return width >= sizes.tablet;
};

export const isTabletWide = () => {
  const width = getWindowWidth();
  return width >= sizes.tabletWide;
};

export const isDesktop = () => {
  const width = getWindowWidth();
  return width >= sizes.desktop;
};

export const isDesktopWide = () => {
  const width = getWindowWidth();
  return width >= sizes.desktopwide;
};

export const isMacDevice = () => navigator.platform.toLowerCase().includes("mac");
