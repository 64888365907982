export const findDeepLink = (form, answerId) => {
  let deepLink = { pageIndex: -1, sectionIndex: -1, questionIndex: -1, answerIndex: -1 };
  form.pages.forEach((page, pageIndex) => {
    page.sections?.forEach((section, sectionIndex) => {
      section.questions?.forEach((question, questionIndex) => {
        question.answers?.forEach((answer, answerIndex) => {
          if (answer.id === answerId) {
            deepLink = { pageIndex, sectionIndex, questionIndex, answerIndex };
          }
        });
      });
    });
  });

  return deepLink;
};

export const toggleConditionalQuestions = (form) => {
  form?.pages?.forEach((page) => {
    page.sections?.forEach((section) => {
      section.questions?.forEach((question) => {
        if (question.conditionType === "visible_after_answer") {
          const deepLink = findDeepLink(form, question.conditionValue);
          const isSelected = form
            .pages[deepLink.pageIndex]
            .sections[deepLink.sectionIndex]
            .questions[deepLink.questionIndex]
            .answers[deepLink.answerIndex].selected;
          question.show = isSelected;
        }
      });
    });
  });

  return form;
};

export const setAnswerValue = (form, deepLink, value) => {
  form
    .pages[deepLink.pageIndex]
    .sections[deepLink.sectionIndex]
    .questions[deepLink.questionIndex]
    .answers[deepLink.answerIndex]
    .answer = value;
};

export const setAnswerSelected = (form, deepLink, value) => {
  if (
    form
      .pages[deepLink.pageIndex]
      .sections[deepLink.sectionIndex]
      .questions[deepLink.questionIndex]
      .type === "single_choice"
  ) {
    form
      .pages[deepLink.pageIndex]
      .sections[deepLink.sectionIndex]
      .questions[deepLink.questionIndex]
      .answers = form
        .pages[deepLink.pageIndex]
        .sections[deepLink.sectionIndex]
        .questions[deepLink.questionIndex]
        .answers.map((answer) => {
          answer.selected = false;
          return answer;
        });
  }

  form
    .pages[deepLink.pageIndex]
    .sections[deepLink.sectionIndex]
    .questions[deepLink.questionIndex]
    .answers[deepLink.answerIndex]
    .selected = value === true ? true : false;
};

const getAnswerValue = (answer) => {
  switch (answer.type) {
  case "selected":
  case "single_choice":
  case "multiple_choice":
    return answer.selected;
  default:
    return answer.answer;
  }
};

export const currentQuizAnswers = ({ nav, form }) => {
  let questions = [];

  form.pages[nav.currentPage].sections.forEach((section) => (
    section.questions.forEach((question) => questions.push(question))
  ));

  return questions.map((question) => (
    question.answers.map((answer) => ({
      id: answer.id,
      value: getAnswerValue(answer)
    }))
  )).reduce((answers, answer) => [...answers, ...answer], []);
};
