import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { renderWhenTrue } from "../../../../helpers/rendering";
import { useMediaListener } from "../../../../hooks/useMediaListener";

import {
  Container,
  Placeholder,
  VideoBox,
  CameraOffIcon,
  PlayInfo,
} from "./video.styled";

export const Video = ({
  className,
  videoRef,
  show,
  large,
  fullScreen,
  isSecondVideo,
  mobileHidden,
  showPlayInfo,
}) => {
  const { isMobile } = useMediaListener();
  const containerRef = useRef(null);
  const showPlaceholder = !show && large;
  const [videoTallerThanContainer, setVideoTallerThanContainer] = useState(null);

  const observer = useRef(new ResizeObserver(() => {
    const { videoWidth, videoHeight } = videoRef.current;
    const { clientWidth, clientHeight } = containerRef.current;

    setVideoTallerThanContainer(containerRef?.current
      ? videoWidth / videoHeight < clientWidth / clientHeight
      : null
    );
  }));

  useEffect(() => {
    if (videoRef?.current) {
      observer.current.observe(videoRef.current)
    }
  }, [videoRef, observer]);

  const renderPlaceholder = renderWhenTrue(() => {
    return (
      <Placeholder>
        <CameraOffIcon />
      </Placeholder>
    );
  });

  const renderPlayInfo = renderWhenTrue(() => <PlayInfo fullScreen={fullScreen} />);

  return (
    <Container
      className={className}
      large={large}
      hidden={!show && !large}
      fullScreen={fullScreen}
      ref={containerRef}
      isSecondVideo={isSecondVideo}
      mobileHidden={mobileHidden}
      withPlayInfo={showPlayInfo}
    >
      <VideoBox
        ref={videoRef}
        autoPlay
        fullScreen={fullScreen}
        hidden={!show}
        tallerThanContainer={videoTallerThanContainer}
        playsInline
        controls={isMobile}
      />
      {renderPlaceholder(showPlaceholder)}
      {renderPlayInfo(showPlayInfo)}
    </Container>
  );
};

Video.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  large: PropTypes.bool,
  fullScreen: PropTypes.bool,
  isSecondVideo: PropTypes.bool,
  mobileHidden: PropTypes.bool,
  showPlayInfo: PropTypes.bool,
};
