import React from "react";

import { FormQuestionTitle } from "../../../../theme";
import { ClearfixContainer } from "../../../../UIElements";
import { QuestionChoiceAnswer } from "../questionChoiceAnswer/questionChoiceAnswer.component";
import { QuestionChoiceOpen } from "../questionChoiceOpen/questionChoiceOpen.component";

export const Choice = ({ title, answers, setAnswer, multi = false, section, setChecked, error }) => {
  return (
    <ClearfixContainer>
      <FormQuestionTitle dangerouslySetInnerHTML={{ __html: title }} />
      {answers.filter((answer) => !answer.isOpen).map((answer, index) => (
        <QuestionChoiceAnswer
          value={answer.answer}
          key={answer.id}
          action={() => {
            if (answer.selected && multi) {
              setAnswer(answer.id, false);
              setChecked(answers.filter((answer) => answer.selected === true).length - 1);
            } else {
              setAnswer(answer.id, true);
              setChecked(answers.filter((answer) => answer.selected === true).length + 1);
            }
          }}
          active={answer.selected}
          correct={index === 3}
          multi={multi}
          error={error}
        />
      ))}
      {answers.filter((answer) => answer.isOpen).map((answer) => (
        <QuestionChoiceOpen
          section={section}
          questionId={answer.isOpen}
          parentAnswer={answer.id}
          key={answer.id}
          multi={multi}
          active={answer.selected}
          setAnswer={setAnswer}
          setChecked={setChecked}
        />
      ))}
    </ClearfixContainer>
  );
};
