import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import { renderWhenTrue } from "../../helpers/rendering";
import { convertListToBoolMap } from "../../helpers/transform";
import { isTablet } from "../../theme/media";
import {
  Container,
  PrevButton,
  NextButton,
  TagsContainer,
  ScrollableContainer,
  ArrowIcon,
} from "../tagFiltersList/tagFiltersList.styled";
import { TagFilter } from "../tagFiltersList/components/tagFilter/tagFilter.component";

import { TagDropdown } from "./components/tagDropdown/tagDropdown.component";

export const CompetenceTagFiltersList = ({ competences, setChosen, isTrainingView, brandArrow }) => {
  const [filtersPosition, setFiltersPosition] = useState(0);
  const [showNextButton, setShowNextButton] = useState(true);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [selectedFilters, setSelectedFilters] = useState(null);
  const competencesIDs = competences.map((competence) => competence.id);
  const [dropdownShowStates, setDropdownShowStates] = useState(convertListToBoolMap(competencesIDs, false));
  const [openCompetence, setOpenCompetence] = useState(null);
  const tagsContainerRef = useRef();
  const scrollableContainerRef = useRef();
  const dropdownRef = useRef();
  const containerRef = useRef();
  const expandedTagButtonRef = useRef();
  const clearFiltersTagRef = useRef();
  const scrollableContainerWidth = scrollableContainerRef.current && scrollableContainerRef.current.scrollWidth;
  const tagsContainerWidth = tagsContainerRef.current && tagsContainerRef.current.offsetWidth;
  const scrollValue = 300;
  let skillsIDs = [];

  useEffect(() => {
    if (openCompetence) {
      positionDropdown();
    }
  }, [openCompetence]);

  useEffect(() => {
    const openCompetenceID = Object.keys(dropdownShowStates).find(
      (key) => !!dropdownShowStates[key]
    );
    setOpenCompetence(competences.find((competence) => competence.id.toString() === openCompetenceID));
  }, [dropdownShowStates, competences]);

  useEffect(() => {
    competences
      .map((competence) => competence.skills)
      .forEach((skills) => skills.forEach((skill) => skillsIDs.push(skill.id)));

    setCheckboxStates(convertListToBoolMap([...skillsIDs, ...competencesIDs], false));
    // eslint-disable-next-line
  }, [competences]);

  useEffect(() => {
    const newSelectedFilters = Object.keys(checkboxStates)
      .filter((key) => checkboxStates[key])
      .map(Number);


    if (newSelectedFilters.length) {
      setChosen(newSelectedFilters);
      setSelectedFilters(newSelectedFilters);
    } else {
      setChosen();
      setSelectedFilters();
    }
    // eslint-disable-next-line
  }, [checkboxStates, setChosen]);

  useEffect(() => {
    if (!tagsContainerRef.current) return;

    const scrolledToRightSide = tagsContainerWidth + filtersPosition >= scrollableContainerWidth;

    if (scrolledToRightSide) {
      setShowNextButton(false);
    } else {
      setShowNextButton(true);
    }
  }, [filtersPosition, tagsContainerRef, scrollableContainerWidth, tagsContainerWidth]);

  const positionDropdown = () => {
    if (dropdownRef.current) {
      const leftPosition =
        containerRef.current.getBoundingClientRect().left - expandedTagButtonRef.current.getBoundingClientRect().left;

      dropdownRef.current.style.display = "block";
      if (-leftPosition < 20) {
        dropdownRef.current.style.left = isTablet() ? 0 : "20px";
      } else {
        dropdownRef.current.style.left = `${-leftPosition}px`;
      }

      const dropdownRightPosition = dropdownRef.current.getBoundingClientRect().right;
      const containerRightPosition = containerRef.current.getBoundingClientRect().right;
      if (dropdownRightPosition > containerRightPosition - 20) {
        dropdownRef.current.style.left = "auto";
        dropdownRef.current.style.right = isTablet() ? 0 : "20px";
      }
    }
  };

  const handlePrevClick = () => {
    if (filtersPosition < scrollValue) {
      setFiltersPosition(0);
    } else {
      setFiltersPosition(filtersPosition - scrollValue);
    }
  };

  const handleNextClick = () => {
    const leftSpace = scrollableContainerWidth - (tagsContainerWidth + filtersPosition);

    if (leftSpace < scrollValue) {
      setFiltersPosition(filtersPosition + leftSpace);
    } else {
      setFiltersPosition(filtersPosition + scrollValue);
    }
  };

  const closeDropdown = () => {
    const hiddenDropdowns = convertListToBoolMap(Object.keys(dropdownShowStates), false);
    setDropdownShowStates({ ...hiddenDropdowns });
  };

  const clearFilters = () => {
    setCheckboxStates(convertListToBoolMap(Object.keys(checkboxStates), false));
    setChosen();
  };

  const renderPrevButton = renderWhenTrue(() => (
    <PrevButton onClick={handlePrevClick}>
      <ArrowIcon brandcolor={(!!brandArrow).toString()} />
    </PrevButton>
  ));

  const renderNextButton = renderWhenTrue(() => (
    <NextButton onClick={handleNextClick}>
      <ArrowIcon brandcolor={(!!brandArrow).toString()} />
    </NextButton>
  ));

  const AllMaterialsFilter = () => (
    <TagFilter
      clearFilters={clearFilters}
      selectedFilters={selectedFilters}
      clearFiltersTagRef={clearFiltersTagRef}
      closeDropdown={closeDropdown}
      isClearTag
    />
  );

  const renderTagFilters = () => (
    <TagsContainer ref={tagsContainerRef}>
      <ScrollableContainer ref={scrollableContainerRef}>
        <AllMaterialsFilter />
        {competences.map((competence) => (
          <TagFilter
            key={competence.id}
            tag={competence}
            dropdownShowStates={dropdownShowStates}
            setDropdownShowStates={setDropdownShowStates}
            expandedButtonRef={dropdownShowStates[competence.id] && expandedTagButtonRef}
            selectedFilters={selectedFilters}
            isCompetenceTag
          />
        ))}
      </ScrollableContainer>
    </TagsContainer>
  );

  const renderDropdown = renderWhenTrue(() => (
    <TagDropdown
      dropdownRef={dropdownRef}
      competence={openCompetence}
      checkboxStates={checkboxStates}
      setCheckboxStates={setCheckboxStates}
      expandedButtonRef={expandedTagButtonRef}
      clearFiltersTagRef={clearFiltersTagRef}
      closeDropdown={closeDropdown}
    />
  ));

  return (
    <Container
      ref={containerRef}
      filtersPosition={filtersPosition}
      withLeftPadding={!!filtersPosition}
      // TODO: Remove when Trainings View will be a module
      isTrainingView={isTrainingView}
    >
      {renderTagFilters()}
      {renderPrevButton(!!filtersPosition)}
      {renderNextButton(showNextButton)}
      {renderDropdown(!!openCompetence)}
    </Container>
  )
};

CompetenceTagFiltersList.propTypes = {
  competences: PropTypes.array,
  setChosen: PropTypes.func,
  isTrainingView: PropTypes.bool,
};
