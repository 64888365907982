export enum SectionName {
  Account = 'account',
  User = 'user',
  Agreements = 'agreements'
}

export enum FieldType {
  StringType = 'string',
  Options = 'options',
  Email = 'email',
  Autocomplete = 'autocomplete',
  Date = 'date'
}
