import React from "react";
import MediaQuery from "react-responsive";
import { breakpoints } from "../../../UIGlobals";

export default class TaskScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows:[[],[],[],[],[]],
      clickedRow: null
    };
  }

  checkAnswers(){
    //silver, diamond, gold, platinum;
    let plainAnswers = this.state.rows.map((row)=>row.map(answer=>{

      switch(answer.split(" ")[1]){
      case "silver":
        return 0;
      case "diamond":
        return 1;
      case "gold":
        return 2;
      case "platinium":
        return 3;
      default: return 4;
      }

    }))
    return plainAnswers[0].includes((1,3,2)) && plainAnswers[1].includes((1,2)) && plainAnswers[2].includes((1,3,2,0)) && plainAnswers[3].includes((1,3,2,0)) && plainAnswers[4].includes((1,3,2,0))
               && !plainAnswers[0].includes((0)) && !plainAnswers[1].includes((0,3))
  }

  verify() {
    const { onSuccess, onFailure } = this.props;


    if (this.checkAnswers()) {
      onSuccess();
    } else {
      onFailure();
    }
  }

  removeFromRow(row, index){
    let newRow = this.state.rows[row];
    newRow.splice(index, 1);
    let newState = JSON.parse(JSON.stringify(this.state));
    newState.rows[row] = newRow;
    this.setState(newState);
  }

  clickVariant(event){
    if(this.state.clickedRow != null && this.state.rows[this.state.clickedRow].length < 4){
      const elClassName = event.target.classList[0] + " " + event.target.classList[1];
      let newRow = [...this.state.rows[this.state.clickedRow], elClassName];
      let newState = JSON.parse(JSON.stringify(this.state));
      newState.rows[this.state.clickedRow] = newRow;
      this.setState(newState);
      this.setState({chooseVariant: false});
      this.setState({clickedRow: null});
      document.getElementById("elem-"+(this.state.rows[this.state.clickedRow].length+(this.state.clickedRow*4))).classList.remove("light");
    }
  }

  clickEmpty(event,cellNumber,rowNumber){
    if(this.state.clickedRow !== null) document.getElementById("elem-"+(this.state.rows[this.state.clickedRow].length+(this.state.clickedRow*4))).classList.remove("light");
    this.setState({clickedRow: rowNumber},()=>{
      if(this.state.rows && this.state.rows[this.state.clickedRow] && this.state.rows[this.state.clickedRow].length < 4){
        this.setState({clickedCell: cellNumber});
        this.setState({chooseVariant: true});
        document.getElementById("elem-"+(this.state.rows[this.state.clickedRow].length+(this.state.clickedRow*4))).classList.add("light");
      }
    });
  }


  render() {
    return (
      <div className="drag-and-drop--2col-screen">
        <MediaQuery query={`(${breakpoints().minL})`}>
          <div className="wrapper">
            <div className={"col-left"}>
              <div className="inside-wrap">
                <div className="logo-wrap">
                  <div className="logo first"></div>
                  <div className="place-to-drag materials" id="drop_zone" >
                    {
                      this.state.rows[0].map((e, index)=><div key={index} className={"material " + e} onClick={()=>{this.removeFromRow(0, index)}}></div>)
                    }
                    <div id="elem-0" onClick={(e) => this.clickEmpty(e,0,0)} className="circle first"></div>
                    <div id="elem-1" onClick={(e) => this.clickEmpty(e,1,0)} className="circle second"></div>
                    <div id="elem-2" onClick={(e) => this.clickEmpty(e,2,0)} className="circle third"></div>
                    <div id="elem-3" onClick={(e) => this.clickEmpty(e,3,0)} className="circle fourth"></div>
                  </div>
                </div>
                <div className="logo-wrap">
                  <div className="logo second"></div>
                  <div className="place-to-drag materials" id="drop_zone" >
                    {
                      this.state.rows[1].map((e, index)=><div key={index} className={"material " + e} onClick={()=>{this.removeFromRow(1, index)}}></div>)
                    }
                    <div id="elem-4" onClick={(e) => this.clickEmpty(e,4,1)} className="circle first"></div>
                    <div id="elem-5" onClick={(e) => this.clickEmpty(e,5,1)} className="circle second"></div>
                    <div id="elem-6" onClick={(e) => this.clickEmpty(e,6,1)} className="circle third"></div>
                    <div id="elem-7" onClick={(e) => this.clickEmpty(e,7,1)} className="circle fourth"></div>
                  </div>

                </div>
                <div className="logo-wrap">
                  <div className="logo third"></div>
                  <div className="place-to-drag materials" id="drop_zone" >
                    {
                      this.state.rows[2].map((e, index)=><div key={index} className={"material " + e} onClick={()=>{this.removeFromRow(2, index)}}></div>)
                    }

                    <div id="elem-8" onClick={(e) => this.clickEmpty(e,8,2)} className="circle first"></div>
                    <div id="elem-9" onClick={(e) => this.clickEmpty(e,9,2)} className="circle second"></div>
                    <div id="elem-10" onClick={(e) => this.clickEmpty(e,10,2)} className="circle third"></div>
                    <div id="elem-11" onClick={(e) => this.clickEmpty(e,11,2)} className="circle fourth"></div>
                  </div>

                </div>
                <div className="logo-wrap">
                  <div className="logo fourth"></div>
                  <div className="place-to-drag materials" id="drop_zone" >
                    {
                      this.state.rows[3].map((e, index)=><div key={index} className={"material " + e} onClick={()=>{this.removeFromRow(3, index)}}></div>)
                    }

                    <div id="elem-12" onClick={(e) => this.clickEmpty(e,12,3)} className="circle first"></div>
                    <div id="elem-13" onClick={(e) => this.clickEmpty(e,13,3)} className="circle second"></div>
                    <div id="elem-14" onClick={(e) => this.clickEmpty(e,14,3)} className="circle third"></div>
                    <div id="elem-15" onClick={(e) => this.clickEmpty(e,15,3)} className="circle fourth"></div>
                  </div>

                </div>
                <div className="logo-wrap">
                  <div className="logo sixth"></div>
                  <div className="place-to-drag materials" id="drop_zone"   >
                    {
                      this.state.rows[4].map((e, index)=><div key={index} className={"material " + e} onClick={()=>{this.removeFromRow(4, index)}}></div>)
                    }

                    <div id="elem-16" onClick={(e) => this.clickEmpty(e,16,4)} className="circle first"></div>
                    <div id="elem-17" onClick={(e) => this.clickEmpty(e,17,4)} className="circle second"></div>
                    <div id="elem-18" onClick={(e) => this.clickEmpty(e,18,4)} className="circle third"></div>
                    <div id="elem-19" onClick={(e) => this.clickEmpty(e,19,4)} className="circle fourth"></div>
                  </div>

                </div>

              </div>
            </div>


            <div className="col-right">
              <h3 className="drag-and-drop--header">
                            Produkt dealerski i dla klienta występuje w zależności od programu
                            w wariantach Diamentowym, Platynowym, Złotym i Srebrnym. <br />
                <span className="bolded">Przyporządkuj warianty występujące w programach, klikając pusty kafalek a następnie jeden z wariantów</span>
              </h3>
              <h5>WARIANTY</h5>
              <div className={"materials" + (this.state.chooseVariant ? " light" : "")}>
                <div className="row" >
                  <div id="object1" onClick={this.clickVariant.bind(this)}  className="material silver"  ></div>
                  <div id="object2"  onClick={this.clickVariant.bind(this)}className="material platinium" ></div>
                </div>
                <div className="row">
                  <div id="object3"  onClick={this.clickVariant.bind(this)}  className="material diamond" ></div>
                  <div id="object4"  onClick={this.clickVariant.bind(this)}  className="material gold" ></div>
                </div>
              </div>
              <button className="btn-check  hidden" onClick={ () => this.verify() }></button>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery query={`(${breakpoints().maxL})`}>
          <div className="wrapper mobile">
            <div className={this.state.chooseVariant && "shadow"} />
            <div className="col-right">
              <h3 className="drag-and-drop--header">
                            Produkt dealerski i dla klienta występuje w zależności od programu
                            w wariantach Diamentowym, Platynowym, Złotym i Srebrnym. <br />
                <span className="bolded">Przyporządkuj warianty występujące w programach, klikając pusty kafalek a następnie jeden z wariantów</span>
              </h3>
            </div>

            <div className={"col-left"}>
              <div className="inside-wrap">
                <div className="logo-wrap">
                  <div className="logo first"></div>
                  <div className="place-to-drag materials" id="drop_zone"  >
                    {
                      this.state.rows[0].map((e, index)=><div key={index} className={"material " + e} onClick={()=>{this.removeFromRow(0, index)}}></div>)
                    }

                    <div id="elem-0" onClick={(e) => this.clickEmpty(e,0,0)} className="circle first"></div>
                    <div id="elem-1" onClick={(e) => this.clickEmpty(e,1,0)} className="circle second"></div>
                    <div id="elem-2" onClick={(e) => this.clickEmpty(e,2,0)} className="circle third"></div>
                    <div id="elem-3" onClick={(e) => this.clickEmpty(e,3,0)} className="circle fourth"></div>
                  </div>
                  <div className={"materials" + (this.state.chooseVariant && this.state.clickedRow === 0 ? " light answer" : "")}>
                    <div className="row" >
                      <div id="object1" onClick={this.clickVariant.bind(this)}  className="material silver"  ></div>
                      <div id="object2"  onClick={this.clickVariant.bind(this)}  className="material platinium" ></div>
                      <div id="object3"  onClick={this.clickVariant.bind(this)}  className="material diamond" ></div>
                      <div id="object4"  onClick={this.clickVariant.bind(this)}  className="material gold" ></div>
                    </div>
                  </div>
                </div>
                <div className="logo-wrap">
                  <div className="logo second"></div>
                  <div className="place-to-drag materials" id="drop_zone"  >
                    {
                      this.state.rows[1].map((e, index)=><div key={index} className={"material " + e} onClick={()=>{this.removeFromRow(1, index)}}></div>)
                    }

                    <div id="elem-4" onClick={(e) => this.clickEmpty(e,4,1)} className="circle first"></div>
                    <div id="elem-5" onClick={(e) => this.clickEmpty(e,5,1)} className="circle second"></div>
                    <div id="elem-6" onClick={(e) => this.clickEmpty(e,6,1)} className="circle third"></div>
                    <div id="elem-7" onClick={(e) => this.clickEmpty(e,7,1)} className="circle fourth"></div>
                  </div>
                  <div className={"materials" + (this.state.chooseVariant && this.state.clickedRow === 1 ? " light answer" : "")}>
                    <div className="row" >
                      <div id="object1" onClick={this.clickVariant.bind(this)}  className="material silver"  ></div>
                      <div id="object2"  onClick={this.clickVariant.bind(this)}  className="material platinium" ></div>
                      <div id="object3"  onClick={this.clickVariant.bind(this)}  className="material diamond" ></div>
                      <div id="object4"  onClick={this.clickVariant.bind(this)}  className="material gold" ></div>
                    </div>
                  </div>
                </div>
                <div className="logo-wrap">
                  <div className="logo third"></div>
                  <div className="place-to-drag materials" id="drop_zone"   >
                    {
                      this.state.rows[2].map((e, index)=><div key={index} className={"material " + e} onClick={()=>{this.removeFromRow(2, index)}}></div>)
                    }

                    <div id="elem-8" onClick={(e) => this.clickEmpty(e,8,2)} className="circle first"></div>
                    <div id="elem-9" onClick={(e) => this.clickEmpty(e,9,2)} className="circle second"></div>
                    <div id="elem-10" onClick={(e) => this.clickEmpty(e,10,2)} className="circle third"></div>
                    <div id="elem-11" onClick={(e) => this.clickEmpty(e,11,3)} className="circle fourth"></div>
                  </div>
                  <div className={"materials" + (this.state.chooseVariant && this.state.clickedRow === 2 ? " light answer" : "")}>
                    <div className="row" >
                      <div id="object1" onClick={this.clickVariant.bind(this)}  className="material silver"  ></div>
                      <div id="object2"  onClick={this.clickVariant.bind(this)}  className="material platinium" ></div>
                      <div id="object3"  onClick={this.clickVariant.bind(this)}  className="material diamond" ></div>
                      <div id="object4"  onClick={this.clickVariant.bind(this)}  className="material gold" ></div>
                    </div>
                  </div>
                </div>
                <div className="logo-wrap">
                  <div className="logo fourth"></div>
                  <div className="place-to-drag materials" id="drop_zone"  >
                    {
                      this.state.rows[3].map((e, index)=><div key={index} className={"material " + e} onClick={()=>{this.removeFromRow(3, index)}}></div>)
                    }

                    <div id="elem-12" onClick={(e) => this.clickEmpty(e,12,3)} className="circle first"></div>
                    <div id="elem-13" onClick={(e) => this.clickEmpty(e,13,3)} className="circle second"></div>
                    <div id="elem-14" onClick={(e) => this.clickEmpty(e,14,3)} className="circle third"></div>
                    <div id="elem-15" onClick={(e) => this.clickEmpty(e,15,3)} className="circle fourth"></div>
                  </div>
                  <div className={"materials" + (this.state.chooseVariant && this.state.clickedRow === 3 ? " light answer" : "")}>
                    <div className="row" >
                      <div id="object1" onClick={this.clickVariant.bind(this)}  className="material silver"  ></div>
                      <div id="object2"  onClick={this.clickVariant.bind(this)}  className="material platinium" ></div>
                      <div id="object3"  onClick={this.clickVariant.bind(this)}  className="material diamond" ></div>
                      <div id="object4"  onClick={this.clickVariant.bind(this)}  className="material gold" ></div>
                    </div>
                  </div>
                </div>
                <div className="logo-wrap">
                  <div className="logo sixth"></div>
                  <div className="place-to-drag materials" id="drop_zone" >
                    {
                      this.state.rows[4].map((e, index)=><div key={index} className={"material " + e} onClick={()=>{this.removeFromRow(4, index)}}></div>)
                    }

                    <div id="elem-16" onClick={(e) => this.clickEmpty(e,16,4)} className="circle first"></div>
                    <div id="elem-17" onClick={(e) => this.clickEmpty(e,17,4)} className="circle second"></div>
                    <div id="elem-18" onClick={(e) => this.clickEmpty(e,18,4)} className="circle third"></div>
                    <div id="elem-19" onClick={(e) => this.clickEmpty(e,19,4)} className="circle fourth"></div>
                  </div>
                  <div className={"materials" + (this.state.chooseVariant && this.state.clickedRow === 4 ? " light answer" : "")}>
                    <div className="row" >
                      <div id="object1" onClick={this.clickVariant.bind(this)}  className="material silver"  ></div>
                      <div id="object2"  onClick={this.clickVariant.bind(this)}  className="material platinium" ></div>
                      <div id="object3"  onClick={this.clickVariant.bind(this)}  className="material diamond" ></div>
                      <div id="object4"  onClick={this.clickVariant.bind(this)}  className="material gold" ></div>
                    </div>
                  </div>
                  <button className="btn-check  hidden" onClick={ () => this.verify() }></button>
                </div>

              </div>
            </div>

          </div>
        </MediaQuery>

      </div>
    );
  }
}
