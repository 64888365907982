import React from "react";
import PropTypes from "prop-types";

import {
  Container,
  PrevArrowIcon,
  NextArrowIcon,
  Button,
} from "./pages.styled";

export const Pages = ({
  className,
  maxPage,
  currentPage,
  onPrevClick,
  onNextClick,
  onPageClick,
}) => {
  const pages = Array(maxPage).fill(null).map((_, index) => index + 1);

  return (
    <Container className={className}>
      <Button disabled={currentPage === 1} onClick={onPrevClick}>
        <PrevArrowIcon />
      </Button>
      {pages.map((page) => (
        <Button
          key={page}
          isCurrent={page === currentPage}
          onClick={() => onPageClick(page)}
        >
          {page}
        </Button>
      ))}
      <Button disabled={currentPage === maxPage} onClick={onNextClick}>
        <NextArrowIcon />
      </Button>
    </Container>
  );
};

Pages.propTypes = {
  className: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
  maxPage: PropTypes.number.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onPageClick: PropTypes.func.isRequired,
};
