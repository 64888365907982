import styled, { css } from "styled-components";
import { prop } from "ramda";

import { ReactComponent as ArrowSvg } from "../../../../images/arrow.svg";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color, SectionHeader } from "../../../../theme";

export const Container = styled.div``;

export const HeaderText = styled(SectionHeader)`
  margin-bottom: 0;
  margin-right: 15px;
`;

export const Header = styled.button`
  background-color: ${Color.transparent};
  border: none;
  text-align: left;
  display: flex;
  align-items: center;
  margin: 0 0 25px;
  padding: 0;
`;

export const ArrowWrapper = styled.div`
  transform: rotate(-90deg);
`;

export const ArrowIcon = styled(ArrowSvg)`
  fill: ${() => Color.brandBasic};
  width: 10px;
  height: 16px;
  transition: 0.3s;

  ${styleWhenTrue(prop("open"), css`
    transform: rotateY(180deg);
  `)}
`;
