import React, { useMemo } from "react";
import { Draggable } from "react-beautiful-dnd";

import { InteractionAnswerLabel } from "../../../../theme";
import { AnswerStatus } from "../../../interactions.constants";

import { Container, DragIcon } from "./option.styled";

export const Option = ({ index, data, confirmed, answerId }) => {
  const { id, label, value } = data;

  const status = useMemo(() => {
    if (confirmed && value.includes(answerId)) {
      return AnswerStatus.CorrectChecked;
    } else if (confirmed && !value.includes(answerId)) {
      return AnswerStatus.WrongChecked;
    } else {
      return AnswerStatus.Default;
    }
  }, [confirmed, value, answerId]);

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          $status={status}
        >
          <DragIcon />
          <InteractionAnswerLabel>{label}</InteractionAnswerLabel>
        </Container>
      )}
    </Draggable>
  );
};
