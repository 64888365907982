import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { CommonActions } from "../../../redux/common/common.reducer";

import { selectFavouriteToastStatus } from "../../../redux/common/common.selectors";

import { Container, ToastText } from "./favouriteToast.styled";

export const FavouriteToast = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const favourite = useSelector(selectFavouriteToastStatus);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let timeoutId = null;
    let fadeTimeoutId = null;

    if (favourite !== null) {
      setVisible(true);
      timeoutId = setTimeout(() => {
        setVisible(false);
      }, 4000);
      fadeTimeoutId = setTimeout(() => {
        setVisible(false);
        dispatch(CommonActions.setFavouriteToastStatus(null));
      }, 4500);
    }

    return () => {
      clearTimeout(timeoutId);
      clearTimeout(fadeTimeoutId);
    };
  }, [favourite]);

  return (
    <Container $isVisible={visible} $isFavourite={favourite}>
      <ToastText>
        {favourite ? (
          t("favouriteList.added")
        ) : (
          t("favouriteList.removed")
        )}
      </ToastText>
    </Container>
  );
}
