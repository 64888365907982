import styled from "styled-components";

import { Button as ButtonBase } from "../../../../../components/button/button.component";
import { FontWeight } from "../../../../../theme/fonts";
import { Color } from "../../../../../theme/colors";
import { Media } from "../../../../../theme/media";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 25px;

  ${Media.tablet`
    width: 75%;
    min-height: 650px;
  `}
`;

export const Counter = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: ${Color.gray4};
  margin: 20px 0 15px;
`;

export const Text = styled.p`
  text-align: center;
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: 30px;
  max-width: 446px;
  font-size: 20px;
  line-height: 27px;

  ${Media.tablet`
    font-size: 26px;
    line-height: 36px;
  `}
`;

export const Button = styled(ButtonBase)`
  background-color: ${Color.blue};
`;
