import { createActions, createReducer } from "reduxsauce";

import { SectionName } from "../../modules/profile/components/editForm/editForm.constants";

export const { Types: UserContextTypes, Creators: UserContextActions } = createActions(
  {
    setLoading: ["loading"],
    tryLogin: ["login", "password", "recaptchaResponse"],
    tryLoginSuccess: ["data"],
    setLoggedOut: ["data"],
    setLoggedOutSuccess: ["data"],
    updateUserInfo: null,
    updateUserInfoSuccess: ["data"],
    turnOffChangePasswordModal: null,
    turnOffRemindPasswordModal: null,
    showAchievementModal: ["achievement"],
    hideAchievementModal: ["achievement"],
    confirmAmbassadorSuccess: null,
    confirmEditProfileSuccess: null,
    confirmRegulationsSuccess: ["agreementType"],
    confirmAgreementsSuccess: null,
    fetchPermissionsSuccess: ["availableRoutes"],
    fetchUserDataSuccess: ["user"],
    changeUserPhoneNumberSuccess: ["phone"],
    setDeviceToken: ["deviceToken"],
    addAvatarSuccess: ["avatar"],
    deleteAvatarSuccess: null,
    sendEditFormSuccess: ["sections"],
    tryConnectWebsocket: null,
    connectFirebase: null,
  },
  { prefix: "USER_CONTEXT/" }
);

const INITIAL_STATE = {
  loading: false,
  loggedIn: false,
  data: {
    achievements: [],
  },
  currentBrand: false,
};

const setLoading = (state, { loading }) => ({ ...state, loading });

const tryLoginSuccess = (state, { data }) => ({
  ...state,
  data: {
    ...state.data,
    ...data,
  },
  loggedIn: true,
});

const setLoggedOutSuccess = (state, { data = {} }) => ({
  loading: false,
  data: {
    ...data,
    deviceToken: state.data.deviceToken,
  },
  loggedIn: false
});

const updateUserInfoSuccess = (state, { data }) => ({
  ...state,
  data: {
    ...state.data,
    user: {
      ...state.data.user,
      ...data,
    },
  },
  loggedIn: true,
});

const turnOffChangePasswordModal = (state) => ({
  ...state,
  data: {
    ...state.data,
    passwordExpirationInfo: {
      ...state.data.passwordExpirationInfo,
      forceChange: false,
    }
  },
});

const turnOffRemindPasswordModal = (state) => ({
  ...state,
  data: {
    ...state.data,
    passwordExpirationInfo: {
      ...state.data.passwordExpirationInfo,
      remindChange: false,
      daysToForceChange: null,
    }
  },
});

const showAchievementModal = (state, { achievement }) => {
  const { achievements } = state.data;

  if (achievements && !!achievements.find((stateAchievement) => stateAchievement.label === achievement.label)) {
    return state;
  }

  return {
    ...state,
    data: {
      ...state.data,
      achievements: [...(achievements || []), achievement],
    },
  };
};

const hideAchievementModal = (state, { achievement }) => ({
  ...state,
  data: {
    ...state.data,
    achievements: [...state.data.achievements.filter(
      ({ label }) => label !== achievement.label),
    ],
  },
});

const confirmAmbassadorSuccess = (state) => ({
  ...state,
  data: {
    ...state.data,
    user: {
      ...state.data.user,
      requireLeaderTerm: false,
    },
  },
});

const confirmEditProfileSuccess = (state) => ({
  ...state,
  data: {
    ...state.data,
    user: {
      ...state.data.user,
      requireVerification: false,
    },
  },
});

const confirmRegulationsSuccess = (state, { agreementType }) => ({
  ...state,
  data: {
    ...state.data,
    user: {
      ...state.data.user,
      requiredAgreement: false,
      missingAgreements: state.data.user.missingAgreements.filter(
        (agreement) => agreement.type !== agreementType
      ),
    }
  }
});

const confirmAgreementsSuccess = (state) => ({
  ...state,
  data: {
    ...state.data,
    user: {
      ...state.data.user,
      missingAgreements: [],
    }
  }
});

const fetchPermissionsSuccess = (state, { availableRoutes }) => ({
  ...state,
  data: {
    ...state.data,
    user: {
      ...state.data.user,
      availableRoutes,
    },
  },
});

const fetchUserDataSuccess = (state, { user }) => ({
  ...state,
  data: {
    ...state.data,
    user,
  },
});

const changeUserPhoneNumberSuccess = (state, { phone }) => ({
  ...state,
  data: {
    ...state.data,
    user: {
      ...state.data.user,
      phone,
    }
  }
});

const setDeviceToken = (state, { deviceToken }) => ({
  ...state,
  data: {
    ...state.data,
    deviceToken,
  },
});

const addAvatarSuccess = (state, { avatar }) => ({
  ...state,
  data: {
    ...state.data,
    user: {
      ...state.data.user,
      avatar,
    },
  },
});

const deleteAvatarSuccess = (state) => ({
  ...state,
  data: {
    ...state.data,
    user: {
      ...state.data.user,
      avatar: null,
    },
  },
});

const sendEditFormSuccess = (state, { sections }) => {
  if (sections.length && sections[0].name === SectionName.personalData) {
    const name = sections[0].fields[0].value;
    const surname = sections[0].fields[1].value;
    return {
      ...state,
      data: {
        ...state.data,
        user: {
          ...state.data.user,
          name,
          surname,
        },
      },
    };
  }
  return state;
};

const tryConnectWebsocket = (state) => (state);

export const reducer = createReducer(INITIAL_STATE, {
  [UserContextTypes.SET_LOADING]: setLoading,
  [UserContextTypes.TRY_LOGIN_SUCCESS]: tryLoginSuccess,
  [UserContextTypes.SET_LOGGED_OUT_SUCCESS]: setLoggedOutSuccess,
  [UserContextTypes.UPDATE_USER_INFO_SUCCESS]: updateUserInfoSuccess,
  [UserContextTypes.TURN_OFF_CHANGE_PASSWORD_MODAL]: turnOffChangePasswordModal,
  [UserContextTypes.TURN_OFF_REMIND_PASSWORD_MODAL]: turnOffRemindPasswordModal,
  [UserContextTypes.SHOW_ACHIEVEMENT_MODAL]: showAchievementModal,
  [UserContextTypes.HIDE_ACHIEVEMENT_MODAL]: hideAchievementModal,
  [UserContextTypes.CONFIRM_AMBASSADOR_SUCCESS]: confirmAmbassadorSuccess,
  [UserContextTypes.CONFIRM_EDIT_PROFILE_SUCCESS]: confirmEditProfileSuccess,
  [UserContextTypes.CONFIRM_REGULATIONS_SUCCESS]: confirmRegulationsSuccess,
  [UserContextTypes.CONFIRM_AGREEMENTS_SUCCESS]: confirmAgreementsSuccess,
  [UserContextTypes.FETCH_PERMISSIONS_SUCCESS]: fetchPermissionsSuccess,
  [UserContextTypes.FETCH_USER_DATA_SUCCESS]: fetchUserDataSuccess,
  [UserContextTypes.CHANGE_USER_PHONE_NUMBER_SUCCESS]: changeUserPhoneNumberSuccess,
  [UserContextTypes.SET_DEVICE_TOKEN]: setDeviceToken,
  [UserContextTypes.ADD_AVATAR_SUCCESS]: addAvatarSuccess,
  [UserContextTypes.DELETE_AVATAR_SUCCESS]: deleteAvatarSuccess,
  [UserContextTypes.SEND_EDIT_FORM_SUCCESS]: sendEditFormSuccess,
  [UserContextTypes.TRY_CONNECT_WEBSOCKET]: tryConnectWebsocket,
});
