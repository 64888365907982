export const getDefs = (data, color) => (
  data.map((item) => ({
    id: `${color.slice(1)}${item.opacity}`,
    type: "linearGradient",
    colors: [{ offset: 0, color, opacity: item.opacity }],
  }))
);

export const getFills = (data, color) => (
  data.map((dataItem) => ({
    match: (item) => item.data.opacity === dataItem.opacity,
    id: `${color.slice(1)}${dataItem.opacity}`,
  }))
);
