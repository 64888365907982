import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sortBy } from "lodash";

import { ButtonColor, ButtonVariant } from "../../../../components/button/button.constants";
import { deepClone } from "../../../../helpers";
import { WebinarsActions } from "../../redux/webinars.reducer";
import { selectParticipants } from "../../redux/webinars.selectors";
import { AddUsersToRoomView } from "../addUsersToRoomView/addUsersToRoomView.component";
import { RoomDetailsDropdown } from "../roomDetailsDropdown/roomDetailsDropdown.component";
import { BroadcastType } from "../video/video.constants";

import {
  Container,
  Title,
  ButtonWrapper,
  Button,
} from "./roomsFinalEditView.styled";

export const RoomsFinalEditView = ({
  rooms,
  updateRooms,
  goBack,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [roomToAddUsers, setRoomToAddUsers] = useState();
  const [isOpenButtonDisabled, setIsOpenButtonDisabled] = useState(false);
  const participants = useSelector(selectParticipants);
  const participantsWithoutPresenter = participants.filter(({ role }) => role !== BroadcastType.Presenter);
  const usersWithRoom = rooms.reduce((prevValue, room) => [...prevValue, ...room.participantIds], []);
  const usersWithoutRoom = participantsWithoutPresenter.filter(({ id, connected }) => !usersWithRoom.includes(id) && connected);

  const removeRoom = (roomName) => {
    updateRooms(rooms.filter((room) => room.name !== roomName));
  };

  const addUsersToRoom = (userIds) => {
    const roomIndex = rooms.findIndex((room) => room.name === roomToAddUsers.name);
    let newRooms = [...rooms];
    newRooms[roomIndex].participantIds = [
      ...newRooms[roomIndex].participantIds, ...userIds
    ];
    updateRooms(newRooms);
  };

  const excludeUser = (roomName, user) => {
    const roomIndex = rooms.findIndex((room) => room.name === roomName);
    let updatedRooms = [...rooms];
    updatedRooms[roomIndex].participantIds = rooms[roomIndex].participantIds.filter(
      (participantId) => participantId !== user.id
    );
    updateRooms(updatedRooms);
  };

  const openRooms = () => {
    setIsOpenButtonDisabled(true);
    let newRooms = deepClone(rooms);
    newRooms.forEach((room) => {
      // 8 is to delay the automatic closing of the room for better user experience
      if (room.endTime) room.endTime += (Date.now() / 1000) + 8;
      dispatch(WebinarsActions.openRoom(room));
    });
  };

  const sortNumericNamedRooms = (firstRoom, secondRoom) => {
    const isNumber = (value) => /^\d+$/.test(value);
    if (isNumber(firstRoom.name) && isNumber(secondRoom.name)) {
      return firstRoom.name - secondRoom.name;
    } else {
      return 0;
    }
  };

  return roomToAddUsers ? (
    <AddUsersToRoomView
      roomName={roomToAddUsers.name}
      addUsersToRoom={addUsersToRoom}
      goBack={() => setRoomToAddUsers(null)}
      usersWithoutRoom={usersWithoutRoom}
      rooms={rooms}
    />
  ) : (
    <Container>
      <Title>{t("webinars.rooms")}</Title>
      {sortBy(rooms, "name")
        .sort(sortNumericNamedRooms)
        .map((room, index) => (
          <RoomDetailsDropdown
            key={index}
            room={room}
            removeRoom={() => removeRoom(room.name)}
            excludeUser={(user) => excludeUser(room.name, user)}
            showAddUsersView={() => setRoomToAddUsers(room)}
          />
        ))
      }
      <ButtonWrapper>
        {!rooms.length ? (
          <Button
            onClick={goBack}
            variant={ButtonVariant.Outlined}
          >
            {t("webinars.roomsFinalEditView.back")}
          </Button>
        ) : (
          <Button
            onClick={openRooms}
            color={ButtonColor.Primary}
            disabled={isOpenButtonDisabled}
          >
            {t("webinars.roomsFinalEditView.openRooms")}
          </Button>
        )}
      </ButtonWrapper>
    </Container>
  );
};
