import { createSelector } from "reselect";
import { prop, path } from "ramda";

export const selectUserContextDomain = prop("userContext");

export const selectUserData = createSelector(
  selectUserContextDomain, prop("data"),
);

export const selectUserLoggedIn = createSelector(
  selectUserContextDomain, prop("loggedIn"),
);

export const selectUserToken = createSelector(
  selectUserData, prop("token"),
);

export const selectUserName = createSelector(
  selectUserData, path(["user", "name"]),
);

export const selectUserSurname = createSelector(
  selectUserData, path(["user", "surname"]),
);

export const selectUserAvatarSrc = createSelector(
  selectUserData, path(["user", "avatar"]),
);

export const selectAvailableRoutes = createSelector(
  selectUserData, path(["user", "availableRoutes"]),
);

export const selectUserPosition = createSelector(
  selectUserData, path(["user", "experience", "position", "name"]),
);

export const selectUserStartOfWork = createSelector(
  selectUserData, path(["user", "experience", "from"]),
);

export const selectUserFirstLogin = createSelector(
  selectUserData, path(["user", "firstLogin"]),
);

export const selectUserStagesFinished = createSelector(
  selectUserData, path(["user", "stagesFinished"]),
);

export const selectUserContentPassed = createSelector(
  selectUserData, path(["user", "contentPassed"]),
);

export const selectUserDuties = createSelector(
  selectUserData, path(["user", "duties"]),
);

export const selectOnboardingMode = createSelector(
  selectUserData, path(["user", "onboardingMode"]),
);

export const selectUserPhone = createSelector(
  selectUserData, path(["user", "phone"]),
);

export const selectVideoForCodeRouteAvailable = createSelector(
  selectAvailableRoutes, prop("api_limited_content_list"),
);

export const selectAmbassadorsRouteAvailable = createSelector(
  selectAvailableRoutes, prop("api_dashboard_user_video"),
);

export const selectNewsRouteAvailable = createSelector(
  selectAvailableRoutes, prop("api_news"),
);

export const selectUserIddRouteAvailable = createSelector(
  selectAvailableRoutes, prop("api_user_idd"),
);

export const selectCompetenceItemsRouteAvailable = createSelector(
  selectAvailableRoutes, prop("api_competence_categories_items"),
);

export const selectFavouriteListRouteAvailable = createSelector(
  selectAvailableRoutes, prop("api_user_favorite_list"),
);

export const selectFavouriteAddRouteAvailable = createSelector(
  selectAvailableRoutes, prop("api_user_favorite_add"),
);

export const selectFavouriteRemoveRouteAvailable = createSelector(
  selectAvailableRoutes, prop("api_user_favorite_remove"),
);

export const selectBannersAvailable = createSelector(
  selectAvailableRoutes, prop("api_banner_list"),
);

export const selectSurveysAvailable = createSelector(
  selectAvailableRoutes, prop("api_certificate_certificate_survey_list"),
);

export const selectIndividualsAvailable = createSelector(
  selectAvailableRoutes, prop("api_certificate_individual_list"),
);

export const selectUserRole = createSelector(
  selectUserData, path(["user", "role"]),
);

export const selectUserBrands = createSelector(
  selectUserData, path(["user", "brands"]),
);

export const selectUserId = createSelector(
  selectUserData, path(["user", "id"]),
);

export const selectAgreementToConfirm = createSelector(
  selectUserData, path(["user", "requiredAgreement"]),
);

export const selectMissingAgreements = createSelector(
  selectUserData, path(["user", "missingAgreements"]),
);

export const selectForceChangePassword = createSelector(
  selectUserData, path(["passwordExpirationInfo", "forceChange"]),
);

export const selectPasswordReminderDaysLeft = createSelector(
  selectUserData, path(["passwordExpirationInfo", "daysToForceChange"]),
);
