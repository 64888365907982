import React, { useState } from "react";
import PropTypes from "prop-types";

import { StarsRate } from "../../../../components/starsRate/starsRate.component";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { CompetenceLevelDropdown } from "../competenceLevelDropdown/competenceLevelDropdown.component";
import { ArrowIcon } from "../competenceTab/competenceTab.styled";

import {
  Container,
  DropdownToggler,
  PanelLeftSide,
  LevelName,
} from "./competenceLevelItem.styled";

export const CompetenceLevelItem = ({ rate, name, description }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const renderDropdown = renderWhenTrue(() => (
    <CompetenceLevelDropdown description={description} />
  ));

  return (
    <Container hiddenBorder={dropdownVisible} onClick={toggleDropdown}>
      <DropdownToggler>
        <PanelLeftSide>
          <StarsRate onlyStars rate={rate} />
          <LevelName>{name}</LevelName>
        </PanelLeftSide>
        <ArrowIcon open={dropdownVisible} />
      </DropdownToggler>
      {renderDropdown(dropdownVisible)}
    </Container>
  )
};

CompetenceLevelItem.propTypes = {
  rate: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
};
