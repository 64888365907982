import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Button as ButtonBase } from "../../../../../components/button/button.component";
import { styleWhenTrue } from "../../../../../helpers/rendering";
import { ReactComponent as CheckSvg } from "../../../../../images/check2.svg";
import { Media, FontWeight, Color, ContentMaxWidth } from "../../../../../theme";

export const Container = styled.div`
  ${Media.desktop`
    padding: 0 80px;
    margin-bottom: 40px;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${() => Color.brandBasic};
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
  padding: 70px 20px 60px;

  ${Media.tablet`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 50px 90px 60px 95px;
  `}
`;

export const TextWrapper = styled.div`
  ${Media.tablet`
    max-width: 700px;
    padding-right: 50px;
  `}
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 30px;
  color: ${Color.white};

  ${Media.tablet`
    font-size: 18px;
    line-height: 30px;
  `}
`;

export const BoldText = styled.span`
  font-weight: ${FontWeight.Bold};
  display: block;

  ${Media.tablet`
    display: inline;
  `}
`;

export const Button = styled(ButtonBase)`
  margin-top: 60px;
  width: 100%;

  ${Media.mobile`
    width: 200px;
    margin-top: 30px;
  `}
`;

export const CheckboxWrapper = styled.button`
  display: flex;
  align-items: center;
  margin: 20px 0 0;
  padding: 0;
  border: none;
  background-color: ${Color.transparent};

  ${styleWhenTrue(prop("disabled"), css`
    cursor: default;
  `)}
`;

export const Checkbox = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${Color.black};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export const CheckIcon = styled(CheckSvg)`
  fill: ${Color.white};
  width: 12px;
  height: 9px;

  ${styleWhenTrue(prop("hidden"), css`
    display: none;
  `)}
`;

const CheckboxTextStyle = css`
  font-size: 12px;
  line-height: 16px;
  color: ${Color.white};
  font-weight: ${FontWeight.SemiBold};
  margin-right: 3px;
`;

export const CheckboxText = styled.p`
  ${CheckboxTextStyle}
`;

export const RegulationsLink = styled.a`
  ${CheckboxTextStyle}
`;

export const AnimationWrapper = styled.div`
  flex-shrink: 0;
  margin: 60px auto 0;

  ${Media.tablet`
    margin: 0;
  `}
`;
