import styled, { css } from "styled-components";
import { prop } from "ramda";

import { hexToRgba } from "../../../../helpers/colors";
import { FontWeight } from "../../../../theme/fonts";
import { Color } from "../../../../theme/colors";
import { Media } from "../../../../theme/media";
import { styleWhenTrue } from "../../../../helpers/rendering";

export const Container = styled.div`
  background-color: ${Color.white};
  border: 1px solid ${Color.dirtyWhite};
  box-shadow: 0 2px 4px 0 ${hexToRgba(Color.gray7, 0.1)}, 0 5px 10px 0 ${hexToRgba(Color.deepGray, 0.01)};
  padding: 16px 20px;
  margin-bottom: 40px;

  ${styleWhenTrue(prop("withPadding"), css`
    margin-top: 56px;
  `)}

  ${Media.tablet`
    margin-bottom: 100px;
  `}
`;

export const Title = styled.p`
  font-size: 10px;
  line-height: 20px;
  color: ${Color.deepGray};
  letter-spacing: 0.5px;
  font-weight: ${FontWeight.Bold};
  text-transform: uppercase;
`;

export const EmptyStateBox = styled.div`
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 54px;
`;

export const EmptyStateMessage = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.gray4};
  margin-top: 22px;
`;
