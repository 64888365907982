import { all, put, takeLatest } from "redux-saga/effects";

import { Theme } from "../../../appConfig";
import { OnboardingAllMode } from "../onboarding.constants";
import * as onboardingApi from "../services/api";

import { OnboardingTypes, OnboardingActions } from "./onboarding.reducer";

function* getInitialData() {
  yield put(OnboardingActions.setIsPending(true));

  if (Theme.OnboardingAll === OnboardingAllMode.Enabled) {
    const user = yield onboardingApi.getOnboardingMode();
    yield put(OnboardingActions.getOnboardingModeSuccess(user.data));
  }

  const { data } = yield onboardingApi.getInitialData();
  yield put(OnboardingActions.getInitialDataSuccess(data));
  yield put(OnboardingActions.setIsPending(false));
}

function* getHeroesList() {
  const { data } = yield onboardingApi.getHeroesList();
  yield put(OnboardingActions.getHeroesListSuccess(data));
}

function* setHero({ id }) {
  const { data } = yield onboardingApi.setHero(id);
  yield put(OnboardingActions.setHeroSuccess(data));
}

function* registerFileDownloading({ id }) {
  yield onboardingApi.registerFileDownload(id);
}

export function* watchOnboarding() {
  yield all([
    takeLatest(OnboardingTypes.GET_INITIAL_DATA, getInitialData),
    takeLatest(OnboardingTypes.REGISTER_FILE_DOWNLOADING, registerFileDownloading),
    takeLatest(OnboardingTypes.GET_HEROES_LIST, getHeroesList),
    takeLatest(OnboardingTypes.SET_HERO, setHero),
  ]);
}
