import React from "react";
import { useTranslation } from "react-i18next";

import {
  Container,
  SquaresIcon,
  Text,
} from "./redirectScreen.styled";

export const RedirectScreen = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <SquaresIcon />
      <Text>{t("webinars.redirectScreen.redirectingToRoom")}</Text>
    </Container>
  );
};
