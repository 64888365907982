import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";
import { useTranslation } from "react-i18next";

import { Loader } from "../../../components/loader/loader.component";
import { RightDrawer } from "../../../components/rightDrawer/rightDrawer.component";
import { Float } from "../../../components/float/float.component";

import { getData } from "./services/api";
import { SectionList } from "./sectionList/sectionList.component";
import { Slide } from "./slide/slide.component";
import { Container, BlankSeparator } from "./scrollContent.styled";

export const ScrollContent = ({
  callComplete,
  currentSlideIndex,
  slidesOverall,
  baseUrl,
  config,
  tags,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState({ backgroundSrc: null, sections: [] });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrollSectionId, setScrollSectionId] = useState(null);
  const [scrollButtonClicked, setScrollButtonClicked] = useState(false);
  const [currentSectionId, setCurrentSectionId] = useState(0);
  const [currentSlideId, setCurrentSlideId] = useState(0);
  const [isPending, setIsPending] = useState(true);
  const listOptions = data.sections.map((section, id) => ({
    id,
    title: section.slides[0].content[0].headerText,
  }));
  const hasSecondSlideIntro = data.sections[0]?.slides.length > 1;
  const floatTitle = currentSectionId && !!data.sections.length
    ? data.sections[currentSectionId].slides[0].content[0].headerText
    : t("lesson.scrollContent.introduction");

  useEffect(() => {
    getData(
      `${baseUrl}${config}`,
      setData,
      setIsPending,
    );
  }, [baseUrl, config]);

  const openList = () => setDrawerOpen(true);

  const closeList = () => setDrawerOpen(false);

  const handleScrollToSection = (sectionId) => {
    closeList();
    setScrollSectionId(sectionId);
    setTimeout(() => setScrollSectionId(null), 1);
  };

  const handleScrollButtonClick = () => {
    setScrollButtonClicked(true);
    setTimeout(() => setScrollButtonClicked(false), 1);
  };

  const handleVisibleChange = (sectionId, slideId) => {
    setCurrentSectionId(sectionId);
    setCurrentSlideId(slideId);
  };

  const handleFinishLesson = () => {
    callComplete();
    window.scrollTo(0, 0);
  };

  if (isPending || !data) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  return (
    <Container backgroundSrc={data.backgroundSrc}>
      {data.sections.map((section, sectionIndex) => (
        section.slides.map((slide, slideIndex) => (
          <React.Fragment key={`${sectionIndex}${slideIndex}`}>
            <ScrollIntoViewIfNeeded
              active={
                (slideIndex === 0 && scrollSectionId === sectionIndex) || (
                  (hasSecondSlideIntro
                    ? sectionIndex === 0 && slideIndex === 1
                    : sectionIndex === 1 && slideIndex === 0)
                  && scrollButtonClicked
                )
              }
            >
              <Slide
                type={slide.type}
                src={slide.src}
                staticSrc={slide.static}
                currentCounter={slideIndex === 0 ? sectionIndex : null}
                overallCounter={data.sections.length}
                content={slide.content}
                tags={tags}
                onVisibleChange={() => handleVisibleChange(sectionIndex, slideIndex)}
                onFloatClick={openList}
                onScrollButtonClick={handleScrollButtonClick}
                isFirstSlide={sectionIndex === 0 && slideIndex === 0}
                isLastSlide={
                  sectionIndex === data.sections.length - 1 && slideIndex === section.slides.length - 1
                }
                isFinishingSlide={currentSlideIndex === slidesOverall - 1}
                onFinishLesson={handleFinishLesson}
                isNextSlide={
                  currentSectionId < sectionIndex || (currentSectionId === sectionIndex && currentSlideId < slideIndex)
                }
                needFix={slide.needFix}
              />
            </ScrollIntoViewIfNeeded>
            <BlankSeparator
              isLastSlide={
                sectionIndex === data.sections.length - 1 && slideIndex === section.slides.length - 1
              }
            />
          </React.Fragment>
        ))
      ))}
      <Float
        currentSection={currentSectionId}
        sectionsOverall={data.sections.length}
        title={floatTitle}
        onClick={openList}
        fixed
        hidden={!currentSectionId}
      />
      <RightDrawer open={drawerOpen} onClose={closeList}>
        <SectionList
          onClose={closeList}
          options={listOptions}
          onSelectOption={handleScrollToSection}
        />
      </RightDrawer>
    </Container>
  );
};

ScrollContent.propTypes = {
  callComplete: PropTypes.func.isRequired,
  currentSlideIndex: PropTypes.number.isRequired,
  slidesOverall: PropTypes.number.isRequired,
  baseUrl: PropTypes.string,
  config: PropTypes.string,
  tags: PropTypes.array,
};
