import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";
import { FormControlLabel, styled as styledMUI } from "@mui/material";

import { Button as ButtonBase } from "../../../../../components/button/button.component";
import { ReactComponent as CheckBase } from "../../../../../images/check.svg";
import { styleWhenTrue } from "../../../../../helpers/rendering";
import { Color } from "../../../../../theme/colors";
import { FontWeight } from "../../../../../theme/fonts";
import { Media } from "../../../../../theme/media";

export const Container = styled.div`
  padding: 40px 25px;
  min-height: 484px;

  ${Media.tablet`
    padding: 100px 30px;
  `}

  ${Media.desktop`
    padding: 100px 125px;
  `}
`;

export const Counter = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: ${Color.gray4};
  margin-bottom: 15px;
`;

export const QuestionText = styled.p`
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 27px;

  ${Media.tablet`
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 43px;
  `}
`;

export const AnswerLabel = styledMUI(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    width: "100%",
    textAlign: "left",
    fontSize: 16,
  }
});

export const Answer = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 2px solid ${Color.gray5};
  padding: 15px;
  font-size: 16px;
  line-height: 22px;
  font-weight: ${FontWeight.Light};
  min-height: 74px;

  & + & {
    margin-top: 2px;
  }

  ${styleWhenTrue(prop("active"), css`
    border-color: ${Color.blue};
  `)}

  ${styleWhenTrue(prop("notChosen"), css`
    background-color: ${Color.gray5};
  `)}

  ${styleWhenTrue(prop("correct"), css`
    border-color: ${Color.green};
    pointer-events: none;
    cursor: default;

    span {
      color: ${Color.green};
    }
  `)}

  ${styleWhenTrue(prop("disabled"), css`
    pointer-events: none;
  `)}

  ${Media.tablet`
    padding: 26px;
  `}
`;

export const ButtonWrapper = styled.div`
  margin-left: auto;
  text-align: right;
`;

export const Button = styled(ButtonBase)`
  margin-top: 28px;
  width: 100%;
  background-color: ${Color.blue};

  ${Media.tablet`
    width: auto;
  `}

  ${Media.noHover`
    background-color: ${Color.blue};
  `}
`;

export const CheckImg = styled(CheckBase)`
  margin-left: auto;
  width: 30px;
  height: 30px;
  visibility: hidden;

  ${styleWhenTrue(propEq("true", "visible"), css`
    visibility: visible;
  `)}
`;
