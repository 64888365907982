import { all, put, takeLatest } from "redux-saga/effects";

import * as certificatesApi from "../services/api";

import { CertificatesTypes, CertificatesActions } from "./certificates.reducer";

function* fetchLessons() {
  const { data } = yield certificatesApi.fetchLessons();
  yield put(CertificatesActions.fetchLessonsSuccess(data));
}

function* fetchCertificatePaths() {
  const { data } = yield certificatesApi.fetchCertificatePaths();
  yield put(CertificatesActions.fetchCertificatePathsSuccess(data));
}

function* fetchSurveys() {
  const { data } = yield certificatesApi.fetchSurveys();
  yield put(CertificatesActions.fetchSurveysSuccess(data));
}

function* fetchPathConfirmations() {
  const { data } = yield certificatesApi.fetchPathConfirmations();
  yield put(CertificatesActions.fetchPathConfirmationsSuccess(data));
}

function* fetchIndividuals() {
  const { data } = yield certificatesApi.fetchIndividuals();
  yield put(CertificatesActions.fetchIndividualsSuccess(data));
}

function* fetchEvents() {
  const { data } = yield certificatesApi.fetchEvents();
  yield put(CertificatesActions.fetchEventsSuccess(data));
}

function* fetchStatistics() {
  yield put(CertificatesActions.setIsPending(true));
  const { data } = yield certificatesApi.fetchStatistics();
  yield put(CertificatesActions.fetchStatisticsSuccess(data));
  yield put(CertificatesActions.setIsPending(false));
}

function* fetchRanking() {
  const { data } = yield certificatesApi.fetchRanking();
  yield put(CertificatesActions.fetchRankingSuccess(data.ranking));
}

function* fetchCertificate({ id }) {
  const { data } = yield certificatesApi.fetchCertificate(id);
  yield put(CertificatesActions.setCertificate(data));
}

export function* watchCertificates() {
  yield all([
    yield takeLatest(CertificatesTypes.FETCH_STATISTICS, fetchStatistics),
    yield takeLatest(CertificatesTypes.FETCH_RANKING, fetchRanking),
    yield takeLatest(CertificatesTypes.FETCH_LESSONS, fetchLessons),
    yield takeLatest(CertificatesTypes.FETCH_CERTIFICATE_PATHS, fetchCertificatePaths),
    yield takeLatest(CertificatesTypes.FETCH_SURVEYS, fetchSurveys),
    yield takeLatest(CertificatesTypes.FETCH_PATH_CONFIRMATIONS, fetchPathConfirmations),
    yield takeLatest(CertificatesTypes.FETCH_INDIVIDUALS, fetchIndividuals),
    yield takeLatest(CertificatesTypes.FETCH_EVENTS, fetchEvents),
    yield takeLatest(CertificatesTypes.FETCH_CERTIFICATE, fetchCertificate),
  ]);
}
