import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { v1 as uuid } from "uuid";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { jsPDF } from "jspdf";

import { ConfirmationModal } from "../../../../components/confirmationModal/confirmationModal.component";
import { Tooltip } from "../../../../components/tooltip/tooltip.component";
import { TooltipPlacement } from "../../../../components/tooltip/tooltip.constants";
import { Color } from "../../../../theme";
import { WebinarsActions } from "../../redux/webinars.reducer";
import { selectCurrentRoom } from "../../redux/webinars.selectors";

import {
  CanvasId,
  FlipchartAction,
  ToolType,
  UserLabelId,
} from "./flipchart.constants";
import {
  Container,
  Canvas,
  Buttons,
  Button,
  CursorIcon,
  PencilIcon,
  // TODO: uncomment when new type of line will be handled
  // LineIcon,
  DeleteIcon,
  RemoveIcon,
  ShapesIcon,
  TextIcon,
  ShapesBox,
  EmptyElipse,
  EmptyRectangle,
  EmptyTriangle,
  Triangle,
  Rectangle,
  Elipse,
  ShapesButton,
  ColorInput,
  PdfIcon,
  Separator,
} from "./flipchart.styled";
import {
  initCanvas,
  addCircle,
  addRectangle,
  addTriangle,
  addText,
  addPath,
  addLine,
  createLabel,
  moveLabel,
  findAndModifyLabel,
  hideLabel,
  hideLabelForASecond,
} from "./flipchart.utils";

export const Flipchart = ({ wideRightPanel = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const room = useSelector(selectCurrentRoom);
  const [canvas, setCanvas] = useState();
  const [drawingMode, setDrawingMode] = useState(false);
  const [currentColor, setCurrentColor] = useState(Color.deepGray);
  const [shapesBoxOpen, setShapesBoxOpen] = useState(false);
  const [clearModalOpen, setClearModalOpen] = useState(false);
  const canvasRef = useRef();

  useEffect(() => {
    setCanvas(initCanvas());
  }, []);

  useEffect(() => {
    if (canvas && room.initFlipchartData) {
      canvas.loadFromJSON(room.initFlipchartData, () => {
        canvas.renderAll();
      });
    }
  }, [canvas, room.initFlipchartData]);

  useEffect(() => {
    if (canvas) {
      createLabel(canvas);
    }
  });

  useEffect(() => {
    if (canvas) {
      canvas.on("object:modified", ({ target }) => {
        if (target) {
          const modifiedObject = {
            element: target,
            id: target.id,
          };
          dispatch(WebinarsActions.flipchartModifyElement(modifiedObject.element, modifiedObject.id));
          dispatch(WebinarsActions.sendFlipchartState(canvas.toJSON(["id"])));
          findAndModifyLabel(false, canvas);
        }
      });

      canvas.on("object:moving", ({ target }) => {
        if (target) {
          const modifiedObject = {
            element: target,
            id: target.id,
          };
          dispatch(WebinarsActions.flipchartModifyElement(modifiedObject.element, modifiedObject.id));
          dispatch(WebinarsActions.sendFlipchartState(canvas.toJSON(["id"])));
          findAndModifyLabel(false, canvas);
        }
      });

      canvas.on("path:created", ({ path }) => {
        if (path) {
          path.id = uuid();
          dispatch(WebinarsActions.flipchartAddElement(path, path.id));
          dispatch(WebinarsActions.sendFlipchartState(canvas.toJSON(["id"])));
          findAndModifyLabel(false, canvas);
        }
      });
    }
  }, [canvas]);

  // TODO: try different type of line, this is not easy to work with
  // const handleAddLine = () => {
  //   turnOffDrawing();
  //   addLine(currentColor, canvas);
  // };

  const handleAddCircle = () => {
    turnOffDrawing();
    closeShapesBox();
    addCircle(currentColor, currentColor, false, canvas);
  };

  const handleAddRectangle = () => {
    turnOffDrawing();
    closeShapesBox();
    addRectangle(currentColor, currentColor, false, canvas);
  };

  const handleAddTriangle = () => {
    turnOffDrawing();
    closeShapesBox();
    addTriangle(currentColor, currentColor, false, canvas);
  };

  const handleAddEmptyCircle = () => {
    turnOffDrawing();
    closeShapesBox();
    addCircle(currentColor, currentColor, true, canvas);
  };

  const handleAddEmptyRectangle = () => {
    turnOffDrawing();
    closeShapesBox();
    addRectangle(currentColor, currentColor, true, canvas);
  };

  const handleAddEmptyTriangle = () => {
    turnOffDrawing();
    closeShapesBox();
    addTriangle(currentColor, currentColor, true, canvas);
  };

  const handleAddText = () => {
    turnOffDrawing();
    addText(currentColor, currentColor, canvas, t("webinars.classes.doubleClickToEdit"));
  };

  const toggleShapesBox = () => setShapesBoxOpen(!shapesBoxOpen);
  const closeShapesBox = () => setShapesBoxOpen(false);

  const toggleDrawing = () => {
    canvas.isDrawingMode = !drawingMode;
    setDrawingMode(!drawingMode);
  };

  const turnOffDrawing = () => {
    canvas.isDrawingMode = false;
    setDrawingMode(false);
  };

  const removeObject = () => {
    turnOffDrawing();
    const element = canvas.getActiveObject();

    if (element) {
      canvas.remove(element);
      dispatch(WebinarsActions.flipchartRemoveElement(element.id));
      dispatch(WebinarsActions.sendFlipchartState(canvas.toJSON(["id"])));
      findAndModifyLabel(false, canvas);
    }
  };

  const openClearModal = () => {
    turnOffDrawing();
    setClearModalOpen(true);
  };
  const closeClearModal = () => setClearModalOpen(false);

  const clearCanvas = () => {
    closeClearModal();
    canvas.remove(...canvas.getObjects());
    dispatch(WebinarsActions.flipchartClearCanvas());
    dispatch(WebinarsActions.sendFlipchartState(canvas.toJSON(["id"])));
  };

  const handleChangeColor = ({ target }) => {
    setCurrentColor(target.value);

    if (canvas) {
      const element = canvas.getActiveObject();

      if (element) {
        element.set({
          stroke: element.stroke ? target.value : "",
          fill: element.fill ? target.value : "",
        });
        canvas.renderAll();
      }

      canvas.freeDrawingBrush.color = target.value;
    }
  };

  useEffect(() => {
    const { action, element, elementId } = room.lastFlipchartData || {};
    const { name, surname } = room.lastFlipchartAuthor || {};

    switch (action) {
    case FlipchartAction.Add:
      if (element.type === ToolType.Circle) {
        addCircle(
          element.fill,
          element.stroke,
          false,
          canvas,
          element.radius,
          elementId,
        );
      } else if (element.type === ToolType.Rectangle) {
        addRectangle(
          element.fill,
          element.stroke,
          false,
          canvas,
          element.width,
          element.height,
          elementId,
        );
      } else if (element.type === ToolType.Triangle) {
        addTriangle(
          element.fill,
          element.stroke,
          false,
          canvas,
          element.width,
          element.height,
          elementId,
        );
      } else if (element.type === ToolType.Text) {
        addText(element.fill, element.stroke, canvas, element.text, elementId);
      } else if (element.type === ToolType.Path) {
        addPath(element.stroke, canvas, element, elementId);
      } else if (element.type === ToolType.Line) {
        addLine(element.stroke, canvas, elementId);
      }

      findAndModifyLabel(true, canvas, `${name} ${surname}`, element.left, element.top);
      break;
    case FlipchartAction.Modify:
      canvas.getObjects().forEach((object) => {
        if (object.id === elementId) {
          object.set(element);
          if (element.type === ToolType.Line) {
            object.set({
              x1: object.x1 + element.left,
              y1: object.y1 + element.top + 50,
              x2: object.x2 + element.left,
              y2: object.y2 + element.top + 50,
            });
          }
          object.setCoords();
          canvas.renderAll();
        } else if (object.id === UserLabelId) {
          moveLabel(object, canvas, `${name} ${surname}`, element.left, element.top);
        }
      });
      break;
    case FlipchartAction.RemoveElement:
      canvas.getObjects().forEach((object) => {
        if (object.id === elementId) {
          canvas.setActiveObject(object);
          canvas.remove(canvas.getActiveObject());
        } else if (object.id === UserLabelId) {
          hideLabel(object, canvas);
        }
      });
      break;
    case FlipchartAction.ClearCanvas:
      canvas.remove(...canvas.getObjects());
      break;
    default: break;
    }
  }, [room.lastFlipchartData, room.lastFlipchartAuthor]);

  const exportCanvasToPdf = () => {
    hideLabelForASecond(canvas);
    const pdf = new jsPDF({
      orientation: canvasRef?.current.width > canvasRef?.current.height ? "landscape" : "portrait",
    });
    const imgData = canvas.toDataURL({
      width: canvas.width,
      height: canvas.height,
      left: 0,
      top: 0,
      format: "png",
    });
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    if (imgProps.width > imgProps.height) {
      const pdfImageWidth = (imgProps.width * pdfHeight) / imgProps.height;
      const margin = pdfWidth - pdfImageWidth;
      pdf.addImage(imgData, "PNG", margin / 2, 0, pdfImageWidth, pdfHeight);
    } else {
      const pdfImageHeight = (imgProps.height * pdfWidth) / imgProps.width;
      const margin = pdfHeight - pdfImageHeight;
      pdf.addImage(imgData, "PNG", 0, margin / 2, pdfWidth, pdfImageHeight);
    }
    pdf.save(`${t("webinars.classes.whiteboard")}.pdf`);
  };

  return (
    <Container wideRightPanel={wideRightPanel}>
      <Buttons>
        <Tooltip title={t("webinars.classes.cursor")} placement={TooltipPlacement.Right}>
          <Button onClick={turnOffDrawing}><CursorIcon isactive={(!drawingMode).toString()} /></Button>
        </Tooltip>
        <Tooltip title={t("webinars.classes.drawing")} placement={TooltipPlacement.Right}>
          <Button onClick={toggleDrawing}><PencilIcon isactive={drawingMode.toString()} /></Button>
        </Tooltip>
        {/* TODO: uncomment when new type of line will be handled, current is not easy to work with */}
        {/* <Tooltip title={t("webinars.classes.line")} placement={TooltipPlacement.Right}>
          <Button onClick={handleAddLine}><LineIcon /></Button>
        </Tooltip> */}
        <Tooltip title={t("webinars.classes.text")} placement={TooltipPlacement.Right}>
          <Button onClick={handleAddText}><TextIcon /></Button>
        </Tooltip>
        <Tooltip title={t("webinars.classes.shapes")} placement={TooltipPlacement.Right}>
          <Button onClick={toggleShapesBox}><ShapesIcon /></Button>
        </Tooltip>
        <Tooltip title={t("webinars.classes.removeObject")} placement={TooltipPlacement.Right}>
          <Button onClick={removeObject}><RemoveIcon /></Button>
        </Tooltip>
        <Tooltip title={t("webinars.classes.deleteAll")} placement={TooltipPlacement.Right}>
          <Button onClick={openClearModal}><DeleteIcon /></Button>
        </Tooltip>
        <Separator $narrow />
        <Tooltip title={t("webinars.classes.chooseColor")} placement={TooltipPlacement.Right}>
          <ColorInput type="color" onChange={handleChangeColor} value={currentColor} />
        </Tooltip>
        <Separator $narrow />
        <Tooltip title={t("webinars.classes.exportAsPdf")} placement={TooltipPlacement.Right}>
          <Button onClick={exportCanvasToPdf}><PdfIcon /></Button>
        </Tooltip>
        {shapesBoxOpen && (
          <ClickAwayListener onClickAway={closeShapesBox}>
            <ShapesBox>
              <Tooltip title={t("webinars.classes.circle")} placement={TooltipPlacement.Right}>
                <ShapesButton onClick={handleAddEmptyCircle}><EmptyElipse /></ShapesButton>
              </Tooltip>
              <Tooltip title={t("webinars.classes.filledCircle")} placement={TooltipPlacement.Right}>
                <ShapesButton onClick={handleAddCircle}><Elipse /></ShapesButton>
              </Tooltip>
              <Tooltip title={t("webinars.classes.triangle")} placement={TooltipPlacement.Right}>
                <ShapesButton onClick={handleAddEmptyTriangle}><EmptyTriangle /></ShapesButton>
              </Tooltip>
              <Tooltip title={t("webinars.classes.filledTriangle")} placement={TooltipPlacement.Right}>
                <ShapesButton onClick={handleAddTriangle}><Triangle /></ShapesButton>
              </Tooltip>
              <Tooltip title={t("webinars.classes.rectangle")} placement={TooltipPlacement.Right}>
                <ShapesButton onClick={handleAddEmptyRectangle}><EmptyRectangle /></ShapesButton>
              </Tooltip>
              <Tooltip title={t("webinars.classes.filledRectangle")} placement={TooltipPlacement.Right}>
                <ShapesButton onClick={handleAddRectangle}><Rectangle /></ShapesButton>
              </Tooltip>
            </ShapesBox>
          </ClickAwayListener>
        )}
      </Buttons>
      <Canvas id={CanvasId} ref={canvasRef} />
      <ConfirmationModal
        open={clearModalOpen}
        confirmText={t("webinars.classes.deleteAll")}
        declineText={t("global.cancel")}
        onConfirm={clearCanvas}
        onDecline={closeClearModal}
      >
        {t("webinars.classes.sureToClear")}
      </ConfirmationModal>
    </Container>
  );
};
