import React from "react";
import PropTypes from "prop-types";

import { Color } from "../../theme";

import { BubbleLabel } from "./bubbleLabel/bubbleLabel.component";
import { BubbleTooltip } from "./bubbleTooltip/bubbleTooltip.component";
import { getDefs, getFills } from "./bubbleChart.utils";
import { BubbleColorType, BubbleColorOpacity } from "./bubbleChart.constants";
import { Container } from "./bubbleChart.styled";

export const BubbleChart = ({
  className,
  data,
  colorType = BubbleColorType.Overall,
}) => {
  const color = colorType === BubbleColorType.Brand ? Color.brandBasic : Color.yellow2;
  const rootChildren = [...data].sort(
    (level1, level2) => level2.value - level1.value
  ).map((item, index) => ({
    ...item,
    label: item.label.toUpperCase(),
    opacity: BubbleColorOpacity[index],
  }));

  return (
    <Container
      className={className}
      data={{ children: rootChildren }}
      id="label"
      defs={getDefs(rootChildren, color)}
      fill={getFills(rootChildren, color)}
      padding={4}
      enableLabels
      labelTextColor={Color.white}
      label={BubbleLabel}
      labelsSkipRadius={50}
      animate
      motionStiffness={90}
      motionDamping={12}
      leavesOnly
      tooltip={BubbleTooltip}
      isZoomable={false}
    />
  );
};

BubbleChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  colorType: PropTypes.string,
};
