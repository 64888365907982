import React from "react";
import { useTranslation } from "react-i18next";

import { ButtonColor } from "../../components/button/button.constants";

import {
  Container,
  Content,
  Title,
  Text,
  ButtonWrapper,
  Button,
} from "./refreshPopup.styled";

export const RefreshPopup = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Content>
        <Title>{t("refreshPopup.title")}</Title>
        <Text>{t("refreshPopup.text")}</Text>
      </Content>
      <ButtonWrapper>
        <Button color={ButtonColor.Primary} onClick={() => location.reload()}>
          {t("refreshPopup.update")}
        </Button>
      </ButtonWrapper>
    </Container>
  );
};
