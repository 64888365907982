import styled from "styled-components";

import { breakpoints } from "../../../../UIGlobals";
import { ContentMaxWidth } from "../../../../theme";

export const Container = styled.div`
  @media (${breakpoints().minXL}) {
    max-width: ${ContentMaxWidth};
    margin: 0 auto;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "stretch")};
  ${({ justifyContent }) => justifyContent && "justify-content: " + justifyContent};
  ${({ marginBottom }) => marginBottom && "margin-bottom: " + marginBottom + "px"};
  ${({ marginTop }) => marginTop && "margin-top: " + marginTop + "px"};
  ${({ position }) => position && "position: " + position};
  ${({ padding }) => padding && "padding: " + padding};
  ${({ mobilePadding }) => mobilePadding &&
    `@media (${breakpoints().maxM}) {
      padding: ${mobilePadding};
    }`
  }
  ${({ mobileMarginTop }) => mobileMarginTop &&
    `@media (${breakpoints().maxM}) {
      margin-top: ${mobileMarginTop}px;
    }`
  }
`;
