import React from "react";
import { useSelector } from "react-redux";

import { selectNav } from "../redux/lesson.selectors";
import Slide from "../slide/slide";

export const Page = ({
  slides = [],
  baseUrl,
  onListClick,
  pageTitle,
  currentSlideIndex,
  slidesOverall,
  tags,
}) => {
  const nav = useSelector(selectNav);

  return (
    <div>
      {slides.filter((slide) => slide.id === nav.currentChild).map((slide) => {
        return (
          <Slide
            slide={slide}
            key={slide.id}
            baseUrl={baseUrl}
            callComplete={() => {}}
            onListClick={onListClick}
            pageTitle={pageTitle}
            currentSlideIndex={currentSlideIndex}
            slidesOverall={slidesOverall}
            tags={tags}
          />
        );
      })}
    </div>
  );
};
