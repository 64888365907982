import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import { useTranslation } from "react-i18next";

import { LoadingWrapper, Col } from "../../../../UIElements";
import { breakpoints } from "../../../../UIGlobals";
import { selectStatsData, selectStatsLoading, selectStatsTeam } from "../../redux/teamIndicators.selectors";
import { TeamIndicatorsActions } from "../../redux/teamIndicators.reducer";

import { NumericalStatistics } from "./numericalStatistics/numericalStatistics.component";
import { UserRankingBox } from "./userRankingBox/userRankingBox.component";
import { ProgressBoxes } from "./progressBoxes/progressBoxes.component";
import { TeamActivity } from "./teamActivity/teamActivity.component";
import { Container, Row } from "./statistics.styled";

export const Statistics = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loading = useSelector(selectStatsLoading);
  const data = useSelector(selectStatsData);
  const team = useSelector(selectStatsTeam);

  useEffect(() => {
    dispatch(TeamIndicatorsActions.getStats());
    dispatch(TeamIndicatorsActions.updateStatsTeam(0, 3));
  }, []);

  return data ? (
    <Container>
      <LoadingWrapper isLoading={loading}>
        <MediaQuery query={`(${breakpoints().minL})`}>
          <Row marginBottom={20} position="relative">
            <Col>
              <NumericalStatistics statistics={data.myStatistic} title={t("statistics.statistics.myStatistics")} />
            </Col>
            <Col width="auto">
              <UserRankingBox ranking={data.ranking} />
            </Col>
          </Row>
        </MediaQuery>
        <MediaQuery query={`(${breakpoints().maxL}) and (${breakpoints().minS})`}>
          <UserRankingBox ranking={data.ranking} />
          <NumericalStatistics marginTop={30} statistics={data.myStatistic} title={t("statistics.statistics.myStatistics")} />
        </MediaQuery>
        <MediaQuery query={`(${breakpoints().maxS})`}>
          <NumericalStatistics statistics={data.myStatistic} title={t("statistics.statistics.myStatistics")} />
          <UserRankingBox ranking={data.ranking} />
        </MediaQuery>
        {data.myStatistic && <ProgressBoxes awards={data.myStatistic.awards} />}
        {data.teamStatistic && (
          <>
            <NumericalStatistics
              threeColumns
              isBlack
              statistics={data.teamStatistic}
              title={t("statistics.statistics.myTeam")}
            />
            <ProgressBoxes awards={data.teamStatistic.awards} />
          </>
        )}
        {team && (team.length > 0) && <TeamActivity />}
      </LoadingWrapper>
    </Container>
  ) : (
    <div />
  );
};
