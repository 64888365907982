import { prop, propEq } from "ramda";
import styled, { css } from "styled-components";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color } from "../../../../theme";
import { ReactComponent as ArrowSvg } from "../../images/dropdownArrow.svg";

export const DropdownElement = styled.div`
  min-height: 36px;
  font-size: 12px;
  padding: 7px 15px;
  border-left: 1px solid ${Color.dirtyWhite};
  border-right: 1px solid ${Color.dirtyWhite};
  background-color: ${Color.white};
  color: ${Color.deepGray};
  line-height: 22px;
  border-top: none;
  cursor: pointer;

  &:hover {
    color: ${() => Color.brandBasic};
  }
`;

export const Container = styled.div`
  width: 200px;
  min-height: 36px;
  display: flex;
  position: relative;
  align-items: center;
  border: 1px solid ${Color.dirtyWhite};
  color: ${Color.gray4};
  font-size: 12px;
  padding: 7px 15px;
  line-height: 22px;
  cursor: pointer;
  margin-left: auto;

  ${styleWhenTrue(propEq(false, "selected"), css`
    background-color: ${Color.almostWhite};

    ${DropdownElement} {
      background-color: ${Color.almostWhite};
    }
  `)}

  ${styleWhenTrue(prop("selected"), css`
    color: ${Color.deepGray};
  `)}
`;

export const Dropdown = styled.div`
  display: none;
  z-index: 1;
  background-color: ${Color.white};

  ${styleWhenTrue(prop("open"), css`
    display: block;
    position: absolute;
    top: calc(100% + 1px);
    left: -1px;
    right: -1px;
    max-height: 200px;
    overflow-y: auto;
    border-bottom: 1px solid ${Color.dirtyWhite};
  `)}
`;

export const ArrowIcon = styled(ArrowSvg)`
  margin-left: auto;
  flex-shrink: 0;

  ${styleWhenTrue(prop("open"), css`
    transform: rotate(180deg);
  `)}
`;
