import React, { useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";

import { AppConfig } from "../../../../appConfig";
import { Color } from "../../../../theme";
import { PageDataContext } from "../page/page.context";

import {
  Container,
  Title,
  Subtitle,
  HelperText,
  CounterWrapper,
  CurrentSlide,
  Separator,
  Float,
} from "./textBox.styled";

export const TextBox = ({
  isHeader,
  fluidHeight,
  onFloatClick = Function.prototype,
  isInteraction,
  callComplete,
  completed,
}) => {
  const { t } = useTranslation();
  const {
    title,
    description,
    helperText,
    additionalTitle,
    currentSlideCounter,
    slidesOverallCounter,
  } = useContext(PageDataContext);

  const subtitleText = useMemo(() => {
    return description
      .replaceAll("#brand", Color.brandBasic)
      .replaceAll("#contentURL", AppConfig.content);
  }, [title]);

  useEffect(() => {
    if (!completed && isInteraction) {
      callComplete();
    }
  }, []);

  return (
    <Container
      $withBg={!isHeader}
      $fluidHeight={fluidHeight}
      $isInteraction={isInteraction}
    >
      {!isHeader && !additionalTitle && (
        <CounterWrapper>
          <CurrentSlide>{currentSlideCounter}</CurrentSlide>
          {` (${slidesOverallCounter})`}
        </CounterWrapper>
      )}
      <Title
        $big={isHeader}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title, { ADD_ATTR: ["target"] }) }}
      />
      {!!title && <Separator />}
      <Subtitle dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(subtitleText, { ADD_ATTR: ["target"] }) }} />
      <HelperText>{helperText}</HelperText>
      {isHeader && (
        <Float
          currentSection={currentSlideCounter - 1}
          sectionsOverall={slidesOverallCounter}
          title={t("lesson.scrollContent.introduction")}
          onClick={onFloatClick}
        />
      )}
    </Container>
  );
};

TextBox.propTypes = {
  isHeader: PropTypes.bool,
  onFloatClick: PropTypes.func,
  isInteraction: PropTypes.bool,
  callComplete: PropTypes.func,
  completed: PropTypes.bool,
};
