import { all, put, takeLatest } from "redux-saga/effects";

import * as videoPlayerApi from "../services/api";

import { VideoPlayerTypes, VideoPlayerActions } from "./videoPlayer.reducer";

function* getInitialData({ contentId }) {
  yield put(VideoPlayerActions.setIsPending(true));
  const { data } = yield videoPlayerApi.getInitialData(contentId);
  yield put(VideoPlayerActions.getInitialDataSuccess(data));
  yield put(VideoPlayerActions.setIsPending(false));
}

function* getRelatedVideos({ contentId }) {
  const { data } = yield videoPlayerApi.getRelatedVideos(contentId);
  yield put(VideoPlayerActions.getRelatedVideosSuccess(data));
}

function* getComments({ contentId }) {
  const { data } = yield videoPlayerApi.getComments(contentId);
  yield put(VideoPlayerActions.getCommentsSuccess(data.data));
}

function* sendComment({ comment, onFinish = Function.prototype }) {
  const { data } = yield videoPlayerApi.sendComment(comment);
  onFinish();
  yield put(VideoPlayerActions.sendCommentSuccess(data.data));
}

function* editComment({ comment }) {
  const { data } = yield videoPlayerApi.editComment(comment);
  yield put(VideoPlayerActions.editCommentSuccess(data.data));
}

function* deleteComment({ commentId }) {
  const { data } = yield videoPlayerApi.deleteComment(commentId);
  yield put(VideoPlayerActions.deleteCommentSuccess(data.data));
}

function* sendFinishAction({ contentId, actionString }) {
  yield videoPlayerApi.sendFinishAction(contentId, actionString);
}

export function* watchVideoPlayer() {
  yield all([
    yield takeLatest(VideoPlayerTypes.GET_INITIAL_DATA, getInitialData),
    yield takeLatest(VideoPlayerTypes.GET_RELATED_VIDEOS, getRelatedVideos),
    yield takeLatest(VideoPlayerTypes.GET_COMMENTS, getComments),
    yield takeLatest(VideoPlayerTypes.SEND_COMMENT, sendComment),
    yield takeLatest(VideoPlayerTypes.EDIT_COMMENT, editComment),
    yield takeLatest(VideoPlayerTypes.DELETE_COMMENT, deleteComment),
    yield takeLatest(VideoPlayerTypes.SEND_FINISH_ACTION, sendFinishAction),
  ]);
}
