import React, { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { cond, T, always, equals } from "ramda";

import { RightDrawer } from "../../../../components/rightDrawer/rightDrawer.component";
import { ButtonColor, ButtonVariant } from "../../../../components/button/button.constants";
import { Loader } from "../../../../components/loader/loader.component";
import { TrainingsActions } from "../../redux/trainings.reducer";
import {
  selectBlockingEvent,
  selectCheckPopupOpenedId,
  selectMissingCourses,
  selectNextCourses,
  selectShowTrainingRegulation,
  selectToRemoveCourses,
} from "../../redux/trainings.selectors";
import { CategoryEnum } from "../../trainings.constants";
import { getDurationInDays } from "../../trainings.utils";
import { EnrollForm } from "../enrollForm/enrollForm.component";
import { CheckModal } from "../checkModal/checkModal.component";
import { CheckModalType } from "../checkModal/checkModal.constants";

import {
  Container,
  ArrowIcon,
  Header,
  HeaderTextWrapper,
  HeaderText,
  HeaderTitle,
  HeaderSubtitle,
  Schedule,
  ScheduleTitle,
  ScheduleSeparator,
  ScheduleItem,
  ScheduleTime,
  InfoTextWrapper,
  InfoText,
  Buttons,
  EnrollButton,
  DetailsButton,
  SeatsIcon,
  PlaceIcon,
  CloseIcon,
  ExamsIcon,
  OthersIcon,
  MoneyIcon,
  DevelopmentIcon,
  ProductOthersIcon,
  HRIcon,
  InsuranceIcon,
  InfoEventsIcon,
  SystemIcon,
  ProductTrainingIcon,
} from "./trainingItem.styled";
import { getFormattedDate } from "./trainingItem.utils";

export const TrainingItem = ({ events }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const blockingEvent = useSelector(selectBlockingEvent);
  const missingCourses = useSelector(selectMissingCourses);
  const checkPopupOpenedId = useSelector(selectCheckPopupOpenedId);
  const nextCourses = useSelector(selectNextCourses);
  const toRemoveCourses = useSelector(selectToRemoveCourses);
  const showTrainingRegulation = useSelector(selectShowTrainingRegulation);
  const [collapsed, setCollapsed] = useState(true);
  const [openEnrollFormId, setOpenEnrollFormId] = useState();
  const [disabledEnrollButtonId, setDisabledEnrollButtonId] = useState();
  const durationInDays = useMemo(() => getDurationInDays(
    events[0].cyclicalInfo ? events[0].cyclicalInfo.nextTimestampStart : events[0].date.from,
    events[0].cyclicalInfo ? events[0].cyclicalInfo.nextTimestampEnd : events[0].date.to,
  ), [events]);

  const categoryIcon = cond([
    [equals(CategoryEnum.Exams), always(<ExamsIcon />)],
    [equals(CategoryEnum.Development), always(<DevelopmentIcon />)],
    [equals(CategoryEnum.ProductFinancing), always(<MoneyIcon />)],
    [equals(CategoryEnum.ProductInsurance), always(<InsuranceIcon />)],
    [equals(CategoryEnum.ProductService), always(<ProductTrainingIcon />)],
    [equals(CategoryEnum.ProductOthers), always(<ProductOthersIcon />)],
    [equals(CategoryEnum.SystemOperations), always(<SystemIcon />)],
    [equals(CategoryEnum.HRTraining), always(<HRIcon />)],
    [equals(CategoryEnum.InformationTraining), always(<InfoEventsIcon />)],
    [equals(CategoryEnum.Other), always(<OthersIcon />)],
    [T, always(<OthersIcon />)],
  ]);

  const handleEnrollButtonClick = (event) => {
    setDisabledEnrollButtonId(event.id);

    dispatch(TrainingsActions.checkEventAccess(event.id, () => {
      if (event.entrySurvey) {
        setOpenEnrollFormId(event.id);
        setDisabledEnrollButtonId();
      } else {
        dispatch(TrainingsActions.enrollEvent(event.id, () => {
          setDisabledEnrollButtonId();
        }));
      }
    }));
  };

  const handleDisenrollButtonClick = (event) => {
    setDisabledEnrollButtonId(event.id);
    dispatch(TrainingsActions.checkEventDisenroll(event.id, () => {
      handleDisenroll(event.id);
    }));
  };

  const handleDisenroll = (eventId) => {
    dispatch(TrainingsActions.disenrollEvent(eventId, () => {
      setDisabledEnrollButtonId();
    }));
  };

  const handleCloseCheckPopup = () => {
    dispatch(TrainingsActions.closeCheckPopup());
    setDisabledEnrollButtonId();

    setTimeout(() => {
      dispatch(TrainingsActions.clearCourses());
    }, 500);
  };

  return (
    <Container>
      <Header onClick={() => setCollapsed(!collapsed)}>
        {categoryIcon(events[0].course.category?.enum)}
        <HeaderTextWrapper>
          <HeaderText>
            <HeaderTitle>{events[0].course.name}</HeaderTitle>
            <HeaderSubtitle>
              {t("trainings.eventList.duration")}
              {" "}
              {durationInDays}
              {" "}
              {durationInDays === 1 ? t("trainings.eventList.day") : t("trainings.eventList.days")}
            </HeaderSubtitle>
          </HeaderText>
          <ArrowIcon collapsed={collapsed.toString()} />
        </HeaderTextWrapper>
      </Header>
      {!collapsed && (
        <Schedule>
          <ScheduleTitle>{t("trainings.eventList.schedule")}</ScheduleTitle>
          <ScheduleSeparator />
          {events.map((event) => (
            <Fragment key={event.id}>
              <ScheduleItem>
                <ScheduleTime>
                  {getFormattedDate(event.cyclicalInfo ? event.cyclicalInfo.nextTimestampStart : event.date.from)}
                </ScheduleTime>
                <InfoTextWrapper>
                  <SeatsIcon />
                  <InfoText>
                    {`${t("trainings.eventList.seatsAvailable")} ${event.availableEnrolments}`}
                  </InfoText>
                </InfoTextWrapper>
                <InfoTextWrapper>
                  <PlaceIcon />
                  <InfoText>
                    {`${t("trainings.eventList.place")} ${event.course.isOnline ? t("trainings.eventList.online") : event.location?.city || t("trainings.eventList.noPlace")}`}
                  </InfoText>
                </InfoTextWrapper>
                <Buttons>
                  {event.userFlags.canBeEnrolled && !event.userFlags.isEnrolled && (
                    <EnrollButton
                      color={ButtonColor.Primary}
                      onClick={() => handleEnrollButtonClick(event)}
                      disabled={event.id === disabledEnrollButtonId}
                    >
                      {event.id === disabledEnrollButtonId ? (
                        <Loader size={22} />
                      ) : (
                        t("trainings.eventList.enroll")
                      )}
                    </EnrollButton>
                  )}
                  {event.userFlags.canBeDisenrolled && event.userFlags.isEnrolled && (
                    <EnrollButton
                      color={ButtonColor.Primary}
                      onClick={() => handleDisenrollButtonClick(event)}
                      disabled={event.id === disabledEnrollButtonId}
                    >
                      {event.id === disabledEnrollButtonId ? (
                        <Loader size={22} />
                      ) : (
                        <>
                          {t("trainings.eventList.disenroll")}
                          <CloseIcon />
                        </>
                      )}
                    </EnrollButton>
                  )}
                  <DetailsButton onClick={() => navigate(`${event.id}`)} variant={ButtonVariant.Outlined}>
                    {t("trainings.eventList.details")}
                  </DetailsButton>
                </Buttons>
              </ScheduleItem>
              <ScheduleSeparator />
              <CheckModal
                modalType={blockingEvent ? CheckModalType.BlockingEvent : CheckModalType.Error}
                eventName={events[0].course.name}
                eventDate={event.cyclicalInfo ? event.cyclicalInfo.nextTimestampStart : event.date.from}
                courses={missingCourses}
                blockingEvent={blockingEvent}
                open={checkPopupOpenedId === event.id && (!!missingCourses?.length || !!blockingEvent)}
                onClose={handleCloseCheckPopup}
              />
              <CheckModal
                modalType={CheckModalType.Success}
                eventName={events[0].course.name}
                courses={nextCourses}
                open={checkPopupOpenedId === event.id && !!nextCourses?.length}
                onClose={handleCloseCheckPopup}
              />
              <CheckModal
                modalType={CheckModalType.Disenroll}
                eventName={events[0].course.name}
                courses={toRemoveCourses}
                showTrainingRegulation={showTrainingRegulation}
                open={checkPopupOpenedId === event.id && (!!toRemoveCourses?.length || showTrainingRegulation)}
                onClose={handleCloseCheckPopup}
                onConfirm={() => handleDisenroll(event.id)}
              />
              {event.entrySurvey && (
                <RightDrawer open={openEnrollFormId === event.id} onClose={() => setOpenEnrollFormId()}>
                  <EnrollForm
                    onClose={() => setOpenEnrollFormId()}
                    withAccommodation={event.entrySurvey.withAccommodation}
                    withParking={event.entrySurvey.withParking}
                    withVege={event.entrySurvey.withVege}
                    eventId={event.id}
                  />
                </RightDrawer>
              )}
            </Fragment>
          ))}
        </Schedule>
      )}
    </Container>
  );
};
