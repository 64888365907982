import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ButtonColor } from "../../../../components/button/button.constants";
import { renderWhenTrueOtherwise, renderWhenTrue } from "../../../../helpers/rendering";
import { selectParticipants } from "../../redux/webinars.selectors";
import { BroadcastType } from "../video/video.constants";
import { Timer } from "../timer/timer.component";

import {
  Container,
  Header,
  Text,
  Button,
  PlayInfo,
} from "./streamTimer.styled";

export const StreamTimer = ({ fullScreen, startTimestamp, liveEnded }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const participants = useSelector(selectParticipants);
  const presenter = participants.find((participant) => participant.role === BroadcastType.Presenter);
  const isCurrentUserPresenter = presenter && presenter.user.isCurrentUser;
  const timeToStream = startTimestamp - Date.now() / 1000;
  const days = Math.floor((timeToStream / (60 * 60 * 24)) % 24);
  const [isTimeOver, setIsTimeOver] = useState(null);

  useEffect(() => {
    setIsTimeOver(startTimestamp <= Date.now() / 1000);
  }, [startTimestamp]);

  const handleTimeOver = () => setIsTimeOver(true);

  const handleLeave = () => navigate("/");

  const renderHeader = renderWhenTrue(() => (
    <Header>{t("webinars.streamTimer.waitingForPresenter")}</Header>
  ));

  const renderHours = renderWhenTrue(() => (
    <>
      <Text>{t("webinars.streamTimer.meetingStartsIn")}</Text>
      <Timer
        streamTimer
        startTimestamp={startTimestamp}
        onEnd={handleTimeOver}
      />
    </>
  ));

  const renderOneDayLeft = renderWhenTrue(() => (
    <Text>{t("webinars.streamTimer.meetingStartsTomorrow")}</Text>
  ));

  const renderDays = renderWhenTrue(() => (
    <Text>
      {t("webinars.streamTimer.meetingStartsIn")} {days} {t("webinars.streamTimer.days")}
    </Text>
  ));

  const renderTimer = () => (
    <>
      {renderHeader(!isCurrentUserPresenter)}
      {renderHours(days === 0 && !isTimeOver)}
      {renderOneDayLeft(days === 1)}
      {renderDays(days > 1)}
    </>
  );

  const renderEndingInfo = () => (
    <Header>
      {t("webinars.streamTimer.meetingEnded")}
      <Button color={ButtonColor.Primary} onClick={handleLeave}>
        {t("webinars.streamTimer.leave")}
      </Button>
    </Header>
  );

  const renderInfoHeader = renderWhenTrueOtherwise(
    () => <Header>{t("webinars.streamTimer.meetingStartsSoon")}</Header>,
    renderEndingInfo,
  );

  const renderContent = renderWhenTrueOtherwise(
    renderTimer,
    () => renderInfoHeader(!liveEnded),
  );

  const renderPlayInfo = renderWhenTrue(() => <PlayInfo fullScreen={fullScreen} />);

  return (
    <Container fullScreen={fullScreen}>
      {!!startTimestamp && renderContent(!isTimeOver && !liveEnded)}
      {renderPlayInfo(!liveEnded)}
    </Container>
  );
};

StreamTimer.propTypes = {
  fullScreen: PropTypes.bool,
  startTimestamp: PropTypes.number,
}
