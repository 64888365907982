import styled, { css } from "styled-components";
import { prop } from "ramda";

import { ListButton } from "../../../../components/listButton/listButton.component";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color, Media } from "../../../../theme";

export const Container = styled.div`
  margin-bottom: 36px;
  height: 240px;
  position: relative;

  ${Media.desktop`
    margin-bottom: 40px;
  `}
`;

export const TimelineBg = styled.div`
  height: 4px;
  background-color: ${Color.gray4};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Timeline = styled.div`
  height: 4px;
  width: 0;
  background-color: ${() => Color.brandBasic};

  ${styleWhenTrue(prop("$fired"), css`
    transition: width 7.9s linear;
    width: 100%;
  `)}
`;

const ListButtonStyles = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const PrevButton = styled(ListButton)`
  ${ListButtonStyles}
  left: -25px;
`;

export const NextButton = styled(ListButton)`
  ${ListButtonStyles}
  right: -25px;

  svg {
    transform: rotate(180deg);
  }
`;

export const Image = styled.img`
  height: 240px;
  width: 100%;
  object-fit: cover;

  ${styleWhenTrue(prop("$withLink"), css`
    cursor: pointer;
  `)}
`;

export const Dots = styled.div`
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;

  ${Media.desktop`
    display: none;
  `}
`;

export const Dot = styled.div`
  width: 6px;
  height: 6px;
  margin: 0 8px;
  background-color: ${Color.gray1};
  border-radius: 50%;

  ${styleWhenTrue(prop("$active"), css`
    background-color: ${() => Color.brandBasic};
  `)}
`;
