import React, { useState } from "react";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

import {
  Container,
  Button,
  Popup,
  Item,
  FastingIcon,
  CheckIcon,
} from "./speedOption.styled";
import { RateValues } from "./speedOption.constants";
import { createValueString } from "./speedOption.utils";

const SpeedOption = ({ className, currentSpeed, setSpeed }) => {
  const [open, setOpen] = useState(false);

  const onItemClick = (value) => {
    setSpeed(value);
    setOpen(false);
  };

  const onOutsideClick = () => {
    setOpen(false);
  };

  return (
    <Container className={className}>
      <Button onClick={() => setOpen(!open)}>
        <FastingIcon $open={open} />
      </Button>
      {open && (
        <ClickAwayListener onClickAway={onOutsideClick}>
          <Popup>
            {RateValues.map((value) => (
              <Item
                key={value}
                onClick={() => onItemClick(value)}
                $current={currentSpeed === value}
              >
                {currentSpeed === value && <CheckIcon />}
                {createValueString(value)}
              </Item>
            ))}
          </Popup>
        </ClickAwayListener>
      )}
    </Container>
  );
};

export default SpeedOption;
