import React from "react";
import PropTypes from "prop-types";

import { Container } from "./simpleProgressChart.styled";

export const SimpleProgressChart = ({
  className,
  value,
  maxValue,
  chartColor,
}) => (
  <Container
    className={className}
    value={value}
    maxValue={maxValue}
    chartColor={chartColor}
  />
);

SimpleProgressChart.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  maxValue: PropTypes.number,
  chartColor: PropTypes.string,
};
