import React from "react";
import PropTypes from "prop-types";

import {
  Container,
  AvatarBox,
  Avatar,
  TextBox,
  Name,
  Email,
} from "./trainerPopupHeader.styled";

export const TrainerPopupHeader = ({
  className,
  avatarUrl,
  name,
  email,
}) => {
  return (
    <Container className={className}>
      <AvatarBox>
        <Avatar src={avatarUrl} />
      </AvatarBox>
      <TextBox>
        <Name>{name}</Name>
        <Email href={`mailto:${email}`}>{email}</Email>
      </TextBox>
    </Container>
  );
};

TrainerPopupHeader.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  className: PropTypes.string,
};
