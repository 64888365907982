import styled from "styled-components";

import { Color, ContentMaxWidth, FontWeight, Media } from "../../theme";

export const Container = styled.div`
  background-color: ${Color.almostWhite};

  ${Media.tablet`
    padding: 0 80px;
  `}
`;

export const Wrapper = styled.div`
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
  padding: 60px 0;
`;

export const Header = styled.h4`
  color: ${Color.black};
  font-weight: ${FontWeight.Bold};
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 30px;
  margin-left: 20px;

  ${Media.tablet`
    margin-left: 0;
  `};
`;

export const EmptyMessage = styled.p`
  margin-left: 20px;

  ${Media.tablet`
    margin-left: 0;
    min-height: 300px;
  `}
`;
