import styled from "styled-components";

import { Button as ButtonBase } from "../components/button/button.component";
import { Color } from "../theme";

export const Container = styled.div`
  padding: 30px 0px;
`;

export const Button = styled(ButtonBase)`
  &:disabled {
    background-color: ${Color.almostWhite};
    color: ${Color.gray1};
  }

  & + & {
    margin-left: 20px;
  }
`;
