import styled from "styled-components";
import { prop } from "ramda";

import { FontWeight, Media, Color } from "../../../theme";

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 0 20px 20px;
  background-image: url(${prop("$background")});
  background-repeat: no-repeat;
  background-position: center 55%;
  background-size: 70%;
  background-color: ${Color.almostWhite};

  ${Media.tablet`
    padding: 20px 20px 150px;
    background-size: cover;
    min-height: 650px;
  `};

  ${Media.tablet`
    padding: 30px 40px 43px;
  `}
`;

export const Title = styled.div`
  font-size: 20px;
  margin: 30px 0;
  line-height: 28px;
  width: 100%;
  color: ${Color.deepGray};
  font-weight: ${FontWeight.Bold};

  ${Media.mobile`
    font-size: 28px;
    line-height: 48px;
    margin-bottom: 30px;
  `}

  ${Media.tablet`
    margin: 115px 0 30px;
  `}
`;

export const Link = styled.a`
  text-decoration: underline;
  color: ${Color.blue3};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  width: 100%;
  line-height: 42px;
  text-transform: uppercase;
  color: ${Color.blue2};
  padding-bottom: 7px;
  font-weight: ${FontWeight.Bold};
  border-bottom: 1px solid ${Color.gray3};
`;

export const LightHeaderText = styled.div`
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.gray3};
`;

export const Row = styled.div`
  display: flex;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  ${Media.tablet`
    flex: 10%;
    flex-direction: row;
    justify-content: flex-end;
  `}
`;

export const TextWrapper = styled.div`
  margin-top: 30px;

  ${Media.tablet`
    flex: 90%;
    display: flex;
    align-items: center;
  `}
`;
