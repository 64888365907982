import Chart from "chart.js/auto";

import { Color, FontWeight } from "../../../../theme";

export const createChartInstance = (yLabels, xLabels, xValues, canvasRef, animate) => {
  if (canvasRef.chart) {
    canvasRef.chart.destroy();
  }

  let rectangleSet = false;

  const newChartInstance = new Chart(canvasRef, {
    type: "line",
    data: {
      labels: xLabels,
      datasets: [
        {
          fill: false,
          borderColor: Color.yellow2,
          tension: 0,
          pointRadius: 7,
          pointBorderWidth: 3,
          pointBackgroundColor: Color.white,
          data: xValues,
        },
      ],
    },
    options: {
      aspectRatio: 3,
      plugins: {
        tooltip: {
          enabled: false,
        },
        legend: {
          display: false,
        },
        scales: {
          y: {
            grid: {
              display: false,
              drawBorder: false,
              tickLength: 40,
            },
            ticks: {
              beginAtZero: true,
              min: 0,
              max: 6,
              padding: 20,
              font: {
                weight: FontWeight.Bold,
              },
              callback: (value) => yLabels[value],
            },
          },
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: Color.gray9,
            },
          },
        },
      },
      interaction: {
        mode: null,
      },
      animation: {
        duration: animate ? 1000 : 1,
        onComplete: () => {
          if (!rectangleSet) {
            const scale = window.devicePixelRatio;
            const sourceCanvas = newChartInstance.canvas;
            const yAxisWidth = newChartInstance.scales.y.width - 40;
            const yAxisHeight = newChartInstance.scales.y.height + newChartInstance.scales.y.top + 30;
            const yAxisRef = document.getElementById("axis-Test");
            if (!yAxisRef) return;

            const yAxisContext = document.getElementById("axis-Test").getContext("2d");

            yAxisContext.scale(scale, scale);
            yAxisContext.canvas.width = yAxisWidth * scale;
            yAxisContext.canvas.height = yAxisHeight * scale;
            yAxisContext.canvas.style.width = `${yAxisWidth}px`;
            yAxisContext.canvas.style.height = `${yAxisHeight}px`;
            yAxisContext.drawImage(sourceCanvas, 0, 0);

            rectangleSet = true;
          }
        },
      },
    },
  });
  canvasRef.chart = newChartInstance;

  return newChartInstance;
};
