import React from "react";
import { useTranslation } from "react-i18next";

import {
  Container,
  Question,
  LightText,
  AnswerBox,
  AnswerBoxCircle,
  AnswerLabel,
  Percentage,
  ResultBackground,
  ResultBackgroundInner,
} from "./surveyQuestion.styled";

export const SurveyQuestion = ({ question, answers, setAnswer, answersCount, resultsView }) => {
  const { t } = useTranslation();
  const hasAnswerSelected = !!answers.find((answer) => answer.selected);

  const handleAnswerClick = (answerId) => {
    setAnswer(answerId);
  };

  return (
    <Container>
      <Question>{question}</Question>
      <LightText>{t("gamification.gamificationSurvey.singleAnswerText")}</LightText>
      {answers.map((answer, index) => (
        <AnswerBox
          key={index}
          selected={answer.selected}
          whiteTheme={hasAnswerSelected && !answer.selected}
          brandTheme={hasAnswerSelected && answer.selected}
          onClick={() => handleAnswerClick(answer.id)}
          disabled={resultsView}
        >
          <AnswerBoxCircle />
          <AnswerLabel>
            {resultsView ? answer.label : answer.answer}
          </AnswerLabel>
          {resultsView && (
            <>
              <Percentage>{(answer.answersCount / answersCount * 100).toFixed()}%</Percentage>
              <ResultBackground>
                <ResultBackgroundInner width={answer.answersCount / answersCount * 100} />
              </ResultBackground>
            </>
          )}
        </AnswerBox>
      ))}
    </Container>
  );
};
