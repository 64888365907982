import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { renderWhenTrue } from "../../../../../helpers/rendering";
import { isMobile } from "../../../../../theme";
import { NextButton } from "../nextButton/nextButton.component";

import { Option } from "./components/option/option.component";
import { OptionCounter } from "./components/optionCounter/optionCounter.component";
import { Header } from "./components/header/header.component";
import {
  Container,
  Content,
  ContentHeader,
  Options,
} from "./interactionMultiselect.styled";

export const InteractionMultiselect = ({
  className,
  title,
  subtitle,
  buttonText,
  options,
  answersIds,
  minAnswersLength,
  next,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { t } = useTranslation();
  const optionsEl = useRef(null);

  const isOptionSelected = (option) =>
    selectedOptions.find(({ id }) => id === option.id);

  const isOptionCorrect = (id) => answersIds.includes(id);

  const toggleOption = (option) => {
    setSelectedOptions(
      isOptionSelected(option)
        ? selectedOptions.filter(({ id }) => id !== option.id)
        : [...selectedOptions, option]
    );
  };

  const renderOptions = renderWhenTrue(() => {
    return options.map((option) => (
      <Option
        key={option.id}
        option={option}
        correctTheme={isOptionSelected(option) && isOptionCorrect(option.id)}
        wrongTheme={isOptionSelected(option) && !isOptionCorrect(option.id)}
        toggleOption={toggleOption}
      />
    ));
  });

  const renderNextButton = renderWhenTrue(() => (
    <NextButton
      text={buttonText}
      disabled={isButtonDisabled}
      onClick={nextInteraction}
    />
  ));

  const nextInteraction = () => {
    if (optionsEl.current) {
      optionsEl.current.scrollTop = 0;
    }
    setSelectedOptions([]);
    next();
  };

  const scrollUp = () => {
    if (optionsEl.current) {
      optionsEl.current.scrollTop -= 50;
    }
  };

  const scrollDown = () => {
    if (optionsEl.current) {
      optionsEl.current.scrollTop += 50;
    }
  };

  const isButtonDisabled =
    selectedOptions.length < minAnswersLength ||
    !selectedOptions.every(({ id }) => answersIds.includes(id));

  return (
    <Container className={className}>
      <Header title={title} subtitle={subtitle}>
        {renderNextButton(!isMobile())}
      </Header>
      <Content>
        <ContentHeader>
          {t("interactions.interactionMultiselect.select")}:
        </ContentHeader>
        <Options ref={optionsEl}>
          {renderOptions(!!options.length)}
          <OptionCounter
            availableOptionsLength={options.length}
            selectedOptionsLength={selectedOptions.length}
            scrollUp={scrollUp}
            scrollDown={scrollDown}
          />
        </Options>
      </Content>
      {renderNextButton(isMobile())}
    </Container>
  );
};

InteractionMultiselect.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  options: PropTypes.array,
  answersIds: PropTypes.array,
  minAnswersLength: PropTypes.number,
  next: PropTypes.func,
};
