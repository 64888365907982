import { StorageName } from "../../../../app/app.constants";
import { TrainingsViewStorageKey } from "../../../trainings/trainings.constants";
import {
  CommonPopulationsItemKey,
  StrategicPopulationsItemKey,
  TooltipOpenStorageKey,
} from "../../../teamIndicators/components/indicatorsTab/indicatorsTab.constants";
import { VideoSpeedStorageKey, VolumeStorageKey } from "../../../videoPlayer/components/playerPanel/playerPanel.constants";
import { KnowledgeBaseViewStorageKey } from "../../../knowledgeBase/knowledgeBase.constants";

export const AnalyticalCookies = ["_gid", "_ga"];

export const FunctionalCookies = (userId) => ([
  "Lng",
  "vwbfs-login-info",
  StorageName.OnboardingBoardView,
  TooltipOpenStorageKey,
  `${StrategicPopulationsItemKey}${userId}`,
  `${CommonPopulationsItemKey}${userId}`,
  VolumeStorageKey,
  VideoSpeedStorageKey,
  KnowledgeBaseViewStorageKey,
  TrainingsViewStorageKey,
]);
