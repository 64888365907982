import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { renderWhenTrueOtherwise } from "../../helpers/rendering";
import { Theme } from "../../appConfig";

import { Container, Initials, Image, HatIcon } from "./avatar.styled";
import { AvatarTheme } from "./avatar.constants";

export const Avatar = ({ className, type, src, name = "", dark = false }) => {
  const renderInitials = useCallback(() => (
    <Initials type={type} $dark={dark}>
      {name.split(" ").map((item) => item[0])}
    </Initials>
  ), [type, dark, name]);

  const renderImage = useCallback(() => {
    if (src) {
      return (
        <Image src={src} alt="avatar" />
      );
    }

    return <HatIcon $dark={dark.toString()} />;
  }, [src, dark]);

  const renderContent = renderWhenTrueOtherwise(
    renderImage,
    renderInitials,
  );

  return (
    <Container
      className={className}
      type={type}
      $dark={!src && dark}
    >
      {renderContent(!!src || Theme.Avatar === AvatarTheme.Image)}
    </Container>
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  name: PropTypes.string,
  dark: PropTypes.bool,
  type: PropTypes.string,
};