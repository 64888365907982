import React, { useEffect, useState } from "react";
import { sort, equals } from "ramda";

import { Loader } from "../../../components/loader/loader.component";
import { renderWhenTrueOtherwise } from "../../../helpers/rendering";
import { getData } from "../../helpers/api.deprecated";

import {
  Description,
  Quiz,
  DropdownQuiz,
  ArrowQuiz,
} from "./components";
import { Container } from "./sequentialQuizInteractions.styled";
import { QuizType, ViewType } from "./sequentialQuizInteractions.constants";

const SequentialQuizInteractions = ({ baseUrl, config, callComplete }) => {
  const [loading, setLoading] = useState(true);
  const [activeView, setActiveView] = useState(ViewType.Question);
  const [activeInteraction, setActiveInteraction] = useState(0);
  const [interactions, setInteractions] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const isInteractionArray = Array.isArray(interactions);

  useEffect(() => {
    if (baseUrl && config) {
      getData(`${baseUrl}${config}`, setInteractions, setLoading);
    }
  }, [baseUrl, config]);

  const goToQuestionView = () => {
    setActiveView(ViewType.Question);
  };

  const goToQuizView = () => {
    setActiveView(ViewType.Quiz);
  };

  const goToSummaryView = () => {
    setActiveView(ViewType.Summary);
  };

  const renderInteraction = ({
    introText,
    question,
    answers,
    correctAnswersId,
    summaryText,
    questionNumber,
    multiselectType,
    complianceTheme,
    type,
    options,
    subquestion,
    yAxis,
    columns,
  }) => {
    const isAnswerCorrect = () => {
      if (type === QuizType.Dropdown || type === QuizType.SelectWithArrowsQuiz) return true;
      const diff = (a, b) => a - b;
      return equals(sort(diff, selectedAnswers), sort(diff, correctAnswersId));
    };

    const handleQuizButtonClick = () => {
      if (!summaryText) {
        handleSummaryButtonClick();
      } else {
        setActiveView(ViewType.Summary);
      }
    };

    const handleSummaryButtonClick = () => {
      setSelectedAnswers([]);
      if (isAnswerCorrect()) {
        if (isInteractionArray) {
          const lastQuizInteraction = activeInteraction === interactions.length - 1;
          if (lastQuizInteraction) {
            callComplete();
          } else {
            setActiveInteraction(activeInteraction + 1);
          }
        } else {
          callComplete();
        }
      } else {
        goToQuestionView();
      }
    };

    switch (activeView) {
    case ViewType.Question:
      if (selectedAnswers.length) {
        setSelectedAnswers([]);
      }
      if (!introText) {
        goToQuizView();
      }
      return (
        <Description
          text={introText}
          questionNumber={questionNumber}
          onNext={goToQuizView}
          complianceTheme={complianceTheme}
        />
      );
    case ViewType.Quiz:
      if (type === QuizType.SelectWithArrowsQuiz) {
        return (
          <ArrowQuiz
            questionNumber={questionNumber}
            question={question}
            subquestion={subquestion}
            yAxis={yAxis}
            columns={columns}
            onNext={goToSummaryView}
          />
        );
      }
      if (type === QuizType.Dropdown) {
        return (
          <DropdownQuiz
            title={question}
            options={options}
            answers={answers}
            selectedAnswers={selectedAnswers}
            setSelectedAnswers={setSelectedAnswers}
            restartQuestion={goToQuestionView}
            onNext={goToSummaryView}
            questionNumber={questionNumber}
          />
        );
      }
      return (
        <Quiz
          question={question}
          subquestion={subquestion}
          answers={answers}
          selectedAnswers={selectedAnswers}
          setSelectedAnswers={setSelectedAnswers}
          questionNumber={questionNumber}
          multiselectType={multiselectType}
          correctAnswersLength={correctAnswersId.length}
          onNext={handleQuizButtonClick}
          restartQuestion={goToQuestionView}
          complianceTheme={complianceTheme}
          isAnswerCorrect={isAnswerCorrect()}
          correctAnswersId={correctAnswersId}
        />
      );
    case ViewType.Summary:
      return (
        <Description
          text={summaryText}
          questionNumber={questionNumber}
          onNext={handleSummaryButtonClick}
          correctTheme={isAnswerCorrect()}
          wrongTheme={selectedAnswers && !isAnswerCorrect()}
          complianceTheme={complianceTheme}
        />
      );
    default:
      return <div />;
    }
  };

  const interaction = interactions
    ? isInteractionArray
      ? interactions[activeInteraction]
      : interactions
    : null;

  return renderWhenTrueOtherwise(
    () => <Loader />,
    () => (
      <Container>
        {renderInteraction(interaction)}
      </Container>
    )
  )(loading || !interaction);
};

export default SequentialQuizInteractions;
