import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 500px;
  margin: 50px auto;
  position: relative;

  @media screen and (max-width: 1024px) {
    padding: 0 25px;
  }

  & .scale_show {
    animation: 0.3s scale 0.4s forwards;
    opacity: 0;
  }

  & h3 {
    text-align: center;
  }

  & .headline-download {
    color: #003263;
    font-size: 25px;
    text-align: center;
    margin: 0;
    line-height: 29px;
    font-weight: 700;
  }

  & #demo {
    visibility: hidden;
    text-align: center;
  }

  & .box {
    width: 200px;
    height: 150px;
    position: relative;
    border-radius: 6px;
    margin: 20px;
    display: inline-block;
    line-height: 150px;
    text-align: center;
    color: #333;
    font-size: 30px;
  }

  & .green {
    background-color: #6fb936;
  }

  & .hover-download {
    transition: transform 0.3s ease-in-out;
  }

  & .hover-download:hover {
    cursor: pointer;
    transform: scale(1.3);
  }

  & .margin-top-max {
    margin-top: 240px;
  }

  & .margin-top-max2 {
    margin-top: 250px;
  }

  @keyframes show-item {
    0% {
      opacity: 0;
      transform: translateY(40px);
    }

    25% {
      opacity: 0.25;
    }

    50% {
      opacity: 0.5;
    }

    75% {
      opacity: 0.75;
    }

    100% {
      opacity: 1;
      transform: translateY(0px);
      transform: scale(1);
    }
  }

  @keyframes scale {
    0% {
      opacity: 0;
      transform: scale(0.2);
    }
    25% {
      opacity: 0.25;
      transform: scale(0.7);
    }
    50% {
      opacity: 0.5;
      transform: scale(1);
    }
    75% {
      opacity: 0.75;
      transform: scale(1.2);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes just_show {
    0% {
      opacity: 0;
    }

    25% {
      opacity: 0.25;
    }

    50% {
      opacity: 0.5;
    }

    75% {
      opacity: 0.75;
    }

    100% {
      opacity: 1;
    }
  }

  & .hover-icon {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }

  & .hover-icon:hover {
    cursor: pointer;
    transform: scale(1.3);
    -webkit-box-shadow: 3px 4px 35px 2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 3px 4px 35px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 3px 4px 35px 2px rgba(0, 0, 0, 0.75);
  }

  & .show1 {
    animation: 0.1s show-item 0.4s forwards;
    opacity: 0;
  }

  & .show2 {
    animation: 0.3s show-item 0.4s forwards;
    opacity: 0;
  }

  & .show3 {
    animation: 0.5s show-item 0.4s forwards;
    opacity: 0;
  }

  & .show4 {
    animation: 0.7s show-item 0.4s forwards;
    opacity: 0;
  }

  & .margin-right {
    margin-right: 36px;
  }

  @keyframes show-item {
    0% {
      opacity: 0;
      transform: translateY(40px);
    }

    25% {
      opacity: 0.25;
    }

    50% {
      opacity: 0.5;
    }

    75% {
      opacity: 0.75;
    }

    100% {
      opacity: 1;
      transform: translateY(0px);
      transform: scale(1);
    }
  }

  & button {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  & .col {
    left: calc(50% - 78px) !important;
  }

  @media screen and (max-width: 1024px) {
    & button {
      right: 25px;
    }
  }
`;
