import styled, { css } from "styled-components";
import { prop } from "ramda";

import { ReactComponent as CameraIconSvg } from "../../images/camera.svg";
import { styleWhenTrue } from "../../helpers/rendering";
import { Color } from "../../theme/colors";
import { FontWeight } from "../../theme/fonts";
import { Button } from "../button/button.component";

export const DropzoneContainer = styled.div`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23${Color.gray7.substring(1)}FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  flex-direction: column;
  padding: 57px 0 31px;
  align-items: center;
  cursor: pointer;

  ${styleWhenTrue(prop("errorMode"), css`
    background-color: ${Color.veryLightPink2};
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23${() => Color.brandBasic.substring(1)}FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  `)}
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const IconBackground = styled.div`
  width: 124px;
  height: 124px;
  border: 1px solid ${Color.gray8};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.white};
`;

export const Title = styled.p`
  margin-top: 15px;
  color: ${() => Color.brandBasic};
  font-size: 16px;
  line-height: 22px;
  font-weight: ${FontWeight.SemiBold};
`;

export const Subtitle = styled.p`
  margin-top: 3px;
  font-size: 12px;
  line-height: 22px;
  color: ${Color.gray4};

  ${styleWhenTrue(prop("errorMode"), css`
    color: ${() => Color.brandBasic}
  `)}
`;

export const RemoveButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: ${Color.white};
  color: ${Color.deepGray};

  &:hover {
    color: ${Color.white};
  }
`;

export const ErrorMessage = styled.p`
  visibility: hidden;
  color: ${() => Color.brandBasic};
  font-size: 10px;
  line-height: 22px;
  margin-top: 5px;

  ${styleWhenTrue(prop("errorMode"), css`
    visibility: visible;
  `)}
`;

export const CameraIcon = styled(CameraIconSvg)`
  g {
    fill: ${() => Color.brandBasic};
  }
`;