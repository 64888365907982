import { createActions, createReducer } from "reduxsauce";

export const { Types: CertificatesTypes, Creators: CertificatesActions } = createActions(
  {
    setIsPending: ["isPending"],
    fetchLessons: null,
    fetchLessonsSuccess: ["lessons"],
    fetchCertificatePaths: null,
    fetchCertificatePathsSuccess: ["certificatePaths"],
    fetchSurveys: null,
    fetchSurveysSuccess: ["surveys"],
    fetchPathConfirmations: null,
    fetchPathConfirmationsSuccess: ["pathConfirmations"],
    fetchIndividuals: null,
    fetchIndividualsSuccess: ["individuals"],
    fetchEvents: null,
    fetchEventsSuccess: ["events"],
    fetchStatistics: null,
    fetchStatisticsSuccess: ["statistics"],
    fetchRanking: null,
    fetchRankingSuccess: ["ranking"],
    fetchCertificate: ["id"],
    setCertificate: ["data"],
  },
  { prefix: "CERTIFICATES/" }
);

const INITIAL_STATE = {
  isPending: true,
  lessons: [],
  surveys: [],
  inProgressPaths: [],
  finishedPaths: [],
  pathConfirmations: [],
  individuals: [],
  events: [],
  statistics: null,
  ranking: [],
  certificate: null,
};

const setIsPending = (state, { isPending }) => ({ ...state, isPending });

const fetchStatisticsSuccess = (state, { statistics }) => ({
  ...state,
  statistics,
});

const fetchRankingSuccess = (state, { ranking }) => ({
  ...state,
  ranking,
});

const fetchLessonsSuccess = (state, { lessons }) => ({
  ...state,
  lessons,
});

const fetchCertificatePathsSuccess = (state, { certificatePaths }) => ({
  ...state,
  inProgressPaths: certificatePaths.inProgress,
  finishedPaths: certificatePaths.finished,
});

const fetchSurveysSuccess = (state, { surveys }) => ({
  ...state,
  surveys,
});

const fetchPathConfirmationsSuccess = (state, { pathConfirmations }) => ({
  ...state,
  pathConfirmations,
});

const fetchIndividualsSuccess = (state, { individuals }) => ({
  ...state,
  individuals,
});

const fetchEventsSuccess = (state, { events }) => ({
  ...state,
  events,
});

const setCertificate = (state, { data }) => ({
  ...state,
  certificate: data,
});

export const reducer = createReducer(INITIAL_STATE, {
  [CertificatesTypes.SET_IS_PENDING]: setIsPending,
  [CertificatesTypes.FETCH_STATISTICS_SUCCESS]: fetchStatisticsSuccess,
  [CertificatesTypes.FETCH_RANKING_SUCCESS]: fetchRankingSuccess,
  [CertificatesTypes.FETCH_LESSONS_SUCCESS]: fetchLessonsSuccess,
  [CertificatesTypes.FETCH_CERTIFICATE_PATHS_SUCCESS]: fetchCertificatePathsSuccess,
  [CertificatesTypes.FETCH_SURVEYS_SUCCESS]: fetchSurveysSuccess,
  [CertificatesTypes.FETCH_PATH_CONFIRMATIONS_SUCCESS]: fetchPathConfirmationsSuccess,
  [CertificatesTypes.FETCH_INDIVIDUALS_SUCCESS]: fetchIndividualsSuccess,
  [CertificatesTypes.FETCH_EVENTS_SUCCESS]: fetchEventsSuccess,
  [CertificatesTypes.SET_CERTIFICATE]: setCertificate,
});
