import { EntryType } from "../../../components/articleForm/articleForm.constants";
import { nodeApi, handleApiError } from "../../../api";

export const getNewsList = (nextPage = "") => {
  const url = nextPage || "/news";
  return nodeApi.get(url).catch(handleApiError);
};

export const getLatestArticles = (limit = 3) => {
  return nodeApi.get("/news", {
    type: EntryType.Article,
    params: {
      limit,
      type: EntryType.Article,
    },
  }).catch(handleApiError);
};

export const getEntry = (id) => {
  return nodeApi.get(`/news/${id}`).catch(() => {
    return { data: null };
  });
};

export const deleteEntry = (id) => {
  return nodeApi.delete(`/news/${id}`);
};

export const createPost = (post) => {
  return nodeApi.post("/news/post", post);
};

export const editPost = (post) => {
  return nodeApi.put(`/news/post/${post.id}`, post);
};

const createArticleFormData = (article) => {
  const formData = new FormData();
  for (let prop in article) {
    if (Object.prototype.hasOwnProperty.call(article, prop) && article[prop]) {
      if (prop === "attachments") {
        for (const attachment of article[prop]) {
          formData.append(prop, attachment);
        }
      } else {
        formData.append(prop, article[prop]);
      }
    }
  }
  return formData;
};

export const createArticle = (article) => {
  const formData = createArticleFormData(article);
  return nodeApi.post("/news/article", formData);
};

export const editArticle = (id, article) => {
  const formData = createArticleFormData(article);
  return nodeApi.put(`news/article/${id}`, formData);
};

export const createComment = (comment, isGamification) => {
  return nodeApi.post(`${isGamification ? "/gamification" : ""}/news/comments/${comment.entryId}`, comment);
};

export const editComment = ({ id, content }) => {
  return nodeApi.put(`/news/comments/${id}`, { content });
};

export const deleteComment = (id) => {
  return nodeApi.delete(`/news/comments/${id}`);
};

export const likeEntry = (id, likeState, isGamification) => {
  return nodeApi.post(`${isGamification ? "/gamification" : ""}/news/likes/${id}/${likeState}`);
};

export const getLatestActivities = () => {
  return nodeApi.get("news/activity").catch(handleApiError);
};
