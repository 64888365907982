import { createActions, createReducer } from "reduxsauce";

export const { Types: TrainingTypes, Creators: TrainingActions } = createActions(
  {
    noop: null, // TODO: remove this action after creating first real action
  },
  { prefix: "TRAINING/" }
);

const INITIAL_STATE = {};

export const reducer = createReducer(INITIAL_STATE, {});
