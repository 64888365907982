import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Media } from "../../../../../theme/media";
import { Color } from "../../../../../theme/colors";
import { styleWhenTrue } from "../../../../../helpers/rendering";
import { ReactComponent as CloseBase } from "../../../../../images/close.svg";
import { ReactComponent as CheckBase } from "../../../../../images/check.svg";

export const CloseIcon = styled(CloseBase)`
  width: 16px;
  height: 16px;
  * {
    stroke: ${Color.deepPurple};
  }
`;

export const CheckIcon = styled(CheckBase)`
  width: 100%;
  fill: ${Color.green};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 600px;

  ${Media.mobile`
    height: 600px;
  `}
`;

export const TitleAndAnswersWrapper = styled.div`
  flex-direction: column;
  width: 100%;

  ${Media.tablet`
    flex: 90%;
    display: flex;
    align-items: flex-start;
  `}
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border: 1px solid ${Color.gray5};
  border-radius: 50%;
  margin-right: 17px;

  ${styleWhenTrue(prop("correctTheme"), css`
    border-color: ${Color.green};
    background-color: ${Color.lightGreen};
  `)}

  ${styleWhenTrue(prop("wrongTheme"), css`
    border-color: ${Color.deepPurple};
    background-color: ${Color.pink2};
  `)}

  ${Media.tablet`
    width: 33px;
    height: 33px;
  `}
`;

export const BorderedTile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: ${Color.deepGray};
  margin-bottom: 7px;
  font-size: 17px;
  width: 100%;
  padding: 35px 20px;
  border: 1px solid ${Color.gray5};
  line-height: 22px;

  ${styleWhenTrue(prop("correctTheme"), css`
    border: 2px solid ${Color.green};
    color: ${Color.green};
    margin-bottom: 5px;
  `)}

  ${styleWhenTrue(prop("wrongTheme"), css`
    border: 2px solid ${Color.deepPurple};
    color: ${Color.deepPurple};
    margin-bottom: 5px;
  `)}

  ${Media.mobile`
    padding: 20px;
  `}
`;
