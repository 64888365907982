import styled from "styled-components";

import { Color, Media } from "../../../../../theme";
import { ReactComponent as HourglassSvg } from "../../../images/hourglass2.svg";

export const Container = styled.div`
  background-color: ${Color.gray8};
  padding: 44px 20px;
  width: 100%;
  display: flex;
  align-items: center;

  ${Media.tablet`
    padding: 44px 40px;
  `}
`;

export const TimeIcon = styled(HourglassSvg)`
  margin-right: 16px;
  height: 16px;
  width: 10px;
  fill: ${() => Color.brandBasic};
  flex-shrink: 0;
`;

export const Text = styled.p`
  font-size: 13px;
  line-height: 19px;
  color: ${Color.deepGray};
  margin-right: 20px;
`;

export const Time = styled.div`
  color: ${Color.deepGray};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 1px;
  flex-shrink: 0;
  margin-left: auto;

  ${Media.tablet`
    justify-content: flex-start;
  `}
`;

export const TimeColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TimeValue = styled.h1``;

export const TimeName = styled.h2`
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0;
`;

export const Colon = styled.div`
  margin: 0 8px;
  align-self: flex-start;
`;
