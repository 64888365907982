/* eslint-disable react/no-string-refs */
import React from "react";
import i18n from "i18next";

import TaskScreen from "./taskScreen.component";
import ResultScreen from "./resultScreen.component";
import { Container } from "./checkBoxes.styled";

const TASK = "task";
const SUCCESS = "success";
const FAILURE = "failure";

const FAILURE_MESSAGES = [
  "SPRÓBUJ JESZCZE RAZ!", "NIE, TO NIE TAK", "ZNOWU POMYŁKA",
  "PRZYŁÓŻ SIĘ BARDZIEJ", ["NIE WIESZ, SPRAWDŹ", "W MATERIAŁACH SZKOLENIOWYCH"]
];


export default class CheckBoxes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: TASK,
      failureMessages: FAILURE_MESSAGES,
      loading: true,
      elements: [],
      config: "11.json"
    };
  }

  componentDidMount() {
    this.attempts = null;
    if (this.state.config) {
      fetch(this.props.baseUrl + this.state.config)
        .then(data => data.json())
        .then(data => this.setState({ ...data },()=>{this.setState({loading: false})}));

    }
  }

  onFailure() {
    const { failureMessages: [message, ...rest] } = this.state;

    this.setState({
      screen: FAILURE,
      failureMessage: message,
      failureMessages: rest.length > 0 ? rest : [message]
    });
  }

  render() {
    return (
      <Container ref="putInOrder">
        { this.state.loading ? i18n.t("lesson.slide.slide.loading") : this.renderContent() }
      </Container>
    );
  }

  renderContent() {
    const { screen } = this.state;
    switch (screen) {
    case TASK:
      return this.renderTask();
    case SUCCESS:
      return this.renderSuccess();
    case FAILURE:
      return this.renderFailure();
    default:
      return this.renderFailure();
    }
  }

  renderTask() {
    return (
      <TaskScreen onSuccess={ screenChanger(this, SUCCESS) }
        onFailure={ () => this.onFailure() }
        content={this.state.elements}
        header={this.state.header}
        instructions={this.state.instructions}
      />
    );
  }

  renderSuccess() {
    return (
      <ResultScreen message="SUPER!!!" action="Dalej" onAction={ () => passAndProceed(this) } />
    );
  }

  renderFailure() {
    const { failureMessage } = this.state;
    return (
      <ResultScreen message={ failureMessage } action="Wróć" onAction={ screenChanger(this, TASK) } />
    );
  }


  componentDidUpdate(){
    // fixme: zamienić timeout na coś przyjaźniejszego
    setTimeout(function(){
      if(this.refs.putInOrder !== undefined && this.refs.putInOrder) {
        this.showScreenElements(this.refs.putInOrder);
      }
    }.bind(this), 1);
  }

  showScreenElements(node){
    const elements = node.querySelectorAll(".hidden");
    for(let i = 0; i < elements.length; i++){
      let element = elements[i];
      if(!element.classList.contains("updated")){
        // ustawienie większego opóźnienia dla kolejnych elementów
        element.style.transitionDelay = "0." + i + "s";
        element.classList.remove("hidden");
        element.classList.add("updated");
        // na koniec animacji wyzerować czas dla transition
        element.addEventListener("transitionend", this.clearTransition.bind(this))
      }
    }
  }

  clearTransition(event){
    if(event.propertyName === "transform"){
      event.target.classList.add("ready");
    }
  }
}

function screenChanger(self, screen) {
  return () => self.setState({ screen });
}

function passAndProceed(self) {
  const { callComplete, callProceed } = self.props;

  callComplete();
  return installFinishedHook(self)
    .then(callProceed);
}

function installFinishedHook(self) {
  if (self.state.activityFinished) {
    return Promise.resolve();
  }
  let promiseResolve;
  self.onFinishedChanged = () => promiseResolve();
  return new Promise((resolve) => promiseResolve = resolve);
}
