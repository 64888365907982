import { createSelector } from "reselect";
import { prop } from "ramda";

export const selectKnowledgeBaseDomain = prop("knowledgeBase");

export const selectIsInitialDataPending = createSelector(
  selectKnowledgeBaseDomain, prop("isPending"),
);

export const selectThumbnailLists = createSelector(
  selectKnowledgeBaseDomain, prop("lists"),
);

export const selectCompetences = createSelector(
  selectKnowledgeBaseDomain, prop("competences"),
);

export const selectCompetenceFilters = createSelector(
  selectKnowledgeBaseDomain, prop("competenceFilters"),
);

export const selectTags = createSelector(
  selectKnowledgeBaseDomain, prop("tags"),
);
