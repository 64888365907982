import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import { selectEntryDetails, selectIsEntryPending } from "../../redux/news.selectors";
import { NewsActions } from "../../redux/news.reducer";
import { ReactComponent as LockedFileImg } from "../../images/lockedFile.svg";
import { Entry } from "../entry/entry.component";
import {
  EmptyStateContainer,
  HeaderText,
  Text,
  Content,
  BackButton,
  ButtonWrapper,
} from "./entryDetails.styled";

export const EntryDetails = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const isEntryPending = useSelector(selectIsEntryPending);
  const entryDetails = useSelector(selectEntryDetails);

  useEffect(() => {
    dispatch(NewsActions.getEntry(params.id));
  }, [dispatch, params.id]);

  const onBackButtonClick = useCallback(() => {
    navigate("/news");
  }, [navigate]);

  if (isEntryPending) {
    return null;
  }

  if (!entryDetails) {
    return (
      <EmptyStateContainer>
        <Content>
          <LockedFileImg />
          <HeaderText>{t("news.entryDetails.noAccess")}</HeaderText>
          <Text>{t("news.entryDetails.checkOther")}</Text>
        </Content>
        <ButtonWrapper>
          <BackButton onClick={onBackButtonClick}>
            {t("news.entryDetails.back")}
          </BackButton>
        </ButtonWrapper>
      </EmptyStateContainer>
    );
  }

  return (
    <Entry
      className={className}
      key={entryDetails.id}
      id={entryDetails.id}
      type={entryDetails.type}
      publisher={entryDetails.publisher}
      permissions={entryDetails.permissions}
      title={entryDetails.title}
      timestamp={entryDetails.timestamp}
      content={entryDetails.content}
      likeCounter={entryDetails.likeCounter}
      comments={entryDetails.comments}
      image={entryDetails.image}
      imagePositionX={entryDetails.imagePosition?.x}
      imagePositionY={entryDetails.imagePosition?.y}
      attachments={entryDetails.attachments}
      liked={entryDetails.liked}
      commentsVisible
    />
  );
};

EntryDetails.propTypes = {
  className: PropTypes.string,
};
