import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as ArrowImg } from "../../images/arrow.svg";

import { Container } from "./listButton.styled";

export const ListButton = ({ className, onClick }) => (
  <Container className={className} onClick={onClick}>
    <ArrowImg />
  </Container>
);

ListButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
