import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { simpleDateFromTimestamp } from "../../../../helpers/date";
import { ReactComponent as CalendarIcon } from "../../../../images/calendar.svg";
import { ReactComponent as PinIcon } from "../../../../images/pin.svg";

import {
  Container,
  DateInfo,
  Title,
  Infos,
  Info,
} from "./upcomingEvent.styled";

export const UpcomingEvent = (props) => {
  const { t } = useTranslation();
  const {
    className,
    id,
    timestamp,
    place,
    title,
    seatsAvailable,
  } = props;
  const navigate = useNavigate();
  const date = useMemo(() => simpleDateFromTimestamp(timestamp), [timestamp]);

  const handleOnClick = useCallback(() => {
    navigate(`/szkolenia/${id}`);
  }, [navigate, id]);

  return (
    <Container className={className} onClick={handleOnClick}>
      <DateInfo>{date}</DateInfo>
      <Title>{title}</Title>
      <Infos>
        <Info>
          <CalendarIcon />
          {seatsAvailable} {t("dashboard.upcomingEvents.seatsAvailable")}
        </Info>
        <Info hidden={!place}>
          <PinIcon />
          {place}
        </Info>
      </Infos>
    </Container>
  );
};

UpcomingEvent.propTypes = {
  className: PropTypes.string,
};
