import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import {
  BoxTitle,
  BoxTextSmall,
  BoxInner,
  Icon,
  InlineBox,
  ProgressCircle,
  RequiredOption,
  Badge,
} from "../../../../UIElements";
import { Color } from "../../../../theme";

export const PathGeneralItem = ({ props, type }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <InlineBox
      $background={props.passed ? "veryLightGreen" : "inherit"}
      padding="30px"
      hover={props.isEnabled}
      borderBottom="dirtyWhite"
      width="100%"
      onClick={() => (
        props.isEnabled
          ? type === "2"
            ? navigate("/szkolenia")
            : type === "5"
              ? props.id
                ? navigate(`/certyfikaty/${props.id}`)
                : null
              : type === "6"
                ? props.userSurveyId
                  ? navigate(`/formularze/${props.userSurveyId}`)
                  : null
                : null
          : null
      )}
    >
      {type === "6" && props.award && (
        <Badge
          name={props.award}
          size={45}
          marginRight={10}>
        </Badge>
      )}
      {type === "6" && !props.award && (
        <ProgressCircle
          radius={25}
          color="brandBasic"
          percent={props.result ? (props.result * 100).toFixed(0) : 0}
        />
      )}
      {type === "2" && (
        <Icon icon="menu_trainings" color={props.passed ? Color.green : Color.black} size={50} />
      )}
      {type === "5" && (
        <Icon icon="menu_certification" color={props.passed ? Color.green : Color.black} size={50} />
      )}
      <BoxInner paddingLeft={29}>
        <BoxTitle fontWeight={400} paddingBottom={8}>
          {props.name}
          {props.required && (
            <RequiredOption> ({t("certificates.certificate.learning.requiredToPass")})</RequiredOption>
          )}
        </BoxTitle>
        <BoxTextSmall color={props.passed ? "green" : "gray4"}>
          {props.description}
        </BoxTextSmall>
      </BoxInner>
    </InlineBox>
  );
};
