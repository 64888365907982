import * as Yup from "yup";

import i18n from "../../../../translations/i18n";

export const FormSchema = Yup.object().shape({
  supervisorName: Yup.string().required(i18n.t("validationMessage.required")),
  supervisorSurname: Yup.string().required(i18n.t("validationMessage.required")),
  supervisorEmail: Yup.string()
    .email(i18n.t("validationMessage.email"))
    .required(i18n.t("validationMessage.required")),
  email: Yup.string()
    .email(i18n.t("validationMessage.email"))
    .required(i18n.t("validationMessage.required")),
  phone: Yup.string()
    .matches(/^\d{9}$/, i18n.t("validationMessage.phone"))
    .required(i18n.t("validationMessage.required")),
});
