export const Heroes = [{
  title: "onboarding.hero.steven",
  description: "onboarding.hero.stevenDescription",
  description2: "onboarding.hero.stevenDescription2",
  animation: import("../../animations/steven.json"),
  name: "hero_1",
}, {
  title: "onboarding.hero.george",
  description: "onboarding.hero.georgeDescription",
  description2: "onboarding.hero.georgeDescription2",
  animation: import("../../animations/george.json"),
  name: "hero_2",
}, {
  title: "onboarding.hero.kate",
  description: "onboarding.hero.kateDescription",
  description2: "onboarding.hero.kateDescription2",
  animation: import("../../animations/kate.json"),
  name: "hero_3",
}, {
  title: "onboarding.hero.doris",
  description: "onboarding.hero.dorisDescription",
  description2: "onboarding.hero.dorisDescription2",
  animation: import("../../animations/doris.json"),
  name: "hero_4",
}];
