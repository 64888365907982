import { createSelector } from "reselect";
import { prop } from "ramda";

export const selectNewsDomain = prop("news");

export const selectNewsEntries = createSelector(
  selectNewsDomain,
  prop("entries"),
);

export const selectNewsNextPage = createSelector(
  selectNewsDomain,
  prop("nextPage"),
);

export const selectNewsLatestArticles = createSelector(
  selectNewsDomain,
  prop("latestArticles"),
);

export const selectEntryDetails = createSelector(
  selectNewsDomain,
  prop("entryDetails"),
);

export const selectNewsLatestActivities = createSelector(
  selectNewsDomain,
  prop("latestActivities"),
);

export const selectAreActivitiesPending = createSelector(
  selectNewsDomain,
  prop("areActivitiesPending"),
);

export const selectIsSavePending = createSelector(
  selectNewsDomain,
  prop("isSavePending"),
);

export const selectAreEntriesPending = createSelector(
  selectNewsDomain,
  prop("areEntriesPending"),
);

export const selectAreLatestPending = createSelector(
  selectNewsDomain,
  prop("areLatestPending"),
);

export const selectIsEntryPending = createSelector(
  selectNewsDomain,
  prop("isEntryPending"),
);
