import styled from "styled-components";

import { Button as ButtonBase } from "../../../../components/button/button.component";
import { Color } from "../../../../theme";
import { ReactComponent as ClockImage } from "../../images/webinar-clock.svg";
import { PlayInfo as PlayInfoBase } from "../playInfo/playInfo.component";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  background-color: ${Color.deepGray};
  position: absolute;
  top: 0;
  left: 0;
  right: 320px;
  bottom: 60px;
  z-index: 1;
`;

export const Header = styled.div`
  font-size: 20px;
  margin: 30px 20px 20px;
  color: ${Color.white};
  text-align: center;
  line-height: 25px;
`;

export const Text = styled.div`
  color: ${Color.gray4};
  margin-bottom: 4px;
`;

export const PlayInfo = styled(PlayInfoBase)`
  position: absolute;
  left: 30px;
  bottom: 20px;
`;

export const Clock = styled(ClockImage)`
  fill: ${() => Color.brandBasic};
`;

export const Button = styled(ButtonBase)`
  display: block;
  margin: 20px auto 0;
`;
