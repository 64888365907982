import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { selectCompetenceItemsRouteAvailable } from "../../../../userContext/redux/userContext.selectors";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { Widget, hasWidget, WidgetType } from "../../../";

import { CareerHistory } from "./careerHistory/careerHistory.component";
import { Container } from "./profileDetails.styled";

export const ProfileDetails = ({ className }) => {
  const competenceRouteAvailable = useSelector(selectCompetenceItemsRouteAvailable);

  const renderCompetences = renderWhenTrue(() => (
    <Widget.ProfileCompetenceList />
  ));

  return (
    <Container className={className}>
      <CareerHistory />
      {renderCompetences(hasWidget(WidgetType.ProfileCompetenceList) && competenceRouteAvailable)}
    </Container>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
};
