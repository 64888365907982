import React from "react";

export const OfflineImg = () =>
  <svg width="299px" height="270px" viewBox="0 0 299 270" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    <defs>
      <polygon id="path-1" points="0.203581388 0.2194238 216.727967 0.2194238 216.727967 65.1504425 0.203581388 65.1504425"></polygon>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop_Connection_Error" transform="translate(-750.000000, -619.000000)">
        <g id="Group-2" transform="translate(750.001045, 619.001006)">
          <path d="M66.5403948,8.10165436 C66.6531948,8.10884905 66.6171948,10.1905128 65.6667948,13.3969464 C64.7355948,16.5841942 62.7195948,20.8746278 59.2443948,24.6566367 C55.7691948,28.4410437 51.6651948,30.820088 48.5667948,32.019203 C45.4539948,33.2399021 43.3803948,33.4557429 43.3611948,33.343026 C43.3179948,33.1703535 45.3123948,32.6955039 48.2691948,31.330911 C51.2211948,29.9831057 55.1115948,27.5896721 58.4619948,23.9395659 C61.8099948,20.2918579 63.8643948,16.2148667 64.9539948,13.1595216 C66.0603948,10.0969818 66.3651948,8.07047737 66.5403948,8.10165436" id="Fill-1" fill="#0A2A41"></path>
          <path d="M242.445755,264.990948 L241.660955,246.227196 L284.978555,246.227196 L289.368155,259.180037 C289.368155,259.180037 303.408155,266.760842 295.509755,266.53301 C287.608955,266.30278 242.445755,264.990948 242.445755,264.990948" id="Fill-3" fill="#003044"></path>
          <path d="M188.353355,132.303832 C188.353355,132.303832 196.484555,111.983629 198.289355,112.132319 C200.096555,112.278611 202.383755,117.852098 199.376555,124.279354 C199.376555,124.279354 220.885355,113.74393 221.828555,117.353266 C222.769355,120.962602 206.550155,128.795222 206.550155,128.795222 C206.550155,128.795222 222.795755,122.499868 224.187755,125.610372 C225.582155,128.723275 211.254155,135.308815 211.254155,135.308815 C211.254155,135.308815 225.999755,129.466726 225.418955,132.147947 C224.842955,134.82677 215.454155,140.834337 215.454155,140.834337 C215.454155,140.834337 225.445355,136.112222 224.866955,138.793443 C224.288555,141.472266 206.924555,157.665115 206.924555,157.665115 C201.997355,161.147346 188.353355,132.303832 188.353355,132.303832" id="Fill-17" fill="#F6C199"></path>
          <path d="M108.784235,91.2622013 C108.784235,91.2622013 125.445035,164.92144 137.853035,159.309582 C165.119435,146.977883 188.353835,132.303113 188.353835,132.303113 L206.992235,157.971369 C206.992235,157.971369 169.029035,182.123945 159.443435,188.025989 C149.860235,193.928033 113.068235,215.303458 94.9290348,173.250493 C76.7274348,131.053635 74.2026348,100.787971 74.2026348,100.787971 L108.784235,91.2622013 Z" id="Fill-19" fill="#F6C199"></path>
          <path d="M78.2310348,213.522772 C78.2310348,213.522772 158.595035,116.56233 213.377435,137.585215 C268.157435,158.608099 298.476635,252.071923 298.476635,252.071923 L239.722235,257.599843 C239.722235,257.599843 213.079835,192.025038 196.027835,194.226613 C178.975835,196.42579 108.905435,259.983684 108.905435,259.983684 L78.2310348,213.522772 Z" id="Fill-21" fill="#006088"></path>
          <path d="M34.5447948,77.1279924 C34.5447948,77.1279924 11.7423948,86.807249 9.43359478,107.561532 L9.43359478,159.816568 L9.57039478,181.757975 C9.57039478,181.757975 96.4815948,206.922603 117.711995,185.705461 L113.888795,128.793063 C113.461595,113.854488 112.717595,92.0066119 101.552795,82.0635499 C99.3927948,80.1401694 97.3143948,79.1808774 97.3143948,79.1808774 C83.2167948,72.3818951 49.3623948,71.2115588 34.5447948,77.1279924" id="Fill-23" fill="#E5EFF3"></path>
          <path d="M103.180235,17.015396 C103.180235,17.015396 105.957035,67.7691393 89.9322348,68.5677499 L80.1738348,68.5845375 L80.1738348,75.7672367 C80.1738348,78.1942455 78.9306348,80.4437853 76.8666348,81.7196437 C72.4674348,84.4416349 64.5738348,87.8711039 58.7634348,82.2160774 C56.2170348,79.7387057 55.1106348,76.1341659 55.3866348,72.5967765 L57.5154348,45.5783163 C57.5154348,45.5783163 48.4818348,43.7604579 50.1930348,34.4313429 C52.3818348,22.5145375 63.7890348,30.5989712 63.7890348,30.5989712 L63.7890348,19.1042544 C63.7890348,19.1042544 64.4850348,12.1397942 79.4754348,12.4851393 L94.4658348,12.8352809 C94.4658348,12.8352809 103.146635,12.8352809 103.180235,17.015396" id="Fill-25" fill="#F6C199"></path>
          <path d="M93.4631148,36.3019576 C93.7583148,36.2971659 94.0535148,39.978449 94.1231148,44.5230951 C94.1255148,45.6142898 94.1279148,46.6527234 94.1303148,47.7319269 C94.1279148,48.722396 94.0007148,49.7488384 93.4799148,50.4731039 C92.9567148,51.1853783 92.2319148,51.4371924 91.7567148,51.4180066 C91.2671148,51.391626 91.0703148,51.1781836 91.1015148,51.1254225 C91.1303148,51.0486791 91.3631148,51.1014402 91.7135148,51.005511 C92.0567148,50.9143783 92.5463148,50.619396 92.8463148,50.067803 C93.4463148,48.9742101 93.0143148,46.7918207 93.0623148,44.5398827 C92.9903148,39.9952367 93.1703148,36.3067588 93.4631148,36.3019576" id="Fill-27" fill="#333333"></path>
          <path d="M82.6712748,36.7094216 C82.9640748,36.692634 83.2448748,37.505634 83.3000748,38.5248818 C83.3552748,39.5441296 83.1608748,40.3811119 82.8680748,40.3978995 C82.5752748,40.4146871 82.2944748,39.5992889 82.2392748,38.5800411 C82.1840748,37.5607933 82.3784748,36.723811 82.6712748,36.7094216" id="Fill-29" fill="#333333"></path>
          <path d="M99.0517548,36.4494535 C99.3445548,36.4326659 99.6253548,37.2480641 99.6805548,38.2673119 C99.7357548,39.2865597 99.5413548,40.123542 99.2485548,40.1403296 C98.9557548,40.154719 98.6749548,39.341719 98.6197548,38.3224712 C98.5645548,37.3032234 98.7589548,36.4662411 99.0517548,36.4494535" id="Fill-31" fill="#333333"></path>
          <path d="M80.1469548,68.396996 C76.8613548,67.5456243 73.8781548,65.5766774 71.7997548,62.8954561 C71.6845548,65.7013853 71.6653548,68.5576774 72.4309548,71.2604827 C73.1965548,73.963288 74.9293548,76.3807039 77.3437548,77.8172437 C78.1837548,78.3160756 79.5973548,78.9300225 79.5973548,78.9300225 C79.6573548,78.7309694 80.0629548,77.0090402 80.0941548,76.8051906 C80.5573548,73.8817482 80.4685548,71.7401287 80.3341548,68.5816597" id="Fill-33" fill="#E0A886"></path>
          <path d="M58.3688748,42.585565 C58.3280748,42.6719013 57.1808748,42.3121667 56.0600748,40.7533172 C55.5320748,40.0002729 55.0928748,38.7316092 55.5296748,37.4317685 C55.7360748,36.7986358 56.1224748,36.1559101 56.7656748,35.7098393 C57.4016748,35.2445827 58.3016748,35.1150783 59.0624748,35.3117331 C60.0824748,35.5683437 60.8312748,36.1391225 61.4864748,36.6139721 L60.7208748,37.2423083 C59.9240748,35.4580252 58.5824748,34.2756977 57.5552748,33.6233791 C56.5160748,32.9542729 55.7816748,32.7264411 55.8176748,32.5921402 C55.8344748,32.4890163 56.6456748,32.496211 57.8672748,33.0526004 C59.0672748,33.5969986 60.6752748,34.7985119 61.6520748,36.8154234 L62.5016748,38.5709278 L60.8840748,37.4413614 C60.2408748,36.9928924 59.5472748,36.5204411 58.8176748,36.3453703 C57.7184748,36.0383968 56.7920748,36.7914411 56.4560748,37.7483349 C56.0864748,38.6908393 56.3216748,39.6477331 56.7056748,40.3696004 C57.5096748,41.8133349 58.4912748,42.4440694 58.3688748,42.585565" id="Fill-35" fill="#333333"></path>
          <path d="M84.7338348,56.8461606 C84.8634348,56.5727623 86.0970348,56.9109128 87.6162348,57.1003729 C89.1330348,57.3114172 90.4122348,57.3042225 90.4674348,57.601603 C90.5322348,57.8797977 89.1858348,58.3858243 87.4770348,58.1555942 C85.7682348,57.9325588 84.5994348,57.0955765 84.7338348,56.8461606" id="Fill-37" fill="#333333"></path>
          <g id="Group-41" transform="translate(13.998955, 204.064269)">
            <mask id="mask-2" fill="white">
              <use href="#path-1"></use>
            </mask>
            <g id="Clip-40"></g>
            <path d="M4.03984,13.2407522 C4.03984,13.2407522 -16.60016,66.8507876 37.87024,65.1096726 C92.34064,63.3661593 177.83824,58.797531 177.83824,58.797531 C177.83824,58.797531 217.09024,51.5308938 216.72544,28.0138496 C216.49024,12.7323274 205.16944,-0.721743363 169.81984,0.271123894 C134.47024,1.26399115 4.03984,13.2407522 4.03984,13.2407522" id="Fill-39" fill="#129DC4" mask="url(#mask-2)"></path>
          </g>
          <path d="M63.7290348,31.1097942 C71.1978348,31.12898 78.6642348,31.1505641 86.1330348,31.1673517 C86.4330348,28.512511 86.7354348,25.8576703 87.0378348,23.2028296 C87.4698348,25.97998 88.1298348,29.116865 90.5418348,30.5629977 C91.5378348,31.160157 92.7090348,31.378396 93.8538348,31.5630597 C97.2570348,32.1122544 100.689035,32.4552013 104.130635,32.5919004 C103.816235,29.1864137 104.531435,25.7785287 104.673035,22.3658473 C104.809835,18.9507676 104.248235,15.2526968 101.869835,12.794511 C100.211435,11.0797765 97.8810348,10.1948296 95.5794348,9.56169684 C86.5698348,7.08192693 76.7202348,7.77741365 68.1474348,11.4970685 C65.2842348,12.7393517 62.4138348,14.4612809 60.9978348,17.2408296 C59.8698348,19.4639889 59.8242348,22.0612721 59.8338348,24.5530331 C59.8386348,26.0471305 59.8674348,27.5939889 60.4962348,28.9489889 C61.1274348,30.3039889 62.5314348,31.4191659 64.0170348,31.2273075" id="Fill-42" fill="#0A2A41"></path>
          <path d="M59.7529548,19.3855667 C62.7961548,17.9897968 64.7425548,14.6442659 64.7521548,11.2987349 C64.7617548,7.95080569 62.9665548,4.72038976 60.3001548,2.69628356 C57.6337548,0.674575598 54.1777548,-0.181592543 50.8345548,0.0318499345 C47.9785548,0.214115421 45.1417548,1.17100923 42.9025548,2.95289418 C40.6657548,4.73717737 39.0697548,7.37043401 38.7625548,10.2147349 C38.4073548,13.488319 39.7633548,16.7834871 41.8609548,19.325611 C45.0697548,23.213142 50.1961548,25.5706021 55.2097548,25.0405933 C60.2257548,24.5129827 64.8961548,20.9180358 66.2929548,16.073611" id="Fill-44" fill="#0A2A41"></path>
          <path d="M34.1439948,27.4234747 C34.2231948,27.2579968 35.9631948,28.1645278 38.9871948,28.8144482 C41.9799948,29.4787579 46.3647948,29.7185809 50.8383948,28.2892358 C55.3095948,26.8574924 58.7391948,24.1163154 60.7911948,21.8379968 C62.8719948,19.5524836 63.7599948,17.8041738 63.9231948,17.8929083 C64.0215948,17.9336783 63.3639948,19.82828 61.3575948,22.3272358 C59.3919948,24.8070057 55.8687948,27.7928021 51.1623948,29.3012889 C46.4535948,30.8049791 41.8527948,30.4212623 38.8071948,29.5435101 C35.7231948,28.6753508 34.0863948,27.5122092 34.1439948,27.4234747" id="Fill-46" fill="#0A2A41"></path>
          <path d="M103.598555,17.6315013 C103.862555,17.5355721 105.172955,20.557342 105.897755,24.5336075 C106.636955,28.5050765 106.488155,31.7954482 106.207355,31.8002661 C105.900155,31.8098375 105.576155,28.6058021 104.853755,24.7254659 C104.148155,20.8403331 103.308155,17.7298287 103.598555,17.6315013" id="Fill-48" fill="#0A2A41"></path>
          <polygon id="Fill-50" fill="#E5EFF3" points="51.8696748 73.6853331 55.8104748 67.2460853 71.7392748 74.6470234 76.0904748 87.1106252 57.6560748 87.1106252"></polygon>
          <path d="M76.6712748,83.1986322 C76.8656748,81.7213225 80.1728748,70.9124995 80.1728748,70.9124995 L82.2632748,70.9124995 L86.0240748,77.0543667 L76.6712748,83.1986322 Z" id="Fill-52" fill="#E5EFF3"></path>
          <path d="M102.726635,83.1986322 C102.726635,83.1986322 113.243435,89.0527119 124.573835,123.98773 L113.649035,134.602296 L102.726635,83.1986322 Z" id="Fill-54" fill="#E5EFF3"></path>
          <path d="M35.6859948,78.0649809 C35.6667948,78.0290075 35.8851948,77.8707243 36.3363948,77.6045207 C36.8019948,77.3742906 37.5147948,77.0457331 38.5059948,76.8730606 C40.4427948,76.5181225 43.6011948,76.7987154 46.5123948,79.0290694 C49.4067948,81.2570252 51.4419948,85.4155561 51.7779948,90.1208836 C52.1643948,94.8693791 50.9091948,99.9272464 49.7139948,105.07145 C47.3187948,115.434202 45.0867948,124.796892 43.4139948,131.564698 C42.5883948,134.795114 41.9043948,137.48353 41.3739948,139.562795 C40.8675948,141.445406 40.5483948,142.476645 40.4715948,142.460059 C40.3923948,142.440671 40.5603948,141.373459 40.9323948,139.462069 C41.3667948,137.36122 41.9307948,134.644025 42.6075948,131.377636 C44.0763948,124.566662 46.1811948,115.172795 48.5763948,104.807645 C49.7811948,99.6394588 51.0171948,94.711096 50.7051948,90.2000252 C50.4387948,85.7153349 48.6003948,81.8062198 45.9939948,79.6717951 C43.3683948,77.5253791 40.4211948,77.1200783 38.5683948,77.3215296 C36.6699948,77.5469632 35.7363948,78.1729013 35.6859948,78.0649809" id="Fill-56" fill="#004663" opacity="0.200000003"></path>
          <path d="M70.8469548,76.0953145 C71.0029548,76.1336862 70.7677548,77.7764738 70.2565548,80.4097305 C69.9565548,81.8582614 69.6397548,83.3979252 69.3013548,85.0263234 C69.2341548,85.2541552 69.1669548,85.6594561 68.6869548,85.9376508 C68.1733548,86.1750756 67.7653548,85.9640314 67.5421548,85.8705004 C67.1173548,85.6642526 66.6877548,85.4556066 66.2509548,85.2445623 C65.3845548,84.8128809 64.4917548,84.3572172 63.5893548,83.8679783 C56.3317548,79.9684561 51.1477548,75.7211906 51.3421548,75.4837659 C51.5413548,75.2103676 56.9749548,78.9923765 64.1437548,82.8415358 C65.0413548,83.3139871 65.9125548,83.7744473 66.7693548,84.2253145 C67.1917548,84.438757 67.6069548,84.6521995 68.0173548,84.8632437 C68.2165548,84.9399871 68.2429548,84.9447836 68.2405548,85.0551021 C68.0965548,84.9399871 68.2285548,84.9783588 68.2573548,84.7577216 C68.6797548,83.1581021 69.0781548,81.644819 69.4525548,80.2202703 C70.1677548,77.6397747 70.6909548,76.0569429 70.8469548,76.0953145" id="Fill-58" fill="#004663" opacity="0.200000003"></path>
          <path d="M83.4656748,75.5940844 C83.7440748,75.5748986 83.9192748,79.3808898 82.1360748,83.8200137 L81.4640748,85.4939783 L81.0320748,83.7384738 C80.9696748,83.4866597 80.9048748,83.2228544 80.8376748,82.9542526 C79.6544748,78.0882437 78.9512748,74.0831995 79.2656748,74.0088544 C79.5776748,73.932111 80.7896748,77.8124473 81.9728748,82.6784561 C82.0376748,82.9446597 82.1024748,83.2132614 82.1624748,83.4626774 L81.0584748,83.3811375 C82.8080748,79.1866331 83.1344748,75.5629075 83.4656748,75.5940844" id="Fill-60" fill="#004663" opacity="0.200000003"></path>
          <path d="M82.4041548,181.338792 C82.3249548,181.345959 82.1209548,179.981366 81.8233548,177.503994 C81.5233548,175.029021 81.1393548,171.441269 80.7025548,167.004543 C79.8265548,158.131092 78.7681548,145.859348 77.8345548,132.290162 C76.9033548,118.713782 76.2673548,106.410861 75.9217548,97.5014367 C75.7465548,93.0479234 75.6361548,89.4433836 75.5953548,86.9492243 C75.5521548,84.455065 75.5641548,83.0736844 75.6457548,83.0712737 C75.7273548,83.0664898 75.8665548,84.4430738 76.0489548,86.9276402 C76.2241548,89.5776844 76.4593548,93.1390561 76.7473548,97.4582685 C77.3329548,106.504392 78.1249548,118.713782 79.0009548,132.208623 C79.9801548,145.69387 80.8657548,157.893667 81.5233548,166.932596 C81.8329548,171.254207 82.0873548,174.810782 82.2745548,177.463224 C82.4353548,179.950189 82.4857548,181.329171 82.4041548,181.338792" id="Fill-62" fill="#004663" opacity="0.200000003"></path>
          <path d="M113.880635,141.245842 C113.717435,141.272014 113.184635,138.801837 112.371035,134.81358 C111.557435,130.822925 110.436635,125.311792 109.109435,119.246669 C107.779835,113.181545 106.488635,107.706385 105.559835,103.739713 C104.631035,99.7754384 104.081435,97.3148544 104.242235,97.269288 C104.391035,97.2285181 105.223835,99.6123588 106.359035,103.531067 C107.496635,107.452173 108.917435,112.912943 110.251835,118.997253 C111.583835,125.081562 112.577435,130.633465 113.184635,134.669686 C113.791835,138.703509 114.034235,141.219253 113.880635,141.245842" id="Fill-64" fill="#004663" opacity="0.200000003"></path>
          <path d="M68.0744748,122.554068 C68.2016748,122.626015 68.0048748,123.14643 67.5776748,123.937846 C67.3640748,124.333554 67.0904748,124.798811 66.7736748,125.304838 C66.4040748,125.808466 66.2432748,126.369652 65.4800748,126.930838 C65.1128748,127.247404 64.3040748,127.22582 63.9656748,126.882873 C63.6032748,126.583094 63.4664748,126.319289 63.2840748,126.093855 C62.9360748,125.621404 62.6336748,125.189723 62.3936748,124.8132 C61.9112748,124.067351 61.6712748,123.556528 61.7840748,123.465395 C61.8992748,123.374262 62.3456748,123.714811 62.9768748,124.340749 C63.2936748,124.654917 63.6560748,125.041032 64.0448748,125.47751 C64.2416748,125.695749 64.4456748,125.954758 64.5896748,126.050687 C64.7456748,126.132227 64.7216748,126.129829 64.9064748,126.038696 C65.2640748,125.813262 65.6480748,125.134563 65.9888748,124.722068 C66.3344748,124.266404 66.6608748,123.863501 66.9464748,123.530147 C67.5224748,122.865838 67.9472748,122.482121 68.0744748,122.554068" id="Fill-66" fill="#5FABCB"></path>
          <path d="M91.3407948,137.163366 C91.4679948,137.235313 91.2735948,137.755729 90.8439948,138.547145 C90.6303948,138.945251 90.3567948,139.410507 90.0399948,139.914136 C89.6703948,140.417764 89.5095948,140.97895 88.7463948,141.540136 C88.3791948,141.856702 87.5703948,141.835118 87.2319948,141.492171 C86.8695948,141.192392 86.7327948,140.930985 86.5503948,140.703153 C86.2023948,140.230702 85.9023948,139.799021 85.6599948,139.422499 C85.1775948,138.676649 84.9375948,138.168224 85.0503948,138.074693 C85.1655948,137.983561 85.6119948,138.324109 86.2431948,138.952446 C86.5599948,139.264215 86.9223948,139.65033 87.3111948,140.089207 C87.5079948,140.305047 87.7119948,140.566454 87.8559948,140.659985 C88.0119948,140.741525 87.9879948,140.739127 88.1727948,140.647994 C88.5303948,140.422561 88.9143948,139.743861 89.2551948,139.331366 C89.6007948,138.875702 89.9271948,138.472799 90.2127948,138.139446 C90.7887948,137.475136 91.2135948,137.093817 91.3407948,137.163366" id="Fill-68" fill="#5FABCB"></path>
          <path d="M57.3435948,171.342941 C57.4707948,171.41249 57.2763948,171.932906 56.8467948,172.72672 C56.6331948,173.122428 56.3595948,173.587684 56.0427948,174.091313 C55.6731948,174.594941 55.5123948,175.156127 54.7491948,175.719711 C54.3819948,176.033879 53.5731948,176.014693 53.2347948,175.669348 C52.8747948,175.369569 52.7355948,175.108162 52.5531948,174.882729 C52.2051948,174.407879 51.9051948,173.976198 51.6627948,173.599676 C51.1827948,172.853826 50.9403948,172.345401 51.0531948,172.25187 C51.1683948,172.160738 51.6147948,172.501286 52.2459948,173.129623 C52.5627948,173.443791 52.9251948,173.827507 53.3139948,174.266384 C53.5107948,174.482224 53.7147948,174.743631 53.8587948,174.837162 C54.0147948,174.918702 53.9907948,174.918702 54.1755948,174.827569 C54.5331948,174.599738 54.9171948,173.923437 55.2579948,173.510941 C55.6035948,173.055277 55.9299948,172.649976 56.2179948,172.316623 C56.7915948,171.652313 57.2163948,171.270994 57.3435948,171.342941" id="Fill-70" fill="#5FABCB"></path>
          <path d="M70.2704748,151.836217 C70.3976748,151.908164 70.2008748,152.426182 69.7736748,153.219996 C69.5600748,153.615704 69.2864748,154.080961 68.9696748,154.584589 C68.6000748,155.088217 68.4392748,155.649403 67.6760748,156.212987 C67.3088748,156.527155 66.5000748,156.507969 66.1592748,156.162624 C65.7992748,155.862846 65.6624748,155.601438 65.4800748,155.376005 C65.1320748,154.901155 64.8296748,154.469474 64.5896748,154.092952 C64.1072748,153.347102 63.8672748,152.838677 63.9800748,152.747545 C64.0952748,152.654014 64.5416748,152.994562 65.1728748,153.622899 C65.4896748,153.937067 65.8520748,154.320784 66.2408748,154.75966 C66.4376748,154.9755 66.6416748,155.236907 66.7856748,155.332837 C66.9416748,155.411978 66.9176748,155.411978 67.1024748,155.320846 C67.4600748,155.093014 67.8440748,154.416713 68.1848748,154.004217 C68.5304748,153.548553 68.8568748,153.143253 69.1424748,152.809899 C69.7184748,152.145589 70.1432748,151.76427 70.2704748,151.836217" id="Fill-72" fill="#5FABCB"></path>
          <path d="M78.7758348,176.49314 C78.9030348,176.565087 78.7086348,177.085503 78.2790348,177.876919 C78.0654348,178.272627 77.7918348,178.740282 77.4750348,179.24391 C77.1054348,179.747538 76.9446348,180.308724 76.1814348,180.86991 C75.8142348,181.186476 75.0054348,181.164892 74.6670348,180.821946 C74.3046348,180.522167 74.1678348,180.258361 73.9854348,180.032928 C73.6374348,179.560476 73.3374348,179.128795 73.0950348,178.752273 C72.6126348,178.006423 72.3726348,177.497999 72.4854348,177.404468 C72.6006348,177.313335 73.0470348,177.653884 73.6782348,178.28222 C73.9950348,178.59399 74.3574348,178.980105 74.7462348,179.418981 C74.9430348,179.634822 75.1470348,179.896229 75.2910348,179.98976 C75.4470348,180.071299 75.4230348,180.068901 75.6078348,179.977769 C75.9654348,179.752335 76.3494348,179.073636 76.6902348,178.66114 C77.0358348,178.205476 77.3622348,177.802574 77.6478348,177.46922 C78.2238348,176.80491 78.6486348,176.423592 78.7758348,176.49314" id="Fill-74" fill="#5FABCB"></path>
          <path d="M91.6443948,107.639954 C91.7715948,107.711901 91.5771948,108.232317 91.1475948,109.023733 C90.9339948,109.419441 90.6627948,109.884698 90.3435948,110.390724 C89.9739948,110.894353 89.8131948,111.455538 89.0523948,112.016724 C88.6827948,112.333291 87.8739948,112.311707 87.5355948,111.96876 C87.1755948,111.668981 87.0363948,111.405176 86.8539948,111.179742 C86.5059948,110.707291 86.2059948,110.275609 85.9635948,109.899087 C85.4835948,109.153238 85.2411948,108.642415 85.3539948,108.551282 C85.4691948,108.460149 85.9179948,108.800698 86.5467948,109.426636 C86.8635948,109.740804 87.2259948,110.126919 87.6147948,110.563397 C87.8115948,110.781636 88.0155948,111.043043 88.1619948,111.136574 C88.3155948,111.215715 88.2915948,111.215715 88.4763948,111.124583 C88.8339948,110.899149 89.2179948,110.22045 89.5587948,109.807954 C89.9043948,109.352291 90.2307948,108.949388 90.5187948,108.616034 C91.0947948,107.951724 91.5171948,107.568007 91.6443948,107.639954" id="Fill-76" fill="#5FABCB"></path>
          <path d="M109.568075,149.796283 C109.695275,149.865831 109.500875,150.386247 109.071275,151.180061 C108.857675,151.575769 108.586475,152.041026 108.267275,152.544654 C107.897675,153.048283 107.736875,153.609469 106.973675,154.173053 C106.606475,154.487221 105.797675,154.468035 105.459275,154.12269 C105.099275,153.822911 104.960075,153.561504 104.777675,153.33607 C104.429675,152.861221 104.129675,152.429539 103.887275,152.053017 C103.407275,151.307168 103.164875,150.798743 103.277675,150.705212 C103.392875,150.614079 103.839275,150.954628 104.470475,151.582964 C104.787275,151.897132 105.149675,152.280849 105.538475,152.719725 C105.735275,152.935566 105.939275,153.196973 106.083275,153.290504 C106.239275,153.372044 106.215275,153.372044 106.400075,153.280911 C106.757675,153.053079 107.141675,152.376778 107.482475,151.964283 C107.828075,151.508619 108.154475,151.103318 108.442475,150.769964 C109.016075,150.105654 109.440875,149.724336 109.568075,149.796283" id="Fill-78" fill="#5FABCB"></path>
          <path d="M94.7895948,164.44803 C94.9167948,164.517578 94.7223948,165.037994 94.2927948,165.831808 C94.0791948,166.227516 93.8055948,166.692773 93.4887948,167.196401 C93.1191948,167.70003 92.9583948,168.261215 92.1951948,168.824799 C91.8279948,169.138968 91.0191948,169.119782 90.6807948,168.774437 C90.3183948,168.474658 90.1815948,168.213251 89.9991948,167.987817 C89.6511948,167.512968 89.3511948,167.081286 89.1087948,166.704764 C88.6287948,165.958915 88.3863948,165.45049 88.4991948,165.356959 C88.6143948,165.265826 89.0607948,165.606375 89.6919948,166.234711 C90.0087948,166.548879 90.3711948,166.932596 90.7599948,167.371472 C90.9567948,167.587313 91.1607948,167.84872 91.3047948,167.944649 C91.4607948,168.023791 91.4367948,168.023791 91.6215948,167.932658 C91.9791948,167.704826 92.3631948,167.028525 92.7039948,166.61603 C93.0495948,166.160366 93.3759948,165.755065 93.6639948,165.421711 C94.2375948,164.757401 94.6623948,164.376083 94.7895948,164.44803" id="Fill-80" fill="#5FABCB"></path>
          <path d="M105.164315,121.692144 C105.291515,121.764091 105.097115,122.284507 104.667515,123.075923 C104.453915,123.47163 104.180315,123.936887 103.863515,124.442914 C103.493915,124.946542 103.333115,125.507728 102.569915,126.068914 C102.202715,126.38548 101.393915,126.363896 101.055515,126.020949 C100.693115,125.72117 100.556315,125.457365 100.373915,125.231931 C100.025915,124.75948 99.7259148,124.327799 99.4835148,123.951276 C99.0011148,123.205427 98.7611148,122.694604 98.8739148,122.603471 C98.9891148,122.512338 99.4355148,122.852887 100.066715,123.478825 C100.383515,123.792993 100.745915,124.17671 101.134715,124.615586 C101.331515,124.833825 101.535515,125.092834 101.679515,125.188763 C101.835515,125.270303 101.811515,125.267905 101.996315,125.176772 C102.353915,124.951338 102.737915,124.272639 103.078715,123.860144 C103.424315,123.40448 103.750715,123.001577 104.036315,122.668223 C104.612315,122.003914 105.037115,121.620197 105.164315,121.692144" id="Fill-82" fill="#5FABCB"></path>
          <path d="M97.0676748,91.7281774 C97.1948748,91.8001243 96.9980748,92.318142 96.5684748,93.1119561 C96.3572748,93.5076641 96.0836748,93.9729207 95.7668748,94.4789473 C95.3972748,94.9801774 95.2364748,95.5437614 94.4732748,96.1049473 C94.1060748,96.4191154 93.2972748,96.3999296 92.9564748,96.0545844 C92.5964748,95.7548057 92.4596748,95.4933986 92.2772748,95.267965 C91.9292748,94.7931154 91.6268748,94.361434 91.3868748,93.9873101 C90.9044748,93.2390623 90.6644748,92.7306375 90.7772748,92.6395048 C90.8924748,92.5459738 91.3388748,92.8865225 91.9700748,93.5148588 C92.2868748,93.8290269 92.6492748,94.2127437 93.0380748,94.6516198 C93.2348748,94.8674606 93.4388748,95.1288676 93.5828748,95.2247968 C93.7388748,95.3039384 93.7148748,95.3039384 93.8996748,95.2128057 C94.2572748,94.9849738 94.6412748,94.3086729 94.9820748,93.8961774 C95.3276748,93.4405137 95.6540748,93.0352128 95.9396748,92.7018588 C96.5156748,92.037549 96.9404748,91.6562305 97.0676748,91.7281774" id="Fill-84" fill="#5FABCB"></path>
          <path d="M61.5025548,106.295746 C61.6297548,106.365295 61.4353548,106.885711 61.0057548,107.679525 C60.7921548,108.075233 60.5185548,108.54049 60.2017548,109.044118 C59.8321548,109.547746 59.6713548,110.108932 58.9081548,110.672516 C58.5409548,110.986684 57.7321548,110.967499 57.3937548,110.622153 C57.0337548,110.322375 56.8945548,110.060968 56.7121548,109.835534 C56.3641548,109.360684 56.0641548,108.929003 55.8217548,108.552481 C55.3393548,107.806631 55.0993548,107.298207 55.2121548,107.204676 C55.3273548,107.113543 55.7737548,107.454092 56.4049548,108.082428 C56.7217548,108.396596 57.0841548,108.780313 57.4729548,109.219189 C57.6697548,109.43503 57.8737548,109.696437 58.0177548,109.789968 C58.1737548,109.871507 58.1497548,109.871507 58.3345548,109.777976 C58.6921548,109.552543 59.0761548,108.876242 59.4169548,108.461348 C59.7625548,108.005684 60.0889548,107.602782 60.3769548,107.269428 C60.9505548,106.605118 61.3753548,106.223799 61.5025548,106.295746" id="Fill-86" fill="#5FABCB"></path>
          <path d="M71.5595148,92.7188862 C71.6867148,92.7908331 71.4899148,93.3088508 71.0627148,94.102665 C70.8491148,94.4983729 70.5755148,94.9636296 70.2587148,95.4696561 C69.8891148,95.9732844 69.7283148,96.5344703 68.9651148,97.0956561 C68.5979148,97.4122225 67.7891148,97.3906384 67.4483148,97.0452933 C67.0883148,96.7455145 66.9515148,96.4841075 66.7691148,96.2586738 C66.4211148,95.7862225 66.1187148,95.3545411 65.8787148,94.978019 C65.3963148,94.2297712 65.1563148,93.7213464 65.2691148,93.6302137 C65.3843148,93.5366827 65.8307148,93.8772314 66.4619148,94.5055676 C66.7787148,94.8197358 67.1411148,95.2034526 67.5299148,95.6423287 C67.7267148,95.8605676 67.9307148,96.1195765 68.0747148,96.2155057 C68.2307148,96.2946473 68.2067148,96.2946473 68.3915148,96.2035145 C68.7491148,95.9780809 69.1331148,95.2993818 69.4739148,94.8868862 C69.8195148,94.4312225 70.1459148,94.0283198 70.4315148,93.6949659 C71.0075148,93.0306561 71.4323148,92.6469393 71.5595148,92.7188862" id="Fill-88" fill="#5FABCB"></path>
          <path d="M96.2099148,80.5286827 C96.3371148,80.6006296 96.1427148,81.1210455 95.7131148,81.9124614 C95.4995148,82.3105676 95.2259148,82.7758243 94.9091148,83.2794526 C94.5395148,83.7830809 94.3787148,84.3442667 93.6155148,84.9054526 C93.2483148,85.222019 92.4395148,85.2004349 92.1011148,84.857488 C91.7387148,84.5577092 91.6019148,84.2939039 91.4195148,84.0684703 C91.0715148,83.596019 90.7691148,83.1643375 90.5291148,82.7878154 C90.0467148,82.0419659 89.8067148,81.5335411 89.9195148,81.4400101 C90.0347148,81.3488774 90.4811148,81.689426 91.1123148,82.3177623 C91.4291148,82.6295322 91.7915148,83.0156473 92.1803148,83.4545234 C92.3771148,83.6703641 92.5811148,83.9317712 92.7251148,84.0253021 C92.8811148,84.106842 92.8571148,84.1044437 93.0419148,84.013311 C93.3995148,83.7878774 93.7835148,83.1091783 94.1243148,82.6966827 C94.4699148,82.241019 94.7963148,81.8381163 95.0819148,81.5047623 C95.6579148,80.8404526 96.0827148,80.459134 96.2099148,80.5286827" id="Fill-90" fill="#5FABCB"></path>
          <path d="M113.583035,101.042423 C113.710235,101.11437 113.513435,101.634786 113.086235,102.426202 C112.872635,102.824308 112.599035,103.289565 112.282235,103.793193 C111.912635,104.296822 111.751835,104.858007 110.988635,105.419193 C110.621435,105.73576 109.812635,105.714176 109.471835,105.371229 C109.111835,105.07145 108.975035,104.810043 108.792635,104.582211 C108.444635,104.10976 108.142235,103.678078 107.902235,103.301556 C107.419835,102.555707 107.179835,102.047282 107.292635,101.953751 C107.407835,101.862618 107.854235,102.203167 108.485435,102.831503 C108.802235,103.143273 109.164635,103.529388 109.553435,103.968264 C109.747835,104.184105 109.954235,104.445512 110.098235,104.539043 C110.254235,104.620583 110.230235,104.618184 110.415035,104.527052 C110.772635,104.301618 111.156635,103.622919 111.497435,103.210423 C111.843035,102.75476 112.169435,102.351857 112.455035,102.018503 C113.031035,101.354193 113.455835,100.972875 113.583035,101.042423" id="Fill-92" fill="#5FABCB"></path>
          <path d="M121.779035,118.251643 C121.906235,118.321192 121.711835,118.841607 121.282235,119.635422 C121.068635,120.03113 120.795035,120.496386 120.478235,121.000015 C120.108635,121.503643 119.947835,122.064829 119.184635,122.628413 C118.817435,122.942581 118.008635,122.923395 117.670235,122.57805 C117.310235,122.278271 117.171035,122.016864 116.988635,121.79143 C116.640635,121.316581 116.340635,120.884899 116.098235,120.508377 C115.618235,119.762528 115.375835,119.254103 115.488635,119.160572 C115.603835,119.069439 116.050235,119.409988 116.681435,120.038324 C116.998235,120.352492 117.360635,120.736209 117.749435,121.175085 C117.946235,121.390926 118.150235,121.652333 118.294235,121.745864 C118.450235,121.827404 118.426235,121.827404 118.611035,121.736271 C118.968635,121.508439 119.352635,120.832138 119.693435,120.419643 C120.039035,119.963979 120.365435,119.558678 120.653435,119.225324 C121.227035,118.561015 121.651835,118.179696 121.779035,118.251643" id="Fill-94" fill="#5FABCB"></path>
          <path d="M65.2184748,72.9406827 C65.3456748,73.0126296 65.1512748,73.5306473 64.7216748,74.3244614 C64.5080748,74.7201694 64.2344748,75.185426 63.9176748,75.6914526 C63.5480748,76.1926827 63.3872748,76.7562667 62.6240748,77.3174526 C62.2568748,77.6316207 61.4480748,77.6124349 61.1096748,77.2670898 C60.7496748,76.967311 60.6104748,76.7059039 60.4280748,76.4804703 C60.0800748,76.008019 59.7800748,75.5739393 59.5376748,75.1998154 C59.0576748,74.4515676 58.8152748,73.9431429 58.9280748,73.8520101 C59.0432748,73.7584791 59.4896748,74.0990278 60.1208748,74.7273641 C60.4376748,75.0415322 60.8000748,75.425249 61.1888748,75.8641252 C61.3856748,76.0799659 61.5896748,76.3413729 61.7336748,76.4373021 C61.8896748,76.5164437 61.8656748,76.5164437 62.0504748,76.425311 C62.4080748,76.1974791 62.7920748,75.5211783 63.1328748,75.1086827 C63.4784748,74.653019 63.8048748,74.2477181 64.0928748,73.9143641 C64.6664748,73.2500544 65.0912748,72.8687358 65.2184748,72.9406827" id="Fill-96" fill="#5FABCB"></path>
          <path d="M56.1131148,141.742067 C56.2403148,141.811615 56.0435148,142.332031 55.6139148,143.125846 C55.4027148,143.521553 55.1291148,143.98681 54.8123148,144.490438 C54.4427148,144.994067 54.2819148,145.555253 53.5187148,146.118837 C53.1515148,146.433005 52.3403148,146.413819 52.0019148,146.068474 C51.6419148,145.768695 51.5051148,145.507288 51.3227148,145.281854 C50.9747148,144.807005 50.6723148,144.375323 50.4323148,143.998801 C49.9499148,143.252952 49.7099148,142.744527 49.8227148,142.650996 C49.9379148,142.559863 50.3843148,142.900412 51.0155148,143.528748 C51.3323148,143.842916 51.6947148,144.226633 52.0835148,144.665509 C52.2779148,144.88135 52.4843148,145.142757 52.6283148,145.236288 C52.7843148,145.317828 52.7603148,145.317828 52.9451148,145.226695 C53.3027148,144.998863 53.6867148,144.322562 54.0251148,143.910067 C54.3731148,143.454403 54.6995148,143.049102 54.9851148,142.715748 C55.5611148,142.051438 55.9859148,141.67012 56.1131148,141.742067" id="Fill-98" fill="#5FABCB"></path>
          <polygon id="Fill-100" fill="#CCDFE7" points="78.6493548 183.584707 78.6493548 180.191211 128.864555 174.341928 143.276555 128.247946 198.745355 128.247946 182.566955 178.126335"></polygon>
          <path d="M169.437995,151.428758 C169.437995,153.695085 167.599595,155.53213 165.333995,155.53213 C163.065995,155.53213 161.227595,153.695085 161.227595,151.428758 C161.227595,149.164829 163.065995,147.325386 165.333995,147.325386 C167.599595,147.325386 169.437995,149.164829 169.437995,151.428758" id="Fill-102" fill="#EDCFC4"></path>
          <path d="M171.082235,151.428758 C171.082235,154.601616 168.507035,157.174917 165.331835,157.174917 C162.156635,157.174917 159.583835,154.601616 159.583835,151.428758 C159.583835,148.258298 162.156635,145.682599 165.331835,145.682599 C168.507035,145.682599 171.082235,148.258298 171.082235,151.428758" id="Fill-104" fill="#FFFFFF"></path>
          <path d="M102.018875,181.401838 C102.018875,181.401838 117.815675,166.245023 119.399675,167.122776 C120.983675,168.000528 120.767675,174.020085 115.374875,178.636678 C115.374875,178.636678 139.317275,177.907616 138.686075,181.581705 C138.054875,185.258192 120.042875,185.706661 120.042875,185.706661 C120.042875,185.706661 137.440475,186.668351 137.426075,190.078634 C137.411675,193.488917 121.636475,193.58005 121.636475,193.58005 C121.636475,193.58005 137.481275,194.337891 135.849275,196.539466 C134.214875,198.741041 123.184475,200.343059 123.184475,200.343059 C123.184475,200.343059 134.234075,200.160793 132.599675,202.364767 C130.967675,204.56874 108.465275,212.159138 108.465275,212.159138 C102.537275,213.298298 102.018875,181.401838 102.018875,181.401838" id="Fill-106" fill="#F6C199"></path>
          <path d="M46.4859948,111.206842 C46.4859948,111.206842 31.2459948,185.175453 44.8683948,185.180249 C74.7963948,185.189842 102.019595,181.403037 102.019595,181.403037 L108.398795,212.467311 C108.398795,212.467311 63.8451948,218.813028 52.6779948,220.237576 C41.5107948,221.664523 -0.830005224,225.96455 0.0123947758,180.177541 C0.854794776,134.239444 11.0523948,105.628559 11.0523948,105.628559 L46.4859948,111.206842 Z" id="Fill-108" fill="#F6C199"></path>
          <polygon id="Fill-110" fill="#E5EFF3" points="12.9997548 96.323426 1.48695478 133.416851 40.9501548 144.981116 47.5573548 105.628559"></polygon>
          <path d="M17.9591148,115.176632 C18.0863148,115.248579 17.8919148,115.766597 17.4623148,116.560411 C17.2487148,116.956119 16.9751148,117.421376 16.6583148,117.927402 C16.2887148,118.428632 16.1279148,118.992216 15.3647148,119.553402 C14.9975148,119.86757 14.1887148,119.848384 13.8503148,119.503039 C13.4903148,119.203261 13.3511148,118.941853 13.1687148,118.71642 C12.8207148,118.243969 12.5207148,117.809889 12.2783148,117.435765 C11.7983148,116.687517 11.5559148,116.179092 11.6687148,116.08796 C11.7839148,115.994429 12.2303148,116.334977 12.8615148,116.963314 C13.1783148,117.277482 13.5407148,117.661199 13.9295148,118.100075 C14.1263148,118.315915 14.3303148,118.577323 14.4743148,118.673252 C14.6303148,118.752393 14.6063148,118.752393 14.7911148,118.661261 C15.1487148,118.433429 15.5327148,117.757128 15.8735148,117.344632 C16.2191148,116.888969 16.5455148,116.483668 16.8335148,116.150314 C17.4071148,115.486004 17.8319148,115.104685 17.9591148,115.176632" id="Fill-112" fill="#5FABCB"></path>
          <path d="M33.9728748,103.674961 C34.1000748,103.746907 33.9056748,104.267323 33.4760748,105.058739 C33.2624748,105.454447 32.9888748,105.922102 32.6720748,106.42573 C32.3024748,106.929359 32.1416748,107.490545 31.3784748,108.05173 C31.0112748,108.368297 30.2024748,108.346713 29.8640748,108.003766 C29.5040748,107.703987 29.3648748,107.440182 29.1824748,107.214748 C28.8344748,106.742297 28.5344748,106.310615 28.2920748,105.934093 C27.8120748,105.188244 27.5696748,104.679819 27.6824748,104.586288 C27.7976748,104.495155 28.2440748,104.835704 28.8752748,105.461642 C29.1920748,105.77581 29.5544748,106.161925 29.9432748,106.600801 C30.1400748,106.816642 30.3440748,107.078049 30.4880748,107.17158 C30.6440748,107.25312 30.6200748,107.250722 30.8048748,107.159589 C31.1624748,106.934155 31.5464748,106.255456 31.8872748,105.842961 C32.2328748,105.387297 32.5592748,104.984394 32.8472748,104.65104 C33.4208748,103.98673 33.8456748,103.605412 33.9728748,103.674961" id="Fill-114" fill="#5FABCB"></path>
          <path d="M39.4863948,120.514133 C39.6135948,120.58608 39.4191948,121.106496 38.9895948,121.897912 C38.7759948,122.29362 38.5047948,122.758876 38.1855948,123.264903 C37.8159948,123.766133 37.6551948,124.329717 36.8919948,124.890903 C36.5247948,125.207469 35.7159948,125.185885 35.3775948,124.842938 C35.0175948,124.54316 34.8783948,124.279354 34.6959948,124.053921 C34.3479948,123.581469 34.0479948,123.14739 33.8055948,122.773266 C33.3255948,122.025018 33.0831948,121.516593 33.1959948,121.425461 C33.3111948,121.334328 33.7575948,121.672478 34.3887948,122.300815 C34.7055948,122.614983 35.0679948,122.998699 35.4567948,123.437576 C35.6535948,123.655815 35.8575948,123.914823 36.0015948,124.010753 C36.1575948,124.089894 36.1335948,124.089894 36.3183948,123.998761 C36.6759948,123.773328 37.0599948,123.094629 37.4007948,122.682133 C37.7463948,122.226469 38.0727948,121.823567 38.3607948,121.487815 C38.9343948,120.825903 39.3591948,120.442186 39.4863948,120.514133" id="Fill-116" fill="#5FABCB"></path>
          <path d="M21.7278348,94.5926234 C21.8550348,94.6645703 21.6606348,95.1849862 21.2310348,95.9764021 C21.0174348,96.3721101 20.7462348,96.839765 20.4270348,97.3433933 C20.0574348,97.8470216 19.8966348,98.4082075 19.1358348,98.9693933 C18.7662348,99.2859597 17.9574348,99.2643756 17.6190348,98.9214287 C17.2590348,98.6216499 17.1198348,98.3578446 16.9374348,98.132411 C16.5918348,97.6599597 16.2894348,97.2282783 16.0470348,96.8517561 C15.5670348,96.1059066 15.3246348,95.5950836 15.4374348,95.5039508 C15.5526348,95.4128181 15.9990348,95.7533667 16.6326348,96.3793048 C16.9470348,96.6934729 17.3094348,97.079588 17.6982348,97.5184641 C17.8950348,97.7343048 18.0990348,97.9957119 18.2454348,98.0892429 C18.3990348,98.1707827 18.3750348,98.1683844 18.5598348,98.0772517 C18.9174348,97.8518181 19.3014348,97.173119 19.6422348,96.7606234 C19.9878348,96.3049597 20.3142348,95.902057 20.6022348,95.568703 C21.1758348,94.9043933 21.6006348,94.5206765 21.7278348,94.5926234" id="Fill-118" fill="#5FABCB"></path>
          <path d="M42.2310348,87.4423004 C42.3582348,87.5142473 42.1614348,88.0346632 41.7342348,88.8260791 C41.5206348,89.2241853 41.2470348,89.689442 40.9302348,90.1930703 C40.5606348,90.6966986 40.3998348,91.2578844 39.6366348,91.8190703 C39.2694348,92.1356367 38.4582348,92.1140526 38.1198348,91.7711057 C37.7598348,91.4713269 37.6230348,91.2075216 37.4406348,90.982088 C37.0926348,90.5096367 36.7902348,90.0779552 36.5502348,89.7014331 C36.0678348,88.9555836 35.8278348,88.4471588 35.9406348,88.3536278 C36.0558348,88.2624951 36.5022348,88.6030437 37.1334348,89.23138 C37.4502348,89.5431499 37.8126348,89.929265 38.2014348,90.3681411 C38.3982348,90.5839818 38.6022348,90.8453889 38.7462348,90.9389198 C38.9022348,91.0204597 38.8782348,91.0180614 39.0630348,90.9269287 C39.4206348,90.7014951 39.8046348,90.022796 40.1454348,89.6103004 C40.4910348,89.1546367 40.8174348,88.751734 41.1030348,88.41838 C41.6790348,87.7540703 42.1038348,87.3727517 42.2310348,87.4423004" id="Fill-120" fill="#5FABCB"></path>
          <path d="M23.1774348,127.589392 C23.3046348,127.661338 23.1078348,128.179356 22.6806348,128.97317 C22.4670348,129.368878 22.1934348,129.834135 21.8766348,130.340161 C21.5070348,130.841392 21.3462348,131.402577 20.5830348,131.966161 C20.2158348,132.28033 19.4070348,132.261144 19.0686348,131.915799 C18.7062348,131.61602 18.5694348,131.354613 18.3870348,131.129179 C18.0390348,130.656728 17.7390348,130.222648 17.4966348,129.848524 C17.0142348,129.100276 16.7742348,128.591852 16.8870348,128.500719 C17.0022348,128.407188 17.4486348,128.747737 18.0798348,129.376073 C18.3966348,129.690241 18.7590348,130.073958 19.1478348,130.512834 C19.3446348,130.728675 19.5486348,130.990082 19.6926348,131.086011 C19.8486348,131.165153 19.8246348,131.165153 20.0094348,131.07402 C20.3670348,130.846188 20.7510348,130.169887 21.0918348,129.757392 C21.4374348,129.301728 21.7638348,128.896427 22.0494348,128.563073 C22.6254348,127.898763 23.0502348,127.517445 23.1774348,127.589392" id="Fill-122" fill="#5FABCB"></path>
          <g id="Group" transform="translate(129.355355, 1.473734)">
            <path d="M64.96152,44.008893 C56.90712,58.13207 38.92152,63.0532381 24.79032,55.0071762 C10.65672,46.9563177 5.72952,28.9839815 13.78392,14.8656009 C21.83592,0.74242394 39.82152,-4.18354066 53.95512,3.86731775 C68.08872,11.9133797 73.01352,29.885716 64.96152,44.008893" id="Fill-124" fill="#A5013F"></path>
            <polygon id="Fill-126" fill="#A5013F" points="12.144 36.4887629 -5.15143483e-14 47.2855947 17.196 48.424754"></polygon>
            <g id="Solid" transform="translate(27.643600, 17.525260)" fill="#FFFFFF" fillRule="nonzero">
              <path d="M12,0 C5.3832,0 0,5.3832 0,12 C0,18.618 5.3832,24 12,24 C18.6168,24 24,18.618 24,12 C24,5.3832 18.6168,0 12,0 Z M17.6484,15.9516 L15.9516,17.6484 L12,13.6968 L8.0484,17.6472 L6.3516,15.9504 L10.3032,12 L6.3516,8.0484 L8.0484,6.3516 L12,10.3032 L15.9516,6.3516 L17.6484,8.0484 L13.6968,12 L17.6484,15.9516 Z" id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>