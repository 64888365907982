import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { InView } from "react-intersection-observer";
import Lottie from "lottie-react";

import { useMediaListener } from "../../../../hooks/useMediaListener";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { Widget, WidgetType, hasWidget } from "../../../../modules";
import { AppConfig } from "../../../../appConfig";
import { TextBox } from "../textBox/textBox.component";
import { FinishBox } from "../finishBox/finishBox.component";
import { getAnimation } from "../services/api";
import { AnimationType } from "../scrollContent.constants";

import { FadeDurationInSeconds } from "./slide.constants";
import {
  Container,
  ContentWrapper,
  AnimationWrapper,
  Video,
  ScrollButtonWrapper,
  ScrollButton,
  ArrowIcon,
  Separator,
  FinishBoxWrapper,
  StaticImage,
  VideoBox,
} from "./slide.styled";

export const Slide = ({
  className,
  src,
  staticSrc,
  type,
  currentCounter,
  overallCounter,
  content,
  onVisibleChange,
  tags,
  onFloatClick = Function.prototype,
  onScrollButtonClick = Function.prototype,
  isFirstSlide,
  isLastSlide,
  isFinishingSlide,
  onFinishLesson,
  isNextSlide,
  needFix,
}) => {
  const { isMobile } = useMediaListener();
  const animRef = useRef();
  let timeout = null;
  const [animationData, setAnimationData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (!!animRef?.current && isFirstSlide && type === AnimationType.AnimWithoutFade) {
      if (isMobile) {
        animRef.current.goToAndStop(0, true);
      } else {
        animRef.current.goToAndPlay(0, true);
      }
    }
  }, [dataLoaded]);

  useEffect(() => {
    if (type !== AnimationType.VideoAnim && type !== AnimationType.VideoPlayer && !!src) {
      getAnimation(src, setAnimationData);
    }
  }, [src, type]);

  const handleInViewChange = (inView) => {
    if (inView) {
      onVisibleChange();
    }

    if (animRef?.current) {
      if (inView) {
        if (type === AnimationType.AnimWithoutFade) {
          if (!isMobile) {
            if (!isNextSlide) {
              animRef.current.goToAndPlay(animRef.current.getDuration(true), true);
              animRef.current.setDirection(-1);
            } else {
              animRef.current.goToAndPlay(0, true);
              animRef.current.setDirection(1);
            }
          }
        } else {
          clearTimeout(timeout);
          animRef.current.play();
        }
      } else {
        if (type === AnimationType.AnimWithoutFade) {
          animRef.current.stop();
        } else {
          timeout = setTimeout(() => {
            if (type === AnimationType.VideoAnim) {
              animRef.current.pause();
              animRef.current.currentTime = 0;
            } else {
              animRef.current.stop();
            }
          }, FadeDurationInSeconds * 1000);
        }
      }
    }
  };

  const renderVideoAnim = renderWhenTrue(() => (
    <Video
      src={src}
      muted
      ref={animRef}
      loop
    />
  ));

  const onEnterFrame = (event) => {
    if (event.direction === 1 && event.currentTime >= event.totalTime - 75) {
      animRef.current.pause();
    } else if (event.direction === -1 && event.currentTime <= (isFirstSlide ? 1 : 60)) {
      animRef.current.pause();
    }
  };

  const renderAnimation = renderWhenTrue(() => {
    if (type === AnimationType.AnimWithoutFade && isMobile) return null;

    return animationData ? (
      <Lottie
        animationData={animationData}
        loop={type !== AnimationType.AnimWithoutFade}
        autoplay={type === AnimationType.Animation && isFirstSlide}
        rendererSettings={{
          preserveAspectRatio: "xMidYMid slice",
        }}
        lottieRef={animRef}
        onDOMLoaded={() => setDataLoaded(true)}
        onEnterFrame={type === AnimationType.AnimWithoutFade ? onEnterFrame : undefined}
      />
    ) : null;
  });

  const renderStatic = renderWhenTrue(() => {
    return <StaticImage src={`${AppConfig.content}${staticSrc}`} />;
  });

  const renderVideoPlayer = renderWhenTrue(() => {
    return (
      <VideoBox>
        {hasWidget(WidgetType.VideoSection) && (
          <Widget.VideoSection
            videoSrc={src}
            onFinish={() => {}}
            title=""
            competences={[]}
            tags={[]}
            callComplete={() => {}}
            isScrollContent
            isScrollLesson
          />
        )}
      </VideoBox>
    );
  });

  const renderScrollButton = renderWhenTrue(() => (
    <ScrollButtonWrapper>
      <ScrollButton onClick={onScrollButtonClick}>
        <ArrowIcon />
      </ScrollButton>
      <Separator />
    </ScrollButtonWrapper>
  ));

  return (
    <Container className={className}>
      <InView
        onChange={(inView) => handleInViewChange(inView)}
        rootMargin={isLastSlide ? "-100% 0% 0%" : "0%"}
      >
        {({ inView, ref }) => {
          return (
            <ContentWrapper ref={ref} isVideoPlayer={type === AnimationType.VideoPlayer}>
              {type === AnimationType.VideoPlayer ? (
                renderVideoPlayer(type === AnimationType.VideoPlayer)
              ) : (
                <AnimationWrapper
                  $visible={inView}
                  $wide={type === AnimationType.WideBoxWithAnim || needFix}
                  $withFade={type !== AnimationType.AnimWithoutFade}
                  $needFix={needFix}
                >
                  {renderAnimation(type !== AnimationType.VideoAnim && type !== AnimationType.VideoPlayer)}
                  {renderVideoAnim(type === AnimationType.VideoAnim)}
                  {renderStatic(type === AnimationType.AnimWithoutFade && isMobile)}
                </AnimationWrapper>
              )}
              {content.map((textBox, index) => (
                <TextBox
                  key={index}
                  currentCounter={currentCounter}
                  overallCounter={overallCounter}
                  headerText={textBox.headerText}
                  tags={tags}
                  htmlText={textBox.htmlText}
                  onFloatClick={onFloatClick}
                  wide={type === AnimationType.WideBoxWithAnim}
                />
              ))}
              {renderScrollButton(currentCounter === 0)}
              {isLastSlide && (
                <FinishBoxWrapper>
                  <Separator />
                  <FinishBox
                    isLastSlide={isFinishingSlide}
                    onClick={onFinishLesson}
                  />
                </FinishBoxWrapper>
              )}
            </ContentWrapper>
          );
        }}
      </InView>
    </Container>
  );
};

Slide.propTypes = {
  className: PropTypes.string,
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  type: PropTypes.string,
  currentCounter: PropTypes.number,
  overallCounter: PropTypes.number,
  content: PropTypes.array.isRequired,
  onVisibleChange: PropTypes.func.isRequired,
  tags: PropTypes.array,
  onFloatClick: PropTypes.func,
  onScrollButtonClick: PropTypes.func,
  isLastSlide: PropTypes.bool,
  isFinishingSlide: PropTypes.bool,
  onFinishLesson: PropTypes.func,
  needFix: PropTypes.bool,
};
