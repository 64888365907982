import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import { ButtonColor } from "../../../../components/button/button.constants";
import { CompetenceActions } from "../../redux/competence.reducer";
import { selectSelfMarkSurveyPages } from "../../redux/competence.selectors";
import { CompetenceTabURI } from "../competenceDetails/competenceDetails.constants";
import { SurveyQuestion } from "../surveyQuestion/surveyQuestion.component";
import { AnswerType, QuestionType } from "../surveyQuestion/surveyQuestion.constants";

import { MaxRate } from "./selfMarkSurvey.constants";
import {
  Container,
  Wrapper,
  TitleWrapper,
  StarIcon,
  Title,
  HeaderSeparator,
  Buttons,
  CancelLink,
  SendButton,
  QuestionSeparator,
  QuestionWrapper,
  BackButton,
} from "./selfMarkSurvey.styled";

export const SelfMarkSurvey = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const surveyPages = useSelector(selectSelfMarkSurveyPages);
  const [answers, setAnswers] = useState([]);
  const allAnswersMaxRate = answers.filter(
    (question) => question.answer.type !== AnswerType.Comment
  ).every(
    (question) => question.answer.params[0].value === MaxRate.toString()
  );
  const disabledButton = !!surveyPages
    && (answers.length < surveyPages[0].sections[0].questions.length - 1
    || (allAnswersMaxRate && !(answers[answers.length - 1].answer.value)));

  useEffect(() => {
    return () => {
      if (surveyPages) {
        dispatch(CompetenceActions.clearSelfMarkSurvey());
      }
    };
  }, [surveyPages, setAnswers, dispatch]);

  useEffect(() => {
    dispatch(CompetenceActions.getSelfMarkSurvey(params.measureId));
  }, [dispatch, params.measureId]);

  const handleSurveySent = () => {
    navigate(`/competence/${params.id}/${CompetenceTabURI.Competence}`);
  };

  const onSendClick = () => {
    const selectedAnswers = answers.filter((question) => {
      if (!allAnswersMaxRate) {
        return question.answer.type === AnswerType.Selected;
      }
      return question;
    }).map((question) => {
      if (question.answer.type === AnswerType.Selected) {
        return {
          id: question.answer.id,
          value: true,
        };
      } else {
        return {
          id: question.questionId,
          value: question.answer.value,
        };
      }
    });

    dispatch(CompetenceActions.sendSelfMarkSurvey(params.measureId, selectedAnswers, handleSurveySent));
  };

  const handleAnswerChoose = (questionId, answerData) => {
    const updatedAnswer = answers.find((answer) => answer.questionId === questionId);
    const newAnswer = {
      questionId,
      answer: answerData,
    };

    if (!updatedAnswer) {
      setAnswers([...answers, newAnswer]);
    } else {
      const newAnswers = answers.map((answer) => {
        if (answer.questionId === questionId) {
          return newAnswer;
        }
        return answer;
      });

      setAnswers(newAnswers);
    }
  };

  const renderQuestions = () => {
    const { questions } = surveyPages[0].sections[0];

    return questions.map((question, index) => {
      const hideQuestion = answers.length < index
        || (question.type === QuestionType.Open && !allAnswersMaxRate);
      let value = null;

      if (question.type === QuestionType.Open) {
        value = answers.length > index ? answers[index].answer.value : "";
      }

      if (question.type !== QuestionType.Open && answers.length > index) {
        value = answers[index].answer.id;
      }

      return (
        <QuestionWrapper key={question.id} hidden={hideQuestion}>
          <SurveyQuestion
            counter={index + 1}
            questionId={question.id}
            question={question.text}
            answers={question.answers}
            value={value}
            onChange={(id, answer) => handleAnswerChoose(id, answer)}
          />
          <QuestionSeparator />
        </QuestionWrapper>
      );
    });
  };

  if (!surveyPages) {
    return <div />;
  }

  return (
    <Container className={className}>
      <Wrapper>
        <BackButton defaultPath={`/competence/${params.id}/details`} />
        <TitleWrapper>
          <StarIcon />
          <Title>{surveyPages[0].title}</Title>
        </TitleWrapper>
        <HeaderSeparator />
        {renderQuestions()}
        <Buttons>
          <SendButton
            onClick={onSendClick}
            color={ButtonColor.Primary}
            disabled={disabledButton}
          >
            {t("competence.survey.send")}
          </SendButton>
          <CancelLink to={`/competence/${params.id}/${CompetenceTabURI.Competence}`}>
            {t("competence.survey.cancel")}
          </CancelLink>
        </Buttons>
      </Wrapper>
    </Container>
  )
};

SelfMarkSurvey.propTypes = {
  className: PropTypes.string,
};
