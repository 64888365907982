import { phpApi, nodeApi, handleApiError } from "../../../api";

export const getInitialData = (contentId) => {
  return phpApi.get(`/api/content/${contentId}/init`).catch(handleApiError);
};

export const getRelatedVideos = (contentId) => {
  return phpApi.get(`/api/content/${contentId}/related`);
};

export const getComments = (contentId) => {
  return nodeApi.get(`/comments/content/${contentId}`);
};

export const sendComment = (comment) => {
  return nodeApi.post(`/comments/content/${comment.contentId}`, comment);
};

export const deleteComment = (commentId) => {
  return nodeApi.delete(`/comments/${commentId}`);
};

export const editComment = (comment) => {
  return nodeApi.put(`/comments/${comment.id}`, comment);
};

export const sendFinishAction = (contentId, actionString) => {
  return phpApi.post(`/api/content/${contentId}/save-actions`, {
    actions: actionString,
  });
};
