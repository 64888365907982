import styled from "styled-components";

import { UserRanking as RankingBase } from "../../../../components/userRanking/userRanking.component";
import { Color, ContentMaxWidth, Media, PageHeader as HeaderBase } from "../../../../theme";

export const Container = styled.div``;

export const Wrapper = styled.div`
  max-width: ${ContentMaxWidth};
  width: 100%;
  margin: 0 auto;

  & + & {
    margin-top: 60px;
  }
`;

export const PageHeader = styled(HeaderBase)`
  max-width: ${ContentMaxWidth};
  width: 100%;
  margin: 0 auto 60px;
  padding-left: 20px;

  ${Media.tablet`
    padding-left: 0;
  `}
`;

export const Statistics = styled.div`
  width: 100%;
  background-color: ${Color.almostWhite};
  padding: 40px 0 60px;

  ${Media.tablet`
    padding: 40px 80px 60px;
  `}
`;

export const Row = styled.div`
  max-width: ${ContentMaxWidth};
  margin: 0 auto;

  > div:first-of-type {
    margin: 0 0 60px;
  }

  ${Media.desktop`
    display: flex;

    > div:first-of-type {
      margin: 0 20px 0 0;
    }
  `}
`;

export const MainContent = styled.div`
  padding: 60px 0;

  ${Media.tablet`
    padding: 60px 80px;
  `}
`;

export const UserRanking = styled(RankingBase)`
  width: 100%;

  ${Media.desktop`
    min-width: 480px;
    width: 30%;
  `}
`;
