import styled from "styled-components";

import {
  Color,
  Media,
  SectionHeader as SectionHeaderBase,
  PageHeader as PageHeaderBase,
  ContentMaxWidth,
} from "../../theme";

export const Container = styled.div`
  padding: 54px 0;
  background-color: ${Color.almostWhite};
  display: flex;
  justify-content: center;

  ${Media.tablet`
    padding: 54px 80px;
  `}

  ${Media.desktop`
    padding: 54px 160px;
  `}
`;

export const Wrapper = styled.div`
  max-width: ${ContentMaxWidth};
  width: 100%;
`;

export const PageHeader = styled(PageHeaderBase)`
  padding-top: 40px;
  margin-left: 20px;

  ${Media.tablet`
    margin-left: 0;
  `}
`;

export const SectionHeader = styled(SectionHeaderBase)`
  margin-top: 60px;
`;
