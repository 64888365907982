import styled, { css } from "styled-components";
import { prop } from "ramda";
import { Link } from "react-router-dom";

import { Color, FontWeight } from "../../../../theme";
import { styleWhenTrue } from "../../../../helpers/rendering";

export const CheckModalContent = styled.div`
  text-align: left;
`;

export const PopupHeader = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.deepGray};
  margin-bottom: 10px;
`;

export const ModalText = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${Color.gray9};

  & + & {
    margin-top: 15px;
  }

  ${styleWhenTrue(prop("$withMargin"), css`
    margin-top: 15px;
  `)}
`;

export const BoldText = styled.span`
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.deepGray};
  text-transform: uppercase;
`;

export const CourseList = styled.ul`
  margin-top: 15px;
  list-style: none;
`;

export const CourseItem = styled.li`
  color: ${Color.deepGray};
  font-size: 12px;
  line-height: 18px;
  font-weight: ${FontWeight.SemiBold};
  text-transform: uppercase;
  display: flex;
  align-items: center;

  & + & {
    margin-top: 10px;
  }
`;

export const Bullet = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${() => Color.brandBasic};
  margin-right: 12px;
`;

export const RegulationsLink = styled(Link)`
  color: ${() => Color.brandBasic};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
