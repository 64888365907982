import React from "react";
import { cond, equals, always } from "ramda";

import { isMobile as checkIfMobile } from "../../../theme/media";
import Bell from "./backgroundImages/bell.png";
import Circles from "./backgroundImages/circles.png";
import Messages from "./backgroundImages/messages.png";
import Money from "./backgroundImages/money.png";
import Notes from "./backgroundImages/notes.png";
import Phone from "./backgroundImages/phone.png";
import MobileBell from "./backgroundImages/mobileBell.svg";
import MobilePhone from "./backgroundImages/mobilePhone.svg";
import MobileMessages from "./backgroundImages/mobileMessages.svg";

import { Link } from "./onboardingInteractions.styled";

export const transformTitle = (text) => {
  const urlAndPhoneRegex = new RegExp(/(\+48[ 0-9]+)|(www.*pl)/);
  const urlRegex = new RegExp(/(www.*pl)/);
  const phoneRegex = new RegExp(/\+48[ 0-9]+/);

  const splittedText = text.split(urlAndPhoneRegex);
  return splittedText.map((textPart) => {
    if (urlRegex.test(textPart)) {
      return (
        <Link key={textPart} target="_blank" rel="noopener noreferrer" href={`http://${textPart}`}>
          {textPart}
        </Link>
      );
    }
    if (phoneRegex.test(textPart)) {
      return <Link key={textPart}>{textPart}</Link>;
    }
    return textPart;
  });
};

export const getBackgroundImage = (background) => {
  const isMobile = checkIfMobile();

  return cond([
    [equals("bell"), always(isMobile ? MobileBell : Bell)],
    [equals("messages"), always(isMobile ? MobileMessages : Messages)],
    [equals("phone"), always(isMobile ? MobilePhone : Phone)],
    [equals("circles"), always(Circles)],
    [equals("money"), always(Money)],
    [equals("notes"), always(Notes)]
  ])(background);
}
