import i18n from "i18next";

import { AppConfig, Theme } from "../../appConfig";
import { GamificationTheme } from "../mainNavigation/components/navItem.constants";

export const MainRoutes = [
  ...AppConfig.modules.filter((module) => [
    "dashboard",
    "knowledgeBase",
    "trainings",
    "profile",
  ].includes(module.moduleName)).map(({ urls, Icon, label, routeName, moduleName }) => ({
    urls,
    Icon,
    label: moduleName === "knowledgeBase"
      ? i18n.t("app.mainNavigation.knowledge")
      : moduleName === "trainings"
        ? i18n.t("app.mainNavigation.trainings")
        : i18n.t(label),
    routeName,
    moduleName,
  }))
];

export const MainRoutesOrder = [
  "dashboard",
  "knowledgeBase",
  "trainings",
  "profile",
];

export const OrderedMainRoutes = MainRoutesOrder.reduce((ordered, currentRoute) => {
  const newRoute = MainRoutes.find((route) => route.moduleName === currentRoute);
  if (newRoute) {
    return [...ordered, MainRoutes.find((route) => route.moduleName === currentRoute)];
  } else {
    return ordered;
  }
}, []);

export const DropdownRoutes = [
  ...AppConfig.modules
    .filter((module) => !MainRoutes.find((route) => route.moduleName === module.moduleName))
    .map(({ urls, Icon, label, routeName }) => ({
      urls,
      Icon,
      label: label === GamificationTheme.Gamification ? i18n.t(Theme.Gamification) : i18n.t(label),
      routeName,
    })),
];
