import styled from "styled-components";

import { ReactComponent as ArrowSvg } from "../../../../../images/arrow-3.svg";
import {
  Color,
  ContentMaxWidth,
  FontWeight,
  Media,
} from "../../../../../theme";

export const Container = styled.div`
  background-color: ${Color.almostWhite};
  padding: 60px 20px 40px;

  ${Media.tablet`
    padding: 40px 25px;
  `}

  ${Media.desktop`
    padding: 115px 80px;
  `}
`;

export const Wrapper = styled.div`
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  ${Media.tablet`
    flex-direction: row;
    align-items: center;
  `}
`;

export const TextBox = styled.div`
  max-width: 500px;
`;

export const Title = styled.h3`
  font-weight: ${FontWeight.ExtraBold};
  font-size: 30px;
  line-height: 41px;

  ${Media.tablet`
    font-size: 45px;
    line-height: 61px;
  `}
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 26px;
  margin-top: 25px;

  ${Media.tablet`
    font-size: 20px;
    line-height: 34px;
  `}
`;

export const AnimationBox = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 500px;
  pointer-events: none;
`;

export const ArrowIcon = styled(ArrowSvg)`
  fill: ${() => Color.brandBasic};
  transition: 0.2s;
`;

export const RoundButton = styled.button`
  margin-top: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid ${() => Color.brandBasic};
  transition: 0.2s;

  ${Media.tablet`
    &:hover {
      background-color: ${() => Color.brandBasic};
      ${ArrowIcon} {
        fill: ${Color.white};
      }
    }
  `}
`;
