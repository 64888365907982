import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

import { ConfirmationModal } from "../../components/confirmationModal/confirmationModal.component";
import { Button } from "../../components/button/button.component";
import { CommonActions } from "../../redux/common/common.reducer";
import { selectUserPhone } from "../../userContext/redux/userContext.selectors";
import { ContentContainer, LoadingWrapper, PageHeader } from "../../UIElements";
import { Color } from "../../theme";
import { useModuleConfig, ModuleType } from "../";

import { SummaryWrapper } from "./components/summaryWrapper/summaryWrapper.component";
import { QuizToc } from "./components/quizToc/quizToc.component";
import { Question } from "./components/question/question.component";
import { selectFormData, selectFormNav, selectIsLoading } from "./redux/form.selectors";
import { FormActions } from "./redux/form.reducer";
import {
  BackButton,
  Section,
  SectionHeader,
  ButtonWrapper,
} from "./form.styled";

const Form = () => {
  const config = useModuleConfig(ModuleType.Form);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const isLoading = useSelector(selectIsLoading);
  const form = useSelector(selectFormData);
  const formNav = useSelector(selectFormNav);
  const userPhone = useSelector(selectUserPhone);
  const [valid, setValid] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [phonePopupVisible, setPhonePopupVisible] = useState(false);

  useEffect(() => {
    dispatch(FormActions.getData(params.id));

    return () => {
      dispatch(FormActions.clearData());
    }
  }, []);

  const handleSetAnswer = (id, value) => {
    dispatch(FormActions.setAnswer(id, value));
  };

  const handleNextButtonClick = () => {
    setSubmitted(true);

    if (
      form.pages[formNav.currentPage].sections[0].questions.every(
        (question) => valid["v_" + question.id]
      )
    ) {
      dispatch(FormActions.saveData());
      setSubmitted(false);
    }
  }

  const handlePrevButtonClick = () => {
    dispatch(FormActions.setPrevPage());
  };

  const handleSaveData = () => {
    if (
      form.type === "event_entry"
      && form.pages[0].sections[1].questions[1].answers[0].answer !== userPhone
    ) {
      setPhonePopupVisible(true);
    } else {
      dispatch(FormActions.saveData());
    }
  };

  const handleFinishButtonClick = () => {
    setSubmitted(true);
    let formValid = true;

    for (const elem in valid) {
      if (!valid[elem]) {
        formValid = false;
      }
    }

    if (formValid) {
      if (window.confirm(t("form.form.doYouWantToFinish"))) {
        handleSaveData();
      }
    }
  };

  const finishEnrolling = (withPhoneUpdate) => {
    if (withPhoneUpdate) {
      dispatch(
        CommonActions.changeUserPhoneNumber(form.pages[0].sections[1].questions[1].answers[0].answer)
      );
    }

    dispatch(FormActions.saveData());
  }

  if (!form) {
    return (
      <ThemeProvider theme={config.theme}>
        <ContentContainer background={Color.white}>
          <LoadingWrapper isLoading={true} />
        </ContentContainer>
      </ThemeProvider>
    );
  }

  if (form.viewType) {
    return (
      <ContentContainer background={Color.white}>
        <BackButton defaultPath="/formularze" />
        <LoadingWrapper isLoading={isLoading}>
          <div>
            {form && <SummaryWrapper type={form.viewType} data={form} />}
          </div>
        </LoadingWrapper>
      </ContentContainer>
    );
  }

  return (
    <ThemeProvider theme={config.theme}>
      {form.pages.filter((page, index) => index === formNav.currentPage).map((page) => (
        <ContentContainer background={Color.white} key={page.id}>
          <BackButton isTestView defaultPath="/formularze" />
          {form.pages.length > 1 && (
            <QuizToc current={formNav.currentPage + 1} length={form.pages.length} />
          )}
          <PageHeader>{page.title}</PageHeader>
          {page.type === "text_page" && <div>{page.text}</div>}
          {page.type === "survey_page" && page.sections.map((section) => (
            <Section key={section.id}>
              <SectionHeader>{section.text}</SectionHeader>
              {section.questions.map((question) => (
                <Question
                  key={question.id}
                  question={question}
                  setAnswer={isLoading ? () => {} : handleSetAnswer}
                  section={section}
                  submitted={submitted}
                  valid={valid}
                  setValid={setValid}
                />
              ))}
            </Section>
          ))}
          <LoadingWrapper isLoading={isLoading}>
            <ButtonWrapper>
              {formNav.currentPage + 1 < form.pages.length && (
                <Button
                  onClick={handleNextButtonClick}
                  width={200}>
                  {t("global.next")}
                </Button>
              )}
              {formNav.currentPage + 1 === form.pages.length && (
                <Button
                  width={200}
                  onClick={handleFinishButtonClick}
                >
                  {t("global.next")}
                </Button>
              )}
              {formNav.currentPage > 0 && form.pages.length > 0 && (
                <Button onClick={handlePrevButtonClick} width={200}>
                  {t("global.previous")}
                </Button>
              )}
            </ButtonWrapper>
          </LoadingWrapper>
          <ConfirmationModal
            open={phonePopupVisible}
            onDecline={() => finishEnrolling(false)}
            onConfirm={() => finishEnrolling(true)}
          >
            {t("trainings.enrollPopup.phoneChanged")}
          </ConfirmationModal>
        </ContentContainer>
      ))}
    </ThemeProvider>
  );
};

export default Form;
