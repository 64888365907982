import { handleApiError, phpApi } from "../../../api";

export const getEventList = () => {
  return phpApi.get("/api/events").catch(handleApiError);
};

export const enrollEvent = (eventId, data) => {
  return phpApi.post(`/api/events/${eventId}/enroll`, data).catch(handleApiError);
};

export const disenrollEvent = (eventId) => {
  return phpApi.post(`/api/events/${eventId}/disenroll`).catch(handleApiError);
};

export const checkEventAccess = (eventId) => {
  return phpApi.get(`/api/events/${eventId}/enroll`).catch((error) => {
    if (error?.response?.status === 409) {
      if (error.response.data.blockingEvent) {
        return { blockingEvent: error.response.data.blockingEvent };
      } else {
        return { missingCourses: error.response.data.missingCourses };
      }
    } else {
      handleApiError(error);
    }
  });
};

export const checkEventDisenroll = (eventId) => {
  return phpApi.get(`/api/events/${eventId}/disenroll`).catch(handleApiError);
};

export const getEventDetails = (id) => {
  return phpApi.get(`/api/events/${id}`).catch(handleApiError);
};

export const fetchTrainerMetric = (trainerId) => {
  return phpApi.get(`/api/user/${trainerId}/metrics`).catch(handleApiError);
};
