import styled from "styled-components";

import { Media } from "../../../../theme";

export const Container = styled.div`
  ${Media.tablet`
    display: flex;
    align-items: center;
  `}
`;

export const ImageWrapper = styled.div`
  width: 100%;
  margin-bottom: 50px;

  ${Media.tablet`
    width: 56%;
    margin-bottom: 0;
  `}
`;

export const Image = styled.img``;
