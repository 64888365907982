import React from "react";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import PropTypes from "prop-types";

import Animation from "./anim/anim404.json";

import {
  Container,
  Text,
  ArrowImage,
  LinkText,
  Link,
} from "./notFoundPage.styled";

export const NotFoundPage = ({ hideLink }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Lottie
        animationData={Animation}
        loop
        rendererSettings={{
          preserveAspectRatio: "xMidYMid slice",
        }}
      />
      <Text>{t("notFoundPage.notFound")}</Text>
      {!hideLink && (
        <Link to="/">
          <ArrowImage />
          <LinkText>{t("notFoundPage.backToDashboard")}</LinkText>
        </Link>
      )}
    </Container>
  );
};

NotFoundPage.propTypes = {
  noLink: PropTypes.bool,
};
