import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Lottie from "lottie-react";

import {
  Container,
  LeftColumn,
  Name,
  Text,
  Shapes,
  Button,
  ButtonWrapper,
  RightColumn,
  AnimationWrapper,
} from "./winner.styled";

export const Winner = ({
  className,
  monsterName,
  monsterAnimation,
  monsterText,
  callComplete,
}) => {
  const { t } = useTranslation();

  const handleFinishTest = () => {
    callComplete();
  };

  return (
    <Container className={className}>
      <LeftColumn>
        <Name>{monsterName}</Name>
        <Text>{monsterText}</Text>
        <ButtonWrapper>
          <Button onClick={handleFinishTest}>
            {t("interactions.opponentTest.next")}
          </Button>
        </ButtonWrapper>
        <Shapes />
      </LeftColumn>
      <RightColumn>
        <AnimationWrapper>
          <Lottie
            animationData={monsterAnimation}
            loop
            rendererSettings={{
              preserveAspectRatio: "xMidYMid slice",
            }}
          />
        </AnimationWrapper>
      </RightColumn>
    </Container>
  );
};

Winner.propTypes = {
  className: PropTypes.string,
  monsterName: PropTypes.string,
  monsterAnimation: PropTypes.object,
};
