import { createActions, createReducer } from "reduxsauce";

export const { Types: AppSettingsTypes, Creators: AppSettingsActions } = createActions(
  {
    setIsLoading: ["isLoading"],
    getBrands: null,
    getBrandsSuccess: ["brands"],
    selectBrand: ["currentBrand"],
    deselectBrand: null,
    clearData: null,
  },
  { prefix: "APP_SETTINGS/" }
);

const INITIAL_STATE = {
  loading: false,
  brands: [],
  currentBrand: null,
};

const setIsLoading = (state, { isLoading }) => ({ ...state, isLoading });

const getBrandsSuccess = (state, { brands }) => ({ ...state, brands });

const selectBrand = (state, { currentBrand }) => ({ ...state, currentBrand });

const deselectBrand = (state) => ({ ...state, currentBrand: null });

const clearData = () => ({ ...INITIAL_STATE });

export const reducer = createReducer(INITIAL_STATE, {
  [AppSettingsTypes.SET_IS_LOADING]: setIsLoading,
  [AppSettingsTypes.GET_BRANDS_SUCCESS]: getBrandsSuccess,
  [AppSettingsTypes.SELECT_BRAND]: selectBrand,
  [AppSettingsTypes.DESELECT_BRAND]: deselectBrand,
  [AppSettingsTypes.CLEAR_DATA]: clearData,
});
