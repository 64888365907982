import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { TutorialStepPopup } from "../../components/tutorialStepPopup/tutorialStepPopup.component";
import { TargetPosition } from "../../components/tutorialStepPopup/tutorialStepPopup.constants";
import { TutorialStep } from "../../components/tutorialPopup/tutorialPopup.constants";
import { selectTutorialCurrentStep } from "../../redux/common/common.selectors";
import { selectAvailableRoutes } from "../../userContext/redux/userContext.selectors";
import { NavRouteName } from "../mainNavigation/mainNavigation.constants";

import { OrderedMainRoutes } from "./mobileNavigationMenu.constants";
import { NavigationDropdown } from "./components/navigationDropdown.component";
import {
  Container,
  NavItem,
  DotsIcon,
  NavTitle,
  MoreButton,
  TutorialWrapper,
} from "./mobileNavigationMenu.styled";

export const MobileNavigationMenu = () => {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const availableRoutes = useSelector(selectAvailableRoutes);
  const tutorialCurrentStep = useSelector(selectTutorialCurrentStep);

  const openDropdown = () => setDropdownOpen(true);

  const closeDropdown = () => setDropdownOpen(false);

  const toggleDropdown = () => {
    if (dropdownOpen) {
      closeDropdown();
    } else {
      openDropdown();
    }
  };

  const handleNavItemClick = (label) => {
    setActiveItem(label);
    if (dropdownOpen) setDropdownOpen(false);
  };

  return (
    <>
      <NavigationDropdown
        onNavItemClick={handleNavItemClick}
        closeDropdown={closeDropdown}
        availableRoutes={availableRoutes}
        open={dropdownOpen}
      />
      <Container>
        {OrderedMainRoutes.map(({ Icon, label, urls, routeName, moduleName }) => {
          if (moduleName === "profile" || availableRoutes?.[routeName]) {
            return (
              <TutorialWrapper key={label}>
                <NavItem
                  key={label}
                  to={urls[0]}
                  onClick={() => handleNavItemClick(label)}
                  selected={activeItem === label}
                >
                  <Icon />
                  <NavTitle>{t(label)}</NavTitle>
                </NavItem>
                {moduleName === "profile" && tutorialCurrentStep === TutorialStep.Profile && (
                  <TutorialStepPopup
                    topPositionInPx={-360}
                    leftPositionInPx={-240}
                    targetBottomInPx={-105}
                    targetRightInPx={53}
                    arrowLeftInPx={240}
                    targetPosition={TargetPosition.Bottom}
                  />
                )}
                {routeName === NavRouteName.KnowledgeBase && tutorialCurrentStep === TutorialStep.KnowledgeBase && (
                  <TutorialStepPopup
                    topPositionInPx={-352}
                    leftPositionInPx={-60}
                    targetBottomInPx={-105}
                    targetLeftInPx={48}
                    arrowLeftInPx={62}
                    targetPosition={TargetPosition.Bottom}
                  />
                )}
                {routeName === NavRouteName.Trainings && tutorialCurrentStep === TutorialStep.Trainings && (
                  <TutorialStepPopup
                    topPositionInPx={-372}
                    leftPositionInPx={-136}
                    targetBottomInPx={-105}
                    targetLeftInPx={132}
                    arrowLeftInPx={147}
                    targetPosition={TargetPosition.Bottom}
                  />
                )}
              </TutorialWrapper>
            )
          }
        })}
        <MoreButton
          onClick={toggleDropdown}
          selected={dropdownOpen}
        >
          <DotsIcon />
          <NavTitle>{t("app.mobileNavigationMenu.more")}</NavTitle>
        </MoreButton>
      </Container>
    </>
  );
};
