import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { ReactComponent as ArrowSvg } from "../../../../images/arrow-2.svg";
import { hexToRgba } from "../../../../helpers/colors";
import { styleWhenTrue, styleIfElse } from "../../../../helpers/rendering";
import { Color, Media, FontWeight } from "../../../../theme";
import { BoardFieldColor, BoardFieldType } from "../boardLegend/boardLegend.constants";

export const Container = styled.div`
  position: relative;
  flex: 1 1 330px;
  max-width: 375px;
  height: 375px;
  background-color: ${Color.white};
  display: flex;
  justify-content: center;
  margin: 0 auto;
  pointer-events: none;

  &:nth-of-type(1),
  &:nth-of-type(2) {
    justify-content: flex-end;
  }

  &:nth-of-type(5),
  &:nth-of-type(6) {
    justify-content: flex-start;
  }

  ${Media.tabletWide`
    &:nth-of-type(1) {
      order: 1;
    }

    &:nth-of-type(4) {
      order: 2;
    }

    &:nth-of-type(5) {
      order: 3;
    }

    &:nth-of-type(2) {
      order: 4;
    }

    &:nth-of-type(3) {
      order: 5;
    }

    &:nth-of-type(6) {
      order: 6;
    }
  `}

  ${styleWhenTrue(prop("isDisabled"), css`
    pointer-events: none;
    filter: grayscale(1);
  `)}

  ${styleWhenTrue(prop("isIntro"), css`
    pointer-events: auto;
  `)}

  ${Media.tabletWide`
    width: 325px;
    max-width: 325px;
    margin: 2px;
    pointer-events: auto;

    ${styleWhenTrue(prop("isDisabled"), css`
      pointer-events: none;
    `)}
  `}

  @media (min-width: 1260px) {
    width: 375px;
    max-width: 375px;
  }
`;

export const StageTitle = styled.p`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: right;
  width: 150px;
  font-size: 10px;
  line-height: 13px;
  font-weight: ${FontWeight.ExtraBold};
  text-transform: uppercase;
  color: ${Color.blue2};

  ${styleWhenTrue(prop("isDisabled"), css`
    color: ${Color.gray1};
  `)}

  ${styleWhenTrue(prop("passed"), css`
    color: ${Color.green};
  `)}
`;

export const LottieWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const StageBox = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 330px;
`;

const ButtonStyles = css`
  height: 46px;
  width: 138px;
  border: 1px solid ${Color.deepGray};
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${FontWeight.Bold};
`;

export const StartButton = styled.button`
  ${ButtonStyles}
  margin-top: 290px;
  background-color: ${Color.violet};
  color: ${Color.white};
  position: relative;
  right: 22px;
  padding-right: 18px;

  &:hover {
    background-color: ${Color.lighterViolet};
  }
`;

export const StartButtonAddon = styled.div`
  position: absolute;
  right: 0;
  width: 18px;
  height: 46px;
  border: 1px solid ${Color.deepGray};
  border-right: none;
`;

export const TaskField = styled.button`
  width: 24px;
  height: 24px;
  padding: 0;
  border: 1px solid ${Color.white};
  position: absolute;
  top: ${prop("y")}px;
  left: ${prop("x")}px;
  ${({ type }) => type && `background-color: ${BoardFieldColor[type]};`}

  ${styleWhenTrue(prop("inProgress"), css`
    background-color: ${BoardFieldColor[BoardFieldType.InProgress]};
  `)}

  ${styleWhenTrue(prop("passed"), css`
    background-color: ${BoardFieldColor[BoardFieldType.Passed]};
  `)}

  ${styleWhenTrue(propEq(BoardFieldType.Test, "type"), css`
    width: 90px;
    height: 90px;
    border: 1px solid ${BoardFieldColor[BoardFieldType.Test]};
    border-radius: 50%;
    background-color: ${Color.white};
  `)}

  ${styleWhenTrue(prop("selected"), css`
    background-color: ${Color.white};
    border: 1px solid ${Color.violet};

    ${styleWhenTrue(propEq(BoardFieldType.Test, "type"), css`
      background-color: ${Color.lightViolet};
    `)}
  `)}

  ${styleIfElse(prop("isDisabled"), css`
    cursor: default;
  `, css`
    &:hover {
      background-color: ${Color.white};
      border: 1px solid ${Color.violet};

      ${styleWhenTrue(propEq(BoardFieldType.Test, "type"), css`
        background-color: ${Color.lightViolet};
      `)}
    }
  `)}
`;

export const FinishSign = styled.div`
  ${ButtonStyles}
  position: absolute;
  top: calc(100% + 15px);
  left: -24px;
  background-color: ${Color.yellow2};
  color: ${Color.deepGray};
  pointer-events: none;
  padding-left: 9px;
`;

export const FinishButtonAddon = styled.div`
  position: absolute;
  left: 0;
  width: 18px;
  height: 46px;
  border: 1px solid ${Color.deepGray};
  border-left: none;
`;

export const HeroWrapper = styled.div`
  width: 60px;
  height: 60px;
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  border-radius: 50%;
  border: 5px solid ${Color.white};
  box-shadow: 0 0 2px ${hexToRgba(Color.black, 0.02)}, 0 2px 2px ${hexToRgba(Color.black, 0.02)}, 0 4px 6px ${hexToRgba(Color.black, 0.1)};
  pointer-events: none;

  &::after {
    content: " ";
    position: absolute;
    top: 107%;
    left: 50%;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: ${Color.white} transparent transparent transparent;
  }
`;

export const HeroImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const GameButton = styled.button`
  display: none;
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  z-index: 1;
  border-radius: 50%;
  background-color: ${Color.blue2};
  padding: 0;
  margin: 0;
  border: none;

  ${styleWhenTrue(prop("disabled"), css`
    background-color: ${Color.gray1};
  `)}

  ${Media.tabletWide`
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const ArrowImg = styled(ArrowSvg)`
  fill: ${Color.white};
  width: 12px;
  height: 12px;
`;
