import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { cond, equals, always, T } from "ramda";

import { ProgressMark } from "../../../../components/progressMark/progressMark.component";
import { AwardType } from "../../../../components/thumbnail/thumbnail.constants";
import { ButtonColor } from "../../../../components/button/button.constants";
import { timestampToDate } from "../../../../helpers/date";

import {
  Container,
  Header,
  TitleWrapper,
  Title,
  Date,
  ArrowWrapper,
  ArrowIcon,
  Content,
  ContentHeader,
  Separator,
  TestRow,
  TestDate,
  Chart,
  ChartWrapper,
  CorrectAnswers,
  Link,
  ProgressWithChildren,
  OverallResult,
  GoldMedal,
  SilverMedal,
  BronzeMedal,
  StarsRate,
  ClockIcon,
  Icons,
} from "./testItem.styled";

export const TestItem = ({
  className,
  title,
  dateTimestamp,
  attempts,
  result,
  award,
  competenceResult,
  minutes,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const date = timestampToDate(dateTimestamp);
  const overallResultInPercent = (result * 100).toFixed();

  const toggleOpen = () => setOpen(!open);

  const getBadge = cond([
    [equals(AwardType.Gold), always(GoldMedal)],
    [equals(AwardType.Silver), always(SilverMedal)],
    [equals(AwardType.Bronze), always(BronzeMedal)],
    [T, always(null)],
  ]);

  const renderOverallChart = () => {
    if (award) {
      const Medal = getBadge(award);
      return <Medal large="true" />;
    }

    if (competenceResult) {
      return (
        <ProgressMark
          rate={competenceResult.rate}
          maxRate={competenceResult.maxRate}
        />
      );
    }

    return (
      <ProgressWithChildren value={Number(overallResultInPercent)} strokeWidth={3}>
        <OverallResult>
          {overallResultInPercent}%
        </OverallResult>
      </ProgressWithChildren>
    );
  };

  const renderItemChart = (award, result, measureResult) => {
    if (measureResult) {
      return <StarsRate rate={measureResult.rate} />;
    }

    if (award) {
      const Medal = getBadge(award);
      return <Medal />;
    }

    return <Chart value={Number(result)} />;
  };

  const renderAttempt = (item) => {
    const percentResult = (item.result * 100).toFixed();
    const linkLabel = item.isOpen ? t("forms.formAndTest.test") : t("surveys.details");

    return (
      <React.Fragment key={item.id}>
        <TestRow>
          <TestDate>
            {dayjs.unix(item.createdAt).format("DD.MM.YYYY")}
          </TestDate>
          <ChartWrapper hidden={item.isOpen}>
            {renderItemChart(item.award, percentResult, item.competenceResult)}
            <CorrectAnswers hidden={item.competenceResult}>
              {percentResult}%
              {" "}
              {t("forms.formAndTest.correctAnswers")}
            </CorrectAnswers>
          </ChartWrapper>
          <Link
            to={`/formularze/${item.id}`}
            color={ButtonColor.Primary}
            outlined={(!item.isOpen).toString()}
          >
            {linkLabel}
          </Link>
        </TestRow>
        <Separator />
      </React.Fragment>
    );
  };

  const renderAttempts = () => attempts.slice().reverse().map(renderAttempt);

  return (
    <Container className={className}>
      <Header onClick={toggleOpen}>
        {renderOverallChart()}
        <TitleWrapper>
          <Title>{title}</Title>
          <Date>
            {t("forms.formAndTest.added")}:
            {" "}
            {date}
          </Date>
        </TitleWrapper>
        <Icons>
          {!!minutes && <ClockIcon />}
          <ArrowWrapper>
            <ArrowIcon open={open} />
          </ArrowWrapper>
        </Icons>
      </Header>
      <Content open={open}>
        <ContentHeader>{t("surveys.testAttempts")}</ContentHeader>
        <Separator $narrow />
        {renderAttempts()}
      </Content>
    </Container>
  );
};

TestItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  dateTimestamp: PropTypes.number,
  attempts: PropTypes.array,
  result: PropTypes.number,
  competenceResult: PropTypes.object,
  award: PropTypes.string,
  minutes: PropTypes.number,
};
