import styled, { css } from "styled-components";
import { prop } from "ramda";

import { BackButton as BackButtonBase } from "../../components/backButton/backButton.component";
import { styleWhenTrue } from "../../helpers/rendering";
import { Color, Media } from "../../theme";

export const Container = styled.div``;

export const BackButton = styled(BackButtonBase)`
  ${styleWhenTrue(prop("isTestView"), css`
    margin-bottom: 50px;
  `)}
`;

export const Section = styled.section`
  padding: 0px 0px 20px 0px;
  max-width: 100%;
  &:not(fist-of-type) {
    margin-bottom: 100px;
  }
`;

export const SectionHeader = styled.h2`
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 1.43;
  letter-spacing: 0.7px;
  text-align: left;
  color: ${Color.black};
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const ButtonWrapper = styled.div`
  margin-top: -50px;
  width: 100%;
  float: right;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  flex-direction: row-reverse;

  ${Media.mobile`
    width: 420px;
  `}
`;
