import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { FontWeight, Color, Media } from "../../../../../../../theme"
import { styleWhenTrue } from "../../../../../../../helpers/rendering";
import { ReactComponent as ArrowBaseIcon } from "../../../../../../../images/arrow.svg";
import { ReactComponent as CheckBaseIcon } from "../../../../../../../images/check.svg";
import { ReactComponent as CloseBaseIcon } from "../../../../../../../images/close.svg";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: ${Color.blue};
  background-color: ${Color.white};
  border: 1px solid ${Color.gray5};
  font-weight: ${FontWeight.Bold};
  font-size: 15px;
  padding: 20px;
  position: relative;
  box-sizing: border-box;

  ${styleWhenTrue(prop("correctTheme"), css`
    border: 2px solid ${Color.green};
    color: ${Color.green};
    padding: 19px;
  `)}
  
  ${styleWhenTrue(prop("wrongTheme"), css`
    border: 2px solid ${Color.red4};
    color: ${Color.red4};
    padding: 19px;
  `)}

  ${Media.tablet`
    width: 40%;
    margin-bottom: 5px;
    background-color: ${Color.transparent};
  `}

  ${Media.desktop`
    font-size: 18px;
  `}
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: ${Color.almostWhite};
  border: 2px solid ${Color.gray5};

  ${Media.tablet`
    margin-right: 10px;
  `}

  ${Media.desktop`
    margin-right: 20px;
  `}
  
  ${styleWhenTrue(prop("correctTheme"), css`
    border: 2px solid ${Color.green};
    background-color: ${Color.lightGreen};
  `)}
  
  ${styleWhenTrue(prop("wrongTheme"), css`
    border: 2px solid ${Color.red4};
    background-color: ${Color.lightPink};
  `)}
`;

export const Label = styled.span`
  letter-spacing: 0;
  line-height: 22px;

  ${Media.tablet`
    line-height: 26px;
  `}
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  left: 0;
  top: 101%;
  background-color: ${Color.white};
  width: 100%;
  box-shadow: 0 1px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  ${styleWhenTrue(prop("show"), css`
    display: block;
  `)}
`;

export const DropdownOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DropdownOption = styled.div`
  display: flex;
  align-items: center;
  min-height: 60px;
  font-size: 18px;
  font-weight: ${FontWeight.Normal};
  color: ${Color.almostBlack};
  cursor: pointer;
  padding-left: 22px;
  &:not(:first-of-type) {
    border-top: 2px solid ${Color.gray5};
  }
  &:hover {
    background-color: ${Color.whiteHover};
  }
`;

export const ArrowIcon = styled(ArrowBaseIcon)`
  height: 20px;
  width: 20px;
  transform: rotate(-90deg);
  fill: ${Color.blue};

  ${styleWhenTrue(propEq("true", "correct"), css`
    fill: ${Color.green};
  `)}

  ${styleWhenTrue(propEq("true", "wrong"), css`
    fill: ${Color.red4};
  `)}
`;

export const CorrectIcon = styled(CheckBaseIcon)`
  height: 100px;
  width: 100px;
  fill: ${Color.green};
`;

export const WrongIcon = styled(CloseBaseIcon)`
  height: 14px;
  width: 14px;
  g {
    stroke: ${Color.red4};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
