import styled, { css } from "styled-components";
import { prop } from "ramda";

import { FontWeight } from "../../../../theme/fonts";
import { Media } from "../../../../theme/media";
import { Color } from "../../../../theme/colors";
import { styleWhenTrue } from "../../../../helpers/rendering";

export const Container = styled.div`
  border-top: 1px solid ${Color.gray8};
  padding: 4px 0;
  display: flex;

  ${Media.tablet`
    padding: 20px 40px;
  `}
`;

export const SocialButton = styled.button`
  display: flex;
  align-items: center;
  background-color: ${Color.transparent};
  border: 0;
  width: 50%;
  justify-content: center;
  padding: 16px 0;
  color: ${Color.gray4};
  cursor: pointer;

  &:hover {
    color: ${() => Color.brandBasic};
  }

  &:last-of-type {
    border-left: 1px solid ${Color.gray8};
  }

  ${Media.tablet`
    padding: 0;
    justify-content: flex-start;
    width: auto;

    &:last-of-type {
      margin-left: 38px;
      border-left: 0;

      ${styleWhenTrue(prop("likeCounterVisible"), css`
        margin-left: 18px;
      `)}
    }
  `}
`;

export const ButtonText = styled.span`
  font-size: 14px;
  line-height: 22px;
  margin-left: 13px;

  ${styleWhenTrue(prop("liked"), css`
    font-weight: ${FontWeight.Bold};
    color: ${Color.deepGray};
  `)}
`;
