import styled from "styled-components";

import { hexToRgba } from "../../../../../helpers/colors";
import { Color, FontWeight, Media } from "../../../../../theme";
import { ReactComponent as CloseSVG } from "../../../../../images/close2.svg";
import { Button as ButtonBase } from "../../../../../components/button/button.component";
import { ReactComponent as ModalDiagramSVG } from "../../../images/diagramWithBackground.svg";

export const CloseIcon = styled(CloseSVG)`
  height: 12px;
  width: 12px;
  fill: ${Color.deepGray};
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: ${Color.transparent};
  border: none;

  ${Media.tablet`
    right: 30px;
    top: 30px;
  `}
`;

export const ModalContent = styled.div`
  padding: 50px 20px 20px;
  color: ${Color.deepGray};
  position: relative;

  ${Media.tablet`
    padding: 130px;
  `}

  ${Media.tabletWide`
    padding: 180px;
  `}
`;

export const ModalBoldText = styled.p`
  font-weight: ${FontWeight.Bold};
  font-size: 12px;
  margin-bottom: 13px;
  text-transform: uppercase;
`;

export const ModalTitle = styled.h1`
  font-size: 25px;
  font-weight: ${FontWeight.Light};
  padding-bottom: 25px;
  border-bottom: 1px solid ${hexToRgba(Color.gray5, 0.8)};

  ${Media.tablet`
    padding-bottom: 30px;
    font-size: 28px;
  `}
`;

export const ModalText = styled.p`
  font-size: 13px;
  line-height: 24px;
  padding-top: 20px;

  &:last-of-type {
    margin-bottom: 30px;
  }


  ${Media.tablet`
    font-size: 15px;
    line-height: 26px;
  `}
`;

export const ModalDiagramIcon = styled(ModalDiagramSVG)`
  margin-bottom: 20px;

  ${Media.tablet`
    margin-bottom: 30px;
  `}
`;

export const Button = styled(ButtonBase)`
  width: 100%;

  ${Media.tablet`
    width: auto;
  `}
`;
