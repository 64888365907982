import {
  Breakpoint,
  Button,
  ButtonSize,
  ButtonTheme,
  FontWeight,
  IconName,
  IconSelectBox,
  InfoChipTheme,
  Typography,
  TypographyVariant,
  UseState,
} from '@elearning-platform/ui';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { AnyAction, Dispatch } from 'redux';

import { Path } from '../../../../enums';
import { RegistrationActions } from '../../redux/registration.actions';
import { selectAccountType } from '../../redux/registration.selectors';
import { Buttons } from '../../registration.styled';
import { AccountType } from '../../registration.types';

import { Container, SelectBoxes, StyledButton, StyledInfoChip } from './step1.styled';
import { Step1Props } from './step1.types';

export const Step1: React.FC<Step1Props> = ({ goNext }: Step1Props): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const dispatch: Dispatch<AnyAction> = useDispatch();
  const isSmallDesktop: boolean = useMediaQuery({ query: Breakpoint.SmallDesktop });
  const [infoVisible, setInfoVisible]: UseState<boolean> = useState<boolean>(false);
  const accountType: AccountType | null = useSelector(selectAccountType) as AccountType | null;

  const handleAnswerChoose: (value: AccountType) => void = (value: AccountType): void => {
    dispatch(RegistrationActions.setAccountType(value));
    setInfoVisible(false);
  };

  const validateAnswer: () => void = (): void => {
    setInfoVisible(!accountType);

    if (accountType) {
      goNext();
    }
  };

  return (
    <Container>
      <Typography
        fontWeight={FontWeight.Bold}
        variant={isSmallDesktop ? TypographyVariant.TextLG : TypographyVariant.TextMD}
      >
        {t('registration.step1.existingAccount')}
      </Typography>
      <SelectBoxes>
        <IconSelectBox
          headline={t('registration.step1.no')}
          icon={IconName.UserAdd}
          onClick={(): void => handleAnswerChoose('new')}
          selected={accountType === 'new'}
          supportingText={t('registration.step1.willCreateAccount')}
        />
        <IconSelectBox
          headline={t('registration.step1.yes')}
          icon={IconName.UsersSwitch}
          onClick={(): void => handleAnswerChoose('existing')}
          selected={accountType === 'existing'}
          supportingText={t('registration.step1.connectAccount')}
        />
      </SelectBoxes>
      <Buttons>
        <StyledButton
          label={t('registration.step1.next')}
          onClick={validateAnswer}
          size={ButtonSize.Large}
          variant={ButtonTheme.PrimaryRed}
        />
        <Button
          internalPath={Path.Main}
          label={t('registration.step1.cancel')}
          size={ButtonSize.Large}
          variant={ButtonTheme.TertiaryGray}
        />
        {!!infoVisible && (
          <StyledInfoChip
            content={t('registration.step1.chooseAnswer')}
            icon={IconName.Warning}
            variant={InfoChipTheme.Yellow}
          />
        )}
      </Buttons>
    </Container>
  );
};
