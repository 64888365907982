import { createActions, createReducer } from "reduxsauce";

import { StreamType } from "../services/streams.constants";

export const {
  Types: WebinarsTypes,
  Creators: WebinarsActions,
} = createActions(
  {
    setIsPending: ["isPending"],
    joinRoom: ["id", "from"],
    joinRoomFailure: ["error"],
    setCurrentRoom: ["room"],
    createStream: ["streamType", "deviceId"],
    handleToggleMedia: ["room", "streamType", "deviceId"],
    toggleRemoteCameraSuccess: ["remoteCameraEnabled"],
    setUserAuthorized: null,
    handleGetRoomAccess: null,
    getRoomAccessSuccess: ["permissions"],
    handleSendMessage: ["message", "receiverId"],
    receiveMessageSuccess: ["message"],
    fetchMessagesSuccess: ["messages"],
    handleSendFile: ["file", "receiverId"],
    setFileErrorStatus: ["fileErrorStatus"],
    setFileUploadProgress: ["fileUploadProgress"],
    receiveChatActivationSuccess: ["chatActivate"],
    handleSendPermissionRequest: ["streamType"],
    sendPermissionRequestSuccess: ["requestSentType"],
    receivePermissionRequest: ["request"],
    handleShareResponse: ["streamType", "participantId", "accepted"],
    receivePermissionResponse: ["accepted"],
    handleAddLocalStream: ["stream"],
    removeRequest: null,
    liveStartSuccess: null,
    liveEndSuccess: null,
    handleEndLive: null,
    handleStartLive: null,
    handleStartRecording: null,
    handlePauseRecording: null,
    updateDevices: ["videoDevices", "audioDevices"],
    setCurrentVideo: ["deviceId"],
    setCurrentAudio: ["deviceId"],
    clearData: null,
    handleLeaveRoom: null,
    openRoom: ["room"],
    openRoomSuccess: ["room"],
    closeRoom: ["roomId"],
    kickUser: ["roomId", "userId"],
    assignUserToRoom: ["roomId", "userId"],
    setJoinToRoomModal: ["roomData"],
    setRedirectScreen: ["redirectScreenOpen"],
    acceptRoomAssign: ["roomId"],
    setSpeakingUser: ["speaking", "mainStream"],
    openFlipchart: null,
    openFlipchartSuccess: ["flipchartId"],
    closeFlipchart: null,
    closeFlipchartSuccess: null,
    flipchartAddElement: ["element", "elementId"],
    flipchartModifyElement: ["element", "elementId"],
    flipchartRemoveElement: ["elementId"],
    flipchartClearCanvas: null,
    handleLeaveGroup: null,
    setLastFlipchartData: ["data", "author"],
    sendFlipchartState: ["data"],
    connectFlipchartSuccess: ["initFlipchartData"],
    muteStream: ["streamType", "participantId"],
    showStreamMutedModal: ["streamType"],
    hideStreamMutedModal: ["streamType"],
    sendPing: null,
    sendPingSuccess: ["pongReceived"],
    setCameraLoader: ["isCameraLoading"],
    setAudioLoader: ["isAudioLoading"],
    setTurnServerConfig: ["turnServerConfig"],
    setDevicePermissionError: ["devicePermissionFailed"],
  },
  { prefix: "WEBINARS/" }
);

const INITIAL_STATE = {
  isPending: false,
  rooms: [],
  error: null,
  room: {
    type: "",
    live: false,
    liveStartTime: null,
    liveEndTime: null,
    chatActivate: false,
    chatStartTime: 0,
    participants: {
      length: null,
      list: [],
    },
    streams: [],
    recording: false,
    recordingPaused: false,
    recordingTime: 0,
    files: [],
    groups: [],
    flipchartId: null,
    lastFlipchartData: null,
    lastFlipchartAuthor: null,
    initFlipchartData: null,
    cyclical: false,
  },
  remoteCameraEnabled: false,
  userAuthorized: false,
  permissions: {
    shareCamera: null,
    shareScreen: null,
    shareCameraWithPermission: null,
    shareScreenWithPermission: null,
  },
  messages: [],
  requests: [],
  requestSentType: null,
  isResponseNegative: false,
  videoDevices: [],
  audioDevices: [],
  currentVideo: null,
  currentAudio: null,
  joinToRoomModalData: null,
  redirectScreenOpen: false,
  speaking: null,
  mainStream: null,
  streamsMuted: [],
  pongReceived: true,
  isCameraLoading: false,
  isAudioLoading: false,
  fileErrorStatus: false,
  fileUploadProgress: 0,
  turnServerConfig: null,
  devicePermissionError: null,
};

const showStreamMutedModal = (state, { streamType }) => ({
  ...state,
  streamsMuted: [...state.streamsMuted, streamType],
});

const hideStreamMutedModal = (state, { streamType }) => ({
  ...state,
  streamsMuted: state.streamsMuted.filter((stream) => stream !== streamType),
});

const joinRoomFailure = (state, { error }) => ({
  ...state,
  error,
});

const setCurrentRoom = (state, { room }) => ({ ...state, room });

const toggleRemoteCameraSuccess = (state, { remoteCameraEnabled }) => ({
  ...state,
  remoteCameraEnabled,
});

const setUserAuthorized = (state) => ({
  ...state,
  userAuthorized: true,
});

const getRoomAccessSuccess = (state, { permissions }) => ({
  ...state,
  permissions,
});

const receiveMessageSuccess = (state, { message }) => {
  const newMessage = {
    avatar: message.participant.user.avatar,
    firstName: message.participant.user.name,
    lastName: message.participant.user.surname,
    isCurrentUser: message.participant.user.isCurrentUser,
    message: message.message,
    fromSystem: message.fromSystem,
    type: message.type,
    timestamp: message.createdTime,
    file: message.file,
    recipient: message.recipient,
  };
  return {
    ...state,
    messages: [...state.messages, newMessage],
  };
};

const fetchMessagesSuccess = (state, { messages }) => {
  const parsedMessages = messages.map((message) => ({
    avatar: message.participant.user.avatar,
    firstName: message.participant.user.name,
    lastName: message.participant.user.surname,
    isCurrentUser: message.participant.user.isCurrentUser,
    message: message.message,
    fromSystem: message.fromSystem,
    type: message.type,
    timestamp: message.createdTime,
    file: message.file,
    recipient: message.recipient,
  }));

  return {
    ...state,
    messages: parsedMessages,
  }
};

const setFileErrorStatus = (state, { fileErrorStatus }) => ({
  ...state,
  fileErrorStatus,
});

const setFileUploadProgress = (state, { fileUploadProgress }) => ({
  ...state,
  fileUploadProgress,
});

const receiveChatActivationSuccess = (state, { chatActivate }) => ({
  ...state,
  room: {
    ...state.room,
    chatActivate,
  }
});

const receivePermissionRequest = (state, { request }) => ({
  ...state,
  requests: [...state.requests, request],
});

const sendPermissionRequestSuccess = (state, { requestSentType }) => ({
  ...state,
  requestSentType,
});

const receivePermissionResponse = (state, { accepted }) => {
  if (!accepted) {
    return {
      ...state,
      isResponseNegative: !accepted,
    };
  } else {
    return {
      ...state,
      requestSentType: null,
      isResponseNegative: false,
    };
  }
};

const removeRequest = (state) => {
  return {
    ...state,
    requests: [...state.requests.slice(1)],
  };
}

const liveStartSuccess = (state) => ({
  ...state,
  room: {
    ...state.room,
    live: true,
  }
});

const liveEndSuccess = (state) => ({
  ...state,
  room: {
    ...state.room,
    live: false,
  },
});

const updateDevices = (state, { videoDevices, audioDevices }) => ({
  ...state,
  videoDevices,
  audioDevices,
});

const setCurrentVideo = (state, { deviceId }) => ({
  ...state,
  currentVideo: deviceId,
});

const setCurrentAudio = (state, { deviceId }) => ({
  ...state,
  currentAudio: deviceId,
});

const setSpeakingUser = (state, { speaking, mainStream }) => ({
  ...state,
  speaking,
  mainStream: mainStream ?? state.mainStream,
});

const setIsPending = (state, { isPending }) => ({ ...state, isPending });

const clearData = () => {
  return {
    ...INITIAL_STATE,
    userAuthorized: true,
  };
};

const openRoomSuccess = (state, { room }) => ({
  ...state,
  room: {
    ...state.room,
    groups: [...state.room.groups, room],
  },
});

const setJoinToRoomModal = (state, { roomData }) => ({
  ...state,
  joinToRoomModalData: roomData,
});

const setRedirectScreen = (state, { redirectScreenOpen }) => ({
  ...state,
  redirectScreenOpen,
});

const openFlipchartSuccess = (state, { flipchartId }) => ({
  ...state,
  room: {
    ...state.room,
    flipchartId,
  },
});

const closeFlipchartSuccess = (state) => ({
  ...state,
  room: {
    ...state.room,
    flipchartId: null,
    lastFlipchartData: null,
    initFlipchartData: null,
  },
});

const setLastFlipchartData = (state, { data, author }) => ({
  ...state,
  room: {
    ...state.room,
    lastFlipchartData: data,
    lastFlipchartAuthor: author,
  }
});

const connectFlipchartSuccess = (state, { initFlipchartData }) => ({
  ...state,
  room: {
    ...state.room,
    initFlipchartData,
  }
});

const sendPingSuccess = (state, { pongReceived }) => ({ ...state, pongReceived });

const setCameraLoader = (state, { isCameraLoading }) => ({ ...state, isCameraLoading });

const setAudioLoader = (state, { isAudioLoading }) => ({ ...state, isAudioLoading });

const setTurnServerConfig = (state, { turnServerConfig }) => ({ ...state, turnServerConfig });

const setDevicePermissionError = (state, { devicePermissionError }) => ({
  ...state,
  isCameraLoading: devicePermissionError !== StreamType.Camera ? state.isCameraLoading : false,
  isAudioLoading: devicePermissionError !== StreamType.Audio ? state.isAudioLoading : false,
  devicePermissionError,
});

export const reducer = createReducer(INITIAL_STATE, {
  [WebinarsTypes.JOIN_ROOM_FAILURE]: joinRoomFailure,
  [WebinarsTypes.SET_IS_PENDING]: setIsPending,
  [WebinarsTypes.SET_CURRENT_ROOM]: setCurrentRoom,
  [WebinarsTypes.TOGGLE_REMOTE_CAMERA_SUCCESS]: toggleRemoteCameraSuccess,
  [WebinarsTypes.SET_USER_AUTHORIZED]: setUserAuthorized,
  [WebinarsTypes.GET_ROOM_ACCESS_SUCCESS]: getRoomAccessSuccess,
  [WebinarsTypes.RECEIVE_MESSAGE_SUCCESS]: receiveMessageSuccess,
  [WebinarsTypes.FETCH_MESSAGES_SUCCESS]: fetchMessagesSuccess,
  [WebinarsTypes.RECEIVE_CHAT_ACTIVATION_SUCCESS]: receiveChatActivationSuccess,
  [WebinarsTypes.RECEIVE_PERMISSION_REQUEST]: receivePermissionRequest,
  [WebinarsTypes.REMOVE_REQUEST]: removeRequest,
  [WebinarsTypes.SEND_PERMISSION_REQUEST_SUCCESS]: sendPermissionRequestSuccess,
  [WebinarsTypes.RECEIVE_PERMISSION_RESPONSE]: receivePermissionResponse,
  [WebinarsTypes.LIVE_START_SUCCESS]: liveStartSuccess,
  [WebinarsTypes.LIVE_END_SUCCESS]: liveEndSuccess,
  [WebinarsTypes.UPDATE_DEVICES]: updateDevices,
  [WebinarsTypes.SET_CURRENT_VIDEO]: setCurrentVideo,
  [WebinarsTypes.SET_CURRENT_AUDIO]: setCurrentAudio,
  [WebinarsTypes.SET_SPEAKING_USER]: setSpeakingUser,
  [WebinarsTypes.CLEAR_DATA]: clearData,
  [WebinarsTypes.OPEN_ROOM_SUCCESS]: openRoomSuccess,
  [WebinarsTypes.SET_JOIN_TO_ROOM_MODAL]: setJoinToRoomModal,
  [WebinarsTypes.SET_REDIRECT_SCREEN]: setRedirectScreen,
  [WebinarsTypes.OPEN_FLIPCHART_SUCCESS]: openFlipchartSuccess,
  [WebinarsTypes.CLOSE_FLIPCHART_SUCCESS]: closeFlipchartSuccess,
  [WebinarsTypes.SET_LAST_FLIPCHART_DATA]: setLastFlipchartData,
  [WebinarsTypes.CONNECT_FLIPCHART_SUCCESS]: connectFlipchartSuccess,
  [WebinarsTypes.SHOW_STREAM_MUTED_MODAL]: showStreamMutedModal,
  [WebinarsTypes.HIDE_STREAM_MUTED_MODAL]: hideStreamMutedModal,
  [WebinarsTypes.SEND_PING_SUCCESS]: sendPingSuccess,
  [WebinarsTypes.SET_CAMERA_LOADER]: setCameraLoader,
  [WebinarsTypes.SET_AUDIO_LOADER]: setAudioLoader,
  [WebinarsTypes.SET_FILE_ERROR_STATUS]: setFileErrorStatus,
  [WebinarsTypes.SET_FILE_UPLOAD_PROGRESS]: setFileUploadProgress,
  [WebinarsTypes.SET_TURN_SERVER_CONFIG]: setTurnServerConfig,
  [WebinarsTypes.SET_DEVICE_PERMISSION_ERROR]: setDevicePermissionError,
});
