import { all, put, takeLatest } from "redux-saga/effects";

import * as surveysApi from "../services/api";

import { SurveysTypes, SurveysActions } from "./surveys.reducer";

function* fetchLists() {
  yield put(SurveysActions.setIsPending(true));
  const { data } = yield surveysApi.fetchLists();
  yield put(SurveysActions.fetchListsSuccess(data));
  yield put(SurveysActions.setIsPending(false));
}

export function* watchSurveys() {
  yield all([
    yield takeLatest(SurveysTypes.FETCH_LISTS, fetchLists),
  ]);
}
