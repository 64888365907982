import styled, { css } from "styled-components";
import { prop } from "ramda";

import { ReactComponent as PlaySvg } from "../../../../images/play.svg";
import { ReactComponent as PauseSvg } from "../../../../images/pause.svg";
import { ReactComponent as LoudSoundSvg } from "../../../../images/volume-high.svg";
import { ReactComponent as QuietSoundSvg } from "../../../../images/volume-medium.svg";
import { ReactComponent as MutedSvg } from "../../../../images/volume-muted.svg";
import { hexToRgba } from "../../../../helpers/colors";
import { styleWhenTrue } from "../../../../helpers/rendering";
import {
  Color,
  Separator as SeparatorBase,
  FontWeight,
  Media,
} from "../../../../theme";
import SpeedOption from "../speedOption/speedOption.component";

export const Container = styled.div`
  background-color: ${Color.white};
  padding-top: 12px;

  ${styleWhenTrue(prop("$fullscreen"), css`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  `)}
`;

export const PlayButton = styled.button`
  background-color: ${() => Color.brandBasic};
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 4px 0 ${hexToRgba(Color.brandBasic, 0.1)},
    0 0 10px 0 ${hexToRgba(Color.brandBasic, 0.1)};
  transition: opacity 0.5s ease-in-out;
  flex-shrink: 0;

  &:hover {
    opacity: 0.7;
  }
`;

export const Separator = styled(SeparatorBase)`
  margin-top: 12px !important;
`;

export const PauseIcon = styled(PauseSvg)`
  fill: ${Color.white};
`;

export const PlayIcon = styled(PlaySvg)`
  fill: ${Color.white};
  position: relative;
  left: 1px;
`;

export const TextWrapper = styled.div`
  padding: 20px;

  ${Media.tablet`
    padding: 30px;
  `}
`;

export const TagsWrapper = styled.div`
  display: flex;
  padding: 20px 20px 0;
  flex-wrap: wrap;

  & + ${TextWrapper} {
    padding-top: 5px;
  }

  ${Media.tablet`
    padding: 23px 30px 0;
  `}
`;

export const Title = styled.h3`
  font-size: 20px;
  line-height: 20px;
  color: ${Color.black};
  font-weight: ${FontWeight.Bold};
`;

export const ToolsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 14px 0 20px;

  ${Media.tablet`
    padding: 0 24px 0 30px;
  `}
`;

export const SliderWrapper = styled.div`
  display: flex;
  flex: 3;
  align-items: center;
  margin-left: 15px;

  ${styleWhenTrue(prop("disabled"), css`
    pointer-events: none;
  `)}
`;

export const TimeText = styled.p`
  font-size: 10px;
  line-height: 20px;
  color: ${Color.gray4};
  flex-shrink: 0;
  margin-left: 15px;
`;

export const CurrentTime = styled.span`
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.darkGray};
`;

export const SoundSlider = styled.div`
  background-color: ${Color.almostWhite};
  border-radius: 3px;
  max-width: 20%;
  height: 36px;
  margin-left: 9px;
  display: none;
  padding-left: 15px;
  padding-right: 15px;

  ${Media.tabletWide`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex: 1;
  `}
`;

export const SoundIconStyles = css`
  fill: ${() => Color.brandBasic};
  margin-right: 10px;
`;

export const LoudSoundIcon = styled(LoudSoundSvg)`
  ${SoundIconStyles}
`;

export const QuietSoundIcon = styled(QuietSoundSvg)`
  ${SoundIconStyles}
`;

export const MutedIcon = styled(MutedSvg)`
  ${SoundIconStyles}
`;

export const SoundButton = styled.button`
  background-color: ${Color.transparent};
  border: none;
  padding: 0;
  height: 24px;
`;

export const PanelButton = styled.button`
  background-color: ${Color.transparent};
  border: none;
  color: ${Color.gray1};
  margin-left: 9px;

  &:hover {
    color: ${() => Color.brandBasic};
  }

  ${styleWhenTrue(prop("deactivated"), css`
    color: ${Color.gray1};
  `)}
`;

export const Subtitle = styled.p`
  font-size: 13px;
  line-height: 20px;
  margin-top: 12px;
`;

export const SubtitleText = styled.span`
  margin-left: 16px;
  color: ${Color.darkGray};
`;

export const Bold = styled.span`
  font-weight: ${FontWeight.SemiBold};
`;

export const Description = styled.p`
  margin-top: 25px;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 22px;
  color: ${Color.deepGray};
  font-weight: ${FontWeight.Light};
  max-width: 646px;

  ${Media.tablet`
    margin-bottom: 30px;
  `}
`;

export const StyledSpeedOption = styled(SpeedOption)`
  margin-left: 9px;
`;
