import React from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { CommonActions } from "../../redux/common/common.reducer";
import { selectUserRole } from "../../userContext/redux/userContext.selectors";
import { UserRoleName } from "../../userContext/userContext.constants";

import { Tag } from "./tagList.styled";

export const TagList = ({ tags }) => {
  const dispatch = useDispatch();
  const userRole = useSelector(selectUserRole);
  if (userRole?.name === UserRoleName.Onboarding || !tags) return null;

  const openSearchPopup = () => {
    dispatch(CommonActions.setSearchPopupActive(true));
  };

  const renderCompetences = () =>
    tags.map((tag) => {
      const name = _.upperFirst(_.camelCase(tag.name));
      return (
        <React.Fragment key={tag.id}>
          <Tag
            onClick={openSearchPopup}
            state={{
              competence: `#${name}`,
            }}
          >
            #{name}
          </Tag>
          {!!tag.skills && renderCompetenceSkills(tag)}
        </React.Fragment>
      );
    });

  const renderCompetenceSkills = (competence) =>
    competence.skills.map((skill) => {
      const name = _.upperFirst(_.camelCase(skill.name));
      return (
        <Tag
          key={skill.id}
          onClick={openSearchPopup}
          state={{
            competence: `#${name}`,
          }}
        >
          #{name}
        </Tag>
      );
    });

  return renderCompetences();
};
