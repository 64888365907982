import styled, { css } from "styled-components";

import { ReactComponent as MessageSvg } from "../../../../../images/message.svg";
import { ReactComponent as SurveyAndTestSvg } from "../../../../../images/surveyAndTest.svg";
import { ReactComponent as VideoSvg } from "../../../../../images/video3.svg";
import { ReactComponent as QuestionnaireSvg } from "../../../../../images/questionnaire.svg";
import { ReactComponent as InfoSvg } from "../../../../../images/infoTip2.svg";
import { Color, FontWeight, Media } from "../../../../../theme";
import { ReactComponent as CoinSvg } from "../../../images/yellowCoin.svg";

export const Container = styled.div`
  background-color: ${Color.white};
  margin-bottom: 2px;
  padding: 33px 20px 33px;
  display: flex;
  align-items: center;

  ${Media.tablet`
    padding: 33px 30px 33px 35px;
  `}
`;

export const TextWrapper = styled.div`
  ${Media.tablet`
    display: flex;
    align-items: center;

    ${Media.tablet`
      width: 100%;
    `}
  `}
`;

export const Title = styled.p`
  font-weight: ${FontWeight.SemiBold};
  font-size: 14px;
  line-height: 20px;
  color: ${Color.deepGray};
  margin-right: 11px;
`;

export const Date = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${Color.gray9};
  margin-left: auto;
`;

export const Points = styled.p`
  background-color: ${Color.almostWhite};
  border-radius: 3px;
  width: 70px;
  font-size: 14px;
  line-height: 19px;
  font-weight: ${FontWeight.Bold};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  flex-shrink: 0;
  margin-left: 10px;

  ${Media.tablet`
    margin-left: 40px;
  `}
`;

export const CoinIcon = styled(CoinSvg)`
  margin-right: 5px;
`;

export const IconWrapper = styled.div`
  width: 26px;
  margin-right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${Media.tablet`
    margin-right: 35px;
  `}
`;

const iconStyles = css`
  fill: ${() => Color.brandBasic};
`;

export const MessageIcon = styled(MessageSvg)`
  ${iconStyles}
`;

export const SurveyAndTestIcon = styled(SurveyAndTestSvg)`
  ${iconStyles}
`;

export const VideoIcon = styled(VideoSvg)`
  ${iconStyles}
`;

export const QuestionnaireIcon = styled(QuestionnaireSvg)`
  ${iconStyles}
`;

export const InfoIcon = styled(InfoSvg)`
  ${Media.tablet`
    margin-right: 20px;
  `}
`;

export const PointsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const ModalWrapper = styled.div`
  font-size: 14px;
  line-height: 21px;
  text-align: left;
`;

export const ModalTitle = styled.p`
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: 10px;
`;
