export const AvatarTheme = {
  Initials: "initials",
  Image: "image",
};

export const AvatarType = {
  Default: "default",
  Small: "small",
  Large: "large",
  ExtraLarge: "extraLarge",
};
