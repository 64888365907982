import styled from "styled-components";

import { Color, Media } from "../../../../../theme";
import { ReactComponent as InfoTipIcon } from "../../../../../images/infoTip.svg";

export const TipContent = styled.div`
  visibility: hidden;
  font-size: 12px;
  padding: 15px;
  position: absolute;
  top: 50px;
  right: -10px;
  width: calc(100vw - 20px);
  line-height: 22px;
  color: ${Color.deepGray};
  background-color: ${Color.white};
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.1), 0 0 10px 0 rgba(0,0,0,0.02);

  p:not(:first-of-type) {
    margin-top: 8px;
  }

  ${Media.mobile`
    width: 400px;
    font-size: 15px;
    right: 0;
  `}
`;

export const TipTitle = styled.div`
  margin-right: 8px;
  font-size: 15px;
  font-weight: 600;
  color: ${Color.gray3};
  text-transform: uppercase;
`;

export const TipIcon = styled(InfoTipIcon)`
  width: 33px;
`;

export const Container = styled.div`
  position: absolute;
  right: 20px;
  top: 60px;
  display: flex;
  align-items: center;

  ${TipIcon}:hover + ${TipContent} {
    visibility: visible;
  }

  ${Media.tablet`
    top: 80px;
    right: 40px;
  `}
`;
