import React from "react";

const Navigation = ({moveNext, movePrev}) => (
  <div className="nav-container">
    <svg className="arrow arrow__next" width="23px" height="40px" viewBox="0 0 23 40" onClick={ moveNext }>
      <path fill="currentColor" d="M3.333,39.288c-0.271,0.271-0.61,0.408-0.985,0.408c-0.373,0-0.713-0.137-0.984-0.408
                        c-0.543-0.544-0.543-1.427,0-1.97L18.682,20L1.363,2.68c-0.543-0.543-0.543-1.426,0-1.97c0.543-0.542,1.427-0.542,1.969,0
                        l18.304,18.305c0.543,0.543,0.543,1.426,0,1.97L3.333,39.288z"/>
    </svg>
    <svg className="arrow arrow__prev" width="23px" height="40px" viewBox="0 0 23 40" onClick={ movePrev }>
      <path fill="currentColor" d="M1.363,20.985c-0.543-0.544-0.543-1.426,0-1.97L19.667,0.71c0.543-0.542,1.426-0.542,1.97,0
                        c0.543,0.544,0.543,1.426,0,1.97L4.318,20l17.318,17.319c0.543,0.543,0.543,1.426,0,1.97c-0.271,0.271-0.611,0.408-0.984,0.408
                        c-0.375,0-0.714-0.137-0.985-0.408L1.363,20.985z"/>
    </svg>
  </div>
);

const MenuItem = ({title, el_description, watched, select, prevImg, hover, hideTooltip, elementId}) => (
  <div className={ watched ? "watched" : "false" }>
    <div className="single-video-box" onClick={ select }>
      <div className="check">
        <svg height="17" width="17" viewBox="0 0 17 17">
          <path fill="currentColor" d="M12.86,6.26L11.93,5.328c-0.11-0.11-0.289-0.11-0.4,0l-3.88,3.881l-1.65-1.651
                    c-0.11-0.11-0.289-0.11-0.399,0L4.669,8.49c-0.11,0.109-0.11,0.289,0,0.399l2.781,2.78c0.055,0.056,0.127,0.084,0.199,0.084
                    c0.073,0,0.145-0.028,0.2-0.084l5.011-5.011c0.053-0.053,0.083-0.125,0.083-0.2C12.943,6.384,12.913,6.313,12.86,6.26z"/>
        </svg>
      </div>
      <div className="circle-wrap">
        {
          !prevImg ?
            <div className="circle">
              <svg height="20" width="20" viewBox="0 0 31 42">
                <path fill="currentColor" d="M36.068,20.176l-29-20C6.761-0.035,6.363-0.057,6.035,0.114C5.706,0.287,5.5,0.627,5.5,0.999v40
                                c0,0.372,0.206,0.713,0.535,0.886c0.146,0.076,0.306,0.114,0.465,0.114c0.199,0,0.397-0.06,0.568-0.177l29-20
                                c0.271-0.187,0.432-0.494,0.432-0.823S36.338,20.363,36.068,20.176z"/>
              </svg>
            </div>
            :
            <img src={prevImg} alt="" />
        }
      </div>
      <div className="desc-box" onMouseEnter = { hover } onMouseLeave= { hideTooltip }>
        <h2>{ title }</h2>
        {
          el_description ?
            <div id={elementId} className="tooltip">
              <p> { el_description }</p>
            </div>

            : <div></div>

        }
      </div>

    </div>
  </div>
);

const Menu = ({videos, play, description, instruction, moveNext, movePrev, styles, isRotatable}) => (
  <div className="video-container">
    <div className="menu-description">
      <h2>{description}</h2>
      <h3>{instruction}</h3>
    </div>
    <div className="menu-content menu-content__rotator">
      { isRotatable && <Navigation moveNext={moveNext} movePrev={movePrev}/> }
      <div className="menu-wrap" style={styles}>
        {
          videos.map(({id, title, watched, prevImg, el_description}) => (
            <MenuItem className="menu-item"
              key={ id }
              title={ title }
              el_description = { el_description }
              watched={ watched }
              description={description}
              select={ () => play(id) }
              prevImg={prevImg}
              elementId = {"tooltip-"+id}
            />
          ))
        }
      </div>
    </div>
  </div>
);

export default Menu;