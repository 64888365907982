import { createSelector } from "reselect";
import { prop } from "ramda";

export const selectAppSettingsDomain = prop("appSettings");

export const selectIsLoading = createSelector(
  selectAppSettingsDomain, prop("isLoading"),
);

export const selectCurrentBrand = createSelector(
  selectAppSettingsDomain, prop("currentBrand"),
);

export const selectBrands = createSelector(
  selectAppSettingsDomain, prop("brands"),
);
