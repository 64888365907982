import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../helpers/rendering";
import { Color, Media, Separator as SeparatorBase } from "../../theme";

import CitySvg from "./images/city.svg";

export const Container = styled.div`
  background-color: ${Color.almostWhite};
  background-image: url(${CitySvg});
  background-repeat: no-repeat;
  background-size: 100%;

  ${styleWhenTrue(prop("$gradientBgStyle"), css`
    background-image: none;

    && {
      background: linear-gradient(90deg, ${Color.white} 0%, #DCF7FF 100%);
    }
  `)}
`;

export const Wrapper = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
`;

export const Separator = styled(SeparatorBase)`
  margin-top: 50px;
  margin-bottom: 50px;

  ${Media.tablet`
    margin-top: 100px;
    margin-bottom: 100px;
  `}
`;
