import React from "react";
import PropTypes from "prop-types";

import { Container, Text } from "./question.styled";

export const Question = ({
  className,
  question,
  isWrong,
  enableValidating,
}) => {
  return (
    <Container
      className={className}
      enableValidating={enableValidating}
      isWrong={isWrong}
    >
      <Text>{question.text}</Text>
    </Container>
  );
};

Question.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object,
  isWrong: PropTypes.bool,
  enableValidating: PropTypes.bool,
};
