import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useTranslation } from "react-i18next";

import {
  selectAmbassadorsRouteAvailable,
  selectVideoForCodeRouteAvailable,
  selectNewsRouteAvailable,
  selectCompetenceItemsRouteAvailable,
  selectUserLoggedIn,
  selectForceChangePassword,
} from "../../userContext/redux/userContext.selectors";
import { renderWhenTrue } from "../../helpers/rendering";
import { UserStatistics } from "../../components/userStatistics/userStatistics.component";
import { VideoForCode } from "../../components/videoForCode/videoForCode.component";
import { ThumbnailList } from "../../components/thumbnailList/thumbnailList.component";
import { Loader } from "../../components/loader/loader.component";
import { TutorialStatus } from "../../components/tutorialPopup/tutorialPopup.constants";
import { Separator } from "../../theme/typography";
import { UserRanking } from "../../components/userRanking/userRanking.component";
import { FavouritesList } from "../../components/favouritesList/favouritesList.component";
import { TutorialPopup } from "../../components/tutorialPopup/tutorialPopup.component";
import { Theme } from "../../appConfig";
import {
  Widget,
  useModuleConfig,
  ModuleType,
  hasWidget,
  WidgetType,
} from "../";

import { WelcomeMessage } from "./components/welcomeMessage/welcomeMessage.component";
import { IncomingEvent } from "./components/incomingEvent/incomingEvent.component";
import { UpcomingEvents } from "./components/upcomingEvents/upcomingEvents.component";
import { Banner } from "./components/banner/banner.component";
import { DashboardActions } from "./redux/dashboard.reducer";
import {
  selectAwards,
  selectIDDStats,
  selectIsInitialDataPending,
  selectLearningProgress,
  selectRanking,
  selectStatistics,
  selectThumbnailLists,
} from "./redux/dashboard.selectors";
import { ThumbnailListName } from "./dashboard.constants";
import {
  Container,
  Wrapper,
  NewsActivityContainer,
  TopRow,
  TopColumn,
  MiddleRow,
  BottomRow,
  BottomColumn,
} from "./dashboard.styled";

const Dashboard = () => {
  const { t } = useTranslation();
  const config = useModuleConfig(ModuleType.Dashboard);
  const dispatch = useDispatch();
  const isPending = useSelector(selectIsInitialDataPending);
  const userLoggedIn = useSelector(selectUserLoggedIn);
  const thumbnailLists = useSelector(selectThumbnailLists);
  const newsRouteAvailable = useSelector(selectNewsRouteAvailable);
  const learningProgress = useSelector(selectLearningProgress);
  const awards = useSelector(selectAwards);
  const iddStats = useSelector(selectIDDStats);
  const statistics = useSelector(selectStatistics);
  const rankingPositions = useSelector(selectRanking);
  const videoForCodeRouteAvailable = useSelector(
    selectVideoForCodeRouteAvailable,
  );
  const ambassadorsRouteAvailable = useSelector(
    selectAmbassadorsRouteAvailable,
  );
  const competenceItemsRouteAvailable = useSelector(
    selectCompetenceItemsRouteAvailable,
  );
  const forceChangePassword = useSelector(selectForceChangePassword);
  const topWidgetEnabled =
    hasWidget(WidgetType.NewsActivity) && newsRouteAvailable;

  useEffect(() => {
    if (userLoggedIn) {
      dispatch(DashboardActions.getHostSex());
      dispatch(DashboardActions.getInitialData());
    }

    if (ambassadorsRouteAvailable) {
      dispatch(DashboardActions.getAllUserVideos());
    }

    if (competenceItemsRouteAvailable) {
      dispatch(DashboardActions.getCompetenceItems());
    }
  }, [dispatch, ambassadorsRouteAvailable, competenceItemsRouteAvailable]);

  const renderNewsActivity = renderWhenTrue(() => (
    <NewsActivityContainer>
      <Widget.NewsActivity />
    </NewsActivityContainer>
  ));

  const renderThumbnailList = (name) => {
    if (
      name !== ThumbnailListName.AmbassadorsBusinessCards
      && thumbnailLists[name]
      && !thumbnailLists[name].length
    ) {
      return null;
    }

    return (
      <>
        <ThumbnailList
          title={t(`dashboard.dashboard.${name}`)}
          thumbnails={thumbnailLists[name]}
          type={name}
        />
        <Separator />
      </>
    );
  };

  const renderVideoForCode = renderWhenTrue(() => <VideoForCode />);

  const renderLoader = renderWhenTrue(() => <Loader />);

  const renderContent = renderWhenTrue(() => (
    <Wrapper>
      <Banner />
      <TopRow>
        {renderNewsActivity(topWidgetEnabled)}
        <TopColumn $atRightSide={topWidgetEnabled}>
          <WelcomeMessage />
          <IncomingEvent topWidgetEnabled={topWidgetEnabled} />
        </TopColumn>
      </TopRow>
      {renderVideoForCode(videoForCodeRouteAvailable)}
      <MiddleRow>
        {renderThumbnailList(ThumbnailListName.Competences)}
        {renderThumbnailList(ThumbnailListName.Incomplete)}
        {renderThumbnailList(ThumbnailListName.Required)}
        <FavouritesList />
        {renderThumbnailList(ThumbnailListName.Recommended)}
      </MiddleRow>
      <MiddleRow>
        <UpcomingEvents />
      </MiddleRow>
      <BottomRow>
        <UserStatistics
          learningProgress={learningProgress}
          awards={awards}
          iddStats={iddStats}
          statistics={statistics}
        />
        <BottomColumn>
          <UserRanking rankingPositions={rankingPositions} />
        </BottomColumn>
      </BottomRow>
    </Wrapper>
  ));

  return (
    <ThemeProvider theme={config.theme}>
      {Theme.Tutorial === TutorialStatus.Enabled && !forceChangePassword && <TutorialPopup />}
      <Container>
        {renderContent(!isPending)}
        {renderLoader(isPending)}
      </Container>
    </ThemeProvider>
  );
};

export default Dashboard;
