import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Avatar } from "../../../../components/avatar/avatar.component";
import { AvatarType } from "../../../../components/avatar/avatar.constants";
import { ButtonColor, ButtonVariant } from "../../../../components/button/button.constants";
import { Tooltip } from "../../../../components/tooltip/tooltip.component";
import { SearchParticipantInput } from "../searchParticipantInput/searchParticipantInput.component";

import {
  Container,
  Header,
  Title,
  Separator,
  GroupName,
  AddButton,
  AddIcon,
  UserRow,
  UserName,
  ButtonsRow,
  Button,
  TickIcon,
} from "./addUsersToRoomView.styled";

export const AddUsersToRoomView = ({
  roomName,
  addUsersToRoom,
  usersWithoutRoom,
  goBack,
}) => {
  const { t } = useTranslation();
  const [usersToAdd, setUsersToAdd] = useState([]);
  const [filteredParticipants, setFilteredParticipants] = useState(usersWithoutRoom.filter((user) => user.connected));

  const isButtonDisabled = !usersToAdd.length;

  const saveChanges = () => {
    addUsersToRoom(usersToAdd);
    goBack();
  };

  const addUser = (user) => {
    setUsersToAdd([...usersToAdd, user.id]);
  };

  return (
    <Container>
      <Header>
        <Title>{t("webinars.addParticipants")}</Title>
        <Separator />
        <GroupName>{roomName}</GroupName>
      </Header>
      <SearchParticipantInput
        participants={usersWithoutRoom}
        setFilteredParticipants={setFilteredParticipants}
      />
      {filteredParticipants.map((user) => (
        <UserRow key={user.id}>
          <Avatar
            src={user.avatar}
            name={`${user.name}`}
            type={AvatarType.Small}
          />
          <UserName>{user.name}</UserName>
          {usersToAdd.includes(user.id)
            ? (
              <TickIcon />
            ) : (
              <Tooltip title={t("webinars.addUsersToRoomView.addUser")}>
                <AddButton onClick={() => addUser(user)}>
                  <AddIcon />
                </AddButton>
              </Tooltip>
            )
          }
        </UserRow>
      ))}
      <ButtonsRow>
        <Button variant={ButtonVariant.Outlined} onClick={goBack}>
          {t("global.cancel")}
        </Button>
        <Button
          color={ButtonColor.Primary}
          onClick={saveChanges}
          disabled={isButtonDisabled}
        >
          {t("global.save")}
        </Button>
      </ButtonsRow>
    </Container>
  );
};
