import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";

import { Tooltip } from "../../../../components/tooltip/tooltip.component";
import { selectUserName } from "../../../../userContext/redux/userContext.selectors";
import { selectHostSex } from "../../redux/dashboard.selectors";
import { DashboardActions } from "../../redux/dashboard.reducer";

import { HostSex } from "./welcomeMessage.constants";
import {
  Container,
  MessageLabel,
  MessageName,
  TodayContainer,
  TodayLabel,
  TodayDate,
  HostMan,
  HostWoman,
  MessageContainer,
  ChangeSexButton,
  FaceIcon,
} from "./welcomeMessage.styled";

export const WelcomeMessage = ({ className }) => {
  const { t } = useTranslation();
  const name = useSelector(selectUserName);
  const hostSex = useSelector(selectHostSex);
  const dispatch = useDispatch();

  const handleSwitchSex = useCallback(() => {
    dispatch(DashboardActions.switchHostSex());
  }, [dispatch]);

  const dateText = dayjs().format("DD MMMM, dddd").toLowerCase();

  const HostComponent = hostSex === HostSex.Man ? HostMan : HostWoman;

  return (
    <Container className={className}>
      <HostComponent />
      <Tooltip title={t("dashboard.welcomeMessage.switchButtonLabel")}>
        <ChangeSexButton onClick={handleSwitchSex}>
          <FaceIcon />
        </ChangeSexButton>
      </Tooltip>
      <MessageContainer>
        <MessageLabel>{t("dashboard.welcomeMessage.messageLabel")}</MessageLabel>
        <MessageName>
          {t("dashboard.welcomeMessage.messageName", { name })}
        </MessageName>
      </MessageContainer>
      <TodayContainer>
        <TodayLabel>{t("dashboard.welcomeMessage.todayLabel")}</TodayLabel>
        <TodayDate>{dateText}</TodayDate>
      </TodayContainer>
    </Container>
  );
};


WelcomeMessage.propTypes = {
  className: PropTypes.string,
};
