import React from "react";

export const QuestionOpenSimple = ({ question, setAnswer, active = false }) => (
  <div>
    <input
      readOnly={!active}
      type="text"
      value={question.answers[0].answer ? question.answers[0].answer : ""}
      placeholder={question.question}
      onChange={setAnswer}
      required={true}
    />
  </div>
);
