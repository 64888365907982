import React from "react";
import PropTypes from "prop-types";

import { Label, Value } from "./bubbleLabel.styled";

export const BubbleLabel = ({ id, value, x, y }) => {
  return (
    <>
      <Label>{id}</Label>
      <Value x={x} y={y + 10}>{value}</Value>
    </>
  );
};

BubbleLabel.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};
