import { fabric } from "fabric";
import { v1 as uuid } from "uuid";

import { store } from "../../../../store";
import { Color, FontWeight } from "../../../../theme";
import { WebinarsTypes } from "../../redux/webinars.reducer";

import { CanvasId, UserLabelId } from "./flipchart.constants";

export const initCanvas = () => (
  new fabric.Canvas(CanvasId, {
    height: 900,
    width: 1200,
    backgroundColor: Color.almostWhite,
  })
);

export const addElement = (element, canvas, elementId) => {
  element.set({ id: elementId || uuid() });
  canvas.add(element);
  canvas.renderAll();

  if (!elementId) {
    store.dispatch({
      type: WebinarsTypes.FLIPCHART_ADD_ELEMENT,
      element: element,
      elementId: element.id,
    });
    store.dispatch({ type: WebinarsTypes.SEND_FLIPCHART_STATE, data: canvas.toJSON(["id"]) });
  }
};

export const addLine = (
  stroke = Color.deepGray,
  canvas,
  lineId,
) => {
  const line = new fabric.Line([0, 0, 0, 100], {
    top: 150,
    left: 200,
    stroke,
  });
  addElement(line, canvas, lineId);
};

export const addCircle = (
  fill = Color.deepGray,
  stroke = Color.deepGray,
  empty,
  canvas,
  radius = 50,
  circleId,
) => {
  const circle = new fabric.Circle({
    radius,
    fill: empty ? "" : fill,
    stroke,
    top: 150,
    left: 200,
  });
  addElement(circle, canvas, circleId);
};

export const addRectangle = (
  fill = Color.deepGray,
  stroke = Color.deepGray,
  empty,
  canvas,
  width = 150,
  height = 75,
  rectangleId,
) => {
  const rectangle = new fabric.Rect({
    height,
    width,
    fill: empty ? "" : fill,
    stroke,
    top: 150,
    left: 200,
  });
  addElement(rectangle, canvas, rectangleId);
};

export const addTriangle = (
  fill = Color.deepGray,
  stroke = Color.deepGray,
  empty,
  canvas,
  width = 100,
  height = 100,
  triangleId,
) => {
  const triangle = new fabric.Triangle({
    width,
    height,
    fill: empty ? "" : fill,
    stroke,
    top: 150,
    left: 200,
  });
  addElement(triangle, canvas, triangleId);
};

export const addText = (fill = Color.deepGray, stroke = Color.deepGray, canvas, text, textId) => {
  const newText = new fabric.Textbox(text, {
    fill,
    stroke,
    top: 150,
    left: 200,
  });

  addElement(newText, canvas, textId);
};

export const addPath = (stroke = Color.deepGray, canvas, element, pathId) => {
  const path = new fabric.Path(element.path, { fill: "", stroke });
  addElement(path, canvas, pathId);
};

export const createLabel = (canvas) => {
  fabric.Text.prototype.set({
    _getNonTransformedDimensions() {
      return new fabric.Point(this.width, this.height).scalarAdd(this.padding);
    },
    _calculateCurrentDimensions() {
      return fabric.util.transformPoint(this._getTransformedDimensions(), this.getViewportTransform(), true);
    }
  });

  const label = new fabric.Text("", {
    fill: Color.white,
    backgroundColor: Color.black,
    fontSize: 12,
    fontWeight: FontWeight.SemiBold,
    fontFamily: "Open Sans",
    excludeFromExport: true,
    visible: false,
    selectable: false,
    padding: 10,
  });
  addElement(label, canvas, UserLabelId);
};

export const findAndModifyLabel = (visible, canvas, text, left, top) => {
  canvas.getObjects().forEach((object) => {
    if (object.id === UserLabelId) {
      if (visible) {
        moveLabel(object, canvas, text, left, top);
      } else {
        hideLabel(object, canvas);
      }
    }
  });
};

export const hideLabelForASecond = (canvas) => {
  canvas.getObjects().forEach((object) => {
    if (object.id === UserLabelId) {
      const { text, left, top } = object;
      hideLabel(object, canvas);
      setTimeout(() => {
        moveLabel(object, canvas, text, left, top);
      }, 1000);
    }
  });
};

export const moveLabel = (label, canvas, text, left, top) => {
  label.set({
    text,
    left,
    top: top - 30,
    visible: true,
  });
  canvas.renderAll();
};

export const hideLabel = (label, canvas) => {
  label.set({
    visible: false,
  });
  canvas.renderAll();
};
