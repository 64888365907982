import styled from "styled-components";

import { ReactComponent as HeartSvg } from "../../../../../images/heart2.svg";
import { ReactComponent as NoteSvg } from "../../../../../images/note.svg";
import { ReactComponent as ArrowSvg } from "../../../../../images/arrow.svg";
import { Color, FontWeight, Media } from "../../../../../theme";

export const Container = styled.button`
  padding: 36px 20px 25px;
  background-color: ${Color.white};
  width: 100%;
  border: none;
  text-align: left;
  border-top: 10px solid ${Color.almostWhite};

  ${Media.tablet`
    padding: 45px 35px;
    display: flex;
    align-items: center;
    border-top: 2px solid ${Color.almostWhite};
  `}
`;

export const TitleWrapper = styled.div`
  margin-right: 20px;
  display: flex;
`;

export const AvatarWrapper = styled.div`
  flex-shrink: 0;
  margin-right: 20px;
`;

export const TextWrapper = styled.div``;

export const Title = styled.p`
  font-size: 16px;
  line-height: 23px;
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.deepGray};
  margin-bottom: 5px;
`;

export const Type = styled.span`
  color: ${() => Color.brandBasic};
  font-size: 10px;
  line-height: 13px;
  font-weight: ${FontWeight.Bold};
  padding: 2px 5px;
  border: 1px solid ${() => Color.brandBasic};
  text-transform: uppercase;
  border-radius: 3px;
  margin-right: 10px;
`;

export const Subtitle = styled.p`
  font-size: 13px;
  line-height: 18px;
`;

export const Author = styled.span`
  color: ${Color.deepGray};
  margin-right: 5px;
`;

export const Date = styled.span`
  color: ${Color.gray4};
`;

export const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ArrowIcon = styled(ArrowSvg)`
  flex-shrink: 0;
  width: 9px;
  margin-left: auto;
  fill: ${Color.gray9};
  transform: rotate(180deg);

  ${Media.tablet`
    margin-left: 70px;
  `}
`;

export const LikeIcon = styled(HeartSvg)`
  width: 20px;
  fill: ${Color.gray9};
`;

export const CommentIcon = styled(NoteSvg)`
  width: 18px;
  fill: ${Color.gray9};
  margin-left: 20px;
`;

export const Counter = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${Color.gray9};
  margin-left: 11px;
  width: 20px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${Color.dirtyWhite};
  padding-top: 17px;
  margin-top: 30px;

  ${Media.tablet`
    border: none;
    padding-top: 0;
    margin-top: 0;
    margin-left: auto;
  `}
`;
