import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Avatar } from "../../avatar/avatar.component";
import { AvatarType } from "../../avatar/avatar.constants";

import {
  Container,
  Position,
  GoldMedalDot,
  SilverMedalDot,
  BronzeMedalDot,
  PointsWrapper,
  Info,
  Points,
} from "./rankingPosition.styled";

export const RankingPosition = ({
  className,
  position,
  avatar,
  name,
  points,
  isCurrentUser,
}) => {
  const { t } = useTranslation();

  const renderMedalDot = () => {
    switch (position) {
    case 1:
      return <GoldMedalDot />;
    case 2:
      return <SilverMedalDot />;
    case 3:
      return <BronzeMedalDot />;
    default:
      return null;
    }
  };

  return (
    <Container className={className}>
      <Position>{position}</Position>
      <Avatar
        type={AvatarType.Small}
        src={avatar}
        name={name}
        dark={isCurrentUser}
      />
      <Info>{name}</Info>
      <PointsWrapper>
        {renderMedalDot()}
        <Points>{points} {t("components.userRanking.points")}</Points>
      </PointsWrapper>
    </Container>
  );
};

RankingPosition.propTypes = {
  className: PropTypes.string,
  position: PropTypes.number,
  avatar: PropTypes.string,
  name: PropTypes.string,
  points: PropTypes.number,
  isCurrentUser: PropTypes.bool,
};
