import styled from "styled-components";

import { Container as ContainerBase, PageHeader, Media } from "../../../../theme";

export const Container = styled(ContainerBase)``;

export const Title = styled(PageHeader)`
  margin-left: 20px;

  ${Media.tablet`
    margin-left: 0;
  `}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;

  ${Media.tabletWide`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const Column = styled.div`
  ${Media.tabletWide`
    width: 100%;
  `}

  & + & {
    margin-top: 20px;
    width: 100%;

    ${Media.tabletWide`
      margin-top: 0;
      margin-left: 20px;
      max-width: 490px;
      width: auto;
    `}
  }
`;

export const Thumbnails = styled.div`
  margin-top: 60px;
`;
