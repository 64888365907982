import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  Container,
  TextWrapper,
  InfoIcon,
  Text,
  RulesLink,
  Separator,
} from "./rulesBox.styled";

export const RulesBox = ({ className, parentPath }) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <TextWrapper>
        <InfoIcon />
        <Text>{t("gamification.rulesInfo.wantKnowMore")}</Text>
      </TextWrapper>
      <Separator />
      <RulesLink to={`${parentPath}/start`}>
        {t("gamification.rulesInfo.rules")}
      </RulesLink>
    </Container>
  );
};

RulesBox.propTypes = {
  className: PropTypes.string,
  parentPath: PropTypes.string.isRequired,
};
