import React from "react";
import { useNavigate } from "react-router";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

import { modalStyles } from "./errorModal.constants";
import { ModalContent, ErrorText, ConfirmButton } from "./errorModal.styled";

export const ErrorModal = ({ isOpen, message }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      style={modalStyles}
    >
      <ModalContent>
        <ErrorText>{message}</ErrorText>
        <ConfirmButton onClick={() => navigate("/")}>
          {t("global.ok")}
        </ConfirmButton>
      </ModalContent>
    </Modal>
  );
};
