import { prop } from "ramda";
import styled, { css } from "styled-components";

import { Button as ButtonBase } from "../../components/button/button.component";
import { Checkbox as CheckboxBase } from "../../components/checkbox/checkbox.component";
import { hexToRgba } from "../../helpers/colors";
import { styleWhenTrue } from "../../helpers/rendering";
import { Color, FontWeight, Separator as SeparatorBase } from "../../theme";

export const Container = styled.div`
  display: none;

  ${styleWhenTrue(prop("open"), css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${Color.almostWhite};
    z-index: 999999;
  `)}
`;

export const Popup = styled.div`
  padding: 60px;
  background-color: ${Color.white};
  box-shadow: 0 8px 10px -5px ${hexToRgba(Color.black, 0.2)},
    0 16px 24px 2px ${hexToRgba(Color.black, 0.14)},
    0 6px 30px 5px ${hexToRgba(Color.black, 0.12)};
  width: 840px;
  max-height: 686px;
`;

export const Title = styled.p`
  font-weight: ${FontWeight.Light};
  font-size: 22px;
  line-height: 28px;
  color: ${Color.deepGray};
`;

export const WelcomeText = styled.p`
  font-size: 13px;
  line-height: 18px;
  color: ${Color.deepGray};
  letter-spacing: 0.5px;
`;

export const TextDiv = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: ${Color.deepGray};
  margin-top: 30px;
  background-color: ${Color.almostWhite};
  padding: 30px;
  overflow-y: auto;
  max-height: 250px;
`;

export const Separator = styled(SeparatorBase)`
  margin: 30px 0;
`;

export const Button = styled(ButtonBase)`
  min-width: 200px;
  margin-top: 30px;
`;

export const Checkbox = styled(CheckboxBase)`
  margin-top: 30px;
`;
