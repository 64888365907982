import React from "react";
import styled from "styled-components";

import { Icon } from "../../../UIElements/icons";
import { breakpoints } from "../../../UIGlobals";
import * as UI from "./timerSlidesUI";

const List = styled.ul`
  font-size: 20px;
  line-height: 22px;
  @media (${breakpoints().maxM}) {
    font-size: 10px;
    line-height: 11px;
  }
`;

const ListRow = styled.li`
  display: flex;
  align-items: center;
  margin: 22px 0;
  @media (${breakpoints().maxM}) {
    margin: 4px 0;
  }
`;



const ListWithBgIcon = ({ data }) => (
  <UI.Container>
    <UI.TextColumn>
      <UI.Header>{data.header}</UI.Header>
      <List>
        {data.elements.map((element, index) => (
          <ListRow key={index}>
            <Icon icon="check_oval" width={38} size={38} />
            <UI.ListText>{element}</UI.ListText>
          </ListRow>
        ))}
      </List>
    </UI.TextColumn>
    <UI.ImageColumn>
      <UI.Image src={data.iconBaseUrl + data.iconUrl} alt="icon" bgIcon />
    </UI.ImageColumn>
  </UI.Container>
);

export default ListWithBgIcon;