import styled from "styled-components";

import { Color } from "../../theme/colors";
import { Media } from "../../theme/media";

import { hexToRgbValues } from "./emojiPicker.utils";

export const Container = styled.div`
  width: 100%;

  em-emoji-picker {
    --border-radius: 0;
    --font-family: "Open Sans", sans-serif;
    --font-size: 14px;
    --shadow: none;
    --rgb-accent: ${() => hexToRgbValues(Color.brandBasic)};
    width: 100%;
    padding: 5px 0 0;

    ${Media.tablet`
      border: 1px solid ${Color.gray8};
      border-top: none;
    `}
  }
`;
