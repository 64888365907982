export const modalStyles = {
  content : {
    background: "none",
    zIndex: 1000,
    border: "none",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  overlay: {
    zIndex: 1000
  }
};
