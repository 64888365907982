import React from "react";

import { Input } from "../../../../UIElements";

import { Container } from "./questionOpen.styled";

export const QuestionOpen = ({ question, setAnswer, error }) => {
  if (question.conditionType === "visible_after_answer" && !question.show ) {
    return null;
  }
  return (
    <Container>
      <Input
        value={question.answers[0].answer}
        title={question.question}
        onChange={setAnswer}
        error={error ? error : false}
        disabled={!question.answers[0].editable}
      />
    </Container>
  );
};
