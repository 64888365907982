import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { Option } from "../option/option.component";

import { Container } from "./optionList.styled";
import { ColumnId } from "./optionList.constants";

const OptionList = ({ currentOrder, setNewOrder, answers, answersConfirmed }) => {
  const onDragEnd = ({ destination, source, draggableId }) => {
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    const draggedItem = currentOrder.find((option) => option.id === draggableId);

    if (draggedItem) {
      const newOrder = currentOrder.toSpliced(source.index, 1);
      newOrder.splice(destination.index, 0, draggedItem);
      setNewOrder(newOrder);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={ColumnId}>
        {(provided) => (
          <Container
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {currentOrder.map((option, index) => (
              <Option
                key={option.id}
                index={index}
                data={option}
                confirmed={answersConfirmed}
                answerId={answers[index].id}
              />
            ))}
            {provided.placeholder}
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default OptionList;
