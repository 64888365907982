import styled from "styled-components";

import { colors } from "../UIGlobals";

const GrayCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  font-size: 1.9rem;
  color: ${colors().almostBlack};
  font-weight: 600;
  border: 1px solid ${colors().dirtyWhite};
  transform: translate(-50%, -50%);
`;

export { GrayCircle };
