import React from "react";

import { MicrophoneIcon, MicrophoneOffIcon } from "../../classesWebinars/classesWebinars.styled";

import {
  VideoBox,
  Video,
  Initials,
  Indicators,
  NameBox,
  Voice,
  IconWrapper,
  StarIcon,
} from "./classesVideo.styled";

export const ClassesVideo = ({
  isSpeaking,
  fullName,
  isPresenter,
  videoBoxRef,
  videoRef,
  isStreamingCamera,
  isStreamingAudio,
  isRightPanelStream,
  setHighest,
  columns,
  shiftNumber,
  isCurrentUser,
  inGrid = false,
  isHidden = false,
  isMainStream = false,
  wideRightPanel = false,
}) => {
  const initials = fullName?.split(" ").map((item) => item[0]);

  return (
    <VideoBox
      ref={videoBoxRef}
      isSpeaking={isSpeaking}
      isRightPanelStream={isRightPanelStream && !isMainStream}
      inGrid={inGrid}
      isHidden={isHidden}
      isMainStream={isMainStream}
      isPresenter={isPresenter}
      wideRightPanel={wideRightPanel}
      setHighest={setHighest}
      columns={columns}
      shiftNumber={shiftNumber}
    >
      <Video
        ref={videoRef}
        hidden={!isStreamingCamera}
        autoPlay
        playsInline
        isCurrentUser={isCurrentUser}
      />
      {!isStreamingCamera && !isHidden && (
        <Initials small={isRightPanelStream}>
          {initials}
        </Initials>
      )}
      {!isHidden && (
        <Indicators>
          {isPresenter && <StarIcon />}
          <IconWrapper>
            {isStreamingAudio ? (
              <MicrophoneIcon />
            ) : (
              <MicrophoneOffIcon />
            )}
          </IconWrapper>
          <NameBox>{fullName}</NameBox>
          {isSpeaking && <Voice />}
        </Indicators>
      )}
    </VideoBox>
  );
};
