import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { renderWhenTrue } from "../../../../../helpers/rendering";
import { transformTitle } from "../../onboardingInteractions.helpers";
import { ButtonVariant } from "../../../../../components/button/button.constants";
import { NextButton } from "../nextButton/nextButton.component";
import { InfoTip } from "../infoTip/infoTip.component";

import {
  Container,
  TextWrapper,
  Text,
  ButtonRow,
  OutlinedButton,
} from "./interactionIntro.styled";

export const InteractionIntro = ({
  className,
  title,
  buttonText,
  next,
  fileUrl,
  showLaterButton,
  infotipText
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const renderInfoTip = renderWhenTrue(() => (
    <InfoTip sentences={infotipText} />
  ));

  const handleLaterClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const handleDownloadClick = () => {
    window.open(fileUrl, "_blank");
  }

  const renderLaterButton = renderWhenTrue(() => (
    <OutlinedButton onClick={handleLaterClick} variant={ButtonVariant.Outlined} $large>
      {t("interactions.interactionIntro.later")}
    </OutlinedButton>
  ));

  const renderDownloadButton = renderWhenTrue(() => (
    <OutlinedButton onClick={handleDownloadClick} variant={ButtonVariant.Outlined} $large>
      {t("interactions.interactionIntro.downloadFile")}
    </OutlinedButton>
  ));

  return (
    <Container className={className}>
      {renderInfoTip(!!infotipText)}
      <TextWrapper>
        <Text>{transformTitle(title)}</Text>
      </TextWrapper>
      <ButtonRow>
        {renderDownloadButton(!!fileUrl)}
        {renderLaterButton(!!showLaterButton)}
        <NextButton
          onClick={next}
          text={buttonText}
        />
      </ButtonRow>
    </Container>
  );
};

InteractionIntro.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  next: PropTypes.func,
  fileUrl: PropTypes.string,
  showLaterButton: PropTypes.bool
};
