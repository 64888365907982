export const CompetenceTabURI = {
  Competence: "details",
  Resources: "resources",
  Statistics: "statistics",
  MarkScale: "mark-scale",
};

export const CompetenceTabName = {
  [CompetenceTabURI.Competence]: "competence",
  [CompetenceTabURI.Resources]: "resources",
  [CompetenceTabURI.Statistics]: "statistics",
  [CompetenceTabURI.MarkScale]: "markScale",
};

export const CompetenceType = {
  Strategic: "strategic",
  Specialized: "specialized",
};
