import { ReactComponent as ModuleIcon } from "./images/moduleIcon.svg";

export const Config = {
  moduleName: "webinars",
  dependencies: [],
  label: "Webinars",
  Icon: ModuleIcon,
  urls: ["/webinar/:id"],
  routeName: "",
  routes: {},
  options: {},
  theme: {}
};
