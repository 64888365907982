import styled from "styled-components";

import { Media } from "../../theme/media";
import { Color } from "../../theme/colors";
import { Button as ButtonBase } from "../button/button.component";

export const Content = styled.div`
  padding: 40px 20px 60px;
  text-align: center;

  ${Media.tablet`
    padding: 80px 75px;
  `}
`;

export const Actions = styled.div`
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${Color.gray8};

  ${Media.tablet`
    padding: 30px;
  `}
`;

export const Button = styled(ButtonBase)`
  & + & {
    margin-left: 10px;
  }
`;