import { logger } from "./logger";

const clearApiCache = () => {
  const locationObject = (typeof window !== "undefined") ? window.location : {};
  const isOperationSecure = locationObject.protocol === "https:";

  if ("caches" in window && isOperationSecure) {
    caches.keys().then(names => {
      for (const name of names) {
        if (name.indexOf("api-cache") !== -1) caches.delete(name);
      }
    }).catch((error) => {
      const insecureError = "Uncaught (in promise) DOMException: The operation is insecure.";
      if (error !== insecureError) { // for firefox on incognito mode
        logger("Cache clear error", `Cache clear error: ${error}`);
      }
    });
  }
}

export { clearApiCache };
