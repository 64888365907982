import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field } from "formik";
import fileDialog from "file-dialog";

import { ContentEditor } from "../../../../components/contentEditor/contentEditor.component";
import { InputStyle } from "../../../../components/input/input.constants";
import { selectFileErrorStatus, selectFileUploadProgress, selectParticipants } from "../../redux/webinars.selectors";
import { WebinarsActions } from "../../redux/webinars.reducer";

import { FileMaxSize } from "./messageField.constants";
import { MessageSchema } from "./messageField.schema";
import {
  Container,
  Form,
  FieldWrapper,
  AdditionalOptions,
  Label,
  AttachmentButton,
  AttachmentIcon,
  FileError,
  FileProgress,
  DropdownInput,
  CloseButton,
  CloseIcon,
  KeyEventWrapper,
} from "./messageField.styled";

export const MessageField = ({ className, fullScreen, classesMode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileTypeError = useSelector(selectFileErrorStatus);
  const fileUploadProgress = useSelector(selectFileUploadProgress);
  const participants = useSelector(selectParticipants).filter(
    (participant) => !participant.user.isCurrentUser && participant.connected,
  );
  const [fileError, setFileError] = useState(null);
  const [fileProgressVisible, setFileProgressVisible] = useState(false);
  const [selectedReceiverId, setSelectedReceiverId] = useState(0);
  const participantOptions = [
    {
      id: 0,
      label: t("webinars.chat.all"),
      name: "all",
      value: "all",
    },
    ...participants.map((participant) => ({
      id: participant.id,
      label: participant.name,
      name: participant.name,
      value: participant.name,
    })),
  ];
  const initialValues = {
    message: "",
  };

  useEffect(() => {
    let timeout = null;
    if (fileUploadProgress && fileUploadProgress !== 1) {
      closeFileError();
      setFileProgressVisible(true);
    } else if (fileProgressVisible && fileUploadProgress === 1) {
      timeout = setTimeout(() => setFileProgressVisible(false), 1000);
    } else {
      setFileProgressVisible(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [fileUploadProgress, fileProgressVisible]);

  const closeFileError = () => {
    setFileError(null);
    dispatch(WebinarsActions.setFileErrorStatus(false));
  };

  const onSubmit = (values, actions) => {
    dispatch(WebinarsActions.handleSendMessage(values.content, selectedReceiverId));
    actions.resetForm();
  };

  const handleAddFile = (file) => {
    closeFileError();

    if (file.size > FileMaxSize) {
      setFileError(t("webinars.chat.largeFile"));
    } else {
      dispatch(WebinarsActions.handleSendFile(file, selectedReceiverId));
    }
  };

  const handleChooseFile = () => {
    fileDialog({
      accept: [".png", ".jpg", ".pdf", ".xls", ".xlsx", ".docx", ".csv", ".ods", ".ppt", ".txt", ".jpeg", ".gif", ".mp3", ".mp4"],
    }).then((files) => {
      const uploadedFile = files[0];
      handleAddFile(uploadedFile);
    });
  };

  const handleReceiverChange = ({ target }) => {
    setSelectedReceiverId(target.value);
  };

  const handleEnterPress = (key, submit) => {
    if (key === "Enter") {
      submit();
    }
  };

  return (
    <Container fullScreen={fullScreen} classesMode={classesMode} className={className}>
      {fileProgressVisible && (
        <FileProgress>
          {t("webinars.chat.sending")}
          {" "}
          ({Math.round(fileUploadProgress * 100)}%)
        </FileProgress>
      )}
      {(!!fileError || !!fileTypeError) && (
        <FileError>
          {fileTypeError ? t("webinars.chat.wrongType") : fileError}
          <CloseButton onClick={closeFileError}>
            <CloseIcon />
          </CloseButton>
        </FileError>
      )}
      <AdditionalOptions>
        {classesMode && (
          <>
            <Label>{t("webinars.chat.sendTo")}</Label>
            {classesMode && (
              <DropdownInput
                options={participantOptions}
                value={participantOptions.find((option) => option.id === selectedReceiverId).value}
                name={participantOptions.find((option) => option.id === selectedReceiverId).name}
                valueId={selectedReceiverId}
                small
                styleType={InputStyle.WebinarMessage}
                onChange={handleReceiverChange}
              />
            )}
          </>
        )}
        <AttachmentButton onClick={handleChooseFile}>
          <AttachmentIcon />
        </AttachmentButton>
      </AdditionalOptions>
      <Formik
        validationSchema={MessageSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        <Form>
          <Field name="content">
            {({ field, form, meta }) => (
              <FieldWrapper>
                <KeyEventWrapper onKeyDown={({ key }) => handleEnterPress(key, form.handleSubmit)}>
                  <ContentEditor
                    {...field}
                    placeholder={t("webinars.chat.writeMessage")}
                    oneLine
                    error={meta.value ? meta.error : ""}
                    classesMode={classesMode}
                  />
                </KeyEventWrapper>
              </FieldWrapper>
            )}
          </Field>
        </Form>
      </Formik>
    </Container>
  );
};

MessageField.propTypes = {
  className: PropTypes.string,
};
