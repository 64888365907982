export const CoordsCompetenceHR = [
  [
    { x: 128, y: 20 },
    { x: 128, y: 44 },
    { x: 128, y: 68 },
    { x: 152, y: 68 },
    { x: 152, y: 92 },
    { x: 152, y: 116 },
    { x: 152, y: 140 },
    { x: 128, y: 140 },
    { x: 104, y: 140 },
    { x: 104, y: 164 },
    { x: 104, y: 188 },
    { x: 104, y: 212 },
    { x: 128, y: 212 },
    { x: 152, y: 212 },
    { x: 210, y: 181 },
  ],
  [
    { x: 20, y: 212 },
    { x: 44, y: 212 },
    { x: 68, y: 212 },
    { x: 92, y: 212 },
    { x: 116, y: 212 },
    { x: 116, y: 188 },
    { x: 116, y: 164 },
    { x: 92, y: 164 },
    { x: 68, y: 164 },
    { x: 68, y: 140 },
    { x: 35, y: 30},
  ],
  [
    { x: 38, y: 331 },
    { x: 38, y: 307,},
    { x: 38, y: 283 },
    { x: 62, y: 283 },
    { x: 86, y: 283 },
    { x: 86, y: 307 },
    { x: 110, y: 307 },
    { x: 134, y: 307 },
    { x: 158, y: 307 },
    { x: 158, y: 283 },
    { x: 158, y: 259 },
    { x: 158, y: 235 },
    { x: 158, y: 211 },
    { x: 182, y: 211 },
    { x: 220, y: 180 },
  ],
  [
    { x: 28, y: 211 },
    { x: 52, y: 211 },
    { x: 76, y: 211 },
    { x: 76, y: 187 },
    { x: 76, y: 163 },
    { x: 76, y: 139 },
    { x: 100, y: 139 },
    { x: 124, y: 139 },
    { x: 148, y: 139 },
    { x: 148, y: 163 },
    { x: 148, y: 187 },
    { x: 148, y: 211 },
    { x: 148, y: 235 },
    { x: 148, y: 259 },
    { x: 50, y: 271 },
  ],
  [
    { x: 44, y: 20 },
    { x: 44, y: 44 },
    { x: 68, y: 44 },
    { x: 92, y: 44 },
    { x: 116, y: 44 },
    { x: 140, y: 44 },
    { x: 140, y: 68 },
    { x: 140, y: 92 },
    { x: 116, y: 92 },
    { x: 116, y: 116 },
    { x: 116, y: 140 },
    { x: 140, y: 140 },
    { x: 164, y: 140 },
    { x: 188, y: 140 },
    { x: 144, y: 176 },
  ],
];
