export const CoordsVWHR = [
  [
    { x: 128, y: 20 },
    { x: 128, y: 44 },
    { x: 128, y: 68 },
    { x: 128, y: 92 },
    { x: 152, y: 116 },
    { x: 176, y: 140 },
    { x: 200, y: 164 },
    { x: 220, y: 200 },
  ],
  [
    { x: 20, y: 212 },
    { x: 44, y: 188 },
    { x: 68, y: 164 },
    { x: 92, y: 140 },
    { x: 116, y: 140 },
    { x: 116, y: 116 },
    { x: 116, y: 92 },
    { x: 116, y: 68 },
    { x: 20, y: 20},
  ],
  [
    { x: 62, y: 331 },
    { x: 86, y: 307,},
    { x: 110, y: 307 },
    { x: 110, y: 283 },
    { x: 134, y: 283 },
    { x: 158, y: 283 },
    { x: 158, y: 259 },
    { x: 158, y: 235 },
    { x: 182, y: 235 },
    { x: 220, y: 180 },
  ],
  [
    { x: 28, y: 187 },
    { x: 52, y: 187 },
    { x: 76, y: 187 },
    { x: 100, y: 187 },
    { x: 100, y: 211 },
    { x: 100, y: 235 },
    { x: 50, y: 271 },
  ],
  [
    { x: 68, y: 20 },
    { x: 68, y: 44 },
    { x: 92, y: 44 },
    { x: 116, y: 68 },
    { x: 140, y: 92 },
    { x: 164, y: 116 },
    { x: 164, y: 140 },
    { x: 144, y: 176 },
  ],
];
