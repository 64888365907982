import styled, { css } from "styled-components";
import { propEq, prop } from "ramda";

import { ReactComponent as HatBase } from "../../images/semiHat.svg";
import { styleWhenTrue } from "../../helpers/rendering";
import { Color, Media, FontWeight } from "../../theme";

import { AvatarType } from "./avatar.constants";

export const Container = styled.div`
  width: 47px;
  height: 47px;
  border: 1px solid ${Color.dirtyWhite};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.white};
  flex-shrink: 0;

  ${styleWhenTrue(propEq(AvatarType.Small, "type"), css`
    width: 37px;
    height: 37px;
  `)}

  ${styleWhenTrue(propEq(AvatarType.Large, "type"), css`
    width: 70px;
    height: 70px;
    border-width: 2px;

    ${Media.mobile`
      width: 90px;
      height: 90px;
    `}
  `)}

  ${styleWhenTrue(propEq(AvatarType.ExtraLarge, "type"), css`
    width: 80px;
    height: 80px;
    border-width: 3px;

    ${Media.mobile`
      width: 100px;
      height: 100px;
    `}

    ${Media.tablet`
      width: 140px;
      height: 140px;
    `}
  `)}

  ${styleWhenTrue(prop("$dark"), css`
    background-color: ${Color.deepGray};
  `)}
`;

export const Initials = styled.span`
  font-size: 16px;
  line-height: 22px;
  font-weight: ${FontWeight.Bold};

  ${styleWhenTrue(propEq(AvatarType.Small, "type"), css`
    font-size: 11px;
  `)}

  ${styleWhenTrue(propEq(AvatarType.Large, "type"), css`
    font-size: 28px;

    ${Media.mobile`
      font-size: 36px;
    `}
  `)}

  ${styleWhenTrue(propEq(AvatarType.ExtraLarge, "type"), css`
    font-size: 32px;

    ${Media.mobile`
      font-size: 38px;
    `}

    ${Media.tablet`
      font-size: 46px;
    `}
  `)}

  ${styleWhenTrue(prop("$dark"), css`
    color: ${Color.white};
  `)}
`;

export const Image = styled.img`
  width: 100%;
  border-radius: 50%;
`;

export const HatIcon = styled(HatBase)`
  width: 50%;
  border-radius: 50%;
  fill: ${Color.deepGray};

  ${styleWhenTrue(prop("$dark"), css`
    fill: ${Color.white};
  `)}
`;
