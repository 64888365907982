import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { ButtonColor, ButtonVariant } from "../../../../../components/button/button.constants";
import { ProgressMark } from "../../../../../components/progressMark/progressMark.component";
import { RightDrawer } from "../../../../../components/rightDrawer/rightDrawer.component";
import { ChartColorType } from "../../../../../components/simpleProgressChart/simpleProgressChart.constants";
import { Button } from "../../../../../components/button/button.component";
import { ReactComponent as InfoIcon } from "../../../images/infoCircle.svg";

import {
  CompetenceBox,
  Title,
  Level,
  InfoButton,
  ModalContent,
  ModalHeader,
  TextWrapper,
  CloseButton,
  CloseIcon,
  ModalTitle,
  ModalDescription,
  ModalButtons,
} from "./competenceItem.styled";

export const CompetenceItem = ({ competence, rate }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);

  const closeModal = () => setModalOpen(false);

  const directToDetails = () => {
    navigate(`/competence/${competence.id}/details`);
  };

  const renderMarkCircle = () => {
    if (!rate) return <ProgressMark />;

    return (
      <ProgressMark
        rate={rate.rate}
        maxRate={rate.maxRate}
        variant={ChartColorType.Gold}
      />
    );
  };

  const levelText = rate
    ? `${t("competence.level")} ${t(rate.name)}`
    : t("competence.ratingInProgress");

  const renderModalContent = () => (
    <ModalContent>
      <CloseButton onClick={closeModal}>
        <CloseIcon />
      </CloseButton>
      <ModalHeader>
        {renderMarkCircle()}
        <TextWrapper>
          <Title>{competence.name}</Title>
          <Level>{levelText}</Level>
        </TextWrapper>
      </ModalHeader>
      <ModalTitle>{t("profile.competenceItem.competenceDescription")}</ModalTitle>
      <ModalDescription>
        {competence.description}
      </ModalDescription>
      <ModalButtons>
        <Button color={ButtonColor.Primary} onClick={closeModal}>
          {t("profile.competenceItem.close")}
        </Button>
        <Button type={ButtonVariant.Outlined} onClick={directToDetails}>
          {t("profile.competenceItem.competenceDetails")}
        </Button>
      </ModalButtons>
    </ModalContent>
  );

  return (
    <>
      <CompetenceBox key={competence.id}>
        <InfoButton onClick={openModal}>
          <InfoIcon />
        </InfoButton>
        {renderMarkCircle()}
        <TextWrapper>
          <Title>{competence.name}</Title>
          <Level>{levelText}</Level>
        </TextWrapper>
      </CompetenceBox>
      <RightDrawer open={modalOpen} onClose={closeModal}>
        {renderModalContent()}
      </RightDrawer>
    </>
  );
};

CompetenceItem.propTypes = {
  competence: PropTypes.object.isRequired,
  rate: PropTypes.object,
};
