import { createActions, createReducer } from "reduxsauce";

export const { Types: NotificationsTypes, Creators: NotificationsActions } = createActions(
  {
    setIsPending: ["isPending"],
    getInitialData: null,
    getInitialDataSuccess: ["notifications", "nextPage"],
    setReadMessage: ["id"],
    setReadMessageSuccess: ["id"],
    deleteNotification: ["id"],
    loadNextPage: ["nextPage"],
    loadNextPageSuccess: ["notifications", "nextPage"],
  },
  { prefix: "NOTIFICATIONS/" }
);

const INITIAL_STATE = {
  isPending: true,
  notifications: [],
  nextPage: null,
};

const setIsPending = (state, { isPending }) => ({ ...state, isPending });

const getInitialDataSuccess = (state, { notifications, nextPage }) => ({
  ...state,
  notifications,
  nextPage,
});

const loadNextPageSuccess = (state, { notifications, nextPage }) => ({
  ...state,
  notifications: [...state.notifications, ...notifications],
  nextPage,
});

export const reducer = createReducer(INITIAL_STATE, {
  [NotificationsTypes.SET_IS_PENDING]: setIsPending,
  [NotificationsTypes.GET_INITIAL_DATA_SUCCESS]: getInitialDataSuccess,
  [NotificationsTypes.LOAD_NEXT_PAGE_SUCCESS]: loadNextPageSuccess,
});
