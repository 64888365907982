import styled from "styled-components";

import { ReactComponent as BasePlaylistIcon } from "../../../../images/playlist.svg";
import { Media } from "../../../../theme/media";
import { Color } from "../../../../theme/colors";
import { FontWeight } from "../../../../theme/fonts";

export const Container = styled.div`
  margin: 60px 0;
  display: flex;
  padding: 30px 20px;
  background-color: ${Color.white};

  ${Media.tablet`
    padding: 30px 35px;
  `}
`;

export const PlaylistIcon = styled(BasePlaylistIcon)`
  height: 20px;
  fill: ${Color.gray1};
  margin-top: 2px;
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 20px;

  ${Media.tablet`
    flex-direction: row;
  `}
`;

export const AddCodeButton = styled.button`
  padding: 0;
  background-color: ${Color.white};
  border: 0;
  color: ${() => Color.brandBasic};
  font-weight: ${FontWeight.Bold};
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  cursor: pointer;
  margin:  20px auto 0 0;

  ${Media.tablet`
    margin: 0 0 0 auto;
    font-size: 10px;
  `}
`;

export const BoldText = styled.p`
  font-size: 13px;
  line-height: 24px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.deepGray};
`;

export const Text = styled(BoldText)`
  color: ${Color.gray4};
  font-weight: ${FontWeight.Normal};

  ${Media.tablet`
    margin-left: 4px;
  `}
`;
