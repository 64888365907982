import styled from "styled-components";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";

import { ContentMaxWidth, Separator as SeparatorBase, Media, FontWeight } from "../../../../theme";

export const Container = styled.div`
  width: 100%;
  max-width: ${ContentMaxWidth};
`;

export const Title = styled.h3`
  font-size: 22px;
  line-height: 30px;
  font-weight: ${FontWeight.Bold};
  margin-bottom: 30px;
  margin-left: 25px;

  ${Media.tablet`
    font-size: 28px;
    line-height: 38px;
  `}
    
  ${Media.tabletWide`
    margin: 0;
  `}
`;

export const Separator = styled(SeparatorBase)`
  display: none;

  ${Media.tablet`
    display: block;
  `}
`;

export const ScrollProvider = styled(ScrollIntoViewIfNeeded)`
  & + & {
    margin-top: 10px;
  }
`;
