import * as Yup from "yup";

import i18n from "../../../../translations/i18n";

import { TitleLength, NotesLength } from "./suggestionForm.constants";

export const FormSchema = Yup.object().shape({
  title: Yup.string()
    .required(i18n.t("validationMessage.required"))
    .max(TitleLength, i18n.t("validationMessage.maxLength")),
  notes: Yup.string()
    .required(i18n.t("validationMessage.required"))
    .max(NotesLength, i18n.t("validationMessage.maxLength")),
});
