import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import {
  Color,
  ContentMaxWidth,
  Media,
  Separator as SeparatorBase,
} from "../../../../theme";

export const Container = styled.div`
  background-color: ${Color.almostWhite};
  padding: 40px 20px;

  ${styleWhenTrue(prop("isPending"), css`
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  `)}

  ${Media.tablet`
    padding: 40px 80px;
  `}

  ${Media.desktop`
    padding: 40px 160px;
  `}
`;

export const Wrapper = styled.div`
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
`;

export const ThumbnailsWrapper = styled.div`
  margin-top: 60px;
`;

export const Separator = styled(SeparatorBase)`
  && {
    margin: 30px 0 60px;
  }
`;

export const TrainingsWrapper = styled.div`
  margin-bottom: 60px;
`;
