import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Container, Text } from "./deletedNotification.styled";

export const DeletedNotification = ({ className }) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCollapsed(true);
    }, 4000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Container className={className} collapsed={collapsed}>
      <Text>
        {t("notifications.notifications.notificationDeleted")}
      </Text>
    </Container>
  );
};

DeletedNotification.propTypes = {
  className: PropTypes.string,
};
