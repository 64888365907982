import { QuestionType } from "./quizQuestions.constants";

const findParamValue = (params, name) => {
  return params.find((param) => param.name === name)?.value;
};

export const parseQuestions = (apiData) => {
  if (!apiData?.length) return [];

  const parsedQuestions = apiData.map(({ params, children, value }) => {
    const typeFromParams = findParamValue(params, "type");
    const questionType = typeFromParams === "multiple_choice" ? QuestionType.MultipleChoice : QuestionType.Error;
    const title = findParamValue(params, "title");
    const question = value;
    const answers = children.map(({ id, params, value }) => {
      const infotip = findParamValue(params, "infotip");
      const checked = findParamValue(params, "selected");
      const correct = findParamValue(params, "correct");

      return {
        id,
        infotip,
        checked,
        label: value,
        value: correct,
      };
    });

    return {
      title,
      question,
      answers,
      type: questionType,
    };
  });

  return parsedQuestions;
};
