import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { ProfileActions } from "../../modules/profile/redux/profile.reducer";
import { UserContextActions } from "../../userContext/redux/userContext.reducer";
import { clearApiCache } from "../../helpers/clearApiCache";
import { AppConfig } from "../../appConfig";

import { Container, Text, Link } from "./ambassadorAccept.styled";
import { AmbassadorAgreementName } from "./ambassadorAccept.constants";

export const AmbassadorAccept = ({ visible }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [mainModalOpen, setMainModalOpen] = useState(visible);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const handleMainModalDecline = () => {
    setMainModalOpen(false);
    setConfirmationModalOpen(true);
  };

  const handleConfirmationDecline = () => {
    setConfirmationModalOpen(false);
    setMainModalOpen(true);
  };

  const handleAccept = () => {
    setMainModalOpen(false);
    setConfirmationModalOpen(false);
    dispatch(ProfileActions.updateUserAgreements([{ name: AmbassadorAgreementName, agree: true }]));
  };

  const handleLogout = () => {
    dispatch(UserContextActions.setLoggedOut());
    clearApiCache();
  };

  const handleAmbassadorDecline = () => {
    setMainModalOpen(false);
    setConfirmationModalOpen(false);
    dispatch(ProfileActions.updateUserAgreements(
      [{ name: AmbassadorAgreementName, agree: false }],
      handleLogout
    ));
  };

  return (
    <>
      <Container
        open={!!mainModalOpen}
        onDecline={handleMainModalDecline}
        onConfirm={handleAccept}
      >
        <Text>
          {t("ambassador.info")}
          {": "}
          <Link
            href={`${AppConfig.content}/files/regulamin_grywalizacja.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("ambassador.regulations")}
          </Link>
        </Text>
        <Text>{t("ambassador.accept")}</Text>
      </Container>
      <Container
        open={confirmationModalOpen}
        onDecline={handleConfirmationDecline}
        onConfirm={handleAmbassadorDecline}
      >
        {t("ambassador.areYouSure")}
      </Container>
    </>
  );
};

AmbassadorAccept.propTypes = {
  visible: PropTypes.bool,
};
