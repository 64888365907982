export const ChartType = {
  Line: "line",
  Dashed: "dashed",
};

export const ChartColorType = {
  Gold: "gold",
  Silver: "silver",
  Bronze: "bronze",
  Maroon: "maroon",
  Brand: "brand",
  Blue: "blue",
};

export const ChartIcon = {
  GoldMedal: "goldMedal",
  SilverMedal: "silverMedal",
  BronzeMedal: "bronzeMedal",
  Clock: "clock",
  ColumnGraph: "columnGraph",
};

export const ChartUnit = {
  Percent: "percent",
  Unit: "unit",
  Minutes: "minutes",
}
