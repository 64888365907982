import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Theme } from "../../appConfig";
import { ExternalRegistration } from "../register.constants";

import { RegistrationType, AccountVariant } from "./firstStep.constants";
import {
  Content,
  Title,
  Asterix,
  OptionsRow,
  Option,
  InternalIcon,
  ExternalIcon,
  UserAddIcon,
  UserCheckIcon,
  OptionTitle,
  OptionText,
  OptionSubtitle,
} from "./firstStep.styled";

export const FirstStep = ({ setRegistrationType, setAccountVariant, setButtonDisabled }) => {
  const { t } = useTranslation();
  const [selectedRegistrationType, setSelectedRegistrationType] = useState();
  const [selectedAccountVariant, setSelectedAccountVariant] = useState();
  const externalRegistrationEnabled = Theme.ExternalRegistration === ExternalRegistration.Enabled;

  useEffect(() => {
    setButtonDisabled(!selectedAccountVariant || externalRegistrationEnabled ? !selectedRegistrationType : false);
  }, [
    selectedAccountVariant,
    selectedRegistrationType,
    externalRegistrationEnabled,
  ]);

  const selectExternalRegistration = () => {
    setSelectedRegistrationType(RegistrationType.External);
    setRegistrationType(RegistrationType.External);
  };

  const selectInternalRegistration = () => {
    setSelectedRegistrationType(RegistrationType.Internal);
    setRegistrationType(RegistrationType.Internal);
  };

  const setNewAccountVariant = () => {
    setSelectedAccountVariant(AccountVariant.New);
    setAccountVariant(AccountVariant.New);
  };

  const setAlreadyHadAccountVariant = () => {
    setSelectedAccountVariant(AccountVariant.Existing);
    setAccountVariant(AccountVariant.Existing);
  };

  return (
    <Content>
      <Title>
        {t("userRegistration.firstStep.didYouHaveAnAccount")}
        <Asterix>*</Asterix>
      </Title>
      <OptionsRow>
        <Option
          onClick={setNewAccountVariant}
          selected={selectedAccountVariant === AccountVariant.New}
          $unselected={selectedAccountVariant === AccountVariant.Existing}
        >
          <UserAddIcon />
          <OptionText>
            <OptionTitle>
              {t("userRegistration.firstStep.no")}
            </OptionTitle>
            <OptionSubtitle>
              {t("userRegistration.firstStep.newAccountConsequences")}
            </OptionSubtitle>
          </OptionText>
        </Option>
        <Option
          onClick={setAlreadyHadAccountVariant}
          selected={selectedAccountVariant === AccountVariant.Existing}
          $unselected={selectedAccountVariant === AccountVariant.New}
        >
          <UserCheckIcon />
          <OptionText>
            <OptionTitle>
              {t("userRegistration.firstStep.yes")}
            </OptionTitle>
            <OptionSubtitle>
              {t("userRegistration.firstStep.existingAccountConsequences")}
            </OptionSubtitle>
          </OptionText>
        </Option>
      </OptionsRow>
      {externalRegistrationEnabled && (
        <>
          <Title>{t("userRegistration.firstStep.registrationType")}</Title>
          <OptionsRow>
            <Option
              onClick={selectExternalRegistration}
              selected={selectedRegistrationType === RegistrationType.External}
              $unselected={selectedRegistrationType === RegistrationType.Internal}
            >
              <ExternalIcon />
              <OptionText>
                <OptionTitle>
                  {t("userRegistration.firstStep.external")}
                </OptionTitle>
                <OptionSubtitle>
                  {t("userRegistration.firstStep.whyExternal")}
                </OptionSubtitle>
              </OptionText>
            </Option>
            <Option
              onClick={selectInternalRegistration}
              selected={selectedRegistrationType === RegistrationType.Internal}
              $unselected={selectedRegistrationType === RegistrationType.External}
            >
              <InternalIcon />
              <OptionText>
                <OptionTitle>
                  {t("userRegistration.firstStep.internal")}
                </OptionTitle>
                <OptionSubtitle>
                  {t("userRegistration.firstStep.whyInternal")}
                </OptionSubtitle>
              </OptionText>
            </Option>
          </OptionsRow>
        </>
      )}
    </Content>
  );
};
