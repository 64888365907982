import React, { useState, useRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { CommonActions } from "../../../../redux/common/common.reducer";
import { FavouriteItemType } from "../../../../components/thumbnail/thumbnail.constants";
import { Tooltip } from "../../../../components/tooltip/tooltip.component";
import { FavouriteIcon, FavouriteListButton } from "../../../../components/thumbnail/thumbnail.styled";
import { ButtonColor } from "../../../../components/button/button.constants";
import { selectFavouriteAddRouteAvailable, selectUserRole } from "../../../../userContext/redux/userContext.selectors";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { UserRoleName } from "../../../../userContext/userContext.constants";
import { AppConfig } from "../../../../appConfig";
import { selectVideoInfo } from "../../redux/videoPlayer.selectors";
import { PlayerPanel } from "../playerPanel/playerPanel.component";

import {
  Container,
  VideoBox,
  VideoContainer,
  Video,
  PlayButton,
  PlayIcon,
  FinishScreen,
  BackButton,
  UpperBackButton,
  UpperSection,
  Loader,
  FullscreenWrapper,
} from "./videoSection.styled";

export const VideoSection = ({
  className,
  videoSrc,
  onFinish,
  timelineDisabled,
  title,
  isInteraction,
  onListClick,
  tags,
  baseUrl,
  url,
  callComplete,
  currentSlideIndex,
  slidesOverall,
  subtitle,
  isScrollContent,
  isScrollLesson,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const fullscreenWrapperRef = useRef(null);
  const lessonInfo = useSelector(state => state.lesson);
  const videoInfo = useSelector(selectVideoInfo);
  const favouriteAddRouteAvailable = useSelector(selectFavouriteAddRouteAvailable);
  const [videoReference, setVideoReference] = useState(null);
  const [fullscreenWrapperReference, setFullscreenWrapperReference] = useState(null);
  const [isFavoriteIconActive, setIsFavoriteIconActive] = useState(videoInfo?.favorite || lessonInfo?.favorite);
  const userRole = useSelector(selectUserRole);
  const contentId = videoInfo?.id || lessonInfo?.id;
  const description = videoInfo?.description || lessonInfo?.description;
  const groupId = videoInfo?.groupsId?.find(() => true) || lessonInfo?.groupsId?.find(() => true);
  const videoDuration = videoReference ? videoReference.duration : null;
  const favoriteItemType = groupId ? FavouriteItemType.ContentGroup : FavouriteItemType.Content;
  const fastForwardEnabled = !timelineDisabled || videoInfo?.fastForward || lessonInfo?.fastForward;
  const isLastSlide = currentSlideIndex === slidesOverall;
  const [finishScreenVisible, setFinishScreenVisible] = useState(false);
  const [currentTime, setCurrentTime] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pauseOnBlur, setPauseOnBlur] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    setIsFavoriteIconActive(videoInfo?.favorite || lessonInfo?.favorite);
  }, [lessonInfo, videoInfo]);

  const toggleFullscreen = useCallback(() => {
    setIsFullscreen(!isFullscreen);
  }, [isFullscreen]);

  useEffect(() => {
    window.addEventListener("fullscreenchange", toggleFullscreen);

    return () => {
      window.removeEventListener("fullscreenchange", toggleFullscreen);
    };
  }, [isFullscreen]);

  useEffect(() => {
    const onPlaying = () => {
      setIsLoading(false);
      setIsPlaying(true);
    };
    const onPause = () => {
      setIsLoading(false);
      setIsPlaying(false);
    };
    const onWaiting = () => {
      setIsLoading(true);
    };

    videoReference?.addEventListener("playing", onPlaying);
    videoReference?.addEventListener("pause", onPause);
    videoReference?.addEventListener("waiting", onWaiting);

    return () => {
      videoReference?.removeEventListener("playing", onPlaying);
      videoReference?.removeEventListener("pause", onPause);
      videoReference?.removeEventListener("waiting", onWaiting);
    }
  }, [videoReference]);

  const handlePause = useCallback(() => {
    if (!videoReference.paused && isPlaying) {
      videoReference.pause();
    }
  }, [videoReference, isPlaying]);

  const handlePlay = useCallback(() => {
    if (videoReference.paused && !isPlaying) {
      setFinishScreenVisible(false);
      videoReference.play();
    }
  }, [videoReference, isPlaying]);

  const handlePauseOnBlur = useCallback(() => {
    if (isPlaying) {
      setPauseOnBlur(true);
      handlePause();
    }
  }, [isPlaying, handlePause]);

  const handlePlayOnFocus = useCallback(() => {
    if (pauseOnBlur) {
      setPauseOnBlur(false);
      handlePlay();
    }
  }, [pauseOnBlur, handlePlay]);

  useEffect(() => {
    setVideoReference(videoRef.current);
  }, [videoRef]);

  useEffect(() => {
    setFullscreenWrapperReference(fullscreenWrapperRef.current);
  }, [fullscreenWrapperRef]);

  useEffect(() => {
    window.addEventListener("blur", handlePauseOnBlur);
    window.addEventListener("focus", handlePlayOnFocus);

    return () => {
      window.removeEventListener("blur", handlePauseOnBlur);
      window.removeEventListener("focus", handlePlayOnFocus);
    };
  }, [handlePauseOnBlur, handlePlayOnFocus]);

  const togglePlaying = () => {
    if (isPlaying) {
      handlePause();
    } else {
      handlePlay();
    }
  };

  const handleFinishAction = () => {
    if (document.fullscreenElement && document.exitFullscreen) {
      document.exitFullscreen();
    }

    setIsPlaying(false);
    setFinishScreenVisible(true);

    if (isInteraction || isScrollContent) {
      callComplete();
    } else {
      onFinish();
    }
  };

  const onTimeUpdate = () => {
    setCurrentTime(Math.floor(videoReference.currentTime));
  };

  const onBackClick = () => {
    const { state } = location;

    if (state?.from === UserRoleName.Onboarding) {
      navigate("/onboarding#next", {
        state: {
          currentView: state.currentView,
          currentStage: state.currentStage,
        },
      });
    } else if (userRole.name === UserRoleName.Onboarding) {
      navigate("/onboarding#next");
    } else {
      window.history.state?.idx ? navigate(-1) : navigate("/lekcje");
    }
  };

  const renderFinishScreen = renderWhenTrue(() => (
    <FinishScreen isScrollContent={isScrollContent}>
      {isScrollContent && !isLastSlide ? t("videoPlayer.goToNext") : t("videoPlayer.thankYou")}
      {!isScrollContent && (
        <BackButton color={ButtonColor.Primary} onClick={onBackClick}>
          {t("lesson.lesson.comeBackToList")}
        </BackButton>
      )}
    </FinishScreen>
  ));

  const handleToggleFavorite = () => {
    if (isFavoriteIconActive) {
      dispatch(CommonActions.removeFavouriteItem(
        favoriteItemType,
        favoriteItemType === FavouriteItemType.ContentGroup ? groupId : contentId,
      ));
    } else {
      dispatch(CommonActions.addFavouriteItem(
        favoriteItemType,
        favoriteItemType === FavouriteItemType.ContentGroup ? groupId : contentId,
      ));
    }
    setIsFavoriteIconActive(!isFavoriteIconActive);
  };

  return (
    <Container className={className} $withBgColor={!isScrollContent}>
      <VideoBox noPadding={isScrollLesson}>
        {!isScrollContent && (
          <UpperSection>
            <UpperBackButton
              defaultPath={userRole.name === UserRoleName.Onboarding ? "/onboarding#next" : "/lekcje"}
            />
            {favouriteAddRouteAvailable && (
              <Tooltip
                title={
                  isFavoriteIconActive
                    ? t("favouriteList.removeFromFavourites")
                    : t("favouriteList.addToFavourites")
                }
              >
                <FavouriteListButton onClick={handleToggleFavorite} $isFavourite={isFavoriteIconActive}>
                  <FavouriteIcon />
                </FavouriteListButton>
              </Tooltip>
            )}
          </UpperSection>
        )}
        <FullscreenWrapper ref={fullscreenWrapperRef} $fullscreen={isFullscreen}>
          <VideoContainer $fullscreen>
            <Video
              ref={videoRef}
              src={videoSrc || `${baseUrl}${url}`}
              onTimeUpdate={onTimeUpdate}
              onClick={togglePlaying}
              autoPlay={!isScrollContent}
              onPlay={handlePlay}
              onPause={handlePause}
              onEnded={handleFinishAction}
              hidden={finishScreenVisible}
              disablePictureInPicture
              controlsList="nodownload"
              $timelineDisabled={!fastForwardEnabled}
              isScrollContent={isScrollContent}
              controls={false}
              playsInline
              $fullscreen={isFullscreen}
            />
            {renderFinishScreen(!isPlaying && finishScreenVisible)}
            <PlayButton hidden={isPlaying || isLoading || finishScreenVisible} onClick={handlePlay}>
              <PlayIcon />
            </PlayButton>
            {isLoading && !finishScreenVisible && <Loader size={50} />}
          </VideoContainer>
          <PlayerPanel
            videoRef={videoReference}
            fullscreenWrapperRef={fullscreenWrapperReference}
            currentTime={currentTime}
            videoDuration={videoDuration}
            isPlaying={isPlaying}
            onPause={handlePause}
            onPlay={handlePlay}
            timelineDisabled={!AppConfig.timelineEnabled && !fastForwardEnabled}
            title={title}
            isInteraction={isInteraction}
            onListClick={onListClick}
            tags={tags}
            currentSlideIndex={currentSlideIndex}
            slidesOverall={slidesOverall}
            subtitle={subtitle}
            description={description}
            isScrollContent={isScrollContent}
            hideVolume={isScrollLesson}
            hideFullscreenButton={finishScreenVisible}
            fullscreen={isFullscreen}
          />
        </FullscreenWrapper>
      </VideoBox>
    </Container>
  );
};

VideoSection.propTypes = {
  className: PropTypes.string,
  onFinish: PropTypes.func,
  videoSrc: PropTypes.string,
  timelineDisabled: PropTypes.bool,
  title: PropTypes.string,
  isInteraction: PropTypes.bool,
  onListClick: PropTypes.func,
  tags: PropTypes.array,
  baseUrl: PropTypes.string,
  url: PropTypes.string,
  callComplete: PropTypes.func,
  currentSlideIndex: PropTypes.number,
  slidesOverall: PropTypes.number,
  subtitle: PropTypes.string,
  isScrollContent: PropTypes.bool,
  isScrollLesson: PropTypes.bool,
};
