import { ReactComponent as DiligentStudentIcon } from "../../../images/diligentStudent.svg";
import { ReactComponent as ImageMasterIcon } from "../../../images/imageMaster.svg";
import { ReactComponent as ComprehenderIcon } from "../../../images/comprehender.svg";
import { ReactComponent as SuperComprehenderIcon } from "../../../images/superComprehender.svg";
import { ReactComponent as ForumMasterIcon } from "../../../images/forumMaster.svg";
import { ReactComponent as ActiveCommentatorIcon } from "../../../images/activeCommentator.svg";
import { ReactComponent as ModelToFollowIcon } from "../../../images/modelToFollow.svg";
import { ReactComponent as OnTheWayUpIcon } from "../../../images/onTheWayUp.svg";
import { ReactComponent as TitanOfWorkIcon } from "../../../images/titanOfWork.svg";
import { ReactComponent as WorkLeaderIcon } from "../../../images/workLeader.svg";

export const BadgeIcon = [
  {
    enum: "diligent_student",
    color: "#FFD947",
    icon: DiligentStudentIcon,
  },
  {
    enum: "image_master",
    color: "#8EC63F",
    icon: ImageMasterIcon,
  },
  {
    enum: "comprehender",
    color: "#F97F8A",
    icon: ComprehenderIcon,
  },
  {
    enum: "super_comprehender",
    color: "#8EC63F",
    icon: SuperComprehenderIcon,
  },
  {
    enum: "forum_master",
    color: "#3498DB",
    icon: ForumMasterIcon,
  },
  {
    enum: "active_commentator",
    color: "#7E97E8",
    icon: ActiveCommentatorIcon,
  },
  {
    enum: "model_to_follow",
    color: "#F97F8A",
    icon: ModelToFollowIcon,
  },
  {
    enum: "on_the_way_up",
    color: "#FFD947",
    icon: OnTheWayUpIcon,
  },
  {
    enum: "titan_of_work",
    color: "#3498DB",
    icon: TitanOfWorkIcon,
  },
  {
    enum: "work_leader",
    color: "#7E97E8",
    icon: WorkLeaderIcon,
  },
];
