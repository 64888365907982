import React from "react";
import { useTranslation } from "react-i18next";

import { ButtonColor } from "../../../../components/button/button.constants";
import { Button } from "../../../../components/button/button.component";
import { QuizStatus, QuizSummary } from "../../../../quiz/summary/summary.styled";
import { Summary } from "../../../../quiz/summary/summary.component";
import { PageHeader } from "../../../../UIElements";

import { Spacer } from "./summaryWrapper.styled";
import { TranslateSummaryData } from "./summaryWrapper.utils";

export const SummaryWrapper = ({ type, data }) => {
  const { t } = useTranslation();

  const goBack = () => {
    window.history.back();
  };

  const passed = data.status;
  const result = data.surveyResult?.scorePercent ? data.surveyResult.scorePercent : 0;
  const scoreText = data.surveyResult?.scoreText ? data.surveyResult.scoreText : "";

  switch (type) {
  case "show_thanks":
    return (
      <div>
        <Spacer />
        <PageHeader>{t("form.form.thankYou")}!</PageHeader>
        <Spacer />
        <Button onClick={goBack} color={ButtonColor.Primary}>
          {t("form.form.returnToFormList")}
        </Button>
      </div>
    );
  case "show_result":
    return (
      <>
        {passed === true && <QuizStatus>{t("form.form.congratulationsTestPassed")}</QuizStatus>}
        {passed === false && <QuizStatus>{t("form.form.testNotPassed")}</QuizStatus>}
        <QuizSummary>
          {t("form.form.answeredCorrectlyOn")} {(result * 100).toFixed()}% {t("form.form.questions")}
        </QuizSummary>
        <QuizSummary>{scoreText}</QuizSummary>
      </>
    );
  case "show_summary":
    return (
      <div>
        <Summary
          quiz={{
            children: TranslateSummaryData(data.surveyResult.questions),
            passed,
            result
          }}
        />
        <Spacer />
        <Button onClick={goBack} color={ButtonColor.Primary}>
          {t("form.form.returnToFormList")}
        </Button>
      </div>
    );
  default:
    return null;
  }
};
