import React from "react";
import styled from "styled-components";

import { breakpoints } from "../../../UIGlobals";
import * as UI from "./timerSlidesUI";

const Header = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  @media (${breakpoints().maxM}) {
    font-size: 13px;
    line-height: 16px;
  }
`;

const Title = styled.div`
  font-size: 45px;
  font-weight: 300;
  line-height: 60px;
  margin: 10px 0 40px;
  text-align: center;
  width: 100%;
  @media (${breakpoints().maxM}) {
    font-size: 23px;
    line-height: 30px;
  }
`;

const Image = styled.img`
  max-height: 50px;
  @media (${breakpoints().maxM}) {
    max-height: 25px;
  }
`;

const TitleWithIcon = ({ data }) => (
  <UI.Container $bgImage={data.iconBaseUrl + data.iconRightUrl} $dark flexColumn>
    <Header>{data.header}</Header>
    <Title>{data.title}</Title>
    <div>
      <Image src={data.iconBaseUrl + data.iconUrl} alt="icon" />
    </div>
  </UI.Container>
);

export default TitleWithIcon;