import styled from "styled-components";

import { Button as ButtonBase } from "../../../../components/button/button.component";
import { Color } from "../../../../theme";
import AnswersBase from "../answers/answers.component";

export const Container = styled.div`
  padding-top: 25px;
  padding-bottom: 50px;
`;

export const Answers = styled(AnswersBase)`
  margin-top: 25px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled(ButtonBase)`
  min-width: 200px;
  background-color: ${Color.blue5};

  &:hover {
    background-color: ${Color.blue};
  }

  &:disabled {
    pointer-events: none;
  }
`;

const Message = styled.p`
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.2;
  text-align: right;
`;

export const ErrorMessage = styled(Message)`
  color: ${Color.error};
`;

export const InfoMessage = styled(Message)`
  color: ${Color.green};
`;
