import styled, { css, keyframes } from "styled-components";
import { prop } from "ramda";

import { hexToRgba } from "../../../../../helpers/colors";
import { styleWhenTrue } from "../../../../../helpers/rendering";
import { ReactComponent as EyeSvg } from "../../../../../images/eye.svg";
import { ReactComponent as LockSvg } from "../../../../../images/lock4.svg";
import { ReactComponent as CheckSvg } from "../../../../../images/check2.svg";
import { ReactComponent as TaskSvg } from "../../../../../images/task.svg";
import { Color, FontWeight } from "../../../../../theme";

export const EyeImage = styled(EyeSvg)`
  width: 16px;
  position: relative;
  top: 1px;

  g {
    fill: ${Color.white};
  }
`;

export const LockImage = styled(LockSvg)`
  width: 12px;
`;

export const TickImage = styled(CheckSvg)`
  width: 13px;
  fill: ${Color.green};
`;

export const TaskImage = styled(TaskSvg)``;

export const Title = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-weight: ${FontWeight.SemiBold};
  text-align: center;
  color: ${Color.white};
  min-width: 85px;
  max-width: 85px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const pulsation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.25); }
  100% { transform: scale(1); }
`;

export const Container = styled.button`
  width: 36px;
  height: 36px;
  border: 1px solid ${Color.white};
  position: absolute;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  background-color: ${Color.violet2};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  overflow: hidden;
  z-index: 2;
  box-shadow: 0 0 20px ${hexToRgba(Color.violet, 0.1)} ${hexToRgba(Color.violet, 0.1)}, 0 2px 4px ${hexToRgba(Color.violet, 0.2)}, 0 6px 20px ${hexToRgba(Color.violet, 0.2)};
  animation: ${pulsation} 1s linear infinite;

  ${Title} {
    display: none;
  }

  ${styleWhenTrue(prop("isTask"), css`
    background-color: ${Color.orange};
  `)}

  ${styleWhenTrue(prop("isDisabled"), css`
    background-color: ${Color.almostWhite};
    pointer-events: none;
    cursor: default;
    animation: none;
  `)}

  ${styleWhenTrue(prop("passed"), css`
    background-color: ${Color.veryLightGreen};
    animation: none;
  `)}

  &:hover {
    animation: none;
    width: 100px;
    height: 100px;
    top: calc(${({ top }) => `${top}px`} - 32px);
    left: calc(${({ left }) => `${left}px`} - 32px);
    background-color: ${Color.violet2};
    z-index: 3;

    ${styleWhenTrue(prop("isTask"), css`
      background-color: ${Color.orange};
    `)}

    ${EyeImage}, ${LockImage}, ${TickImage}, ${TaskImage} {
      display: none;
    }

    ${Title} {
      display: block;
    }
  }
`;
