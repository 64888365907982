import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  Container,
  Wrapper,
  IconWrapper,
  DownloadIcon,
  Title,
  Separator,
  TextWrapper,
  Type,
} from "./multiFilesDownload.styled";

export const MultiFilesDownload = ({ className, name, url }) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <Wrapper href={url} target="_blank">
        <IconWrapper>
          <DownloadIcon />
        </IconWrapper>
        <TextWrapper>
          <Title>{name}</Title>
          <Type>{t("global.filesToDownload")}</Type>
        </TextWrapper>
      </Wrapper>
      <Separator />
    </Container>
  );
};

MultiFilesDownload.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
};
