import React, { useMemo, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ReactComponent as TrashIcon } from "../../../../images/trash2.svg";
import { ReactComponent as PenIcon } from "../../../../images/pencil.svg";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { timestampToDate } from "../../../../helpers/date";
import { Modal } from "../../../modal/modal.component";
import { AvatarType } from "../../../avatar/avatar.constants";
import { Avatar } from "../../../avatar/avatar.component";
import { ConfirmationModal } from "../../../confirmationModal/confirmationModal.component";
import { CommentEdit } from "../commentEdit/commentEdit.component";

import { ModalType } from "./comment.constants";
import {
  Container,
  AvatarWrapper,
  Content,
  Text,
  Sender,
  DateText,
  OptionsDropdown,
  SystemMessage,
} from "./comment.styled";

export const Comment = (props) => {
  const {
    className,
    id,
    publisher,
    content,
    timestamp,
    permissions,
    onDelete,
    onEdit,
    isDeleted,
    isEdited,
  } = props;
  const { t } = useTranslation();
  const date = useMemo(() => timestampToDate(timestamp), [timestamp]);
  const name = `${publisher.firstName} ${publisher.lastName}`;
  const [openModal, setOpenModal] = useState();

  const handleOpenDeleteModal = () => setOpenModal(ModalType.Delete);

  const handleOpenEditModal = () => setOpenModal(ModalType.Edit);

  const handleCloseModal = () => setOpenModal(null);

  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  const handleEdit = useCallback((values) => {
    handleCloseModal();
    onEdit(values);
  }, [onEdit]);

  const renderOptionsDropdown = renderWhenTrue(() => (
    <OptionsDropdown
      editPermission={permissions.edit}
      deletePermission={permissions.delete}
      onEdit={handleOpenEditModal}
      onDelete={handleOpenDeleteModal}
      disabled={isDeleted}
    />
  ));

  const renderEditForm = renderWhenTrue(() => (
    <Modal
      open={openModal === ModalType.Edit}
      title={t("news.comments.edit")}
      HeaderIcon={PenIcon}
      onClose={handleCloseModal}
    >
      <CommentEdit
        id={id}
        content={content}
        onCancel={handleCloseModal}
        onSave={handleEdit}
      />
    </Modal>
  ));

  return (
    <Container className={className}>
      <AvatarWrapper>
        <Avatar
          type={AvatarType.Small}
          src={publisher.avatar}
          name={name}
          dark={publisher.isCurrentUser}
        />
      </AvatarWrapper>
      <Content>
        <Text>
          <Sender>{name}</Sender>
          {" "}
          {isDeleted ? (
            <SystemMessage>{t("lesson.comments.commentDeleted")}</SystemMessage>
          ) : content}
          {!isDeleted && isEdited && (
            <SystemMessage>{` (${t("lesson.comments.commentEdited")})`}</SystemMessage>
          )}
        </Text>
        <DateText>{date}</DateText>
      </Content>
      {renderOptionsDropdown(!isDeleted && !!permissions && Object.values(permissions).includes(true))}
      <ConfirmationModal
        open={openModal === ModalType.Delete}
        title={t("news.entry.deleteModalTitle", { type: t("news.typeLabel.comment") })}
        confirmText={t("news.entry.deleteModalConfirmText")}
        declineText={t("news.entry.deleteModalDeclineText")}
        HeaderIcon={TrashIcon}
        onClose={handleCloseModal}
        onConfirm={handleDelete}
      >
        {t("news.entry.deleteModalContent", { type: t("news.typeLabel.comment") })}
      </ConfirmationModal>
      {renderEditForm(openModal === ModalType.Edit)}
    </Container>
  );
};

Comment.propTypes = {
  className: PropTypes.string,
};
