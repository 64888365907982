import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";

export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
}

export const withParams = (Component) => {
  return (props) => <Component {...props} params={useParams()} />;
}

export const withLocation = (Component) => {
  return (props) => <Component {...props} location={useLocation()} />;
};
