import React from "react";
import styled from "styled-components";

import { colors } from "../UIGlobals";
import {
  ThumbnailSlider,
  ListHeader
} from "./";

const ListRowWrapper = styled.div`
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom + "px" : "0")};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom + "px" : "0")};
  border-bottom: ${props => (props.borderBottom
  ? ("1px solid " + (props.borderColor
    ? props.borderColor
    : colors().gray5 ))
  : "none")};
`;

const ThumbnailListRow = ({
  title,
  children,
  marginBottom,
  paddingBottom,
  borderBottom,
  borderColor,
  listHeader = true
}) =>
  children.length > 0 ? (
    <ListRowWrapper paddingBottom={paddingBottom} marginBottom={marginBottom} borderBottom={borderBottom} borderColor={borderColor}>
      {listHeader && <ListHeader>{title}</ListHeader>}
      <ThumbnailSlider>{children}</ThumbnailSlider>
    </ListRowWrapper>
  ) : (
    <div />
  );

export { ThumbnailListRow };
