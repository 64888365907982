import { createActions, createReducer } from "reduxsauce";

export const { Types: CompetenceTypes, Creators: CompetenceActions } = createActions(
  {
    setIsPending: ["isPending"],
    getUserCompetenceList: null,
    getUserCompetenceListSuccess: ["userCompetenceList"],
    getCompetenceSkills: ["id"],
    getCompetenceSkillsSuccess: ["competenceSkills"],
    getCompetenceOverview: ["id"],
    getCompetenceOverviewSuccess: ["competenceOverview"],
    getCompetenceStatistics: ["id"],
    getCompetenceStatisticsSuccess: ["competenceStatistics"],
    getCompetenceScales: ["id"],
    getCompetenceScalesSuccess: ["competenceScales"],
    getCompetenceHistory: ["id"],
    getCompetenceHistorySuccess: ["competenceHistory"],
    getCompetenceHistoryNextPage: ["endpoint"],
    appendCompetenceHistory: ["competenceHistory"],
    getResources: ["id"],
    getResourcesSuccess: ["resources"],
    enrollEvent: ["id"],
    enrollEventSuccess: ["id"],
    disenrollEvent: ["id"],
    disenrollEventSuccess: ["id"],
    clearSelfMarkSurvey: null,
    getSelfMarkSurvey: ["measureId"],
    getSelfMarkSurveySuccess: ["selfMarkSurvey"],
    sendSelfMarkSurvey: ["measureId", "answers", "onFinish"],
    getConfirmationCategories: null,
    getConfirmationCategoriesSuccess: ["confirmationCategories"],
    sendConfirmation: ["competenceId", "values", "onFinish"],
    sendConfirmationSuccess: ["confirmation"],
    getConfirmationList: ["competenceId"],
    getConfirmationListSuccess: ["confirmations"],
    sendSuggestion: ["values", "onFinish"],
  },
  { prefix: "COMPETENCE/" }
);

const INITIAL_STATE = {
  isPending: false,
  userCompetenceList: [],
  competenceOverview: null,
  resources: {
    statistic: null,
    items: [],
  },
  selfMarkSurvey: null,
  confirmationCategories: [],
  confirmations: [],
  competenceSkills: [],
};

const setIsPending = (state, { isPending }) => ({ ...state, isPending });

const getUserCompetenceListSuccess = (state, { userCompetenceList }) => ({
  ...state,
  userCompetenceList,
});

const getCompetenceSkillsSuccess = (state, { competenceSkills }) => ({
  ...state,
  competenceSkills,
});

const getCompetenceOverviewSuccess = (state, { competenceOverview }) => ({
  ...state,
  competenceOverview,
});

const getCompetenceStatisticsSuccess = (state, { competenceStatistics }) => ({
  ...state,
  competenceStatistics,
});

const getCompetenceScalesSuccess = (state, { competenceScales }) => ({
  ...state,
  competenceScales,
});

const getCompetenceHistorySuccess = (state, { competenceHistory }) => ({
  ...state,
  competenceHistory,
});

const appendCompetenceHistory = (state, { competenceHistory }) => ({
  ...state,
  competenceHistory: {
    data: [...state.competenceHistory.data, ...competenceHistory.data],
    nextPage: competenceHistory.nextPage
  }
});

const getResourcesSuccess = (state, { resources }) => ({
  ...state,
  resources,
});

const enrollEventSuccess = (state, { id }) => {
  const updatedItems = state.resources.items.map((item) => {
    if (!!item.course && item.id === id) {
      return {
        ...item,
        userFlags: {
          ...item.userFlags,
          isEnrolled: true,
        }
      }
    }
    return item;
  });

  return {
    ...state,
    resources: {
      ...state.resources,
      items: updatedItems,
    },
  };
};

const disenrollEventSuccess = (state, { id }) => {
  const updatedItems = state.resources.items.map((item) => {
    if (!!item.course && item.id === id) {
      return {
        ...item,
        userFlags: {
          ...item.userFlags,
          isEnrolled: false,
        }
      }
    }
    return item;
  });

  return {
    ...state,
    resources: {
      ...state.resources,
      items: updatedItems,
    },
  };
};

const clearSelfMarkSurvey = (state) => ({
  ...state,
  selfMarkSurvey: null,
});

const getSelfMarkSurveySuccess = (state, { selfMarkSurvey }) => ({
  ...state,
  selfMarkSurvey,
});

const getConfirmationCategoriesSuccess = (state, { confirmationCategories }) => ({
  ...state,
  confirmationCategories,
});

const sendConfirmationSuccess = (state, { confirmation }) => ({
  ...state,
  confirmations: [confirmation, ...state.confirmations],
});

const getConfirmationListSuccess = (state, { confirmations }) => ({
  ...state,
  confirmations,
});

export const reducer = createReducer(INITIAL_STATE, {
  [CompetenceTypes.SET_IS_PENDING]: setIsPending,
  [CompetenceTypes.APPEND_COMPETENCE_HISTORY]: appendCompetenceHistory,
  [CompetenceTypes.GET_USER_COMPETENCE_LIST_SUCCESS]: getUserCompetenceListSuccess,
  [CompetenceTypes.GET_COMPETENCE_OVERVIEW_SUCCESS]: getCompetenceOverviewSuccess,
  [CompetenceTypes.GET_COMPETENCE_STATISTICS_SUCCESS]: getCompetenceStatisticsSuccess,
  [CompetenceTypes.GET_COMPETENCE_SCALES_SUCCESS]: getCompetenceScalesSuccess,
  [CompetenceTypes.GET_COMPETENCE_HISTORY_SUCCESS]: getCompetenceHistorySuccess,
  [CompetenceTypes.GET_COMPETENCE_SKILLS_SUCCESS]: getCompetenceSkillsSuccess,
  [CompetenceTypes.GET_RESOURCES_SUCCESS]: getResourcesSuccess,
  [CompetenceTypes.ENROLL_EVENT_SUCCESS]: enrollEventSuccess,
  [CompetenceTypes.DISENROLL_EVENT_SUCCESS]: disenrollEventSuccess,
  [CompetenceTypes.CLEAR_SELF_MARK_SURVEY]: clearSelfMarkSurvey,
  [CompetenceTypes.GET_SELF_MARK_SURVEY_SUCCESS]: getSelfMarkSurveySuccess,
  [CompetenceTypes.GET_CONFIRMATION_CATEGORIES_SUCCESS]: getConfirmationCategoriesSuccess,
  [CompetenceTypes.SEND_CONFIRMATION_SUCCESS]: sendConfirmationSuccess,
  [CompetenceTypes.GET_CONFIRMATION_LIST_SUCCESS]: getConfirmationListSuccess,
});
