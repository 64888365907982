import styled from "styled-components";
import { Drawer } from "@mui/material";

import { Media } from "../../theme/media";

export const Container = styled(Drawer)`
  .MuiPaper-root {
    width: 85%;

    ${Media.desktop`
      width: 974px;
    `}
  }
`;
