import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Color } from "../../../../theme";
import { styleWhenTrue } from "../../../../helpers/rendering";

export const FilterButton = styled.button`
  position: relative;
  padding: 10px 18px;
  background-color: ${Color.white};
  border: 1px solid ${Color.gray5};
  color: ${Color.black};
  border-radius: 39px;
  font-size: 12px;
  flex-shrink: 0;

  &:hover {
    background-color: ${Color.gray8};
  }

  &:not(:last-of-type) {
    margin-right: 15px;
  }

  ${styleWhenTrue(prop("active"), css`
    background-color: ${() => Color.brandBasic};
    color: ${Color.white};
    border: 1px solid ${Color.transparent};

    &:hover {
      background-color: ${() => Color.brandBasic};
    }
  `)}
`;
