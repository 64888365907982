import React from "react";
import { useTranslation } from "react-i18next";

import { QuestionType } from "../../interactions/external/quizQuestions/quizQuestions.constants";

import {
  Container,
  Separator,
  Title,
  Question,
  Hint,
} from "./interactionQuestionHeader.styled";

const InteractionQuestionHeader = ({ type, title, question, hint }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{title}</Title>
      <Separator />
      <Question dangerouslySetInnerHTML={{ __html: question }} />
      {type === QuestionType.MultipleChoice && (
        <Hint>{t("interactions.quizQuestions.multipleHint")}</Hint>
      )}
      {hint && <Hint>{hint}</Hint>}
    </Container>
  );
};

export default InteractionQuestionHeader;
