import { all, takeLatest } from 'redux-saga/effects';

import { EmailVerificationTypes } from './emailVerification.actions';
import { checkToken } from './emailVerification.sagas';

export function* watchEmailVerification() {
  yield all([
    // TODO: Change Reduxsauce for sth else, it cannot work with Typescript
    yield takeLatest(EmailVerificationTypes.CHECK_TOKEN, checkToken),
  ]);
}
