import styled, { css } from "styled-components";
import { prop } from "ramda";

import { ReactComponent as MovieIconBase } from "../../../../../images/movie.svg";
import { Input as InputBase } from "../../../../../components/input/input.component";
import { styleWhenTrue } from "../../../../../helpers/rendering";
import { Color, FontWeight, Media } from "../../../../../theme";

export const Header = styled.h3`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.9px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.deepGray};
  margin-bottom: 30px;
  margin-left: 0;
`;

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${Media.tablet`
    height: 550px;
  `}
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  ${Media.tablet`
    padding: 60px 250px 0 30px;
  `}
`;

export const ThumbnailHeader = styled.h4`
  font-size: 12px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.deepGray};
  margin-top: 35px;
`;

export const ImageThumbnailContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
  align-items: center;

  > * {
    margin: 5px;
  }

  ${Media.tablet`
    > * {
      margin: 0 5px 0 0;
    }

  `}
`;

export const ImageThumbnail = styled.img`
  border-radius: 3px;
  opacity: 0.8;
  cursor: pointer;
  width: 130px;
  height: 88px;

  ${Media.tablet`
    width: 100px;
    height: 63px;
  `}

  ${styleWhenTrue(prop("selected"),css`
    border: 2px solid ${() => Color.brandBasic};
    opacity: 1;
  `)}
`;

export const InfoText = styled.span`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: ${Color.gray4};
  text-align: center;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid ${Color.gray8};
  height: 100px;
  padding: 0 30px;

  ${Media.tablet`
    justify-content: space-between;
  `}
`;

export const MovieIcon = styled(MovieIconBase)`
  height: 21px;
  width: 19px;
  margin-right: 15px;
`;

export const TransferInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RequiredField = styled.span`
  font-size: 10px;
  color: ${Color.gray4};
  position: absolute;
  top: 14px;
  right: 10px;

  ${Media.tablet`
    right: 20px;
  `}
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Input = styled(InputBase)`
  padding-right: 110px;
  ${Media.tablet`
    padding-right: 90px;
  `}
`;
