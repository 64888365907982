import styled from "styled-components";

import { ReactComponent as CloseSVG } from "../../../../images/close2.svg";
import { Color } from "../../../../theme";

export const Container = styled.div`
  padding: 80px 40px 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CloseIcon = styled(CloseSVG)`
  height: 12px;
  width: 12px;
  fill: ${Color.deepGray};
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 30px;
  top: 30px;
  background-color: ${Color.transparent};
  border: none;
`;
