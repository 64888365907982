import { CookieName } from "../../cookiePopup.constants";

export const CookieInfoData = [{
  cookieName: CookieName.Necessary,
  titleKey: "cookiePopup.necessaryTitle",
  textKey: "cookiePopup.necessaryText",
}, {
  cookieName: CookieName.Analytical,
  titleKey: "cookiePopup.analyticTitle",
  textKey: "cookiePopup.analyticText",
  editable: true,
}, {
  cookieName: CookieName.Functional,
  titleKey: "cookiePopup.functionalTitle",
  textKey: "cookiePopup.functionalText",
  editable: true,
}];
