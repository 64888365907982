import i18n from "../../translations/i18n";
import { handleApiError, phpApi } from "../../api";

export const tryLogin = (login, password, recaptchaResponse) => {
  return phpApi.post(
    "/api/user/login",
    {
      login,
      password,
      "g-recaptcha-response": recaptchaResponse,
    },
    { removeDataBody: true },
  ).catch((error) => {
    const errorData = error.response
      || { data: { message: i18n.t("userContext.login.connectionError"), success: false } };

    return errorData;
  });
};

export const updateUserInfo = () => {
  return phpApi.get("/api/user/info").catch(handleApiError);
};

export const pushToken = (token) => {
  return phpApi.post("/api/push", { token }).catch(handleApiError);
};
