import styled from "styled-components";

import { Media, Color, FontWeight } from "../../theme";
import { Button as ButtonBase } from "../button/button.component";
import { VisibilityField as VisibilityFieldBase } from "../visibilityField/visibilityField.component";


export const Container = styled.div``;

export const Fields = styled.div`
  padding: 20px;

  ${Media.tablet`
    padding: 20px 30px;
  `}
`;

export const Actions = styled.div`
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${Media.tablet`
    padding: 30px;
  `}
`;

export const Button = styled(ButtonBase)`
  & + & {
    margin-left: 10px;
  }
`;

export const VisibilityField = styled(VisibilityFieldBase)`
  padding-top: 30px;
  padding-bottom: 15px;
`;

export const ChallengeField = styled.div`
  border-top: 1px solid ${Color.dirtyWhite};
  margin-top: 20px;
  padding-top: 20px;

  ${Media.tablet`
    display: flex;
    align-items: center;
  `}
`;

export const ChallengeText = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.deepGray};
  margin-right: 20px;
  margin-bottom: 10px;
`;

export const DropdownWrapper = styled.div`
  width: 100%;

  ${Media.mobile`
    width: auto;
    min-width: 400px;
  `}
`;
