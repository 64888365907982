import React from "react";
import PropTypes from "prop-types";

import { Container, TextField } from "./animatedInput.styled";

export const AnimatedInput = (props) => {
  const {
    className,
    label,
    value,
    onChange,
    isError,
    autofocus,
  } = props;

  return (
    <Container className={className}>
      <TextField
        autoFocus={autofocus}
        error={isError}
        label={label}
        value={value}
        onChange={onChange}
        variant="standard"
      />
    </Container>
  );
};

AnimatedInput.propTypes = {
  className: PropTypes.string,
};
