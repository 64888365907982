import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { DetectorHandler, OfflineHandler } from "../../../../helpers/connectionHandler";
import NetworkErrorModal from "../../../../app/networkErrorModal";
import { CertificateStatusId } from "../../certificates.constants";

import {
  Container,
  CupIcon,
  NameWrapper,
  Name,
  Subname,
  ExpirationDate,
  ArrowIcon,
} from "./pathBox.styled";

export const PathBox = ({ id, name, description, expirationDate, statusId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [offlineMode, setOfflineMode] = useState(false);
  const expirationDateLabel = useMemo(() => {
    switch (statusId) {
    case CertificateStatusId.InProgress:
      return t("certificates.certificates.passTo");
    case CertificateStatusId.Failed:
      return t("certificates.certificates.notPassed");
    case CertificateStatusId.Passed:
    case CertificateStatusId.Complete:
      return t("certificates.certificates.passed");
    default:
      return "";
    }
  }, [statusId]);

  const goToPath = () => {
    navigate(`/certyfikaty/${id}`);
  };

  const handleClick = (online) => {
    online ? goToPath(id) : setOfflineMode(true);
  };

  return (
    <DetectorHandler render={({ online }) => (
      <Container $status={statusId} onClick={() => handleClick(online)}>
        {offlineMode && (
          <OfflineHandler>
            <NetworkErrorModal isOpen />
          </OfflineHandler>
        )}
        <CupIcon $status={statusId} />
        <NameWrapper>
          <Name>{name}</Name>
          <Subname>{description}</Subname>
          {!!expirationDate && (
            <ExpirationDate $status={statusId}>
              {expirationDateLabel}:
              {" "}
              {dayjs.unix(expirationDate).format(
                statusId === CertificateStatusId.InProgress ? "DD.MM.YYYY" : "YYYY"
              )}
            </ExpirationDate>
          )}
        </NameWrapper>
        <ArrowIcon $status={statusId} />
      </Container>
    )} />
  );
};

PathBox.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  expirationDate: PropTypes.number,
  statusId: PropTypes.number,
};
