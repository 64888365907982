/* eslint-disable max-lines */
import {
  Breakpoint,
  Button,
  ButtonSize,
  ButtonTheme,
  ButtonType,
  CheckboxSize,
  Color,
  ComboBox,
  DateInput,
  DisclaimerVariant,
  FontWeight,
  IconName,
  Input,
  Select,
  SelectBox,
  SelectBoxValue,
  SelectOptionType,
  TabItemType,
  TabsSelect,
  TextArea,
  Typography,
  TypographyVariant,
  UseState,
} from '@elearning-platform/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { TransProps, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { NavigateFunction, useNavigate } from 'react-router';
import { AnyAction, Dispatch } from 'redux';
import { validatePolish } from 'validate-polish';

import { Path } from '../../../../enums';
import { currentLanguage } from '../../../../helpers/translations';
import { RegistrationActions } from '../../redux/registration.actions';
import { selectAccountType, selectCompanies, selectSections } from '../../redux/registration.selectors';
import { SectionName } from '../../registration.enums';
import { Buttons } from '../../registration.styled';
import { AccountType, Company, Duty, FormSection, SectionField } from '../../registration.types';
import { DutyDrawer } from '../dutyDrawer/dutyDrawer.component';

import { divisions } from './step2.constants';
import { schema } from './step2.schema';
import {
  AgreementsList,
  AgreementsWrapper,
  Container,
  Fields,
  FieldsRow,
  OptionsWrapper,
  StyledButton,
  StyledCheckboxWithText,
  StyledDisclaimer,
  Wrapper,
} from './step2.styled';
import { Answers, ControllerRender, Step2Props } from './step2.types';

export const Step2: React.FC<Step2Props> = ({ goBack }: Step2Props): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const dispatch: Dispatch<AnyAction> = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const isSmallDesktop: boolean = useMediaQuery({ query: Breakpoint.SmallDesktop });
  const accountType: AccountType = useSelector(selectAccountType) as AccountType;
  const companies: Company[] | null = useSelector(selectCompanies) as Company[] | null;
  const sections: FormSection[] | null = useSelector(selectSections) as FormSection[] | null;
  const agreements: FormSection | undefined = sections?.find(
    (section: FormSection): boolean => section.name === SectionName.Agreements
  );
  const [selectedUserType, setSelectedUserType]: UseState<TabItemType> = useState<TabItemType>(divisions[0]);
  const [openDutiesDrawer, setOpenDutiesDrawer]: UseState<boolean> = useState<boolean>(false);
  const [selectedDuties, setSelectedDuties]: UseState<Duty[]> = useState<Duty[]>([]);
  const [selectedDate, setSelectedDate]: UseState<dayjs.Dayjs | undefined> = useState<dayjs.Dayjs | undefined>();
  const [renderCombobox, setRenderCombobox] = useState(false);
  const [selectedDealerStation, setSelectedDealerStation]: UseState<Company | null> = useState<Company | null>(null);
  const [selectedCompanies, setSelectedCompanies]: UseState<Company[]> = useState<Company[]>([]);
  const [selectedAgreements, setSelectedAgreements]: UseState<string[]> = useState<string[]>([]);
  const [validationEnabled, setValidationEnabled]: UseState<boolean> = useState<boolean>(false);
  const [peselValid, setPeselValid]: UseState<boolean> = useState<boolean>(true);
  const currentDate: dayjs.Dayjs = useMemo((): dayjs.Dayjs => dayjs(), []);

  const { control, handleSubmit, setValue, getValues, formState: { errors } }: UseFormReturn<Answers> = useForm({
    defaultValues: {
      account_type: accountType,
      user_type: selectedUserType.value,
      user_date: '',
      user_name: '',
      user_surname: '',
      user_email: '',
      user_position: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect((): void => {
    setValue('user_type', selectedUserType.value);
    setValue('user_company_id', undefined);
    setSelectedDuties([]);
    setSelectedCompanies([]);
    setSelectedDealerStation(null);
    dispatch(RegistrationActions.fetchCompanies(selectedUserType.value));
    dispatch(RegistrationActions.fetchDuties(selectedUserType.value));
  }, [dispatch, setValue, selectedUserType.value]);

  useEffect((): () => void => {
    setTimeout((): void => setRenderCombobox(true), 1); // Workaround to current Combobox state

    return (): void => {
      setRenderCombobox(false);
    };
  }, [companies]);

  useEffect((): void => {
    if (validationEnabled) {
      const peselIsValid: boolean = validatePolish.pesel(getValues().user_pesel || '');
      setPeselValid(peselIsValid);
    }
  }, [getValues, validationEnabled]);

  const removeSelectedDuty: (id: number) => void = (id: number): void => {
    setSelectedDuties(selectedDuties.filter((duty: Duty): boolean => duty.id !== id));
  };

  const selectDate: (value: dayjs.Dayjs | null) => void = (value: dayjs.Dayjs | null): void => {
    if (value) {
      setSelectedDate(value);
      setValue('user_date', value.toString());
    }
  };

  const selectDealerStation: (id: string) => void = (id: string): void => {
    const selectedStation: Company | undefined = companies?.find(
      (company: Company): boolean => company.id.toString() === id
    );

    if (selectedStation) {
      setSelectedDealerStation(selectedStation);
      setValue('user_company_id', selectedStation.id);
    }
  };

  const selectCompany: (id: string, index: number) => void = (id: string, index: number): void => {
    const availableCompanies: Company[] | null = index === 0 ? companies : selectedCompanies[index - 1].children;
    const selectedCompany: Company | undefined = availableCompanies?.find(
      (company: Company): boolean => company.id.toString() === id
    );

    if (selectedCompany) {
      const newArray: Company[] = selectedCompanies.slice(0, index);
      newArray[index] = selectedCompany;
      setSelectedCompanies(newArray);
      setValue('user_company_id', selectedCompany.id);
    }
  };

  const toggleAgreement: (name: string) => void = (name: string): void => {
    if (selectedAgreements.includes(name)) {
      setSelectedAgreements(
        selectedAgreements.filter((agreementName: string): boolean => agreementName !== name)
      );
    } else {
      setSelectedAgreements([...selectedAgreements, name]);
    }
  };

  const agreementsValid: () => boolean = (): boolean => {
    const requiredAgreements: SectionField[] | undefined = agreements?.fields.filter(
      (field: SectionField): boolean => !!field.required
    );
    const agreementsCorrect: boolean | undefined = requiredAgreements?.every(
      (agreement: SectionField): boolean => selectedAgreements.includes(agreement.name)
    );

    return !!agreementsCorrect;
  };

  const onSubmit: (data: Answers) => void = (data: Answers): void => {
    if (!agreementsValid()) {
      return;
    }

    if ((selectedUserType === divisions[0] && !!selectedDealerStation?.flags.pesel_visible)
      || (selectedUserType === divisions[1] && !!selectedCompanies[0]?.flags.pesel_visible)
    ) {
      if (!validatePolish.pesel(data.user_pesel || '')) {
        setPeselValid(false);

        return;
      }
    }

    const agreementsAccepted: { [key: string]: true } = selectedAgreements.reduce((
      prevValue: { [key: string]: true },
      currentValue: string
    ): { [key: string]: true } => ({
      ...prevValue,
      [`agreements_${currentValue}`]: true,
    }), {});

    if (selectedUserType === divisions[0] && selectedDealerStation && selectedDuties.length) {
      dispatch(RegistrationActions.sendForm(
        {
          ...data,
          user_duties: selectedDuties.map((duty: Duty): number => duty.id).toString(),
          ...agreementsAccepted,
        },
        (): void => navigate(Path.ConfirmEmail),
      ));
    } else if (
      (selectedUserType === divisions[1] && selectedCompanies.length)
      || selectedUserType === divisions[2]
    ) {
      dispatch(RegistrationActions.sendForm(
        { ...data, ...agreementsAccepted },
        (): void => navigate(Path.ConfirmEmail),
      ));
    }
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Wrapper>
        <Typography
          fontWeight={FontWeight.Bold}
          variant={isSmallDesktop ? TypographyVariant.TextLG : TypographyVariant.TextMD}
        >
          {t('registration.step2.fill')}
        </Typography>
        <Fields>
          <OptionsWrapper>
            <Typography fontWeight={FontWeight.Bold} variant={TypographyVariant.Subheader}>
              {t('registration.step2.chooseDivision')}
            </Typography>
            <TabsSelect
              activeItem={selectedUserType}
              items={divisions}
              onChange={setSelectedUserType}
            />
          </OptionsWrapper>
          <FieldsRow>
            <Controller
              control={control}
              name='user_name'
              render={({ field }: ControllerRender<'user_name'>): JSX.Element => (
                <Input
                  {...field}
                  description={errors.user_name?.message}
                  id='user_name'
                  label={t('registration.step2.name')}
                  withError={!!errors.user_name}
                />
              )}
            />
            <Controller
              control={control}
              name='user_surname'
              render={({ field }: ControllerRender<'user_surname'>): JSX.Element => (
                <Input
                  {...field}
                  description={errors.user_surname?.message}
                  id='user_surname'
                  label={t('registration.step2.surname')}
                  withError={!!errors.user_surname}
                />
              )}
            />
          </FieldsRow>
          <Controller
            control={control}
            name='user_email'
            render={({ field }: ControllerRender<'user_email'>): JSX.Element => (
              <Input
                {...field}
                description={errors.user_email?.message}
                id='user_email'
                label={t('registration.step2.email')}
                withError={!!errors.user_email}
              />
            )}
          />
          {selectedUserType === divisions[0] && !!renderCombobox && (
            <ComboBox
              description={
                errors.user_company_id?.message
                || (validationEnabled && !selectedDealerStation && t('registration.step2.fieldRequired') || '')
              }
              emptyText={t('registration.step2.noResults')}
              icon={IconName.Search}
              label={t('registration.step2.dealerStationNr')}
              onSelect={selectDealerStation}
              options={companies?.map((company: Company): SelectOptionType => ({
                label: company.name,
                value: company.id.toString(),
              })) || []}
              value={selectedDealerStation?.id.toString() ?? ''}
              withError={!!errors.user_company_id || (validationEnabled && !selectedDealerStation)}
            />
          )}
          {selectedUserType === divisions[1] && !selectedCompanies.length && (
            <Select
              description={(validationEnabled && t('registration.step2.fieldRequired')) || ''}
              label={t('registration.step2.chooseCompany')}
              onSelect={(id: string): void => selectCompany(id, 0)}
              options={companies?.map((company: Company): SelectOptionType => ({
                label: company.name,
                value: company.id.toString(),
              })) || []}
              value=''
              withError={!!validationEnabled}
            />
          )}
          {selectedUserType === divisions[1] && selectedCompanies.map((company: Company, index: number): JSX.Element => {
            const companiesAvailable: Company[] | null = index === 0 ? companies : selectedCompanies[index - 1].children;

            return (
              <>
                <Select
                  key={company.id}
                  label={t('registration.step2.chooseCompany')}
                  onSelect={(id: string): void => selectCompany(id, index)}
                  options={companiesAvailable?.map((company: Company): SelectOptionType => ({
                    label: company.name,
                    value: company.id.toString(),
                  })) || []}
                  value={company.id.toString()}
                />
                {index === selectedCompanies.length - 1 && !!company.children.length && (
                  <Select
                    label={t('registration.step2.chooseCompany')}
                    onSelect={(id: string): void => selectCompany(id, index + 1)}
                    options={company.children.map((company: Company): SelectOptionType => ({
                      label: company.name,
                      value: company.id.toString(),
                    })) || []}
                    value=''
                  />
                )}
              </>
            );
          })}
          {selectedUserType === divisions[2] && (
            <Controller
              control={control}
              name='user_company_name'
              render={({ field }: ControllerRender<'user_company_name'>): JSX.Element => (
                <Input
                  {...field}
                  description={
                    errors.user_company_name?.message
                    || (validationEnabled && !field.value && t('registration.step2.fieldRequired'))
                    || ''
                  }
                  id='user_company_name'
                  label={t('registration.step2.companyName')}
                  value={field.value ?? ''}
                  withError={!!errors.user_company_name || (validationEnabled && !field.value)}
                />
              )}
            />
          )}
          <Controller
            control={control}
            name='user_position'
            render={({ field }: ControllerRender<'user_position'>): JSX.Element => (
              <Input
                {...field}
                description={errors.user_position?.message}
                id='user_position'
                label={t('registration.step2.position')}
                withError={!!errors.user_position}
              />
            )}
          />
          {selectedUserType === divisions[0] && (
            <SelectBox
              buttonLabel={t('registration.step2.add')}
              description={
                (!!validationEnabled && !selectedDuties.length && t('registration.step2.fieldRequired')) || ''
              }
              headline={t('registration.step2.duties')}
              icon={IconName.Add}
              onAddClick={(): void => setOpenDutiesDrawer(true)}
              onRemoveClick={(id: string): void => removeSelectedDuty(Number(id))}
              values={selectedDuties.map(({ name, groupName, id }: Duty): SelectBoxValue => ({
                primaryText: groupName,
                secondaryText: name,
                id: id.toString(),
              }))}
              withError={!!validationEnabled && !selectedDuties.length}
            />
          )}
          <LocalizationProvider adapterLocale={currentLanguage} dateAdapter={AdapterDayjs}>
            <DateInput
              brandColor={Color.Brand300}
              description={
                (!!validationEnabled && !selectedDate && t('registration.step2.fieldRequired'))
                || (!!validationEnabled && !!selectedDate && selectedDate > currentDate && t('registration.step2.invalidDate'))
                || ''
              }
              label={t('registration.step2.startDate')}
              maxDate={currentDate}
              onChange={selectDate}
              value={selectedDate}
              withError={!!validationEnabled && (!selectedDate || selectedDate > currentDate)}
            />
          </LocalizationProvider>
          {(selectedUserType === divisions[0] || (selectedUserType === divisions[1] && !!selectedCompanies[0]?.flags.pesel_visible)) && (
            <Controller
              control={control}
              name='user_pesel'
              render={({ field }: ControllerRender<'user_pesel'>): JSX.Element => (
                <Input
                  {...field}
                  description={
                    errors.user_pesel?.message
                    || (validationEnabled && !peselValid && t('registration.step2.invalidPesel') || '')
                  }
                  id='user_pesel'
                  label={t('registration.step2.pesel')}
                  onChange={(event: React.FormEvent<HTMLInputElement>): void => {
                    setPeselValid(true);
                    field.onChange(event);
                  }}
                  value={field.value ?? ''}
                  withError={!!errors.user_pesel || (validationEnabled && !peselValid)}
                />
              )}
            />
          )}
          {selectedUserType === divisions[2] && (
            <Controller
              control={control}
              name='user_purpose'
              render={({ field }: ControllerRender<'user_purpose'>): JSX.Element => (
                <TextArea
                  {...field}
                  description={
                    errors.user_purpose?.message
                    || (validationEnabled && !field.value && t('registration.step2.fieldRequired'))
                    || ''
                  }
                  id='user_purpose'
                  label={t('registration.step2.purpose')}
                  maxLength={500}
                  value={field.value ?? ''}
                  withError={!!errors.user_purpose || (validationEnabled && !field.value)}
                />
              )}
            />
          )}
          <AgreementsWrapper>
            <StyledDisclaimer
              content={`<b>${agreements?.additionalInfo?.administrator_info || ''}</b>`}
              icon={IconName.Flare}
              title=''
              variant={DisclaimerVariant.Brand}
            />
            <AgreementsList>
              {agreements?.fields.map((field: SectionField): JSX.Element => {
                const checked: boolean = selectedAgreements.includes(field.name);

                return (
                  <StyledCheckboxWithText
                    checked={checked}
                    description={t('registration.step2.fieldRequired')}
                    key={field.id}
                    label={`<div>${field.label}</div>`}
                    onClick={(): void => toggleAgreement(field.name)}
                    size={CheckboxSize.Small}
                    withError={!!validationEnabled && !!field.required && !checked}
                  />
                );
              })}
            </AgreementsList>
          </AgreementsWrapper>
        </Fields>
        <Buttons>
          <StyledButton
            label={t('registration.step2.send')}
            onClick={(): void => setValidationEnabled(true)}
            size={ButtonSize.Large}
            type={ButtonType.Submit}
            variant={ButtonTheme.PrimaryRed}
          />
          <Button
            label={t('registration.step2.goBack')}
            onClick={goBack}
            size={ButtonSize.Large}
            variant={ButtonTheme.TertiaryGray}
          />
        </Buttons>
        <DutyDrawer
          confirmDuties={setSelectedDuties}
          initDuties={selectedDuties}
          onClose={(): void => setOpenDutiesDrawer(false)}
          open={openDutiesDrawer}
        />
      </Wrapper>
    </Container>
  );
};
