export const ActionType = {
  WS_CONNECTED: "WS_CONNECTED",
  WS_DISCONNECTED: "WS_DISCONNECTED",
  WS_MESSAGE: "WS_MESSAGE",
  WS_SEND_MESSAGE: "WS_SEND_MESSAGE",
};

export const MessageType = {
  Connected: "CONNECTED",
  Authorize: "Authorize",
  AchievementReceive: "ACHIEVEMENT.RECEIVE",
  AchievementReceived: "ACHIEVEMENT.RECEIVED",
  UserRefresh: "User.Refresh",
};
