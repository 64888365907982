import styled from "styled-components";

import { Button as ButtonBase } from "../../components/button/button.component";
import { Color, FontWeight, Media } from "../../theme";

export const Container = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  left: 20px;
  z-index: 999999;
  background-color: ${Color.white};
  border: 1px solid ${Color.dirtyWhite};
  max-width: 600px;
  margin-left: auto;
`;

export const Content = styled.div`
  padding: 35px 25px;
  border-bottom: 1px solid ${Color.dirtyWhite};

  ${Media.tablet`
    padding: 35px 50px;
  `}
`;

export const Title = styled.p`
  color: ${Color.deepGray};
  font-size: 18px;
  line-height: 1.3;
  font-weight: ${FontWeight.Bold};
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: ${Color.gray9};
  margin-top: 10px;
`;

export const ButtonWrapper = styled.div`
  margin: 30px;
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled(ButtonBase)`
  min-width: fit-content;
`;
