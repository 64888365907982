import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fromPairs } from "ramda";
import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";

import { UserContextActions } from "../../../../userContext/redux/userContext.reducer";
import { ButtonColor } from "../../../../components/button/button.constants";
import { clearApiCache } from "../../../../helpers/clearApiCache";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { ProfileActions } from "../../redux/profile.reducer";
import { selectUserAgreements } from "../../redux/profile.selectors";

import { AgreementType } from "./agreementsTab.constants";
import {
  Container,
  AgreementsContainer,
  Agreement,
  CheckboxesRow,
  Title,
  Text,
  Button,
} from "./agreementsTab.styled";
import { AgreementCheckbox } from "./agreementCheckbox/agreementCheckbox.component";

export const AgreementsTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const agreements = useSelector(selectUserAgreements);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const editableAgreements = agreements.filter((agreement) => !agreement.disabled);
  const disabledAgreements = agreements.filter((agreement) => agreement.disabled);
  const initialCheckboxStates = fromPairs(agreements.map((agreement) => [agreement.name, agreement.agree]));
  const [checkboxStates, setCheckboxStates] = useState(initialCheckboxStates);

  useEffect(() => {
    dispatch(ProfileActions.fetchUserAgreements());
  }, [dispatch]);

  useEffect(() => {
    const agreementsUnchanged = isEqual(initialCheckboxStates, checkboxStates);
    setButtonDisabled(agreementsUnchanged);
  }, [checkboxStates, initialCheckboxStates]);

  useEffect(() => {
    const newCheckboxStates = fromPairs(agreements.map((agreement) => [agreement.name, agreement.agree]));
    setCheckboxStates(newCheckboxStates);
  }, [agreements, dispatch]);

  const toggleCheckboxState = (target) => {
    const newCheckboxStates = { ...checkboxStates, [target]: !checkboxStates[target] };
    setCheckboxStates(newCheckboxStates);
  };

  const handleLogout = () => {
    dispatch(UserContextActions.setLoggedOut());
    clearApiCache();
  };

  const saveChanges = () => {
    let updatedAgreements = agreements;

    Object.keys(checkboxStates).forEach((key) => {
      const index = updatedAgreements.findIndex((agreement) => agreement.name === key);
      updatedAgreements[index] = { ...updatedAgreements[index], agree: checkboxStates[key] };
    });

    dispatch(ProfileActions.updateUserAgreements(updatedAgreements, handleLogout));
  };

  const renderAgreements = (agreements, type) => agreements.map(
    (agreement) => {
      const renderDisagreeCheckbox = renderWhenTrue(() => (
        <AgreementCheckbox
          label={t("profile.agreementsTab.iDontAccept")}
          value={agreement.name}
          popup={agreement.popup}
          onChange={() => toggleCheckboxState(agreement.name)}
          checked={!checkboxStates[agreement.name]}
        />
      ));

      return (
        <Agreement key={agreement.name}>
          <Text dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(agreement.content) }} />
          {!agreement.disabled && (
            <CheckboxesRow>
              <AgreementCheckbox
                label={t("profile.agreementsTab.iAccept")}
                value={agreement.name}
                onChange={() => toggleCheckboxState(agreement.name)}
                checked={checkboxStates[agreement.name]}
                disabled={type === AgreementType.Disabled}
              />
              {renderDisagreeCheckbox(type === AgreementType.Editable)}
            </CheckboxesRow>
          )}
        </Agreement>
      );
    }
  );

  const renderDisabledAgreements = renderWhenTrue(() => (
    <>
      <Title>{t("profile.agreementsTab.acceptedAgreements")}</Title>
      {renderAgreements(disabledAgreements, AgreementType.Disabled)}
    </>
  ));

  const renderEditableAgreements = renderWhenTrue(() => (
    <>
      <Title>{t("profile.agreementsTab.editAgreements")}</Title>
      {renderAgreements(editableAgreements, AgreementType.Editable)}
    </>
  ));

  if (!agreements.length) return <div />;

  return (
    <Container>
      <AgreementsContainer>
        {renderDisabledAgreements(!!disabledAgreements.length)}
        {renderEditableAgreements(!!editableAgreements.length)}
        <Button
          onClick={saveChanges}
          color={ButtonColor.Primary}
          disabled={buttonDisabled}
        >
          {t("profile.agreementsTab.saveAgreements")}
        </Button>
      </AgreementsContainer>
    </Container>
  );
};
