import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  Container,
  Header,
  HeaderText,
  ArrowWrapper,
  ArrowIcon,
} from "./expandableBox.styled";

export const ExpandableBox = ({ children, header }) => {
  const [open, setOpen] = useState(false);

  const toggleExpand = () => {
    setOpen(!open);
  };

  return (
    <Container>
      <Header onClick={toggleExpand}>
        <HeaderText>{header}</HeaderText>
        <ArrowWrapper>
          <ArrowIcon open={open} />
        </ArrowWrapper>
      </Header>
      {open && children}
    </Container>
  );
};

ExpandableBox.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string,
};
