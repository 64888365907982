import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ButtonColor } from "../../../../components/button/button.constants";

import {
  Container,
  CheckIcon,
  TextWrapper,
  Title,
  Subtitle,
  GoButton,
} from "./finishBox.styled";

export const FinishBox = ({ withTest, handleClick }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <CheckIcon />
      <TextWrapper>
        <Title>{t("scrollLesson.finishBox.congratulations")}</Title>
        {withTest && <Subtitle>{t("scrollLesson.finishBox.goToTest")}</Subtitle>}
      </TextWrapper>
      <GoButton
        onClick={handleClick}
        color={ButtonColor.Primary}
        $large
      >
        {withTest ? t("scrollLesson.finishBox.startTest") : t("scrollLesson.finishBox.next")}
      </GoButton>
    </Container>
  );
};

FinishBox.propTypes = {
  withTest: PropTypes.bool,
  navigateToTest: PropTypes.func,
};
