import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Modal } from "../../../../components/modal/modal.component";
import { renderWhenTrueOtherwise } from "../../../../helpers/rendering";
import { DashboardActions } from "../../redux/dashboard.reducer";

import { ModalContentStep1 } from "./modalContentStep1/modalContentStep1.component";
import { ModalContentStep2 } from "./modalContentStep2/modalContentStep2.component";
import { AddIcon } from "./uploadVideoModal.styled";

export const UploadVideoModal = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [frames, setFrames] = useState([]);
  const [selectedThumbnail, setSelectedThumbnail] = useState();
  const [title, setTitle] = useState("");
  const [video, setVideo] = useState();
  const [agreementCheckboxState, setAgreementCheckboxState] = useState(false);
  const dispatch = useDispatch();

  const handleSendFile = () => {
    const formData = new FormData();
    const miniature = selectedThumbnail.blob || frames[0];
    formData.append("title", title);
    formData.append("video", video);
    formData.append("miniature", miniature);
    formData.append("agreement", agreementCheckboxState)
    dispatch(DashboardActions.createUserVideo(formData, agreementCheckboxState));
    handleCloseModal();
  };

  const handleChangeInput = (event) => setTitle(event.target.value);

  const handleChangeThumbnail = (thumbnail) => setSelectedThumbnail(thumbnail);

  const handleCloseModal = () => {
    setStep(1);
    onClose();
  };

  const renderModalContent = renderWhenTrueOtherwise(
    () => (
      <ModalContentStep1
        setFrames={setFrames}
        setStep={setStep}
        setVideo={setVideo}
        checkboxState={agreementCheckboxState}
        setCheckboxState={setAgreementCheckboxState}
      />
    ),
    () => (
      <ModalContentStep2
        frames={frames}
        title={title}
        selectedThumbnail={selectedThumbnail}
        handleChangeInput={handleChangeInput}
        handleChangeThumbnail={handleChangeThumbnail}
        handleSendFile={handleSendFile}
      />
    )
  );

  return (
    <Modal
      open={open}
      title={t("dashboard.uploadVideo.addVideo")}
      HeaderIcon={AddIcon}
      onClose={handleCloseModal}
    >
      {renderModalContent(step === 1)}
    </Modal>
  );
};

UploadVideoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
