import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { ReactComponent as DownloadSvg } from "../../../../images/download.svg";
import { FontWeight, Color, Media } from "../../../../theme";

export const Container = styled.div`
  height: 206px;
  padding: 0 20px;
  overflow-y: auto;
  max-height: 50vh;
  margin: 0 0 101px;

  ${styleWhenTrue(prop("classesMode"), css`
    max-width: fit-content;
    min-width: 100%;
    margin: 0 0 149px;
  `)}

  ${styleWhenTrue(prop("liveEnded"), css`
    margin: 0;
  `)}

  ${Media.tablet`
    height: 100%;
    max-height: unset;
    margin: 0 0 101px;

    ${styleWhenTrue(prop("fullScreen"), css`
      padding: 0;
      height: unset;
      flex: 1;
      margin: 0 0 85px;
    `)}

    ${styleWhenTrue(prop("classesMode"), css`
      margin: 0 0 130px;
      padding: 0;
    `)}

    ${styleWhenTrue(prop("liveEnded"), css`
      margin: 0;
    `)}
  `}
`;

export const MessageWrapper = styled.div`
  margin-bottom: 20px;
  
  :first-of-type {
    margin-top: 20px;
  }
`;

export const Message = styled.div`
  display: flex;
`;

export const TextWrapper = styled.div`
  margin-left: 10px;
  width: 100%;
`;

export const MessageText = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${Color.darkGray};
  word-break: break-word;

  & + & {
    margin-top: 5px;
  }

  ${styleWhenTrue(prop("systemMsg"), css`
    font-style: italic;
  `)}
`;

export const NameLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const Name = styled(MessageText)`
  font-weight: ${FontWeight.SemiBold};
`;

export const Dot = styled.div`
  margin: 0 5px;
  width: 3px;
  height: 3px;
  background-color: ${Color.gray7};
`;

export const Time = styled.span`
  font-size: 10px;
  line-height: 16px;
  color: ${Color.gray1};
`;

export const FileBox = styled.div`
  margin-top: ${({ movedLower }) => movedLower ? "30px" : "10px"};
  margin-left: -50px;
  background-color: ${Color.almostWhite};
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 2px;
  }
`;

export const FileText = styled.div`
  margin-right: 7px;
`;

export const FileName = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: ${Color.deepGray};
  word-break: break-word;
`;

export const DownloadLink = styled.a`
  width: 33px;
  height: 33px;
  background-color: ${Color.white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: 1px solid ${Color.gray8};
`;

export const DownloadIcon = styled(DownloadSvg)`
  width: 13px;
  height: 13px;
`;

export const ReceiverLine = styled.div`
  margin-bottom: 4px;
  font-size: 10px;
  line-height: 14px;
  color: ${Color.almostBlack};
`;

export const Receiver = styled.span`
  font-weight: ${FontWeight.SemiBold};
  color: ${() => Color.brandBasic};
`;
