import React from "react";
import PropTypes from "prop-types";
import Lottie from "lottie-react";
import { InView } from "react-intersection-observer";

import { Anim } from "./ruleItem.constants";
import {
  Container,
  Title,
  Text,
  AnimationWrapper,
} from "./ruleItem.styled";

export const RuleItem = ({ className, title, text, animationName }) => {
  return (
    <Container className={className}>
      <InView>
        {({ inView, ref }) => (
          <AnimationWrapper ref={ref}>
            <Lottie
              animationData={inView ? Anim[animationName] : undefined}
              loop={false}
              rendererSettings={{
                preserveAspectRatio: "xMidYMid slice",
              }}
            />
          </AnimationWrapper>
        )}
      </InView>
      <Title>
        {title}
      </Title>
      <Text>
        {text}
      </Text>
    </Container>
  );
};

RuleItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
