import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { NotFoundPage } from "../../app/notFoundPage/notFoundPage.component";
import { Loader } from "../../components/loader/loader.component";
import { useMediaListener } from "../../hooks/useMediaListener";
import { AppConfig } from "../../appConfig";
import { useModuleConfig, ModuleType } from "..";

import OutroImage from "./images/outro.jpg";
import OutroMobileImage from "./images/outro_mobile.jpg";
import { InfoScreen } from "./components/infoScreen/infoScreen.component";
import { Question } from "./components/question/question.component";
import { AnonSurveyActions } from "./redux/anonSurvey.reducer";
import {
  selectIsPending,
  selectQuestions,
  selectStartHeader,
  selectStartText,
  selectSurveyCompleted,
  selectSurveyNotFound,
} from "./redux/anonSurvey.selectors";
import {
  Container,
  Wrapper,
  PageImage,
  LoaderWrapper,
  QuestionCounter,
  CounterItem,
} from "./anonSurvey.styled";

const AnonSurvey = () => {
  const config = useModuleConfig(ModuleType.AnonSurvey);
  const dispatch = useDispatch();
  const location = useLocation();
  const isPending = useSelector(selectIsPending);
  const startHeader = useSelector(selectStartHeader);
  const startText = useSelector(selectStartText);
  const questions = useSelector(selectQuestions);
  const surveyCompleted = useSelector(selectSurveyCompleted);
  const surveyNotFound = useSelector(selectSurveyNotFound);
  const { isTablet } = useMediaListener();
  const surveyToken = location.pathname.split("/")[2];
  const [page, setPage] = useState(0);
  const [answers, setAnswers] = useState([{}]);

  useEffect(() => {
    dispatch(AnonSurveyActions.getQuestions(surveyToken));
  }, []);

  const handleNextClick = () => {
    if (page === questions.length - 1) {
      const answersCopy = [...answers];
      answersCopy.shift();
      dispatch(AnonSurveyActions.sendAnswers(surveyToken, answersCopy));
    } else {
      setPage(page + 1);
    }
  };
  const handlePrevClick = () => setPage(page - 1);

  const handleSetAnswer = (newAnswer) => {
    if (answers[page]) {
      const newAnswers = answers.map((answer, index) => {
        return index === page ? newAnswer : answer;
      });
      setAnswers(newAnswers);
    } else {
      setAnswers([...answers, newAnswer]);
    }
  };

  if (surveyNotFound) {
    return (
      <ThemeProvider theme={config.theme}>
        <Container withBackground>
          <NotFoundPage hideLink />
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={config.theme}>
      <Container>
        {isPending && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
        {!isPending && (!!questions?.length || surveyCompleted) && (
          <Wrapper>
            <PageImage
              src={isTablet
                ? surveyCompleted
                  ? OutroImage
                  : `${AppConfig.content}${questions[page].pictureDesktop}`
                : surveyCompleted
                  ? OutroMobileImage
                  : `${AppConfig.content}${questions[page].pictureMobile}`
              }
              lastImage={surveyCompleted}
            />
            {page === 0 && !surveyCompleted && (
              <InfoScreen
                title={startHeader}
                text={startText}
                onNextClick={handleNextClick}
              />
            )}
            {page > 0 && !surveyCompleted && (
              <>
                <QuestionCounter>
                  {questions.map((question, index) => (
                    index !== 0 && <CounterItem key={question.id} active={page === index} />
                  ))}
                </QuestionCounter>
                <Question
                  sectionTitle={questions[page].title}
                  question={questions[page].sections[0].questions[0].question}
                  answers={questions[page].sections[0].questions[0].answers}
                  onNextClick={handleNextClick}
                  onPrevClick={handlePrevClick}
                  setValue={handleSetAnswer}
                  answerId={answers[page]?.id}
                  value={answers[page]?.value || ""}
                  type={questions[page].sections[0].questions[0].type}
                />
              </>
            )}
            {surveyCompleted && (
              <InfoScreen finished />
            )}
          </Wrapper>
        )}
      </Container>
    </ThemeProvider>
  );
};

AnonSurvey.propTypes = {};

export default AnonSurvey;
