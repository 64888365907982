import styled, { css } from "styled-components";
import { prop } from "ramda";
import { NavLink } from "react-router-dom";

import { Color, Media, Transitions } from "../../../theme";
import { ReactComponent as ArrowSvg } from "../../../images/arrow-3.svg";
import { styleWhenTrue } from "../../../helpers/rendering";

export const Background = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 70px;
  top: 0;
  background-color: ${Color.deepGray};
  opacity: 0.4;
  display: none;

  ${styleWhenTrue(prop("open"), css`
    display: block;
  `)}
`;

export const Container = styled.div`
  position: fixed;
  bottom: 70px;
  left: 0;
  right: 0;
  background-color: ${Color.white};
  padding: 25px 20px;
  transform: translateY(100%);
  transition: ${Transitions.custom};
  z-index: 101;

  ${styleWhenTrue(prop("open"), css`
    transform: translateY(0);
  `)}

  ${Media.tablet`
    display: none;
  `}
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${Color.dirtyWhite};
`;

export const InnerContainer = styled.div``;

export const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 70px;
  width: 100%;
  text-decoration: none;

  & + & {
    border-top: 1px solid ${Color.dirtyWhite};
  }
`;

export const IconWrapper = styled.div`
  svg {
    margin-right: 20px;
    height: 20px;
    width: 20px;

    path {
      fill: ${Color.deepGray};
    }
  }
`;

export const ActiveIndicator = styled.div`
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${() => Color.brandBasic};
  margin-left: 15px;
`;

export const ArrowIcon = styled(ArrowSvg)`
  height: 14px;
  transform: rotate(-90deg);
  margin-left: auto;

  path {
    fill: ${Color.gray1};
  }
`;
