import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  
  .MuiFormGroup-root-1 {
    flex-direction: row !important;
  }

  .MuiTypography-body1-46 {
    font-size: 14px;
    padding-right: 20px;
  }

  .gzhmAd:not(:first-child) {
    margin-left: 0 !important;
  }

  .bRgCRE:not(:first-child) {
    margin-left: 0;
  }

  .headLine {
    margin: 0 auto;
  }

  .accountRadio {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    flex-direction: row !important;
  }

  .iOXWeS {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }

  .register-checked svg, .register-head svg {
    color: #005cff;
  }

  div.Select-placeholder {
    padding-left: 0px;
  }

  div.Select-option.is-focused {
    color: #fff;
  }

  div.Select--multi .Select-value {
    background-color: #fff;
    color: #000;
    color: #D9017A;
    border-color: #000;
  }

  div.Select--multi .Select-value-icon {
    border-color: #000;
  }

  .tooltipParent {
    position: relative;
  }

  .tooltipParent svg {
    background: #f5f6fa;
    border-radius: 50%;
  }

  .tooltipContainer {
    position: absolute !important;
    width: 20px !important;
    height: 20px !important;
    right: 5px !important;
    top: 10px !important;
  }

  .datePickerReact {
    font-size: 16px;
    border: none;
    background: transparent;
    width: 100%;
  }

  .react-date-picker__wrapper {
    width: 100%;
    border: none;
  }

  .react-date-picker__inputGroup {
    white-space: nowrap;
  }

  .react-date-picker__inputGroup__input {
    min-width: 29%;
    text-align: center;
  }

  .react-date-picker__inputGroup__divider {
    padding: 1px 5px;
  }

  .react-date-picker__calendar {
    max-width: 100%;
  }

  input[name="date"] {
    display: none;
  }

  .register-checkbox a {
    color: #005cff;
  }

  .register-checkbox a:visited {
    color: #005cff;
  }
`;

export const LogoImg = styled.img`
  display: block;
  width: 115px;
  height: auto;
`;
