import styled from "styled-components";

import { Media, SectionHeader } from "../../../../theme";

export const Title = styled(SectionHeader)`
  &:not(:first-of-type) {
    margin-top: 60px;
  }

  ${Media.tablet`
    width: 100%;
  `}
`;
