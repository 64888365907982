import styled from "styled-components";

import { Color, ContentMaxWidth, FontWeight } from "../../../../../theme";
import { Button } from "../../../../../components/button/button.component";

export const Container = styled.div`
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
`;

export const CareerBoxContainer = styled.div`
  background-color: ${Color.white};
  padding: 10px 35px 15px;

  & > *:not(:last-child) {
    border-bottom: 1px solid ${Color.gray5};
  }
`;

export const CareerElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 89px;
`;

export const CareerLeftSide = styled.div``;

export const Position = styled.p`
  font-size: 14px;
  line-height: 19px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.deepGray};
`;

export const Company = styled(Position)`
  color: ${Color.gray9};
`;

export const Period = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${Color.gray9};
`;

export const ExpandButton = styled(Button)`
  min-width: auto;
  padding: 10px 0;
  margin-bottom: 15px;
`;
