import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Theme } from "../../appConfig";
import { CompetenceActions } from "../competence/redux/competence.reducer";
import { useModuleConfig, ModuleType } from "../";

import { IndicatorsTab } from "./components/indicatorsTab/indicatorsTab.component";
import { StatisticsTheme } from "./components/statistics/statistics.constants";
import { Statistics } from "./components/statistics/statistics.component";
import { IndicatorsTabURI, IndicatorsTabName, CompetenceType } from "./teamIndicators.constants";
import {
  Container,
  HeaderWrapper,
  HeaderBox,
  ContentWrapper,
  Header,
  Tabs,
  Tab,
  TabText,
} from "./teamIndicators.styled";

const TeamIndicators = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const config = useModuleConfig(ModuleType.TeamIndicators);
  const activeTab = location.pathname.replace(`${config.urls[1]}/`, "");

  useEffect(() => {
    dispatch(CompetenceActions.getUserCompetenceList());
  }, [dispatch]);

  useEffect(() => {
    if (config.urls.includes(location.pathname)) {
      navigate(IndicatorsTabURI.Strategic, { replace: true });
    }
  }, [navigate, location.pathname]);

  const renderTabLink = (tab) => {
    if (Theme.StatisticsTheme === StatisticsTheme.Hidden && tab === IndicatorsTabURI.Statistics) {
      return null;
    }

    const currentTab = tab === activeTab;

    return (
      <Tab to={tab} disabled={currentTab}>
        <TabText active={currentTab}>
          {t(`teamIndicators.tabs.${IndicatorsTabName[tab]}`)}
        </TabText>
      </Tab>
    );
  };

  const renderTabContent = () => (
    <ContentWrapper>
      <Routes>
        <Route
          path={IndicatorsTabURI.Strategic}
          element={<IndicatorsTab competenceType={CompetenceType.Strategic} />}
        />
        <Route
          path={IndicatorsTabURI.Common}
          element={<IndicatorsTab competenceType={CompetenceType.Common} />}
        />
        <Route
          path={IndicatorsTabURI.Statistics}
          element={<Statistics />}
        />
      </Routes>
    </ContentWrapper>
  );

  return (
    <ThemeProvider theme={config.theme}>
      <Container>
        <HeaderWrapper>
          <HeaderBox>
            <Header>{t(config.label)}</Header>
            <Tabs>
              {renderTabLink(IndicatorsTabURI.Strategic)}
              {renderTabLink(IndicatorsTabURI.Common)}
              {renderTabLink(IndicatorsTabURI.Statistics)}
            </Tabs>
          </HeaderBox>
        </HeaderWrapper>
        {renderTabContent()}
      </Container>
    </ThemeProvider>
  );
};

export default TeamIndicators;
