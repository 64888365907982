import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { FontWeight, Color, Media } from "../../../../theme";

export const Container = styled.div`
  padding-left: 72px;
  display: flex;
  align-items: center;
  background-color: ${() => Color.brandBasic};
  margin-bottom: 6px;
  height: 61px;
  overflow: hidden;
  opacity: 1;
  transition: 0.5s;

  ${Media.tablet`
    padding-left: 115px;
  `}

  ${styleWhenTrue(prop("collapsed"), css`
    height: 0;
    opacity: 0;
    margin-bottom: 0;
  `)}
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.white};
`;
