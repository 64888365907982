import { handleApiError, nodeApi } from "../../../api";

export const getInitialData = () => {
  return nodeApi.get("/notifications").catch(handleApiError);
};

export const setReadMessage = (id) => {
  return nodeApi.post(`/notifications/${id}/read`).catch(handleApiError);
};

export const deleteNotification = (id) => {
  return nodeApi.delete(`/notifications/${id}`).catch(handleApiError);
};

export const loadNextPage = (nextPage) => {
  return nodeApi.get(nextPage).catch(handleApiError);
};
