import styled, { css } from "styled-components";
import { prop } from "ramda";

import { hexToRgba } from "../../../../helpers/colors";
import { styleWhenTrue } from "../../../../helpers/rendering";
import {
  Color,
  Media,
  FontWeight,
  Separator,
  ContentMaxWidth,
} from "../../../../theme";

export const StagesWrapper = styled.div`
  background-color: ${Color.almostWhite};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0 75px;

  ${Media.tablet`
    padding: 105px 25px;
  `}
`;

export const StagesBox = styled.div`
  max-width: ${ContentMaxWidth};
  width: 100%;
  margin: 0 auto;
`;

export const SeparatorWrapper = styled.div`
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
`;

export const StagesHeaderSeparator = styled(Separator)`
  display: none;

  ${Media.tablet`
    display: block;
  `}
`;

export const StageTitle = styled.h3`
  font-size: 22px;
  line-height: 30px;
  font-weight: ${FontWeight.Bold};

  ${Media.tablet`
    font-size: 28px;
    line-height: 38px;
  `}
`;

export const StagesHeader = styled.div`
  margin: 0 25px 30px;

  ${Media.tablet`
    margin: 0;
  `}

  ${Media.tabletWide`
    display: flex;
    justify-content: space-between;
  `}
`;

export const StagesView = styled.div`
  display: none;

  ${Media.tabletWide`
    display: flex;
    align-items: center;
    margin-left: auto;
  `}
`;

export const StagesViewLabel = styled.p`
  font-size: 11px;
  line-height: 20px;
  font-weight: ${FontWeight.Bold};
  text-transform: uppercase;
  margin-right: 5px;
`;

export const ViewButton = styled.button`
  width: 50px;
  height: 50px;
  box-shadow: 0 2px 4px ${hexToRgba(Color.darkGray, 0.2)}, 0 0 20px ${hexToRgba(Color.almostBlack, 0.03)};
  background-color: ${Color.white};
  border-radius: 50%;
  border: none;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    fill: ${Color.blue2};
  }
  
  ${styleWhenTrue(prop("active"), css`
    background-color: ${Color.blue2};

    svg {
      fill: ${Color.white};
    }
  `)}
`;

export const MobileViewToggler = styled.div`
  display: none;

  ${Media.smallMobile`
    display: flex;
    border-top: 1px solid ${Color.gray5};
    border-bottom: 1px solid ${Color.gray5};
    margin: 25px -25px 20px
  `}

  ${Media.tabletWide`
    display: none;
  `}
`;

export const MobileViewButton = styled.button`
  height: 50px;
  outline: none;
  border: none;
  width: 50%;
  color: ${Color.deepGray};
  font-weight: ${FontWeight.Bold};
  background-color: ${Color.white};
  text-transform: uppercase;

  ${styleWhenTrue(prop("selected"), css`
    color: ${Color.blue2};
    border-bottom: 1px solid ${Color.blue2};
  `)}
`;
