import React from "react";
import PropTypes from "prop-types";

import { BulletList } from "../bulletList/bulletList.component";
import { ColorType } from "../bulletList/bulletList.constants";

import { Container } from "./competenceLevelDropdown.styled";

export const CompetenceLevelDropdown = ({ description }) => {
  return (
    <Container>
      <BulletList text={description} colorType={ColorType.Gray} />
    </Container>
  );
};

CompetenceLevelDropdown.propTypes = {
  description: PropTypes.string,
};
