import React from "react";

import { AnswerStatus } from "../../../interactions.constants";

import { Container, CorrectIcon, WrongIcon } from "./answerDisc.styled";

const AnswerDisc = ({ status }) => {
  return (
    <Container status={status}>
      {status === AnswerStatus.CorrectChecked && <CorrectIcon />}
      {status === AnswerStatus.WrongChecked && <WrongIcon />}
    </Container>
  );
};

export default AnswerDisc;
