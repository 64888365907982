import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { getPeopleCounterLabel } from "../../../../../helpers/peopleCounter";

import {
  Container,
  Level,
  Label,
  Percentage,
} from "./statsItem.styled";

export const StatsItem = ({
  className,
  label,
  value,
  percent,
}) => {
  const formattedPercent = (percent * 100).toFixed();
  const counterLabel = useMemo(() => getPeopleCounterLabel(value), [value]);

  return (
    <Container className={className} key={label}>
      <Level>
        <Label>{label}:</Label>
        {" "}
        {value}
        {" "}
        {counterLabel}
      </Level>
      <Percentage>{formattedPercent}%</Percentage>
    </Container>
  );
};

StatsItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
};
