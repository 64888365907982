import React from "react";
import PropTypes from "prop-types";

import { AvatarType } from "../../../../../components/avatar/avatar.constants";
import { Avatar } from "../../../../../components/avatar/avatar.component";

import {
  Container,
  Position,
  Name,
  Coins,
  CoinIcon,
  Value,
} from "./rankingItem.styled";

export const RankingItem = ({
  className,
  isCurrentUser,
  position,
  userName,
  coins,
}) => {
  return (
    <Container className={className}>
      <Position isCurrentUser={isCurrentUser}>{position}</Position>
      <Avatar
        dark={isCurrentUser}
        type={AvatarType.Small}
        name={userName}
      />
      <Name isCurrentUser={isCurrentUser}>{userName}</Name>
      <Coins>
        <CoinIcon />
        <Value isCurrentUser={isCurrentUser}>{coins}</Value>
      </Coins>
    </Container>
  );
};

RankingItem.propTypes = {
  className: PropTypes.string,
  isCurrentUser: PropTypes.bool.isRequired,
  position: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
  coins: PropTypes.number.isRequired,
};
