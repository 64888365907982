import { prop } from "ramda";
import styled, { css } from "styled-components";

import { styleWhenTrue } from "../../../../../helpers/rendering";
import { Color, FontWeight } from "../../../../../theme";
import { ReactComponent as ArrowSVG } from "../../images/arrow.svg";

export const Container = styled.div`
  width: 100%;
  max-width: 450px;
  height: 128px;
  border: 2px solid ${Color.almostWhite};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px 10px 24px;

  &:not(:last-of-type) {
    margin-bottom: 2px;
  }

  ${styleWhenTrue(prop("grayTheme"), css`
    background-color: ${Color.almostWhite};
  `)}

  ${styleWhenTrue(prop("setValidatingTheme"), css`
    border-color: ${Color.green};
  
    ${styleWhenTrue(prop("wrongTheme"), css`
      border-color: ${Color.red4};
    `)}

    ${styleWhenTrue(prop("grayTheme"), css`
      background-color: ${Color.white};
    `)}
  `)}
`;

export const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ArrowButton = styled.button`
  height: 52px;
  width: 40px;
  background-color: ${Color.almostWhite};
  border: none;
  
  &:first-of-type {
    margin-bottom: 2px;
  }

  ${styleWhenTrue(prop("grayTheme"), css`
    background-color: ${Color.white};
  `)}

  ${styleWhenTrue(prop("disabled"), css`
    cursor: default;
  `)}
`;

export const ArrowDown = styled(ArrowSVG)`
  width: 10px;
  fill: ${Color.blueLighter};
`;

export const ArrowUp = styled(ArrowDown)`
  transform: rotate(180deg);
`;

export const Text = styled.h1`
  font-weight: ${FontWeight.Light};
  font-size: 15px;
  line-height: 20px;
  padding-right: 5px;
`;
