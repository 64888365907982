import styled from "styled-components";

import { hexToRgba } from "../../../helpers/colors";
import { Color, FontWeight } from "../../../theme";

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 68px;
  background-color: ${Color.white};
  padding: 23px 24px 23px 21px;
  margin-bottom: 2px;
  box-shadow: 0 5px 10px 0 ${hexToRgba(Color.deepGray, 0.01)};
`;

export const Position = styled.span`
  font-size: 13px;
  line-height: 22px;
  color: ${Color.gray9};
  width: 35px;
  text-align: center;
  font-weight: ${FontWeight.SemiBold};
  margin-right: 13px;
  flex-shrink: 0;
`;

export const PointsWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const MedalDot = styled.span`
  width: 9px;
  height: 9px;
  border-radius: 50%;
`;

export const BronzeMedalDot = styled(MedalDot)`
  background-color: ${Color.brown};
`;

export const SilverMedalDot = styled(MedalDot)`
  background-color: ${Color.gray4};
`;

export const GoldMedalDot = styled(MedalDot)`
  background-color: ${Color.gold};
`;

export const Info = styled.span`
  margin: 0 12px;
  font-size: 14px;
  line-height: 22px;
  color: ${Color.gray9};
  min-width: 80px;
`;

export const Points = styled(Info)`
  width: 80px;
  text-align: right;
`;
