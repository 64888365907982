import React from "react";
import PropTypes from "prop-types";

import {
  Container,
  HeaderTextWrapper,
  Title,
  Subtitle,
  TextBold,
} from "./header.styled";

export const Header = ({ className, children, title, subtitle }) => {
  const renderHeaderTitle = () => {
    const titleParts = title.split(":");
    return titleParts.length > 1 ? (
      <Title>
        {titleParts[0]}: <TextBold>{titleParts[1]}</TextBold>
      </Title>
    ) : (
      <Title>
        <TextBold>{title}</TextBold>
      </Title>
    );
  };

  return (
    <Container className={className}>
      <HeaderTextWrapper>
        {renderHeaderTitle()}
        <Subtitle>{subtitle}</Subtitle>
      </HeaderTextWrapper>
      {children}
    </Container>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
