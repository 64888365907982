import { ReactComponent as ModuleIcon } from "../../images/profile.svg";

export const Config = {
  moduleName: "profile",
  dependencies: [
    "@competence/profileCompetenceList",
  ],
  label: "app.mainNavigation.profile",
  Icon: ModuleIcon,
  urls: ["/profil/*", "/profil"],
  routeName: "",
  routes: {
    edit: "/edit",
    details: "/details",
    idd: "/idd",
    agreements: "/agreements",
    cookies: "/cookies",
    passwords: "/pass"
  },
  options: {},
  theme: {}
};
