import { createActions, createReducer } from "reduxsauce";

import { deepClone } from "../../../helpers";
import { findDeepLink, setAnswerSelected, setAnswerValue, toggleConditionalQuestions } from "../form.utils";

export const { Types: FormTypes, Creators: FormActions } = createActions(
  {
    setIsLoading: ["isLoading"],
    getData: ["id"],
    getDataSuccess: ["formData"],
    setAnswer: ["id", "value"],
    saveData: null,
    setNextPage: null,
    setPrevPage: null,
    finishForm: null,
    clearData: null,
  },
  { prefix: "FORM/" }
);

const INITIAL_STATE = {
  isLoading: false,
  nav: { currentPage: 0 },
  form: null,
};

const setIsLoading = (state, { isLoading }) => ({ ...state, isLoading });

const getDataSuccess = (state, { formData }) => ({
  ...state,
  form: toggleConditionalQuestions(formData),
  nav: { currentPage: 0 },
});

const setAnswer = (state, { id, value }) => {
  const form = deepClone(state.form);
  const deepLink = findDeepLink(form, id);

  if (deepLink.pageIndex > -1) {
    if (typeof value === "string") {
      setAnswerValue(form, deepLink, value);
    } else {
      setAnswerSelected(form, deepLink, value);
      toggleConditionalQuestions(form);
    }
  }

  return { ...state, form };
};

const setNextPage = (state) => ({
  ...state,
  nav: { currentPage: state.nav.currentPage + 1 },
});

const setPrevPage = (state) => ({
  ...state,
  nav: { currentPage: state.nav.currentPage > 0 ? state.nav.currentPage - 1 : 0 },
});

const clearData = () => ({ ...INITIAL_STATE });

export const reducer = createReducer(INITIAL_STATE, {
  [FormTypes.SET_IS_LOADING]: setIsLoading,
  [FormTypes.GET_DATA_SUCCESS]: getDataSuccess,
  [FormTypes.SET_ANSWER]: setAnswer,
  [FormTypes.SET_NEXT_PAGE]: setNextPage,
  [FormTypes.SET_PREV_PAGE]: setPrevPage,
  [FormTypes.CLEAR_DATA]: clearData,
});
