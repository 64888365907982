import { UserContextTypes } from "../../userContext/redux/userContext.reducer";
import { AppConfig } from "../../appConfig";

import { ActionType, MessageType } from "./websocket.constants";

const socketMiddleware = () => {
  return (store) => {
    const socket = new WebSocket(AppConfig.webservice);

    socket.onopen = () => {
      store.dispatch({ type: ActionType.WS_CONNECTED });
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      store.dispatch({ type: ActionType.WS_MESSAGE, value: message });
    };

    socket.onclose = () => {
      store.dispatch({ type: ActionType.WS_DISCONNECTED });
      setTimeout(() => socketMiddleware(), 1000);
    };

    return (next) => (action) => {
      if (action.type && action.type === UserContextTypes.TRY_CONNECT_WEBSOCKET && socket.readyState === 1) {
        const { token } = store.getState().userContext.data;
        const value = JSON.stringify({ command: MessageType.Authorize, data: { token } });
        socket.send(value);
      }

      if (action.type && action.type === UserContextTypes.HIDE_ACHIEVEMENT_MODAL && socket.readyState === 1) {
        const { achievement } = action;
        const value = JSON.stringify({ command: MessageType.AchievementReceived, data: { enum: achievement.enum } });
        socket.send(value);
      }

      if (
        action.type &&
        action.type === ActionType.WS_SEND_MESSAGE &&
        socket.readyState === 1
      ) {
        socket.send(action.value);
      }

      return next(action);
    };
  };
};

export default socketMiddleware;
