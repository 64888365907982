import styled from "styled-components";

import { Color, Media, FontWeight } from "../../../../../theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 600px;

  ${Media.tablet`
    height: 600px;
  `}
`;

export const Tile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${Color.gray5};
  background-color: ${Color.white};
  margin-bottom: 3px;
  font-size: 16px;
  padding: 20px;
  line-height: 22px;

  &:last-of-type {
    margin-bottom: 60px;
  }

  ${Media.tablet`
    flex-direction: row;
    padding: 0 15px 0 35px;
    height: 70px;
  `}
`;

export const TileButton = styled.button`
  border: 2px solid ${Color.blue2};
  color: ${Color.blue2};
  background-color: ${Color.white};
  font-weight: ${FontWeight.Bold};
  width: 100%;
  margin-top: 18px;
  height: 50px;
  max-width: 300px;

  &:hover {
    background-color: ${Color.white};
    opacity: 0.8;
  }

  ${Media.tablet`
    width: 170px;
    margin-top: 0;
  `}
`;
