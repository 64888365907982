import { createSelector } from "reselect";
import { prop } from "ramda";

export const selectProfileDomain = prop("profile");

export const selectIsPending = createSelector(
  selectProfileDomain, prop("isPending"),
);

export const selectIsEditTabPending = createSelector(
  selectProfileDomain, prop("isEditTabPending"),
);

export const selectAvatarSrc = createSelector(
  selectProfileDomain, prop("avatarSrc"),
);

export const selectFullName = createSelector(
  selectProfileDomain, prop("fullName"),
);

export const selectCareerHistory = createSelector(
  selectProfileDomain, prop("experience"),
);

export const selectIddHistory = createSelector(
  selectProfileDomain, prop("iddHistory"),
);

export const selectEditSections = createSelector(
  selectProfileDomain, prop("editForm"),
);

export const selectEditCompanies = createSelector(
  selectProfileDomain, prop("editFormCompanies"),
);

export const selectEditPositions = createSelector(
  selectProfileDomain, prop("editFormPositions"),
);

export const selectEditBrands = createSelector(
  selectProfileDomain, prop("editFormBrands"),
);

export const selectEditFormInVerification = createSelector(
  selectProfileDomain, prop("editFormInVerification"),
);

export const selectIsEditFormError = createSelector(
  selectProfileDomain, prop("isEditFormError"),
);

export const selectPosition = createSelector(
  selectProfileDomain, prop("position"),
);

export const selectIddNextPage = createSelector(
  selectProfileDomain, prop("iddNextPage"),
);

export const selectUserAgreements = createSelector(
  selectProfileDomain, prop("agreements"),
);
