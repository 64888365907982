import React, { useCallback, useEffect, useState } from "react";

import {
  Container,
  ArrowsContainer,
  ArrowButton,
  ArrowUp,
  ArrowDown,
  Text,
} from "./arrowSelectBox.styled";

export const ArrowSelectBox = ({
  options,
  boxIndex,
  enableValidating,
  columnsCorrectness,
  setColumnsCorrectness,
  columnIndex,
  grayTheme,
}) => {
  const [selectedOption, setSelectedOption] = useState(Math.floor(Math.random() * options.length));
  const [isWrong, setIsWrong] = useState(false);

  const updateCorrectnessArray = useCallback((isCorrect) => {
    let newArray = columnsCorrectness;
    newArray[columnIndex][boxIndex] = isCorrect;
    setColumnsCorrectness(newArray);
  }, [boxIndex, columnIndex, columnsCorrectness, setColumnsCorrectness]);

  const validateBox = useCallback(() => {
    const isCorrect = options[selectedOption].yAxisLabel.includes(boxIndex + 1);
    setIsWrong(!isCorrect);
    updateCorrectnessArray(isCorrect);
  }, [boxIndex, options, selectedOption, updateCorrectnessArray]);

  useEffect(() => {
    if (enableValidating) {
      validateBox();
    } else {
      setIsWrong(false);
    }
  }, [enableValidating, validateBox]);

  const updateSelectedOption = (updateValue) => {
    const newValue = (selectedOption + updateValue) % options.length;
    setSelectedOption(newValue < 0 ? options.length - 1 : newValue);
  };

  const handleSelectUp = () => updateSelectedOption(1);

  const handleSelectDown = () => updateSelectedOption(-1);

  return (
    <Container
      grayTheme={grayTheme}
      wrongTheme={isWrong}
      setValidatingTheme={enableValidating}
    >
      <Text>{options[selectedOption].text}</Text>
      <ArrowsContainer>
        <ArrowButton
          grayTheme={grayTheme}
          disabled={enableValidating}
          onClick={handleSelectUp}
        >
          <ArrowUp />
        </ArrowButton>
        <ArrowButton
          grayTheme={grayTheme}
          disabled={enableValidating}
          onClick={handleSelectDown}
        >
          <ArrowDown />
        </ArrowButton>
      </ArrowsContainer>
    </Container>
  );
};
