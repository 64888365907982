import React from "react";
import { useTranslation } from "react-i18next";

import { Container, Marker } from "./quizToc.styled";

const QuizToc = ({ nav, questions }) => {
  const { t } = useTranslation();
  const currentQuestionIndex = questions.findIndex((question) => question.id === nav.currentChild) + 1;

  return (
    <Container>
      <Marker>
        {t("global.question")} {currentQuestionIndex}
      </Marker> | {questions.length}
    </Container>
  );
};

export default QuizToc;
