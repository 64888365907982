import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  Container,
  PlaylistIcon,
  Text,
  BoldText,
  AddCodeButton,
  TextWrapper,
} from "./emptyState.styled";

export const EmptyState = ({ className, onAddCodeClick }) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <PlaylistIcon />
      <TextWrapper>
        <BoldText>
          {t("components.videoForCode.activationCodePanel.activationCodeProposal_1")}
        </BoldText>
        <Text>
          {t("components.videoForCode.activationCodePanel.activationCodeProposal_2")}
        </Text>
        <AddCodeButton onClick={onAddCodeClick}>
          {t("components.videoForCode.activationCodePanel.addCode")}
        </AddCodeButton>
      </TextWrapper>
    </Container>
  );
};

EmptyState.propTypes = {
  className: PropTypes.string,
};
