import styled from "styled-components";

import { Color } from "../../../../theme/colors";
import { Media } from "../../../../theme/media";
import { FontWeight } from "../../../../theme/fonts";

import { OptionsDropdown as DropdownBase } from "../../../optionsDropdown/optionsDropdown.component";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AvatarWrapper = styled.div`
  flex-shrink: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  width: 100%;
  padding-top: 8px;
  
  ${Media.tablet`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const Text = styled.p`
  font-size: 13px;
  line-height: 22px;
  color: ${Color.deepGray};
  word-break: break-word;
  margin-right: 20px;
`;

export const Sender = styled.span`
  font-weight: ${FontWeight.Bold};
`;

export const DateText = styled.div`
  font-size: 12px;
  line-height: 22px;
  color: ${Color.gray1};

  ${Media.tablet`
    flex-shrink: 0;
  `}
`;

export const OptionsDropdown = styled(DropdownBase)`
  padding-top: 8px;
`;

export const SystemMessage = styled.span`
  color: ${Color.gray9};
  font-style: italic;
`;
