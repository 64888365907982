import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import MediaQuery from "react-responsive";

import { Badge, Popup } from "../../../../UIElements";
import { breakpoints } from "../../../../UIGlobals";
import { Color } from "../../../../theme";

import {
  GhostButton,
  HeadlineSmall,
  HeadlineBig,
  Row,
  Col,
  Thumbnail,
  RowText,
} from "./videoPopup.styled";
import { modalStyles } from "./videoPopup.constants";

export const VideoPopup = ({ items, title }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <GhostButton onClick={() => setIsOpen(true)}>{t("global.video")}</GhostButton>
      <Modal
        isOpen={isOpen}
        style={modalStyles}
        onRequestClose={() => setIsOpen(false)}
      >
        <Popup width={974} show={isOpen} closeAction={() => setIsOpen(false)}>
          <HeadlineSmall>{t("global.video")}</HeadlineSmall>
          <HeadlineBig>{title}</HeadlineBig>
          {items.map((item) => (
            <Row
              href={
                item.type === "lesson" || item.type === "lesson_slide_scroll"
                  ? `/lekcje/${item.id}`
                  : `/wideo/${item.id}`
              }
              key={item.id}
            >
              <MediaQuery query={`(${breakpoints().maxL})`}>
                <MediaQuery query={`(${breakpoints().maxS})`}>
                  <Col $background={Color.black} width="95px" alignItems="center" flexShrink="0">
                    <Thumbnail src={item.image} width="95px" />
                  </Col>
                  <Col $flexDirection="column" paddingLeft={5} paddingRight={8}>
                    <RowText fontSize={1.4} paddingBottom={8}>{item.name}</RowText>
                    <RowText fontSize={1.2} color="gray3">{item.description}</RowText>
                  </Col>
                </MediaQuery>
                <MediaQuery query={`(${breakpoints().minS})`}>
                  <Col $background={Color.black} width="145px" alignItems="center" flexShrink="0">
                    <Thumbnail src={item.image} />
                  </Col>
                  <Col $flexDirection="column" paddingLeft={15} paddingRight={8}>
                    <RowText fontSize={1.4} paddingBottom={8}>{item.name}</RowText>
                    <RowText fontSize={1.2} color="gray3">{item.description}</RowText>
                  </Col>
                </MediaQuery>
                <Col width="auto" minWidth={33}>
                  <Badge size={33} hover={true} hideBorder={true} name="eye" color={item.completed ? Color.black : "" }/>
                </Col>
              </MediaQuery>
              <MediaQuery query={`(${breakpoints().minL})`}>
                <Col $background={Color.black} width="145px" alignItems="center">
                  <Thumbnail src={item.image} />
                </Col>
                <Col $flexDirection="column" paddingLeft={30}>
                  <RowText fontSize={1.6} paddingBottom={8}> {item.name}</RowText>
                  <RowText fontSize={1.4} color="gray3">{item.description}</RowText>
                </Col>
                <Col width="auto" minWidth={49}>
                  <Badge size={49} hover={true} hideBorder={true} name="eye" color={item.completed ? Color.black : "" }/>
                </Col>
              </MediaQuery>
            </Row>
          ))}
        </Popup>
      </Modal>
    </>
  );
};
