import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { getPeopleCounterLabel } from "../../../helpers/peopleCounter";

import {
  Container,
  Label,
  Value,
  VerticalSeparator
} from "./bubbleTooltip.styled";

export const BubbleTooltip = ({
  className,
  id,
  value,
  data,
}) => {
  const level = `${id.charAt(0).toUpperCase()}${id.slice(1).toLowerCase()}`;
  const formattedPercent = (data.percent * 100).toFixed();
  const counterLabel = useMemo(() => getPeopleCounterLabel(value), [value]);

  return (
    <Container className={className}>
      <Label>{level}:</Label>
      <Value>
        {value}
        {" "}
        {counterLabel}
      </Value>
      <VerticalSeparator />
      <Label>{formattedPercent}%</Label>
    </Container>
  );
};

BubbleTooltip.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  data: PropTypes.shape({
    percent: PropTypes.number.isRequired,
  }),
};
