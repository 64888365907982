import {
  all,
  put,
  call,
  select,
  takeEvery,
  takeLatest,
  take,
  debounce,
} from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";
import { replace } from "redux-first-history";

import { StreamDirection, StreamType } from "../services/streams.constants";
import {
  joinRoom,
  updateRoom,
  iceCandidate,
  updateStreams,
  createStream,
  getRoomAccess,
  closeStream,
  toggleMedia,
  authorizeInTurnServer,
} from "../services/streams";
import {
  sendMessage,
  sendFile,
  sendPermissionRequest,
  sendPermissionResponse,
  startLive,
  endLive,
  startRecording,
  pauseRecording,
  leaveRoom,
  getRoomInfo,
  openRoom,
  closeRoom,
  kickUser,
  acceptGroupAssign,
  assignUserToRoom,
  leaveGroup,
  openFlipchart,
  closeFlipchart,
  connectFlipchart,
  sendFlipchartAddElement,
  sendFlipchartModifyElement,
  sendFlipchartState,
  roomRedirect,
  sendFlipchartRemoveElement,
  sendFlipchartClearCanvas,
  muteStream,
  sendPing,
  fetchChatMessages,
} from "../services/api";
import { MessageType } from "../webinars.constants";

import { WebinarsTypes, WebinarsActions } from "./webinars.reducer";

const createTabActiveEventChannel = () => {
  return eventChannel((emitter) => {

    function handler() {
      if (!document.hidden) {
        emitter(END);
      }
    }

    document.addEventListener("visibilitychange", handler);

    return () => {
      document.removeEventListener("visibilitychange", handler);
    }
  })
}

function* handleWebsocketMessage(action) {
  const message = action.value;
  const room = yield select((state) => state.webinars.room);

  switch (message.command) {
  case MessageType.StreamMuted:
    yield put(WebinarsActions.showStreamMutedModal(message.data.streamType));
    break;
  case MessageType.Authorized:
    yield put(WebinarsActions.setUserAuthorized());
    break;
  case MessageType.IceCandidate:
    yield call(iceCandidate, message.data.stream, message.data.candidate);
    break;
  case MessageType.StreamConnected:
    if (room) {
      let stream = message.data;
      const newRoom = { ...room, streams: [...room.streams, stream] };
      yield put(WebinarsActions.setCurrentRoom(newRoom));
      const turnServerConfig = yield select((state) => state.webinars.turnServerConfig);
      yield call(updateStreams, newRoom, turnServerConfig);
    }
    break;
  case MessageType.StreamDisconnected:
    if (room) {
      if (message.data.direction === StreamDirection.Out) {
        const deviceId = yield select((state) =>
          message.data.type === StreamType.Audio ? state.webinars.currentAudio : state.webinars.currentVideo
        );
        yield handleToggleMedia({ room, streamType: undefined, deviceId, streamId: message.data.id });
      }

      let stream = message.data;
      const newRoom = {
        ...room,
        streams: room.streams.filter(({ id }) => id !== stream.id),
      };
      yield put(WebinarsActions.setCurrentRoom(newRoom));
      const turnServerConfig = yield select((state) => state.webinars.turnServerConfig);
      yield call(updateStreams, newRoom, turnServerConfig);
    }
    break;
  case MessageType.ParticipantConnected:
    if (room) {
      let newParticipantsList = [];
      const newParticipant = message.data;
      const participantExists = !!room.participants.list.find((user) => user.id === newParticipant.id);

      if (participantExists) {
        newParticipantsList = room.participants.list.map((user) => {
          if (user.id === newParticipant.id) {
            return newParticipant;
          }
          return user;
        });
      } else {
        newParticipantsList = [...room.participants.list, newParticipant];
      }

      const participants = {
        list: newParticipantsList,
        length: newParticipantsList.length,
      };
      const newRoom = { ...room, participants };
      yield put(WebinarsActions.setCurrentRoom(newRoom));
      yield put(WebinarsActions.receiveMessageSuccess({
        participant: newParticipant,
        fromSystem: true,
        type: MessageType.ParticipantConnected,
        createdTime: Date.now() / 1000,
      }));
      yield call(updateRoom, newRoom);
    }
    break;
  case MessageType.ParticipantDisconnected:
    if (room) {
      const updatedParticipant = message.data.participant;
      const isRedirecting = !!message.data.redirecting;
      const newParticipantsList = room.participants.list.map((user) => {
        if (user.id === updatedParticipant.id) {
          return updatedParticipant;
        } else {
          return user;
        }
      });
      const participants = {
        list: newParticipantsList,
        length: newParticipantsList.length,
      };
      const newRoom = { ...room, participants };
      yield put(WebinarsActions.setCurrentRoom(newRoom));
      if (!isRedirecting) {
        yield put(WebinarsActions.receiveMessageSuccess({
          participant: updatedParticipant,
          fromSystem: true,
          type: MessageType.ParticipantDisconnected,
          createdTime: Date.now() / 1000,
        }));
      }
      yield call(updateRoom, newRoom);
    }
    break;
  case MessageType.RoomUpdate:
    yield call(updateRoom, message.data);
    yield put(WebinarsActions.setCurrentRoom(message.data));
    break;
  case MessageType.ToggleCamera:
    yield put(WebinarsActions.togglePresenterCamera(message.cameraMode));
    break;
  case MessageType.ReceiveChatMessage:
    yield put(WebinarsActions.receiveMessageSuccess(message.data));
    break;
  case MessageType.ReceiveChatActivation:
    yield put(WebinarsActions.receiveChatActivationSuccess(message.data.activate));
    break;
  case MessageType.GroupAssigned:
    yield put(WebinarsActions.setJoinToRoomModal(message.data));
    break;
  case MessageType.RoomRedirect:
    const { roomId } = message.data;
    yield put(WebinarsActions.setRedirectScreen(false));
    yield put(replace(`/webinar/${message.data.roomId}`));
    yield call(roomRedirect, roomId);
    yield put(WebinarsActions.handleLeaveRoom());
    yield handleJoinRoom({ id: roomId });
    break;
  case MessageType.PermissionRequest:
    yield put(WebinarsActions.receivePermissionRequest(message.data));
    break;
  case MessageType.PermissionResponse:
    const { accepted, streamType } = message.data;
    yield put(WebinarsActions.receivePermissionResponse(accepted));
    const presenterScreenStream = room.streams.find(
      (stream) => stream.direction === StreamDirection.In && stream.type === StreamType.Screen
    );

    if (accepted) {
      if (document.hidden) {
        const channel = yield call(createTabActiveEventChannel);
        try {
          while (true) {
            yield take(channel);
          }
        } finally {
          yield createStreamOnPermissionResponse(streamType, presenterScreenStream);
        }
      } else {
        yield createStreamOnPermissionResponse(streamType, presenterScreenStream);
      }
    }
    break;
  case MessageType.PermissionsUpdate:
    yield put(WebinarsActions.getRoomAccessSuccess(message.data));
    break;
  case MessageType.LiveStarted:
    if (room) {
      const newRoom = message.data;
      yield put(WebinarsActions.setCurrentRoom(newRoom));
      yield call(updateRoom, newRoom);
      yield put(WebinarsActions.liveStartSuccess());
    }
    break;
  case MessageType.LiveEnded:
    yield closeAllStreams();

    if (room) {
      const newRoom = message.data;
      yield put(WebinarsActions.setCurrentRoom(newRoom));
      yield call(updateRoom, newRoom);
      yield put(WebinarsActions.liveEndSuccess());
    }
    break;
  case MessageType.StreamUpdated:
    const { direction, type, video } = message.data;
    if (direction === StreamDirection.In && type === StreamType.Camera) {
      yield put(WebinarsActions.toggleRemoteCameraSuccess(video));
    }
    break;
  case MessageType.FlipchartCreated:
    yield handleConnectFlipchart();
    break;
  case MessageType.FlipchartClosed:
    yield put(WebinarsActions.closeFlipchartSuccess());
    break;
  case MessageType.FlipchartDraw:
    const { data, author } = message.data;
    yield put(WebinarsActions.setLastFlipchartData(data, author));
    break;
  case MessageType.TunnelConnected:
  case MessageType.TunnelDisconnected:
    const { stream } = message.data;
    yield handleTunnelConnected(stream.type);
    break;
  case MessageType.Pong:
    yield put(WebinarsActions.sendPingSuccess(true));
    break;
  default:
    break;
  }
}

function* handleLeaveGroup() {
  yield leaveGroup();
}

function* createStreamOnPermissionResponse(streamType, presenterScreenStream) {
  if (streamType === StreamType.Screen && !!presenterScreenStream) {
    const turnServerConfig = yield select((state) => state.webinars.turnServerConfig);
    yield toggleMedia(presenterScreenStream.id, undefined, undefined, undefined, turnServerConfig);
  }
  yield put(WebinarsActions.createStream(streamType));
}

function* handleToggleMedia({ room, streamType, deviceId, streamId }) {
  const stream = room.streams.find(
    (stream) => stream.direction === StreamDirection.Out && stream.type === streamType
  );
  const turnServerConfig = yield select((state) => state.webinars.turnServerConfig);
  yield toggleMedia(
    streamId ?? stream.id,
    room,
    deviceId,
    streamType,
    turnServerConfig,
  );
  yield setCurrentDevice(streamType, deviceId);
}

function* handleJoinRoom({ id, from }) {
  yield put(WebinarsActions.setIsPending(true));

  try {
    const { data } = yield joinRoom(id, from);
    const room = data.data;
    const turnServerData = yield authorizeInTurnServer();

    yield put(WebinarsActions.setTurnServerConfig(turnServerData.data));
    yield put(WebinarsActions.setCurrentRoom(room));
    const turnServerConfig = yield select((state) => state.webinars.turnServerConfig);
    if (turnServerConfig?.enabled) {
      yield updateStreams(room, turnServerConfig);
    } else {
      yield updateStreams(room);
    }
    yield put(WebinarsActions.toggleRemoteCameraSuccess(true));
    yield handleGetRoomAccess();
    if (room.flipchartId) {
      const { data } = yield connectFlipchart();
      yield put(WebinarsActions.connectFlipchartSuccess(data.data));
    }
    if (room.chatActivate) {
      const { data } = yield fetchChatMessages();
      yield put(WebinarsActions.fetchMessagesSuccess(data.data));
    }
  } catch (error) {
    yield put(WebinarsActions.joinRoomFailure(error));
  } finally {
    yield put(WebinarsActions.setIsPending(false));
  }
}

function* handleAddLocalStream({ stream }) {
  const room = yield select((state) => state.webinars.room);
  if (!room.live) {
    const newRoom = { ...room, streams: [...room.streams, stream] };
    yield put(WebinarsActions.setCurrentRoom(newRoom));
  }
}

function* handleCreateStream({ streamType, deviceId }) {
  const room = yield select((state) => state.webinars.room);
  const currentUser = room.participants.list.find((participant) => participant.user.isCurrentUser);

  if (streamType === StreamType.Camera) {
    yield put(WebinarsActions.setCameraLoader(true));
  } else if (streamType === StreamType.Audio) {
    yield put(WebinarsActions.setAudioLoader(true));
  }

  if (room) {
    const turnServerConfig = yield select((state) => state.webinars.turnServerConfig);
    yield createStream(streamType, room.id, deviceId, currentUser.id, turnServerConfig);
    yield setCurrentDevice(streamType, deviceId);
  } else {
    throw new Error("No room to create stream for!");
  }
}

function* handleGetRoomAccess() {
  const { data } = yield getRoomAccess();
  yield put(WebinarsActions.getRoomAccessSuccess(data));
}

function* handleSendMessage({ message, receiverId }) {
  yield sendMessage(message, receiverId);
}

function* handleSendFile({ file, receiverId }) {
  const data = yield sendFile(file, receiverId);
  if (data === null) {
    yield put(WebinarsActions.setFileErrorStatus(true));
  }
}

function* handleSendPermissionRequest({ streamType }) {
  yield sendPermissionRequest(streamType);
  yield put(WebinarsActions.sendPermissionRequestSuccess(streamType));
}

function* handleShareResponse({ streamType, participantId, accepted }) {
  yield sendPermissionResponse(streamType, participantId, accepted);
  yield put(WebinarsActions.removeRequest());
}

function* handleEndLive() {
  yield endLive();
  yield closeAllStreams();
}

function* handleStartLive() {
  const room = yield select((state) => state.webinars.room);
  yield startLive(room);
}

function* setCurrentDevice(streamType, deviceId) {
  if (deviceId && streamType === StreamType.Camera) {
    yield put(WebinarsActions.setCurrentVideo(deviceId));
  }

  if (deviceId && streamType === StreamType.Audio) {
    yield put(WebinarsActions.setCurrentAudio(deviceId));
  }
}

function* handleStartRecording() {
  yield startRecording();
}

function* handlePauseRecording() {
  yield pauseRecording();
}

function* closeAllStreams() {
  const room = yield select((state) => state.webinars.room);

  for (const stream of room.streams) {
    closeStream(stream.id);
  }
}

function* handleLeaveRoom() {
  yield closeAllStreams();
  yield leaveRoom();
  yield put(WebinarsActions.clearData());
}

function* handleOpenRoom({ room }) {
  yield openRoom(room);
  yield getRoomInfo();
}

function* handleCloseRoom({ roomId }) {
  yield closeRoom(roomId);
}

function* handleKickUser({ roomId, userId }) {
  yield kickUser(roomId, userId);
  yield getRoomInfo();
}

function* handleAcceptRoomAssign({ roomId }) {
  yield put(WebinarsActions.setJoinToRoomModal(null));
  yield put(WebinarsActions.setRedirectScreen(true));
  yield acceptGroupAssign(roomId);
}

function* handleAssignUserToRoom({ roomId, userId }) {
  yield assignUserToRoom(roomId, userId);
  yield getRoomInfo();
}

function* handleOpenFlipchart() {
  const { data } = yield openFlipchart();

  if (data?.id) {
    yield put(WebinarsActions.openFlipchartSuccess(data.id));

    if (data.data) {
      yield put(WebinarsActions.connectFlipchartSuccess(data.data));
    }
  }
}

function* handleCloseFlipchart() {
  yield closeFlipchart();
  yield put(WebinarsActions.closeFlipchartSuccess());
}

function* handleConnectFlipchart() {
  const { data } = yield connectFlipchart();

  if (data?.data) {
    yield put(WebinarsActions.connectFlipchartSuccess(data.data));
  }
}

function* handleFlipchartAddElement({ element, elementId }) {
  yield sendFlipchartAddElement(element, elementId);
}

function* handleFlipchartModifyElement({ element, elementId }) {
  yield sendFlipchartModifyElement(element, elementId);
}

function* handleFlipchartRemoveElement({ elementId }) {
  yield sendFlipchartRemoveElement(elementId);
}

function* handleFlipchartClearCanvas() {
  yield sendFlipchartClearCanvas();
}

function* handleSendFlipchartState({ data }) {
  yield sendFlipchartState(data);
}

function* handleMuteStream({ streamType, participantId }) {
  yield muteStream(streamType, participantId);
}

function* handleTunnelConnected(streamType) {
  if (streamType === StreamType.Camera) {
    yield put(WebinarsActions.setCameraLoader(false));
  } else if (streamType === StreamType.Audio) {
    yield put(WebinarsActions.setAudioLoader(false));
  }
}

function* handleSendPing() {
  yield put(WebinarsActions.sendPingSuccess(false));
  yield sendPing();
}

export function* watchWebinars() {
  yield all([
    yield takeEvery(MessageType.WebsocketMessage, handleWebsocketMessage),
    yield takeLatest(WebinarsTypes.JOIN_ROOM, handleJoinRoom),
    yield takeLatest(WebinarsTypes.CREATE_STREAM, handleCreateStream),
    yield takeLatest(WebinarsTypes.HANDLE_TOGGLE_MEDIA, handleToggleMedia),
    yield takeLatest(WebinarsTypes.HANDLE_GET_ROOM_ACCESS, handleGetRoomAccess),
    yield takeLatest(WebinarsTypes.HANDLE_SEND_MESSAGE, handleSendMessage),
    yield takeLatest(WebinarsTypes.HANDLE_SEND_FILE, handleSendFile),
    yield takeLatest(WebinarsTypes.HANDLE_ADD_LOCAL_STREAM, handleAddLocalStream),
    yield takeLatest(WebinarsTypes.HANDLE_SEND_PERMISSION_REQUEST, handleSendPermissionRequest),
    yield takeLatest(WebinarsTypes.HANDLE_SHARE_RESPONSE, handleShareResponse),
    yield takeLatest(WebinarsTypes.HANDLE_END_LIVE, handleEndLive),
    yield takeLatest(WebinarsTypes.HANDLE_START_LIVE, handleStartLive),
    yield takeLatest(WebinarsTypes.HANDLE_START_RECORDING, handleStartRecording),
    yield takeLatest(WebinarsTypes.HANDLE_PAUSE_RECORDING, handlePauseRecording),
    yield takeLatest(WebinarsTypes.HANDLE_LEAVE_ROOM, handleLeaveRoom),
    yield takeEvery(WebinarsTypes.OPEN_ROOM, handleOpenRoom),
    yield takeEvery(WebinarsTypes.CLOSE_ROOM, handleCloseRoom),
    yield takeEvery(WebinarsTypes.KICK_USER, handleKickUser),
    yield takeEvery(WebinarsTypes.ASSIGN_USER_TO_ROOM, handleAssignUserToRoom),
    yield takeEvery(WebinarsTypes.ACCEPT_ROOM_ASSIGN, handleAcceptRoomAssign),
    yield takeLatest(WebinarsTypes.HANDLE_LEAVE_GROUP, handleLeaveGroup),
    yield takeLatest(WebinarsTypes.OPEN_FLIPCHART, handleOpenFlipchart),
    yield takeLatest(WebinarsTypes.CLOSE_FLIPCHART, handleCloseFlipchart),
    yield takeLatest(WebinarsTypes.FLIPCHART_ADD_ELEMENT, handleFlipchartAddElement),
    yield takeLatest(WebinarsTypes.FLIPCHART_REMOVE_ELEMENT, handleFlipchartRemoveElement),
    yield takeLatest(WebinarsTypes.FLIPCHART_CLEAR_CANVAS, handleFlipchartClearCanvas),
    yield takeLatest(WebinarsTypes.MUTE_STREAM, handleMuteStream),
    yield takeLatest(WebinarsTypes.SEND_PING, handleSendPing),
    yield debounce(100, WebinarsTypes.FLIPCHART_MODIFY_ELEMENT, handleFlipchartModifyElement),
    yield debounce(100, WebinarsTypes.SEND_FLIPCHART_STATE, handleSendFlipchartState),
  ]);
}
