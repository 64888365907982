import { createSelector } from "reselect";
import { prop, path } from "ramda";

export const selectDashboardDomain = prop("dashboard");

export const selectIsInitialDataPending = createSelector(
  selectDashboardDomain, prop("isPending")
);

export const selectBrands = createSelector(
  selectDashboardDomain, prop("brands"),
);

export const selectDashboardData = createSelector(
  selectDashboardDomain, path(["data"]),
);

export const selectHostSex = createSelector(
  selectDashboardData, path(["hostSex"]),
);

export const selectRanking = createSelector(
  selectDashboardData, path(["ranking"]),
);

export const selectAwards = createSelector(
  selectDashboardData, path(["awards"]),
);

export const selectLearningProgress = createSelector(
  selectDashboardData, path(["learningProgress"]),
);

export const selectThumbnailLists = createSelector(
  selectDashboardData, path(["content"]),
);

export const selectIDDStats = createSelector(
  selectDashboardData, path(["iddStats"]),
);

export const selectStatistics = createSelector(
  selectDashboardData, path(["statistics"]),
);

export const selectSurveyStatistics = createSelector(
  selectStatistics, path(["survey"]),
);

export const selectLessonStatistics = createSelector(
  selectStatistics, path(["lesson"]),
);

export const selectCertificateStatistics = createSelector(
  selectStatistics, path(["certificate"]),
);

export const selectIncomingEvent = createSelector(
  selectDashboardData, path(["events", "incoming"]),
);

export const selectIncomingEventTitle = createSelector(
  selectIncomingEvent, path(["course", "name"]),
);

export const selectIncomingEventStartTimestamp = createSelector(
  selectIncomingEvent, path(["timestampStart"]),
);

export const selectIncomingEventId = createSelector(
  selectIncomingEvent, path(["id"]),
);

export const selectUpcomingEvents = createSelector(
  selectDashboardData, path(["events", "recommended"]),
);

export const selectBanners = createSelector(
  selectDashboardData, prop("banners"),
);
