import styled, { css } from "styled-components";
import { DatePicker } from "@mui/x-date-pickers";
import { prop, propEq } from "ramda";

import { styleWhenTrue } from "../../helpers/rendering";
import { Color, FontWeight, Media } from "../../theme";
import { InputStyle } from "../input/input.constants";

export const Picker = styled(DatePicker)`
  width: 100%;
  vertical-align: top;

  .MuiInputBase-root {
    border: 1px solid ${Color.gray8};
    border-radius: 0;
    background-color: ${Color.white};
    margin-bottom: 10px;
    height: 50px;

    ${styleWhenTrue(prop("disabled"), css`
      background-color: ${Color.whiteHover};
    `)}
  }

  fieldset {
    border: none;
  }

  input {
    font-size: 16px;
    padding-left: 15px;
    font-weight: ${FontWeight.SemiBold};
    color: ${Color.gray9};

    ${styleWhenTrue(prop("$active"), css`
      color: ${Color.black};
    `)}

    ${styleWhenTrue(prop("disabled"), css`
      font-size: 14px;
      font-weight: ${FontWeight.Normal};

      ${styleWhenTrue(propEq(InputStyle.ProfileForm, "$styleType"), css`
        color: ${Color.gray9};
      `)}
    `)}
  }

  button {
    margin-right: 0;

    ${Media.tablet`
      margin-right: 8px;
    `}
  }
`;

export const PickerProps = {
  layout: {
    sx: {
      ".MuiPickersDay-root.Mui-selected": {
        color: Color.white,
      },
      ".MuiPickersYear-yearButton.Mui-selected": {
        color: Color.white,
      },
    },
  },
};

export const Error = styled.p`
  color: ${Color.error};
  font-size: 10px;
`;
