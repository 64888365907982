import React, { useContext } from "react";
import PropTypes from "prop-types";

import { hasWidget, WidgetType, Widget } from "../../../";
import { TextBox } from "../textBox/textBox.component";
import { PageDataContext } from "../page/page.context";

import {
  Container,
  VideoContainer,
  VideoBox,
  AdditionalAnim,
} from "./videoSlide.styled";

export const VideoSlide = ({ callComplete, completed }) => {
  const {
    title,
    assetSrc,
    currentSlideCounter,
    slidesOverallCounter,
  } = useContext(PageDataContext);

  return (
    <Container>
      <AdditionalAnim />
      <VideoContainer>
        <VideoBox>
          {hasWidget(WidgetType.VideoSection) && (
            <Widget.VideoSection
              videoSrc={assetSrc}
              onFinish={() => {}}
              timelineDisabled={!completed}
              title={title}
              competences={[]}
              tags={[]}
              callComplete={callComplete}
              isScrollContent
              isScrollLesson
              currentSlideIndex={currentSlideCounter}
              slidesOverall={slidesOverallCounter}
            />
          )}
        </VideoBox>
        <TextBox />
      </VideoContainer>
    </Container>
  );
};

VideoSlide.propTypes = {
  callComplete: PropTypes.func,
  completed: PropTypes.bool,
};
