import styled, { css } from "styled-components";
import { propEq } from "ramda";

import { ReactComponent as CheckSvg } from "../../../../images/check2.svg";
import { ReactComponent as CloseSvg } from "../../../../images/close2.svg";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color } from "../../../../theme";
import { AnswerStatus } from "../../../interactions.constants";

export const Container = styled.div`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border: 2px solid ${Color.dirtyWhite};
  border-radius: 50%;
  background-color: ${Color.white};
  display: flex;
  align-items: center;
  justify-content: center;

  ${styleWhenTrue(propEq(AnswerStatus.Checked, "status"), css`
    border: 6px solid ${Color.blue5};
  `)}

  ${styleWhenTrue(propEq(AnswerStatus.CorrectChecked, "status"), css`
    border: 2px solid ${Color.green};
  `)}

  ${styleWhenTrue(propEq(AnswerStatus.WrongChecked, "status"), css`
    border: 2px solid ${Color.red};
  `)}

  ${styleWhenTrue(propEq(AnswerStatus.WrongUnchecked, "status"), css`
    border: 2px solid ${Color.green};
  `)}
`;

export const CorrectIcon = styled(CheckSvg)`
  width: 10px;
  height: 10px;
  fill: ${Color.green};
`;

export const WrongIcon = styled(CloseSvg)`
  width: 10px;
  height: 10px;
  fill: ${Color.red4};
`;
