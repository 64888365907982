import React from "react";
import styled from "styled-components";

import { colors } from "../UIGlobals";

class ProgressRing extends React.Component {
  constructor(props) {
    super(props);

    const { radius, stroke } = this.props;

    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

  render() {
    const { radius, stroke, progress, percent, color } = this.props;
    const strokeDashoffset = (percent = 1, animated = true) => {
      const path = animated ? progress : this.circumference;
      return this.circumference - (path / 100) * this.circumference * percent;
    };

    return (
      <StyledSVG height={radius * 2} width={radius * 2}>
        <circle
          stroke="#ebecf0"
          fill="transparent"
          strokeWidth={3}
          strokeDasharray={452.3893421169302}
          style={{ strokeDashoffset: strokeDashoffset(1, false) }}
          r={72}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke={color ? colors()[color] : colors().black}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={this.circumference + " " + this.circumference}
          style={{ strokeDashoffset: strokeDashoffset(percent / 100 / 2) }}
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </StyledSVG>
    );
  }
}
const StyledSVG = styled.svg`
  -ms-transform: rotate(150deg);
  -webkit-transform: rotate(150deg);
  -moz-transform: rotate(150deg);
  -o-transform: rotate(150deg);
  transform: rotate(150deg);
  position: absolute;
  z-index: 1;
`;
class ProgressHalf extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      progress: 0
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({ progress: this.state.progress + 1 });
      if (this.state.progress === 100) clearInterval(this.interval);
    }, 10);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    const { color, percent } = this.props;
    return <ProgressRing radius={78} stroke={3} progress={this.state.progress} percent={percent} color={color} />;
  }
}

export { ProgressHalf };
