import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Lottie from "lottie-react";

import { ChartColorType } from "../../../../../components/progressChart/progressChart.constants";

import {
  Container,
  Shapes,
  Name,
  Stage,
  TextWrapper,
  AnimationWrapper,
  Heart,
  MonsterWrapper,
  Chart,
} from "./opponent.styled";

export const Opponent = ({
  className,
  questionCounter,
  allQuestionsCounter,
  monsterName,
  stage,
  monsterAvatar,
}) => {
  const { t } = useTranslation();
  const chartValue = useMemo(() => (
    100 - questionCounter / allQuestionsCounter * 100
  ), [questionCounter, allQuestionsCounter]);

  return (
    <Container className={className}>
      <MonsterWrapper>
        <Heart />
        <Chart
          value={chartValue}
          circleRatio={0.5}
          strokeWidth={3}
          color={ChartColorType.Brand}
        >
          <AnimationWrapper>
            <Lottie
              animationData={monsterAvatar}
              loop
              rendererSettings={{
                preserveAspectRatio: "xMidYMid slice",
              }}
            />
          </AnimationWrapper>
        </Chart>
      </MonsterWrapper>
      <TextWrapper>
        <Name>{monsterName}</Name>
        <Stage>
          {t("interactions.opponentTest.stageCounter", { counter: stage })}
        </Stage>
      </TextWrapper>
      <Shapes />
    </Container>
  );
};

Opponent.propTypes = {
  className: PropTypes.string,
  questionCounter: PropTypes.number,
  allQuestionsCounter: PropTypes.number,
  monsterName: PropTypes.string,
  stage: PropTypes.number,
  monsterAvatar: PropTypes.object,
};
