import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../helpers/rendering";
import { Color, Media } from "../../../theme";

export const Container = styled.div`
  background-color: ${Color.almostWhite};
  overflow: hidden;
  padding: 35vh 20px 20px;
  ${({ backgroundSrc }) => backgroundSrc ? `background-image: url(${backgroundSrc})` : ""};
  background-repeat: no-repeat;
  background-size: 100%;

  ${Media.tablet`
    padding: 0 50px 60px;
  `}

  ${Media.desktop`
    padding: 0 80px 100px;
  `}
`;

export const BlankSeparator = styled.div`
  height: 100vh;

  ${styleWhenTrue(prop("isLastSlide"), css`
    height: 0;
  `)}
`;
