import { SET_NETWORK_ERROR } from "./actions";

const initialState = { errorNetwork: false };

export default function errorNetworkReducer(state = initialState, action) {
  switch (action.type) {
  case SET_NETWORK_ERROR:
    return { errorNetwork: action.error };
  default:
    return state;
  }
}
