import { createActions, createReducer } from "reduxsauce";

export const { Types: ScrollLessonTypes, Creators: ScrollLessonActions } = createActions(
  {
    setQuestionCompleted: ["answers", "onFinish"],
    saveQuiz: null,
  },
  { prefix: "SCROLL_LESSON/" }
);

const INITIAL_STATE = {};

export const reducer = createReducer(INITIAL_STATE, {
});
