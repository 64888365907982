import { phpApi, handleOnboardingApiError } from "../../../api";

export const getInitialData = () => {
  const url = "/api/onboarding";
  return phpApi.get(url).catch(handleOnboardingApiError);
};

export const getOnboardingMode = () => {
  const url = "/api/onboarding/assign";
  return phpApi.get(url);
};

export const getHeroesList = () => {
  const url = "/api/onboarding/characters";
  return phpApi.get(url);
};

export const setHero = (id) => {
  const url = "/api/onboarding/characters";
  return phpApi.post(url, {
    character: id,
  });
};

export const registerFileDownload = (id) => {
  const url = `/api/file/${id}/download`;
  return phpApi.get(url);
};
