import styled, { css } from "styled-components";

import { ReactComponent as SeatsSvg } from "../../../../images/trainings.svg";
import { ReactComponent as PlaceSvg } from "../../../../images/pin.svg";
import { Color, FontWeight, Separator as SeparatorBase } from "../../../../theme";
import { ReactComponent as ChecklistSvg } from "../../images/checklist.svg";
import { ReactComponent as DotsSvg } from "../../images/dots.svg";
import { ReactComponent as TableSvg } from "../../images/table.svg";
import { ReactComponent as TargetSvg } from "../../images/target.svg";
import { ReactComponent as PeopleSvg } from "../../images/people.svg";
import { ReactComponent as MoneySvg } from "../../images/money.svg";
import { ReactComponent as UmbrellaSvg } from "../../images/umbrella.svg";

export const Container = styled.div`
  padding: 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
`;

export const Title = styled.p`
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
`;

export const DateTitle = styled.p`
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.6px;
  font-weight: ${FontWeight.Bolder};
`;

export const Text = styled.p`
  font-size: 12px;
  line-height: 17px;

  ::first-letter {
    text-transform: uppercase;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const Separator = styled(SeparatorBase)`
  && {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

export const SeatsIcon = styled(SeatsSvg)`
  stroke: ${() => Color.brandBasic};
  width: 14px;
  height: 14px;
  margin-right: 15px;
  flex-shrink: 0;
`;

export const PlaceIcon = styled(PlaceSvg)`
  fill: ${() => Color.brandBasic};
  height: 18px;
  width: 12px;
  margin-right: 15px;
  flex-shrink: 0;
`;

export const IconWrapper = styled.div`
  position: absolute;
  bottom: -80px;
  right: 0;
  z-index: -1;
  opacity: 0.5;
`;

const itemIconStyles = css`
  width: 160px;
  height: 160px;

  path {
    fill: ${() => Color.brandBasic};
  }
`;

export const ExamsIcon = styled(ChecklistSvg)`
  ${itemIconStyles}
`;

export const ProductOthersIcon = styled(DotsSvg)`
  ${itemIconStyles}
`;

export const OthersIcon = styled(TableSvg)`
  ${itemIconStyles}
`;

export const DevelopmentIcon = styled(TargetSvg)`
  ${itemIconStyles}
`;

export const HRIcon = styled(PeopleSvg)`
  ${itemIconStyles}
`;

export const MoneyIcon = styled(MoneySvg)`
  ${itemIconStyles}
`;

export const InsuranceIcon = styled(UmbrellaSvg)`
  ${itemIconStyles}
`;
