import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleIfElse, styleWhenTrue } from "../../../helpers/rendering";
import { Color, Media } from "../../../theme";

export const Container = styled.div`
  position: fixed;
  bottom: 80px;
  left: 0;
  right: 0;
  max-width: 200px;
  margin: 0 auto;
  z-index: 999;
  background-color: ${Color.black};
  color: ${Color.white};
  height: 42px;
  border-radius: 3px;
  border: 1px solid ${Color.black};
  display: flex;
  flex-direction: column;
  transition: opacity 0.5s ease-in-out;
  opacity: ${styleIfElse(prop("$isVisible"), "1", "0")};
  pointer-events: none;

  ${styleWhenTrue(prop("$isFavourite"), css`
    background-color: ${Color.lightGreen};
    color: ${Color.green};
    border: 1px solid ${Color.green2};
  `)}

  ${Media.tablet`
    left: 30px;
    bottom: 30px;
    margin: 0;
  `}

  ${Media.desktop`
    left: 110px;
  `}
`;

export const ToastText = styled.p`
  flex: 1;
  padding: 9px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
`;
