import styled from "styled-components";

import { FontWeight, Color, Media } from "../../theme";

export const Container = styled.div``;

export const Title = styled.p`
  font-size: 24px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.deepGray};
`;

export const Separator = styled.div`
  margin-top: 15px;
  margin-bottom: 35px;
  height: 2px;
  background-color: ${Color.blue5};
`;

export const Question = styled.div`
  font-size: 20px;
  line-height: 1.1;
  color: ${Color.deepGray};

  b {
    font-weight: ${FontWeight.Bold};
  }

  ${Media.tablet`
    font-size: 26px;
  `}
`;

export const Hint = styled.p`
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.2;
  color: ${Color.gray9};
`;
