import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { TooltipPlacement } from "../../../../components/tooltip/tooltip.constants";
import { UserRoleName } from "../../../../userContext/userContext.constants";
import { Tooltip } from "../../../../components/tooltip/tooltip.component";
import { OnboardingMode } from "../../../../app/app.constants";
import { HeroName } from "../../onboarding.constants";
import { selectOnboardingHero, selectOnboardingMode } from "../../redux/onboarding.selectors";
import DorisImg from "../../images/doris.png";
import StevenImg from "../../images/steven.png";
import GeorgeImg from "../../images/george.png";
import KateImg from "../../images/kate.png";
import { ContentType } from "../defaultStages/stage/stage.constants";
import { BoardFieldType } from "../boardLegend/boardLegend.constants";
import { BgAnimPromises, AvatarAnimPromises, LeasinGoAnimPromises } from "../board/board.constants";

import {
  Container,
  StageTitle,
  LottieWrapper,
  StageBox,
  TaskField,
  FinishSign,
  FinishButtonAddon,
  HeroWrapper,
  HeroImg,
  GameButton,
  ArrowImg,
} from "./boardStage.styled";

export const BoardStage = ({
  stage,
  stageIndex,
  coords,
  selectedIndex,
  onGameModeClick,
  isIntro,
  isLastStage,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [bgAnim, setBgAnim] = useState();
  const [avatarAnim, setAvatarAnim] = useState();
  const titleStatus = stage.passed
    ? t("components.stages.passed")
    : stage.isEnabled
      ? t("components.stages.inProgressShort")
      : t("components.stages.locked");
  const onboardingMode = useSelector(selectOnboardingMode);
  const hero = useSelector(selectOnboardingHero);
  const heroImg = useMemo(() => {
    switch (hero.name) {
    case HeroName.Steven: return StevenImg;
    case HeroName.Doris: return DorisImg;
    case HeroName.George: return GeorgeImg;
    case HeroName.Kate: return KateImg;
    default: return "";
    }
  }, [hero]);

  useEffect(() => {
    const OpponentAnimPromises = onboardingMode === OnboardingMode.LeasinGo
      ? LeasinGoAnimPromises
      : AvatarAnimPromises;

    OpponentAnimPromises[stageIndex].then(setAvatarAnim);
    BgAnimPromises[stageIndex].then(setBgAnim);
  }, [stageIndex]);

  const handleLessonRedirect = (lesson) => {
    const isLastOnboardingTest = isLastStage && stage.itemGroup["1"].items.at(-1).id === lesson.id;
    if (lesson.contentType === ContentType.Lesson || lesson.contentType === ContentType.ScrollLesson) {
      navigate(`/lekcje/${lesson.id}`, {
        state: { from: UserRoleName.Onboarding, isLastOnboardingTest },
      });
    } else if (lesson.contentType === ContentType.Video) {
      navigate(`/wideo/${lesson.id}`, {
        state: { from: UserRoleName.Onboarding },
      });
    }
  }

  return (
    <Container isDisabled={!stage.isEnabled} isIntro={isIntro}>
      <StageTitle passed={stage.passed} isDisabled={!stage.isEnabled}>
        {stage.name} -&nbsp;{titleStatus}
      </StageTitle>
      <LottieWrapper>
        <Lottie
          loop={stage.isEnabled}
          autoplay={stage.isEnabled}
          animationData={bgAnim}
          rendererSettings={{
            preserveAspectRatio: "xMidYMid slice",
          }}
        />
      </LottieWrapper>
      <StageBox>
        {stage.itemGroup["1"].items.map((item, itemIndex) => {
          const itemCoords = coords[stageIndex][itemIndex];
          const type = itemIndex === stage.itemGroup["1"].items.length - 1
            ? BoardFieldType.Test
            : item.name.includes("Zadanie")
              ? BoardFieldType.Task
              : BoardFieldType.Elearning;
          const isNext = stage.isEnabled && !stage.passed && item.isEnabled && !item.passed;

          return itemCoords && (
            <Tooltip title={item.name} key={itemIndex} placement={TooltipPlacement.Bottom}>
              <TaskField
                x={itemCoords?.x}
                y={itemCoords?.y}
                type={type}
                passed={item.isEnabled && item.passed}
                inProgress={item.isEnabled && !!item.progress}
                isDisabled={!item.isEnabled}
                selected={selectedIndex === itemIndex}
                onClick={() => item.isEnabled ? handleLessonRedirect(item) : undefined}
              >
                {type === BoardFieldType.Test && (
                  <Lottie
                    animationData={avatarAnim}
                    autoplay={stage.isEnabled}
                    loop={stage.isEnabled}
                    rendererSettings={{
                      preserveAspectRatio: "xMidYMid slice",
                    }}
                  />
                )}
                {type === BoardFieldType.Test && stageIndex === 4 && (
                  <FinishSign>
                    <FinishButtonAddon />
                    {t("onboarding.stages.finish")}
                  </FinishSign>
                )}
                {isNext && (
                  <HeroWrapper>
                    <HeroImg src={heroImg} />
                  </HeroWrapper>
                )}
              </TaskField>
            </Tooltip>
          );
        })}
      </StageBox>
      <GameButton onClick={onGameModeClick} disabled={!stage.isEnabled}>
        <ArrowImg />
      </GameButton>
    </Container>
  );
};

BoardStage.propTypes = {
  stage: PropTypes.object.isRequired,
  stageIndex: PropTypes.number.isRequired,
  coords: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  selectedIndex: PropTypes.number,
  onGameModeClick: PropTypes.func,
  isIntro: PropTypes.bool,
};
