import styled from "styled-components";

import { Button as ButtonBase } from "../../../../../components/button/button.component";
import { hexToRgba } from "../../../../../helpers/colors";
import { Color, FontWeight, Media } from "../../../../../theme";

export const DropdownContent = styled.div`
  padding-bottom: 34px;

  ${Media.tablet`
    padding-bottom: 0;
  `}

  ${Media.desktop`
    padding: 16px 76px 0 67px;
  `}
`;

export const DropdownRow = styled.div`
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  padding: 8px 0;

  ${Media.tablet`
    padding: 20px 0;
  `}

  &:last-of-type {
    border-bottom: 1px solid ${hexToRgba(Color.gray5, 0.8)};
    margin-bottom: 20px;
  }
`;

export const RowTitle = styled.h1`
  font-weight: ${FontWeight.Bold};
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: ${Color.gray9};
`;

export const RowValue = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${Color.gray9};
`;

export const DescriptionWrapper = styled.div`
  padding-top: 25px;
  border-top: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  margin-bottom: 30px;

  ${Media.tablet`
    border: none;
    padding-top: 0;
  `}
`;

export const DescriptionHeader = styled.p`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.6px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: ${FontWeight.ExtraBold};

  ${Media.tablet`
    margin-bottom: 20px;
  `}
`;

export const DescriptionLabel = styled.p`
  font-size: 13px;
  line-height: 24px;
  color: ${Color.deepGray};

  ${Media.tablet`
    padding-right: 20px;
  `}
`;

export const Button = styled(ButtonBase)`
  width: 100%;

  ${Media.tablet`
    width: auto;
  `}
`;
