import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  DefaultButtonStyles,
  OutlinedButtonStyles,
} from "../../../../../components/button/button.styled";
import { ReactComponent as InfoSvg } from "../../../../../images/info.svg";
import { Color, Media, Separator as SeparatorBase } from "../../../../../theme";

export const Container = styled.div`
  background-color: ${Color.white};
  margin-top: 20px;
  padding: 21px 20px;

  ${Media.tablet`
    padding: 21px 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
  `}
`;

export const TextWrapper = styled.div`
  display: flex;
`;

export const InfoIcon = styled(InfoSvg)`
  width: 22px;
  height: 22px;
  margin-right: 17px;
  flex-shrink: 0;

  g {
    fill: ${() => Color.brandBasic};
  }
`;

export const Text = styled.p`
  font-size: 13px;
  line-height: 24px;
  color: ${Color.deepGray};
`;

export const RulesLink = styled(Link)`
  ${DefaultButtonStyles}
  ${OutlinedButtonStyles}
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;

  ${Media.tablet`
    margin-right: 0;
    margin-left: auto;
  `}
`;

export const Separator = styled(SeparatorBase)`
  ${Media.tablet`
    display: none;
  `}
`;
