import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { AnimName } from "../ruleItem/ruleItem.constants";
import { RuleItem } from "../ruleItem/ruleItem.component";

import {
  Container,
  Wrapper,
  Section,
  SectionTitle,
  Text,
  Separator,
  RulesWrapper,
  RulesRow,
} from "./rules.styled";

export const Rules = ({ className }) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <Wrapper>
        <Section>
          <SectionTitle>
            {t("gamification.start.assumptions")}
          </SectionTitle>
          <Text>
            {t("gamification.start.assumptionText")}
          </Text>
        </Section>
        <Separator />
        <Section>
          <SectionTitle>
            {t("gamification.start.rules")}
          </SectionTitle>
          <RulesWrapper>
            <RulesRow>
              <RuleItem
                title={t("gamification.start.challenges")}
                text={t("gamification.start.challengesText")}
                animationName={AnimName.Game}
              />
              <RuleItem
                title={t("gamification.start.beUpToDate")}
                text={t("gamification.start.beUpToDateText")}
                animationName={AnimName.Diamond}
              />
            </RulesRow>
            <RulesRow>
              <RuleItem
                title={t("gamification.start.coins")}
                text={t("gamification.start.coinsText")}
                animationName={AnimName.Coin}
              />
              <RuleItem
                title={t("gamification.start.badges")}
                text={t("gamification.start.badgesText")}
                animationName={AnimName.Badge}
              />
            </RulesRow>
          </RulesWrapper>
        </Section>
      </Wrapper>
    </Container>
  );
};

Rules.propTypes = {
  className: PropTypes.string,
};
