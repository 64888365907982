import styled from "styled-components";

export const Container = styled.div`
  min-height: 600px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  .elearning-calc__task {
    color: #000;
    font-size: 1.4rem;
    padding: 0 25px;
    line-height: 25px;
  }

  .elearning-calc__task .row {
    padding: 10px 0px;
  }

  .elearning-calc__task .row:first-of-type {
    padding-top: 20px;
  }

  .elearning-calc__task p {
    padding-top: 20px;
  }

  .elearning-calc__task .large {
    text-transform: uppercase;
    font-weight: 600;
  }

  .elearning-calc__col:not(:last-child) {
    margin-right: 1rem;
  }

  .elearning-calc__col--scaled {
    display: inline-block;
    transform: scale(2);
  }

  .elearning-calc__input input {
    border: 1px dashed gray;
    background: #fff;
    width: 100%;
    padding: 0.5rem 0;
    text-align: center;
    font-size: 1.4rem;
  }
  
  .elearning-calc__input input:focus {
    outline: none;
    border-color: #000;
  }

  .elearning-calc__input input[disabled] {
    background: none;
    border-color: transparent;
    color: #000;
  }

  .elearning-calc__label {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-top: 0.25rem;
    margin-top: 0.25rem;
    border-top: 1px solid #000;
  }

  .elearning-calc__actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
  }

  .elearning-calc__actions p {
    font-size: 1.4rem;
    font-weight: 600;
  }

  .elearning-calc__actions--failed p {
    color: #c20012;
  }

  .elearning-calc__actions--ok p {
    color: #81cd36;
  }

  & form {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    margin-top: 3rem;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 2px solid #000;
  }

  & form span {
    font-size: 1.4rem;
    font-weight: 600;
  }

  & form span + span {
    font-size: 1.2rem;
    color: #000;
  }

  @media screen and (max-width: 1024px) {
    & form {
      display: flex;
      flex-flow: column;
    }

    .elearning-calc__col:not(:last-child) {
      margin-bottom: 15px;
    }

    .elearning-calc__col {
      width: 90%;
      text-align: center;
    }
  }
`;
