export const MedalType = {
  Gold: "gold",
  Silver: "silver",
  Bronze: "bronze",
};

export const ContentType = {
  Video: "video",
  Lesson: "lesson",
  ScrollLesson: "lesson_slide_scroll",
};

export const ContentTypeString = {
  [ContentType.Video]: "learning.filters.filters.video",
  [ContentType.Lesson]: "learning.filters.filters.elearning"
};

export const NextLessonHash = "#next";
