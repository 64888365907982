import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectUserBrands } from "../../userContext/redux/userContext.selectors";
import { Theme } from "../../appConfig";
import { AppSettingsActions } from "../redux/appSettings.reducer";

import { BrandTheme } from "./brandReset.constants";
import { Container } from "./brandReset.styled";

export const BrandReset = ({ children }) => {
  const dispatch = useDispatch();
  const brands = useSelector(selectUserBrands);
  const hasMultipleBrands = brands && brands.length > 1;
  const isActive = Theme.Brand === BrandTheme.Changeable && hasMultipleBrands;

  const handleSelectBrand = () => {
    dispatch(AppSettingsActions.deselectBrand());
  };

  return (
    <Container
      onClick={handleSelectBrand}
      $active={isActive}
    >
      {children}
    </Container>
  );
};
