import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { WebinarsActions } from "../../redux/webinars.reducer";

import {
  Container,
  Modal,
  RedirectIcon,
  Title,
  Text,
  Bold,
  BrandColorText,
} from "./joinToRoomModal.styled";

export const JoinToRoomModal = ({ room }) => {
  const { t } = useTranslation();
  const [timeout, setTimeout] = useState(5);
  const dispatch = useDispatch();

  useEffect(() => {
    if (timeout === 0) {
      dispatch(WebinarsActions.acceptRoomAssign(room.roomId));
      return;
    }

    const interval = setInterval(() => {
      setTimeout(timeout - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [timeout]);

  return (
    <Container>
      <Modal>
        <RedirectIcon />
        <Title>{t("webinars.joinToRoomModal.joinToRoom")}</Title>
        <Text>
          {t("webinars.joinToRoomModal.youWillBeRedirectedTo")} {" "}
          <Bold>{room.roomName}</Bold> {" "}
          {t("webinars.joinToRoomModal.after")} {" "}
          <BrandColorText>{timeout}{t("webinars.joinToRoomModal.seconds")}</BrandColorText>
        </Text>
      </Modal>
    </Container>
  );
};
