import styled from "styled-components";

import { Color, Media } from "../../../../theme";

export const Container = styled.div`
  background-color: ${Color.almostWhite};
  padding: 40px 0;

  ${Media.tablet`
    padding: 60px 80px 80px;
  `}

  ${Media.desktop`
    padding: 60px 82px 80px 160px;
  `}

  ${Media.desktopWide`
    padding: 60px 160px 80px;
  `}
`;
