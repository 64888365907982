import styled, { css } from "styled-components";
import { prop } from "ramda";

import { DropdownInput as DropdownInputBase } from "../../../../../components/dropdownInput/dropdownInput.component";
import { Modal as ModalBase } from "../../../../../components/modal/modal.component";
import { Button as ButtonBase } from "../../../../../components/button/button.component";
import { Color, FontWeight, Media } from "../../../../../theme";
import { ReactComponent as PlusSvg } from "../../../../../images/addInCircle.svg";
import { styleWhenTrue } from "../../../../../helpers/rendering";
import { OptionsWrapper } from "../../../../../components/dropdownInput/dropdownInput.styled";

export const PlusIcon = styled(PlusSvg)`
  g {
    stroke: ${() => Color.brandBasic};
  }
`;

export const DropdownInput = styled(DropdownInputBase)`
  ${styleWhenTrue(prop("disabled"), css`
    background-color: ${Color.almostWhite};
  `)}

  ${Media.tablet`
    ${OptionsWrapper} {
      &:last-of-type {
        max-height: 150px;
      }
    }
  `}
`;

export const Modal = styled(ModalBase)`
  .MuiPaper-root {
    height: 100%;

    ${Media.tablet`
      height: unset;
    `}
  }
`;

export const ModalContent = styled.div`
  padding: 35px 20px 20px;
  height: 100%;

  ${Media.tablet`
    padding: 35px 30px 50px;
    border-bottom: 1px solid ${Color.gray8};
  `}
`;

export const ButtonRow = styled.div`
  padding: 20px;

  ${Media.tablet`
    padding: 30px;
    display: flex;
    justify-content: flex-end;
  `}
`;

export const Title = styled.h1`
  color: ${Color.deepGray};
  font-size: 16px;
  font-weight: ${FontWeight.Bold};
  margin-bottom: 20px;

  &:not(:first-of-type) {
    margin-top: 25px;
  }
`;

export const Button = styled(ButtonBase)`
  width: 100%;

  ${Media.tablet`
    width: unset;
  `}
`;
