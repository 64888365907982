import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import shuffle from "lodash/shuffle";

export const TaskScreen = ({ sentences, onSuccess, onFailure }) => {
  const { t } = useTranslation();
  const [items, setItems] = useState(shuffle(sentences));

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setItems(newItems);
  };

  const checkOrder = (items) => {
    return items.every((item, index) => item === sentences[index]);
  }

  const handleOnClick = () => {
    if (checkOrder(items)) {
      onSuccess();
    } else {
      onFailure();
    }
  }

  return (
    <div className="put-in-order--task-screen">
      <h3 className="put-in-order--header hidden">
        {t("interactions.dragAndDropQuiz.introduction")}
      </h3>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((value, i) => (
                <Draggable key={`item-${i}`} draggableId={`item-${i}`} index={i}>
                  {(provided) => (
                    <div
                      className="put-in-order--task-screen--item hidden"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {value}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <button className="put-in-order--button hidden" onClick={handleOnClick}>{t("global.check")}</button>
    </div>
  );
}
