import styled from "styled-components";

export const Container = styled.div`
  video {
    width: 100%;
    max-height: 500px;
  }

  @media screen and (max-width: 768px) {
    video {
      max-height: 350px;
    }
  }

  .hidden {
    display: none;
  }

  .video-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 670px;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    .video-container {
      height: 550px;
    }
  }

  .video-container .menu-description {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    color: #000000;
    height: 140px;
    text-align: center;
    font-size: 18px;
    font-weight: lighter;
    margin-top: 10px;
    padding: 0 15px;
    line-height: 25px;
  }

  .video-container .menu-content {
    height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 550px;
    padding: 10px;
    flex-wrap: wrap;
  }

  .video-container .menu-content .nav-container {
    width: 100%;
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* ie11 fix */
    min-height: 320px; /* ie11 fix */
  }

  .video-container .menu-content .locked {
    color: #d6d6d6 !important;
    pointer-events: none !important;
  }

  .video-container .menu-content .arrow {
    position: absolute;
    cursor: pointer;
    color: #47545c;
  }

  .video-container .menu-content .arrow:hover {
    color: #d1d3d4;
  }

  .video-container .menu-content .arrow__next {
    right: 0;
    margin-right: 60px;
  }

  .video-container .menu-content .arrow__prev {
    left: 0;
    margin-left: 60px;
  }

  .video-container .menu-content__rotator {
    overflow: hidden;
    padding: 0;
    justify-content: flex-start;
    box-sizing: border-box;
  }

  @media screen and (min-width: 1401px) {
    .video-container .menu-content__rotator {
      width: 825px;
    }
  }

  @media screen and (max-width: 1400px) {
    .video-container .menu-content__rotator {
      width: 660px;
    }
  }

  @media screen and (max-width: 880px) {
    .video-container .menu-content__rotator {
      width: 495px;
    }
  }

  @media screen and (max-width: 700px) {
    .video-container .menu-content__rotator {
      width: 330px;
    }
  }

  @media screen and (max-width: 530px) {
    .video-container .menu-content__rotator {
      width: 165px;
    }
  }

  @media screen and (max-width: 330px) {
    .video-container .menu-content .arrow__next {
      margin-right: 30px;
    }

    .video-container .menu-content .arrow__prev {
      margin-left: 30px;
    }
  }

  .video-container .menu-content .menu-wrap {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
  }

  .video-container .menu-content .menu-wrap .single-video-box {
    width: 165px;
    height: 165px;
    border: 1px #d1d3d4 solid;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
  }

  .video-container .menu-content .menu-wrap .single-video-box .check {
    display: none;
  }

  .video-container .menu-content .menu-wrap .single-video-box .circle-wrap {
    width: 165px;
    height: 100px;
    border-bottom: 1px #e6e7e8 solid;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .video-container .menu-content .menu-wrap .single-video-box .circle-wrap img {
    width: 165px;
    height: 100px;
  }

  .video-container .menu-content .menu-wrap .single-video-box .circle-wrap .circle {
    width: 69px;
    height: 69px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px #d1d3d4 solid;
    border-radius: 50%;
  }

  .video-container .menu-content .menu-wrap .single-video-box .circle-wrap .circle svg {
    color: #d1d3d4;
  }

  .video-container .menu-content .menu-wrap .single-video-box .desc-box {
    color: #000000;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 65px;
    position: relative;
  }

  .video-container .menu-content .menu-wrap .single-video-box .desc-box .tooltip {
    position: absolute;
    left: -1px;
    top: 0;
    background: white;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: calc(102%);
    border: 1px #e6e7e8 solid;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    font-weight: lighter;
    padding: 10px;
    display: none;
  }

  .video-container .menu-content .menu-wrap .single-video-box .desc-box h2 {
    font-size: 11px;
    font-weight: 200;
    padding: 5px;
    line-height: 20px;
  }

  .video-container .menu-content .menu-wrap .watched .single-video-box .check {
    display: inherit;
    background: #14a6d5;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .video-container .menu-content .menu-wrap .watched .single-video-box .check svg {
    color: #fff;
  }

  .video-container .menu-content .menu-wrap .watched .single-video-box .circle-wrap .circle {
    border: 5px #14a6d5 solid;
  }

  .video-container .menu-content .menu-wrap .watched .single-video-box .circle-wrap .circle svg {
    color: #14a6d5;
  }

  .video-container .menu-content .menu-wrap .watched .single-video-box .desc-box {
    color: #14a6d5;
  }

  .modal-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
  }

  .modal-container .modal-header {
    display: flex;
  }

  .modal-container .modal-header .icon-wrap {
    background: #949599;
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-container .modal-header .icon-wrap svg {
    color: #ffffff;
  }

  .modal-container .modal-header .modal-title-wrap {
    background: #49535c;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .modal-container .modal-header .modal-title-wrap h2 {
    color: #ffffff;
    font-size: 24px;
    font-weight: normal;
    margin-left: 50px;
    padding-right: 15px;
    line-height: 30px;
  }

  .modal-container .modal-events {
    width: 100%;
    height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-container .modal-events .modal-events-wrap {
    width: 495px;
    max-width: 100%; 
  }

  .modal-container .modal-events .modal-events-wrap h3 {
    width: 495px;
    margin: 0;
    color: #46535b;
    font-weight: 200;
    padding: 25px;
    border-top: 1px #e7e7e7 solid;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    max-width: 100%; 
    line-height: 30px;
    padding-right: 25px;
  }

  .modal-container .modal-events .modal-events-wrap h3:hover {
    color: #6e6c71;
  }

  .modal-container .modal-events .modal-events-wrap h3 svg {
    margin-top: 5px;
  }
  
  .modal-container .modal-events .modal-events-wrap .border-bottom {
    border-bottom: 1px #e7e7e7 solid;
  }
`;
