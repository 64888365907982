import styled, { css } from "styled-components";

import { styleThemeOr } from "../../../../helpers/rendering";
import { Color } from "../../../../theme/colors";
import { FontWeight } from "../../../../theme/fonts";
import { Media } from "../../../../theme/media";

import { ReactComponent as HostManSVG } from "../../images/hostMan.svg";
import { ReactComponent as HostWomanSVG } from "../../images/hostWoman.svg";
import { ReactComponent as FaceSvg } from "../../images/face.svg";

export const Container = styled.div`
  height: 230px;
  background-color: ${() => Color.brandBasic};
  position: relative;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${Media.tablet`
    padding: 25px;
  `}
`;

export const MessageContainer = styled.div`
  font-size: 25px;
  line-height: 32px;
  font-weight: ${FontWeight.SemiBold};
  z-index: 1;

  ${Media.tablet`
    font-size: 28px;
    line-height: 38px;
  `}
`;

export const MessageLabel = styled.div`
  color: ${Color.white};
`;

export const MessageName = styled.div`
  color: ${styleThemeOr(["welcomeMessage", "backgroundColor"], Color.white)};
`;

export const TodayContainer = styled.div`
  font-size: 14px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
`;

export const TodayLabel = styled.label`
  color: ${Color.gray1};
`;

export const TodayDate = styled.label`
  color: ${Color.gray8};
`;

const dynamicHostStyles = css`
  position: absolute;
  right: 10px;
  bottom: 0;
  height: 140px;

  #body {
    fill: ${Color.white};
  }

  ${Media.tablet`
    right: 15px;
    height: 166px;
  `}

  ${Media.desktop`
    right: 60px;
  `}
`;

export const HostMan = styled(HostManSVG)`
  ${dynamicHostStyles}
`;

export const HostWoman = styled(HostWomanSVG)`
  ${dynamicHostStyles}
`;

export const ChangeSexButton = styled.button`
  color: ${Color.gray4};
  border: none;
  background: none;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 2;
`;

export const FaceIcon = styled(FaceSvg)`
  fill: ${Color.gray4};
`;
