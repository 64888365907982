import styled, { css } from "styled-components";
import { prop, propOr, propEq } from "ramda";

import { styleWhenTrue } from "../../helpers/rendering";
import { Color } from "../../theme/colors";
import { IconVariant } from "./icon.constants";

const navigationIconStyles = css`
  svg * {
    fill: ${Color.black};

    ${styleWhenTrue(prop("$active"), css`
      fill: ${Color.white};
    `)}

    ${styleWhenTrue(prop("special"), css`
      fill: ${Color.gray4};
    `)}
  }
`;

export const Container = styled.i`
  width: auto;
  height: ${propOr(20, "size")}px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 100%;
    display: flex;
    color: inherit;
    fill: currentColor;
  }

  ${styleWhenTrue(propEq(IconVariant.Navigation, "$variant"), navigationIconStyles)}
`;
