import styled from "styled-components";

import { breakpoints } from "../../../../../UIGlobals";
import { Color } from "../../../../../theme";

export const SmallBox = styled.div`
  display: flex;
  width: 100%;
  font-size: 15px;
  height: 100px;
  align-items: center;

  @media (${breakpoints().maxM}) {
    padding-left: 27px;
  }
`;

export const BlackCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Color.black};
  border-radius: 50%;
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  margin-right: 20px;
  font-size: 30px;
  font-weight: bold;
  color: ${Color.white};
`;

export const LightGrayText = styled.div`
  font-weight: 400;
  color: ${Color.gray4};
`;

export const Box = styled.div`
  background: ${Color.gray8};
  margin: auto 0 4px 20px;
  height: 422px;
  width: auto;
  padding: 35px 50px 25px 30px;
  display: flex;
  flex-direction: column;

  @media (${breakpoints().maxS}) {
    margin: 0;
    width: 100%;
  }
`;

export const RankingTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
`;

export const RankingIconWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;

  @media (${breakpoints().maxM}) {
    padding-left: 20px;
  }
`;
