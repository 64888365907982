import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import { StatisticsTheme } from "../../../modules/teamIndicators/components/statistics/statistics.constants";
import { selectUnreadNotifications } from "../../../redux/common/common.selectors";
import { BrandReset } from "../../../appSettings/brandReset/brandReset.component";
import { UserRoleName } from "../../../userContext/userContext.constants";
import { Theme } from "../../../appConfig";

import {
  Container,
  AvatarContainer,
  TextAvatar,
  Menu,
  User,
  Email,
  MenuItems,
  MenuItem,
  NotificationsNumber,
  NotificationIndicator,
} from "./userMenu.styled";

export const UserMenu = ({ children, user, multiBrands }) => {
  const { t } = useTranslation();
  const unreadNotifications = useSelector(selectUnreadNotifications);
  const [isOpen, setIsOpen] = useState(false);
  const shouldRenderStatisticsItem = user.role.name !== UserRoleName.Onboarding
    && Theme.StatisticsTheme === StatisticsTheme.Visible;

  return (
    <Container
      $isOpen={isOpen}
      onMouseLeave={() => setIsOpen(false)}
    >
      <AvatarContainer onMouseEnter={() => setIsOpen(true)}>
        {children}
      </AvatarContainer>
      <Menu $isOpen={isOpen}>
        <TextAvatar>{user.name && user.name[0]} {user.surname && user.surname[0]}</TextAvatar>
        <User>{user.name} {user.surname}</User>
        <Email>{user.email}</Email>
        <MenuItems>
          <MenuItem>
            <NavLink to="/powiadomienia">{t("app.mainHeader.rightNav.notifications")}</NavLink>
            {!!unreadNotifications && (
              <div>
                <NotificationsNumber>{unreadNotifications}</NotificationsNumber>
                <NotificationIndicator />
              </div>
            )}
          </MenuItem>
          {multiBrands && (
            <MenuItem>
              <BrandReset>{t("app.mainHeader.rightNav.selectBrand")}</BrandReset>
            </MenuItem>
          )}
          <MenuItem>
            <NavLink to="/profil/details">{t("app.mainHeader.rightNav.showProfile")}</NavLink>
          </MenuItem>
          {shouldRenderStatisticsItem && (
            <MenuItem>
              <NavLink to="/statystyki">{t("app.mainHeader.rightNav.statistics")}</NavLink>
            </MenuItem>
          )}
        </MenuItems>
      </Menu>
    </Container>
  );
};
