import React from "react";
import { useTranslation } from "react-i18next";

import { ClearfixContainer } from "../../UIElements";

import { QuestionButtons } from "../question/question.styled";
import { QuizSummary } from "../summary/summary.styled";
import { Button } from "../quiz.styled";

import { SummaryWrapper } from "./summaryShort.styled";

const SummaryShort = ({ actions = false, wrapped }) => {
  const { t } = useTranslation();

  return (
    <SummaryWrapper wrapped={wrapped}>
      <ClearfixContainer>
        <QuizSummary offsetTop>{t("form.form.thankYou")}!</QuizSummary>
        {actions && (
          <QuestionButtons>
            {actions.map((action, index) => (
              <Button key={index} onClick={action.action}>
                {action.label}
              </Button>
            ))}
          </QuestionButtons>
        )}
      </ClearfixContainer>
    </SummaryWrapper>
  );
};

export default SummaryShort;
