export const CoordsUsedAndInsurance = [
  [
    { x: 128, y: 20 },
    { x: 128, y: 44 },
    { x: 128, y: 68 },
    { x: 152, y: 68 },
    { x: 152, y: 92 },
    { x: 152, y: 116 },
    { x: 152, y: 140 },
    { x: 128, y: 140 },
    { x: 128, y: 164 },
    { x: 128, y: 188 },
    { x: 128, y: 212 },
    { x: 152, y: 212 },
    { x: 176, y: 212 },
    { x: 220, y: 181 },
  ],
  [
    { x: 20, y: 212 },
    { x: 44, y: 212 },
    { x: 68, y: 212 },
    { x: 92, y: 212 },
    { x: 116, y: 212 },
    { x: 116, y: 188 },
    { x: 116, y: 164 },
    { x: 92, y: 164 },
    { x: 68, y: 164 },
    { x: 68, y: 140 },
    { x: 35, y: 30},
  ],
  [
    { x: 38, y: 331 },
    { x: 38, y: 307,},
    { x: 38, y: 283 },
    { x: 62, y: 283 },
    { x: 86, y: 283 },
    { x: 86, y: 307 },
    { x: 110, y: 307 },
    { x: 134, y: 307 },
    { x: 158, y: 307 },
    { x: 158, y: 283 },
    { x: 158, y: 259 },
    { x: 182, y: 259 },
    { x: 206, y: 259 },
    { x: 230, y: 259 },
    { x: 254, y: 259 },
    { x: 278, y: 259 },
    { x: 278, y: 235 },
    { x: 278, y: 211 },
    { x: 254, y: 211 },
    { x: 230, y: 211 },
    { x: 206, y: 211 },
    { x: 182, y: 211 },
    { x: 158, y: 211 },
    { x: 158, y: 187 },
    { x: 158, y: 163 },
    { x: 134, y: 163 },
    { x: 110, y: 163 },
    { x: 110, y: 139 },
    { x: 86, y: 139 },
    { x: 86, y: 115 },
    { x: 86, y: 91 },
    { x: 110, y: 91 },
    { x: 134, y: 91 },
    { x: 158, y: 91 },
    { x: 182, y: 91 },
    { x: 204, y: 91 },
    { x: 236, y: 82 },
  ],
  [
    { x: 28, y: 115 },
    { x: 52, y: 115 },
    { x: 76, y: 115 },
    { x: 76, y: 91 },
    { x: 76, y: 67 },
    { x: 76, y: 43 },
    { x: 100, y: 43 },
    { x: 124, y: 43 },
    { x: 148, y: 43 },
    { x: 148, y: 67 },
    { x: 148, y: 91 },
    { x: 172, y: 91 },
    { x: 196, y: 91 },
    { x: 220, y: 91 },
    { x: 220, y: 115 },
    { x: 220, y: 139 },
    { x: 196, y: 139 },
    { x: 172, y: 139 },
    { x: 148, y: 139 },
    { x: 148, y: 163 },
    { x: 148, y: 187 },
    { x: 172, y: 187 },
    { x: 196, y: 187 },
    { x: 220, y: 187 },
    { x: 220, y: 211 },
    { x: 220, y: 235 },
    { x: 196, y: 235 },
    { x: 172, y: 235 },
    { x: 172, y: 259 },
    { x: 172, y: 283 },
    { x: 148, y: 283 },
    { x: 124, y: 283 },
    { x: 124, y: 259 },
    { x: 124, y: 235 },
    { x: 100, y: 235 },
    { x: 20, y: 269 },
  ],
  [
    { x: 20, y: 20 },
    { x: 20, y: 44 },
    { x: 44, y: 44 },
    { x: 68, y: 44 },
    { x: 92, y: 44 },
    { x: 116, y: 44 },
    { x: 116, y: 68 },
    { x: 116, y: 92 },
    { x: 92, y: 92 },
    { x: 92, y: 116 },
    { x: 92, y: 140 },
    { x: 116, y: 140 },
    { x: 140, y: 140 },
    { x: 164, y: 140 },
    { x: 188, y: 140 },
    { x: 212, y: 140 },
    { x: 236, y: 140 },
    { x: 180, y: 184 },
  ],
];
