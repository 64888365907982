import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Container } from "../../../../theme/containers";
import { CompetenceActions } from "../../redux/competence.reducer";
import { CompetenceDescriptionBox } from "../competenceDescriptionBox/CompetenceDescriptionBox.component";
import { CompetenceMeasuresBox } from "../competenceMeasuresBox/competenceMeasuresBox.component";
import { SuggestionBox } from "../suggestionBox/suggestionBox.component";
import { BoxType } from "../suggestionBox/suggestionBox.constants";

import { Title } from "./competenceTab.styled";

export const CompetenceTab = ({ competenceId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(CompetenceActions.getCompetenceSkills(competenceId));
    dispatch(CompetenceActions.getConfirmationCategories());
  }, [competenceId, dispatch]);

  return (
    <Container>
      <Title>{t("competence.competenceTab.competenceDescription")}</Title>
      <CompetenceDescriptionBox />
      <Title>{t("competence.competenceTab.competenceMeasures")}</Title>
      <CompetenceMeasuresBox competenceId={competenceId} />
      <SuggestionBox competenceId={competenceId} type={BoxType.Confirmation} />
    </Container>
  );
};

CompetenceTab.propTypes = {
  competenceId: PropTypes.number.isRequired,
};
