import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { AnswerStatus } from "../interactions/interactions.constants";
import { styleWhenTrue } from "../helpers/rendering";

import { Color } from "./colors";
import { Media } from "./media";
import { FontWeight } from "./fonts";
import { ContentMaxWidth } from "./containers";

export const PageHeader = styled.h1`
  font-size: 4.15rem;
  font-weight: ${FontWeight.Light};
  line-height: 1.33;
  text-align: left;
  color: ${Color.black};
  padding-right: 20px;
  margin: 0 0 60px;
  max-width: ${ContentMaxWidth};
`;

export const SectionHeader = styled.h3`
  font-size: 18px;
  line-height: 20px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.black};
  margin-bottom: 25px;
  margin-left: 20px;

  ${Media.tablet`
    margin-left: 0;
  `}
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background: ${Color.gray5};
  flex: none;

  ${Media.tablet`
    margin: 40px 0;
  `}

  ${styleWhenTrue(prop("$narrow"), css`
    && {
      margin: 0;
    }
  `)}
`;

export const unorderedListStyles = css`
  list-style: none;
  padding: 20px;
  margin: 0;
  position: relative;

  li {
    list-style-type: none;
    padding-left: 26px;
    margin-left: 0;
  }

  li::before {
    content: "";
    color: ${() => Color.brandBasic};
    font-weight: ${FontWeight.Bold};
    display: inline-block;
    width: 6px;
    height: 6px;
    background: ${() => Color.brandBasic};
    position: absolute;
    left: 0;
    border-radius: 100%;
    transform: translateY(8px);
  }
`;

export const UnorderedList = styled.ul`
  ${unorderedListStyles};
`;

export const StrongText = styled.strong`
  font-weight: ${FontWeight.Bold};
`;

export const ItalicText = styled.em`
  font-style: italic;
`;

export const UnderlinedText = styled.del`
  text-decoration: underline;
`;

export const ErrorMessage = styled.span`
  font-size: 10px;
  font-weight: ${FontWeight.Normal};
  line-height: 15px;
  display: flex;
  align-items: center;
  color: ${() => Color.brandBasic};
  text-align: right;
`;

export const Asterisk = styled.span`
  position: relative;
  color: ${() => Color.brandBasic};

  &:after {
    content: "*";
    position: absolute;
    left: 0;
    top: 0;
  }
`;

export const InteractionAnswerLabel = styled.p`
  color: ${Color.deepGray};
  font-size: 14px;

  ${styleWhenTrue(propEq(AnswerStatus.Checked, "status"), css`
    color: ${Color.blue5};
  `)}

  ${Media.tablet`
    font-size: 15px;
  `}
`;

export const FormQuestionTitle = styled.h1`
  font-size: 2.4rem;
  font-weight: ${FontWeight.Light};
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #343434;
  margin: 5.5rem 0 7rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin: 15px 0 0;
  }

  ${Media.tablet`
    flex-direction: row;

    img {
      flex-shrink: 0;
      margin: 0 0 0 15px;
    }
  `}
`;
