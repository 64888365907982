import { createSelector } from "reselect";
import { prop, path } from "ramda";

export const selectCommonDomain = prop("common");

export const selectVisibilityList = createSelector(
  selectCommonDomain, prop("visibilityList"),
);

export const selectBrands = createSelector(
  selectCommonDomain, prop("brands"),
);

export const selectVideoForCode = createSelector(
  selectCommonDomain, prop("videoForCode"),
);

export const selectVideoForCodeError = createSelector(
  selectVideoForCode, prop("error"),
);

export const selectVideoForCodeData = createSelector(
  selectVideoForCode, prop("data"),
);

export const selectVideoForCodeTitle = createSelector(
  selectVideoForCode, path(["available", "title"]),
);

export const selectVideoForCodeItems = createSelector(
  selectVideoForCode, path(["available", "items"]),
);

export const selectFavouriteItems = createSelector(
  selectCommonDomain, prop("favouriteItems"),
);

export const selectFavouriteToastStatus = createSelector(
  selectCommonDomain, prop("favouriteToastStatus"),
);

export const selectGlobalErrorVisible = createSelector(
  selectCommonDomain, prop("globalErrorVisible"),
);

export const selectSearchPopupActive = createSelector(
  selectCommonDomain, prop("searchPopupActive"),
);

export const selectTutorialCurrentStep = createSelector(
  selectCommonDomain, prop("tutorialCurrentStep"),
);

export const selectUnreadNotifications = createSelector(
  selectCommonDomain, prop("unreadNotifications"),
);
