import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Separator } from "../../../../theme";
import { AppConfig } from "../../../../appConfig";

import {
  Container,
  Title,
  File,
  Filename,
  DownloadIcon,
} from "./files.styled";

export const Files = ({ className, files = [] }) => {
  const { t } = useTranslation();

  const renderFiles = () => files.map((file) => (
    <File
      key={file.id}
      href={`${AppConfig.api}${file.url}`}
      rel="noopener noreferrer"
      target="_blank"
    >
      <DownloadIcon />
      <Filename>{file.label}</Filename>
    </File>
  ));

  if (!files.length) {
    return null;
  }

  return (
    <>
      <Container className={className}>
        <Title>{t("webinars.filesToDownload")}</Title>
        {renderFiles()}
      </Container>
      <Separator $narrow />
    </>
  );
};

Files.propTypes = {
  className: PropTypes.string,
  files: PropTypes.array,
};
