import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../helpers/rendering";
import { hexToRgba } from "../../helpers/colors";
import { ReactComponent as ArrowSvg } from "../../images/arrow.svg";
import { ReactComponent as CoinSvg } from "../../images/coins.svg";
import { ReactComponent as UmbrellaSvg } from "../../images/umbrella.svg";
import {
  Color,
  FontWeight,
  Media,
  Separator as SeparatorBase,
} from "../../theme";

export const Container = styled.div`
  background-color: ${Color.white};
  box-shadow: 0px 5px 10px ${hexToRgba(Color.deepGray, 0.01)};
`;

export const Header = styled.button`
  background-color: ${Color.transparent};
  padding: 25px 25px 25px 20px;
  border: 0;

  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;

  ${Media.tablet`
    padding: 45px 40px 45px 35px;
  `}
`;

export const TextWrapper = styled.div``;

export const Title = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${Color.deepGray};
  font-weight: ${FontWeight.SemiBold};
`;

export const Duration = styled.p`
  font-size: 13px;
  line-height: 17px;
  color: ${Color.gray9};
  margin-top: 4px;
`;

export const ArrowWrapper = styled.div`
  transform: rotate(-90deg);
  margin-left: auto;
`;

export const ArrowIcon = styled(ArrowSvg)`
  fill: ${Color.gray9};
  width: 9px;
  height: 15px;

  transition: transform 0.3s ease-in-out;

  ${styleWhenTrue(prop("open"), css`
    transform: rotateY(180deg);
  `)}
`;

export const Content = styled.div`
  height: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  overflow: hidden;

  ${styleWhenTrue(prop("open"), css`
    height: auto;
    opacity: 1;
    padding: 0 20px 25px;

    ${Media.tablet`
      padding: 0 40px 50px;
    `}

    ${Media.desktop`
      padding: 0 115px 50px;
    `}
  `)}
`;

export const DatesHeader = styled.p`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.6px;
  font-weight: ${FontWeight.Bold};
  text-transform: uppercase;
`;

export const Separator = styled(SeparatorBase)`
  && {
    margin: 20px 0;
  }
`;

export const IconStyles = css`
  margin-right: 30px;
`;

export const CoinIcon = styled(CoinSvg)`
  ${IconStyles}
`;

export const UmbrellaIcon = styled(UmbrellaSvg)`
  ${IconStyles}
`;
