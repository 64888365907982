import styled from "styled-components";

import { hexToRgba } from "../../../../helpers/colors";
import { Color } from "../../../../theme/colors";
import { FontWeight } from "../../../../theme/fonts";

import { Button } from "../../../../components/button/button.component";

export const EmptyStateContainer = styled.div`
  background-color: ${Color.white};
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 10px 0 ${hexToRgba(Color.deepGray, 0.01)};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 77px 60px;
  border-bottom: 1px solid ${Color.gray5};
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${Color.deepGray};
  text-align: center;
`;

export const HeaderText = styled(Text)`
  font-weight: ${FontWeight.SemiBold};
  margin: 25px 0 1px;
`;

export const ButtonWrapper = styled.div`
  padding: 30px 0;
  display: flex;
  justify-content: center;
`;

export const BackButton = styled(Button)``;
