import { Switch } from "@mui/material";
import { prop } from "ramda";
import styled, { css } from "styled-components";

import { styleIfElse, styleWhenTrue } from "../../helpers/rendering";
import { Color } from "../../theme";

export const Container = styled(Switch).attrs(() => ({
  classes: {
    root: "root",
    switchBase: "switchBase",
    thumb: "thumb",
    track: "track",
    checked: "checked",
  },
  disableRipple: true,
}))`
  &.root {
    width: 48px;
    height: 26px;
    padding: 0;
  }

  .switchBase {
    padding: 4px;
    color: ${Color.gray6};

    &.checked {
      transform: translateX(22px);
      color: ${Color.white};

      ${styleWhenTrue(prop("disabled"), css`
        // TODO: To remove !important change styling of this component please, styled.attrs is old approach
        color: ${Color.white} !important;
      `)}
      
      & + .track {
        background-color: ${styleIfElse(prop("disabled"), Color.gray5, Color.gray6)};
        opacity: 1;
        border: 2px solid ${styleIfElse(prop("disabled"), Color.gray5, Color.gray6)};
      }
    }
  }

  .thumb {
    width: 18px;
    height: 18px;
    box-shadow: none;
  }

  .track {
    border-radius: 13px;
    border: 2px solid ${Color.gray6};
    background-color: ${Color.white};
    opacity: 1;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }
`;
