import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { SectionHeader } from "../../../../../theme";
import { GamificationActions } from "../../../redux/gamification.reducer";
import { selectRanking, selectUserRank } from "../../../redux/gamification.selectors";
import { RankingItem } from "../rankingItem/rankingItem.component";

import { Container } from "./ranking.styled";

export const Ranking = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const rankingList = useSelector(selectRanking);
  const userRank = useSelector(selectUserRank);
  const isCurrentUserOnThePage = !!rankingList.find((item) => item.user.isCurrentUser);

  useEffect(() => {
    dispatch(GamificationActions.fetchRanking());
  }, [dispatch]);

  return (
    <Container className={className}>
      <SectionHeader>
        {t("gamification.ranking.ranking")}
      </SectionHeader>
      {rankingList.map((item) => (
        <RankingItem
          key={item.user.id}
          isCurrentUser={item.user.isCurrentUser}
          position={item.rank}
          userName={`${item.user.name} ${item.user.surname}`}
          coins={item.points}
        />
      ))}
      {!isCurrentUserOnThePage && !!userRank && (
        <RankingItem
          key={userRank.user.id}
          isCurrentUser
          position={userRank.rank}
          userName={`${userRank.user.name} ${userRank.user.surname}`}
          coins={userRank.points}
        />
      )}
    </Container>
  );
};

Ranking.propTypes = {
  className: PropTypes.string,
};
