import { all, put, takeLatest } from "redux-saga/effects";

import { store } from "../../../store";
import * as formApi from "../services/api";
import { currentQuizAnswers } from "../form.utils";

import { FormTypes, FormActions } from "./form.reducer";

function* getData({ id }) {
  yield put(FormActions.setIsLoading(true));
  const { data } = yield formApi.getData(id);
  yield put(FormActions.getDataSuccess(data));
  yield put(FormActions.setIsLoading(false));
}

function* saveData() {
  yield put(FormActions.setIsLoading(true));

  const id = store.getState().form.form.id;
  const answers = currentQuizAnswers(store.getState().form);
  yield formApi.saveData(id, answers);
  yield put(FormActions.setNextPage());
  yield put(FormActions.setIsLoading(false));
  yield put(FormActions.finishForm());
}

function* finishForm() {
  yield put(FormActions.setIsLoading(true));

  const id = store.getState().form.form.id;
  const nav = store.getState().form.nav;
  const hasNextPage = nav.currentPage !== store.getState().form.form.pages.length;

  if (!hasNextPage) {
    const { data } = yield formApi.finishForm(id);
    yield put(FormActions.getDataSuccess(data));
  }

  yield put(FormActions.setIsLoading(false));
}

export function* watchForm() {
  yield all([
    yield takeLatest(FormTypes.GET_DATA, getData),
    yield takeLatest(FormTypes.SAVE_DATA, saveData),
    yield takeLatest(FormTypes.FINISH_FORM, finishForm),
  ]);
}
