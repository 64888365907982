import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { Loader } from "../../../../components/loader/loader.component";
import { ButtonVariant } from "../../../../components/button/button.constants";
import { getFileSize } from "../../../../helpers/getFileSize";
import { Separator } from "../../../../theme/typography";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { MarkdownRenderers } from "../entry/entry.constants";
import { ArticleImage } from "../articleImage/articleImage.component";
import {
  ArticleTitle,
  AttachmentsWrapper,
  SectionHeader,
  AttachmentLink,
  AttachmentName,
  AttachmentList,
  AttachmentText,
  AttachmentSize,
} from "../entry/entry.styled";

import {
  Container,
  Actions,
  Button,
  Content,
  Wrapper,
  EntryWrapper,
} from "./entryPreview.styled";

export const EntryPreview = ({ className, entry, onClose, onSave }) => {
  const { t } = useTranslation();
  const [isSending, setIsSending] = useState(false);

  const renderImage = renderWhenTrue(() => {
    const src = typeof entry.image === "string" ? entry.image : URL.createObjectURL(entry.image);
    return (
      <ArticleImage
        src={src}
        alt="Image preview"
        positionX={entry.imagePositionX}
        positionY={entry.imagePositionY}
      />
    );
  });

  const handleButtonClick = () => {
    setIsSending(true);
    onSave();
  };

  const renderAttachments = renderWhenTrue(() => (
    <AttachmentsWrapper>
      <SectionHeader>
        {t("news.newsFeed.attachments")}
      </SectionHeader>
      <AttachmentList>
        {entry.attachments.map((file) => (
          <div key={`${file.name}${file.lastModified}${file.size}`}>
            <AttachmentLink disabled>
              <AttachmentText>
                <AttachmentName>{file.name}</AttachmentName>
                <AttachmentSize>{getFileSize(file.size)}</AttachmentSize>
              </AttachmentText>
            </AttachmentLink>
          </div>
        ))}
      </AttachmentList>
    </AttachmentsWrapper>
  ));

  return (
    <Container className={className}>
      <Content>
        <EntryWrapper>
          {renderImage(!!entry.image)}
          <Wrapper>
            <ArticleTitle>{entry.title}</ArticleTitle>
            <ReactMarkdown components={MarkdownRenderers} remarkPlugins={[remarkGfm]}>
              {entry.content}
            </ReactMarkdown>
          </Wrapper>
        </EntryWrapper>
        {renderAttachments(entry.attachments && !!entry.attachments.length)}
      </Content>
      <Separator $narrow />
      <Actions>
        <Button
          onClick={onClose}
          variant={ButtonVariant.Outlined}
        >
          {t("news.entryPreview.cancel")}
        </Button>
        <Button onClick={handleButtonClick} disabled={isSending}>
          {isSending ? <Loader size={22} /> : t("news.entryPreview.save")}
        </Button>
      </Actions>
    </Container>
  );
};


EntryPreview.propTypes = {
  className: PropTypes.string,
  entry: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    attachments: PropTypes.array,
  }).isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};
