import styled, { css } from "styled-components";
import { propEq } from "ramda";

import { hexToRgba } from "../../../../helpers/colors";
import { styleIfElse } from "../../../../helpers/rendering";
import { ReactComponent as InfotipSvg } from "../../../../images/infoTip2.svg";
import { Color, FontWeight } from "../../../../theme";

export const InfotipText = styled.div`
  display: none;
  position: absolute;
  width: 130px;
  ${styleIfElse(propEq("right", "position"), css`left: 25px;`, css`right: 25px;`)}
  top: 2px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 16px;
  color: ${Color.deepGray};
  font-weight: ${FontWeight.Light};
  padding: 10px;
  border: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  background-color: ${Color.white};
`;

export const InfotipWrapper = styled.div`
  position: relative;
  margin-left: 7px;
  &:hover {
    ${InfotipText} {
      display: block;
    }
  }
`;

export const InfotipIcon = styled(InfotipSvg)``;
