import React, { useEffect } from "react";
import i18n from "i18next";

import { ClearfixContainer } from "../../UIElements";

import { QuestionButtons } from "../question/question.styled";
import Question from "../question/question.component";
import { Button } from "../quiz.styled";

import { QuizStatus, QuizSummary } from "./summary.styled";

const Summary = ({ quiz, actions = false }) => {
  const questions = quiz ? (quiz.children.questions ? quiz.children.questions : quiz.children) : [];
  const passed = quiz.passed ?? quiz.params.find((param) => param.name === "passed")?.value;
  const result = quiz.result ?? quiz.params.find((param) => param.name === "result")?.value;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ClearfixContainer>
      {passed === true && <QuizStatus>{ i18n.t("form.form.congratulationsTestPassed") }</QuizStatus>}
      {passed === false && <QuizStatus>{ i18n.t("form.form.testNotPassed") }</QuizStatus>}
      {result !== null && <QuizSummary>{ i18n.t("form.form.answeredCorrectlyOn") } {(result * 100).toFixed()}% { i18n.t("form.form.questions") }</QuizSummary>}
      {result === null && <QuizSummary>{ i18n.t("forms.formAndTest.results") }:</QuizSummary>}
      {questions.map(question => <Question question={question} preview={true} key={question.children[0].value}/>)}
      {actions && (
        <QuestionButtons>
          {actions.map((action, index) => (
            <Button key={index} onClick={action.action}>
              {action.label}
            </Button>
          ))}
        </QuestionButtons>
      )}
    </ClearfixContainer>
  );
};

export {
  Summary,
};
