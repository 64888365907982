import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { cond, equals, always } from "ramda";
import { useNavigate } from "react-router";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";

import { renderWhenTrue, renderWhenTrueOtherwise } from "../../../../../helpers/rendering";
import { Separator } from "../../../../../theme/typography";
import { AppConfig } from "../../../../../appConfig";
import { UserRoleName } from "../../../../../userContext/userContext.constants";
import { BoardStage } from "../../boardStage/boardStage.component";

import { MedalType, ContentType, NextLessonHash } from "./stage.constants";
import {
  Container,
  TitleWrapper,
  Title,
  Description,
  Lesson,
  LessonText,
  Name,
  ExpandWrapper,
  ArrowWrapper,
  ArrowImg,
  PassedText,
  LessonsProgress,
  CheckImg,
  CheckImgBig,
  Thumbnail,
  PDFBadge,
  Badges,
  GoldMedal,
  SilverMedal,
  BronzeMedal,
  StateText,
  EyeImg,
  EyeImgWrapper,
  LockImg,
  LockImgWrapper,
  LessonSwitchLockImg,
  LessonContentWrapper,
  LessonSwitchWrapper,
  SelectedLessonRow,
  GoToLessonButton,
  SwitchButton,
  BackIcon,
  NextIcon,
  ButtonsRow,
  SelectedLessonTitle,
  TabletRow,
} from "./stage.styled";

export const Stage = (props) => {
  const {
    className,
    title,
    description,
    lessons,
    lessonsPassed,
    isMapView,
    disabled,
    shouldShowNextLesson,
    hasExpandedStageId,
    stageIndex,
    stage,
    coords,
    isIntro,
    children,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const shouldBeExpanded = shouldShowNextLesson || hasExpandedStageId;
  const [expandVisible, setExpandVisible] = useState(shouldBeExpanded);
  const [selectedLessonIndex, setSelectedLessonIndex] = useState(
    stage?.isEnabled && !stage.passed
      ? stage.itemGroup["1"].items.findIndex((item) => item.isEnabled && !item.passed)
      : 0
  );

  useEffect(() => {
    if (shouldBeExpanded) {
      setExpandVisible(true);
    }
  }, [shouldBeExpanded]);

  const toggleExpand = useCallback(() => {
    if (!disabled) {
      setExpandVisible(!expandVisible);
    }
  }, [expandVisible, disabled]);

  const handleLessonRedirect = useCallback((id) => {
    const contentType = lessons.find((lesson) => lesson.id === id).contentType;

    if (contentType === ContentType.Lesson || contentType === ContentType.ScrollLesson) {
      navigate(`/lekcje/${id}`, {
        state: { from: UserRoleName.Onboarding },
      });
    } else if (contentType === ContentType.Video) {
      navigate(`/wideo/${id}`, {
        state: { from: UserRoleName.Onboarding },
      });
    }
  }, [navigate, lessons]);

  const renderPDFBadge = renderWhenTrue(() => (
    <PDFBadge>{t("components.stages.pdf")}</PDFBadge>
  ));

  const renderPassedBadge = renderWhenTrue(() => <CheckImgBig />);

  const renderMedalBadge = useCallback(cond([
    [equals(MedalType.Gold), always(<GoldMedal />)],
    [equals(MedalType.Silver), always(<SilverMedal />)],
    [equals(MedalType.Bronze), always(<BronzeMedal />)],
  ]), []);

  const renderInProgressBadge = renderWhenTrue(() => (
    <>
      <StateText>{t("components.stages.inProgress")}</StateText>
      <EyeImgWrapper>
        <EyeImg />
      </EyeImgWrapper>
    </>
  ));

  const renderLockedBadge = renderWhenTrue(() => (
    <>
      <StateText>{t("components.stages.locked")}</StateText>
      <LockImgWrapper>
        <LockImg />
      </LockImgWrapper>
    </>
  ));

  const renderLessonSelector = useCallback(() => {
    const isDisabled = !lessons[selectedLessonIndex].isEnabled;
    return (
      <LessonSwitchWrapper>
        <BoardStage
          stageIndex={stageIndex}
          stage={stage}
          coords={coords}
          selectedIndex={selectedLessonIndex}
        />
        <SelectedLessonRow>
          {!!lessons[selectedLessonIndex].progress && !lessons[selectedLessonIndex].passed && !isDisabled && (
            <EyeImgWrapper small>
              <EyeImg />
            </EyeImgWrapper>
          )}
          {isDisabled && <LessonSwitchLockImg />}
          {renderPassedBadge(lessons[selectedLessonIndex].passed && !isDisabled)}
          <SelectedLessonTitle greyed={isDisabled}>
            {lessons[selectedLessonIndex].name}
          </SelectedLessonTitle>
        </SelectedLessonRow>
        <TabletRow>
          <GoToLessonButton
            onClick={() => handleLessonRedirect(lessons[selectedLessonIndex].id)}
            disabled={isDisabled}
          >
            {t(`components.stages.${isDisabled ? "materialNotAvailable" : "goToMaterial"}`)}
          </GoToLessonButton>
          <ButtonsRow>
            <SwitchButton
              disabled={selectedLessonIndex === 0}
              onClick={() => setSelectedLessonIndex(selectedLessonIndex - 1)}
            >
              <BackIcon/>
              {t("components.stages.previous")}
            </SwitchButton>
            <SwitchButton
              disabled={selectedLessonIndex === lessons.length - 1}
              onClick={() => setSelectedLessonIndex(selectedLessonIndex + 1)}
            >
              {t("components.stages.next")}
              <NextIcon />
            </SwitchButton>
          </ButtonsRow>
        </TabletRow>
      </LessonSwitchWrapper>
    )
  }, [lessons, selectedLessonIndex]);

  const renderLessons = useCallback(() => (
    lessons.map((lesson) => {
      const shouldScrollToNextLesson = window.location.hash === NextLessonHash;
      const isScrollActive = shouldScrollToNextLesson && lesson.isEnabled && !lesson.passed;

      return (
        <ScrollIntoViewIfNeeded
          active={isScrollActive}
          key={lesson.id}
        >
          <Lesson
            onClick={() => handleLessonRedirect(lesson.id)}
            disabled={!lesson.isEnabled}
          >
            <Thumbnail
              src={`${AppConfig.content}${lesson.image}`}
              locked={!lesson.isEnabled}
            />
            <LessonContentWrapper>
              <LessonText locked={!lesson.isEnabled}>
                <Name>{lesson.name}</Name>
              </LessonText>
              <Badges>
                {renderInProgressBadge(!!lesson.progress && !lesson.passed && lesson.isEnabled)}
                {renderLockedBadge(!lesson.isEnabled)}
                {renderPDFBadge(lesson.pdf && lesson.isEnabled)}
                {renderPassedBadge(lesson.passed && lesson.isEnabled)}
                {renderMedalBadge(lesson.medal && lesson.isEnabled)}
              </Badges>
            </LessonContentWrapper>
          </Lesson>
          <Separator $narrow />
        </ScrollIntoViewIfNeeded>
      );
    })
  ), [
    lessons,
    renderMedalBadge,
    renderPassedBadge,
    renderPDFBadge,
    renderInProgressBadge,
    renderLockedBadge,
    handleLessonRedirect,
    t,
  ]);

  const renderExpand = renderWhenTrue(() => {
    if (isMapView && isIntro) return children;
    if (isMapView) return renderLessonSelector();

    return (
      <ExpandWrapper>
        <Separator $narrow />
        <Description>{description}</Description>
        {renderLessons()}
      </ExpandWrapper>
    );
  });

  const renderStagePassed = () => (
    <>
      <PassedText>{t("components.stages.passed")}</PassedText>
      <CheckImg />
    </>
  );

  const renderStageLessonsProgress = () => (
    <LessonsProgress>{lessonsPassed}/{lessons.length}</LessonsProgress>
  );

  const renderStageProgress = renderWhenTrueOtherwise(
    renderStagePassed,
    renderStageLessonsProgress,
  );

  return (
    <Container className={className} disabled={disabled}>
      <TitleWrapper
        onClick={toggleExpand}
        disabled={disabled}
        isMapView={isMapView}
      >
        <Title>{title}</Title>
        <ArrowWrapper withLeftMargin={isMapView}>
          <ArrowImg expanded={expandVisible.toString()} />
        </ArrowWrapper>
        {!isMapView && renderStageProgress(lessonsPassed === lessons.length)}
      </TitleWrapper>
      {renderExpand(expandVisible)}
    </Container>
  );
};

Stage.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  lessons: PropTypes.array,
  lessonsPassed: PropTypes.number,
  isIntro: PropTypes.bool,
};
