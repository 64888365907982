import styled from "styled-components";

import { Color, FontWeight, Media } from "../../../../../theme";
import { hexToRgba } from "../../../../../helpers/colors";
import { ReactComponent as AddIconBase } from "../../../../../images/add.svg";

export const IconWrapper = styled.div`
  margin: 0 auto 20px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Color.white};
`;

export const AddIcon = styled(AddIconBase)`
  width: 34px;
  transition: all .3s ease;
`;

export const Container = styled.button`
  width: calc(100% - 40px);
  height: 270px;
  border: 1px solid ${Color.gray5};
  background-color: ${Color.transparent};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all .3s ease;
  margin: 0 20px;

  &:hover {
    background-color: ${hexToRgba(Color.black, 0.02)};

    ${AddIcon} {
      transform: rotate(-90deg);
    }
  }

  ${Media.tablet`
    height: auto;
    min-height: 550px;
    width: 100%;
    margin: 0;
  `}
`;

export const Title = styled.h1`
  color: ${Color.deepGray};
  font-weight: ${FontWeight.Bold};
  font-size: 16px;
  margin-bottom: 5px;
`;

export const Text = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: ${Color.gray9};
  width: 200px;
  text-align: center;
`;
