import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation, } from "react-i18next";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { remove } from "ramda";
import fileDialog from "file-dialog";

import { EntryPreview } from "../../modules/news/components/entryPreview/entryPreview.component";
import { renderWhenTrue, renderWhenTrueOtherwise } from "../../helpers/rendering";
import { ReactComponent as AttachmentIcon } from "../../images/attachment.svg";
import { ImageUpload } from "../imageUpload/imageUpload.component";
import { Modal } from "../modal/modal.component";
import { ButtonVariant, ButtonColor } from "../button/button.constants";
import { ContentEditor } from "../contentEditor/contentEditor.component";
import { Input } from "../input/input.component";
import { Chip } from "../chip/chip.component";
import { Separator } from "../../theme/typography";
import { ReactComponent as SearchIcon } from "../../images/search.svg";
import { DropdownInput } from "../dropdownInput/dropdownInput.component";
import { Loader } from "../loader/loader.component";

import {
  FileMaxSize,
  FilesSumMaxSize,
  FileMaxSizeInMB,
  EntryType,
  FilesSumMaxSizeInMB,
} from "./articleForm.constants";
import { ArticleSchema } from "./articleForm.schema";
import {
  Container,
  Fields,
  Actions,
  Button,
  AddButton,
  Icon,
  VisibilityField,
  Attachments,
  AttachmentsLabel,
  AttachmentsList,
  AttachmentsEmpty,
  AttachmentErrorText,
  ErrorAttachmentName,
  ImageUploadWrapper,
  ChallengeField,
  ChallengeText,
  DropdownWrapper,
} from "./articleForm.styled";

export const ArticleForm = ({
  className,
  onSubmit,
  onCancel,
  onEdit,
  onSave,
  values = {},
  inGamification,
  challenges = [],
  challengesLoadingFinished,
}) => {
  const { t } = useTranslation();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [filesOverSize, setFilesOverSize] = useState([]);
  const [isFilesSumOverSize, setIsFilesSumOverSize] = useState(false);
  const initialValues = useMemo(() => ({
    image: values.image || null,
    imagePositionX: values.imagePositionX || 0,
    imagePositionY: values.imagePositionY || 0,
    type: EntryType.Article,
    title: values.title || "",
    content: values.content || "",
    visibility: values.visibility || (inGamification ? [0] : []),
    attachments: values.attachments || [],
    attachmentsToDelete: [],
    challenge: values.challenge || [],
  }), [
    values.image,
    values.imagePositionX,
    values.imagePositionY,
    values.attachments,
    values.visibility,
    values.content,
    values.title,
    values.challenge,
  ]);

  const formik = useFormik({
    validationSchema: ArticleSchema,
    initialValues,
    onSubmit: () => handleSubmit(),
  });

  useEffect(() => {
    const { attachments, image } = formik.values;
    const filesSizesSum = [...attachments, image].reduce((sizesSum, currentFile) => sizesSum + currentFile?.size, 0);
    setIsFilesSumOverSize(filesSizesSum > FilesSumMaxSize);
  }, [formik]);

  const handleSubmitCallback = useCallback(() => {
    setIsPreviewOpen(false);
    formik.setSubmitting(false);
    formik.resetForm();
    onSubmit();
  }, [onSubmit, formik]);

  const handleSubmit = useCallback(() => {
    if (values.id) {
      const article = {
        ...formik.values,
        imageDelete: !formik.values.image,
      };
      onEdit(values.id, article, handleSubmitCallback);
    } else {
      onSave(formik.values, handleSubmitCallback);
    }
  }, [values.id, formik.values, handleSubmitCallback]);

  const handlePreviewSubmit = useCallback(() => {
    handleSubmit(formik.values, formik);
  }, [handleSubmit, formik]);

  const handleCancel = useCallback((event) => {
    event.persist();
    onCancel(event);
  }, [onCancel]);

  const handlePreviewClick = useCallback((event) => {
    event.persist();
    setIsPreviewOpen(true);
  }, []);

  const handlePreviewClose = useCallback(() => {
    setIsPreviewOpen(false);
  }, []);

  const handleImagePositionChange = (positionX, positionY) => {
    formik.setFieldValue("imagePositionX", positionX);
    formik.setFieldValue("imagePositionY", positionY);
  };

  const handleChallengeChange = ({ target }) => {
    const challenge = challenges.find((challenge) => challenge.id === target.value);
    formik.setFieldValue("challenge", challenge);
  };

  const handleAddAttachments = useCallback((attachments, attachmentsOverSize) => {
    formik.setFieldValue("attachments", formik.values.attachments.concat(attachments));
    setFilesOverSize(attachmentsOverSize);
  }, [formik]);

  const handleNewAttachmentClick = () => {
    fileDialog({ multiple: true }).then((files) => {
      const preparedFiles = [...files].filter((file) => file.size <= FileMaxSize);
      const filesOverSize = [...files]
        .filter((file) => file.size > FileMaxSize)
        .map((file) => file.name);
      handleAddAttachments(preparedFiles, filesOverSize);
    });
  };

  const getRemoveAttachmentHandler = useCallback((index) => () => {
    const attachmentId = formik.values.attachments[index].id;
    const updatedAttachments = remove(index, 1, formik.values.attachments);

    if (attachmentId) {
      const updatedRemovedAttachments = [...formik.values.attachmentsToDelete, attachmentId];
      formik.setFieldValue("attachmentsToDelete", updatedRemovedAttachments);
    }

    formik.setFieldValue("attachments", updatedAttachments);
    setFilesOverSize([]);
  }, [formik]);

  const renderAttachmentChip = ({ name }, index) => (
    <Chip
      key={name}
      maxLength={50}
      onRemove={getRemoveAttachmentHandler(index)}
    >
      {name}
    </Chip>
  );

  const renderAttachmentError = renderWhenTrue(() => (
    <>
      <AttachmentErrorText>
        {t("news.addNews.article.attachmentsNotAdded")}
        {filesOverSize.map((filename, index) => {
          const isLast = index === filesOverSize.length - 1;
          return (
            <ErrorAttachmentName key={filename}> {filename}{!isLast && ","}</ErrorAttachmentName>
          );
        })}
      </AttachmentErrorText>
      <AttachmentErrorText>{t("validationMessage.maxSize", { maxSize: FileMaxSizeInMB })}</AttachmentErrorText>
    </>
  ));

  const renderAttachments = () => {
    return (
      <>
        <AddButton variant={ButtonVariant.Outlined} onClick={handleNewAttachmentClick}>
          <Icon size={15}>
            <AttachmentIcon />
          </Icon>
          {t("components.contentEditor.toolbar.attachFiles")}
        </AddButton>
        <Attachments withError={!!filesOverSize.length}>
          <AttachmentsLabel>{t("news.addNews.article.attachmentsLabel")}</AttachmentsLabel>
          {renderWhenTrueOtherwise(
            () => (
              <AttachmentsList>
                {formik.values.attachments.map(renderAttachmentChip)}
              </AttachmentsList>
            ),
            () => <AttachmentsEmpty>{t("news.addNews.article.attachmentsEmptyLabel")}</AttachmentsEmpty>,
          )(!!formik.values.attachments.length)}
        </Attachments>
        {isFilesSumOverSize && !filesOverSize.length && (
          <AttachmentErrorText>
            {t("news.addNews.article.filesSumOverSize", { maxSize: FilesSumMaxSizeInMB })}
          </AttachmentErrorText>
        )}
        {renderAttachmentError(!!filesOverSize.length)}
      </>
    );
  }

  const renderPreview = renderWhenTrue(() => (
    <Modal
      title={t("news.addNews.article.previewTitle")}
      HeaderIcon={SearchIcon}
      open={isPreviewOpen}
      onClose={handlePreviewClose}
    >
      <EntryPreview
        entry={formik.values}
        onClose={handlePreviewClose}
        onSave={handlePreviewSubmit}
      />
    </Modal>
  ));

  return (
    <Container className={className}>
      {renderPreview(!inGamification)}
      <form onSubmit={formik.handleSubmit}>
        <Fields>
          <ImageUploadWrapper>
            <ImageUpload
              name="image"
              onChange={formik.handleChange}
              onPositionChange={handleImagePositionChange}
              value={formik.values.image || ""}
              positionX={formik.values.imagePositionX}
              positionY={formik.values.imagePositionY}
            />
          </ImageUploadWrapper>
          <Input
            name="title"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={formik.handleFocus}
            value={formik.values.title}
            error={formik.touched.title && formik.errors.title}
            placeholder={t("news.addNews.article.titlePlaceholder")}
            autofocus
          />
          <ContentEditor
            name="content"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={formik.handleFocus}
            value={formik.values.content}
            error={formik.errors.content}
            placeholder={t("news.addNews.article.contentPlaceholder")}
            withToolbar
          />
          {renderAttachments()}
          {inGamification ? (
            <ChallengeField>
              <ChallengeText>{t("news.addNews.post.challenge")}</ChallengeText>
              <DropdownWrapper>
                <DropdownInput
                  name="challenge"
                  onChange={handleChallengeChange}
                  value={formik.values.challenge?.name}
                  valueId={formik.values.challenge?.id}
                  placeholder={t("news.addNews.post.choose")}
                  options={challenges}
                  small
                  loadingFinished={challengesLoadingFinished}
                  emptyMessage={t("news.addNews.article.noChallenges")}
                />
              </DropdownWrapper>
            </ChallengeField>
          ) : (
            <>
              <Separator $narrow />
              <VisibilityField
                name="visibility"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onFocus={formik.handleFocus}
                value={formik.values.visibility}
                error={formik.touched.visibility && formik.errors.visibility}
              />
            </>
          )}
        </Fields>
        <Separator $narrow />
        <Actions>
          <Button
            onClick={handleCancel}
            variant={ButtonVariant.Outlined}
          >
            {t("news.addNews.article.cancel")}
          </Button>
          {!inGamification && (
            <Button
              onClick={handlePreviewClick}
              color={ButtonColor.Action}
              disabled={!formik.isValid || isFilesSumOverSize || formik.isSubmitting}
            >
              {t("news.addNews.article.preview")}
            </Button>
          )}
          <Button
            onClick={formik.handleSubmit}
            disabled={!formik.isValid || isFilesSumOverSize || formik.isSubmitting}
          >
            {formik.isSubmitting ? <Loader size={22} /> : t("news.addNews.article.save")}
          </Button>
        </Actions>
      </form>
    </Container>
  );
};

ArticleForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  inGamification: PropTypes.bool,
  challenges: PropTypes.array,
};
