import styled from "styled-components";

import { ReactComponent as MedalSvgBase } from "../../images/medal.svg";
import { ReactComponent as ColumnGraphBase } from "../../images/columnGraph.svg";
import { Color, FontWeight, Media } from "../../theme";

export const Container = styled.div`
  width: 100%;
  background-color: ${Color.white};
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${Media.tablet`
    flex-direction: row;
    padding: 80px 65px;
  `}

  ${Media.desktop`
    flex-direction: column;
  `}

  ${Media.desktopWide`
    flex-direction: row;
  `}
`;

export const ChartWrapper = styled.div`
  width: 110px;
  height: 110px;
  position: relative;

  ${Media.tablet`
    margin-right: 50px;
  `}

  ${Media.desktop`
    margin-right: 0;
  `}

  ${Media.desktopWide`
    margin-right: 50px;
  `}
`;

export const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Color.dirtyWhite};
  border-radius: 50%;
  width: 60px;
  height: 60px;
  z-index: 99;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const GoldMedalSvg = styled(MedalSvgBase)`
  width: 14px;
  height: 25px;
  fill: ${Color.gold};
`;

export const ColumnGraphSvg = styled(ColumnGraphBase)`
  width: 16px;
  height: 20px;
`;

export const MinutesValue = styled.p`
  font-weight: ${FontWeight.Bold};
  font-size: 10px;
  line-height: 21px;
`;

export const Data = styled.div`
  margin-top: 30px;
  width: 100%;

  ${Media.tablet`
    margin-top: 0;
    width: auto;
  `}

  ${Media.desktop`
    margin-top: 30px;
  `}

  ${Media.desktopWide`
    margin-top: 0;
  `}
`;

export const DataItem = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border: 3px solid ${({ color }) => (color === "brand" ? Color.brandBasic : color)};
  border-radius: 50%;
  margin-right: 15px;
`;

export const Value = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: ${Color.deepGray};
  font-weight: ${FontWeight.SemiBold};
  width: 45px;
`;

export const Label = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: ${Color.gray9};
`;

export const NoCupsTitle = styled.p`
  color: ${Color.deepGray};
  font-size: 14px;
  line-height: 21px;
  font-weight: ${FontWeight.Bold};
`;

export const NoCupsSubtitle = styled.p`
  color: ${Color.gray9};
  font-size: 14px;
  line-height: 21px;
  max-width: 230px;
  margin-top: 15px;
`;
