import React, { useState } from "react";

import { Title, Text } from "../../cookiePopup.styled";

import { Container, Header, Switch } from "./detailsItem.styled";

export const DetailsItem = ({
  title,
  text,
  editable,
  initValue,
  onEdit,
}) => {
  const [checked, setChecked] = useState(initValue);

  const handleEdit = (value) => {
    setChecked(value);
    onEdit(value);
  };

  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        {editable && (
          <Switch
            checked={checked}
            onChange={({ target }) => handleEdit(target.checked)}
          />
        )}
      </Header>
      <Text>{text}</Text>
    </Container>
  );
};
