import React, { useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ChartColorType } from "../../../../components/simpleProgressChart/simpleProgressChart.constants";
import { ProgressMark } from "../../../../components/progressMark/progressMark.component";
import { useMediaListener } from "../../../../hooks/useMediaListener";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { selectCompetenceOverview } from "../../redux/competence.selectors";

import {
  Container,
  ScrollableContainer,
  CanvasContainer,
  Header,
  TextWrapper,
  Title,
  Level,
  ScrollableChartWrapper,
} from "./competenceStatisticsChart.styled";
import { createChartInstance } from "./competenceStatisticsChart.helpers";

export const CompetenceStatisticsChart = ({ competenceStatistics }) => {
  const { t } = useTranslation();
  const chartContainer = useRef(null);
  const scrollableContainerRef = useRef(null);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [chartInstance, setChartInstance] = useState(null);
  const competenceOverview = useSelector(selectCompetenceOverview);
  const competence = competenceOverview && competenceOverview.competence;
  const rate = competenceOverview && competenceOverview.rate;
  const isMobile = useMediaListener();

  const xAxisLabels = useMemo(() => competenceStatistics.xValues.map((element) => element.name),
    [competenceStatistics]);

  const xAxisValues = useMemo(() => competenceStatistics.xValues.map((element) => element.value),
    [competenceStatistics]);

  const yAxisValues = useMemo(() => competenceStatistics.yValues,
    [competenceStatistics]);

  useEffect(() => {
    scrollChartRight();
  }, [chartInstance]);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = createChartInstance(
        yAxisValues,
        xAxisLabels,
        xAxisValues,
        chartContainer.current,
        isFirstRender,
      );
      setChartInstance(newChartInstance);
      setIsFirstRender(false);
    }
  }, [chartContainer, xAxisLabels, yAxisValues, xAxisValues, isMobile, isFirstRender]);

  const scrollChartRight = () => {
    const container = scrollableContainerRef.current;
    if (container) {
      container.scrollLeft = container.scrollWidth - container.clientWidth;
    }
  };

  const renderProgressMark = () => {
    if (!rate) return <ProgressMark />;
    return (
      <ProgressMark
        rate={rate.rate}
        maxRate={rate.maxRate}
        variant={ChartColorType.Gold}
      />
    );
  };

  const renderLevelText = renderWhenTrue(() => {
    if (rate) {
      return `${t("competence.level")} ${t(rate.name)}`;
    }
    return t("competence.ratingInProgress");
  });

  return (
    <Container>
      <Header>
        {renderProgressMark()}
        <TextWrapper>
          <Title>{competence.name}</Title>
          <Level>{renderLevelText(!!rate)}</Level>
        </TextWrapper>
      </Header>
      <ScrollableChartWrapper>
        <ScrollableContainer ref={scrollableContainerRef}>
          <CanvasContainer>
            <canvas ref={chartContainer} />
          </CanvasContainer>
        </ScrollableContainer>
        <canvas id="axis-Test" height="300" width="0"/>
      </ScrollableChartWrapper>
    </Container>
  );
};
