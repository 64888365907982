import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { BackButton } from "../../../../components/backButton/backButton.component";
import { Entry } from "../../../news/components/entry/entry.component";
import { GamificationActions } from "../../redux/gamification.reducer";
import { selectEntryDetails } from "../../redux/gamification.selectors";

import {
  Container,
  Wrapper,
  BackButtonContainer,
} from "./entryDetails.styled";

export const EntryDetails = ({ className }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const entryDetails = useSelector(selectEntryDetails);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(GamificationActions.getEntryDetails(params.id));

    return () => {
      dispatch(GamificationActions.clearEntryDetails());
    };
  }, [dispatch]);

  if (!entryDetails) {
    return null;
  }

  return (
    <Container className={className}>
      <Wrapper>
        <BackButtonContainer>
          <BackButton defaultPath="/gamification/init" />
        </BackButtonContainer>
        <Entry
          key={entryDetails.id}
          id={entryDetails.id}
          type={entryDetails.type}
          publisher={entryDetails.publisher}
          title={entryDetails.title}
          timestamp={entryDetails.timestamp}
          content={entryDetails.content}
          likeCounter={entryDetails.likeCounter}
          comments={entryDetails.comments}
          image={entryDetails.image}
          imagePositionX={entryDetails.imagePosition?.x}
          imagePositionY={entryDetails.imagePosition?.y}
          attachments={entryDetails.attachments}
          liked={entryDetails.liked}
          challenge={entryDetails.userChallenge}
          commentsVisible
          isGamification
        />
      </Wrapper>
    </Container>
  );
};
