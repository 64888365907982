import styled from "styled-components";

import { Color, ContentMaxWidth, FontWeight, Media } from "../../../../theme";
import { ReactComponent as ClockSVG } from "../../../../images/clock.svg";
import { ReactComponent as DisabledSvg } from "../../../../images/disabled.svg";
import { hexToRgba } from "../../../../helpers/colors";

export const Container = styled.div`
  padding: 40px 20px;
  background-color: ${Color.almostWhite};

  ${Media.tablet`
    padding: 40px 80px;
  `}

  ${Media.desktop`
    padding: 40px 160px;
  `}
`;

export const TableWrapper = styled.div`
  padding: 15px 35px 10px;
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
  background-color: ${Color.white};
  box-shadow: 0 2px 10px ${hexToRgba(Color.gray9, 0.04)};

  ${Media.tablet`
    padding: 0 35px 15px;
  `}
`;

export const Table = styled.table`
  width: 100%;
`;

export const ThumbnailCell = styled.div`
  font-weight: ${FontWeight.Normal};
  color: ${Color.deepGray};
  flex: 1 1 0%;

  > div {
    width: 90%;
    max-width: 200px;
  }

  ${Media.tablet`
    display: flex;
    align-items: center;
    flex: 0 1 auto;

    > div {
      width: 110px;
    }
  `}
`;

export const TableHead = styled.thead`
  color: ${Color.gray9};
  font-weight: ${FontWeight.ExtraBold};
  text-align: left;
  letter-spacing: 0.9px;
  text-transform: uppercase;

  &:after {
    content: "";
    position: absolute;
    height: 2px;
    left: 0;
    right: 0;
    background-color: ${Color.almostWhite};
  }
`;

export const HeaderCell = styled.th`
  padding: 14px 0;
  line-height: 14px;
  vertical-align: middle;

  &:nth-of-type(1) {
    width: 55%;
  }

  &:nth-of-type(2) {
    width: 18%;
    min-width: 100px;
  }

  &:last-of-type {
    width: 15%;
    min-width: 80px;
    text-align: right;
  }
`;

export const HeaderRow = styled.tr``;

export const Cell = styled.td`
  padding: 20px 0;
  vertical-align: middle;
  font-size: 15px;
  line-height: 20px;
  color: ${Color.gray9};

  &:last-of-type {
    text-align: right;
  }

  &:not(:last-of-type) {
    padding-right: 10px;
  }
`;

export const BodyRow = styled.tr`
  &:first-of-type {
    ${Cell} {
      padding-top: 35px;
    }
  }

  &:not(:first-of-type) {
    border-top: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  }
`;

export const ClockIcon = styled(ClockSVG)`
  height: 25px;
  margin-right: 5px;
  vertical-align: middle;
`;

export const Minutes = styled.span`
  color: ${Color.gray6};
  position: relative;
  top: 1px;

  ${Media.tablet`
    color: ${Color.deepGray};
    font-weight: ${FontWeight.SemiBold};
  `}
`;

export const ListItem = styled.div`
  padding: 20px 0;
  display: flex;

  &:not(:first-of-type) {
    border-top: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  }
`;

export const TextWrapper = styled.div`
  flex: 1 1 0%;

  ${Media.tablet`
    flex: 0 1 auto;
  `}
`;

export const MobileCell = styled.div`
  margin-bottom: 15px;
  margin-left: 5px;
  color: ${Color.gray1};
`;

export const CellTitle = styled.p`
  text-transform: uppercase;
  font-weight: ${FontWeight.ExtraBold};
  color: ${Color.gray1};
  margin-bottom: 5px;
`;

export const BoxTitle = styled.h1`
  color: ${Color.gray6};
  font-size: 14px;
  font-weight: ${FontWeight.SemiBold};
`;

export const EmptyHistoryWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${Color.gray9};
  font-size: 14px;
  padding: 20px 0;

  ${Media.tablet`
    padding: 35px 0 20px;
  `}
`;

export const DisabledIcon = styled(DisabledSvg)`
  margin-right: 12px;
`;
