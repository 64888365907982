import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { ProgressWithChildren } from "../progressWithChildren/progressWithChildren.component";
import { useMediaListener } from "../../hooks/useMediaListener";
import { simpleDateFromTimestamp, timestampToDate } from "../../helpers/date";
import { ChallengeDropdownContent } from "../../modules/gamification/components/dashboard/challengeDropdownContent/challengeDropdownContent.component";
import { ChallengeItemContent } from "../../modules/gamification/components/dashboard/challengeItemContent/challengeItemContent.component";

import { ChallengeStatus, ChallengeType, IconType } from "./challengeElement.constants";
import {
  Container,
  HeaderWrapper,
  Header,
  IconWrapper,
  MessageIcon,
  SurveyAndTestIcon,
  VideoIcon,
  TitleAndDateColumn,
  Title,
  HourglassIcon,
  DateText,
  Arrow,
  ProgressChildren,
  QuestionnaireIcon,
} from "./challengeElement.styled";

export const ChallengeElement = ({
  expirationTimestamp,
  availableFrom,
  isNew,
  points,
  title,
  doneTimestamp,
  item,
  earnedPoints,
  percentageScore,
  status,
  type,
  active,
  description,
  className,
}) => {
  const { t } = useTranslation();
  const { isTablet } = useMediaListener();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const duringVerification = status === ChallengeStatus.InVerification;
  const ChallengeIcon = useMemo(() => {
    switch (type.enum) {
    case IconType.Survey:
      return SurveyAndTestIcon;
    case IconType.Content:
      return VideoIcon;
    case IconType.Questionnaire:
      return QuestionnaireIcon;
    default:
      return MessageIcon;
    }
  }, [type.enum]);
  const isRatedQuestionnaire = type.enum === ChallengeType.Questionnaire && status === ChallengeStatus.Rated;
  const isButtonDisabled = status !== ChallengeStatus.Available && !isRatedQuestionnaire;

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const dateText = doneTimestamp
    ? `${t("gamification.challengeElement.started")}: ${timestampToDate(doneTimestamp)}`
    : availableFrom * 1000 > Date.now()
      ? `${t("gamification.challengeElement.activeFrom")}: ${dayjs.unix(availableFrom).format("DD MMMM YYYY, HH:mm")}`
      : `${t("gamification.challengeElement.activeTo")}: ${simpleDateFromTimestamp(expirationTimestamp)}`;

  return (
    <Container className={className}>
      <HeaderWrapper onClick={toggleDropdown}>
        <Header>
          {duringVerification && !isTablet && <HourglassIcon />}
          {!duringVerification && !!doneTimestamp && !!percentageScore && !isTablet && (
            <ProgressWithChildren value={percentageScore} strokeWidth={3}>
              <ProgressChildren>
                {percentageScore}%
              </ProgressChildren>
            </ProgressWithChildren>
          )}
          {((!duringVerification && !percentageScore) || isTablet) && (
            <IconWrapper isNew={isNew && !isTablet}>
              <ChallengeIcon inactive={(!active).toString()} />
            </IconWrapper>
          )}
          <TitleAndDateColumn>
            <Title>{title}</Title>
            <DateText inactive={!active}>
              {dateText}
            </DateText>
          </TitleAndDateColumn>
        </Header>
        {isTablet && (
          <ChallengeItemContent
            isNew={isNew}
            challengeType={type.enum}
            percentageScore={doneTimestamp ? percentageScore : null}
            points={points}
            earnedPoints={earnedPoints}
            duringVerification={duringVerification}
          />
        )}
        <Arrow open={dropdownOpen} />
      </HeaderWrapper>
      {dropdownOpen && (
        <ChallengeDropdownContent
          points={points}
          earnedPoints={earnedPoints}
          type={type.label}
          item={item}
          customButtonText={isRatedQuestionnaire ? t("gamification.challengeElement.details") : undefined}
          description={description}
          isButtonDisabled={isButtonDisabled}
        />
      )}
    </Container>
  );
};

ChallengeElement.propTypes = {
  isNew: PropTypes.bool,
  points: PropTypes.number,
  title: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  doneTimestamp: PropTypes.number,
  status: PropTypes.string,
  item: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string.isRequired,
  }),
  earnedPoints: PropTypes.number,
  percentageScore: PropTypes.number,
  duringVerification: PropTypes.bool,
  type: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  active: PropTypes.bool,
  description: PropTypes.string,
};
