import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { isIE } from "react-device-detect";
import { useParams } from "react-router";

import { Tooltip } from "../../../components/tooltip/tooltip.component";
import { useMediaListener } from "../../../hooks/useMediaListener";
import { DeviceKind, StreamType } from "../services/streams.constants";
import {
  selectParticipants,
  selectCurrentRoom,
  selectRequests,
  selectRequestSentType,
  selectMessages,
  selectJoinToRoomModalData,
  selectRedirectScreenOpen,
  selectSpeakingUser,
  selectPongReceived,
  selectMainStreamUser,
} from "../redux/webinars.selectors";
import { WebinarsActions } from "../redux/webinars.reducer";
import { BrowserModal } from "../components/browserModal/browserModal.component";
import { StreamModal } from "../components/streamModal/streamModal.component";
import { ShareModal } from "../components/shareModal/shareModal.component";
import { WaitingModal } from "../components/waitingModal/waitingModal.component";
import { ClassesVideo } from "../components/classesVideo/classesVideo.component";
import { BroadcastType } from "../components/video/video.constants";
import { UsersList } from "../components/usersLists/usersList.component";
import { RequestName, RoomStatus, RoomType } from "../webinars.constants";
import { initVideoStreams } from "../services/streams";
import { RoomsModal } from "../components/roomsModal/roomsModal.component";
import { ClassesStreamTimer } from "../components/classesStreamTimer/classesStreamTimer.component";
import { MobilePlaceholder } from "../components/mobilePlaceholder/mobilePlaceholder.component";
import { JoinToRoomModal } from "../components/joinToRoomModal/joinToRoomModal.component";
import { RedirectScreen } from "../components/redirectScreen/redirectScreen.component";
import { Flipchart } from "../components/flipchart/flipchart.component";
import { CloseRoomTimer } from "../components/closeRoomTimer/closeRoomTimer.component";
import { ConnectionModal } from "../components/connectionModal/connectionModal.component";

import { RightPanelView, StreamsView } from "./classesWebinars.constants";
import {
  ButtonPanel,
  Container,
  Separator,
  ChatButton,
  UsersButton,
  ColumnsLayoutButton,
  ChatIcon,
  UsersIcon,
  ColumnsLayoutIcon,
  PreviousButton,
  NextButton,
  ArrowIcon,
  PanelLeftSide,
  PanelRightSide,
  LiveInfo,
  Title,
  RecordingTimer,
  StreamsContainer,
  Chat,
  RightDrawer,
  RoomTitle,
  GreyedTitle,
  GridLayoutButton,
  GridLayoutIcon,
} from "./classesWebinars.styled";

export const ClassesWebinars = () => {
  const matchParams = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isTabletWide } = useMediaListener();
  const room = useSelector(selectCurrentRoom);
  const participants = useSelector(selectParticipants);
  const connectedParticipants = participants.filter((participant) => participant.connected);
  const requests = useSelector(selectRequests);
  const requestSentType = useSelector(selectRequestSentType);
  const messages = useSelector(selectMessages);
  const redirectScreenOpen = useSelector(selectRedirectScreenOpen);
  const joinToRoomModalData = useSelector(selectJoinToRoomModalData);
  const speakingUserId = useSelector(selectSpeakingUser);
  const pongReceived = useSelector(selectPongReceived);
  const mainStreamUserId = useSelector(selectMainStreamUser);
  const localCameraVideoRef = useRef(null);
  const localScreenVideoRef = useRef(null);
  const remoteCameraRefs = useRef({});
  const remoteAudioRefs = useRef({});
  const remoteScreenVideoRef = useRef(null);
  const localAudioRef = useRef(null);
  const streamsContainerRef = useRef(null);
  const presenter = participants.find((participant) => participant.role === BroadcastType.Presenter);
  const remoteUsers = participants.filter((participant) => !participant.user.isCurrentUser);
  const currentUser = participants.find((participant) => participant.user.isCurrentUser);
  const isCurrentUserPresenter = presenter && presenter.user.isCurrentUser;
  const [streamsView, setStreamsView] = useState(StreamsView.Default);
  const [currentRightPanelView, setCurrentRightPanelView] = useState(RightPanelView.Streams);
  const [streamModalVisible, setStreamModalVisible] = useState(false);
  const [shareScreenModalVisible, setShareScreenModalVisible] = useState(false);
  const [shareCameraModalVisible, setShareCameraModalVisible] = useState(false);
  const [browserModalVisible, setBrowserModalVisible] = useState(false);
  const [roomsViewOpen, setRoomsViewOpen] = useState(false);
  const [streamsCurrentPage, setStreamsCurrentPage] = useState(0);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [messagesUnreadAtPosition, setMessagesUnreadAtPosition] = useState(0);
  const [isRatioWider, setIsRatioWider] = useState();
  const [connectionModalVisible, setConnectionModalVisible] = useState(false);
  const videoRequests = requests.filter((request) => request.access.name === RequestName.ShareCamera);
  const screenRequests = requests.filter((request) => request.access.name === RequestName.ShareScreen);
  const isScreenStreamON = room.streams.find((stream) => stream.type === StreamType.Screen);
  const isGridView = streamsView === StreamsView.Grid && !isScreenStreamON && !room.flipchartId && room.live;
  const inPrivateRoom = room.type === RoomType.MeetingGroup;

  const columns = useMemo(() => {
    if (!isGridView) return null;

    if (connectedParticipants.length <= 4) return 2;
    else if (connectedParticipants.length <= 6) {
      if (currentRightPanelView) return 2;
      return 3;
    }
    else if (currentRightPanelView) return 3;
    else return 4;
  }, [isGridView, connectedParticipants, currentRightPanelView]);

  const rows = isGridView
    ? currentRightPanelView
      ? connectedParticipants.length > 6 ? 3 : 2
      : connectedParticipants.length > 8 ? 3 : 2
    : null;

  const arrowButtonsEnabled = isGridView && connectedParticipants.length > columns * rows;

  const handleResize = () => {
    const ref = streamsContainerRef?.current;
    if (ref) {
      const ratio = (ref.clientWidth * rows) / (ref.clientHeight * columns);
      setIsRatioWider(ratio >= 16 / 9);
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      setTimeout(() => {
        document.body.style.overflow = "unset";
      }, 1);
    };
  }, []);

  useEffect(() => {
    if (!currentRightPanelView) {
      const maxUsersOnPreviousPages = streamsCurrentPage * columns * rows;
      if (streamsCurrentPage > 0 && connectedParticipants.length <= maxUsersOnPreviousPages) {
        setStreamsCurrentPage(streamsCurrentPage - 1);
      }
    }
  }, [currentRightPanelView, connectedParticipants, streamsCurrentPage]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [connectedParticipants.length, isGridView, currentRightPanelView, streamsCurrentPage]);

  useEffect(() => {
    const maxScreenStreams = columns * rows;
    const newNextButtonDisabled = connectedParticipants.length <= maxScreenStreams * (streamsCurrentPage + 1);

    setNextButtonDisabled(newNextButtonDisabled);
  }, [columns, connectedParticipants, streamsCurrentPage]);

  useEffect(() => {
    if (videoRequests.length) {
      setShareCameraModalVisible(true);
    } else if (screenRequests.length) {
      setShareScreenModalVisible(true);
    }
  }, [videoRequests, screenRequests]);

  useEffect(() => {
    if (!room.live) {
      setCurrentRightPanelView(RightPanelView.Chat);
    } else {
      setCurrentRightPanelView(RightPanelView.Streams);
    }
  }, [room.live]);

  const updateDeviceLists = useCallback((devices) => {
    const videoDevices = devices.filter(
      (device) => device.kind === DeviceKind.Video
    );
    const audioDevices = devices.filter(
      (device) => !!device.groupId && device.kind === DeviceKind.Audio
    );

    dispatch(WebinarsActions.updateDevices(videoDevices, audioDevices));
  }, [dispatch]);

  useEffect(() => {
    remoteCameraRefs.current = remoteUsers.reduce((refs, user) => {
      return {
        ...refs,
        [user.id]: remoteCameraRefs.current[user.id] || null,
      };
    }, {});

    remoteAudioRefs.current = remoteUsers.reduce((refs, user) => {
      return {
        ...refs,
        [user.id]: remoteAudioRefs.current[user.id] || null,
      };
    }, {});

    initVideoStreams(
      localCameraVideoRef,
      localScreenVideoRef,
      remoteCameraRefs,
      remoteScreenVideoRef,
      localAudioRef,
      remoteAudioRefs,
    );
  }, [remoteCameraRefs.current, remoteAudioRefs.current, remoteUsers]);

  useEffect(() => {
    if (isIE) {
      showBrowserModal();
      return;
    }

    if (navigator.mediaDevices) {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        updateDeviceLists(devices);
        const defaultVideoDevice = devices.find(
          (device) => device.kind === DeviceKind.Video
        );
        const defaultAudioDevice = devices.find(
          (device) => !!device.groupId && device.kind === DeviceKind.Audio
        );

        if (defaultVideoDevice) {
          dispatch(WebinarsActions.setCurrentVideo(defaultVideoDevice.deviceId));
        }
        if (defaultAudioDevice) {
          dispatch(WebinarsActions.setCurrentAudio(defaultAudioDevice.deviceId));
        }
      });

      navigator.mediaDevices.addEventListener("devicechange", () => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          updateDeviceLists(devices);
        });
      });
    }
  }, [dispatch, matchParams.id, updateDeviceLists]);

  useEffect(() => {
    const waitingTime = 5000;
    let timeout = null;
    if (pongReceived) {
      timeout = setTimeout(() => dispatch(WebinarsActions.sendPing()), waitingTime);
    } else {
      timeout = setTimeout(() => setConnectionModalVisible(true), waitingTime);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, pongReceived]);

  const toggleStreamsView = (type) => {
    if ((type === StreamsView.Default) && !currentRightPanelView) {
      setCurrentRightPanelView(RightPanelView.Streams);
    } else if (type === StreamsView.Grid) {
      setCurrentRightPanelView(null);
    }
    setStreamsView(type);
  };

  useEffect(() => {
    if (isScreenStreamON || !!room.flipchartId) {
      toggleStreamsView(StreamsView.Default);
    }
  }, [isScreenStreamON, room.flipchartId]);

  const showBrowserModal = () => setBrowserModalVisible(true);

  const handleOpenStreamModal = () => setStreamModalVisible(true);

  const handleCloseStreamModal = () => setStreamModalVisible(false);

  const handleToggleStream = () => {
    if (room.live) {
      dispatch(WebinarsActions.handleEndLive());
    } else {
      dispatch(WebinarsActions.handleStartLive());
      setTimeout(() => {
        togglePanelView(RightPanelView.Chat)
      }, 500);
    }
  };

  useEffect(() => {
    if (messages.length) {
      if (currentRightPanelView === RightPanelView.Chat) {
        setMessagesUnreadAtPosition(messages.length);
      }
    }
  }, [messages.length, currentRightPanelView]);

  const handleShareCameraResponseModal = (accept) => {
    dispatch(
      WebinarsActions.handleShareResponse(
        StreamType.Camera,
        videoRequests[0].participant.id,
        accept
      )
    );
  };

  const handleCloseShareCameraModal = () => {
    setShareCameraModalVisible(false);
  };

  const handleCloseShareScreenModal = () => setShareScreenModalVisible(false);

  const handleShareScreenResponseModal = (accept) => {
    dispatch(
      WebinarsActions.handleShareResponse(
        StreamType.Screen,
        screenRequests[0].participant.id,
        accept
      )
    );
  };

  const togglePanelView = (type) => {
    if (currentRightPanelView === type) {
      if (!isGridView) {
        setCurrentRightPanelView(RightPanelView.Streams);
      } else {
        setCurrentRightPanelView(null);
      }
    } else {
      setCurrentRightPanelView(type);
    }
  };

  const showNextStreams = () => setStreamsCurrentPage(streamsCurrentPage + 1);

  const showPreviousStreams = () => setStreamsCurrentPage(streamsCurrentPage - 1);

  const handleToggleFlipchart = () => {
    if (room.flipchartId) {
      dispatch(WebinarsActions.closeFlipchart());
    } else {
      dispatch(WebinarsActions.openFlipchart());
    }
  };

  const renderStreams = () => {
    const maxStreamsVisible = columns * rows;
    const sliceStart = streamsCurrentPage * maxStreamsVisible - (streamsCurrentPage === 0 ? 0 : 1);
    const sliceEnd = sliceStart + maxStreamsVisible - (streamsCurrentPage === 0 ? 1 : 0);
    const isSomeoneStreamingScreen = !!room.streams.find((stream) => stream.type === StreamType.Screen);
    const streamsToRender = remoteUsers
      .filter((user) => user.connected)
      .map((user) => user.id)
      .slice(sliceStart, sliceEnd);
    const bottomStreamsAmount = (streamsToRender.length +  (streamsCurrentPage === 0 ? 1 : 0)) % columns;
    const shiftNumber = bottomStreamsAmount ? columns - bottomStreamsAmount : 0;

    return (
      <>
        <ClassesVideo
          fullName={currentUser.name}
          videoRef={localCameraVideoRef}
          isStreamingCamera={!!room.streams.find(
            (stream) => stream.participantId === currentUser.id && stream.type === StreamType.Camera
          )}
          isStreamingAudio={!!room.streams.find(
            (stream) => stream.participantId === currentUser.id && stream.type === StreamType.Audio && !stream.muted
          )}
          isPresenter={currentUser.role === BroadcastType.Presenter}
          inGrid={isGridView}
          isSpeaking={currentUser.id === speakingUserId && !isSomeoneStreamingScreen}
          isRightPanelStream={!isGridView}
          setHighest={isRatioWider}
          isHidden={streamsCurrentPage > 0}
          shiftNumber={streamsToRender.length ? 0 : shiftNumber}
          columns={columns}
          isCurrentUser
        >
          <audio ref={localAudioRef} autoPlay />
        </ClassesVideo>
        {remoteUsers.map((user, i) =>(
          <React.Fragment key={i}>
            <ClassesVideo
              fullName={user.name}
              isMainStream={user.id === mainStreamUserId && !isSomeoneStreamingScreen && !room.flipchartId}
              videoRef={el => remoteCameraRefs.current[user.id] = el}
              isStreamingCamera={!!room.streams.find(
                (stream) => stream.participantId === user.id && stream.type === StreamType.Camera
              )}
              isStreamingAudio={!!room.streams.find(
                (stream) => stream.participantId === user.id && stream.type === StreamType.Audio && !stream.muted
              )}
              isPresenter={user.role === BroadcastType.Presenter}
              isRightPanelStream={!isGridView}
              inGrid={isGridView}
              isSpeaking={user.id === speakingUserId && !isSomeoneStreamingScreen}
              setHighest={isRatioWider}
              columns={columns}
              shiftNumber={streamsToRender.slice(-bottomStreamsAmount).includes(user.id) ? shiftNumber : 0}
              wideRightPanel={currentRightPanelView !== RightPanelView.Streams}
              isHidden={!user.connected || (isGridView && !streamsToRender.includes(user.id))}
            >
            </ClassesVideo>
            <audio ref={el => remoteAudioRefs.current[user.id] = el} autoPlay />
          </React.Fragment>
        ))}
      </>
    );
  };

  const renderParticipantScreen = () => (
    <ClassesVideo
      isMainStream
      fullName={currentUser.name}
      videoRef={localScreenVideoRef}
      isStreamingCamera={!!room.streams.find(
        (stream) => stream.participantId === currentUser.id && stream.type === StreamType.Screen
      )}
      isPresenter={currentUser.role === BroadcastType.Presenter}
      inGrid={isGridView}
      isHidden={
        !!room.flipchartId || !room.streams.find(
          (stream) => stream.participantId === currentUser.id && stream.type === StreamType.Screen
        )
      }
    />
  );

  const renderRemoteScreen = () => {
    const participantId = room.streams.find((stream) => stream.type === StreamType.Screen)?.participantId;
    const user = remoteUsers.find((remoteUser) => remoteUser.id === participantId);
    return (
      <ClassesVideo
        isMainStream
        fullName={user?.name || ""}
        videoRef={remoteScreenVideoRef}
        isStreamingCamera={!!room.streams.find(
          (stream) => stream.participantId !== currentUser.id && stream.type === StreamType.Screen
        )}
        isPresenter={user?.role === BroadcastType.Presenter}
        inGrid={isGridView}
        isHidden={
          !!room.flipchartId || !room.streams.find(
            (stream) => stream.participantId !== currentUser.id && stream.type === StreamType.Screen
          )
        }
      />
    );
  };

  const renderUpperPanel = () => (
    <>
      <PanelLeftSide rightPanelOpen={!!currentRightPanelView}>
        {inPrivateRoom ? (
          <>
            <RoomTitle>{t("webinars.room")}</RoomTitle>
            <GreyedTitle>{room.name}</GreyedTitle>
            {!!room.liveEndTime && <CloseRoomTimer timestamp={room.liveEndTime} />}
          </>
        ) : (
          <>
            {isCurrentUserPresenter && (
              <>
                <LiveInfo>{t("webinars.live")}</LiveInfo>
                <Title>{room.name}</Title>
              </>
            )}
            {room.recording && (
              <RecordingTimer withMargin={isGridView && !currentRightPanelView} />
            )}
          </>
        )}
      </PanelLeftSide>
      {room.live && (
        <PanelRightSide
          rightPanelOpen={!!currentRightPanelView}
        >
          <Separator />
          <Tooltip title={t("webinars.classes.chat")}>
            <ChatButton
              onClick={() => togglePanelView(RightPanelView.Chat)}
              unread={room.chatActivate && messages.length > messagesUnreadAtPosition}
            >
              <ChatIcon selected={currentRightPanelView === RightPanelView.Chat} />
            </ChatButton>
          </Tooltip>
          <Tooltip title={t("webinars.classes.participants")}>
            <UsersButton onClick={() => togglePanelView(RightPanelView.Users)}>
              <UsersIcon selected={currentRightPanelView === RightPanelView.Users} />
            </UsersButton>
          </Tooltip>
          <Separator />
          <Tooltip title={t("webinars.classes.galleryView")}>
            <GridLayoutButton
              onClick={() => toggleStreamsView(StreamsView.Grid)}
              disabled={isScreenStreamON || !!room.flipchartId || !room.live}
            >
              <GridLayoutIcon selected={isGridView} />
            </GridLayoutButton>
          </Tooltip>
          <Tooltip title={t("webinars.classes.speakerView")}>
            <ColumnsLayoutButton onClick={() => toggleStreamsView(StreamsView.Default)}>
              <ColumnsLayoutIcon selected={!isGridView} />
            </ColumnsLayoutButton>
          </Tooltip>
        </PanelRightSide>
      )}
    </>
  );

  if (redirectScreenOpen) return <RedirectScreen />;

  return (
    <Container>
      {!isTabletWide && <MobilePlaceholder />}
      {renderUpperPanel()}
      {renderParticipantScreen()}
      {renderRemoteScreen()}
      {!!room.flipchartId && (
        <Flipchart wideRightPanel={currentRightPanelView !== RightPanelView.Streams} />
      )}
      {!room.live && (
        <ClassesStreamTimer
          startTimestamp={room.liveStartTime}
          liveEnded={room.status === RoomStatus.Ended}
        />
      )}
      <StreamsContainer
        gridView={streamsView === StreamsView.Grid}
        columns={columns}
        rows={rows}
        isWide={currentRightPanelView !== RightPanelView.Streams}
        ref={streamsContainerRef}
      >
        {renderStreams()}
      </StreamsContainer>
      {currentRightPanelView === RightPanelView.Chat && (
        <Chat
          handleClose={room.live ? (() => togglePanelView(RightPanelView.Chat)) : undefined}
          fullScreen
          classesMode
          isLive={room.live}
          liveEnded={room.status === RoomStatus.Ended}
        />
      )}
      {currentRightPanelView === RightPanelView.Users && (
        <UsersList
          participants={connectedParticipants}
          handleClose={() => togglePanelView(RightPanelView.Users)}
        />
      )}
      {arrowButtonsEnabled && (
        <>
          <PreviousButton
            disabled={streamsCurrentPage === 0}
            onClick={showPreviousStreams}
          >
            <ArrowIcon />
          </PreviousButton>
          {nextButtonDisabled ? (
            <NextButton
              disabled={nextButtonDisabled}
              onClick={showNextStreams}
            >
              <ArrowIcon />
            </NextButton>
          ) : (
            <Tooltip title={t("webinars.classes.more")}>
              <NextButton
                disabled={nextButtonDisabled}
                onClick={showNextStreams}
              >
                <ArrowIcon />
              </NextButton>
            </Tooltip>
          )}
        </>
      )}
      <ButtonPanel
        onOpenStreamModal={handleOpenStreamModal}
        onRoomsButtonClick={() => setRoomsViewOpen(true)}
        onFlipchartToggle={handleToggleFlipchart}
        classesMode
      />
      <RightDrawer
        open={roomsViewOpen}
        onClose={() => setRoomsViewOpen(false)}
        variant="persistent"
      >
        <RoomsModal handleClose={() => setRoomsViewOpen(false)} />
      </RightDrawer>
      <StreamModal
        onConfirm={handleToggleStream}
        onClose={handleCloseStreamModal}
        open={streamModalVisible}
      />
      <ShareModal
        onConfirm={() => handleShareCameraResponseModal(true)}
        onDecline={() => handleShareCameraResponseModal(false)}
        onClose={handleCloseShareCameraModal}
        open={shareCameraModalVisible}
        type={StreamType.Camera}
      />
      <ShareModal
        onConfirm={() => handleShareScreenResponseModal(true)}
        onDecline={() => handleShareScreenResponseModal(false)}
        onClose={handleCloseShareScreenModal}
        open={shareScreenModalVisible}
        type={StreamType.Screen}
      />
      {!!joinToRoomModalData && (
        <JoinToRoomModal room={joinToRoomModalData} />
      )}
      <BrowserModal open={browserModalVisible} />
      <WaitingModal type={requestSentType} />
      <ConnectionModal open={connectionModalVisible} />
    </Container>
  );
};
