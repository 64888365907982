import styled, { css } from "styled-components";
import { prop, propEq } from "ramda";

import { hexToRgba } from "../../../../helpers/colors";
import { Color, FontWeight, Media } from "../../../../theme";
import { ReactComponent as InfoSvg } from "../../images/info.svg";
import { styleWhenTrue } from "../../../../helpers/rendering";

import { BoardFieldType } from "./boardLegend.constants";

export const Container = styled.div`
  background-color: ${Color.white};
  padding: 30px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${Media.tablet`
    padding: 40px 30px 30px;
  `}

  ${Media.tabletWide`
    flex-direction: row;
    align-items: center;
    padding: 15px 15px 15px 40px;
  `}
`;

export const UpperContent = styled.div`
  margin-bottom: 30px;

  ${Media.tablet`
    display: flex;
    align-items: center;
  `}

  ${Media.tabletWide`
    margin-bottom: 0;
  `}
`;

export const Label = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.deepGray};
  margin-right: 15px;
  margin-bottom: 19px;

  ${Media.tablet`
    margin-bottom: 0;
  `}

  ${Media.desktop`
    margin-right: 30px;
  `}
`;

export const Tip = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 30px 15px 22px;
  background-color: ${hexToRgba(Color.veryLightViolet, 0.7)};
  width: 100%;

  ${Media.tabletWide`
    width: auto;
    max-width: 345px;
    margin-left: auto;
  `}
`;

export const InfoText = styled.p`
  margin-left: 12px;
  font-size: 12px;
  line-height: 20px;
`;

export const InfoSpan = styled.span`
  font-weight: ${FontWeight.Bold};
  color: ${Color.violet};
`;

export const InfoIcon = styled(InfoSvg)`
  flex-shrink: 0;
`;

export const Fields = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const FieldType = styled.div`
  display: flex;
  margin-top: 11px;
  margin-right: 25px;
  margin-bottom: 11px;
  align-items: center;
  width: 85px;

  ${Media.tablet`
    margin-right: 35px;
    width: auto;
  `}

  ${Media.tabletWide`
    margin-right: 20px;
  `}

  ${Media.desktop`
    margin-right: 35px;
  `}
`;

export const FieldIcon = styled.div`
  width: 17px;
  height: 17px;
  border: 2px solid ${Color.white};
  background-color: ${prop("color")};
  flex-shrink: 0;

  ${styleWhenTrue(propEq(BoardFieldType.Test, "type"), css`
    border-radius: 50%;
    border: 1px solid ${prop("color")};
    background-color: ${Color.white};
  `)}
`;

export const FieldLabel = styled.p`
  font-size: 13px;
  line-height: 17px;
  margin-left: 10px;
  white-space: nowrap;
`;
