import { Config as NewsConfig } from "./news/news.config";
import { Config as DashboardConfig } from "./dashboard/dashboard.config";
import { Config as OnboardingConfig } from "./onboarding/onboarding.config";
import { Config as KnowledgeBaseConfig } from "./knowledgeBase/knowledgeBase.config";
import { Config as WebinarsConfig } from "./webinars/webinars.config";
import { Config as VideoPlayerConfig } from "./videoPlayer/videoPlayer.config";
import { Config as NotificationsConfig } from "./notifications/notifications.config";
import { Config as CompetenceConfig } from "./competence/competence.config";
import { Config as SurveysConfig } from "./surveys/surveys.config";
import { Config as TeamIndicatorsConfig } from "./teamIndicators/teamIndicators.config";
import { Config as ProfileConfig } from "./profile/profile.config";
import { Config as GamificationConfig } from "./gamification/gamification.config";
import { Config as CertificatesConfig } from "./certificates/certificates.config";
import { Config as ScrollLessonConfig } from "./scrollLesson/scrollLesson.config";
import { Config as AnonSurveyConfig } from "./anonSurvey/anonSurvey.config";
import { Config as TrainingsConfig } from "./trainings/trainings.config";
import { Config as FormConfig } from "./form/form.config";
import { Config as TrainingConfig } from "./training/training.config";
import { Config as SearchConfig } from "./search/search.config";
import { Config as IddConfig } from "./idd/idd.config";
//<-- IMPORT MODULE CONFIG -->

export const Module = {
  News: NewsConfig,
  Dashboard: DashboardConfig,
  Onboarding: OnboardingConfig,
  KnowledgeBase: KnowledgeBaseConfig,
  Webinars: WebinarsConfig,
  VideoPlayer: VideoPlayerConfig,
  Notifications: NotificationsConfig,
  Competence: CompetenceConfig,
  Surveys: SurveysConfig,
  TeamIndicators: TeamIndicatorsConfig,
  Profile: ProfileConfig,
  Gamification: GamificationConfig,
  Certificates: CertificatesConfig,
  ScrollLesson: ScrollLessonConfig,
  AnonSurvey: AnonSurveyConfig,
  Trainings: TrainingsConfig,
  Form: FormConfig,
  Training: TrainingConfig,
  Search: SearchConfig,
  Idd: IddConfig,
  //<-- INJECT MODULE CONFIG -->
};
