import React, { useState, useCallback, useRef, useEffect } from "react";

import { Loader } from "../../../components/loader/loader.component";
import { renderWhenTrueOtherwise } from "../../../helpers/rendering";
import { getData } from "../../helpers/api.deprecated";

import { InteractionType } from "./onboardingInteractions.constants";
import { getBackgroundImage } from "./onboardingInteractions.helpers";
import {
  InteractionIntro,
  InteractionMultiselect,
  InteractionDropdown,
  InteractionTrueOrFalse,
  TextAnswers,
  DownloadTiles,
  InteractionThumbsQuiz,
} from "./components";
import {
  Container,
  HeaderContainer,
  Row,
  LightHeaderText,
} from "./onboardingInteractions.styled";

const OnboardingInteractions = ({ baseUrl, config, callComplete }) => {
  const [loading, setLoading] = useState(true);
  const [activeInteraction, setActiveInteraction] = useState(0);
  const containerEl = useRef(null);
  const [interactions, setInteractions] = useState(null);

  useEffect(() => {
    if (baseUrl && config) {
      getData(`${baseUrl}${config}`, setInteractions, setLoading);
    }
  }, [baseUrl, config]);

  const renderInteraction = (data) => {
    containerEl.current && containerEl.current.scrollIntoView();
    const {
      type,
      title,
      subtitle,
      buttonText,
      answer,
      answers,
      answersIds,
      correctAnswerId,
      question,
      options,
      minAnswersLength,
      tiles,
      showLaterButton,
      fileUrl,
      infotipText,
      correctText,
      incorrectText
    } = data;

    switch (type) {
    case InteractionType.TextAnswers:
      return (
        <TextAnswers
          title={title}
          buttonText={buttonText}
          answers={answers}
          correctAnswerId={correctAnswerId}
          next={onNextButtonClick}
        />
      );
    case InteractionType.Intro:
      return (
        <InteractionIntro
          title={title}
          buttonText={buttonText}
          next={onNextButtonClick}
          showLaterButton={showLaterButton}
          fileUrl={fileUrl}
          infotipText={infotipText}
        />
      );
    case InteractionType.Multiselect:
      return (
        <InteractionMultiselect
          title={title}
          subtitle={subtitle}
          buttonText={buttonText}
          options={options}
          answersIds={answersIds}
          minAnswersLength={minAnswersLength}
          next={onNextButtonClick}
        />
      );
    case InteractionType.TrueOrFalse:
      return (
        <InteractionTrueOrFalse
          buttonText={buttonText}
          question={question}
          answer={answer}
          next={onNextButtonClick}
        />
      );
    case InteractionType.Dropdown:
      return (
        <InteractionDropdown
          title={title}
          buttonText={buttonText}
          infotipText={infotipText}
          options={options}
          answers={answers}
          next={onNextButtonClick}
        />
      );
    case InteractionType.DownloadTiles:
      return (
        <DownloadTiles
          title={title}
          buttonText={buttonText}
          tiles={tiles}
          infotipText={infotipText}
          next={onNextButtonClick}
        />
      );
    case InteractionType.ThumbAnswers:
      return (
        <InteractionThumbsQuiz
          title={title}
          buttonText={buttonText}
          answer={answer}
          correctText={correctText}
          incorrectText={incorrectText}
          next={onNextButtonClick}
        />
      );
    default:
      return <div />;
    }
  };

  const onNextButtonClick = useCallback(() => {
    const lastQuizInteraction = activeInteraction === interactions.length - 1;
    if (lastQuizInteraction) {
      callComplete();
    } else {
      setActiveInteraction(activeInteraction + 1);
    }
  }, [interactions, activeInteraction, callComplete]);

  const renderHeaderActivePart = useCallback(() => {
    return (
      <Row>
        {activeInteraction + 1}
        <LightHeaderText>|{interactions.length}</LightHeaderText>
      </Row>
    );
  }, [interactions, activeInteraction]);

  const interaction = interactions ? interactions[activeInteraction] : null;

  return renderWhenTrueOtherwise(
    () => <Loader />,
    () => (
      <Container
        ref={containerEl}
        $background={getBackgroundImage(interaction.background)}
      >
        <HeaderContainer>
          {interaction.header}
          {renderHeaderActivePart()}
        </HeaderContainer>
        {renderInteraction(interaction)}
      </Container>
    )
  )(loading || !interaction);
};

export default OnboardingInteractions;
