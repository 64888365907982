import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";

import { Loader } from "../../components/loader/loader.component";
import { CommonActions } from "../../redux/common/common.reducer";
import { renderWhenTrueOtherwise } from "../../helpers/rendering";
import { useModuleConfig, ModuleType } from "../";

import { Notification } from "./components/notification/notification.component";
import { NotificationsActions } from "./redux/notifications.reducer";
import {
  selectIsPending,
  selectNotifications,
  selectNextPage,
} from "./redux/notifications.selectors";
import {
  Container,
  Wrapper,
  Header,
  EmptyMessage,
} from "./notifications.styled";

const Notifications = () => {
  const config = useModuleConfig(ModuleType.Notifications);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isPending = useSelector(selectIsPending);
  const notifications = useSelector(selectNotifications);
  const nextPage = useSelector(selectNextPage);

  useEffect(() => {
    dispatch(NotificationsActions.getInitialData());
    dispatch(CommonActions.setNotificationsRead());
  }, [dispatch]);

  const handleNextPage = () => {
    if (nextPage) {
      dispatch(NotificationsActions.loadNextPage(nextPage));
    }
  };

  const renderEmptyState = () => (
    <EmptyMessage>{t("notifications.notifications.notificationsEmpty")}</EmptyMessage>
  );

  const renderNotification = (notification) => (
    <Notification key={notification.id} content={notification} />
  );

  const renderNotificationList = () => (
    <InfiniteScroll
      loadMore={handleNextPage}
      hasMore={!!nextPage}
    >
      {notifications.map(renderNotification)}
    </InfiniteScroll>
  );

  const renderNotifications = renderWhenTrueOtherwise(
    renderNotificationList,
    renderEmptyState,
  );

  const renderContent = renderWhenTrueOtherwise(
    () => renderNotifications(!!notifications.length),
    () => <Loader />,
  );

  return (
    <ThemeProvider theme={config.theme}>
      <Container>
        <Wrapper>
          <Header>{t(config.label)}</Header>
          {renderContent(!isPending)}
        </Wrapper>
      </Container>
    </ThemeProvider>
  );
};

export default Notifications;
