import styled from "styled-components";

import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.div`
  background-color: ${Color.white};
  min-width: 300px;
  height: 180px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  & + & {
    margin-left: 20px;
  }

  ${Media.tablet`
    min-width: 387px;
  `}
`;

export const Category = styled.span`
  font-size: 10px;
  font-weight: ${FontWeight.Bold};
  line-height: 20px;
  color: ${() => Color.brandBasic};
  text-transform: uppercase;
`;

export const Name = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: ${Color.deepGray};
  min-height: 44px;
`;

export const Progress = styled.div`
  position: relative;
`;

export const ProgressWrapper = styled.div`
  margin-top: 18px;
  width: 50px;
  height: 50px;
`;
