import React from "react"

export const renderTextWithNewlines = (text) =>
  text.split(/(\r\n)/).map(
    (text, index) => (
      <span key={index}>
        {text}
        <br/>
      </span>
    )
  );
