import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../helpers/rendering";
import { Media } from "../../theme";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${styleWhenTrue(prop("$isLesson"), css`
    min-height: 220px;

    ${Media.mobile`
      min-height: 350px;
    `}

    ${Media.tablet`
      min-height: 500px;
    `}
  `)}
`;
