import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { ConfirmationModal } from "../../../../components/confirmationModal/confirmationModal.component";
import { Tooltip } from "../../../../components/tooltip/tooltip.component";
import { Avatar } from "../../../../components/avatar/avatar.component";
import { AvatarType } from "../../../../components/avatar/avatar.constants";
import { MicrophoneIcon, MicrophoneOffIcon } from "../../classesWebinars/classesWebinars.styled";
import { WebinarsActions } from "../../redux/webinars.reducer";
import { selectCurrentRoom, selectParticipants } from "../../redux/webinars.selectors";
import { RoomType } from "../../webinars.constants";
import { StreamType } from "../../services/streams.constants";
import { SearchParticipantInput } from "../searchParticipantInput/searchParticipantInput.component";
import { BroadcastType } from "../video/video.constants";

import {
  Container,
  TextWrapper,
  Title,
  Amount,
  List,
  UserRow,
  FullName,
  Indicators,
  CameraIcon,
  CameraOffIcon,
  IconWrapper,
  StarIcon,
  ExcludeButton,
  ExcludeIcon,
  CloseButton,
  CloseIcon,
} from "./usersList.styled";

export const UsersList = ({ handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const room = useSelector(selectCurrentRoom);
  const participants = useSelector(selectParticipants);
  const activeParticipants = useMemo(() => participants.filter((user) => user.connected), [participants]);
  const presenter = activeParticipants.find((participant) => participant.role === BroadcastType.Presenter);
  const isCurrentUserPresenter = presenter?.user.isCurrentUser;
  const [muteModalData, setMuteModalData] = useState();
  const [filteredParticipants, setFilteredParticipants] = useState(activeParticipants);
  const [muteUserModalTitle, setMuteUserModalTitle] = useState("");
  const [muteUserModalText, setMuteUserModalText] = useState("");
  const [muteUserModalConfirmText, setMuteUserModalConfirmText] = useState("");
  const [muteUserModalHeaderIcon, setMuteUserModalHeaderIcon] = useState("");

  useEffect(() => {
    switch (muteModalData?.streamType) {
    case StreamType.Audio:
      setMuteUserModalTitle(t("webinars.usersList.muteUser"));
      setMuteUserModalText(t("webinars.usersList.muteUserQuestion", { name: muteModalData?.participantName }));
      setMuteUserModalConfirmText(t("webinars.usersList.mute"));
      setMuteUserModalHeaderIcon(MicrophoneOffIcon);
      break;
    case StreamType.Camera:
      setMuteUserModalTitle(t("webinars.usersList.turnOffUsersCamera"));
      setMuteUserModalText(t("webinars.usersList.turnOffCameraQuestion", { name: muteModalData?.participantName }));
      setMuteUserModalConfirmText(t("webinars.usersList.turnOff"));
      setMuteUserModalHeaderIcon(CameraOffIcon);
      break;
    default:
      break;
    }
  }, [muteModalData]);

  const kickUser = (roomId, userId) => {
    dispatch(WebinarsActions.kickUser(roomId, userId));
  };

  const openMuteModal = (streamType, participantId, participantName) => setMuteModalData({
    streamType,
    participantId,
    participantName,
  });

  const closeMuteModal = () => setMuteModalData(null);

  const muteParticipantStream = ({ streamType, participantId }) => {
    dispatch(WebinarsActions.muteStream(streamType, participantId));
    closeMuteModal();
  };

  const renderParticipants = useCallback(() => filteredParticipants.map(
    (participant, index) => {
      const { user, role, id, name } = participant;
      const isStreamingCamera = !!room.streams.find(
        (stream) => stream.participantId === id && stream.type === "camera"
      );
      const isStreamingAudio = !!room.streams.find(
        (stream) => stream.participantId === id && stream.type === "audio" && !stream.muted
      );

      return (
        <UserRow key={index}>
          <Avatar
            src={user.avatar}
            name={`${participant.name}`}
            type={AvatarType.Small}
          />
          <FullName>{participant.name}</FullName>
          <Indicators>
            {role === BroadcastType.Presenter && <StarIcon />}
            {isStreamingAudio
              ? isCurrentUserPresenter
                ? (
                  <Tooltip title={t("webinars.usersList.muteUser")}>
                    <IconWrapper onClick={() => openMuteModal(StreamType.Audio, id, name)}>
                      <MicrophoneIcon />
                    </IconWrapper>
                  </Tooltip>
                ) : (
                  <IconWrapper>
                    <MicrophoneIcon />
                  </IconWrapper>
                )
              : (
                <IconWrapper>
                  <MicrophoneOffIcon />
                </IconWrapper>
              )
            }
            {isStreamingCamera
              ? isCurrentUserPresenter
                ? (
                  <Tooltip title={t("webinars.usersList.turnOffUsersCamera")}>
                    <IconWrapper onClick={() => openMuteModal(StreamType.Camera, id, name)}>
                      <CameraIcon />
                    </IconWrapper>
                  </Tooltip>
                ) : (
                  <IconWrapper>
                    <CameraIcon />
                  </IconWrapper>
                )
              : (
                <IconWrapper>
                  <CameraOffIcon />
                </IconWrapper>
              )
            }
            {room.type === RoomType.MeetingGroup && isCurrentUserPresenter && (
              <Tooltip title={t("webinars.usersList.removeUser")}>
                <ExcludeButton
                  disabled={role === BroadcastType.Presenter}
                  onClick={() => kickUser(room.id, user.id)}
                >
                  <ExcludeIcon />
                </ExcludeButton>
              </Tooltip>
            )}
          </Indicators>
        </UserRow>
      )}
  ), [filteredParticipants, room]);

  return (
    <Container>
      <TextWrapper>
        <Title>{t("webinars.participants")}</Title>
        {" "}
        <Amount>({activeParticipants.length})</Amount>
        {!!handleClose && (
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        )}
      </TextWrapper>
      <SearchParticipantInput
        participants={activeParticipants}
        setFilteredParticipants={setFilteredParticipants}
        filteredParticipants={filteredParticipants}
      />
      <List>
        {renderParticipants()}
      </List>
      <ConfirmationModal
        open={!!muteModalData}
        title={muteUserModalTitle}
        onConfirm={() => muteParticipantStream(muteModalData)}
        onDecline={closeMuteModal}
        confirmText={muteUserModalConfirmText}
        HeaderIcon={muteUserModalHeaderIcon}
        declineText={t("global.cancel")}
      >
        {muteUserModalText}
      </ConfirmationModal>
    </Container>
  );
};

UsersList.propTypes = {
  handleClose: PropTypes.func,
};
