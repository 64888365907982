import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { CommonActions } from "../../../../redux/common/common.reducer";
import { renderWhenTrue } from "../../../../helpers/rendering";
import { AnimatedInput } from "../../../animatedInput/animatedInput.component";

import {
  Container,
  ErrorText,
  Button,
  KeyEventWrapper,
} from "./form.styled";

export const Form = ({ className, onSend, error }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [code, setCode] = useState("");

  const handleCodeChange = useCallback((event) => {
    setCode(event.target.value);
  }, [setCode]);

  const handleSend = useCallback(() => {
    if (code) {
      dispatch(CommonActions.unlockVideo(code));
      setCode("");
      onSend();
    }
  }, [dispatch, code, onSend]);

  const handleEnterPress = (key, submit) => {
    if (key === "Enter") {
      submit();
    }
  };

  const renderError = renderWhenTrue(() => (
    <ErrorText>{error}</ErrorText>
  ));

  return (
    <Container className={className}>
      <KeyEventWrapper onKeyDown={({ key }) => handleEnterPress(key, handleSend)}>
        <AnimatedInput
          autofocus
          isError={!!error}
          label={t("components.videoForCode.popup.activationCode")}
          onChange={handleCodeChange}
          value={code}
        />
      </KeyEventWrapper>
      {renderError(!!error)}
      <Button
        onClick={handleSend}
        disabled={!code}
        $large
      >
        {t("components.videoForCode.popup.confirm")}
      </Button>
    </Container>
  );
};

Form.propTypes = {
  className: PropTypes.string,
};
