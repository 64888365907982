import { prop } from "ramda";
import styled, { css } from "styled-components";

import { styleWhenTrue } from "../../../../../../../helpers/rendering";
import { Color, FontWeight, Media } from "../../../../../../../theme";

export const Container = styled.div`
  width: 100%;
  border: 2px solid ${Color.gray5};
  display: flex;
  align-items: center;
  font-size: 15px;
  padding: 20px 30px;
  font-weight: ${FontWeight.Light};

  ${styleWhenTrue(prop("enableValidating"), css`
    border: 2px solid ${Color.green};
    color: ${Color.green};

    ${styleWhenTrue(prop("isWrong"), css`
      border-color: ${Color.red4};
      color: ${Color.red4};
    `)}
  `)}

  ${Media.tablet`
    height: 74px;
    width: 100%;
    padding: 0 30px;
    border-right: none;
  `}
`;

export const Text = styled.span`
  letter-spacing: 0;
  line-height: 26px;

  ${Media.tablet`
    line-height: 22px;
  `}
`;
