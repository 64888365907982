import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Media, Color, FontWeight } from "../../../../../theme";
import { styleWhenTrue } from "../../../../../helpers/rendering";

export const Container = styled.button`
  width: 100%;
  height: 45px;
  background-color: ${Color.blue2};
  font-weight: ${FontWeight.Bold};
  color: ${Color.white};
  border: none;
  text-transform: uppercase;

  &:hover {
    opacity: 0.8;
  }

  ${Media.tablet`
    width: 190px;
  `}

  ${styleWhenTrue(prop("complianceTheme"), css`
    background-color: ${Color.blue4};
    color: ${Color.white};
  `)}

  ${styleWhenTrue(prop("disabled"), css`
    background-color: ${Color.white};
    color: ${Color.blue2};
    border: 1px solid ${Color.gray5};

    &:hover {
      opacity: 1;
      cursor: default;
    }
  `)}
`;
