import React, { useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { useDispatch } from "react-redux";
import remarkGfm from "remark-gfm";

import { getFileSize } from "../../../../helpers/getFileSize";
import { removeUnfinishedMarkdowns } from "../../../../helpers/markdowns";
import { renderWhenTrue, renderWhenTrueOtherwise } from "../../../../helpers/rendering";
import { Modal } from "../../../../components/modal/modal.component";
import { CommentList } from "../../../../components/commentList/commentList.component";
import { ConfirmationModal } from "../../../../components/confirmationModal/confirmationModal.component";
import { EntryType, EntryLength } from "../../../../components/articleForm/articleForm.constants";
import { NewsActions } from "../../redux/news.reducer";
import { ReactComponent as PenIcon } from "../../../../images/pen.svg";
import { ReactComponent as TrashIcon } from "../../../../images/trash.svg";
import DocumentCoverSvg from "../../images/documentCover.svg";
import { EntryHeader } from "../entryHeader/entryHeader.component";
import { Socials } from "../socials/socials.component";
import { EntryEdit } from "../entryEdit/entryEdit.component";
import { ArticleImage } from "../articleImage/articleImage.component";

import {
  MarkdownRenderers,
  ModalType,
  EntryEditTitle,
} from "./entry.constants";
import {
  Container,
  ArticleWrapper,
  Content,
  TextWrapper,
  AttachmentsWrapper,
  SectionHeader,
  AttachmentList,
  AttachmentLink,
  AttachmentCover,
  AttachmentText,
  AttachmentSize,
  AttachmentName,
  ReadMore,
  ChallengeContainer,
  ChallengeItem,
} from "./entry.styled";

export const Entry = (props) => {
  const {
    id,
    type,
    publisher,
    permissions,
    title,
    image,
    imagePositionX = 0,
    imagePositionY = 0,
    timestamp,
    content,
    visibility,
    likeCounter,
    attachments,
    liked,
    comments,
    commentsVisible,
    challenge,
    isGamification,
    className,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [commentListVisible, setCommentListVisible] = useState(commentsVisible);
  const [openModal, setOpenModal] = useState();
  const [fullArticleVisible, setFullArticleVisible] = useState(content.length < EntryLength.ArticleShort);
  const [deletePending, setDeletePending] = useState(false);
  const typeLabel = useMemo(() => {
    if (type === EntryType.Article) {
      return t("news.typeLabel.article");
    }
    return t("news.typeLabel.post");
  }, [type, t]);

  const handleOpenEntryDeleteModal = () => setOpenModal(ModalType.Delete);

  const handleOpenEntryEditModal = () => setOpenModal(ModalType.Edit);

  const handleCloseModal = () => setOpenModal(null);

  const handleToggleComments = () => setCommentListVisible(!commentListVisible);

  const handleLikeClick = useCallback((likeState) => {
    dispatch(NewsActions.likeEntry(id, likeState, isGamification));
  }, [dispatch, id, isGamification]);

  const handleCommentSubmit = useCallback((values, actions) => {
    const comment = { ...values, entryId: id };
    dispatch(NewsActions.saveComment(comment, isGamification, () => {
      actions.setSubmitting(false);
      actions.resetForm();
    }));
  }, [dispatch, id, isGamification]);

  const handleCommentEdit = useCallback((values) => {
    dispatch(NewsActions.editComment(values));
  }, [dispatch]);

  const handleCommentDelete = useCallback((commentId) => {
    dispatch(NewsActions.deleteComment(commentId));
  }, [dispatch]);

  const handleEntryDelete = useCallback(() => {
    setDeletePending(true);
    dispatch(NewsActions.deleteEntry(id));
  }, [dispatch, id]);

  const toggleFullArticleVisibility = useCallback(() => {
    setFullArticleVisible(!fullArticleVisible);
  }, [fullArticleVisible]);

  const renderEditForm = () => (
    <Modal
      title={t(EntryEditTitle[type])}
      HeaderIcon={PenIcon}
      open={openModal === ModalType.Edit}
      onClose={handleCloseModal}
    >
      <EntryEdit
        id={id}
        type={type}
        title={title}
        image={image}
        imagePositionX={imagePositionX}
        imagePositionY={imagePositionY}
        content={content}
        visibility={visibility}
        attachments={attachments}
        onCancel={handleCloseModal}
        onSave={handleCloseModal}
      />
    </Modal>
  );

  const renderChallenge = renderWhenTrue(() => (
    <ChallengeContainer>
      <SectionHeader>{t("news.newsFeed.challenge")}</SectionHeader>
      <ChallengeItem
        expirationTimestamp={challenge.challenge.dateTo}
        isNew={challenge.challenge.isNew}
        points={challenge.challenge.points}
        title={challenge.challenge.label}
        item={challenge.challenge.item}
        type={challenge.challenge.type}
        active={challenge.challenge.active}
        description={challenge.challenge.description}
        status={challenge.status}
        percentageScore={challenge.progress * 100}
        doneTimestamp={challenge.attemptDate}
        earnedPoints={challenge.points}
      />
    </ChallengeContainer>
  ));

  const renderAttachments = renderWhenTrue(() => (
    <AttachmentsWrapper>
      <SectionHeader>{t("news.newsFeed.attachments")}</SectionHeader>
      <AttachmentList>
        {attachments.map((attachment) => {
          return (
            <AttachmentLink
              key={attachment.url}
              href={attachment.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <AttachmentCover imageSrc={attachment.thumbUrl || DocumentCoverSvg} />
              <AttachmentText>
                <AttachmentName>{attachment.name}</AttachmentName>
                <AttachmentSize>{getFileSize(attachment.size)}</AttachmentSize>
              </AttachmentText>
            </AttachmentLink>
          );
        })}
      </AttachmentList>
    </AttachmentsWrapper>
  ));

  const renderPost = renderWhenTrue(() => (
    <Content>
      <EntryHeader
        type={type}
        publisher={publisher}
        permissions={permissions}
        title={title}
        timestamp={timestamp}
        onEditClick={handleOpenEntryEditModal}
        onDeleteClick={handleOpenEntryDeleteModal}
      />
      <TextWrapper>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
      </TextWrapper>
      {renderChallenge(!!challenge)}
    </Content>
  ));

  const renderShortArticle = () => {
    let source = content.slice(0, EntryLength.ArticleShort);
    source = removeUnfinishedMarkdowns(source);

    return (
      <>
        <ReactMarkdown components={MarkdownRenderers} remarkPlugins={[remarkGfm]}>
          {`${source}...`}
        </ReactMarkdown>
        <ReadMore onClick={toggleFullArticleVisibility}>
          {t("news.entry.readMore")}
        </ReadMore>
      </>
    );
  };

  const renderFullArticle = () => (
    <>
      <ReactMarkdown components={MarkdownRenderers} remarkPlugins={[remarkGfm]}>
        {content}
      </ReactMarkdown>
      <ReadMore
        onClick={toggleFullArticleVisibility}
        noDisplay={content.length < EntryLength.ArticleShort}
      >
        {t("news.entry.hide")}
      </ReadMore>
    </>
  );

  const renderArticleContent = renderWhenTrueOtherwise(
    renderFullArticle,
    renderShortArticle,
  );

  const renderArticle = renderWhenTrue(() => (
    <Content>
      <ArticleWrapper>
        {!!image && (
          <ArticleImage
            src={image}
            alt=""
            positionX={imagePositionX}
            positionY={imagePositionY}
          />
        )}
        <EntryHeader
          type={type}
          publisher={publisher}
          permissions={permissions}
          title={title}
          timestamp={timestamp}
          onEditClick={handleOpenEntryEditModal}
          onDeleteClick={handleOpenEntryDeleteModal}
        />
        <TextWrapper>
          {renderArticleContent(fullArticleVisible)}
        </TextWrapper>
      </ArticleWrapper>
      {renderChallenge(!!challenge)}
      {renderAttachments(!!attachments?.length)}
    </Content>
  ));

  if (deletePending) {
    return null;
  }

  return (
    <Container className={className}>
      {renderPost(type === EntryType.Post)}
      {renderArticle(type === EntryType.Article)}
      <Socials
        liked={liked}
        likeCounter={likeCounter}
        commentCounter={comments.length}
        handleToggleComments={handleToggleComments}
        handleLikeClick={handleLikeClick}
      />
      <CommentList
        comments={comments}
        hidden={!commentListVisible}
        onHideClick={handleToggleComments}
        onSubmit={handleCommentSubmit}
        onEdit={handleCommentEdit}
        onDelete={handleCommentDelete}
        hidePermissions={isGamification}
      />
      <ConfirmationModal
        open={openModal === ModalType.Delete}
        title={t("news.entry.deleteModalTitle", { type: typeLabel })}
        confirmText={t("news.entry.deleteModalConfirmText")}
        declineText={t("news.entry.deleteModalDeclineText")}
        HeaderIcon={TrashIcon}
        onClose={handleCloseModal}
        onConfirm={handleEntryDelete}
      >
        {t("news.entry.deleteModalContent", { type: typeLabel })}
      </ConfirmationModal>
      {renderEditForm()}
    </Container>
  );
};


Entry.propTypes = {};
