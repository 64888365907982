import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ButtonColor } from "../../components/button/button.constants";
import { CommonActions } from "../../redux/common/common.reducer";
import { selectMissingAgreements } from "../../userContext/redux/userContext.selectors";

import {
  Container,
  Popup,
  Title,
  Separator,
  ButtonWrapper,
  Button,
  Checkbox,
} from "./agreementsModal.styled";

export const AgreementsModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const missingAgreements = useSelector(selectMissingAgreements);
  const [checkList, setCheckList] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleClick = () => {
    setButtonDisabled(true);
    const agreements = missingAgreements.map((agreement) => ({ name: agreement.type, agree: true }));
    dispatch(CommonActions.updateUserAgreements(agreements));
  };

  const handleCheckboxChange = (event, id) => {
    const newList = [...checkList];

    if (event.target.checked) {
      newList.push(id);
      setCheckList(newList);
    } else {
      setCheckList(newList.filter((agreementId) => agreementId !== id));
    }
  };

  return (
    <Container open>
      <Popup>
        <Title>{t("missingAgreements.acceptToUse")}</Title>
        {missingAgreements.map((agreement) => (
          <Checkbox
            key={agreement.id}
            label={agreement.content}
            onChange={(event) => handleCheckboxChange(event, agreement.id)}
            withLinks
          />
        ))}
        <Separator />
        <ButtonWrapper>
          <Button
            color={ButtonColor.Primary}
            onClick={handleClick}
            disabled={checkList.length !== missingAgreements.length || buttonDisabled}
          >
            {t("missingAgreements.accept")}
          </Button>
        </ButtonWrapper>
      </Popup>
    </Container>
  );
};
