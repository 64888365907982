import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../helpers/rendering";

export const Container = styled.div`
  min-height: 50vh;

  ${styleWhenTrue(prop("isPending"), css`
    display: flex;
    align-items: center;
    justify-content: center;
  `)}
`;
