import { createActions, createReducer } from "reduxsauce";

import { NotificationStatus } from "./common.constants";

export const { Types: CommonTypes, Creators: CommonActions } = createActions(
  {
    fetchVisibility: null,
    fetchVisibilitySuccess: ["visibilityList"],
    fetchBrands: null,
    fetchBrandsSuccess: ["brands"],
    fetchPaidVideos: null,
    fetchPaidVideosSuccess: ["available"],
    unlockVideo: ["code"],
    unlockVideoSuccess: ["available"],
    unlockVideoFailure: ["error"],
    clearUnlockVideoError: null,
    fetchNotifications: null,
    fetchNotificationsSuccess: ["notifications"],
    setNotificationsRead: null,
    userProfileEditConfirm: null,
    sendRegulationsConfirmation: ["agreement"],
    updateUserAgreements: ["agreements"],
    fetchFavouriteItems: null,
    fetchFavouriteItemsSuccess: ["favouriteItems"],
    addFavouriteItem: ["materialType", "id"],
    removeFavouriteItem: ["materialType", "id"],
    setFavouriteToastStatus: ["favouriteToastStatus"],
    setGlobalErrorVisible: ["status"],
    setSearchPopupActive: ["searchPopupActive"],
    fetchPermissions: null,
    fetchUserData: null,
    editLessonComment: ["comment"],
    deleteLessonComment: ["commentId"],
    changeUserPhoneNumber: ["phoneNumber"],
    setTutorialCurrentStep: ["tutorialCurrentStep"],
    setNewPassword: ["oldPassword", "newPassword", "onSuccess", "onFailure"],
  },
  { prefix: "COMMON/" }
);

const INITIAL_STATE = {
  visibilityList: [],
  brands: [],
  videoForCode: {
    error: "",
    available: [],
  },
  unreadNotifications: 0,
  favouriteItems: [],
  favouriteToastStatus: null,
  globalErrorVisible: false,
  searchPopupActive: false,
  tutorialCurrentStep: null,
};

const fetchVisibilitySuccess = (state, { visibilityList }) => ({ ...state, visibilityList });

const fetchBrandsSuccess = (state, { brands }) => ({ ...state, brands });

const fetchPaidVideosSuccess = (state, { available }) => ({
  ...state,
  videoForCode: {
    ...state.videoForCode,
    available,
  }
});

const unlockVideoFailure = (state, { error }) => {
  return {
    ...state,
    videoForCode: {
      ...state.videoForCode,
      error,
    }
  };
};

const unlockVideoSuccess = (state, { available }) => ({
  ...state,
  videoForCode: {
    ...state.videoForCode,
    error: "",
    available,
  }
});

const clearUnlockVideoError = (state) => ({
  ...state,
  videoForCode: {
    ...state.videoForCode,
    error: "",
  }
});

const fetchNotificationsSuccess = (state, { notifications }) => {
  const unreadNotifications = notifications.filter(
    (notification) => notification.status.name === NotificationStatus.Received
  );

  return {
    ...state,
    unreadNotifications: unreadNotifications.length,
  };
};

const setNotificationsRead = (state) => ({ ...state, unreadNotifications: 0 });

const fetchFavouriteItemsSuccess = (state, { favouriteItems }) => ({
  ...state,
  favouriteItems,
});

const setFavouriteToastStatus = (state, { favouriteToastStatus }) => ({
  ...state,
  favouriteToastStatus,
});

const setGlobalErrorVisible = (state, { status }) => ({
  ...state,
  globalErrorVisible: status,
});

const setSearchPopupActive = (state, { searchPopupActive }) => ({
  ...state,
  searchPopupActive,
});

const setTutorialCurrentStep = (state, { tutorialCurrentStep }) => ({
  ...state,
  tutorialCurrentStep,
});

export const reducer = createReducer(INITIAL_STATE, {
  [CommonTypes.FETCH_VISIBILITY_SUCCESS]: fetchVisibilitySuccess,
  [CommonTypes.FETCH_BRANDS_SUCCESS]: fetchBrandsSuccess,
  [CommonTypes.FETCH_PAID_VIDEOS_SUCCESS]: fetchPaidVideosSuccess,
  [CommonTypes.UNLOCK_VIDEO_SUCCESS]: unlockVideoSuccess,
  [CommonTypes.UNLOCK_VIDEO_FAILURE]: unlockVideoFailure,
  [CommonTypes.CLEAR_UNLOCK_VIDEO_ERROR]: clearUnlockVideoError,
  [CommonTypes.FETCH_NOTIFICATIONS_SUCCESS]: fetchNotificationsSuccess,
  [CommonTypes.SET_NOTIFICATIONS_READ]: setNotificationsRead,
  [CommonTypes.FETCH_FAVOURITE_ITEMS_SUCCESS]: fetchFavouriteItemsSuccess,
  [CommonTypes.SET_FAVOURITE_TOAST_STATUS]: setFavouriteToastStatus,
  [CommonTypes.SET_GLOBAL_ERROR_VISIBLE]: setGlobalErrorVisible,
  [CommonTypes.SET_SEARCH_POPUP_ACTIVE]: setSearchPopupActive,
  [CommonTypes.SET_TUTORIAL_CURRENT_STEP]: setTutorialCurrentStep,
});
