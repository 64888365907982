import React from "react";
import PropTypes from "prop-types";
import { cond, equals, T, always } from "ramda";

import { OutlinedContainer, DefaultContainer } from "./button.styled";
import { ButtonVariant } from "./button.constants";

export const Button = ({
  className,
  children,
  variant,
  type = "button",
  ...buttonProps
}) => {
  const getContainer = cond([
    [equals(ButtonVariant.Outlined), always(OutlinedContainer)],
    [T, always(DefaultContainer)],
  ]);

  const Container = getContainer(variant);

  return (
    <Container
      {...buttonProps}
      type={type}
      $variant={variant}
      className={className}
    >
      {children}
    </Container>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  outlined: PropTypes.bool,
  onClick: PropTypes.func,
};
