import { nodeApi, handleApiError } from "../../../api";

export const getQuestions = (token) => {
  return nodeApi.get(`/surveys/${token}/init`).catch((error) => {
    const { response } = error;

    if (response.status === 410) {
      return { status: 410 };
    } else if (response.status === 404) {
      return { status: 404 };
    } else {
      handleApiError(error);
    }
  });
};

export const sendAnswers = (token, answers) => {
  return nodeApi.post(`/surveys/${token}/send`, { answers }).catch(handleApiError);
};
