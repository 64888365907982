import { lighten } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  video {
    width: 100%;
    max-height: 500px;
  }

  .path-choice {
    position: relative;
    width: 100%;
    font-size: 20px;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 600px;

    @media screen and (max-width: 768px) {
      height: 450px;
    }

    &.path-choice--replay-screen {
      height: 600px;

      @media screen and (max-width: 768px) {
        height: 670px;
      }

      @media screen and (max-width: 480px) {
        height: 850px;
      }
    }

    &--header {
      text-align: center;
      font-size: 2.6rem;
      padding: 1em 0;
      margin: 0 0 1em;
      border-bottom: 1px solid #ddd;
      color: #222;
      width: 100%;
      transition: transform .3s ease-out, opacity .3s ease-out;

      &.hidden {
        opacity: 0;
        transform: translate3d(0, 60px, 0) scale3d(.9, .9, 1);
      }
    }

    &--choice-item {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 2rem;
      width: 100%;

      &--text {
        margin: 0;
        padding: 1rem;
        flex: 0 0 calc(100% - 100px);
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eee;
        transition: transform .6s ease-out, opacity .6s ease-out;

        &.hidden {
          opacity: 0;
          transform: translate3d(100px, 0px, 0) scale3d(.9, .9, 1);
        }
      }

      &--button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        flex: 0 0 50px;
        margin: 0;
        padding: 0;
        background: #0c6a8a url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAPNJREFUeNpi/P//P8NAAiaGAQZDwgHiQMw5UA6YCsQvgPgbENfTxAWgRIgHo4O9QBxMQA9JGJ8k83/coB+IxQfSATCQO9AOAIH5QGw6kA6AgeaBdgBZiZTaDkBOpMID6QCiEymti+JJQDwfiE3JKYioEQLIoBKbPYx4qmNmIP5D5RBhA+LfI7Y6rkL3PQiw0MHiBUA8DYhPY5OktQPygHjyQETBBCCWIGQ5LULgADS4V1OjQUKXyogaIbAI6uuT1G6SERMChcO6SYatUbofiEPp1SgF4alIlrdS02JiKiMYkAbiD0D8lRYFBuNo53SgHQAQYAB7r+TNHC0c4QAAAABJRU5ErkJggg==') 14px center no-repeat;
        background-size: 24px;
        border: none;
        border-radius: 30px;
        color: #fff;
        cursor: pointer;
        outline: none;
        transition: transform .2s ease-out, opacity .2s ease-out;

        &:hover {
          background-color: ${lighten("#0c6a8a", 0.15)};
        }

        &.hidden {
          opacity: 0;
          transform: translate3d(0, 60px, 0) scale3d(.9, .9, 1);
        }
      }
    }

    &--summary {
      font-size: 1.7rem;
      text-align: center;
      transition: transform .6s ease-out, opacity .6s ease-out;
      width: 100%;

      p {
        padding: 1rem 1rem 0;
        margin: 0;
        padding-bottom: 2rem;
        line-height: 20px;

        &:last-child {
          padding-bottom: 1rem;
        }
      }

      .color {
        color: #16a7d5;
      }

      &.hidden {
        opacity: 0;
        transform: translate3d(0, 100px, 0) scale3d(.9, .9, 1);
      }

      &--button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 46px;
        margin: 40px 0 0;
        padding: 0 30px 0 0;
        background: #0c6a8a;
        border: none;
        border-radius: 23px;
        color: #fff;
        cursor: pointer;
        font-size: .7em;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1px;
        outline: none;
        transition: background .2s ease-out, transform .2s ease-out, opacity .2s ease-out;

        &:hover {
          background-color: ${lighten("#0c6a8a", 0.15)};
        }

        &:after {
          position: absolute;
          height: 60px;
          width: 60px;
          border-radius: 30px;
          content: '';
          top: -7px;
          right: -1px;
          background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAPNJREFUeNpi/P//P8NAAiaGAQZDwgHiQMw5UA6YCsQvgPgbENfTxAWgRIgHo4O9QBxMQA9JGJ8k83/coB+IxQfSATCQO9AOAIH5QGw6kA6AgeaBdgBZiZTaDkBOpMID6QCiEymti+JJQDwfiE3JKYioEQLIoBKbPYx4qmNmIP5D5RBhA+LfI7Y6rkL3PQiw0MHiBUA8DYhPY5OktQPygHjyQETBBCCWIGQ5LULgADS4V1OjQUKXyogaIbAI6uuT1G6SERMChcO6SYatUbofiEPp1SgF4alIlrdS02JiKiMYkAbiD0D8lRYFBuNo53SgHQAQYAB7r+TNHC0c4QAAAABJRU5ErkJggg==') 16px center no-repeat;
          background-color: inherit;
        }

        &.hidden {
          opacity: 0;
          transform: translate3d(100px, 0, 0) scale3d(.9, .9, 1);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    video {
      max-height: 350px;
    }
  }
`;
