import React from "react";

import { Container, EyeOnImg, EyeOffImg } from "./eyeButton.styled";

export const EyeButton = ({ passwordVisible, togglePasswordVisibility }) => {
  return (
    <Container onClick={togglePasswordVisibility}>
      {passwordVisible ? <EyeOnImg /> : <EyeOffImg />}
    </Container>
  );
};
