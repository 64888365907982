export const TutorialLocalStorageKey = "tutorialViewed";

export const TutorialStep = {
  Init: "init",
  Profile: "profile",
  Search: "search",
  KnowledgeBase: "knowledgeBase",
  Trainings: "trainings",
};

export const TutorialStatus = {
  Enabled: "enabled",
  Disabled: "disabled",
};
