import styled from "styled-components";

import { FontWeight, Color, Media } from "../../../../../../../theme";

export const Container = styled.div`
  width: 100%;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${Color.gray1};

  ${Media.tablet`
    padding: 40px 0;
  `}
`;

export const HeaderTextWrapper = styled.div``;

export const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 10px;

  ${Media.tablet`
    line-height: 40px;
    font-size: 26px;
    margin-bottom: 5px;
  `}
`;

export const TextBold = styled.span`
  font-weight: ${FontWeight.Bold};
`;

export const Subtitle = styled.h2`
  font-size: 16px;
  line-height: 20px;

  ${Media.tablet`
    font-size: 20px;
  `}
`;
