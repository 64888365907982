import i18n from "i18next";

export const DropdownOptions = [{
  id: 1,
  label: i18n.t("gamification.challengeList.all"),
  name: i18n.t("gamification.challengeList.all"),
  value: null,
}, {
  id: 2,
  label: i18n.t("gamification.challengeList.active"),
  name: i18n.t("gamification.challengeList.active"),
  value: true,
}, {
  id: 3,
  label: i18n.t("gamification.challengeList.inactive"),
  name: i18n.t("gamification.challengeList.inactive"),
  value: false,
}, {
  id: 4,
  label: i18n.t("gamification.challengeList.news"),
  name: i18n.t("gamification.challengeList.news"),
  value: "news",
}];

export const ElementType = {
  News: "news",
  Challenge: "challenge",
};
