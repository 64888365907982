import styled from "styled-components";

import { ConfirmationModal } from "../../components/confirmationModal/confirmationModal.component";
import { Color } from "../../theme";

export const Container = styled(ConfirmationModal)``;

export const Text = styled.p`
  & + & {
    margin-top: 30px;
  }
`;

export const Link = styled.a`
  color: ${() => Color.brandBasic};
  margin: 30px 0;
`;
