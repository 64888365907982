import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../../../helpers/rendering";
import { Color, FontWeight, Media } from "../../../../../theme";
import { ReactComponent as CoinSvg } from "../../../images/yellowCoin.svg";

export const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${Color.white};
  padding: 16px 20px 15px 11px;

  & + & {
    margin-top: 2px;
  }

  ${Media.tablet`
    padding: 16px 30px 15px 16px;
  `}
`;

export const Position = styled.p`
  font-size: 13px;
  line-height: 22px;
  width: 35px;
  text-align: center;
  color: ${Color.gray9};
  margin-right: 13px;
  font-weight: ${FontWeight.SemiBold};

  ${styleWhenTrue(prop("isCurrentUser"), css`
    color: ${Color.deepGray};
  `)}
`;

export const Name = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${Color.gray9};
  margin-left: 12px;

  ${styleWhenTrue(prop("isCurrentUser"), css`
    color: ${Color.deepGray};
  `)}
`;

export const Coins = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const CoinIcon = styled(CoinSvg)`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
`;

export const Value = styled(Name)`
  margin-left: 5px;
`;
