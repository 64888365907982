import React, { useState } from "react";

import { isTabletWide, isTablet } from "../../../../../theme/media";
import { renderWhenTrue, renderWhenTrueOtherwise } from "../../../../../helpers/rendering";
import { NextButton } from "../nextButton/nextButton.component";

import {
  Content,
  Container,
  Text,
  ThumbsAndTexts,
  ThumbButton,
  CorrectAnswerComment,
  WrongAnswerComment,
  ButtonRow,
  ThumbUpIcon,
  ThumbDownIcon,
  ThumbsContainer,
} from "./interactionThumbsQuiz.styled";

export const InteractionThumbsQuiz = ({
  correctText,
  incorrectText,
  title,
  answer,
  buttonText,
  next,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [selectedBox, setSelectedBox] = useState();
  const isButtonDisabled = selectedAnswer !== answer;

  const setFalse = () => {
    setSelectedBox("false");
    setSelectedAnswer(false);
  };

  const setTrue = () => {
    setSelectedBox("true");
    setSelectedAnswer(true);
  };

  const isOptionSelected = (value) => selectedBox === value.toString();

  const isCorrectTheme = (value) => answer === value && isOptionSelected(value);

  const isWrongTheme = (value) => !answer === value && isOptionSelected(value);

  const isFaded = (value) => (selectedBox && isCorrectTheme(value)) || isWrongTheme(value);

  const nextInteraction = () => {
    setSelectedAnswer(null);
    setSelectedBox(null);
    next();
  };

  const renderCorrectAnswerComment = renderWhenTrue(() => (
    <CorrectAnswerComment>{correctText}</CorrectAnswerComment>
  ));

  const renderWrongAnswerComment = renderWhenTrue(() => (
    <WrongAnswerComment>{incorrectText}</WrongAnswerComment>
  ));

  const renderAnswerComments = renderWhenTrue(() => (
    <>
      {renderCorrectAnswerComment(isCorrectTheme(selectedAnswer))}
      {renderWrongAnswerComment(!isCorrectTheme(selectedAnswer))}
    </>
  ));

  const renderThumbIcon = renderWhenTrueOtherwise(
    () => <ThumbUpIcon faded={isFaded(false)} />,
    () => <ThumbDownIcon faded={isFaded(true)} />
  )

  const renderThumbButton = (answer) => (
    <ThumbButton
      selected={isOptionSelected(answer)}
      correctTheme={isCorrectTheme(answer)}
      wrongTheme={isWrongTheme(answer)}
      onClick={answer ? setTrue : setFalse}
    >
      {renderThumbIcon(answer)}
    </ThumbButton>
  );

  const renderTitle = renderWhenTrue(() => <Text>{title}</Text>);

  return (
    <Container>
      <Content>
        {renderTitle(!isTablet())}
        <ThumbsAndTexts>
          <ThumbsContainer>
            {renderThumbButton(true)}
            {renderThumbButton(false)}
          </ThumbsContainer>
          <div>
            {renderTitle(isTablet())}
            {renderAnswerComments(isTabletWide() && selectedAnswer !== null)}
          </div>
        </ThumbsAndTexts>
        {renderAnswerComments(!isTabletWide() && selectedAnswer !== null)}
      </Content>
      <ButtonRow>
        <NextButton
          onClick={nextInteraction}
          disabled={isButtonDisabled}
          text={buttonText}
        />
      </ButtonRow>
    </Container>
  );
};
