import styled from "styled-components";

import { ReactComponent as DisabledSvg } from "../../../../images/disabled.svg";
import { hexToRgba } from "../../../../helpers/colors";
import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.div`
  background-color: ${Color.white};
  padding: 0 25px 35px;
  width: 100%;

  ${Media.tablet`
    padding: 0 35px 35px;
  `}
`;

export const EmptyHistoryWrapper = styled.div`
  margin-top: 35px;
  display: flex;
  align-items: center;
  color: ${Color.gray9};
  font-size: 14px;
`;

export const DisabledIcon = styled(DisabledSvg)`
  margin-right: 12px;
`;

export const Table = styled.table`
  width: 100%;
`;

export const TableHead = styled.thead`
  position: relative;
  color: ${Color.gray9};
  font-weight: ${FontWeight.ExtraBold};
  text-align: left;
  letter-spacing: 0.6px;
  text-transform: uppercase;

  &:after {
    content: "";
    position: absolute;
    height: 2px;
    left: 0;
    right: 0;
    background-color: ${Color.almostWhite};
  }
`;

export const TableHeader = styled.th`
  padding: 14px 0;

  &:not(:last-of-type) {
    padding-right: 10px;
  }

  &:nth-of-type(2) {
    width: 35%;
  }

  &:nth-of-type(3) {
    width: 20%;
  }

  &:last-of-type {
    text-align: right;
  }
`;

export const TableBodyRow = styled.tr`
  border-bottom: 1px solid ${hexToRgba(Color.gray5, 0.8)};
`;

export const TableBody = styled.tbody``;

export const TableBodyElement = styled.td`
  font-size: 14px;
  color: ${Color.gray9};
  padding: 30px 0;

  &:not(:last-of-type) {
    padding-right: 35px;
  }

  &:nth-of-type(2) {
    font-weight: ${FontWeight.SemiBold};
    color: ${Color.deepGray};
  }

  &:last-of-type {
    div {
      justify-content: flex-end;
    }
  }
`;

export const ListTitle = styled.h1`
  color: ${Color.deepGray};
  font-weight: ${FontWeight.SemiBold};
  border-bottom: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  padding: 35px 0 15px;
  font-size: 14px;
`;

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid ${hexToRgba(Color.gray5, 0.8)};
  color: ${Color.gray9};
`;

export const ListItemTitle = styled.h1`
  font-weight: ${FontWeight.ExtraBold};
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

export const ListItemValue = styled.p`
  font-size: 14px;
`;
