import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import MediaQuery from "react-responsive";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { BackButton } from "../../components/backButton/backButton.component";
import { TagList } from "../../components/tagList/tagList.component";
import { OfflineHandler, OnlineHandler } from "../../helpers/connectionHandler";
import {
  Badge,
  BadgeWrap,
  BoxInner,
  BoxTitle,
  Button,
  ContentContainer,
  Head,
  HeaderButton,
  Icon,
  InlineBox,
  InlineBoxInside,
  InlineBoxInsideCenter,
  InlineBoxText,
  LoadingWrapper,
  SmallAvatar,
  Thumbnail,
  ThumbnailListRow,
} from "../../UIElements";
import { breakpoints, breakpointsArray } from "../../UIGlobals";
import { AppConfig } from "../../appConfig";
import { Color } from "../../theme";
import { TrainingsActions } from "../trainings/redux/trainings.reducer";
import { selectIsInitialDataPending, selectEventDetails } from "../trainings/redux/trainings.selectors";
import { useModuleConfig, ModuleType } from "../";

import { Enroll } from "./components/enroll/enroll.component";
import { FilesPopup } from "./components/filesPopup/filesPopup.component";
import { VideoPopup } from "./components/videoPopup/videoPopup.component";
import { Details } from "./components/details/details.component";
import { Agenda } from "./components/agenda/agenda.component";
import { LocationMap } from "./components/locationMap/locationMap.component";
import { AddToCalendar } from "./components/addToCalendar/addToCalendar.component";
import {
  HeaderTitle,
  HeaderWithTagsWrapper,
  ListHeader,
  BoxWrapper,
  Box,
  Text,
  Link,
  BorderedListHeader,
  MapPlaceholder,
  Col,
  StyledDisenroll,
} from "./training.styled";
import { StatusName } from "./training.constants";
import { countDays, parseAgenda } from "./training.utils";

const Training = () => {
  const config = useModuleConfig(ModuleType.Training);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const loading = useSelector(selectIsInitialDataPending);
  const trainingData = useSelector(selectEventDetails);
  const collection = trainingData?.requiredContents;
  const eventOnline = !!trainingData?.webinarUrl || !!trainingData?.roomId || !!trainingData?.videoId;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(TrainingsActions.getEventDetails(params.id));

    return () => {
      dispatch(TrainingsActions.clearEvents());
    };
  }, []);

  const action = (item) => {
    switch (item.type) {
    case "lesson":
    case "lesson_slide_scroll":
      return () => {
        navigate(`/lekcje/${item.id}`);
      };
    case "video":
      return () => {
        navigate(`/wideo/${item.id}`);
      };
    case ("file" || "files"):
      return () => {
        window.open(item.url, "_blank");
      };

    default:
      return () => {};
    }
  };

  if (!trainingData?.course) {
    return <div />;
  }

  return (
    <ThemeProvider theme={config.theme}>
      <LoadingWrapper isLoading={loading}>
        <ContentContainer background="white" padding="30px 0 80px">
          <BackButton strictPath="/szkolenia" />
          <HeaderButton hasHorizontalPadding hasBorder>
            <HeaderWithTagsWrapper>
              {trainingData.competences && <TagList tags={trainingData.competences} />}
              <HeaderTitle>{trainingData.course?.name}</HeaderTitle>
            </HeaderWithTagsWrapper>
            <BadgeWrap>
              <MediaQuery query={`(${breakpoints().minM})`}>
                {trainingData.userFlags.isEnrolled && (
                  <AddToCalendar trainingData={trainingData} />
                )}
                {trainingData.userFlags.canBeEnrolled && (
                  <Enroll
                    eventId={trainingData.id}
                    buttonProps={{
                      width: 181,
                      text: "normal",
                      weight: "normal",
                    }}
                    entrySurvey={trainingData.entrySurvey}
                    eventName={trainingData.course?.name}
                    eventDate={trainingData.date.from}
                  />
                )}
                {trainingData.userFlags.canBeDisenrolled && (
                  <StyledDisenroll
                    width={181}
                    $text="initial"
                    $weight="normal"
                    eventId={trainingData.id}
                    eventName={trainingData.course?.name}
                  />
                )}
              </MediaQuery>
            </BadgeWrap>
          </HeaderButton>
          {trainingData.sources && (
            <ThumbnailListRow
              paddingBottom={36}
              marginBottom={60}
              borderBottom
              borderColor={Color.dirtyWhite}
              title={`${t("training.recommendedBeforeTraining")}:`}
              perPage={
                window.innerWidth > breakpointsArray.l
                  ? 4
                  : window.innerWidth > breakpointsArray.m
                    ? 3
                    : window.innerWidth > breakpointsArray.s
                      ? 2
                      : 1
              }
            >
              {trainingData.sources.elements.map((item) => (
                <Thumbnail
                  key={item.id}
                  title={item.name}
                  category={item.category}
                  items={item.items?.length}
                  action={action(item)}
                  uri={
                    item.type === "file"
                      ? "/assets/img/files.jpg"
                      : item.type === "contents"
                        ? item.items[0].image
                        : item.image
                  }
                  required={item.required}
                  background={item.type === "file" ? Color.lightYellow : ""}
                  itemsType={item.type}
                  date={
                    item.validity ? (
                      dayjs.unix(item.validity).locale("pl").format("DD.MM.YYYY")
                    ) : null
                  }
                >
                </Thumbnail>
              ))}
            </ThumbnailListRow>
          )}
          {collection.length > 0 && (
            <>
              <ListHeader>{t("training.training.toPass")}:</ListHeader>
              <ThumbnailListRow
                paddingBottom={10}
                marginBottom={46}
                borderBottom={false}
                key={collection.title}
                perPage={
                  window.innerWidth > breakpointsArray.l
                    ? 4
                    : window.innerWidth > breakpointsArray.m
                      ? 3
                      : window.innerWidth > breakpointsArray.s
                        ? 2
                        : 1
                }
              >
                {collection.map((item, index) => {
                  const badges = [];
                  item.award && badges.push(item.award);
                  !item.award && item.completed && badges.push("completed");
                  item.type === "file" && badges.push("download");
                  return (
                    <Thumbnail
                      key={collection.title + index}
                      action={action(item)}
                      title={item.name}
                      category={item.category}
                      badges={badges}
                      items={item.items?.length}
                      itemsType={item.type}
                      progress={item.progress && item.progress > 0 ? item.progress : null}
                      date={item.updated}
                      uri={
                        item.type === "file"
                          ? "/assets/img/files.jpg"
                          : item.type === "contents"
                            ? item.items[0].image
                            : item.image
                      }
                      required={item.required}
                      minutes={item.minutes}
                      background={item.type === "files" ? Color.lightYellow : false}
                    >
                      {(item.type === "file" || item.type === "files") && (
                        <FilesPopup items={item.items || []} header={item.name} />
                      )}
                      {item.type === "contents" && (
                        <VideoPopup items={item.items || []} title={item.name} />
                      )}
                    </Thumbnail>
                  );
                })}
              </ThumbnailListRow>
            </>
          )}
          <BoxWrapper>
            <Box cyclical={!!trainingData.cyclical}>
              <Icon icon="training_data" size={30} color={Color.brandBasic} />
              <Text color="gray4" fontSize={1.5}>
                {trainingData.cyclicalInfo?.nextTimestampStart || trainingData.date.from ? (
                  dayjs.unix(trainingData.cyclicalInfo?.nextTimestampStart || trainingData.date.from).locale("pl").format("DD MMMM YYYY")
                ) : null}
              </Text>
            </Box>
            <Box cyclical={!!trainingData.cyclical}>
              <Icon icon="training_marker" size={30} color={Color.brandBasic} />
              <Text color="gray4" fontSize={1.5}>
                {trainingData.location
                  ? trainingData.location.city
                  : trainingData.cyclical || eventOnline
                    ? t("training.details.online2")
                    : t("training.details.trainingLocationNone")
                }
              </Text>
            </Box>
            {!trainingData.cyclical && (
              <Box>
                <Icon icon="dashboard_trainings" size={30} color={Color.brandBasic} />
                <Text color="gray4" fontSize={1.5}>
                  {t("training.training.freePlaces")}: {trainingData.availableEnrolments}
                </Text>
              </Box>
            )}
          </BoxWrapper>
          {!!trainingData.urls?.length && (
            <BoxWrapper>
              {trainingData.urls.map((url, index) => (
                <Link key={index} target="blank" href={`${AppConfig.api}${url.url}`}>
                  <Button
                    marginLeft="0"
                    marginRight="20"
                    color="brandBasic"
                    $background="white"
                    border="brandBasic"
                    $text="normal"
                    $weight="normal"
                    padding="0 25px"
                    hoverColor="white"
                    hoverBackground="brandBasic"
                  >
                    <BadgeWrap alignItems="center">
                      <Badge
                        size={34}
                        name="download"
                        border={Color.dirtyWhite}
                        marginRight={25}
                        hover="pointer"
                        customBackground={Color.transparent}
                        className="badge"
                        noColor
                      />
                      {url.label}
                    </BadgeWrap>
                  </Button>
                </Link>
              ))}
            </BoxWrapper>
          )}
          <BorderedListHeader>
            {t("training.training.courseDetails")}
          </BorderedListHeader>
          <Details
            days={countDays(trainingData)}
            trainers={trainingData.trainers}
            category={trainingData.course.category?.name}
            description={trainingData.course.description}
            start={trainingData.cyclicalInfo?.nextTimestampStart || trainingData.date.from}
            end={trainingData.cyclicalInfo?.nextTimestampEnd || trainingData.date.to}
            location={trainingData.location}
            status={trainingData.status.name}
            webinarUrl={trainingData.webinarUrl}
            roomId={trainingData.roomId}
            author={trainingData.author}
            videoId={trainingData.videoId}
            modules={trainingData.modules || []}
            finished={trainingData.status.name === StatusName.Finished}
            isCyclical={!!trainingData.cyclical}
          />

          <BorderedListHeader>
            {t("training.training.agenda")}
          </BorderedListHeader>
          <Agenda agenda={parseAgenda(trainingData.agenda)} />
          <Icon Icon="training_insurance" size={12} color={Color.black} />
        </ContentContainer>
        {!!trainingData.location?.coordinates && (
          <>
            <OnlineHandler>
              <LocationMap
                isMarkerShown
                lat={trainingData.location.coordinates.lat}
                lng={trainingData.location.coordinates.lng}
              />
            </OnlineHandler>
            <OfflineHandler>
              <MapPlaceholder>
                <Text>{t("training.training.offlineMap")}</Text>
              </MapPlaceholder>
            </OfflineHandler>
          </>
        )}
        <ContentContainer padding="80px 0 120px" autoHeight background="almostWhite">
          {trainingData.files && trainingData.files.length > 0 && (
            <>
              <ListHeader>{t("global.filesToDownload")}</ListHeader>
              <Col $flexDirection="column">
                {trainingData.files.map((file, index) => (
                  <Link padding="17px 35px" target="_blank" href={file.url} key={index}>
                    <Head>
                      <BadgeWrap>
                        <Badge
                          size={34}
                          name="download"
                          color={Color.brandBasic}
                          border={Color.dirtyWhite}
                          marginRight={10}
                          hover="pointer"
                        />
                      </BadgeWrap>
                      <BoxInner paddingLeft={4}>
                        <BoxTitle color="gray4">{file.label}</BoxTitle>
                      </BoxInner>
                    </Head>
                  </Link>
                ))}
              </Col>
            </>
          )}
          {trainingData.enrolments && trainingData.enrolments.length > 0 && (
            <>
              <ListHeader>{t("training.training.courseParticipants")}</ListHeader>
              <Col $flexDirection="column">
                {trainingData.enrolments.map((user, index) => (
                  <InlineBox key={index} >
                    <InlineBoxText maxWidth={30} minWidth={30}>
                      {index + 1}
                    </InlineBoxText>
                    <SmallAvatar color="black" $background="white" $border="dirtyWhite">
                      {user.avatar ? (
                        <img src={user.avatar} alt="avatar"/>
                      ) : (
                        user.name
                          .split(" ")
                          .map((string) => string.substr(0, 1).toUpperCase())
                          .slice(0, 2)
                          .join("")
                      )}
                    </SmallAvatar>
                    <InlineBoxText>{user.name}</InlineBoxText>

                    <InlineBoxInsideCenter />
                    <InlineBoxInside />
                  </InlineBox>
                ))}
              </Col>
            </>
          )}
        </ContentContainer>
        <MediaQuery query={`(${breakpoints().maxM})`}>
          {trainingData.userFlags.isEnrolled && (
            <AddToCalendar trainingData={trainingData} />
          )}
          {trainingData.userFlags.canBeEnrolled && (
            <Enroll
              eventId={trainingData.id}
              buttonProps={{
                width: 181,
                text: "normal",
                weight: "normal",
                tabletFull: true,
                marginBottom: "0"
              }}
              entrySurvey={trainingData.entrySurvey}
              eventName={trainingData.course?.name}
              eventDate={trainingData.date.from}
            />
          )}
          {trainingData.userFlags.canBeDisenrolled && (
            <StyledDisenroll
              width={181}
              text="initial"
              weight="normal"
              eventId={trainingData.id}
              tabletFull
              marginBottom="0"
              eventName={trainingData.course?.name}
            />
          )}
        </MediaQuery>
      </LoadingWrapper>
    </ThemeProvider>
  );
};

export default Training;
