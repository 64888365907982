import styled from "styled-components";

import { Color } from "../../../../theme";

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  flex-direction: ${({ $flexDirection }) => ($flexDirection ? $flexDirection : "row")};
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop + "px" : "0")};
`;

export const Col = styled.div`
  width: ${({ width }) => (width ? width : "100%")};
  display: flex;
  flex-direction: ${({ $flexDirection }) => ($flexDirection ? $flexDirection : "TableRow")};
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : "initial")};
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? paddingBottom + "px" : "0")};
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft + "px" : "0")};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "initial")};
`;

export const Text = styled.span`
  font-size: ${({ fontSize }) => (fontSize ? fontSize + "rem" : "1.2rem")};
  color: ${({ color }) => (color ? Color[color] : Color.deepGray)};
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop + "px" : "17px")};
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? paddingBottom + "px" : "0")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "400")};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "1")};
`;
