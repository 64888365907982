import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import createSagaMiddleware from "redux-saga";

import createSocketMiddleware from "../redux/websocket/websocket.middleware";
import { logger } from "../helpers/logger";

import { createRootReducer } from "./rootReducer";
import rootSaga from "./rootSaga";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "example",
    "lesson",
    "video",
    "singleQuiz",
    "statistics",
    "events",
    "event",
    "forms",
    "form",
    "certificates",
    "userRegistration",
    "search",
    "news",
    "webinars",
    "videoPlayer",
    "knowledgeBase",
    "notifications",
    "competence",
    "surveys",
    "profile",
    "teamIndicators",
    "gamification",
    "anonSurvey",
    "trainings",
    "idd",
    "emailVerification",
    "registration"
  ],
  stateReconciler: hardSet,
};

const persistedReducer = persistReducer(persistConfig, createRootReducer());
const sagaMiddleware = createSagaMiddleware();
const socketMiddleware = createSocketMiddleware();
const middlewares = [sagaMiddleware, socketMiddleware];

export const configureStore = (initialState) => {
  const composeEnhancers = composeWithDevTools({});
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  const rootSagaTask = sagaMiddleware.run(rootSaga);
  rootSagaTask.toPromise().catch((error) => logger("Api middleware error", error));

  const persistor = persistStore(store);

  return { store, persistor };
};
