import styled from "styled-components";
import { Link } from "react-router-dom";

import { hexToRgba } from "../../../../helpers/colors";
import { ReactComponent as SurveySvg } from "../../../../images/forms2.svg";
import { ReactComponent as ArrowSvg } from "../../../../images/arrow.svg";
import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled(Link)`
  background-color: ${Color.white};
  padding: 32px 23px 32px 20px;
  box-shadow: 0 5px 10px ${hexToRgba(Color.deepGray, 0.01)};
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }

  ${Media.tablet`
    padding: 47px 43px 47px 35px;
  `}
`;

export const SurveyIcon = styled(SurveySvg)`
  width: 46px;
  height: 46px;
  flex-shrink: 0;
  margin-right: 23px;

  ${Media.tablet`
    margin-right: 33px;
  `}
`;

export const Title = styled.p`
  font-size: 16px;
  color: ${Color.deepGray};
  line-height: 20px;
  font-weight: ${FontWeight.SemiBold};
  margin-right: 23px;

  ${Media.tablet`
    margin-right: 33px;
  `}
`;

export const ArrowIcon = styled(ArrowSvg)`
  width: 10px;
  height: 16px;
  flex-shrink: 0;
  transform: rotate(180deg);
  margin-left: auto;
  fill: ${Color.gray9};
`;
