import { ReactComponent as ModuleIcon } from "../../images/statistics.svg";

export const Config = {
  moduleName: "teamIndicators",
  dependencies: [],
  label: "teamIndicators.pageTitle",
  Icon: ModuleIcon,
  urls: ["/indicators/*", "/indicators"],
  routeName: "api_competence_population_read",
  routes: {
    strategic: "/strategic",
    common: "/common",
    statistics: "/statistics",
  },
  options: {},
  theme: {}
};
