import { createActions, createReducer } from "reduxsauce";

export const { Types: TeamIndicatorsTypes, Creators: TeamIndicatorsActions } = createActions(
  {
    fetchChartsData: ["chartIDs"],
    fetchChartsDataSuccess: ["charts"],
    createNewChart: ["competenceId", "skillId"],
    createNewChartSuccess: ["chart"],
    setIsPending: ["isPending"],
    updateChartMeasure: ["order", "competenceId", "skillId"],
    updateChartMeasureSuccess: ["order", "chart"],
    removeChart: ["order"],
    clearCharts: null,
    setStatsLoading: ["statsLoading"],
    getStats: null,
    getStatsSuccess: [""],
    updateStatsTeam: ["offset", "limit"],
    updateStatsTeamSuccess: ["data"],
    reduceStatsTeam: null,
  },
  { prefix: "TEAM_INDICATORS/" }
);

const INITIAL_STATE = {
  isPending: false,
  charts: [],
  statsLoading: false,
  statsTeamCount: null,
  statsData: [],
  statsTeam: [],
};

const setIsPending = (state, { isPending }) => ({ ...state, isPending });

const fetchChartsDataSuccess = (state, { charts }) => ({
  ...state,
  charts,
});

const createNewChartSuccess = (state, { chart }) => {
  return ({
    ...state,
    charts: [...state.charts, chart],
  });
};

const removeChart = (state, { order }) => {
  const newCharts = [...state.charts];
  newCharts.splice(order, 1);
  return ({
    ...state,
    charts: newCharts,
  });
};

const updateChartMeasureSuccess = (state, { order, chart }) => {
  const newCharts = [...state.charts];
  newCharts[order] = chart;

  return ({
    ...state,
    charts: newCharts,
  });
};

const clearCharts = (state) => {
  return ({
    ...state,
    charts: [],
  });
};

const setStatsLoading = (state, { statsLoading }) => ({ ...state, statsLoading });

const getStatsSuccess = (state, { statsData }) => ({ ...state, statsData });

const updateStatsTeamSuccess = (state, { data }) => ({
  ...state,
  statsTeam: [...state.statsTeam, ...(data.elements || [])],
  teamCount: data.count,
});

const reduceStatsTeam = (state) => ({
  ...state,
  statsTeam: [
    ...state.statsTeam.slice(
      0,
      state.statsTeam.length > 13 ? state.statsTeam.length - 10 : 3
    )
  ],
});

export const reducer = createReducer(INITIAL_STATE, {
  [TeamIndicatorsTypes.SET_IS_PENDING]: setIsPending,
  [TeamIndicatorsTypes.FETCH_CHARTS_DATA_SUCCESS]: fetchChartsDataSuccess,
  [TeamIndicatorsTypes.CREATE_NEW_CHART_SUCCESS]: createNewChartSuccess,
  [TeamIndicatorsTypes.UPDATE_CHART_MEASURE_SUCCESS]: updateChartMeasureSuccess,
  [TeamIndicatorsTypes.REMOVE_CHART]: removeChart,
  [TeamIndicatorsTypes.CLEAR_CHARTS]: clearCharts,
  [TeamIndicatorsTypes.SET_STATS_LOADING]: setStatsLoading,
  [TeamIndicatorsTypes.GET_STATS_SUCCESS]: getStatsSuccess,
  [TeamIndicatorsTypes.UPDATE_STATS_TEAM_SUCCESS]: updateStatsTeamSuccess,
  [TeamIndicatorsTypes.REDUCE_STATS_TEAM]: reduceStatsTeam,
});
