import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color } from "../../../../theme/colors";
import { FontWeight } from "../../../../theme/fonts";
import { Media } from "../../../../theme/media";

export const Container = styled.div`
  align-items: center;
  background-color: ${Color.almostWhite};
`;

export const Content = styled.div`
  max-width: 1240px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 20px;
`;

export const MonstersWrapper = styled.div`
  width: 100%;
  padding: 40px 0;
  display: flex;
  overflow-x: scroll;

  ${Media.tablet`
    overflow-x: auto;
    padding: 80px 0;
    justify-content: ${({ leasinGoMode }) => leasinGoMode ? "space-around" : "space-between"};
  `}

  ${Media.desktop`
    justify-content: space-around;
  `}
`;

export const Monster = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: 20px;
  background-color: ${Color.transparent};
  border: none;
  padding: 0;

  :first-of-type {
    margin-left: 0;
  }

  ${styleWhenTrue(prop("disabled"), css`
    cursor: default;
  `)}

  ${styleWhenTrue(prop("cursorDefault"), css`
    cursor: default;
  `)}
`;

export const Name = styled.p`
  margin-top: 19px;
  font-size: 16px;
  line-height: 22px;
  font-weight: ${FontWeight.Bold};

  ${styleWhenTrue(prop("disabled"), css`
    color: ${Color.gray3};
  `)}
`;

export const ImageWrapper = styled.div`
  width: 129px;
  height: 129px;
  background-color: ${Color.white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${Media.desktop`
    width: 162px;
    height: 162px;
  `}
`;

export const Image = styled.img`
  max-width: 84px;
  max-height: 90px;
  ${Media.desktop`
    max-width: 105px;
    max-height: 112px;
  `}
`;

export const Stage = styled.p`
  font-size: 12px;
  line-height: 17px;
  font-weight: ${FontWeight.ExtraBold};
  color: ${Color.blue};
  text-transform: uppercase;
  margin-top: 5px;

  ${styleWhenTrue(prop("disabled"), css`
    color: ${Color.gray3};
  `)}
`;
