import styled from "styled-components";

import { FontWeight } from "../../../theme";

export const Label = styled.tspan`
  font-size: 10px;
  font-weight: ${FontWeight.Bold};
`;

export const Value = styled(Label)`
  font-size: 8px;
`;
