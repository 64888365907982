import styled, { css } from "styled-components";

import { Button as ButtonBase } from "../../../../components/button/button.component";
import { Input as InputBase } from "../../../../components/input/input.component";
import { hexToRgba } from "../../../../helpers/colors";
import { ReactComponent as AcceptSvg } from "../../../../images/check4.svg";
import { ReactComponent as ErrorSvg } from "../../../../images/closeInCircle.svg";
import {
  Color,
  Media,
  ContentMaxWidth,
  FontWeight,
  Separator as SeparatorBase,
} from "../../../../theme";

export const Container = styled.div`
  padding: 40px 20px;
  background-color: ${Color.almostWhite};
  box-shadow: 0 5px 10px ${hexToRgba(Color.deepGray, 0.01)};

  ${Media.tablet`
    padding: 40px 80px;
  `}

  ${Media.desktop`
    padding: 40px 160px;
  `}
`;

export const FieldsContainer = styled.div`
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
  background-color: ${Color.white};
  padding: 35px;

  ${Media.tablet`
    padding: 60px 100px 100px;
  `}

  ${Media.tabletWide`
    padding: 60px 120px 100px;
  `}

  ${Media.desktop`
    padding: 60px 180px 100px;
  `}
`;

export const FieldsWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

export const SectionTitle = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: ${Color.deepGray};
  letter-spacing: 0.6px;
  font-weight: ${FontWeight.Bolder};
  text-transform: uppercase;
`;

export const Separator = styled(SeparatorBase)`
  && {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 600px;
  }
`;

export const Label = styled.p`
  margin: 30px 0 10px;
  font-weight: ${FontWeight.SemiBold};
  font-size: 15px;
  line-height: 22px;
  color: ${Color.gray9};
`;

export const Input = styled(InputBase)`
  max-width: 320px;

  & + & {
    margin-top: 20px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  max-width: 290px;
  gap: 7px;

  &:nth-last-of-type(2) {
    margin-bottom: 20px;
  }
`;

const iconStyles = css`
  flex-shrink: 0;
  width: 22px;
  height: 22px;
`;

export const CheckIcon = styled(AcceptSvg)`
  ${iconStyles}
  fill: ${Color.green};
`;

export const ErrorIcon = styled(ErrorSvg)`
  ${iconStyles}
  fill: ${Color.error};
  padding: 2px;
`;

export const InfoText = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: ${Color.gray9};
`;

export const InfoTextBold = styled(InfoText)`
  font-weight: ${FontWeight.Bold};
  color: ${Color.almostBlack};
`;

export const Button = styled(ButtonBase)`
  margin-top: 50px;
  min-width: 200px;
`;

export const Message = styled.p`
  margin-top: 5px;
  display: flex;
  width: fit-content;
  background-color: ${Color.lightYellow};
  font-size: 12px;
  line-height: 16px;
  padding: 12px 15px;
  border-radius: 2px;
`;

export const Error = styled(Message)`
  background-color: ${Color.error};
  color: ${Color.white};
`;
