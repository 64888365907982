import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { renderWhenTrue } from "../../../../helpers/rendering";
import { renderTextWithNewlines } from "../../../../helpers/textOperations";
import { ReactComponent as CircleIcon } from "../../images/competenceCircle.svg";
import { selectCompetenceOverview } from "../../redux/competence.selectors";
import { ArrowIcon } from "../competenceTab/competenceTab.styled";
import { LevelMeasureDropdown } from "../levelMeasureDropdown/levelMeasureDropdown.component";

import {
  Box,
  BoxText,
  DropdownTogglePanel,
  Text,
} from "./CompetenceDescriptionBox.styled";

export const CompetenceDescriptionBox = () => {
  const { t } = useTranslation();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const competenceOverview = useSelector(selectCompetenceOverview);
  const descriptionText = competenceOverview.competence.description;

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const renderLevelMeasureDropdown = renderWhenTrue(() => (
    <LevelMeasureDropdown />
  ));

  return (
    <Box>
      <BoxText>{renderTextWithNewlines(descriptionText)}</BoxText>
      <DropdownTogglePanel onClick={toggleDropdown}>
        <CircleIcon />
        <Text>{t("competence.competenceDescriptionBox.lookHowWeMeasure")}</Text>
        <ArrowIcon open={dropdownVisible} />
      </DropdownTogglePanel>
      {renderLevelMeasureDropdown(dropdownVisible)}
    </Box>
  );
};
