import React from "react";
import { useTranslation } from "react-i18next";

import { ButtonVariant } from "../../components/button/button.constants";
import { LoadingWrapper } from "../../UIElements";

import {
  Container,
  InfoRow,
  InfoIcon,
  InfoText,
  InfoLink,
  ButtonsRow,
  Button,
} from "./registrationFooter.styled";

export const RegistrationFooter = ({
  onNext,
  step,
  goBack,
  signIn,
  loading,
  firstStepButtonDisabled,
}) => {
  const { t } = useTranslation();
  const goToMainPage = () => window.location.replace("/");

  return (
    <Container>
      {step === 1 ? (
        <>
          <InfoRow>
            <InfoIcon />
            <InfoText>
              {t("userRegistration.registrationFooter.ifYouCantRememberPassword")} {" "}
              <InfoLink href="#przypomnijhaslo">
                {t("userRegistration.registrationFooter.remindPassword")}
              </InfoLink>
              {" "} {t("userRegistration.registrationFooter.ifYouCantRememberPassword_2")}
            </InfoText>
          </InfoRow>
          <ButtonsRow>
            <Button
              variant={ButtonVariant.Outlined}
              onClick={goToMainPage}
            >
              {t("userRegistration.registrationFooter.cancel")}
            </Button>
            <Button
              disabled={firstStepButtonDisabled}
              onClick={onNext}
            >
              {t("userRegistration.registrationFooter.next")}
            </Button>
          </ButtonsRow>
        </>
      ) : (
        <ButtonsRow>
          <Button
            variant={ButtonVariant.Outlined}
            onClick={goBack}
          >
            {t("userRegistration.registrationFooter.back")}
          </Button>
          <LoadingWrapper isLoading={loading}>
            <Button onClick={signIn}>
              {t("userRegistration.registrationFooter.register")}
            </Button>
          </LoadingWrapper>
        </ButtonsRow>
      )}
    </Container>
  );
};
