import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";

import { Container } from "./loader.styled";

export const Loader = ({ className, size = 50, isLesson }) => {
  return (
    <Container className={className} $isLesson={isLesson}>
      <CircularProgress size={size} />
    </Container>
  );
};


Loader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};
