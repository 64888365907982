import styled, { css } from "styled-components";
import { Drawer, Dialog, DialogContent } from "@mui/material";

import { hexToRgba } from "../../helpers/colors";
import { Color } from "../../theme/colors";
import { FontWeight } from "../../theme/fonts";
import { Icon as IconBase } from "../icon/icon.component";
import { Media } from "../../theme/media";
import { ReactComponent as CloseIconBase } from "../../images/close2.svg";

const commonAttrs = {
  BackdropProps: {
    classes: {
      root: "modal-backdrop"
    }
  },
  classes: {
    paper: "modal-wrapper"
  }
};

const commonStyles = css`
  .modal-backdrop {
    background-color: ${hexToRgba(Color.black, .7)};
  }

  .modal-wrapper {
    max-width: 780px;
    border-radius: 0;
    box-shadow: 0 2px 10px ${hexToRgba(Color.deepGray, .01)};

    ${Media.tablet`
      width: 80vw;
    `}
  }
`;

export const DialogContainer = styled(Dialog).attrs(() => commonAttrs)`
  ${commonStyles}
`;

export const DrawerContainer = styled(Drawer).attrs(() => ({
  ...commonAttrs,
  anchor: "bottom",
}))`
  ${commonStyles}

  .modal-wrapper {
    max-width: 100%;
    max-height: calc(100% - 40px);
  }
`;

export const Content = styled(DialogContent)`
  && {
    padding: 0;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
  }
`;

export const Header = styled.header`
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid ${Color.gray8};
  font-size: 10px;
  line-height: 20px;
  color: ${Color.deepGray};
  font-weight: ${FontWeight.Bold};
  text-transform: uppercase;
  flex: none;
  position: relative;
  box-shadow: 0px 5px 10px ${hexToRgba(Color.deepGray, 0.01)};

  ${Media.tablet`
    padding: 0 30px;
  `}
`;

export const Icon = styled(IconBase)`
  margin-right: 15px;
  color: ${() => Color.brandBasic};
`;

export const CloseIcon = styled(CloseIconBase)`
  height: 12px;
  width: 12px;
  fill: ${Color.deepGray};
  margin-left: auto;
  cursor: pointer;
`;
