export const TrainingsView = {
  Default: "default",
  DateList: "dateList",
  Calendar: "calendar",
};

export const CategoryEnum = {
  Exams: "exams",
  Development: "development_training",
  ProductFinancing: "product_financing_training",
  ProductInsurance: "product_insurance_training",
  ProductService: "product_service_training",
  ProductOthers: "product_others_training",
  SystemOperations: "system_operations_training",
  HRTraining: "hr_training",
  InformationTraining: "information_training",
  Other: "other_trainings",
};

export const TrainingsViewStorageKey = "trainingsView";

export const TrainingPhoneLength = {
  Short: 9,
  Long: 11,
};
