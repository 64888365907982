import { prop } from "ramda";
import styled, { css } from "styled-components";

import { styleWhenTrue } from "../../../helpers/rendering";
import { Media, Color, FontWeight, ContentMaxWidth } from "../../../theme";

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;

  ${Media.tablet`
    min-height: 650px;
    max-width: ${ContentMaxWidth};
    margin: 0 auto;
  `};
`;

export const Header = styled.h1`
  font-size: 26px;
  line-height: 35px;
  font-weight: ${FontWeight.SemiBold};
  text-align: left;

  ${styleWhenTrue(prop("complianceTheme"), css`
    color: ${Color.blue4};
  `)}
`;

export const Subheader = styled.p`
  font-size: 13px;
  line-height: 18px;
  margin-top: 5px;
  font-weight: ${FontWeight.Light};
`;

export const LightHeader = styled.p`
  color: ${Color.gray4};
  font-size: 16px;
  margin-bottom: 13px;
`;

export const HeaderContainer = styled.div`
  margin-bottom: 30px;
  width: 100%;
`;
