import dayjs from "dayjs";

export const countDays = (event) => {
  if (!event.date.to) return 0;

  const start = dayjs.unix(event.date.from);
  const end = dayjs.unix(event.date.to);
  let daysDiff = Math.abs(start.diff(end, "days")) + 1;

  if (end.format("HH:mm") < start.format("HH:mm")) {
    daysDiff += 1;
  }

  const days = daysDiff > 0 ? daysDiff : 1;
  return days;
}

export const parseAgenda = (agenda) => {
  const map = new Map();
  const parsedAgenda = [];

  agenda.map((single) => ({
    ...single,
    from: dayjs.unix(single.timestampStart).format("HH:mm"),
    to: dayjs.unix(single.timestampEnd).format("HH:mm")
  })).forEach((single) => {
    const day = dayjs.unix(single.timestampStart).format("DD-MM-YYYY");
    const dayAgenda = map.get(day) || [];
    dayAgenda.push(single);
    map.set(day, dayAgenda);
  });

  map.forEach((value) => parsedAgenda.push(value));
  return parsedAgenda;
}
