import React, { useState, useEffect } from "react";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { OnboardingMode } from "../../../../app/app.constants";
import {
  selectOnboardingUser,
  selectOnboardingCertificatePath,
  selectOnboardingContactInfo,
  selectOnboardingStatistic,
} from "../../redux/onboarding.selectors";
import { Header } from "../header/header.component";
import ContactsSection from "../contactsSection";
import { ActionSection } from "../actionSection/actionSection.component";
import VideosSection from "../videosSection";

import { Container } from "./onboardingHR.styled";

export const OnboardingHR = () => {
  const { t } = useTranslation();
  const user = useSelector(selectOnboardingUser);
  const certificatePath = useSelector(selectOnboardingCertificatePath);
  const contactInfo = useSelector(selectOnboardingContactInfo);
  const statistic = useSelector(selectOnboardingStatistic);

  const [scrollActive, setScrollActive] = useState(false);
  const startOfWork = dayjs.unix(user.experience.from);
  const firstLogin = dayjs.unix(user.firstLogin);
  const today = dayjs();

  const daysLeft = startOfWork.diff(today, "days");
  const daysFromFirstLogin = startOfWork.diff(firstLogin, "days");

  const workStartProgress = (daysFromFirstLogin - daysLeft) / daysFromFirstLogin;
  const { items } = statistic;
  const contentWatchedInPercent = Math.round(items.count / items.total * 100);

  useEffect(() => {
    setScrollActive(false);
  }, [scrollActive]);

  const handleScrollToContact = () => {
    setScrollActive(true);
  };

  return (
    <Container>
      <Header
        name={user.name}
        daysLeft={daysLeft}
        contentWatchedInPercent={contentWatchedInPercent}
        workStartProgress={workStartProgress}
        welcomeMessage={t("onboarding.header.welcomeMessageDaysLeft", { daysLeft })}
        mode={OnboardingMode.HR}
      />
      <ActionSection onAction={handleScrollToContact} />
      <VideosSection content={certificatePath} />
      <ScrollIntoViewIfNeeded active={scrollActive} options={{
        block: "start",
        behavior: "smooth",
      }}>
        <ContactsSection contacts={contactInfo} />
      </ScrollIntoViewIfNeeded>
    </Container>
  );
};
