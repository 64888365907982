import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Slider } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Tooltip } from "../../../../components/tooltip/tooltip.component";
import { Icon } from "../../../../components/icon/icon.component";
import { TagList } from "../../../../components/tagList/tagList.component";
import { CookieName } from "../../../../app/cookiePopup/cookiePopup.constants";
import { ReactComponent as FullscreenIcon } from "../../../../images/fullscreen.svg";
import { ReactComponent as ListIcon } from "../../../../images/list.svg";
import { isCookieEnabled } from "../../../../helpers/isCookieEnabled";
import { renderWhenTrue } from "../../../../helpers/rendering";

import { MuiSliderSize, VolumeStorageKey, VideoSpeedStorageKey } from "./playerPanel.constants";
import {
  Container,
  PlayButton,
  Separator,
  PlayIcon,
  PauseIcon,
  Title,
  TextWrapper,
  ToolsWrapper,
  SliderWrapper,
  TimeText,
  CurrentTime,
  SoundSlider,
  LoudSoundIcon,
  QuietSoundIcon,
  MutedIcon,
  SoundButton,
  PanelButton,
  TagsWrapper,
  Subtitle,
  SubtitleText,
  Bold,
  Description,
  StyledSpeedOption,
} from "./playerPanel.styled";

export const PlayerPanel = ({
  className,
  videoRef,
  fullscreenWrapperRef,
  currentTime,
  videoDuration,
  isPlaying,
  onPause,
  onPlay,
  timelineDisabled,
  title,
  isInteraction,
  onListClick,
  tags,
  currentSlideIndex,
  slidesOverall,
  subtitle,
  description,
  isScrollContent,
  hideVolume,
  hideFullscreenButton,
  fullscreen,
}) => {
  const { t } = useTranslation();
  const getInitialVolume = () => localStorage.getItem(VolumeStorageKey) || 1;
  const getInitialSpeed = () => localStorage.getItem(VideoSpeedStorageKey) || 1;
  const [volume, setVolume] = useState(getInitialVolume());
  const [progress, setProgress] = useState(0);
  const [speed, setSpeed] = useState(Number(getInitialSpeed()));
  const [draggingDisabled, setDraggingDisabled] = useState(false);
  const [timelineVisible, setTimelineVisible] = useState(true);
  const fullscreenEnabled = videoRef && videoRef.requestFullscreen && !hideFullscreenButton;

  const handleDraggingDisabling = useCallback(() => {
    setDraggingDisabled(true);
    setTimeout(() => setDraggingDisabled(false), 1);
  }, []);

  const setPlaybackSpeed = (value) => {
    setSpeed(value);
    videoRef.playbackRate = value;

    if (isCookieEnabled(CookieName.Functional)) localStorage.setItem(VideoSpeedStorageKey, value);
  };

  const handleChangeVolume = useCallback((volume) => {
    const newVolume = volume / 100;
    setVolume(newVolume);
    videoRef.muted = false;
    videoRef.volume = newVolume;

    if (isCookieEnabled(CookieName.Functional)) localStorage.setItem(VolumeStorageKey, newVolume);
  }, [localStorage, videoRef]);

  const handleSliderVolume = (_, volume) => {
    handleChangeVolume(volume);
  };

  const handleDeviceOrientationChange = useCallback(() => {
    let timeout = null;
    setTimelineVisible(false); // It's important, it has to be invisible during device orientation change
    timeout = setTimeout(() => setTimelineVisible(true), 1); // Otherwise iOS devices will be crashing

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("focus", handleDraggingDisabling);
    window.addEventListener("orientationchange", handleDeviceOrientationChange);

    return () => {
      window.removeEventListener("focus", handleDraggingDisabling);
      window.removeEventListener("orientationchange", handleDeviceOrientationChange);
    };
  }, [handleDraggingDisabling]);

  useEffect(() => {
    if (videoRef?.volume) {
      videoRef.volume = volume;
    }
  }, [videoRef, volume]);

  useEffect(() => {
    if (videoRef?.playbackRate) {
      videoRef.playbackRate = speed;
    }
  }, [videoRef, speed]);

  useEffect(() => {
    setProgress(currentTime);
  }, [currentTime]);

  const handlePauseClick = () => {
    onPause();
  };

  const handlePlayClick = () => {
    onPlay();
    setVolume(videoRef.volume);
  };

  const handleChangeProgress = (_, progress) => {
    if (!timelineDisabled && progress < videoDuration - 1) {
      setProgress(progress);
      videoRef.currentTime = progress;
    }
  };

  const handleMuteClick = () => {
    if (!volume) {
      handleChangeVolume(Math.round(localStorage.getItem(VolumeStorageKey) * 100) || 100);
    } else {
      setVolume(0);
    }
  };

  const toggleFullscreen = () => {
    if (fullscreen) {
      document.exitFullscreen();
    } else if (fullscreenWrapperRef.requestFullscreen) {
      fullscreenWrapperRef.requestFullscreen();
    }
  };

  const returnTimeString = (value) => {
    if (value < 10) {
      return `0${value}`;
    }
    return value;
  };

  const convertTimer = (timeInSeconds) => {
    let minutes = Math.floor(timeInSeconds / 60);
    let seconds = Math.floor(timeInSeconds % 60);
    return `${returnTimeString(minutes)}:${returnTimeString(seconds)}`;
  };

  const renderPlayButton = () => {
    const icon = isPlaying ? <PauseIcon /> : <PlayIcon />;
    const onClickAction = isPlaying ? handlePauseClick : handlePlayClick;

    return (
      <PlayButton onClick={onClickAction}>
        {icon}
      </PlayButton>
    );
  };

  const renderTimeSlider = renderWhenTrue(() => {
    const current = convertTimer(currentTime);
    const duration = videoDuration ? convertTimer(videoDuration) : "00:00";

    return (
      <SliderWrapper disabled={timelineDisabled || draggingDisabled}>
        <Slider
          min={0}
          max={videoDuration || undefined}
          value={progress}
          onChange={handleChangeProgress}
          size={MuiSliderSize}
        />
        <TimeText>
          <CurrentTime>{current}</CurrentTime>
          {" / "}
          {duration}
        </TimeText>
      </SliderWrapper>
    );
  });

  const renderListButton = renderWhenTrue(() => (
    <PanelButton onClick={onListClick}>
      <Icon>
        <ListIcon />
      </Icon>
    </PanelButton>
  ));

  const renderSoundSlider = renderWhenTrue(() => {
    const value = volume ? Math.round(volume * 100) : 0;
    const icon = volume > 0
      ? volume > 0.5
        ? <LoudSoundIcon />
        : <QuietSoundIcon />
      : <MutedIcon />;
    return (
      <SoundSlider>
        <SoundButton onClick={handleMuteClick}>
          {icon}
        </SoundButton>
        <Slider
          value={value}
          min={0}
          max={100}
          onChange={handleSliderVolume}
          size={MuiSliderSize}
        />
      </SoundSlider>
    );
  });

  const renderSubtitle = renderWhenTrue(() => (
    <Subtitle>
      <Bold>{currentSlideIndex + 1}</Bold>
      {" "}
      ({slidesOverall})
      <SubtitleText>{subtitle}</SubtitleText>
    </Subtitle>
  ));

  return (
    <Container className={className} $fullscreen={fullscreen}>
      <ToolsWrapper>
        {renderPlayButton()}
        {renderTimeSlider(timelineVisible)}
        {renderListButton(isInteraction && !fullscreen)}
        <StyledSpeedOption currentSpeed={speed} setSpeed={setPlaybackSpeed} />
        {renderSoundSlider(!hideVolume && !!videoRef)}
        <Tooltip title={t("videoPlayer.fullscreen")}>
          <PanelButton onClick={toggleFullscreen} hidden={!fullscreenEnabled}>
            <Icon>
              <FullscreenIcon />
            </Icon>
          </PanelButton>
        </Tooltip>
      </ToolsWrapper>
      <Separator $narrow />
      {!isScrollContent && !fullscreen && (
        <>
          <TagsWrapper>
            <TagList tags={tags} />
          </TagsWrapper>
          <TextWrapper>
            <Title>{title}</Title>
            {renderSubtitle(isInteraction)}
            {!!description && (
              <Description>{description}</Description>
            )}
          </TextWrapper>
        </>
      )}
    </Container>
  );
};

PlayerPanel.propTypes = {
  className: PropTypes.string,
  videoRef: PropTypes.object,
  currentTime: PropTypes.number,
  isPlaying: PropTypes.bool,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
  timelineDisabled: PropTypes.bool,
  isInteraction: PropTypes.bool,
  onListClick: PropTypes.func,
  currentSlideIndex: PropTypes.number,
  slidesOverall: PropTypes.number,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  isScrollContent: PropTypes.bool,
  hideVolume: PropTypes.bool,
  hideFullscreenButton: PropTypes.bool,
  fullscreen: PropTypes.bool,
};
