import styled from "styled-components";

import { ContentMaxWidth, Media } from "../../../../theme";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 60px 25px 45px;

  ${Media.tablet`
    padding: 100px 25px;
  `}
`;

export const ContentWrapper = styled.div`
  width: ${ContentMaxWidth};
`;

export const VideosWrapper = styled.div``;
