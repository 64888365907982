import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Float as FloatBase } from "../../../../components/float/float.component";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.div`
  background-color: ${Color.white};
  padding: 25px;
  z-index: 1;

  ${styleWhenTrue(prop("$withBg"), css`
    background-color: ${Color.white};
  `)}

  ${Media.tablet`
    width: 44%;
    margin-left: 20px;
    background-color: ${Color.transparent};

    ${styleWhenTrue(prop("$withBg"), css`
      background-color: ${Color.white};
    `)}

    ${styleWhenTrue(prop("$isInteraction"), css`
      width: 100%;
      margin-left: 0;
    `)}
  `}

  ${styleWhenTrue(prop("$fluidHeight"), css`
    height: 100%;
  `)}
`;

export const Title = styled.p`
  font-weight: ${FontWeight.Bold};
  font-size: 24px;
  line-height: 32px;

  ${styleWhenTrue(prop("$big"), css`
    font-size: 38px;
    line-height: 52px;
  `)}
`;

export const Separator = styled.div`
  height: 2px;
  background-color: ${() => Color.brandBasic};
  margin: 15px 0 25px;
`;

export const Subtitle = styled.p`
  font-size: 18px;
  line-height: 28px;

  b {
    font-weight: ${FontWeight.Bold};
  }
`;

export const HelperText = styled.p`
  margin-top: 10px;
  font-size: 15px;
  line-height: 20px;
  color: ${Color.gray9};
`;

export const CounterWrapper = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
`;

export const CurrentSlide = styled.span`
  font-weight: ${FontWeight.SemiBold};
`;

export const Float = styled(FloatBase)`
  margin-top: 30px;
`;
