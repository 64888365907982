import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Label } from "../trainerPopup/trainerPopup.styled";

import {
  Container,
  StatsWrapper,
  StatBox,
  StatLabel,
  StatValue,
  TextBox,
  Specialization,
  Separator,
} from "./trainerPopupStats.styled";

export const TrainerPopupStats = ({
  className,
  yearsOfExperience,
  trainingsDone,
  specializations,
}) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <StatsWrapper>
        <StatBox>
          <StatValue>{yearsOfExperience}</StatValue>
          <StatLabel>{t("trainings.trainerPopup.experience")}</StatLabel>
        </StatBox>
        {!!trainingsDone && (
          <StatBox>
            <StatValue>{trainingsDone}</StatValue>
            <StatLabel>{t("trainings.trainerPopup.trainingsDone")}</StatLabel>
          </StatBox>
        )}
      </StatsWrapper>
      <Separator />
      <TextBox>
        <Label>{t("trainings.trainerPopup.specialization")}</Label>
        <Specialization>
          {specializations.map((specialization) => specialization.name).join(", ")}
        </Specialization>
      </TextBox>
    </Container>
  );
};

TrainerPopupStats.propTypes = {
  yearsOfExperience: PropTypes.string.isRequired,
  trainingsDone: PropTypes.string.isRequired,
  specializations: PropTypes.array.isRequired,
  className: PropTypes.string,
};
