import i18n from "i18next";

import { ReactComponent as ShareSvg } from "../../images/share.svg";
import { ReactComponent as CameraSvg } from "../../images/camera.svg";

export const ShareCameraContent = {
  title: i18n.t("webinars.shareVideo.shareVideo"),
  text: i18n.t("webinars.shareVideo.allowSharing"),
  icon: CameraSvg,
};

export const ShareScreenContent = {
  title: i18n.t("webinars.shareScreenModal.shareScreen"),
  text: i18n.t("webinars.shareScreenModal.allowSharing"),
  icon: ShareSvg,
};
