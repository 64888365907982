import React from "react";
import styled from "styled-components";
import i18n from "i18next";

import { colors } from "../UIGlobals";
import { Icon } from "./";

const Wrapper = styled.i`
  display: flex;
  position: ${props => (props.position ? props.position : "absolute")};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : "0")};
  top: ${props => (props.top ? props.top : "10px")};
  right: ${props => (props.right ? props.right : "10px")};
  width: 28px;
  height: 28px;
  background: ${colors().gray1};
  border-radius: 14px;
  text-align: center;
  line-height: 15px;
  align-items: center;
  justify-content: center;
`;

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
  &:hover {
    .tooltip-text {
      visibility: visible;
      z-index: 1;
    }
  }
`;

const TooltipText = styled.span`
  visibility: hidden;
  font-weight: bold;
  font-size: 9px;
  width: 100px;
  background-color: ${colors().black};
  color: ${colors().white};
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  bottom: 130%;
  left: 50%;
  margin-left: -50px;
  &::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border: 5px solid ${colors().black};
    border-color: ${colors().black} transparent transparent transparent;
  }
`;

const ThumbnailWarning = ({
  position,
  marginLeft,
  top,
  right,
}) => (
  <Wrapper
    position={position}
    marginLeft={marginLeft}
    top={top}
    right={right}
  >
    <TooltipWrapper>
      <TooltipText className="tooltip-text">{i18n.t("dashboard.dashboard.required")}</TooltipText>
      <Icon icon="warning" size={24} />
    </TooltipWrapper>
  </Wrapper>
);

export { ThumbnailWarning };
