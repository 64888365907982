import React from "react";
import shuffle from "lodash/shuffle";

import * as UI from "./trueFalseOrderUI";
import { Icon } from "../../../UIElements";
import { colors } from "../../../UIGlobals";

class TaskScreen extends React.Component {
  constructor(props) {
    super(props);

    let sentences = [];
    props.sentences.forEach(item => {
      sentences.push({ content: item.content, ordered: true });
    });

    this.state = {
      items: [...shuffle(sentences)]
    };
  }

  createItems = items => (
    items.map((item) => (
      <UI.Item key={`item-${item.content}`}>
        <UI.ItemContent>
          {item.content}
        </UI.ItemContent>
        <UI.ItemArrows>
          <UI.ArrowButton onClick={() => this.onItemUp(item)}>
            <Icon icon="arrow_down" color={colors().white} size={30} rotation={"180deg"} />
          </UI.ArrowButton>
          <UI.ArrowButton onClick={() => this.onItemDown(item)}>
            <Icon icon="arrow_down" color={colors().white} size={30} />
          </UI.ArrowButton>
        </UI.ItemArrows>
      </UI.Item>
    )));

  onItemUp = item => {
    const index = this.state.items.findIndex(searchItem => searchItem === item);

    this.setState(state => {
      let newOrder = JSON.parse(JSON.stringify(state.items));
      const falseItems = newOrder.filter(item => !item.ordered);

      if ((index > 0 && falseItems.length === 0)
            || (index > 0 && falseItems.length > 0 && falseItems[0].content !== item.content)) {
        newOrder[index].content = this.state.items[index - 1].content;
        newOrder[index - 1].content = item.content;
      } else if (falseItems.length > 0 && falseItems[0].content === item.content) {
        newOrder[index].ordered = true;
      }

      return {
        items: newOrder
      };
    });
  };

  onItemDown = item => {
    const index = this.state.items.findIndex(searchItem => searchItem === item);

    this.setState(state => {
      let newOrder = JSON.parse(JSON.stringify(state.items));
      const trueItems = newOrder.filter(item => item.ordered);

      if ((index < newOrder.length - 1 && trueItems.length === 0)
            || (index < newOrder.length - 1 && trueItems.length > 0 && trueItems[trueItems.length - 1].content !== item.content)) {
        newOrder[index].content = state.items[index + 1].content;
        newOrder[index + 1].content = state.items[index].content;
      } else if (trueItems.length > 0 && trueItems[trueItems.length - 1].content === item.content) {
        newOrder[index].ordered = false;
      }

      return {
        items: newOrder
      };
    });
  };

  verify = () => {
    const { onSuccess, onFailure } = this.props;
    this.sameOrder(this.props.sentences, this.state.items) ? onSuccess() : onFailure();
  }

  sameOrder(key, userItems) {
    const correctAnswer = key.slice(0, 4);
    const userAnswer = userItems.filter(item => item.ordered);

    if (JSON.stringify(correctAnswer) !== JSON.stringify(userAnswer)) {
      return false;
    }

    return true;
  }

  render() {
    return (
      <UI.Container>
        <UI.TaskScreen>
          <UI.HeaderDiv>
            <UI.Header>POLECENIE</UI.Header>
            <UI.Header>
              Używając strzałek uporządkuj prawidłowe działania w kolejności. Odrzuć nieprawidłowe stwierdzenia.
            </UI.Header>
            <UI.MainButton onClick={this.verify}>Dalej</UI.MainButton>
          </UI.HeaderDiv>
          <UI.ItemsDiv>
            {this.createItems(this.state.items.filter(item => item.ordered))}
            <UI.Separator>Miejsce na działania nieprawidłowe</UI.Separator>
            {this.createItems(this.state.items.filter(item => !item.ordered))}
          </UI.ItemsDiv>
        </UI.TaskScreen>
        <UI.Mobile>
          <UI.Header>UWAGA</UI.Header>
          <UI.Header>
            Interakcja nie jest dostępna w wersji mobilnej.
          </UI.Header>
          <UI.MainButton onClick={this.props.onSuccess}>Obejrzałem</UI.MainButton>
        </UI.Mobile>
      </UI.Container>
    );
  }
}

export default TaskScreen;
