import styled from "styled-components";

import { colors, breakpoints } from "../UIGlobals";
import { Transitions } from "../theme";

const InlineBox = styled.div`
  background-color: ${props => (props.$background ? colors()[props.$background] : colors().white)};
  padding: ${props => (props.padding ? props.padding : "17px 29px")};
  box-shadow: 0px 5px 10px 0 rgba(38, 39, 42, 0.01);
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent ? props.justifyContent : "space-between"};
  margin-bottom: 2px;
  transition: ${Transitions.custom};
  cursor: ${props => (props.hover ? "pointer" : "auto ")};
  flex-wrap: ${props => (props.flexWrap ? props.flexWrap : "")};
  opacity: 0.999;
  width: ${props => props.width ? props.width : "" };
  border: ${props => props.border ? `1px solid ${colors()[props.border]}` : 0  };
  border-bottom: ${props => props.borderBottom ? `1px solid ${colors()[props.borderBottom]}` : "" };
  &:hover {
    opacity: ${props => (props.hover ? "0.7" : "1")};
  }
  @media (${breakpoints().maxM}) {
    flex-direction: ${props => (props.mobileFlexDirection ? props.mobileFlexDirection : "row")};
  }
`;

const InlineBoxText = styled.div`
  text-align: ${props => props.textAlign ? props.textAlign : "left"};
  font-weight: ${props => props.fontWeight ? props.fontWeight : 600};
  color: ${colors().gray4};
  font-size: 1.3rem;
  max-width: ${props => (props.maxWidth ? props.maxWidth + "px" : "initial")};
  min-width: ${props => (props.minWidth ? props.minWidth + "px" : "initial")};
  width: ${props => (props.width ? props.width : "inherit")};
`;

const InlineBoxTextLast = styled.div`
  font-weight: 500;
  color: ${colors().gray4};
  font-size: 1.3rem;
  margin-left: 10px;
  min-width: 80px;
  max-width: 80px;
  text-align: right;
`;

const InlineBoxInside = styled.div`
  margin-left: ${props => (props.marginLeft !== null ? props.marginLeft : "auto")};
  display: flex;
  align-items: center;
`;

const Dot = styled.span`
  display: block;
  width: ${props => (props.width ? props.width + "px" : "3px")};
  height: ${props => (props.height ? props.height + "px" : "3px")};
  margin-right: ${props => (props.marginRight ? props.marginRight + "px" : "0")};
  border-radius: 50%;
  margin-left: ${props => (props.marginLeft !== null ? props.marginLeft : "auto")};
  background: ${props => (props.background ? colors()[props.background] : colors().white)};
`;

const IconCircle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 1px ${colors().dirtyWhite};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InlineBoxInsideCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  transform: translateX(-50%);
`;

export {
  Dot,
  InlineBox,
  InlineBoxText,
  InlineBoxTextLast,
  InlineBoxInside,
  IconCircle,
  InlineBoxInsideCenter,
};
