import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Radio, RadioGroup } from "@mui/material";

import { renderWhenTrueOtherwise } from "../../../../../helpers/rendering";
import { WrongAnswer } from "../wrongAnswer/wrongAnswer.component";

import {
  Container,
  Counter,
  QuestionText,
  Answer,
  AnswerLabel,
  Button,
  ButtonWrapper,
  CheckImg,
} from "./question.styled";

export const Question = ({
  className,
  questionCounter,
  allQuestionsCounter,
  answers,
  onNextQuestion,
  onWin,
  correctAnswer,
  question,
}) => {
  const { t } = useTranslation();
  const [answerValue, setAnswerValue] = useState(null);
  const [isAnswerConfirmed, setIsAnswerConfirmed] = useState(false);

  const handleChange = useCallback((value) => {
    setAnswerValue(value);
  }, [setAnswerValue]);

  const handleConfirmation = useCallback(() => {
    setIsAnswerConfirmed(true);

    if (questionCounter >= allQuestionsCounter - 1 && answerValue === answers[correctAnswer]) {
      onWin();
    }
  }, [
    setIsAnswerConfirmed,
    answerValue,
    questionCounter,
    allQuestionsCounter,
    answers,
    correctAnswer,
    onWin,
  ]);

  const handleTryAgain = useCallback(() => {
    setAnswerValue(null);
    setIsAnswerConfirmed(false);
  }, []);

  const handleNextQuestion = useCallback(() => {
    setAnswerValue(null);
    setIsAnswerConfirmed(false);
    onNextQuestion();
  }, [setAnswerValue, setIsAnswerConfirmed, onNextQuestion]);

  const renderAnswers = useCallback(() => (
    answers.map((answer) => {
      const isCorrectAnswer = isAnswerConfirmed && answer === answers[correctAnswer];

      return (
        <Answer
          key={answer}
          active={answerValue === answer}
          correct={isCorrectAnswer}
          notChosen={!!answerValue && answerValue !== answer}
          onClick={() => handleChange(answer)}
          disabled={isAnswerConfirmed && answerValue !== answer}
        >
          <AnswerLabel
            value={answer}
            control={<Radio />}
            label={answer}
          />
          <CheckImg visible={isCorrectAnswer.toString()} />
        </Answer>
      );
    })
  ), [answers, answerValue, correctAnswer, isAnswerConfirmed, handleChange]);

  const renderConfirmButton = useCallback(() => (
    <Button
      $large
      onClick={handleConfirmation}
      disabled={!answerValue}
    >
      {t("interactions.opponentTest.confirm")}
    </Button>
  ), [answerValue, handleConfirmation, t]);

  const renderNextButton = () => (
    <Button
      $large
      onClick={handleNextQuestion}
    >
      {t("interactions.opponentTest.next")}
    </Button>
  );

  const renderButton = renderWhenTrueOtherwise(
    renderNextButton,
    renderConfirmButton,
  );

  if (isAnswerConfirmed && answerValue !== answers[correctAnswer]) {
    return <WrongAnswer onTryAgain={handleTryAgain} currentQuestion={questionCounter + 1} />;
  }

  return (
    <Container className={className}>
      <Counter>
        {t("interactions.opponentTest.questionCounter", { counter: questionCounter + 1 })}
      </Counter>
      <QuestionText>{question}</QuestionText>
      <RadioGroup value={answerValue}>
        {renderAnswers()}
      </RadioGroup>
      <ButtonWrapper>
        {renderButton(isAnswerConfirmed)}
      </ButtonWrapper>
    </Container>
  );
};


Question.propTypes = {
  className: PropTypes.string,
  questionCounter: PropTypes.number,
  allQuestionsCounter: PropTypes.number,
  answers: PropTypes.array,
  onNextQuestion: PropTypes.func,
  onWin: PropTypes.func,
  correctAnswer: PropTypes.number,
};
