import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import shuffle from "lodash/shuffle";

import InteractionQuestionHeader from "../../../components/interactionQuestionHeader/interactionQuestionHeader.component";
import { Loader } from "../../../components/loader/loader.component";
import { getData } from "../../helpers/api";

import OptionList from "./optionList/optionList.component";
import AnswerList from "./answerList/answerList.component";
import {
  Container,
  OptionsWrapper,
  ButtonWrapper,
  Button,
  ErrorMessage,
} from "./dragAndDropOrder.styled";

const DragAndDropOrder = ({ baseUrl, config, callComplete }) => {
  const { t } = useTranslation();
  const [initialData, setInitialData] = useState();
  const [currentOrder, setCurrentOrder] = useState([]);
  const [answersConfirmed, setAnswersConfirmed] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const loadInitialData = async (data) => {
    setInitialData(data);
    setCurrentOrder(shuffle(data.options));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (baseUrl && config) {
      getData(`${baseUrl}${config}`, loadInitialData);
    }
  }, [baseUrl, config]);

  const handleSetNewOrder = (newOrder) => {
    setCurrentOrder(newOrder);
    setAnswersConfirmed(false);
    setShowErrorMessage(false);
  };

  const handleCallComplete = () => {
    callComplete();
    window.scrollTo(0, 0);
  };

  const checkAnswers = () => {
    let resultCorrect = true;

    for (let i = 0; i < initialData.answers.length; i++) {
      if (!currentOrder[i].value.includes(initialData.answers[i].id)) {
        resultCorrect = false;
        break;
      }
    }

    return resultCorrect;
  };

  const confirmAnswers = () => {
    setAnswersConfirmed(true);

    if (checkAnswers()) {
      handleCallComplete();
    } else {
      setShowErrorMessage(true);
    }
  };

  if (!initialData) {
    return <Loader />;
  }

  return (
    <Container>
      <InteractionQuestionHeader title={initialData.title} question={initialData.question} hint={initialData.hint} />
      <OptionsWrapper>
        <OptionList
          currentOrder={currentOrder}
          setNewOrder={handleSetNewOrder}
          answers={initialData.answers}
          answersConfirmed={answersConfirmed}
        />
        <AnswerList answers={initialData.answers} />
      </OptionsWrapper>
      <ButtonWrapper>
        <Button disabled={answersConfirmed} onClick={confirmAnswers}>
          {t("interactions.dragAndDropOrder.confirm")}
        </Button>
      </ButtonWrapper>
      {showErrorMessage && (
        <ErrorMessage>{t("interactions.dragAndDropOrder.answersNotCorrect")}</ErrorMessage>
      )}
    </Container>
  );
};

export default DragAndDropOrder;
