import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../helpers/rendering";
import { Media, FontWeight, Color } from "../../theme";
import { Icon as IconBase } from "../icon/icon.component";
import { Button as ButtonBase } from "../button/button.component";
import { VisibilityField as VisibilityFieldBase } from "../visibilityField/visibilityField.component";

export const Container = styled.div``;

export const Fields = styled.div`
  padding: 20px;

  ${Media.tablet`
    padding: 20px 30px;
  `}
`;

export const Actions = styled.div`
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${Media.tablet`
    padding: 30px;
  `}
`;

export const Button = styled(ButtonBase)`
  & + & {
    margin-left: 10px;
  }
`;

export const AddButton = styled(ButtonBase)`
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding-left: 15px;
`;

export const Icon = styled(IconBase)`
  margin-right: 15px;
  color: ${Color.gray4};
`;

export const VisibilityField = styled(VisibilityFieldBase)`
  padding-top: 30px;
  padding-bottom: 15px;
`;

export const ImageUploadWrapper = styled.div`
  margin-bottom: 20px;
`;

export const Attachments = styled.div`
  padding: 30px 0 25px;
  font-size: 12px;
  line-height: 17px;
  display: flex;

  ${styleWhenTrue(prop("withError"), css`
    padding-bottom: 11px;
  `)}
`;

export const AttachmentsLabel = styled.label`
  font-weight: ${FontWeight.Bold};
  color: ${Color.deepGray};
  margin-right: 30px;
`;

export const AttachmentsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;
  margin-left: -5px;
`;

export const AttachmentsEmpty = styled.div`
  color: ${Color.gray4};
`;

export const AttachmentErrorText = styled.p`
  color: ${() => Color.brandBasic};
  font-size: 10px;
  line-height: 17px;

  &:last-of-type {
    margin-bottom: 15px;
  }
`;

export const ErrorAttachmentName = styled.span`
  font-weight: ${FontWeight.Bold};
`;

export const ChallengeField = styled.div`
  border-top: 1px solid ${Color.dirtyWhite};
  margin-top: 20px;
  padding-top: 20px;

  ${Media.tablet`
    display: flex;
    align-items: center;
  `}
`;

export const ChallengeText = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.deepGray};
  margin-right: 20px;
  margin-bottom: 10px;
`;

export const DropdownWrapper = styled.div`
  width: 100%;

  ${Media.mobile`
    width: auto;
    min-width: 400px;
  `}
`;
