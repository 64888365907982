import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { CommonActions } from "../../redux/common/common.reducer";
import {
  selectVideoForCodeItems,
  selectVideoForCodeTitle,
  selectVideoForCodeError,
} from "../../redux/common/common.selectors";
import { renderWhenTrueOtherwise, renderWhenTrue } from "../../helpers/rendering";
import { ReactComponent as VideoIcon } from "../../images/video.svg";
import { Modal } from "../modal/modal.component";
import { ThumbnailListType } from "../thumbnailList/thumbnailList.constants";

import { Form } from "./components/form/form.component";
import { EmptyState } from "./components/emptyState/emptyState.component";
import { Container, ThumbnailList } from "./videoForCode.styled";
import { Separator } from "../../theme/typography";

export const VideoForCode = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const availableVideos = useSelector(selectVideoForCodeItems);
  const availableVideosTitle = useSelector(selectVideoForCodeTitle);
  const formError = useSelector(selectVideoForCodeError);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    dispatch(CommonActions.fetchPaidVideos());
  }, [dispatch]);

  const handleCloseModal = useCallback(() => {
    setModalVisible(false);
    dispatch(CommonActions.clearUnlockVideoError());
  }, [dispatch]);
  const handleOpenModal = useCallback(() => setModalVisible(true), []);

  const renderEmptyState = useCallback(() => (
    <EmptyState onAddCodeClick={handleOpenModal} />
  ), [handleOpenModal]);

  const renderThumbnailList = useCallback(() => (
    <>
      <ThumbnailList
        title={availableVideosTitle}
        thumbnails={availableVideos}
        type={ThumbnailListType.VideoForCode}
        onCodeThumbnailClick={handleOpenModal}
      />
      <Separator />
    </>
  ), [availableVideosTitle, availableVideos, handleOpenModal]);

  const renderFormModal = renderWhenTrue(() => (
    <Modal
      className={className}
      open
      title={t("components.videoForCode.popup.addCode")}
      onClose={handleCloseModal}
      HeaderIcon={VideoIcon}
    >
      <Form onSend={handleCloseModal} error={formError} />
    </Modal>
  ));

  const renderContent = renderWhenTrueOtherwise(
    renderThumbnailList,
    renderEmptyState,
  );

  return (
    <Container className={className}>
      {renderContent(!!availableVideos && !!availableVideos.length)}
      {renderFormModal(modalVisible || !!formError)}
    </Container>
  );
};

VideoForCode.propTypes = {
  className: PropTypes.string,
};
