import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Lottie from "lottie-react";
import { useSelector } from "react-redux";
import { InView } from "react-intersection-observer";

import { GamificationTheme } from "../../../../../app/mainNavigation/components/navItem.constants";
import { AppConfig, Theme } from "../../../../../appConfig";
import RewardAnimation from "../../../anims/reward.json";
import { selectIsStarted } from "../../../redux/gamification.selectors";

import {
  Container,
  Wrapper,
  TextWrapper,
  Text,
  BoldText,
  Button,
  CheckboxWrapper,
  Checkbox,
  CheckIcon,
  CheckboxText,
  RegulationsLink,
  AnimationWrapper,
} from "./acceptBox.styled";

export const AcceptBox = ({ className, onParticipate }) => {
  const { t } = useTranslation();
  const isStarted = useSelector(selectIsStarted);
  const buttonLabel = isStarted
    ? t("gamification.start.dashboard")
    : t("gamification.start.participate");
  const [checked, setChecked] = useState(isStarted);

  const toggleCheckbox = () => {
    if (!isStarted) {
      setChecked(!checked);
    }
  };

  return (
    <Container className={className}>
      <Wrapper>
        <TextWrapper>
          <Text>
            {t("gamification.start.rewards")}
            {" "}
            <BoldText>
              {t("gamification.start.doIt")}
            </BoldText>
          </Text>
          {Theme.Gamification === GamificationTheme.Gamification && (
            <CheckboxWrapper onClick={toggleCheckbox} disabled={isStarted}>
              <Checkbox>
                <CheckIcon hidden={!checked} />
              </Checkbox>
              <CheckboxText>
                {t("gamification.start.accept")}
              </CheckboxText>
              {" "}
              <RegulationsLink
                target="_blank"
                href={`${AppConfig.content}/files/regulamin_grywalizacja.pdf`}
                rel="noopener noreferrer"
              >
                {t("gamification.start.regulations")}
              </RegulationsLink>
              {" "}
              <CheckboxText>
                {t("gamification.start.accept2")}
              </CheckboxText>
            </CheckboxWrapper>
          )}
          <Button
            disabled={!checked && Theme.Gamification === GamificationTheme.Gamification}
            onClick={onParticipate}
            $large
          >
            {buttonLabel}
          </Button>
        </TextWrapper>
        <InView>
          {({ inView, ref }) => (
            <AnimationWrapper ref={ref}>
              <Lottie
                animationData={inView ? RewardAnimation : undefined}
                loop={false}
                rendererSettings={{
                  preserveAspectRatio: "xMidYMid slice",
                }}
              />
            </AnimationWrapper>
          )}
        </InView>
      </Wrapper>
    </Container>
  );
};

AcceptBox.propTypes = {
  className: PropTypes.string,
  onParticipate: PropTypes.func.isRequired,
};
